import React from 'react';

export const EmailIcon = () => {
  return (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6415 0.333008H1.34486C1.13342 0.333008 0.937089 0.371843 0.755859 0.449513L6.99318 4.78608L13.2305 0.449513C13.0493 0.371843 12.868 0.333008 12.6415 0.333008Z"
        fill="white"
      />
      <path
        d="M7.41532 6.97778C7.2945 7.0684 7.14347 7.10723 6.99245 7.10723C6.84142 7.10723 6.6904 7.0684 6.56958 6.97778L0 2.4082C0 2.43409 0 2.44704 0 2.48587V9.515C0 10.1493 0.604099 10.6671 1.34412 10.6671H12.6559C13.3959 10.6671 14 10.1493 14 9.515V2.48587C14 2.45998 14 2.44704 14 2.4082L7.41532 6.97778Z"
        fill="white"
      />
    </svg>
  );
};
