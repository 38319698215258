import React, { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import TagManager from 'react-gtm-module';

import CartProductInputs from 'components/Cart/Inputs/CartProductInputs';
import { RemoveIcon } from 'assets/svg/Checkout/Remove';
import { EditQuantityIcon } from 'assets/svg/Checkout/EditQuantityIcon';

import { selectCartLoading } from 'ducks/cart/selectors';
import { selectOrderLoading } from 'ducks/order/selectors';
import { requestDeleteProductFromCart, setQtyUpdateStatus } from 'ducks/cart/actions';
import { changeField, addItemError, removeItemError } from 'ducks/checkout/actions';
import { CheckoutStep } from 'ducks/checkout/types';
import { numberFormat } from 'utils/products/product';
import SecondStepInputs from 'pages/Checkout/ProductList/components/SecondStepInput/SecondStepInput';
import { selectItemErrors } from 'ducks/checkout/selectors';
import { filterPrices } from 'utils/products/productVariant';
import midDefaultProduct from 'assets/images/default-mid-product.png';

import { ShippingMethod } from 'models/interfaces/order';
import {
  priceTypeSelector,
  selectAddressId,
  selectErpCustomerAddressCode, selectIsHidePrice,
} from 'ducks/application/selectors';
import { useHistory } from 'react-router-dom';
import Routes from 'routes';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { getDefaultProductName } from 'utils/products/productUtilsHelpers';
import { getDefaultCategoryName } from 'utils/categories/categories';
import { selectCategories } from 'ducks/category/selectors';
import {useGA4CartEvent} from 'GA/CartGa4/cartGA4';
import {
  IconWrapper,
  EditQuantityText,
  EditQuantityWrapper,
  InfoContent,
  BoxThumbs,
  Image,
  InfoWrapper,
  Cost,
  CostLayout,
  Quantity,
  Subcode,
  Title,
  Remove,
  Layout,
  InnerLayout,
} from './styles';

interface IProductItem {
  item: any;
  isSecondStep: boolean;
}
const ProductItem: React.FC<IProductItem> = ({ item, isSecondStep }: IProductItem) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { variant } = item;
  const loadingCart = useSelector(selectCartLoading);
  const loadingOrder = useSelector(selectOrderLoading);
  const itemErrors = useSelector(selectItemErrors);
  const currentPrices = React.useMemo(() => filterPrices(variant.addressPrices), [
    variant.addressPrices,
  ]);
  const isHidePrice = useSelector(selectIsHidePrice);
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const priceType = useSelector(priceTypeSelector);
  const categories = useSelector(selectCategories);
  const [topInputValue, setTopInputValue] = useState(item.quantity);
  const cartItemCoeff = Number((currentPrices[0].qty / variant.units.uom2Conversion).toFixed(2));
  const [bottomInputValue, setBottomInputValue] = useState<number | null>(
    variant.units.uom2Conversion
      ? Number(((topInputValue * cartItemCoeff) / currentPrices[0].qty).toFixed(2))
      : null
  );

  useEffect(() => {
    setTopInputValue(item.quantity);
  }, [item.quantity]);

  const [unitPrice, setUnitPrice] = useState(0);
  const [oldPrice, setOldPrice] = useState(0);
  const shippingMethod = useSelector(priceTypeSelector);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);

  const deliveryPriceType =
    shippingMethod === ShippingMethod.DELIVERY ? 'deliveryValue' : 'pickupValue';

  const errorHandler = (hasError: boolean) => {
    if (hasError) {
      dispatch(addItemError({ itemId: item.id, hasError }));
    } else {
      dispatch(removeItemError(item.id));
    }
  };

  const {handleFireGA4RemoveItemFromCart} = useGA4CartEvent();

  const onItemDelete = cartItem => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'checkout_remove_product',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });

    if (loadingCart || loadingOrder) return;
    dispatch(requestDeleteProductFromCart(cartItem.id));

    if (itemErrors.some(itemError => itemError.itemId === item.id)) {
      dispatch(removeItemError(item.id));
    }
    handleFireGA4RemoveItemFromCart(item);
  };

  useEffect(() => {
    if (!itemErrors.length) dispatch(setQtyUpdateStatus());
  }, [dispatch, itemErrors]);

  useEffect(() => {
    const promotionPrice = currentPrices;
    if (promotionPrice.length > 1) {
      if (topInputValue >= promotionPrice[1].qty) {
        setUnitPrice(promotionPrice[1][deliveryPriceType]);
      } else {
        setUnitPrice(promotionPrice[0][deliveryPriceType]);
      }
    } else {
      setUnitPrice(promotionPrice[0][deliveryPriceType]);
    }
  }, [topInputValue, shippingMethod, variant.addressPrices]);

  const handleClick = () => {
    clearGtmEcommerce();
    const { itemCategoryName, itemCategory2Name } = getDefaultCategoryName(
      variant.category.id,
      categories
    );
    TagManager.dataLayer({
      dataLayer: {
        event: 'view_item',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
        user_ID: user.id,
        ecommerce: {
          items: [
            {
              item_id: variant.erpSku,
              item_name: getDefaultProductName(variant),
              price: variant.prices.length ? variant.prices[0][priceType] : 0,
              item_category: itemCategoryName,
              item_category2: itemCategory2Name,
              quantity: 1,
            },
          ],
        },
      },
    });
    history.push(Routes.PRODUCT_DETAIL.replace(':code', String(variant.erpProductId)));
  };

  const isShowPromotionPrice = React.useMemo((): boolean => {
    if (currentPrices.length > 1) {
      return (
        topInputValue >= currentPrices[1].qty &&
        currentPrices[0][deliveryPriceType] > currentPrices[1][deliveryPriceType]
      );
    }
    return false;
  }, [currentPrices, deliveryPriceType, topInputValue]);

  useEffect(() => {
    if (isShowPromotionPrice) {
      setOldPrice(currentPrices[0][deliveryPriceType] * topInputValue);
    } else {
      setOldPrice(0);
    }
  }, [isShowPromotionPrice, shippingMethod, topInputValue]);

  /**
   * Render more info
   */
  const renderMoreInfo = () => {
    return (
      <div className="more-info">
        <Quantity>{t('checkout.quantity').toString()}:</Quantity>
        {isSecondStep ? (
          <SecondStepInputs
            values={[topInputValue, bottomInputValue]}
            uoms={[item.variant.units.perName, item.variant.units.uom2Name]}
            item={item}
          />
        ) : (
          <CartProductInputs
            cartItem={item}
            topInputValue={topInputValue}
            bottomInputValue={bottomInputValue}
            setTopInputValue={(setTopInputValue)}
            setBottomInputValue={setBottomInputValue}
            isSecondStep={isSecondStep}
            popperPlacement="right"
            onHasErrors={errorHandler}
          />
        )}
      </div>
    )
  }

  /**
   * Render cost layout
   */
  const renderCostLayout = () => {
    return (
      <CostLayout className="cost-layout">
        {!isHidePrice ? (
          <div>
            <Cost isDelete={isShowPromotionPrice}>${numberFormat(oldPrice || item.total)}</Cost>
            {isShowPromotionPrice ? (
              <Cost isDelete={false}>${numberFormat(item.total)}</Cost>
            ) : null}
            <div>
              ${numberFormat(unitPrice)}
              {item.variant.units?.perName
                ? `/${item.variant.units?.perName}`
                : t('checkout.each_with_slash').toString()}
            </div>
          </div>
        ) : null}
        <IconWrapper>
          <Remove onClick={() => onItemDelete(item)}>
            <RemoveIcon />
            <span>{t('checkout.remove').toString()}</span>
          </Remove>
          {isSecondStep ? (
            <EditQuantityWrapper
              onClick={() => dispatch(changeField('step', CheckoutStep.FIRST))}
            >
              <EditQuantityIcon />
              <EditQuantityText>{t('checkout.edit_quantity').toString()}</EditQuantityText>
            </EditQuantityWrapper>
          ) : null}
        </IconWrapper>
      </CostLayout>
    )
  }

  return (
    <Layout>
      <InnerLayout className="inner-layout-1">
        <BoxThumbs>
          <Image
            onClick={handleClick}
            src={variant.images[0]?.url || midDefaultProduct}
            alt="product image"
            style={{ cursor: 'pointer' }}
          />
        </BoxThumbs>
        <InfoWrapper className="info-wrapper">
          <InfoContent>
            <div>
              <Title onClick={handleClick}>{item.variant.name}</Title>
              <Subcode>
                {t('checkout.sku')} {variant.erpSku}
              </Subcode>
              <div className="more-info-pc">
                {renderMoreInfo()}
              </div>
            </div>
            {renderCostLayout()}
          </InfoContent>
        </InfoWrapper>
      </InnerLayout>

      <InnerLayout className="inner-layout-2">
        <BoxThumbs />
        <InfoWrapper className="info-wrapper">
          <InfoContent>
            <div>
              {renderMoreInfo()}
            </div>
            {renderCostLayout()}
          </InfoContent>
        </InfoWrapper>
      </InnerLayout>
    </Layout>
  );
};

export default memo(ProductItem);
