import React from 'react';

export const ShowTable = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2V7H7V2H2ZM1 0C0.447715 0 0 0.447715 0 1V8C0 8.55229 0.447715 9 1 9H8C8.55229 9 9 8.55229 9 8V1C9 0.447715 8.55229 0 8 0H1ZM13 7V2H18V7H13ZM11 1C11 0.447715 11.4477 0 12 0H19C19.5523 0 20 0.447715 20 1V8C20 8.55229 19.5523 9 19 9H12C11.4477 9 11 8.55229 11 8V1ZM13 18V13H18V18H13ZM11 12C11 11.4477 11.4477 11 12 11H19C19.5523 11 20 11.4477 20 12V19C20 19.5523 19.5523 20 19 20H12C11.4477 20 11 19.5523 11 19V12ZM2 18V13H7V18H2ZM0 12C0 11.4477 0.447715 11 1 11H8C8.55229 11 9 11.4477 9 12V19C9 19.5523 8.55229 20 8 20H1C0.447715 20 0 19.5523 0 19V12Z"
        fill="#939599"
      />
    </svg>
  );
};

export const ShowTableActive = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 1C0 0.447715 0.447715 0 1 0H8C8.55229 0 9 0.447715 9 1V8C9 8.55229 8.55229 9 8 9H1C0.447715 9 0 8.55229 0 8V1ZM12 0C11.4477 0 11 0.447715 11 1V8C11 8.55229 11.4477 9 12 9H19C19.5523 9 20 8.55229 20 8V1C20 0.447715 19.5523 0 19 0H12ZM12 11C11.4477 11 11 11.4477 11 12V19C11 19.5523 11.4477 20 12 20H19C19.5523 20 20 19.5523 20 19V12C20 11.4477 19.5523 11 19 11H12ZM1 11C0.447715 11 0 11.4477 0 12V19C0 19.5523 0.447715 20 1 20H8C8.55229 20 9 19.5523 9 19V12C9 11.4477 8.55229 11 8 11H1Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 0.447715 0.447715 0 1 0H8C8.55229 0 9 0.447715 9 1V8C9 8.55229 8.55229 9 8 9H1C0.447715 9 0 8.55229 0 8V1ZM12 0C11.4477 0 11 0.447715 11 1V8C11 8.55229 11.4477 9 12 9H19C19.5523 9 20 8.55229 20 8V1C20 0.447715 19.5523 0 19 0H12ZM12 11C11.4477 11 11 11.4477 11 12V19C11 19.5523 11.4477 20 12 20H19C19.5523 20 20 19.5523 20 19V12C20 11.4477 19.5523 11 19 11H12ZM1 11C0.447715 11 0 11.4477 0 12V19C0 19.5523 0.447715 20 1 20H8C8.55229 20 9 19.5523 9 19V12C9 11.4477 8.55229 11 8 11H1Z"
        fill="#00853e"
      />
      <path
        d="M1 -2C-0.656854 -2 -2 -0.656855 -2 1H2C2 1.55228 1.55228 2 1 2V-2ZM8 -2H1V2H8V-2ZM11 1C11 -0.656854 9.65686 -2 8 -2V2C7.44772 2 7 1.55228 7 1H11ZM11 8V1H7V8H11ZM8 11C9.65685 11 11 9.65685 11 8H7C7 7.44772 7.44772 7 8 7V11ZM1 11H8V7H1V11ZM-2 8C-2 9.65686 -0.656854 11 1 11V7C1.55228 7 2 7.44772 2 8H-2ZM-2 1V8H2V1H-2ZM13 1C13 1.55229 12.5523 2 12 2V-2C10.3431 -2 9 -0.656855 9 1H13ZM13 8V1H9V8H13ZM12 7C12.5523 7 13 7.44772 13 8H9C9 9.65685 10.3431 11 12 11V7ZM19 7H12V11H19V7ZM18 8C18 7.44771 18.4477 7 19 7V11C20.6569 11 22 9.65686 22 8H18ZM18 1V8H22V1H18ZM19 2C18.4477 2 18 1.55229 18 1H22C22 -0.656855 20.6569 -2 19 -2V2ZM12 2H19V-2H12V2ZM13 12C13 12.5523 12.5523 13 12 13V9C10.3431 9 9 10.3431 9 12H13ZM13 19V12H9V19H13ZM12 18C12.5523 18 13 18.4477 13 19H9C9 20.6569 10.3431 22 12 22V18ZM19 18H12V22H19V18ZM18 19C18 18.4477 18.4477 18 19 18V22C20.6569 22 22 20.6569 22 19H18ZM18 12V19H22V12H18ZM19 13C18.4477 13 18 12.5523 18 12H22C22 10.3431 20.6569 9 19 9V13ZM12 13H19V9H12V13ZM2 12C2 12.5523 1.55228 13 1 13V9C-0.656854 9 -2 10.3431 -2 12H2ZM2 19V12H-2V19H2ZM1 18C1.55229 18 2 18.4477 2 19H-2C-2 20.6569 -0.656855 22 1 22V18ZM8 18H1V22H8V18ZM7 19C7 18.4477 7.44771 18 8 18V22C9.65686 22 11 20.6569 11 19H7ZM7 12V19H11V12H7ZM8 13C7.44771 13 7 12.5523 7 12H11C11 10.3431 9.65686 9 8 9V13ZM1 13H8V9H1V13Z"
        fill="#00853e"
        mask="url(#path-1-inside-1)"
      />
    </svg>
  );
};

export const ShowList = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7V2H18V7H2ZM0 1C0 0.447715 0.447715 0 1 0H19C19.5523 0 20 0.447715 20 1V8C20 8.55229 19.5523 9 19 9H1C0.447715 9 0 8.55229 0 8V1ZM2 18V13H18V18H2ZM0 12C0 11.4477 0.447715 11 1 11H19C19.5523 11 20 11.4477 20 12V19C20 19.5523 19.5523 20 19 20H1C0.447715 20 0 19.5523 0 19V12Z"
        fill="#939599"
      />
    </svg>
  );
};

export const ShowListActive = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0C0.447715 0 0 0.447715 0 1V8C0 8.55229 0.447715 9 1 9H19C19.5523 9 20 8.55229 20 8V1C20 0.447715 19.5523 0 19 0H1ZM1 11C0.447715 11 0 11.4477 0 12V19C0 19.5523 0.447715 20 1 20H19C19.5523 20 20 19.5523 20 19V12C20 11.4477 19.5523 11 19 11H1Z"
        fill="#00853e"
      />
    </svg>
  );
};
