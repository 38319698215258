import React, { useEffect, useRef } from 'react';
import { PopperPlacementType } from '@material-ui/core';
import TagManager from 'react-gtm-module';

import { ListViewMinus, ListViewPlus } from 'assets/svg/Product/Products';
import { ProductVariantClass } from 'models/classes/productVariantClass';
import { IPrice } from 'models/interfaces/productVariant';
import ProductInputPopper from 'components/Popper/ProductInputPopper';
import { AssociationVariantClass } from 'models/classes/associationClass';
import {
  IncrementButton,
  StyledTextField,
  StyledInputWrapper,
  InputWithLabelWrapper,
  InputButtonsWrapper,
  IncrementButtonLabel,
} from 'components/Product/Inputs/styles';
import { PRODUCT_PRICING_METHOD_STANDARD } from 'ducks/productVariant/types';
import { useSelector } from 'react-redux';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectAddressId, selectErpCustomerAddressCode } from 'ducks/application/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';

interface IProductInputs {
  product: ProductVariantClass | AssociationVariantClass;
  productPrices: IPrice[];
  topInputValue: number;
  bottomInputValue?: number;
  setTopInputValue: React.Dispatch<React.SetStateAction<number>>;
  setBottomInputValue: React.Dispatch<React.SetStateAction<number>>;
  setCurrentPrice?: React.Dispatch<React.SetStateAction<IPrice[]>>;
  messageTop?: string;
  messageBottom?: string;
  popperPlacement: PopperPlacementType;
}

export const ProductMultiInput = React.memo(
  ({
    product,
    productPrices,
    topInputValue: defaultTopInputValue,
    bottomInputValue,
    setTopInputValue,
    setBottomInputValue,
    setCurrentPrice,
    messageTop,
    messageBottom,
    popperPlacement,
  }: IProductInputs) => {
    const user = useSelector(selectUserData);
    const branch = useSelector(selectBranch);
    const selectedAddressId = useSelector(selectAddressId);
    const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
    const timeClickGtm = useRef(0);
    const topRef = useRef();
    const bottomRef = useRef();

    const buttonTopRef = useRef();
    const buttonBottomRef = useRef();
    const defaultPrice = productPrices[0];
    const minQuantity = defaultPrice?.qty;
    const isMultiInput = product.units.uom2Conversion && product.units.uom2Name;
    const topInputValue = defaultTopInputValue || 0;

    const productCoeff = parseFloat((minQuantity / product.units.uom2Conversion).toFixed(2));

    const handleGtmEventChangeUOM = () => {
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'change_UOM',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
      timeClickGtm.current = Date.now() / 1000;
    };

    useEffect(() => {
      if (product.pricingMethod === PRODUCT_PRICING_METHOD_STANDARD) {
        let currentPrices: IPrice[] = [defaultPrice];

        if (topInputValue >= minQuantity) {
          currentPrices = [...productPrices].filter(price => price.qty <= topInputValue).slice(-1);
        }
        setCurrentPrice(currentPrices);
      }
    }, [product, setCurrentPrice, topInputValue, defaultPrice, minQuantity]);

    const handleIncrement = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      const newTopValue = topInputValue - (topInputValue % minQuantity) + minQuantity;

      setTopInputValue(newTopValue);
      if (timeClickGtm.current === 0) {
        handleGtmEventChangeUOM();
      } else if (timeClickGtm.current > 0 && Date.now() / 1000 - timeClickGtm.current > 10) {
        handleGtmEventChangeUOM();
      }

      if (isMultiInput) {
        const newBottomValue = parseFloat(((newTopValue * productCoeff) / minQuantity).toFixed(2));

        setBottomInputValue(newBottomValue);
      }
    };

    const handleDecrement = () => {
      let newTopValue = topInputValue - (topInputValue % minQuantity) - minQuantity;
      newTopValue = newTopValue <= 0 ? minQuantity : newTopValue;
      setTopInputValue(newTopValue);

      if (isMultiInput) {
        const newBottomValue = parseFloat(((newTopValue * productCoeff) / minQuantity).toFixed(2));
        setBottomInputValue(newBottomValue);
      }
      if (timeClickGtm.current === 0) {
        handleGtmEventChangeUOM();
      } else if (timeClickGtm.current > 0 && Date.now() / 1000 - timeClickGtm.current > 10) {
        handleGtmEventChangeUOM();
      }
    };

    const handleTopFieldChange = (value: number) => {
      setTopInputValue(value);

      if (isMultiInput) {
        const newBottomValue = parseFloat(((value * productCoeff) / minQuantity).toFixed(2));
        setBottomInputValue(newBottomValue);
      }

      if (timeClickGtm.current === 0) {
        handleGtmEventChangeUOM();
      } else if (timeClickGtm.current > 0 && Date.now() / 1000 - timeClickGtm.current > 10) {
        handleGtmEventChangeUOM();
      }
    };

    const handleBottomFieldChange = e => {
      const newBottomValue: number = parseFloat(e.target.value);
      setBottomInputValue(newBottomValue);

      const newTopValue = parseFloat(((newBottomValue / productCoeff) * minQuantity).toFixed(2));
      setTopInputValue(newTopValue);
    };

    return (
      <InputButtonsWrapper>
        <InputWithLabelWrapper>
          <StyledInputWrapper>
            {messageTop && popperPlacement === 'left' ? (
              <ProductInputPopper
                element={buttonTopRef.current}
                message={messageTop}
                placement={popperPlacement}
              />
            ) : null}
            <IncrementButton
              aria-label="minus"
              ref={buttonTopRef}
              disabled={topInputValue === 0}
              onClick={handleDecrement}
            >
              <ListViewMinus />
            </IncrementButton>
            <StyledTextField
              type="number"
              InputProps={{ disableUnderline: true }}
              onChange={e => handleTopFieldChange(parseInt(e.target.value, 10))}
              value={topInputValue || ''}
            />
            <IncrementButton aria-label="plus" onClick={event => handleIncrement(event)}>
              <ListViewPlus />
            </IncrementButton>
          </StyledInputWrapper>
          <IncrementButtonLabel ref={topRef}>{product.units.perName}</IncrementButtonLabel>
          {messageTop && popperPlacement === 'right' ? (
            <ProductInputPopper
              element={topRef.current}
              message={messageTop}
              placement={messageTop && messageBottom ? 'right-end' : popperPlacement}
            />
          ) : null}
        </InputWithLabelWrapper>

        {isMultiInput ? (
          <InputWithLabelWrapper>
            <StyledInputWrapper>
              {messageBottom && popperPlacement === 'left' ? (
                <ProductInputPopper
                  element={buttonBottomRef.current}
                  message={messageBottom}
                  placement={popperPlacement}
                />
              ) : null}
              <IncrementButton aria-label="minus" ref={buttonBottomRef} onClick={handleDecrement}>
                <ListViewMinus />
              </IncrementButton>
              <StyledTextField
                type="number"
                InputProps={{ disableUnderline: true }}
                onChange={handleBottomFieldChange}
                value={parseFloat(bottomInputValue.toFixed(2)) || ''}
              />
              <IncrementButton aria-label="plus" onClick={event => handleIncrement(event)}>
                <ListViewPlus />
              </IncrementButton>
            </StyledInputWrapper>
            <IncrementButtonLabel ref={bottomRef}>{product.units.uom2Name}</IncrementButtonLabel>
            {messageBottom && popperPlacement === 'right' ? (
              <ProductInputPopper
                element={bottomRef.current}
                message={messageBottom}
                placement={popperPlacement}
              />
            ) : null}
          </InputWithLabelWrapper>
        ) : null}
      </InputButtonsWrapper>
    );
  }
);
