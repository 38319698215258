import React from 'react';

import { SalesMan } from 'components/common/SalesMan/SalesMan';
import { ContactPhones } from 'components/common/ContactLinks/ContactPhones';
import { ContactEmails } from 'components/common/ContactLinks/ContactEmails';

import { useTranslation } from 'react-i18next';

import {
  MainContainer,
  TextSubheaderTypography,
  StyledContactsLinkContainer,
  StyledContactsBlock,
} from './styles';

interface IRepresentativeContactComponentProps {
  email?: string;
  id?: number;
  mobilePhone?: string;
  name?: string;
  surname?: string;
  workPhone?: string;
  image?: {
    url?: string;
  };
}

interface IRepresentativeContactComponent {
  salesRepresentative: IRepresentativeContactComponentProps;
  display?: string;
}

const RepresentativeContactComponent: React.FC<IRepresentativeContactComponent> = ({
  salesRepresentative,
  display = 'block',
}: IRepresentativeContactComponent) => {
  const { email, name, surname, mobilePhone, workPhone, image } = salesRepresentative;

  const { t } = useTranslation();
  const salesName = [name, surname].filter(Boolean).join(' ');
  return (
    <MainContainer display={display}>
      <StyledContactsBlock>
        <TextSubheaderTypography>{t('salesman.title')}</TextSubheaderTypography>
        <SalesMan salesName={salesName} imgUrl={image?.url} />
      </StyledContactsBlock>
      <StyledContactsLinkContainer>
        {workPhone && (
          <ContactPhones
            phones={[{ text: `${workPhone}`, phoneTo: `${workPhone}`, type: 'Tel.' }]}
          />
        )}

        {mobilePhone && (
          <ContactPhones
            mobile
            phones={[{ text: `${mobilePhone}`, phoneTo: `${mobilePhone}`, type: 'Tel.' }]}
          />
        )}

        <ContactEmails emails={[email]} />
      </StyledContactsLinkContainer>
    </MainContainer>
  );
};

export default RepresentativeContactComponent;
