import * as React from 'react';
import addMonths from 'date-fns/addMonths';
import isSameDay from 'date-fns/isSameDay';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameMonth from 'date-fns/isSameMonth';
import addYears from 'date-fns/addYears';
import max from 'date-fns/max';
import min from 'date-fns/min';
import {
  DateRange,
  NavigationAction,
} from 'pages/General/Profile/components/OrdersAndQuotes/DateRangePicker/types';
import Menu from 'pages/General/Profile/components/OrdersAndQuotes/DateRangePicker/components/Menu';
import { defaultRanges } from 'pages/General/Profile/components/OrdersAndQuotes/DateRangePicker/defaults';
import {
  isWithinRange,
  parseOptionalDate,
} from 'pages/General/Profile/components/OrdersAndQuotes/DateRangePicker/utils';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import MobileMenu from 'pages/General/Profile/components/OrdersAndQuotes/DateRangePicker/components/MobileMenu';

type Marker = symbol;

export const MARKERS: { [key: string]: Marker } = {
  FIRST_MONTH: Symbol('firstMonth'),
  SECOND_MONTH: Symbol('secondMonth'),
};

const getValidatedMonths = (range: DateRange, minDate: Date, maxDate: Date) => {
  const { startDate, endDate } = range;
  if (startDate && endDate) {
    const newStart = max([startDate, minDate]);
    const newEnd = min([endDate, maxDate]);

    return [newStart, isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd];
  }
  return [startDate, endDate];
};

interface DateRangePickerProps {
  open: boolean;
  initialDateRange?: DateRange;
  minDate?: Date | string;
  maxDate?: Date | string;
  onChange: (dateRange: DateRange) => void;
  clearDataRangeFilter: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const DateRangePickerImpl: React.FC<DateRangePickerProps> = ({
  open,
  initialDateRange,
  minDate,
  maxDate,
  onChange,
  clearDataRangeFilter,
}: DateRangePickerProps) => {
  const today = new Date();
  const { t } = useTranslation();
  const definedRanges = defaultRanges(t);
  const minDateValid = parseOptionalDate(minDate, addYears(today, -10));
  const maxDateValid = parseOptionalDate(maxDate, addYears(today, 10));
  const [intialFirstMonth, initialSecondMonth] = getValidatedMonths(
    initialDateRange || {},
    minDateValid,
    maxDateValid
  );

  const isMobile = useMediaQuery('(max-width: 600px)');

  const [dateRange, setDateRange] = React.useState<DateRange>({ ...initialDateRange });
  const [hoverDay, setHoverDay] = React.useState<Date>();
  const [firstMonth, setFirstMonth] = React.useState<Date>(intialFirstMonth || today);
  const [secondMonth, setSecondMonth] = React.useState<Date>(
    initialSecondMonth || addMonths(firstMonth, 1)
  );

  const { startDate, endDate } = dateRange;

  const setFirstMonthValidated = (date: Date) => {
    if (isBefore(date, secondMonth)) {
      setFirstMonth(date);
    }
  };

  const setSecondMonthValidated = (date: Date) => {
    if (isAfter(date, firstMonth)) {
      setSecondMonth(date);
    }
  };

  const setDateRangeValidated = (range: DateRange) => {
    let { startDate: newStart, endDate: newEnd } = range;
    if (newStart && newEnd) {
      newStart = max([newStart, minDateValid]);
      newEnd = min([newEnd, maxDateValid]);
      setDateRange(range);
      onChange(range);
      setFirstMonth(newStart);
      setSecondMonth(isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd);
    }
  };

  const onDayClick = (e, day: Date) => {
    if (startDate && !endDate && !isBefore(day, startDate)) {
      const endDay = new Date(day.setHours(23, 59, 0, 0));
      const newRange = { startDate, endDate: endDay };
      onChange(newRange);
      setDateRange(newRange);
    } else {
      setDateRange({ startDate: day, endDate: undefined });
    }
    setHoverDay(day);
  };

  const onMonthNavigate = (e, marker: Marker, action: NavigationAction) => {
    if (marker === MARKERS.FIRST_MONTH) {
      const firstNew = addMonths(firstMonth, action);
      if (isBefore(firstNew, secondMonth)) setFirstMonth(firstNew);
    } else {
      const secondNew = addMonths(secondMonth, action);
      if (isBefore(firstMonth, secondNew)) setSecondMonth(secondNew);
    }
  };

  const onDayHover = (date: Date) => {
    if (startDate && !endDate) {
      if (!hoverDay || !isSameDay(date, hoverDay)) {
        setHoverDay(date);
      }
    }
  };

  const inHoverRange = (day: Date) => {
    return (startDate &&
      !endDate &&
      hoverDay &&
      isAfter(hoverDay, startDate) &&
      isWithinRange(day, startDate, hoverDay)) as boolean;
  };

  const helpers = {
    inHoverRange,
  };

  const handlers = {
    onDayClick,
    onDayHover,
    onMonthNavigate,
  };

  const MenuComponent = () =>
    isMobile ? (
      <MobileMenu
        initDateRange={initialDateRange}
        clearDateRange={setDateRange}
        clearDataRangeFilter={clearDataRangeFilter}
        dateRange={dateRange}
        minDate={minDateValid}
        maxDate={maxDateValid}
        ranges={definedRanges}
        firstMonth={firstMonth}
        secondMonth={secondMonth}
        setFirstMonth={setFirstMonthValidated}
        setSecondMonth={setSecondMonthValidated}
        setDateRange={setDateRangeValidated}
        helpers={helpers}
        handlers={handlers}
      />
    ) : (
      <Menu
        initDateRange={initialDateRange}
        clearDateRange={setDateRange}
        clearDataRangeFilter={clearDataRangeFilter}
        dateRange={dateRange}
        minDate={minDateValid}
        maxDate={maxDateValid}
        ranges={definedRanges}
        firstMonth={firstMonth}
        secondMonth={secondMonth}
        setFirstMonth={setFirstMonthValidated}
        setSecondMonth={setSecondMonthValidated}
        setDateRange={setDateRangeValidated}
        helpers={helpers}
        handlers={handlers}
      />
    );

  return open ? <MenuComponent /> : null;
};

export const DateRangePicker = DateRangePickerImpl;
