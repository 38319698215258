import get from 'lodash/get';

export const pushKlaviyoEvent = (eventName, eventProperties) => {
  if (get(window, 'klaviyo')) {
    const klaviyo = get(window, 'klaviyo');
    klaviyo.push(['track', eventName, eventProperties]);
  }
}

export const pushKlaviyoIdentity = (email: string) => {
  if (get(window, 'klaviyo')) {
    const klaviyo = get(window, 'klaviyo');
    klaviyo.push(['identify', {
      '$email': email,
    }]);
  }
}