import React from 'react';

export const HeaderLogoMobile = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="88px"
      height="50px"
      viewBox="0 0 88 50"
      enableBackground="new 0 0 88 50"
      xmlSpace="preserve"
    >
      {' '}
      <image
        id="image0"
        width="88"
        height="50"
        x="0"
        y="0"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAAAyCAQAAABy89SDAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
cwAACxMAAAsTAQCanBgAAAAHdElNRQflBBcTJTEVXiSDAAADAElEQVRo3u2a7XXiRhSGn9mz/z1b
gccVRKkAuQKUCsAVYFdgqMByBdJWIFGB5AogFSAqQK7gzQ8NMgomnBBnvTmZVz90587XM1czV5wj
jMSPUWNuPmKYLz8I98MUgANwAA7AATgAB+CfSgE4AAfgAByAA3AA/qkUgANwAA7A/3vgB2644fmz
gL/2Vk0DRERnerSmAbWfD/zd5KD5WeCxHDD6XOCWkgaANbmviXHU/E7bt7WMcViuAQuD2glrWiAB
SrbseMUB4Bh5CwDNaHDc42jIzcJ7E2bEtNQ8mAZU8GxqADkyFt6OmCFJ2hyvRLmejnxWlaYAmms+
qHGKAFQpOerltHubQ7kqpbKgSBtNAPSojWI/7k4RKFfq299L+7mUKvPAimU9kvNX4YeYKVOm1JeS
PXC/hERTJfIxVPE2tDKlnV/zAXAfHs1V+gUlB74KFPftq84DoI3GX/xjq+i63LPxV0KlShnf2PLK
NRO/eTo1/r6iIKOg6haM1U65Mq4A+IXpoHWn773VcAXENKbsfSmxLGusHMgSkRLJghzWLL9yWg8m
5Zxcf4+ogS13pjnb689jbN8KplVDZGqtSUiJeTGt1iTkxLycysO33O5x9ahKlYrjvTmI+N5amQbk
lKlSpazb2We1G5Ssfw5jIKEAloyACcVhWutUMmJpaug/3yVEQMuW8jAqsqYFfiUGoDFrAF59zKZ+
ETXrs7gNM+72BTksa6DkSZYRcyBnowdifoPu0EnaH5e+Y+535dAb91ni6b259fiudzo4dPMDfwWy
2un+YN7MW5XS/ritlKqEwwgnyng2a7/Rx0y40sLHDTkeWQC2OwxYZop46Y/T2OfNWLCkMW0/zjP4
I3hCptUdha5YYpky4tZXLHli0dszukXpUm0GpZ1PYKvT7f3Sky5BAijq8jDIKVelSvO35yqreZ8u
o71tLv7W/M20oIQx8EppapBjc7L9B31rvjzC770HV3/RfnMJ3bEuj/Dwt4TlmtgnpH81wv8E+O8p
/P3gP6I/AM+JVbzllh3zAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTA0LTIzVDE5OjM3OjQ5KzAz
OjAwbetZxAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wNC0yM1QxOTozNzo0OSswMzowMBy24XgA
AAAASUVORK5CYII="
      />
    </svg>
  );
};
