// Types
import { FAQActionTypes } from 'ducks/FAQ/types';

// Classes
import { FAQClass } from 'models/classes/faqClass';

export const fetchFAQ = () => ({
  type: FAQActionTypes.REQUEST_FAQ,
});

export const storeFAQ = (FAQ: FAQClass[]) => ({
  type: FAQActionTypes.REQUEST_FAQ_SUCCESS,
  payload: {
    FAQ,
  },
});
