import styled, { ThemedStyledProps } from 'styled-components/macro';
import { Slider, TextField, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

interface IFilterWrapper extends ThemedStyledProps<object, HTMLDivElement> {
  isShow: boolean;
}

const FilterWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.lightGrey};
  width: 284px;
  min-height: 1048px;
  margin-top: 8px;
`;

const FilterInner = styled.div`
  position: sticky;
  top: 0;
  overflow-y: auto;
  max-height: 100vh;
  background-color: inherit;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px;
  &::-webkit-scrollbar {
    display: none;
  }
  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const MobileFilterWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 100%;
  height: ${(props: IFilterWrapper) => (props.isShow ? '100%' : '0')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;
  visibility: ${(props: IFilterWrapper) => (props.isShow ? 'visible' : 'hidden')};
  z-index: 9998;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  margin-top: 0;
  @media (max-width: 1023px) {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: hidden;
  }
`;

const SingleFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
`;

interface ICheckboxWrapper extends ThemedStyledProps<object, HTMLDivElement> {
  isOpen: boolean;
}

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  text-decoration: ${(props: IFilterWrapper) => (props.isShow ? '': 'line-through')};
  color: ${(props: IFilterWrapper) => (props.isShow ? '#00133C': '#99A8BB')};

  &:last-child {
    padding-bottom: 25px;
  }

  @media (max-width: 1023px) {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.borderGrey};

    &:last-child {
      padding-bottom: 0;
    }
  }

  .MuiCheckbox-root {
    padding: 5px 9px;
  }
`;

const BlackWrapper = styled.div`
  width: 100vw;
  height: ${(props: IFilterWrapper) => (props.isShow ? '100vh' : '0')};
  visibility: ${(props: IFilterWrapper) => (props.isShow ? 'visible' : 'hidden')};
  background: ${({ theme: { colors } }) => colors.black};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  opacity: 0.25;
  z-index: 9;
`;

const MultipleSelectionWrapper = styled.div`
  flex: 1 1 auto;
  padding-bottom: 90px;
  @media (max-width: 1023px) {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0 20px;
  }
`;

const ResponsiveFilterWrapper = styled.div`
  height: ${(props: ICheckboxWrapper) => (props.isOpen ? '100%' : '0')};
  visibility: ${(props: ICheckboxWrapper) => (props.isOpen ? 'visible' : 'hidden')};

  @media (max-width: 950px) {
    z-index: 20;
    margin-top: 11px;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    height: ${(props: ICheckboxWrapper) => (props.isOpen ? '100vh' : '0')};
    background: ${({ theme: { colors } }) => colors.lightGrey};
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const CheckboxContentWrapper = styled.div`
  display: ${(props: ICheckboxWrapper) => (props.isOpen ? 'block' : 'none')};
`;

const RangeFilterContentWrapper = styled.div`
  display: ${(props: ICheckboxWrapper) => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
`;

const CheckboxTitle = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  margin: 7px 0;

  &:hover {
    cursor: pointer;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  height: 56px;
  border: none;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGrey};
  background: ${({ theme: { colors } }) => colors.lightGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  outline: none;

  &:hover {
    cursor: pointer;
  }
`;

const StyledClearButton = styled.button`
  width: 100%;
  height: 48px;
  border: 1px solid ${({ theme: { colors } }) => colors.buttonGrey};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { colors } }) => colors.lightGrey};
  margin-top: 8px;
  outline: none;
  @media (max-width: 1023px) {
    height: 45px;
    background: #fff;
    margin: 0;
  }
  &:hover {
    cursor: pointer;
  }
  &:active {
    border: 1px solid ${({ theme: { colors } }) => colors.mainGreen};
  }
  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20.8px;
    text-align: center;
    letter-spacing: 0.01em;
    color: ${({ theme: { colors } }) => colors.mainGreen};
    margin: 0;
    @media (max-width: 1023px) {
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0.1px;
      color: #001A3B;
    }
  }
`;

const StyledSingleClearButton = styled(StyledClearButton)`
  @media (max-width: 1023px) {
    height: 40px;
    margin: 0 5px;

    p {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const StyledShowButton = styled.button`
  width: 100%;
  height: 48px;
  border: 1px solid ${({ theme: { colors } }) => colors.mainGreen};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { colors } }) => colors.mainGreen};
  outline: none;

  &[disabled] {
    background: #b2becc;
    border-color: #b2becc;
  }

  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.01em;
    color: ${({ theme: { colors } }) => colors.white};
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1023px) {
    height: 40px;
    margin: 0 5px;

    p {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const StyledApplyButton = styled(StyledShowButton)`
  @media (max-width: 1023px) {
    height: 45px;
    margin: 0 0 8px;
  }
  p {
    @media (max-width: 1023px) {
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0.1px;
      margin: 0;
    }
  }
`;

const ClearButtonTitle = styled.p`
  color: ${({ theme: { colors } }) => colors.mainGreen};
  font-size: 16px;
  font-family: Open Sans;
  line-height: 100%;
  letter-spacing: 0.01em;
  font-weight: 600;
`;

const ButtonNameWrapper = styled.div`
  text-align: left;
  width: fit-content;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;

  @media (max-width: 1023px) {
    font-family: Open Sans;
    font-weight: normal;
    margin-bottom: 3px;
    margin-right: 5px;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1 1 100%;

  @media (max-width: 1023px) {
    padding-right: 8px;
  }
`;

const OpenFilterButton = styled.button`
  width: 100%;
  height: 40px;
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  background: ${({ theme: { colors } }) => colors.lightGrey};
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  vertical-align: center;
  outline: none;
  display: flex;
  justify-content: space-between;
`;

const OpenFilterButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
`;

const ButtonTitle = styled.p`
  margin: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  padding-left: 6px;

  &:hover {
    cursor: pointer;
  }
  color: ${({ theme: { colors } }) => colors.black};
`;

const MobileSingleFilter = styled.div`
  width: 100%;
  height: 48px;
  background: ${({ theme: { colors } }) => colors.lightGrey};
  border: none;
`;

const MobileSingleFilterButton = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme: { colors } }) => colors.lightGrey};
  border: none;
  display: flex;
  flex-direction: row;
  outline: none;
  align-items: center;
  justify-content: space-between;
`;

const AllFiltersWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1023px) {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding: 0;
  }
`;

const GoBackButtonWrapper = styled.div`
  width: 100%;
  height: ${(props: ICheckboxWrapper) => (props.isOpen ? '50px' : '0')};
`;

const GoBackButton = styled.button`
  border: none;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.filterBorderGrey};
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  background: ${({ theme: { colors } }) => colors.lightGrey};
  outline: none;
`;

const GoBackTitle = styled.p`
  margin: 0 0 0 10px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: ${({ theme: { colors } }) => colors.black};
`;

const MobilePapersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 8px;
`;

const MobilePaper = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FilterHeaderWrapper = styled.div`
  width: 100%;
  padding: 20px 16px 5px;
`;

const FilterHeader = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #CCD3DD;
  padding-bottom: 8px;
  margin-bottom: 8px;
`;

const FilterWindowHeader = styled.p`
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.1px;
  color: #00133C;
`;

const CloseFilterPageButton = styled.button`
  cursor: pointer;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.2px;
  background: none;
  border: 0;
  outline: 0;
  padding: 0;
`;

const FilterLabel = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px;
    background: #F2F4F6;
    padding: 4px 2px 4px 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    .text {
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }
`;

const FilterLabelClearButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;
  padding: 0;
  margin-left: 3px;
  svg {
    width: 8px;
    height: 8px;
  }
`;

const ActionButtonsWrapper = styled.div`
  position: sticky;
  bottom: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1023px) {
    position: relative;
    bottom: auto;
    display: block;
    padding: 20px;
    margin: 0;
  }
`;

const ClearMobilePaperButton = styled.button`
  border: none;
  width: fit-content;
  background: ${({ theme: { colors } }) => colors.lightGrey};
  display: flex;
  align-items: center;
  outline: none;
  &:hover {
    cursor: pointer;
  }
  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: ${({ theme: { colors } }) => colors.mainGreen};
  }
`;

const SingleClearButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 16px;
`;

const StyledSlider = styled(Slider)`
  color: ${({ theme: { colors } }) => colors.mainGreen};
  margin-top: 35px;

  .MuiSlider-thumb {
    width: 16px;
    height: 16px;
    background: ${({ theme: { colors } }) => colors.mainGreen};
    margin-top: -7px;
  }

  @media (max-width: 1023px) {
    width: 95%;
  }
`;

const RangeFilterInputsWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  background: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.productBorderGrey};
  margin-top: 16px;
`;

const RangeFilterInput = styled(TextField)`
  width: 50%;
  padding: 8px;
  border-right: 1px solid ${({ theme: { colors } }) => colors.productBorderGrey};

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:last-child {
    border-right: none;
  }
`;

const MobileSingleFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 0;
`;

const FilterDot = styled.div`
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
  height: 10px;
  padding: 0;
  width: 10px;
  border-radius: 5px;
  margin-top: 7px;
  margin-right: 2px;
  background-color: ${({ theme: { colors } }) => colors.mainGreen};
`;

const EmptySpan = styled.span`
  width: 10px;
`;

const AccordionWrapper = styled(Accordion)`
  box-shadow: none !important;
  border: 0 !important;
  border-radius: 0 !important;
  margin: 0 0 10px !important;
  &:before {
    display: none;
  }
  .MuiAccordionSummary-root {
    min-height: 44px !important;
    border-bottom: 1px solid #CCD3DD;
    padding: 0;
  }
`
const AccordionContent = styled(AccordionSummary)`
  padding-left: 24px !important;
  &.Mui-expanded {
    border-radius: 4px 4px 0 0;
  }
  .MuiAccordionSummary-expandIcon {
    position: absolute;
    left: -4px;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    margin: 0;
    &.Mui-expanded {
      transform: translate(0, -50%) rotate(180deg);
    }
  }
  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 !important;
    .txt-name {
      flex: 1 1 auto;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.2px;
      color: #00133C;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .btn-clear {
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: ${({ theme: { colors } }) => colors.mainGreen};
      border: 0;
      outline: 0;
      background: none;
      cursor: pointer;
    }
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  flex-direction: column;
  padding: 0 !important;
  .checkbox-wrapper {
    /* display: block; */
    border-bottom: 0;
    .MuiCheckbox-root {
      padding: 0;
      margin-right: 4px;
      &.Mui-checked {
        & + * {
          font-weight: 600;
          color: ${({ theme: { colors } }) => colors.mainGreen};
        }
      }
    }
    .MuiIconButton-label {
      margin-left: -2px;
    }
    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }
  }
`
export {
  SingleClearButtonWrapper,
  ClearMobilePaperButton,
  ActionButtonsWrapper,
  FilterLabel,
  FilterLabelClearButton,
  CloseFilterPageButton,
  FilterWindowHeader,
  FilterHeaderWrapper,
  FilterHeader,
  MobilePaper,
  MobilePapersWrapper,
  GoBackTitle,
  GoBackButton,
  GoBackButtonWrapper,
  AllFiltersWrapper,
  MobileSingleFilterButton,
  MobileSingleFilter,
  ButtonTitle,
  OpenFilterButton,
  FilterContainer,
  ButtonNameWrapper,
  ClearButtonTitle,
  StyledApplyButton,
  StyledShowButton,
  StyledSingleClearButton,
  StyledButton,
  CheckboxTitle,
  CheckboxContentWrapper,
  ResponsiveFilterWrapper,
  MultipleSelectionWrapper,
  BlackWrapper,
  CheckboxWrapper,
  SingleFilterWrapper,
  MobileFilterWrapper,
  FilterWrapper,
  FilterInner,
  StyledClearButton,
  StyledSlider,
  RangeFilterInput,
  RangeFilterInputsWrapper,
  RangeFilterContentWrapper,
  MobileSingleFilterWrapper,
  FilterDot,
  OpenFilterButtonContent,
  EmptySpan,
  AccordionWrapper,
  AccordionContent,
  StyledAccordionDetails,
};
