import React from 'react';
import {IOptionValue, IOption, FindMatchProduct} from 'models/interfaces/product';
import {ProductVariantClass} from 'models/classes/productVariantClass';

import {ProductClass} from 'models/classes/productClass';
import size from 'lodash/size';
import {IPrice} from 'models/interfaces/productVariant';
import {AttributesDropdownV2} from './AttributesDropdown/AttributesDropdownV2';
import SpecifyOption from './SpecifyOption/SpecifyOption';

interface IDefaultType {
	option: IOption;
	optionValues: IOptionValue[];
	currentProductVariant: ProductVariantClass;
  findMatchProducts: Array<FindMatchProduct> | [];
  product: ProductClass;
  price: IPrice;

	onAttributeClick(optionCode: string, code: string): void;
}

function DefaultTypeV2({
  option,
  optionValues,
  currentProductVariant,
  onAttributeClick,
  findMatchProducts,
  product,
  price
}: IDefaultType) {
  let currentCode = '';
  if (currentProductVariant.optionValues.length) {
    const optionValue = currentProductVariant.optionValues.find(
      item => item.code === option.code
    );
    if (optionValue) {
      currentCode = optionValue.optionCode;
    }
  }

  if (size(optionValues) === 0) {
    return null;
  }

  const isNumber = (n: any): boolean => !Number.isNaN(parseFloat(n));

  const compareStrings = (a: string, b: string) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  const optionValuesFiltered: IOptionValue[] = optionValues?.sort((a, b) => {
    if (isNumber(a.value)) {
      return Number(a.value) - Number(b.value);
    }

    return compareStrings(a.value as string, b.value as string);
  });

  // eslint-disable-next-line no-nested-ternary
  return option.isSpecify ? (
    <SpecifyOption
      options={optionValuesFiltered}
      option={option}
      handleAttributeClick={onAttributeClick}
      selected={currentCode}
      findMatchProducts={findMatchProducts}
      product={product}
      currentProductVariant={currentProductVariant}
      price={price}
    />
  ) : <AttributesDropdownV2
    options={optionValuesFiltered}
    option={option}
    handleAttributeClick={onAttributeClick}
    selected={currentCode}
    findMatchProducts={findMatchProducts}
  />
}

export {DefaultTypeV2};
