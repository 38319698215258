import React from 'react';

export const GreenCheck = () => {
  return (
    <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 3L3.5 5.5L7.5 1"
        stroke="#4A9463"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
