import React from 'react';
import { DialogContentText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Warning } from 'assets/svg/MiddleMenu/MiddleMenu';
import { StylesDialog, StylesDialogContent, SubmitButton, CloseButton } from './styles';

interface ICheckoutConfirnModal {
	open: boolean;
	callbackOnClose?: Function;
  callbackOnSubmit?: Function;
}

export const CheckoutConfirmModal = ({open, callbackOnClose, callbackOnSubmit}: ICheckoutConfirnModal) => {
  const { t } = useTranslation();

  return (
    <StylesDialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StylesDialogContent>
        <DialogContentText>
          <button type="button" className="btn-close" onClick={() => callbackOnClose()}>
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 1L11 11M1 21L11 11M11 11L1 1M11 11L21 21"
                stroke="currentColor"
                strokeWidth="2"
              />
            </svg>
          </button>

          <div className="ic-big">
            <Warning />
          </div>

          <div className="txt-title">{t('quick_order.warning')}</div>
          <div className="txt-message">
            {t('quick_order.continue_to_checkout_confirm')}
          </div>

          <div className="box-submit">
            <SubmitButton className="btn-submit" onClick={callbackOnSubmit}>
              {t('quick_order.continue_to_checkout')}
            </SubmitButton>
            <CloseButton className="btn-cancel" onClick={callbackOnClose}>
              {t('quick_order.go_back')}
            </CloseButton>
          </div>
        </DialogContentText>
      </StylesDialogContent>
    </StylesDialog>
  );
};
