import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import React from 'react';

const StyledGrid = styled(Grid)`
  box-shadow: 0 0 15px ${({ theme: { colors } }) => colors.productBorderGrey};
  border-radius: 4px;
  background: ${({ theme: { colors } }) => colors.white};
  padding: 40px 40px 48px;
  margin-bottom: 40px;
  
  @media (max-width: 767px) {
    padding: 16px 10px 32px;
    margin-bottom: 16px;
  }
`;

interface IGridProps {
  children: React.ReactNode,
}

const GridComponent: React.FC<IGridProps> = ({ children }: IGridProps ) => {
  return (
    <StyledGrid container direction="column" justify="flex-start" alignItems="stretch">
      {children}
    </StyledGrid>
  );
}

export default GridComponent;
