import React from 'react';
import styled from 'styled-components';
import { GeoIcon } from 'assets/svg/ContactUs/ContactIcons';

const StyledLink = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.small};
  line-height: 100%;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  margin-left: 6px;
  margin-bottom: 8px;
`;

const ContactRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 6px;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

interface GeoProps {
  geoAddress: string;
}

interface GeosProps {
  geos: Array<GeoProps>;
}

export const ContactGeos: React.FC<GeosProps> = ({ geos }: GeosProps) => {
  return (
    <ContactRow>
      <GeoIcon />
      <LinksContainer>
        {geos.map(geo => (
          <StyledLink key={geo.geoAddress}>{geo.geoAddress}</StyledLink>
        ))}
      </LinksContainer>
    </ContactRow>
  );
};
