import React from 'react';
import { Breadcrumbs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Routes from 'routes';
import { Separator } from 'assets/svg/BreadCrumpSeparator';
import { StyledCurrentPageName, StyledLink } from '../BreadCrumbs/styles';

interface ICustomBreadCrumbsProps {
	data: any;
}

function defineSeparator() {
  return <Separator />;
}

export const CustomBreadCrumbs: React.FC<ICustomBreadCrumbsProps> = ({
  data,
}: ICustomBreadCrumbsProps) => {
  const usedSeparator = defineSeparator();
  const { t } = useTranslation();
  if (data.length === 4) {
    data.splice(1, 1);
  }
  return (
    <Breadcrumbs separator={usedSeparator} aria-label="breadcrumb">
      <StyledLink
        to={{
          pathname: Routes.CATEGORIES,
        }}
      >
        {t('breadcrumbs.home')}
      </StyledLink>
      {data.map((pageProps, index) => {
        let path = `${Routes.CATEGORIES}/${pageProps.code}`;
        if (index === 1 && data.length === 3) {
          path =`${Routes.CATEGORIES}/${data[0].code}/${pageProps.code}`
        }
        return index < data.length -1 && <StyledLink
          to={{
            pathname: path,
          }}
          key={pageProps.name}
        >
          {pageProps.name}
        </StyledLink>
      })}
      <StyledCurrentPageName>{data[data.length -1].name}</StyledCurrentPageName>
    </Breadcrumbs>
  );
}
