import { ProductVariantClass } from 'models/classes/productVariantClass';
import { IPrice } from 'models/interfaces/productVariant';

export const productToProductVariantClass = (product: any) => {
  const newProduct = {
    id: product.id,
    name: product.name,
    nameEn: product.nameEn,
    nameFr: product.nameFr,
    productCode: product?.productCode,
    inventory: product.inventory || null,
    image: product?.image,
    images: product?.images,
    prices: product?.prices,
    code: product?.erpProductId,
    units: { ...product?.units, uom2Conversion: Number(product?.units?.uom2Conversion) },
    mainTaxon: product?.mainTaxon || null,
    erpSku: product.erpSku || product.code,
    category: product.category,
    pricingMethod: product.pricingMethod,
    erpProductId: product.erpProductId,
    defaultLanguage: product.defaultLanguage,
    nearbyHubs: product.nearbyHubs,
    nearbyBranches: product.nearbyBranches,
    newInventory: product.newInventory,
  };

  return new ProductVariantClass(newProduct);
};

export const getPriceByDeliveryType = (deliveryType: string, cartItem: any) => {
  switch (deliveryType) {
    case 'pickup':
      return cartItem.productPickupPrice;
    case 'delivery':
      return cartItem.productDeliveryPrice;
    default:
      return 0;
  }
}

export const filterPrices = (prices: IPrice[]) =>
  prices
    .filter(
      (price: IPrice, index: number, self: IPrice[]) =>
        index === self.findIndex((thing: IPrice) => thing.qty === price.qty)
    )
    .sort((a, b) => a.qty - b.qty);
