import styled, { ThemedStyledProps } from 'styled-components/macro';
import { MediumButton } from 'components/common/Buttons/MediumButton';
import { withStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface ISubtotalWrapper extends ThemedStyledProps<object, HTMLDivElement> {
  showPrices: boolean;
  isFirstStep: boolean;
}

const SidebarLayout = styled.div`
  position: sticky;
  top: 15px;
  width: 350px;
  @media (max-width: 824px) {
    width: 100%;
  }
`;

const UserOptInForOrderUpdateEmails = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  background-color: #f0f9f3;
  border: 1px solid #196742;
  border-radius: 16px;
  padding: 8px 16px;
  margin-bottom: 16px;
  .ic {
    flex: 0 0 24px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .txt-message {
    flex: 1 1 auto;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    padding: 0 2px 0 8px;
  }
  .checkbox-wrapper {
    flex: 0 0 16px;
    .MuiCheckbox-root {
      padding: 0;
    }
  }
`;

const SubtotalWrapper = styled.div`
  min-height: 220px;
  background-color: ${({ theme: { colors } }) => colors.lightGrey};
  padding: 24px 30px 30px 30px;
  font-family: Open Sans, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: ${(props: ISubtotalWrapper) => (props.showPrices ? 'space-between' : 'flex-end')};
  @media (max-width: 824px) {
    padding: 24px 10px;
  }
`;

const Key = styled.span`
  font-size: 16px;
  line-height: 22px;
`;

const InvisibleKey = styled.span`
  font-size: 16px;
  line-height: 22px;
  visibility: hidden;
`;

const Price = styled.span`
  font-weight: bold;
  font-size: 20px;
  text-align: right;
`;

const Row = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 12px;
  min-height: 72px;
`;

const PriceItem = styled.span`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TotalRow = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px;
  min-height: 72px;

  @media (max-width: 1023px) {
    margin-bottom: 20px;
  }
`;

const LineBottom = styled.div`
  margin-bottom: 10px;
  height: 1px;
  background-color: ${({ theme: { colors } }) => colors.filterBorderGrey};

  @media (max-width: 1023px) {
    margin-bottom: 20px;
  }
`;

const ApplyRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
`;

const TextInput = styled.input`
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  outline: 0;
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  font-size: 14px;
  margin-right: 10px;
  font-family: Open Sans, sans-serif;

  &::placeholder {
    color: ${({ theme: { colors } }) => colors.grey};
  }
`;

const PromoCodeTitle = styled.span`
  display: block;
  font-size: 16px;
  line-height: 22px;

  @media (max-width: 1023px) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const ApplyButton = withStyles({
  root: {
    width: 124,
    height: 38,
    fontWeight: 600,
    fontSize: 16,
  },
})(MediumButton);

const Total = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;

  @media (max-width: 1023px) {
    font-size: 22px;
    line-height: 22px;
  }
`;

const TotalPrice = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  text-align: right;

  @media (max-width: 1023px) {
    font-size: 22px;
    line-height: 22px;
  }
`;

const StyledChip = styled(Chip as any)`
  height: 30px;
  border-radius: 4px;
  background-color: ${({ theme: { colors } }) => colors.white};
  font-size: 12px;
  color: ${({ theme: { colors } }) => colors.grey};

  .MuiChip-label {
    padding-top: 2px;
  }

  &:focus {
    background-color: ${({ theme: { colors } }) => colors.white} !important;
  }
`;
const StyledCloseIcon = styled(CloseIcon as any)`
  color: ${({ theme: { colors } }) => colors.grey};
  right: 0;
  width: 14px;
  height: 14px;
  cursor: pointer;
`;

const NextStepButton = withStyles({
  root: {
    width: '100%',
    fontWeight: 600,
    '@media (max-width: 1023px)': {
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
})(MediumButton);

const StyledStrike = styled.s`
  font-size: 16px;
  color: ${({ theme: { colors } }) => colors.grey};
`;
const PromoCodeForm = styled.div`
  padding: 12px 0 24px;
`;
const StyledSaved = styled.div`
  font-size: 14px;
  font-style: italic;
  font-weight: normal;
  text-align: right;
  color: ${({ theme: { colors } }) => colors.waitingOnStockBlue};
`;

export {
  Row,
  PriceItem,
  InvisibleKey,
  Key,
  Price,
  TotalRow,
  LineBottom,
  ApplyRow,
  TextInput,
  TotalPrice,
  SidebarLayout,
  UserOptInForOrderUpdateEmails,
  SubtotalWrapper,
  NextStepButton,
  Total,
  ApplyButton,
  PromoCodeTitle,
  StyledChip,
  StyledCloseIcon,
  StyledStrike,
  PromoCodeForm,
  StyledSaved,
};
