import React from 'react';

export const Download = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3V16M12 16L16 11.625M12 16L8 11.625"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ExternalLink = () => {
  return (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.38619 17.32C1.91375 17.32 1.51157 17.1541 1.17964 16.8222C0.847721 16.4902 0.681763 16.0881 0.681763 15.6156V2.38802C0.681763 1.91559 0.847721 1.5134 1.17964 1.18147C1.51157 0.849552 1.91426 0.683594 2.38771 0.683594H8.55476V2.08259H2.38846C2.31155 2.08259 2.24103 2.11464 2.17691 2.17874C2.11281 2.24286 2.08076 2.31338 2.08076 2.39029V15.6133C2.08076 15.6903 2.11281 15.7608 2.17691 15.8249C2.24103 15.889 2.31155 15.921 2.38846 15.921H15.6115C15.6884 15.921 15.7589 15.889 15.8231 15.8249C15.8872 15.7608 15.9192 15.6903 15.9192 15.6133V9.44704H17.3182V15.6141C17.3182 16.0875 17.1523 16.4902 16.8203 16.8222C16.4884 17.1541 16.0862 17.32 15.6138 17.32H2.38619ZM6.75191 12.2354L5.76636 11.2499L14.9385 2.08259H10.8385V0.683594H17.3182V7.16334H15.9192V3.06334L6.75191 12.2354Z"
        fill="currentColor"
      />
    </svg>
  );
};
