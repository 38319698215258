import React from 'react';
import { ContentContainer, StyledOl, StyledLi } from './styles';

const TermsOfServiceFrench = () => {
  return (
    <ContentContainer>
      <div>
        <p>Avril 2021</p>
        <h1>CONDITIONS D’UTILISATION DE PRODUITS DE BÂTIMENT TAIGA LTÉE</h1>
      </div>
      <div>
        Merci d’avoir choisi d’utiliser les services offerts par Produits de bâtiment Taiga ltée,
        ses filiales et les membres de son groupe (ci-après « Taiga »). Veuillez lire les présentes
        conditions d’utilisation attentivement : elles constituent une entente (l’« Entente ») entre
        vous (ci-après « vous », « votre », « vos ») et Taiga. En cliquant sur « J’accepte », en
        signifiant votre acceptation électroniquement, en accédant aux Services (selon la définition
        attribuée à ce terme ci-après) ou en les utilisant, vous acceptez d’être lié par l’Entente.
        Si vous n’êtes pas d’accord avec l’Entente, n’utilisez pas les Services.
      </div>
      <StyledOl>
        <StyledLi>
          <strong>DISPOSITIONS GÉNÉRALES:</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            Pour l’application de l’Entente, « Services » désignent le site Web de Taiga
            (taiganow.com), sa plateforme et les comptes et applications connexes.
          </StyledLi>
          <StyledLi>
            L’Entente décrit les modalités qui régissent votre utilisation des Services de Taiga.
            D’autres modalités s’appliquent aussi aux Services, par exemple :
            <p>(a) La politique sur la protection de la vie privée de Taiga.</p>
            <p>(b) Des modalités et conditions établies par des tiers.</p>
            <p>
              (c) Pour l’ouverture d’un compte : les nouveaux comptes font systématiquement l’objet
              d’un examen aux fins d’approbation par le directeur de la succursale visée, et les
              nouveaux clients de Taiga doivent passer par un processus de vérification. Les comptes
              sont approuvés au gré de l’équipe de direction de Taiga.
            </p>
            <p>
              (d) Pour les commandes : toutes les commandes passées par l’intermédiaire du site Web
              de Taiga doivent être examinées et approuvées avant d’être libérées pour la livraison
              ou la cueillette.
            </p>
            <p>
              (e) Pour les prix : Taiga fait de son mieux pour que les prix annoncés soient exacts.
              Si toutefois il y a un écart entre le prix affiché sur taiganow.com et le prix qui
              vous est indiqué par un représentant de Taiga, Taiga facturera le prix le plus bas.
              Taiga se réserve également le droit de refuser un achat lorsqu’un prix est erroné.
            </p>
            <p>
              (f) Pour les expéditions et les retours : les commandes passées sur le site Web de
              Taiga doivent être approuvées avant que les articles achetés soient libérés et
              expédiés. Vous devez communiquer avec votre représentant de Taiga pour connaître le
              calendrier d’expédition.
            </p>
            <p>
              (g) Les autres modalités qui vous sont transmises en lien avec les Services
              concernant, par exemple, les produits, les commandes, l’activation de comptes, les
              paiements ou le traitement de données.
            </p>
          </StyledLi>
          <StyledLi>
            Vous devez être âgé d’au moins 18 ans pour utiliser les Services. En accédant aux
            Services ou en les utilisant, vous convenez que :
            <p>(a) en utilisant votre compte, vous concluez une entente exécutoire avec Taiga;</p>
            <p>(b) Yvous utiliserez les Services au Canada ou aux États-Unis seulement;</p>
            <p>
              (c) vous respecterez l’Entente ainsi que l’ensemble des lois, des règles et des
              règlements municipaux, provinciaux, territoriaux, étatiques, fédéraux et
              internationaux applicables.
            </p>
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>DROIT D’UTILISER LES SERVICES:</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            Les Services sont protégés par les lois sur la propriété intellectuelle, notamment en
            matière de droit d’auteur et de secrets commerciaux. Le seul droit qui vous est accordé
            est celui d’utiliser les Services aux fins décrites par Taiga. Taiga se réserve tous les
            autres droits à l’égard des Services. Taiga vous accorde une licence personnelle,
            limitée, non exclusive et incessible vous permettant d’utiliser les Services jusqu’à ce
            que l’Entente prenne fin, à condition que vous honoriez vos obligations de paiement et
            que vous respectiez l’Entente.
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>SERVICES:</strong>
        </StyledLi>
        <div>
          Vous reconnaissez que les Services visés par les présentes et par le site Web
          https://www.taigabuilding.com vous ont été présentés et qu’ils vous conviennent. Vous
          convenez que Taiga peut les modifier à l’occasion, auquel cas elle vous informera des
          modifications conformément aux dispositions pertinentes des présentes.
        </div>
        <StyledLi>
          <strong>PAIEMENT:</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            Vous êtes liés par les modalités de paiement qui figurent dans votre plus récent
            contrat, dont vous pouvez obtenir une copie en communiquant avec votre représentant de
            Taiga. Si vous n’avez pas conclu de contrat, vous êtes liés par les modalités standards
            qui figurent à votre compte. Communiquez avec votre représentant de Taiga pour en savoir
            plus.
          </StyledLi>
          <StyledLi>
            Vous vous engagez à fournir à Taiga des coordonnées et des renseignements de facturation
            complets et exacts au moment de votre inscription aux Services et de l’ouverture d’un
            compte. Vous devez notamment fournir la dénomination sociale de votre société, ses
            adresses municipale et courriel ainsi que le nom et le numéro de téléphone d’une
            personne (que vous avez choisie) autorisée à prendre des décisions concernant
            l’ouverture et la modification de votre compte (l’« Administrateur »). L’Administrateur
            sera votre contact aux fins de facturation. Vous vous engagez à mettre à jour ces
            renseignements dans les 30 jours suivant tout changement à leur égard. Si vous
            fournissez des renseignements inexacts ou frauduleux, Taiga se réserve le droit de
            fermer votre compte et d’exercer tout autre recours à sa disposition. Vous reconnaissez
            que vous serez tenu responsable des dommages découlant de l’omission de transmettre ces
            renseignements ou du non-respect des dispositions de l’Entente lors de la transmission.
          </StyledLi>
          <StyledLi>
            Si vous croyez qu’une facture que vous avez reçue pour des produits ou des Services
            comporte une erreur, vous devez la contester en nous remettant un avis à cet effet (un «
            Avis de contestation ») dans les 10 jours suivant la date de la facture (la « Période de
            contestation »). Si un paiement n’est pas reçu et qu’aucun Avis de contestation n’a été
            remis (ou qu’un tel avis a été remis après la Période de contestation), Taiga se réserve
            le droit de mettre fin à votre accès aux Services pour défaut de paiement. Vous devez
            transmettre tout Avis de contestation à votre représentant de Taiga.
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>SUSPENSION DE COMPTE</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            Si votre compte est suspendu en raison d’un défaut de paiement, Taiga conservera vos
            renseignements (les « Données du compte ») aussi longtemps que l’exigent les lois
            applicables, après quoi elle en disposera de manière confidentielle. Vous pourrez
            également demander à Taiga de vous donner accès aux Données du compte conformément aux
            dispositions de l’Entente.
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>ANNULATION</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            Vous pouvez demander l’annulation des Services à tout moment en avisant Taiga par écrit
            (un « Avis d’annulation »). Vous pouvez transmettre un Avis d’annulation par courriel à
            l’adresse taiganow@taigabuilding.com ou à votre représentant de Taiga en indiquant « Fermeture
            du compte » en objet.
          </StyledLi>
          <StyledLi>
            <strong>Consultation de vos données après l’annulation : </strong>
            <span>
              En vertu de l’Entente, vous avez le droit de demander à Taiga de vous transmettre les
              Données du compte stockées sur sa plateforme au moment de l’annulation, que ce soit à
              la suite de la remise d’un Avis d’annulation ou d’un défaut de paiement. Taiga peut
              vous facturer des frais raisonnables pour la consultation de ces données, que vous
              devrez acquitter à l’avance. Taiga déploiera tous les efforts raisonnables pour vous
              fournir les Données du compte à votre demande et au moment du paiement des frais.
              Taiga ne peut aucunement être tenue responsable de la perte des Données du compte si
              vous ne les demandez pas dans les 90 jours qui suivent la fermeture de votre compte.
            </span>
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>FRAIS, REMBOURSEMENTS ET DIFFÉRENDS</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            Vous vous engagez à payer à Taiga des « frais d’administration » d’au moins 50 $ et d’au
            plus 150 $ si vous contestez à tort une facture. Si vous portez le différend devant les
            tribunaux et qu’on ne vous donne pas gain de cause, vous vous engagez à dédommager Taiga
            de toutes les pertes liées à sa défense, y compris, sans limiter la portée générale de
            ce qui précède, les honoraires versés sur une base avocat-client.
          </StyledLi>
          <StyledLi>
            Des frais administratifs de 50 $ s’appliquent pour tous les chèques sans provision
            reçus.
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>LIMITES DU SERVICE DE SOUTIEN</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            Taiga offre un service de soutien à la clientèle pendant les heures normales
            d’ouverture, sauf les jours fériés. Veuillez communiquer avec votre succursale Taiga
            locale pour connaître l’horaire du service à la clientèle.
            <p>
              (a) Taiga vous offre du soutien pour l’utilisation de votre compte sur sa plateforme.
            </p>
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>CONTENU ET UTILISATION DES SERVICES</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            Vous vous engagez à ne pas utiliser les Services pour téléverser, publier, distribuer,
            reproduire, promouvoir ou transmettre les éléments suivants ou interagir avec ceux-ci ou
            transmettre un lien qui mène vers ceux-ci, et à ne pas permettre à des tiers de le faire
            :
            <p>
              (a) Des renseignements ou des propos illicites, frauduleux, diffamatoires, obscènes,
              pornographiques, blasphématoires, menaçants, abusifs, haineux, harcelants, injurieux,
              inappropriés ou répréhensibles, quelle qu’en soit la nature, y compris toute conduite
              violente, qui incite à la violence ou à la tenue de propos incendiaires envers une
              personne ou qui constitue une infraction à une loi civile ou criminelle locale,
              provinciale, territoriale, fédérale ou étrangère.
            </p>
            <p>
              (b) Du contenu par lequel vous usurpez l’identité d’une autre personne ou représentez
              faussement votre identité ou vos compétences, qui pourrait porter atteinte à la vie
              privée d’une personne, qui est déloyal ou trompeur ou qui présente un risque pour la
              santé ou la sécurité d’une personne ou du public.
            </p>
            <p>
              (c) Un virus, un cheval de Troie, un vers ou tout autre élément informatique
              perturbateur ou nuisible;
            </p>
            <p>
              (d) Tout contenu qui ne vous appartient pas ou que vous n’avez pas le droit d’utiliser
              sans le consentement du titulaire des droits de propriété intellectuelle.
            </p>
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>UTILISATION DE VOS RENSEIGNEMENTS PERSONNELS</strong>
        </StyledLi>
        <div>
          Taiga utilisera vos renseignements personnels conformément à sa politique sur la
          protection de la vie privée, que vous pouvez consulter au https://taiganow.com/privacy.
        </div>
        <StyledLi>
          <strong>EXONÉRATION DE GARANTIES</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            LORSQUE VOUS UTILISEZ LES SERVICES ET LE CONTENU FOURNIS PAR TAIGA, VOUS LE FAITES
            ENTIÈREMENT À VOS RISQUES. SAUF INDICATION CONTRAIRE DANS L’ENTENTE, LES SERVICES SONT
            FOURNIS «&nbsp;TELS QUELS &nbsp;». DANS TOUTE LA MESURE PERMISE PAR LES LOIS
            APPLICABLES, TAIGA ET LES MEMBRES DE SON GROUPE, DE MÊME QUE LEURS TIERS FOURNISSEURS,
            CONCÉDANTS DE LICENCE, DISTRIBUTEURS ET FOURNISSEURS (COLLECTIVEMENT, LES « FOURNISSEURS
            »), DÉCLINENT TOUTE GARANTIE EXPRESSE OU IMPLICITE, Y COMPRIS LES GARANTIES DE
            CONVENANCE À UN USAGE PARTICULIER, DE TITRE, DE QUALITÉ MARCHANDE, DE SAUVEGARDE DE
            DONNÉES, D’ABSENCE DE CONTRAVENTION OU DE CONTREFAÇON QUANT AUX DROITS DE PROPRIÉTÉ
            INTELLECTUELLE AINSI QUE TOUTE GARANTIE CONCERNANT L’EXACTITUDE, LA FIABILITÉ OU LA
            QUALITÉ DU CONTENU DES SERVICES OU QUI LEUR EST LIÉ. TAIGA, LES MEMBRES DE SON GROUPE ET
            LES FOURNISSEURS NE GARANTISSENT PAS QUE LES SERVICES SONT SÉCURISÉS, EXEMPTS DE BOGUES,
            DE VIRUS OU D’ERREURS OU QU’ILS SONT À L’ABRI DES INTERRUPTIONS, DES VOLS OU DE LA
            DESTRUCTION. SI LES EXCLUSIONS DE GARANTIES IMPLICITES NE S’APPLIQUENT PAS À VOUS, LA
            DURÉE DE TOUTE GARANTIE DE CE GENRE EST LIMITÉE AUX 60 &nbsp;JOURS QUI SUIVENT LA DATE
            DE L’ACHAT DES SERVICES OU, SI ELLE EST ANTÉRIEURE, LA DATE DE LEUR LIVRAISON.
          </StyledLi>
          <StyledLi>
            <span>
              TAIGA, LES MEMBRES DE SON GROUPE ET LES FOURNISSEURS DÉCLINENT TOUTE DÉCLARATION OU
              GARANTIE SELON LAQUELLE VOTRE UTILISATION DES SERVICES RESPECTERA LES LOIS ET LES
              RÈGLEMENTS APPLICABLES.
            </span>
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>LIMITATION DE RESPONSABILITÉ</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            DANS TOUTE LA MESURE PERMISE PAR LES LOIS APPLICABLES, LA RESPONSABILITÉ DE TAIGA, DES
            MEMBRES DE SON GROUPE ET DES FOURNISSEURS À L’ÉGARD DES RÉCLAMATIONS LIÉES À L’ENTENTE
            NE PEUT DÉPASSER LE MONTANT QUE VOUS AVEZ PAYÉ POUR LES SERVICES DANS LES DOUZE (12)
            &nbsp;MOIS PRÉCÉDANT LA RÉCLAMATION. SOUS RÉSERVE DES LOIS APPLICABLES, TAIGA, LES
            MEMBRES DE SON GROUPE ET LES FOURNISSEURS NE PEUVENT EN AUCUN CAS ÊTRE TENUS
            RESPONSABLES &nbsp;: (A) &nbsp;DES DOMMAGES INDIRECTS, ACCESSOIRES OU CONSÉCUTIFS OU DES
            DOMMAGES-INTÉRÊTS SPÉCIAUX OU PUNITIFS; (B) &nbsp;DES DOMMAGES LIÉS À DES DÉFAILLANCES
            DU SYSTÈME DE TÉLÉCOMMUNICATION, D’INTERNET OU DES COMMUNICATIONS ÉLECTRONIQUES, À LA
            CORRUPTION, LA SÉCURITÉ, LA PERTE OU LE VOL DE DONNÉES, À DES VIRUS OU DES LOGICIELS
            ESPIONS, À UNE PERTE DE CLIENTÈLE, DE REVENUS, DE PROFITS OU D’INVESTISSEMENT, OU À
            L’UTILISATION DE LOGICIELS OU DE MATÉRIEL QUI NE CORRESPONDENT PAS AUX EXIGENCES SYSTÈME
            DE TAIGA. CETTE LIMITATION S’APPLIQUE MÊME SI TAIGA, LES MEMBRES DE SON GROUPE ET LES
            FOURNISSEURS ONT ÉTÉ AVISÉS DE LA POSSIBILITÉ DE TELS DOMMAGES. L’ENTENTE PRÉVOIT
            L’ENTIÈRE RESPONSABILITÉ DE TAIGA ET DES MEMBRES DE SON GROUPE, DE MÊME QUE VOTRE UNIQUE
            RECOURS RELATIVEMENT AUX SERVICES ET À LEUR UTILISATION.
          </StyledLi>
          <StyledLi>
            Vous comprenez qu’il vous incombe de faire part à Taiga de tout problème quant à l’accès
            aux Services et que vous avez tout intérêt à le faire.
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>INDEMNISATION</strong>
        </StyledLi>
        <div>
          Vous vous engagez à indemniser Taiga, les membres de son groupe et les Fournisseurs et à
          les dégager de toute responsabilité à l’égard des réclamations, des responsabilités et des
          dépenses, y compris les honoraires et débours raisonnables d’avocat, découlant de votre
          utilisation des Services ou d’un manquement de votre part à l’Entente (collectivement, les
          « &nbsp;Réclamations &nbsp;»). Taiga se réserve le droit, à sa seule discrétion et à ses
          frais, d’assumer la défense et le contrôle exclusifs dans le cadre de toute Réclamation.
          Vous vous engagez à coopérer raisonnablement avec Taiga à sa demande pour sa défense en
          cas de Réclamation.
        </div>
        <StyledLi>
          <strong>DROIT D’AUTEUR ET MARQUES DE COMMERCE</strong>
        </StyledLi>
        <div>
          Les logos, les marques de commerce et les illustrations de Taiga, de même que la
          disposition et les fonctionnalités générales des Services, demeurent la propriété de
          Taiga. Les marques de commerce, les logos et les illustrations de tiers appartiennent à
          ces tiers. Aucune licence ni aucun droit n’est accordé à vous ou à un tiers à l’égard de
          ces logos, illustrations, disposition et fonctionnalités du seul fait de votre utilisation
          des Services. Tout droit qui vous est transféré en lien avec Taiga ou les Services l’est
          pour votre utilisation seulement, et Taiga ne vous l’accorde à aucune autre fin.
          L’ensemble des droits dans les Services fournis par Taiga en vertu des présentes, y
          compris les documents et les renseignements (ou leurs copies) que Taiga vous transmet pour
          vous permettre d’utiliser les Services, appartiennent exclusivement à Taiga ou aux membres
          de son groupe.
        </div>
        <StyledLi>
          <strong>SÉCURITÉ DU SYSTÈME ET DU RÉSEAU</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            Les utilisateurs ne doivent pas porter atteinte ou tenter de porter atteinte à la
            sécurité du réseau de Taiga. La personne qui porte atteinte à la sécurité de Taiga ou
            des utilisateurs des Services peut engager sa responsabilité civile ou criminelle. Taiga
            mènera une enquête sur les cas d’atteinte potentiels et pourrait faire intervenir des
            autorités d’application de la loi ou de réglementation et coopérer avec elles pour
            poursuivre les utilisateurs impliqués. Constituent notamment une telle atteinte:
            <p>
              <span>
                (a) le fait pour un utilisateur de consulter des données qui ne lui sont pas
                destinées ou de se connecter à un compte qu’il n’est pas autorisé à utiliser;
              </span>
            </p>
            <p>
              <span>
                (b) toute tentative de sonder, de scruter ou de tester la vulnérabilité du système
                ou du réseau de Taiga ou d’enfreindre les mesures de sécurité ou d’authentification
                sans autorisation;
              </span>
            </p>
            <p>
              <span>
                (c) toute tentative de nuire à l’utilisation des Services par un autre utilisateur,
                notamment par voie de surcharge, d’hameçonnage, d’inondation, de bombardement de
                courriels ou de plantage;
              </span>
            </p>
            <p>
              <span>
                (d) toute mesure prise par un utilisateur pour obtenir des Services auxquels il n’a
                pas droit.
              </span>
            </p>
          </StyledLi>
          <StyledLi>
            <span>
              Taiga n’est aucunement tenue de surveiller les activités des utilisateurs pour
              déterminer s’il y a eu violation de l’Entente, et les présentes ne l’obligent
              aucunement à surveiller ou à contrôler les activités sur Internet. Taiga n’est pas en
              droit de surveiller les activités des utilisateurs, sauf si elle a un motif
              raisonnable de soupçonner qu’il y a eu violation de l’Entente.
            </span>
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>RÉSILIATION</strong>
        </StyledLi>
        <div>
          <span>
            Taiga peut, à sa discrétion exclusive, sans préavis et avec prise d’effet immédiate,
            restreindre ou résilier l’Entente ou refuser de l’appliquer, ou suspendre les Services
            ou des services connexes, en totalité ou en partie, si elle estime que votre utilisation
            des Services est contraire à l’Entente ou inappropriée, qu’elle dépasse largement
            l’utilisation normale qu’en font les autres utilisateurs ou en diffère largement ou
            soulève des soupçons de fraude, d’utilisation abusive, de problème de sécurité,
            d’activité illicite ou d’accès non autorisé, pour préserver l’intégrité ou la
            disponibilité des Services ou de ses systèmes, pour respecter les politiques applicables
            de Taiga, si vous indiquez ne plus vouloir recevoir de communications électroniques ou
            si votre utilisation des Services entre en conflit avec les intérêts de Taiga ou d’un
            autre utilisateur. Dès que vous recevez un avis de la part de Taiga vous indiquant
            qu’elle a mis fin à votre utilisation des Services, vous devez cesser d’utiliser les
            Services et le solde de votre compte sera dû. La résiliation de l’Entente n’a aucun
            effet sur le droit de Taiga de recevoir les paiements qui lui sont dus. Toutes les
            dispositions de l’Entente demeureront en vigueur malgré sa résiliation, à l’exception du
            paragraphe &nbsp;2.1.
          </span>
        </div>
        <StyledLi>
          <strong>DISPOSITIONS DIVERSES</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            <strong>Cession.</strong>
            <span>
              Sauf dans la mesure expressément prévue aux présentes, vous ne pouvez pas transférer
              ou céder l’Entente ou votre compte sans le consentement écrit préalable de Taiga.
              Taiga peut céder l’Entente à tout moment sans votre consentement et sans préavis.
            </span>
          </StyledLi>
          <StyledLi>
            <strong>Autres services de Taiga.</strong>
            <span>
              Taiga pourrait vous offrir d’autres services, produits ou promotions (les «
              &nbsp;Services supplémentaires &nbsp;»). Des modalités, conditions et frais
              supplémentaires pourraient s’y appliquer. Dans le cadre de l’utilisation de Services
              supplémentaires, vous pourriez téléverser ou saisir des données de votre compte (p.
              &nbsp;ex. noms, adresses, numéros de téléphone, achats) sur Internet.
            </span>
          </StyledLi>
          <StyledLi>
            <strong>Communications.</strong>
            <span>
              Taiga peut être légalement tenue de communiquer avec vous au sujet des Services ou des
              produits de tiers. Vous convenez que Taiga peut le faire en vous envoyant un courriel
              ou publiant les renseignements concernés sur son site Web.
            </span>
          </StyledLi>
          <StyledLi>
            <strong>Gestion des mots de passe et mises à jour. </strong>
            <span>
              Il vous incombe d’assurer la sécurité des mots de passe qui vous permettent d’utiliser
              Services et de communiquer avec Taiga si vous prenez connaissance d’un accès non
              autorisé à votre compte. Les Services peuvent périodiquement être mis à jour par
              l’ajout d’outils, de fonctionnalités ou d’applications tierces ou au moyen de mises à
              jour générales visant leur amélioration. Vous convenez d’accepter ces mises à jour.
            </span>
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>MODIFICATIONS</strong>
        </StyledLi>
        <div>
          Taiga se réserve le droit de modifier l’Entente à tout moment à sa seule discrétion, et
          les modifications entreront en vigueur au moment où elles sont publiées au moyen des
          Services ou sur le site Web utilisé pour fournir les Services ou qu’elles sont portées à
          votre connaissance de toute autre façon. Taiga peut également modifier les Services ou
          cesser de les offrir, en totalité ou en partie. Vous devez lire attentivement toute
          version modifiée de l’Entente, car votre utilisation des Services constitue votre
          acceptation des modifications.
        </div>
        <StyledLi>
          <strong>LOIS APPLICABLES ET TRIBUNAUX COMPÉTENTS</strong>
        </StyledLi>
        <div>
          Vous convenez que l’Entente est régie par les lois de la province de la
          Colombie-Britannique et les lois du Canada qui s’y appliquent. Vous convenez que les
          différends relatifs à l’Entente doivent être soumis aux tribunaux de la
          Colombie-Britannique, qui ont compétence exclusive pour entendre et régler tout différend
          et toute réclamation qui découle de l’Entente ou qui y est lié, y compris les questions
          relatives à son objet, sa validité et son caractère obligatoire à votre égard.
        </div>
      </StyledOl>
    </ContentContainer>
  );
};

export default TermsOfServiceFrench;
