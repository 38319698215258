import { ISelectedValue, FilterType } from 'models/interfaces/productVariant';
import { FacetClass } from 'models/classes/facetClass';

export const getMultiselectedValues = (
  code: string,
  name: string,
  values: string[],
  selectedValues: ISelectedValue[]
) => {
  return selectedValues.some(selectedValue => selectedValue.code === code)
    ? selectedValues.map(selectedValue =>
      selectedValue.code === code
        ? {
          ...selectedValue,
          name,
          values,
          type: FilterType.MULTISELECT
        }
        : selectedValue
    )
    : [
      ...selectedValues,
      {
        code,
        name,
        values,
        type: FilterType.MULTISELECT
      },
    ];
};

export const getRangeFilterSelectedValues = (
  code: string,
  name: string,
  values: number[],
  selectedValues: ISelectedValue[]
) => {
  return selectedValues.some(selectedValue => selectedValue.code === code)
    ? selectedValues.map(selectedValue =>
      selectedValue.code === code
        ? {
          ...selectedValue,
          name: `${name}: ${values[0]} - ${values[1]}`,
          values,
          type: FilterType.RANGE
        }
        : selectedValue
    )
    : [
      ...selectedValues,
      {
        code,
        name: `${name}: ${values[0]} - ${values[1]}`,
        values,
        isRangeFilter: true,
        type: FilterType.RANGE
      },
    ];
};

export const findNecessaryCheck = (
  facetProperty: string,
  bucketKey: string,
  selectedValues: ISelectedValue[]
) =>
  selectedValues.some(
    selectedValue => selectedValue.code === facetProperty && selectedValue.bucketKey === bucketKey
  );

export const generateFilter = (newSelectedValues: ISelectedValue[]) => {
  const filterParamsMock = newSelectedValues.reduce((acc, selectedValue) => {
    const tempAcc = { ...acc };

    tempAcc[selectedValue.code] = [];

    return tempAcc;
  }, {});

  newSelectedValues.forEach(selectedValue => {
    if (selectedValue.values) {
      if (selectedValue.isRangeFilter) {
        filterParamsMock[
          selectedValue.code
        ] = `${selectedValue.values[0]}..${selectedValue.values[1]}`;
      } else {
        filterParamsMock[selectedValue.code] = selectedValue.values;
      }
    } else {
      filterParamsMock[selectedValue.code].push(selectedValue.bucketKey);
    }
  });

  return filterParamsMock;
};

export const getValueForFacetRangeFilter = (facet: FacetClass, value: number[]) => {
  const selectedBuckets = facet.buckets.slice(value[0], value[1] + 1);
  const valuesToUse = {
    facetKey: facet.property,
    values: selectedBuckets.map(bucket => bucket.key),
  };

  const firstSelectedBucketLabel = selectedBuckets[0].label;
  const lastSelectedBucketLabel = selectedBuckets[selectedBuckets.length - 1].label;
  const selectedValueName = `${firstSelectedBucketLabel} - ${lastSelectedBucketLabel}`;

  return {
    valuesToUse,
    selectedValueName,
  };
};
