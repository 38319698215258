import styled from 'styled-components/macro';
import { TextField } from '@material-ui/core';

const InputButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
`;

const InputWithLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 10px;
`;

const StyledInputWrapper = styled.div`
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  overflow: hidden;
  max-width: 60px;
`;

const IncrementButton = styled.button`
  width: 22px;
  height: 22px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { colors } }) => colors.white};
  outline: none;
  &:hover {
    cursor: pointer;
  }
  svg {
    width: 6px;
    rect {
      fill: #48576B;
    }
  }
`;

const IncrementButtonLabel = styled.p`
  margin: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: #00133C;
`;

const StyledTextField = styled(TextField as any)`
  .MuiInputBase-input {
    height: 22px;
    font-size: 10px;
    font-weight: bold;
    color: #00133C;
    text-align: center;
    padding: 0 !important;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const PromotionText = styled.p`
  text-decoration: underline;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  &:hover {
    font-size: 18px;
    font-weight: 900;
  }
`;

const NextTierPrice = styled.span`
  font-family: Open Sans,serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  margin: 0;
`;

const BranchName = styled.p`
  color: #337D58;
  font-size: 14px;
  font-weight: 600;
`;

const Price = styled.p`
  color: #00133C;
  font-size: 12px;
  font-weight: normal;
  margin: 0;
  .sign {
    font-size: 14px;
    padding: 0 7px 0 2px;
  }
`;

export {
  StyledTextField,
  IncrementButtonLabel,
  IncrementButton,
  StyledInputWrapper,
  InputWithLabelWrapper,
  InputButtonsWrapper,
  PromotionText,
  NextTierPrice,
  BranchName,
  Price
};
