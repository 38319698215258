import {IAddress} from 'models/interfaces/user';
import {ISurvey} from '../interfaces/surveys';

interface IDefaultLanguage {
	id?: number;
	code?: string;
	name?: string;
}

export class UserClass {
  id: number = null;

  email: string = null;

  firstName: string = null;

  lastName: string = null;

  roles: string[] = null;

  addresses: IAddress[] = null;

  defaultAddress: IAddress = null;

  updatedAt: string = null;

  defaultLocale: IDefaultLanguage = null;

  defaultPricing: string = null;

  availableLocales: IDefaultLanguage[] = null;

  defaultDisplayLanguage: IDefaultLanguage = null;

  lastLogin: string = null;

  isEmailEnabled: boolean = null;

  isMailchimpEnabled: boolean = null;

  surveys: ISurvey[] = null;

  orderCount: number = null;

  erpTelephone1: string = null;

  customer: any = null;

  isMaintain: number = 0;

  loginMethod: string = null;

	showPrice: boolean = null;

	constructor(...args) {
	  args.forEach((item: UserClass) => {
	    this.id = item.id || null;
	    this.email = item.email || null;
	    this.firstName = item.firstName || null;
	    this.lastName = item.lastName || null;
	    this.roles = item.roles || null;
	    this.addresses = item.addresses || null;
	    this.defaultAddress = item.defaultAddress || null;
	    this.updatedAt = item.updatedAt || null;
	    this.defaultLocale = item.defaultLocale || null;
	    this.defaultDisplayLanguage = item.defaultDisplayLanguage || null;
	    this.defaultPricing = item.defaultPricing || null;
	    this.availableLocales = item.availableLocales || null;
	    this.lastLogin = item.lastLogin || null;
	    this.isEmailEnabled = item.isEmailEnabled || false;
	    this.isMailchimpEnabled = item.isMailchimpEnabled || false;
	    this.surveys = Array.isArray(item.surveys) ? item.surveys : [];
	    this.orderCount = item.orderCount || null;
	    this.erpTelephone1 = item.erpTelephone1 || null;
	    this.customer = item.customer;
	    this.isMaintain = item.isMaintain || 0;
	    this.loginMethod = item.loginMethod || null;
	    this.showPrice = item.showPrice
	  });
	}
}
