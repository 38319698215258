import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { SalesRepresentativeClass } from 'models/classes/salesRepresentative';

import {useDispatch} from 'react-redux';
import {setNearbyBranchEmailStatus} from 'ducks/productVariant/actions';
import {setClickedProductRequestPricing, setOpenPopupInquireNow} from 'ducks/product/actions';
import {
  PopUpHeaderSmall as PopUpHeader,
  PopUpTextSmall as PopUpText,
  StyledButtonSmall as StyledButton,
} from '../styles';

interface ISubmitEmailSent {
  setOpenRequestNearbyBranch: Dispatch<SetStateAction<boolean>>;
  salesRepresentative: SalesRepresentativeClass;
}

const SubmitEmailSent: React.FC<ISubmitEmailSent> = ({
  setOpenRequestNearbyBranch,
  salesRepresentative,
}: ISubmitEmailSent) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setNearbyBranchEmailStatus(false));
    setOpenRequestNearbyBranch(false);
    dispatch(setOpenPopupInquireNow(false));
    dispatch(setClickedProductRequestPricing(null));
  };

  const representativeName = salesRepresentative.name;
  const { t } = useTranslation();

  return (
    <>
      <PopUpHeader>{t('request_pricing_popup.thank_you')}</PopUpHeader>
      <PopUpText>
        {t('request_pricing_popup.representative_will_answer', { representativeName })}
      </PopUpText>
      <StyledButton onClick={handleClose}>
        <p>{t('request_pricing_popup.back_to_products')}</p>
      </StyledButton>
    </>
  );
};

export default SubmitEmailSent;
