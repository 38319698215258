import { priceTypeSelector } from 'ducks/application/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import usePrevious from './usePrevious';

const usePreviousPricing = () => {
  const pricing = useSelector(priceTypeSelector);
  const previousPricing = usePrevious(pricing);
  const pricingForFilter = useMemo(() => (pricing === 'hide' ? previousPricing : pricing), [
    pricing,
    previousPricing,
  ]);

  return pricingForFilter;
};

export default usePreviousPricing;
