import { UserClass } from 'models/classes/userClass';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import axios from 'utils/axios';
import { ProfileActionTypes, RequestChangePasswordAction } from 'ducks/profile/types';
import { storeErrors } from 'utils/errors';

function* getStoreContactProfile() {
  try {
    const response = yield call(axios.get, '/api/profile');
    const storeContactProfile = new UserClass(response.data);
    yield put({
      type: ProfileActionTypes.REQUEST_STORE_CONTACT_PROFILE_SUCCESS,
      payload: { storeContactProfile },
    });
  } catch (errors) {
    yield put({
      type: ProfileActionTypes.REQUEST_STORE_CONTACT_PROFILE_ERROR,
      payload: { errors },
    });
  }
}

function* postChangePassword(resetPasswordAction: RequestChangePasswordAction) {
  const { currentPassword, newPassword } = resetPasswordAction.payload;

  const changePasswordParams = {
    currentPassword,
    newPassword,
  };

  try {
    yield call(axios.patch, '/api/auth/profile/change-password', changePasswordParams);

    yield put({
      type: ProfileActionTypes.REQUEST_CHANGE_PASSWORD_SUCCESS,
      payload: { status: 'Success' },
    });
  } catch (error) {
    yield storeErrors(
      ProfileActionTypes.REQUEST_CHANGE_PASSWORD_ERROR,
      [400, 422],
      error.response
    );
  }
}

function* watchGetStoreContactProfile() {
  yield takeEvery(ProfileActionTypes.REQUEST_STORE_CONTACT_PROFILE, getStoreContactProfile);
}

function* watchPostChangePassword() {
  yield takeEvery(ProfileActionTypes.REQUEST_CHANGE_PASSWORD, postChangePassword);
}

export default function* profileSaga() {
  yield all([watchGetStoreContactProfile(), watchPostChangePassword()]);
}
