import React from 'react';

export const PhoneIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.70093 1.11463C3.95837 1.37189 4.21668 1.62842 4.47295 1.88685C4.92486 2.34266 4.92574 2.87431 4.47559 3.3266C4.15269 3.65107 3.8317 3.97744 3.50251 4.29532C3.41552 4.37922 3.40864 4.44833 3.45272 4.55404C3.66886 5.07311 3.98136 5.53272 4.33296 5.96495C5.03967 6.83367 5.83892 7.60472 6.79209 8.20299C6.99622 8.33111 7.22203 8.42438 7.43466 8.53976C7.54405 8.59906 7.61902 8.58017 7.70659 8.48968C8.02861 8.15687 8.35839 7.83138 8.68744 7.5053C9.1184 7.07833 9.65934 7.07746 10.0922 7.50618C10.6211 8.03007 11.1477 8.55645 11.6721 9.08474C12.112 9.5278 12.1085 10.0693 11.6661 10.5156C11.3673 10.8172 11.0514 11.1034 10.7688 11.4194C10.3574 11.8793 9.84137 12.0282 9.25107 11.9957C8.39309 11.9486 7.6016 11.6639 6.83939 11.2935C5.1451 10.4702 3.69668 9.32911 2.48505 7.88702C1.58915 6.82078 0.848757 5.6604 0.36185 4.34979C0.126378 3.71593 -0.0420256 3.06671 0.00922768 2.37999C0.0408583 1.95625 0.200036 1.59576 0.512389 1.29853C0.847732 0.979188 1.16726 0.643299 1.49762 0.318538C1.9302 -0.106813 2.47129 -0.105935 2.90489 0.31971C3.17258 0.582388 3.43573 0.849459 3.70093 1.11463Z"
        fill="white"
      />
    </svg>
  );
};
