import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
  Slide,
} from '@material-ui/core';

import { Loader, PasswordField } from 'components/common';
import { useFormik } from 'formik';
import { AuthActionTypes } from 'ducks/auth/types';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { authErrorsSelector, selectCheckedOtp, selectShowOtp } from 'ducks/auth/selectors';
import { selectAppLoading } from 'ducks/application/selectors';
import { selectUserLoading } from 'ducks/user/selectors';
import { validateRecaptcha } from 'ducks/auth/actions';

import { useTranslation } from 'react-i18next';

import Fade from 'react-reveal/Fade';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PasswordStrengthBar from 'react-password-strength-bar';

const StyledGrid = styled(Grid)`
  padding: 0;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 100px;
  @media (max-width: 737px) {
	padding-top: 9px;
	padding-bottom: 18px;
  }
`;

const StyledTypography = styled(Typography)`
  font-family: Open Sans, serif;
  align-items: center;
  letter-spacing: 0.01em;
  color: #333333;
  padding-bottom: 8px;
  @media (max-width: 737px) {
	font-size: 16px;
	line-height: 20px;
  }
` as typeof Typography;

const PasswordMessageTypography = styled(Typography)`
  font-family: Open Sans, serif;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #939599;
  padding-bottom: 40px;
  @media (max-width: 737px) {
	padding-bottom: 20px;
  }
`;

const StyledCard = styled(Card)`
  max-width: 440px;
  margin: 0 auto;
  @media (max-width: 737px) {
	margin: 0 10px;
  }
`;

const StyledButton = withStyles({
  root: {
    textAlign: 'center',
    width: '100%',
    height: '48px',
    backgroundColor: '#00853e',
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    letterSpacing: '0.01em',
    fontWeight: 'bold',
    color: '#FFFFFF',
    '&$disabled': {
      color: '#FFFFFF',
    },
    '&:hover': {
      backgroundColor: '#37875F',
    },
  },
  disabled: {
    backgroundColor: '#DADCE0',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const StyledCardContent = styled(CardContent)`
  padding-left: 60px;
  padding-top: 0;
  padding-right: 60px;
  @media (max-width: 737px) {
	padding-left: 24px;
	padding-right: 24px;
	padding-bottom: 0;
  }
`;

const StyledCardActions = styled(CardActions)`
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 50px;
  @media (max-width: 737px) {
	padding-left: 24px;
	padding-right: 24px;
	padding-bottom: 10px;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  padding-top: 50px;
  padding-left: 60px;
  padding-bottom: 12px;

  div > span {
	font-family: Open Sans, serif;
	font-style: normal;
	font-weight: 600;
	font-size: 28px;
	line-height: 38px;
	letter-spacing: 0.01em;
  }

  @media (max-width: 440px) {
	padding-top: 20px;
	padding-left: 20px;
  }
`;

const StyledTextField = styled(TextField as any)`
  padding-bottom: 20px;
  margin: 0;
  @media (max-width: 737px) {
	padding-bottom: 10px;
  }
`;

const StyledPasswordField = withStyles({
  root: {
    paddingBottom: '28px',
    margin: '0',
    '& label.Mui-focused': {
      color: '#00853e',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#00853e',
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#C4C4C4',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00853e',
        },
      },
    },
    '@media (max-width: 737px)': {
      paddingBottom: '18px',
    },
  },
})(PasswordField);

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(5, 'invalid_password')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\w\W]\S{7,}$/, 'new_password_rule')
    .required('password_required'),
  confirmPassword: Yup.string()
    .required('password_confirm')
    .oneOf([Yup.ref('password')], 'password_not_match'),
  otp: Yup.number().when('showOtp', {
    is: true,
    then: Yup.number().required()
  })
});

const initialValues = {
  password: '',
  confirmPassword: '',
  otp: '',
};

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const appLoading = useSelector(selectAppLoading);
  const userLoading = useSelector(selectUserLoading);
  const authErrors = useSelector(authErrorsSelector);
  const checkedOtp = useSelector(selectCheckedOtp);
  const showOtp = useSelector(selectShowOtp);
  const { executeRecaptcha } = useGoogleReCaptcha();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const initialErrors = {};
  const initialTouched = {};

  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    setEmail(query.get('email').replace(' ', '+'));
    setToken(query.get('token'));
  }, [query]);

  useEffect(() => {
    if (token && email) {
      dispatch({ type: AuthActionTypes.VERIFY_TOKEN, payload: { token, email } });
    }
  }, [dispatch, token, email]);

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues,
    onSubmit: async () => {
      if (!checkedOtp) {
        const recaptchaToken = await executeRecaptcha();
        dispatch(validateRecaptcha(email, recaptchaToken));
      } else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await submitLogin();
      }
    },
    validationSchema,
    initialErrors,
    initialTouched,
    enableReinitialize: true,
  });
  const submitLogin = useCallback(async () => {
    const { password, otp } = values;

    const payload: any = {
      email,
      password,
      otp,
      recoveryCode: token,
    };
    if (!otp) {
      payload.recaptchaToken = await executeRecaptcha();
    }
    if (payload.otp.toString() === '') {
      delete payload.otp;
    }
    dispatch({
      type: AuthActionTypes.RECOVER_PASSWORD,
      payload,
    });
  }, [dispatch, email, executeRecaptcha, token, values]);

  useEffect(() => {
    if (checkedOtp && !showOtp) {
      submitLogin();
    }
  }, [checkedOtp, showOtp, submitLogin])

  const { t, i18n } = useTranslation();
  const requestLink = `<a href="/${i18n.language}/reset-password">${t('login.request')}</a>`;

  return (
    <>
      {appLoading || userLoading ? (
        <Loader />
      ) : (
        <Slide direction='down' in>
          <StyledGrid item xs={12} sm={8} md={8}>
            <StyledCard variant='outlined'>
              <StyledCardHeader title={t('login.title')} />
              <Fade>
                <form onSubmit={handleSubmit}>
                  <StyledCardContent>
                    {
                      !authErrors ? (
                        <>
                          <StyledTypography>{t('login.set_password')}</StyledTypography>
                          <PasswordMessageTypography>
                            {t('login.password_rules')}
                          </PasswordMessageTypography>
                        </>
                      ) : (
                        <>
                          <StyledTypography>{t('login.wrong_reset_token_title')}</StyledTypography>
                          <StyledTypography dangerouslySetInnerHTML={{__html: t('login.wrong_reset_token_content', {link: requestLink, interpolation: {escapeValue: false}})}}/>
                        </>
                      )
                    }

                    <StyledTextField
                      fullWidth
                      variant='outlined'
                      label={t('login.email')}
                      name='email'
                      disabled
                      value={email || ''}
                      margin='dense'
                    />
                    <StyledPasswordField
                      fullWidth
                      variant='outlined'
                      error={!!(errors.password && touched.password)}
                      label={t('login.password')}
                      name='password'
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.password && touched.password && t(`login.${errors.password}`)
                      }
                      margin='dense'
                    />
                    <PasswordStrengthBar
                      minLength={8}
                      shortScoreWord={t('profile.new_password_too_short')}
                      scoreWords={[
                        t('profile.new_password_too_weak'),
                        t('profile.new_password_weak'),
                        t('profile.new_password_ok'),
                        t('profile.new_password_good'),
                        t('profile.new_password_strong'),
                      ]}
                      password={values.password} />
                    <StyledPasswordField
                      fullWidth
                      variant='outlined'
                      error={!!(errors.confirmPassword && touched.confirmPassword)}
                      label={t('login.confirm_password')}
                      name='confirmPassword'
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.confirmPassword &&
												touched.confirmPassword &&
												t(`login.${errors.confirmPassword}`)
                      }
                      margin='dense'
                    />
                    {
                      showOtp && <StyledTextField
                        fullWidth
                        type='number'
                        variant='outlined'
                        error={authErrors?.error}
                        label={t('sign-in.otp')}
                        name='otp'
                        value={values.otp}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          authErrors?.errors?.otp && t(`sign-in.${authErrors?.errors?.otp}`)
                        }
                        margin='dense'
                        style={{ marginTop: '24px' }}
                      />
                    }
                  </StyledCardContent>
                  <StyledCardActions>
                    <Grid container direction='row'>
                      <StyledButton
                        type='submit'
                        disabled={!(values.password && values.confirmPassword)}
                      >
                        {t('login.button')}
                      </StyledButton>
                    </Grid>
                  </StyledCardActions>
                </form>
              </Fade>
            </StyledCard>
          </StyledGrid>
        </Slide>
      )}
    </>
  );
};

export default Login;
