import { createMuiTheme } from '@material-ui/core';
import { colors, fontSizes, ITheme } from './theme';

const branchCode: string = '7';

const extendedColors: ITheme = {
  ...colors,
  mainGreen: '#1E91D6',
  secondaryGreen: '#8FC93A',
  borderGreen: '#0072BB',
};

const extendedThemeOptions: { [key: string]: ITheme } = {
  colors: extendedColors,
  fontSizes,
  palette: {
    type: 'light',
  },
};

const themeExtended = createMuiTheme(extendedThemeOptions);

export { themeExtended, branchCode };
