import React, {useCallback, useEffect, useState} from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import {useDispatch, useSelector} from 'react-redux';
import { InputAdornment, TextField, Grid, Divider, ClickAwayListener } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';

import {
  priceTypeSelector,
  selectAddressId, selectErpCustomerAddressCode, selectIsHidePrice,
  selectLanguage,
} from 'ducks/application/selectors';
import {useProductVariantSearch, useProductVariantSearchBySKU} from 'api/ProductVariant';
import { useDebounce } from 'hooks/useDebounce';
import { ProductVariantClass } from 'models/classes/productVariantClass';
import defaultProduct from 'assets/images/default-product.png';
import ProductListLoading from 'pages/Product/ProductListLoading/ProductListLoading';
import { usePermission } from 'hooks/usePermission';
import {IQuickOrderItem} from 'ducks/quickOrder/types';
import {addItemToQuickOrder, clearSearchTerm, replaceQuickOrderItem} from 'ducks/quickOrder/actions';
import {selectClearSearchTerm, selectQuickOrderItems} from 'ducks/quickOrder/selectors';
import get from 'lodash/get';
import {useLanguage} from 'hooks/useLanguage';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectBranch } from 'ducks/branch/selectors';
import { selectUserData } from 'ducks/user/selectors';
import {StyledAvailableAndLeadTime} from '../../Product/ProductTableView/ProductTableCard/styles';
import {
  AvailableGrid,
  Measure,
  NotAvailableGrid,
  PriceGrid, SearchItemSku,
  SearchWrapper,
  StyledDiv,
  StyledIconButton,
  StyledLi,
  StyledLink,
  StyledUl,
  TextWrapperGrid,
  TitleGrid,
  WrapperDiv,
} from './styles';

const SearchBox = withStyles({
  root: {
    width: '100%',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    boxSizing: 'border-box',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.01em',
    color: '#939599',
    '& label.Mui-focused': {
      color: '#00853e',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#00853e',
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#C4C4C4',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00853e',
        },
      },
    },
  },
})(TextField);

type Props = {
  isCurrent?: boolean;
  currentQuickOrderItem?: IQuickOrderItem;
  handleOuterClick?: React.Dispatch<React.SetStateAction<boolean>>;
  isSearchBySku: boolean;
}

let data;

const SearchComponent = ({handleOuterClick, isSearchBySku, isCurrent, currentQuickOrderItem}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedAddressId = useSelector(selectAddressId);
  const currentLanguage = useLanguage();
  const { canSeePrice, canSeeInventory } = usePermission();
  const selectedLanguage = useSelector(selectLanguage);
  const isHidePrice = useSelector(selectIsHidePrice);
  const priceType = useSelector(priceTypeSelector);
  const quickOrderItems = useSelector(selectQuickOrderItems);
  const isClearSearchTerm = useSelector(selectClearSearchTerm);
  const branch = useSelector(selectBranch);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const user = useSelector(selectUserData);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchVal, setSearchVal] = useState<string>('');

  useEffect(() => {
    if (isClearSearchTerm) {
      setSearchVal('');
    }
    return () => {
      dispatch(clearSearchTerm({value: false}));
    }
  }, [dispatch, isClearSearchTerm]);

  const handleClear = () => {
    setSearchVal('');
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const debouncedSearchTerm = useDebounce(searchVal, 1000);

  if (isSearchBySku) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const searchBySKUData = useProductVariantSearchBySKU({
      addressId: selectedAddressId,
      language: selectedLanguage,
      searchVal: debouncedSearchTerm,
      openSearchDropdown: setOpen,
      onChangeLoading: setLoading,
    });
    data = searchBySKUData.data;
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const searchByNameData = useProductVariantSearch({
      addressId: selectedAddressId,
      language: selectedLanguage,
      searchVal: debouncedSearchTerm,
      openSearchDropdown: setOpen,
      onChangeLoading: setLoading,
    });
    data = searchByNameData.data;
  }

  const handleEventPickProduct = useCallback((productVar: ProductVariantClass) => {
    const quickOrderProductIds = quickOrderItems.map(item => item.productId);
    if (quickOrderProductIds.includes(productVar.id)) {
      return;
    }
    clearGtmEcommerce();
    if (!isCurrent) {
      dispatch(addItemToQuickOrder({
        productId: productVar.id,
        categoryId: productVar.category.id,
      }));
      TagManager.dataLayer({
        dataLayer: {
          event: 'quick_order_add_item',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
    } else {
      dispatch(replaceQuickOrderItem({
        quickOrderItemId: currentQuickOrderItem.id,
        productId: productVar.id,
        categoryId: productVar.category.id,
      }));
      TagManager.dataLayer({
        dataLayer: {
          event: isSearchBySku ? 'quick_order_change_sku': 'quick_order_change_name',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
    }
    setSearchVal('');
    handleClose();
    dispatch(clearSearchTerm({value: true}));
  }, [currentQuickOrderItem, dispatch, isCurrent, quickOrderItems, branch, user, erpCustomerAddressCode, selectedAddressId]);

  const handleFocus = () => {
    setOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchVal(event.target.value as string);
  };

  const renderProductStock = useCallback((variant: ProductVariantClass) => {
    if (!variant.inventory) return null;
    if (variant.newInventory?.onHand > 0) {
      return (
        <StyledAvailableAndLeadTime>
          {t('products_and_search.available_and_lead_time', {
            onHand: variant.newInventory.onHand,
            leadTime: variant.newInventory.leadTime,
          }).toString()}
        </StyledAvailableAndLeadTime>
      );
    }
    return variant.inventory.isStocked ||
    !variant.inventory.status.specialOrderString ? (
        <>
          <AvailableGrid
            className={`${variant.inventory.status.waitingOnStock ? 'waiting-on-stock' : ''}`}
            item
          >
            {canSeeInventory
              ? variant.inventory.status.status
              : variant.inventory.status.origin}
          </AvailableGrid>
        </>
      ) : (
        <>
          <NotAvailableGrid item>
            {canSeeInventory
              ? variant.inventory.status.status
              : variant.inventory.status.origin}
          </NotAvailableGrid>
        </>
      )
  }, [canSeeInventory, t]);

  const renderPrice = (variant: ProductVariantClass) => {
    const isPrice = Boolean(variant.prices.length);

    if (!isHidePrice && canSeePrice) {
      return (
        <PriceGrid isPrice={isPrice && priceType !== 'hide'} item>
          {isPrice && variant.prices[0][priceType] > 0 && priceType !== 'hide' ? (
            <>
              ${variant.prices[0][priceType]}
              <Measure> /{currentLanguage === 'en' ? variant.units.perNameEn : variant.units.perNameFr}</Measure>
            </>
          ) : (
            <>{t('products_and_search.call_for_pricing')}</>
          )}
        </PriceGrid>
      );
    }
    if (isHidePrice && canSeePrice) {
      return (
        <PriceGrid isPrice={false} item>
          {t('products_and_search.call_for_pricing').toString()}
        </PriceGrid>
      );
    }

    return null;
  };

  const filterExistsQuickOrderProducts = useCallback((searchProducts: Array<ProductVariantClass>) => {
    return searchProducts.map((product: ProductVariantClass) => {
      const quickOrderItemsProductIds = quickOrderItems.map(item => item.productId);
      return !quickOrderItemsProductIds.includes(product.id) ? product : null;
    }).filter(v => v);
  }, [quickOrderItems]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <SearchWrapper>
        <SearchBox
          data-test-id={isSearchBySku ? 'sku-search' : 'product-name-search'}
          variant="outlined"
          placeholder={t('search.placeholder')}
          onChange={handleChange}
          autoFocus
          onFocus={handleFocus}
          onBlur={() => handleOuterClick(false)}
          value={searchVal}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="disabled" />
              </InputAdornment>
            ),
            endAdornment: (
              <StyledIconButton onClick={handleClear} visible={searchVal.length} className="btn-clear">
                <ClearIcon />
              </StyledIconButton>
            ),
          }}
        />

        {!data?.data?.length && open && loading && (
          <StyledDiv>
            <WrapperDiv>
              <ProductListLoading />
            </WrapperDiv>
          </StyledDiv>
        )}

        {filterExistsQuickOrderProducts(get(data, 'data', [])).length && open ? (
          <StyledDiv className="search-dropdown">
            <WrapperDiv className="dropdown-wrapper">
              <StyledUl className="dropdown-menu">
                {filterExistsQuickOrderProducts(get(data, 'data', [])).map(variant => (
                  <React.Fragment key={variant.code}>
                    <StyledLi>
                      <StyledLink
                        to="#"
                        onClick={() => handleEventPickProduct(variant)}
                      >
                        <Grid key={variant.name} container>
                          <Grid item xs={2}>
                            <img
                              width={50}
                              height={50}
                              src={variant.image ?? defaultProduct}
                              alt="product"
                            />
                          </Grid>
                          <TextWrapperGrid item xs={10} container direction="column">
                            <TitleGrid item>{variant.name}</TitleGrid>
                            <SearchItemSku>{t('quick_order.sku').toString()}#: {variant.erpSku}</SearchItemSku>
                            {renderProductStock(variant)}
                            {renderPrice(variant)}
                          </TextWrapperGrid>
                        </Grid>
                      </StyledLink>
                    </StyledLi>
                    <Divider />
                  </React.Fragment>
                ))}
              </StyledUl>
            </WrapperDiv>
          </StyledDiv>
        ) : null}
      </SearchWrapper>
    </ClickAwayListener>
  );
};

export default SearchComponent;
