import { UserClass } from 'models/classes/userClass';
import { IContact } from 'models/interfaces/contacts';
import { IAddress } from 'models/interfaces/user';
import i18next from 'i18next';
import { IShippingAddress } from 'models/interfaces/shippingAddress';
import {REACT_APP_INTERNAL_EMAIL_PREFIX} from 'assets/constants/application';

export const getAddressString = (address: IAddress|IShippingAddress): string => {
  if (address?.name) {
    return address.name;
  }
  return [
    address?.customer?.erpName,
    address?.erpAddress1,
    address?.erpCity,
    address?.erpPostCode
  ].filter(adr => adr).join(', ');
};

export const getAddressStringOrder = (address: IAddress|IShippingAddress): string => [
  address.customer.erpName,
  address.erpAddress1,
  address.erpCity,
  address.erpPostCode
].filter(adr => adr).join(', ');

export const getValue = (filter: Array<string>, user: UserClass, contacts: IContact[]) => {
  switch (filter[0]) {
    case 'address':
      return getAddressString(user.addresses.find(item => String(item.id) === String(filter[1])));
    case 'contact': {
      const contact = contacts.find(item => item.email === filter[1]);

      return contact ? contact.fullName : i18next.t('products_and_search.offlineUsers');
    }
    case 'dateRange':
    case 'input':
      return filter[1];
    default:
      return i18next.t(
        `orders_and_quotes.${filter[1]
          .toLowerCase()
          .split(' ')
          .join('_')}`
      );
  }
};

export const getUserType = (email: string): number => {
  let internalEmailPrefix: any = REACT_APP_INTERNAL_EMAIL_PREFIX;
  internalEmailPrefix = internalEmailPrefix.split(',');
  let userType = 1;
  for (let i = 0; i < internalEmailPrefix.length; i+=1) {
    if (email?.includes(internalEmailPrefix[i])) {
      userType = 2;
      break;
    }
  }
  return userType;
}
