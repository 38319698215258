export enum UIActionTypes {
  TOGGLE_SNACKBAR_OPEN = '@@ui/TOGGLE_SNACKBAR_OPEN',
  TOGGLE_SNACKBAR_CLOSE = '@@ui/TOGGLE_SNACKBAR_CLOSE',
}

export type SnackbarType = 'success' | 'error';

export interface SnackbarState {
  open: boolean;
  type: SnackbarType;
  message: null | string;
}
