import React from 'react';

export const WarningIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="9.5" stroke="#939599" />
      <path
        d="M8.72559 5.38184C8.72559 4.7028 9.10384 4.36328 9.86035 4.36328C10.6169 4.36328 10.9951 4.7028 10.9951 5.38184C10.9951 5.7054 10.8994 5.95833 10.708 6.14062C10.5212 6.31836 10.2386 6.40723 9.86035 6.40723C9.10384 6.40723 8.72559 6.06543 8.72559 5.38184ZM10.8994 15H8.81445V8.35742H10.8994V15Z"
        fill="#939599"
      />
    </svg>
  );
};

export const NotesIcon = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M5.0625 4.77344C4.6485 4.77344 4.3125 4.43744 4.3125 4.02344V1.39844C4.3125 0.984438 4.6485 0.648438 5.0625 0.648438C5.4765 0.648438 5.8125 0.984438 5.8125 1.39844V4.02344C5.8125 4.43744 5.4765 4.77344 5.0625 4.77344Z"
          fill="#939599"
        />
        <path
          d="M8.62501 4.77344C8.211 4.77344 7.875 4.43744 7.875 4.02344V1.39844C7.875 0.984438 8.211 0.648438 8.62501 0.648438C9.03901 0.648438 9.37501 0.984438 9.37501 1.39844V4.02344C9.37501 4.43744 9.03901 4.77344 8.62501 4.77344Z"
          fill="#939599"
        />
        <path
          d="M12.1875 4.77344C11.7735 4.77344 11.4375 4.43744 11.4375 4.02344V1.39844C11.4375 0.984438 11.7735 0.648438 12.1875 0.648438C12.6015 0.648438 12.9375 0.984438 12.9375 1.39844V4.02344C12.9375 4.43744 12.6015 4.77344 12.1875 4.77344Z"
          fill="#939599"
        />
        <path
          d="M13.6875 2.33594H3.5625C2.42475 2.33594 1.5 3.26069 1.5 4.39844V16.5859C1.5 17.7237 2.42475 18.6484 3.5625 18.6484H13.6875C14.8252 18.6484 15.75 17.7237 15.75 16.5859V4.39844C15.75 3.26069 14.8252 2.33594 13.6875 2.33594ZM5.25 6.64844H9C9.414 6.64844 9.75 6.98444 9.75 7.39844C9.75 7.81244 9.414 8.14844 9 8.14844H5.25C4.836 8.14844 4.5 7.81244 4.5 7.39844C4.5 6.98444 4.836 6.64844 5.25 6.64844ZM12.75 14.1484H5.25C4.836 14.1484 4.5 13.8124 4.5 13.3984C4.5 12.9844 4.836 12.6484 5.25 12.6484H12.75C13.164 12.6484 13.5 12.9844 13.5 13.3984C13.5 13.8124 13.164 14.1484 12.75 14.1484ZM12.75 11.1484H5.25C4.836 11.1484 4.5 10.8124 4.5 10.3984C4.5 9.98444 4.836 9.64844 5.25 9.64844H12.75C13.164 9.64844 13.5 9.98444 13.5 10.3984C13.5 10.8124 13.164 11.1484 12.75 11.1484Z"
          fill="#939599"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="18" height="18" fill="white" transform="translate(0 0.648438)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const OrderItemCheckIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM7 14L2 9.19231L3.4 7.84615L7 11.3077L14.6 4L16 5.34615L7 14Z"
      fill="#337D58"
    />
  </svg>
);
