import { createSelector } from 'reselect';
import { RootState } from 'store';

const selectAuthState = (state: RootState) => state.auth;

export const selectUser = createSelector(selectAuthState, authState => authState.user);
export const authErrorsSelector = createSelector(selectAuthState, authState => authState.error);
export const getLoading = createSelector(selectAuthState, authState => authState.loading);
export const authenticatedSelector = createSelector(
  selectAuthState,
  authState => authState.authenticated,
);
export const selectPasswordRequestSent = createSelector(
  selectAuthState,
  authState => authState.resetPasswordRequestSent,
);
export const getRememberMe = createSelector(selectAuthState, authState => authState.remembered);
export const selectShowOtp = createSelector(
  selectAuthState,
  authState => authState.showOtp,
);
export const selectCheckedOtp = createSelector(
  selectAuthState,
  authState => authState.checkedOtp,
);
export const selectIsDisplayLanguageChanged = createSelector(
  selectAuthState,
  authState => authState.isChangedDisplayLanguage,
);

export const selectIsRefreshToken = createSelector(selectAuthState, authState => authState.isRefreshToken);
export const selectCreateMagicLinkLoading = createSelector(
  selectAuthState,
  authState => authState.createMagicLinkLoading,
);
export const selectCreateMagicLinkStatus = createSelector(
  selectAuthState,
  authState => authState.createMagicLinkStatus,
);
export const selectCreateMagicLinkErrorMessages = createSelector(
  selectAuthState,
  authState => authState.createMagicLinkErrorMessages,
);
export const selectLoginByMagicLinkLoading = createSelector(
  selectAuthState,
  authState => authState.loginByMagicLinkLoading,
);
export const selectLoginByMagicLinkErrorMessages = createSelector(
  selectAuthState,
  authState => authState.loginByMagicLinkErrorMessage,
);
export const selectLoginByMagicLinkStatus = createSelector(
  selectAuthState,
  authState => authState.loginByMagicLinkStatus,
);