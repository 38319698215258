import {
  IOptionValue,
  IProductImage,
  IDocument,
  IPrice,
  IMergedAttributes,
  IUnits,
  IInventory,
  IProductAssociation,
  IProductResizeImage, INearbyHubs, INearbyBranches, INewInventory,
} from 'models/interfaces/productVariant';
import { IMainCategory, ITaxon } from 'models/interfaces/product';
import { IAddressPrice } from 'models/interfaces/order';
import {IDefaultLanguage} from '../interfaces/category';

export class ProductVariantClass {
  id: number = null;

  name: string = null;

  nameEn: string = null;

  nameFr: string = null;

  productCode: string = null;

  inventory: IInventory = null;

  image: string = null;

  images: IProductImage[] = null;

  addressPrices: IAddressPrice[];

  isOfflineProductVariant: boolean = null;

  resizeImages: IProductResizeImage[] = null;

  videoUrl: string = null;

  price: number = null;

  prices: IPrice[] = null;

  pricingMethod: string = null;

  documents: IDocument[] = null;

  optionValues: IOptionValue[] = null;

  code: number|string = null;

  mergedAttributes: IMergedAttributes[] = null;

  description: string = null;

  shortDescription: string = null;

  units: IUnits = null;

  associations: IProductAssociation = null;

  mainTaxon: ITaxon = null;

  category: IMainCategory = null;

  isDocumentExists: boolean = null;

  erpSku: string = null;

  erpProductId: number = null;

  branchName: string = null;

  nearbyHubs: INearbyHubs[];

  nearbyBranches: INearbyBranches;

  defaultLanguage: IDefaultLanguage;

  newInventory: INewInventory;

  uom1ToUom2Conversion: string;

  sysWeight: number | null = null;

  constructor(partial: Partial<ProductVariantClass>) {
	  Object.assign(this, partial);
  }
}
