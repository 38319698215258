import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import ReactGA from 'react-ga';
import axios from 'utils/axios';

// Types

import {
  RequestPatchProfileAction,
  UserActionTypes,
  RequestCreateUserProfileAction,
  RequestCreateCustomerAction,
  RequestSubscriberAction,
} from 'ducks/user/types';
import { storeError } from 'utils/errors';
import { DEFAULT_PHONE_NUMBER } from 'assets/constants/user';
import { setDefaultPhoneNumber } from 'ducks/user/actions';
import { UserClass } from 'models/classes/userClass';
import { AppStorage } from 'components/App/services/storage';
import {setAddressCode, setAddressId, setPriceType} from 'ducks/application/actions';

function* getStorePhoneNumber() {
  try {
    const response = yield call(axios.get, '/api/nearest-store');
    const storePhoneNumber = response.data.data.phoneNumber;
    yield put({
      type: UserActionTypes.REQUEST_STORE_PHONE_NUMBER_SUCCESS,
      payload: { storePhoneNumber },
    });
  } catch (error) {
    yield put(setDefaultPhoneNumber(DEFAULT_PHONE_NUMBER));
    yield storeError(UserActionTypes.REQUEST_STORE_PHONE_NUMBER_ERROR, [404, 422], error.response);
  }
}

function* fetchProfile() {
  try {
    const { data: userResponse } = yield call(axios.get, '/api/auth/profile');
    const user = new UserClass(userResponse);
    const defaultAddress = AppStorage.getDefaultAddressId();
    if (defaultAddress) {
      const checkDefaultAddress = user.addresses.find(adr => adr.id === defaultAddress);
      if (!checkDefaultAddress) {
        AppStorage.removeDefaultAddressId();
      }
    }
    const defaultAddressCode = AppStorage.getDefaultAddressCode();
    if (defaultAddressCode) {
      const checkDefaultAddressCode = user.addresses.find(adr => adr.erpPostCode === defaultAddressCode);
      if (!checkDefaultAddressCode) {
        AppStorage.removeDefaultAddressCode();
      }
    }
    if (user.defaultAddress) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !defaultAddress && AppStorage.setDefaultAddressId(user.defaultAddress.id);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !defaultAddressCode && AppStorage.setDefaultAddressCode(user.defaultAddress.erpPostCode);
      yield !defaultAddress ? put(setAddressCode(user.defaultAddress.erpPostCode)) : put(setAddressCode(defaultAddressCode));
      yield !defaultAddressCode ? put(setAddressId(user.defaultAddress.id)) : put(setAddressId(defaultAddress));
    } else if (user.addresses.length) {
      yield put(setAddressCode(user.addresses[0].erpPostCode));
      yield put(setAddressId(user.addresses[0].id));
    }
    yield put(setPriceType(user.defaultPricing));
    AppStorage.setDeliveryType(user.defaultPricing);
    yield put({ type: UserActionTypes.REQUEST_FETCH_PROFILE_SUCCESS, payload: { user } });

    if (userResponse) {
      ReactGA.set({
        userId: userResponse.email,
      });
    }
  } catch (error) {
    if (error) {
      yield storeError(UserActionTypes.REQUEST_FETCH_PROFILE_ERROR, [401], error.response);
    }
  }
}

function* patchProfile(patchProfileAction: RequestPatchProfileAction) {
  const userPatchParams = { ...patchProfileAction.payload.data };

  try {
    yield call(axios.put, '/api/auth/profile/update', userPatchParams);
    yield put({ type: UserActionTypes.REQUEST_PATCH_PROFILE_SUCCESS });
    yield fetchProfile();
  } catch (error) {
    yield storeError(UserActionTypes.REQUEST_FETCH_PROFILE_ERROR, [204, 400, 401], error.response);
  }
}

function* createUserProfile(requestCreateUserAction: RequestCreateUserProfileAction) {
  try {
    yield call(axios.post, '/api/auth/register', requestCreateUserAction.payload);
    yield put({ type: UserActionTypes.REQUEST_CREATE_USER_PROFILE_SUCCESS });
    yield put({ type: UserActionTypes.REQUEST_CREATE_USER_PROFILE_CLOSE_RECAPTCHA_MODAL });
  } catch (error) {
    if (error.response.data.message === 'Validate recaptcha error!') {
      yield put({ type: UserActionTypes.REQUEST_CREATE_USER_PROFILE_OPEN_RECAPTCHA_MODAL });
    } else {
      yield storeError(UserActionTypes.REQUEST_CREATE_USER_PROFILE_ERROR, [201, 400, 422], error.response);
    }
  }
}

function* createNewCustomer(requestCreateCustomerAction: RequestCreateCustomerAction) {
  try {
    yield call(axios.post, '/new-api/send-prospect-email', requestCreateCustomerAction.payload);
    yield put({ type: UserActionTypes.REQUEST_CREATE_CUSTOMER_SUCCESS });
  } catch (error) {
    yield storeError(
      UserActionTypes.REQUEST_CREATE_CUSTOMER_ERROR,
      [201, 400, 422],
      error.response,
    );
  }
}

function* createSubscriber(requestCreateSubscriberAction: RequestSubscriberAction) {
  try {
    yield call(axios.post, '/api/add-subscriber', requestCreateSubscriberAction.payload);
    yield put({ type: UserActionTypes.REQUEST_CREATE_SUBSCRIBER_SUCCESS });
    yield put({ type: UserActionTypes.REQUEST_CREATE_SUBSCRIBER_CLOSE_RECAPTCHA_MODAL });
  } catch (error) {
    if (error.response.data.message === 'Validate recaptcha error!') {
      yield put({ type: UserActionTypes.REQUEST_CREATE_SUBSCRIBER_OPEN_RECAPTCHA_MODAL });
    } else {
      yield storeError(UserActionTypes.REQUEST_CREATE_SUBSCRIBER_ERROR, [201, 400], error.response);
    }
  }
}

function* watchCreateUserProfile() {
  yield takeEvery(UserActionTypes.REQUEST_CREATE_USER_PROFILE, createUserProfile);
}

function* watchCreateNewCustomer() {
  yield takeEvery(UserActionTypes.REQUEST_CREATE_CUSTOMER, createNewCustomer);
}

function* watchSubscriber() {
  yield takeLatest(UserActionTypes.REQUEST_CREATE_SUBSCRIBER, createSubscriber);
}

function* watchGetStorePhoneNumber() {
  yield takeEvery(UserActionTypes.REQUEST_STORE_PHONE_NUMBER, getStorePhoneNumber);
}

function* watchFetchProfile() {
  yield takeEvery(UserActionTypes.REQUEST_FETCH_PROFILE, fetchProfile);
}

function* watchPatchProfile() {
  yield takeEvery(UserActionTypes.REQUEST_PATCH_PROFILE, patchProfile);
}

export default function* userSaga() {
  yield all([
    watchGetStorePhoneNumber(),
    watchFetchProfile(),
    watchPatchProfile(),
    watchCreateUserProfile(),
    watchCreateNewCustomer(),
    watchSubscriber(),
  ]);
}
