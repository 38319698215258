import React from 'react';

export const LinkedInIcon = () => {
  return (
    <svg height="32" width="38" viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.2171 0H2.32558C1.04651 0 0 1.0155 0 2.27132V29.2713C0 30.5271 1.04651 31.5504 2.32558 31.5504H29.2171C30.5039 31.5504 31.5504 30.5271 31.5504 29.2713V2.27132C31.5504 1.0155 30.5039 0 29.2171 0ZM9.3566 26.8833V11.829H4.67444V26.8833H9.3566ZM9.7287 7.0538C9.7287 5.55768 8.51164 4.34062 7.01552 4.34062C5.51165 4.34062 4.30234 5.55768 4.30234 7.0538C4.30234 8.54993 5.51165 9.76698 7.01552 9.76698C8.51164 9.76698 9.7287 8.54993 9.7287 7.0538ZM16.783 11.8283H12.2947H12.2869V26.8826H16.9613V19.433C16.9613 17.464 17.3334 15.5648 19.7675 15.5648C22.1706 15.5648 22.2016 17.8128 22.2016 19.557V26.8826H26.8838V18.6268C26.8838 14.5725 26.0078 11.4563 21.2714 11.4563C18.9923 11.4563 17.4652 12.7043 16.8451 13.8904H16.783V11.8283ZM35.3257 26.4C34.0854 26.4 33.1164 27.369 33.1164 28.6016C33.1164 29.8419 34.0854 30.8031 35.3257 30.8031C36.5583 30.8031 37.535 29.8341 37.535 28.6016C37.5273 27.369 36.5583 26.4 35.3257 26.4ZM35.3257 30.524C34.2715 30.524 33.442 29.6868 33.442 28.6093C33.442 27.524 34.2715 26.6946 35.3257 26.6946C36.38 26.6946 37.2094 27.5318 37.2094 28.6093C37.2094 29.6868 36.38 30.524 35.3257 30.524ZM35.6745 28.7243C36.0699 28.6778 36.2714 28.4608 36.2714 28.0964C36.2714 27.6546 36.0001 27.4375 35.4497 27.4375H34.566V29.7708H34.8994V28.7553H35.3102L35.318 28.7708L35.9614 29.7708H36.318L35.628 28.7321L35.6745 28.7243ZM35.287 28.4918H34.8994V27.7011H35.3877C35.6435 27.7011 35.9304 27.7398 35.9304 28.0732C35.9381 28.4608 35.6435 28.4918 35.287 28.4918Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="37.6" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
