import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const MediumButton = withStyles({
  root: {
    textAlign: 'center',
    width: '252px',
    height: '48px',
    backgroundColor: '#00853e',
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    letterSpacing: '0.01em',
    fontWeight: 'bold',
    color: '#FFFFFF',
    '&$disabled': {
      color: '#FFFFFF',
    },
    '&:hover': {
      backgroundColor: '#009650',
    },
    '@media (max-width: 768px)': {
      width: '240px',
    },
    '@media (max-width: 320px)': {
      width: '100%',
    },
  },
  disabled: {
    backgroundColor: '#DADCE0',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);
