const USER_ACCOUNT_PATH = '/account';

const Routes = {
  HOME: '/',
  LOGIN: '/login',
  SIGN_IN: '/sign-in',
  RESET_PASSWORD: '/reset-password',
  USER_ACCOUNT_UPDATE_PASSWORD: `${USER_ACCOUNT_PATH}/update-password`,
  NOT_AUTHORIZED: '/not-authorized',
  ERROR_500: '/500-error',
  MAINTENANCE: '/maintenance',
  CATEGORIES: '/categories',
  SUBCATEGORIES: '/categories/:id',
  SUBCATEGORIES_LEVEL2: '/categories/:id/:sub_id',
  SUBCATEGORY_PRODUCTS: '/categories/:id/:code',
  ACCOUNT: '/account',
  CART: '/cart',
  NOT_FOUND: '/not-found',
  SEARCH: '/search',
  EMPTY_SEARCH: '/search?q=',
  PRODUCT_DETAIL: '/product/:code',
  CONTACT_US: '/contact-us',
  FAQ: '/faq',
  COLOUR_DISCLAIMER: '/colour-disclaimer',
  CHECKOUT: '/checkout',
  PROFILE: '/profile',
  PRIVACY_POLICY: '/privacy',
  TERMS_OF_SERVICE: '/terms-of-service',
  ADDRESS_MISSING: '/address-missing',
  SPECIAL: '/specials',
  QUICK_ORDER: '/quick-order',
  DOCUMENT_GROUP: '/document-group',
  UNSUBSCRIBE_ORDER: '/unsubscribe-order',
};


export default Routes;
