import styled from 'styled-components/macro';
import { Link as RouterLink } from 'react-router-dom';
import {
  Dialog,
  Button,
  DialogContent,
  TextField,
  FormControl,
  DialogTitle,
  DialogContentText,
  MenuItem,
  DialogActions,
  IconButton,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MediumButton } from 'components/common/Buttons/MediumButton';

const StylesDialog = styled(Dialog)`
  /* z-index: 10000 !important; */
  .MuiPaper-root {
    max-width: 700px;
    background: rgba(0, 0, 0, 0.8);
    @media (max-width: 767px) {
      width: 100%;
      max-width: 100%;
      height: 100vh;
      max-height: 100vh;
      margin: 0;
    }
  }
`;

const StylesDialogContent = styled(DialogContent)`
  position: relative;
  padding: 0;
  @media (max-width: 767px) {
    padding: 0 15px;
  }
  .btn-close {
    position: absolute;
    right: 10px;
    top: 16px;
    color: #939599;
    border: 0;
    background: none;
    cursor: pointer;
    transition: all 0.4s ease;
    &:hover {
      color: #333;
    }
  }
`;

const FirstScreenFormContainer = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  flex-shrink: 0;
  padding-bottom: 45px;

  @media (max-width: 767px) {
    width: 100%;
    padding-bottom: 24px;
  }
`;

const FirstScreenFormHeader = styled.h2`
  font-family: Open Sans, serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 54px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.white};
  padding: 0;
  margin-top: 30px;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    margin: 40px 20px 32px 20px;
    font-size: 29px;
    line-height: 38px;
    text-align: center;
  }

  @media (max-width: 560px) {
    font-size: 26px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

const FirstScreenFormInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 8px;
  padding: 0 29px;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  @media (max-width: 480px) {
    padding: 0 10px;
    margin-bottom: 0;
  }
`;

const firstNameOrder = {
  order: 1,
};

const lastNameOrder = {
  order: 2,
};

const emailOrder = {
  order: 3,
};

const companyOrder = {
  order: 4,
};

const branchOrder = {
  order: 5,
};

const salesOrder = {
  order: 6,
};

const StyledTextField = withStyles({
  root: {
    fontFamily: 'Open Sans',
    width: '300px',
    marginBottom: '19px',
    height: '62px',
    borderRadius: '4px',
    '& .MuiInputBase-root': {
      fontFamily: 'Open Sans',
      height: '47px',
      backgroundColor: 'rgba(255, 255, 255)',
      '&:hover': {
        borderColor: '#FFFFFF',
      },
      '& fieldset legend': {
        fontSize: '4px',
      },
      '& .MuiOutlinedInput-input': {
        padding: '14px',
      },
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#E74659',
        },
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
        borderColor: '#FFFFFF',
      },
      fieldset: {
        borderColor: '#FFFFFF',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FFFFFF',
      },
    },
    '& .MuiFormLabel-root': {
      color: '#545353',
      fontFamily: 'Open Sans',
      lineHeight: '16px',
      letterSpacing: '0.01em',

      marginTop: '-4px',
      '&.Mui-error': {
        '&.Mui-focused': {
          color: '#E74659',
        },
      },
      '&.Mui-focused, &.MuiFormLabel-filled': {
        background: '#333333',
        color: '#FFFFFF',
        borderRadius: '4px',
        padding: '4px',
        left: '-5px',
        fontSize: '14px',
      },
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Open Sans',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.01em',
      marginTop: '0px',
      marginLeft: '0px',
      '&.Mui-error': {
        color: '#E74659',
      },
    },
    '@media (max-width: 767px)': {
      width: 'auto',
      marginBottom: '11px',
    },
  },
})(TextField);

const StyledFormControl = withStyles({
  root: {
    fontFamily: 'Open Sans',
    width: '300px',
    height: '62px',
    textAlign: 'left',
    marginBottom: '19px',
    borderRadius: '4px',
    '& .MuiInputBase-root': {
      fontFamily: 'Open Sans',
      height: '47px',
      backgroundColor: 'rgba(255, 255, 255)',
      '& fieldset legend': {
        fontSize: '4px',
      },
      '&:hover': {
        borderColor: '#FFFFFF',
      },
      '& .MuiOutlinedInput-input': {
        padding: '14px',
      },
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#E74659',
        },
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
        borderColor: '#FFFFFF',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FFFFFF',
      },
      '& .MuiOutlinedInput-notchedOutline PrivateNotchedOutline-legendLabelled-8': {
        fontSize: '5px',
      },
    },
    '& .MuiFormLabel-root': {
      color: '#545353',
      fontFamily: 'Open Sans',
      lineHeight: '16px',
      letterSpacing: '0.01em',
      marginTop: '-4px',
      '&.Mui-error': {
        '&.Mui-focused': {
          color: '#E74659',
        },
      },
      '&.Mui-focused, &.MuiFormLabel-filled': {
        background: '#333333',
        color: '#FFFFFF',
        borderRadius: '4px',
        padding: '4px',
        left: '-5px',
        fontSize: '14px',
      },
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Open Sans',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.01em',
      marginTop: '0px',
      marginLeft: '0px',
      '&.Mui-error': {
        color: '#E74659',
      },
    },
    '@media (max-width: 767px)': {
      width: '100%',
      marginBottom: '11px',
    },
  },
})(FormControl);

const FirstScreenSubmitButton = withStyles({
  root: {
    height: '47px',
    width: '300px',
    borderRadius: '4px',
    backgroundColor: '#337d58',
    marginBottom: '0',
    '&:hover': {
      backgroundColor: '#37875F',
      '&.Mui-disabled': {
        backgroundColor: '#009650',
        opacity: '50%',
      },
    },
    '@media (max-width: 767px)': {
      width: '100%',
      marginBottom: '10px',
    },
    '&.Mui-disabled': {
      backgroundColor: '#009650',
      opacity: '50%',
    },
  },
})(MediumButton);

const ButtonWrapper = styled.div`
  height: auto;
  width: 100%;
  padding: 0 30px;

  @media (max-width: 480px) {
    padding: 0 10px;
  }
`;

const StyledDialog = withStyles({
  paper: {
    margin: '0',
    padding: '48px',
    overflow: 'hidden',
    '@media (max-width: 767px)': {
      padding: '20px',
    },
  },
})(Dialog);

const StyledDialogTitle = withStyles({
  root: {
    margin: '0',
    padding: '0',
    fontFamily: 'Open Sans',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '33px',
    letterSpacing: '0.01em',
    '@media (max-width: 767px)': {
      fontSize: '18px',
      lineHeight: '25px',
      fontWeight: 700,
      marginRight: '8px',
    },
  },
})(DialogTitle);

const StyledDialogContent = withStyles({
  root: {
    margin: '0',
    padding: '0',
  },
})(DialogContent);

const StyledDialogContentText = withStyles({
  root: {
    margin: '32px 0',
    padding: '0',
    fontFamily: 'Open Sans',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '25px',
    letterSpacing: '0',
    '@media (max-width: 767px)': {
      fontWeight: 400,
      margin: '20px 0',
      maxWidth: '260px',
    },
  },
})(DialogContentText);

const MenuItemCust = withStyles({
  root: {
    '&.MuiMenuItem-root': {
      fontFamily: 'Open Sans',
    },
  },
})(MenuItem);

const StyledDialogActions = withStyles({
  root: {
    margin: '0',
    padding: '0',
    justifyContent: 'flex-start',
  },
})(DialogActions);

const StyledDialogButton = withStyles({
  root: {
    margin: '0',
    padding: '0',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.01em',
    color: '#00853e',
    textTransform: 'none',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
    '@media (max-width: 767px)': {
      fontSize: '14px',
      lineHeight: '19px',
    },
  },
})(Button);

const StyledIconButton = withStyles({
  root: {
    position: 'absolute',
    top: '4px',
    right: '4px',
    '@media (max-width: 767px)': {
      top: '-4px',
      right: '-4px',
    },
  },
})(IconButton);

const StyledTypography = styled(Typography)`
  padding-top: 16px;
  font-family: Open Sans, serif;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: center;
  width: 100%;
  color: #fff;
`;

const StyledRequestLink = styled(RouterLink)`
  color: #00853e;
  cursor: pointer;
  padding-bottom: 21px;
  font-family: Open Sans, serif;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-decoration: none;
  @media (max-width: 737px) {
    font-size: 14px;
    line-height: 19px;
  }
`;

export {
  StylesDialog,
  StylesDialogContent,
  FirstScreenFormContainer,
  FirstScreenFormHeader,
  FirstScreenFormInputsContainer,
  firstNameOrder,
  lastNameOrder,
  emailOrder,
  companyOrder,
  branchOrder,
  salesOrder,
  StyledTextField,
  StyledFormControl,
  FirstScreenSubmitButton,
  ButtonWrapper,
  StyledDialog,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogContentText,
  MenuItemCust,
  StyledDialogActions,
  StyledDialogButton,
  StyledIconButton,
  StyledTypography,
  StyledRequestLink,
};
