import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {ShippingMethod} from 'models/interfaces/order';

import {changeField} from 'ducks/checkout/actions';
import {changeCartDeliveryType} from 'ducks/order/actions';
import {selectCart, selectOrderId} from 'ducks/cart/selectors';
import {RadioBlockWrapper} from 'pages/Checkout/styles';
import {AppStorage} from 'components/App/services/storage';
import {checkCartItemsNoPriceAction} from 'ducks/cart/actions';
import {setPriceType} from 'ducks/application/actions';
import {priceTypeSelector} from 'ducks/application/selectors';
import {selectChangeCartDeliveryLoading, selectChangeCartDeliveryStatus} from 'ducks/order/selectors';
import {ShippingRadio} from './ShippingRadio';

const Shipping = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const selectedOrderId = useSelector(selectOrderId);
  const shippingMethod = useSelector(priceTypeSelector);
  const cart = useSelector(selectCart);
  const changeCartDeliveryTypeLoading = useSelector(selectChangeCartDeliveryLoading);
  const changeCartDeliveryStatus = useSelector(selectChangeCartDeliveryStatus);
  const prevShippingMethod = useRef<string|ShippingMethod>(cart.pricing);

  const updatePricing = (method) => {
    dispatch(changeField('shippingMethod', method));
    dispatch(
      changeCartDeliveryType({
        cartId: parseInt(selectedOrderId, 10),
        deliveryType: method,
      })
    );
    AppStorage.setDeliveryType(method);
  };

  const isDelivery = ShippingMethod.DELIVERY === shippingMethod;

  const setPricing = (pricingType: ShippingMethod) => {
    updatePricing(pricingType);
    dispatch(checkCartItemsNoPriceAction(cart.id, pricingType));
    dispatch(setPriceType(pricingType));
  }

  useEffect(() => {
    if (!changeCartDeliveryTypeLoading && changeCartDeliveryStatus === 'error') {
      dispatch(setPriceType(prevShippingMethod.current));
      dispatch(changeField('shippingMethod', prevShippingMethod.current));
    }
    if (changeCartDeliveryStatus === 'success') {
      prevShippingMethod.current = shippingMethod;
    }
  }, [dispatch, changeCartDeliveryTypeLoading, changeCartDeliveryStatus])

  return (
    <RadioBlockWrapper>
      <ShippingRadio
        label={t('checkout.delivery')}
        onClick={() => setPricing(ShippingMethod.DELIVERY)}
        isActive={isDelivery}
        disabled={false}
      />
      <ShippingRadio
        label={t('checkout.pick_up')}
        onClick={() => setPricing(ShippingMethod.PICKUP)}
        isActive={!isDelivery}
        disabled={false}
      />
    </RadioBlockWrapper>
  );
};

export default Shipping;
