import styled from 'styled-components/macro';

const SpecialPageWrapper = styled.div`
  padding: 36px 0 108px;
  @media only screen and (max-width: 1023px) {
    padding: 26px 0 70px;
  }
  @media only screen and (max-width: 767px) {
    padding: 10px 0 50px;
  }
  .MuiBreadcrumbs-root {
    margin-bottom: 32px;
    @media only screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
  .title-page-specials {
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: calc(46/32);
    letter-spacing: 0.01em;
    color: #333333;
    margin-bottom: 48px;
    @media only screen and (max-width: 767px) {
      font-size: 24px;
      margin-bottom: 12px;
    }
  }
`;

const SpecialLinks = styled.div`
  .item-special {
    display: flex;
    align-items: center;
    position: relative;
    background: #fff;
    border: 1px solid #c2cad6;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.1px;
    text-decoration: none;
    color: ${({ theme: { colors } }) => colors.mainGreen};
    padding: 16px 40px 16px 16px;
    margin-bottom: 8px;
    &:hover {
      svg {
        color: #007A33;
      }
    }
    &.external-link {
      svg {
        width: 22px;
        height: 22px;
      }
    }
    svg {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translate(0, -50%);
      width: 24px;
      height: 24px;
      color: #48576b;
    }
  }
`;

export {
  SpecialPageWrapper,
  SpecialLinks,
};
