import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Grid, Typography } from '@material-ui/core';
import styled, { ThemedStyledProps } from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const SearchWrapper = styled.div`
  width: 100%;
  padding: 7px 20px 8px;
  .MuiInputBase-root {
    height: 40px;
  }
  .MuiOutlinedInput-input {
    padding: 9.5px 14px 9.5px 0;
  }
`;

export const StyledGrid = styled(Grid)`
  padding: 24px;
`;

export const StyledTypography = styled(Typography)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
`;

export const ShowResultsGrid = styled(Grid)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.mainGreen};
`;

export const StyledLink = styled(Link)`
  width: 100%;
  text-decoration: none;
  padding: 6px 16px;
`;

export const Measure = styled(Typography)`
  font-family: Open Sans;
  color: ${({ theme: { colors } }) => colors.lightGreySearch};
  display: inline;
`;

export const TitleGrid = styled(Grid)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.searchDropdownText};
  margin-bottom: 5px;
  @media (max-width: 1023px) {
    -webkit-line-clamp: initial;
    -webkit-box-orient: initial;
  }
`;

export const AvailableGrid = styled(Grid)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.colors.mainGreen};
  padding-bottom: 5px;
  &.waiting-on-stock {
    color: ${props => props.theme.colors.waitingOnStockBlue};
  }
`;

export const NotAvailableGrid = styled(Grid)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.black};
  padding-bottom: 5px;
`;

interface IPriceGridProps extends ThemedStyledProps<object, HTMLDivElement> {
  isPrice: boolean;
}

export const PriceGrid = styled(Grid)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: ${(props: IPriceGridProps) => (props.isPrice ? '700' : '600')};
  font-size: ${(props: IPriceGridProps) => (props.isPrice ? '16' : '14')}px;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.mainGreen};
`;

export const TextWrapperGrid = styled(Grid)`
  padding-left: 10px;
`;

export const StyledIconButton = styled(({ visible, ...rest }) => <IconButton {...rest} />)`
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

export const StyledDiv = styled.div`
  position: relative;
  margin: 4px 0;
`;

export const WrapperDiv = styled.div`
  z-index: 1401;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px ${({ theme: { colors } }) => colors.blackOpacity(0.2)};
  border-radius: 6px;
  width: 100%;
  position: absolute;
  background-color: ${({ theme: { colors } }) => colors.white};
  @media (min-width: 1024px) and (max-width: 1200px) {
    min-width: 300px;
  }
  .loading-wrapper-list {
    height: 110px;
    > * {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border-width: 6px;
    }
  }
`;

export const StyledUl = styled.ul`
  overflow-y: auto;
  max-height: 547px;
  list-style: none;
  margin: 0;
  padding-left: 0;
  @media only screen and (max-width: 1200px) {
    max-height: 450px;
  }
  @media only screen and (max-width: 767px) {
    max-height: 377px;
  }
  @media only screen and (max-width: 599px) {
    max-height: 226px;
  }
  hr {
    position: relative;
    left: 10px;
    width: calc(100% - 20px);
  }
`;

export const StyledLi = styled.li`
  min-height: auto;
  cursor: pointer;
  display: flex;
  outline: 0;
  box-sizing: border-box;
  align-items: center;
  padding: 0;
  justify-content: flex-start;
  &:hover {
    background-color: ${({ theme: { colors } }) => colors.lightGreyHoverSearchResult};
  }
`;

export const ViewAllButton = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  a {
    display: block;
    font-size: 16px;
    color: ${({ theme: { colors } }) => colors.mainGreen};
    text-decoration: none;
    text-align: center;
    padding: 25px 10px;
    @media only screen and (max-width: 767px) {
      font-size: 14px;
      padding: 18px 10px;
    }
    &:hover {
      background-color: ${({ theme: { colors } }) => colors.lightGreyHoverSearchResult};
    }
  }
`;
