import styled from 'styled-components/macro';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

interface ICustomRadio {
  isActive: boolean;
  inside: string;
}

const CustomRadioCoords = {
  shipping: {
    top: '24px',
    left: '20px',
  },
  address: {
    mobile: {
      top: '11px',
      right: '11px',
    },
    desktop: {
      top: '14px',
      right: '12px',
    },
  },
};

const CustomRadio = styled.div`
  position: absolute;
  border-radius: 16px;
  border: ${(p: ICustomRadio) => (p.isActive ? '4px solid #00853e' : '2px solid #BFBFBF')};
  box-sizing: border-box;
  width: 16px;
  height: 16px;

  @media (min-width: 415px) {
    top: ${(p: ICustomRadio) => CustomRadioCoords[p.inside].desktop.top};
    right: ${(p: ICustomRadio) => CustomRadioCoords[p.inside].desktop.right};
  }
  @media (max-width: 415px) {
    top: ${(p: ICustomRadio) => CustomRadioCoords[p.inside].mobile.top};
    right: ${(p: ICustomRadio) => CustomRadioCoords[p.inside].mobile.right};
  }
`;

const AddressBlock = styled.div`
  height: 100%;
  cursor: pointer;
  background: ${({ theme: { colors } }) => colors.lightGrey};
  position: relative;
  border-radius: 4px;
  padding: 24px;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  margin-bottom: 10px;

  & > p {
    margin: 15px 0px;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
`;

const Col = styled.div`
  margin-bottom: 10px;
  @media (min-width: 415px) {
    width: 33.3333333333%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const ASTitle = styled.p`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 177.778% */
  letter-spacing: 0.1px;
  margin: 16px 0;
`;

const SAWrapper = styled.div`
  margin-top: 34px;
  margin-bottom: 20px;
`;

const AddressTitle = styled.span`
  font-weight: 700;
`;

const AddressWrapper = styled.div`
  position: relative;
`;

const AddressVisual = styled.div`
  position: relative;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  cursor: pointer;
  padding: 18px 14px;
  .txt-field-label {
    position: absolute;
    left: 9px;
    top: 0;
    transform: translate(0, -50%);
    font-size: 12px;
    color: #939599;
    background: #fff;
    padding: 0 5px;
  }
  .ic-arrow {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translate(0, -50%);
    color: #939599;
  }
  .txt-address {
    display: block;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 5px;
  }
  .txt-info {
    display: block;
    font-size: 16px;
    .txt-name {
    }
    .divider {
      padding: 0 10px;
    }
    .txt-phone {
    }
  }
`;

const AddressAutocomplete = styled.div`
  position: relative;
  .MuiAutocomplete-popper {
    position: absolute;
    left: 0;
    top: 48px;
    .MuiAutocomplete-listbox {
      li {
        position: relative;
        padding-left: 28px;
        .ic-check {
          display: block;
          position: absolute;
          left: 0;
          top: 5px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 2px solid #bfbfbf;
          &.checked {
            border: 4px solid ${({ theme: { colors } }) => colors.mainGreen};
          }
        }
        .txt-address {
          display: block;
          font-weight: 600;
          font-size: 18px;
          margin-bottom: 5px;
        }
        .txt-info {
          display: block;
          font-size: 16px;
          .txt-name {
          }
          .divider {
            padding: 0 10px;
          }
          .txt-phone {
          }
        }
      }
    }
  }
`;

const StyledTextField = withStyles({
  root: {
    margin: '0 0 33px 0',
    '&:last-child': {
      margin: 0,
    },
    '& .MuiInputBase-root': {
      fontFamily: 'Open Sans',
      height: '48px',
    },
    '& .MuiInputLabel-outlined': {
      fontFamily: 'Open Sans',
    },
    '& .MuiInputBase-multiline': {
      height: 'auto',
    },
    '& .MuiInputLabel-root': {
      fontFamily: 'Open Sans',
      color: '#939599',
      transform: 'translate(14px, 18px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '17px 14px',
    },
    '& .Mui-focused': {
      backgroundColor: '#F7F7F7',
    },
    '& .Mui-focused.MuiFormLabel-root': {
      backgroundColor: '#FFFFFF',
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      borderColor: '#DADEE4',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#000000',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#C4C4C4',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00853e',
        },
      },
    },
    '@media (max-width: 1023px)': {
      margin: '0 0 16px 0',
      '&:last-child': {
        margin: 0,
      },
    },
    '@media (max-width: 320px)': {
      margin: '0 0 16px 0',
      '&:last-child': {
        margin: 0,
      },
      '& .MuiInputBase-root': {
        height: '40px',
      },
      '& .MuiInputBase-multiline': {
        height: 'auto',
      },
      '& .MuiInputLabel-root': {
        transform: 'translate(14px, 12px) scale(1)',
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -8px) scale(0.75)',
      },
    },
  },
})(TextField);

export {
  AddressTitle,
  SAWrapper,
  ASTitle,
  Flex,
  Col,
  AddressBlock,
  CustomRadio,
  AddressWrapper,
  AddressVisual,
  AddressAutocomplete,
  StyledTextField,
};
