import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import Routes from 'routes';
import {
  MiddleLogo,
  MiddleLogoTest, OrderHistory,
  TruckIcon,
  UserIcon,
} from 'assets/svg/MiddleMenu/MiddleMenu';
import SearchComponent from 'components/Layout/Search/SearchComponent';
import SmallCheckout from 'components/Checkout/SmallCheckout/SmallCheckout';
import {selectCart, selectCartItemsQuantity} from 'ducks/cart/selectors';
import { formatPhoneNumber } from 'utils/common/common';
import { UserClass } from 'models/classes/userClass';
import { selectBranch } from 'ducks/branch/selectors';
import { branchCode } from 'themeExtended';
import { usePermission } from 'hooks/usePermission';
import { selectSalesRepresentative } from 'ducks/salesRepresentative/selectors';
import { useHistory } from 'react-router-dom';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import TagManager from 'react-gtm-module';
import {
  selectAddressId,
  selectErpCustomerAddressCode,
  selectIsHidePrice,
} from 'ducks/application/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import {useGA4CartEvent} from 'GA/CartGa4/cartGA4';
import get from 'lodash/get';
import { numberFormat } from 'utils/products/product';
import {
  LogoGrid,
  MenuContentGrid,
  MiddleMenuContentWrapper,
  MiddleMenuGrid,
  MyAccountItem,
  StyledButton,
  PhoneItem,
  PhoneNumber,
  PhoneTitle,
  SearchGrid,
  StyledLink,
  TruckItem,
  ColoredSvg,
} from '../styles';

interface IMiddleMenu {
  user: UserClass;
}

export const MiddleMenu = ({ user }: IMiddleMenu) => {
  const { t } = useTranslation();
  const history = useHistory();
  const selectedItemsQuantity = useSelector(selectCartItemsQuantity);
  const salesRep = useSelector(selectSalesRepresentative);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const cart = useSelector(selectCart);
  const isHidePrice = useSelector(selectIsHidePrice);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const workPhone = salesRep?.workPhone ? formatPhoneNumber(salesRep?.workPhone) : '';
  const { canSeePrice, canPlaceQuote, canPlaceOrder, canSeeOrderHistory } = usePermission();
  const {handleFireGA4ViewCart} = useGA4CartEvent()

  const handleCheckoutClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (history.location.pathname.includes(Routes.CHECKOUT)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
      handleFireGA4ViewCart();
    }
  };

  const handleCheckoutClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (type: string) => {
    if (type === 'profile') {
      clearGtmEcommerce();
      history.push(`${Routes.PROFILE  }?state=profile.personal_settings`, {
        tab: 'profile.personal_settings',
      });
    }
    if (type === 'order_history') {
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'click_order_history',
          user_type: getUserType(user.email),
          branch_id: branch?.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
      history.push(`${Routes.PROFILE  }?state=profile.orders`, {
        tab: 'profile.orders',
      });
    }
  };

  return (
    <Grid item container xs={12}>
      <MenuContentGrid item container>
        <MiddleMenuContentWrapper>
          <LogoGrid item>
            <StyledLink aria-label="categories" to={Routes.CATEGORIES}>
              <ColoredSvg>
                {branch?.code === branchCode ? <MiddleLogoTest /> : <MiddleLogo />}
              </ColoredSvg>
            </StyledLink>
          </LogoGrid>

          <SearchGrid item>
            <SearchComponent />
          </SearchGrid>

          <MiddleMenuGrid item>
            <PhoneItem item container direction="column">
              {user && salesRep && (
                <>
                  <PhoneTitle>
                    {t('header.call_representative').toString()} {salesRep?.name}
                  </PhoneTitle>
                  <PhoneNumber>{workPhone}</PhoneNumber>
                </>
              )}
            </PhoneItem>

            <MyAccountItem
              item
              container
              direction="column"
              onClick={() => handleNavigate('profile')}
            >
              <ColoredSvg>
                <UserIcon />
              </ColoredSvg>
              <StyledButton data-test-id="my-account-button">{t('header.my_account').toString()}</StyledButton>
            </MyAccountItem>

            {canSeeOrderHistory && (
              <MyAccountItem
                item
                container
                direction="column"
                onClick={() => handleNavigate('order_history')}
              >
                <ColoredSvg style={{ marginBottom: 2 }}>
                  <OrderHistory />
                </ColoredSvg>
                <StyledButton>
                  <span dangerouslySetInnerHTML={{ __html: t('header.order_history') }} />
                </StyledButton>
              </MyAccountItem>
            )}

            {canSeePrice && (canPlaceQuote || canPlaceOrder) && (
              <TruckItem
                item
                container
                direction="column"
                className={`${
                  history.location.pathname.includes(Routes.CHECKOUT) ? 'disabled' : ''
                }`}
                onClick={handleCheckoutClick}
                data-test-id="cart"
              >
                <ColoredSvg className="ic-cart">
                  <TruckIcon />
                  <span className="product-number">{selectedItemsQuantity || 0}</span>
                </ColoredSvg>
                {
                  !isHidePrice && (
                    <div className="total">
                      <div className="total-label">{t('checkout.subtotal').toString()}</div>
                      <div className="total-number">${numberFormat(get(cart, 'subtotal', 0))}</div>
                    </div>
                  )
                }
              </TruckItem>
            )}

            <SmallCheckout anchorEl={anchorEl} handleClose={handleCheckoutClose} />
          </MiddleMenuGrid>
        </MiddleMenuContentWrapper>
      </MenuContentGrid>
    </Grid>
  );
};
