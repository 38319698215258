import React from 'react';

export const Cross = () => {
  return (
    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.88111 4.5L7.8722 1.50859C7.95447 1.42624 7.99987 1.31638 8 1.19923C8 1.08202 7.9546 0.972031 7.8722 0.889814L7.61008 0.627748C7.52767 0.545206 7.41782 0.5 7.30055 0.5C7.18348 0.5 7.07363 0.545206 6.99122 0.627748L4.00013 3.61897L1.00891 0.627748C0.926634 0.545206 0.816715 0.5 0.699512 0.5C0.582439 0.5 0.47252 0.545206 0.390244 0.627748L0.128 0.889814C-0.0426667 1.06049 -0.0426667 1.3381 0.128 1.50859L3.11915 4.5L0.128 7.49128C0.0456585 7.57376 0.000325203 7.68362 0.000325203 7.80077C0.000325203 7.91791 0.0456585 8.02777 0.128 8.11019L0.390179 8.37225C0.472455 8.45473 0.582439 8.5 0.699447 8.5C0.81665 8.5 0.926569 8.45473 1.00885 8.37225L4.00006 5.38097L6.99115 8.37225C7.07356 8.45473 7.18341 8.5 7.30049 8.5H7.30062C7.41776 8.5 7.52761 8.45473 7.61002 8.37225L7.87213 8.11019C7.95441 8.02784 7.9998 7.91791 7.9998 7.80077C7.9998 7.68362 7.95441 7.57376 7.87213 7.49135L4.88111 4.5Z"
        fill="#939599"
      />
    </svg>
  );
};

export const BigCross = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 1L9 9M1 17L9 9M9 9L1 1M9 9L17 17"
        stroke="#BFBFBF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
