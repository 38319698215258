import styled from 'styled-components/macro';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';

const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  @media (max-width: 1023px) {
    margin-top: 0;
    min-width: 150px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
  &.button-block-reorder.fr {
    width: calc(100% + 10px);
  }
  &.button-block-inquire-now.fr {
    width: calc(100% + 30px);
    margin-left: -21px;
  }
  [class*="styles__HeaderButton-"] {
    padding: 8px 3px;
  }
  [class*="styles__StyledButton-"] {
    padding: 10.61px 13px;
    margin-left: 0;
  }
`;

interface IHeaderButton {
  quote?: boolean;
}

const HeaderButton = styled.button`
  height: 44px;
  border: 1px solid ${({ theme: { colors } }) => colors.mainGreen};
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  padding: 6px 13px;
  background-color: ${(props: IHeaderButton) =>
    props.quote
      ? ({ theme: { colors } }) => colors.pantone
      : ({ theme: { colors } }) => colors.lightGrey};
  margin-left: 0;
  width: 100%;
  &:hover {
    border: 1px solid ${({ theme: { colors } }) => colors.mainGreenHover};
  }
`;

const HeaderButtonText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: ${({ theme: { colors } }) => colors.mainGreen};
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 150%;
`;

const CreatedBlock = styled.div`
  width: ${({ isFrench }: IBlocks) => (isFrench ? '170px' : '220px')};
  margin-right: 20px;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;
interface IHeaderText {
  textAlign?: string;
}

const HeaderText = styled.span`
  float: left;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin-bottom: 4px;
  color: ${({ theme: { colors } }) => colors.grey};
  text-align: ${(p: IHeaderText) => p.textAlign ?? 'start'};
  word-break: normal;
`;

const OrderBlock = styled.div`
  width: 148px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const OrderBlockInfoWrapper = styled.div`
  display: flex;
`;

const OrderBlockInfo = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-top: 9px;
  word-break: break-word;

  &:first-child {
    margin-top: 0;
  }

  @media (max-width: 1023px) {
    &:first-child {
      width: 140px;
      margin-right: 10px;
    }

    &:last-child {
    }
    span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 19px;
      color: ${({ theme: { colors } }) => colors.grey};
    }
  }
`;

interface IPaddingText {
  type?: boolean;
}

const TextElement = styled.div`
  word-break: break-word;
  padding: 1px 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: ${(props: IPaddingText) =>
    props.type ? ({ theme: { colors } }) => colors.grey : ({ theme: { colors } }) => colors.black};
  @media (max-width: 1023px) {
    font-size: 14px;
  }

`;

const PaddingText = styled(TextElement)`
  margin-top: 9px;
  display: flex;

  &:first-child {
    margin-top: 0;
  }
`;

const StatusText = styled.div`
  height: max-content;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  background: ${({ theme: { colors } }) => colors.white};
  border-radius: 4px;
  padding: 3px 8px;
  @media (max-width: 1023px) {
    max-width: 140px;
    width: max-content;
  }
`;

const StyledCardContent = styled(CardContent)`
  position: relative;
  padding: 24px;
  min-height: 155px;
  display: flex;
  flex-direction: column;
`;

interface IStyledIconButton {
  expanded?: boolean;
}

const StyledIconButton = styled(IconButton)`
  width: max-content;
  height: max-content;
  transform: rotate(0deg);
  padding: 0;
  ${(p: IStyledIconButton) => p.expanded && 'transform: rotate(180deg)'}
`;

const TotalBlock = styled.div`
  width: ${({ isFrench }: IBlocks) => (isFrench ? '240px' : '150px')};
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  @media (max-width: 1023px) {
    display: block;
    width: inherit;
    padding-top: 20px;
  }
  > * {
    @media (max-width: 1023px) {
      display: inline-block;
      vertical-align: middle;
      float: none;
      margin: 0;
    }
  }
  [class*="HeaderText"] {
    padding-right: 10px;
  }
`;

const ReorderButtonsBlock = styled.div`
  @media (max-width: 600px) {
    width: 100%;
  }
  [class*="styles__StyledButton"] {
    padding-left: 6px;
    padding-right: 6px;
    @media (max-width: 1023px) {
      margin-right: 0;
    }
  }
`;

const TotalText = styled.div`
  font-weight: bold;
  font-size: 16px;
  padding: 1px 0;
  text-align: end;

  @media (max-width: 1023px) {
    text-align: start;
  }
`;

interface IBlocks {
  isFrench: boolean;
}

const UserBlock = styled.div`
  width: 240px;
  margin-right: 20px;
  @media (max-width: 1023px) {
    width: 70%;
  }
`;

const UserBlockInfo = styled(OrderBlockInfo)`
  font-weight: normal;
  font-size: 12px;
  &.full-width {
    @media (max-width: 1023px) {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
`;

const CardHeaderMobile = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrderCreatedWrapper = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
`;

const StatusShipWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  @media (max-width: 1023px) {
    padding-bottom: 0;
  }
`;

const MoreInfoBlock = styled.div`
  @media (max-width: 1023px) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  > * {
    @media (max-width: 1023px) {
      flex: 1 1 50%;
      max-width: 50%;
      margin: 0 0 20px !important;
    }
  }
  [class*="HeaderText"] {
    @media (max-width: 1023px) {
      display: block;
      float: none;
      margin-bottom: 5px;
    }
  }
`;

const StatusBlock = styled.div`
  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const ShipDateBlock = styled.div`
  width: 110px;

  @media (max-width: 1023px) {
    margin-top: 9px;
    width: 100%;
  }
`;

const UserWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  @media (max-width: 1023px) {
    padding-bottom: 0;
  }
`;

const UserTotalWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const StyledIconButtonMobile = styled(IconButton)`
  transform: rotate(0deg);
  padding: 4px;

  &:hover {
    background-color: inherit;
  }

  ${(p: IStyledIconButton) => p.expanded && 'transform: rotate(180deg)'}
`;

const StyledCardContentMobile = styled(CardContent)`
  position: relative;
  padding: 20px 10px;
`;

const TagBlock = styled.div`
  width: 100%;
  padding: 7px 6px 0 6px;
  display: flex;
  align-items: center;
  font-size: 12px;
  @media (max-width: 1023px) {
    padding: 0 6px;
    justify-content: space-between;
  }
`;

const TotalSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
`;

const NotificationBlock = styled.div`
  width: 100%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  text-align: right;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.waitingOnStockBlue};
  margin-top: 4px;
  @media (max-width: 1023px) {
    text-align: left;
  }
`;

const NotificationInvoiced = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  background: #c9e9d1;
  border-radius: 20px;
  padding: 25px 30px;
  margin-top: 20px;
  @media (max-width: 1023px) {
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
  }
  .box-ic {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 70px;
    width: 70px;
    height: 70px;
    border-radius: 20px;
    background: #337D46;
    @media (max-width: 1023px) {
      flex: 0 0 40px;
      width: 40px;
      height: 40px;
      border-radius: 8px;
    }
    svg {
      @media (max-width: 1023px) {
        width: 20px;
        height: 20px;
      }
    }
  }
  .box-text {
    flex: 0 0 calc(100% - 70px);
    padding-left: 20px;
    @media (max-width: 1023px) {
      flex: 0 0 calc(100% - 40px);
      padding-left: 8px;
    }
    .lg {
      font-size: 24px;
      line-height: (36/24);
      font-weight: 700;
      color: #337D46;
      @media (max-width: 1023px) {
        font-size: 14px;
      }
    }
    .md {
      font-family: Open Sans;
      font-size: 16px;
      line-height: (24/16);
      color: #5D9B6D;
      @media (max-width: 1023px) {
        font-size: 11px;
      }
    }
  }
  @media (max-width: 1023px) {
  }
`;

const StyledDate = styled.div`
  min-width: 68px;
`;

const StyledButton = styled.button`
  cursor: pointer;
  border: none;
  background: ${({ theme: { colors } }) => colors.mainGreen};
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 14px 0 13px;
  outline: none;
  margin-left: 10px;
  @media (max-width: 1023px) {
    max-width: 100%;
    margin-left: 0;
    margin-right: 6px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
  &:hover {
    background-color: ${({ theme: { colors } }) => colors.mainGreenHover};
  }
  &:active {
    background-color: ${({ theme: { colors } }) => colors.mainGreefPassed};
  }
`;

const ButtonTitle = styled.p`
  margin: 0;
  color: ${({ theme: { colors } }) => colors.white};
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.1px;
  font-style: normal;
`;

const UserOptInForOrderUpdateEmails = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  background-color: #f0f9f3;
  border: 1px solid #196742;
  border-radius: 8px;
  padding: 4.5px 16px;
  margin: 16px 0 0;
  @media (max-width: 1023px) {
    margin: 0 0 16px;
  }
  .ic {
    flex: 0 0 24px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .txt-message {
    flex: 1 1 auto;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    padding: 0 2px 0 8px;
  }
  .checkbox-wrapper {
    flex: 0 0 16px;
    .MuiCheckbox-root {
      padding: 0;
    }
  }
`;

export {
  HeaderButtonText,
  HeaderButton,
  ButtonBlock,
  CardHeader,
  CreatedBlock,
  HeaderText,
  OrderBlock,
  OrderBlockInfo,
  OrderBlockInfoWrapper,
  PaddingText,
  StatusText,
  StyledCardContent,
  StyledIconButton,
  TotalBlock,
  TotalText,
  UserBlock,
  CardHeaderMobile,
  OrderCreatedWrapper,
  StatusShipWrapper,
  MoreInfoBlock,
  StatusBlock,
  ShipDateBlock,
  UserTotalWrapper,
  TagBlock,
  StyledIconButtonMobile,
  StyledCardContentMobile,
  TotalSection,
  TotalWrapper,
  NotificationBlock,
  TextElement,
  UserBlockInfo,
  UserWrapper,
  ReorderButtonsBlock,
  NotificationInvoiced,
  StyledDate,
  StyledButton,
  ButtonTitle,
  UserOptInForOrderUpdateEmails
};
