import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, CheckboxProps, withStyles } from '@material-ui/core';
import { FilterArrowDown, FilterArrowUp } from 'assets/svg/Filter/Arrow';
import { FacetClass } from 'models/classes/facetClass';
import { ISelectedValue } from 'models/interfaces/productVariant';
import {
  SingleFilterWrapper,
  StyledButton,
  ButtonNameWrapper,
  CheckboxContentWrapper,
  ResponsiveFilterWrapper,
  CheckboxWrapper,
  CheckboxTitle,
} from 'components/Product/ProductFilter/styles';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectAddressId, selectErpCustomerAddressCode } from 'ducks/application/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { ProductFilterContext } from 'context/ProductFilterContext';
import { useGetDumpProductSearchFilterIds } from 'api/ProductVariant';
import { useQuery } from 'utils/common';

const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#00853e',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

interface ICheckboxFilterProps {
  facet: FacetClass;
  handleChange: (facetCode: string, bucketLabel: string, bucketKey: string) => void;
  findNecessaryCheck: (facetProperty: string, bucketLabel: string) => boolean;
  selectedValues: ISelectedValue[];
}

const SearchPageCheckboxFilter = ({
  facet,
  handleChange,
  findNecessaryCheck,
  selectedValues,
}: ICheckboxFilterProps) => {
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const [open, setOpen] = useState(false);
  const arrow = useMemo(() => (open ? <FilterArrowUp /> : <FilterArrowDown />), [open]);
  const query = useQuery();

  const { inStock, filter, pagination, addressId, selectedLanguage } = React.useContext(ProductFilterContext);

  const {data, refetch} = useGetDumpProductSearchFilterIds(query.get('q'), selectedAddressId, {
    ...filter,
    inStock,
    filterAttributes: { ...filter },
    ...pagination,
    addressId,
    language: selectedLanguage,
  });

  useEffect(() => {
    refetch();
  }, [])

  const handleOpenClick = () => {
    if (!open) {
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'filter_click_expand',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
    }
    setOpen(!open);
  };

  const setFilterCount = (f: any, b: any) => {
    const facetFilter = data?.data?.find(v => {
      return v.key === f.property;
    });
    if (!facetFilter) {
      return 0;
    }
    const bucketCount: any[] = Object.entries(facetFilter.value).find((value: any) => {
      return parseInt(value[0], 10) === b.key;
    });
    if (bucketCount) {
      return bucketCount[1].length;
    }
    return 0;
  };

  useEffect(() => {
    selectedValues.every(selectedValue => {
      if (selectedValue.code === facet.property) {
        setOpen(true);
        return false;
      }

      return true;
    });
  }, [selectedValues, facet]);

  return (
    <SingleFilterWrapper key={`prop${facet.property}`}>
      <StyledButton onClick={handleOpenClick}>
        <ButtonNameWrapper>{facet.label}</ButtonNameWrapper>
        {arrow}
      </StyledButton>
      <CheckboxContentWrapper isOpen={open}>
        <ResponsiveFilterWrapper isOpen={open}>
          {facet &&
            facet.buckets.map(bucket => (
              <CheckboxWrapper
                key={bucket.label}
                onClick={() => {
                  if (setFilterCount(facet, bucket) > 0) {
                    handleChange(facet.property, bucket.label, bucket.key);
                    refetch();
                  }
                  return null;
                }}
                isShow={setFilterCount(facet, bucket) > 0}
              >
                <GreenCheckbox
                  checked={findNecessaryCheck(facet.property, bucket.key)}
                  name={bucket.label}
                />
                <CheckboxTitle>
                  {bucket.label} ({setFilterCount(facet, bucket)})
                </CheckboxTitle>
              </CheckboxWrapper>
            ))}
        </ResponsiveFilterWrapper>
      </CheckboxContentWrapper>
    </SingleFilterWrapper>
  );
};

export default SearchPageCheckboxFilter;
