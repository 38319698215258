import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Material
import { Grid } from '@material-ui/core';

import {
  DesktopLogoEnglish,
  DesktopLogoFrench,
  DesktopLogoTest,
  EmailIcon,
  FaxIcon,
  LocationIcon,
  PhoneIcon,
  TollFreeIcon,
} from 'assets/svg/Footer/FooterIcons';
import Routes from 'routes';
import { selectParentCategories } from 'ducks/category/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { useTranslation } from 'react-i18next';

import { branchCode } from 'themeExtended';
import { formatPhoneNumber } from 'utils/common/common';
import { GlobalBannerNotification } from 'components/Layout/Notification/GlobalBannerNotification';
import {
  BodyTypography,
  CopyrightGrid,
  EmailDiv,
  EmailTypography,
  FirstGrid,
  FooterWrapper,
  ForthGrid,
  HeaderTypography,
  LocationDiv,
  LocationTypography,
  LogoGrid,
  MenuGrid,
  MenuTypography,
  PhonesDiv,
  PhoneWrapperDiv,
  SecondGrid,
  StyledEmailDiv,
  StyledHyperLink,
  StyledLink,
  StyledLocationDiv,
  StyledProductGrid,
  StyledTypography,
  ThirdGrid,
} from './styles/FooterStyles';

const Footer: React.FC = () => {
  const categories = useSelector(selectParentCategories);

  const categoriesResult = useMemo(() => {
    return categories.map(category => (
      <StyledLink key={category.code} to={`${Routes.CATEGORIES}/${category.code}`}>
        <BodyTypography>{category.name}</BodyTypography>
      </StyledLink>
    ));
  }, [categories]);

  const { t, i18n } = useTranslation();

  const branch = useSelector(selectBranch);

  const phonesList = useMemo(() => {
    if (branch && branch?.phones?.length) {
      return (
        <PhonesDiv>
          <PhoneWrapperDiv>
            {branch?.phones?.map(phone => {
              let icon = <></>;
              switch (phone.type) {
                case 'Tel.':
                  icon = <PhoneIcon />;
                  break;
                case 'Fax':
                  icon = <FaxIcon />;
                  break;
                case 'Toll Free':
                  icon = <TollFreeIcon />;
                  break;
                default:
                  return <></>;
              }

              return (
                <div key={phone.number} className="item">
                  {icon} <span className="txt">{`${formatPhoneNumber(phone.number)} ${phone.type}`}</span>
                </div>
              );
            })}
          </PhoneWrapperDiv>
        </PhonesDiv>
      );
    }

    return null;
  }, [branch]);

  const address = useMemo(() => {
    if (branch) {
      return (
        <LocationDiv>
          <StyledLocationDiv>
            <LocationIcon />
          </StyledLocationDiv>
          <LocationTypography>{branch.address}</LocationTypography>
        </LocationDiv>
      );
    }
    return null;
  }, [branch]);

  const emails = useMemo(() => {
    let email = '';
    if (branch) {
      if (branch?.email !== '') {
        email = branch?.email;
      } else {
        email = 'taiganow@taigabuilding.com';
      }

      if (typeof email === 'string' && email.indexOf(',') > -1) {
        return (
          <EmailDiv>
            {email.split(',').map((em, idx) => (
              <div title={em} key={em} style={{ display: 'flex' }}>
                <StyledEmailDiv style={{ opacity: idx !== 0 ? 0 : null }}>
                  <EmailIcon />
                </StyledEmailDiv>
                <EmailTypography as="a" href={`mailto:${em}`} style={{ textDecoration: 'none' }}>
                  {em}
                </EmailTypography>
              </div>
            ))}
          </EmailDiv>
        );
      }

      return (
        <EmailDiv style={{ display: 'flex' }}>
          <StyledEmailDiv>
            <EmailIcon />
          </StyledEmailDiv>
          <EmailTypography
            as="a"
            href={`mailto:${email}`}
            style={{ textDecoration: 'none' }}
          >
            {email}
          </EmailTypography>
        </EmailDiv>
      );
    }
    return null;
  }, [branch]);

  const DesktopLogo = () =>
    i18n.language === 'en' ? <DesktopLogoEnglish /> : <DesktopLogoFrench />;
  return (
    <>
      <GlobalBannerNotification />
      <FooterWrapper container direction="column" alignItems="center">
        <LogoGrid container item>
          {branch?.code === branchCode ? <DesktopLogoTest /> : <DesktopLogo />}
        </LogoGrid>
        <MenuGrid container item>
          <FirstGrid container item direction="column">
            <MenuTypography>{t('footer.taiga_products').toString()}</MenuTypography>
            {address}
            {phonesList}
            {emails}
          </FirstGrid>
          <SecondGrid container item direction="column">
            <HeaderTypography>{t('footer.use_and_help').toString()}</HeaderTypography>
            <StyledLink to={Routes.FAQ}>
              <BodyTypography>{t('footer.faq').toString()}</BodyTypography>
            </StyledLink>
            <StyledLink to={Routes.COLOUR_DISCLAIMER}>
              <BodyTypography>{t('footer.color_disclaimer').toString()}</BodyTypography>
            </StyledLink>
            <StyledLink to={Routes.PRIVACY_POLICY}>
              <BodyTypography>{t('footer.policy').toString()}</BodyTypography>
            </StyledLink>
            <StyledLink to={Routes.TERMS_OF_SERVICE}>
              <BodyTypography>{t('footer.terms').toString()}</BodyTypography>
            </StyledLink>
          </SecondGrid>
          <ThirdGrid container item direction="column">
            <Grid container item>
              <HeaderTypography>{t('footer.products').toString()}</HeaderTypography>
            </Grid>
            <StyledProductGrid container item justify="space-between">
              {categoriesResult}
            </StyledProductGrid>
          </ThirdGrid>
          <ForthGrid container item direction="column">
            <HeaderTypography>{t('footer.company_info').toString()}</HeaderTypography>
            <StyledLink to={Routes.CONTACT_US}>
              <BodyTypography>{t('footer.feedback').toString()}</BodyTypography>
            </StyledLink>
            <StyledLink to={Routes.CONTACT_US}>
              <BodyTypography>{t('footer.contact_us').toString()}</BodyTypography>
            </StyledLink>
            <StyledHyperLink href="https://www.taigabuilding.com" target="_blank">
              <BodyTypography>{t('footer.about_us').toString()}</BodyTypography>
            </StyledHyperLink>
          </ForthGrid>
        </MenuGrid>
        <CopyrightGrid container item alignItems="center" justify="center">
          <StyledTypography>{t('footer.copyright', {year: new Date().getFullYear()}).toString()}</StyledTypography>
        </CopyrightGrid>
      </FooterWrapper>
    </>
  );
};

export default Footer;
