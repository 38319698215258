import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  QueryStatus,
  InfiniteData,
} from 'react-query';

import { IUniversalOrdersDataResponse } from 'api/Order';
import { ContentItem, FiltersBlock } from 'pages/General/Profile/index';
import ProfileFilterLoader from 'components/common/Loader/ProfileLoader';
import { IRequestData } from 'models/interfaces/order';
import {
  NoOrdersText,
  OrdersList,
  LoadNextListWrapper,
  StyledLoadButton,
  LoadNextTitle,
} from './styles';

interface IContent {
  data: InfiniteData<IUniversalOrdersDataResponse>;
  hasNextPage: boolean;
  fetchNextPage(
    options?: FetchNextPageOptions
  ): Promise<InfiniteQueryObserverResult<IUniversalOrdersDataResponse, Error>>;
  status: QueryStatus;
  onFilterChange(data: IRequestData): void;
  isFetchingNextPage: boolean;
  filterParams: IRequestData;
  isQuotes?: boolean;
  openOrderTab?: (orderNumber: number) => void;
  setIsApplyFilterInputInput: Function;
  isApplyFilterInput: boolean;
}

const Content = ({
  data,
  hasNextPage,
  fetchNextPage,
  status,
  onFilterChange,
  isFetchingNextPage,
  filterParams,
  isQuotes,
  openOrderTab,
  isApplyFilterInput,
  setIsApplyFilterInputInput
}: IContent) => {
  const { t } = useTranslation();

  const defaultFilterValues: IRequestData = Object.keys(filterParams).reduce(
    (acc, currentValue) => {
      acc[currentValue] = filterParams[currentValue];

      return acc;
    },
    {
      input: '',
      status: '',
      address: '',
      contact: '',
      from: '',
      to: '',
      dateRange: '',
      sort: 'DESC',
    }
  );

  const nextContent = () => {
    if (!data?.pages[0]?.data.length) {
      return <NoOrdersText>{t('products_and_search.no_orders').toString()}</NoOrdersText>;
    }

    return hasNextPage ? (
      <LoadNextListWrapper>
        <StyledLoadButton onClick={() => fetchNextPage()}>
          <LoadNextTitle>
            {t('products_and_search.load_next', { paginationItemsPerPage: 12 }).toString()}
          </LoadNextTitle>
        </StyledLoadButton>
      </LoadNextListWrapper>
    ) : null;
  };

  return (
    <>
      <FiltersBlock
        onFilterChange={onFilterChange}
        defaultFilterValues={defaultFilterValues}
        isQuotes={isQuotes}
        arrowFromUpToDown={filterParams.sort !== 'ASC'}
        isApplyFilterInput={isApplyFilterInput}
        setIsApplyFilterInputInput={setIsApplyFilterInputInput}
      />
      {status === 'loading' ? (
        <ProfileFilterLoader isFilterLoader />
      ) : (
        <>
          <OrdersList>
            {data.pages.map(page => (
              <React.Fragment key={page.pageId}>
                {page.data.map(pageItem => (
                  <ContentItem key={pageItem.id} element={pageItem} openOrderTab={openOrderTab} />
                ))}
              </React.Fragment>
            ))}
          </OrdersList>

          {isFetchingNextPage ? <ProfileFilterLoader isLoadingMore /> : nextContent()}
        </>
      )}
    </>
  );
};

export { Content };
