import {Dispatch, SetStateAction} from 'react';

// Classes
import {ProductVariantClass} from 'models/classes/productVariantClass';
import {FacetClass} from 'models/classes/facetClass';
import {IFetchFilteredProductsPayload, IRangeFilter} from 'models/interfaces/productVariant';
import {IError} from 'models/interfaces/error';
import {AssociationVariantClass} from 'models/classes/associationClass';

export enum ProductActionTypes {
	REQUEST_PRODUCTS = '@@productVariant/REQUEST_PRODUCTS',
	REQUEST_PRODUCTS_SUCCESS = '@@productVariant/REQUEST_PRODUCTS_SUCCESS',
	REQUEST_FEATURED_PRODUCT = '@@productVariant/REQUEST_FEATURED_PRODUCT',
	REQUEST_FEATURED_PRODUCTS_SUCCESS = '@@productVariant/REQUEST_FEATURED_PRODUCTS_SUCCESS',
	REQUEST_PRODUCTS_ERROR = '@@productVariant/REQUEST_PRODUCTS_ERROR',

	REQUEST_FILTERED_PRODUCTS = '@@productVariant/REQUEST_FILTERED_PRODUCTS',
	REQUEST_FILTERED_PRODUCTS_SUCCESS = '@@productVariant/REQUEST_FILTERED_PRODUCTS_SUCCESS',
	REQUEST_FILTERED_PRODUCTS_SUCCESS_FIRST_LOAD = '@@productVariant/REQUEST_FILTERED_PRODUCTS_SUCCESS_FIRST_LOAD',
	REQUEST_FILTERED_PRODUCTS_ERROR = '@@productVariant/REQUEST_FILTERED_PRODUCTS_ERROR',
	SET_FILTERED_PRODUCTS_ERROR = '@@productVariant/SET_FILTERED_PRODUCTS_ERROR',

	REQUEST_FILTERS = '@@productVariant/REQUEST_FILTERS',
	REQUEST_FILTERS_SUCCESS = '@@productVariant/REQUEST_FILTERS_SUCCESS',
	REQUEST_FILTERS_ERROR = '@@productVariant/REQUEST_FILTERS_ERROR',

	REQUEST_PRICING = '@@productVariant/REQUEST_PRICING',
	REQUEST_PRICING_SUCCESS = '@@productVariant/REQUEST_PRICING_SUCCESS',
	REQUEST_PRICING_ERROR = '@@productVariant/REQUEST_PRICING_ERROR',

	RESET_PRODUCTS = '@@productVariant/RESET_PRODUCTS',
	RESET_FEATURED_PRODUCTS = '@@productVariant/RESET_FEATURED_PRODUCTS',

	SET_SHOW_PRICES = '@@productVariant/SET_SHOW_PRICES',
	RESET_PRODUCTS_ERROR = '@@productVariant/RESET_PRODUCTS_ERROR',

	REQUEST_VARIANT_ASSOCIATIONS = '@@productVariant/REQUEST_VARIANT_ASSOCIATIONS',
	REQUEST_VARIANT_ASSOCIATIONS_SUCCESS = '@@productVariant/REQUEST_VARIANT_ASSOCIATIONS_SUCCESS',
	REQUEST_VARIANT_ASSOCIATIONS_ERROR = '@@productVariant/REQUEST_VARIANT_ASSOCIATIONS_ERROR',

	RESET_VARIANT_ASSOCIATIONS = '@@productVariant/RESET_VARIANT_ASSOCIATIONS',

	SET_PAGINATION_LOADING = '@@productVariant/SET_PAGINATION_LOADING',
	REMOVE_PAGINATION_LOADING = '@@productVariant/REMOVE_PAGINATION_LOADING',

	REQUEST_GET_PRODUCT_IDS = '@@productVariant/REQUEST_GET_PRODUCT_IDS',
	REQUEST_GET_PRODUCT_IDS_SUCCESS = '@@productVariant/REQUEST_GET_PRODUCT_IDS_SUCCESS',

	REQUEST_PRODUCT_ASSOCIATION = '@@product/REQUEST_PRODUCT_ASSOCIATION',
	REQUEST_PRODUCT_ASSOCIATION_SUCCESS = '@@product/REQUEST_PRODUCT_ASSOCIATION_SUCCESS',

	REQUEST_SEND_NEARBY_BRANCH_EMAIL = '@@product/REQUEST_SEND_NEARBY_BRANCH_EMAIL',
	REQUEST_SEND_NEARBY_BRANCH_EMAIL_SUCCESS = '@@product/REQUEST_SEND_NEARBY_BRANCH_EMAIL_SUCCESS',
	REQUEST_SET_SEND_NEARBY_BRANCH_EMAIL_STATUS = '@@product/REQUEST_SET_SEND_NEARBY_BRANCH_EMAIL_STATUS',

}

export const PRODUCT_PRICING_METHOD_BLENDED = 'blended';
export const PRODUCT_PRICING_METHOD_STANDARD = 'standard';

export interface ProductState {
	readonly products: ProductVariantClass[];
	readonly featuredProducts: ProductVariantClass[];
	readonly facets: FacetClass[];
	readonly rangeFilters: IRangeFilter[];
	readonly loading: boolean;
	readonly error: IError | null;
	readonly isLastPage: boolean;
	readonly pricingReqSuccess: boolean;
	readonly showPrices: boolean;
	readonly similarProducts: AssociationVariantClass[];
	readonly alsoLikeProducts: AssociationVariantClass[];
	readonly accessories: AssociationVariantClass[];
	readonly featuredProductsLoading: boolean;
	readonly paginationLoading: boolean;
	readonly total: number;
	readonly productIdsList: any;
	readonly relatedProducts: ProductVariantClass[];
	readonly alterNativeProducts: ProductVariantClass[];
	readonly sendEmailNearbyBranchStatus: boolean;
	readonly productIdListLoading: boolean;
}

export interface FetchProductsAction {
	type: ProductActionTypes.REQUEST_PRODUCTS;
	payload: {
		page?: number;
		itemsPerPage?: number;
		categoryCode: string;
	};
}

export interface FetchFeaturedProductAction {
	type: ProductActionTypes.REQUEST_FEATURED_PRODUCT,
	payload: {
		branchId: number;
		categoryCode: string;
	}
}

export interface FetchFilteredProductsAction {
	type: ProductActionTypes.REQUEST_FILTERED_PRODUCTS;
	payload: IFetchFilteredProductsPayload;
}

export interface RequestPricingAction {
	type: ProductActionTypes.REQUEST_PRICING;
	payload: {
		message: string;
		quantity: string;
		addressId: number;
		productCode: string;
		productSku: string;
		setEmailSuccess: Dispatch<SetStateAction<boolean>>;
		setEmailLoading: Dispatch<SetStateAction<boolean>>;
	};
}

export interface RequestVariantAssociations {
	type: ProductActionTypes.REQUEST_VARIANT_ASSOCIATIONS;
	payload: {
		variantCode: string;
	};
}

export interface RequestProductIdsByCategory {
	type: ProductActionTypes.REQUEST_GET_PRODUCT_IDS,
	payload: {
		category: string;
		branchId: number;
		filters?: any;
	}
}

export interface IRequestAssociationProductAction {
	type: ProductActionTypes.REQUEST_PRODUCT_ASSOCIATION,
	payload: {
		productId: number;
	}
}

export enum NearbyBranchEmailType {
	NEARBY_BRANCH_AVAILABLE_WITH_INVENTORY = 2,
	NEARBY_BRANCH_AVAILABLE_WITHOUT_INVENTORY = 3,
	NO_NEARBY_BRANCH_OR_HUBS = 4,
	HUB_BRANCH_WITH_INVENTORY_AVAILABLE = 5,
	HUB_BRANCH_WITHOUT_INVENTORY_AVAILABLE = 6,
	HOME_BRANCH_WITH_INVENTORY_IN_STOCK = 7,
	HOME_BRANCH_WAITING_ON_STOCK = 8,
	HOME_BRANCH_WITH_SPECIAL_ORDER_STRING = 9

}

export interface IRequestSendNearbyBranchEmailAction {
	type: ProductActionTypes.REQUEST_SEND_NEARBY_BRANCH_EMAIL;
	payload: {
		emailType: NearbyBranchEmailType;
		addressId: number;
		productId: number;
		categoryId: number;
		quantity: number;
		comment?: string,
	}
}
