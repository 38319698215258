import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const FooterWrapper = styled(Grid)`
  background: ${({ theme: { colors } }) => colors.mainGreen};
  width: 100%;
`;

const LogoGrid = styled(Grid)`
  height: 133px;
  padding: 15px 0 0 16px;
  @media (min-width: 1201px) {
    width: 1200px;
  }
`;

const MenuGrid = styled(Grid)`
  padding-bottom: 35px;
  color: ${({ theme: { colors } }) => colors.white};
  height: fit-content;
  padding-left: 16px;
  @media (min-width: 1201px) {
    width: 1200px;
  }
`;

const LocationDiv = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme: { colors } }) => colors.white};
  padding-top: 18px;
`;

const LocationTypography = styled(Typography)`
  display: inline;
  padding-left: 9px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  color: ${({ theme: { colors } }) => colors.white};
`;

const PhonesDiv = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme: { colors } }) => colors.white};
  padding-top: 20px;
`;

const StyledPhoneDiv = styled.div`
  margin-top: 3px;
`;

const StyledLocationDiv = styled.div`
  margin-top: 1px;
`;

const StyledEmailDiv = styled.div`
  margin-top: -1px;
`;

const PhoneWrapperDiv = styled.div`
  .item {
    margin-bottom: 3px;
  }
  svg {
    vertical-align: middle;
    path {
      fill: #fff;
    }
  }
  .txt {
    padding-left: 9px;
  }
`;

const PhoneTypography = styled(Typography)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  color: ${({ theme: { colors } }) => colors.white};
`;

const EmailDiv = styled.div`
  width: 100%;
  color: ${({ theme: { colors } }) => colors.white};
  padding-top: 20px;
  padding-bottom: 37px;
`;

const EmailTypography = styled(Typography)`
  display: inline;
  padding-left: 9px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.white};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const MenuTypography = styled(Typography)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: ${({ theme: { colors } }) => colors.white};
`;

const CopyrightGrid = styled(Grid)`
  background: ${({ theme: { colors } }) => colors.borderGreen};
  color: ${({ theme: { colors } }) => colors.copyrightGreen};
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const HeaderTypography = styled(Typography)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 155%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: ${({ theme: { colors } }) => colors.white};
  padding-bottom: 16px;
`;

const BodyTypography = styled(Typography)`
  padding-bottom: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.white};
`;

const FirstGrid = styled(Grid)`
  width: 21%;
  border-right: 1px solid ${({ theme: { colors } }) => colors.borderGreen};
  padding-right: 45px;
  @media (min-width: 1024px) and (max-width: 1200px) {
    padding-right: 32px;
  }
`;

const SecondGrid = styled(Grid)`
  width: 23%;
  border-right: 1px solid ${({ theme: { colors } }) => colors.borderGreen};
  padding-left: 45px;
  padding-right: 45px;
  @media (min-width: 1024px) and (max-width: 1200px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`;

const ThirdGrid = styled(Grid)`
  width: 34%;
  border-right: 1px solid ${({ theme: { colors } }) => colors.borderGreen};
  padding-left: 45px;
  padding-right: 10px;
  @media (min-width: 1024px) and (max-width: 1200px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`;

const ForthGrid = styled(Grid)`
  padding-left: 45px;
  width: 22%;
  @media (min-width: 1024px) and (max-width: 1200px) {
    padding-left: 32px;
  }
`;

const StyledTypography = styled(Typography)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`;

const StyledHyperLink = styled.a`
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.white};
`;

const StyledProductGrid = styled(Grid)`
  * {
    flex: 0 50%;
  }
`;
export {
  FooterWrapper,
  LogoGrid,
  MenuGrid,
  MenuTypography,
  LocationDiv,
  LocationTypography,
  PhonesDiv,
  PhoneTypography,
  PhoneWrapperDiv,
  StyledEmailDiv,
  StyledHyperLink,
  StyledLink,
  StyledLocationDiv,
  StyledPhoneDiv,
  StyledProductGrid,
  StyledTypography,
  FirstGrid,
  SecondGrid,
  ThirdGrid,
  ForthGrid,
  BodyTypography,
  EmailDiv,
  EmailTypography,
  HeaderTypography,
  CopyrightGrid,
};
