import { ClickAwayListener } from '@material-ui/core';
import Routes from 'routes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  ContainerError,
  ModalCard,
  ModalCardContent,
  ModalFromButton,
  ModalTitle, ModalWindow, StyledButtonLink,
} from '../OrdersModal/styles';
import { setSubmitOrderError } from '../../../ducks/order/actions';

const OrdersModalError = () => {
  const [showPopup, setShowPopup] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const closeOrderPopup = () => {
    setShowPopup(false);
    dispatch(setSubmitOrderError());
  }

  return (
    <ModalWindow open={showPopup} onClose={closeOrderPopup}>
      <ClickAwayListener onClickAway={closeOrderPopup}>
        <ModalCard variant="outlined" tabIndex={NaN}>
          <ModalTitle>{t('checkout.submit_order_error_title').toString()}</ModalTitle>
          <ModalCardContent />
          <ContainerError>
            <p>{t('checkout.submit_order_error').toString()}</p>
            <br/>
            <ModalFromButton>
              <StyledButtonLink to={`${Routes.CATEGORIES}`} onClick={closeOrderPopup}>
                {t('products_and_search.return_home').toString()}
              </StyledButtonLink>
            </ModalFromButton>
          </ContainerError>
        </ModalCard>
      </ClickAwayListener>
    </ModalWindow>
  )
}

export default OrdersModalError;
