import { IError } from 'models/interfaces/error';
import {ProductVariantClass} from '../../models/classes/productVariantClass';

export enum CartActionTypes {
  ADD_PRODUCT = '@@cart/REQUEST_ADD_PRODUCT',
  ADD_PRODUCT_ERROR = '@@cart/REQUEST_ADD_PRODUCT_ERROR',
  ADD_PRODUCT_SUCCESS = '@@cart/ADD_PRODUCT_SUCCESS',
  SET_ADD_TO_TRUCK_STATUS = '@@cart/SET_ADD_TO_TRUCK_STATUS',
  SET_ADD_TO_TRUCK_ITEM = '@@cart/SET_ADD_TO_TRUCK_ITEM',

  STORE_FIRST_LOAD_SUMMARY = '@@cart/STORE_FIRST_LOAD_SUMMARY',
  SET_STORE_FIRST_LOAD_SUMMARY_STATUS = '@@cart/SET_STORE_FIRST_LOAD_SUMMARY_STATUS',

  GET_CART_SUMMARY = '@@cart/GET_CART_SUMMARY',
  GET_CART_SUMMARY_ERROR = '@@cart/GET_CART_SUMMARY_ERROR',

  CHANGE_PRODUCT_QTY = '@@cart/CHANGE_PRODUCT_QTY',
  CHANGE_PRODUCT_QTY_SUCCESS = '@@cart/CHANGE_PRODUCT_QTY_SUCCESS',
  CHANGE_PRODUCT_QTY_ERROR = '@@cart/CHANGE_PRODUCT_QTY_ERROR',

  DELETE_PRODUCT = '@@cart/DELETE_PRODUCT',
  DELETE_PRODUCT_ERROR = '@@cart/DELETE_PRODUCT_ERROR',

  STORE_CART_AFTER_UPDATE = '@@cart/STORE_CART_AFTER_UPDATE',
  STORE_CART = '@@cart/STORE_CART',

  SET_QTY_UPDATE_FALSE = '@@cart/SET_QTY_UPDATE_FALSE',

  UPDATE_ITEM_INPUT_VALUES = '@@cart/UPDATE_ITEM_INPUT_VALUES',

  RESET_CART_ERRORS = '@@cart/RESET_CART_ERRORS',

  ADD_PROMOCODE = '@@cart/ADD_PROMOCODE',
  DELETE_PROMOCODE = '@@cart/DELETE_PROMOCODE',
  PROMOCODE_ERROR = '@@cart/PROMOCODE_ERROR',
  START_LOADER = '@@cart/START_LOADER',

	CHECK_CART_ITEMS_NO_PRICE = '@@cart/CHECK_CART_ITEMS_NO_PRICE',
	STORE_CART_ITEMS_NO_PRICE = '@@cart/STORE_CART_ITEMS_NO_PRICE',

  REMOVE_CART = '@@cart/REMOVE_CART',
  REMOVE_CART_SUCCESS = '@@cart/REMOVE_CART_SUCCESS',
}

interface IItemInputValue {
  itemId: string;
  topValue: number;
  bottomValue: number;
  itemPrice: number;
}

export interface CartState {
  data: any; // TODO put appropriate type
  loading: boolean;
  error: IError | null;
  promocodeError: IError | null;
  cartUpdated: boolean;
  cartItemQtyUpdated: boolean;
  itemsInputValues: IItemInputValue[];
	cartItemsNoPrice: CartItemsNoPrice[];
  addToCartError: boolean;
  addToTruckSuccess: boolean;
  addedToTruckItem: {
    item: ProductVariantClass | null
    quantity: number
  };
  storeCartFirstLoadSuccess: boolean;
}

export interface RequestAddProductAction {
  type: CartActionTypes.ADD_PRODUCT;
  payload: {
    addProductToCart; // TODO put appropriate type
  };
}

export interface RequestChangeProductQtyAction {
  type: CartActionTypes.CHANGE_PRODUCT_QTY;
  payload: {
    cartItemId: number;
    qty: number;
  };
}

export interface RequestCartSummaryAction {
  type: CartActionTypes.GET_CART_SUMMARY;
  payload: {
    addressId: number;
  };
}

export interface RequestDeleteProductAction {
  type: CartActionTypes.DELETE_PRODUCT;
  payload: {
    cartItemId: string;
  };
}

export interface RequestAddPromocodeAction {
  type: CartActionTypes.ADD_PROMOCODE;
  payload: {
    id: string;
    promoCode: string;
  };
}

export interface RequestDeletePromocodeAction {
  type: CartActionTypes.DELETE_PROMOCODE;
  payload: {
    id: string;
  };
}

export interface IRequestCheckCartItemsNoPriceAction {
	type: CartActionTypes.CHECK_CART_ITEMS_NO_PRICE,
	payload: {
		cartId: number;
		deliveryType: string;
	}
}

export interface CartItemsNoPrice {
	id: number;
	name: string;
	sku: string;
	image: string;
}
