import {useState, useCallback} from 'react';

interface ISort {
  'order[name]'?: string;
  'order[price]'?: string;
  'order[search_rate]'?: string;
}

const useSortType = (initialValue: ISort = {}, choosenSortType: number = 6) => {
  // we need this declaration because there are two SortDropdown Components (mobile/desktop)
  // and we need to save state between them
  const [chosenSortType, setChosenSortType] = useState(choosenSortType);
  const [sortType, setSortType] = useState<ISort>(initialValue);

  const sortProducts = useCallback(
    (value: number) => {
      setChosenSortType(value);
      switch (value) {
        case 1: {
          setSortType({
            'order[search_rate]': 'desc',
          });
          break;
        }

        case 2: {
          setSortType({
            'order[price]': 'asc',
          });
          break;
        }

        case 3: {
          setSortType({
            'order[price]': 'desc',
          });
          break;
        }

        case 4: {
          setSortType({
            'order[name]': 'asc',
          });
          break;
        }

        case 5: {
          setSortType({
            'order[name]': 'desc',
          });
          break;
        }

        case 6: {
          setSortType({});
          break;
        }

        default:
          break;
      }
    },
    [setSortType]
  );

  const setValueSortType = (value) => {
    setSortType(value)
  }
  const setValueChooseSortType = (value) => {
    setChosenSortType(value)
  }

  return { chosenSortType, sortType, sortProducts, setValueSortType, setValueChooseSortType };
};

export default useSortType;
