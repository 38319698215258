import React, { useMemo } from 'react';

import { useGlobalBanner } from 'hooks/useGlobalBanner';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { selectBranch } from 'ducks/branch/selectors';
import { StyledGrid, MenuContentGrid } from './styles';

const GlobalBannerNotification = () => {
  const branch = useSelector(selectBranch);
  const {data: globalBanner, isLoading} = useGlobalBanner(branch?.id);

  const renderGlobalBanner = useMemo(() => {
    if (!isEmpty(globalBanner)) {
      return globalBanner.text;
    }
    return null;
  }, [globalBanner]);

  if (isLoading) return null;

  if (renderGlobalBanner) {
    return (
      <StyledGrid container variant="warning">
        <MenuContentGrid item variant="warning">
          {renderGlobalBanner}
        </MenuContentGrid>
      </StyledGrid>
    );
  }

  return null;
};

export { GlobalBannerNotification };
