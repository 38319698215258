import styled from 'styled-components/macro';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { MediumButton } from 'components/common/Buttons/MediumButton';
import { withStyles } from '@material-ui/core/styles';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { PasswordField } from 'components/common';

const StyledGrid = styled(Grid)`
  padding: 0;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 100px;
  @media (max-width: 737px) {
    padding-top: 9px;
    padding-bottom: 38px;
  }
`;

const StyledCard = styled(Card)`
  max-width: 440px;
  margin: 0 auto;

  @media (max-width: 737px) {
    margin: 0 10px;
  }
`;

const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#00853e',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const StyledButton = withStyles({
  root: {
    textAlign: 'center',
    width: '100%',
    height: '48px',
    backgroundColor: '#00853e',
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    letterSpacing: '0.01em',
    fontWeight: 'bold',
    color: '#FFFFFF',
    '&$disabled': {
      color: '#FFFFFF',
    },
    '&:hover': {
      backgroundColor: '#37875F',
    },
  },
  disabled: {
    backgroundColor: '#DADCE0',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const StyledCardContent = styled(CardContent)`
  padding-left: 60px;
  padding-top: 0;
  padding-right: 60px;
  @media (max-width: 737px) {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 7px;
  }
`;

const StyledCardActions = styled(CardActions)`
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 10px;

  @media (max-width: 737px) {
    padding-top: 0;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  padding-top: 52px;
  padding-left: 60px;
  padding-bottom: 26px;

  div > span {
    font-family: Open Sans, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 0.01em;
    @media (max-width: 737px) {
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
    }
  }
  @media (max-width: 737px) {
    padding-top: 20px;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const StyledTextField = withStyles({
  root: {
    paddingBottom: '24px',
    margin: '0',
    '& label.Mui-focused': {
      color: '#00853e',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#00853e',
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#C4C4C4',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00853e',
        },
      },
    },
  },
})(TextField);

const StyledPasswordField = withStyles({
  root: {
    paddingBottom: '4px',
    '& label.Mui-focused': {
      color: '#00853e',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#00853e',
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#C4C4C4',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00853e',
        },
      },
    },
  },
})(PasswordField);

const StyledForgotLink = styled(RouterLink)`
  color: #00853e;
  cursor: pointer;
  padding-bottom: 21px;
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-decoration: none;
  @media (max-width: 737px) {
    padding-bottom: 16px;
  }
`;

const StyledRequestLink = styled(RouterLink)`
  color: #00853e;
  cursor: pointer;
  padding-bottom: 21px;
  font-family: Open Sans, serif;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-decoration: none;
  @media (max-width: 737px) {
    font-size: 14px;
    line-height: 19px;
  }
`;

const StyledTypography = styled(Typography)`
  padding-top: 32px;
  padding-bottom: 29px;
  font-family: Open Sans, serif;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: center;
  width: 100%;
  @media (max-width: 737px) {
    padding-top: 24px;
    padding-bottom: 16px;
    font-size: 14px;
    line-height: 19px;
  }
`;

const PasswordFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiTypography-body1 {
    font-family: Open Sans, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
  }
`;

const StyledRecaptchaTokenError = styled.p`
	font-size: 14px;
	color: #f44336;
`;


const Or = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  margin: 8px 0;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    background: #C2CAD6;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #48576B;
    background: #fff;
    padding: 0 4px;
  }
`;

const LoginWithoutPasswordButton = withStyles({
  root: {
    height: '47px',
    width: '100%',
    borderRadius: '4px',
    color: '#00853e',
    border: '1px solid #00853e',
    backgroundColor: 'transparent',
    transition: 'all 0.4s ease',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#00853e',
      '&.Mui-disabled': {
        color: '#00853e',
        borderColor: '#00853e',
        opacity: '50%',
      },
    },
    '&.Mui-disabled': {
      borderColor: '#00853e',
      opacity: '50%',
    },
    '& svg': {
      marginLeft: '8px',
    },
  },
})(MediumButton);


export {
  StyledButton,
  StyledCard,
  StyledCardActions,
  StyledCardContent,
  StyledCardHeader,
  StyledForgotLink,
  StyledFormControlLabel,
  StyledGrid,
  StyledPasswordField,
  StyledRequestLink,
  StyledTextField,
  StyledTypography,
  PasswordField,
  PasswordFieldWrapper,
  GreenCheckbox,
  StyledRecaptchaTokenError,
  Or,
  LoginWithoutPasswordButton
};
