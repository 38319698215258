import formatISO from 'date-fns/formatISO';

export interface IStorageData {
  closed_nps?: string;
  closed_survey?: string;
  new_order?: boolean;
  product_page_views?: number;
}

export class SurveyStorage {
  private static readonly LOCAL_STORAGE_NAME = 'survey';

  public static getStorageData = (): string => {
    return localStorage.getItem(SurveyStorage.LOCAL_STORAGE_NAME);
  };

  public static getStorage = (): IStorageData => {
    return JSON.parse(localStorage.getItem(SurveyStorage.LOCAL_STORAGE_NAME)) || [];
  };

  public static saveStorage = (data: Partial<IStorageData>) => {
    const storage = SurveyStorage.getStorage();
    localStorage.setItem(
      SurveyStorage.LOCAL_STORAGE_NAME,
      JSON.stringify({
        ...storage,
        ...data,
      })
    );
  };

  public static setClosedUserSurvey = () => {
    const now = formatISO(new Date());
    SurveyStorage.saveStorage({ closed_survey: now });
  };

  public static setClosedNps = () => {
    const now = formatISO(new Date());
    SurveyStorage.saveStorage({ closed_nps: now });
  };

  public static isClosedUserSurvey = (): string | null => {
    const storage = SurveyStorage.getStorage();
    return storage?.closed_survey || null;
  };

  public static isClosedNps = (): string | null => {
    const storage = SurveyStorage.getStorage();
    return storage?.closed_nps || null;
  };

  public static setNewOrderFlag = () => {
    SurveyStorage.saveStorage({ new_order: true });
  };

  public static removeNewOrderFlag = () => {
    SurveyStorage.saveStorage({ new_order: false });
  };

  public static isNewOrder = (): boolean | null => {
    const storage = SurveyStorage.getStorage();
    return Boolean(storage?.new_order);
  };

  public static setProductPageView = () => {
    const storage = SurveyStorage.getStorage();
    const views = Number(storage?.product_page_views) || 0;
    SurveyStorage.saveStorage({ product_page_views: views + 1 });
  };

  public static getProductPageView = (): number => {
    const storage = SurveyStorage.getStorage();
    return storage?.product_page_views || 0;
  };
}
