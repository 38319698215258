import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import languageEN from './locales/en/translate.json';
import languageFR from './locales/fr/translate.json';

export const getShortLanguageCode = (code: string): string => {
  return code.includes('fr') ? 'fr' : 'en';
};

export const getFullLanguageCode = (code: string): string => {
  return code === 'fr_CA' ? 'fr_CA' : 'en_CA';
};

export const getFullLanguage = (code: string): string => {
  return code === 'fr' ? 'fr_CA': 'en_CA';
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: languageEN,
      fr: languageFR,
    },
    /* default language when load the website in browser */
    /* When react i18next not finding any language to as default in borwser */
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr'],
    /* debugger For Development environment */
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
  });

export default i18n;
