import React from 'react';
import { useTranslation } from 'react-i18next';

import { Taxes } from 'assets/svg/Profile/Taxes';
import { Delivery } from 'assets/svg/Profile/Delivery';
import { Purse } from 'assets/svg/Profile/Purse';
import { addDotToPrice } from 'utils/common/common';
import { OrderClass } from 'models/classes/orderClass';
import { QuoteClass } from 'models/classes/quoteClass';
import { NotesIcon } from 'assets/svg/Profile/OrdersAndQuotes';
import {getAddressStringOrder} from 'utils/profile/profileUtilsHelpers';
import {usePermission} from 'hooks/usePermission';
import {useSelector} from 'react-redux';
import { selectIsHidePrice } from 'ducks/application/selectors';
import {
  SvgWrapper,
  Label,
  NotesBlock,
  PaymentInfoBlock,
  Price,
  TaxesName,
  TaxesSingleItem,
  TaxesBlockItem,
  TaxesBlock,
  NotesText,
} from './styles';

interface IPaymentInformationBlock {
  element: OrderClass | QuoteClass;
  isQuotes: boolean;
  expanded: boolean;
}

const PaymentInformationBlock: React.FC<IPaymentInformationBlock> = ({
  element,
  isQuotes,
  expanded
}: IPaymentInformationBlock) => {
  const { t } = useTranslation();
  const {canSeePrice} = usePermission();
  const isHidePrice = useSelector(selectIsHidePrice);
  return (
    <>
      {element.taxTotal !== 0 && canSeePrice && !isHidePrice && expanded &&  (
        <TaxesBlock>
          <SvgWrapper>
            <Taxes />
          </SvgWrapper>
          <TaxesBlockItem>
            <TaxesSingleItem>
              <TaxesName>{t('orders_and_quotes.goods_total').toString()}</TaxesName>
              <Price>${addDotToPrice(String(element.subTotal))}</Price>
            </TaxesSingleItem>
            {element.adjustments.length > 0 &&
              element.adjustments.map(tax =>
                tax.label && tax.amount ? (
                  <TaxesSingleItem key={tax.id}>
                    <TaxesName>{tax.label}</TaxesName>
                    <Price>${addDotToPrice(String(tax.amount))}</Price>
                  </TaxesSingleItem>
                ) : null
              )}
            <TaxesSingleItem>
              <Label>{t('orders_and_quotes.order_total').toString()}</Label>
              <Price bold>
                $
                {addDotToPrice(String(element.total))}
              </Price>
            </TaxesSingleItem>
          </TaxesBlockItem>
        </TaxesBlock>
      )}

      <PaymentInfoBlock>
        <SvgWrapper>
          <Delivery />
        </SvgWrapper>
        <div>
          <Label>{t(`checkout.${element.pricing === 'pickup' ? 'pick_up' : 'delivery'}`).toString()}</Label>
          <div>{getAddressStringOrder(element.shippingAddress)}</div>
        </div>
      </PaymentInfoBlock>

      <PaymentInfoBlock>
        <SvgWrapper>
          <Purse />
        </SvgWrapper>
        <div>
          <Label>{t('checkout.transaction_type').toString()}</Label>
          <div>
            {isQuotes ? t('checkout.submitted_as_quote').toString() : t('checkout.submitted_as_order').toString()}
          </div>
        </div>
      </PaymentInfoBlock>

      <NotesBlock>
        <SvgWrapper>
          <NotesIcon />
        </SvgWrapper>
        <div>
          <Label>{t('orders_and_quotes.notes').toString()}</Label>
          <NotesText>{element.note}</NotesText>
        </div>
      </NotesBlock>

      {/* <DocumentsBlock>
            {element.orderDocuments.map(doc => {
              return (
                <DocumentItem>
                  <Document />
                  <div>
                    <DocumentItemName>{doc.docName}</DocumentItemName>
                    <DocumentItemSize>{doc.docSize}</DocumentItemSize>
                  </div>
                </DocumentItem>
              );
            })}
          </DocumentsBlock> */}
    </>
  );
};

export default PaymentInformationBlock;
