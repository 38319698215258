import React from 'react';
import { useTranslation } from 'react-i18next';

import { SalesRepresentativeClass } from 'models/classes/salesRepresentative';

import { useSelector } from 'react-redux';
import { selectInquireNowOrderHistory } from 'ducks/order/selectors';
import {
  PopUpHeaderSmall as PopUpHeader,
  PopUpTextSmall as PopUpText,
  StyledButtonSmall as StyledButton,
} from '../styles';

interface ISubmitEmailSent {
  handleClose: Function;
  salesRepresentative: SalesRepresentativeClass;
}

const SubmitEmailSentInquireNow: React.FC<ISubmitEmailSent> = ({
  handleClose,
  salesRepresentative,
}: ISubmitEmailSent) => {

  const inquireNowOrderHistory = useSelector(selectInquireNowOrderHistory);

  const representativeName = salesRepresentative.name;
  const { t } = useTranslation();

  return (
    <>
      <PopUpHeader>{t('request_pricing_popup.thank_you')}</PopUpHeader>
      <PopUpText>
        {t('request_pricing_popup.representative_will_answer', { representativeName })}
      </PopUpText>
      <StyledButton onClick={() => handleClose()}>
        <p>{inquireNowOrderHistory.type === 'order' ? t('profile.back_to_order_history') : t('profile.back_to_quote_history')}</p>
      </StyledButton>
    </>
  );
};

export default SubmitEmailSentInquireNow;
