import React from 'react';

export const Purse = () => {
  return (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 3.39771C0 1.74085 1.34315 0.397705 3 0.397705H17C18.6569 0.397705 20 1.74085 20 3.39771V12.3583C20 14.0152 18.6569 15.3583 17 15.3583H3C1.34315 15.3583 0 14.0152 0 12.3583V3.39771Z"
        fill="#939599"
      />
      <rect x="12" y="5.38452" width="10" height="4.98688" rx="2.49344" fill="white" />
      <ellipse cx="15.5" cy="7.8779" rx="1.5" ry="1.49606" fill="#939599" />
    </svg>
  );
};
