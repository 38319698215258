// Classes
import { UserClass } from 'models/classes/userClass';
import { IErrors } from 'models/interfaces/error';

export enum AuthActionTypes {
	REQUEST = '@@auth/REQUEST',

	VERIFY_TOKEN = '@@auth/VERIFY_TOKEN',
	VERIFY_TOKEN_SUCCESS = '@@auth/VERIFY_TOKEN_SUCCESS',
	VERIFY_TOKEN_ERROR = '@@auth/VERIFY_TOKEN_ERROR',

	LOGIN = '@@auth/LOGIN',
	LOGIN_SUCCESS = '@@auth/LOGIN_SUCCESS',
	LOGIN_ERROR = '@@auth/LOGIN_ERROR',

	REQUEST_VALIDATE_RECAPTCHA = '@@auth/REQUEST_VALIDATE_RECAPTCHA',
	REQUEST_VALIDATE_RECAPTCHA_SUCCESS = '@@auth/REQUEST_VALIDATE_RECAPTCHA_SUCCESS',
	REQUEST_VALIDATE_RECAPTCHA_ERROR = '@@auth/REQUEST_VALIDATE_RECAPTCHA_ERROR',
	SHOW_OTP = '@@auth/SHOW_OTP',

	LOGOUT = '@@auth/LOGOUT',
	LOGOUT_SUCCESS = '@@auth/LOGOUT_SUCCESS',

	CHECK_AUTH = '@@auth/CHECK_AUTH',

	RESET_PASSWORD = '@@auth/RESET_PASSWORD',
	RESET_PASSWORD_SUCCESS = '@@auth/RESET_PASSWORD_SUCCESS',
	RESET_PASSWORD_ERROR = '@@auth/RESET_PASSWORD_ERROR',
	RESET_PASSWORD_CLEAR_STATE = '@@auth/RESET_PASSWORD_CLEAR_STATE',

	RECOVER_PASSWORD = '@@auth/RECOVER_PASSWORD',
	RECOVER_PASSWORD_SUCCESS = '@@auth/RECOVER_PASSWORD_SUCCESS',
	RECOVER_PASSWORD_ERROR = '@@auth/RECOVER_PASSWORD_ERROR',

	REMEMBER_ME = '@@auth/REQUEST_REMEMBER_ME',
	FORGET_ME = '@@auth/REQUEST_FORGET_ME',

	REQUEST_CHANGE_DISPLAY_LANGUAGE = '@@auth/REQUEST_CHANGE_DISPLAY_LANGUAGE',
	REQUEST_CHANGE_DISPLAY_LANGUAGE_SUCCESS = '@@auth/REQUEST_CHANGE_DISPLAY_LANGUAGE_SUCCESS',

	REQUEST_CHANGE_TOKEN = '@@auth/REQUEST_CHANGE_TOKEN',
	REQUEST_CREATE_MAGIC_LINK = '@@auth/REQUEST_CREATE_MAGIC_LINK',
	REQUEST_CREATE_MAGIC_LINK_SUCCESS = '@@auth/REQUEST_CREATE_MAGIC_LINK_SUCCESS',
	REQUEST_CREATE_MAGIC_LINK_ERROR = '@@auth/REQUEST_CREATE_MAGIC_LINK_ERROR',
	REQUEST_LOGIN_BY_MAGIC_LINK = '@@auth/REQUEST_LOGIN_BY_MAGIC_LINK',
	REQUEST_LOGIN_BY_MAGIC_LINK_SUCCESS = '@@auth/REQUEST_LOGIN_BY_MAGIC_LINK_SUCCESS',
	REQUEST_LOGIN_BY_MAGIC_LINK_ERROR = '@@auth/REQUEST_LOGIN_BY_MAGIC_LINK_ERROR',
	REQUEST_RELOAD_STATE_LOGIN_LINK = '@@auth/REQUEST_RELOAD_STATE_LOGIN_LINK',
}

export interface LoginAction {
	type: AuthActionTypes.LOGIN;
	payload: { email: string; password: string; recaptchaToken: string, otp: number, languageCode: string, fromReset?: boolean };
}

export interface ValidateRecaptchaTokenAction {
	type: AuthActionTypes.REQUEST_VALIDATE_RECAPTCHA;
	payload: { email: string, recaptchaToken: string };
}

export interface VerifyTokenAction {
	type: AuthActionTypes.VERIFY_TOKEN;
	payload: {
		token: string;
		email: string;
	};
}

export interface ResetPasswordAction {
	type: AuthActionTypes.RESET_PASSWORD;
	payload: {
		email: string;
	};
}

export interface RecoverPasswordAction {
	type: AuthActionTypes.RECOVER_PASSWORD;
	payload: {
		password: string;
		recoveryCode: string;
		email: string;
		recaptchaToken: string;
		otp?: number;
	};
}

export interface ChangeDisplayLanguageAction {
	type: AuthActionTypes.REQUEST_CHANGE_DISPLAY_LANGUAGE,
	payload: {
		languageCode: string;
	};
}

export interface CreateMagicLinkAction {
	type: AuthActionTypes.REQUEST_CREATE_MAGIC_LINK,
	payload: {
		email: string;
	}
}

export interface LoginByMagicLinkAction {
	type: AuthActionTypes.REQUEST_LOGIN_BY_MAGIC_LINK,
	payload: {
		token: string;
	}
}

export interface AuthState {
	readonly loading: boolean;
	readonly authenticated: boolean;
	readonly error: IErrors | null;
	readonly signUpSucceeded: boolean;
	readonly resetPasswordRequestSent: boolean;
	readonly recoverPasswordSucceeded: boolean;
	readonly remembered: boolean;
	readonly user: UserClass | null;
	readonly showOtp: boolean;
	readonly checkedOtp: boolean;
	readonly isChangedDisplayLanguage: boolean;
	readonly isRefreshToken: boolean;
	readonly createMagicLinkLoading: boolean;
	readonly createMagicLinkStatus: string;
	readonly createMagicLinkErrorMessages: string;
	readonly loginByMagicLinkLoading: boolean;
	readonly loginByMagicLinkErrorMessage: string;
	readonly loginByMagicLinkStatus: string;
}
