import { AxiosRequestConfig } from 'axios';
import { CookieStorage } from 'cookie-storage';
import { UserClass } from 'models/classes/userClass';
import axios, { baseURL } from './axios';

export class TokenStorage {
  private static readonly LOCAL_STORAGE_TOKEN = 'accessToken';

  private static readonly LOCAL_STORAGE_REFRESH_TOKEN = 'refreshToken';

  public static isAuthenticated(): boolean {
    return this.getToken() !== null;
  }

  private static getStorage(): Storage {
    if (TokenStorage.checkIfRemembered()) {
      return new CookieStorage({ path: '/', sameSite: 'Lax' });
    }
    return sessionStorage;
  }

  public static getAuthentication(): AxiosRequestConfig {
    return {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    };
  }

  public static getNewToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${baseURL}/api/auth/refresh-token`,
          { refresh_token: this.getRefreshToken() },
          {
            headers: {
              Accept: 'application/json',
              Authorization: '',
            },
          }
        )
        .then(response => {
          this.storeToken(response.data.token);
          this.storeRefreshToken(response.data.refresh_token);

          resolve(response.data.token);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  public static storeToken(token: string): void {
    this.getStorage().setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token);
  }

  public static storeRefreshToken(refreshToken: string): void {
    if (refreshToken) {
      this.getStorage().setItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
    }
  }

  public static clear(): void {
    this.deleteCookie(TokenStorage.LOCAL_STORAGE_TOKEN);
    this.deleteCookie(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
    sessionStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    sessionStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
    localStorage.removeItem('address-cache');
  }

  private static getRefreshToken(): string | null {
    return this.getStorage().getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  public static getToken(): string | null {
    return this.getStorage().getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
  }

  public static currentUser(): UserClass {
    return new UserClass();
  }

  public static checkIfRemembered() {
    const rememberMe = localStorage.getItem('rememberMe');
    return !!rememberMe;
  }

  public static remember() {
    localStorage.setItem('rememberMe', 'true');
  }

  public static forget() {
    localStorage.removeItem('rememberMe');
  }

  private static deleteCookie(cookieName) {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
}
