import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Banner, Video } from 'components/common';

import { selectSlider } from 'ducks/slider/selectors';
import { menuIsOpenedSelector } from 'ducks/application/selectors';
import { StyledGrid, StyledGridItem, StyledSlider } from './styles';

const SliderContainer: React.FC = () => {
  const mediaArr = useSelector(selectSlider);
  const isOpened = useSelector(menuIsOpenedSelector);
  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderOptions = {
    autoplay: true,
    autoplayInterval: 7000,
    wrapAround: true,
    renderCenterLeftControls: () => <></>,
    renderCenterRightControls: () => <></>,
    defaultControlsConfig: {
      pagingDotsStyle: {
        fill: 'white',
      },
    },
    slideIndex: currentSlide,
    afterSlide: index => {
      setCurrentSlide(index);
    },
  };
  const isCurrentSlide = (index): boolean => currentSlide === index;

  return !isOpened && mediaArr && mediaArr.length ? (
    <StyledGrid container justify="center" alignItems="center">
      <StyledGridItem item xs={12} className={mediaArr.length === 1 ? 'length-1' : ''}>
        {mediaArr.length === 1 && mediaArr[0].type === 'banner' && (
          <Banner key={mediaArr[0].id} url={mediaArr[0].url} path={mediaArr[0].path} />
        )}

        {mediaArr.length === 1 && mediaArr[0].type !== 'banner' && (
          <div className="video">
            <Video
              name={mediaArr[0].name}
              url={mediaArr[0].url}
              width="1200"
              height="392"
              controls={false}
            />
          </div>
        )}

        {mediaArr.length > 1 && (
          <StyledSlider {...sliderOptions}>
            {mediaArr.map((media, index) =>
              media.type === 'banner' ? (
                <Banner key={media.id} url={media.url} path={media.path} />
              ) : (
                <div className="video" key={media.id}>
                  <Video
                    stopPlaying={!isCurrentSlide(index)}
                    name={media.name}
                    url={media.url}
                    width="1200"
                    height="392"
                    controls={false}
                  />
                </div>
              )
            )}
          </StyledSlider>
        )}
      </StyledGridItem>
    </StyledGrid>
  ) : null;
};
export default SliderContainer;
