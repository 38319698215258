import React, {useEffect, useRef, useState} from 'react';
import {Route, useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useMediaQuery} from '@material-ui/core';

import {authenticatedSelector} from 'ducks/auth/selectors';
import {checkAuth, reloadStateLoginLink, setIsChangeRefreshToken} from 'ducks/auth/actions';

import Routes from 'routes';
import {StoreComponent} from 'components';
import {check404error} from 'utils/errors';
import {selectCategories, selectCategoryError} from 'ducks/category/selectors';
import {
  selectClickedProductRequestPricing,
  selectClickProductRequestNearbyHubsAndBranch, selectNearbyBranchData,
  selectOpenPopupInquireNow, selectOpenPopupNearbyBranch,
  selectProductsError
} from 'ducks/product/selectors'; // which exact products? search ???
import {selectProductVariantsError} from 'ducks/productVariant/selectors';
import {
  priceTypeSelector, selectAddressId, selectErpCustomerAddressCode,
  serverErrorSelector
} from 'ducks/application/selectors';
import MobileHeader from 'components/Layout/Header/MobileHeader/MobileHeader';
import Header from 'components/Layout/Header/Header/Header';
import MobileFooter from 'components/Layout/Footer/MobileFooter';
import Footer from 'components/Layout/Footer/Footer';
import {selectUserData} from 'ducks/user/selectors';
import NearbyBranchProvider from 'context/NearbyBranchContext';
import ProductStockModal from 'pages/Product/ProductDetail/ProductStockModal/ProductStockModal';
import AddedToTruckModal from 'pages/Product/ProductDetail/AddedToTruckModal/AddedToTruckModal';
import {selectAddedToTruckItem, selectAddToTruckSuccess, selectCart} from 'ducks/cart/selectors';
import {setAddToTruckItem, setAddToTruckStatus} from 'ducks/cart/actions';
import {pushKlaviyoEvent, pushKlaviyoIdentity} from 'utils/klaviyo';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import {fireKlaviyoEventGetData, setKlaviyoEventStatus} from 'ducks/klaviyoEvent/actions';
import {selectKlaviyoEventData, selectKlaviyoEventStatus} from 'ducks/klaviyoEvent/selectors';
import {
  selectQuickOrderAddToCartStatus,
} from 'ducks/quickOrder/selectors';
import {setQuickOrderAddToCartStatus} from 'ducks/quickOrder/actions';
import TagManager from 'react-gtm-module';
import {useRequestPricing} from 'hooks/useRequestPricing';
import {clearGtmEcommerce} from 'utils/clearGtmEcommerce';
import {getUserType} from 'utils/profile/profileUtilsHelpers';
import {selectBranch} from 'ducks/branch/selectors';
import { useLanguage } from 'hooks/useLanguage';
import { getDefaultCategoryName } from 'utils/categories/categories';
import size from 'lodash/size';
import { getDefaultProductName } from 'utils/products/productUtilsHelpers';
import ProductFilterProvider from 'context/ProductFilterContext';
import RequestNearbyBranchPopup from '../../Popups/RequestNearbyBranchPopup/RequestNearbyBranchPopup';
import CustomerMaintainNotification from '../Notification/CustomerMaintainNotification';
import {getShortLanguageCode} from '../../../i18n';
import RequestPricingPopup from '../../Popups/RequestPricing/RequestPricingPopup';

type Props = {
	component: React.ComponentType<any>;
	withContainer?: boolean;
	[key: string]: unknown;
};

const StoreRoutes: React.FC<Props> = ({
  component: Component,
  withContainer = true,
  ...rest
}: Props) => {
  const history = useHistory();
  const user = useSelector(selectUserData);
  const isAuthenticated = useSelector(authenticatedSelector);
  const serverError = useSelector(serverErrorSelector);
  const categoryError = useSelector(selectCategoryError);
  const productError = useSelector(selectProductsError);
  const productVariantsError = useSelector(selectProductVariantsError);
  const isOpenPopupInquireNow = useSelector(selectOpenPopupInquireNow);
  const productNearbyBranch = useSelector(selectClickProductRequestNearbyHubsAndBranch);
  const isOpenPopupNearbyBranch = useSelector(selectOpenPopupNearbyBranch);
  const nearbyBranchData = useSelector(selectNearbyBranchData);
  const addToTruckSuccess = useSelector(selectAddToTruckSuccess);
  const addedToCartItem = useSelector(selectAddedToTruckItem);
  const klaviyoStatus = useSelector(selectKlaviyoEventStatus);
  const klaviyoData = useSelector(selectKlaviyoEventData);
  const cart = useSelector(selectCart);
  const categories = useSelector(selectCategories);
  const priceType = useSelector(priceTypeSelector);
  const currentLang = useLanguage();
  const clickedProductRequestPricing = useSelector(selectClickedProductRequestPricing);
  const location = useLocation();
  const dispatch = useDispatch();
  const [isRefreshed, setRefreshed] = useState(true);
  const [isFireKlavyoEvent, setIsFireKlavyoEvent] = useState(false);
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const isFiredEvent = useRef(false);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const quickOrderAddToCartStatus = useSelector(selectQuickOrderAddToCartStatus);

  const {setIsRequestPricing} = useRequestPricing(
    clickedProductRequestPricing,
  );

  useEffect(() => {
    dispatch(checkAuth());
    dispatch(reloadStateLoginLink());
  });

  useEffect(() => {
    if (user && branch && selectedAddressId && erpCustomerAddressCode && !isFiredEvent.current) {
      if (user.customer) {
        clearGtmEcommerce()
        isFiredEvent.current = true;
        TagManager.dataLayer({
          dataLayer: {
            event: 'userLogin',
            user_ID: user.id,
            user_type: getUserType(user.email),
            branch_id: branch.id,
            customer_code: user?.customer?.erpCustomerCode,
            address_code: erpCustomerAddressCode,
            address_id: selectedAddressId,
            method: user.loginMethod === 'form_submit' ? 'Form Submit' : 'Magic Link',
          }
        });
      }
      dispatch(setIsChangeRefreshToken(false));
    }
  }, [dispatch, selectedAddressId, erpCustomerAddressCode, user, branch]);

  useEffect(() => {
    if (addToTruckSuccess && get(addedToCartItem, 'item', null)) {
      dispatch(fireKlaviyoEventGetData({productId: addedToCartItem.item.id, quantity: addedToCartItem.quantity}))
      setTimeout(() => {
        dispatch(setAddToTruckStatus(false));
        dispatch(setAddToTruckItem(null, null));
      }, 1000);
    }
  }, [dispatch, addToTruckSuccess, addedToCartItem]);

  useEffect(() => {
    if (addToTruckSuccess && !addedToCartItem) {
      setTimeout(() => {
        dispatch(setAddToTruckStatus(false));
      }, 1000);
    }
  }, [dispatch, addToTruckSuccess, addedToCartItem]);

  useEffect(() => {
    if (klaviyoStatus && cart && categories) {
      const addedCategories = cart?.items.map(item => {
        const itemParentCategory = categories.find(category => category.id === item.category.parentId);
        return [itemParentCategory.name, item.categoryName]
      });
      const addedItemsName = cart?.items.map(item => !isEmpty(item.variant.name) ? item.variant.name : item.variant.erpSku);
      const total = cart?.items.reduce((acc, item) => acc + item.total, 0);
      const items = cart.items.map(item => {
        let itemPrice;
        switch (priceType) {
          case 'delivery':
            itemPrice = item.productDeliveryPrice;
            break;
          case 'pickup':
            itemPrice = item.productPickupPrice;
            break;
          default:
            itemPrice = 0;
            break;
        }
        const itemParentCategory = categories.find(category => category.id === item.category.parentId);
        return {
          ProductID: item.variant.erpProductId,
          SKU: item.variant.erpSku,
          ProductName: !isEmpty(item.variant.name) ? item.variant.name : item.variant.erpSku,
          Quantity: item.quantity,
          ItemPrice: parseFloat(String(itemPrice)),
          RowTotal: item.total,
          ProductURL: `${window.location.origin}/${getShortLanguageCode(currentLang)}/product/${item.variant.erpProductId}`,
          ImageURL: item.variant.images[0]?.url || '',
          ProductCategories: [itemParentCategory.name, item.categoryName],
        }
      });
      let price;
      switch (priceType) {
        case 'delivery':
          price = klaviyoData.cartItem.productDeliveryPrice;
          break;
        case 'pickup':
          price = klaviyoData.cartItem.productPickupPrice;
          break;
        default:
          price = 0;
          break;
      }
      pushKlaviyoEvent('Added to Cart', {
        '$value': total,
        'AddedItemProductName': !isEmpty(klaviyoData.product.name) ? klaviyoData.product.name : klaviyoData.product.erpSku,
        'AddedItemProductID': klaviyoData.product.erpProductId,
        'AddedItemSKU': klaviyoData.product.erpSku,
        'AddedItemCategories': uniq(flatten(addedCategories)),
        'AddedItemImageURL': klaviyoData.product.image,
        'AddedItemURL': `${window.location.origin}/${getShortLanguageCode(currentLang)}/product/${klaviyoData.product.erpProductId}`,
        'AddedItemPrice': parseFloat(String(price)),
        'AddedItemQuantity': klaviyoData.quantity,
        'ItemNames': uniq(addedItemsName),
        'CheckoutURL': `${window.location.origin}/${getShortLanguageCode(currentLang)}/checkout`,
        'Items': items
      });
      dispatch(setKlaviyoEventStatus(false));
    }
  }, [klaviyoStatus, klaviyoData, cart, categories, priceType, currentLang, addToTruckSuccess, dispatch]);

  useEffect(() => {
    if (quickOrderAddToCartStatus && cart && size(cart.items)) {
      const cartItems = get(cart, 'items', []);
      const total = cartItems.reduce((acc, item) => acc + item.total, 0);
      const addedCategories = cartItems.map(item => item.categoryName);
      const addedItemsName = cartItems.map(item => item.variant.name);
      const items = cartItems.map(item => ({
        ProductID: item.variant.erpProductId,
        SKU: item.variant.erpSku,
        ProductName: item.variant.name,
        Quantity: item.quantity,
        ItemPrice: item.variant.prices[0][priceType],
        RowTotal: item.total,
        ProductURL: `${window.location.origin}/${currentLang}/product/${item.variant.erpProductId}`,
        ImageURL: item.variant.images[0]?.url || '',
        ProductCategories: [item.categoryName],
      }));
      const successAddedToCartItems = [];
      cart.items.map(p => {
        const item = cartItems.find(cItem => cItem.productId === p.productId);
        if (item) {
          let price = 0;
          switch (priceType) {
            case 'delivery':
              price = item.productDeliveryPrice;
              break;
            case 'pickup':
              price = item.productPickupPrice;
              break;
            default:
              price = 0;
              break;
          }
          pushKlaviyoEvent('Added to Cart', {
            '$value': total,
            'AddedItemProductName': item.variant.name,
            'AddedItemProductID': item.variant.erpProductId,
            'AddedItemSKU': item.variant.erpSku,
            'AddedItemCategories': uniq(addedCategories),
            'AddedItemImageURL': item.productImage,
            'AddedItemURL': `${window.location.origin}/${currentLang}/product/${item.variant.erpProductId}`,
            'AddedItemPrice': price,
            'AddedItemQuantity': p.quantity,
            'ItemNames': uniq(addedItemsName),
            'CheckoutURL': `${window.location.origin}/${currentLang}/checkout`,
            'Items': items
          });
          const { itemCategoryName, itemCategory2Name } = getDefaultCategoryName(
            item.categoryId,
            categories
          );
          successAddedToCartItems.push({
            item_id: item.variant.erpSku,
            item_name: getDefaultProductName(item.variant),
            item_category: itemCategoryName,
            item_category2: itemCategory2Name,
            quantity: p.quantity,
            price,
          });
        }
        return p;
      });
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'add_to_cart',
          ecommerce: {
            items: successAddedToCartItems,
          },
        },
      });
      setTimeout(() => {
        dispatch(setQuickOrderAddToCartStatus(false));
      });
    }
  }, [quickOrderAddToCartStatus, cart, priceType, currentLang, dispatch, categories]);

  useEffect(() => {
    if (addToTruckSuccess) {
      setTimeout(() => {
        const elm = document.getElementsByTagName('body')

        if (elm) {
          elm[0].style.paddingRight = null
          elm[0].style.overflow = null
        }
      }, 200);
    }
  }, [addToTruckSuccess]);

  useEffect(() => {
    if (serverError) {
      history.replace(Routes.ERROR_500);
    }

    if (check404error([categoryError, productError, productVariantsError])) {
      history.replace(Routes.NOT_FOUND);
    }

    if (!isAuthenticated && !isRefreshed) {
      const locationState = location.state as { from: string };
      const redirectTo = locationState?.from || Routes.HOME;
      history.replace(redirectTo);
    } else if (isAuthenticated && isRefreshed) {
      const redirectTo = location.pathname || Routes.CATEGORIES;
      history.replace(redirectTo, location.state);
    }
    setRefreshed(false);
  }, [
    isAuthenticated,
    isRefreshed,
    location,
    history,
    categoryError,
    productError,
    productVariantsError,
    serverError,
  ]);

  useEffect(() => {
    if (user && !isFireKlavyoEvent) {
      pushKlaviyoIdentity(user.email)
      setIsFireKlavyoEvent(true);
    }
  }, [user, isFireKlavyoEvent])

  const isCheckoutRouter = location.pathname.includes(Routes.CHECKOUT);

  return (
    <>
      {isMobile ? <MobileHeader/> : <Header/>}
      {
        user && get(user, 'isMaintain', 0) === 1 && isCheckoutRouter && <CustomerMaintainNotification/>
      }
      {isAuthenticated && (
        <Route
          {...rest}
          render={props => (
            <StoreComponent withContainer={withContainer}>
              <NearbyBranchProvider>
                <AddedToTruckModal
                  open={addToTruckSuccess}
                />
                {
                  isOpenPopupInquireNow &&
                  <RequestNearbyBranchPopup product={productNearbyBranch}/>
                }
                {
                  isOpenPopupNearbyBranch && nearbyBranchData &&
                  <ProductStockModal
                    open={isOpenPopupNearbyBranch}
                    nearbyHubs={nearbyBranchData.nearbyHubs}
                    nearbyBranches={nearbyBranchData.nearbyBranches}
                    product={productNearbyBranch}
                  />
                }
                {(clickedProductRequestPricing) ? (
                  <RequestPricingPopup setRequestPricing={setIsRequestPricing} product={clickedProductRequestPricing}/>
                ) : null}
                <ProductFilterProvider>
                  <Component {...props} />
                </ProductFilterProvider>
              </NearbyBranchProvider>
            </StoreComponent>
          )}
        />
      )}
      {isMobile ? <MobileFooter/> : <Footer/>}
    </>
  );
};

export default StoreRoutes;
