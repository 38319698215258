import React, { useEffect, useRef } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { authenticatedSelector } from 'ducks/auth/selectors';
import { checkAuth } from 'ducks/auth/actions';

import Routes from 'routes';
import { AuthComponent } from 'components';
import {
  selectAddressId,
  selectErpCustomerAddressCode,
  serverErrorSelector,
} from 'ducks/application/selectors';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import TagManager from 'react-gtm-module';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';

type Props = {
  component: React.ComponentType<any>;
  [key: string]: unknown;
};

const recaptureKey = process.env.REACT_APP_GOOGLE_CAPTCHA_KEY;

const AuthRoutes: React.FC<Props> = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const isAuthenticated = useSelector(authenticatedSelector);
  const branch = useSelector(selectBranch);
  const serverError = useSelector(serverErrorSelector);
  const user = useSelector(selectUserData);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const location = useLocation();
  const dispatch = useDispatch();
  const isFiredEvent = useRef(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  useEffect(() => {
    return () => {
      isFiredEvent.current = false;
    };
  }, []);

  useEffect(() => {
    const email = query.get('email')?.replace(' ', '+');
    const token = query.get('token');
    if (email && token) {
      localStorage.removeItem('accessToken');
    }
    dispatch(checkAuth());
    if (serverError) {
      history.replace(Routes.ERROR_500);
    }

    if (
      isAuthenticated &&
      user &&
      branch &&
      selectedAddressId &&
      erpCustomerAddressCode &&
      !isFiredEvent.current
    ) {
      isFiredEvent.current = true;
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'userLogin',
          user_ID: user?.id ?? '',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
      const locationState = location.state as { from: string };
      const redirectTo = locationState?.from || Routes.CATEGORIES;
      history.replace(redirectTo);
    }
    if (user) {
      if (user.addresses.length === 0) {
        history.replace(Routes.ADDRESS_MISSING);
      }
    }
  }, [
    isAuthenticated,
    isFiredEvent.current,
    selectedAddressId,
    erpCustomerAddressCode,
    user,
    branch,
  ]);

  return !isAuthenticated ? (
    <Route
      {...rest}
      render={props => (
        <AuthComponent>
          <GoogleReCaptchaProvider reCaptchaKey={recaptureKey}>
            <Component {...props} />
          </GoogleReCaptchaProvider>
        </AuthComponent>
      )}
    />
  ) : null;
};

export default AuthRoutes;
