import styled from 'styled-components/macro';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import { MediumButton } from 'components/common/Buttons/MediumButton';

const QuickOrderPageWrapper = styled.div`
  padding: 48px 0 120px;
  .MuiBreadcrumbs-root {
    margin-bottom: 16px;
  }
  .title-page {
    color: #00133c;
    font-family: Open Sans;
    font-size: 32px;
    font-weight: bold;
    line-height: 44px; /* 137.5% */
    letter-spacing: 0.1px;
    font-style: normal;
    margin-bottom: 48px;
  }
`;

const QuickOrderTableWrapper = styled.div`
`;

const QuickOrderTable = styled.div`
  position: relative;
  overflow: visible;
  min-width: 1000px;
  width: 100%;
  padding: 1px;
  margin-bottom: 24px;
  [class*="corner-"] {
    width: 9px;
    height: 9px;
  }
  .corner-1 {
    position: absolute;
    left: 0;
    top: 0;
    border-top: 1px solid #C2CAD6;
    border-left: 1px solid #C2CAD6;
    border-radius: 8px 0 0 0;
  }
  .corner-2 {
    position: absolute;
    right: 0;
    top: 0;
    border-top: 1px solid #C2CAD6;
    border-right: 1px solid #C2CAD6;
    border-radius: 0 8px 0 0;
  }
  .corner-3 {
    position: absolute;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid #C2CAD6;
    border-right: 1px solid #C2CAD6;
    border-radius: 0 0 8px 0;
  }
  .corner-4 {
    position: absolute;
    left: 0;
    bottom: 0;
    border-bottom: 1px solid #C2CAD6;
    border-left: 1px solid #C2CAD6;
    border-radius: 0 0 0 8px;
  }
  .line-1 {
    position: absolute;
    left: 8px;
    right: 8px;
    top: 0;
    border-top: 1px solid #C2CAD6;
  }
  .line-2 {
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 0;
    border-left: 1px solid #C2CAD6;
  }
  .line-3 {
    position: absolute;
    left: 8px;
    right: 8px;
    bottom: 0;
    border-bottom: 1px solid #C2CAD6;
  }
  .line-4 {
    position: absolute;
    top: 8px;
    bottom: 8px;
    right: 0;
    border-right: 1px solid #C2CAD6;
  }
  table {
    position: relative;
    overflow: visible;
    border-radius: 8px;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    tr {
      th,
      td {
        border: 1px solid #C2CAD6;
      }
    }
    colgroup {
      col {
        &.col-item-sku-product {
          width: calc(85 / 600 * 100%);
        }
        &.col-product-name {
          width: calc(133 / 600 * 100%);
        }
        &.col-stock-status {
          width: calc(82 / 600 * 100%);
        }
        &.col-quantity {
          width: calc(100 / 600 * 100%);
        }
        &.col-price {
          width: calc(81 / 600 * 100%);
        }
        &.col-total-price {
          width: calc(77 / 600 * 100%);
        }
        &.col-action {
        }
      }
    }
    thead {
      border-radius: 8px 8px 0 0;
      tr {
        &:first-child {
          border-radius: 8px 8px 0 0;
        }
        th {
          color: #00133C;
          font-family: Open Sans;
          font-size: 16px;
          font-weight: 600;
          line-height: 28px; /* 175% */
          letter-spacing: 0.1px;
          font-style: normal;
          background: #f2f4f6;
          border-top: 0;
          padding: 18px 5px;
          &:first-child {
            border-radius: 8px 0 0 0;
            border-left: 0;
          }
          &:last-child {
            border-radius: 0 8px 0 0;
            border-right: 0;
          }
        }
      }
    }
    tbody {
      border-radius: 0 0 8px 8px;
      tr {
        &:last-child {
          border-radius: 0 0 8px 8px;
          td {
            border-bottom: 0;
            &:first-child {
              border-radius: 0 0 0 8px;
            }
            &:last-child {
              border-radius: 0 0 8px 0;
            }
          }
        }
        td {
          padding: 12px 16px;
          &:first-child {
            border-left: 0;
          }
          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }
`;

const TextWithPencil = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00133C;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.2px;
  border-radius: 4px;
  border: 1px solid #00133C;
  cursor: pointer;
  padding: 5px 8px;
  .text {
    flex: 1 1 calc(100% - 16px);
    width: calc(100% - 16px);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-right: 3px;
  }
  svg {
    flex: 1 1 16px;
    width: 16px;
    height: 16px;
  }
`;

const TextWaitingOnStock = styled.div`
  font-family: "Open Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: #007AFF;
  text-decoration: underline;
  cursor: pointer;
  margin: 0px;
`;

const TextError = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #001A3B;
    font-family: "Open Sans";
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    cursor: pointer;
    margin: 0;
`;

const TextInStock = styled.div`
    font-family: "Open Sans";
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #00853e;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    cursor: pointer;
    margin: 0;
`;

const TextPrice = styled.div`
  .md {
    .price {
      color: #00133C;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 175% */
      letter-spacing: 0.1px;
    }
    .price-lg {
      color: #00133C;
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 177.778% */
      letter-spacing: 0.1px;
    }
    .unit {
      color: #48576B;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
    }
  }
  .sm {
    color: #48576B;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }
  .line-through {
    text-decoration: line-through;
  }
`;

const CartProductInputsWrapper = styled.div`
  [class*="IncrementButtonLabel-"] {
    margin-top: 10px;
  }
  [class*="PromotionText-"] {
    font-size: 12px;
  }
  [class*="NextTierPriceText-"] {
    line-height: 1.2;
    margin-top: 5px;
  }
  .saving_wrapper {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    overflow: visible;
    color: #00133C;
    font-family: Open Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 160% */
    letter-spacing: 0.2px;
    margin-top: 6px;
    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 10px;
      bottom: 8px;
      transform-origin: right top;
      transform: translate(0, 100%) rotate(-9deg);
      width: 75px;
      height: 0;
      box-shadow: 0 0 7px 1px rgba(91, 91, 91, 1);
      z-index: 2;
    }
    &:after {
      display: block;
      content: '';
      position: absolute;
      right: 10px;
      bottom: 8px;
      transform-origin: left top;
      transform: translate(0, 100%) rotate(9deg);
      width: 75px;
      height: 0;
      box-shadow: 0 0 7px 1px rgba(91, 91, 91, 1);
      z-index: 2;
    }
    .saving_inner {
      display: block;
      position: relative;
      background: #eefef1;
      padding: 1px 4px;
      z-index: 3;
    }
    .saving_percent {
      font-weight: bold;
      font-size: 1.2em;
      color: ${({ theme: { colors } }) => colors.mainGreen};
    }
    .el-break {
      display: none;
    }
  }
`;

const SearchComponentWrapper = styled.div`
  > div {
    padding: 0;
  }
  .MuiInputBase-root {
    padding: 0;
  }
  .MuiOutlinedInput-input {
    padding: 10.5px 8px;
    padding-right: 26px;
  }
  .MuiInputAdornment-root {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0;
    .MuiSvgIcon-root {
      color: #48576B;
    }
  }
  .btn-clear {
    display: none;
  }
  .search-dropdown {
    .dropdown-wrapper {
      width: 510px;
      @media (max-width: 1023px) {
        width: 400px;
      }
    }
    .dropdown-menu {
      max-height: 524px;
    }
    .dropdown-view-all-button {
      display: none;
    }
  }
`;

const QuickOrderAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .col {
    display: flex;
    align-items: center;
  }
  .box-left {
    > * {
      margin-right: 8px;
    }
  }
`;

const DeleteButton = styled(Button)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 0;
  width: 32px;
  height: 32px;
  padding: 0;
  .MuiSvgIcon-root {
    font-size: 18px;
  }
`;

const RequestPricingButton = withStyles({
  root: {
    minWidth: 0,
    width: 'auto',
    height: '32px',
    fontFamily: 'Open Sans',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: '#007A33',
    background: '#fff',
    border: '1px solid #007A33',
    whiteSpace: 'nowrap',
    padding: '4px 8px',
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
    '&.MuiButton-label': {
      textTransform: 'none',
    },
  },
})(MediumButton);

const AddLineButton = withStyles({
  root: {
    width: 'auto',
    height: '44px',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px',
    letterSpacing: '0.1px',
    whiteSpace: 'nowrap',
    padding: '6px 16px',
    '@media (max-width: 1023px)': {
    },
    '& svg': {
      marginRight: '8px',
    },
  },
})(MediumButton);

const ClearAllButton = withStyles({
  root: {
    width: 'auto',
    height: '44px',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px',
    letterSpacing: '0.1px',
    color: '#00133C',
    background: '#fff',
    border: '1px solid #B2BECC',
    whiteSpace: 'nowrap',
    padding: '6px 16px',
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
    '&.MuiButton-label': {
      textTransform: 'none',
    },
    '&.Mui-disabled': {
      color: '#B2BECC !important',
    }
  },
})(MediumButton);

const FinalPriceTotal = styled.div`
  display: flex;
  align-items: center;
  .txt-total {
    color:  #00133C;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    letter-spacing: 0.2px;
    margin-right: 8px;
  }
  .txt-price {
    color:  #00133C;
    text-align: center;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 32px;
    letter-spacing: 0.1px;
  }
`;

const AddToCartButton = withStyles({
  root: {
    width: 'auto',
    height: '44px',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px',
    letterSpacing: '0.1px',
    padding: '6px 16px',
    whiteSpace: 'nowrap',
    marginLeft: '24px',
    '@media (max-width: 1023px)': {
    },
    '& svg': {
      width: '20px',
      marginRight: '8px',
      '& path': {
        fill: '#fff',
      },
    },
  },
})(MediumButton);

export {
  QuickOrderPageWrapper,
  QuickOrderTableWrapper,
  QuickOrderTable,
  TextWithPencil,
  TextWaitingOnStock,
  TextError,
  TextInStock,
  TextPrice,
  CartProductInputsWrapper,
  SearchComponentWrapper,
  QuickOrderAction,
  DeleteButton,
  RequestPricingButton,
  AddLineButton,
  ClearAllButton,
  FinalPriceTotal,
  AddToCartButton,
};
