import { Reducer } from 'redux';

// Types
import { UserActionTypes, UserState } from 'ducks/user/types';

const initialState: UserState = {
  loading: false,
  error: null,
  storePhoneNumber: null,
  updateShippingAddressSuccess: false,
  userData: null,
  isCreateUserSuccess: false,
  isCreateUserError: false,
  isCreateCustomerSuccess: false,
  isCreateSubscriberSuccess: false,
  isUpdateProfileSuccess: null,
  showRecaptchaModalRegister: false,
  showRecaptchaModalSubscriber: false,
};

const userReducer: Reducer<UserState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case UserActionTypes.REQUEST_STORE_PHONE_NUMBER: {
      return { ...state, loading: true, error: null };
    }
    case UserActionTypes.REQUEST_STORE_PHONE_NUMBER_SUCCESS: {
      return { ...state, error: null, loading: false, storePhoneNumber: payload.storePhoneNumber };
    }
    case UserActionTypes.REQUEST_STORE_PHONE_NUMBER_ERROR: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    }
    case UserActionTypes.SET_DEFAULT_PHONE_NUMBER: {
      return { ...state, error: null, loading: false, storePhoneNumber: payload.phoneNumber };
    }
    case UserActionTypes.REQUEST_FETCH_PROFILE: {
      return { ...state, loading: true, error: null };
    }
    case UserActionTypes.REQUEST_FETCH_PROFILE_SUCCESS: {
      return { ...state, error: null, loading: false, userData: payload.user };
    }
    case UserActionTypes.REQUEST_FETCH_PROFILE_ERROR: {
      return { ...state, error: payload.error, loading: false };
    }
    case UserActionTypes.REQUEST_PATCH_PROFILE: {
      return { ...state, loading: true, error: null };
    }
    case UserActionTypes.REQUEST_PATCH_PROFILE_SUCCESS: {
      return { ...state, error: null, loading: false, isUpdateProfileSuccess: 'Success' };
    }
    case UserActionTypes.REQUEST_UPDATE_USER_PROFILE_STATUS: {
      return { ...state, isUpdateProfileSuccess: payload.data }
    }
    case UserActionTypes.REQUEST_PATCH_PROFILE_ERROR: {
      return { ...state, error: payload.error, loading: false, isUpdateProfileSuccess: null };
    }
    case UserActionTypes.REQUEST_CREATE_USER_PROFILE: {
      return { ...state, loading: true, error: null, isCreateUserSuccess: false };
    }
    case UserActionTypes.FINISH_USER_PROFILE_CREATE: {
      return { ...state, isCreateUserSuccess: false, isCreateUserError: false, loading: false };
    }
    case UserActionTypes.REQUEST_CREATE_USER_PROFILE_SUCCESS: {
      return { ...state, error: null, loading: false, isCreateUserSuccess: true };
    }
    case UserActionTypes.REQUEST_CREATE_USER_PROFILE_ERROR: {
      return {
        ...state,
        error: payload.error,
        loading: false,
        isCreateUserError: true,
      };
    }
    case UserActionTypes.REQUEST_CREATE_CUSTOMER: {
      return { ...state, loading: true, error: null, isCreateCustomerSuccess: false };
    }
    case UserActionTypes.REQUEST_CREATE_CUSTOMER_SUCCESS: {
      return { ...state, error: null, loading: false, isCreateCustomerSuccess: true };
    }
    case UserActionTypes.REQUEST_CREATE_CUSTOMER_ERROR: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    }
    case UserActionTypes.REQUEST_CREATE_SUBSCRIBER: {
      return { ...state, loading: true, error: null, isCreateSubscriberSuccess: false };
    }
    case UserActionTypes.REQUEST_CREATE_SUBSCRIBER_SUCCESS: {
      return { ...state, error: null, loading: false, isCreateSubscriberSuccess: true };
    }
    case UserActionTypes.REQUEST_CREATE_SUBSCRIBER_ERROR: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    }
    case UserActionTypes.SURVEY_COMPLETED: {
      const newState = { ...state };
      newState.userData.surveys.push(payload.data);
      return newState;
    }
    case UserActionTypes.ORDER_COUNT_INCREASE: {
      const newState = { ...state };
      newState.userData.orderCount = state.userData.orderCount + 1;
      return newState;
    }
    case UserActionTypes.REQUEST_CREATE_USER_PROFILE_OPEN_RECAPTCHA_MODAL: {
      return {
        ...state,
        showRecaptchaModalRegister: true,
      };
    }
    case UserActionTypes.REQUEST_CREATE_USER_PROFILE_CLOSE_RECAPTCHA_MODAL: {
      return {
        ...state,
        showRecaptchaModalRegister: false,
      };
    }
    case UserActionTypes.REQUEST_CREATE_SUBSCRIBER_OPEN_RECAPTCHA_MODAL: {
      return {
        ...state,
        showRecaptchaModalSubscriber: true,
      };
    }
    case UserActionTypes.REQUEST_CREATE_SUBSCRIBER_CLOSE_RECAPTCHA_MODAL: {
      return {
        ...state,
        showRecaptchaModalSubscriber: false,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
