// Interfaces
import {
  IPatchProfile,
  ICreateProfile,
  ICreateNewCustomer,
  ICreateSubscriber,
} from 'ducks/user/interfaces/interfaces';
import { UserClass } from 'models/classes/userClass';
import { IError } from 'models/interfaces/error';

export enum UserActionTypes {
  REQUEST_STORE_PHONE_NUMBER = '@@user/REQUEST_STORE_PHONE_NUMBER',
  REQUEST_STORE_PHONE_NUMBER_SUCCESS = '@@user/REQUEST_STORE_PHONE_NUMBER_SUCCESS',
  REQUEST_STORE_PHONE_NUMBER_ERROR = '@@user/REQUEST_STORE_PHONE_NUMBER_ERROR',

  REQUEST_FETCH_PROFILE = '@@user/REQUEST_FETCH_PROFILE',
  REQUEST_FETCH_PROFILE_SUCCESS = '@@user/REQUEST_FETCH_PROFILE_SUCCESS',
  REQUEST_FETCH_PROFILE_ERROR = '@@user/REQUEST_FETCH_PROFILE_ERROR',

  REQUEST_PATCH_PROFILE = '@@user/REQUEST_PATCH_PROFILE',
  REQUEST_PATCH_PROFILE_SUCCESS = '@@user/REQUEST_PATCH_PROFILE_SUCCESS',
  REQUEST_PATCH_PROFILE_ERROR = '@@user/REQUEST_PATCH_PROFILE_ERROR',
  REQUEST_UPDATE_USER_PROFILE_STATUS = '@@user/REQUEST_UPDATE_USER_PROFILE_STATUS',

  REQUEST_CREATE_USER_PROFILE = '@@user/REQUEST_CREATE_USER_PROFILE',
  REQUEST_CREATE_USER_PROFILE_SUCCESS = '@@user/REQUEST_CREATE_USER_PROFILE_SUCCESS',
  REQUEST_CREATE_USER_PROFILE_ERROR = '@@user/REQUEST_CREATE_USER_PROFILE_ERROR',
  FINISH_USER_PROFILE_CREATE = '@@user/FINISH_USER_PROFILE_CREATE',
	REQUEST_CREATE_USER_PROFILE_OPEN_RECAPTCHA_MODAL = '@@user/REQUEST_CREATE_USER_PROFILE_OPEN_RECAPTCHA_MODAL',
	REQUEST_CREATE_USER_PROFILE_CLOSE_RECAPTCHA_MODAL = '@@user/REQUEST_CREATE_USER_PROFILE_CLOSE_RECAPTCHA_MODAL',

  REQUEST_CREATE_CUSTOMER = '@@user/REQUEST_CREATE_CUSTOMER',
  REQUEST_CREATE_CUSTOMER_SUCCESS = '@@user/REQUEST_CREATE_CUSTOMER_SUCCESS',
  REQUEST_CREATE_CUSTOMER_ERROR = '@@user/REQUEST_CREATE_CUSTOMER_ERROR',

  REQUEST_CREATE_SUBSCRIBER = '@@user/REQUEST_CREATE_SUBSCRIBER',
  REQUEST_CREATE_SUBSCRIBER_SUCCESS = '@@user/REQUEST_CREATE_SUBSCRIBER_SUCCESS',
  REQUEST_CREATE_SUBSCRIBER_ERROR = '@@user/REQUEST_CREATE_SUBSCRIBER_ERROR',
	REQUEST_CREATE_SUBSCRIBER_OPEN_RECAPTCHA_MODAL = '@@user/REQUEST_CREATE_SUBSCRIBER_OPEN_RECAPTCHA_MODAL',
	REQUEST_CREATE_SUBSCRIBER_CLOSE_RECAPTCHA_MODAL = '@@user/REQUEST_CREATE_SUBSCRIBER_CLOSE_RECAPTCHA_MODAL',

  SURVEY_COMPLETED = '@@user/SURVEY_COMPLETED',
  ORDER_COUNT_INCREASE = '@@user/ORDER_COUNT_INCREASE',

  SET_DEFAULT_PHONE_NUMBER = '@@user/SET_DEFAULT_PHONE_NUMBER',
}

export interface RequestPatchProfileActionParams {
  data: IPatchProfile;
}

export interface RequestPatchProfileAction {
  type: UserActionTypes.REQUEST_PATCH_PROFILE;
  payload: RequestPatchProfileActionParams;
}

export interface RequestCreateUserProfileAction {
  type: UserActionTypes.REQUEST_CREATE_USER_PROFILE;
  payload: ICreateProfile;
}

export interface RequestCreateCustomerAction {
  type: UserActionTypes.REQUEST_CREATE_CUSTOMER;
  payload: ICreateNewCustomer;
}

export interface RequestSubscriberAction {
  type: UserActionTypes.REQUEST_CREATE_SUBSCRIBER;
  payload: ICreateSubscriber;
}

export interface UserState {
  readonly loading: boolean;
  readonly error: IError | null;
  readonly storePhoneNumber: string | null;
  readonly updateShippingAddressSuccess: boolean;
  readonly userData: UserClass | null;
  readonly isCreateUserSuccess: boolean;
  readonly isCreateUserError: boolean;
  readonly isCreateCustomerSuccess: boolean;
  readonly isCreateSubscriberSuccess: boolean;
	readonly isUpdateProfileSuccess: string | null;
	readonly showRecaptchaModalRegister: boolean;
	readonly showRecaptchaModalSubscriber: boolean;
}
