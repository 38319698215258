import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Link as LinkTo } from 'react-router-dom';
import Routes from 'routes';
import {
  Link,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { MediumButton } from 'components/common/Buttons/MediumButton';
import { Arrow } from 'assets/svg/Landing/DropDownArrow';
import { SuccessIcon } from 'assets/svg/Landing/SuccessIcon';
import { useDispatch, useSelector } from 'react-redux';
import { createSubscriber, createUserProfile, finishUserProfile } from 'ducks/user/actions';
import {
  selectCreateSubscriberResult,
  selectCreateUserProfileResult, selectShowRecaptchaModalSubscriber,
  selectUserCreateError,
  selectUserErrors,
} from 'ducks/user/selectors';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { LANDING_IMAGES_URL, LANDING_VIDEOS_URL } from './constants';
import { RecaptchaModalSubscription } from './RecaptchaModalSubcription/RecaptchaModalSubscription';
import { BranchClass } from '../../models/classes/branchClass';
import { selectBranches } from '../../ducks/branch/selectors';

const MainContainer = styled.div`
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 100%;
  margin: 0 auto;
  height: auto;
  min-width: 320px;
`;

const BackgroundMobile = styled.div`
  width: 100%;
  height: 671px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 1023px) {
    background-image: url(${LANDING_IMAGES_URL}/5th-ground.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 622px;
  }

  @media (max-width: 810px) {
    height: 600px;
  }

  @media (max-width: 720px) {
    height: 410px;
  }

  @media (max-width: 580px) {
    background-position-x: -435px;
    height: 473px;
  }

  @media (max-width: 455px) {
    background-position-x: -557px;
  }
`;

const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  z-index: 0;
  background-image: url(${LANDING_IMAGES_URL}/5th-ground.jpg);
  background-repeat: no-repeat;
  background-size: cover;
`;

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: 671px;
  overflow: hidden;

  @media (max-width: 1023px) {
    & video {
      display: none;
    }
  }
`;

const ShadowTopBackground = styled.div`
  height: 94px;
  width: 100%;
  position: absolute;
  top: 0;
  background: linear-gradient(
    180deg,
    ${({ theme: { colors } }) => colors.white} 0%,
    ${({ theme: { colors } }) => colors.invisible} 100%
  );
  z-index: 100;

  @media (max-width: 1280px) {
    height: 80px;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

const ShadowBottomBackground = styled.div`
  height: 94px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    180deg,
    ${({ theme: { colors } }) => colors.white} 0%,
    ${({ theme: { colors } }) => colors.invisible} 100%
  );
  transform: rotate(-180deg);
  z-index: 1;

  @media (max-width: 1280px) {
    height: 80px;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

const BackgroundContainer = styled.div`
  @media (max-width: 1024px) {
    margin-top: -25px;
  }
  @media (max-width: 810px) {
    margin-top: 10px;
    padding: 0 10px;
  }
  @media (max-width: 720px) {
    margin-top: 30px;
  }

  @media (max-width: 580px) {
    margin-top: 14px;
  }
`;

const FormScreenCard = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 44px;

  @media (max-width: 1280px) {
    margin-top: 42px;
  }
  @media (max-width: 720px) {
    max-width: 480px;
    width: 100%;
    padding: 0 10px;
  }
`;

const FirstSection = styled.div`
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
`;

const CardContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  height: auto;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 4px;
  box-shadow: 0 0 20px ${({ theme: { colors } }) => colors.blackOpacity('0.15')};
  padding: 100px 80px 82px;
  margin: -88px auto -120px;
  z-index: 101;

  @media (max-width: 1280px) {
    max-width: 880px;
    padding: 100px 40px 60px;
    margin: -82px auto;
  }

  @media (max-width: 910px) {
    max-width: 810px;
  }

  @media (max-width: 810px) {
    padding: 60px 40px 60px;
    max-width: 720px;
  }

  @media (max-width: 720px) {
    padding: 40px;
    margin: 0 0px -10px;
  }

  @media (max-width: 480px) {
    padding: 40px 10px 11px;
  }
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 580px;
  margin-bottom: 17px;

  @media (max-width: 480px) {
    margin-bottom: 10px;
    flex-direction: column;
  }
`;

const StyledTextField = withStyles({
  root: {
    fontFamily: 'Open Sans',
    width: '280px',
    marginTop: '19px',
    height: '62px',
    borderRadius: '4px',
    '& label.Mui-focused': {
      color: '#00853e',
      top: '4px',
    },
    '& .MuiInputBase-root': {
      fontFamily: 'Open Sans',
      height: '47px',
      '&:hover': {
        borderColor: '#DADEE4',
      },
      '&.Mui-focused': {
        borderColor: '#00853e',
      },
      '& .MuiOutlinedInput-input': {
        padding: '14px',
      },
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#B70016',
        },
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
        borderColor: '#00853e',
      },
      fieldset: {
        borderColor: '#00853e',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#DADEE4',
        '& .PrivateNotchedOutline-legendLabelled-9': {
          fontSize: '5px',
        },
      },
    },
    '& .MuiFormLabel-root': {
      color: '#939599',
      fontFamily: 'Open Sans',
      lineHeight: '16px',
      letterSpacing: '0.01em',
      marginTop: '-4px',
      '&.Mui-error': {
        '&.Mui-focused': {
          color: '#B70016',
          top: '4px',
        },
      },
      '& .Mui-focused': {
        color: '#00853e',
        borderColor: '#00853e',
        borderRadius: '4px',
        top: '4px',
        fontSize: '14px',
      },
      '&.MuiFormLabel-filled': {
        borderRadius: '4px',
        top: '4px',
      },
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Open Sans',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.01em',
      marginTop: '0px',
      marginLeft: '0px',
      '&.Mui-error': {
        color: '#B70016',
      },
    },
    '@media (max-width: 720px)': {
      width: '100%',
      marginTop: '11px',
    },
  },
})(TextField);

const EmailStyledTextField = withStyles({
  root: {
    fontFamily: 'Open Sans',
    width: '302px',
    height: '62px',
    borderRadius: '4px',
    '& .MuiInputBase-root': {
      height: '48px',
      fontFamily: 'Open Sans',
      backgroundColor: 'rgba(255,255,255, 0.4)',
      borderColor: '#FFFFFF',
      '&.Mui-error,.Mui-focused': {
        backgroundColor: 'rgba(255,255,255, 0.6)',
      },
      '& .MuiOutlinedInput-input': {
        padding: '14px',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#00853e !important',
      },
      '&.Mui-error fieldset': {
        borderColor: '#B70016 !important',
      },
      '&.Mui-focused': {
        backgroundColor: 'rgba(255,255,255, 0.6)',
      },
      '& fieldset legend': {
        width: '0',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#DADEE4',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FFFFFF',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Open Sans',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.01em',
      marginLeft: '0px',
      marginRight: '0px',
      width: 'max-content',
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      padding: '1px 3px',
      '&.Mui-error': {
        color: '#B70016',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#FFFFFF',
      fontFamily: 'Open Sans',
      top: '-3px',
    },
    '& .MuiInputLabel-shrink': {
      color: '#FFFFFF',
      transform: 'translate(0px, -12px) scale(0.75);',
    },
    '@media (max-width: 580px)': {
      width: '300px',

      '& .MuiFormHelperText-root': {
        marginTop: '0',
      },
    },
  },
})(TextField);

const StyledFormControl = withStyles({
  root: {
    fontFamily: 'Open Sans',
    width: '280px',
    marginTop: '19px',
    height: '60px',
    borderRadius: '4px',
    '& label.Mui-focused': {
      color: '#00853e',
      top: '4px',
    },
    '& .MuiInputBase-root': {
      fontFamily: 'Open Sans',
      height: '47px',
      '&:hover': {
        borderColor: '#DADEE4',
      },
      '&.Mui-focused': {
        borderColor: '#00853e',
      },
      '& .MuiOutlinedInput-input': {
        padding: '14px',
      },
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#B70016',
        },
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
        borderColor: '#00853e',
      },
      fieldset: {
        borderColor: '#00853e',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#DADEE4',
        '& .PrivateNotchedOutline-legendLabelled-9': {
          fontSize: '5px',
        },
      },
    },
    '& .MuiFormLabel-root': {
      color: '#939599',
      fontFamily: 'Open Sans',
      lineHeight: '16px',
      letterSpacing: '0.01em',
      marginTop: '-4px',
      '&.Mui-error': {
        '&.Mui-focused': {
          color: '#B70016',
          top: '4px',
        },
      },
      '& .Mui-focused': {
        color: '#00853e',
        borderColor: '#00853e',
        borderRadius: '4px',
        top: '4px',
        fontSize: '14px',
      },
      '&.MuiFormLabel-filled': {
        borderRadius: '4px',
        top: '4px',
      },
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Open Sans',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.01em',
      marginTop: '0px',
      marginLeft: '0px',
      '&.Mui-error': {
        color: '#B70016',
      },
    },
    '@media (max-width: 720px)': {
      width: '100%',
      marginTop: '11px',
    },
  },
})(FormControl);

const StyledSelect = styled(Select)`
  .MuiSelect-select {
    &:focus {
      background-color: ${({ theme: { colors } }) => colors.white};
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

const SubmitButton = withStyles({
  root: {
    height: '48px',
    width: '280px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#37875F',
      '&.Mui-disabled': {
        backgroundColor: '#009650',
        opacity: '50%',
      },
    },
    '@media (max-width: 720px)': {
      width: '100%',
      maxWidth: '480px',
      marginBottom: '10px',
    },
    '&.Mui-disabled': {
      backgroundColor: '#009650',
      opacity: '50%',
    },
  },
})(MediumButton);

const OpenFormButton = withStyles({
  root: {
    height: '48px',
    width: '300px',
    borderRadius: '4px',
    marginTop: '14px',
    '&:hover': {
      backgroundColor: '#37875F',
      '&.Mui-disabled': {
        backgroundColor: '#009650',
        opacity: '50%',
      },
    },
    '@media (max-width: 1280px)': {
      marginTop: '58px',
    },
    '@media (max-width: 810px)': {
      marginTop: '35px',
    },
    '@media (max-width: 480px)': {
      marginTop: '57px',
    },
    '@media (max-width: 720px)': {
      width: '300px',
      marginBottom: '10px',
    },
    '&.Mui-disabled': {
      backgroundColor: '#009650',
      opacity: '50%',
    },
  },
})(MediumButton);

const EmailSubmitButton = withStyles({
  root: {
    height: '48px',
    width: '200px',
    borderRadius: '4px',
    color: '#00853e',
    backgroundColor: '#FFFFFF',
    margin: '0 0 0 18px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#4A9463',
      '&.Mui-disabled': {
        opacity: '50%',
        color: '#00853e',
        backgroundColor: '#FFFFFF',
      },
    },
    '@media (max-width: 580px)': {
      width: '300px',
      marginBottom: '10px',
    },
    '&.Mui-disabled': {
      opacity: '50%',
      color: '#00853e',
      backgroundColor: '#FFFFFF',
    },
  },
})(MediumButton);

const StyledLinkButton = styled(LinkTo)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 280px;
  font-size: 16px;
  font-family: Open Sans;
  font-weight: 600;
  letter-spacing: 0.01rem;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.mainGreen};
  border-radius: 4px;
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  margin-right: 20px;
  margin-bottom: 16px;
  line-height: 22px;

  &:hover {
    color: ${({ theme: { colors } }) => colors.secondaryGreen};
    border-color: ${({ theme: { colors } }) => colors.secondaryGreen};
  }

  @media (max-width: 720px) {
    width: 100%;
    max-width: 580px;
    margin-bottom: 24px;
    margin-right: 0px;
  }
`;

const RegisterText = styled.span`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.textColor};
  display: block;
  margin-bottom: 18px;

  @media (max-width: 1280px) {
    margin-bottom: 40px;
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme: { colors } }) => colors.secondaryGreen};
  cursor: pointer;
`;

const SloganText = styled.h2`
  font-family: Open Sans;
  font-size: 50px;
  line-height: 60px;
  font-weight: 800;
  letter-spacing: 0.01em;
  text-align: center;
  margin: 0 auto;
  color: ${({ theme: { colors } }) => colors.white};
  text-transform: uppercase;
  max-width: 854px;
  margin-left: 22px;
  position: relative;
  z-index: 1;

  @media (max-width: 1024px) {
    margin-left: 0;
  }

  @media (max-width: 810px) {
    width: 700px;
    font-size: 38px;
  }

  @media (max-width: 720px) {
    width: 100%;
    text-align: center;
    font-size: 26px;
    line-height: 31px;
    margin: 0 auto;
  }
`;

const MenuItemCust = withStyles({
  root: {
    '&.MuiMenuItem-root': {
      fontFamily: 'Open Sans',
    },
  },
})(MenuItem);

const CardContent = styled.div`
  display: flex;
  flex-direction: row;

  div {
    margin: auto -20px auto 0;
  }

  h3 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: ${({ theme: { colors } }) => colors.mainGreen};
    margin-bottom: 40px;
    margin-top: 0px;
  }

  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.01em;
  }

  img {
    margin-right: -250px;
  }

  @media (max-width: 1400px) {
    img {
      margin-right: -152px;
    }
  }

  @media (max-width: 1280px) {
    img {
      max-width: 641px;
      margin-right: -108px;
    }
    h3 {
      margin-top: 6px;
      margin-bottom: 19px;
      line-height: 120%;
    }
  }

  @media (max-width: 1018px) {
    img {
      max-width: 550px;
      margin-right: -40px;
    }
  }

  @media (max-width: 910px) {
    div {
      margin: auto 0;
    }

    img {
      max-width: 460px;
    }
  }

  @media (max-width: 810px) {
    flex-direction: column;
    align-items: center;

    h3 {
      text-align: center;
    }

    img {
      margin-right: 0px;
      margin-top: 20px;
    }
  }

  @media (max-width: 720px) {
    h3 {
      line-height: 29px;
      margin-top: 0;
      margin-bottom: 23px;
    }
  }

  @media (max-width: 580px) {
    img {
      max-width: 400px;
    }
  }

  @media (max-width: 480px) {
    img {
      max-width: 320px;
      margin-top: 5px;
    }
    h3 {
      padding: 0 10px;
    }
  }

  @media (max-width: 380px) {
    img {
      width: 300px;
    }
  }
`;

const Subslogan = styled.p`
  font-family: Open Sans;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  margin-bottom: 11px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.white};
  margin: 40px 0 41px 24px;
  position: relative;
  z-index: 1;

  @media (max-width: 1024px) {
    margin: 40px 0;
  }

  @media (max-width: 720px) {
    font-size: 16px;
    line-height: 22px;
  }

  @media (max-width: 580px) {
    text-align: left;
    margin-left: 15px;
    margin-top: 22px;
    margin-bottom: 29px;
  }

  @media (max-width: 480px) {
    margin-left: 0px;
  }
`;

const EmailButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 580px) {
    flex-direction: column;
    align-items: center;

    button {
      margin-left: 0 !important;
      margin-top: 7px !important;
      width: 300px;
    }
  }
`;

const SubscriberSuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.white};

  svg {
    width: 26px;
    height: 26px;
    margin-top: 3px;
    margin-right: 10px;
  }

  @media (max-width: 1280px) {
    font-size: 22px;
    line-height: 30px;
  }

  @media (max-width: 720px) {
    font-size: 18px;
    line-height: 25px;
  }
`;

const useStyles = makeStyles({
  h3: {
    '@media (max-width: 1280px) and (min-width: 1018px)': {
      marginTop: '-7px !important',
    },
    '@media (max-width: 360px)': {
      fontSize: '21px !important',
    },
  },
});

interface TextProps {
  cardContentH3: string;
  cardContentParagraph: string;
  firstnameLabel: string;
  lastnameLabel: string;
  emailLabel: string;
  companyLabel: string;
  salesLabel: string;
  branchLabel: string;
  buttonLabel: string;
  notCustomer: string;
  firstButtonLabel: string;
  click: string;
  slogan: string;
  firstnameValidateMin: string;
  firstnameValidateMax: string;
  firstnameRequired: string;
  lastnameValidateMin: string;
  lastnameValidateMax: string;
  lastnameRequired: string;
  companyValidateMin: string;
  companyValidateMax: string;
  companyRequired: string;
  salesValidateMin: string;
  salesValidateMax: string;
  salesRequired: string;
  emailInvalid: string;
  emailValidateMax: string;
  emailRequired: string;
  branchRequired: string;
  subslogan: string;
  secondButtonLabel: string;
  expandButtonLabel: string;
  isEnglish: boolean;
  submittedEmail?: string;
  emailIsUsed?: string;
  landingFirstRef?: React.RefObject<HTMLDivElement>;
}
const LandingFive: React.FC<TextProps> = ({
  cardContentH3,
  cardContentParagraph,
  firstnameLabel,
  lastnameLabel,
  emailLabel,
  companyLabel,
  salesLabel,
  branchLabel,
  buttonLabel,
  notCustomer,
  firstButtonLabel,
  click,
  slogan,
  firstnameValidateMin,
  firstnameValidateMax,
  firstnameRequired,
  lastnameValidateMin,
  lastnameValidateMax,
  lastnameRequired,
  companyValidateMin,
  companyValidateMax,
  companyRequired,
  salesValidateMin,
  salesValidateMax,
  salesRequired,
  emailInvalid,
  emailValidateMax,
  emailRequired,
  branchRequired,
  subslogan,
  expandButtonLabel,
  secondButtonLabel,
  emailIsUsed,
  isEnglish,
  submittedEmail,
  landingFirstRef
}: TextProps) => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, `${firstnameValidateMin}`)
      .max(50, `${firstnameValidateMax}`)
      .required(`${firstnameRequired}`),
    lastName: Yup.string()
      .min(2, `${lastnameValidateMin}`)
      .max(50, `${lastnameValidateMax}`)
      .required(`${lastnameRequired}`),
    company: Yup.string()
      .min(2, `${companyValidateMin}`)
      .max(150, `${companyValidateMax}`)
      .required(`${companyRequired}`),
    salesRepresentative: Yup.string()
      .min(2, `${salesValidateMin}`)
      .max(50, `${salesValidateMax}`)
      .required(`${salesRequired}`),
    email: Yup.string()
      .email(`${emailInvalid}`)
      .max(255, `${emailValidateMax}`)
      .required(`${emailRequired}`),
    signupBranchId: Yup.number()
      .min(1)
      .required(branchRequired),
  });
  const validationSchemaEmail = Yup.object().shape({
    email: Yup.string()
      .email(`${emailInvalid}`)
      .max(255, `${emailValidateMax}`)
      .required(`${emailRequired}`),
  });
  const dispatch = useDispatch();
  const isUserSuccess = useSelector(selectCreateUserProfileResult);
  const isCreateSubscriberSuccess = useSelector(selectCreateSubscriberResult);

  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isOpenForm, _] = useState(false);
  const isServerError = useSelector(selectUserCreateError);
  const serverErrorMessage = useSelector(selectUserErrors);
  const openRecaptchaModal = useSelector(selectShowRecaptchaModalSubscriber);
  const branches = useSelector(selectBranches);
  const validationError = 400;

  const { executeRecaptcha } = useGoogleReCaptcha();
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      signupBranchId: '',
      salesRepresentative: '',
    },
    validationSchema,
    onSubmit: values => {
      setIsLoading(true);
      // @ts-ignore
      executeRecaptcha().then(recaptchaToken => {
        setIsLoading(true);
        values['recaptchaToken'] = recaptchaToken;
        values['isForce'] = true;
        // @ts-ignore
        dispatch(createUserProfile(values));
      });
    },
  });


  const handleSubmit = (values, isForce = false) => {
    if (!isForce) {
      executeRecaptcha().then(recaptchaToken => {
        setIsLoading(true);
        dispatch(createSubscriber({...values, recaptchaToken, isForce}));
      });
    } else {
      dispatch(createSubscriber({...values, isForce}));
    }
  }

  const input = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchemaEmail,
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  const handleSubmitRecaptcha = () => {
    handleSubmit(input.values, false);
  };

  useEffect(() => {
    if (isUserSuccess && isLoading) {
      setIsLoading(false);
      formik.resetForm();
      dispatch(finishUserProfile());
    }
  }, [dispatch, isLoading, isUserSuccess]);

  useEffect(() => {
    if (isCreateSubscriberSuccess) {
      setIsLoading(false);
    }
  }, [isCreateSubscriberSuccess]);

  useEffect(() => {
    if (isServerError) {
      setIsLoading(false);
    }
  }, [isServerError]);

  const DevicesPhotoLanguage = isEnglish
    ? `${LANDING_IMAGES_URL}/5th-devices.png`
    : `${LANDING_IMAGES_URL}/5th-screen-devices-french.png`;

  const classes = useStyles();

  const videoUrl = `${LANDING_VIDEOS_URL}/Golf+1+CSV1.mp4`;

  return (
    <MainContainer>
      <RecaptchaModalSubscription open={openRecaptchaModal} callbackOnClose={() => setIsLoading(false)} handleSubmit={() => handleSubmitRecaptcha()} />
      <FirstSection>
        <CardContainerWrapper>
          <CardContent>
            <div>
              <h3 className={!isEnglish ? classes.h3 : ''}>{cardContentH3}</h3>
              <p>{cardContentParagraph}</p>
            </div>
            <img src={DevicesPhotoLanguage} alt="Screen" />
          </CardContent>
          {!isOpenForm && (
            <OpenFormButton onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              });
              landingFirstRef.current?.click();
              // setOpenForm(!isOpenForm);
            }}>
              {expandButtonLabel}
            </OpenFormButton>
          )}

          {isOpenForm && (
            <FormScreenCard onSubmit={formik.handleSubmit}>
              <InputsContainer>
                <StyledTextField
                  variant="outlined"
                  label={firstnameLabel}
                  name="firstName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  error={!!(formik.errors.firstName && formik.touched.firstName)}
                  helperText={
                    formik.errors.firstName && formik.touched.firstName
                      ? formik.errors.firstName
                      : ''
                  }
                />
                <StyledTextField
                  variant="outlined"
                  label={lastnameLabel}
                  name="lastName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  error={!!(formik.errors.lastName && formik.touched.lastName)}
                  helperText={
                    formik.errors.lastName && formik.touched.lastName ? formik.errors.lastName : ''
                  }
                />
                <StyledTextField
                  variant="outlined"
                  label={emailLabel}
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={!!((formik.errors.email && formik.touched.email) || isServerError)}
                  helperText={
                    isServerError &&
                    serverErrorMessage?.code === validationError &&
                    formik.values.email
                      ? emailIsUsed
                      : formik.errors.email && formik.touched.email && formik.errors.email
                  }
                />
                <StyledTextField
                  variant="outlined"
                  label={companyLabel}
                  name="company"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company}
                  error={!!(formik.errors.company && formik.touched.company)}
                  helperText={
                    formik.errors.company && formik.touched.company ? formik.errors.company : ''
                  }
                />
                <StyledFormControl variant="outlined">
                  <InputLabel id="location-label">{branchLabel}</InputLabel>
                  <StyledSelect
                    labelId="location-label"
                    id="taiga-branch"
                    name="branch"
                    label={branchLabel}
                    IconComponent={() => <Arrow />}
                    value={formik.values.signupBranchId}
                    onChange={(event) => {
                      formik.setFieldValue('signupBranchId', event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={!!(formik.errors.signupBranchId && formik.touched.signupBranchId)}
                  >
                    {branches.length > 0 &&
                      branches.map((branch: BranchClass) => (
                        <MenuItemCust key={branch.id} value={branch.id.toString()}>
                          {branch.erpName}
                        </MenuItemCust>
                      ))}
                  </StyledSelect>
                  <FormHelperText error focused>
                    {formik.errors.signupBranchId && formik.touched.signupBranchId ? formik.errors.signupBranchId : null}
                  </FormHelperText>
                </StyledFormControl>
                <StyledTextField
                  variant="outlined"
                  label={salesLabel}
                  name="salesRepresentative"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.salesRepresentative}
                  error={
                    !!(formik.errors.salesRepresentative && formik.touched.salesRepresentative)
                  }
                  helperText={
                    formik.errors.salesRepresentative && formik.touched.salesRepresentative
                      ? formik.errors.salesRepresentative
                      : ''
                  }
                />
              </InputsContainer>
              <ButtonsContainer>
                <StyledLinkButton to={Routes.SIGN_IN}>{firstButtonLabel}</StyledLinkButton>
                <SubmitButton type="submit" disabled={isLoading}>
                  {buttonLabel}
                </SubmitButton>
              </ButtonsContainer>
              <RegisterText>
                {notCustomer} <StyledLink href="#contact-registration">{click}</StyledLink>
              </RegisterText>
            </FormScreenCard>
          )}
        </CardContainerWrapper>
      </FirstSection>
      <BackgroundMobile>
        <ShadowTopBackground />
        <VideoWrapper>
          <StyledVideo src={videoUrl} autoPlay loop muted playsInline />
        </VideoWrapper>
        <BackgroundContainer>
          <SloganText>{slogan}</SloganText>
          <Subslogan>{subslogan}</Subslogan>
          {!isCreateSubscriberSuccess && (
            <form onSubmit={input.handleSubmit}>
              <EmailButtonContainer>
                <EmailStyledTextField
                  variant="outlined"
                  label={emailLabel}
                  name="email"
                  onChange={input.handleChange}
                  onBlur={input.handleBlur}
                  value={input.values.email}
                  error={!!(input.errors.email && input.touched.email)}
                  helperText={input.errors.email && input.touched.email ? input.errors.email : ''}
                  data-test-id="keep-up-to-date"
                />
                <EmailSubmitButton type="submit" disabled={isLoading}>
                  {secondButtonLabel}
                </EmailSubmitButton>
              </EmailButtonContainer>
            </form>
          )}
          {isCreateSubscriberSuccess && (
            <SubscriberSuccessContainer>
              <SuccessIcon />
              {submittedEmail}
            </SubscriberSuccessContainer>
          )}
        </BackgroundContainer>
        <ShadowBottomBackground />
      </BackgroundMobile>
    </MainContainer>
  );
};

export default LandingFive;
