import React from 'react';
import { GlassMagnifier } from 'react-image-magnifiers';

interface IZoomImage {
  image: string;
  largeImage?: string;
  className?: string;
  allowOverflow?: boolean;
  magnifierBorderColor?: string;
  magnifierSize?: string;
  magnifierBorderSize?: number;
  square?: boolean;
  cursorStyle?: string;
}

export const ZoomImage = React.memo(
  ({
    image,
    className,
    largeImage,
    allowOverflow = false,
    magnifierBorderColor = `${({ theme: { colors } }) => colors.whiteOpacity(0.5)}`,
    magnifierSize = '20%',
    magnifierBorderSize = 1,
    square = true,
    cursorStyle = 'none',
  }: IZoomImage) => {
    return (
      <GlassMagnifier
        className={className}
        imageSrc={image}
        largeImageSrc={largeImage}
        allowOverflow={allowOverflow}
        magnifierSize={magnifierSize}
        magnifierBorderSize={magnifierBorderSize}
        magnifierBorderColor={magnifierBorderColor}
        square={square}
        cursorStyle={cursorStyle}
      />
    );
  }
);
