import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import Carousel from 'react-elastic-carousel';
import { useMediaQuery } from '@material-ui/core';
import { LeftArrow, RightArrow } from 'assets/svg/FeaturedProducts/FeaturedProducts';

interface IDotsSlider {
  dots: HTMLDivElement[];
}

const SliderWrapper = styled.div`
  width: 440px;
  margin-top: 10px;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const StyledCarousel = styled(Carousel)`
  .slick-active {
    border: 1px solid ${({ theme: { colors } }) => colors.borderSliderGreen};
    box-sizing: border-box;
    border-radius: 4px;
  }

  & li {
    list-style-type: none;
    margin: 0 5px;
    outline: none;
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DotsSlider: FC<IDotsSlider> = ({ dots }: IDotsSlider) => {
  const carouselRef = useRef(null);
  const isMobile = useMediaQuery('(max-width: 1023px)');

  return (
    <SliderWrapper>
      <StyledCarousel
        ref={carouselRef}
        itemsToShow={isMobile ? 4 : 5}
        isRTL={false}
        renderArrow={({ type, onClick }) => (
          <ArrowWrapper onClick={onClick}>
            {type === 'PREV' ? <LeftArrow /> : <RightArrow />}
          </ArrowWrapper>
        )}
        pagination={false}
        onPrevStart={(currentItem, nextItem) => {
          if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo(dots.length);
          }
        }}
        onNextStart={(currentItem, nextItem) => {
          if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo(0);
          }
        }}
      >
        {dots}
      </StyledCarousel>
    </SliderWrapper>
  );
};

export default DotsSlider;
