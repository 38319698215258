import { createSelector } from 'reselect';
import { RootState } from 'store';

const selectCategoryState = (state: RootState) => state.categories;

export const selectCategories = createSelector(
  selectCategoryState,
  categoryState => categoryState.categories
);

export const selectCategory = createSelector(
  selectCategoryState,
  categoryState => categoryState.category
);

export const selectCategoryError = createSelector(
  selectCategoryState,
  categoryState => categoryState.error
);

export const selectParentCategories = createSelector(
  selectCategoryState,
  categoryState => categoryState.parentCategories
);
