import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';
import {useTranslation} from 'react-i18next';

const StyledPopper = styled(({ placement, ...rest }) => <Popper placement={placement} {...rest} />)`
  && {
    z-index: 1500;
    ${props => (props.placement === 'right' ? 'padding-left: 25px;' : 'padding-right:25px;')}

    &[x-placement*='right'] .arrow {
      left: 0;
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 12px solid #fff;
      margin-left: -11px;
    }

    &[x-placement*='left'] .arrow {
      right: 0;
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 12px solid #fff;
      margin-right: -11px;
    }

    .arrow {
      position: absolute;
      ${props => (props.placement === 'right' ? 'padding-left: 25px;' : 'padding-right:25px;')}
      font-size: 7px;
      width: 3em;
      height: 3em;
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 250px;
  background: #fff;
  box-shadow: 0px 6px 43px rgba(0, 0, 0, 0.21);
  border-radius: 6px;
  color: ${({ theme: { colors } }) => colors.textColor};
  padding: 16px;
  @media (max-width: 1023px) {
    width: 122px;
    font-size: 12px;
  }
  .ic-warning {
    width: 32px;
    height: 32px;
    margin-bottom: 16px;
  }
  .title-invalid {
    color: #00133C;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.1px;
    margin-bottom: 10px;
  }
  .txt-message {
    color: #48576B;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }
`;

interface IProductPopper {
  message: string;
  placement: PopperPlacementType;
  element: any;
}

const ProductInputPopper: React.FC<IProductPopper> = ({
  message,
  placement,
  element,
}: IProductPopper) => {
  const {t} = useTranslation();
  const [arrowRef, setArrowRef] = useState(null);
  const [open, setOpen] = useState(true); // set open/close correctly

  const handleArrowRef = node => {
    setArrowRef(node);
    setOpen(true);
  };

  return (
    <StyledPopper
      open={open}
      disablePortal={false}
      modifiers={{
        flip: {
          enabled: false,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'scrollParent',
        },
        arrow: {
          enabled: true,
          element: arrowRef,
        },
      }}
      anchorEl={element}
      placement={placement}
      transition
      className="product-input-popper"
    >
      <span className="arrow" ref={handleArrowRef} />

      <StyledDiv>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="ic-warning"
        >
          <rect width="32" height="32" rx="16" fill="#FEE4E2" />
          <g clipPath="url(#clip0_6720_50547)">
            <path
              d="M16.0007 13.3335V16.0002M16.0007 18.6668H16.0073M22.6673 16.0002C22.6673 19.6821 19.6826 22.6668 16.0007 22.6668C12.3188 22.6668 9.33398 19.6821 9.33398 16.0002C9.33398 12.3183 12.3188 9.3335 16.0007 9.3335C19.6826 9.3335 22.6673 12.3183 22.6673 16.0002Z"
              stroke="#D92D20"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_6720_50547">
              <rect width="16" height="16" fill="white" transform="translate(8 8)" />
            </clipPath>
          </defs>
        </svg>

        <div className="title-invalid">{t('quick_order.invalid_quantity')}</div>

        <div className="txt-message">{message}</div>
      </StyledDiv>
    </StyledPopper>
  );
};

export default ProductInputPopper;
