import { Reducer } from 'redux';

// Types
import { FAQActionTypes, FAQState } from 'ducks/FAQ/types';

const initialState: FAQState = {
  FAQ: [],
  loading: false,
  error: null,
  isFetchFAQ: false,
};

const faqReducer: Reducer<FAQState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case FAQActionTypes.REQUEST_FAQ: {
      return { ...state, loading: true };
    }
    case FAQActionTypes.REQUEST_FAQ_SUCCESS: {
      return { ...state, loading: false, FAQ: payload.FAQ, isFetchFAQ: true };
    }
    case FAQActionTypes.REQUEST_FAQ_ERROR: {
      return { ...state, loading: false, error: payload.data, isFetchFAQ: true };
    }
    default:
      return state;
  }
};

export default faqReducer;
