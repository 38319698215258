import React from 'react';
import { Paper, Grid, Divider, useMediaQuery } from '@material-ui/core';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import format from 'date-fns/format';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import { useTranslation } from 'react-i18next';
import fr from 'date-fns/locale/fr-CA';
import en from 'date-fns/locale/en-CA';
import { useSelector } from 'react-redux';

import Month from 'pages/General/Profile/components/OrdersAndQuotes/DateRangePicker/components/Month';
import DefinedRanges from 'pages/General/Profile/components/OrdersAndQuotes/DateRangePicker/components/DefinedRanges';
import {
  DateRange,
  DefinedRange,
  Setter,
  NavigationAction,
} from 'pages/General/Profile/components/OrdersAndQuotes/DateRangePicker/types';
import { selectLanguage } from 'ducks/application/selectors';
import {
  StyledGridHeader,
  StyledTypography,
  StyledDivider,
  StyledClearAll,
  StyledGridHeaderItem,
  StyledMonthDivider,
} from 'pages/General/Profile/components/OrdersAndQuotes/DateRangePicker/styles';
import { MARKERS } from '../index';

interface MenuProps {
  initDateRange: DateRange;
  clearDateRange: React.Dispatch<React.SetStateAction<{}>>;
  clearDataRangeFilter: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  dateRange: DateRange;
  ranges: DefinedRange[];
  minDate: Date;
  maxDate: Date;
  firstMonth: Date;
  secondMonth: Date;
  setFirstMonth: Setter<Date>;
  setSecondMonth: Setter<Date>;
  setDateRange: Setter<DateRange>;
  helpers: {
    inHoverRange: (day: Date) => boolean;
  };
  handlers: {
    onDayClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, day: Date) => void;
    onDayHover: (day: Date) => void;
    onMonthNavigate: (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      marker: symbol,
      action: NavigationAction
    ) => void;
  };
}

const Menu: React.FunctionComponent<MenuProps> = ({
  initDateRange,
  clearDateRange,
  clearDataRangeFilter,
  dateRange,
  ranges,
  minDate,
  maxDate,
  firstMonth,
  secondMonth,
  setFirstMonth,
  setSecondMonth,
  setDateRange,
  helpers,
  handlers,
}: MenuProps) => {
  const { t } = useTranslation();
  const language = useSelector(selectLanguage);
  const { startDate, endDate } = dateRange;

  const canNavigateCloser = differenceInCalendarMonths(secondMonth, firstMonth) >= 2;
  const commonProps = { dateRange, minDate, maxDate, helpers, handlers };

  const isMobile = useMediaQuery('(max-width: 1023px)');

  return (
    <Paper elevation={5} square>
      <Grid container direction="row" wrap="nowrap">
        <Grid>
          <StyledGridHeader container alignItems="center">
            <StyledGridHeaderItem item>
              <StyledTypography variant="subtitle1">
                {startDate
                  ? format(startDate, 'MMMM dd, yyyy', {
                    locale: language !== 'fr_CA' ? en : fr,
                  })
                  : t('orders_and_quotes.start_date')}
              </StyledTypography>
            </StyledGridHeaderItem>
            <StyledGridHeaderItem item>
              <ArrowRightAlt color="action" />
            </StyledGridHeaderItem>
            <StyledGridHeaderItem item>
              <StyledTypography variant="subtitle1">
                {endDate
                  ? format(endDate, 'MMMM dd, yyyy', {
                    locale: language !== 'fr_CA' ? en : fr,
                  })
                  : t('orders_and_quotes.end_date')}
              </StyledTypography>
            </StyledGridHeaderItem>
          </StyledGridHeader>
          <Divider />
          <Grid container direction={isMobile ? 'column' : 'row'} justify="center" wrap="nowrap">
            <Month
              {...commonProps}
              value={firstMonth}
              setValue={setFirstMonth}
              navState={[true, canNavigateCloser]}
              marker={MARKERS.FIRST_MONTH}
            />
            <StyledMonthDivider />
            <Month
              {...commonProps}
              value={secondMonth}
              setValue={setSecondMonth}
              navState={[canNavigateCloser, true]}
              marker={MARKERS.SECOND_MONTH}
            />
          </Grid>
        </Grid>
        <StyledDivider />
        <Grid>
          <DefinedRanges selectedRange={dateRange} ranges={ranges} setRange={setDateRange} />
          <StyledClearAll
            onClick={e => {
              clearDateRange({ ...initDateRange });
              clearDataRangeFilter(e);
            }}
          >
            {t('products_and_search.clear_all')}
          </StyledClearAll>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Menu;
