import {Dispatch, SetStateAction} from 'react';
// Types
import {ProductActionTypes} from 'ducks/productVariant/types';

// Classes
import {ProductVariantClass} from 'models/classes/productVariantClass';
import {FacetClass} from 'models/classes/facetClass';
import {
  IFetchFilteredProductsPayload,
  IFetchFilters,
  IFetchProductsPayload,
  IRangeFilter,
} from 'models/interfaces/productVariant';
import {AssociationVariantClass} from 'models/classes/associationClass';

export const fetchFeaturedProducts = (payload: IFetchProductsPayload) => ({
  type: ProductActionTypes.REQUEST_FEATURED_PRODUCT,
  payload: {
    ...payload,
  },
});

export const fetchFilteredProducts = (payload: IFetchFilteredProductsPayload) => ({
  type: ProductActionTypes.REQUEST_FILTERED_PRODUCTS,
  payload: {
    ...payload,
  },
});

export const fetchFilters = (payload: IFetchFilters) => ({
  type: ProductActionTypes.REQUEST_FILTERS,
  payload: {
    ...payload,
  }
})

export const storeProducts = (products: ProductVariantClass[], isLastPage: boolean, total: number) => ({
  type: ProductActionTypes.REQUEST_PRODUCTS_SUCCESS,
  payload: {
    products,
    isLastPage,
    total,
  },
});

export const storeFeaturedProducts = (products: ProductVariantClass[]) => ({
  type: ProductActionTypes.REQUEST_FEATURED_PRODUCTS_SUCCESS,
  payload: {
    products,
  },
});

export const storeFilteredProducts = (products: ProductVariantClass[], isLastPage: boolean, total: number) => ({
  type: ProductActionTypes.REQUEST_FILTERED_PRODUCTS_SUCCESS,
  payload: {
    products,
    isLastPage,
    total,
  },
});

export const storeFilteredProductsFirstLoad = (
  products: ProductVariantClass[],
  isLastPage: boolean,
  total: number,
) => ({
  type: ProductActionTypes.REQUEST_FILTERED_PRODUCTS_SUCCESS_FIRST_LOAD,
  payload: {
    products,
    isLastPage,
    total
  },
});

export const storeFilters = (facets: FacetClass[], rangeFilters: IRangeFilter[]) => ({
  type: ProductActionTypes.REQUEST_FILTERS_SUCCESS,
  payload: {
    facets,
    rangeFilters,
  },
});

export const storeProductIdsList = (productIdsList: any) => ({
  type: ProductActionTypes.REQUEST_GET_PRODUCT_IDS_SUCCESS,
  payload: {
    productIdsList,
  }
})

export const resetProducts = () => ({
  type: ProductActionTypes.RESET_PRODUCTS,
});

export const sendRequestPricing = (
  message: string,
  quantity: string,
  addressId: number,
  productCode: string,
  productSku: string,
  setEmailSuccess: Dispatch<SetStateAction<boolean>>,
  setEmailLoading: Dispatch<SetStateAction<boolean>>,
) => ({
  type: ProductActionTypes.REQUEST_PRICING,
  payload: {
    message,
    quantity,
    addressId,
    productCode,
    productSku,
    setEmailSuccess,
    setEmailLoading,
  },
});

export const sendNearbyBranchEmail = (
  emailType: number,
  addressId: number,
  branchId: number,
  productId: number,
  categoryId: number,
  availableQuantity?: number,
  quantity?: number,
  comment?: string,
) => ({
  type: ProductActionTypes.REQUEST_SEND_NEARBY_BRANCH_EMAIL,
  payload: {
    emailType,
    addressId,
    branchId,
    productId,
    categoryId,
    availableQuantity,
    quantity,
    comment
  }
});

export const setNearbyBranchEmailStatus = (status: boolean) => ({
  type: ProductActionTypes.REQUEST_SET_SEND_NEARBY_BRANCH_EMAIL_STATUS,
  payload: {
    status,
  }
});
export const resetFeaturedProducts = () => ({
  type: ProductActionTypes.RESET_FEATURED_PRODUCTS,
});

export const toggleShowPrices = (value: boolean) => ({
  type: ProductActionTypes.SET_SHOW_PRICES,
  payload: {value},
});

export const resetProductsError = () => ({
  type: ProductActionTypes.RESET_PRODUCTS_ERROR,
});

export const requestVariantAssociation = (variantCode: string) => ({
  type: ProductActionTypes.REQUEST_VARIANT_ASSOCIATIONS,
  payload: {
    variantCode,
  },
});

export const storeVariantAssociations = (
  similarProducts: AssociationVariantClass[],
  alsoLikeProducts: AssociationVariantClass[],
  accessories: AssociationVariantClass[],
) => ({
  type: ProductActionTypes.REQUEST_VARIANT_ASSOCIATIONS_SUCCESS,
  payload: {
    similarProducts,
    alsoLikeProducts,
    accessories,
  },
});

export const resetAssociations = () => ({
  type: ProductActionTypes.RESET_VARIANT_ASSOCIATIONS,
});

export const setPaginationLoading = () => ({
  type: ProductActionTypes.SET_PAGINATION_LOADING,
});

export const removePaginationLoading = () => ({
  type: ProductActionTypes.REMOVE_PAGINATION_LOADING,
});

export const requestDumpProductIdsByCategory = (categoryCode: string, branchId: number, filters?: any) => ({
  type: ProductActionTypes.REQUEST_GET_PRODUCT_IDS,
  payload: {
    category: categoryCode,
    branchId,
    filters,
  }
});

export const fetchProductAssociation = (productId: number) => ({
  type: ProductActionTypes.REQUEST_PRODUCT_ASSOCIATION,
  payload: {
    productId,
  },
});

export const storeAssociationProducts = (relatedProducts: ProductVariantClass[], alterNativeProducts: ProductVariantClass[]) => ({
  type: ProductActionTypes.REQUEST_PRODUCT_ASSOCIATION_SUCCESS,
  payload: {
    relatedProducts,
    alterNativeProducts
  }
})

export const storeFilterProductError = (code: any) => ({
  type: ProductActionTypes.REQUEST_FILTERED_PRODUCTS_ERROR,
  payload: {
    error: code
  }
});

export const setFilterProductError = (error: any) => ({
  type: ProductActionTypes.SET_FILTERED_PRODUCTS_ERROR,
  payload: {
    error
  }
});
