import styled from 'styled-components/macro';
import { Switch } from '@material-ui/core';
import React from 'react';

interface IStyledLoadingDiv {
  width?: number;
  height?: number;
  isMobile?: boolean;
}

const ProductStock = styled.p`
  font-family: "Open Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 145.455% */
  text-decoration-line: underline;
  color: ${props => props.theme.colors.mainGreen};
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
  &:hover {
    font-weight: bold;
  }
  &.waiting-on-stock {
    color: ${props => props.theme.colors.waitingOnStockBlue};
  }
`;

const SpecialOrder = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #001A3B;
  font-family: "Open Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 145.455% */
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
  &:hover {
    font-weight: bold;
  }
`;

const StyledLoadButton = styled.button`
  width: 241px;
  height: 48px;
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { colors } }) => colors.white};
  margin-top: 47px;
  outline: none;

  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.01em;
    color: ${({ theme: { colors } }) => colors.mainGreen};
  }

  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme: { colors } }) => colors.mainGreenHover};
  }

  &:active {
    border: 1px solid ${({ theme: { colors } }) => colors.mainGreen};
  }

  &:disabled {
    border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};

    p {
      color: ${({ theme: { colors } }) => colors.borderGrey};
    }
  }

  @media (max-width: 1023px) {
    height: 40px;
    margin: 0 5px;

    p {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .loading-wrapper-list {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    height: auto;
    margin-right: 10px;
    margin-top: -2px;
    > * {
      width: 22px;
      height: 22px;
      border-width: 4px;
    }
  }
`;

const LoadNextTitle = styled.p`
  color: ${({ theme: { colors } }) => colors.mainGreen};
  margin: 0;
  font-size: 16px;
  font-family: Open Sans;
  line-height: 100%;
  letter-spacing: 0.01em;
  font-weight: 600;
`;

const SubcategoryTitle = styled.h1`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  letter-spacing: 0.1px;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 1023px) {
    margin-bottom: 24px;
  }
`;

const PageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

const DropdownsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DropdownTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  margin-right: 12px;
`;

const SingleDropdownWrapper = styled.div`
  display: flex;
  margin-right: 30px;
  height: 40px;
  width: max-content;
`;

const MobileButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MobileButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ProductWorkspace = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

const ProductListWorkspace = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-left: 4px;
  height: fit-content;
  @media (max-width: 1023px) {
    flex-direction: column;
    margin: 0;
  }
`;

const ProductListItemsWrapper = styled.div`
  position: relative;
  .loading-wrapper-list {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 99;
  }
`;

const ListStyleButtonsWrapper = styled.div`
  display: flex;
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ShowTableButton = styled.button`
  height: fit-content;
  width: fit-content;
  border: none;
  background: none;
  margin-right: 14px;
  padding: 0;
  outline: none;
`;

const ShowListButton = styled.button`
  height: fit-content;
  width: fit-content;
  border: none;
  background: none;
  padding: 0;
  outline: none;
`;

const LoadNextListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const InStockControlLabel = styled.span`
  font-family: Open Sans;
  margin-right: 14px;
  display: block;
  width: max-content;

  @media (max-width: 1023px) {
    font-size: 14px;
    margin-right: 0;
  }
`;

const GreenSwitch = styled(({ ...rest }) => <Switch {...rest} inputProps={{ 'data-test-id': 'in-stock-toggle' }} />)`
  .MuiSwitch-switchBase {
    color: ${({ theme: { colors } }) => colors.grey};
  }

  .Mui-checked {
    color: ${({ theme: { colors } }) => colors.switchCheckedGreen};
  }

  .MuiSwitch-track,
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme: { colors } }) => colors.buttonGrey};
    opacity: 1;
  }
`;

const LoadingWrapperList = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingIndicator = styled.div`
  width: ${(props: IStyledLoadingDiv) => (props.width ? `${props.width}px` : '80px')};
  height: ${(props: IStyledLoadingDiv) => (props.height ? `${props.height}px` : '80px')};
  border-radius: 60px;
  border: ${(props: IStyledLoadingDiv) => (props.isMobile ? '5px' : '10px')} solid
    ${({ theme: { colors } }) => colors.mainGreen};
  border-top-color: ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  animation: loading 1.2s linear infinite;
  -webkit-animation: loading 1.2s linear infinite;
`;

const LoadingWrapperPagination = styled.div`
  margin-top: ${(props: IStyledLoadingDiv) => (props.isMobile ? 0 : '15px')};
  display: flex;
  justify-content: center;
`;

const StyledAvailableAndLeadTime = styled.p`
  color: #196742;
  font-family: "Open Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 145.455% */
  text-decoration-line: underline;
  cursor: pointer;
  margin: 0;
  &:hover {
    font-weight: bold;
  }
  &.on-detail {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 4px;
  }
`;

export {
  LoadNextListWrapper,
  ShowListButton,
  ShowTableButton,
  Flex,
  ListStyleButtonsWrapper,
  ProductListWorkspace,
  ProductListItemsWrapper,
  ProductWorkspace,
  MobileButtonsWrapper,
  MobileButtonsRow,
  SingleDropdownWrapper,
  DropdownTitle,
  DropdownsWrapper,
  PageHeaderWrapper,
  SubcategoryTitle,
  LoadNextTitle,
  StyledLoadButton,
  SpecialOrder,
  ProductStock,
  InStockControlLabel,
  GreenSwitch,
  LoadingWrapperList,
  LoadingIndicator,
  LoadingWrapperPagination,
  StyledAvailableAndLeadTime
};
