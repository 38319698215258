// Styles
import styled from 'styled-components/macro';
import { Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const HeaderGrid = styled(Grid)`
  flex-wrap: nowrap;
`;

const MenuContentGrid = styled(Grid)`
  @media (min-width: 1201px) {
    width: 1200px;
    margin: 0 auto;
  }
`;

const UpperMenuGridWapper = styled.div`
  height: 45px;
`;

const UpperMenuGrid = styled(Grid)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
  background: #fafafa;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  z-index: 1200;
  &.is-sticky {
    position: fixed;
  }
`;

const TwoDropdownsWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 70%;
`;

const UpperMenuContent = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
`;

const HeaderLink = styled.p`
  font-family: Open Sans,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.letterGrey};
  white-space: nowrap;
  &:hover {
    cursor: pointer;
  }
`;

const MiddleMenuGrid = styled(Grid)`
  display: flex;
  height: 100px;
  align-items: center;
`;

const PhoneItem = styled(Grid)`
  justify-content: center;
  border-right: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  padding-right: 20px;
  align-items: flex-start;
`;

const PhoneTitle = styled.p`
  font-family: Open Sans,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 0;
  line-height: 150%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: ${({ theme: { colors } }) => colors.grey};
`;

const PhoneNumber = styled.p`
  margin: 0;
  font-family: Open Sans,serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.black};
`;

const MyAccountItem = styled(Grid)`
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  padding: 0 8px;
`;

const StyledButton = styled(Button)`
  font-family: Open Sans,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: right;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  white-space: nowrap;
  color: ${({ theme: { colors } }) => colors.textColor};
  padding: 4px 8px;
  .MuiButton-label {
    text-align: center;
  }
`;

const TruckItem = styled(Grid)`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 10px;
  padding-right: 16px;
  cursor: pointer;
  &.disabled {
    * {
      color: #ababab;
    }
    svg path {
      fill: #ababab;
    }
    /* .product-number {
      color: #fff;
      background: #787878;
    } */
    .total {
      .total-label {
        color: #ababab;
      }
      .total-number {
        color: #ababab;
      }
    }
  }
  .ic-cart {
    display: flex;
    flex-wrap: nowrap;
  }
  .product-number {
    min-width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    text-align: center;
    font-family: Open Sans, serif;
    font-weight: 700;
    font-size: 8px;
    color: #fff;
    background: #f5001d;
    padding: 0 2px;
    margin-left: -6px;
    margin-top: -6px;
  }
  .total {
    margin-left: 8px;
    .total-label {
      color: #8092AA;
      font-family: Open Sans;
      font-size: 14px;
      line-height: 24px;
      font-style: normal;
      font-weight: 400;
    }
    .total-number {
      color: #00133C;
      font-family: Open Sans;
      font-size: 16px;
      line-height: 28px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.1px;
    }
  }
`;

const StyledLinkFAQ = styled(Link)`
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.letterGrey};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.letterGrey};
  margin-left: 40px;
  @media (min-width: 1024px) and (max-width: 1200px) {
    margin-left: 30px;
  }
`;

const MiddleMenuContentWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const LogoGrid = styled(Grid)`
  height: 106px;
  width: fit-content;
  padding-right: 40px;
  @media (min-width: 1024px) and (max-width: 1200px) {
    padding-right: 10px;
  }
  a {
    margin-left: 0 !important;
  }
`;

const SearchGrid = styled(Grid)`
  & .MuiOutlinedInput-input {
    padding: 14.5px 14px;
    @media (min-width: 1024px) and (max-width: 1200px) {
      padding-left: 0;
    }
  }

  @media (min-width: 1024px) and (max-width: 1200px) {
    width: 320px;
    margin-right: 10px;
  }
  @media (min-width: 1201px) {
    width: 497px;
    margin-right: 40px;
  }
`;

const PricingGrid = styled(Grid)`
  display: flex;
  align-items: center;
  @media (max-width: 1200px) {
    padding-left: 15px;
  }
  .prices-switch {
    margin-left: 0;
    margin-right: 14px;
    .MuiFormControlLabel-label {
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin-left: 8px;
    }
  }
`;

const BranchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 33px;
  @media (max-width: 1200px) {
    margin-right: 20px;
  }
`;

const StyledParagraph = styled.p`
  margin: 0 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.xSmall};
  line-height: 20px;
  letter-spacing: 0.01em;
  white-space: nowrap;
  color: ${({ theme: { colors } }) => colors.letterGrey};
`;

const ColoredSvg = styled.span`
  position: relative;
  line-height: 1;
  margin-bottom: 5px;
  svg {
    path {
      fill: ${({ theme: { colors } }) => colors.mainGreen};
    }
  }
`;

export {
  HeaderGrid,
  MenuContentGrid,
  UpperMenuGridWapper,
  UpperMenuGrid,
  TwoDropdownsWrapper,
  UpperMenuContent,
  HeaderLink,
  MiddleMenuContentWrapper,
  MiddleMenuGrid,
  PhoneItem,
  PhoneNumber,
  PhoneTitle,
  MyAccountItem,
  StyledButton,
  TruckItem,
  StyledLink,
  StyledLinkFAQ,
  PricingGrid,
  LogoGrid,
  SearchGrid,
  BranchWrapper,
  StyledParagraph,
  ColoredSvg,
};
