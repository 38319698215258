import { Dialog } from '@material-ui/core';
import styled from 'styled-components/macro';

const ProductStockModalWrapper = styled(Dialog)`
  .btn-close {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(0, -50%);
    color: #000;
    border: 0;
    outline: 0;
    background: none;
    cursor: pointer;
    transition: all 0.4s ease;
    z-index: 2;
    &:hover {
      opacity: 0.7;
    }
  }
  .MuiDialog-paper {
    max-width: 800px;
    border-radius: 20px;
    @media (max-width: 1023px) {
      border-radius: 8px;
    }
    @media (max-width: 599px) {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      border-radius: 0;
      margin: 0;
    }
  }
  .MuiDialogContent-root {
    padding: 0;
  }
  .modal-header {
    position: relative;
    border-bottom: 1px solid #f0f0f1;
    padding: 16px 30px;
    @media (max-width: 1023px) {
      border-bottom: 0;
      padding: 14px 15px;
    }
    @media (max-width: 599px) {
      padding: 20px 15px;
    }
  }
  .modal-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 177.778% */
    letter-spacing: 0.1px;
    padding-right: 15px;
    margin: 0;
    @media (max-width: 1023px) {
      padding-right: 35px;
    }
  }
  .box-row {
    display: flex;
    flex-wrap: wrap;
    background: #f8f8f8;
  }
  .box-menu {
    width: 30%;
    background: #fff;
    padding: 20px;
    @media (max-width: 1023px) {
      width: 100%;
      padding: 0;
    }
    .MuiTabs-flexContainer {
      @media (max-width: 1023px) {
        padding: 0 10px;
      }
    }
    .MuiTabs-scroller {
      @media (max-width: 1023px) {
        display: block;
        white-space: nowrap;
        overflow-x: auto !important;
      }
    }
    .MuiTabs-root {
      @media (max-width: 1023px) {
        border-bottom: 1px solid #eff0f1;
      }
    }
    .MuiTab-root {
      opacity: 1;
      border-radius: 8px;
      background: #fff;
      padding: 12px 20px;
      @media (max-width: 1023px) {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        height: 28px;
        max-width: 100%;
        min-width: 0;
        min-height: 0;
        border: 1px solid #c2cad6;
        border-radius: 4px;
        padding: 0 12px;
        margin-right: 8px;
      }

      &.Mui-selected {
        background: #edf8f2;
        @media (max-width: 1023px) {
          background: ${({ theme: { colors } }) => colors.mainGreen};
          border-color: ${({ theme: { colors } }) => colors.mainGreen};
        }
        .tab-title {
          .md {
            text-align: left;
            font-weight: 700;
            color: ${({ theme: { colors } }) => colors.mainGreen};
            @media (max-width: 1023px) {
              color: #fff;
            }
            .number {
              background: #fff;
              color: ${({ theme: { colors } }) => colors.mainGreen};
            }
          }
          .sm {
            text-align: left;
            color: ${({ theme: { colors } }) => colors.mainGreen};
          }
        }
      }
      .MuiTab-wrapper {
        display: block;
        text-transform: none;
      }
    }
    .MuiTabs-indicator {
      display: none;
    }
    .tab-title {
      .md {
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        line-height: calc(24 / 16);
        color: #24283a;
        @media (max-width: 1023px) {
          font-size: 12px;
          font-weight: 600;
          border-radius: 4px;
          color: #00133c;
        }
        .number {
          display: none;
          justify-content: center;
          align-items: center;
          white-space: nowrap;
          height: 16px;
          background: ${({ theme: { colors } }) => colors.mainGreen};
          color: #fff;
          border-radius: 20px;
          padding: 0 4px;
          margin-left: 8px;
          @media (max-width: 1023px) {
            display: inline-flex;
          }
        }
      }
      .sm {
        text-align: left;
        font-size: 12px;
        line-height: calc(18 / 12);
        letter-spacing: 0.01em;
        color: #7c7e89;
        @media (max-width: 1023px) {
          display: none;
        }
      }
    }
  }
  .box-detail {
    width: 70%;
    padding: 20px;
    @media (max-width: 1023px) {
      width: 100%;
      padding: 0;
    }
    .box-card {
      height: 100%;
      background: #fff;
      border-radius: 12px;
      padding: 24px 20px;
      @media (max-width: 1023px) {
        padding: 16px 0 24px;
      }
    }
    .product-action {
      @media (max-width: 1023px) {
        padding: 0 10px;
      }
      [class*="ProductPriceWrapper-"] {
        align-items: baseline;
      }
      [class*="ProductPrice-"] {
        font-size: 18px;
        line-height: 32px;
      }
      [class*="ProductPackage-"] {
        font-size: 16px;
        color: #48576B;
        padding-left: 5px;
      }
      [class*="ProductSquarePrice-"] {
        font-size: 15px;
        color: #00133C;
      }
      [class*="TotalText-"] {
        font-size: 18px;
        color: #00133C;
      }
    }
    .product-price-section-wrapper {
      padding: 0 0 16px;
    }
    .box-popup-onhand {
      display: flex;
      align-items: center;
      gap: 0 16px;
      border-bottom: 1px solid #C2CAD6;
      padding-bottom: 16px;
      margin-bottom: 16px;
      @media (max-width: 1023px) {
        display: block;
        padding: 0 10px;
      }
    }
    .box-popup-onhand__text {
      color: #99A8BB;
      font-family: "Open Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0.1px;
      margin: 0;
      strong {
        color: #00133C;
        font-weight: 700;
      }
    }
    .ic-cube {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 83px;
      min-width: 83px;
      height: 84px;
      font-size: 45px;
      background: #dbf0e5;
      color: ${({ theme: { colors } }) => colors.mainGreen};
      border-radius: 50%;
      margin: 0 auto;
      @media (max-width: 1023px) {
        width: 48px;
        height: 48px;
        min-width: 0;
        font-size: 26px;
        margin: 0 0 16px;
      }
      &.cube-2 {
        @media (max-width: 1023px) {
          margin: 0 auto 16px;
        }
      }
      svg {
        width: 1em;
        height: 1em;
      }
    }
    .txt-name {
      font-weight: 700;
      font-size: 28px;
      line-height: calc(42 / 28);
      color: #24283a;
      text-align: center;
      margin-bottom: 10px;
      @media (max-width: 1023px) {
        font-size: 20px;
        line-height: calc(30 / 20);
        margin-bottom: 0;
      }
      span {
        display: inline-block;
        vertical-align: baseline;
      }
    }
    .txt-note {
      font-size: 16px;
      line-height: calc(24 / 16);
      text-align: center;
      color: #a8aab1;
      margin: 0 auto 50px;
      @media (max-width: 1023px) {
        max-width: 231px;
        font-size: 12px;
        margin: 0 auto 30px;
      }
      br {
        @media (max-width: 1023px) {
          display: none;
        }
      }
    }
    .box-action {
      display: flex;
      justify-content: center;
    }
    .btn-inquire {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 180px;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      border: none;
      color: #fff;
      background: ${({ theme: { colors } }) => colors.mainGreen};
      border-radius: 4px;
      outline: none;
      padding: 11px 15px;
      &:hover,
      &:active {
        background: ${({ theme: { colors } }) => colors.mainGreenHover};
      }
    }
  }
  .saving_wrapper {
    @media (max-width: 599px) {
      margin-left: -10px;
    }
  }
`;

export { ProductStockModalWrapper };
