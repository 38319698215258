import React, { createRef, RefObject } from 'react';
import styled from 'styled-components/macro';
import {
  Container,
  ExpansionPanelDetails,
  Typography,
  Link
} from '@material-ui/core';

import { GridComponent, ListComponent, ListItemComponent } from 'components/FAQPage';
import { BreadCrumbs } from 'components/common';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import get from 'lodash/get';
import axiosRequest from 'axios';
import fileDownload from 'js-file-download';
import { fetchDocumentGroup } from '../../api/DocumentGroup';
import { getFileExtension } from '../../utils/common';
import axios from '../../utils/axios';

const StyledContainer = styled(Container)`
  max-width: 1230px;
  margin: 0 auto;
  padding: 10px 15px 48px;
  min-height: 100vh;
  /* .MuiGrid-root {
    padding-bottom: 25px;
  } */
`;

const StyledBreadCrumbsContainer = styled(Container)`
  margin-top: 38px;
  margin-bottom: 12px;
  padding: 0;

  @media (max-width: 767px) {
    margin-top: 0px;
    margin-bottom: 20px;
  }
`;

const StyledH1 = styled.h1`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  letter-spacing: 0.1px;
  margin: 0 0 24px;
`;

const StyledH2 = styled.h2`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 150% */
  letter-spacing: 0.1px;
  /* text-transform: capitalize; */
  margin: 0 0 24px;
  @media (max-width: 767px) {
    margin: 0 0 18px;
  }
`;

const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)`
  background-color: ${({ theme: { colors } }) => colors.white};
  padding: 16px 24px 0;
  @media (max-width: 767px) {
    padding: 12px 0;
  }
`;

const StyledQuestion = styled(Typography)`
  font-family: Open Sans;
  font-weight: 600;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.large};
  line-height: 25px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  cursor: pointer;
  @media (max-width: 767px) {
    font-size: ${({ theme: { fontSizes } }) => fontSizes.small};
    line-height: 19px;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  font-family: Open Sans;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.large};
  line-height: 25px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.mainGreen};

  @media (max-width: 767px) {
    font-size: ${({ theme: { fontSizes } }) => fontSizes.medium};
    line-height: 22px;
  }
`;

const DocumentGroup: React.FC = () => {
  const { t } = useTranslation();

  const { data } = useQuery({
    queryKey: ['documentGroup'],
    queryFn: fetchDocumentGroup
  });

  const documents = get(data, 'data.data', []);

  const handleDownload = (url: string, filename: string, type: string) => {
    if (type === 'link') {
      window.open(url, '_blank');

      return;
    }

    axios.post('/api/auth/document-group/write-log-user-download-document')
      .then(() => {
        axiosRequest
          .get(url, {
            responseType: 'blob',
          })
          .then(res => {
            const fileExtension = getFileExtension(url);
            fileDownload(res.data, `${filename}.${fileExtension}`);
          });
      })
  };

  const refs: RefObject<HTMLHeadingElement>[] = Array.from({ length: documents.length }).map(() =>
    createRef()
  );

  const handleClick = (i: number) => {
    refs[i].current.scrollIntoView({ behavior: 'smooth' });
  };

  const renderQuestions = () =>
    documents &&
    documents.map((document, i) => (
      <GridComponent key={document.id}>
        <StyledH2 id={`${document.name}`} ref={refs[i]}>
          {document.name}
        </StyledH2>
        {document.files.length> 0 && document.files
          .sort((a, b) => a.position - b.position)
          .map(file => {
            const { name, path, id, type } = file;

            return (
              <StyledExpansionPanelDetails key={id}>
                <StyledQuestion onClick={() => handleDownload(path, name, type)}>{name}</StyledQuestion>
              </StyledExpansionPanelDetails>
            );
          })}
      </GridComponent>
    ));

  return <StyledContainer>
    <StyledBreadCrumbsContainer>
      <BreadCrumbs currentPageName="Document" />
    </StyledBreadCrumbsContainer>
    <StyledH1>{t('document_group.title').toString()}</StyledH1>
    <ListComponent>
      {documents &&
        documents.map((document, i) => (
          <ListItemComponent key={document.id}>
            <StyledLink onClick={() => handleClick(i)}>{document.name}</StyledLink>
          </ListItemComponent>
        ))}
    </ListComponent>
    {renderQuestions()}
  </StyledContainer>
};

export default DocumentGroup;
