import { Dispatch, SetStateAction, useState } from 'react';

import { FacetClass } from 'models/classes/facetClass';
import { ISelectedValue } from 'models/interfaces/productVariant';

interface IUseFacetRangeFilterState {
  step: number;
  minValue: number;
  maxValue: number;
  value: number[];
  setValue: Dispatch<SetStateAction<number[]>>;
  defaultValues: number[];
}

export const useFacetRangeFilterState = (
  facet: FacetClass,
  selectedFacetValues: ISelectedValue
): IUseFacetRangeFilterState => {
  const step = 1;
  const minValue = 0;
  const maxValue = facet.buckets.length - 1;

  const defaultValues = selectedFacetValues
    ? [
      facet.buckets.findIndex(bucket => bucket.key === selectedFacetValues.values[0]),
      facet.buckets.findIndex(
        bucket => bucket.key === selectedFacetValues.values[selectedFacetValues.values.length - 1]
      ),
    ]
    : [minValue, maxValue];

  const [value, setValue] = useState<number[]>(defaultValues);

  return {
    step,
    minValue,
    maxValue,
    value,
    setValue,
    defaultValues,
  };
};
