// Types
import { UserActionTypes } from 'ducks/user/types';

// Interfaces
import {
  IPatchProfile,
  ICreateProfile,
  ICreateNewCustomer,
  ICreateSubscriber,
} from 'ducks/user/interfaces/interfaces';
import { ISurvey } from 'models/interfaces/surveys';

export const fetchStorePhoneNumber = () => ({
  type: UserActionTypes.REQUEST_STORE_PHONE_NUMBER,
});

export const createUserProfile = (data: ICreateProfile) => ({
  type: UserActionTypes.REQUEST_CREATE_USER_PROFILE,
  payload: data,
});

export const finishUserProfile = () => ({
  type: UserActionTypes.FINISH_USER_PROFILE_CREATE,
});

export const createCustomer = (data: ICreateNewCustomer) => ({
  type: UserActionTypes.REQUEST_CREATE_CUSTOMER,
  payload: data,
});

export const createSubscriber = (data: ICreateSubscriber) => ({
  type: UserActionTypes.REQUEST_CREATE_SUBSCRIBER,
  payload: data,
});

export const setDefaultPhoneNumber = (phoneNumber: string) => ({
  type: UserActionTypes.SET_DEFAULT_PHONE_NUMBER,
  payload: {
    phoneNumber,
  },
});

export const patchProfile = (data: IPatchProfile) => ({
  type: UserActionTypes.REQUEST_PATCH_PROFILE,
  payload: { data },
});

export const surveyCompleted = (data: ISurvey) => ({
  type: UserActionTypes.SURVEY_COMPLETED,
  payload: { data },
});

export const orderCountIncrease = () => ({
  type: UserActionTypes.ORDER_COUNT_INCREASE,
  payload: {},
});

export const fetchProfile = () => ({
  type: UserActionTypes.REQUEST_FETCH_PROFILE,
});

export const setUpdateUserProfileStatus = (data: any) => ({
  type: UserActionTypes.REQUEST_UPDATE_USER_PROFILE_STATUS,
  payload: {
    data
  }
});