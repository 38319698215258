import * as React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import styled from 'styled-components';

interface IStyledRangeDiv {
  startOfRange?: boolean;
  endOfRange?: boolean;
  disabledProp?: boolean;
  highlighted?: boolean;
}

const StyledRangeDiv = styled.div`
  display: flex;
  ${(p: IStyledRangeDiv) => p.startOfRange && 'border-radius: 50% 0 0 50%'};
  ${(p: IStyledRangeDiv) => p.endOfRange && 'border-radius: 0 50% 50% 0'};
  background-color: ${(p: IStyledRangeDiv) =>
    !p.disabledProp && p.highlighted ? ({ theme: { colors } }) => colors.lightGrey : null};
`;

interface IStyledIconButton {
  disabledProp?: boolean;
  outlinedProp?: boolean;
  filledProp?: boolean;
}

const StyledIconButton = styled(IconButton)`
  height: 36px;
  width: 36px;
  padding: 0;
  ${(p: IStyledIconButton) =>
    !p.disabledProp &&
    p.outlinedProp &&
    `border: 1px solid ${({ theme: { colors } }) => colors.borderGrey}`};
  background-color: ${(p: IStyledIconButton) =>
    !p.disabledProp && p.filledProp ? ({ theme: { colors } }) => colors.mainGreen : null};
  &:hover {
    background-color: ${(p: IStyledIconButton) =>
    !p.disabledProp && p.filledProp ? ({ theme: { colors } }) => colors.mainGreen : null};
  }
`;

interface IStyledTypography {
  disabledProp?: boolean;
  filledProp?: boolean;
}

const StyledTypography = styled(Typography)`
  line-height: 1.6;
  color: ${(p: IStyledTypography) =>
    !p.disabledProp && p.filledProp ? ({ theme: { colors } }) => colors.white : null};
`;

interface IProps {
  filled?: boolean;
  outlined?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  startOfRange?: boolean;
  endOfRange?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onHover?: () => void;
  value: number | string;
}

const Day: React.FC<IProps> = ({
  filled,
  outlined,
  highlighted,
  disabled,
  startOfRange,
  endOfRange,
  onClick,
  onHover,
  value,
}: IProps) => {
  return (
    <StyledRangeDiv
      startOfRange={startOfRange}
      endOfRange={endOfRange}
      disabledProp={disabled}
      highlighted={highlighted}
    >
      <StyledIconButton
        disabledProp={disabled}
        outlinedProp={outlined}
        filledProp={filled}
        disabled={disabled}
        onClick={onClick}
        onMouseOver={onHover}
        onFocus={onHover}
      >
        <StyledTypography disabledProp={disabled} filledProp={filled} variant="body2">
          {value}
        </StyledTypography>
      </StyledIconButton>
    </StyledRangeDiv>
  );
};

export default Day;
