import {OrderActionTypes} from 'ducks/order/types';
import {SubmitOrderDto, IChangeCartDeliveryTypeDto, IOrder} from 'models/interfaces/order';
import axios from '../../utils/axios';

export const submitOrder = ({orderId, updatedOrder}: SubmitOrderDto) => ({
  // TODO put appropriate type
  type: OrderActionTypes.SUBMIT_ORDER,
  payload: {
    orderId,
    updatedOrder,
  },
});

export const finishLoadingOrder = () => ({
  type: OrderActionTypes.FINISH_LOADING,
});

export const startLoadingOrder = () => ({
  type: OrderActionTypes.START_LOADING,
});

export const changeCartDeliveryType = ({cartId, deliveryType}: IChangeCartDeliveryTypeDto) => ({
  type: OrderActionTypes.CHANGE_CART_DELIVERY_TYPE,
  payload: {
    cartId,
    deliveryType,
  },
});

export const setSubmitOrderError = () => ({
  type: OrderActionTypes.SET_SUBMIT_ORDER_ERROR,
});

export const setOrdersSubmitted = (orders: IOrder[]) => ({
  type: OrderActionTypes.SUBMIT_ORDER_SUCCESS,
  payload: {
    orders,
  }
});

export const setOrderSubmittedStatus = (status: boolean) => ({
  type: OrderActionTypes.SET_SUBMIT_ORDER_STATUS,
  payload: {
    status,
  }
});

export const setReorderStatus = (status: boolean) => ({
  type: OrderActionTypes.SET_REORDER_STATUS,
  payload: {
    status,
  }
});

export const setInquireNowOrderHistory = (payload: {
  type: string;
  orderNumber: string;
  dateRequired: string;
  orderType: string;
}) => ({
  type: OrderActionTypes.SET_INQUIRE_NOW_ORDER_HISTORY,
  payload,
});

export const setOpenInquireNowOrderHistory = (payload: boolean) => ({
  type: OrderActionTypes.SET_OPEN_INQUIRE_NOW_ORDER_HISTORY,
  payload,
});

export const setEmailInquireNowOrderHistorySent = (payload: boolean) => ({
  type: OrderActionTypes.SET_EMAIL_INQUIRE_NOW_ORDER_HISTORY_SENT,
  payload,
});

export const sendInquireNowOrderEmail = (payload: {
  addressId: number;
  content: string;
  orderType: string;
  orderNumber: string;
}) => {
  return axios.post('/api/auth/order/inquire-order', payload, {
    headers: {
      'Content-Type': 'application/json',
      'x-address-id': payload.addressId.toString(),
    },
  });
}

export const sendInquireNowQuoteEmail = (payload: {
  addressId: number;
  content: string;
  orderType: string;
  orderNumber: string;
}) => {
  return axios.post('/api/auth/order/inquire-quote', payload, {
    headers: {
      'Content-Type': 'application/json',
      'x-address-id': payload.addressId.toString(),
    },
  });
}