import React from 'react';

export const Taxes = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.39062 0H15.6094C16.9277 0 18 1.07227 18 2.39062V16.7344C18 17.434 17.434 18 16.7344 18H4.92188C4.22227 18 3.65625 17.434 3.65625 16.7344V13.5H1.26562C0.566016 13.5 0 12.934 0 12.2344V2.39062C0 1.07227 1.07227 0 2.39062 0ZM1.26562 12.375H3.65625V2.39062C3.65625 1.69102 3.09023 1.125 2.39062 1.125C1.69102 1.125 1.125 1.69102 1.125 2.39062V12.2344C1.125 12.3117 1.18828 12.375 1.26562 12.375ZM12.8815 11.7785C13.2938 11.4307 13.5 10.9716 13.5 10.4014C13.5 10.0858 13.4301 9.80962 13.2902 9.57292C13.154 9.33622 12.9371 9.11925 12.6394 8.922C12.3454 8.72475 11.8488 8.49163 11.1496 8.22265V6.63568C11.7376 6.68589 12.3114 6.82576 12.8707 7.05529L13.3763 5.79647C12.7129 5.50239 11.9707 5.33742 11.1496 5.30155V4.5H10.4127V5.32307C9.68486 5.3948 9.11653 5.59922 8.70777 5.93634C8.30259 6.26987 8.1 6.69665 8.1 7.21668C8.1 7.70084 8.24701 8.10789 8.54104 8.43784C8.83864 8.7642 9.34243 9.06724 10.0524 9.34698L10.4127 9.48685V11.1545C10.0685 11.133 9.6759 11.0631 9.23486 10.9447C8.79741 10.8264 8.42271 10.6901 8.11076 10.5359V11.9561C8.77052 12.2466 9.53785 12.4008 10.4127 12.4187V13.5H11.1496V12.3972C11.8918 12.3291 12.4691 12.1228 12.8815 11.7785ZM11.6982 10.1001C11.8201 10.2041 11.8811 10.3314 11.8811 10.4821C11.8811 10.8228 11.6373 11.0344 11.1496 11.1169V9.77735C11.3934 9.88852 11.5763 9.99611 11.6982 10.1001ZM9.88028 7.60938C9.77271 7.50538 9.71892 7.37448 9.71892 7.21668C9.71892 6.91184 9.9502 6.72534 10.4127 6.6572V7.9214C10.1689 7.81381 9.99143 7.7098 9.88028 7.60938Z"
        fill="#939599"
      />
    </svg>
  );
};
