import { useInfiniteQuery, useMutation } from 'react-query';
import { AxiosError } from 'axios';

import axios from 'utils/axios';
import { IRequestData } from 'models/interfaces/order';
import { OrderClass } from 'models/classes/orderClass';
import { QuoteClass } from 'models/classes/quoteClass';
import publicRequest from 'utils/publicRequest';

export const ORDERS = 'orders';

export interface IUniversalOrdersDataResponse {
  data: (OrderClass | QuoteClass)[];
	currentPage: number;
	lastPage: number;
  pageId: number;
  total: number;
}

function useProfileOrders(params?: IRequestData) {
  return useInfiniteQuery<IUniversalOrdersDataResponse, AxiosError>(
    [ORDERS, params],
    async ({ pageParam = 1 }) => {
      const { data } = await axios.get('/api/auth/order', {
        params: {
          ...params,
          page: pageParam,
        },
        headers: {
          accept: 'application/json',
        },
      });
      return data.data;
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.currentPage < lastPage.lastPage) {
          return lastPage.currentPage + 1;
        }
        return undefined;
      },
      staleTime: 10000,
      cacheTime: 2000,
      refetchOnWindowFocus: false
    }
  );
}

function useUpdateOptInOrder(id: number) {
  const handleUpdateOptInOrder = () => {
    return axios.put('/api/auth/order/update-opt-in', {
      id,
    });
  }
  return useMutation(handleUpdateOptInOrder);
}

function unsubscribeOrder(account: string, order: string) {
  return publicRequest.get('/api/unsubscribe-order', {
    params: {
      account,
      order,
    },
  });
}

function resubscribeOrder(account: string, order: string) {
  return publicRequest.post('/api/resubscribe-order', {}, {
    params: {
      account,
      order,
    },
  });
}

export { useProfileOrders, useUpdateOptInOrder, unsubscribeOrder, resubscribeOrder };
