import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { usePermission } from 'hooks/usePermission';
import { addDotToPrice } from 'utils/common/common';
import { OrderClass } from 'models/classes/orderClass';
import { QuoteClass } from 'models/classes/quoteClass';
import { WarningIcon } from 'assets/svg/Profile/OrdersAndQuotes';
import { selectIsHidePrice, selectLanguage } from 'ducks/application/selectors';
import { Arrow } from 'assets/svg/Profile/Tabs';
import { getSaleType } from 'utils/order/order';
import upperFirst from 'lodash/upperFirst';
import get from 'lodash/get';
import { withStyles, Checkbox, CheckboxProps } from '@material-ui/core';
import { Mailbox } from 'assets/svg/Checkout/Checkout';
import { useUpdateOptInOrder } from 'api/Order';
import {
  CreatedBlock,
  HeaderText,
  OrderBlockInfo,
  StatusText,
  TotalBlock,
  TotalText,
  CardHeaderMobile,
  OrderCreatedWrapper,
  StatusShipWrapper,
  StatusBlock,
  MoreInfoBlock,
  ShipDateBlock,
  NotificationInvoiced,
  UserTotalWrapper,
  TagBlock,
  StyledIconButtonMobile,
  StyledCardContentMobile,
  HeaderButton,
  HeaderButtonText,
  ButtonBlock,
  TextElement,
  UserBlockInfo,
  UserWrapper,
  ReorderButtonsBlock,
  NotificationBlock,
  StyledButton,
  ButtonTitle,
  UserOptInForOrderUpdateEmails,
} from './styles';

const GreenCheckbox = withStyles({
  root: {
    color: '#b2becc',
    '&$checked': {
      color: '#00853e',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

interface IMobileCardContentHeader {
  element: OrderClass | QuoteClass;
  isQuotes: boolean;
  statusText: string;
  expanded: boolean;
  handleExpandClick: () => void;
  // eslint-disable-next-line no-undef
  renderTag: () => JSX.Element;
  handleReorderClick: () => void;
  orderTotal: number;
  isReorder: boolean;
  openOrderTab?: (orderNumber: number) => void;
  handleClickInquireNow: CallableFunction;
  isShowOtpInOrderUpdateEmail: boolean;
}

const MobileCardContentHeader: React.FC<IMobileCardContentHeader> = ({
  element,
  isQuotes,
  statusText,
  expanded,
  handleExpandClick,
  renderTag,
  handleReorderClick,
  orderTotal,
  isReorder,
  openOrderTab,
  handleClickInquireNow,
  isShowOtpInOrderUpdateEmail
}: IMobileCardContentHeader) => {
  const { t } = useTranslation();
  const language = useSelector(selectLanguage);
  const saleType = getSaleType(element.saleType);
  const isHidePrice = useSelector(selectIsHidePrice);
  const { canPlaceOrder, canPlaceQuote } = usePermission();
  const [optInOrderUpdate, setOptInOrderUpdate] = useState(element.optInOrderUpdate);

  const {mutate, isSuccess} = useUpdateOptInOrder(element.id);

  useEffect(() => {
    if (isSuccess) {
      setOptInOrderUpdate(prev => !prev);
    }
  }, [isSuccess]);

  const ReorderButtons = () => {
    if (isQuotes) {
      return element?.acceptedOrderNumber ? (
        <>
          {
            canPlaceOrder && (
              <ButtonBlock>
                <StyledButton onClick={() => openOrderTab(element?.acceptedOrderNumber)}>
                  <ButtonTitle>{t('orders_and_quotes.go_to_order').toString()}</ButtonTitle>
                </StyledButton>
                <div>
                  <WarningIcon />
                </div>
              </ButtonBlock>
            )
          }
          {
            canPlaceQuote && (
              <ButtonBlock style={{ marginTop: 10 }}>
                <HeaderButton onClick={() => handleClickInquireNow()}>
                  <HeaderButtonText>{t('product_detail.inquire_now').toString()}</HeaderButtonText>
                </HeaderButton>
              </ButtonBlock>
            )
          }
        </>
      ) : (
        <>
          {canPlaceQuote && (
            <ButtonBlock style={{ marginTop: 10 }}>
              <HeaderButton onClick={() => handleClickInquireNow()}>
                <HeaderButtonText>{t('product_detail.inquire_now').toString()}</HeaderButtonText>
              </HeaderButton>
            </ButtonBlock>
          )}
        </>
      );
    }

    return (
      !isReorder && !isHidePrice && (
        <>
          {(canPlaceOrder) && (
            <ButtonBlock>
              <StyledButton onClick={handleReorderClick}>
                <ButtonTitle>{t('orders_and_quotes.reorder').toString()}</ButtonTitle>
              </StyledButton>
            </ButtonBlock>
          )}
          {
            canPlaceOrder && (
              <ButtonBlock style={{ marginTop: 10 }}>
                <HeaderButton onClick={() => handleClickInquireNow()}>
                  <HeaderButtonText>{t('product_detail.inquire_now').toString()}</HeaderButtonText>
                </HeaderButton>
              </ButtonBlock>
            )
          }
        </>
      )
    );
  };

  return (
    <StyledCardContentMobile>
      <CardHeaderMobile>
        <OrderCreatedWrapper>
          <OrderBlockInfo>
            <span>
              {isQuotes
                ? upperFirst(t('orders_and_quotes.sm_quote'))
                : upperFirst(t('orders_and_quotes.sm_order'))}
              #:
            </span>{' '}
            <strong> {element.erpOrderNumber}</strong>
          </OrderBlockInfo>

          <OrderBlockInfo>
            <span>
              {t('orders_and_quotes.po').toString()}
              #:
            </span>{' '}
            <strong>{element.customerRef}</strong>
          </OrderBlockInfo>
          {get(element, 'purchaseOrderNumber', null) && (
            <OrderBlockInfo>
              <span>Taiga PO#:</span> <strong>{element.purchaseOrderNumber}</strong>
            </OrderBlockInfo>
          )}

          <OrderBlockInfo>
            <span>
              {!element.isOffline ? (
                <>
                  {t('orders_and_quotes.online_order', {
                    name: isQuotes
                      ? upperFirst(t('orders_and_quotes.sm_quote'))
                      : upperFirst(t('orders_and_quotes.sm_order')),
                  })}
                </>
              ) : (
                <>
                  {t('orders_and_quotes.offline_order', {
                    name: isQuotes
                      ? upperFirst(t('orders_and_quotes.sm_quote'))
                      : upperFirst(t('orders_and_quotes.sm_order')),
                  })}
                </>
              )}
            </span>
          </OrderBlockInfo>
        </OrderCreatedWrapper>

        <StatusShipWrapper>
          {statusText && (
            <StatusBlock>
              <HeaderText>{t('orders_and_quotes.status').toString()} &nbsp;</HeaderText>
              <StatusText>
                {t(
                  `process.${statusText
                    ?.toLowerCase()
                    ?.split(' ')
                    ?.join('_')}`
                ).toString()}
              </StatusText>
            </StatusBlock>
          )}

          <MoreInfoBlock>
            <CreatedBlock isFrench={false}>
              <HeaderText>{t('orders_and_quotes.date_created').toString()}</HeaderText>
              <TextElement>{element.createdAt}</TextElement>
            </CreatedBlock>

            {element.dateRequired ? (
              <ShipDateBlock>
                <HeaderText>
                  {element.saleType.toLowerCase() === 'pickup' ||
                  element.saleType.toLowerCase() === 'will call'
                    ? t('orders_and_quotes.est_pickup_date').toString()
                    : t('orders_and_quotes.est_ship_date').toString()}
                </HeaderText>
                <TextElement>{element.dateRequired}</TextElement>
              </ShipDateBlock>
            ) : null}
          </MoreInfoBlock>
        </StatusShipWrapper>

        <UserWrapper>
          <MoreInfoBlock>
            <UserBlockInfo>
              <HeaderText>{t('orders_and_quotes.type').toString()}</HeaderText>
              <TextElement>{t(`orders_and_quotes.${saleType}`).toString()}</TextElement>
            </UserBlockInfo>

            <UserBlockInfo>
              <HeaderText>{t('orders_and_quotes.contact_name').toString()}:</HeaderText>
              <TextElement>{element.contactName}</TextElement>
            </UserBlockInfo>

            <UserBlockInfo className="full-width">
              <HeaderText>{t('orders_and_quotes.address').toString()}</HeaderText>
              <TextElement>{element.deliveryAddress}</TextElement>
            </UserBlockInfo>
          </MoreInfoBlock>
        </UserWrapper>
        {
          !isHidePrice && (
            <TotalBlock isFrench={language === 'fr_CA'}>
              <HeaderText>{t('orders_and_quotes.order_total').toString()}</HeaderText>
              <TotalText>
                $
                {addDotToPrice(
                  String(
                    isReorder
                      ? orderTotal
                      : element.erpOrderTotal +
                          element.adjustments.reduce(
                            (taxTotal, curAdjustment) => taxTotal + curAdjustment.amount,
                            0
                          )
                  )
                )}
              </TotalText>
            </TotalBlock>
          )
        }
        {expanded && get(element, 'tnStatus', '')?.toLowerCase() === 'invoiced' && (
          <NotificationInvoiced>
            <div className="box-ic">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.6585 26.4583L7.70846 19.5083L5.3418 21.8583L14.6585 31.175L34.6585 11.175L32.3085 8.825L14.6585 26.4583Z"
                  fill="white"
                />
              </svg>
            </div>

            <div className="box-text">
              <div className="lg">{t('orders_and_quotes.invoiced').toString()}</div>
              {element.saleType.toLowerCase() === 'will call' ? (
                <div className="md">
                  {t('orders_and_quotes.order_has_been_picked_up_and_invoiced').toString()}
                </div>
              ) : (
                <div className="md">
                  {t('orders_and_quotes.order_has_been_shipped_and_invoiced').toString()}
                </div>
              )}
            </div>
          </NotificationInvoiced>
        )}

        <UserTotalWrapper>
          <ReorderButtonsBlock>
            <ReorderButtons />
            {isReorder && (
              <NotificationBlock>
                {t('orders_and_quotes.prices_and_store_address_will_be_set').toString()}
              </NotificationBlock>
            )}
          </ReorderButtonsBlock>
        </UserTotalWrapper>
        {
          canPlaceOrder && !isQuotes && isShowOtpInOrderUpdateEmail && (
            <UserOptInForOrderUpdateEmails>
              <span className="ic">
                <Mailbox />
              </span>
              <div className="txt-message">
                {t('checkout.opt_in_order').toString()}
              </div>
              <div className="checkbox-wrapper">
                <GreenCheckbox checked={optInOrderUpdate} onClick={() => {
                  mutate();
                }} />
              </div>
            </UserOptInForOrderUpdateEmails>
          )
        }
        <TagBlock>
          {element.tag !== '' && element.tag !== null && element.tag !== 'null' ? (
            renderTag()
          ) : (
            <span />
          )}
          <StyledIconButtonMobile
            expanded={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <Arrow />
          </StyledIconButtonMobile>
        </TagBlock>
      </CardHeaderMobile>
    </StyledCardContentMobile>
  );
};

export default MobileCardContentHeader;
