import { Reducer } from 'redux';
import { CheckoutState, CheckoutStep, CheckoutActionTypes } from './types';

const initialState: CheckoutState = {
  order: 'order',
  step: CheckoutStep.FIRST,
  shippingMethod: null,
  addressWasChanged: false,
  previousSelectedAddress: '',
  itemErrors: [],
};

const checkoutReducer: Reducer<CheckoutState> = (
  state = initialState,
  { type, payload, fieldName }
) => {
  switch (type) {
    case CheckoutActionTypes.CHANGE_FILED: {
      return {
        ...state,
        [fieldName]: payload,
      };
    }
    case CheckoutActionTypes.ADD_ITEM_ERROR: {
      return {
        ...state,
        itemErrors: [...state.itemErrors, { itemId: payload.itemId, hasError: payload.hasError }],
      };
    }
    case CheckoutActionTypes.REMOVE_ITEM_ERROR: {
      const newErrors = [...state.itemErrors];
      return {
        ...state,
        itemErrors: newErrors.filter(item => item.itemId !== payload),
      };
    }

    default:
      return state;
  }
};

export default checkoutReducer;
