import React from 'react';

export const MaintenanceIcon = () => {
  return (
    <svg
      width="647"
      height="161"
      viewBox="0 0 647 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 155.004L647 155.004V148.816L0 148.816V155.004Z" fill="#DDDDDD" />
      <path
        d="M414.794 117.719C412.29 119.645 410.236 122.148 408.823 125.037H404.265C402.853 122.148 400.798 119.645 398.295 117.719H414.794Z"
        fill="#EFEFEF"
      />
      <path
        d="M422.433 116.117C419.801 118.172 417.683 120.739 416.206 123.757H411.391C409.915 120.739 407.796 118.107 405.164 116.117H422.433Z"
        fill="#DDDDDD"
      />
      <path d="M210.802 149.562H232.244V133.063H210.802V149.562Z" fill="#EFEFEF" />
      <path d="M210.802 132.309H232.244V115.809H210.802V132.309Z" fill="#EFEFEF" />
      <path d="M210.802 115.055H232.244V98.5555H210.802V115.055Z" fill="#EFEFEF" />
      <path d="M210.802 97.7344H232.244V81.2353H210.802V97.7344Z" fill="#EFEFEF" />
      <path d="M210.802 80.4766H232.244V63.9775H210.802V80.4766Z" fill="#EFEFEF" />
      <path d="M210.802 63.2227H232.244V46.7236H210.802V63.2227Z" fill="#EFEFEF" />
      <path d="M210.802 45.9688H232.244V29.4697L210.802 29.4697V45.9688Z" fill="#EFEFEF" />
      <path d="M188.005 149.562H209.447V133.063H188.005V149.562Z" fill="#EFEFEF" />
      <path d="M188.005 132.309H209.447V115.809H188.005V132.309Z" fill="#EFEFEF" />
      <path d="M188.005 115.055H209.447V98.5555H188.005V115.055Z" fill="#EFEFEF" />
      <path d="M188.005 97.7344H209.447V81.2353H188.005V97.7344Z" fill="#EFEFEF" />
      <path d="M188.005 80.4766H209.447V63.9775H188.005V80.4766Z" fill="#EFEFEF" />
      <path d="M188.005 63.2227H209.447V46.7236H188.005V63.2227Z" fill="#EFEFEF" />
      <path d="M188.005 45.9688H209.447V29.4697L188.005 29.4697V45.9688Z" fill="#EFEFEF" />
      <path d="M165.272 149.562H186.714V133.063H165.272V149.562Z" fill="#EFEFEF" />
      <path d="M165.272 132.309H186.714V115.809H165.272V132.309Z" fill="#EFEFEF" />
      <path d="M165.272 115.055H186.714V98.5555H165.272V115.055Z" fill="#EFEFEF" />
      <path d="M165.272 97.7344H186.714V81.2353H165.272V97.7344Z" fill="#EFEFEF" />
      <path d="M165.272 80.4766H186.714V63.9775H165.272V80.4766Z" fill="#EFEFEF" />
      <path d="M142.54 149.562H163.982V133.063H142.54V149.562Z" fill="#EFEFEF" />
      <path d="M142.54 132.309H163.982V115.809H142.54V132.309Z" fill="#EFEFEF" />
      <path d="M142.54 115.055H163.982V98.5555H142.54V115.055Z" fill="#EFEFEF" />
      <path d="M119.742 149.562H141.185V133.063H119.742V149.562Z" fill="#EFEFEF" />
      <path d="M119.742 132.309H141.185V115.809H119.742V132.309Z" fill="#EFEFEF" />
      <path d="M119.742 115.055H141.185V98.5555H119.742V115.055Z" fill="#EFEFEF" />
      <path d="M119.742 97.7344H141.185V81.2353H119.742V97.7344Z" fill="#EFEFEF" />
      <path d="M97.0099 149.562H118.452V133.063H97.0099V149.562Z" fill="#EFEFEF" />
      <path d="M97.0099 132.309H118.452V115.809H97.0099V132.309Z" fill="#EFEFEF" />
      <path d="M97.0099 115.055H118.452V98.5555H97.0099V115.055Z" fill="#EFEFEF" />
      <path d="M97.0099 97.7344H118.452V81.2353H97.0099V97.7344Z" fill="#EFEFEF" />
      <path d="M73.8585 149.566H95.3008V133.067H73.8585V149.566Z" fill="#EFEFEF" />
      <path d="M73.8585 132.309H95.3008V115.81H73.8585V132.309Z" fill="#EFEFEF" />
      <path d="M73.8585 115.055H95.3008V98.5556H73.8585V115.055Z" fill="#EFEFEF" />
      <path d="M51.126 149.566H72.5684V133.067H51.126V149.566Z" fill="#EFEFEF" />
      <path d="M51.126 132.309H72.5684V115.81H51.126V132.309Z" fill="#EFEFEF" />
      <path d="M51.126 115.055H72.5684V98.5556H51.126V115.055Z" fill="#EFEFEF" />
      <path d="M51.126 97.7344H72.5684V81.2353H51.126V97.7344Z" fill="#EFEFEF" />
      <path d="M51.126 80.4805H72.5684V63.9814H51.126V80.4805Z" fill="#EFEFEF" />
      <path d="M28.3287 149.566H49.771V133.067H28.3287L28.3287 149.566Z" fill="#EFEFEF" />
      <path d="M28.3287 132.309H49.771V115.81H28.3287L28.3287 132.309Z" fill="#EFEFEF" />
      <path d="M28.3287 115.055H49.771V98.5556H28.3287L28.3287 115.055Z" fill="#EFEFEF" />
      <path d="M28.3287 97.7344H49.771V81.2353H28.3287L28.3287 97.7344Z" fill="#EFEFEF" />
      <path d="M28.3287 80.4805H49.771V63.9814H28.3287L28.3287 80.4805Z" fill="#EFEFEF" />
      <path d="M5.59621 149.566H27.0386L27.0386 133.067H5.59621L5.59621 149.566Z" fill="#EFEFEF" />
      <path d="M5.59621 132.309H27.0386L27.0386 115.81H5.59621L5.59621 132.309Z" fill="#EFEFEF" />
      <path d="M5.59621 115.055H27.0386L27.0386 98.5556H5.59621L5.59621 115.055Z" fill="#EFEFEF" />
      <path d="M98.0887 123.973H100.015L100.015 65.9371H98.0887V123.973Z" fill="#323B49" />
      <path
        d="M73.1702 103.339L81.2592 95.2498C81.6444 94.8646 82.158 94.8646 82.5432 95.2498C82.9283 95.635 82.9283 96.1486 82.5432 96.5338L81.7086 97.3684C80.9382 98.1388 80.9382 99.4869 81.7086 100.257L78.1777 103.788C77.4073 103.018 76.0591 103.018 75.2888 103.788L74.4542 104.623C74.069 105.008 73.5554 105.008 73.1702 104.623C72.7851 104.302 72.7851 103.724 73.1702 103.339Z"
        fill="#323B49"
      />
      <path
        d="M82.7139 106.516L84.3936 104.836L80.762 101.204L79.0824 102.884L82.7139 106.516Z"
        fill="#6B7787"
      />
      <path
        d="M55.9009 94.9961V106.423H66.0442C68.4838 106.423 70.4097 108.414 70.4097 110.789H51.4712V90.5664C53.9107 90.6306 55.9009 92.5566 55.9009 94.9961Z"
        fill="#6B7787"
      />
      <path
        d="M33.8174 65.9766H68.8056C75.9316 65.9766 82.0946 70.8557 83.6996 77.7891L96.2825 131.588H33.8174V65.9766ZM81.1317 78.3669C79.7835 72.589 74.7118 68.6087 68.8056 68.6087H40.4298V128.955H92.9442L81.1317 78.3669Z"
        fill="#6B7787"
      />
      <path d="M76.7222 136.453H100.026V123.999H76.7222V136.453Z" fill="#323B49" />
      <path
        d="M111.754 81.3828V99.23H144.816V101.156L108.608 103.082V81.3828H111.754Z"
        fill="#6B7787"
      />
      <path d="M106.882 103.105H108.616V73.9593H106.882V103.105Z" fill="#3C4759" />
      <path
        d="M95.2544 65.9766H106.874V142.052H100.069V70.7915C97.4371 70.7273 95.2544 68.6087 95.2544 65.9766Z"
        fill="#6B7787"
      />
      <path
        d="M21.9397 135.121H80.0394L74.5183 112.844H36.8338C28.5521 112.844 21.8755 119.52 21.8755 127.802V135.121H21.9397Z"
        fill="#00853e"
      />
      <path
        d="M19.8853 146.545H89.4765V134.283L32.3398 129.276C25.6631 128.634 19.8853 133.898 19.8853 140.639V146.545Z"
        fill="#4A9463"
      />
      <path
        d="M83.9558 124.077H92.2374L86.3954 100.645C82.0941 100.645 78.9483 104.689 80.0397 108.862L83.9558 124.077Z"
        fill="#4A9463"
      />
      <path
        d="M32.276 117.207H40.5576C41.007 117.207 41.3922 117.592 41.3922 118.042C41.3922 118.491 41.007 118.876 40.5576 118.876H32.276C31.8266 118.876 31.4414 118.491 31.4414 118.042C31.4414 117.592 31.8266 117.207 32.276 117.207Z"
        fill="#4A9463"
      />
      <path
        d="M32.2755 119.582H40.5571C41.0065 119.582 41.3917 119.967 41.3917 120.417C41.3917 120.866 41.0065 121.251 40.5571 121.251H32.2755C31.8261 121.251 31.4409 120.866 31.4409 120.417C31.4409 119.967 31.8261 119.582 32.2755 119.582Z"
        fill="#4A9463"
      />
      <path
        d="M32.2755 121.957H40.5571C41.0065 121.957 41.3917 122.342 41.3917 122.792C41.3917 123.241 41.0065 123.626 40.5571 123.626H32.2755C31.8261 123.626 31.4409 123.241 31.4409 122.792C31.4409 122.342 31.8261 121.957 32.2755 121.957Z"
        fill="#4A9463"
      />
      <path
        d="M32.2755 124.332H40.5571C41.0065 124.332 41.3917 124.717 41.3917 125.167C41.3917 125.616 41.0065 126.001 40.5571 126.001H32.2755C31.8261 126.001 31.4409 125.616 31.4409 125.167C31.4409 124.717 31.8261 124.332 32.2755 124.332Z"
        fill="#4A9463"
      />
      <path d="M51.4511 112.84H70.3896V110.785H51.4511V112.84Z" fill="#323B49" />
      <path d="M19.9327 146.582H89.5239V142.987H19.9327V146.582Z" fill="#6B7787" />
      <path
        d="M38.3842 148.935C42.3956 144.924 42.3956 138.42 38.3842 134.409C34.3729 130.397 27.8692 130.397 23.8579 134.409C19.8466 138.42 19.8466 144.924 23.8579 148.935C27.8692 152.946 34.3729 152.946 38.3842 148.935Z"
        fill="#3C4759"
      />
      <path
        d="M31.1208 132.488C36.1925 132.488 40.3012 136.597 40.3012 141.669C40.3012 146.74 36.1925 150.849 31.1208 150.849C26.0491 150.849 21.9404 146.74 21.9404 141.669C21.9404 136.597 26.0491 132.488 31.1208 132.488Z"
        fill="#6B7787"
      />
      <path
        d="M37.4939 143.188C38.325 139.668 36.1455 136.141 32.626 135.31C29.1064 134.479 25.5795 136.658 24.7484 140.178C23.9174 143.698 26.0968 147.224 29.6164 148.056C33.136 148.887 36.6629 146.707 37.4939 143.188Z"
        fill="#3C4759"
      />
      <path
        d="M31.1208 147.768C34.4891 147.768 37.2197 145.037 37.2197 141.669C37.2197 138.301 34.4891 135.57 31.1208 135.57C27.7525 135.57 25.022 138.301 25.022 141.669C25.022 145.037 27.7525 147.768 31.1208 147.768Z"
        fill="#B2B2B2"
      />
      <path
        d="M31.1206 138.969C32.5972 138.969 33.817 140.189 33.817 141.665C33.817 143.142 32.5972 144.361 31.1206 144.361C29.6441 144.361 28.4243 143.142 28.4243 141.665C28.4243 140.189 29.6441 138.969 31.1206 138.969Z"
        fill="#3C4759"
      />
      <path
        d="M31.1212 139.742C32.2126 139.742 33.0472 140.641 33.0472 141.668C33.0472 142.695 32.1484 143.594 31.1212 143.594C30.0299 143.594 29.1953 142.695 29.1953 141.668C29.1953 140.641 30.0299 139.742 31.1212 139.742Z"
        fill="#6B7787"
      />
      <path
        d="M104.869 142.532C105.337 136.878 101.133 131.916 95.4791 131.448C89.8255 130.98 84.863 135.184 84.3951 140.837C83.9272 146.491 88.131 151.454 93.7847 151.921C99.4383 152.389 104.401 148.185 104.869 142.532Z"
        fill="#3C4759"
      />
      <path
        d="M100.434 148.773C104.355 145.558 104.927 139.774 101.713 135.853C98.4981 131.932 92.7138 131.36 88.793 134.574C84.8723 137.789 84.2997 143.573 87.5142 147.494C90.7287 151.415 96.513 151.987 100.434 148.773Z"
        fill="#6B7787"
      />
      <path
        d="M100.561 144.469C102.095 141.194 100.684 137.295 97.4089 135.761C94.1339 134.227 90.2352 135.639 88.7011 138.914C87.167 142.189 88.5783 146.087 91.8534 147.622C95.1285 149.156 99.0272 147.744 100.561 144.469Z"
        fill="#3C4759"
      />
      <path
        d="M100.714 142.154C100.971 138.795 98.4562 135.864 95.0977 135.607C91.7391 135.35 88.8083 137.865 88.5515 141.223C88.2946 144.582 90.8091 147.513 94.1676 147.77C97.5261 148.027 100.457 145.512 100.714 142.154Z"
        fill="#B2B2B2"
      />
      <path
        d="M94.6128 138.973C96.0894 138.973 97.3092 140.192 97.3092 141.669C97.3092 143.146 96.0894 144.365 94.6128 144.365C93.1363 144.365 91.9165 143.146 91.9165 141.669C91.9165 140.192 93.1363 138.973 94.6128 138.973Z"
        fill="#3C4759"
      />
      <path
        d="M94.6129 143.594C95.6766 143.594 96.5389 142.732 96.5389 141.668C96.5389 140.604 95.6766 139.742 94.6129 139.742C93.5493 139.742 92.687 140.604 92.687 141.668C92.687 142.732 93.5493 143.594 94.6129 143.594Z"
        fill="#6B7787"
      />
      <path d="M111.633 99.1992H157.92V97.4017H111.633V99.1992Z" fill="#4A9463" />
      <path d="M111.633 105.082H157.92V103.285H111.633V105.082Z" fill="#4A9463" />
      <path d="M154.966 103.328H157.919V99.2194H154.966V103.328Z" fill="#00853e" />
      <path d="M111.633 103.328H114.586V99.2194H111.633V103.328Z" fill="#00853e" />
      <path d="M133.332 103.328H136.285V99.2194H133.332V103.328Z" fill="#00853e" />
      <path d="M135.847 97.3867H157.931V80.4383H135.847V97.3867Z" fill="#00853e" />
      <path d="M150.438 86.0898H156.216V82.1737H150.438V86.0898Z" fill="#E7F9EE" />
      <path d="M150.438 87.4961H156.216V86.7899H150.438V87.4961Z" fill="#E7F9EE" />
      <path d="M150.438 88.5156H156.216V87.8094H150.438V88.5156Z" fill="#E7F9EE" />
      <path d="M111.621 97.3867H133.706V80.4383H111.621V97.3867Z" fill="#00853e" />
      <path d="M126.213 86.0898H131.991V82.1737H126.213V86.0898Z" fill="#E7F9EE" />
      <path d="M126.213 87.4961H131.991V86.7899H126.213V87.4961Z" fill="#E7F9EE" />
      <path d="M126.213 88.5156H131.991V87.8094H126.213V88.5156Z" fill="#E7F9EE" />
      <path d="M135.847 79.7305H157.931V62.782H135.847V79.7305Z" fill="#00853e" />
      <path d="M150.438 68.4961H156.216V64.58H150.438V68.4961Z" fill="#E7F9EE" />
      <path d="M150.438 69.8359H156.216V69.1298H150.438V69.8359Z" fill="#E7F9EE" />
      <path d="M150.438 70.8594H156.216V70.1532H150.438V70.8594Z" fill="#E7F9EE" />
      <path d="M111.621 79.7305H133.705V62.782H111.621V79.7305Z" fill="#00853e" />
      <path d="M126.212 68.4961H131.99V64.58H126.212V68.4961Z" fill="#E7F9EE" />
      <path d="M126.212 69.8359H131.99V69.1298H126.212V69.8359Z" fill="#E7F9EE" />
      <path d="M126.212 70.8594H131.99V70.1532H126.212V70.8594Z" fill="#E7F9EE" />
      <path
        d="M70.7949 97.2415C73.5554 99.1675 79.3333 97.4341 79.3333 97.4341L78.0493 94.2884C78.0493 94.2884 73.9406 95.1872 72.5925 94.3526C69.6393 92.555 69.5109 88.9599 69.5109 88.8957C69.4467 87.9327 68.6122 87.2265 67.6492 87.3549C66.6862 87.4191 66.0442 88.2537 66.1084 89.2167C66.1084 89.4735 66.5578 94.2884 70.7949 97.2415Z"
        fill="#00853e"
      />
      <path d="M69.3184 89.601L69.8961 87.9961L69.3184 89.601Z" fill="#F1AC70" />
      <path
        d="M62.829 83.1847L62.5557 88.5703L66.4667 88.7688L66.74 83.3831L62.829 83.1847Z"
        fill="#CE8B5E"
      />
      <path
        d="M70.0243 104.496V88.831C70.0243 87.6754 69.1256 86.7125 67.97 86.6483L61.4859 86.4557C60.0093 86.3915 58.8537 87.5471 58.7896 89.0236V104.431H70.0243V104.496Z"
        fill="#4A9463"
      />
      <path
        d="M70.4739 78.302C70.6665 73.9365 68.0986 72.5241 64.8886 72.3957C61.6787 72.2673 59.0466 73.4871 58.854 77.8526C58.7256 82.2181 61.1651 85.8774 64.3751 86.0058C67.585 86.1342 70.3455 82.6675 70.4739 78.302Z"
        fill="#FAC48D"
      />
      <path
        d="M64.7601 79.5885C64.7601 79.1391 64.5033 78.7539 64.1181 78.7539C63.7971 78.7539 63.4761 79.0749 63.4761 79.5243C63.4761 79.9737 63.7329 80.3589 64.1181 80.3589C64.4391 80.3589 64.7601 80.0379 64.7601 79.5885Z"
        fill="#030404"
      />
      <path
        d="M68.6764 79.5885C68.6764 79.1391 68.4196 78.7539 68.0986 78.7539C67.7133 78.7539 67.4565 79.0749 67.4565 79.5243C67.4565 79.9737 67.7134 80.3589 68.0344 80.3589C68.3554 80.3589 68.6764 80.0379 68.6764 79.5885Z"
        fill="#030404"
      />
      <path
        d="M66.6862 77.1488C69.6393 77.4056 70.5381 78.0476 70.5381 78.0476C70.5381 78.0476 71.9505 70.4722 64.3751 70.9216C56.8638 71.3068 58.854 78.7538 58.854 78.7538C58.854 78.7538 63.9899 76.8921 66.6862 77.1488Z"
        fill="#4A9463"
      />
      <path
        d="M66.6217 77.0828C69.1896 77.2754 70.2168 77.8532 70.4736 77.9816L70.5378 77.9174C71.5649 77.2754 72.2069 76.3766 72.2069 75.4136C72.2069 73.937 71.3082 73.1025 69.5748 72.5889C64.8883 71.2407 61.229 75.9914 61.229 77.9174C62.8982 77.468 65.0809 76.9544 66.6217 77.0828Z"
        fill="#00853e"
      />
      <path
        d="M62.3205 89.9231C62.3205 88.9601 61.5501 88.2539 60.5871 88.2539C59.6242 88.2539 58.918 89.0243 58.918 89.9873C58.918 90.2441 58.9822 95.059 63.0267 98.3331C65.6588 100.452 71.5651 99.1677 71.5651 99.1677L70.5379 95.8936C70.5379 95.8936 66.365 96.5355 65.0811 95.5726C62.1921 93.5824 62.3205 89.9873 62.3205 89.9231Z"
        fill="#00853e"
      />
      <path
        d="M58.918 104.496H72.2071L78.4344 113.548L75.738 115.923L70.0885 108.797H62.7057C60.5871 108.797 58.918 107.064 58.918 105.01V104.496Z"
        fill="#00853e"
      />
      <path
        d="M495.427 27.7139C514.237 28.6769 563.734 31.309 569.384 33.3634C576.381 35.8671 583.315 42.8006 583.315 42.8006L615.221 81.4482C615.221 81.4482 638.012 90.3718 641.607 92.1693C644.624 93.6459 644.624 96.4065 644.624 96.4065V134.861C644.624 136.723 643.084 138.264 641.222 138.264H488.237V34.519C488.237 30.6028 491.511 27.4571 495.427 27.7139Z"
        fill="#4A9463"
      />
      <path
        d="M553.912 52.4937V114.766C553.912 116.949 555.71 118.747 557.893 118.747H581.132L581.261 118.49C585.69 110.593 593.33 105.458 602.254 104.43C604.244 104.174 605.785 102.504 605.785 100.45V82.8597L586.397 50.2468C585.755 49.1554 584.535 48.4492 583.187 48.4492H557.893C555.71 48.5134 553.912 50.311 553.912 52.4937ZM554.94 52.4937C554.94 50.8246 556.288 49.4764 557.957 49.4764H583.251C584.214 49.4764 585.113 49.99 585.562 50.8246L604.822 83.1807V100.514C604.822 102.055 603.666 103.275 602.189 103.467C593.073 104.559 585.241 109.759 580.619 117.784H557.957C556.288 117.784 554.94 116.436 554.94 114.766V52.4937Z"
        fill="#00853e"
      />
      <path
        d="M565.917 51.9805H583.122C583.764 51.9805 584.342 52.3015 584.663 52.8793L599.172 78.7513C599.814 79.9069 598.979 81.3834 597.631 81.3834H565.917C563.414 81.3834 561.359 79.3291 561.359 76.8253V56.5386C561.359 54.0348 563.414 51.9805 565.917 51.9805Z"
        fill="white"
      />
      <path
        d="M565.917 51.9805H583.122C583.764 51.9805 584.342 52.3015 584.663 52.8793L599.172 78.7513C599.814 79.9069 598.979 81.3834 597.631 81.3834H565.917C563.414 81.3834 561.359 79.3291 561.359 76.8253V56.5386C561.359 54.0348 563.414 51.9805 565.917 51.9805Z"
        fill="#7AC5FC"
        fillOpacity="0.2"
      />
      <path
        d="M588.579 51.9805H590.954L615.222 81.3834H607.711C605.977 81.3834 604.372 80.4847 603.473 78.9439L588.001 52.9434C587.744 52.5583 588.065 51.9805 588.579 51.9805Z"
        fill="#E4F3FE"
      />
      <path
        d="M558.791 85.75H561.552C562.258 85.75 562.836 86.3278 562.836 87.034V91.7847C562.836 92.4909 562.258 93.0686 561.552 93.0686H558.791C558.085 93.0686 557.507 92.4909 557.507 91.7847V86.9698C557.507 86.2636 558.085 85.75 558.791 85.75Z"
        fill="#6B7787"
      />
      <path
        d="M644.625 113.613H640.708L639.424 98.1406H644.625C644.625 98.2048 649.376 105.459 644.625 113.613Z"
        fill="#DAD9D9"
      />
      <path
        d="M639.425 98.2031L640.709 113.675C638.782 113.675 637.113 112.327 636.792 110.401L635.444 102.954C634.995 100.45 636.856 98.2031 639.425 98.2031Z"
        fill="#FC861D"
      />
      <path
        d="M597.439 56.7955V82.6676H596.412V56.7955C596.412 53.6498 593.844 51.0819 590.698 51.0819H585.947V50.0547H590.698C594.422 50.0547 597.439 53.072 597.439 56.7955Z"
        fill="#6B7787"
      />
      <path
        d="M593.587 57.1179L597.439 56.7969V75.9281L593.137 75.0293C591.918 74.7083 591.019 73.6169 591.019 72.3329V59.9426C591.083 58.466 592.11 57.3105 593.587 57.1179Z"
        fill="#6B7787"
      />
      <path
        d="M512.568 28.6111C510.45 34.7099 500.371 67.066 500.05 122.534H499.022C499.343 67.708 509.23 35.3519 511.541 28.5469L512.568 28.6111Z"
        fill="#00853e"
      />
      <path
        d="M596.925 81.7695C597.439 81.7695 597.824 82.1547 597.824 82.6683C597.824 83.1819 597.439 83.5671 596.925 83.5671C596.412 83.5671 596.026 83.1819 596.026 82.6683C596.091 82.1547 596.476 81.7695 596.925 81.7695Z"
        fill="#6B7787"
      />
      <path
        d="M585.947 49.7344C586.461 49.7344 586.846 50.1196 586.846 50.6332C586.846 51.1467 586.461 51.5319 585.947 51.5319C585.434 51.5319 585.049 51.1467 585.049 50.6332C585.113 50.1196 585.498 49.7344 585.947 49.7344Z"
        fill="#6B7787"
      />
      <path
        d="M540.88 31.5002V116.307H536.9V32.2064V31.3718C537.028 27.0705 533.69 23.4112 529.324 23.2186L529.452 19.2383C536.001 19.4951 541.073 25.0162 540.88 31.5002Z"
        fill="#99BDAB"
      />
      <path
        d="M644.111 122.535C645.524 122.535 646.615 123.498 646.423 124.59L644.497 135.311C644.176 136.98 642.314 138.264 640.067 138.264H469.17C469.62 136.402 469.876 134.412 469.876 132.422C469.876 128.891 469.106 125.617 467.822 122.599H644.111V122.535Z"
        fill="#3C4759"
      />
      <path
        d="M469.876 132.359C469.876 134.349 469.619 136.339 469.17 138.201H425.322H422.048H397.974V122.473H467.822C469.106 125.554 469.876 128.828 469.876 132.359Z"
        fill="#1F252D"
      />
      <path
        d="M505.571 116.309H549.932C552.436 116.309 554.426 118.299 554.426 120.802V138.264C554.426 139.099 553.72 139.741 552.949 139.741H502.617C501.783 139.741 501.141 139.035 501.141 138.264V120.802C501.077 118.299 503.131 116.309 505.571 116.309Z"
        fill="#323B49"
      />
      <path d="M546.465 118.746H542.292V140.381H546.465V118.746Z" fill="#3C4759" />
      <path d="M513.275 118.746H509.102V140.381H513.275V118.746Z" fill="#3C4759" />
      <path d="M476.553 3.57422H167.051V104.751H476.553V3.57422Z" fill="#CFE2D7" />
      <path
        d="M479.827 0.300781H163.713V108.09H479.827V0.300781ZM476.553 104.752H167.052V3.57491H476.553V104.752Z"
        fill="#99BDAB"
      />
      <path d="M465.575 12.1133H460.888V96.8554H465.575V12.1133Z" fill="#E7F9EE" />
      <path d="M449.911 12.1133H445.224V96.8554H449.911V12.1133Z" fill="#E7F9EE" />
      <path d="M434.182 12.1133H429.496V96.8554H434.182V12.1133Z" fill="#E7F9EE" />
      <path d="M418.517 12.1133H413.831V96.8554H418.517V12.1133Z" fill="#E7F9EE" />
      <path d="M402.789 12.1133H398.102V96.8554H402.789V12.1133Z" fill="#E7F9EE" />
      <path d="M387.06 12.1133H382.374V96.8554H387.06V12.1133Z" fill="#E7F9EE" />
      <path d="M371.396 12.1133H366.709V96.8554H371.396V12.1133Z" fill="#E7F9EE" />
      <path d="M355.667 12.1133H350.981V96.8554H355.667V12.1133Z" fill="#E7F9EE" />
      <path d="M340.002 12.1133H335.316V96.8554H340.002V12.1133Z" fill="#E7F9EE" />
      <path d="M324.274 12.1133H319.588V96.8554H324.274V12.1133Z" fill="#E7F9EE" />
      <path d="M308.545 12.1133H303.859V96.8554H308.545V12.1133Z" fill="#E7F9EE" />
      <path d="M324.017 12.1133H319.331V96.8554H324.017V12.1133Z" fill="#E7F9EE" />
      <path d="M308.288 12.1133H303.602V96.8554H308.288V12.1133Z" fill="#E7F9EE" />
      <path d="M292.624 12.1133H287.938V96.8554H292.624V12.1133Z" fill="#E7F9EE" />
      <path d="M276.895 12.1133H272.209V96.8554H276.895V12.1133Z" fill="#E7F9EE" />
      <path d="M261.231 12.1133H256.544V96.8554H261.231V12.1133Z" fill="#E7F9EE" />
      <path d="M245.502 12.1133H240.816V96.8554H245.502V12.1133Z" fill="#E7F9EE" />
      <path d="M229.773 12.1133H225.087V96.8554H229.773V12.1133Z" fill="#E7F9EE" />
      <path d="M214.109 12.1133H209.423V96.8554H214.109V12.1133Z" fill="#E7F9EE" />
      <path d="M198.38 12.1133H193.694V96.8554H198.38V12.1133Z" fill="#E7F9EE" />
      <path d="M182.652 12.1133H177.965V96.8554H182.652V12.1133Z" fill="#E7F9EE" />
      <path d="M468.528 108.09H378.843V114.381H468.528V108.09Z" fill="#3C4759" />
      <path
        d="M378.971 112.776H375.44L366.709 120.865H320.101V112.776H303.666L292.56 122.856H177.965V108.09H378.971V112.776Z"
        fill="#3C4759"
      />
      <path
        d="M605.592 108.09C619.009 108.09 629.859 118.939 629.859 132.357C629.859 134.347 629.602 136.337 629.153 138.199H582.031C581.582 136.337 581.325 134.347 581.325 132.357C581.325 118.939 592.174 108.09 605.592 108.09Z"
        fill="#1F252D"
      />
      <path
        d="M624.341 137.977C627.45 127.62 621.574 116.703 611.216 113.594C600.858 110.485 589.942 116.361 586.833 126.718C583.723 137.076 589.6 147.993 599.957 151.102C610.315 154.211 621.232 148.335 624.341 137.977Z"
        fill="#3C4759"
      />
      <path
        d="M622.918 135.498C624.662 125.94 618.327 116.779 608.77 115.035C599.212 113.291 590.051 119.625 588.307 129.183C586.563 138.74 592.897 147.902 602.455 149.646C612.012 151.39 621.174 145.055 622.918 135.498Z"
        fill="#6B7787"
      />
      <path
        d="M605.592 119.836C612.461 119.836 618.11 125.421 618.11 132.355C618.11 139.224 612.525 144.873 605.592 144.873C598.722 144.873 593.073 139.288 593.073 132.355C593.137 125.485 598.722 119.836 605.592 119.836Z"
        fill="#3C4759"
      />
      <path
        d="M605.592 120.738C612.012 120.738 617.212 125.938 617.212 132.358C617.212 138.778 612.012 143.978 605.592 143.978C599.172 143.978 593.972 138.778 593.972 132.358C593.972 125.938 599.172 120.738 605.592 120.738Z"
        fill="#99BDAB"
      />
      <path
        d="M613.488 133.96C614.375 133.96 615.093 133.241 615.093 132.355C615.093 131.469 614.375 130.75 613.488 130.75C612.602 130.75 611.883 131.469 611.883 132.355C611.883 133.241 612.602 133.96 613.488 133.96Z"
        fill="#3C4759"
      />
      <path
        d="M597.696 133.96C598.582 133.96 599.301 133.241 599.301 132.355C599.301 131.469 598.582 130.75 597.696 130.75C596.809 130.75 596.091 131.469 596.091 132.355C596.091 133.241 596.809 133.96 597.696 133.96Z"
        fill="#3C4759"
      />
      <path
        d="M606.748 123.304C607.39 123.946 607.39 124.909 606.748 125.551C606.106 126.193 605.143 126.193 604.501 125.551C603.859 124.909 603.859 123.946 604.501 123.304C605.143 122.727 606.106 122.727 606.748 123.304Z"
        fill="#3C4759"
      />
      <path
        d="M606.748 139.161C607.39 139.803 607.39 140.766 606.748 141.408C606.106 142.05 605.143 142.05 604.501 141.408C603.859 140.766 603.859 139.803 604.501 139.161C605.143 138.519 606.106 138.519 606.748 139.161Z"
        fill="#3C4759"
      />
      <path
        d="M600.007 125.168C600.906 125.168 601.612 125.874 601.612 126.773C601.612 127.672 600.906 128.378 600.007 128.378C599.108 128.378 598.402 127.672 598.402 126.773C598.466 125.874 599.172 125.168 600.007 125.168Z"
        fill="#3C4759"
      />
      <path
        d="M611.177 136.402C612.076 136.402 612.782 137.109 612.782 138.007C612.782 138.906 612.076 139.612 611.177 139.612C610.279 139.612 609.573 138.906 609.573 138.007C609.637 137.109 610.343 136.402 611.177 136.402Z"
        fill="#3C4759"
      />
      <path
        d="M600.007 136.402C600.906 136.402 601.612 137.109 601.612 138.007C601.612 138.906 600.906 139.612 600.007 139.612C599.108 139.612 598.402 138.906 598.402 138.007C598.466 137.109 599.172 136.402 600.007 136.402Z"
        fill="#3C4759"
      />
      <path
        d="M611.177 125.168C612.076 125.168 612.782 125.874 612.782 126.773C612.782 127.672 612.076 128.378 611.177 128.378C610.279 128.378 609.573 127.672 609.573 126.773C609.637 125.874 610.343 125.168 611.177 125.168Z"
        fill="#3C4759"
      />
      <path
        d="M605.592 129.34C607.261 129.34 608.609 130.688 608.609 132.357C608.609 134.026 607.261 135.375 605.592 135.375C603.923 135.375 602.575 134.026 602.575 132.357C602.575 130.688 603.923 129.34 605.592 129.34Z"
        fill="#3C4759"
      />
      <path
        d="M605.592 130.176C606.812 130.176 607.775 131.139 607.775 132.359C607.775 133.578 606.812 134.541 605.592 134.541C604.372 134.541 603.409 133.578 603.409 132.359C603.409 131.139 604.372 130.176 605.592 130.176Z"
        fill="#6B7787"
      />
      <path
        d="M459.396 146.228C467.043 138.582 467.043 126.184 459.396 118.537C451.749 110.891 439.352 110.891 431.705 118.537C424.059 126.184 424.059 138.582 431.705 146.228C439.352 153.875 451.749 153.875 459.396 146.228Z"
        fill="#3C4759"
      />
      <path
        d="M463.128 133.577C463.79 123.884 456.469 115.49 446.776 114.829C437.084 114.167 428.69 121.488 428.028 131.181C427.367 140.873 434.688 149.267 444.38 149.928C454.073 150.59 462.467 143.269 463.128 133.577Z"
        fill="#6B7787"
      />
      <path
        d="M458.049 133.426C458.617 126.536 453.493 120.489 446.602 119.921C439.712 119.352 433.665 124.477 433.097 131.368C432.528 138.258 437.653 144.305 444.544 144.873C451.434 145.441 457.481 140.316 458.049 133.426Z"
        fill="#3C4759"
      />
      <path
        d="M457.045 134.217C458.066 127.881 453.757 121.917 447.421 120.896C441.085 119.875 435.121 124.184 434.1 130.52C433.079 136.856 437.388 142.82 443.724 143.841C450.06 144.862 456.024 140.553 457.045 134.217Z"
        fill="#99BDAB"
      />
      <path
        d="M454.597 131.271C455.239 131.913 455.239 132.876 454.597 133.517C453.955 134.159 452.992 134.159 452.35 133.517C451.708 132.876 451.708 131.913 452.35 131.271C452.992 130.629 454.019 130.629 454.597 131.271Z"
        fill="#3C4759"
      />
      <path
        d="M438.804 131.271C439.446 131.913 439.446 132.876 438.804 133.517C438.162 134.159 437.199 134.159 436.557 133.517C435.915 132.876 435.915 131.913 436.557 131.271C437.199 130.629 438.162 130.629 438.804 131.271Z"
        fill="#3C4759"
      />
      <path
        d="M446.7 123.308C447.342 123.95 447.342 124.913 446.7 125.555C446.058 126.197 445.095 126.197 444.453 125.555C443.811 124.913 443.811 123.95 444.453 123.308C445.095 122.731 446.058 122.731 446.7 123.308Z"
        fill="#3C4759"
      />
      <path
        d="M446.7 139.165C447.342 139.807 447.342 140.77 446.7 141.412C446.058 142.054 445.095 142.054 444.453 141.412C443.811 140.77 443.811 139.807 444.453 139.165C445.095 138.523 446.058 138.523 446.7 139.165Z"
        fill="#3C4759"
      />
      <path
        d="M439.96 125.172C440.858 125.172 441.565 125.878 441.565 126.777C441.565 127.676 440.858 128.382 439.96 128.382C439.061 128.382 438.354 127.676 438.354 126.777C438.419 125.878 439.125 125.172 439.96 125.172Z"
        fill="#3C4759"
      />
      <path
        d="M451.194 136.406C452.093 136.406 452.799 137.112 452.799 138.011C452.799 138.91 452.093 139.616 451.194 139.616C450.296 139.616 449.589 138.91 449.589 138.011C449.589 137.112 450.296 136.406 451.194 136.406Z"
        fill="#3C4759"
      />
      <path
        d="M439.96 136.406C440.858 136.406 441.565 137.112 441.565 138.011C441.565 138.91 440.858 139.616 439.96 139.616C439.061 139.616 438.354 138.91 438.354 138.011C438.419 137.112 439.125 136.406 439.96 136.406Z"
        fill="#3C4759"
      />
      <path
        d="M451.194 125.172C452.093 125.172 452.799 125.878 452.799 126.777C452.799 127.676 452.093 128.382 451.194 128.382C450.296 128.382 449.589 127.676 449.589 126.777C449.589 125.878 450.296 125.172 451.194 125.172Z"
        fill="#3C4759"
      />
      <path
        d="M445.545 129.34C447.214 129.34 448.562 130.688 448.562 132.357C448.562 134.026 447.214 135.375 445.545 135.375C443.876 135.375 442.528 134.026 442.528 132.357C442.528 130.688 443.876 129.34 445.545 129.34Z"
        fill="#3C4759"
      />
      <path
        d="M445.545 130.176C446.765 130.176 447.728 131.139 447.728 132.359C447.728 133.578 446.765 134.541 445.545 134.541C444.325 134.541 443.362 133.578 443.362 132.359C443.362 131.139 444.389 130.176 445.545 130.176Z"
        fill="#6B7787"
      />
      <path
        d="M445.545 109.629C456.587 109.629 465.832 117.59 467.822 128.054H464.74C462.75 119.259 454.918 112.582 445.481 112.582C436.108 112.582 428.211 119.194 426.221 128.054H423.14C425.258 117.59 434.503 109.629 445.545 109.629Z"
        fill="#99BDAB"
      />
      <path
        d="M399.515 151.935C410.329 151.935 419.095 143.168 419.095 132.354C419.095 121.54 410.329 112.773 399.515 112.773C388.701 112.773 379.934 121.54 379.934 132.354C379.934 143.168 388.701 151.935 399.515 151.935Z"
        fill="#3C4759"
      />
      <path
        d="M416.665 136.35C418.871 126.889 412.989 117.431 403.528 115.224C394.067 113.018 384.608 118.9 382.402 128.361C380.196 137.822 386.077 147.281 395.538 149.487C405 151.693 414.458 145.812 416.665 136.35Z"
        fill="#6B7787"
      />
      <path
        d="M401.427 144.728C408.257 143.662 412.931 137.261 411.865 130.43C410.799 123.599 404.397 118.925 397.566 119.991C390.735 121.058 386.062 127.459 387.128 134.29C388.194 141.121 394.596 145.795 401.427 144.728Z"
        fill="#3C4759"
      />
      <path
        d="M399.515 143.974C405.932 143.974 411.135 138.772 411.135 132.354C411.135 125.937 405.932 120.734 399.515 120.734C393.097 120.734 387.895 125.937 387.895 132.354C387.895 138.772 393.097 143.974 399.515 143.974Z"
        fill="#99BDAB"
      />
      <path
        d="M408.567 131.267C409.209 131.909 409.209 132.872 408.567 133.514C407.925 134.156 406.962 134.156 406.32 133.514C405.678 132.872 405.678 131.909 406.32 131.267C406.962 130.625 407.925 130.625 408.567 131.267Z"
        fill="#3C4759"
      />
      <path
        d="M392.71 131.267C393.352 131.909 393.352 132.872 392.71 133.514C392.068 134.156 391.105 134.156 390.463 133.514C389.821 132.872 389.821 131.909 390.463 131.267C391.105 130.625 392.132 130.625 392.71 131.267Z"
        fill="#3C4759"
      />
      <path
        d="M400.67 123.304C401.312 123.946 401.312 124.909 400.67 125.551C400.028 126.193 399.065 126.193 398.423 125.551C397.781 124.909 397.781 123.946 398.423 123.304C399.001 122.727 400.028 122.727 400.67 123.304Z"
        fill="#3C4759"
      />
      <path
        d="M400.67 139.161C401.312 139.803 401.312 140.766 400.67 141.408C400.028 142.05 399.065 142.05 398.423 141.408C397.781 140.766 397.781 139.803 398.423 139.161C399.001 138.519 400.028 138.519 400.67 139.161Z"
        fill="#3C4759"
      />
      <path
        d="M393.929 125.168C394.828 125.168 395.534 125.874 395.534 126.773C395.534 127.672 394.828 128.378 393.929 128.378C393.031 128.378 392.324 127.672 392.324 126.773C392.389 125.874 393.095 125.168 393.929 125.168Z"
        fill="#3C4759"
      />
      <path
        d="M405.1 136.402C405.999 136.402 406.705 137.109 406.705 138.007C406.705 138.906 405.999 139.612 405.1 139.612C404.201 139.612 403.495 138.906 403.495 138.007C403.559 137.109 404.265 136.402 405.1 136.402Z"
        fill="#3C4759"
      />
      <path
        d="M393.929 136.402C394.828 136.402 395.534 137.109 395.534 138.007C395.534 138.906 394.828 139.612 393.929 139.612C393.031 139.612 392.324 138.906 392.324 138.007C392.389 137.109 393.095 136.402 393.929 136.402Z"
        fill="#3C4759"
      />
      <path
        d="M405.1 125.168C405.999 125.168 406.705 125.874 406.705 126.773C406.705 127.672 405.999 128.378 405.1 128.378C404.201 128.378 403.495 127.672 403.495 126.773C403.559 125.874 404.265 125.168 405.1 125.168Z"
        fill="#3C4759"
      />
      <path
        d="M399.515 129.34C401.184 129.34 402.532 130.688 402.532 132.357C402.532 134.026 401.184 135.374 399.515 135.374C397.846 135.374 396.497 134.026 396.497 132.357C396.497 130.688 397.846 129.34 399.515 129.34Z"
        fill="#3C4759"
      />
      <path
        d="M399.515 130.172C400.734 130.172 401.697 131.135 401.697 132.355C401.697 133.574 400.734 134.537 399.515 134.537C398.295 134.537 397.332 133.574 397.332 132.355C397.332 131.135 398.295 130.172 399.515 130.172Z"
        fill="#6B7787"
      />
      <path
        d="M399.514 109.629C410.557 109.629 419.801 117.59 421.791 128.054H418.71C416.72 119.259 408.887 112.582 399.45 112.582C390.077 112.582 382.181 119.194 380.191 128.054H377.109C379.228 117.59 388.472 109.629 399.514 109.629Z"
        fill="#99BDAB"
      />
      <path
        d="M272.235 134.713C273.547 123.979 265.908 114.214 255.174 112.902C244.44 111.591 234.675 119.229 233.363 129.963C232.052 140.697 239.69 150.462 250.424 151.774C261.158 153.085 270.923 145.447 272.235 134.713Z"
        fill="#3C4759"
      />
      <path
        d="M269.512 137.875C272.569 128.653 267.572 118.699 258.351 115.642C249.129 112.585 239.175 117.582 236.118 126.803C233.061 136.025 238.058 145.978 247.279 149.036C256.501 152.093 266.454 147.096 269.512 137.875Z"
        fill="#6B7787"
      />
      <path
        d="M265.084 134.68C266.387 127.89 261.938 121.33 255.148 120.027C248.358 118.724 241.797 123.173 240.495 129.963C239.192 136.753 243.641 143.314 250.431 144.616C257.221 145.919 263.781 141.47 265.084 134.68Z"
        fill="#3C4759"
      />
      <path
        d="M264.267 134.17C265.292 127.835 260.987 121.868 254.651 120.843C248.316 119.818 242.349 124.123 241.324 130.458C240.3 136.794 244.605 142.761 250.94 143.785C257.275 144.81 263.242 140.505 264.267 134.17Z"
        fill="#99BDAB"
      />
      <path
        d="M261.809 131.267C262.451 131.909 262.451 132.872 261.809 133.514C261.167 134.156 260.204 134.156 259.562 133.514C258.92 132.872 258.92 131.909 259.562 131.267C260.204 130.625 261.231 130.625 261.809 131.267Z"
        fill="#3C4759"
      />
      <path
        d="M244.861 133.96C245.747 133.96 246.466 133.241 246.466 132.355C246.466 131.469 245.747 130.75 244.861 130.75C243.974 130.75 243.256 131.469 243.256 132.355C243.256 133.241 243.974 133.96 244.861 133.96Z"
        fill="#3C4759"
      />
      <path
        d="M253.913 123.304C254.555 123.946 254.555 124.909 253.913 125.551C253.271 126.193 252.308 126.193 251.666 125.551C251.024 124.909 251.024 123.946 251.666 123.304C252.308 122.727 253.271 122.727 253.913 123.304Z"
        fill="#3C4759"
      />
      <path
        d="M253.913 139.161C254.555 139.803 254.555 140.766 253.913 141.408C253.271 142.05 252.308 142.05 251.666 141.408C251.024 140.766 251.024 139.803 251.666 139.161C252.308 138.519 253.271 138.519 253.913 139.161Z"
        fill="#3C4759"
      />
      <path
        d="M247.172 125.168C248.07 125.168 248.777 125.874 248.777 126.773C248.777 127.672 248.07 128.378 247.172 128.378C246.273 128.378 245.567 127.672 245.567 126.773C245.631 125.874 246.337 125.168 247.172 125.168Z"
        fill="#3C4759"
      />
      <path
        d="M258.406 136.402C259.305 136.402 260.011 137.109 260.011 138.007C260.011 138.906 259.305 139.612 258.406 139.612C257.508 139.612 256.802 138.906 256.802 138.007C256.802 137.109 257.508 136.402 258.406 136.402Z"
        fill="#3C4759"
      />
      <path
        d="M247.172 136.402C248.07 136.402 248.777 137.109 248.777 138.007C248.777 138.906 248.07 139.612 247.172 139.612C246.273 139.612 245.567 138.906 245.567 138.007C245.631 137.109 246.337 136.402 247.172 136.402Z"
        fill="#3C4759"
      />
      <path
        d="M258.406 125.168C259.305 125.168 260.011 125.874 260.011 126.773C260.011 127.672 259.305 128.378 258.406 128.378C257.508 128.378 256.802 127.672 256.802 126.773C256.802 125.874 257.508 125.168 258.406 125.168Z"
        fill="#3C4759"
      />
      <path
        d="M252.821 129.34C254.49 129.34 255.838 130.688 255.838 132.357C255.838 134.026 254.49 135.374 252.821 135.374C251.152 135.374 249.803 134.026 249.803 132.357C249.803 130.688 251.152 129.34 252.821 129.34Z"
        fill="#3C4759"
      />
      <path
        d="M252.821 130.176C254.041 130.176 255.004 131.139 255.004 132.359C255.004 133.578 254.041 134.541 252.821 134.541C251.601 134.541 250.638 133.578 250.638 132.359C250.574 131.139 251.601 130.176 252.821 130.176Z"
        fill="#6B7787"
      />
      <path
        d="M252.821 109.629C263.863 109.629 273.108 117.59 275.098 128.054H272.016C270.026 119.259 262.194 112.582 252.757 112.582C243.32 112.582 235.487 119.259 233.562 128.054H230.48C232.47 117.59 241.715 109.629 252.821 109.629Z"
        fill="#99BDAB"
      />
      <path
        d="M220.575 146.216C228.222 138.57 228.222 126.172 220.575 118.526C212.929 110.879 200.531 110.879 192.884 118.526C185.238 126.172 185.238 138.57 192.884 146.216C200.531 153.863 212.929 153.863 220.575 146.216Z"
        fill="#3C4759"
      />
      <path
        d="M224.019 135.697C225.848 126.156 219.595 116.939 210.054 115.111C200.513 113.283 191.297 119.535 189.468 129.076C187.64 138.617 193.892 147.834 203.433 149.662C212.974 151.49 222.191 145.238 224.019 135.697Z"
        fill="#6B7787"
      />
      <path
        d="M219.028 134.723C220.331 127.933 215.882 121.373 209.092 120.07C202.302 118.767 195.742 123.216 194.439 130.006C193.137 136.796 197.585 143.357 204.375 144.659C211.165 145.962 217.726 141.513 219.028 134.723Z"
        fill="#3C4759"
      />
      <path
        d="M206.809 143.991C213.226 143.948 218.394 138.711 218.351 132.294C218.309 125.876 213.072 120.708 206.654 120.751C200.237 120.794 195.069 126.031 195.111 132.448C195.154 138.866 200.391 144.033 206.809 143.991Z"
        fill="#99BDAB"
      />
      <path
        d="M215.778 131.267C216.42 131.909 216.42 132.872 215.778 133.514C215.136 134.156 214.173 134.156 213.531 133.514C212.889 132.872 212.889 131.909 213.531 131.267C214.173 130.625 215.136 130.625 215.778 131.267Z"
        fill="#3C4759"
      />
      <path
        d="M198.83 133.96C199.716 133.96 200.435 133.241 200.435 132.355C200.435 131.469 199.716 130.75 198.83 130.75C197.944 130.75 197.225 131.469 197.225 132.355C197.225 133.241 197.944 133.96 198.83 133.96Z"
        fill="#3C4759"
      />
      <path
        d="M207.882 123.304C208.524 123.946 208.524 124.909 207.882 125.551C207.24 126.193 206.277 126.193 205.635 125.551C204.993 124.909 204.993 123.946 205.635 123.304C206.277 122.727 207.24 122.727 207.882 123.304Z"
        fill="#3C4759"
      />
      <path
        d="M207.882 139.161C208.524 139.803 208.524 140.766 207.882 141.408C207.24 142.05 206.277 142.05 205.635 141.408C204.993 140.766 204.993 139.803 205.635 139.161C206.277 138.519 207.24 138.519 207.882 139.161Z"
        fill="#3C4759"
      />
      <path
        d="M201.141 125.168C202.04 125.168 202.746 125.874 202.746 126.773C202.746 127.672 202.04 128.378 201.141 128.378C200.242 128.378 199.536 127.672 199.536 126.773C199.6 125.874 200.307 125.168 201.141 125.168Z"
        fill="#3C4759"
      />
      <path
        d="M212.311 136.402C213.21 136.402 213.916 137.109 213.916 138.007C213.916 138.906 213.21 139.612 212.311 139.612C211.413 139.612 210.707 138.906 210.707 138.007C210.771 137.109 211.477 136.402 212.311 136.402Z"
        fill="#3C4759"
      />
      <path
        d="M201.141 136.402C202.04 136.402 202.746 137.109 202.746 138.007C202.746 138.906 202.04 139.612 201.141 139.612C200.242 139.612 199.536 138.906 199.536 138.007C199.6 137.109 200.307 136.402 201.141 136.402Z"
        fill="#3C4759"
      />
      <path
        d="M212.311 125.168C213.21 125.168 213.916 125.874 213.916 126.773C213.916 127.672 213.21 128.378 212.311 128.378C211.413 128.378 210.707 127.672 210.707 126.773C210.771 125.874 211.477 125.168 212.311 125.168Z"
        fill="#3C4759"
      />
      <path
        d="M206.726 129.34C208.395 129.34 209.744 130.688 209.744 132.357C209.744 134.026 208.395 135.374 206.726 135.374C205.057 135.374 203.709 134.026 203.709 132.357C203.709 130.688 205.057 129.34 206.726 129.34Z"
        fill="#3C4759"
      />
      <path
        d="M206.726 130.176C207.945 130.176 208.908 131.139 208.908 132.359C208.908 133.578 207.945 134.541 206.726 134.541C205.506 134.541 204.543 133.578 204.543 132.359C204.543 131.139 205.506 130.176 206.726 130.176Z"
        fill="#6B7787"
      />
      <path
        d="M206.726 109.629C217.768 109.629 227.013 117.59 229.003 128.054H225.922C223.931 119.259 216.099 112.582 206.662 112.582C197.289 112.582 189.393 119.194 187.402 128.054H184.321C186.439 117.59 195.684 109.629 206.726 109.629Z"
        fill="#99BDAB"
      />
    </svg>
  );
};
