import Routes from 'routes';
import { QueryClient, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import axios from 'utils/axios';
import { AxiosError } from 'axios';
import { ProductClass } from 'models/classes/productClass';

const queryClient = new QueryClient();

const PRODUCTS = 'products';

interface IFetchProduct {
  productId: string;
  addressId: number;
  language?: 'en_US' | 'en_CA' | 'fr_CA';
  categoryCode?: string;
}

function useProduct({ productId, addressId, language, categoryCode }: IFetchProduct) {
  const history = useHistory();
  return useQuery<ProductClass, AxiosError>(
    [PRODUCTS, { productId, addressId, language }],
    async () => {
      if (productId && addressId) {
        const { data } = await axios.get(`/api/auth/product/product-detail/${productId}`, {
          headers: {
            accept: 'application/json',
            'X-Address-Id': addressId.toString(),
            'Accept-Language': language,
            'x-category-code': categoryCode,
          },
        });

        if (!data.data.enabled) {
          return history.replace(Routes.NOT_FOUND);
        }

        return data.data;
      }

      return null;
    },
  );
}

function requestProductDetail({ productId, addressId, language, categoryCode }) {
  return axios.get(`/api/auth/product/product-detail/${productId}?language=${language}`, {
    headers: {
      accept: 'application/json',
      'X-Address-Id': addressId.toString(),
      'Accept-Language': language,
      'x-category-code': categoryCode,
    },
  }).then(response => {
    return {
      data: response.data,
      error: null,
      status: 'success',
      loading: false,
    };
  }).catch(error => {
    return {
      data: null,
      error,
      status: 'error',
      loading: false,
    };
  });
}

function requestProductNearbyBranch({ productId, addressId, language, categoryCode }) {
  return axios.get(`/api/auth/product/nearby-branch/get-nearby-branch/${productId}`, {
    headers: {
      accept: 'application/json',
      'X-Address-Id': addressId.toString(),
      'Accept-Language': language,
      'x-category-code': categoryCode,
    },
  }).then(response => {
    return {
      data: response.data,
      error: null,
      status: 'success',
      loading: false,
    };
  }).catch(error => {
    return {
      data: null,
      error,
      status: 'error',
      loading: false,
    };
  });
}

function searchProductBySku({ query, addressId, language }) {
  return axios.get('/api/auth/product/search/product-by-sku', {
    params: {
      query,
    },
    headers: {
      accept: 'application/json',
      'X-Address-Id': addressId.toString(),
      'Accept-Language': language,
    },
  });
}


function getProductDetail(productId, addressId, language, categoryCode) {
  return queryClient.fetchQuery({
    queryKey: [PRODUCTS, { productId, addressId, language }],
    queryFn: () => requestProductDetail({ productId, addressId, language, categoryCode }),
  });
}

function useGetProductDetail(productId, addressId, language, categoryCode, options) {
  return useQuery(
    [PRODUCTS, { productId, addressId, language, categoryCode }],
    async () => {
      try {
        const { data } = await requestProductDetail({ productId, addressId, language, categoryCode });
        return data.data;
      } catch (error) {
        return null;
      }
    },
    {
      enabled: !!productId && !!addressId,
      ...options,
    }
  );
}

export { useProduct, PRODUCTS, requestProductDetail, requestProductNearbyBranch, searchProductBySku, getProductDetail, useGetProductDetail };
