import React, { useCallback, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { CustomArrowProps } from 'react-slick';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  LeftArrow,
  RightArrow,
} from 'assets/svg/FeaturedProducts/FeaturedProducts';
import { menuIsOpenedSelector, selectAddressId, selectErpCustomerAddressCode } from 'ducks/application/selectors';
import { ProductVariantClass } from 'models/classes/productVariantClass';
import ProductTableHoveredCard from 'pages/Product/ProductTableView/ProductTableHoveredCard/ProductTableHoveredCard';
import CarouselProductCard from 'components/common/ProductCarousel/components/CarouselProductCard';
import get from 'lodash/get';
import {usePermission} from 'hooks/usePermission';
import {setClickedProductRequestNearbyHubsAndBranch, setOpenPopupInquireNow} from 'ducks/product/actions';
import {NearbyBranchEmailType} from 'ducks/productVariant/types';
import {NearbyBranchContext} from 'context/NearbyBranchContext';
import TagManager from 'react-gtm-module';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { createUserActionLog } from 'api/UserActionLog';
import {
  CarouselHeader,
  ProductStock,
  SliderWrapper,
  SpecialOrder,
  StyledSlider,
  ListWrapper,
} from './styles';

const SampleNextArrow: React.FC<CustomArrowProps> = ({ onClick, className }: CustomArrowProps) => {
  return (
    <div className={className} onClick={onClick} aria-hidden="true" style={{ top: '25%' }}>
      <RightArrow />
    </div>
  );
};

const SamplePrevArrow: React.FC<CustomArrowProps> = ({ onClick, className }: CustomArrowProps) => {
  return (
    <div className={className} onClick={onClick} style={{ top: '25%' }} aria-hidden="true">
      <LeftArrow />
    </div>
  );
};

interface IProductCarouselProps {
	categoryName?: string;
	categoryCode?: string;
	isRelated: boolean;
	products: ProductVariantClass[];
  productType: string;
}

const AssociationProductSlider: React.FC<IProductCarouselProps> = ({
  categoryName,
  categoryCode,
 	products,
 	isRelated,
  productType
}: IProductCarouselProps) => {
  const dispatch = useDispatch();
  const { setEmailType } = React.useContext(NearbyBranchContext);
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const {canSeeInventory} = usePermission();
  const isOpened = useSelector(menuIsOpenedSelector);
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);

  const [hoveredTableView, setHoveredTableView] = useState(0);

  const handleChangeTableView = () => {
    setHoveredTableView(0);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    draggable: false,
    swipe: true,
    swipeToSlide: true,
    focusOnSelect: false,
    adaptiveHeight: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,
          focusOnSelect: true,
          adaptiveHeight: true,
          arrows: true,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          focusOnSelect: true,
          adaptiveHeight: true,
          arrows: true,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        },
      },
      {
        breakpoint: 800,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          focusOnSelect: true,
          adaptiveHeight: true,
          arrows: true,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        },
      },
      {
        breakpoint: 599,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          draggable: true,
          swipeToSlide: true,
          focusOnSelect: true,
          adaptiveHeight: false,
        },
      },
    ],
  };

  const handleClickProductStock = useCallback(async (variant: ProductVariantClass) => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_inventory_request',
        user_type: getUserType(user.email),
        branch_id: branch?.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      }
    });
    const status = get(variant, 'inventory.status', {});
    dispatch(setClickedProductRequestNearbyHubsAndBranch(variant));
    dispatch(setOpenPopupInquireNow(true));
    if (status.inStock) {
      setEmailType(NearbyBranchEmailType.HOME_BRANCH_WITH_INVENTORY_IN_STOCK);
    } else if (status.waitingOnStock) {
      setEmailType(NearbyBranchEmailType.HOME_BRANCH_WAITING_ON_STOCK);
    } else if (status.specialOrderString) {
      setEmailType(NearbyBranchEmailType.HOME_BRANCH_WITH_SPECIAL_ORDER_STRING);
    }
  }, [branch, dispatch, erpCustomerAddressCode, isRelated, selectedAddressId, setEmailType, user]);

  const onClickProductStock = async () => {
    let entryPoint = 'Alternative Products Inventory Status';
    if (isRelated) {
      entryPoint = 'Related Products Inventory Status';
    }
    await createUserActionLog(entryPoint, 'Inventory Request');
  }

  const renderProductStock = (variant: ProductVariantClass) => {
    const status = get(variant, 'inventory.status', {});

    if (status.inStock || !status.specialOrderString || status.waitingOnStock) {
      return (
        <ProductStock
          className={`${status.waitingOnStock ? 'waiting-on-stock' : ''}`}
          onClick={async () => {
            await onClickProductStock();
            handleClickProductStock(variant);
          }}
        >
          {canSeeInventory ? status.status : status.origin}
        </ProductStock>
      );
    }
    return (
      <SpecialOrder className="special-order" onClick={async () => {
        await onClickProductStock();
        handleClickProductStock(variant);
      }}>
        {canSeeInventory ? status.status : status.origin}
      </SpecialOrder>
    );
  };

  const renderProductCard = (
    productVar: ProductVariantClass,
  ) =>
    hoveredTableView !== productVar.id || !productVar.prices.length ? (
      <CarouselProductCard
        key={productVar.code}
        categoryCode={categoryCode}
        categoryName={categoryName}
        isMobile={isMobile}
        productVar={productVar}
        renderProductStock={renderProductStock}
        setHoveredTableView={setHoveredTableView}
        handleClickProductStock={handleClickProductStock}
        productType={isRelated ? 'related' : 'alternative'}
        products={products}
      />
    ) : (
      <ProductTableHoveredCard
        key={productVar.code}
        productVar={productVar}
        categoryName={categoryName}
        renderProductStock={renderProductStock}
        handleClickProductStock={handleClickProductStock}
        handleChangeTableView={handleChangeTableView}
        listName={isRelated ? t('products_carousel.related_products') : t('products_carousel.alternative_products')}
        isFeatured={false}
        isRelated={isRelated}
        productType={productType}
      />
    );

  const renderSlider = (productArray: Array<ProductVariantClass>) =>
    productArray.length > 5 || isMobile ? (
      <SliderWrapper>
        <StyledSlider {...settings}>
          {productArray && productArray.map(productVar => renderProductCard(productVar))}
        </StyledSlider>
      </SliderWrapper>
    ) : (
      <ListWrapper>
        {productArray && productArray.map(productVar => renderProductCard(productVar))}
      </ListWrapper>
    );

  const renderComponent = () => {
    if (products.length > 0) {
      return renderSlider(products);
    }
    return <div/>;
  };

  const renderHeader = () => {
    if (products.length > 0) {
      return <CarouselHeader>{isRelated ? t('products_carousel.related_products') : t('products_carousel.alternative_products')}</CarouselHeader>;
    }

    return <div/>;
  };

  return !isOpened ? (
    <>
      {(products?.length > 0) ? (
        <div style={{width: '100%'}}>
          {renderHeader()}
          {renderComponent()}
        </div>
      ) : null}
    </>
  ) : <div/>;
};

export default AssociationProductSlider;
