import styled from 'styled-components/macro';

const SalesManContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const ImgWrapper = styled.div`
  width: 50px;
  height: 50px;
  max-width: 50px;
  max-height: 50px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSalesManName = styled.div`
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
  max-width: 46px;
`;

const StyledImg = styled.img`
  object-fit: cover;
`;

export { SalesManContainer, ImgWrapper, StyledSalesManName, StyledImg };
