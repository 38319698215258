import ReactGA from 'react-ga';

import { IOrderItem } from 'models/interfaces/order';

export const purchaseGAEvent = (
  items: IOrderItem[],
  price: number,
  tax: number,
  currency: string,
  orderId: number,
  branch: string,
  isOrder: boolean
) => {
  items.forEach(item =>
    ReactGA.plugin.execute('ec', 'addProduct', {
      id: item.variant.code,
      name: item.variant.name,
      quantity: item.quantity,
      price: item.unitPrice / 100,
    })
  );

  ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
    id: orderId,
    affiliation: branch,
    currency,
    revenue: price / 100,
    tax: tax / 100,
  });

  if (isOrder) {
    ReactGA.event({
      category: 'Checkout',
      action: 'Submit (Order)',
      label: 'Order was submitted',
      value: Math.trunc(price / 100),
    });
  } else {
    ReactGA.event({
      category: 'Checkout',
      action: 'Submit (Quote)',
      label: 'Quote was submitted',
      value: Math.trunc(price / 100),
      dimension1: branch,
      metric1: 1,
    });
  }
};

export const orderTabClickGAEvent = () => {
  ReactGA.event({
    category: 'Orders and Quotes',
    action: 'Orders Tab Click',
    label: 'Order tab was clicked',
    metric11: 1,
  });
};

export const quoteTabClickGAEvent = () => {
  ReactGA.event({
    category: 'Orders and Quotes',
    action: 'Quotes Tab Click',
    label: 'Order tab was clicked',
    metric12: 1,
  });
};

export const goToOrderClickGAEvent = () => {
  ReactGA.event({
    category: 'Orders and Quotes',
    action: 'Go to Order button click',
    label: 'Go to Order button was clicked',
    metric14: 1,
  });
};

export const reorderClickGAEvent = () => {
  ReactGA.event({
    category: 'Orders and Quotes',
    action: 'Reorder button click',
    label: 'Go to Order button was clicked',
    metric13: 1,
  });
};

export const orderHistoryLinkGAEvent = () => {
  ReactGA.event({
    category: 'Orders and Quotes',
    action: 'Order History button click',
    label: 'Order History button was clicked',
    metric15: 1,
  });
};
