import styled, {ThemedStyledProps} from 'styled-components/macro';

const Layout = styled.div`
  width: 100%;
  font-family: Open Sans;
  border-top: 1px solid ${({ theme: { colors } }) => colors.filterBorderGrey};
  padding: 24px 0px;
  @media (max-width: 988px) {
    padding: 16px 0px;
  }
`;

const InnerLayout = styled.div`
  display: flex;
  width: 100%;
  &.inner-layout-1 {
    .more-info {
      @media (max-width: 1023px) {
        display: none;
      }
    }
    .cost-layout {
      @media (max-width: 1023px) {
        display: none;
      }
    }
  }
  &.inner-layout-2 {
    display: none;
    @media (max-width: 1023px) {
      display: block;
    }
    .info-wrapper {
      width: 100%;
      padding-left: 0;
    }
  }
`;

const Remove = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: flex-end;
  line-height: 16px;
  margin-top: 16px;
  color: ${({ theme: { colors } }) => colors.grey};

  svg {
    margin-right: 5px;
  }

  @media (max-width: 1023px) {
    margin-top: 0px;
    padding: 8px;
    border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
    border-radius: 4px;

    &:hover {
      background-color: ${({ theme: { colors } }) => colors.whiteSmoke};
    }

    svg {
      margin-right: 0;
    }

    span {
      display: none;
    }
  }
`;

const Title = styled.h1`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.textColor};
  margin: 0px 50px 0px 0px;
  @media (max-width: 988px) {
    font-size: 16px;
    line-height: 130%;
  }
`;

const Subcode = styled.p`
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.grey};

  margin: 8px 0px;
`;

const Quantity = styled.p`
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.grey};

  margin: 10px 0px;
`;

const CostLayout = styled.div`
  text-align: right;
  @media (max-width: 632px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    text-align: left;
    margin-top: 20px;
  }
`;

interface TotalCost extends ThemedStyledProps<object, HTMLParagraphElement> {
  isDelete: boolean;
}

const Cost = styled.p`
  color: ${({theme: {colors}}) => colors.mainGreen};
  font-weight: bold;
  font-size: ${(props: TotalCost) => props.isDelete ? '16px': '20px'};
  margin: 0 0 8px 0;
  text-decoration: ${(props: TotalCost) => props.isDelete ? 'line-through': ''};

  @media (max-width: 1023px) {
    margin: 0;
  }
`;

const InfoWrapper = styled.div`
  flex: 1 1 calc(100% - 260px);
  width: calc(100% - 260px);
  @media (max-width: 1023px) {
    flex: 1 1 calc(100% - 50px);
    width: calc(100% - 50px);
    padding-left: 10px;
  }
  .saving_wrapper {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    overflow: visible;
    white-space: nowrap;
    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 10px;
      bottom: 1px;
      transform-origin: right top;
      transform: translate(0, 100%) rotate(-5deg);
      width: 75px;
      height: 0;
      box-shadow: 0 0 7px 1px rgba(91, 91, 91, 1);
      z-index: 2;
    }
    &:after {
      display: block;
      content: '';
      position: absolute;
      right: 10px;
      bottom: 1px;
      transform-origin: left top;
      transform: translate(0, 100%) rotate(5deg);
      width: 75px;
      height: 0;
      box-shadow: 0 0 7px 1px rgba(91, 91, 91, 1);
      z-index: 2;
    }
    .saving_inner {
      position: relative;
      background: #eefef1;
      padding: 5px 15px;
      z-index: 3;
    }
    .saving_percent {
      font-weight: bold;
      font-size: 1.2em;
      color: ${({ theme: { colors } }) => colors.mainGreen};
    }
    .el-break {
      display: none;
    }
  }
`;

const BoxThumbs = styled.div`
  flex: 1 1 260px;
  width: 260px;
  margin-right: 28px;
  @media (max-width: 1023px) {
    flex: 1 1 50px;
    width: 50px;
  }
`;

const Image = styled.img`
  width: 260px;
  height: 260px;
  object-fit: cover;
  @media (max-width: 1023px) {
    width: 50px;
    height: 50px;
    margin-right: 0;
  }
`;

const InfoContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  @media (max-width: 632px) {
    flex-direction: column;
  }
`;

const EditQuantityWrapper = styled.div`
  height: auto;
  width: max-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;

  @media (max-width: 1023px) {
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
    margin-left: 4px;

    &:hover {
      background-color: ${({ theme: { colors } }) => colors.whiteSmoke};
    }
  }
`;

const EditQuantityText = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  margin-left: 6px;

  @media (max-width: 1023px) {
    display: none;
  }
`;

const IconWrapper = styled.div`
  @media (max-width: 1023px) {
    display: flex;
    justify-content: flex-end;
  }
`;

export {
  IconWrapper,
  EditQuantityText,
  EditQuantityWrapper,
  InfoContent,
  BoxThumbs,
  Image,
  InfoWrapper,
  Cost,
  CostLayout,
  Quantity,
  Subcode,
  Title,
  Remove,
  Layout,
  InnerLayout,
};
