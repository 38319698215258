import React from 'react';

export const HeaderLogoDesktop = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="123px"
      height="70px"
      viewBox="0 0 123 70"
      enableBackground="new 0 0 123 70"
      xmlSpace="preserve"
    >
      {' '}
      <image
        id="image0"
        width="123"
        height="70"
        x="0"
        y="0"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHsAAABGCAQAAABGKiOHAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
cwAACxMAAAsTAQCanBgAAAAHdElNRQflBBcTIiQ3wlavAAAEUklEQVR42u2b3XXiSBCFv96z79Mb
wbYjGDKwJgJwBOAI7IkANgJDBOAIjCOQHAFMBCgD5AjuPqj100i2wYexPUe6vEjdpeq6VaWqFiAj
8dWQmovfvcRfn83xc9DT7hJ62l1CT7tL6Gl3CT3tLqGn3SX0tLuEnnaX0NPuEnraXUJPu0voaXcJ
Pe0uoafdJfS0u4SedpfQ0+4SetpdQk+7S+hpdwkdpf33WbSsyQCIcJ9N6CNp/zQpgFZ/Ju2tj5r7
U8w/D+0rH7UZ08827CNpvxe73/vvbFkiLBnbPCzHzBxL+xp8isOaupIpjox7tmSlRI7v3OKAhR8f
MvCSaSAD9wA4xsCax0A/gGXEuEE3IuYH35lh/cjc/KzNT7ktZ9Z5hZFlz8pclzKOHZn5p3bVBos8
XvF1rJ3ci7MPUjGrVbukJgUNSbcvapp4U3YVbUlL7TWWBTmtJM0q8yXNZf21u2Jtxdo3tEaVGyTN
j6O9rJ1ZOdngvE57zCtQVDcI5EInadVCW3WZipKmoQvltFcMoNuA5oNi7TUP3BCVSa4ZGO9JDRjU
rLE8+6UHLPOkEvh0M5nSmuxjLcKXADyzMfflfFqSiPNuIUi5MlsAkmaiswru2iciOZMCExIzryZM
qgVTRSZhxR0jEj8RccvI2wIwJDVJGe16xDVTExsta5+4FpO4inbg0wpREL29HgJN++AmOIz2MMiH
SR59RdJhZslKeVQr6xRJcppIRYZKWh5byVN+tNTQ1zA+OEvK42uzOhR+uXIAz62jA+BXOGQypX6/
8cidz4kRqUm1ZsmEOWgE3L+1J09JScm4brYHk7XKv4VVkzSc1nwAsHDQVXJ8A2ANjAC4JAGTkRCB
d0PyOu3UXJgLc8EV23xAE+192u710HKFK4/CSCTl0ZPX5BSXmmINTqadeepNZ+RuTBiCHAPfOp/8
3X2Z2xIkuU8MSJjWjLVlZIflUjYoek0NM7alE9JaSSsw8N4HS8S/hWOPxhaIwqvksKW7n7iRJSIz
ScFIERmOdYM2Lk9Tk2Bqo5GKPdA1i5LMutWcCTMAk7FqnS/isdaVd9yeXybhVGzJGDIPxsY1q9ZM
uWRYZJdJlDEiJTN5rwkqbtzUL6ddWE0bErVKrn1bwpbzUdsKNbm2Sh4FEpOij2smqfbkoIGkTe18
l290yvOVNtUOJIx2pJj/Kt/LMmCJYy4bJqosdyx8t63DslHCU624fSMi47pc4Y5FWMJ0A2bBiTAz
OWZyLMxWjjG3pFzVBO6ZUtYSij3BpFi0DTv/aR9tzryFeRDNUNe+IX1UtH3EK0vigx1fdBB9K1Xd
25z51dbioeS7byBQ6/oa8XCEjpNebVWEIyNpbagv4jwPnhViXzLQqKz669Kk0cn63sQ7yiG8kOTv
x/KVlW6O1LE73vp348y0pWWzlssqKrcnX4L2ue/tCtVXEu7EKz/gtfVz39sVbMvm8cugoz8P9LS7
hP8BAAMcbjNvFPwAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMDQtMjNUMTk6MzQ6MzYrMDM6MDB6
UZs3AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIxLTA0LTIzVDE5OjM0OjM2KzAzOjAwCwwjiwAAAABJ
RU5ErkJggg=="
      />
    </svg>
  );
};
