import React, { useCallback, useEffect, useRef, useState } from 'react';
import Routes from 'routes';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useProductListBreadCrumbs, usePreviousPricing } from 'pages/Product/hooks';
import useSortType from 'hooks/useSortType';
import { FormGroup, Grid, FormControl, FormControlLabel } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { ShowList, ShowListActive, ShowTable, ShowTableActive } from 'assets/svg/Filter/Filter';
import {
  fetchFilteredProducts,
  resetProducts,
  removePaginationLoading,
  fetchFilters,
} from 'ducks/productVariant/actions';
import {
  selectIsLastPage,
  selectProducts,
  selectProductsLoading,
  selectPaginationLoading,
  selectFacets,
  selectRangeFilters,
  selectMultiselectFacets,
  selectProductVariantsError,
} from 'ducks/productVariant/selectors';
import ProductRowViewCards from 'pages/Product/ProductRowView/ProductRowCards';
import ProductTableViewCards from 'pages/Product/ProductTableView/ProductTableCards';
import ProductWithPaperWrapper from 'pages/Product/ProductWithPaperWrapper';
import ProductFilter from 'components/Product/ProductFilter/ProductFilter';
import SortDropdown from 'components/Product/Dropdowns/SortDropdown/SortDropdown';
import ShowDropdown from 'components/Product/Dropdowns/ShowDropdown';
import { StyledBreadCrumbsContainer } from 'components/common/BreadCrumbs/styles';
import { BreadCrumbs } from 'components/common';
import { NoProductsModal } from 'pages/Product/NoProductsModal/NoProductsModal';
import { Content } from 'components/Layout/Store/styles';
import { CategoryNotification } from 'components/Layout/Notification/CategoryNotification';
import { NoInStockModal } from 'pages/Product/NoProductsModal/NoInStockModal';
import ProductListLoading from 'pages/Product/ProductListLoading/ProductListLoading';
import {
  menuIsOpenedSelector,
  selectAddressId,
  selectErpCustomerAddressCode,
} from 'ducks/application/selectors';
import { PRICE_RANGE_FILTER } from 'assets/constants/product';
import { getBanner } from 'ducks/application/actions';
import { setInStockApplied } from 'ducks/GA/actions';
import { useDesktopFilter } from 'components/Product/ProductFilter/hooks/useDesktopFilter';
import { selectBranch } from 'ducks/branch/selectors';
import isEmpty from 'lodash';
import { usePermission } from 'hooks/usePermission';
import TagManager from 'react-gtm-module';
import { MAP_SORT_TYPE, SORT_TYPE_KEY } from 'utils/common/common';
import { AppStorage } from 'components/App/services/storage';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectUserData } from 'ducks/user/selectors';
import get from 'lodash/get';
import { ProductVariantClass } from 'models/classes/productVariantClass';
import { NearbyBranchEmailType } from 'ducks/productVariant/types';
import { NearbyBranchContext } from 'context/NearbyBranchContext';
import {
  setClickedProductRequestNearbyHubsAndBranch,
  setOpenPopupInquireNow,
} from 'ducks/product/actions';
import { IInventoryStatus } from 'models/interfaces/productVariant';
import { createUserActionLog } from 'api/UserActionLog';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { ProductFilterContext } from 'context/ProductFilterContext';
import {
  FilterSkeleton,
  ProductListSkeleton,
  ProductListMobileSkeleton,
} from './ProductListSkeletons';
import {
  LoadNextListWrapper,
  ShowListButton,
  ShowTableButton,
  ListStyleButtonsWrapper,
  ProductListWorkspace,
  ProductWorkspace,
  MobileButtonsWrapper,
  MobileButtonsRow,
  SingleDropdownWrapper,
  DropdownTitle,
  DropdownsWrapper,
  PageHeaderWrapper,
  SubcategoryTitle,
  LoadNextTitle,
  SpecialOrder,
  ProductStock,
  InStockControlLabel,
  GreenSwitch,
  LoadingIndicator,
  LoadingWrapperPagination,
  StyledLoadButton,
  ProductListItemsWrapper,
} from './styles';


interface IProductList
  extends RouteComponentProps<{ code: string; id: string }, { statusCode?: number }> { }

// @ts-ignore
const ProductList: React.FC<IProductList> = ({ match, location }: IProductList) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setEmailType, setFromBranchId } = React.useContext(NearbyBranchContext);
  const {
    pagination,
    setPagination,
    filter,
    setFilter,
    show,
    setShow,
    selectedView,
    setSelectedView,
    firstLoad,
    setFirstLoad,
    isMobile,
    addressId,
    selectedLanguage,
    inStock,
    setInStock
  } = React.useContext(ProductFilterContext);
  const { canSeeInventory } = usePermission();
  const history = useHistory();
  const products = useSelector(selectProducts);
  // const total = useSelector(selectTotal);
  const isLastPage = useSelector(selectIsLastPage);
  const loading = useSelector(selectProductsLoading);
  const paginationLoading = useSelector(selectPaginationLoading);
  const facets = useSelector(selectFacets);
  const rangeFilters = useSelector(selectRangeFilters);
  const multiselectFacets = useSelector(selectMultiselectFacets);
  const branch = useSelector(selectBranch);
  const user = useSelector(selectUserData);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const scrollTo = useRef(null);
  const errorFilterProducts = useSelector(selectProductVariantsError);
  const { mainCategoryName, categoryName } = useProductListBreadCrumbs(location.pathname);
  const { code, id } = match.params;
  const [selectedValues, setSelectedValues] = useState([]);

  const isFiredEventGoToSupCategory = useRef('');
  const {
    chosenSortType,
    sortType,
    sortProducts,
    setValueSortType,
    setValueChooseSortType,
  } = useSortType(
    {
      'order[name]': 'asc',
    },
    4
  );
  const isOpened = useSelector(menuIsOpenedSelector);

  // const colors = facets.find((facet: FacetClass) => facet.type === 'swatch');

  const {
    handleChange,
    findNecessaryCheck,
    handleRangeFilterChange,
    handleMultiselectChange,
  } = useDesktopFilter(selectedValues, setFirstLoad, setPagination, setFilter, setSelectedValues);

  const pricingForFilter = usePreviousPricing();

  useEffect(() => {
    // @ts-ignore
    if (errorFilterProducts && errorFilterProducts === 404) {
      history.replace(Routes.NOT_FOUND);
    }
  }, [dispatch, errorFilterProducts]);

  useEffect(() => {
    if (
      user &&
      branch &&
      erpCustomerAddressCode &&
      selectedAddressId &&
      isFiredEventGoToSupCategory.current !== code
    ) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'go_to_subcategory',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
      isFiredEventGoToSupCategory.current = code;
    }
    if (code) {
      setSelectedValues([]);
      setFilter({});
      setFirstLoad(true);
      setPagination({ page: 1, itemsPerPage: 12 });
    }
  }, [
    code,
    user,
    branch,
    erpCustomerAddressCode,
    selectedAddressId,
    isFiredEventGoToSupCategory.current,
    setFilter,
    setSelectedValues,
    setFirstLoad,
  ]);

  const fetchProduct = () => {
    const newPage = pagination.page + 1;
    const newPagination = {
      ...pagination,
      page: newPage,
    };

    setPagination(newPagination);
    setFirstLoad(false);
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_load_more_product',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
  };

  useEffect(() => {
    if (AppStorage.getFiltered()) {
      const filters = JSON.parse(AppStorage.getFiltered());
      setFilter(filters.filterAttributes);
      setInStock(filters.inStock);
      // eslint-disable-next-line guard-for-in
      for (const key in filters) {
        if (SORT_TYPE_KEY.includes(`${key}`)) {
          const st = {};
          st[key] = filters[key];
          setValueSortType(st);
          setValueChooseSortType(MAP_SORT_TYPE[`${key}_${filters[key]}`] as number);
        }
      }
    }
    return () => {
      isFiredEventGoToSupCategory.current = '';
    };
  }, []);

  useEffect(() => {
    if (code && branch) {
      dispatch(
        fetchFilteredProducts({
          language: selectedLanguage,
          filterAttributes: { ...filter },
          ...sortType,
          ...pagination,
          category: code || null,
          firstLoad,
          addressId,
          inStock
        })
      );
    }
  }, [
    dispatch,
    branch,
    sortType,
    code,
    pagination,
    filter,
    firstLoad,
    selectedLanguage,
    addressId,
    inStock,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetProducts());
      dispatch(removePaginationLoading());
    };
  }, [dispatch]);

  useEffect(() => {
    if (code && branch) {
      dispatch(fetchFilters({ category: code, language: selectedLanguage }));
      dispatch(getBanner(code, branch.id));
    }
  }, [dispatch, code, branch]);

  const handleClickProductStock = useCallback(async (variant: ProductVariantClass) => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_inventory_request',
        user_type: getUserType(user?.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      }
    });
    if (variant.newInventory?.onHand > 0 && !variant.inventory.isStocked) {
      setEmailType(NearbyBranchEmailType.HUB_BRANCH_WITH_INVENTORY_AVAILABLE);
      setFromBranchId(variant.newInventory.branchId);
    } else {
      const status = get(variant, 'inventory.status', {} as IInventoryStatus);
      if (status.inStock) {
        setEmailType(NearbyBranchEmailType.HOME_BRANCH_WITH_INVENTORY_IN_STOCK);
      } else if (status.waitingOnStock) {
        setEmailType(NearbyBranchEmailType.HOME_BRANCH_WAITING_ON_STOCK);
      } else if (status.specialOrderString) {
        setEmailType(NearbyBranchEmailType.HOME_BRANCH_WITH_SPECIAL_ORDER_STRING);
      }
      setFromBranchId(branch?.id);
    }
    dispatch(setClickedProductRequestNearbyHubsAndBranch(variant));
    dispatch(setOpenPopupInquireNow(true));
  }, [user, branch, erpCustomerAddressCode, selectedAddressId, dispatch, setEmailType, setFromBranchId]);

  const onClickProductStock = async (variant: ProductVariantClass) => {
    await createUserActionLog('Catalog Page Inventory Status', 'Inventory Request');
    handleClickProductStock(variant);
  }

  const renderProductStock = (variant: ProductVariantClass) => {
    const status = get(variant, 'inventory.status', {} as IInventoryStatus);
    if (status.waitingOnStock) {
      return (
        <ProductStock className="waiting-on-stock" onClick={() => onClickProductStock(variant)}>
          {canSeeInventory ? status.status : status.origin}
        </ProductStock>
      );
    }

    if (status.inStock || !status.specialOrderString) {
      return (
        <ProductStock onClick={() => onClickProductStock(variant)}>
          {canSeeInventory ? status.status : status.origin}
        </ProductStock>
      );
    }

    return (
      <SpecialOrder className="special-order" onClick={() => onClickProductStock(variant)}>
        {canSeeInventory ? status.status : status.origin}
      </SpecialOrder>
    );
  };

  const handleInStockSwitch = () => {
    dispatch(setInStockApplied());
    setPagination({ ...pagination, page: 1 });
    setFirstLoad(true);
    setInStock(!inStock);
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_in_stock_filter',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
  };

  const renderFilter = isLoading => {
    if (isLoading && !isMobile) return <FilterSkeleton />;
    return (
      <ProductFilter
        setSelectedValues={setSelectedValues}
        selectedValues={selectedValues}
        show={show}
        setShow={setShow}
        setFilter={setFilter}
        isMobile={isMobile}
        productsAmount={products.length}
        rangeFilters={rangeFilters}
        multiselectFacets={multiselectFacets}
        setInStock={setInStock}
        scrollEl={scrollTo}
        handleChange={handleChange}
        findNecessaryCheck={findNecessaryCheck}
        handleMultiselectFilterChange={handleMultiselectChange}
        handleRangeFilterChange={handleRangeFilterChange}
        categoryCode={code}
      />
    );
  };

  const closeNoProductModal = () => {
    setFilter({});
    setSelectedValues([]);
    if (isEmpty(filter)) {
      history.push(`${Routes.CATEGORIES}/${id}`);
    }
  };
  const closeNoInStockModal = () => {
    setInStock(false);
  };

  const isNoProducts: boolean = Boolean(!loading && !products.length);

  const ProductSkeleton = () =>
    !isMobile ? <ProductListSkeleton /> : <ProductListMobileSkeleton />;

  const ProductListItems = () =>
    !isMobile && selectedView === 'list' ? (
      <ProductRowViewCards
        products={products}
        categoryName={categoryName}
        code={code}
        mainCategoryName={mainCategoryName}
        id={id}
        renderProductStock={renderProductStock}
        handleClickProductStock={handleClickProductStock}
      />
    ) : (
      <ProductTableViewCards
        products={products}
        categoryName={categoryName}
        code={code}
        mainCategoryName={mainCategoryName}
        id={id}
        renderProductStock={renderProductStock}
        filterRange={filter[PRICE_RANGE_FILTER] || ''}
        pricingForFilter={pricingForFilter || ''}
        handleClickProductStock={handleClickProductStock}
      />
    );

  const handleEventTrackingChangeView = (event: string) => {
    if (event !== selectedView) {
      setSelectedView(event);
      TagManager.dataLayer({
        dataLayer: {
          event: 'change_view',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
    }
  };

  return (
    <>
      <CategoryNotification />
      {!isOpened && (
        <Content>
          <StyledBreadCrumbsContainer>
            {!products.length && <Skeleton height={40} />}
            {products.length > 0 ? (
              <BreadCrumbs
                currentPageName={categoryName}
                previousPages={[
                  {
                    pagename: mainCategoryName,
                    url: `${Routes.CATEGORIES}/${id}`,
                  },
                ]}
              />
            ) : null}
          </StyledBreadCrumbsContainer>
          {/* <ColorFilter
            colors={colors}
            handleChange={handleChange}
            findNecessaryCheck={findNecessaryCheck}
          /> */}
          <Grid container item direction="column" md={12} xs={12} ref={scrollTo}>
            <NoInStockModal open={isNoProducts && inStock} handleClose={closeNoInStockModal} />
            <NoProductsModal open={isNoProducts && !inStock} handleClose={closeNoProductModal} />
            {!isMobile ? (
              <Grid container item direction="row" md={12} xs={12}>
                <PageHeaderWrapper>
                  <div>
                    <SubcategoryTitle>{categoryName}</SubcategoryTitle>
                    {/* {total > 0 && (
                      <p style={{ fontSize: '22px' }}>
                        ({total} {t('categories.products')})
                      </p>
                    )} */}
                  </div>
                  <DropdownsWrapper>
                    <FormControl>
                      <FormGroup>
                        <FormControlLabel
                          control={<GreenSwitch checked={inStock} onClick={handleInStockSwitch} />}
                          label={
                            <InStockControlLabel>
                              {t('products_and_search.in_stock').toString()}
                            </InStockControlLabel>
                          }
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                    <SingleDropdownWrapper>
                      <DropdownTitle>{t('products_and_search.sort_by').toString()}</DropdownTitle>
                      <SortDropdown
                        sortProducts={sortProducts}
                        setPagination={setPagination}
                        pagination={pagination}
                        setFirstLoad={setFirstLoad}
                        defaultSortByValue={4}
                        chosenSortType={chosenSortType}
                      />
                    </SingleDropdownWrapper>
                    <SingleDropdownWrapper>
                      <DropdownTitle>{t('products_and_search.show').toString()}</DropdownTitle>
                      <ShowDropdown setPagination={setPagination} setFirstLoad={setFirstLoad} itemsPerPage={pagination.itemsPerPage} />
                    </SingleDropdownWrapper>
                    <ListStyleButtonsWrapper>
                      <ShowTableButton
                        aria-label="table"
                        onClick={() => handleEventTrackingChangeView('table')}
                      >
                        {selectedView === 'table' ? <ShowTableActive /> : <ShowTable />}
                      </ShowTableButton>
                      <ShowListButton
                        aria-label="list"
                        onClick={() => handleEventTrackingChangeView('list')}
                      >
                        {selectedView === 'list' ? <ShowListActive /> : <ShowList />}
                      </ShowListButton>
                    </ListStyleButtonsWrapper>
                  </DropdownsWrapper>
                </PageHeaderWrapper>
              </Grid>
            ) : (
              <>
                <SubcategoryTitle>{categoryName}</SubcategoryTitle>
                {/* {total > 0 && (
                  <p style={{ fontSize: '22px' }}>
                    ({total} {t('categories.products')})
                  </p>
                )} */}
              </>
            )}
            <Grid container item direction="row" md={12} xs={12}>
              <ProductWorkspace>
                {isMobile ? (
                  <MobileButtonsWrapper>
                    <MobileButtonsRow>
                      {renderFilter(!products.length)}
                      {!show ? (
                        <SortDropdown
                          sortProducts={sortProducts}
                          setPagination={setPagination}
                          pagination={pagination}
                          setFirstLoad={setFirstLoad}
                          defaultSortByValue={4}
                          chosenSortType={chosenSortType}
                        />
                      ) : null}
                    </MobileButtonsRow>
                    <MobileButtonsRow>
                      <FormControl>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <GreenSwitch checked={inStock} onClick={handleInStockSwitch} />
                            }
                            label={
                              <InStockControlLabel>
                                {t('products_and_search.in_stock').toString()}
                              </InStockControlLabel>
                            }
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </MobileButtonsRow>
                  </MobileButtonsWrapper>
                ) : (
                  renderFilter(!products.length)
                )}

                {!products.length ? (
                  <div>
                    <ProductSkeleton />
                  </div>
                ) : (
                  <ProductListWorkspace>
                    <ProductWithPaperWrapper
                      facets={[...facets, ...multiselectFacets]}
                      rangeFilters={rangeFilters}
                      selectedValues={selectedValues}
                      setSelectedValues={setSelectedValues}
                      setFilter={setFilter}
                      setPagination={setPagination}
                      setFirstLoad={setFirstLoad}
                    >
                      <ProductListItemsWrapper>
                        <ProductListItems />
                        {loading && <ProductListLoading />}
                      </ProductListItemsWrapper>

                      {!isLastPage ? (
                        <>
                          {!paginationLoading ? (
                            <LoadNextListWrapper>
                              <StyledLoadButton onClick={fetchProduct}>
                                <LoadNextTitle>
                                  {loading && <ProductListLoading />}
                                  {t('products_and_search.load_next', {
                                    paginationItemsPerPage: pagination.itemsPerPage,
                                  }).toString()}
                                </LoadNextTitle>
                              </StyledLoadButton>
                            </LoadNextListWrapper>
                          ) : (
                            <LoadingWrapperPagination isMobile={isMobile}>
                              <LoadingIndicator width={isMobile ? 40 : 80} isMobile={isMobile} />
                            </LoadingWrapperPagination>
                          )}
                        </>
                      ) : null}
                    </ProductWithPaperWrapper>
                  </ProductListWorkspace>
                )}
              </ProductWorkspace>
            </Grid>
          </Grid>
        </Content>
      )}
    </>
  );
};

export default ProductList;
