/* eslint-disable indent */
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { useFormik } from 'formik';
import { BlackPhone, GreenPhone } from 'assets/svg/Popups/Popups';
import { selectUserData } from 'ducks/user/selectors';
import { SalesRepresentativeClass } from 'models/classes/salesRepresentative';
import defaultAvatar from 'assets/images/default-product.png';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from 'utils/common/common';
import { selectAddressId, selectErpCustomerAddressCode } from 'ducks/application/selectors';
import TagManager from 'react-gtm-module';
import * as Yup from 'yup';
import {
  HeaderText,
  OrderBlockInfo,
  PaddingText,
  StyledDate,
} from 'pages/General/Profile/components/OrdersAndQuotes/ContentItem/components/CardContentHeader/styles';
import { selectInquireNowOrderHistory } from 'ducks/order/selectors';
import get from 'lodash/get';
import {
  sendInquireNowOrderEmail,
  sendInquireNowQuoteEmail,
  setEmailInquireNowOrderHistorySent,
} from 'ducks/order/actions';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectBranch } from 'ducks/branch/selectors';
import upperFirst from 'lodash/upperFirst';
import {
  SingleCheckboxWrapper,
  PhoneButton,
  StyledButton,
  ButtonsWrapper,
  BackToProducts,
  MessageTextField,
  PhoneNumberWrapper,
  PopUpText,
  PopUpText2,
  ProductWrapper,
  PopUpHeader,
  StyledWrapper,
  PhoneNumber,
} from '../styles';

interface IProductPricingEmail {
  disabled: boolean;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  handleClose: Function;
  salesRepresentative: SalesRepresentativeClass;
  error: boolean;
}

const InquireNowOrderHistoryEmail: React.FC<IProductPricingEmail> = ({
  error,
  disabled,
  setDisabled,
  salesRepresentative,
  handleClose,
}: IProductPricingEmail) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const { defaultAddress } = useSelector(selectUserData);
  const selectedAddressId = useSelector(selectAddressId);
  const inquireNowOrderHistory = useSelector(selectInquireNowOrderHistory);
  const user = useSelector(selectUserData);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const branch = useSelector(selectBranch);

  const ValidationSchema = Yup.object().shape({
    message: Yup.string().required(t('order_quote_inquire_now.message_required')),
  });

  const renderBackToProducts = useMemo(
    () => (
      <BackToProducts type="button" onClick={() => handleClose()}>
        <span>
          {inquireNowOrderHistory.type === 'order'
            ? t('profile.back_to_order_history')
            : t('profile.back_to_quote_history')}
        </span>
      </BackToProducts>
    ),
    [inquireNowOrderHistory.type, t, handleClose]
  );

  const initialValues = {
    message: '',
    addressId: selectedAddressId || defaultAddress.id || undefined,
  };

  const { values, handleChange, handleBlur, handleSubmit, errors } = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    onSubmit: ({ addressId }) => {
      if (disabled) return;
      setDisabled(true);
      if (inquireNowOrderHistory.type === 'quote') {
        clearGtmEcommerce();
        TagManager.dataLayer({
          dataLayer: {
            event: 'send_quote_inquiry',
            user_type: getUserType(user.email),
            branch_id: branch.id,
            customer_code: user?.customer?.erpCustomerCode,
            address_code: erpCustomerAddressCode,
            address_id: addressId,
          },
        });
        sendInquireNowQuoteEmail({
          addressId,
          content: values.message,
          orderType: inquireNowOrderHistory.orderType.toLowerCase(),
          orderNumber: inquireNowOrderHistory.orderNumber,
        }).then(() => {
          dispatch(setEmailInquireNowOrderHistorySent(true));
          setDisabled(false);
        });
      } else {
        clearGtmEcommerce();
        TagManager.dataLayer({
          dataLayer: {
            event: 'send_order_inquiry',
            user_type: getUserType(user.email),
            branch_id: branch.id,
            customer_code: user?.customer?.erpCustomerCode,
            address_code: erpCustomerAddressCode,
            address_id: addressId,
          },
        });
        sendInquireNowOrderEmail({
          addressId,
          content: values.message,
          orderType: inquireNowOrderHistory.orderType.toLowerCase(),
          orderNumber: inquireNowOrderHistory.orderNumber,
        }).then(() => {
          dispatch(setEmailInquireNowOrderHistorySent(true));
          setDisabled(false);
        });
      }
    },
    enableReinitialize: false,
  });

  const renderTextFields = () => (
    <form onSubmit={handleSubmit}>
      <MessageTextField
        multiline
        rows={5}
        label={t('request_pricing_popup.message')}
        variant="outlined"
        name="message"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.message}
        fullWidth
        error={!!errors.message}
        helperText={errors.message}
      />
      <ButtonsWrapper>
        {renderBackToProducts}
        <StyledButton type="submit" disabled={error || disabled}>
          <span>{t('request_pricing_popup.button')}</span>
        </StyledButton>
      </ButtonsWrapper>
    </form>
  );

  return (
    <StyledWrapper>
      <PopUpHeader>
        {t('profile.order_history_inquire_now_title', {
          type:
            inquireNowOrderHistory.type === 'order'
              ? upperFirst(t('orders_and_quotes.sm_order'))
              : upperFirst(t('orders_and_quotes.sm_quote')),
        })}
      </PopUpHeader>
      <ProductWrapper>
        <img alt="product avatar" src={defaultAvatar} />
        <div style={{ flex: 1 }}>
          {get(inquireNowOrderHistory, 'orderNumber', null) && (
            <OrderBlockInfo style={{ width: '100%' }}>
              <HeaderText>{inquireNowOrderHistory.type === 'order' ? upperFirst(t('orders_and_quotes.sm_order')) : upperFirst(t('orders_and_quotes.sm_quote'))}#:</HeaderText>
              &nbsp;
              {inquireNowOrderHistory.orderNumber}
            </OrderBlockInfo>
          )}
          {inquireNowOrderHistory.dateRequired ? (
            <PaddingText style={{ width: '100%' }}>
              <HeaderText>
                {inquireNowOrderHistory.orderType.toLowerCase() === 'pickup' ||
                inquireNowOrderHistory.orderType.toLowerCase() === 'will call' ? (
                  <>
                    <span className="pc">{t('orders_and_quotes.est_pickup_date')}</span>
                    <span className="sp">{t('orders_and_quotes.est')}</span>
                  </>
                ) : (
                  <>
                    <span className="pc">{t('orders_and_quotes.est_ship_date')}</span>
                    <span className="sp">{t('orders_and_quotes.est')}</span>
                  </>
                )}
                : &nbsp;
              </HeaderText>
              <StyledDate>{inquireNowOrderHistory.dateRequired}</StyledDate>
            </PaddingText>
          ) : null}
        </div>
      </ProductWrapper>
      <PopUpText>
        {t('request_pricing_popup.call_your_representative_order_quote_inquire', {
          representativeName: salesRepresentative.name,
        })}
      </PopUpText>
      <PhoneNumberWrapper>
        {!isMobile
          ? salesRepresentative.workPhone && (
              <PhoneNumber>
                <BlackPhone /> {formatPhoneNumber(salesRepresentative.workPhone)}
              </PhoneNumber>
            )
          : salesRepresentative.workPhone && (
              <PhoneButton>
                <PhoneNumber>
                  <GreenPhone /> {formatPhoneNumber(salesRepresentative.workPhone)}
                </PhoneNumber>
              </PhoneButton>
            )}
      </PhoneNumberWrapper>
      <SingleCheckboxWrapper>
        <PopUpText2>{t('nearby_branch.send_email_to_inquiry')}</PopUpText2>
      </SingleCheckboxWrapper>
      {renderTextFields()}
    </StyledWrapper>
  );
};

export default InquireNowOrderHistoryEmail;
