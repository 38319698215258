import React, { Dispatch, SetStateAction, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { useFormik } from 'formik';
import TagManager from 'react-gtm-module';
import { BlackPhone2, GreenPhone } from 'assets/svg/Popups/Popups';
import { sendRequestPricing } from 'ducks/productVariant/actions';
import { selectUserData } from 'ducks/user/selectors';
import { SalesRepresentativeClass } from 'models/classes/salesRepresentative';
import { ProductVariantClass } from 'models/classes/productVariantClass';

import defaultAvatar from 'assets/images/default-product.png';

import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from 'utils/common/common';
import { requestPricingEmailSentGAEvent } from 'GA/ProductVariantGA/ProductVariantGA';
import get from 'lodash/get';
import { AssociationVariantClass } from 'models/classes/associationClass';
import { selectBranch } from 'ducks/branch/selectors';
import { getAddressString, getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectAddressId } from 'ducks/application/selectors';
import { Autocomplete } from '@material-ui/lab';
import { IAddress } from 'models/interfaces/user';
import { StyledTextField } from 'pages/General/Profile/PersonalSettings/styles';
import axios from 'utils/axios';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import {setClickedProductRequestPricing} from 'ducks/product/actions';
import {
  SingleCheckboxWrapper,
  PhoneButton,
  StyledButton,
  ButtonsWrapper,
  BackToProducts,
  MessageTextField,
  QuantityTextField,
  PhoneNumberWrapper,
  PopUpText,
  PopUpText2,
  ProductWrapper,
  PopUpHeader,
  StyledWrapper,
  StyledFormControl,
  PhoneNumber,
  AutocompleteWrapper,
} from '../styles';

interface IProductPricingEmail {
  setRequestPricing: Dispatch<SetStateAction<boolean>>;
  product: ProductVariantClass | AssociationVariantClass;
  disabled: boolean;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  setEmailSuccess: Dispatch<SetStateAction<boolean>>;
  setEmailLoading: Dispatch<SetStateAction<boolean>>;
  salesRepresentative: SalesRepresentativeClass;
  loading: boolean;
  error: boolean;
  setCurrentSaleRepresentative: Dispatch<SetStateAction<any>>;
}

const ProductPricingEmail: React.FC<IProductPricingEmail> = ({
  setRequestPricing,
  product,
  setEmailSuccess,
  setEmailLoading,
  loading,
  error,
  disabled,
  setDisabled,
  salesRepresentative,
  setCurrentSaleRepresentative,
}: IProductPricingEmail) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const { defaultAddress, addresses, email, customer } = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const currentAddressId = useRef(selectedAddressId);

  const handleClose = () => {
    setRequestPricing(false);
    dispatch(setClickedProductRequestPricing(null));
  };

  const handleChangeAddress = (address: number) => {
    if (address !== currentAddressId.current) {
      axios
        .get('/api/auth/sales-representative', {
          headers: {
            'x-address-id': address.toString(),
          },
        })
        .then(res => {
          setCurrentSaleRepresentative(res.data.data);
        });
      currentAddressId.current = address;
    }
  };

  const renderBackToProducts = () => (
    <BackToProducts type="button" onClick={handleClose}>
      <p>{t('request_pricing_popup.back_to_products')}</p>
    </BackToProducts>
  );

  const initialValues = {
    quantity: '',
    message: '',
    addressId: selectedAddressId || defaultAddress.id || undefined,
  };

  const initialErrors = {};
  const initialTouched = {};

  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setValues } = useFormik({
    initialValues,
    onSubmit: ({ message, quantity, addressId }) => {
      if (loading || disabled) return;
      setDisabled(true);
      requestPricingEmailSentGAEvent(product.name);
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'send_price_request',
          user_type: getUserType(email),
          branch_id: branch.id,
          customer_code: customer?.erpCustomerCode,
          address_code: defaultAddress?.erpCustomerAddressCode,
        },
      });
      dispatch(
        sendRequestPricing(
          message,
          quantity,
          addressId,
          product.code.toString(),
          product.erpSku,
          setEmailSuccess,
          setEmailLoading
        )
      );
    },
    initialErrors,
    initialTouched,
    enableReinitialize: false,
  });

  const renderTextFields = () => (
    <form onSubmit={handleSubmit}>
      <QuantityTextField
        label={t('request_pricing_popup.quantity')}
        variant="outlined"
        name="quantity"
        error={!!(errors.quantity && touched.quantity)}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={
          errors.quantity && touched.quantity && t(`request_pricing_popup.${errors.quantity}`)
        }
        value={values.quantity}
        fullWidth
      />
      <StyledFormControl variant="outlined">
        <AutocompleteWrapper>
          <Autocomplete
            disablePortal
            disableClearable
            options={addresses}
            renderInput={params => (
              <StyledTextField fullWidth variant="outlined" autoFocus label="Address" {...params} />
            )}
            getOptionLabel={(address: IAddress) => getAddressString(address)}
            renderOption={(address: IAddress, props) => {
              const label = getAddressString(address);
              return (
                <li
                  {...props}
                  className={`${address.id === values.addressId ? 'item-selected' : ''}`}
                >
                  {label}
                  {address.id === values.addressId && <span className="ic-arrow">✓</span>}
                </li>
              );
            }}
            onChange={(event, value) => {
              setValues({ ...values, addressId: value.id });
              handleChangeAddress(value.id);
            }}
            value={addresses.find(adr => adr.id === values.addressId)}
          />
        </AutocompleteWrapper>
      </StyledFormControl>

      <MessageTextField
        multiline
        rows={5}
        label={t('request_pricing_popup.message')}
        variant="outlined"
        name="message"
        error={!!(errors.message && touched.message)}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={
          errors.message && touched.message && t(`request_pricing_popup.${errors.message}`)
        }
        value={values.message}
        fullWidth
      />
      <ButtonsWrapper>
        {renderBackToProducts()}
        <StyledButton type="submit" disabled={loading || error || disabled}>
          <p>{t('request_pricing_popup.button')}</p>
        </StyledButton>
      </ButtonsWrapper>
    </form>
  );

  const representativeName = salesRepresentative.name;
  const productImages = get(product, 'images', []);

  return (
    <StyledWrapper>
      <PopUpHeader>{t('request_pricing_popup.request_pricing')}</PopUpHeader>
      <ProductWrapper>
        <img
          alt="product avatar"
          src={
            productImages.length > 0 && productImages[0].url ? productImages[0].url : defaultAvatar
          }
        />
        <p>{product.name}</p>
      </ProductWrapper>
      <PopUpText>
        {t('request_pricing_popup.call_your_representative', { representativeName })}
      </PopUpText>
      <PhoneNumberWrapper>
        {!isMobile
          ? salesRepresentative.workPhone && (
            <>
              <BlackPhone2 />
              <PhoneNumber>{formatPhoneNumber(salesRepresentative.workPhone)}</PhoneNumber>
            </>
          )
          : salesRepresentative.workPhone && (
            <PhoneButton>
              <GreenPhone />
              <PhoneNumber>{formatPhoneNumber(salesRepresentative.workPhone)}</PhoneNumber>
            </PhoneButton>
          )}
      </PhoneNumberWrapper>
      <SingleCheckboxWrapper>
        <PopUpText2>{t('request_pricing_popup.email_your_rep')}</PopUpText2>
      </SingleCheckboxWrapper>
      {renderTextFields()}
    </StyledWrapper>
  );
};

export default ProductPricingEmail;
