import styled from 'styled-components/macro';
import { AppBar, IconButton, Toolbar, Grid } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import LanguageDropdown from 'components/Layout/Header/Dropdowns/LanguageDropdown/LanguageDropdown';

const StyledAppBar = styled(AppBar)`
  background: ${({ theme: { colors } }) => colors.mainGreen};
  box-shadow: none;
  z-index: 0;
`;

const StyledToolbar = styled(({ fullWidthGreen, ...rest }) => <Toolbar {...rest} />)`
  background: #007a33;
  min-height: inherit;
  padding: 0;
  height: 40px;
  border-radius: 0;
`;

const StyledIconButton = styled(IconButton)`
  background: ${({ theme: { colors } }) => colors.secondaryGreen};
  border-radius: 0;
  padding-left: 16px;
  padding-right: 16px;
  &.btn-menu {
    width: 56px;
    height: 100%;
    background: #1d9d49;
    padding: 0;
    &:hover {
      background: #208b45;
    }
    svg {
      width: 32px;
      height: 32px;
    }
  }
`;

const StyledLanguageDropdown = styled(LanguageDropdown)`
  margin-left: auto;
  background: #f4f4f4;
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  border-radius: 4px;
  @media (max-width: 1023px) {
    margin-left: 0;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-left: auto;
  .MuiButtonBase-root {
    height: 40px;
    padding: 8px 10px;
  }
  .item-phone {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
    }
  }
  .item-profile {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
    }
  }
  .item-order-history {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
    }
    svg {
      width: 18px;
      height: 17px;
    }
  }
  .truck-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    .ic-cart {
      display: flex;
      flex-wrap: nowrap;
    }
    .product-number {
      min-width: 12px;
      height: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      text-align: center;
      font-family: Open Sans, serif;
      font-weight: 700;
      font-size: 6px;
      color: #fff;
      background: #f5001d;
      padding: 0 2px;
      margin-left: -6px;
      margin-top: -6px;
    }
    .total {
      text-align: left;
      margin-left: 2px;
      .total-label {
        color: #FFF;
        font-family: Open Sans;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0.08px;
        margin-bottom: 4px;
      }
      .total-number {
        color: #FFF;
        font-family: Open Sans;
        font-size: 8px;
        font-style: normal;
        font-weight: 700;
        line-height: 8px;
        letter-spacing: 0.08px;
      }
    }
  }
`;

const StyledLink = styled(RouterLink)`
  text-decoration: none;
  height: 32px;
`;

const LogoutButton = styled.button`
  outline: none;
  background: ${({ theme: { colors } }) => colors.mainGreen};
  border: none;
`;

const WhiteCloseIcon = styled(CloseIcon)`
  color: ${({ theme: { colors } }) => colors.white};
`;

const SpecialLink = styled(RouterLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: #b9ba16;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 155%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  padding: 0 16px;
  transition: all 0.4s ease;
  @media only screen and (max-width: 599px) {
    padding: 0 12px;
  }
  &:hover {
    background: #aaab16;
  }
`;

const GridPriceWrapper = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px 5px;
  .prices-switch {
    order: 2;
    margin-left: 0;
    margin-right: 0;
    .MuiFormControlLabel-label {
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin-left: 8px;
    }
  }
  .pricing-dropdown-wrapper {
    order: 1;
    .MuiSelect-select {
      padding-left: 0;
    }
  }
`;

export {
  StyledAppBar,
  StyledLink,
  StyledToolbar,
  StyledIconButton,
  StyledDiv,
  StyledLanguageDropdown,
  LogoutButton,
  WhiteCloseIcon,
  SpecialLink,
  GridPriceWrapper,
};
