import React, { useState } from 'react';
import styled from 'styled-components';
import { Fade } from '@material-ui/core';
import ShippingAddressPopup from 'components/Popups/ShippingAddressPopup';
import { UserClass } from 'models/classes/userClass';
import ChoosePricingPopUp from 'components/Popups/ChoosePricingPopUp';
import { IAddress } from 'models/interfaces/user';

const PopUpBackground = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { colors } }) => colors.blackOpacity(0.3)};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1500;
`;

const PopUpWrapper = styled.div`
  width: 484px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 50px 60px;
  background: ${({ theme: { colors } }) => colors.white};
  border-radius: 6px;
  position: relative;
  max-height: 100%;
  overflow-y: auto;

  @media (max-width: 1024px) {
    width: 300px;
    padding: 16px 20px 20px 20px;
  }
`;

interface IMonthlyPopupsProps {
  user: UserClass;
}

const MonthlyPopups: React.FC<IMonthlyPopupsProps> = ({ user }: IMonthlyPopupsProps) => {
  const [popupsClose, setPopupsClose] = useState(false);
  const [shippingOpen, setShippingOpen] = useState(user.addresses.length > 1);
  const [address, setAddress] = useState<IAddress>(user.defaultAddress || user.addresses[0]);

  const renderShippingAddressPopUp = () => {
    if (shippingOpen) {
      return (
        <ShippingAddressPopup
          address={address}
          setAddress={setAddress}
          setShippingOpen={setShippingOpen}
          user={user}
        />
      );
    }

    return null;
  };

  const renderPricingPopUp = () => {
    return (
      <ChoosePricingPopUp
        address={address}
        close={popupsClose}
        setClose={setPopupsClose}
        user={user}
      />
    );
  };

  return !popupsClose && user ? (
    <PopUpBackground>
      <Fade in timeout={500}>
        <PopUpWrapper>
          {shippingOpen ? renderShippingAddressPopUp() : renderPricingPopUp()}
        </PopUpWrapper>
      </Fade>
    </PopUpBackground>
  ) : null;
};

export default MonthlyPopups;
