import styled from 'styled-components/macro';
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const CategoriesWrapper = styled.div`
  width: calc(100% + 32px);
  margin: 32px -16px 0 0;

  @media (max-width: 1023px) {
    display: flex;
    justify-content: space-between;
    margin: 32px -8px 0 0;
  }
`;
const SkeletonWrapper = styled.div`
  margin: 32px 0 0;
  display: flex;
  width: 100%;
`;

const CategoryCard = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 190px;

  @media (max-width: 1023px) {
    height: 150px;
  }
`;

const CategoryInfo = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 0 0 16px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width: 1023px) {
    padding: 0 10px 7px 8px;
  }
`;

const Header = styled.h1`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  letter-spacing: 0.1px;
  margin-top: 24px;
  margin-bottom: 0;
`;

const ShopInfo = styled(Typography)`
  font-weight: 400;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.medium};
  line-height: 24px;
  font-family: Open Sans;
  letter-spacing: 0.16px;
  max-width: 688px;

  @media (max-width: 1023px) {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
  }
`;

const CategoryHeader = styled(Typography)`
  color: ${({ theme: { colors } }) => colors.white};
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.16px;

  @media (max-width: 1023px) {
    font-size: 14px;
    letter-spacing: -0.24px;
  }
`;

const ProductNumber = styled(Typography)`
  color: ${({ theme: { colors } }) => colors.white};
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.16px;
  opacity: 0.8;

  @media (max-width: 1023px) {
    font-size: 12px;
  }
`;

const CardWrapper = styled(({ imgUrl, children, ...rest }) => <div {...rest}>{children}</div>)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 4px;
  background-image: ${props => `url(${props.imgUrl})`};
  background-size: cover;

  &:hover {
    cursor: pointer;
  }
`;

const StyledLink = styled(Link)`
  width: 100%;
  height: 100%;
  text-decoration: none;
`;

export {
  CategoriesWrapper,
  CategoryCard,
  CategoryInfo,
  Header,
  ShopInfo,
  CategoryHeader,
  ProductNumber,
  CardWrapper,
  StyledLink,
  SkeletonWrapper,
};
