import React from 'react';

export const ShippingIcon = ({ ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" { ...rest }>
      <path d="M7.65085 15.2249C7.73909 15.5443 8.06955 15.7317 8.38896 15.6434C8.70836 15.5552 8.89576 15.2247 8.80752 14.9053L7.65085 15.2249ZM6.07692 7.27458L6.65525 7.1148L6.07692 7.27458ZM4.7037 5.92011L4.54329 6.49827L4.7037 5.92011ZM3.16041 4.86926C2.8411 4.78067 2.51043 4.9677 2.42184 5.28701C2.33325 5.60632 2.52028 5.93699 2.83959 6.02558L3.16041 4.86926ZM20.1509 15.5804C20.4716 15.4971 20.6641 15.1695 20.5807 14.8488C20.4974 14.5281 20.1698 14.3357 19.8491 14.419L20.1509 15.5804ZM10.3424 17.519C10.6753 18.7241 9.94282 19.9871 8.66162 20.32L8.96345 21.4815C10.8632 20.9878 12.0184 19.0796 11.499 17.1995L10.3424 17.519ZM8.66162 20.32C7.37414 20.6546 6.07443 19.9089 5.73942 18.6963L4.58275 19.0158C5.10008 20.8884 7.06998 21.9735 8.96345 21.4815L8.66162 20.32ZM5.73942 18.6963C5.40651 17.4912 6.13896 16.2282 7.42017 15.8953L7.11833 14.7339C5.2186 15.2276 4.06334 17.1357 4.58275 19.0158L5.73942 18.6963ZM7.42017 15.8953C8.70764 15.5607 10.0074 16.3064 10.3424 17.519L11.499 17.1995C10.9817 15.3269 9.0118 14.2418 7.11833 14.7339L7.42017 15.8953ZM8.80752 14.9053L6.65525 7.1148L5.49858 7.43435L7.65085 15.2249L8.80752 14.9053ZM4.86411 5.34195L3.16041 4.86926L2.83959 6.02558L4.54329 6.49827L4.86411 5.34195ZM6.65525 7.1148C6.41661 6.251 5.73187 5.5827 4.86411 5.34195L4.54329 6.49827C5.01802 6.62998 5.37583 6.99001 5.49858 7.43435L6.65525 7.1148ZM11.0716 17.94L20.1509 15.5804L19.8491 14.419L10.7698 16.7785L11.0716 17.94Z" fill="currentColor"/>
      <path d="M9.56443 8.73049C9.07942 6.97492 8.83692 6.09714 9.24856 5.40562C9.66021 4.71409 10.5652 4.47889 12.3753 4.00849L14.2952 3.50955C16.1052 3.03915 17.0103 2.80394 17.7233 3.20319C18.4363 3.60244 18.6788 4.48023 19.1638 6.2358L19.6782 8.09786C20.1632 9.85343 20.4057 10.7312 19.9941 11.4227C19.5824 12.1143 18.6774 12.3495 16.8673 12.8199L14.9474 13.3188C13.1374 13.7892 12.2323 14.0244 11.5194 13.6252C10.8064 13.2259 10.5639 12.3481 10.0789 10.5926L9.56443 8.73049Z" stroke="currentColor" strokeWidth="1.2" fill="none"/>
    </svg>
  );
};
