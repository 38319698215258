import React, {useEffect, useMemo, useState} from 'react';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { TextField, FormControlLabel } from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { CheckedGreyCheckbox, GreyCheckbox } from 'assets/svg/SignIn';
import { MediumButton } from 'components/common/Buttons/MediumButton';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {forgetMe, login, loginByMagicLink, reloadStateLoginLink, rememberMe} from 'ducks/auth/actions';
import {
  authErrorsSelector,
  getRememberMe,
  selectLoginByMagicLinkStatus
} from 'ducks/auth/selectors';
import {selectCreateUserProfileResult, selectUserLoading} from 'ducks/user/selectors';
import upperFirst from 'lodash/upperFirst';
import { LANDING_IMAGES_URL } from './constants';
import { ModalRegister } from './ModalRegister/ModalRegister';
import {PasswordFieldWrapper, StyledPasswordField} from '../Auth/SignIn/styles';
import PopupRegisterSuccess from './ModalRegister/PopupRegisterSuccess';
import { LoginWithMagicLinkModal } from './LoginWithMagicLinkModal/LoginWithMagicLinkModal';
import {useQuery} from '../../utils/common';

const FirstScreenMainContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  margin-top: -105px;
  padding-top: 190px;
  padding-bottom: 101px;
  background-image: url(${LANDING_IMAGES_URL}/lp_bg_5760_3600.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -120px;

  @media (max-width: 1650px) {
    background-position-y: -40px;
  }

  @media (max-width: 1600px) {
    background-position-y: 0;
  }

  @media (max-width: 1600px) {
    background-position-y: 0;
  }

  @media (max-width: 1440px) {
    background-image: url(${LANDING_IMAGES_URL}/lp_bg_1440-900.jpg);
  }

  @media (max-width: 1280px) {
    padding-top: 165px;
    padding-bottom: 94px;
  }

  @media (max-width: 1024px) {
    padding-bottom: 80px;
  }

  @media (max-width: 900px) {
    background-image: url(${LANDING_IMAGES_URL}/lp_bg_966_900.jpg);
  }

  @media (max-width: 767px) {
    padding-top: 115px;
    padding-bottom: 40px;
    margin-top: -91px;
  }
`;

const ShadowTopBackground = styled.div`
  height: 94px;
  width: 100%;
  position: absolute;
  top: 0;
  background: linear-gradient(
    180deg,
    ${({ theme: { colors } }) => colors.white} 0%,
    ${({ theme: { colors } }) => colors.invisible} 100%
  );

  @media (max-width: 1280px) {
    height: 80px;
  }

  @media (max-width: 810px) {
    display: none;
  }
`;

const FirstScreenBottomBlur = styled.div`
  height: 94px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    180deg,
    ${({ theme: { colors } }) => colors.white} 0%,
    ${({ theme: { colors } }) => colors.invisible} 100%
  );
  transform: rotate(-180deg);
  z-index: 1;

  @media (max-width: 1024px) {
    height: 80px;
  }

  @media (max-width: 900px) {
    height: 60px;
  }

  @media (max-width: 763px) {
    height: 40px;
  }
`;

const FirstScreenContentContainer = styled.div`
  max-width: 1237px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  padding: 0 15px;
  margin: 0 auto;

  @media (min-width: 1281px) {
    margin-top: 58px;
  }

  @media (max-width: 1280px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  @media (max-width: 1024px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const FirstScreenTextContainer = styled.div`
  max-width: 464px;

  @media (max-width: 1280px) {
    max-width: 880px;
  }
`;

const FirstScreenFormContainer = styled.div`
  width: 700px;
  height: auto;
  background: ${({ theme: { colors } }) => colors.blackOpacity('0.8')};
  border-radius: 10px;
  flex-shrink: 0;
  padding: 60px 40px 50px;

  @media (max-width: 767px) {
    max-width: 540px;
    width: 100%;
    padding: 24px 10px;
  }

  @media (max-width: 480px) {
    padding: 24px 10px;
    margin: 0 10px;
  }
`;

const FirstScreenPrimaryText = styled.h1`
  font-family: Open Sans, serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin-bottom: 24px;
  color: ${({ theme: { colors } }) => colors.textColor};

  @media (max-width: 1280px) {
    text-align: center;
    margin-top: 0;
  }

  @media (max-width: 767px) {
    max-width: 540px;
    font-size: 29px;
    line-height: 38px;
    margin-bottom: 32px;
  }

  @media (max-width: 560px) {
    font-size: 26px;
  }

  @media (max-width: 500px) {
    line-height: 31px;
  }
`;

const useStyles = makeStyles({
  frSize: {
    '@media (min-width: 1280px)': {
      fontSize: '37px !important',
    },
    '@media (max-width: 480px)': {
      fontSize: '24px !important',
    },
  },
  enSize: {
    '@media (max-width: 480px)': {
      fontSize: '26px !important',
    },
  },
  frTitle: {
    '@media (max-width: 480px)': {
      fontSize: '16px !important',
    },
  },
});

const FirstScreenFormInputsContainer = styled.div`
  .title-logo {
    font-family: 'Open Sans';
    color: #fff;
    font-weight: 800;
    font-size: 32px;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      font-size: 24px;
      text-align: center;
      margin-bottom: 24px;
    }
  }
  .box-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -38px;
    margin-left: -38px;
    @media (max-width: 767px) {
      margin: 0;
    }
  }
  .box-col {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 38px;
    padding-left: 38px;
    &:first-child {
      border-right: 1px solid rgba(197, 198, 200, 0.38);
      @media (max-width: 767px) {
        border-right: 0;
        border-bottom: 1px solid rgba(197, 198, 200, 0.38);
        padding-bottom: 24px;
        margin-bottom: 24px;
      }
    }
    @media (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0;
    }
  }
  .title-col {
    min-height: 68.63px;
    font-family: 'Open Sans';
    color: #fff;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 23px;
    @media (max-width: 767px) {
      font-size: 18px;
      min-height: 0;
      margin-bottom: 12px;
    }
  }
  [class*=styles__PasswordFieldWrapper] {
    .MuiFormControl-root {
      margin-top: 0;
      margin-bottom: 24px;
    }
    .MuiInputLabel-outlined {
      z-index: 1;
      transform: translate(14px, 20px) scale(1);
      pointer-events: none;
    }
    .MuiInputLabel-outlined.MuiInputLabel-marginDense {
      transform: translate(14px, 12px) scale(1);
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
    .MuiFormLabel-root {
      color: #545353;
      margin-top: 2px;
      font-family: Open Sans;
      line-height: 16px;
      letter-spacing: 0.01em;
    }
    .MuiFormLabel-root.Mui-focused,
    .MuiFormLabel-root.MuiFormLabel-filled {
      left: -5px;
      color: #FFFFFF;
      padding: 4px;
      font-size: 14px;
      background: #333333;
      border-radius: 4px;
      margin-top: -4px;
    }
    .MuiInputBase-root {
      height: 47px;
      background-color: rgba(255, 255, 255);
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.23);
      legend {
        width: 10px;
      }
    }
    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: #f44336;
    }
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border: 1px solid #FFFFFF;
    }
    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: #E74659;
    }
    .MuiOutlinedInput-root:hover.Mui-focused fieldset {
      border-color: #fff;
    }
    .MuiOutlinedInput-root.Mui-error:hover.Mui-focused fieldset {
      border-color: #E74659;
    }
    .MuiFormHelperText-root {
      font-size: 12px;
      margin-top: 0px;
      font-family: Open Sans;
      line-height: 16px;
      margin-left: 0px;
      letter-spacing: 0.01em;
      color: #E74659;
    }
  }
`;

const StyledTextField = withStyles({
  root: {
    fontFamily: 'Open Sans',
    width: '100%',
    marginBottom: '24px',
    borderRadius: '4px',
    '@media (max-width: 767px)': {
      width: '100% !important',
    },
    '& .MuiInputBase-root': {
      fontFamily: 'Open Sans',
      height: '47px',
      backgroundColor: 'rgba(255, 255, 255)',
      '&:hover': {
        borderColor: '#FFFFFF',
      },
      '& fieldset legend': {
        fontSize: '4px',
      },
      '& .MuiOutlinedInput-input': {
        padding: '14px',
      },
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#E74659',
        },
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
        borderColor: '#FFFFFF',
      },
      fieldset: {
        borderColor: '#FFFFFF',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FFFFFF',
      },
    },
    '& .MuiFormLabel-root': {
      color: '#545353',
      fontFamily: 'Open Sans',
      lineHeight: '16px',
      letterSpacing: '0.01em',

      marginTop: '-4px',
      '&.Mui-error': {
        '&.Mui-focused': {
          color: '#E74659',
        },
      },
      '&.Mui-focused, &.MuiFormLabel-filled': {
        background: '#333333',
        color: '#FFFFFF',
        borderRadius: '4px',
        padding: '4px',
        left: '-5px',
        fontSize: '14px',
      },
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Open Sans',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.01em',
      marginTop: '0px',
      marginLeft: '0px',
      '&.Mui-error': {
        color: '#E74659',
      },
    },
    '@media (max-width: 720px)': {
      width: 'auto',
      marginBottom: '11px',
    },
  },
})(TextField);

const FirstScreenSubmitButton = withStyles({
  root: {
    height: '47px',
    width: '100%',
    borderRadius: '4px',
    marginTop: '24px',
    backgroundColor: '#337d58',
    '&:hover': {
      backgroundColor: '#37875F',
      '&.Mui-disabled': {
        backgroundColor: '#009650',
        opacity: '50%',
      },
    },
    '@media (max-width: 767px)': {
      marginTop: '40px',
    },
    '&.Mui-disabled': {
      backgroundColor: '#009650',
      opacity: '50%',
    },
  },
})(MediumButton);

const RegisterButton = withStyles({
  root: {
    height: '47px',
    width: '100%',
    borderRadius: '4px',
    color: '#00853e',
    border: '1px solid #00853e',
    backgroundColor: '#efefef',
    transition: 'all 0.4s ease',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#00853e',
      '&.Mui-disabled': {
        backgroundColor: '#00853e',
        opacity: '50%',
      },
    },
    '&.Mui-disabled': {
      backgroundColor: '#00853e',
      opacity: '50%',
    },
    '& svg': {
      marginLeft: '8px',
    },
  },
})(MediumButton);

const Or = styled.div`
  position: relative;
  text-align: center;
  margin: 8px 0;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    background: #48576B;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #fff;
    background: #2D2827;
    padding: 0 4px;
  }
`;

const LoginWithoutPasswordButton = withStyles({
  root: {
    height: '47px',
    width: '100%',
    borderRadius: '4px',
    color: '#fff',
    border: '1px solid #fff',
    backgroundColor: 'transparent',
    transition: 'all 0.4s ease',
    '&:hover': {
      color: '#00853e',
      borderColor: '#00853e',
      backgroundColor: 'transparent',
      '&.Mui-disabled': {
        color: '#00853e',
        borderColor: '#00853e',
        opacity: '50%',
      },
    },
    '&.Mui-disabled': {
      borderColor: '#00853e',
      opacity: '50%',
    },
    '& svg': {
      marginLeft: '8px',
    },
  },
})(MediumButton);

const StyledForgotLinkWrapper = styled.div`
  text-align: right;
  margin-top: -10px;
  margin-bottom: 12px;
`;

const StyledForgotLink = styled(RouterLink)`
  color: #00853e;
  cursor: pointer;
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-decoration: none;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiTypography-body1 {
    font-family: Open Sans, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
  }
  .MuiFormControlLabel-label {
    color: #dadee4;
  }
`;

const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#00853e',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

interface TextProps {
  header: string;
  passwordRequired: string;
  isEnglish: boolean;
  emailLabel: string;
  emailRequired: string;
  emailInvalid: string;
}

const LandingFirst: React.FC<TextProps> = ({
  header,
  passwordRequired,
  isEnglish,
  emailLabel,
  emailRequired,
  emailInvalid,
}: TextProps, ref) => {
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const { t } = useTranslation();
  const query = useQuery();
  const classes = useStyles();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(emailInvalid)
      .required(emailRequired),
    password: Yup.string().required(passwordRequired),
  });

  const dispatch = useDispatch();
  const [openModalRegisterModal, setOpenModalRegisterModal] = useState(false);
  const error = useSelector(authErrorsSelector);
  const isUserSuccess = useSelector(selectCreateUserProfileResult);
  const createUserLoading = useSelector(selectUserLoading);
  const rememberMeState = useSelector(getRememberMe);
  const loginByMagicLinkStatus = useSelector(selectLoginByMagicLinkStatus);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [openPopupCreateUserSuccess, setOpenPopupCreateUserSuccess] = useState(false);
  const [checkedG, setCheckRememberMe] = useState(rememberMeState);
  const [showLoginWithMagicLinkModal, setShowLoginWithMagicLinkModal] = useState(false);
  const [isLoginWithMagicLink, setIsLoginWithMagicLink] = useState<boolean>(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckRememberMe(event.target.checked);
    if (event.target.checked) {
      dispatch(rememberMe());
    } else {
      dispatch(forgetMe());
    }
  };

  const handleSubmit = values => {
    executeRecaptcha().then(token => {
      dispatch(login(values.email, values.password, token, true));
    });
  };

  useEffect(() => {
    if (isUserSuccess && !createUserLoading) {
      setOpenPopupCreateUserSuccess(true);
    }
  }, [isUserSuccess, createUserLoading]);

  useEffect(() => {
    dispatch(rememberMe());
  }, [dispatch])

  useEffect(() => {
    const token = query.get('token');
    if (!isLoginWithMagicLink && token) {
      setIsLoginWithMagicLink(true);
      dispatch(loginByMagicLink(token));
    }
  }, [dispatch, isLoginWithMagicLink, query]);

  useEffect(() => {
    if (loginByMagicLinkStatus) {
      setShowLoginWithMagicLinkModal(true);
    }
  }, [loginByMagicLinkStatus]);

  useEffect(() => {
    if (searchParams.get('register') && searchParams.get('register') === 'true') {
      setOpenModalRegisterModal(true);
    }
  }, [searchParams]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  return (
    <>
      <FirstScreenMainContainer>
        <ShadowTopBackground />
        <FirstScreenContentContainer>
          <FirstScreenTextContainer>
            <FirstScreenPrimaryText className={isEnglish ? classes.enSize : classes.frSize}>
              {header}
            </FirstScreenPrimaryText>
          </FirstScreenTextContainer>

          <FirstScreenFormContainer id="prospect-customer-registration">
            <form onSubmit={formik.handleSubmit}>
              <FirstScreenFormInputsContainer>
                <div className="title-logo">TaigaNow</div>

                <div className="box-row">
                  <div className="box-col">
                    <div className="title-col">{t('sign-in.title')}</div>

                    <StyledTextField
                      variant="outlined"
                      label={emailLabel}
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      error={!!(formik.errors.email && formik.touched.email)}
                      helperText={
                        formik.errors.email && formik.touched.email ? formik.errors.email : ''
                      }
                      data-test-id="email-input"
                    />

                    <PasswordFieldWrapper>
                      <StyledPasswordField
                        fullWidth
                        variant="outlined"
                        error={!!((formik.errors?.password && formik.touched.password) || error)}
                        label={t('sign-in.password')}
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          error
                            ? error?.errors?.password
                            : formik.errors.password && formik.touched.password && t(`${formik.errors.password}`)
                        }
                        margin="dense"
                        data-test-id="password-input"
                      />
                    </PasswordFieldWrapper>

                    <StyledForgotLinkWrapper data-test-id="forgot-password">
                      <StyledForgotLink to="/reset-password">{t('sign-in.forgot_password')}</StyledForgotLink>
                    </StyledForgotLinkWrapper>

                    <StyledFormControlLabel
                      control={
                        <GreenCheckbox
                          icon={<GreyCheckbox />}
                          checkedIcon={<CheckedGreyCheckbox />}
                          onChange={handleCheckboxChange}
                          defaultChecked
                          name="checkedG"
                          value={checkedG}
                        />
                      }
                      label={t('sign-in.remember_me')}
                    />

                    <FirstScreenSubmitButton
                      type="submit"
                      disabled={!(formik.values.email && formik.values.password)}
                      data-test-id="sign-in"
                    >
                      {t('sign-in.button')}
                    </FirstScreenSubmitButton>

                    <Or className="or"><span>{t('sign-in.or')}</span></Or>

                    <LoginWithoutPasswordButton onClick={() => setShowLoginWithMagicLinkModal(true)} data-test-id="login-without-password">
                      {t('sign-in.login_without_password')}
                    </LoginWithoutPasswordButton>
                  </div>

                  <div className="box-col">
                    <div className="title-col">{t('sign-in.register_account').toString()}</div>

                    <RegisterButton onClick={() => setOpenModalRegisterModal(true)} data-test-id="register" ref={ref}>
                      {t('sign-in.register').toString()}
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.4301 18.8211C14.2401 18.8211 14.0501 18.7511 13.9001 18.6011C13.6101 18.3111 13.6101 17.8311 13.9001 17.5411L19.4401 12.0011L13.9001 6.46109C13.6101 6.17109 13.6101 5.69109 13.9001 5.40109C14.1901 5.11109 14.6701 5.11109 14.9601 5.40109L21.0301 11.4711C21.3201 11.7611 21.3201 12.2411 21.0301 12.5311L14.9601 18.6011C14.8101 18.7511 14.6201 18.8211 14.4301 18.8211Z"
                          fill="currentColor"
                        />
                        <path
                          d="M20.33 12.75H3.5C3.09 12.75 2.75 12.41 2.75 12C2.75 11.59 3.09 11.25 3.5 11.25H20.33C20.74 11.25 21.08 11.59 21.08 12C21.08 12.41 20.74 12.75 20.33 12.75Z"
                          fill="currentColor"
                        />
                      </svg>
                    </RegisterButton>
                  </div>
                </div>
              </FirstScreenFormInputsContainer>
            </form>
          </FirstScreenFormContainer>
        </FirstScreenContentContainer>
        <FirstScreenBottomBlur />
      </FirstScreenMainContainer>

      {openModalRegisterModal && (
        <ModalRegister
          open={openModalRegisterModal}
          formheader={t('app.register_now')}
          buttonLabel={t('sign-in.register')}
          firstnameLabel={t('profile.first_name')}
          lastnameLabel={t('profile.last_name')}
          emailLabel={t('profile.email')}
          companyLabel={upperFirst(t('profile.company'))}
          salesLabel={t('salesman.title')}
          branchLabel={upperFirst(t('profile.taiga_branch'))}
          firstnameValidateMin={t('contact_us.name_min_length')}
          firstnameValidateMax={t('contact_us.phone_max_length')}
          firstnameRequired={t('profile.first_name_is_required')}
          lastnameValidateMin={t('contact_us.name_min_length')}
          lastnameValidateMax={t('contact_us.phone_max_length')}
          lastnameRequired={t('profile.last_name_is_required')}
          companyValidateMin={t('contact_us.name_min_length')}
          companyValidateMax={t('profile.max_length_150')}
          companyRequired={t('profile.company_required')}
          salesValidateMin={t('contact_us.name_min_length')}
          salesValidateMax={t('contact_us.phone_max_length')}
          salesRequired={t('profile.sale_representative_required')}
          emailInvalid={t('contact_us.invalid_mail')}
          emailValidateMax={t('contact_us.name_max_length')}
          emailRequired={t('contact_us.email_required')}
          branchRequired={t('profile.taiga_branch_required')}
          emailIsUsed={t('profile.email_in_used')}
          callbackOnClose={() => {
            history.replace('/');
            setOpenModalRegisterModal(false);
          }}
          closeModalRegister={() => {
            history.replace('/');
            setOpenModalRegisterModal(false);
          }}
        />
      )}

      {showLoginWithMagicLinkModal && (
        <LoginWithMagicLinkModal
          open={showLoginWithMagicLinkModal}
          callbackOnClose={() => setShowLoginWithMagicLinkModal(false)}
          setOpenModalRegisterModal={() => {
            setShowLoginWithMagicLinkModal(false);
            setOpenModalRegisterModal(true);
            dispatch(reloadStateLoginLink());
          }}
        />
      )}

      <PopupRegisterSuccess
        openPopup={openPopupCreateUserSuccess}
        handleClose={() => setOpenPopupCreateUserSuccess(false)}
        popupheader={t('profile.thank_for_registering')}
        popuptext={t('profile.register_success_message')}
        popupclose={t('checkout.close_modal')}
      />
    </>
  );
};

export default React.forwardRef(LandingFirst);
