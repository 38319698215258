import {Reducer} from 'redux';
import {CartActionTypes, CartState} from 'ducks/cart/types';
import {getDefaultInputValues} from 'utils/cart/cart';

const initialState: CartState = {
  data: null,
  loading: false,
  error: null,
  cartUpdated: false,
  cartItemQtyUpdated: true,
  itemsInputValues: [],
  promocodeError: null,
  cartItemsNoPrice: [],
  addToCartError: false,
  addToTruckSuccess: false,
  addedToTruckItem: null,
  storeCartFirstLoadSuccess: false,
};

const cartReducer: Reducer<CartState> = (state = initialState, {type, payload}) => {
  switch (type) {
    case CartActionTypes.ADD_PRODUCT: {
      return {...state, loading: true, error: null, addToTruckSuccess: false};
    }
    case CartActionTypes.ADD_PRODUCT_SUCCESS: {
      return {...state, loading: false, addToCartError: false, addToTruckSuccess: true};
    }
    case CartActionTypes.ADD_PRODUCT_ERROR: {
      return {...state, loading: false, addToCartError: payload, addToTruckSuccess: false};
    }
    case CartActionTypes.SET_ADD_TO_TRUCK_STATUS: {
      return { ...state, addToTruckSuccess: payload.status };
    }
    case CartActionTypes.CHANGE_PRODUCT_QTY: {
      const allItems = [...state.data.data?.items];
      const item = state.data.data?.items?.find(itm => itm.id === payload.cartItemId);
      if (item) item.quantity = payload.qty;

      return {
        ...state,
        cartItemQtyUpdated: false,
        loading: true,
        error: null,
        data: {
          ...state.data,
          data: {
            ...state.data.data,
            items: allItems.map(cur => (cur.id === item.id ? item : cur)),
          },
        },
      };
    }
    case CartActionTypes.CHANGE_PRODUCT_QTY_SUCCESS: {
      return {...state, cartItemQtyUpdated: true, loading: false, error: null};
    }
    case CartActionTypes.CHANGE_PRODUCT_QTY_ERROR: {
      return {...state, loading: false, error: payload.error, cartItemQtyUpdated: true};
    }
    case CartActionTypes.DELETE_PRODUCT: {
      const allItems = [...state.data.data?.items];
      const newData = {
        ...state.data,
        cart: {
          ...state.data.cart,
          items: allItems.filter(e => e.id !== payload.cartItemId),
        },
      };

      return {
        ...state,
        error: null,
        itemsInputValues: getDefaultInputValues(newData.cart),
        data: newData,
        loading: false,
      };
    }
    case CartActionTypes.DELETE_PRODUCT_ERROR: {
      return {
        ...state,
        error: null,
        loading: false,
      };
    }
    case CartActionTypes.STORE_CART_AFTER_UPDATE: {
      return {...state, loading: false, cartUpdated: true, data: payload.data};
    }
    case CartActionTypes.STORE_CART: {
      return {...state, loading: false, data: payload.data, cartUpdated: false};
    }
    case CartActionTypes.GET_CART_SUMMARY: {
      return {...state, loading: true, error: null, promocodeError: null, cartUpdated: false};
    }
    case CartActionTypes.GET_CART_SUMMARY_ERROR: {
      return {...state, loading: false, error: payload};
    }
    case CartActionTypes.STORE_FIRST_LOAD_SUMMARY: {
      return {
        ...state,
        loading: false,
        data: payload.data,
        itemsInputValues: payload.inputValues,
        storeCartFirstLoadSuccess: true
      };
    }
    case CartActionTypes.SET_STORE_FIRST_LOAD_SUMMARY_STATUS: {
      return {...state, storeCartFirstLoadSuccess: payload.status};
    }
    case CartActionTypes.UPDATE_ITEM_INPUT_VALUES: {
      const {cartItemId, newTopValue, newBottomValue, newItemPrice} = payload;
      const newValues = state.itemsInputValues.map(values =>
        values.itemId === cartItemId
          ? {
            ...values,
            topValue: newTopValue || values.topValue,
            bottomValue: Number(newBottomValue?.toFixed(2) || values?.bottomValue?.toFixed(2)),
            itemPrice: newItemPrice || values.itemPrice,
          }
          : values,
      );

      return {...state, itemsInputValues: newValues, loading: true};
    }
    case CartActionTypes.SET_QTY_UPDATE_FALSE: {
      return {...state, cartItemQtyUpdated: false, error: null};
    }
    case CartActionTypes.RESET_CART_ERRORS: {
      return {...state, data: {...state.data, errors: []}};
    }
    case CartActionTypes.ADD_PROMOCODE: {
      return {...state, loading: true, error: null, promocodeError: null};
    }
    case CartActionTypes.DELETE_PROMOCODE: {
      return {...state, loading: true, error: null, promocodeError: null};
    }
    case CartActionTypes.PROMOCODE_ERROR: {
      return {...state, loading: false, promocodeError: payload.error};
    }

    case CartActionTypes.START_LOADER: {
      return {...state, loading: true};
    }
    case CartActionTypes.STORE_CART_ITEMS_NO_PRICE: {
      return {...state, cartItemsNoPrice: payload.data};
    }
    case CartActionTypes.SET_ADD_TO_TRUCK_ITEM: {
      return {...state, addedToTruckItem: {
        item: payload.item,
        quantity: payload.quantity
      }};
    }

    default:
      return state;
  }
};

export default cartReducer;
