import React, {Dispatch, SetStateAction} from 'react';
import { UserClass } from 'models/classes/userClass';
import { IAddress } from 'models/interfaces/user';
import { usePopupScrollEffect } from 'hooks/usePopupScrollEffect';
import { PROVINCE_CODES_WITH_NAME } from 'assets/constants/application';

import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from 'utils/common/common';
import {
  AddressInfo,
  AddressName,
  AddressProperty,
  PopUpContentWrapper,
  StyledTextField,
  StepBox,
  StepBoxContainer,
  StepBoxLight,
  StyledButton,
} from 'components/Popups/StartSettings/styles';
import {useSelector} from 'react-redux';
import {selectUserAddresses} from 'ducks/user/selectors';
import {Autocomplete} from '@material-ui/lab';
import { KeyboardArrowDown } from '@material-ui/icons';
import {getAddressString} from '../../../utils/profile/profileUtilsHelpers';

interface IShippingAddressProps {
  user: UserClass;
  address: IAddress;
  setAddress: Dispatch<SetStateAction<IAddress>>;
  nextStep: () => void;
}

const ShippingAddressStep: React.FC<IShippingAddressProps> = ({
  nextStep,
  address,
  setAddress,
}: IShippingAddressProps) => {
  const { t } = useTranslation();
  const userAddresses = useSelector(selectUserAddresses);
  usePopupScrollEffect();

  const handleChange = (chosenAddress) => {
    const selectAddress = userAddresses.find(adr => {
      return adr.erpPostCode === chosenAddress.erpPostCode;
    });
    setAddress(selectAddress);
  };

  const handleSelectClick = () => {
    nextStep();
  };

  const renderAddresses = () =>
    [1, 2, 3].map(number =>
      address[`erpAddress${number}`] ? (
        <AddressProperty key={`erpAddress${number}`}>{address[`erpAddress${number}`]}</AddressProperty>
      ) : null
    );

  return (
    <PopUpContentWrapper>
      {
        userAddresses && (
          <Autocomplete
            disablePortal
            disableClearable
            blurOnSelect
            options={userAddresses}
            getOptionLabel={(adr: IAddress) => getAddressString(adr)}
            popupIcon={<KeyboardArrowDown />}
            openOnFocus
            onChange={(event, adr: IAddress) => {
              if (adr?.name || adr?.erpPostCode) {
                handleChange(adr);
              }
            }}
            renderInput={(params) => (
              <StyledTextField
                fullWidth
                variant="outlined"
                label={t('start_settings_popup.step1_title')}
                {...params}
              />
            )}
            defaultValue={address}
          />
        )
      }
      <AddressInfo>
        <AddressName>{address.name ? address.name : address.postcode}</AddressName>
        {address.salesRepresentative ? (
          <AddressProperty>{address.salesRepresentative.fullName}</AddressProperty>
        ) : null}
        {renderAddresses()}
        <AddressProperty>
          {address.erpCity}, {PROVINCE_CODES_WITH_NAME[address.provinceCode]}
        </AddressProperty>
        <AddressProperty>{address.postcode}</AddressProperty>
        <AddressProperty>{PROVINCE_CODES_WITH_NAME[address.countryCode]}</AddressProperty>
        {address.salesRepresentative && address.salesRepresentative.workPhone ? (
          <AddressProperty>
            {formatPhoneNumber(address.salesRepresentative.workPhone)}
          </AddressProperty>
        ) : null}
      </AddressInfo>
      <div>
        <StyledButton onClick={handleSelectClick}>
          <p>{t('shipping_address_popup.button')}</p>
        </StyledButton>
        <StepBoxContainer>
          <StepBox />
          <StepBoxLight />
          <StepBoxLight />
        </StepBoxContainer>
      </div>
    </PopUpContentWrapper>
  );
};

export default ShippingAddressStep;
