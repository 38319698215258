import React from 'react';
import styled from 'styled-components/macro';

const SingleItem = styled.div`
  display: flex;
  padding: 14px 0;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.productBorderGrey};
  align-items: center;
  width: 100%;
  &:last-child {
    border-bottom: none;
  }
`;

interface IItemImage {
  imageUrl?: string;
}

const ItemImage = styled(({children, ...rest}) => (
  <a {...rest}>{children}</a>
))`
  min-height: 60px;
  min-width: 60px;
  background: url(${(p: IItemImage) => p.imageUrl}) center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 14px;
  cursor: ${props => (props.canReorder ? 'pointer' : 'default')};
  @media (max-width: 1023px) {
    align-self: flex-start;
  }
`;

const SingleItemInfoWrapper = styled(({children, ...rest}) => (
  <div {...rest}>{children}</div>
))`
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: ${props => (props.canReorder ? 'pointer' : 'default')};

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

const SingleItemInfoElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: max-content;
  min-width: 120px;
  padding-left: 8px;
  padding-right: 8px;

  @media (max-width: 1023px) {
    width: max-content;
    flex-direction: row;
  }

  .saving_wrapper {
    font-size: 11px;
    .saving_percent {
      font-weight: bold;
      font-size: 1.2em;
      color: ${({ theme: { colors } }) => colors.mainGreen};
    }
  }
`;

const MobileInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
`;

const ItemNameBlock = styled.div`
  width: 40%;
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }
`;

const ItemMainName = styled.div`
  font-size: 16px;
  font-weight: 400;
  @media (max-width: 1023px) {
    font-size: 14px;
  }
`;

const ItemSubName = styled.div`
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.grey};
`;

const ItemInfoElementHeader = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

interface IItemInfoElementValue {
  bold?: boolean;
}

const ItemInfoElementValue = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: ${(p: IItemInfoElementValue) => (p.bold ? '700' : '400')};
  font-size: 16px;
  line-height: 130%;
  margin: 15px 0 8px;
  p {
    &:nth-child(1) {
      font-size: 12px;
      text-decoration: line-through;
      margin: 0 0 5px;
    }
    &:nth-child(2) {
      font-size: 24px;
      margin: 0;
    }
  }
`;

const ItemWithCheckboxWrapper = styled.div`
  display: flex;
  align-items: start;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.productBorderGrey};
  &:last-child {
    border-bottom: none;
  }
`;

const CheckboxWrapper = styled.div`
  margin-top: 27px;
  margin-right: 10px;
  .MuiButtonBase-root {
    padding: 9px 0;
  }
  @media (max-width: 1023px) {
    width: 20px;
  }
`;

const RequestPricingWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1023px) {
    justify-content: flex-start;
  }
`;

const RequestPricingButton = styled.button`
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  background: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  height: max-content;
  cursor: pointer;

  &:hover {
    border: 1px solid ${({ theme: { colors } }) => colors.mainGreen};
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const RequestPricing = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  text-align: center;
  margin: 0;
  line-height: 12px;
  padding: 9px 12px;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const NotAvailableWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const NotAvailableText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: ${({ theme: { colors } }) => colors.grey};
`;

export {
  SingleItem,
  ItemInfoElementValue,
  ItemInfoElementHeader,
  ItemSubName,
  ItemMainName,
  ItemNameBlock,
  SingleItemInfoElementWrapper,
  SingleItemInfoWrapper,
  ItemImage,
  ItemWithCheckboxWrapper,
  CheckboxWrapper,
  MobileInfoWrapper,
  RequestPricingWrapper,
  RequestPricingButton,
  RequestPricing,
  NotAvailableText,
  NotAvailableWrapper,
};
