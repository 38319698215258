import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useFacetRangeFilterState } from 'components/Product/ProductFilter/components/FacetRangeFilter/hooks/useFacetRangeFilterState';

import { FilterArrowDown, FilterArrowUp } from 'assets/svg/Filter/Arrow';
import { IMultiselectFilterValues, ISelectedValue } from 'models/interfaces/productVariant';
import { FacetClass } from 'models/classes/facetClass';
import ValueLabelComponent from 'components/Product/ProductFilter/components/RangeFilter/components/ValueLabelComponent/ValueLabelComponent';

import { getValueForFacetRangeFilter } from 'components/Product/ProductFilter/utils';

import {
  ButtonNameWrapper,
  SingleFilterWrapper,
  StyledButton,
  StyledSlider,
  RangeFilterContentWrapper,
} from 'components/Product/ProductFilter/styles';

interface IRangeFilterProps {
  facet: FacetClass;
  selectedValues: ISelectedValue[];
  handleMultiselectFilterChange: (
    code: string,
    name: string,
    values: IMultiselectFilterValues
  ) => void;
}

const FacetRangeFilter = ({
  facet,
  selectedValues,
  handleMultiselectFilterChange,
}: IRangeFilterProps) => {
  const selectedFacetValues = selectedValues.find(
    selectedValue => selectedValue.code === facet.property
  );
  const [open, setOpen] = useState(selectedValues.some(value => value.code === facet.property));
  const { step, minValue, maxValue, value, setValue } = useFacetRangeFilterState(
    facet,
    selectedFacetValues
  );

  useEffect(() => {
    selectedValues.some(selectedValue => {
      if (selectedValue.code === facet.property) {
        setOpen(true);
        return false;
      }

      return true;
    });
  }, [selectedValues, facet]);

  const handleOpenClick = () => {
    setOpen(!open);
  };

  const delayedFilterChange = useCallback(
    debounce((code, values) => {
      const { selectedValueName, valuesToUse } = getValueForFacetRangeFilter(facet, values);

      handleMultiselectFilterChange(code, selectedValueName, valuesToUse);
    }, 1000),
    []
  );

  const handleChange = (event: any, newValue: number | number[]) => {
    setValue(newValue as number[]);

    delayedFilterChange(facet.property, newValue as number[]);
  };

  const getValueText = (currentValue: number) => `${facet.buckets[currentValue].label}`;

  return (
    <SingleFilterWrapper>
      <StyledButton onClick={handleOpenClick}>
        <ButtonNameWrapper>{facet.label}</ButtonNameWrapper>
        {open ? <FilterArrowUp /> : <FilterArrowDown />}
      </StyledButton>
      <RangeFilterContentWrapper isOpen={open}>
        <StyledSlider
          value={value}
          onChange={handleChange}
          valueLabelDisplay={open ? 'on' : 'off'}
          aria-labelledby="range-slider"
          step={step}
          min={minValue}
          max={maxValue}
          valueLabelFormat={getValueText}
          ValueLabelComponent={ValueLabelComponent}
        />
      </RangeFilterContentWrapper>
    </SingleFilterWrapper>
  );
};

export default FacetRangeFilter;
