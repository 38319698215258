import React from 'react';
import styled from 'styled-components';
import error500 from 'assets/images/error500.png';

import { useTranslation } from 'react-i18next';

const ContentContainer = styled.div`
  font-family: Open Sans;
  text-align: center;
`;

const ErrorImage = styled.img`
  @media (max-width: 520px) {
    width: 280px;
  }
`;

const TextDiv = styled.div`
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.01em;
  margin: 35px 0 48px 0;

  @media (max-width: 520px) {
    margin: 20px 0 20px 0;
    padding: 0 10px;
    font-size: 18px;
  }
`;

const TextDivSub = styled.div`
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  @media (max-width: 520px) {
    padding: 0 10px;
    font-size: 14px;
  }
`;

const Error500: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ContentContainer>
      <ErrorImage src={error500} alt="505" />
      <TextDiv>{t('errors.500_title')}</TextDiv>
      <TextDivSub>{t('errors.refresh_page')}</TextDivSub>
    </ContentContainer>
  );
};

export default Error500;
