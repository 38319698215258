import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {ScrollWindowTop} from 'utils/cart/cart';

import {requestCartSummary} from 'ducks/cart/actions';
import {selectCartLoading} from 'ducks/cart/selectors';
import { selectAddressId, selectErpCustomerAddressCode, selectIsHidePrice } from 'ducks/application/selectors';
import {selectOrder} from 'ducks/checkout/selectors';

import Routes from 'routes';
import { OrderHistoryOutline } from 'assets/svg/Order/Order';
import {Accordion, AccordionDetails, ClickAwayListener, Typography} from '@material-ui/core';
import {SurveyStorage} from 'components/Survey/services/storage';
import TagManager from 'react-gtm-module';
import {getUserType} from 'utils/profile/profileUtilsHelpers';
import {selectUserData} from 'ducks/user/selectors';
import {selectBranch} from 'ducks/branch/selectors';
import {selectOrders, selectSubmitOrderStatus} from 'ducks/order/selectors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IOrder } from 'models/interfaces/order';
import {setOrderSubmittedStatus} from 'ducks/order/actions';
import {numberFormat} from 'utils/products/product';
import {usePermission} from 'hooks/usePermission';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import {getDefaultCategoryName} from 'utils/categories/categories';
import {selectCategories} from 'ducks/category/selectors';
import {getDefaultProductName} from 'utils/products/productUtilsHelpers';
import {AccordionContent} from '../styles';
import {
  ModalWindow,
  ModalCardContent,
  ModalCard,
  ModalTitle,
  ModalSubTitle,
  StyledLink,
  ModalFromButton,
  Container,
  StyledButtonLink
} from './styles';
import CartItemBody from '../CartOrders/OrderItemCart/CartItemBody/CartItemBody';

const OrdersModal: React.FC = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [showPopup, setShowPopup] = useState(false);

  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const submittedOrderStatus = useSelector(selectSubmitOrderStatus);
  const cartLoading = useSelector(selectCartLoading);
  const orderLoading = useSelector(selectCartLoading);
  const orderType = useSelector(selectOrder);
  const orders = useSelector(selectOrders);
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const categories = useSelector(selectCategories);
  const isHidePrice = useSelector(selectIsHidePrice);
  const isQuote = orderType === 'quote';
  const {canSeePrice, canPlaceQuote, canPlaceOrder, canSeeOrderHistory} = usePermission();
  useEffect(() => {
    if (submittedOrderStatus && !cartLoading && !orderLoading && erpCustomerAddressCode && selectedAddressId) {
      setShowPopup(true);
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: isQuote ? 'send_a_quote' :'send_an_order',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        }
      })
      // eslint-disable-next-line array-callback-return
      orders.map(orderData => {
        const handleEvent = () => {
          const items = orderData.items.map(item => {
            const {itemCategoryName, itemCategory2Name} = getDefaultCategoryName(item.cartItem.categoryId, categories);
            return {
              item_id: item.product.erpSku,
              item_name: getDefaultProductName(item.variant),
              currency: 'CAD',
              price: item.total,
              quantity: item.quantity,
              item_category2: itemCategory2Name,
              item_category: itemCategoryName,
              source_branch: orderData.branchId,
              sales_branch: branch.id,
            }
          })
          const eventData = {
            transaction_id: orderData.orderNumber,
            value: parseFloat(String(orderData.goodsTotal)),
            tax: orderData.taxTotal,
            shipping: 0,
            currency: 'CAD',
            items
          }
          clearGtmEcommerce();
          TagManager.dataLayer({
            dataLayer: {
              event: 'purchase',
              ecommerce: eventData,
              user_type: getUserType(user.email),
              branch_id: branch.id,
              customer_code: user?.customer?.erpCustomerCode,
              address_code: erpCustomerAddressCode,
              address_id: selectedAddressId,
            }
          })
        }
        handleEvent();
      })
    }
  }, [dispatch, submittedOrderStatus, erpCustomerAddressCode, selectedAddressId, cartLoading, orderLoading]);

  const closeOrderPopup = () => {
    ScrollWindowTop();
    dispatch(requestCartSummary(selectedAddressId));
    setShowPopup(false);
    dispatch(setOrderSubmittedStatus(false));
  };

  if (showPopup) {
    SurveyStorage.setNewOrderFlag();
  }

  const renderSubmitHeader = () =>
    isQuote ? t('checkout.quote_submitted') : t('checkout.order_submitted');

  const renderSubmitSubheader = () =>
    isQuote ? t('checkout.thanks_for_quote') : t('checkout.thanks_for_order');

  const renderPath = () =>
    isQuote
      ? {pathname: Routes.PROFILE, state: {tab: 'profile.quotes'}}
      : {pathname: Routes.PROFILE, state: {tab: 'profile.orders'}};

  const renderLinkText = () => {
    return isQuote ? (
      t('checkout.go_to_quotes')
    ) : (
      <>
        <OrderHistoryOutline style={{ verticalAlign: 'middle' }}/>{' '}
        <span style={{ verticalAlign: 'middle' }}>{t('checkout.go_to_order_history').toString()}</span>
      </>
    );
  };

  const renderRequiredDate = (order: IOrder) => {
    if (order.dateRequired && !isQuote) {
      return order.dateRequired;
    }
    return '-----';
  };

  return (
    <ModalWindow open={showPopup} onClose={closeOrderPopup}>
      <ClickAwayListener onClickAway={closeOrderPopup}>
        <ModalCard variant="outlined" tabIndex={NaN}>
          {/* NaN is used here because when there are two modals - one is this and second is feedback
      - we can't type smth on feedback's input. The only way to fix this is set tabindex to ""
      But typescript don't allow you do this - it requires number, so the solution is to set NaN */}
          <ModalTitle>{renderSubmitHeader()}</ModalTitle>
          <ModalSubTitle>{renderSubmitSubheader()}</ModalSubTitle>
          <ModalCardContent>
            {
              orders.length ? orders.map(order => {
                return (
                  <Accordion key={order.orderNumber} defaultExpanded>
                    <AccordionContent
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <div className="box-customer-name">
                        <Typography>
                          <div/>
                        </Typography>
                      </div>

                      <div className="box-summary">
                        <div className="row-summary">
                          <div className="col-summary col-order-number">
                            <div className="txt-order-number">
                              <span className="txt-label">{t(`checkout.${!isQuote ? 'order_and_po' : 'quote_and_po'}`).toString()}</span>
                            </div>
                            <div>
                              <span className="txt-label">{t(`orders_and_quotes.${!isQuote ? 'order' : 'quote'}`).toString()}: </span>
                              <span className="txt-field-value">{order.orderNumber}</span>
                            </div>
                            {
                              order.poNumber && (
                                <div>
                                  <span className="txt-label">{t('checkout.po').toString()}: </span>
                                  <span className="txt-field-value">{order.poNumber}</span>
                                </div>
                              )
                            }
                          </div>

                          <div className="col-summary">
                            <div className="txt-label">{t('orders_and_quotes.created').toString()}</div>
                            <div className="">{order.createdAt}</div>
                          </div>

                          <div className="col-summary">
                            <div className="txt-label">{t(`checkout.${order.deliveryType === 'delivery' ? 'ship_date' : 'pickup_date'}`).toString()}</div>
                            <div className="txt-field-value">{renderRequiredDate(order)}</div>
                          </div>

                          <div className="col-summary">
                            <div className="txt-label">{t('orders_and_quotes.user').toString()}</div>
                            <div className="txt-field-value">{`${user?.firstName} ${user?.lastName}`}</div>
                          </div>
                          {
                            !isHidePrice && (
                              <div className="col-summary col-total">
                                <div className="txt-label">{t('orders_and_quotes.total').toString()}</div>
                                <div className="txt-total">${numberFormat(order.total)}</div>
                              </div>
                            )
                          }
                        </div>
                        {
                          order.tag && (
                            <div className="box-highlight-label">
                              <span className="label">{order.tag}</span>
                            </div>
                          )
                        }
                      </div>
                    </AccordionContent>

                    <AccordionDetails>
                      <CartItemBody order={order} />
                    </AccordionDetails>
                  </Accordion>
                )
              }): null
            }
          </ModalCardContent>

          <Container>
            <ModalFromButton style={{ order: 2 }}>
              <StyledButtonLink to={`${Routes.CATEGORIES}`} onClick={closeOrderPopup}>
                {t('checkout.continue_shopping').toString()}
              </StyledButtonLink>
            </ModalFromButton>
            {
              canSeePrice && canSeeOrderHistory && (canPlaceQuote || canPlaceOrder) && (
                <StyledLink onClick={() => {
                  closeOrderPopup();
                  if (!isQuote) {
                    clearGtmEcommerce()
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'click_order_history',
                        user_type: getUserType(user.email),
                        branch_id: branch?.id,
                        customer_code: user?.customer?.erpCustomerCode,
                        address_code: erpCustomerAddressCode,
                        address_id: selectedAddressId,
                      }
                    });
                  }
                }} to={renderPath()} style={{ order: 1 }}>
                  {renderLinkText()}
                </StyledLink>
              )
            }
          </Container>
        </ModalCard>
      </ClickAwayListener>
    </ModalWindow>
  );
};

export default OrdersModal;
