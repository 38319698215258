import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Checkbox, withStyles, CheckboxProps, Box, Typography, Tooltip } from '@material-ui/core';
import {
  selectCart,
  selectCartDataErrors,
  selectCartItemQtyUpdateStatus,
  selectCartItems,
  selectCartLoading,
  // selectCartPromoCodeErrors,
  selectOrderAdjustments,
  selectOrderId,
} from 'ducks/cart/selectors';
import { selectOrderLoading } from 'ducks/order/selectors';
import { numberFormat } from 'utils/products/product';
import { useTranslation } from 'react-i18next';
import { selectStep, selectItemErrors, selectOrder } from 'ducks/checkout/selectors';
import { toggleSnackbarClose, toggleSnackbarOpen } from 'ducks/ui/actions';
import { changeField } from 'ducks/checkout/actions';
import { CheckoutStep } from 'ducks/checkout/types';
import FormContext from 'pages/Checkout/FormContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { requestAddPromocode, requestCartSummary, requestDeletePromocode } from 'ducks/cart/actions';
import { selectAddressId, selectErpCustomerAddressCode, selectIsHidePrice } from 'ducks/application/selectors';
import TagManager from 'react-gtm-module';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import { Mailbox } from 'assets/svg/Checkout/Checkout';
import InfoIcon from '@material-ui/icons/Info';
import { useGlobalSettings } from 'hooks/useGlobalSetting';
import CheckoutDisabledModal from 'pages/Checkout/CheckoutDisabledModal';
import { ConfirmButton } from './ConfirmButton';
import {
  SidebarLayout,
  UserOptInForOrderUpdateEmails,
  SubtotalWrapper,
  Row,
  PriceItem,
  Key,
  Price,
  TotalPrice,
  LineBottom,
  Total,
  TotalRow,
  PromoCodeTitle,
  // ApplyRow,
  // TextInput,
  // ApplyButton,
  NextStepButton,
  StyledChip,
  StyledCloseIcon,
  // PromoCodeForm,
  StyledSaved,
} from './styles';

interface ICheckoutSidebar {
  isAbleToSecondStep: boolean;
  formError: string;
  zeroPriceError: string | null;
}

const validationSchema = Yup.object().shape({
  promocode: Yup.string().required(),
});

const GreenCheckbox = withStyles({
  root: {
    color: '#b2becc',
    '&$checked': {
      color: '#00853e',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const CheckoutSidebar = ({
  isAbleToSecondStep = false,
  formError,
  zeroPriceError,
}: ICheckoutSidebar) => {
  const { poRef, handleSubmit, requestDateRef, setErrors, setFieldValue, values, transactionTypeRef } = useContext(FormContext);
  const optValue = values.optInOrder;
  const checkoutStep = useSelector(selectStep);
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const isFirstStep = useMemo(() => checkoutStep === CheckoutStep.FIRST, [checkoutStep]);
  const isSecondStep = useMemo(() => checkoutStep === CheckoutStep.SECOND, [checkoutStep]);
  const itemErrors = useSelector(selectItemErrors);
  const isQtyUpdated = useSelector(selectCartItemQtyUpdateStatus);
  const adjustments = useSelector(selectOrderAdjustments);
  const isHidePrice = useSelector(selectIsHidePrice);
  const cartErrors = useSelector(selectCartDataErrors);
  // const promocodeErrors = useSelector(selectCartPromoCodeErrors);
  const cartLoading = useSelector(selectCartLoading);
  const orderLoading = useSelector(selectOrderLoading);
  const selectedOrderId = useSelector(selectOrderId);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const cart = useSelector(selectCart);
  const cartItems = useSelector(selectCartItems);

  const [isClickSubmitCart, setIsClickSubmitCart] = useState<boolean>(false);

  const totalSysWeight = useMemo(() => {
    if (cartItems) {
      const sysWeights = cartItems.map(item => get(item, 'variant.sysWeight', 0) * get(item, 'quantity', 0));
      return sysWeights.filter((v) => !isNull(v))?.reduce((acc, item) => acc + parseFloat(item), 0);
    }
    return null;
  }, [cartItems]);

  const isHasEmptySysWeight = useMemo(() => {
    return cartItems?.some(item => get(item, 'variant.sysWeight', null) === null);
  }, [cartItems]);

  const isOrder = useSelector(selectOrder) === 'order';
  const [kickedProducts, setKickedProducts] = useState([]);
  const [kickedProductsError, setKickedProductsError] = useState(null);
  const [promocode, setPromocode] = useState(null);

  const { data: globalSettings, refetch, isLoading } = useGlobalSettings();

  const dispatch = useDispatch();


  const handleCheckoutContinue = async () => {
    const refetchGlobalSettings = await refetch();
    if (!refetchGlobalSettings.data.isEnableCheckout) {
      return;
    }
    if (!globalSettings.isEnableCheckout) return;
    if (zeroPriceError) {
      dispatch(toggleSnackbarOpen('error', zeroPriceError));
    } else {
      dispatch(toggleSnackbarClose());
      dispatch(requestCartSummary(selectedAddressId));
      dispatch(changeField('step', CheckoutStep.SECOND));
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'click_continue_to_checkout',
          user_type: getUserType(user?.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
    }
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (cartErrors && cartErrors.length) {
      setKickedProducts(cartErrors.map(error => error.variant.name));
      setKickedProductsError(t('checkout.products_not_available', { kickedProducts }));
      dispatch(toggleSnackbarOpen('error', kickedProductsError));
    }
  }, [cartErrors, kickedProductsError, dispatch, setKickedProductsError, kickedProducts, t]);

  useEffect(() => {
    if (formError.length) {
      dispatch(toggleSnackbarClose());
    }
  }, [formError, dispatch]);

  const clickButtonWhenFormError = useCallback(
    (error: string) => {
      dispatch(toggleSnackbarOpen('error', error));
      if ([t('checkout.delivery_date_error'), t('checkout.pickup_date_required'), t('checkout.delivery_date_required')].includes(error)) {
        requestDateRef.current.focus();
        requestDateRef.current.scrollIntoView({
          behavior: 'smooth',
        });
        setErrors({
          deliveryDate: error,
        });
        transactionTypeRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      } else if (poRef.current) {
        poRef.current.focus();
        poRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    },
    [dispatch, poRef, requestDateRef, setErrors, t, transactionTypeRef],
  );

  const handleSubmitOrder = async () => {
    if (orderLoading) return;
    setIsClickSubmitCart(true);
    const refetchGlobalSettings = await refetch();
    if (!refetchGlobalSettings.data.isEnableCheckout) {
      return;
    }
    if (formError || zeroPriceError) {
      clickButtonWhenFormError(formError || zeroPriceError);
    } else {
      handleSubmit();
    }
  };

  const Subtotal = useCallback(() => {
    if (cart) {
      return cart.subtotal ? `$${numberFormat(cart.subtotal)}` : '$0.00';
    }

    return null;
  }, [cart]);

  const showSidebarBody = useMemo(
    () => isAbleToSecondStep && isSecondStep && isQtyUpdated && !cartLoading,
    [isAbleToSecondStep, isSecondStep, isQtyUpdated, cartLoading],
  );

  const promotionCoupon = cart?.promotionCoupon?.code;

  const addPromoCode = useCallback(
    (promoCode: string) => {
      setPromocode(promocode);
      dispatch(requestAddPromocode(selectedOrderId, promoCode));
    },
    [promocode, dispatch, selectedOrderId],
  );
  const deletePromoCode = useCallback(() => {
    setPromocode(null);
    dispatch(requestDeletePromocode(selectedOrderId));
  }, [dispatch, selectedOrderId]);

  const formik = useFormik({
    initialValues: {
      promocode,
    },
    validationSchema,
    onSubmit: data => {
      addPromoCode(data.promocode);
      formik.resetForm();
    },
  });

  const isDisableSecondStep = useMemo(() => {
    if (!globalSettings?.isEnableCheckout) return true;
    return !isAbleToSecondStep || itemErrors.length !== 0 || orderLoading || cartLoading || get(user, 'isMaintain', 0) === 1;
  }, [globalSettings?.isEnableCheckout, isAbleToSecondStep, itemErrors.length, orderLoading, cartLoading, user]);

  const SubtotalComponent = () => <Price>{Subtotal()}</Price>;

  return (
    <div>
      <SidebarLayout>
        {
          isOrder && globalSettings?.isShowOrderUpdateEmail && (
            <UserOptInForOrderUpdateEmails>
              <span className="ic">
                <Mailbox />
              </span>
              <div className="txt-message">
                {t('checkout.opt_in_order').toString()}
              </div>
              <div className="checkbox-wrapper">
                <GreenCheckbox checked={optValue} onClick={() => setFieldValue('optInOrder', !optValue)} />
              </div>
            </UserOptInForOrderUpdateEmails>
          )
        }
        <SubtotalWrapper showPrices={!isHidePrice || isSecondStep} isFirstStep={!isSecondStep}>
          <div style={{ visibility: !isHidePrice ? 'visible' : 'hidden' }}>
            {promotionCoupon && (
              <>
                <Row>
                  <PromoCodeTitle>{t('checkout.promocode').toString()}</PromoCodeTitle>
                  <div>
                    <StyledChip
                      label={promotionCoupon}
                      onDelete={deletePromoCode}
                      variant="outlined"
                      deleteIcon={<StyledCloseIcon />}
                    />
                  </div>
                </Row>
                <LineBottom />
              </>
            )}

            {!isHidePrice || isSecondStep ? (
              <>
                <Row>
                  <Key>{t('checkout.subtotal').toString()}</Key>
                  <SubtotalComponent />
                </Row>
                <LineBottom />
              </>
            ) : null}
            {showSidebarBody && (
              <>
                <Key style={{ display: 'block', marginBottom: 25 }}>{t('checkout.taxes').toString()}</Key>
                {adjustments
                  ?.filter(i => i.type === 'tax')
                  .map(adjustment => {
                    return (
                      <PriceItem key={adjustment.label}>
                        {!isHidePrice ? (
                          <>
                            <Key>{adjustment.label}</Key>
                            <Price>${numberFormat(adjustment.amount)}</Price>
                          </>
                        ) : null}
                      </PriceItem>
                    );
                  })}
                <LineBottom style={{ marginTop: 30 }} />
                <TotalRow>
                  <Total>{t('checkout.total').toString()}</Total>
                  {!isHidePrice ? (
                    <TotalPrice>
                      ${numberFormat(cart?.total)}
                      {promotionCoupon && (
                        <StyledSaved>
                          You saved ${numberFormat(Math.abs(cart?.adjustmentsWoTax))}
                        </StyledSaved>
                      )}
                    </TotalPrice>
                  ) : null}
                </TotalRow>
                <LineBottom style={{ marginTop: 10 }} />
                <Box style={{ marginBottom: 30, display: 'flex', justifyContent: 'space-between' }}>
                  <Typography>
                    {t('product_detail.total_weight').toString()}:
                  </Typography>
                  <Typography style={{ display: 'flex', alignItems: 'center' }}>
                    {
                      totalSysWeight ? 
                      (
                        <>
                          {parseFloat(numberFormat(totalSysWeight))} {t('product_detail.lbs').toString()}
                        </>
                      ) : (
                        <Typography style={{ color: '#FF0000' }}>
                          {t('product_detail.sys_weight_not_available').toString()}
                        </Typography>
                      )
                    }
                    {
                      isHasEmptySysWeight && (
                        <Tooltip
                          title={
                            <Typography style={{ fontSize: '14px', fontWeight: 400 }}>
                              {t('product_detail.total_weight_may_not_included_all_items').toString()}
                            </Typography>
                          }
                          enterTouchDelay={0}
                          leaveTouchDelay={15000}
                        >
                          <InfoIcon color="error" fontSize="small" />
                        </Tooltip>
                      )
                    }
                  </Typography>
                </Box>
              </>
            )}
          </div>

          <div>
            {isFirstStep ? (
              // @ts-ignore
              <NextStepButton
                disabled={isDisableSecondStep}
                onClick={handleCheckoutContinue}
              >
                {cartLoading && <CircularProgress color="inherit" size={22} style={{ marginRight: 5 }} />}
                {t('checkout.continue_to_checkout').toString()}
              </NextStepButton>
            ) : (
              <ConfirmButton
                loading={cartLoading}
                formError={formError}
                handleSubmitOrder={handleSubmitOrder}
                isDisabled={false}
              />
            )}
            {
              !globalSettings?.isEnableCheckout && (
                <Box style={{ border: '1px solid red', padding: '12px', marginTop: '20px', borderRadius: '4px', fontWeight: 500 }}>
                  <Typography color="error" style={{ fontSize: '14px' }}>
                    {t('checkout.checkout_disabled').toString()}
                  </Typography>
                </Box>
              )
            }
          </div>
        </SubtotalWrapper>
      </SidebarLayout>
      <CheckoutDisabledModal open={isClickSubmitCart && !globalSettings?.isEnableCheckout && !isLoading} onClose={() => {
        setIsClickSubmitCart(false);
      }} />
    </div>
  );
};

export default CheckoutSidebar;
