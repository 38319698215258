import { all, put, takeEvery, call } from 'redux-saga/effects';

import axios from 'utils/axios';

import { SliderActionTypes } from 'ducks/slider/types';
import { setServerError } from 'ducks/application/actions';
import { getError } from 'utils/errors';

function* requestSlider() {
  try {
    const { data: response } = yield call(axios.get, '/api/main-sliders');
    const slides = response.data;
    yield put({
      type: SliderActionTypes.REQUEST_SLIDER_DATA_SUCCESS,
      payload: { slides },
    });
  } catch (error) {
    yield put(setServerError(getError(error.response, [])));
  }
}

function* watchRequestSlider() {
  yield takeEvery(SliderActionTypes.REQUEST_SLIDER_DATA, requestSlider);
}

export default function* sliderSaga() {
  yield all([watchRequestSlider()]);
}
