import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import errorImage from 'assets/images/404.png';
import { MediumButton } from 'components/common/Buttons/MediumButton';
import Routes from 'routes';
import { resetCategoriesError } from 'ducks/category/actions';
import { resetProductsError } from 'ducks/product/actions';

import { useTranslation } from 'react-i18next';
import {setFilterProductError} from 'ducks/productVariant/actions';

const MainContainer = styled.div`
  @media (max-width: 768px) {
    margin: 25px 60px 0 60px;
  }
  @media (max-width: 320px) {
    margin: 10px 11px 0 11px;
  }
`;

const ContentContainer = styled.div`
  margin: 139px auto 0 auto;
  padding-bottom: 56px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    margin: 48px auto 0 auto;
    padding-bottom: 40px;
  }
  @media (max-width: 320px) {
    margin: 40px auto 0 auto;
  }
`;

const ImageDiv = styled.div`
  background: url(${errorImage}) center center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 476px;
  height: 200px;

  @media (max-width: 768px) {
    width: 369px;
    height: 155px;
  }
  @media (max-width: 380px) {
    width: 310px;
    height: 130.25px;
  }
  @media (max-width: 320px) {
    width: 304px;
    height: 128px;
  }
`;

const TextDiv = styled.div`
  font-family: Open Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.01em;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 48px;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 24px;
    margin-top: 12px;
    margin-bottom: 36px;
  }
  @media (max-width: 380px) {
    font-size: 19px;
    margin-top: 8px;
    margin-bottom: 24px;
  }
  @media (max-width: 320px) {
    font-size: 18px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const NotFound: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetCategoriesError());
    dispatch(resetProductsError());
    dispatch(setFilterProductError(null));
  }, [dispatch]);

  const { t } = useTranslation();

  return (
    <MainContainer>
      <ContentContainer>
        <ImageDiv />
        <TextDiv>{t('errors.404_title')}</TextDiv>
        <StyledLink to={Routes.HOME}>
          <MediumButton>{t('errors.view_catalog')}</MediumButton>
        </StyledLink>
      </ContentContainer>
    </MainContainer>
  );
};

export default NotFound;
