import React from 'react';
import styled from 'styled-components/macro';
import { Popover } from '@material-ui/core';

const ProductInfoPartContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 70px;

  @media (max-width: 1023px) {
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0;
  }
`;

const SliderContainer = styled.div`
  width: 450px;
  @media (max-width: 1023px) {
    width: 100%;
    height: auto;
  }
`;

const ProductSliderWrapper = styled.div`
  width: 37.5%;
  @media (max-width: 1023px) {
    width: 100%;
  }
  .product-slider {
    width: 100%;
    .slick-slider {
      width: 100%;
      .slick-list {
        width: 100% !important;
      }
    }
  }
`;

const ProductInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 62.5%;
  word-wrap: break-word;
  padding-left: 57px;
  @media (max-width: 1023px) {
    width: 100%;
    padding: 0;
  }
`;

const ProductName = styled.h1`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  letter-spacing: 0.1px;
  margin: 0;
  @media (max-width: 1023px) {
  }
`;

const ProductSku = styled.p`
  font-family: Open Sans;
  font-size: 16px;
  line-height: 28px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.2px;
  color: #99A8BB;
  margin-top: 5px;
  margin-bottom: 0;

  @media (max-width: 1023px) {
    margin-top: 0;
  }
`;

const NearbyHubsButton = styled.button`
  display: block;
  font-size: 16px;
  line-height: calc(22 / 16);
  letter-spacing: 0.01em;
  text-decoration: underline;
  color: #246CFB;
  border: 0;
  outline: 0;
  background: none;
  text-align: left;
  cursor: pointer;
  padding: 0;
  margin-top: 4px;
  margin-bottom: 4px;
  &:hover {
    text-decoration: none;
  }
`;

const ProductInStock = styled(({ stock, status, children, t, ...rest }) => (
  <p {...rest}> {children} </p>
))`
  display: flex;
  flex-direction: column;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: ${props => {
    if (props.waitingOnStock) {
      return props.theme.colors.waitingOnStockBlue;
    }
    if (props.inStock) {
      return props.theme.colors.mainGreen;
    }

    return '#001A3B';
  }};
  margin: 0;
  .txt-stock {
    display: block;
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;
    margin-top: 4px;
    margin-bottom: 4px;
    &:hover {
      font-weight: bold;
    }
  }
`;

const ProductInStockV2 = styled(({ stock, status, children, t, ...rest }) => (
  <div {...rest}> {children} </div>
))`
  display: flex;
  flex-direction: column;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: ${props => {
    if (props.waitingOnStock) {
      return props.theme.colors.waitingOnStockBlue;
    }
    if (props.inStock) {
      return props.theme.colors.mainGreen;
    }

    return props.theme.colors.black;
  }};
  margin: 0 0 24px;
  .txt-stock {
    display: block;
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;
    margin-top: 16px;
    margin-bottom: 4px;
    &:hover {
      font-weight: bold;
    }
  }
  .short-description {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-top: 24px;
  }
`;

const SpecificationAccordionWrapper = styled.div`
  &.has-scroll {
    .MuiCollapse-wrapper {
      max-height: 473px;
      overflow-y: auto;
    }
  }
  .MuiAccordion-root {
    border: 1px solid #C2CAD6;
    box-shadow: none;
    border-radius: 4px;
    margin: 0 0 8px !important;
    &:before {
      display: none;
    }
  }
  .MuiIconButton-root {
    padding: 10px;
  }
  .MuiAccordionSummary-root {
    min-height: 44px;
    border-radius: 4px 4px 0 0;
    &.Mui-expanded {
      background: #f2f4f6;
    }
  }
  .MuiAccordionSummary-content {
    margin: 8px 0;
    &.Mui-expanded {
      margin: 0;
      .MuiTypography-root {
        font-weight: 600;
        color: ${({ theme: { colors } }) => colors.mainGreen};
      }
    }
  }
  .MuiAccordionSummary-expandIcon {
    &.Mui-expanded {
      color: ${({ theme: { colors } }) => colors.mainGreen};
    }
  }
  .MuiTypography-root {
    font-family: Open Sans;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.2px;
    font-style: normal;
    font-weight: normal;
    color: #00133C;
  }
  .MuiCollapse-container {
    border-radius: 0 0 4px 4px;
  }
  .MuiAccordionDetails-root {
    white-space: pre-line;
  }
`;

const ProductDescriptionShort = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin: 16px 0 0;

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

const ProductPriceSectionWrapper = styled(({ border, children, ...rest }) => (
  <div {...rest}> {children} </div>
))`
  display: flex;
  flex-direction: column;
  border-top: ${props => {
    if (props.border) {
      return `1px solid ${props.theme.colors.productBorderGrey}`;
    }
    return 'none';
  }};
  border-bottom: ${props => {
    if (props.border) {
      return `1px solid ${props.theme.colors.productBorderGrey}`;
    }
    return 'none';
  }};
  padding: ${props => {
    if (props.border) {
      return '24px 0';
    }
    return '24px 0';
  }};
  margin-top: 24px;

  @media (max-width: 1023px) {
    padding: 24px 0;
  }
`;

const ProductPriceWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const ProductPrice = styled.p`
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  letter-spacing: 0.1px;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  margin: 0;
`;

const ProductPackage = styled.p`
  color: #48576B;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.2px;
  padding-left: 6px;
  margin: 0;
`;

const ProductSquarePrice = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.01em;
  margin: 0;
  color: ${({ theme: { colors } }) => colors.textColor};
`;

const ProductAttributeWrapperList = styled.div`
  border-bottom: 1px solid #C2CAD6;
  [class*="ProductAttributeWrapper"] {
    &:first-child {
      [class*="InfoSectionTitle"] {
        margin-top: 16px;
      }
    }
  }
  @media (max-width: 1023px) {
  }
`;

const ProductAttributeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.productBorderGrey};
  position: relative;
  &:last-child {
    border: none;
  }
  &.highlight {
    .info-section-title {
      @media only screen and (min-width: 768px) {
        color: ${({ theme: { colors } }) => colors.mainGreen};
      }
    }
    .box-select {
      border: 1px solid ${({ theme: { colors } }) => colors.mainGreen};
    }
  }
  &.warning {
    .box-select {
      border: 1px solid #ffdcbc;
      box-shadow: 0 4px 6px 1px rgb(255 220 188 / 35%);
      .MuiSelect-select {
        padding-right: 45px;
      }
      .ic-warning {
        display: block;
      }
    }
  }
  .box-select {
    position: relative;
    max-width: 351px;
  }
  .ic-warning {
    display: none;
    position: absolute;
    right: 34px;
    top: 9px;
    z-index: 2;
  }
`;

const InfoSectionTitle = styled.p`
  font-family: Open Sans;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.2px;
  font-style: normal;
  font-weight: normal;
  color: ${({ theme: { colors } }) => colors.grey};
  margin-top: 20px;
  margin-bottom: 16px;
  strong {
    font-weight: 600;
    letter-spacing: 0.1px;
    color: #00133C;
  }
`;

const WarningNote = styled.div`
  position: relative;
  max-width: 351px;
  border-radius: 5px;
  background: #f7f7f7;
  padding: 14px 42px;
  .ic-warning {
    position: absolute;
    left: 13px;
    top: 15px;
  }
`;

const ColorCirclesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  margin-bottom: 24px;
`;

const ColorCircle = styled(({ color, children, ...rest }) => <div {...rest}> {children} </div>)`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: ${props => (props.color ? `url(${props.color})` : props.theme.colors.black)};
`;

const AttributePapersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  margin-bottom: 20px;
`;

const AttributePaper = styled(({ selected, children, ...rest }) => (
  <button {...rest} type="button">
    {' '}
    {children}{' '}
  </button>
))`
  padding: 12px 12px;
  border: 1px solid
    ${props => {
    if (props.warning) {
      return 'rgb(255, 220, 188)';
    }
    return props.selected ? props.theme.colors.mainGreen : props.theme.colors.borderGrey
  }};
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 8px;
  background: ${({ theme: { colors } }) => colors.white};
  outline: none;
`;

const AttributePaperTitle = styled.p`
  margin: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.01em;
`;

const TotalWrapper = styled.div`
  margin-top: 24px;
  .table-total {
    width: auto;
    table-layout: fixed;
    border-spacing: 0;
    margin-bottom: 50px;
    tr {
      th,
      td {
        font-weight: normal;
        vertical-align: top;
        padding: 0;
      }
      th {
        padding-right: 10px;
      }
    }
    .old-price {
      font-weight: bold;
      text-decoration: line-through;
    }
    .price {
      font-weight: bold;
      font-size: 2.2em;
      line-height: 1;
      margin-bottom: 10px;
    }
  }
  .saving_wrapper {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    overflow: visible;
    white-space: nowrap;
    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 10px;
      bottom: 1px;
      transform-origin: right top;
      transform: translate(0, 100%) rotate(-5deg);
      width: 75px;
      height: 0;
      box-shadow: 0 0 7px 1px rgba(91, 91, 91, 1);
      z-index: 2;
    }
    &:after {
      display: block;
      content: '';
      position: absolute;
      right: 10px;
      bottom: 1px;
      transform-origin: left top;
      transform: translate(0, 100%) rotate(5deg);
      width: 75px;
      height: 0;
      box-shadow: 0 0 7px 1px rgba(91, 91, 91, 1);
      z-index: 2;
    }
    .saving_inner {
      position: relative;
      background: #eefef1;
      padding: 5px 15px;
      z-index: 3;
    }
    .saving_percent {
      font-weight: bold;
      font-size: 1.2em;
      color: ${({ theme: { colors } }) => colors.mainGreen};
    }
    .el-break {
      display: none;
    }
  }
`;

const TotalText = styled.p`
  margin: 0 0 8px 0;
  font-family: Open Sans,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.01em;
  margin-bottom: 16px;
  .text-total {
    display: block;
    font-family: Open Sans;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.2px;
    font-style: normal;
    font-weight: normal;
    color: #00133C;
    margin-bottom: 4px;
  }
  .text-price {
    display: block;
    color: #00133C;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 150% */
    letter-spacing: 0.1px;
  }
`;

const StyledButton = styled.button`
  cursor: pointer;
  border: none;
  background: ${({ theme: { colors } }) => colors.mainGreen};
  min-width: 320px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 0 13px 0;
  outline: none;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.mainGreenHover};
  }

  &:active {
    background-color: ${({ theme: { colors } }) => colors.mainGreefPassed};
  }

  @media (max-width: 1023px) {
    max-width: 100%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const RequestPricingStyledButton = styled.button`
  cursor: pointer;
  border: none;
  background: ${({ theme: { colors } }) => colors.mainGreen};
  min-width: 180px;
  height: 40.5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 0 13px 0;
  outline: none;
  margin: 0 auto;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.mainGreenHover};
  }

  &:active {
    background-color: ${({ theme: { colors } }) => colors.mainGreefPassed};
  }

  @media (max-width: 1023px) {
    max-width: 100%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ButtonTitle = styled.p`
  margin: 0;
  color: ${({ theme: { colors } }) => colors.white};
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.1px;
  font-style: normal;
`;

const StyledPopover = styled(Popover)`
  margin-bottom: 15px;
  pointer-events: none;
`;

const PopoverColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  width: 100px;
`;

const PopoverColor = styled(({ color, children, ...rest }) => <div {...rest}> {children} </div>)`
  width: 100%;
  height: 64px;
  background: ${props => (props.color ? `url(${props.color})` : props.theme.colors.black)};
`;

const PopoverText = styled.p`
  margin: 3px 0 0 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
`;

const ColorWithPopover = styled(({ color, children, ...rest }) => (
  <button {...rest} type="button">
    {' '}
    {children}{' '}
  </button>
))`
  margin-right: 10px;
  border-radius: 50%;
  padding: 3px;
  background: ${({ theme: { colors } }) => colors.white};
  border: none;
  border: ${props => (props.selected ? `1px solid ${props.theme.colors.mainGreen}` : 'none')};
  opacity: ${props => (props.disabled ? '0.3' : 'none')};
  outline: none;
`;

const NextTierPriceWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  `;

const NextTierPriceInner = styled.div`
  display: flex;
  cursor: pointer;
  p {
    color: ${({ theme: { colors } }) => colors.mainGreen};
    text-decoration: underline;
  }
  &:hover > p {
    font-size: 16px;
    font-weight: 900;
  }
`;

const NextTierPriceText = styled.p`
  font-family: Open Sans,serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
  margin: 0;
`;

const NextTierPrice = styled.p`
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  margin: 0;
`;

const StyledLoadingDiv = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 60px;
  border: 10px solid ${({ theme: { colors } }) => colors.mainGreen};
  border-top-color: ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  animation: loading 1.2s linear infinite;
  -webkit-animation: loading 1.2s linear infinite;
`;

const StyledLoadingDivWrapper = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLeftBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const SwatchWrapperScroll = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 24px;
  [class*="SwatchWrapperScrollRow-"] {
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
`
const SwatchWrapperScrollRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  [class*="SwatchItem-"] {
    margin-right: 12px;
    &:last-child {
      margin-right: 0 !important;
    }
  }
`

const SwatchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0;
  overflow: hidden;
  margin-left: -6px;
  margin-right: -6px;
  margin-bottom: 24px;
  @media (max-width: 1023px) {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    margin: 0;
  }
  [class*="SwatchItem-"] {
    padding-left: 6px;
    padding-right: 6px;
    margin-bottom: 10px;
    @media (max-width: 1023px) {
      display: inline-flex;
      vertical-align: top;
      white-space: initial;
      padding: 0;
      margin-right: 12px;
    }
  }
  [class*="SwatchItemInner-"] {
    width: 129px;
  }
`;

const SwatchItem = styled.div`
  border-radius: 0;
  border: 0;
  outline: 0;
  background: none;
  padding: 0;
`;

const SwatchItemInnerWithPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 129px;
  height: 246px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #c2cad6;
  background: #fff;
  text-align: center;
  cursor: pointer;
  &.active {
    border: 2px solid #007a33;
    .txt-title {
      font-weight: bold;
      color: #007a33;
    }
  }
  &.warning {
    border: 2px solid rgb(255, 220, 188);
  }
  &.disabled {
    cursor: cursor;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: transparent
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width: 100%25; height: 100%25;'%3E%3Cline x1='0' y1='100%25' x2='100%25' y2='0' stroke='%23C2CAD6' stroke-width='1' /%3E%3C/svg%3E")
        no-repeat left top;
      background-size: 100% 100%;
    }
    .img-product {
      img {
        opacity: 0.5;
      }
    }
    .txt-title {
      color: #99a1b1;
    }
    .txt-price {
      background: #f2f2f2;
      .lg {
        color: #99a1b1;
      }
      .md {
        color: #99a1b1;
      }
    }
    .txt-on-stock {
      color: #99a1b1;
    }
    .txt-special-availability {
      .md {
        color: #99a1b1;
      }
      .lg {
        color: #99a1b1;
      }
    }
    .txt-waiting-on-stock {
      color: #99a1b1;
    }
  }
  .img-product {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #C2CAD6;
    padding: 8px;
    img {
      width: 64px;
      height: 64px;
      object-fit: cover;
    }
  }
  .img-default-product {
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  .box-detail {
    width: 100%;
    overflow: hidden;
    padding: 12px 8px 0;
  }
  .txt-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    color: #00133C;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px; /* 171.429% */
    margin-bottom: 8px;
  }
  .txt-price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 88px;
    min-height: 52px;
    border-radius: 4px;
    border: 1px solid #4eb86c;
    background: #edf8f2;
    padding: 4px 7px;
    .lg {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #007a33;
      font-family: Open Sans;
      font-style: normal;
      font-size: 12px;
      font-weight: 600;
      line-height: 24px; /* 160% */
      letter-spacing: 0.1px;
      white-space: initial;
      word-break: break-all;
    }
    .md {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #00133c;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 20px; /* 166.667% */
      letter-spacing: 0.2px;
      white-space: initial;
      word-break: break-all;
    }
  }
  .txt-on-stock {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px; /* 160% */
    letter-spacing: 0.2px;
    color: #007a33;
    padding: 0 5px;
    margin-top: 8px;
  }
  .txt-special-availability {
    margin-top: 8px;
    .md {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #f5001d;
      text-align: center;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.2px;
      padding: 0 5px;
      margin-bottom: 4px;
    }
    .lg {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #f5001d;
      text-align: center;
      font-family: Open Sans;
      font-style: normal;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      padding: 0 5px;
    }
  }
  .txt-waiting-on-stock {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #007aff;
    text-align: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px; /* 160% */
    letter-spacing: 0.2px;
    padding: 0 5px;
    margin-top: 8px;
  }
`;

const SwatchItemInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 129px;
  height: 140px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #c2cad6;
  background: #fff;
  text-align: center;
  cursor: pointer;
  &.active {
    border: 2px solid #007a33;
  }
  &.warning {
    border: 2px solid rgb(255, 220, 188);
  }
  &.disabled {
    cursor: cursor;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: transparent
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width: 100%25; height: 100%25;'%3E%3Cline x1='0' y1='100%25' x2='100%25' y2='0' stroke='%23C2CAD6' stroke-width='1' /%3E%3C/svg%3E")
        no-repeat left top;
      background-size: 100% 100%;
    }
    .img-product {
      img {
        opacity: 0.5;
      }
    }
    .txt-title {
      flex: 0 0 auto;
      color: #99a1b1;
      border-bottom: 1px solid #e7eaef;
      margin-bottom: 0;
    }
  }
  .img-product {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    padding: 8px;
    img {
      width: 64px;
      height: 64px;
      object-fit: cover;
    }
  }
  .txt-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 58px;
    background: #f2f4f6;
    width: 100%;
    color: #007a33;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.1px;
    padding: 0 5px;
    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const DisabledItemTooltip = styled.div`
  width: 116px;
  .md-1 {
    color: #F2F4F6;
    font-family: Open Sans;
    font-size: 10px;
    line-height: 16px; 
    letter-spacing: 0.2px;
    font-style: normal;
    font-weight: normal;
  }
  .lg {
    color: #FFF;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
    font-style: normal;
    border-bottom: 1px solid #fff;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
  .md-2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.2px;
  }
`;

const InquireNowButtonTitle = styled.p`
  margin: 0;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.1px;
  font-style: normal;
  color: #007A33;
`;

const InquireNowButton = styled.button`
  margin-top: 10px;  
  cursor: pointer;
  padding: 12.5px 0;
  background-color: #FFFFFF;
  border: 1px solid #007A33;  
  border-radius: 4px;
  display: flex;
  min-width: 320px;
  justify-content: center;
  outline: none;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.mainGreenHover};
    color: #ffffff;  
  }
  
  &:hover ${InquireNowButtonTitle} {
    color: #ffffff;
  }  

  &:active {
    background-color: ${({ theme: { colors } }) => colors.mainGreefPassed};
    color: #ffffff;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

export {
  ProductInfoPartContainer,
  SliderContainer,
  ProductSliderWrapper,
  ProductInfoWrapper,
  ProductName,
  ProductSku,
  NearbyHubsButton,
  ProductInStock,
  SpecificationAccordionWrapper,
  ProductDescriptionShort,
  ProductPriceSectionWrapper,
  ProductPriceWrapper,
  ProductPrice,
  ProductPackage,
  ProductSquarePrice,
  ProductAttributeWrapperList,
  ProductAttributeWrapper,
  InfoSectionTitle,
  WarningNote,
  ColorCirclesWrapper,
  ColorCircle,
  AttributePapersWrapper,
  AttributePaper,
  AttributePaperTitle,
  TotalWrapper,
  TotalText,
  StyledButton,
  ButtonTitle,
  StyledPopover,
  PopoverColorWrapper,
  PopoverColor,
  PopoverText,
  ColorWithPopover,
  NextTierPriceWrapper,
  NextTierPriceInner,
  NextTierPriceText,
  NextTierPrice,
  StyledLoadingDiv,
  StyledLoadingDivWrapper,
  RequestPricingStyledButton,
  ProductInStockV2,
  StyledLeftBlock,
  SwatchWrapper,
  SwatchWrapperScroll,
  SwatchWrapperScrollRow,
  SwatchItem,
  SwatchItemInnerWithPrice,
  SwatchItemInner,
  DisabledItemTooltip,
  InquireNowButton,
  InquireNowButtonTitle
};
