import React from 'react';

export const FilterIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 1H1L6 8V15L10 13V8L15 1Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const GoBackArrow = () => {
  return (
    <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 6H1M1 6L6 1M1 6L6 11"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const WhiteTruck = () => {
  return (
    <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4675 9.95991V8.77365H14.0927L13.7194 9.95991H12.4675ZM6.12057 8.77365H7.78967V9.95991H6.52687L6.12057 8.77365ZM7.78967 3.92887V5.15946H4.87973L4.45148 3.92887H7.78967ZM8.87678 3.92887H11.3804V5.15946H8.87678V3.92887ZM5.26406 6.25702H7.78967V7.67609H5.74722L5.26406 6.25702ZM11.3804 9.95991H8.87678V8.77365H11.3804V9.95991ZM8.87678 6.25702H11.3804V7.67609H8.87678V6.25702ZM14.4331 7.67609H12.4565V6.25702H14.8833L14.4331 7.67609ZM15.2347 5.17055H12.4675V3.92887H15.63L15.2347 5.17055ZM5.67036 10.5253C5.74722 10.6694 5.81311 11.0575 6.14253 11.0575H14.1037C14.3343 11.0575 14.5539 10.9023 14.6198 10.6805L16.8818 3.55193C16.9368 3.38563 16.9038 3.20825 16.805 3.06412C16.7062 2.92 16.5414 2.84239 16.3657 2.84239H4.15499C4.13303 2.84239 4.11107 2.84239 4.08911 2.84239L3.58399 1.37898C3.52908 1.22377 3.41927 1.10182 3.26554 1.04638L0.739934 0.0375137C0.465411 -0.073351 0.146965 0.0707731 0.0371565 0.347935C-0.0726525 0.625097 0.0700992 0.946604 0.344622 1.05747L2.65061 1.96656L5.5276 10.1262"
        fill="white"
      />
      <path
        d="M22.778 5.8373L21.3175 3.74195C21.2626 3.66435 21.1748 3.60892 21.0759 3.60892H18.8029C18.6382 3.60892 18.5064 3.74195 18.5064 3.90825V6.00359C18.5064 6.16989 18.6382 6.30293 18.8029 6.30293H22.5364C22.767 6.31401 22.9097 6.03685 22.778 5.8373ZM25.8197 11.5357V12.4559C25.8197 12.6222 25.6879 12.7553 25.5232 12.7553H23.0964C22.9207 11.5579 21.8995 10.6267 20.6587 10.6267C19.4178 10.6267 18.3966 11.5579 18.2209 12.7553H13.3344C13.2466 12.1455 12.9391 11.6133 12.4999 11.2364H17.1338V2.56679C17.1338 2.23419 17.3973 1.95703 17.7377 1.95703H20.5708C21.3724 1.95703 22.1081 2.35614 22.5583 3.02133L24.3702 5.75969C24.6337 6.1588 24.7765 6.63552 24.7765 7.11224V11.2253H25.5012C25.6769 11.2364 25.8197 11.3694 25.8197 11.5357ZM21.581 13.1211C21.581 12.6 21.1638 12.1788 20.6477 12.1788C20.1316 12.1788 19.7143 12.6 19.7143 13.1211C19.7143 13.6422 20.1316 14.0635 20.6477 14.0635C21.1638 14.0635 21.581 13.6422 21.581 13.1211ZM22.5144 13.1211C22.5144 14.1632 21.6799 15.0058 20.6477 15.0058C19.6155 15.0058 18.7809 14.1632 18.7809 13.1211C18.7809 12.079 19.6155 11.2364 20.6477 11.2364C21.6799 11.2364 22.5144 12.079 22.5144 13.1211ZM9.28245 11.2364H5.09873C4.93402 11.2364 4.80225 11.3694 4.80225 11.5357V12.4559C4.80225 12.6222 4.93402 12.7553 5.09873 12.7553H8.4479C8.53575 12.1455 8.84321 11.6133 9.28245 11.2364ZM11.819 13.1211C11.819 12.6 11.4018 12.1788 10.8857 12.1788C10.3696 12.1788 9.95228 12.6 9.95228 13.1211C9.95228 13.6422 10.3696 14.0635 10.8857 14.0635C11.4018 14.0635 11.819 13.6422 11.819 13.1211ZM12.7524 13.1211C12.7524 14.1632 11.9179 15.0058 10.8857 15.0058C9.85346 15.0058 9.01891 14.1632 9.01891 13.1211C9.01891 12.079 9.85346 11.2364 10.8857 11.2364C11.9179 11.2364 12.7524 12.079 12.7524 13.1211Z"
        fill="white"
      />
    </svg>
  );
};

export const ListViewMinus = () => {
  return (
    <svg width="9" height="2" viewBox="0 0 9 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="9" height="2" rx="1" fill="#939599" />
    </svg>
  );
};

export const ListViewPlus = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="4" width="10" height="2" rx="1" fill="#939599" />
      <rect x="4" width="2" height="10" rx="1" fill="#939599" />
    </svg>
  );
};

export const DocumentIcon = () => {
  return (
    <svg width="28" height="33" viewBox="0 0 28 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="27" height="32" rx="1.5" stroke="#939599" />
      <rect x="6.22223" y="6.28516" width="15.5556" height="1.57143" rx="0.785714" fill="#939599" />
      <rect x="6.22223" y="12.5723" width="15.5556" height="1.57143" rx="0.785713" fill="#939599" />
      <rect x="6.22223" y="18.8574" width="15.5556" height="1.57142" rx="0.785712" fill="#939599" />
      <rect x="6.22223" y="25.1426" width="15.5556" height="1.57143" rx="0.785713" fill="#939599" />
    </svg>
  );
};

export const DropdownArrow = () => {
  return (
    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 15L7.58824 8L0.999999 1"
        stroke="#939599"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
