import styled from 'styled-components/macro';
import { Dialog, Button, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StylesDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 416px;
  }
  .MuiDialogContent-root {
    padding: 40px 48px;
  }
  .MuiTypography-root {
    margin-bottom: 0;
  }
`

const StylesDialogContent = styled(DialogContent)`
  position: relative;
  padding: 36px 48px !important;
  @media only screen and (max-width: 479px) {
    padding: 36px 20px 20px !important;
  }
  .btn-close {
    position: absolute;
    right: 18px;
    top: 21px;
    font-size: 20px;
    color: #939599;
    border: 0;
    background: none;
    cursor: pointer;
    transition: all 0.4s ease;
    @media only screen and (max-width: 479px) {
      right: 6px;
      top: 12px;
    }
    &:hover {
      color: #333;
    }
    svg {
      width: 1em;
      height: 1em;
    }
  }
  .icon-success {
    margin-bottom: 24px;
  }
  .title-modal {
    color: #00133C;
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.1px;
    margin-bottom: 8px;
  }
  .title-note {
    color: #00133C;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.2px;
    margin-bottom: 24px;
    span {
      font-weight: 600;
    }
  }
  .input-email {
    width: 100%;
    height: 52px;
    color: #00133C;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.2px;
    border: 1px solid #C2CAD6;
    border-radius: 4px;
    resize: none;
    outline: 0;
    padding: 14px 16px;
    margin-bottom: 24px;
    transition: all 0.4s ease;
    @media only screen and (max-width: 479px) {
      font-size: 20px;
      margin-bottom: 20px;
    }
    &:focus {
      border-color: #00133C;
    }
    &:-ms-input-placeholder {
      color: #99A8BB;
    }
    &:-moz-placeholder {
      color: #99A8BB;
    }
    &::-moz-placeholder {
      color: #99A8BB;
    }
    &::-webkit-input-placeholder {
      color: #99A8BB;
    }
    &.invalid {
      border-color: #d32f2f;
    }
  }
  .box-submit {
    text-align: center;
  }
  .btn-submit {
  }
`;

const StyledSubmitButton = withStyles({
  root: {
    textAlign: 'center',
    width: '100%',
    height: '48px',
    backgroundColor: '#00853e',
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    letterSpacing: '0.01em',
    fontWeight: 'normal',
    color: '#FFFFFF',
    '&$disabled': {
      color: '#FFFFFF',
      backgroundColor: '#99a8bb',
    },
    '&:hover': {
      backgroundColor: '#37875F',
    },
  },
  disabled: {
    backgroundColor: '#DADCE0',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

export {
  StylesDialog,
  StylesDialogContent,
  StyledSubmitButton
};
