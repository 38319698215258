/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, no-console */
import React, { useState } from 'react';
import { CustomArrowProps } from 'react-slick';
import { IconButton, useMediaQuery } from '@material-ui/core';

import {
  LeftArrow,
  RightArrow,
  EnlargeImageIcon,
} from 'assets/svg/FeaturedProducts/FeaturedProducts';
import { IProductResizeImage } from 'models/interfaces/productVariant';
import DotsSlider from 'components/common/ImagesSlider/DotsSlider/DotsSlider';
import { ZoomImage } from 'components/common/ZoomImage/ZoomImage';

import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import TagManager from 'react-gtm-module';

import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { useSelector } from 'react-redux';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { selectAddressId, selectErpCustomerAddressCode } from 'ducks/application/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import {
  SliderWrapper,
  StyledSlider,
  ProductCard,
  DotWrapper,
  EnlargeImage,
  StyledToolbar,
  StyledDialogContent,
  StyledDialog,
  StyledArrow,
} from './styles';

const SampleNextArrow: React.FC<CustomArrowProps> = ({ onClick, className }: CustomArrowProps) => {
  return (
    <div className={className} onClick={onClick} aria-hidden="true">
      <RightArrow />
    </div>
  );
};

const SamplePrevArrow: React.FC<CustomArrowProps> = ({ onClick, className }: CustomArrowProps) => {
  return (
    <div className={className} onClick={onClick} aria-hidden="true">
      <LeftArrow />
    </div>
  );
};

interface IImagesSlider {
  images: IProductResizeImage[];
}

const ImagesSlider: React.FC<IImagesSlider> = ({ images }: IImagesSlider) => {
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const [modalImage, setModalImage] = useState(null);
  const isMobile = useMediaQuery('(max-width: 1023px)');

  const settings = {
    appendDots: dots => {
      return dots.length > 5 ? <DotsSlider dots={dots} /> : <ul>{dots}</ul>;
    },
    customPaging(i: number) {
      const url = images[i]?.small;

      return (
        <DotWrapper>
          <img src={url} alt="dot product avatar" />
        </DotWrapper>
      );
    },
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    adaptiveHeight: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const handleClose = () => setModalImage(null);

  /**
   * Handle prev, next
   */
  const handlePrevNext = (val: string) => {
    if (
      !modalImage ||
      !(
        (val === 'prev' && modalImage.currentIndex > 0) ||
        (val === 'next' && modalImage.currentIndex < images.length - 1)
      ) ||
      !(Array.isArray(images) && images.length)
    ) {
      return;
    }

    const newIndex = modalImage.currentIndex + (val === 'prev' ? -1 : 1);

    setModalImage({ ...images[newIndex], currentIndex: newIndex });
  };

  return (
    images && (
      <SliderWrapper>
        <StyledSlider {...settings}>
          {images.map((image, index) => (
            <ProductCard key={image.original} className="product-card-on-slider">
              {!isMobile ? (
                <EnlargeImage
                  itemIndex={index}
                  onClick={() => {
                    clearGtmEcommerce();
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'click_expand_image',
                        user_type: getUserType(user.email),
                        branch_id: branch.id,
                        customer_code: user?.customer?.erpCustomerCode,
                        address_code: erpCustomerAddressCode,
                        address_id: selectedAddressId,
                      },
                    });
                    setModalImage({ ...image, currentIndex: index });
                  }}
                >
                  <EnlargeImageIcon />
                </EnlargeImage>
              ) : null}

              <div
                onClick={() => {
                  clearGtmEcommerce();
                  TagManager.dataLayer({
                    dataLayer: {
                      event: 'click_expand_image',
                      user_type: getUserType(user.email),
                      branch_id: branch.id,
                      customer_code: user?.customer?.erpCustomerCode,
                      address_code: erpCustomerAddressCode,
                      address_id: selectedAddressId,
                    },
                  });
                  setModalImage({ ...image, currentIndex: index });
                }}
              >
                <ZoomImage
                  image={image.medium}
                  largeImage={image.large}
                  className="zoom-image"
                  magnifierSize={isMobile ? '0' : '30%'}
                  cursorStyle={isMobile ? 'pointer' : 'none'}
                />
              </div>
            </ProductCard>
          ))}
        </StyledSlider>

        <StyledDialog
          fullWidth={false}
          maxWidth="lg"
          open={modalImage}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <StyledToolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </StyledToolbar>

          <StyledDialogContent>
            {modalImage?.currentIndex > 0 && (
              <StyledArrow className="left-arrow">
                <IconButton color="inherit" onClick={() => handlePrevNext('prev')}>
                  <ArrowBackIos />
                </IconButton>
              </StyledArrow>
            )}

            {modalImage?.currentIndex < images.length - 1 && (
              <StyledArrow className="right-arrow">
                <IconButton color="inherit" onClick={() => handlePrevNext('next')}>
                  <ArrowForwardIos />
                </IconButton>
              </StyledArrow>
            )}

            <ZoomImage
              image={modalImage?.original}
              largeImage={modalImage?.large}
              className="zoom-image"
              cursorStyle={isMobile ? 'pointer' : 'none'}
            />
          </StyledDialogContent>
        </StyledDialog>
      </SliderWrapper>
    )
  );
};

export default ImagesSlider;
