import React from 'react';

export const EnlargeImageIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.6957 4.70285L23.8448 4.70117C23.6451 4.70117 23.4646 4.82191 23.3885 5.00644C23.3117 5.19098 23.3538 5.40303 23.495 5.54488L26.0743 8.12411L8.18342 26.1102L5.60641 23.5331C5.46516 23.392 5.2525 23.3498 5.06797 23.426C4.88343 23.5027 4.76331 23.6827 4.76331 23.8825L4.7627 30.732C4.7627 31.005 4.98344 31.2259 5.25662 31.2259L12.1074 31.2276C12.3072 31.2276 12.4876 31.107 12.5637 30.9225C12.6405 30.7378 12.5984 30.5257 12.4572 30.3839L9.88014 27.807L23.5108 14.0767L27.7688 9.81861L30.3458 12.3956C30.487 12.5369 30.6997 12.579 30.8842 12.5028C31.0688 12.4261 31.1889 12.2462 31.1889 12.0465L31.1895 5.19685C31.1896 4.92382 30.9687 4.70285 30.6957 4.70285Z"
        fill="#C4C4C4"
      />
      <path
        d="M33.1782 0H2.82192C1.26332 0 0 1.26324 0 2.82192V33.1781C0 34.7368 1.26332 36 2.82192 36H33.1782C34.7368 36 36.0001 34.7368 36.0001 33.1781V2.82192C36 1.26324 34.7368 0 33.1782 0ZM33.6 33.1781C33.6 33.4107 33.4108 33.6 33.1782 33.6H2.82192C2.58929 33.6 2.40003 33.4107 2.40003 33.1781V2.82192C2.40003 2.58921 2.58929 2.39995 2.82192 2.39995H33.1782C33.4108 2.39995 33.6001 2.58921 33.6001 2.82192V33.1781H33.6Z"
        fill="#C4C4C4"
      />
    </svg>
  );
};

export const LeftArrow = () => {
  return (
    <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 1L0.999999 9.5L9 18"
        stroke="#939599"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const RightArrow = () => {
  return (
    <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 18L9 9.5L0.999999 1"
        stroke="#939599"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AddToTruck = () => {
  return (
    <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="39" height="31" rx="3.5" stroke="#C4C4C4" />
      <path
        d="M19.4675 18.9599V17.7737H21.0927L20.7194 18.9599H19.4675ZM13.1206 17.7737H14.7897V18.9599H13.5269L13.1206 17.7737ZM14.7897 12.9289V14.1595H11.8797L11.4515 12.9289H14.7897ZM15.8768 12.9289H18.3804V14.1595H15.8768V12.9289ZM12.2641 15.257H14.7897V16.6761H12.7472L12.2641 15.257ZM18.3804 18.9599H15.8768V17.7737H18.3804V18.9599ZM15.8768 15.257H18.3804V16.6761H15.8768V15.257ZM21.4331 16.6761H19.4565V15.257H21.8833L21.4331 16.6761ZM22.2347 14.1706H19.4675V12.9289H22.63L22.2347 14.1706ZM12.6704 19.5253C12.7472 19.6694 12.8131 20.0575 13.1425 20.0575H21.1037C21.3343 20.0575 21.5539 19.9023 21.6198 19.6805L23.8818 12.5519C23.9368 12.3856 23.9038 12.2082 23.805 12.0641C23.7062 11.92 23.5414 11.8424 23.3657 11.8424H11.155C11.133 11.8424 11.1111 11.8424 11.0891 11.8424L10.584 10.379C10.5291 10.2238 10.4193 10.1018 10.2655 10.0464L7.73993 9.03751C7.46541 8.92665 7.14697 9.07077 7.03716 9.34793C6.92735 9.6251 7.0701 9.9466 7.34462 10.0575L9.65061 10.9666L12.5276 19.1262"
        fill="#C4C4C4"
      />
      <path
        d="M29.778 14.8363L28.3175 12.741C28.2626 12.6634 28.1748 12.6079 28.0759 12.6079H25.8029C25.6382 12.6079 25.5064 12.741 25.5064 12.9073V15.0026C25.5064 15.1689 25.6382 15.302 25.8029 15.302H29.5364C29.767 15.313 29.9097 15.0359 29.778 14.8363ZM32.8197 20.5348V21.4549C32.8197 21.6212 32.6879 21.7543 32.5232 21.7543H30.0964C29.9207 20.5569 28.8995 19.6257 27.6587 19.6257C26.4178 19.6257 25.3966 20.5569 25.2209 21.7543H20.3344C20.2466 21.1445 19.9391 20.6124 19.4999 20.2354H24.1338V11.5658C24.1338 11.2332 24.3973 10.9561 24.7377 10.9561H27.5708C28.3724 10.9561 29.1081 11.3552 29.5583 12.0204L31.3702 14.7587C31.6337 15.1578 31.7765 15.6345 31.7765 16.1113V20.2243H32.5012C32.6769 20.2354 32.8197 20.3685 32.8197 20.5348ZM28.581 22.1201C28.581 21.5991 28.1638 21.1778 27.6477 21.1778C27.1316 21.1778 26.7143 21.5991 26.7143 22.1201C26.7143 22.6412 27.1316 23.0625 27.6477 23.0625C28.1638 23.0625 28.581 22.6412 28.581 22.1201ZM29.5144 22.1201C29.5144 23.1623 28.6799 24.0048 27.6477 24.0048C26.6155 24.0048 25.7809 23.1623 25.7809 22.1201C25.7809 21.078 26.6155 20.2354 27.6477 20.2354C28.6799 20.2354 29.5144 21.078 29.5144 22.1201ZM16.2824 20.2354H12.0987C11.934 20.2354 11.8022 20.3685 11.8022 20.5348V21.4549C11.8022 21.6212 11.934 21.7543 12.0987 21.7543H15.4479C15.5357 21.1445 15.8432 20.6124 16.2824 20.2354ZM18.819 22.1201C18.819 21.5991 18.4018 21.1778 17.8857 21.1778C17.3696 21.1778 16.9523 21.5991 16.9523 22.1201C16.9523 22.6412 17.3696 23.0625 17.8857 23.0625C18.4018 23.0625 18.819 22.6412 18.819 22.1201ZM19.7524 22.1201C19.7524 23.1623 18.9179 24.0048 17.8857 24.0048C16.8535 24.0048 16.0189 23.1623 16.0189 22.1201C16.0189 21.078 16.8535 20.2354 17.8857 20.2354C18.9179 20.2354 19.7524 21.078 19.7524 22.1201Z"
        fill="#C4C4C4"
      />
    </svg>
  );
};
