import {useLocation} from 'react-router-dom';

export function isValidEmail(email: string) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(email);
}

export const useQuery = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
}

export const getFileExtension = (url) => {
  const regex = /(?:\.([^.]+))?$/;
  const match = regex.exec(url);
  if (match && match[1]) {
    return match[1].toLowerCase();
  }
  return null;
}