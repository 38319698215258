import React from 'react';
import Routes from 'routes';
import { useTranslation } from 'react-i18next';
import { StyledButtonLink, ViewCatalogue, Attention } from './styles';

const EmptyCheckout: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Attention>{t('checkout.no_products')}</Attention>

      <StyledButtonLink to={`${Routes.CATEGORIES}`}>
        <ViewCatalogue>{t('checkout.view_catalog')}</ViewCatalogue>
      </StyledButtonLink>
    </>
  );
};

export default EmptyCheckout;
