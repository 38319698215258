import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { InputLabel, Select, FormHelperText } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Arrow } from 'assets/svg/Landing/DropDownArrow';
import { createUserProfile, finishUserProfile } from 'ducks/user/actions';
import {
  selectCreateUserProfileResult,
  selectShowRecaptchaModalRegister,
  selectUserCreateError,
  selectUserErrors,
  selectUserLoading,
} from 'ducks/user/selectors';
import { selectBranches } from 'ducks/branch/selectors';
import { BranchClass } from 'models/classes/branchClass';
import { RecaptchaModalRegister } from '../RecaptchaModalRegister/RecaptchaModalRegister';

import {
  StylesDialog,
  StylesDialogContent,
  FirstScreenFormContainer,
  FirstScreenFormHeader,
  FirstScreenFormInputsContainer,
  firstNameOrder,
  lastNameOrder,
  emailOrder,
  companyOrder,
  branchOrder,
  salesOrder,
  StyledTextField,
  StyledFormControl,
  FirstScreenSubmitButton,
  ButtonWrapper,
  MenuItemCust,
} from './styles';

interface IModalRegisterModal {
  formheader: string;
  buttonLabel: string;
  firstnameLabel: string;
  lastnameLabel: string;
  emailLabel: string;
  companyLabel: string;
  salesLabel: string;
  branchLabel: string;
  firstnameValidateMin: string;
  firstnameValidateMax: string;
  firstnameRequired: string;
  lastnameValidateMin: string;
  lastnameValidateMax: string;
  lastnameRequired: string;
  companyValidateMin: string;
  companyValidateMax: string;
  companyRequired: string;
  salesValidateMin: string;
  salesValidateMax: string;
  salesRequired: string;
  emailInvalid: string;
  emailValidateMax: string;
  emailRequired: string;
  branchRequired: string;
  emailIsUsed?: string;
  open: boolean;
  callbackOnClose?: Function;
  closeModalRegister?: Function;
}

export const ModalRegister = ({
  formheader,
  buttonLabel,
  firstnameLabel,
  lastnameLabel,
  emailLabel,
  companyLabel,
  salesLabel,
  branchLabel,
  firstnameValidateMin,
  firstnameValidateMax,
  firstnameRequired,
  lastnameValidateMin,
  lastnameValidateMax,
  lastnameRequired,
  companyValidateMin,
  companyValidateMax,
  companyRequired,
  salesValidateMin,
  salesValidateMax,
  salesRequired,
  emailInvalid,
  emailValidateMax,
  emailRequired,
  branchRequired,
  emailIsUsed,
  open,
  callbackOnClose,
  closeModalRegister,
}: IModalRegisterModal) => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim(firstnameRequired)
      .min(2, firstnameValidateMin)
      .max(50, firstnameValidateMax)
      .required(firstnameRequired)
      .test('has-non-space', firstnameRequired, value => {
        return value?.trim().length >= 2;
      }),
    lastName: Yup.string()
      .trim(lastnameRequired)
      .min(2, lastnameValidateMin)
      .max(50, lastnameValidateMax)
      .required(lastnameRequired)
      .test('has-non-space', lastnameRequired, value => {
        return value?.trim().length >= 2;
      }),
    company: Yup.string()
      .trim(companyRequired)
      .min(2, companyValidateMin)
      .max(150, companyValidateMax)
      .required(companyRequired)
      .test('has-non-space', companyRequired, value => {
        return value?.trim().length >= 2;
      }),
    salesRepresentative: Yup.string()
      .trim(salesRequired)
      .min(2, salesValidateMin)
      .max(50, salesValidateMax)
      .required(salesRequired)
      .test('has-non-space', salesRequired, value => {
        return value?.trim().length >= 2;
      }),
    email: Yup.string()
      .email(emailInvalid)
      .max(255, emailValidateMax)
      .required(emailRequired)
      .test('has-non-space', emailRequired, value => {
        return value?.trim().length > 0;
      }),
    signupBranchId: Yup.number()
      .min(1)
      .required(branchRequired),
  });

  const dispatch = useDispatch();
  const isUserSuccess = useSelector(selectCreateUserProfileResult);
  const isServerError = useSelector(selectUserCreateError);
  const branches = useSelector(selectBranches);
  const serverErrorMessage = useSelector(selectUserErrors);
  const openRecaptchaModal = useSelector(selectShowRecaptchaModalRegister);
  const isLoading = useSelector(selectUserLoading);
  const validationError = 422;
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = (values, isForce = false) => {
    if (!isForce) {
      executeRecaptcha().then(token => {
        // eslint-disable-next-line no-param-reassign
        values.recaptchaToken = token;
        dispatch(createUserProfile(values));
      });
    } else {
      // eslint-disable-next-line no-param-reassign
      values.isForce = true;
      dispatch(createUserProfile(values));
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      signupBranchId: '',
      salesRepresentative: '',
      recaptchaToken: '',
      isForce: false,
    },
    validationSchema,
    onSubmit: values => {
      const payload = {
        ...values,
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        email: values.email.trim(),
        company: values.company.trim(),
      };
      handleSubmit(payload);
    },
  });

  useEffect(() => {
    if (isUserSuccess && !isLoading) {
      dispatch(finishUserProfile());
      formik.resetForm();
      closeModalRegister();
    }
  }, [dispatch, isLoading, isUserSuccess]);

  const handleClose = () => {
    callbackOnClose();
  };

  /**
   * ModalRegister: z-index: 1300;
   * HeaderWrapper: z-index: 1;
   */
  useEffect(() => {
    const element = document.querySelector('.main-header');
    element.classList.add('z-index-1');

    return () => {
      element.classList.remove('z-index-1');
    };
  }, []);

  return (
    <StylesDialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StylesDialogContent>
        <button type="button" className="btn-close" onClick={handleClose}>
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 1L11 11M1 21L11 11M11 11L1 1M11 11L21 21"
              stroke="currentColor"
              strokeWidth="2"
            />
          </svg>
        </button>

        <RecaptchaModalRegister
          open={openRecaptchaModal}
          handleSubmit={() => handleSubmit(formik.values, true)}
        />

        <FirstScreenFormContainer id="prospect-customer-registration">
          <FirstScreenFormHeader>{formheader}</FirstScreenFormHeader>
          <form onSubmit={formik.handleSubmit}>
            <FirstScreenFormInputsContainer>
              <StyledTextField
                style={firstNameOrder}
                variant="outlined"
                label={firstnameLabel}
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                error={!!(formik.errors.firstName && formik.touched.firstName)}
                helperText={
                  formik.errors.firstName && formik.touched.firstName ? formik.errors.firstName : ''
                }
              />
              <StyledTextField
                style={lastNameOrder}
                variant="outlined"
                label={lastnameLabel}
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={!!(formik.errors.lastName && formik.touched.lastName)}
                helperText={
                  formik.errors.lastName && formik.touched.lastName ? formik.errors.lastName : ''
                }
              />
              <StyledTextField
                style={emailOrder}
                variant="outlined"
                label={emailLabel}
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={!!((formik.errors.email && formik.touched.email) || isServerError)}
                helperText={
                  isServerError &&
                  serverErrorMessage?.code === validationError &&
                  formik.values.email
                    ? emailIsUsed
                    : formik.errors.email && formik.touched.email && formik.errors.email
                }
              />
              <StyledTextField
                style={companyOrder}
                variant="outlined"
                label={companyLabel}
                name="company"
                value={formik.values.company}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={!!(formik.errors.company && formik.touched.company)}
                helperText={
                  formik.errors.company && formik.touched.company ? formik.errors.company : ''
                }
              />
              <StyledFormControl variant="outlined" style={branchOrder}>
                <InputLabel id="taiga-branch-label">{branchLabel}</InputLabel>
                <Select
                  labelId="taiga-branch-label"
                  id="taiga-branch"
                  name="signupBranchId"
                  label="Taiga Branch"
                  value={formik.values.signupBranchId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!(formik.errors.signupBranchId && formik.touched.signupBranchId)}
                  IconComponent={Arrow}
                >
                  {branches.length > 0 &&
                    branches.map((branch: BranchClass) => (
                      <MenuItemCust key={branch.id} value={branch.id}>
                        {branch.erpName}
                      </MenuItemCust>
                    ))}
                </Select>
                <FormHelperText error focused>
                  {formik.errors.signupBranchId && formik.touched.signupBranchId
                    ? formik.errors.signupBranchId
                    : null}
                </FormHelperText>
              </StyledFormControl>
              <StyledTextField
                style={salesOrder}
                variant="outlined"
                label={salesLabel}
                name="salesRepresentative"
                value={formik.values.salesRepresentative}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={!!(formik.errors.salesRepresentative && formik.touched.salesRepresentative)}
                helperText={
                  formik.errors.salesRepresentative && formik.touched.salesRepresentative
                    ? formik.errors.salesRepresentative
                    : ''
                }
              />
            </FirstScreenFormInputsContainer>

            <ButtonWrapper>
              <FirstScreenSubmitButton type="submit" disabled={isLoading}>
                {buttonLabel}
              </FirstScreenSubmitButton>
            </ButtonWrapper>
          </form>
        </FirstScreenFormContainer>
      </StylesDialogContent>
    </StylesDialog>
  );
};
