// Classes
import { FAQClass } from 'models/classes/faqClass';
import { IErrors } from 'models/interfaces/error';

export enum FAQActionTypes {
  REQUEST_FAQ = '@@faq/REQUEST_FAQ',
  REQUEST_FAQ_SUCCESS = '@@faq/REQUEST_FAQ_SUCCESS',
  REQUEST_FAQ_ERROR = '@@faq/REQUEST_FAQ_ERROR',
}

export interface FAQState {
  readonly FAQ: FAQClass[];
  readonly loading: boolean;
  readonly error: IErrors | null;
	readonly isFetchFAQ: boolean;
}
