import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { LANDING_IMAGES_URL } from './constants';

const MainContainer = styled.div`
  width: 100%;
  min-width: 320px;
  margin: 0 auto;
  font-family: Open Sans;
  background-color: ${({ theme: { colors } }) => colors.white};
`;

const FirstSection = styled.div`
  height: 566px;
  padding-top: 99px;
  display: flex;
  justify-content: center;

  @media (max-width: 1280px) {
    padding-top: 0px;
    height: 498px;
  }

  @media (max-width: 990px) {
    height: 420px;
  }

  @media (max-width: 910px) {
    height: auto;
  }
`;

const SecondSection = styled.div`
  height: 697px;
  background-image: url(${LANDING_IMAGES_URL}/2nd-beach.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;

  @media (max-width: 1280px) {
    height: 620px;
  }

  @media (max-width: 810px) {
    max-height: 500px;
  }

  @media (max-width: 720px) {
    height: 360px;
  }

  @media (max-width: 480px) {
    background-position-y: 10px;
    height: 263px;
  }
`;

const ShadowTopBackground = styled.div`
  height: 94px;
  width: 100%;
  background: linear-gradient(
    180deg,
    ${({ theme: { colors } }) => colors.white} 0%,
    ${({ theme: { colors } }) => colors.invisible} 100%
  );

  @media (max-width: 1280px) {
    height: 80px;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

const ShadowBottomBackground = styled.div`
  height: 94px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    180deg,
    ${({ theme: { colors } }) => colors.white} 0%,
    ${({ theme: { colors } }) => colors.invisible} 100%
  );
  transform: rotate(-180deg);
  z-index: 1;

  @media (max-width: 1280px) {
    height: 80px;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

const WhiteSquare = styled.div`
  background: ${({ theme: { colors } }) => colors.white};
  width: 1000px;
  padding: 81px 0;
  border-radius: 4px;
  box-shadow: 0px 0px 20px ${({ theme: { colors } }) => colors.blackOpacity(0.15)};
  position: relative;
  z-index: 1;
  margin-bottom: -112px;

  @media (max-width: 1280px) {
    max-width: 880px;
    margin-bottom: -80px;
  }

  @media (max-width: 990px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 910px) {
    max-width: 810px;
  }

  @media (max-width: 810px) {
    padding: 40px 20px;
    max-width: 720px;
  }

  @media (max-width: 720px) {
    margin-top: 40px;
    margin-bottom: -10px;
  }

  @media (max-width: 460px) {
    padding: 40px 0px;
  }
`;

const MainTextContainer = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.01em;
  padding: 0 40px;
  color: ${({ theme: { colors } }) => colors.mainGreen};

  h3 {
    padding: 0;
    margin: 0;
    font-size: 24px;
    max-width: 357px;
  }

  @media (max-width: 1280px) {
    padding: 0 0 2px;
    max-width: 288px;
    margin-top: 102px;

    h3 {
      line-height: 120%;
    }
  }

  @media (max-width: 990px) {
    margin: auto;
    max-width: 320px;
  }

  @media (max-width: 810px) {
    max-width: 600px;

    h3 {
      max-width: 600px;
      text-align: center;
    }
  }

  @media (max-width: 684px) {
    padding: 0 20px;
  }
  @media (max-width: 480px) {
    padding: 0 10px;

    h3 {
      margin: 0 10px;
    }
  }
`;

const SubTextContainer = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.large};
  line-height: 25px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.black};
  padding-top: 40px;
  max-width: 380px;

  @media (max-width: 1280px) {
    padding-top: 18px;
  }

  @media (max-width: 810px) {
    max-width: 600px;
  }

  @media (max-width: 680px) {
    font-size: 16px;
    padding-top: 26px;
    line-height: 22px;
  }
`;

const SideImage = styled.img`
  margin-left: -120px;
  width: 600px;

  @media (max-width: 1280px) {
    margin-left: -52px;
  }

  @media (max-width: 990px) {
    margin-left: 0px;
    max-width: 510px;
  }

  @media (max-width: 910px) {
    max-width: 440px;
  }

  @media (max-width: 610px) {
    max-width: 400px;
    margin-top: 20px;
  }

  @media (max-width: 550px) {
    max-width: 350px;
  }

  @media (max-width: 480px) {
    max-width: 320px;
    height: 207px;
  }
`;

const SquareFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1280px) {
    align-items: flex-start;
  }

  @media (max-width: 810px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const BottomBlockTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 120%;
  max-width: 810px;
  margin-top: 145px;
  text-align: right;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: ${({ theme: { colors } }) => colors.white};
  text-shadow: 0px 0px 20px ${({ theme: { colors } }) => colors.blackOpacity(0.55)};

  @media (max-width: 1280px) {
    margin-top: 140px;
  }

  @media (max-width: 810px) {
    margin: auto 0;
    font-size: 38px;
  }

  @media (max-width: 720px) {
    max-width: 650px;
    padding: 0 20px;
  }

  @media (max-width: 685px) {
    font-size: 32px;
    padding: 0 10px;
  }

  @media (max-width: 480px) {
    font-size: 26px;
  }
`;

const BottomBlock = styled.div`
  width: 1000px;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;

  @media (max-width: 1280px) {
    max-width: 880px;
  }

  @media (max-width: 1191px) {
    height: 370px;
  }

  @media (max-width: 910px) {
    max-width: 790px;
  }

  @media (max-width: 810px) {
    max-width: 720px;
    height: 100%;
  }

  @media (max-width: 720px) {
    width: auto;
  }

  @media (max-width: 480px) {
    height: 278px;
  }
`;

const useStyles = makeStyles({
  frContainer: {
    '@media (max-width: 370px)': {
      height: '505px !important',
    },
  },
  frBottomTitle: {
    '@media (max-width: 480px)': {
      fontSize: '20px !important',
    },
  },
});

interface IBlockProps {
  squareTitle?: string;
  squareText?: string;
  bottomTitle?: string;
  isEnglish?: boolean;
}

const PartScreenTemplate: React.FC<IBlockProps> = ({
  squareTitle,
  squareText,
  bottomTitle,
  isEnglish,
}: IBlockProps) => {
  const squareImage = isEnglish
    ? `${LANDING_IMAGES_URL}/2nd-checkout.png`
    : `${LANDING_IMAGES_URL}/2nd-screen-french.png`;
  const classes = useStyles();

  return (
    <MainContainer>
      <FirstSection className={!isEnglish ? classes.frContainer : ''}>
        <WhiteSquare>
          <SquareFlex>
            <SideImage alt="" src={squareImage} />
            <MainTextContainer>
              <h3>{squareTitle}</h3>
              <SubTextContainer>{squareText}</SubTextContainer>
            </MainTextContainer>
          </SquareFlex>
        </WhiteSquare>
      </FirstSection>
      <SecondSection>
        <ShadowTopBackground />
        <BottomBlock>
          <BottomBlockTitle className={!isEnglish ? classes.frBottomTitle : ''}>
            {bottomTitle}
          </BottomBlockTitle>
        </BottomBlock>
        <ShadowBottomBackground />
      </SecondSection>
    </MainContainer>
  );
};

export default PartScreenTemplate;
