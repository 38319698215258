import { IOrder } from 'models/interfaces/order';
import { IError } from 'models/interfaces/error';

export enum OrderActionTypes {
  CHANGE_ORDER = '@@order/REQUEST_CHANGE_ORDER',
  CHANGE_ORDER_ERROR = '@@order/REQUEST_CHANGE_ORDER_ERROR',

  START_LOADING = '@@order/START_LOADING',
  FINISH_LOADING = '@@order/FINISH_LOADING',

  SUBMIT_ORDER = '@@order/SUBMIT_ORDER',
  SUBMIT_ORDER_SUCCESS = '@@order/SUBMIT_ORDER_SUCCESS',
	SUBMIT_ORDER_ERROR = '@@order/SUBMIT_ORDER_ERROR',
	SET_SUBMIT_ORDER_ERROR = '@@order/SET_SUBMIT_ORDER_ERROR',

  SET_SUBMIT_ORDER_STATUS = '@@order/SET_SUBMIT_ORDER_STATUS',
	SET_REORDER_STATUS = '@@order/SET_REORDER_STATUS',

	CHANGE_CART_DELIVERY_TYPE = '@@cart/REQUEST_CHANGE_CART_DELIVERY_TYPE',
	CHANGE_CART_DELIVERY_TYPE_ERROR = '@@cart/CHANGE_CART_DELIVERY_TYPE_ERROR',
	CHANGE_CART_DELIVERY_TYPE_SUCCESS = '@@cart/CHANGE_CART_DELIVERY_TYPE_SUCCESS',

  SET_OPEN_INQUIRE_NOW_ORDER_HISTORY = '@@order/SET_OPEN_INQUIRE_NOW_ORDER_HISTORY',
  SET_INQUIRE_NOW_ORDER_HISTORY = '@@order/SET_INQUIRE_NOW_ORDER_HISTORY',
  SET_EMAIL_INQUIRE_NOW_ORDER_HISTORY_SENT = '@@order/SET_EMAIL_INQUIRE_NOW_ORDER_HISTORY_SENT',
}
export interface OrderState {
  loading: boolean;
  error: IError | null;
	submitOrderError: boolean | null;
  changeCartDeliveryLoading: boolean;
  changeCartDeliveryStatus: string;
	orders: IOrder[];
	submitOrderSuccess: Boolean;
	reorderSuccess: Boolean;
  inquireNowOrderHistory: {
    type: string;
    orderNumber: string;
    dateRequired: string;
    orderType: string;
  };
  openInquireNowOrderHistory: boolean;
  emailInquireNowOrderHistorySent: boolean;
}

export interface SubmitOrderAction {
  type: OrderActionTypes.SUBMIT_ORDER;
  payload: {
    orderId: string;
    updatedOrder: IOrder;
  };
}

export interface UpdateCartAction {
  type: OrderActionTypes.CHANGE_ORDER;
  payload: {
    cartId: number;
    updatedOrder: IOrder;
  };
}

export interface IChangeCartDeliveryAction {
	type: OrderActionTypes.CHANGE_CART_DELIVERY_TYPE,
	payload: {
		cartId: number;
		deliveryType: string;
	}
}
