import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomArrowProps } from 'react-slick';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  LeftArrow,
  RightArrow,
} from 'assets/svg/FeaturedProducts/FeaturedProducts';
import { selectFeaturedProducts } from 'ducks/productVariant/selectors';
import { resetFeaturedProducts } from 'ducks/productVariant/actions';
import { menuIsOpenedSelector, selectAddressId, selectErpCustomerAddressCode } from 'ducks/application/selectors';
import { ProductVariantClass } from 'models/classes/productVariantClass';
import ProductTableHoveredCard from 'pages/Product/ProductTableView/ProductTableHoveredCard/ProductTableHoveredCard';
import { AssociationVariantClass } from 'models/classes/associationClass';
import CarouselProductCard from 'components/common/ProductCarousel/components/CarouselProductCard';
import { selectBranch } from 'ducks/branch/selectors';
import get from 'lodash/get';
import {NearbyBranchEmailType} from 'ducks/productVariant/types';
import {NearbyBranchContext} from 'context/NearbyBranchContext';
import {usePermission} from 'hooks/usePermission';
import {setClickedProductRequestNearbyHubsAndBranch, setOpenPopupInquireNow} from 'ducks/product/actions';
import TagManager from 'react-gtm-module';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { selectUserData } from 'ducks/user/selectors';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import {
  CarouselHeader,
  ProductStock,
  SliderWrapper,
  SpecialOrder,
  StyledSlider,
  ListWrapper,
} from './styles';
import { createUserActionLog } from '../../../api/UserActionLog';

const SampleNextArrow: React.FC<CustomArrowProps> = ({ onClick, className }: CustomArrowProps) => {
  return (
    <div className={className} onClick={onClick} aria-hidden="true" style={{ top: '25%' }}>
      <RightArrow />
    </div>
  );
};

const SamplePrevArrow: React.FC<CustomArrowProps> = ({ onClick, className }: CustomArrowProps) => {
  return (
    <div className={className} onClick={onClick} style={{ top: '25%' }} aria-hidden="true">
      <LeftArrow />
    </div>
  );
};

interface IProductCarouselProps {
  similarProducts?: AssociationVariantClass[];
  alsoLikeProducts?: AssociationVariantClass[];
  isFeatured?: boolean;
  categoryName?: string;
  categoryCode?: string;
}

const ProductCarousel: React.FC<IProductCarouselProps> = ({
  similarProducts,
  alsoLikeProducts,
  isFeatured,
  categoryName,
  categoryCode,
}: IProductCarouselProps) => {
  const dispatch = useDispatch();
  const {canSeeInventory} = usePermission();
  const {setEmailType} = React.useContext(NearbyBranchContext);
  const products = useSelector(selectFeaturedProducts);
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const isOpened = useSelector(menuIsOpenedSelector);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const user = useSelector(selectUserData);
  const { t } = useTranslation();
  const [hoveredTableView, setHoveredTableView] = useState(0);
  const handleChangeTableView = () => {
    setHoveredTableView(0);
  };

  useEffect(() => {
    return () => {
      dispatch(resetFeaturedProducts());
    }
  }, [dispatch, categoryCode]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    draggable: false,
    swipe: true,
    swipeToSlide: true,
    focusOnSelect: false,
    adaptiveHeight: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,
          focusOnSelect: true,
          adaptiveHeight: true,
          arrows: true,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          focusOnSelect: true,
          adaptiveHeight: true,
          arrows: true,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        },
      },
      {
        breakpoint: 800,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          focusOnSelect: true,
          adaptiveHeight: true,
          arrows: true,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        },
      },
      {
        breakpoint: 599,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          draggable: true,
          swipeToSlide: true,
          focusOnSelect: true,
          adaptiveHeight: false,
        },
      },
    ],
  };

  const handleClickProductStock = useCallback((variant: ProductVariantClass) => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_inventory_request',
        user_type: getUserType(user?.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      }
    });
    const status = get(variant, 'inventory.status', {});
    if (status.inStock) {
      setEmailType(NearbyBranchEmailType.HOME_BRANCH_WITH_INVENTORY_IN_STOCK);
    } else if (status.waitingOnStock) {
      setEmailType(NearbyBranchEmailType.HOME_BRANCH_WAITING_ON_STOCK);
    } else if (status.specialOrderString) {
      setEmailType(NearbyBranchEmailType.HOME_BRANCH_WITH_SPECIAL_ORDER_STRING);
    }
    dispatch(setClickedProductRequestNearbyHubsAndBranch(variant));
    dispatch(setOpenPopupInquireNow(true));
  }, [dispatch, setEmailType, user, branch, erpCustomerAddressCode, selectedAddressId]);

  const onClickProductStock = async (variant: ProductVariantClass) => {
    await createUserActionLog('Featured Products Inventory Status', 'Inventory Request');
    handleClickProductStock(variant);
  }

  const renderProductStock = (variant: ProductVariantClass) => {
    const status = get(variant, 'inventory.status', {});

    if (status.status === t('products_and_search.waiting_on_stock')) {
      return (
        <ProductStock className="waiting-on-stock" onClick={() => onClickProductStock(variant)}>
          {canSeeInventory ? status.status : status.origin}
        </ProductStock>
      );
    }

    if (get(status, 'inStock')) {
      return (
        <ProductStock onClick={() => onClickProductStock(variant)}>
          {canSeeInventory ? status.status : status.origin}
        </ProductStock>
      );
    }

    return <SpecialOrder onClick={() => onClickProductStock(variant)}>{canSeeInventory ? status.status : status.origin}</SpecialOrder>;
  };

  const renderProductCard = (
    productVar: ProductVariantClass,
  ) =>
    hoveredTableView !== productVar.id || !productVar.prices.length ? (
      <CarouselProductCard
        key={productVar.code}
        categoryCode={categoryCode}
        categoryName={categoryName}
        isMobile={isMobile}
        productVar={productVar}
        renderProductStock={renderProductStock}
        handleClickProductStock={handleClickProductStock}
        setHoveredTableView={setHoveredTableView}
        productType='featured'
        products={products}
      />
    ) : (
      <ProductTableHoveredCard
        key={productVar.code}
        productVar={productVar}
        categoryName={categoryName}
        renderProductStock={renderProductStock}
        handleClickProductStock={handleClickProductStock}
        handleChangeTableView={handleChangeTableView}
        listName={
          isFeatured
            ? t('products_carousel.featured_products')
            : t('products_carousel.accosiated_products')
        }
      />
    );

  const renderSlider = (productArray: Array<ProductVariantClass>) =>
    productArray.length > 5 || isMobile ? (
      <SliderWrapper className="slider-wrapper">
        <StyledSlider {...settings}>
          {productArray && productArray.map(productVar => renderProductCard(productVar))}
        </StyledSlider>
      </SliderWrapper>
    ) : (
      <ListWrapper className="list-wrapper">
        {productArray && productArray.map(productVar => renderProductCard(productVar))}
      </ListWrapper>
    );

  const renderComponent = () => {
    if (products.length > 0) {
      return renderSlider(products);
    }
    return null;
  };

  const renderHeader = () => {
    if (similarProducts) {
      return <CarouselHeader>{t('products_carousel.similar_products').toString()}</CarouselHeader>;
    }

    if (products.length > 0) {
      return <CarouselHeader>{t('products_carousel.featured_products').toString()}</CarouselHeader>;
    }

    if (alsoLikeProducts) {
      return <CarouselHeader>{t('products_carousel.may_like').toString()}</CarouselHeader>;
    }

    return null;
  };

  return !isOpened ? (
    <>
      {(products?.length > 0 && isFeatured) ? (
        <>
          {renderHeader()}
          {renderComponent()}
        </>
      ) : null}
    </>
  ) : null;
};

export default ProductCarousel;
