import React, { useRef } from 'react';
import { PopperPlacementType } from '@material-ui/core';

import { ListViewMinus, ListViewPlus } from 'assets/svg/Product/Products';
import ProductInputPopper from 'components/Popper/ProductInputPopper';
import { numberFormat as f } from 'utils/products/product';
import TagManager from 'react-gtm-module';

import { useSelector } from 'react-redux';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectAddressId, selectErpCustomerAddressCode } from 'ducks/application/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import {
  IncrementButton,
  StyledTextField,
  StyledInputWrapper,
  InputWithLabelWrapper,
  InputButtonsWrapper,
  IncrementButtonLabel,
} from './styles';

interface IProductInputs {
  product: {
    minQuantity: number;
    uom2Conversion: number;
    perName: string;
    uom2Name: string;
  };
  topInputValue: number;
  bottomInputValue: number | null;
  popperPlacement?: PopperPlacementType;

  onInputChange({
    topInputValue,
    bottomInputValue,
  }: {
    topInputValue: number;
    bottomInputValue: number;
  }): void;

  validationMessageTop: string | null;
  validationMessageBottom: string | null;
}

export const ProductInputFields = React.memo(
  ({
    product: { minQuantity, uom2Conversion, perName, uom2Name },
    topInputValue = 0,
    bottomInputValue = null,
    popperPlacement = 'left',
    onInputChange,
    validationMessageTop,
    validationMessageBottom,
  }: IProductInputs) => {
    const user = useSelector(selectUserData);
    const branch = useSelector(selectBranch);
    const selectedAddressId = useSelector(selectAddressId);
    const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
    const timeClickGtm = useRef(0);
    const topRef = useRef();
    const bottomRef = useRef();
    const buttonTopRef = useRef();
    const buttonBottomRef = useRef();
    const isMultiInput = Boolean(uom2Conversion && uom2Name);

    const productCoeff = parseFloat(f(minQuantity / uom2Conversion));

    const handleGtmEventChangeUOM = () => {
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'change_UOM',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
      timeClickGtm.current = Date.now() / 1000;
    };

    const handleIncrement = () => {
      const newTopValue = topInputValue - (topInputValue % minQuantity) + minQuantity;
      let newBottomValue = null;

      if (isMultiInput) {
        newBottomValue = parseFloat(f((newTopValue * productCoeff) / minQuantity));
      }

      onInputChange({ topInputValue: newTopValue, bottomInputValue: newBottomValue });
      if (timeClickGtm.current === 0) {
        handleGtmEventChangeUOM();
      } else if (timeClickGtm.current > 0 && Date.now() / 1000 - timeClickGtm.current > 10) {
        handleGtmEventChangeUOM();
      }
    };

    const handleDecrement = () => {
      let newTopValue = topInputValue - (topInputValue % minQuantity) - minQuantity;
      newTopValue = newTopValue <= 0 ? minQuantity : newTopValue;

      let newBottomValue = null;

      if (isMultiInput) {
        newBottomValue = parseFloat(f((newTopValue * productCoeff) / minQuantity));
      }

      onInputChange({ topInputValue: newTopValue, bottomInputValue: newBottomValue });
      if (timeClickGtm.current === 0) {
        handleGtmEventChangeUOM();
      } else if (timeClickGtm.current > 0 && Date.now() / 1000 - timeClickGtm.current > 10) {
        handleGtmEventChangeUOM();
      }
    };

    const handleTopFieldChange = (value: number) => {
      let newBottomValue = null;
      if (isMultiInput) {
        newBottomValue = parseFloat(f((value * productCoeff) / minQuantity));
      }

      onInputChange({ topInputValue: value, bottomInputValue: newBottomValue });
      if (timeClickGtm.current === 0) {
        handleGtmEventChangeUOM();
      } else if (timeClickGtm.current > 0 && Date.now() / 1000 - timeClickGtm.current > 10) {
        handleGtmEventChangeUOM();
      }
    };

    const handleBottomFieldChange = e => {
      const newBottomValue: number = parseFloat(e.target.value);
      const newTopValue = parseFloat(f((newBottomValue / productCoeff) * minQuantity));

      onInputChange({ topInputValue: newTopValue, bottomInputValue: newBottomValue });
    };

    return (
      <InputButtonsWrapper>
        <InputWithLabelWrapper
          className={`${
            (validationMessageTop && popperPlacement === 'left') ||
            (validationMessageTop && popperPlacement === 'right')
              ? 'has-error'
              : ''
          }`}
        >
          <StyledInputWrapper>
            {validationMessageTop && popperPlacement === 'left' ? (
              <ProductInputPopper
                element={buttonTopRef.current}
                message={validationMessageTop}
                placement={popperPlacement}
              />
            ) : null}
            <IncrementButton aria-label="minus" ref={buttonTopRef} onClick={handleDecrement}>
              <ListViewMinus />
            </IncrementButton>
            <StyledTextField
              type="number"
              InputProps={{ disableUnderline: true }}
              onChange={e => handleTopFieldChange(parseInt(e.target.value, 10))}
              value={topInputValue || ''}
            />
            <IncrementButton aria-label="plus" onClick={handleIncrement}>
              <ListViewPlus />
            </IncrementButton>
          </StyledInputWrapper>
          <IncrementButtonLabel ref={topRef}>{perName}</IncrementButtonLabel>
          {validationMessageTop && popperPlacement === 'right' ? (
            <ProductInputPopper
              element={topRef.current}
              message={validationMessageTop}
              placement={
                validationMessageTop && validationMessageBottom ? 'top-end' : popperPlacement
              }
            />
          ) : null}
        </InputWithLabelWrapper>

        {isMultiInput && bottomInputValue ? (
          <InputWithLabelWrapper
            className={`${validationMessageBottom
              ? 'has-error'
              : ''
            }`}
          >
            <StyledInputWrapper>
              <IncrementButton aria-label="minus" ref={buttonBottomRef} onClick={handleDecrement}>
                <ListViewMinus />
              </IncrementButton>
              <StyledTextField
                type="number"
                InputProps={{ disableUnderline: true }}
                onChange={handleBottomFieldChange}
                value={parseFloat(f(bottomInputValue)) || ''}
              />
              <IncrementButton aria-label="plus" onClick={handleIncrement}>
                <ListViewPlus />
              </IncrementButton>
            </StyledInputWrapper>
            <IncrementButtonLabel ref={bottomRef}>{uom2Name}</IncrementButtonLabel>
            {validationMessageBottom ? (
              <ProductInputPopper
                element={bottomRef.current}
                message={validationMessageBottom}
                placement='right'
              />
            ) : null}
          </InputWithLabelWrapper>
        ) : null}
      </InputButtonsWrapper>
    );
  }
);
