import { Reducer } from 'redux';
import {
  SalesRepresentativeActionTypes,
  SalesRepresentativeState,
} from 'ducks/salesRepresentative/types';

const initialState: SalesRepresentativeState = {
  loading: true,
  error: null,
  salesRepresentative: null,
};

const salesRepresentativeReducer: Reducer<SalesRepresentativeState> = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case SalesRepresentativeActionTypes.REQUEST_SALES_REPRESENTATIVE: {
      return { ...state, loading: true };
    }
    case SalesRepresentativeActionTypes.REQUEST_SALES_REPRESENTATIVE_SUCCESS: {
      return {
        ...state,
        loading: false,
        salesRepresentative: payload.salesRepresentative,
      };
    }
    case SalesRepresentativeActionTypes.REQUEST_SALES_REPRESENTATIVE_ERROR: {
      return { ...state, loading: false, error: payload.error };
    }
    default:
      return state;
  }
};

export default salesRepresentativeReducer;
