import styled from 'styled-components';
import { Grid } from '@material-ui/core';

interface IProps {
  variant: 'warning' | 'general';
}

const StyledGrid = styled(Grid)<IProps>`
  ${({ variant, theme: { colors } }) =>
    variant === 'warning' && ` background: ${colors.redOpacity(0.1)}; `}
  ${({ variant, theme: { colors } }) =>
    variant === 'general' && ` background: ${colors.yellowBackground}; `}
`;

const MenuContentGrid = styled(Grid)<IProps>`
  ${({ variant, theme: { colors } }) => variant === 'warning' && `color: ${colors.red};  `}
  ${({ variant, theme: { colors } }) =>
    variant === 'general' && `color: ${colors.yellow}; `}

  font-family: Open Sans, sans-serif;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  padding: 12px 0;
  @media (min-width: 1201px) {
    width: 1200px;
    margin: 0 auto;
  }
  @media (max-width: 1200px) {
    padding: 12px 10px;
  }
`;

export { StyledGrid, MenuContentGrid };
