import React, { createRef, RefObject, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import {
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Link,
  Typography,
} from '@material-ui/core';

import { ExpandIcon } from 'assets/svg/FAQ/FAQ';
import { GridComponent, ListComponent, ListItemComponent } from 'components/FAQPage';
import { BreadCrumbs } from 'components/common';
import { fetchFAQ } from 'ducks/FAQ/actions';
import { selectFAQ, selectIsFetchFAQ } from 'ducks/FAQ/selectors';
import { menuIsOpenedSelector } from 'ducks/application/selectors';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled(Container)`
  max-width: 1230px;
  margin: 0 auto;
  padding: 10px 15px 48px;
  min-height: 100vh;
`;

const StyledBreadCrumbsContainer = styled(Container)`
  margin-top: 38px;
  margin-bottom: 12px;
  padding: 0;

  @media (max-width: 767px) {
    margin-top: 0px;
    margin-bottom: 20px;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  font-family: Open Sans;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.large};
  line-height: 25px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.mainGreen};

  @media (max-width: 767px) {
    font-size: ${({ theme: { fontSizes } }) => fontSizes.medium};
    line-height: 22px;
  }
`;

const StyledH1 = styled.h1`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  letter-spacing: 0.1px;
  margin: 0 0 24px;
`;

const StyledH2 = styled.h2`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 150% */
  letter-spacing: 0.1px;
  text-transform: capitalize;
  margin: 0 0 24px;
  @media (max-width: 767px) {
    margin: 0 0 18px;
  }
`;

const StyledQuestion = styled(Typography)`
  font-family: Open Sans;
  font-weight: 600;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.large};
  line-height: 25px;
  letter-spacing: 0.01em;

  @media (max-width: 767px) {
    font-size: ${({ theme: { fontSizes } }) => fontSizes.small};
    line-height: 19px;
  }
`;

const StyledAnswer = styled(Typography)`
  font-family: Open Sans;
  font-weight: 400;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.medium};
  line-height: 22px;
  letter-spacing: 0.01em;

  @media (max-width: 767px) {
    font-size: ${({ theme: { fontSizes } }) => fontSizes.small};
    line-height: 21px;
  }
`;

const StyledExpansionPanel = styled(ExpansionPanel)`
  background-color: ${({ expanded, theme: { colors } }) => expanded && colors.lightGrey};
  box-shadow: none;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.borderGrey};

  &.Mui-expanded {
    margin: 0;
  }

  &:before {
    display: none;
  }

  ${StyledQuestion} {
    color: ${({ expanded, theme: { colors } }) => !expanded && colors.mainGreen};
  }
`;

const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)`
  background-color: ${({ theme: { colors } }) => colors.white};
  padding: 16px 24px 24px;

  @media (max-width: 767px) {
    padding: 12px 0;
  }
`;

const StyledExpansionPanelSummary = styled(ExpansionPanelSummary)`
  @media (max-width: 767px) {
    padding: 0;
  }

  div:first-child {
    margin: 20px 0 20px;

    @media (max-width: 767px) {
      margin: 12px 0 12px;
    }
  }
`;

const FAQ: React.FC = () => {
  const [expanded, setExpanded] = React.useState<number | undefined>(undefined);
  const dispatch = useDispatch();
  const faqSections = useSelector(selectFAQ);
  const isFetchFAQ = useSelector(selectIsFetchFAQ);
  const isOpened = useSelector(menuIsOpenedSelector);

  const { t } = useTranslation();

  useEffect(() => {
    if (!isFetchFAQ) {
      dispatch(fetchFAQ());
    }
  }, [dispatch, isFetchFAQ]);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : undefined);
  };

  const refs: RefObject<HTMLHeadingElement>[] = Array.from({ length: faqSections.length }).map(() =>
    createRef()
  );

  const handleClick = (i: number) => {
    refs[i].current.scrollIntoView({ behavior: 'smooth' });
  };

  const renderQuestions = () =>
    faqSections &&
    faqSections.map((faq, i) => (
      <GridComponent key={faq.id}>
        <StyledH2 id={`${faq.name}`} ref={refs[i]}>
          {faq.name}
        </StyledH2>
        {faq.questions.length> 0 && faq.questions
          .sort((a, b) => a.position - b.position)
          .map(singleQuestion => {
            const { question, answer, id } = singleQuestion;

            return (
              <StyledExpansionPanel expanded={expanded === id} onChange={handleChange(id)} key={id}>
                <StyledExpansionPanelSummary expandIcon={<ExpandIcon />}>
                  <StyledQuestion>{question}</StyledQuestion>
                </StyledExpansionPanelSummary>
                <StyledExpansionPanelDetails>
                  <StyledAnswer>{answer}</StyledAnswer>
                </StyledExpansionPanelDetails>
              </StyledExpansionPanel>
            );
          })}
      </GridComponent>
    ));

  return !isOpened ? (
    <StyledContainer>
      <StyledBreadCrumbsContainer>
        <BreadCrumbs currentPageName="FAQ" />
      </StyledBreadCrumbsContainer>
      <StyledH1>{t('faq.title')}</StyledH1>
      <ListComponent>
        {faqSections &&
          faqSections.map((faq, i) => (
            <ListItemComponent key={faq.id}>
              <StyledLink onClick={() => handleClick(i)}>{faq.name}</StyledLink>
            </ListItemComponent>
          ))}
      </ListComponent>
      {renderQuestions()}
    </StyledContainer>
  ) : null;
};

export default FAQ;
