import { createSelector } from 'reselect';
import { RootState } from 'store';

export const selectOrderState = (state: RootState) => state.order;

export const selectOrderLoading = createSelector(
  selectOrderState,
  orderState => orderState.loading,
);

export const selectSubmitOrderError = createSelector(
  selectOrderState,
  orderState => orderState.submitOrderError,
);

export const selectOrderError = createSelector(selectOrderState, orderState => orderState.error);

export const selectChangeCartDeliveryLoading = createSelector(selectOrderState, orderState => orderState.changeCartDeliveryLoading);
export const selectChangeCartDeliveryStatus = createSelector(selectOrderState, orderState => orderState.changeCartDeliveryStatus);
export const selectOrders = createSelector(selectOrderState, orderState => orderState.orders);
export const selectSubmitOrderStatus = createSelector(selectOrderState, orderState => orderState.submitOrderSuccess);
export const selectReorderStatus = createSelector(selectOrderState, orderState => orderState.reorderSuccess);
export const selectInquireNowOrderHistory = createSelector(selectOrderState, orderState => orderState.inquireNowOrderHistory);
export const selectOpenInquireNowOrderHistory = createSelector(selectOrderState, orderState => orderState.openInquireNowOrderHistory);

export const selectEmailInquireNowOrderHistorySent = createSelector(selectOrderState, orderState => orderState.emailInquireNowOrderHistorySent);