import {createSelector} from 'reselect';
import {RootState} from 'store';

const selectProductState = (state: RootState) => state.products;

export const selectProductsError = createSelector(
  selectProductState,
  productState => productState.error
);

export const selectProduct = createSelector(
  selectProductState,
  productState => productState.product
);

export const selectOpenPopupInquireNow = createSelector(
  selectProductState,
  productState => productState.isOpenPopupInquire
);

export const selectClickProductRequestNearbyHubsAndBranch = createSelector(
  selectProductState,
  productState => productState.clickedProductRequestNearbyHubsAndBranch
)

export const selectOpenPopupNearbyBranch = createSelector(
  selectProductState,
  productState => productState.isOpenPopupNearbyBranch
)

export const selectNearbyBranchData = createSelector(
  selectProductState,
  productState => productState.nearbyBranchData
)

export const selectClickedProductRequestPricing = createSelector(
  selectProductState,
  productState => productState.clickedProductRequestPricing,
)