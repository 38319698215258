import { useState } from 'react';
import { useSelector } from 'react-redux';

import { ProductVariantClass } from 'models/classes/productVariantClass';
import { AssociationVariantClass } from 'models/classes/associationClass';
import { useAddProductToCart } from 'hooks/useAddProductToCart';
import { numberFormat } from 'utils/products/product';
import { priceTypeSelector, selectAddressCode } from 'ducks/application/selectors';
import { addProductGAEvent } from 'GA/ProductVariantGA/ProductVariantGA';
import { selectBranch } from 'ducks/branch/selectors';
import { useTranslation } from 'react-i18next';
import { useChangeStep } from 'pages/Checkout/hooks';
import { filterPrices } from 'utils/products/productVariant';
import get from 'lodash/get';
import size from 'lodash/size';

const useSnackbarState = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return {
    openSnackbar,
    setOpenSnackbar,
    handleCloseSnackBar,
  };
};

const usePurchaseClickHandler = (
  productVar: ProductVariantClass | AssociationVariantClass,
  topInputValue: number,
  bottomInputValue: number,
  listName: string,
  categoryName: string,
) => {
  const [messageTop, setMessageTop] = useState('');
  const [messageBottom, setMessageBottom] = useState('');
  const address = useSelector(selectAddressCode);
  const addProduct = useAddProductToCart();
  const branch = useSelector(selectBranch);
  const deliveryType = useSelector(priceTypeSelector);
  const changeStep = useChangeStep();

  const productPrices = filterPrices(productVar.prices);

  const { t } = useTranslation();

  const handleAddProduct = () => {
    addProduct(productVar.id, topInputValue, deliveryType, branch.id, productVar.category.id);
    changeStep(1);
    addProductGAEvent(productVar, address, branch.erpName, topInputValue, listName, categoryName);
  };

  const handlePurchaseClick = () => {
    if (size(productPrices) === 0) {
      handleAddProduct();
      return true;
    }
    let messageTopTemp: string;
    let messageBottomTemp: string;

    const minQuantity = get(productPrices, '[0].qty', 1);

    const minimalQuantityValue = `${minQuantity} ${productVar.units.perName}`;
    const orderOnly = `${productVar.units.perName} (1 ${productVar.units.perName} = ${Number(
      (minQuantity / productVar.units.uom2Conversion).toFixed(2)
    )} ${productVar.units.uom2Name}) `;
    const unitsPerName = `${productVar.units.perName}`;

    // min order uom quantity
    if (minQuantity > topInputValue) {
      // top uom value
      messageTopTemp = t('price_calculator.min_quantity', { minimalQuantityValue });
      setMessageTop(messageTopTemp);
    } else {
      messageTopTemp = '';
      setMessageTop('');
    }

    if ((!messageTopTemp && topInputValue % minQuantity) !== 0) {
      messageTopTemp = t('price_calculator.divisible', { minQuantity });
      setMessageTop(messageTopTemp);
    } else {
      messageTopTemp = '';
      setMessageTop('');
    }

    if (bottomInputValue !== null) {
      const productCoeff = Number(
        numberFormat(minQuantity / productVar.units.uom2Conversion)
      );
      const remaining = Number(numberFormat(Number(numberFormat(bottomInputValue)) % productCoeff));
      // min order uom2 quantity
      if (remaining !== 0 && remaining !== productCoeff) {
        // bottom uom value
        messageBottomTemp = t('price_calculator.packs_provide', { unitsPerName, orderOnly });
        setMessageBottom(messageBottomTemp);
      } else {
        messageBottomTemp = '';
        setMessageBottom('');
      }
    } else {
      messageBottomTemp = '';
      setMessageBottom('');
    }

    if (messageTopTemp === '' && messageBottomTemp === '') {
      handleAddProduct();
      return true;
    }
    return false;
  };

  return {
    messageTop,
    messageBottom,
    addProduct,
    handleAddProduct,
    handlePurchaseClick,
  };
};

export { useSnackbarState, usePurchaseClickHandler };
