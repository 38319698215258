/**
 * Format number to specified decimal point
 */
export const numberFormat = (n: number, decimal: number = 2) => {
  try {
    return n.toFixed(decimal);
  } catch {
    return null;
  }
};
