import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Routes from 'routes';
import { selectCartItems, selectSubtotalPriceFromCart } from 'ducks/cart/selectors';
import {
  CheckoutWrapper,
  InfoContainer,
  ItemContainer,
  ItemsContainer,
  ModalFormButton,
  Total,
  StyledButtonLink,
  StyledPopover,
  SubtotalPrice,
  SubtotalText,
  SubtotalWrapper,
  Title,
  Image,
  CheckoutFooter,
  ClearButton,
} from 'components/Checkout/SmallCheckout/styles';
import InfoIcon from '@material-ui/icons/Info';
import defaultProductImage from 'assets/images/default-product.png';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import TagManager from 'react-gtm-module';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import {
  selectAddressId,
  selectErpCustomerAddressCode,
  selectIsHidePrice,
} from 'ducks/application/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { RemoveIcon } from 'assets/svg/Checkout/Remove';
import { numberFormat } from 'utils/products/product';
import { removeCart, requestDeleteProductFromCart } from 'ducks/cart/actions';
import { useGA4CartEvent } from 'GA/CartGa4/cartGA4';
import { ICartItem } from 'models/interfaces/cart';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { Accordion, Box, Tooltip, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ShippingIcon } from 'assets/svg/ShippingIcon';
import { BranchIcon } from 'assets/svg/BranchIcon';
import { filterPrices } from 'utils/products/productVariant';
// import ProductItem from 'pages/Checkout/ProductList/ProductItem';

import { AccordionContent, StyledAccordionDetails } from 'pages/Checkout/styles';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import CartProductInputs from './Inputs/CartProductInputs';

import CartProductInputWithoutPrice from './Inputs/CartProductInputWithoutPrice';


interface IProduct {
  item?: ICartItem;
  name?: string;
  url?: string;
  handleClose?: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProductForSmallCheckout: React.FC<IProduct> = ({
  item,
  name,
  url,
  handleClose
}: IProduct) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const isHidePrice = useSelector(selectIsHidePrice);
  const handleOnClickProductName = () => {
    history.push(Routes.PRODUCT_DETAIL.replace(':code', String(item.variant.erpProductId)));
    handleClose();
  }
  const [loading, setLoading] = React.useState(false);
  const { handleFireGA4RemoveItemFromCart } = useGA4CartEvent()
  const handleRemoveItem = () => {
    setLoading(true);
    dispatch(requestDeleteProductFromCart(item.id));
    handleFireGA4RemoveItemFromCart(item);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const productPrices = useMemo(() => filterPrices(item?.variant?.addressPrices as any), [item?.variant?.addressPrices]);

  return (
    <ItemContainer className="small-checkout-item">
      <Image src={url || defaultProductImage} alt="product image" />
      <InfoContainer className="info-container">
        <Title onClick={handleOnClickProductName}>{name}</Title>
        {
          productPrices.length > 0 ? (
            <CartProductInputs
              cartItem={item}
              bottomInputValue={1}
              setBottomInputValue={() => { }}
              isSecondStep={false}
              popperPlacement="right"
              onHasErrors={() => { }}
            />
          ) : (
            <CartProductInputWithoutPrice
              cartItem={item}
              isSecondStep={false}
            />
          )
        }

        {
          !isHidePrice && (
            <Total>
              <div className="box-total">
                <span className="total">{t('checkout.product_subtotal').toString()}</span>
                {
                  productPrices.length > 0 ? (
                    <span className="price">${numberFormat(item.total)}</span>
                  ) : (
                    <span className="price-to-be-confirmed">{t('checkout.price_to_be_confirmed').toString()}</span>
                  )
                }
              </div>
              <div className="box-delete">
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <button type="button" disabled={loading} className="btn-delete" onClick={handleRemoveItem}>
                  <RemoveIcon />
                </button>
              </div>
            </Total>
          )
        }

      </InfoContainer>
    </ItemContainer>
  );
};

interface ISmallCheckout {
  anchorEl: Element | null;
  handleClose: () => void;
}

const SmallCheckout: React.FC<ISmallCheckout> = ({ anchorEl, handleClose }: ISmallCheckout) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cartItems = useSelector(selectCartItems);
  const subtotalValue = useSelector(selectSubtotalPriceFromCart);
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const isHidePrice = useSelector(selectIsHidePrice);
  const renderSubtotal = subtotalValue !== undefined ? subtotalValue.toFixed(2) : '0.00';

  const totalSysWeight = useMemo(() => {
    if (cartItems && cartItems.length > 0) {
      const sysWeights = cartItems.map(item => get(item, 'variant.sysWeight', 0) * item.quantity);
      return sysWeights.filter((v) => !isNull(v))?.reduce((acc, item) => acc + parseFloat(item), 0);
    }
    return null;
  }, [cartItems]);

  const isHasEmptySysWeight = useMemo(() => {
    return cartItems?.some(item => get(item, 'variant.sysWeight', null) === null);
  }, [cartItems]);

  const { t } = useTranslation();
  const openPopover = !!anchorEl;
  const [disableClearButton, setDisableClearButton] = React.useState(false);

  const { handleFireGA4RemoveCart, handleFireGA4ViewCart } = useGA4CartEvent();

  const goToCheckout = () => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'go_to_checkout',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
    handleFireGA4ViewCart();

    handleClose();
    history.push(Routes.CHECKOUT);
  };
  const handleRemoveCart = () => {
    if (cartItems.length === 0) return;
    handleFireGA4RemoveCart();
    handleClose();
    dispatch(removeCart());
    setDisableClearButton(true);
    setTimeout(() => {
      setDisableClearButton(false);
    }, 1000);
  }

  const handleGTMExpanded = (branchId: number) => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'checkout_click_grouping',
        user_type: getUserType(user.email),
        branch_id: branchId,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      }
    });
  }

  const mapCartItems = groupBy(cartItems, 'branchName');

  return (
    // @ts-ignore
    <StyledPopover
      style={{ zIndex: 1500 }}
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <CheckoutWrapper>

        <ItemsContainer>
          {
            map(mapCartItems, (items, branchName) => {
              return (
                <Accordion key={branchName} defaultExpanded onClick={() => handleGTMExpanded(items[0].branchId)}>
                  <AccordionContent expandIcon={<ExpandMoreIcon />}>
                    <div className="col-branch-name">
                      <div className="branch-name">
                        {branchName !== branch?.erpName ? (
                          <ShippingIcon className="shipping-icon" />
                        ) : (
                          <BranchIcon className="branch-icon" />
                        )}
                        {branchName !== branch?.erpName
                          ? t('product_detail.ready_in', { leadTime: items[0].leadTime }).toString()
                          : branchName}
                        <div className="number">{items.length}</div>
                      </div>
                      {
                        branchName !== branch?.erpName && (
                          <div className="additional-may-change">{t('checkout.additional_may_charge').toString()}</div>
                        )
                      }
                    </div>
                  </AccordionContent>

                  <StyledAccordionDetails>
                    {
                      items.map(item => <ProductForSmallCheckout
                        key={item.id}
                        item={item}
                        name={item.variant.name || item.variant.erpSku}
                        url={item.variant.images[0]?.url}
                        handleClose={handleClose}
                      />)
                    }
                  </StyledAccordionDetails>
                </Accordion>
              )
            })
          }
        </ItemsContainer>

        <CheckoutFooter>
          {!isHidePrice ? (
            <SubtotalWrapper style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Box>
                <SubtotalText>{t('checkout.subtotal').toString()}: </SubtotalText>
                <SubtotalPrice>${renderSubtotal}</SubtotalPrice>
              </Box>
              {
                totalSysWeight ? (
                  <Box style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    <SubtotalText>{t('product_detail.total_weight').toString()}: </SubtotalText>
                    <SubtotalPrice style={{ marginBottom: '0', fontSize: '16px' }}>
                      {parseFloat(numberFormat(totalSysWeight))} {t('product_detail.lbs').toString()}
                    </SubtotalPrice>
                    {
                      isHasEmptySysWeight && (
                        <Tooltip
                          title={
                            <Typography style={{ fontSize: '14px', fontWeight: 400 }}>
                              {t('product_detail.total_weight_may_not_included_all_items').toString()}
                            </Typography>
                          }
                          enterTouchDelay={0}
                          leaveTouchDelay={15000}
                        >
                          <InfoIcon color="error" fontSize="small" />
                        </Tooltip>
                      )
                    }
                  </Box>
                ) : (
                  <Box style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    <SubtotalText >
                      {t('product_detail.total_weight').toString()}:
                    </SubtotalText>
                    <Typography style={{ fontSize: '16px', fontWeight: 700, color: '#FF0000', lineHeight: '28px', display: 'flex', alignItems: 'center', gap: '4px' }}>
                      {t('product_detail.sys_weight_not_available').toString()}
                      {
                        isHasEmptySysWeight && (
                          <Tooltip
                            title={
                              <Typography style={{ fontSize: '14px', fontWeight: 400 }}>
                                {t('product_detail.total_weight_may_not_included_all_items').toString()}
                              </Typography>
                            }
                            enterTouchDelay={0}
                            leaveTouchDelay={15000}
                          >
                            <InfoIcon color="error" fontSize="small" />
                          </Tooltip>
                        )
                      }
                    </Typography>
                  </Box>
                )
              }
            </SubtotalWrapper>
          ) : null}

          <StyledButtonLink to="#" onClick={goToCheckout}>
            <ModalFormButton>{t('checkout.proceed_to_checkout').toString()}</ModalFormButton>
          </StyledButtonLink>

          <ClearButton disabled={disableClearButton} onClick={handleRemoveCart}>{t('checkout.clear_all').toString()}</ClearButton>
        </CheckoutFooter>
      </CheckoutWrapper>
    </StyledPopover>
  );
};

export default SmallCheckout;
