import { Dispatch, SetStateAction } from 'react';

import {
  ISelectedValue,
  FilterType,
  IMultiselectFilterValues,
} from 'models/interfaces/productVariant';
import { IPagination } from 'components/Product/Dropdowns/ShowDropdown';
import {
  generateFilter,
  getMultiselectedValues,
  getRangeFilterSelectedValues,
} from 'components/Product/ProductFilter/utils';
import TagManager from 'react-gtm-module';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { useSelector } from 'react-redux';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { selectAddressId, selectErpCustomerAddressCode } from 'ducks/application/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';

export const useDesktopFilter = (
  selectedValues: ISelectedValue[],
  setFirstLoad: Dispatch<SetStateAction<boolean>>,
  setPagination: Dispatch<SetStateAction<IPagination>>,
  setFilter: Dispatch<SetStateAction<{}>>,
  setSelectedValues: Dispatch<SetStateAction<ISelectedValue[]>>
) => {
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const findNecessaryCheck = (facetProperty: string, bucketKey: string) =>
    selectedValues.some(
      selectedValue => selectedValue.code === facetProperty && selectedValue.bucketKey === bucketKey
    );

  const handleChange = (facetCode: string, bucketLabel: string, bucketKey: string) => {
    const isSelectedValueExists = findNecessaryCheck(facetCode, bucketKey);
    if (isSelectedValueExists) {
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'filter_deselect',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
    } else {
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'filter_select',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
    }

    setFirstLoad(true);
    setPagination(prevState => {
      return {
        ...prevState,
        page: 1,
      };
    });

    if (!isSelectedValueExists) {
      const newSelectedValues = [
        ...selectedValues,
        {
          name: bucketLabel,
          code: facetCode,
          bucketKey,
          type: FilterType.CHECKBOX,
        },
      ];

      setSelectedValues(newSelectedValues);
      setFilter(generateFilter(newSelectedValues));
    } else {
      const newSelectedValues = selectedValues.filter(item => {
        if (item.bucketKey === bucketKey) {
          return item.code !== facetCode;
        }

        return true;
      });

      setSelectedValues(newSelectedValues);
      setFilter(generateFilter(newSelectedValues));
    }
  };

  const handleMultiselectChange = (
    code: string,
    name: string,
    { values }: IMultiselectFilterValues
  ) => {
    const newSelectedValues = getMultiselectedValues(code, name, values, selectedValues);

    setFirstLoad(true);
    setPagination(prevState => {
      return {
        ...prevState,
        page: 1,
      };
    });
    setSelectedValues(newSelectedValues);
    setFilter(generateFilter(newSelectedValues));
  };

  const handleRangeFilterChange = (code: string, name: string, values: number[]) => {
    const newSelectedValues = getRangeFilterSelectedValues(code, name, values, selectedValues);

    setFirstLoad(true);
    setPagination(prevState => {
      return {
        ...prevState,
        page: 1,
      };
    });
    setSelectedValues(newSelectedValues);
    setFilter(generateFilter(newSelectedValues));
  };

  return {
    handleChange,
    findNecessaryCheck,
    handleRangeFilterChange,
    handleMultiselectChange,
  };
};
