import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { Checkbox, CheckboxProps, useMediaQuery, withStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { ShippingPopUpCross, ShippingPopUpSmallCross } from 'assets/svg/Popups/Cross';
import { UserClass } from 'models/classes/userClass';
import { patchProfile } from 'ducks/user/actions';
import { DELIVERY_PRICE_TYPE, PICKUP_PRICE_TYPE } from 'assets/constants/application';
import { IAddress } from 'models/interfaces/user';
import { usePopupScrollEffect } from 'hooks/usePopupScrollEffect';

import { useTranslation } from 'react-i18next';

const CrossButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1024px) {
    width: fit-content;
    position: absolute;
    top: 16px;
    right: 16px;
  }
`;

const CrossButton = styled.button`
  width: fit-content;
  height: fit-content;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  outline: none;
`;

const PopUpContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 36px;

  @media (max-width: 1024px) {
    padding-right: 0px;
  }
`;

const PopUpHeader = styled.h2`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0.01em;
  margin: 0;

  @media (max-width: 1024px) {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
  }
`;

const PopUpHelperText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.grey};
  margin-top: 17px;
  margin-bottom: 31px;

  @media (max-width: 1024px) {
    margin: 16px 0;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  height: 48px;
  border: none;
  background: ${({ theme: { colors } }) => colors.mainGreen};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  outline: none;

  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: ${({ theme: { colors } }) => colors.white};
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    height: 40px;
    margin-top: 20px;

    p {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const CheckboxesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    margin-top: 16px;
  }
`;

const Circle = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid ${({ theme: { colors } }) => colors.buttonGrey};
  border-radius: 50%;
`;

const CheckedCircle = styled.div`
  width: 16px;
  height: 16px;
  border: 4px solid ${({ theme: { colors } }) => colors.circleGreen};
  border-radius: 50%;
`;

const CheckboxTitle = styled.p`
  margin: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  margin-left: 10px;
`;

const StyledCheckbox = withStyles({
  root: {
    width: 'fit-content',
    height: 'fit-content',
    padding: '0',
    '&:hover': {
      background: 'none',
      cursor: 'pointer',
    },
    '&:last-child': {
      marginTop: '26px',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const SingleCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  &:last-child {
    margin-top: 20px;
  }
`;

interface IChoosePricingProps {
  close: boolean;
  setClose: Dispatch<SetStateAction<boolean>>;
  user: UserClass;
  address: IAddress;
}

const ChoosePricingPopUp: React.FC<IChoosePricingProps> = ({
  close,
  setClose,
  user,
  address,
}: IChoosePricingProps) => {
  const [checkedShipping, setCheckedShipping] = useState(
    user.defaultPricing || DELIVERY_PRICE_TYPE
  );
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const dispatch = useDispatch();

  usePopupScrollEffect();

  const handleCheck = (value: string) => {
    setCheckedShipping(value);
  };

  const handleNext = () => {
    dispatch(
      patchProfile({
        defaultPricing: checkedShipping,
        defaultAddressId: address.id,
      })
    );
    setClose(true);
  };

  const handleCloseClick = () => {
    dispatch(
      patchProfile({
        defaultPricing: checkedShipping,
        defaultAddressId: address.id,
      })
    );

    setClose(true);
  };

  const { t } = useTranslation();
  const { firstName } = user;

  return !close ? (
    <>
      <CrossButtonWrapper>
        <CrossButton onClick={handleCloseClick}>
          {isMobile ? <ShippingPopUpSmallCross /> : <ShippingPopUpCross />}
        </CrossButton>
      </CrossButtonWrapper>
      <PopUpContentWrapper>
        <PopUpHeader>{t('pricing_popup.welcome', { firstName })}</PopUpHeader>
        <PopUpHelperText>{t('pricing_popup.select_pricing')}</PopUpHelperText>
        <CheckboxesWrapper>
          <SingleCheckboxWrapper onClick={() => handleCheck(DELIVERY_PRICE_TYPE)}>
            <StyledCheckbox
              icon={<Circle />}
              checkedIcon={<CheckedCircle />}
              name="checkedH"
              checked={checkedShipping === DELIVERY_PRICE_TYPE}
            />
            <CheckboxTitle>{t('pricing_popup.delivery_pricing')}</CheckboxTitle>
          </SingleCheckboxWrapper>
          <SingleCheckboxWrapper onClick={() => handleCheck(PICKUP_PRICE_TYPE)}>
            <StyledCheckbox
              icon={<Circle />}
              checkedIcon={<CheckedCircle />}
              name="checkedH"
              checked={checkedShipping === PICKUP_PRICE_TYPE}
            />
            <CheckboxTitle>{t('pricing_popup.pickup_pricing')}</CheckboxTitle>
          </SingleCheckboxWrapper>
        </CheckboxesWrapper>
        <StyledButton onClick={handleNext}>
          <p>{t('pricing_popup.button')}</p>
        </StyledButton>
      </PopUpContentWrapper>
    </>
  ) : null;
};

export default ChoosePricingPopUp;
