import React, { Dispatch, SetStateAction } from 'react';
import { DELIVERY_PRICE_TYPE, PICKUP_PRICE_TYPE } from 'assets/constants/application';
import { usePopupScrollEffect } from 'hooks/usePopupScrollEffect';

import { useTranslation } from 'react-i18next';
import {
  CheckboxesWrapper,
  CheckboxTitle,
  CheckedCircle,
  Circle,
  PopUpContentWrapper,
  SingleCheckboxWrapper,
  StepBox,
  StepBoxContainer,
  StepBoxLight,
  StepTitle,
  StyledButton,
  StyledCheckbox,
} from 'components/Popups/StartSettings/styles';

interface IChoosePricingProps {
  setCheckedShipping: Dispatch<SetStateAction<string>>;
  checkedShipping: string;
  nextStep: () => void;
}

const ChoosePricingStep: React.FC<IChoosePricingProps> = ({
  nextStep,
  setCheckedShipping,
  checkedShipping,
}: IChoosePricingProps) => {
  usePopupScrollEffect();

  const handleCheck = (value: string) => {
    setCheckedShipping(value);
  };

  const { t } = useTranslation();

  return (
    <PopUpContentWrapper>
      <CheckboxesWrapper>
        <StepTitle>{t('start_settings_popup.step2_title')}</StepTitle>
        <SingleCheckboxWrapper onClick={() => handleCheck(DELIVERY_PRICE_TYPE)}>
          <StyledCheckbox
            icon={<Circle />}
            checkedIcon={<CheckedCircle />}
            name="checkedH"
            checked={checkedShipping === DELIVERY_PRICE_TYPE}
          />
          <CheckboxTitle>{t('pricing_popup.delivery_pricing')}</CheckboxTitle>
        </SingleCheckboxWrapper>
        <SingleCheckboxWrapper onClick={() => handleCheck(PICKUP_PRICE_TYPE)}>
          <StyledCheckbox
            icon={<Circle />}
            checkedIcon={<CheckedCircle />}
            name="checkedH"
            checked={checkedShipping === PICKUP_PRICE_TYPE}
          />
          <CheckboxTitle>{t('pricing_popup.pickup_pricing')}</CheckboxTitle>
        </SingleCheckboxWrapper>
      </CheckboxesWrapper>
      <div>
        <StyledButton onClick={nextStep}>
          <p>{t('pricing_popup.button')}</p>
        </StyledButton>
        <StepBoxContainer>
          <StepBox />
          <StepBox />
          <StepBoxLight />
        </StepBoxContainer>
      </div>
    </PopUpContentWrapper>
  );
};

export default ChoosePricingStep;
