import { IQuestion } from 'models/interfaces/faq';

export class FAQClass {
  id: number = null;

  name: string = null;

  position: number = null;

  questions: IQuestion[] = null;

  constructor(...args) {
    args.forEach((item: FAQClass) => {
      this.id = item.id || null;
      this.name = item.name || null;
      this.position = item.position || null;
      this.questions = item.questions || null;
    });
  }
}
