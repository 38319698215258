import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import axios from 'utils/axios';
import {IFireKlaviyoEventAction, KLAVIYO_EVENT} from './types';
import {selectAddressId} from '../application/selectors';

function* fireKlaviyoEventAddToCart(action: IFireKlaviyoEventAction) {
  try {
    const {productId, quantity} = action.payload;
    const addressId = yield select(selectAddressId);
    const {data: response} = yield call(axios.post, '/api/auth/cart/klaviyo/event/get-event-data', {
      productId,
      quantity
    }, {
      headers: {
        'X-Address-Id': addressId
      },
    });
    if (response) {
      yield put({type: KLAVIYO_EVENT.FIRE_KLAVIYO_EVENT_SUCCESS, payload: response.data});
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* watchFireKlaviyoEventAddToCart() {
  yield takeLatest(KLAVIYO_EVENT.FIRE_KLAVIYO_EVENT, fireKlaviyoEventAddToCart);
}

export default function* klaviyoSaga() {
  yield all([watchFireKlaviyoEventAddToCart()])
}