import React from 'react';

import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {setAddToTruckStatus} from 'ducks/cart/actions';
import { StylesDialog, StylesDialogContent } from './styles';

interface IAddedToTruckModalModal {
  open: boolean;
}

const AddedToTruckModal = ({ open }: IAddedToTruckModalModal) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  return (
    <StylesDialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="added-to-truck-modal"
      onBackdropClick={() => {
        dispatch(setAddToTruckStatus(false));
      }}
    >
      <StylesDialogContent>
        <svg
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="ic"
        >
          <circle cx="30" cy="30" r="30" fill="#337D58" />
          <path
            d="M24.8038 42.5039C24.116 42.5039 23.4625 42.2249 22.981 41.7366L13.248 31.8656C12.2507 30.8541 12.2507 29.1799 13.248 28.1684C14.2454 27.1569 15.8962 27.1569 16.8936 28.1684L24.8038 36.1907L42.4814 18.2625C43.4788 17.251 45.1296 17.251 46.127 18.2625C47.1243 19.274 47.1243 20.9483 46.127 21.9598L26.6266 41.7366C26.1451 42.2249 25.4917 42.5039 24.8038 42.5039Z"
            fill="white"
          />
        </svg>

        <div className="txt">{t('product_detail.add_to_truck_success')}</div>
      </StylesDialogContent>
    </StylesDialog>
  );
};

export default AddedToTruckModal;
