import React, { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Routes from 'routes';

// Material
import { Grid, useMediaQuery, Grow } from '@material-ui/core';

// Selectors
import { selectParentCategories } from 'ducks/category/selectors';
import { selectUserProviceCode } from 'ducks/user/selectors';
import { menuIsOpenedSelector } from 'ducks/application/selectors';

import { Slider } from 'components/common';

import { useTranslation } from 'react-i18next';
import Skeleton from '@material-ui/lab/Skeleton';

import { PROVINCE_CODES_WITH_NAME } from 'assets/constants/application';

import { selectBranch } from 'ducks/branch/selectors';
import defaultAvatar from 'assets/images/default-category-product.png';
import { CategoryNotification } from 'components/Layout/Notification/CategoryNotification';
import { Content } from 'components/Layout/Store/styles';
import { getBanner } from 'ducks/application/actions';
import {
  CategoriesWrapper,
  CategoryCard,
  CategoryInfo,
  Header,
  // ShopInfo,
  CategoryHeader,
  ProductNumber,
  CardWrapper,
  StyledLink,
  SkeletonWrapper,
} from './styles';

const CategoryList: React.FC = () => {
  const dispatch = useDispatch();
  const categories = useSelector(selectParentCategories);
  const proviceCode = useSelector(selectUserProviceCode);
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const isOpened = useSelector(menuIsOpenedSelector);
  const branch = useSelector(selectBranch);

  const { t } = useTranslation();

  useEffect(() => {
    if (branch) {
      dispatch(getBanner('menu_category', branch.id));
    }
  }, [branch]);

  const MainContent = () =>
    !(categories && categories.length) ? (
      <SkeletonWrapper>
        <Skeleton variant="rect" width="25%" height={174} style={{ margin: '8px' }} />
        <Skeleton variant="rect" width="25%" height={174} style={{ margin: '8px' }} />
        <Skeleton variant="rect" width="25%" height={174} style={{ margin: '8px' }} />
        <Skeleton variant="rect" width="25%" height={174} style={{ margin: '8px' }} />
      </SkeletonWrapper>
    ) : (
      <>
        <CategoriesWrapper>
          <Grid
            container
            item
            direction="row"
            alignItems="center"
            spacing={isMobile ? 1 : 2}
            md={12}
            xs={12}
          >
            {categories.map(category => (
              <Grow in={!!categories} key={category.id}>
                <CategoryCard item xs={6} md={3}>
                  <CardWrapper imgUrl={category.urlPath || defaultAvatar}>
                    <StyledLink to={`${Routes.CATEGORIES}/${category.code}`}>
                      <CategoryInfo>
                        <CategoryHeader>{category.name}</CategoryHeader>
                        <ProductNumber>
                          {category.items ? category.items : 0} {t('categories.products').toString()}
                        </ProductNumber>
                      </CategoryInfo>
                    </StyledLink>
                  </CardWrapper>
                </CategoryCard>
              </Grow>
            ))}
          </Grid>
        </CategoriesWrapper>
        {!isMobile && PROVINCE_CODES_WITH_NAME[proviceCode] !== 'Quebec' && <Slider />}
      </>
    );

  return !isOpened ? (
    <>
      <CategoryNotification />
      <Content>
        <Grid container item md={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Header className="txt-categories-header">{t('categories.header').toString()}</Header>
            {/* <ShopInfo>{t('categories.shop_info')}</ShopInfo> */}
          </Grid>
          <MainContent />
        </Grid>
      </Content>
    </>
  ) : null;
};

export default memo(CategoryList);
