import styled from 'styled-components/macro';
import { Dialog, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { MediumButton } from 'components/common/Buttons/MediumButton';

const StylesDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 8px;
  }
`

const StylesDialogContent = styled(DialogContent)`
  position: relative;
  width: 486px;
  padding: 43px 56px 48px !important;
  @media only screen and (max-width: 479px) {
    padding: 36px 20px 20px !important;
  }
  .btn-close {
    position: absolute;
    right: 30px;
    top: 30px;
    color: #48576B;
    border: 0;
    background: none;
    cursor: pointer;
    transition: all 0.4s ease;
    padding: 0;
    &:hover {
      color: #333;
    }
  }
  .ic-big {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    border: 8px solid #FFFAEB;
    background: #FEF0C7;
    margin-bottom: 28px;
    svg {
      width: 24px;
      height: 24px;
      color: #dc6803;
    }
  }
  .txt-title {
    color: #00133C;
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 150% */
    letter-spacing: 0.1px;
    margin-bottom: 16px;
    @media (max-width: 1023px) {
      font-size: 22px;
    }
  }
  .txt-message {
    color: #00133C;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 177.778% */
    letter-spacing: 0.2px;
    @media (max-width: 1023px) {
      font-size: 16px;
    }
  }
  .box-submit {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    padding-top: 32px;
    .btn-submit {
      width: calc(223 / 372 * 100%);
    }
    .btn-cancel {
      width: calc(133 / 372 * 100%);
    }
  }
`;

const SubmitButton = withStyles({
  root: {
    width: '100%',
    height: '56px',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '28px',
    letterSpacing: '0.1px',
    borderRadius: '8px',
    whiteSpace: 'nowrap',
    padding: '6px 16px',
    '@media (max-width: 1023px)': {
      height: '44px',
    },
    '& svg': {
      marginRight: '8px',
    },
  },
})(MediumButton);

const CloseButton = withStyles({
  root: {
    width: '100%',
    height: '56px',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '28px',
    letterSpacing: '0.1px',
    color: '#196742',
    background: '#fff',
    border: '1px solid #196742',
    borderRadius: '8px',
    whiteSpace: 'nowrap',
    padding: '6px 16px',
    '@media (max-width: 1023px)': {
      height: '44px',
    },
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
    '& .MuiButton-label': {
      textTransform: 'none',
    },
  },
})(MediumButton);

export {
  StylesDialog,
  StylesDialogContent,
  SubmitButton,
  CloseButton
};
