import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BreadCrumbs } from 'components/common';
import { StyledBreadCrumbsContainer } from 'components/common/BreadCrumbs/styles';
import Routes from 'routes';

const StyledH1 = styled.h1`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  letter-spacing: 0.1px;
  margin-bottom: 24px;
`;

const StyledP = styled.p`
  color: ${({ theme: { colors } }) => colors.disclaimerGrey};
  font-size: 14px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 14.4px;
  font-family: Open Sans;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;

  @media (max-width: 1023px) {
    justify-content: center;
  }
`;

const StyledLink = styled(Link)`
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 252px;
  height: 48px;
  background-color: ${({ theme: { colors } }) => colors.mainGreen};
  text-decoration: none;
  cursor: pointer;
`;

const LinkP = styled.p`
  font-size: 16px;
  font-family: Open Sans;
  line-height: 22px;
  letterspacing: 0.01em;
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.white};
`;

const ColourDisclaimer = () => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledBreadCrumbsContainer>
        <BreadCrumbs currentPageName="Colour Disclaimer" />
      </StyledBreadCrumbsContainer>
      <StyledH1>{t('colour_disclaimer.title')}</StyledH1>
      <StyledP>
        {t('colour_disclaimer.non_italic_content')}
        <br />
        <br />
        <em>
          <strong>{t('colour_disclaimer.note')}</strong>
          {t('colour_disclaimer.italic_content')}
        </em>
      </StyledP>
      <StyledP>
        <br />
        {t('colour_disclaimer.contact_content')}
      </StyledP>
      <ButtonWrapper>
        <StyledLink to={Routes.CONTACT_US}>
          <LinkP>{t('colour_disclaimer.contact_us')}</LinkP>
        </StyledLink>
      </ButtonWrapper>
    </div>
  );
};

export default ColourDisclaimer;
