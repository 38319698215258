import { Reducer } from 'redux';

// Types
import { CategoryActionTypes, CategoryState } from 'ducks/category/types';

const initialState: CategoryState = {
  category: null,
  categories: [],
  loading: false,
  error: null,
  language: null,
  parentCategories: [],
};

const categoryReducer: Reducer<CategoryState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case CategoryActionTypes.REQUEST_CATEGORIES: {
      return { ...state, loading: true };
    }
    case CategoryActionTypes.REQUEST_CATEGORIES_SUCCESS: {
      return { ...state, loading: false, categories: payload.categories };
    }
    case CategoryActionTypes.REQUEST_CATEGORIES_ERROR: {
      return { ...state, loading: false, error: payload.error };
    }
    case CategoryActionTypes.REQUEST_PARENT_CATEGORY: {
      return { ...state, loading: true };
    }
    case CategoryActionTypes.REQUEST_PARENT_CATEGORY_SUCCESS: {
      return { ...state, loading: false, parentCategories: payload.categories };
    }
    case CategoryActionTypes.REQUEST_PARENT_CATEGORY_ERROR: {
      return { ...state, loading: false, error: payload.error };
    }
    case CategoryActionTypes.REQUEST_CATEGORY: {
      return { ...state, loading: true };
    }
    case CategoryActionTypes.REQUEST_CATEGORY_SUCCESS: {
      return { ...state, loading: false, category: payload.category };
    }
    case CategoryActionTypes.REQUEST_CATEGORY_ERROR: {
      return { ...state, loading: false, error: payload.error };
    }
    case CategoryActionTypes.RESET_CATEGORIES: {
      return { ...state, loading: false, categories: [] };
    }
    case CategoryActionTypes.RESET_CATEGORY: {
      return { ...state, loading: false, category: null };
    }
    case CategoryActionTypes.RESET_CATEGORIES_ERROR: {
      return { ...state, error: null };
    }
    default:
      return state;
  }
};

export default categoryReducer;
