import React from 'react';
import { useHistory } from 'react-router-dom';

import { NoOrdersText } from 'pages/General/Profile/components/OrdersAndQuotes/Content/styles';
import { OrdersContainer, ViewCatalogueButton } from 'pages/General/Profile/Orders/styles';
import { useTranslation } from 'react-i18next';

interface INoResults {
  typeOfOrder: string;
}

const NoResults: React.FC<INoResults> = ({ typeOfOrder }: INoResults) => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleViewCatalogueClick = () => {
    history.push('/categories');
  };

  return (
    <OrdersContainer>
      <>
        <NoOrdersText>{t('products_and_search.no_orders_yet', { typeOfOrder })}</NoOrdersText>
        <ViewCatalogueButton onClick={handleViewCatalogueClick}>View Catalogue</ViewCatalogueButton>
      </>
    </OrdersContainer>
  );
};

export default NoResults;
