import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'utils/axios';

import { CategoryClass } from 'models/classes/categoryClass';
import { categoryToCategoryClass } from 'utils/categories/categories';
import {
  resetCategories,
  resetCategory,
  storeCategories,
  storeCategory,
  storeParentCategories,
} from 'ducks/category/actions';
import {
  CategoryActionTypes,
  FetchCategoriesAction,
  FetchCategoryByCodeAction,
  IFetchParentCategoryAction,
} from 'ducks/category/types';
import { getError, storeError } from 'utils/errors';
import { setServerError } from 'ducks/application/actions';
import { selectAddressId } from '../application/selectors';

function* requestCategories(fetchCategoriesAction: FetchCategoriesAction) {
  const { language, addressId, branchId } = fetchCategoriesAction.payload;
  try {
    const { data } = yield call(axios.get, `/api/auth/categories?lang=${language}&address=${addressId}&branch_id=${branchId}`, {
      headers: {
        'Accept-Language': language,
        'x-address-id': addressId.toString(),
      }
    });

    const categories: CategoryClass[] = data.data.map(category =>
      categoryToCategoryClass(category)
    );
    yield put(storeCategories(categories));
  } catch (error) {
    yield put(setServerError(getError(error.response, [])));
  }
}

function* requestParentCategories(fetchParentCategoryAction: IFetchParentCategoryAction) {
  const { language, branchId } = fetchParentCategoryAction.payload;
  const address = yield select(selectAddressId);
  yield put(resetCategories());
  try {
    const { data: response } = yield call(axios.get, `/api/auth/category/parent-category?lang=${language}&address=${address}&branch_id=${branchId}`, {
      headers: {
        'Accept-Language': language,
        'X-Address-Id': address,
      },
    });
    const categories: CategoryClass[] = response.data.map(category =>
      categoryToCategoryClass(category)
    ).sort((a, b) => a.position - b .position);

    categories.forEach(cat => {
      if (cat.children) {
        // eslint-disable-next-line no-param-reassign
        cat.children = cat.children.map(subcat => new CategoryClass(subcat)).sort((a, b) => a.position - b .position);
      }
      cat.children.forEach(child => {
        if (child?.children) {
          // eslint-disable-next-line no-param-reassign
          child.children = child.children.map(subcat => new CategoryClass(subcat)).sort((a, b) => a.position - b .position);
        }
      })
    });

    yield put(storeParentCategories(categories));
  } catch (error) {
    yield put(setServerError(getError(error.response, [])));
  }
}

function* requestCategory(fetchCategoryByCodeAction: FetchCategoryByCodeAction) {
  const { code, addressId, language } = fetchCategoryByCodeAction.payload;
  yield put(resetCategory());
  try {
    // @ts-ignore
    const { data: response } = yield call(axios.get, `/api/auth/category/${code}`, {
      headers: {
        'Accept-Language': language,
        'x-address-id': addressId,
      },
    });
    const category: CategoryClass = categoryToCategoryClass(response.data);
    yield put(storeCategory(category));
  } catch (error) {
    yield storeError(CategoryActionTypes.REQUEST_CATEGORY_ERROR, [404], error.response);
  }
}

function* watchRequestParentCategories() {
  yield takeLatest(CategoryActionTypes.REQUEST_PARENT_CATEGORY, requestParentCategories);
}

function* watchRequestCategory() {
  yield takeLatest(CategoryActionTypes.REQUEST_CATEGORY, requestCategory);
}

function* watchRequestCategories() {
  yield takeLatest(CategoryActionTypes.REQUEST_CATEGORIES, requestCategories);
}

export default function* categorySaga() {
  yield all([watchRequestParentCategories(), watchRequestCategory(), watchRequestCategories()]);
}
