import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { selectUserData } from 'ducks/user/selectors';
import { mapNumberToDefaultPricingType } from 'utils/products/productUtilsHelpers';
import { toggleShowPrices } from 'ducks/productVariant/actions';
import { setPriceType } from 'ducks/application/actions';
import { priceTypeSelector } from 'ducks/application/selectors';

export const useSetPrices = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserData);
  const pricingType = useSelector(priceTypeSelector);

  useEffect(() => {
    if (user && !pricingType) {
      const pricingTypeIndex = mapNumberToDefaultPricingType(user);

      dispatch(toggleShowPrices([0, 1].includes(pricingTypeIndex)));
      dispatch(setPriceType(user.defaultPricing));
    }
  }, [user, dispatch, pricingType]);

  return { user };
};
