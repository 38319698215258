import {createSelector} from 'reselect';
import {RootState} from 'store';

export const selectCartState = (state: RootState) => state.cart;

export const selectCartItems = createSelector(
  selectCartState,
  cartState => cartState.data?.data?.items,
);
export const selectShippingAddress = createSelector(
  selectCartState,
  cartState => cartState.data?.data?.shippingAddress?.id,
);
export const selectOrderId = createSelector(selectCartState, cartState => cartState.data?.data?.id);
export const selectDefaultCartItemsInputValues = createSelector(
  selectCartState,
  cartState => cartState.itemsInputValues,
);
export const selectSubtotalPrice = createSelector(selectDefaultCartItemsInputValues, values =>
  values?.reduce((a, c) => {
    return a + c.itemPrice * c.topValue;
  }, 0),
);

export const selectSubtotalPriceFromCart = createSelector(
  selectCartState,
  cartState => cartState.data?.data?.itemsTotal,
);

export const selectSubtotal = createSelector(
  selectCartState,
  cartState => cartState.data?.data?.subtotal,
);

export const selectSubtotalWoTax = createSelector(
  selectCartState,
  cartState => cartState.data?.data?.subtotalWoTax,
);

export const selectCartItemsQuantity = createSelector(selectCartItems, cartItems =>
  cartItems?.reduce((a, c) => {
    return a + c.quantity;
  }, 0),
);

export const selectOrderAdjustments = createSelector(
  selectCartState,
  cartState => cartState.data?.data?.adjustments,
);

export const selectCartUpdated = createSelector(
  selectCartState,
  cartState => cartState.cartUpdated,
);
export const selectCartLoading = createSelector(selectCartState, cartState => cartState.loading);
export const selectCartItemQtyUpdateStatus = createSelector(
  selectCartState,
  cartState => cartState.cartItemQtyUpdated,
);
export const selectDefaultCartDeliveryType = createSelector(
  selectCartState,
  cartState => cartState.data?.data?.pricing,
);
export const selectCartError = createSelector(selectCartState, cartState => cartState.error);
export const selectCartDataErrors = createSelector(
  selectCartState,
  cartState => cartState.data?.errors,
);
export const selectCartPromoCodeErrors = createSelector(
  selectCartState,
  cartState => cartState.promocodeError,
);
export const selectCart = createSelector(selectCartState, cartState => cartState.data?.data);

export const selectCartPurchaseValues = createSelector(selectCartState, cartState => {
  return {
    taxes: cartState.data?.data.adjustmentsTotal,
    price: cartState.data?.data.total,
    currency: cartState.data?.data?.currencyCode,
  };
});

export const selectCartItemsNoPrice = createSelector(
  selectCartState,
  cartState => cartState.cartItemsNoPrice,
);

export const selectAddToCartError = createSelector(
  selectCartState,
  cartState => cartState.addToCartError
);

export const selectAddToTruckSuccess = createSelector(
  selectCartState,
  cartState => cartState.addToTruckSuccess
);

export const selectAddedToTruckItem = createSelector(
  selectCartState,
  cartState => cartState.addedToTruckItem
)
export const selectStoreCartFirstLoadSuccess = createSelector(
  selectCartState,
  cartState => cartState.storeCartFirstLoadSuccess
)