import React from 'react';

export const OrderHistoryOutline = ({ ...rest }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" { ...rest }>
      <path
        d="M5.19001 6.3775C5.00001 6.3775 4.8 6.2975 4.66 6.1575C4.37 5.8675 4.37 5.3875 4.66 5.0975L8.29 1.4675C8.58 1.1775 9.06001 1.1775 9.35001 1.4675C9.64001 1.7575 9.64001 2.2375 9.35001 2.5275L5.72 6.1575C5.57 6.2975 5.38001 6.3775 5.19001 6.3775Z"
        fill="currentColor"
      />
      <path
        d="M18.81 6.3775C18.62 6.3775 18.43 6.3075 18.28 6.1575L14.65 2.5275C14.36 2.2375 14.36 1.7575 14.65 1.4675C14.94 1.1775 15.42 1.1775 15.71 1.4675L19.34 5.0975C19.63 5.3875 19.63 5.8675 19.34 6.1575C19.2 6.2975 19 6.3775 18.81 6.3775Z"
        fill="currentColor"
      />
      <path
        d="M20.21 10.6016C20.14 10.6016 20.07 10.6016 20 10.6016H19.77H4C3.3 10.6116 2.5 10.6116 1.92 10.0316C1.46 9.58156 1.25 8.88156 1.25 7.85156C1.25 5.10156 3.26 5.10156 4.22 5.10156H19.78C20.74 5.10156 22.75 5.10156 22.75 7.85156C22.75 8.89156 22.54 9.58156 22.08 10.0316C21.56 10.5516 20.86 10.6016 20.21 10.6016ZM4.22 9.10156H20.01C20.46 9.11156 20.88 9.11156 21.02 8.97156C21.09 8.90156 21.24 8.66156 21.24 7.85156C21.24 6.72156 20.96 6.60156 19.77 6.60156H4.22C3.03 6.60156 2.75 6.72156 2.75 7.85156C2.75 8.66156 2.91 8.90156 2.97 8.97156C3.11 9.10156 3.54 9.10156 3.98 9.10156H4.22Z"
        fill="currentColor"
      />
      <path
        d="M11.75 19.5C9.68158 19.5 8 17.8184 8 15.75C8 13.6816 9.68158 12 11.75 12C13.8184 12 15.5 13.6816 15.5 15.75C15.5 17.8184 13.8184 19.5 11.75 19.5ZM11.75 13.1842C10.3368 13.1842 9.18421 14.3368 9.18421 15.75C9.18421 17.1632 10.3368 18.3158 11.75 18.3158C13.1632 18.3158 14.3158 17.1632 14.3158 15.75C14.3158 14.3368 13.1632 13.1842 11.75 13.1842Z"
        fill="currentColor"
      />
      <path
        d="M10.9604 17.1323C10.763 17.1323 10.5657 17.0297 10.4551 16.8481C10.2893 16.5639 10.3762 16.2007 10.6604 16.035L11.2604 15.6718C11.3235 15.6323 11.3551 15.5692 11.3551 15.506V14.7718C11.3551 14.4481 11.6235 14.1797 11.9472 14.1797C12.2709 14.1797 12.5393 14.4402 12.5393 14.7639V15.4981C12.5393 15.9797 12.2788 16.4376 11.8683 16.6823L11.2604 17.0455C11.1735 17.1086 11.063 17.1323 10.9604 17.1323Z"
        fill="currentColor"
      />
      <path
        d="M14.89 22.7507H8.86C5.28 22.7507 4.48 20.6207 4.17 18.7707L2.76 10.1207C2.69 9.71073 2.97 9.33073 3.38 9.26073C3.79 9.19073 4.17 9.47073 4.24 9.88073L5.65 18.5207C5.94 20.2907 6.54 21.2507 8.86 21.2507H14.89C17.46 21.2507 17.75 20.3507 18.08 18.6107L19.76 9.86073C19.84 9.45073 20.23 9.18073 20.64 9.27073C21.05 9.35073 21.31 9.74073 21.23 10.1507L19.55 18.9007C19.16 20.9307 18.51 22.7507 14.89 22.7507Z"
        fill="currentColor"
      />
    </svg>
  );
};
