import axios from 'utils/axios';

export const createUserActionLog = async (entryPoint: string, actionName: string) => {
  try {
    await axios.post('/api/auth/action-log/write-log', {
      entryPoint,
      actionName,
    });
    // @ts-ignore
  } catch (error: any) {
    console.log('====', error);
  }
}