import { Reducer } from 'redux';

// Types
import { AuthState, AuthActionTypes } from 'ducks/auth/types';

const initialState: AuthState = {
  loading: true,
  authenticated: false,
  error: null,
  signUpSucceeded: false,
  resetPasswordRequestSent: false,
  recoverPasswordSucceeded: false,
  user: null,
  remembered: true,
  showOtp: false,
  checkedOtp: false,
  isChangedDisplayLanguage: false,
  isRefreshToken: false,
  createMagicLinkLoading: false,
  createMagicLinkStatus: null,
  createMagicLinkErrorMessages: '',
  loginByMagicLinkLoading: false,
  loginByMagicLinkErrorMessage: '',
  loginByMagicLinkStatus: null,
};

const authReducer: Reducer<AuthState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case AuthActionTypes.REQUEST: {
      return { ...state, loading: true, error: null };
    }
    case AuthActionTypes.LOGIN: {
      return {
        ...state,
        authenticated: false,
        error: null,
        loading: true,
        user: null,
      };
    }
    case AuthActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        authenticated: true,
        error: null,
        loading: false,
        user: payload.user,
        checkedOtp: false,
        showOtp: false,
      };
    }
    case AuthActionTypes.LOGIN_ERROR: {
      return {
        ...state,
        authenticated: false,
        error: payload.data,
        loading: false,
        user: null,
      };
    }
    case AuthActionTypes.VERIFY_TOKEN: {
      return { ...state, error: null, loading: true };
    }
    case AuthActionTypes.VERIFY_TOKEN_SUCCESS: {
      return { ...state, error: null, loading: false };
    }
    case AuthActionTypes.VERIFY_TOKEN_ERROR: {
      return { ...state, error: payload.errors, loading: false };
    }
    case AuthActionTypes.LOGOUT: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.LOGOUT_SUCCESS: {
      return { ...state, loading: false, authenticated: false, user: null, checkedOtp: false, showOtp: false };
    }
    case AuthActionTypes.RESET_PASSWORD: {
      return {
        ...state,
        loading: true,
        resetPasswordRequestSent: false,
        error: null,
      };
    }
    case AuthActionTypes.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        resetPasswordRequestSent: true,
        error: null,
      };
    }
    case AuthActionTypes.RESET_PASSWORD_ERROR: {
      return {
        ...state,
        loading: false,
        resetPasswordRequestSent: false,
        error: payload.data,
      };
    }
    case AuthActionTypes.RESET_PASSWORD_CLEAR_STATE: {
      return {
        ...state,
        loading: false,
        resetPasswordRequestSent: false,
        error: null,
      };
    }
    case AuthActionTypes.RECOVER_PASSWORD: {
      return {
        ...state,
        loading: true,
        recoverPasswordSucceeded: false,
        error: null,
      };
    }
    case AuthActionTypes.RECOVER_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        recoverPasswordSucceeded: true,
        error: null,
      };
    }
    case AuthActionTypes.RECOVER_PASSWORD_ERROR: {
      return {
        ...state,
        loading: false,
        recoverPasswordSucceeded: false,
        error: payload.data,
        banner: payload.url,
      };
    }
    case AuthActionTypes.REMEMBER_ME: {
      return { ...state, remembered: true };
    }
    case AuthActionTypes.FORGET_ME: {
      return { ...state, remembered: false };
    }
    case AuthActionTypes.REQUEST_VALIDATE_RECAPTCHA_SUCCESS: {
      return { ...state, checkedOtp: payload.isCheck };
    }
    case AuthActionTypes.REQUEST_VALIDATE_RECAPTCHA_ERROR: {
      return { ...state, error: payload.data };
    }
    case AuthActionTypes.SHOW_OTP: {
      return { ...state, showOtp: true };
    }
    case AuthActionTypes.REQUEST_CHANGE_DISPLAY_LANGUAGE_SUCCESS:
      return { ...state, isChangedDisplayLanguage: true };
    case AuthActionTypes.REQUEST_CHANGE_TOKEN:
      return { ...state, isRefreshToken: payload.data };
    case AuthActionTypes.REQUEST_CREATE_MAGIC_LINK:
      return { ...state, createMagicLinkStatus: null, createMagicLinkErrorMessages: '', createMagicLinkLoading: true, loginByMagicLinkStatus: null, loginByMagicLinkErrorMessage: '' };
    case AuthActionTypes.REQUEST_CREATE_MAGIC_LINK_SUCCESS:
      return { ...state, createMagicLinkStatus: 'success', createMagicLinkErrorMessages: '', createMagicLinkLoading: false };
    case AuthActionTypes.REQUEST_CREATE_MAGIC_LINK_ERROR: {
      let createMagicLinkStatus = 'error';
      if (payload.data === 'verification_in_process') {
        createMagicLinkStatus = 'warning';
      }
      return { ...state, createMagicLinkStatus, createMagicLinkErrorMessages: payload.data, createMagicLinkLoading: false };
    }
    case AuthActionTypes.REQUEST_LOGIN_BY_MAGIC_LINK: {
      return { ...state, loginByMagicLinkLoading: true, loginByMagicLinkStatus: null, loginByMagicLinkErrorMessage: '' };
    }
    case AuthActionTypes.REQUEST_LOGIN_BY_MAGIC_LINK_SUCCESS: {
      return { ...state, loginByMagicLinkLoading: false, loginByMagicLInkStatus: 'success' };
    }
    case AuthActionTypes.REQUEST_LOGIN_BY_MAGIC_LINK_ERROR: {
      let loginByMagicLinkStatus = 'warning';
      if (payload.data === 'invalid_credentials') {
        loginByMagicLinkStatus = 'error';
      }
      return { ...state, loginByMagicLinkLoading: false, loginByMagicLinkStatus, loginByMagicLinkErrorMessage: payload.data };
    }
    case AuthActionTypes.REQUEST_RELOAD_STATE_LOGIN_LINK: {
      return {
        ...state,
        loginByMagicLinkStatus: null,
        loginByMagicLinkErrorMessage: '',
        createMagicLinkStatus: null,
        createMagicLinkErrorMessages: '',
      }
    }
    default:
      return state;
  }
};

export default authReducer;
