import React from 'react';
import { ContentContainer, PageTitle, StyledOl, StyledLi } from './styles';

const TermsOfServiceEnglish = () => {
  return (
    <ContentContainer>
      <div>
        <p>April 2021</p>
        <PageTitle>TAIGA BUILDING PRODUCTS LTD. TERMS OF SERVICE</PageTitle>
      </div>
      <div>
        Thank you for selecting the Services offered by Taiga Building Products Ltd. and/or its
        subsidiaries and affiliates (referred to as “Taiga”, “we”, “our” or “us”). Review these
        Terms of Service (the “Agreement”) thoroughly. This Agreement is an agreement between you
        (referred to as “you”, “your” and “User”) and Taiga. By clicking “I Agree”, indicating
        acceptance electronically, or by, accessing or using the Services (as defined below), you
        agree to this Agreement. If you do not agree to this Agreement you may not use the Services.
      </div>
      <StyledOl>
        <StyledLi>
          <strong>GENERAL:</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            <span>In this Agreement &ldquo;</span>
            <strong>Service</strong>
            <span>&rdquo; or &ldquo;</span>
            <strong>Services</strong>
            <span>
              &rdquo; means use of the Taiga website (taiganow.com), platform, establishing an
              account and related applications.{' '}
            </span>
          </StyledLi>
          <StyledLi>
            This Agreement describes the terms governing your use of the Taiga Services. The
            Services includes, but not limited to:
            <p>(a) Taiga&rsquo;s Privacy Policy.</p>
            <p>(b) Additional terms and conditions, which may include those from third parties.</p>
            <p>
              (c) Establishing an account. &nbsp;Accounts you set up are always reviewed and
              approved by the applicable Branch Manager and go through a vetting process if you are
              a new Taiga customer. Accounts are granted at the discretion of Taiga management.
            </p>
            <p>
              (d) Orders Placed. &nbsp;All orders placed through the Taiga website will be reviewed
              and approved before released for pickup or delivery.
            </p>
            <p>
              (e) Pricing. &nbsp;Taiga always uses its best efforts for pricing to be accurate;
              however in the event of a price discrepancy between the price shown on taiganow.com
              and price provided by your Taiga representative, Taiga will honour the lowest pricing
              from the representative or official price communications. Taiga also reserves the
              right to decline purchases based on a &nbsp;pricing error.
            </p>
            <p>
              (f) Shipping and Returns. &nbsp;Items purchased through the Taiga website are subject
              to approval prior to release and shipping of the goods purchased. &nbsp;You are to
              contact your Taiga Representative for accurate shipping schedules.
            </p>
            <p>
              (g) Any terms provided separately to you for the Services, including product terms,
              ordering, account activation, payment terms, data processing agreements, etc.
            </p>
          </StyledLi>
          <StyledLi>
            You must be at least 18 years of age to use our Services. By accessing or using our
            Services you agree that:
            <p>(a) By using your account, you form a binding contract with Taiga;</p>
            <p>(b) You will use only use the Services in Canada and U.S.; </p>
            <p>
              (c) You will comply with this Agreement and all applicable local, provincial, state,
              national and international laws, rules and regulations.{' '}
            </p>
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>YOUR RIGHT TO USE THE SERVICES:</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            <span>
              The Services are protected by copyright, trade secret, and other intellectual property
              laws. You are only granted the right to use the Services and only for the purposes
              described by Taiga. Taiga reserves all other rights in the Services. Until termination
              of this Agreement and as long as you meet any applicable payment obligations and
              comply with this Agreement, Taiga grants to you a personal, limited, nonexclusive,
              non-transferable right and license to use the Services.
            </span>
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>SERVICES:</strong>
        </StyledLi>
        <div>
          <span>
            You acknowledge that our Services as presented in these Term of Service and on our
            website at https://www.taigabuilding.com have been communicated to you and you agree to
            them. You agree that Taiga can change the Services from time to time. Where such changes
            occur Taiga will notify you in accordance with the terms of this Agreement.{' '}
          </span>
        </div>
        <StyledLi>
          <strong>PAYMENT:</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            <span>
              You are bound to the payment terms laid out in your latest agreement. To retrieve a
              copy of your latest agreement please contact your Taiga Representative. If you do not
              have an agreement you are bound to the standard terms noted on your account. For more
              information please contact your Taiga Representative.
            </span>
          </StyledLi>
          <StyledLi>
            <span>
              Upon registering for the Services and establishing your account, you agree to provide
              Taiga with your complete and accurate billing and contact information. This
              information includes but is not limited to your legal company name, street address,
              e-mail address, and the name and telephone number of a person (appointed by you) who
              is responsible for and authorized to make all decisions concerning registration for
              and changes to your account for the Services (the &ldquo;Administrator&rdquo;). The
              Administrator shall act as the billing contact on your behalf. You agree to update the
              above information within thirty days of any change to it. If the contact information
              you provide is false or fraudulent, Taiga reserves the right to terminate your account
              in addition to any other legal remedies. You agree that you are responsible for any
              damages arising from the failure to provide such information or follow this Agreement
              in providing it.
            </span>
          </StyledLi>
          <StyledLi>
            <span>
              If you believe an invoice you have received for products or Services is incorrect, you
              must dispute the invoice by providing us a notification of your dispute (a
              &ldquo;Dispute Notice&rdquo;) within ten days of the date of the invoice (the
              &ldquo;Dispute Period&rdquo;). If payment hasn&rsquo;t been received and a Dispute
              Notice has not been filed, or a Dispute Notice was filed after the Dispute Period,
              Taiga reserves the right to terminate your access to the Services for non-payment.
              Dispute Notices must be sent to your Taiga Representative.{' '}
            </span>
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>ACCOUNT SUSPENSION</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            <span>
              If your account is suspended for non-payment Taiga will store your information (the
              &ldquo;Account Data&rdquo;) for as long as we are legally required to. When Taiga is
              no longer bound to store it, Taiga will dispose of the Account Data in a confidential
              manner. If your account is suspended Taiga will provide you access to your Account
              Data upon request in accordance with the provisions of this Agreement.{' '}
            </span>
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>CANCELLATION </strong>
        </StyledLi>
        <ol>
          <StyledLi>
            <span>
              You can request to cancel the Services by informing us in in writing (a
              &ldquo;Cancellation Notice&rdquo;) at any time. Cancellation Notices can be provided
              to Taiga by emailing taiganow@taigabuilding.com, or by emailing your Taiga Representative
              with the subject line &lsquo;Account Termination&rsquo;.{' '}
            </span>
          </StyledLi>
          <StyledLi>
            <strong>Accessing Your Account Data After Cancellation: </strong>
            <span>
              You have the right under this Agreement to request that Taiga provide you with your
              Account Data that is stored on our platform either upon cancellation by notice or
              cancellation for non-payment as set out above. Taiga has the right to charge you a
              reasonable fee to provide you your Account Data. Such fee must be paid in advance.
              Taiga will use all reasonable efforts to supply you with your Account Data upon
              request and upon payment of the required fee. Taiga bears no responsibility for loss
              of your Account Data if you do not request it within 90 days of cancellation of your
              account.{' '}
            </span>
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>FEES, REFUNDS AND DISPUTES: </strong>
        </StyledLi>
        <ol>
          <StyledLi>
            <span>
              If you dispute a charge and the dispute is inaccurate, you agree to pay TAIGA an
              &ldquo;Administration Fee&rdquo; of not less than $50 and not more than $150. If you
              elevate the dispute to the level of a court of law and lose the dispute you agree to
              indemnify Taiga for all losses relating to defending your claims including without
              limiting the generality of the forgoing Taiga&rsquo;s legal costs on a solicitor and
              own client basis.
            </span>
          </StyledLi>
          <StyledLi>
            <span>
              Checks that are returned NSF (Non-Sufficient Funds) for any reason are subject to a
              $50.00 administration fee. &nbsp;
            </span>
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>SUPPORT BOUNDARIES: </strong>
        </StyledLi>
        <ol>
          <StyledLi>
            Taiga provides support to its customers during regular operation hours, excluding stat
            holidays. Please contact your local Taiga Branch for support hours.
            <p>(a) Taiga provides support related to your registered account on our platform.</p>
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>CONTENT AND USE OF THE SERVICES</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            You agree not to use, nor permit any third party to use, the Services to upload, post,
            distribute, link to, publish, reproduce, engage in, promote or transmit any of the
            following:
            <p>
              (a) Illegal, fraudulent, defamatory, obscene, pornographic, profane, threatening,
              abusive, hateful, harassing, offensive, inappropriate or objectionable information or
              communications of any kind, including without limitation conduct that is excessively
              violent, incites or threatens violence, encourages &quot;flaming&quot; others or
              criminal or civil liability under any local, provincial, federal or foreign law;
            </p>
            <p>
              (b) Content that would impersonate someone else or falsely represent your identity or
              qualifications, or that may constitute a breach of any individual&rsquo;s privacy; is
              illegally unfair or deceptive, or creates a safety or health risk to an individual or
              the public;
            </p>
            <p>(c) Virus, Trojan horse, worm or other disruptive or harmful software or data;</p>
            <p>
              (d) Any Content that you do not own or have the right to use without permission from
              the intellectual property rights owners thereof.
            </p>
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>USE OF THE YOUR PERSONAL INFORMATION</strong>
        </StyledLi>
        <div>
          <span>
            TAIGA will use your personal information in accordance with our Privacy Policy which can
            be accessed here: https://taiganow.com/privacy{' '}
          </span>
        </div>
        <StyledLi>
          <strong>DISCLAIMER OF WARRANTIES</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            <span>
              YOUR USE OF THE SERVICES, AND CONTENT PROVIDED BY TAIGA IS ENTIRELY AT YOUR OWN RISK.
              EXCEPT AS DESCRIBED IN THIS AGREEMENT, THE SERVICES ARE PROVIDED &quot;AS IS.&quot; TO
              THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, TAIGA, ITS AFFILIATES, AND ITS AND
              THEIR THIRD PARTY PROVIDERS, LICENSORS, DISTRIBUTORS OR SUPPLIERS (COLLECTIVELY,
              &quot;SUPPLIERS&quot;) DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY
              WARRANTY THAT THE SERVICES ARE FIT FOR A PARTICULAR PURPOSE, TITLE, MERCHANTABILITY,
              DATA LOSS, NON-INTERFERENCE WITH OR NON-INFRINGEMENT OF ANY INTELLECTUAL PROPERTY
              RIGHTS, OR THE ACCURACY, RELIABILITY, QUALITY OF CONTENT IN OR LINKED TO THE SERVICES.
              TAIGA AND ITS AFFILIATES AND SUPPLIERS DO NOT WARRANT THAT THE SERVICES ARE SECURE,
              FREE FROM BUGS, VIRUSES, INTERRUPTION, ERRORS, THEFT OR DESTRUCTION. IF THE EXCLUSIONS
              FOR IMPLIED WARRANTIES DO NOT APPLY TO YOU, ANY IMPLIED WARRANTIES ARE LIMITED TO 60
              DAYS FROM THE DATE OF PURCHASE OR DELIVERY OF THE SERVICES, WHICHEVER IS SOONER.{' '}
            </span>
          </StyledLi>
          <StyledLi>
            <span>
              TAIGA, ITS AFFILIATES AND SUPPLIERS DISCLAIM ANY REPRESENTATIONS OR WARRANTIES THAT
              YOUR USE OF THE SERVICES WILL SATISFY OR ENSURE COMPLIANCE WITH ANY LEGAL OBLIGATIONS
              OR LAWS OR REGULATIONS.
            </span>
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>LIMITATION OF LIABILITY </strong>
        </StyledLi>
        <ol>
          <StyledLi>
            <span>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE ENTIRE LIABILITY OF TAIGA, ITS
              AFFILIATES AND SUPPLIERS FOR ALL CLAIMS RELATING TO THIS AGREEMENT SHALL BE LIMITED TO
              THE AMOUNT YOU PAID FOR THE SERVICES DURING THE TWELVE (12) MONTHS PRIOR TO SUCH
              CLAIM. SUBJECT TO APPLICABLE LAW, TAIGA, ITS AFFILIATES AND SUPPLIERS ARE NOT LIABLE
              FOR ANY OF THE FOLLOWING: (A) INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE OR
              CONSEQUENTIAL DAMAGES; (B) DAMAGES RELATING TO FAILURES OF TELECOMMUNICATIONS, THE
              INTERNET, ELECTRONIC COMMUNICATIONS, CORRUPTION, SECURITY, LOSS OR THEFT OF DATA,
              VIRUSES, SPYWARE, LOSS OF BUSINESS, REVENUE, PROFITS OR INVESTMENT, OR USE OF SOFTWARE
              OR HARDWARE THAT DOES NOT MEET TAIGA SYSTEMS REQUIREMENTS. THE ABOVE LIMITATIONS APPLY
              EVEN IF TAIGA AND ITS AFFILIATES AND SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OF
              SUCH DAMAGES. THIS AGREEMENT SETS FORTH THE ENTIRE LIABILITY OF TAIGA, ITS AFFILIATES
              AND YOUR EXCLUSIVE REMEDY WITH RESPECT TO THE SERVICES AND ITS USE. &nbsp;
            </span>
          </StyledLi>
          <StyledLi>
            <span>
              You understand that it is your responsibility and in your best interest to inform
              TAIGA about any problem with access to the Services.
            </span>
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>INDEMNIFICATION</strong>
        </StyledLi>
        <div>
          <span>
            You agree to indemnify and hold Taiga and its Affiliates and Suppliers harmless from any
            and all claims, liability and expenses, including reasonable attorneys&#39; fees and
            costs, arising out of your use of the Services or breach of this Agreement (collectively
            referred to as &quot;Claims&quot;). Taiga reserves the right, in its sole discretion and
            at its own expense, to assume the exclusive defense and control of any Claims. You agree
            to reasonably cooperate as requested by Taiga in the defense of any Claims. &nbsp;
          </span>
        </div>
        <StyledLi>
          <strong>COPYRIGHT AND TRADEMARKS</strong>
        </StyledLi>
        <div>
          <span>
            Taiga logos, trademarks, graphics and the general layout and operations of the Services
            are and remain the property of Taiga. &nbsp;Third party trademarks, logos or graphics
            are owned by that third party. &nbsp;No license or other rights in or to such logos,
            graphics and the general layout and operations are granted to you or any party through
            use of the Services. Any rights that may transfer to you in connection with Taiga and/
            or the Services are strictly usage-based rights only and Taiga grants no other rights to
            you in connection therewith. All right, title and interest in and to Taiga&rsquo;s
            provision of the Services under this Agreement, including any materials or information
            disclosed or provided to you by Taiga (or copies thereof) for the purpose of the your
            use of the Services are owned exclusively by Taiga and/ or its affiliates.{' '}
          </span>
        </div>
        <StyledLi>
          <strong>SYSTEM AND NETWORK SECURITY:</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            Users are prohibited from violating or attempting to violate the security of Taiga
            network. Violations of Taiga or the security of its Users may result in civil or
            criminal liability. TAIGA will investigate occurrences, which may involve such
            violations and may involve, and cooperate with, law enforcement and regulatory
            authorities in prosecuting Users who are involved in such violations. These violations
            include, without limitation:
            <p>
              <span>
                (a) Accessing data not intended for such User or logging into an account which such
                User is not authorized to access.
              </span>
            </p>
            <p>
              <span>
                (b) Attempting to probe, scan or test the vulnerability of Taiga or to breach
                security or authentication measures without proper authorization.
              </span>
            </p>
            <p>
              <span>
                (c) Attempting to interfere with Service to any User, without limitation, via means
                of overloading, phishing, &ldquo;Flooding&rdquo;, &ldquo;mail bombing&rdquo;, or
                &ldquo;crashing&rdquo;.
              </span>
            </p>
            <p>
              <span>
                (d) Taking any action in order to obtain Services to which such User is not
                entitled.
              </span>
            </p>
          </StyledLi>
          <StyledLi>
            <span>
              Taiga is under no obligation to monitor any User&rsquo;s activities to determine if a
              violation of this Agreement has occurred, nor does it assume any responsibility
              through this Agreement to monitor or police Internet-related activities. Taiga has no
              right to look at any User&rsquo;s activities unless there are reasonable cause to
              suspect violations of the terms of this Agreement.{' '}
            </span>
          </StyledLi>
        </ol>
        <StyledLi>
          <strong>TERMINATION. </strong>
        </StyledLi>
        <div>
          <span>
            Taiga may, in its sole discretion and without notice, restrict, deny, terminate this
            Agreement or suspend the Services, related or other Services, effective immediately, in
            whole or in part, if we determine that your use of the Services violates the Agreement,
            is improper or substantially exceeds or differs from normal use by other users,
            &nbsp;raises suspicion of fraud, misuse, security concern, illegal activity or
            unauthorized access issues, to protect the integrity or availability of the Services or
            systems and comply with applicable Taiga policy, if you no longer agree to receive
            electronic communications, or if your use of the Services conflicts with Taiga&rsquo;s
            interests or those of another user of the Services. Upon Taiga&rsquo;s notice that your
            use of the Services has been terminated you must immediately stop using the Services and
            any outstanding account payments will become due. Any termination of this Agreement
            shall not affect Taiga&rsquo;s rights to any payments due to it. This Agreement with the
            exception of section 2.1 will survive and remain in effect even if the Agreement is
            terminated. &nbsp; &nbsp;
          </span>
        </div>
        <StyledLi>
          <strong>MISCELLANEOUS PROVISIONS:</strong>
        </StyledLi>
        <ol>
          <StyledLi>
            <strong>Assignment.</strong>
            <span>
              Other than as expressly provided under this Agreement, you shall not transfer or
              assign this Agreement nor your account without the prior written consent of Taiga.
              Taiga may assign this Agreement at any time without consent from or notice to you.{' '}
            </span>
          </StyledLi>
          <StyledLi>
            <strong>We may tell you about other Taiga Services.</strong>
            <span>
              You may be offered other services, products, or promotions by Taiga (&quot;Additional
              Services&quot;). Additional terms and conditions and fees may apply. With some
              Additional Services you may upload or enter data from your account(s) such as names,
              addresses and phone numbers, purchases, etc., to the Internet.
            </span>
          </StyledLi>
          <StyledLi>
            <strong>Communications.</strong>
            <span>
              Taiga may be required by law to send you communications about the Services or third
              party products. You agree that Taiga may send these communications to you via email or
              by posting them on our websites
            </span>
          </StyledLi>
          <StyledLi>
            <strong>You will manage your passwords and accept updates.</strong>
            <span>
              You are responsible for securely managing your password(s) for the Services and to
              contact Taiga if you become aware of any unauthorized access to your account. The
              Services may periodically be updated with tools, utilities, improvements, third party
              applications, or general updates to improve the Services. You agree to receive these
              updates.
            </span>
          </StyledLi>
        </ol>

        <StyledLi>
          <strong>CHANGES. </strong>
        </StyledLi>
        <div>
          <span>
            We reserve the right to modify this Agreement, in our sole discretion, at any time, and
            the modifications will be effective when posted through the Services or on our website
            for the Services or when we notify you by other means. We may also change or discontinue
            the Services, in whole or in part. &nbsp;It is important that you review this Agreement
            whenever we modify it because your continued use of the Services indicates your
            agreement to the modifications.
          </span>
        </div>
        <StyledLi>
          <strong>GOVERNING LAW AND JURISDICTION</strong>
        </StyledLi>
        <div>
          <span>
            You agree that this Agreement shall be governed by the laws of the Province of British
            Columbia and of Canada where applicable. You agree that any dispute arising out of this
            agreement shall be under the jurisdiction of the courts of British Columbia and such
            courts shall have the exclusive jurisdiction to hear, settle and/or determine any
            dispute or claim that arises out of or in connection with this Agreement, including its
            subject matter, its validity and its binding effect on you.{' '}
          </span>
        </div>
      </StyledOl>
    </ContentContainer>
  );
};

export default TermsOfServiceEnglish;
