import React from 'react';
import styled from 'styled-components';
import ModalComponent from '@material-ui/core/Modal';
import { Card } from '@material-ui/core';

const ModalWindow = styled(ModalComponent)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalCard = styled(Card)`
  position: fixed;
  outline: none;
  max-height: 90%;
  overflow: auto;

  @media (max-width: 600px) {
    width: 300px;
  }

  @media (max-width: 320px) {
    width: 250px;
  }
`;

interface IProps {
  isOpen: boolean;
  handleClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children: React.ReactNode;
}

const DateRangePickerModal: React.FC<IProps> = ({ isOpen, handleClose, children }: IProps) => {
  return (
    <ModalWindow open={isOpen} onClose={handleClose}>
      <ModalCard variant="outlined">{children}</ModalCard>
    </ModalWindow>
  );
};

export default DateRangePickerModal;
