import { createSelector } from 'reselect';
import { RootState } from 'store';

const selectUIState = (state: RootState) => state.ui;

export const selectSnackbarType = createSelector(selectUIState, UIState => UIState.type);

export const selectSnackbarMessage = createSelector(selectUIState, UIState => UIState.message);

export const selectSnackbarOpen = createSelector(selectUIState, UIState => UIState.open);
