import React, { useCallback, useEffect, useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import {ProductVariantClass} from 'models/classes/productVariantClass';
import ProductTableHoveredCard from 'pages/Product/ProductTableView/ProductTableHoveredCard/ProductTableHoveredCard';
import ProductTableCard from 'pages/Product/ProductTableView/ProductTableCard/ProductTableCard';
import CustomizedSnackbar from 'components/common/Snackbar/Snackbar';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {selectAddToCartError} from 'ducks/cart/selectors';
import useFilteredProducts from '../hooks/useFilteredProducts';
import {useSnackbarState} from './hooks';
import {ProductCardContainer} from './styles';

interface ITableCards {
  products: ProductVariantClass[];
  categoryName?: string;
  code?: string;
  mainCategoryName?: string;
  id?: string;
  renderProductStock: CallableFunction;
  handleClickProductStock: CallableFunction;
  filterRange?: string;
  pricingForFilter?: string;
}

const ProductTableCards: React.FC<ITableCards> = ({
  products,
  categoryName,
  code,
  mainCategoryName,
  id,
  renderProductStock,
  handleClickProductStock,
  filterRange,
  pricingForFilter,
}: ITableCards) => {
  const {t} = useTranslation();
  const addToCartError = useSelector(selectAddToCartError);

  const [hoveredTableView, setHoveredTableView] = useState(0);

  const handleTableProductViewChange = useCallback((productId: number) => {
    setHoveredTableView(productId);
  }, []);

  const filteredProducts = useFilteredProducts(filterRange, products, pricingForFilter);

  const {openSnackbar, handleCloseSnackBar} = useSnackbarState();

  const handleChangeTableView = () => {
    setHoveredTableView(0);
  };

  useEffect(() => {
    handleChangeTableView();
  }, [products]);

  return (
    <ProductCardContainer>
      {!filteredProducts && (
        <>
          <Skeleton variant="rect" width={201} height={301} style={{margin: '8px'}}/>
          <Skeleton variant="rect" width={201} height={301} style={{margin: '8px'}}/>
          <Skeleton variant="rect" width={201} height={301} style={{margin: '8px'}}/>
          <Skeleton variant="rect" width={201} height={301} style={{margin: '8px'}}/>
        </>
      )}
      {filteredProducts.map(product => (
        <React.Fragment key={product.code}>
          {hoveredTableView !== product.id ? (
            <ProductTableCard
              products={products}
              productVar={product}
              categoryName={categoryName}
              code={code}
              mainCategoryName={mainCategoryName}
              id={id}
              renderProductStock={renderProductStock}
              handleClickProductStock={handleClickProductStock}
              handleTableProductViewChange={handleTableProductViewChange}
            />
          ) : (
            <ProductTableHoveredCard
              productVar={product}
              categoryName={categoryName}
              code={code}
              mainCategoryName={mainCategoryName}
              id={id}
              renderProductStock={renderProductStock}
              handleClickProductStock={handleClickProductStock}
              handleChangeTableView={handleChangeTableView}
              isFeatured={false}
            />
          )}
        </React.Fragment>
      ))}
      <CustomizedSnackbar
        open={openSnackbar}
        status={addToCartError ? 'Error' : 'Success'}
        handleClose={handleCloseSnackBar}
        successMessage={t('products_carousel.added_to_truck')}
        errorMessage={t('products_carousel.product_wasnt_added')}
      />
    </ProductCardContainer>
  );
};

export default ProductTableCards;
