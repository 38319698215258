// Classes
import { SalesRepresentativeClass } from 'models/classes/salesRepresentative';
import { IError } from 'models/interfaces/error';

export enum SalesRepresentativeActionTypes {
  REQUEST_SALES_REPRESENTATIVE = '@@contact/REQUEST_SALES_REPRESENTATIVE',
  REQUEST_SALES_REPRESENTATIVE_SUCCESS = '@@contact/REQUEST_SALES_REPRESENTATIVE_SUCCESS',
  REQUEST_SALES_REPRESENTATIVE_ERROR = '@@contact/REQUEST_SALES_REPRESENTATIVE_ERROR',
}

export interface SalesRepresentativeState {
  readonly salesRepresentative: SalesRepresentativeClass;
  readonly loading: boolean;
  readonly error: IError | null;
}
