import styled from 'styled-components/macro';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';

const StyledCollapse = styled(Collapse)`
  width: 100%;
  .MuiCollapse-wrapper {
    width: 100%;
  }
`;

interface IStyledCard {
  isquotes?: boolean;
}

const StyledCard = styled(Card)`
  box-shadow: none;
  background-color: ${(p: IStyledCard) =>
    !p.isquotes
      ? ({ theme: { colors } }) => colors.lightGrey
      : ({ theme: { colors } }) => colors.pantone};
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Open Sans;
  margin-bottom: 25px;
  @media (min-width: 1280px) {
    margin-right: 40px;
  }
`;

const StyledCardContentOpen = styled(CardContent)`
  border-top: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  background-color: ${({ theme: { colors } }) => colors.white};
  padding: 0;

  &.MuiCardContent-root {
    &:last-child {
      padding-bottom: 0;
    }
  }
`;

const ItemsBlock = styled.div`
  padding: 0 24px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.productBorderGrey};

  @media (max-width: 1023px) {
    padding: 0 10px;
  }
`;

const TagWrapper = styled.div`
  height: 18px;
  min-width: 90px;
  width: max-content;
  background: ${({ theme: { colors } }) => colors.greengreyOpacity(0.2)};
  position: relative;
  transform: rotate(180deg);
  padding: 0 8px;
  border-radius: 4px 0 0 4px;

  &:after {
    border: 9px solid transparent;
    border-left: 7px solid ${({ theme: { colors } }) => colors.greengreyOpacity(0.2)};
    content: '';
    position: absolute;
    right: -16px;
    top: 0px;
  }

  span {
    display: block;
    transform: rotate(180deg);
  }

  margin-top: 9px;
`;

const StatusSteps = styled.div`
  background: #f7f7f7;
  @media (max-width: 1023px) {
    padding-bottom: 15px;
  }
  & + .MuiCardContent-root {
    border-top: 0;
  }
  .title-status-steps {
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    padding: 0 24px;
  }
  .MuiStepConnector-vertical {
    padding: 0;
    margin-left: 14px;
  }
  .MuiStepConnector-lineVertical {
    min-height: 32px;
    border-left-width: 4px;
  }
  .MuiStepper-root {
    padding: 10px 15px;
  }
  .MuiPaper-root {
    background: none;
  }
  .MuiStepIcon-root {
    width: 32px;
    height: 32px;
    color: #fff;
    border-radius: 50%;
    &.MuiStepIcon-active {
      color: #f7f7f7;
      circle {
        stroke: #337d58;
        stroke-width: 3;
      }
      .MuiStepIcon-text {
        fill: #337d58;
      }
    }
    &.MuiStepIcon-completed {
      color: transparent;
      background: #337d58 url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.50005 7.379L12.3941 0.484253L13.4553 1.54475L5.50005 9.5L0.727051 4.727L1.78755 3.6665L5.50005 7.379Z' fill='white'/%3E%3C/svg%3E") no-repeat center center;
      border-radius: 50%;
    }
  }
  .MuiStepConnector-root {
    top: 14px;
    left: calc(-50% + 16px);
    right: calc(50% + 16px);
    &.MuiStepConnector-active {
      .MuiStepConnector-line {
        border-color: #337d58;
      }
    }
  }
  .MuiStepConnector-line {
    border-color: #fff;
  }
  .MuiStepConnector-lineHorizontal {
    border-top-width: 4px;
  }
  .MuiStepIcon-text {
    font-size: 12px;
    font-weight: 600;
    fill: #00133C;
  }
  .MuiStepLabel-root {
    @media (max-width: 1023px) {
      align-items: flex-start;
    }
  }
  .MuiStepLabel-label {
    font-size: 14px;
    font-weight: 600;
    color: #8092AA;
    margin-top: 8px;
    &.MuiStepLabel-active {
      color: #337d58;
    }
    .sm {
      font-size: 12px;
      font-weight: normal;
      margin-top: 3px;
    }
  }
`;

export { StyledCollapse, StyledCard, StyledCardContentOpen, ItemsBlock, TagWrapper, StatusSteps };
