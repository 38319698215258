import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import axios from 'utils/axios';
import { IQuickOrderItem, QuickOrderActionTypes } from './types';

export const getQuickOrderItems = () => ({
  type: QuickOrderActionTypes.REQUEST_GET_QUICK_ORDER_ITEMS,
});

export const addItemToQuickOrder = (payload: {
  productId: number;
  categoryId: number;
}) => ({
  type: QuickOrderActionTypes.ADD_ITEM_TO_QUICK_ORDER,
  payload,
});

export const updateQuickOrderItemQuantity = (payload: {
  quantity: number;
  quickOrderItemId: number;
}) => ({
  type: QuickOrderActionTypes.UPDATE_QUICK_ORDER_ITEM_QUANTITY,
  payload,
});

export const removeQuickOrderItem = (payload: { quickOrderItemId: number }) => ({
  type: QuickOrderActionTypes.REQUEST_REMOVE_QUICK_ORDER_ITEM,
  payload,
});

export const clearQuickOrder = () => ({
  type: QuickOrderActionTypes.REQUEST_CLEAR_QUICK_ORDER,
});

export const bulkAddToCart = (payload: {
  products: { productId: number; quantity: number; categoryId: number }[];
  deliveryType: string;
}) => ({
  type: QuickOrderActionTypes.REQUEST_BULK_ADD_TO_CART,
  payload,
});

export const replaceQuickOrderItems = (payload: { quickOrderItems: IQuickOrderItem[] }) => ({
  type: QuickOrderActionTypes.REPLACE_QUICK_ORDER_ITEMS,
  payload,
});

export const setDisableQuickOrderCheckout = (payload: { disableCheckout: boolean }) => ({
  type: QuickOrderActionTypes.DISABLE_CHECKOUT,
  payload,
});

export const clearSearchTerm = (payload: {value: boolean}) => ({
  type: QuickOrderActionTypes.REQUEST_CLEAR_SEARCH_TERM,
  payload
});

export const replaceQuickOrderItem = (payload: {quickOrderItemId: number, productId: number, categoryId: number}) => ({
  type: QuickOrderActionTypes.REQUEST_REPLACE_QUICK_ORDER_ITEM,
  payload
});

export const bulkAddToCartSuccess = (payload: Array<{
  productId: number;
  quantity: number;
  categoryId: number;
}>) => ({
  type: QuickOrderActionTypes.REQUEST_BULK_ADD_TO_CART_SUCCESS,
  payload
});

export const setQuickOrderAddToCartStatus = (payload: boolean) => ({
  type: QuickOrderActionTypes.REQUEST_SET_QUICK_ORDER_ADD_TO_CART_STATUS,
  payload
});

export const clearQuickOrderProducts = () => ({
  type: QuickOrderActionTypes.REQUEST_CLEAR_QUICK_ORDER
});

export const storeQuickOrder = (quickOrderItems: IQuickOrderItem[]) => ({
  type: QuickOrderActionTypes.REQUEST_GET_QUICK_ORDER_ITEMS_SUCCESS,
  payload: {
    quickOrderItems,
  },
});
export const useGetQuickOrder = (addressId) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['quickOrderItems', addressId],
    queryFn: async () => {
      try {
        if (!addressId) return null;
        const {data} = await axios.get('/api/auth/quick-order/get-quick-order', {
          headers: {
            'X-Address-Id': addressId?.toString(),
          },
        });
        dispatch(storeQuickOrder(data.data));
        return data.data;
      } catch (error) {
        dispatch(storeQuickOrder([]));
        return null;
      }
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });
}