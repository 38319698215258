import styled from 'styled-components/macro';
import { FormControl, MenuItem, Select } from '@material-ui/core';

const Wrapper = styled.div`
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;

  @media (max-width: 1023px) {
    height: 40px;
    padding: 0;
    width: 100%;
    justify-content: flex-start;
  }
`;

const StyledSelect = styled(Select)`
  .MuiSelect-select {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 182px;
    height: 30px;
    padding: 0 5px 0 0;

    div {
      display: none;
    }

    @media (max-width: 1023px) {
      width: 100%;
    }
  }

  &:focus {
    background-color: ${({ theme: { colors } }) => colors.white};
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0 5px;
  }
`;

const StyledFormControl = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 16px 8px 16px 10px;
  width: 100%;
`;

const StyledParagraph = styled.p`
  font-family: Open Sans;
  font-weight: 400;
  font-size: 14px;
  text-align: right;
`;

export { StyledParagraph, StyledMenuItem, StyledFormControl, StyledSelect, Wrapper };
