import { IProductAssociation } from 'models/interfaces/productVariant';
import { ProductVariantClass } from 'models/classes/productVariantClass';
import { UserClass } from 'models/classes/userClass';
import { AssociationVariantClass } from 'models/classes/associationClass';
import get from 'lodash/get';

const filterAssociations = (associations: IProductAssociation[], code: string) => {
  const filteredByCode =
    associations.length > 0
      ? associations.filter(association => association.code === code)[0]
      : null;

  return filteredByCode
    ? filteredByCode.associatedProductVariants.filter(
      product => typeof product === 'object' && product !== null,
    )
    : [];
};

export const mapAssociatedProducts = (associations: IProductAssociation[]) => {
  const similarProducts = filterAssociations(associations, 'sys_similar_prod');
  const alsoLikeProducts = filterAssociations(associations, 'sys_you_may_also_like');
  const accessories = filterAssociations(associations, 'Accessories');

  const similarProductsToStore =
    similarProducts.length > 0
      ? similarProducts.map(similarProduct => new AssociationVariantClass(similarProduct))
      : [];
  const alsoLikeProductsToStore =
    alsoLikeProducts.length > 0
      ? alsoLikeProducts.map(alsoLikeProduct => new AssociationVariantClass(alsoLikeProduct))
      : [];
  const accessoriesToStore =
    accessories.length > 0
      ? accessories.map(accessory => new AssociationVariantClass(accessory))
      : [];

  return {
    similarProductsToStore,
    alsoLikeProductsToStore,
    accessoriesToStore,
  };
};

export const getVariantOptions = (variants: ProductVariantClass[], singleProduct: boolean) => {
  let variantOptions = [];

  if (singleProduct) {
    variants.forEach(variant => {
      variant.optionValues.forEach(option => {
        if (
          variantOptions.filter(variantOption => variantOption.optionCode === option.optionCode)
            .length === 0
        ) {
          const newOption = {
            optionCode: option.optionCode,
            values: [
              {
                code: option.code,
                value: option.value,
                image: option.image,
              },
            ],
          };

          variantOptions.push(newOption);
        } else {
          variantOptions = variantOptions.map(variantOption => {
            if (variantOption.optionCode === option.optionCode) {
              const newValues = variantOption.values;

              if (
                variantOption.values.filter(singleOption => singleOption.code === option.code)
                  .length === 0
              ) {
                newValues.push({
                  code: option.code,
                  value: option.value,
                  image: option.image,
                });
              }

              return { ...variantOption, values: newValues };
            }

            return variantOption;
          });
        }

        return option;
      });

      return variant;
    });
  }

  return variantOptions;
};

export const getNecessarySalesRep = (user: UserClass, addressCode: string) => {
  const address = user.addresses.find(singleAddress => singleAddress.erpPostCode === addressCode);

  if (address && address.salesRepresentative) {
    return address.salesRepresentative;
  }

  if (user && user.defaultAddress && user.defaultAddress.salesRepresentative) {
    return user.defaultAddress.salesRepresentative;
  }

  return null;
};

export const mapNumberToDefaultPricingType = (user: UserClass) => {
  const { defaultPricing } = user;

  switch (defaultPricing) {
    case 'delivery':
      return 0;
    case 'pickup':
      return 1;
    default:
      return 2;
  }
};

export const getDefaultProductName = (product: ProductVariantClass) => {
  return get(product, 'defaultLanguage.name', product.erpSku);
}