import { Reducer } from 'redux';

// Types
import { BranchActionTypes, BranchState } from 'ducks/branch/types';

const initialState: BranchState = {
  branch: null,
  branches: [],
  loading: false,
  error: null,
};

const branchReducer: Reducer<BranchState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case BranchActionTypes.REQUEST_BRANCH: {
      return { ...state, loading: true };
    }
    case BranchActionTypes.REQUEST_BRANCH_SUCCESS: {
      return { ...state, loading: false, branch: payload.branch };
    }
    case BranchActionTypes.REQUEST_BRANCH_ERROR: {
      return { ...state, loading: false, error: payload.error };
    }
    case BranchActionTypes.REQUEST_BRANCHES: {
      return { ...state, loading: true, branches: [] };
    }
    case BranchActionTypes.REQUEST_BRANCHES_SUCCESS: {
      return { ...state, loading: false, branches: payload.branches };
    }
    case BranchActionTypes.REQUEST_BRANCHES_ERROR: {
      return { ...state, loading: false }
    }
    default:
      return state;
  }
};

export default branchReducer;
