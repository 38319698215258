import styled from 'styled-components/macro';
import { Dialog, DialogContent } from '@material-ui/core';

const StylesDialog = styled(Dialog)`
  &.added-to-truck-modal {
    .MuiBackdrop-root {
      background: none;
    }
    .MuiPaper-root {
      text-align: center;
      border-radius: 0;
      background: rgba(0, 0, 0, 0.8);
      max-width: 310px;
    }
    .MuiDialogContent-root {
      padding: 24px 30px 30px;
    }
    .MuiTypography-root {
      margin: 0;
    }
    .ic {
      margin-bottom: 15px;
    }
    .txt {
      font-size: 16px;
      color: #fff;
    }
  }
`

const StylesDialogContent = styled(DialogContent)`
`;

export {
  StylesDialog,
  StylesDialogContent,
};
