import React, { Dispatch, SetStateAction } from 'react';
import { usePopupScrollEffect } from 'hooks/usePopupScrollEffect';

import { useTranslation } from 'react-i18next';
import {
  CheckboxTitle,
  CheckboxWrapper,
  PopUpContentWrapper,
  StepTitle,
  StyledButton,
  GreenCheckbox,
  StepBoxContainer,
  StepBox,
} from 'components/Popups/StartSettings/styles';

interface IChoosePricingProps {
  setMailChimpEnabled: Dispatch<SetStateAction<boolean>>;
  isMailChimpEnabled: boolean;
  handleSave: () => void;
}

const ChooseMailchimpStep: React.FC<IChoosePricingProps> = ({
  handleSave,
  setMailChimpEnabled,
  isMailChimpEnabled,
}: IChoosePricingProps) => {
  usePopupScrollEffect();

  const handleCheck = (value: boolean) => {
    setMailChimpEnabled(value);
  };

  const { t } = useTranslation();

  return (
    <PopUpContentWrapper>
      <div>
        <StepTitle>{t('start_settings_popup.step3_title')}</StepTitle>
        <CheckboxWrapper onClick={() => handleCheck(!isMailChimpEnabled)}>
          <GreenCheckbox name="isMailChimpEnabled" checked={isMailChimpEnabled} disableRipple />
          <CheckboxTitle>{t('start_settings_popup.mailchimp_agreement')}</CheckboxTitle>
        </CheckboxWrapper>
      </div>
      <div>
        <StyledButton onClick={handleSave}>
          <p>{t('start_settings_popup.start_shopping')}</p>
        </StyledButton>
        <StepBoxContainer>
          <StepBox />
          <StepBox />
          <StepBox />
        </StepBoxContainer>
      </div>
    </PopUpContentWrapper>
  );
};

export default ChooseMailchimpStep;
