import React from 'react';
import styled from 'styled-components/macro';
import { Popover } from '@material-ui/core';

const ProductInfoPartContainer = styled.div`
  display: flex;
  width: 100%;
  margin-left: 36px;
  margin-top: 37px;
  margin-bottom: 70px;

  @media (max-width: 1023px) {
    flex-direction: column;
    margin: 0;
  }
`;

const SliderContainer = styled.div`
  width: 450px;
  height: 520px;

  @media (max-width: 1023px) {
    width: 100%;
    height: auto;
  }
`;

const ProductInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 36px;
  width: 100%;
  word-wrap: break-word;
  @media (max-width: 1023px) {
    margin: 0;
  }
`;

const ProductName = styled.h1`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 46px;
  letter-spacing: 0.01em;
  margin: 0;

  @media (max-width: 1023px) {
    font-size: 24px;
    line-height: 33px;
  }
`;

const ProductSku = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  margin-top: 5px;
  margin-bottom: 0;
  color: ${({ theme: { colors } }) => colors.grey};

  @media (max-width: 1023px) {
    margin-top: 0;
  }
`;

const ProductInStock = styled(({ stock, status, children, t, ...rest }) => (
  <p {...rest}> {children} </p>
))`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: ${props => {
    if (props.waitingOnStock) {
      return props.theme.colors.waitingOnStockBlue;
    }
    if (props.inStock) {
      return props.theme.colors.mainGreen;
    }

    return props.theme.colors.red;
  }};
  margin: 0;
  > * {
    display: block;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .txt-stock {
    cursor: pointer;
  }
`;

const ProductDescriptionShort = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin: 24px 0;

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 21px;
    margin-top: 64px;
  }
`;

const ProductPriceSectionWrapper = styled(({ border, children, ...rest }) => (
  <div {...rest}> {children} </div>
))`display: flex;
  flex-direction: column;
  padding: ${props => {
    if (props.border) {
      return '24px 0 32px 0';
    }
    return '24px 0 24px 0';
  }};
  border-bottom: ${props => {
    if (props.border) {
      return `1px solid ${props.theme.colors.productBorderGrey}`;
    }
    return 'none';
  }};

  @media (max-width: 1023px) {
    padding: 24px 0;
  }`;

const ProductPriceWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const ProductPrice = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.01em;
  margin: 0;
  color: ${({ theme: { colors } }) => colors.mainGreen};
`;

const ProductPackage = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.01em;
  margin: 0;
  color: ${({ theme: { colors } }) => colors.grey};
`;

const ProductSquarePrice = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.01em;
  margin: 0;
  color: ${({ theme: { colors } }) => colors.textColor};
`;

const ProductAttributeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.productBorderGrey};
  position: relative;
  &:last-child {
    border: none;
  }
  &.highlight {
    .info-section-title {
      @media only screen and (min-width: 768px) {
        color: ${({ theme: { colors } }) => colors.mainGreen};
      }
    }
    .box-select {
      border: 1px solid ${({ theme: { colors } }) => colors.mainGreen};
    }
  }
  &.warning {
    .box-select {
      border: 1px solid #ffdcbc;
      box-shadow: 0 4px 6px 1px rgb(255 220 188 / 35%);
      .MuiSelect-select {
        padding-right: 45px;
      }
      .ic-warning {
        display: block;
      }
    }
  }
  .box-select {
    position: relative;
    max-width: 351px;
  }
  .ic-warning {
    display: none;
    position: absolute;
    right: 34px;
    top: 9px;
    z-index: 2;
  }
`;

const InfoSectionTitle = styled.p`
  margin-top: 24px;
  margin-bottom: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.grey};
`;

const WarningNote = styled.div`
  position: relative;
  max-width: 351px;
  border-radius: 5px;
  background: #f7f7f7;
  padding: 14px 42px;
  .ic-warning {
    position: absolute;
    left: 13px;
    top: 15px;
  }
`;

const ColorCirclesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  margin-bottom: 24px;
`;

const ColorCircle = styled(({ color, children, ...rest }) => <div {...rest}> {children} </div>)`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: ${props => (props.color ? `url(${props.color})` : props.theme.colors.black)};
`;

const AttributePapersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  margin-bottom: 20px;
`;

const AttributePaper = styled(({ selected, children, ...rest }) => (
  <button {...rest} type="button">
    {' '}
    {children}{' '}
  </button>
))`
  padding: 12px 12px;
  border: 1px solid
    ${props => (props.selected ? props.theme.colors.mainGreen : props.theme.colors.borderGrey)};
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 8px;
  background: ${({ theme: { colors } }) => colors.white};
  outline: none;
`;

const AttributePaperTitle = styled.p`
  margin: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.01em;
`;

const TotalWrapper = styled.div`
  .table-total {
    width: auto;
    table-layout: fixed;
    border-spacing: 0;
    margin-bottom: 50px;
    tr {
      th,
      td {
        font-weight: normal;
        vertical-align: top;
        padding: 0;
      }
      th {
        padding-right: 10px;
      }
    }
    .old-price {
      font-weight: bold;
      text-decoration: line-through;
    }
    .price {
      font-weight: bold;
      font-size: 2.2em;
      line-height: 1;
      margin-bottom: 10px;
    }
  }
  .saving_wrapper {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    overflow: visible;
    white-space: nowrap;
    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 10px;
      bottom: 1px;
      transform-origin: right top;
      transform: translate(0, 100%) rotate(-5deg);
      width: 75px;
      height: 0;
      box-shadow: 0 0 7px 1px rgba(91, 91, 91, 1);
      z-index: 2;
    }
    &:after {
      display: block;
      content: '';
      position: absolute;
      right: 10px;
      bottom: 1px;
      transform-origin: left top;
      transform: translate(0, 100%) rotate(5deg);
      width: 75px;
      height: 0;
      box-shadow: 0 0 7px 1px rgba(91, 91, 91, 1);
      z-index: 2;
    }
    .saving_inner {
      position: relative;
      background: #eefef1;
      padding: 5px 15px;
      z-index: 3;
    }
    .saving_percent {
      font-weight: bold;
      font-size: 1.2em;
      color: ${({ theme: { colors } }) => colors.mainGreen};
    }
    .el-break {
      display: none;
    }
  }
  .additional_freight {
    color: var(--neutral-600, #48576B);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 10px;
    margin-top: 0;
  }
`;

const AdditionalFreight = styled.p`
  color: var(--neutral-600, #48576B);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 10px;
  margin-top: 0;
`;

const TotalText = styled.p`
  color: var(--neutral-800, #00133C);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.1px;
  margin: 0;
`;

const StyledButton = styled.button`
  cursor: pointer;
  border: none;
  background: ${({ theme: { colors } }) => colors.mainGreen};
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 0 13px 0;
  outline: none;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.mainGreenHover};
  }

  &:active {
    background-color: ${({ theme: { colors } }) => colors.mainGreefPassed};
  }

  @media (max-width: 1023px) {
    max-width: 100%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledInquiryButton = styled.button`
  color: var(--primary-800, #007A33);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 175% */
  letter-spacing: 0.1px;
  width: 100%;
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--Corner-Medium, 8px);
  border: 1px solid var(--neutral-400, #B2BECC);
  background: none;
  cursor: pointer;
`;


const ButtonTitle = styled.p`
  margin: 0;
  color: ${({ theme: { colors } }) => colors.white};
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
`;

const StyledPopover = styled(Popover)`
  margin-bottom: 15px;
  pointer-events: none;
`;

const PopoverColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  width: 100px;
`;

const PopoverColor = styled(({ color, children, ...rest }) => <div {...rest}> {children} </div>)`
  width: 100%;
  height: 64px;
  background: ${props => (props.color ? `url(${props.color})` : props.theme.colors.black)};
`;

const PopoverText = styled.p`
  margin: 3px 0 0 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
`;

const ColorWithPopover = styled(({ color, children, ...rest }) => (
  <button {...rest} type="button">
    {' '}
    {children}{' '}
  </button>
))`
  margin-right: 10px;
  border-radius: 50%;
  padding: 3px;
  background: ${({ theme: { colors } }) => colors.white};
  border: none;
  border: ${props => (props.selected ? `1px solid ${props.theme.colors.mainGreen}` : 'none')};
  opacity: ${props => (props.disabled ? '0.3' : 'none')};
  outline: none;
`;

const NextTierPriceWrapper = styled.div`
  display: flex;
`;

const NextTierPriceInner = styled.div`
  display: flex;
  cursor: pointer;
  p {
    color: ${({ theme: { colors } }) => colors.mainGreen};
    text-decoration: underline;
  }
  &:hover > p {
    font-size: 18px;
    font-weight: 900;
  }
`;

const NextTierPriceText = styled.p`
  font-family: Open Sans,serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
  margin: 0;
`;

const NextTierPrice = styled.p`
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  margin: 0;
`;

const StyledLoadingDiv = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 60px;
  border: 10px solid ${({ theme: { colors } }) => colors.mainGreen};
  border-top-color: ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  animation: loading 1.2s linear infinite;
  -webkit-animation: loading 1.2s linear infinite;
`;

const StyledLoadingDivWrapper = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  ProductInfoPartContainer,
  SliderContainer,
  ProductInfoWrapper,
  ProductName,
  ProductSku,
  ProductInStock,
  ProductDescriptionShort,
  ProductPriceSectionWrapper,
  ProductPriceWrapper,
  ProductPrice,
  ProductPackage,
  ProductSquarePrice,
  ProductAttributeWrapper,
  InfoSectionTitle,
  WarningNote,
  ColorCirclesWrapper,
  ColorCircle,
  AttributePapersWrapper,
  AttributePaper,
  AttributePaperTitle,
  TotalWrapper,
  TotalText,
  StyledButton,
  ButtonTitle,
  StyledPopover,
  PopoverColorWrapper,
  PopoverColor,
  PopoverText,
  ColorWithPopover,
  NextTierPriceWrapper,
  NextTierPriceInner,
  NextTierPriceText,
  NextTierPrice,
  StyledLoadingDiv,
  StyledLoadingDivWrapper,
  AdditionalFreight,
  StyledInquiryButton
};
