import React from 'react';
import styled from 'styled-components';

import errorImage from 'assets/images/400.png';

import { useTranslation } from 'react-i18next';

const MainContainer = styled.div`
  @media (max-width: 768px) {
    padding: 0 30px;
    margin: 25px auto 0;
  }
  @media (max-width: 599px) {
    padding: 0;
  }
  @media (max-width: 320px) {
    padding: 0 11px;
    margin: 10px auto 0;
  }
`;

const ContentContainer = styled.div`
  margin: 139px auto 0;
  padding: 0 20px 56px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    margin: 48px auto 0 auto;
    padding-bottom: 40px;
  }
  @media (max-width: 320px) {
    margin: 40px auto 0 auto;
  }
`;

const ImageDiv = styled.div`
  background: url(${errorImage}) center center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 476px;
  height: 200px;

  @media (max-width: 768px) {
    width: 369px;
    height: 155px;
  }
  @media (max-width: 380px) {
    width: 310px;
    height: 130.25px;
  }
  @media (max-width: 320px) {
    width: 304px;
    height: 128px;
  }
`;

const TextDiv = styled.div`
  max-width: 745px;
  font-family: Open Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.01em;
  text-align: center;
  margin: 35px auto 48px;
  @media (max-width: 768px) {
    font-size: 24px;
    margin-top: 12px;
    margin-bottom: 36px;
  }
  @media (max-width: 599px) {
    max-width: 100%;
  }
  @media (max-width: 380px) {
    font-size: 19px;
    margin-top: 8px;
    margin-bottom: 24px;
  }
  @media (max-width: 320px) {
    font-size: 18px;
  }
`;

const Error400: React.FC = () => {

  const { t } = useTranslation();

  return (
    <MainContainer>
      <ContentContainer>
        <ImageDiv />
        <TextDiv>{t('errors.400_title')}</TextDiv>
      </ContentContainer>
    </MainContainer>
  );
};

export default Error400;
