import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
  Slide,
} from '@material-ui/core';

import { useFormik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { authErrorsSelector, getLoading, selectPasswordRequestSent } from 'ducks/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { AuthActionTypes } from 'ducks/auth/types';

import * as Yup from 'yup';

import { useTranslation, Trans } from 'react-i18next';

const StyledGrid = styled(Grid)`
  padding: 0;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 100px;

  @media (max-width: 737px) {
    padding-top: 9px;
    padding-bottom: 38px;
  }
`;

const StyledTypography = styled(Typography)`
  align-items: center;
  letter-spacing: 0.01em;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 150%;
  color: #333333;
  padding-bottom: 24px;
` as typeof Typography;

const StyledCard = styled(Card)`
  max-width: 440px;
  margin: 0 auto;

  @media (max-width: 737px) {
    margin: 0 10px;
  }
`;

const StyledButton = withStyles({
  root: {
    textAlign: 'center',
    width: '100%',
    height: '48px',
    backgroundColor: '#00853e',
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    letterSpacing: '0.01em',
    fontWeight: 'bold',
    color: '#FFFFFF',
    '&$disabled': {
      color: '#FFFFFF',
    },
    '&:hover': {
      backgroundColor: '#37875F',
    },
  },
  disabled: {
    backgroundColor: '#DADCE0',
  },
  label: {
    textTransform: 'none',
  },
})(Button);

const StyledCardContent = styled(CardContent)`
  padding-left: 60px;
  padding-top: 0;
  padding-right: 60px;
  @media (max-width: 440px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const StyledCardActions = styled(CardActions)`
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 50px;
  @media (max-width: 440px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  padding-top: 50px;
  padding-left: 60px;
  padding-bottom: 12px;

  div > span {
    font-family: Open Sans, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 0.01em;
  }
  @media (max-width: 440px) {
    padding-top: 20px;
    padding-left: 20px;
  }
`;

const StyledMessage = styled(Typography)`
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
`;

const StyledReturnLink = styled(RouterLink)`
  color: #00853e;
  text-decoration: none;
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
`;

const StyledTextField = withStyles({
  root: {
    paddingBottom: '12px',
    '& label.Mui-focused': {
      color: '#00853e',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#00853e',
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#C4C4C4',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00853e',
        },
      },
    },
    '& .MuiFormLabel-root': {
      fontFamily: 'Open Sans',
    },
  },
})(TextField);

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('invalid_email')
    .required('email_required')
    .max(255),
});

const initialValues = {
  email: '',
};

const ResetPassword: React.FC = () => {
  const dispatch = useDispatch();

  const [stepFirst, setStepFirst] = useState(true);
  const error = useSelector(authErrorsSelector);
  const [clicked, setClicked] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const requestSent = useSelector(selectPasswordRequestSent);
  const loading = useSelector(getLoading);
  const initialErrors = {};
  const initialTouched = {};

  useEffect(() => {
    dispatch({
      type: AuthActionTypes.RESET_PASSWORD_CLEAR_STATE,
    });
  }, [dispatch]);

  useEffect(() => {
    if (clicked && requestSent && !loading) {
      setStepFirst(false);
    }

    if (clicked && !requestSent && !loading && error) {
      setEmailError(true);
    }
  }, [requestSent, clicked, loading, error]);

  const { values, touched, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit: ({ email }) => {
      setClicked(true);
      dispatch({
        type: AuthActionTypes.RESET_PASSWORD,
        payload: {
          email,
        },
      });
    },
    validationSchema,
    initialErrors,
    initialTouched,
    enableReinitialize: true,
  });

  const { t } = useTranslation();

  return (
    <Slide direction="down" in>
      <StyledGrid item xs={12} sm={8} md={8}>
        <StyledCard variant="outlined">
          <StyledCardHeader title={t('password_recovery.title').toString()} data-test-id="password-recovery-sent" />
          <form onSubmit={handleSubmit}>
            <StyledCardContent>
              {stepFirst ? (
                <>
                  <StyledTypography>{t('password_recovery.enter_email').toString()}</StyledTypography>
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    error={!!(errors.email && touched.email) || emailError}
                    label={t('password_recovery.email').toString()}
                    name="email"
                    value={values.email}
                    onChange={e => {
                      handleChange(e);
                      setEmailError(false);
                    }}
                    onBlur={handleBlur}
                    helperText={
                      (errors.email && touched.email && t(`password_recovery.${errors.email}`).toString()) ||
                      (emailError && error ? t(`password_recovery.${error.errors.message}`).toString() : '')
                    }
                    margin="dense"
                    data-test-id="forgot-email-input"
                  />
                </>
              ) : (
                <StyledMessage>
                  <Trans i18nKey="password_recovery.notification" values={{ mail: values.email }}>
                    A new password has been sent to <strong>{values.email}</strong>. Please check
                    your email.
                  </Trans>
                </StyledMessage>
              )}
            </StyledCardContent>
            <StyledCardActions>
              <Grid container direction="row">
                {stepFirst ? (
                  <StyledButton type="submit" disabled={!values.email} data-test-id="get-a-recovery-link">
                    {t('password_recovery.button').toString()}
                  </StyledButton>
                ) : (
                  <StyledReturnLink to="sign-in">
                    {t('password_recovery.back_sign_in').toString()}
                  </StyledReturnLink>
                  // <StyledReturnLink to={`/login?email=${values.email}&token=${token}`}>Back to Sign In</StyledReturnLink>
                )}
              </Grid>
            </StyledCardActions>
          </form>
        </StyledCard>
      </StyledGrid>
    </Slide>
  );
};

export default ResetPassword;
