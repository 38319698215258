import styled from 'styled-components/macro';
import { Typography } from '@material-ui/core';

const NoOrdersText = styled(Typography)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.textColor};
`;

const OrdersList = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoadNextListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledLoadButton = styled.button`
  width: 241px;
  height: 48px;
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { colors } }) => colors.white};
  margin-top: 47px;
  outline: none;

  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.01em;
    color: ${({ theme: { colors } }) => colors.mainGreen};
  }

  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme: { colors } }) => colors.mainGreenHover};
  }

  &:active {
    border: 1px solid ${({ theme: { colors } }) => colors.mainGreen};
  }

  &:disabled {
    border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};

    p {
      color: ${({ theme: { colors } }) => colors.borderGrey};
    }
  }

  @media (max-width: 1023px) {
    height: 40px;
    margin: 25px 5px;

    p {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const LoadNextTitle = styled.p`
  color: ${({ theme: { colors } }) => colors.mainGreen};
  margin: 0;
  font-size: 16px;
  font-family: Open Sans;
  line-height: 100%;
  letter-spacing: 0.01em;
  font-weight: 600;
`;

export { NoOrdersText, OrdersList, LoadNextListWrapper, StyledLoadButton, LoadNextTitle };
