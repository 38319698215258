export enum CheckoutActionTypes {
  CHANGE_FILED = '@@checkout/CHANGE_FIELD',
  ADD_ITEM_ERROR = '@@checkout/ADD_ITEM_ERROR',
  REMOVE_ITEM_ERROR = '@@checkout/REMOVE_ITEM_ERROR',
}

export type Order = 'order' | 'quote';

export interface ErrorItem {
  itemId: number;
  hasError: boolean;
}
export interface CheckoutState {
  readonly order: Order;
  step: CheckoutStep;
  readonly shippingMethod: string | null;
  readonly addressWasChanged: boolean;
  readonly previousSelectedAddress: string;
  readonly itemErrors: ErrorItem[];
}

export enum CheckoutStep {
  FIRST = 1,
  SECOND = 2,
}
