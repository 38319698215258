import React from 'react';
import Carousel from 'nuka-carousel';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { LANDING_IMAGES_URL } from './constants';

const SectionBenefits = styled.div`
  width: 100%;
  min-width: 320px;
  margin: 0 auto;
  font-family: Open Sans;
`;

const FirstPart = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.white};

  @media (max-width: 810px) {
    padding: 0;
    margin-bottom: -10px;
  }

  @media (max-width: 720px) {
    height: auto;
  }
`;

const OurBenefitsWrapper = styled.div`
  position: relative;
  margin-top: -120px;
  margin-bottom: -116px;
  z-index: 1;
  max-width: 1200px;
  padding: 40px;
  background: ${({ theme: { colors } }) => colors.white};
  box-shadow: 0px 0px 20px ${({ theme: { colors } }) => colors.blackOpacity(0.15)};
  border-radius: 4px;
  font-family: Open Sans;

  @media (max-width: 1280px) {
    max-width: 1000px;
  }

  @media (max-width: 1024px) {
    max-width: 880px;
    margin: -80px 0;
  }

  @media (max-width: 910px) {
    max-width: 800px;
  }

  @media (max-width: 810px) {
    max-width: 720px;
  }

  @media (max-width: 720px) {
    margin: -7px 0 0;
  }

  @media (max-width: 720px) {
    padding: 40px 10px;
  }
`;

const OurBenefitsWrapperTitle = styled.div`
  display: flex;
  margin: 40px 97px 40px 95px;

  span {
    font-size: 50px;
    font-weight: 800;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-shadow: 0px 0px 20px ${({ theme: { colors } }) => colors.blackOpacity(0.25)};
  }

  @media (max-width: 1024px) {
    margin: 40px 0px 24px;
  }

  @media (max-width: 880px) {
    span {
      font-size: 35px;
    }
  }

  @media (max-width: 837px) {
    span {
      font-size: 36px;
    }
  }

  @media (max-width: 720px) {
    margin: 0px auto 25px;

    span {
      font-size: 26px;
    }
  }
`;

const OurBenefitsTiles = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @media (max-width: 720px) {
    justify-content: center;
    margin-bottom: -13px;
  }
`;

const OurBenefitTile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 192px;
  height: 260px;
  padding: 39px 15px 24px 15px;

  p {
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    margin-top: 21px;
    margin-bottom: 5px;
  }

  &:hover {
    box-shadow: 0px 0px 20px ${({ theme: { colors } }) => colors.blackOpacity(0.1)};
    border-radius: 10px;
  }

  @media (max-width: 1200px) {
    width: 155px;
  }

  @media (max-width: 1024px) {
    width: 146px;
    height: 198px;
    padding-top: 30px;

    img {
      width: 76px;
      height: 76px;
    }

    p {
      font-size: 14px;
      margin-top: 16px;
    }
  }

  @media (max-width: 880px) {
    width: 115px;
  }

  @media (max-width: 720px) {
    width: 192px;
    height: 260px;
    padding-top: 39px;
    box-shadow: 0px 0px 20px ${({ theme: { colors } }) => colors.blackOpacity(0.1)};
    border-radius: 10px;

    img {
      width: 100px;
      height: 100px;
    }

    p {
      margin-top: 21px;
      font-size: 16px;
    }
  }
`;

const SecondPart = styled.div`
  background-image: url(${LANDING_IMAGES_URL}/taiga_background_benefit.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 698px;

  @media (max-width: 1024px) {
    height: 619px;
  }

  @media (max-width: 880px) {
    height: 560px;
  }

  @media (max-width: 720px) {
    height: 370px;
  }

  @media (max-width: 480px) {
    display: flex;
    justify-content: center;
    height: 268px;
    background-size: cover;
  }

  @media (max-width: 480px) {
    background-position-x: -75px;
  }
`;

const TitleContainer = styled.div`
  width: 1000px;
  margin: 0 auto;

  span {
    font-weight: 800;
    font-size: 50px;
    line-height: 120%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: ${({ theme: { colors } }) => colors.white};
    text-shadow: 0px 0px 20px ${({ theme: { colors } }) => colors.blackOpacity(0.55)};
  }

  @media (max-width: 1280px) {
    max-width: 880px;
  }

  @media (max-width: 910px) {
    max-width: 810px;
  }

  @media (max-width: 810px) {
    span {
      font-size: 38px;
    }
  }

  @media (max-width: 820px) {
    text-align: center;
  }

  @media (max-width: 720px) {
    span {
      font-size: 26px;
      text-align: center;
      margin: 0px 10px;
    }
  }
`;

const ShadowTopBackground = styled.div`
  height: 94px;
  width: 100%;
  background: linear-gradient(
    180deg,
    ${({ theme: { colors } }) => colors.white} 0%,
    ${({ theme: { colors } }) => colors.invisible} 100%
  );
  position: absolute;
  top: 0;

  @media (max-width: 1024px) {
    height: 80px;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

const ShadowBottomBackground = styled.div`
  height: 94px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    180deg,
    ${({ theme: { colors } }) => colors.white} 0%,
    ${({ theme: { colors } }) => colors.invisible} 100%
  );
  transform: rotate(-180deg);
  z-index: 1;

  @media (max-width: 1024px) {
    height: 80px;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

const OurBenefitsStatic = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 720px) {
    display: none;
  }
`;

const OurBenefitsCarousel = styled.div`
  width: 100%;

  @media (min-width: 721px) {
    display: none;
  }
`;

const OurBenefitCarouselTile = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  width: 100%;
  height: 319px;
`;

const useStyles = makeStyles({
  span: {
    '@media (max-width: 376px)': {
      fontSize: '24px !important',
    },
  },
});

const autoplay = true;
const infiniteScroll = true;

interface TextProps {
  titleFirst: string;
  benefitFirst: string;
  benefitSecond: string;
  benefitThird: string;
  benefitFourth: string;
  benefitFifth: string;
  titleSecond: string;
  isEnglish: boolean;
}

const BenefitsSection: React.FC<TextProps> = ({
  titleFirst,
  benefitFirst,
  benefitSecond,
  benefitThird,
  benefitFourth,
  benefitFifth,
  titleSecond,
  isEnglish,
}: TextProps) => {
  const classes = useStyles();

  return (
    <SectionBenefits>
      <FirstPart>
        <OurBenefitsWrapper>
          <OurBenefitsWrapperTitle>
            <span className={!isEnglish ? classes.span : ''}>{titleFirst}</span>
          </OurBenefitsWrapperTitle>
          <OurBenefitsTiles>
            <OurBenefitsCarousel>
              <Carousel
                cellAlign="center"
                autoplay={autoplay}
                wrapAround={infiniteScroll}
                renderCenterLeftControls={() => null}
                renderCenterRightControls={() => null}
                defaultControlsConfig={{
                  pagingDotsStyle: {
                    fill: '#00853e',
                  },
                }}
              >
                <OurBenefitCarouselTile>
                  <OurBenefitTile>
                    <img src={`${LANDING_IMAGES_URL}/benefit1.png`} alt="secure" width="100" />
                    <p>{benefitFirst}</p>
                  </OurBenefitTile>
                </OurBenefitCarouselTile>
                <OurBenefitCarouselTile>
                  <OurBenefitTile>
                    <img src={`${LANDING_IMAGES_URL}/benefit2.png`} alt="secure" />
                    <p>{benefitSecond}</p>
                  </OurBenefitTile>
                </OurBenefitCarouselTile>
                <OurBenefitCarouselTile>
                  <OurBenefitTile>
                    <img src={`${LANDING_IMAGES_URL}/benefit3.png`} alt="secure" />
                    <p>{benefitThird}</p>
                  </OurBenefitTile>
                </OurBenefitCarouselTile>
                <OurBenefitCarouselTile>
                  <OurBenefitTile>
                    <img src={`${LANDING_IMAGES_URL}/benefit4.png`} alt="secure" />
                    <p>{benefitFourth}</p>
                  </OurBenefitTile>
                </OurBenefitCarouselTile>
                <OurBenefitCarouselTile>
                  <OurBenefitTile>
                    <img src={`${LANDING_IMAGES_URL}/benefit5.png`} alt="secure" />
                    <p>{benefitFifth}</p>
                  </OurBenefitTile>
                </OurBenefitCarouselTile>
              </Carousel>
            </OurBenefitsCarousel>
            <OurBenefitsStatic>
              <OurBenefitTile>
                <img src={`${LANDING_IMAGES_URL}/benefit1.png`} alt="secure" width="100" />
                <p>{benefitFirst}</p>
              </OurBenefitTile>
              <OurBenefitTile>
                <img src={`${LANDING_IMAGES_URL}/benefit2.png`} alt="secure" />
                <p>{benefitSecond}</p>
              </OurBenefitTile>
              <OurBenefitTile>
                <img src={`${LANDING_IMAGES_URL}/benefit3.png`} alt="secure" />
                <p>{benefitThird}</p>
              </OurBenefitTile>
              <OurBenefitTile>
                <img src={`${LANDING_IMAGES_URL}/benefit4.png`} alt="secure" />
                <p>{benefitFourth}</p>
              </OurBenefitTile>
              <OurBenefitTile>
                <img src={`${LANDING_IMAGES_URL}/benefit5.png`} alt="secure" />
                <p>{benefitFifth}</p>
              </OurBenefitTile>
            </OurBenefitsStatic>
          </OurBenefitsTiles>
        </OurBenefitsWrapper>
      </FirstPart>
      <SecondPart>
        <ShadowTopBackground />
        <TitleContainer>
          <span>{titleSecond}</span>
        </TitleContainer>
        <ShadowBottomBackground />
      </SecondPart>
    </SectionBenefits>
  );
};

export default BenefitsSection;
