/* eslint-disable indent */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Grid } from '@material-ui/core';

import { selectBranch } from 'ducks/branch/selectors';
import { menuIsClosed } from 'ducks/application/actions';

import Routes from 'routes';

import { BranchIcon } from 'assets/svg/BranchIcon';
import { RightArrow, LeftArrow } from 'assets/svg/SubMenu/SubMenu';

import { useTranslation } from 'react-i18next';
import { selectParentCategories } from 'ducks/category/selectors';
import TagManager from 'react-gtm-module';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { useSetPrices } from 'hooks/useSetPrices';
import {
  selectAddressId,
  selectSpecialPages,
  selectErpCustomerAddressCode,
} from 'ducks/application/selectors';
import { useQuery } from 'react-query';
import size from 'lodash/size';
import {
  StyledDiv,
  StyledContainer,
  MobileMainNav,
  StyledLocationDropdownWrapper,
  StyledLocationDropdown,
  StyledLocationGrid,
  StyledParagraph,
  BranchBlock,
  BranchWrapper,
} from './styles';
import { fetchDocumentGroup } from '../../../api/DocumentGroup';
import { usePermission } from '../../../hooks/usePermission';

const MobileMenuBlock: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const categories = useSelector(selectParentCategories);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const specialPages = useSelector(selectSpecialPages);
  const { user } = useSetPrices();
  const [activeNavs, setActiveNavs] = useState([]);
  const { canPlaceOrder, canPlaceQuote, canSeeOrderHistory } = usePermission();

  /**
   * On change active nav
   */
  const onChangeActiveNav = (item: any, parent: any) => {
    if (!(Array.isArray(item?.children) && item?.children.length)) {
      dispatch(menuIsClosed());
      return;
    }
    const newActiveNavs = [...activeNavs].filter(code => {
      return !parent.children.map(child => child.code).includes(code);
    });
    setActiveNavs([...newActiveNavs, item.code]);
  };

  const { t } = useTranslation();

  /**
   * On sub menu back
   */
  const onSubMenuBack = (item: any) => {
    if (!item?.code) return;

    const newActiveNavs = [...activeNavs].filter(code => code !== item.code);
    setActiveNavs(newActiveNavs);
  };

  const handleGoToYourAccount = (type: string) => {
    if (type === 'profile') {
      clearGtmEcommerce();
      history.push(Routes.PROFILE, {
        tab: 'profile.personal_settings',
      });
    }
    if (type === 'order_history') {
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'click_order_history',
          user_type: getUserType(user.email),
          branch_id: branch?.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
      history.push(Routes.PROFILE, {
        tab: 'profile.orders',
      });
    }
  };

  const { data: documentGroup } = useQuery({
    queryKey: ['documentGroup'],
    queryFn: fetchDocumentGroup
  });

  return (
    <StyledDiv className="mobile-menu-block">
      <StyledContainer direction="column">
        <Grid item container direction="column" className="box-branch-wrapper">
          <StyledLocationGrid item xs={12}>
            <BranchBlock>
              <BranchWrapper>
                <BranchIcon />
                <StyledParagraph>{branch?.erpName}</StyledParagraph>
              </BranchWrapper>
            </BranchBlock>

            <StyledLocationDropdownWrapper>
              <StyledLocationDropdown />
            </StyledLocationDropdownWrapper>
          </StyledLocationGrid>
        </Grid>

        <MobileMainNav className="mobile-main-nav">
          <li className="product-menu-list">
            <div className="sub-menu-title">{t('header.products').toString()}</div>
            {categories?.length > 0 && (
              <ul className="sub-menu">
                {categories.map(category => (
                  <li key={category.code}>
                    <div
                      role="button"
                      className="nav-link"
                      onClick={() => onChangeActiveNav(category, null)}
                    >
                      <div className="nav-link-title">
                        <Link to={`${Routes.CATEGORIES}/${category.code}`}>{category.name}</Link>
                      </div>
                      {Array.isArray(category.children) && category.children.length > 0 && (
                        <RightArrow className="ic-arrow" />
                      )}
                    </div>

                    {activeNavs.includes(category.code) &&
                      Array.isArray(category.children) &&
                      category.children.length > 0 && (
                        <ul className="sub-menu full-height">
                          <li className="sub-menu-back">
                            <div
                              role="button"
                              className="btn-back"
                              onClick={() => onSubMenuBack(category)}
                            >
                              <LeftArrow /> Back
                            </div>

                            <div
                              role="button"
                              className="btn-close"
                              onClick={() => dispatch(menuIsClosed())}
                            >
                              Close
                            </div>
                          </li>

                          {/* menu title */}
                          <li className="sub-menu-title">
                            <div className="txt-left">{category.name}</div>
                          </li>

                          {/* menu list */}
                          {category.children.map(sub1 =>
                            sub1.items && sub1.items > 0 ? (
                              <li key={sub1.code}>
                                <div role="button" className="nav-link">
                                  <div className="nav-link-title">
                                    <Link
                                      to={`${Routes.CATEGORIES}/${category.code}/${sub1.code}`}
                                      onClick={() => onChangeActiveNav(sub1, category)}
                                    >
                                      {sub1.name}
                                    </Link>
                                  </div>
                                  {Array.isArray(sub1.children) && sub1.children.length > 0 && (
                                    <RightArrow className="ic-arrow" />
                                  )}
                                </div>

                                {activeNavs.includes(sub1.code) &&
                                  Array.isArray(sub1.children) &&
                                  sub1.children.length > 0 && (
                                    <ul className="sub-menu full-height">
                                      {/* menu back/close */}
                                      <li className="sub-menu-back">
                                        <div
                                          role="button"
                                          className="btn-back"
                                          onClick={() => onSubMenuBack(sub1)}
                                        >
                                          <LeftArrow /> Back
                                        </div>

                                        <div
                                          role="button"
                                          className="btn-close"
                                          onClick={() => dispatch(menuIsClosed())}
                                        >
                                          Close
                                        </div>
                                      </li>

                                      {/* menu title */}
                                      <li className="sub-menu-title">
                                        <div className="txt-left">{sub1.name}</div>
                                      </li>

                                      {/* menu list */}
                                      {sub1.children.map((sub2: any) => (
                                        <li key={sub2.code}>
                                          <div className="nav-link">
                                            <div className="nav-link-title">
                                              <Link
                                                onClick={() => dispatch(menuIsClosed())}
                                                to={`${Routes.CATEGORIES}/${category.code}/${sub2.code}`}
                                              >
                                                {sub2.name}
                                              </Link>
                                            </div>
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                              </li>
                            ) : null
                          )}
                        </ul>
                      )}
                  </li>
                ))}
              </ul>
            )}
          </li>

          <li className="taiga-now-features-menu-list">
            <div className="sub-menu-title">{t('header.taiga_now_features').toString()}</div>
            <ul className="sub-menu">
              {Array.isArray(specialPages) && specialPages.length > 0 && (
                <li>
                  <Link
                    to={Routes.SPECIAL}
                    className="nav-link"
                    onClick={() => dispatch(menuIsClosed())}
                  >
                    <span className="nav-link-title">{t('header.specials_pages').toString()}</span>
                  </Link>
                </li>
              )}
              {(canPlaceOrder || canPlaceQuote) && (
                <li className="nav-item-quick-order">
                  <Link
                    to={Routes.QUICK_ORDER}
                    className="nav-link"
                    onClick={() => dispatch(menuIsClosed())}
                  >
                    <span className="nav-link-title">
                      {t('header.quick_order').toString()}
                      <span className="new-label">{t('header.new').toString()}</span>
                    </span>
                  </Link>
                </li>
              )}
              {
                size(documentGroup?.data?.data) > 0 && (
                  <li className="nav-item-quick-order">
                    <Link
                      to={Routes.DOCUMENT_GROUP}
                      className="nav-link"
                      onClick={() => dispatch(menuIsClosed())}
                    >
                  <span className="nav-link-title">
                    {t('document_group.title').toString()}
                    <span className="new-label">{t('header.new').toString()}</span>
                  </span>
                    </Link>
                  </li>
                )
              }
              {canSeeOrderHistory && (
              <li>
                <Link
                  to={Routes.PROFILE}
                  className="nav-link"
                  onClick={() => {
                    dispatch(menuIsClosed());
                    setTimeout(() => handleGoToYourAccount('order_history'), 300);
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: t('header.order_history') }} className="nav-link-title" />
                </Link>
              </li>
              )}
            </ul>
          </li>

          <li className="your-account-menu-list">
            <div className="sub-menu-title">{t('header.your_account').toString()}</div>
            <ul className="sub-menu">
              <li>
                <Link
                  to={Routes.PROFILE}
                  className="nav-link"
                  onClick={() => {
                    dispatch(menuIsClosed());
                    setTimeout(() => handleGoToYourAccount('profile'), 300);
                  }}
                >
                  <span className="nav-link-title">{t('header.profile').toString()}</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="use-and-help-menu-list">
            <div className="sub-menu-title">{t('header.use_and_help').toString()}</div>
            <ul className="sub-menu">
              <li>
                <Link
                  to={Routes.FAQ}
                  className={`nav-link ${location.pathname === Routes.FAQ ? 'active' : ''}`}
                  onClick={() => dispatch(menuIsClosed())}
                >
                  <span className="nav-link-title">{t('header.faq').toString()}</span>
                </Link>
              </li>
              <li className="bb-0">
                <Link
                  to={Routes.CONTACT_US}
                  className="nav-link"
                  onClick={() => dispatch(menuIsClosed())}
                >
                  <span className="nav-link-title">{t('header.contact_us').toString()}</span>
                </Link>
              </li>
            </ul>
          </li>
        </MobileMainNav>
      </StyledContainer>
    </StyledDiv>
  );
};

export default MobileMenuBlock;
