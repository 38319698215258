import styled from 'styled-components/macro';

const ProductCardContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;

  @media (max-width: 1023px) {
    justify-content: space-between;
  }
`;

export { ProductCardContainer };
