import styled from 'styled-components/macro';
import ModalComponent from '@material-ui/core/Modal';
import { Card, withStyles, IconButton } from '@material-ui/core';

const StyledIFrameOpenButton = styled.div`
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  margin-top: 24px;
  cursor: pointer;
`;

const StyledIFrameOpenButtonText = styled.span`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ theme: { colors } }) => colors.mainGreen};
`;

const ModalWindow = styled(ModalComponent)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: calc(100vh - 50px);
  max-width: calc(100vw - 50px);
  inset: 20px !important;
  z-index: 1600 !important;
`;

const ModalCard = styled(Card)`
  position: relative;
  outline: none;
  height: 100%;
  max-height: 100vh;
  width: 100%;
  padding: 20px;
  overflow-y: auto;

  @media (max-width: 620px) {
    max-height: 80vh;
  }
`;

const StyledIFrame = styled.iframe`
  width: 100%;
  height: 98%;
  border: none;
`;

const StyledIFrameIcon = styled.img`
  margin-right: 5px;
`;

const StyledIconButton = withStyles({
  root: {
    position: 'absolute',
    top: '4px',
    right: '4px',
    '@media (max-width: 720px)': {
      top: '-8px',
      right: '-8px',
    },
  },
})(IconButton);

export {
  StyledIFrameOpenButton,
  StyledIFrameOpenButtonText,
  ModalWindow,
  ModalCard,
  StyledIFrame,
  StyledIFrameIcon,
  StyledIconButton,
};
