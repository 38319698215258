import styled from 'styled-components/macro';
import { AppBar, Grid, IconButton, Toolbar, Typography } from '@material-ui/core';
import React from 'react';

const LowerMenuGrid = styled(Grid as any)`
  border-bottom: 1px solid #c2cad6;
  padding: 14px 0;
`;

const LowerMenuContentGrid = styled(({ fullWidthGreen, ...rest }) => <Grid {...rest} /> as any)`
  align-self: center;
  @media (min-width: 1201px) {
    width: 1200px;
  }
`;

const ProductsButton = styled.div`
  width: 245px;
  height: 48px;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const LowerMenuWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const StyledTypography = styled(Typography as any)`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #196742;
  &.fr {
    svg {
      right: 0;
    }
  }
  svg {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
    color: #00133C;
  }
`;

const StyledToolbar = styled(Toolbar as any)`
  background: none;
  min-height: inherit;
  padding-left: 0;
  padding-right: 0;
`;

const StyledAppBar = styled(AppBar as any)`
  background: none;
  box-shadow: none;
`;

const StyledIconButton = styled(IconButton as any)`
  width: 44px;
  height: 44px;
  color: #196742;
`;

const QuickButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .divider {
    width: 1px;
    height: 44px;
    border-right: 1px solid #c2cad6;
    padding-left: 16px;
    margin-right: 16px;
  }
  .btn-quick {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 48px;
    color: #007a33;
    background: #edf8f2;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0 20px;
    margin-right: 8px;
    transition: all 0.4s ease;
    &:hover,
    &.active {
      color: #fff;
      background: #007a33;
    }
    &:last-child {
      margin-right: 0;
    }
    .new {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      border-radius: 4px;
      color: #fff;
      background: #f79009;
      font-family: Open Sans;
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.1px;
      padding: 0 6px;
      margin-left: 8px;
    }
  }
`;

export {
  StyledTypography,
  StyledIconButton,
  StyledAppBar,
  StyledToolbar,
  ProductsButton,
  LowerMenuContentGrid,
  LowerMenuGrid,
  LowerMenuWrapper,
  QuickButtons,
};
