import React from 'react';

export const GeoIcon = () => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.15761 0C3.39463 0 0.333252 2.67119 0.333252 5.95454C0.333252 7.79111 1.41694 10.0203 3.55432 12.5801C5.11845 14.4535 6.65977 15.7895 6.72463 15.8455C6.84385 15.9484 7.00069 16 7.15772 16C7.31019 16 7.46277 15.9513 7.58104 15.8537C7.64604 15.8 9.19126 14.518 10.7584 12.6705C12.8974 10.1486 13.982 7.88905 13.982 5.95451C13.982 2.67119 10.9205 0 7.15761 0ZM7.15761 8.5252C5.31792 8.5252 3.82126 7.24233 3.82126 5.66548C3.82126 4.08863 5.31795 2.80576 7.15761 2.80576C8.99726 2.80576 10.494 4.08863 10.494 5.66548C10.494 7.24233 8.99723 8.5252 7.15761 8.5252Z"
      fill="white"
    />
  </svg>
);
