import React, {useState} from 'react';
import {NearbyBranchEmailType} from 'ducks/productVariant/types';
import {useSelector} from 'react-redux';
import {selectBranch} from 'ducks/branch/selectors';

export const NearbyBranchContext = React.createContext(undefined);

const NearbyBranchProvider: React.FC<React.ReactNode> = ({children}: any) => {
  const branch = useSelector(selectBranch);
  const [emailType, setEmailType] = useState(NearbyBranchEmailType.NO_NEARBY_BRANCH_OR_HUBS);
  const [fromBranchId, setFromBranchId] = useState(branch?.id);
  return <NearbyBranchContext.Provider
    value={{emailType, setEmailType, fromBranchId, setFromBranchId}}>{children}</NearbyBranchContext.Provider>
}

export default NearbyBranchProvider;
