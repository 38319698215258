import React, {Dispatch, SetStateAction, useEffect} from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  ActionButtonsWrapper,
  AllFiltersWrapper,
  BlackWrapper,
  ButtonTitle,
  ClearButtonTitle,
  CloseFilterPageButton,
  FilterContainer,
  FilterHeaderWrapper,
  FilterHeader,
  FilterWindowHeader,
  FilterLabel,
  FilterLabelClearButton,
  MobileFilterWrapper,
  MultipleSelectionWrapper,
  OpenFilterButton,
  StyledClearButton,
  OpenFilterButtonContent,
  EmptySpan,
  StyledApplyButton,
  AccordionWrapper,
  AccordionContent,
  StyledAccordionDetails,
  CheckboxWrapper,
  CheckboxTitle,
} from 'components/Product/ProductFilter/styles';
import { FilterIcon } from 'assets/svg/Product/Products';
import { BigCross } from 'assets/svg/Filter/Cross';
import { FacetClass } from 'models/classes/facetClass';
import {
  ISelectedValue,
} from 'models/interfaces/productVariant';
import { useTranslation } from 'react-i18next';
import { withStyles, CheckboxProps, Checkbox } from '@material-ui/core';
import {useSelector} from 'react-redux';
import {selectTotal as selectProductListTotal} from 'ducks/productVariant/selectors';
import { selectSearchFacets, selectTotal as selectSearchProductTotal } from 'ducks/search/selectors';
import isEmpty from 'lodash/isEmpty';
import { ProductFilterContext } from 'context/ProductFilterContext';
import { useGetDumpProductSearchFilterIds } from 'api/ProductVariant';
import { selectAddressId } from 'ducks/application/selectors';
import { useQuery } from 'utils/common';
import {generateFilter} from '../../utils';

const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#00853e',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

interface IMobileFilterWrapper {
  clearFilter: () => void;
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
  multiselectFacets: FacetClass[];
  handleChange: (facetCode: string, bucketLabel: string, bucketKey: string) => void;
  findNecessaryCheck: (facetProperty: string, bucketLabel: string) => boolean;
  selectedValues: ISelectedValue[];
  setSelectedValues: Dispatch<SetStateAction<ISelectedValue[]>>;
  setFilter: Dispatch<SetStateAction<object>>;
}

const MobileSearchPageAllFiltersWrapper = ({
  clearFilter,
  setShow,
  show,
  multiselectFacets,
  handleChange,
  findNecessaryCheck,
  selectedValues,
  setSelectedValues,
  setFilter,
}: IMobileFilterWrapper) => {
  const { t } = useTranslation();
  const selectedAddressId = useSelector(selectAddressId);
  const total = useSelector(window.location.pathname.includes('search') ? selectSearchProductTotal:  selectProductListTotal);
  const [currentFilters, setCurrentFilters] = React.useState(selectedValues);

  const { inStock, filter } = React.useContext(ProductFilterContext);
  const query = useQuery();

  const {data} = useGetDumpProductSearchFilterIds(query.get('q'), selectedAddressId, {
    ...filter,
    inStock
  });

  const handleShow = () => {
    setShow(!show);
  };

  const handleApplySelectedFilter = () => {
    handleShow();
    setCurrentFilters(selectedValues);
  };
  const handleClearFilter = () => {
    clearFilter();
    setCurrentFilters([]);
  };

  const facets = useSelector(selectSearchFacets);

  const filters = [...facets, ...multiselectFacets];
  const sortedFilters = [...filters.sort((a, b) => Number(a.position) - Number(b.position))];

  const getTotalItemCount = (facetFilterFilter: FacetClass) => {
    const facetFilter = data?.data.find(v => {
      return v.key === facetFilterFilter.property;
    });
    if (!facetFilter) {
      return 0;
    }

    let bucketCount = 0;
    Object.entries(facetFilter.value).filter(v => !isEmpty(v[0])).map((value: any) => {
      bucketCount += value[1].length;
      return value;
    });
    return bucketCount;
  }

  const setFilterCount = (f: any, b: any) => {
    const facetFilter = data?.data?.find(v => {
      return v.key === f.property;
    });
    if (!facetFilter) {
      return 0;
    }
    const bucketCount: any[] = Object.entries(facetFilter.value).find((value: any) => {
      return parseInt(value[0], 10) === b.key;
    });
    if (bucketCount) {
      return bucketCount[1].length;
    }
    return 0;
  };

  const handleDelete = (name: string, facetCode: string) => {
    const clearedFilterValues = selectedValues.filter(elem => {
      if (elem.name === name) {
        return elem.code !== facetCode;
      }

      return true;
    });

    setSelectedValues(clearedFilterValues);
    setFilter(generateFilter(clearedFilterValues));
  };

  const handleClearSingleFilter = (event, facetProperty: string) => {
    event.stopPropagation();
    const clearedFilterValues = selectedValues.filter(elem => {
      return elem.code !== facetProperty;
    });
    setSelectedValues(clearedFilterValues);
    setFilter(generateFilter(clearedFilterValues));
  }

  const handleClickFilter = (facet: FacetClass, bucket: any) => {
    handleChange(facet.property, bucket.label, bucket.key);
  }

  const handleCancel = () => {
    setSelectedValues(currentFilters);
    setFilter(generateFilter(currentFilters));
    handleShow();
  }

  const getFacetLabel = React.useCallback((facetProperty: string) => {
    return facets.find(f => f.property === facetProperty).label;
  }, [facets]);

  const getBucketCount = React.useCallback((facetProperty: string, bucketKey: string) => {
    const idx = data?.data?.findIndex(v => v.key === facetProperty);
    const value = data?.data[idx];
    return value?.value[bucketKey].length;
  }, [data?.data]);

  const isShowClearButton = React.useCallback((facetProperty: string) => {
    return selectedValues.some(v => v.code === facetProperty);
  }, [selectedValues]);

  /**
   * Toggle variable viewport
   * The trick to viewport units on mobile
   * Fix: Element is obscured by safari toolbar
   */
  const toggleVariableViewport = () => {
    const vh: number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  useEffect(() => {
    if (show) {
      toggleVariableViewport()
    } else {
      document.documentElement.style.setProperty('--vh', null);
    }
  }, [show])

  /**
   * Event Listener
   */
  useEffect(() => {
    window.addEventListener('resize', toggleVariableViewport);

    return () => {
      window.removeEventListener('resize', toggleVariableViewport);
    };
  });

  return (
    <FilterContainer>
      <OpenFilterButton onClick={handleShow}>
        <EmptySpan />
        <OpenFilterButtonContent>
          <FilterIcon />
          <ButtonTitle>{t('products_and_search.filter').toString()}</ButtonTitle>
        </OpenFilterButtonContent>
        <EmptySpan />
      </OpenFilterButton>

      <BlackWrapper isShow={show} />

      <MobileFilterWrapper isShow={show}>
        <AllFiltersWrapper>
          <FilterHeaderWrapper>
            <FilterHeader>
              <FilterWindowHeader>{t('products_and_search.filters').toString()}</FilterWindowHeader>
              <CloseFilterPageButton onClick={handleCancel}>
                {t('products_and_search.cancel').toString()}
              </CloseFilterPageButton>
            </FilterHeader>

            <FilterLabel>
              {
                selectedValues.length > 0 && selectedValues.map((v) => {
                  return (
                    <li key={v.name}>
                      <span className="text">{getFacetLabel(v.code)}: {v.name} ({getBucketCount(v.code, v.bucketKey)})</span>
                      <FilterLabelClearButton onClick={() => handleDelete(v.name, v.code)}>
                        <BigCross />
                      </FilterLabelClearButton>
                    </li>
                  )
                })
              }
            </FilterLabel>
          </FilterHeaderWrapper>

          <MultipleSelectionWrapper>
            {sortedFilters.map((facet: FacetClass) => {
              return (
                <AccordionWrapper key={facet.label} defaultExpanded={isShowClearButton(facet.property)}>
                  <AccordionContent expandIcon={<ExpandMoreIcon />}>
                    <div className="txt-name">{facet.label} ({getTotalItemCount(facet)})</div>
                    {
                      isShowClearButton(facet.property) && (
                        <button type="button" className="btn-clear" onClick={(event) => handleClearSingleFilter(event, facet.property)}>
                          {t('products_and_search.clear').toString()}
                        </button>
                      )
                    }
                  </AccordionContent>

                  <StyledAccordionDetails>
                    {facet.buckets.map((bucket) => {
                      return (
                        <CheckboxWrapper
                          key={bucket.label}
                          className="checkbox-wrapper"
                          onClick={() => {
                            if (setFilterCount(facet, bucket) > 0) {
                              handleClickFilter(facet, bucket);
                            }
                          }}
                          isShow={setFilterCount(facet, bucket) > 0}
                        >
                          <GreenCheckbox checked={findNecessaryCheck(facet.property, bucket.key)} />
                          <CheckboxTitle>{bucket.label} ({setFilterCount(facet, bucket)})</CheckboxTitle>
                        </CheckboxWrapper>
                      )
                    })}
                  </StyledAccordionDetails>
                </AccordionWrapper>
              );
            })}
          </MultipleSelectionWrapper>

          <ActionButtonsWrapper>
            <StyledApplyButton
              disabled={total === 0}
              onClick={handleApplySelectedFilter}
            >
              {
                total > 0 ? (
                  <p>{t('products_and_search.showXResults', {count: total}).toString()}</p>
                ) : <p>{t('products_and_search.showResults').toString()}</p>
              }
            </StyledApplyButton>

            <StyledClearButton onClick={handleClearFilter}>
              <ClearButtonTitle>{t('products_and_search.clear_all').toString()}</ClearButtonTitle>
            </StyledClearButton>
          </ActionButtonsWrapper>
        </AllFiltersWrapper>
      </MobileFilterWrapper>
    </FilterContainer>
  );
};

export default MobileSearchPageAllFiltersWrapper;
