import React, { Dispatch, SetStateAction, useState } from 'react';

import {
  ISelectedValue,
  FilterType,
  IMultiselectFilterValues,
} from 'models/interfaces/productVariant';
import {
  generateFilter,
  getMultiselectedValues,
  getRangeFilterSelectedValues,
} from 'components/Product/ProductFilter/utils';

export const useMobileFilter = (
  selectedValues: ISelectedValue[],
  setFilter: Dispatch<SetStateAction<{}>>,
  setSelectedValues: Dispatch<SetStateAction<ISelectedValue[]>>,
  setInStock: Dispatch<SetStateAction<boolean>>,
  isMobile: boolean
) => {
  const [localSelectedValues, setLocalSelectedValues] = useState<ISelectedValue[]>(selectedValues);
  const [selectedFilter, setSelectedFilter] = useState('');

  const applySelectedFilter = () => {
    setSelectedValues(localSelectedValues);
    setSelectedFilter('');
    setFilter(generateFilter(localSelectedValues));
  };

  const goBackFilterHandler = () => {
    setSelectedFilter('');
  };

  const handleMobileFilterSelect = (filterCode: string) => {
    setSelectedFilter(filterCode);
  };

  const clearFilter = () => {
    setFilter({});
    setSelectedValues([]);
    setInStock(false);
    if (isMobile) setLocalSelectedValues([]);
  };

  const getMobileCheck = (facetProperty: string, bucketKey: string) =>
    localSelectedValues.some(
      selectedValue => selectedValue.code === facetProperty && selectedValue.bucketKey === bucketKey
    );

  const handleMobileChange = (facetCode: string, bucketLabel: string, bucketKey: string) => {
    const isSelectedValueExists = getMobileCheck(facetCode, bucketKey);

    if (!isSelectedValueExists) {
      const newSelectedValues = [
        ...localSelectedValues,
        {
          name: bucketLabel,
          code: facetCode,
          bucketKey,
          type: FilterType.CHECKBOX,
        },
      ];

      setLocalSelectedValues(newSelectedValues);
    } else {
      const newSelectedValues = localSelectedValues.filter(item => {
        if (item.bucketKey === bucketKey) {
          return item.code !== facetCode;
        }

        return true;
      });

      setLocalSelectedValues(newSelectedValues);
    }
  };

  const handleMultiselectMobileChange = (
    code: string,
    name: string,
    { values }: IMultiselectFilterValues
  ) => {
    const newSelectedValues = getMultiselectedValues(code, name, values, localSelectedValues);

    setLocalSelectedValues(newSelectedValues);
  };

  const handleRangeFilterMobileChange = (code: string, name: string, values: number[]) => {
    const newSelectedValues = getRangeFilterSelectedValues(code, name, values, localSelectedValues);
    setLocalSelectedValues(newSelectedValues);
  };

  const clearSingleFilter = (property: string, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const newSelectedValues = localSelectedValues.filter(value => value.code !== property);

    setLocalSelectedValues(newSelectedValues);
  };

  return {
    handleMobileFilterSelect,
    getMobileCheck,
    clearSingleFilter,
    goBackFilterHandler,
    applySelectedFilter,
    clearFilter,
    selectedFilter,
    setSelectedFilter,
    handleMobileChange,
    localSelectedValues,
    setLocalSelectedValues,
    handleMultiselectMobileChange,
    handleRangeFilterMobileChange,
  };
};
