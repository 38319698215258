import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, Select, TextField, Typography } from '@material-ui/core';

import { MediumButton } from 'components/common/Buttons/MediumButton';

const MainContainer = styled.div`
  padding: 48px 0 56px 0;

  @media (max-width: 1200px) {
    padding: 33px 11px 56px 60px;
  }
  @media (max-width: 767px) {
    padding: 25px 11px 0 30px;
  }
  @media (max-width: 380px) {
    padding: 10px 11px 0 11px;
  }
`;

const PageTitle = styled.h1`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  letter-spacing: 0.1px;
  margin: 12px 0 24px;
`;

const ContentWrapper = styled.div`
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 24px 0 0;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 767px) {
    margin-top: 32px;
  }
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const FormsContainer = styled.div`
  margin: 0;
  padding: 0;
  max-width: 600px;
  margin-right: 64px;

  @media (max-width: 1000px) {
    margin-right: 32px;
  }
  @media (max-width: 700px) {
    margin-right: 0;
  }
`;

const StyledTextField = withStyles({
  root: {
    marginBottom: '25px',
    '& .MuiInputLabel-root': {
      color: '#939599',
    },
    '& .MuiInputLabel-shrink': {},
    '& .MuiInputBase-root.Mui-focused': {
      backgroundColor: '#F7F7F7',
    },
    '& .Mui-focused.MuiFormLabel-root': {
      backgroundColor: '#FFFFFF',
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      borderColor: '#00853e',
    },
    '& .MuiOutlinedInput-root.Mui-error.Mui-focused fieldset': {
      borderColor: '#f44336',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#C4C4C4',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00853e',
        },
      },
      '&.Mui-error': {
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336',
          },
        },
      },
    },
    '@media (min-width: 1100px)': { minWidth: '600px' },
    '@media (max-width: 320px)': {
      marginBottom: '16px',
    },
  },
})(TextField);

const StyledFromButton = withStyles({
  root: {
    height: '48px',
    width: '185px',
    borderRadius: '4px',
    marginTop: '78px',
    '@media (max-width:480px)': { width: '100%' },
  },
})(MediumButton);

const StyledFormControl = withStyles({
  root: {
    width: '100%',
    marginBottom: '24px',
    '& .MuiInputLabel-root': {
      color: '#939599',
      transform: 'translate(14px, 18px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .Mui-focused.MuiFormLabel-root': {
      backgroundColor: '#FFFFFF',
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      borderColor: '#00853e',
    },
    '@media (max-width: 1023px)': {
      '& .MuiInputLabel-root': {
        transform: 'translate(14px, 12px) scale(1)',
      },
    },
    '@media (min-width:1100px)': { minWidth: '600px' },
    '@media (max-width: 320px)': {
      marginBottom: '16px',
      '& .MuiInputBase-formControl': {
        height: '40px',
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -8px) scale(0.75)',
      },
    },
  },
})(FormControl);

const VerticalLine = styled.div`
  border-left: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  height: 489px;

  @media (max-width: 700px) {
    border-left: none;
    height: 1px;
    width: 100%;
    max-width: 600px;
    border-top: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
    margin: 32px 0;
  }
`;

const StyledContactsLinkContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const SalesAndContactsWrapper = styled.div`
  margin: 0;
  padding: 0;
  max-height: 550px;

  @media (max-width: 1200px) {
    margin-left: 3%;
  }
  @media (max-width: 700px) {
    width: auto;
  }
`;

const ContactInfo = styled.div`
  margin: 0 0 0 40px;
  padding: 0;

  @media (max-width: 1200px) {
    margin: 40px 0 0 0;
  }
`;

const TextSubheaderTypography = styled(Typography)`
  font-family: Open Sans;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.small};
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 23px;
  color: ${({ theme: { colors } }) => colors.grey};
`;

const StyledSelect = styled(Select)`
  &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme: { colors } }) => colors.blackOpacity(0.23)};
  }

  @media (max-width: 1023px) {
    height: 40px;
  }
`;

export {
  MainContainer,
  PageTitle,
  ContentWrapper,
  FormsContainer,
  StyledTextField,
  StyledFromButton,
  StyledFormControl,
  VerticalLine,
  StyledContactsLinkContainer,
  SalesAndContactsWrapper,
  ContactInfo,
  TextSubheaderTypography,
  StyledSelect,
};
