import { QuickOrderActionTypes, QuickOrderState } from './types';

const initialState: QuickOrderState = {
  loading: false,
  error: null,
  quickOrderItems: [],
  disableCheckout: false,
  clearSearchTerm: false,
  quickOrderProducts: [],
  quickOrderAddToCartStatus: false,
};

const quickOrderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case QuickOrderActionTypes.ADD_ITEM_TO_QUICK_ORDER_SUCCESS: {
      return {
        ...state,
        quickOrderItems: [...state.quickOrderItems, payload.quickOrderItem],
      };
    }
    case QuickOrderActionTypes.REQUEST_GET_QUICK_ORDER_ITEMS_SUCCESS: {
      return {
        ...state,
        quickOrderItems: payload.quickOrderItems,
      };
    }
    case QuickOrderActionTypes.REQUEST_GET_QUICK_ORDER_ITEMS_ERROR: {
      return {
        ...state,
        quickOrderItems: [],
      };
    }
    case QuickOrderActionTypes.UPDATE_QUICK_ORDER_ITEM_QUANTITY_SUCCESS: {
      return {
        ...state,
        quickOrderItems: state.quickOrderItems.map(item => {
          if (item.id === payload.quickOrderItemId) {
            return {
              ...item,
              quantity: payload.quantity,
            };
          }
          return item;
        }),
      };
    }
    case QuickOrderActionTypes.REQUEST_REMOVE_QUICK_ORDER_ITEM_SUCCESS: {
      return {
        ...state,
        quickOrderItems: state.quickOrderItems.filter(item => item.id !== payload.quickOrderItemId),
      };
    }
    case QuickOrderActionTypes.DISABLE_CHECKOUT: {
      return {
        ...state,
        disableCheckout: payload.disableCheckout,
      };
    }
    case QuickOrderActionTypes.REQUEST_CLEAR_SEARCH_TERM: {
      return {
        ...state,
        clearSearchTerm: payload.value,
      };
    }
    case QuickOrderActionTypes.REQUEST_REPLACE_QUICK_ORDER_ITEM_SUCCESS: {
      return {
        ...state,
        quickOrderItems: state.quickOrderItems.map(item => {
          if (item.id === payload.quickOrderItem.id) {
            return payload.quickOrderItem;
          }
          return item;
        }),
      }
    }
    case QuickOrderActionTypes.REQUEST_BULK_ADD_TO_CART_SUCCESS: {
      return {
        ...state,
        quickOrderProducts: payload,
        quickOrderAddToCartStatus: true,
        quickOrderItems: [],
      }
    }
    case QuickOrderActionTypes.REQUEST_SET_QUICK_ORDER_ADD_TO_CART_STATUS: {
      return {
        ...state,
        quickOrderAddToCartStatus: payload,
      }
    }
    case QuickOrderActionTypes.REQUEST_CLEAR_QUICK_ORDER_SUCCESS: {
      return {
        ...state,
        quickOrderItems: [],
      }
    }
    default:
      return state;
  }
};

export default quickOrderReducer;
