import { FAQClass } from 'models/classes/faqClass';
import { IFAQResponse } from 'models/interfaces/faq';

export const faqTofaqClass = (faq: IFAQResponse) => {
  const newSingleFAQ = {
    id: faq.id,
    name: faq.name,
    position: faq.position,
    questions: faq.questions,
  };

  return new FAQClass(newSingleFAQ);
};
