export const ORDER_STATUSES = [
  'Submitted',
  'Awaiting Confirmation',
  'Confirmed',
  'Waiting On Stock',
  'Picking',
  'Invoiced',
  'Awaiting Delivery',
  'Shipped',
  'Please Contact Sales Rep For Pick Up',
  'Picked Up',
];

export const SUBMITTED = 'Submitted';
export const CONFIRMED = 'Confirmed';
export const SAVED_PARKED_CREDITCONTROL = 'Saved,Parked,Credit Control';

export const WAITING_ON_STOCK = 'Waiting On Stock';
export const AWAITING_STOCK = 'Awaiting Stock';

export const PICKING = 'Picking';

export const AWAITING_DELIVERY = 'Awaiting Delivery';
export const PLEASE_CONTACT_SALES_REP_FOR_PICK_UP = 'Please Contact Sales Rep For Pick Up';

export const SHIPPED = 'Shipped';
export const DELIVERY_INVOICING = 'Delivery,Invoicing';

export const PICKED_UP = 'Picked Up';
export const TO_PICK_UP_INVOICING = 'To Pick Up,Invoicing';

export const INVOICED = 'Invoiced';

export const DELIVERY = 'delivery';

export const SAVED = 'Saved';

export const PARKED = 'Parked';

export const CREDIT_CONTROL = 'Credit Control';

export const INVOICING = 'Invoicing';

export const NONE = 'None';
