import React, {useEffect, useState} from 'react';
import {CircularProgress, DialogContentText} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {useDispatch, useSelector} from 'react-redux';
import {
  selectCreateMagicLinkErrorMessages,
  selectCreateMagicLinkLoading,
  selectCreateMagicLinkStatus,
  selectLoginByMagicLinkErrorMessages,
  selectLoginByMagicLinkLoading,
  selectLoginByMagicLinkStatus
} from 'ducks/auth/selectors';
import { StylesDialog, StylesDialogContent, StyledSubmitButton } from './styles';
import {isValidEmail} from '../../../utils/common';
import {reloadStateLoginLink, requestCreateMagicLink} from '../../../ducks/auth/actions';
import {CreateMagicLinkSuccessIcon} from '../../../assets/svg/MagicLink/CreateMagicLinkSuccessIcon';
import {CreateMagicLinkWarningIcon} from '../../../assets/svg/MagicLink/CreateMagicLinkWarningIcon';
import {CreateMagicLinkErrorIcon} from '../../../assets/svg/MagicLink/CreateMagicLinkErrorIcon';
import {LoginWithoutPasswordButton} from '../../Auth/SignIn/styles';

interface ILoginWithMagicLinkModal {
	open: boolean;
	callbackOnClose?: Function;
  setOpenModalRegisterModal: Function;
}


export const LoginWithMagicLinkModal = ({open, callbackOnClose, setOpenModalRegisterModal}: ILoginWithMagicLinkModal) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const createMagicLinkStatus = useSelector(selectCreateMagicLinkStatus);
  const createMagicLinkLoading = useSelector(selectCreateMagicLinkLoading);
  const createMagicLinkErrorMessage = useSelector(selectCreateMagicLinkErrorMessages);
  const loginByMagicLinkErrorMessage = useSelector(selectLoginByMagicLinkErrorMessages);
  const loginByMagicLinkStatus = useSelector(selectLoginByMagicLinkStatus);
  const loginByMagicLinkLoading = useSelector(selectLoginByMagicLinkLoading);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState<string>('');

  const handleClose = () => {
    dispatch(reloadStateLoginLink());
    callbackOnClose();
  }

  const handleChangeInput = (e) => {
    setEmail(e.target.value.trim());
  }

  const submit = () => {
    if (!success) {
      dispatch(requestCreateMagicLink(email));
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    if (createMagicLinkStatus === 'success' && !createMagicLinkLoading) {
      setSuccess(true);
    }
    if (['error', 'warning'].includes(createMagicLinkStatus) && !createMagicLinkLoading) {
      setSuccess(false);
    }
  }, [createMagicLinkStatus, createMagicLinkLoading]);

  return (
    <StylesDialog
      open={open}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StylesDialogContent>
        <DialogContentText>
          <button type="button" className="btn-close" onClick={handleClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6 18L18 6L6 18Z" fill="#48576B"/>
              <path d="M6 18L18 6" stroke="#48576B" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M18 18L6 6" stroke="#48576B" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
          {
            createMagicLinkStatus === 'success' && !createMagicLinkLoading && (
              <div className="icon-success">
                <CreateMagicLinkSuccessIcon/>
              </div>
            )
          }

          {
            (createMagicLinkStatus === 'warning' || loginByMagicLinkStatus === 'warning') && (!createMagicLinkLoading || !loginByMagicLinkLoading) && (
              <div className="icon-success">
                <CreateMagicLinkWarningIcon/>
              </div>
            )
          }

          {
            (createMagicLinkStatus === 'error' || loginByMagicLinkStatus === 'error') && (!createMagicLinkLoading || !loginByMagicLinkLoading) && (
              <div className="icon-success">
                <CreateMagicLinkErrorIcon/>
              </div>
            )
          }

          {
            (createMagicLinkErrorMessage === 'email_not_exists' || createMagicLinkErrorMessage === 'something_error' || loginByMagicLinkErrorMessage === 'magic_link_expired' || loginByMagicLinkErrorMessage === 'invalid_credentials') && (!createMagicLinkLoading || !loginByMagicLinkLoading) && (
              <>
                {
                  (createMagicLinkErrorMessage === 'email_not_exists' || createMagicLinkErrorMessage === 'something_error' || loginByMagicLinkErrorMessage === 'invalid_credentials') && (
                    <>
                      <div className="title-modal">{t('sign-in.something_missing_title')}</div>
                      <div className="title-note">
                        {t('sign-in.something_missing_note')}
                      </div>
                    </>
                  )
                }
                {
                  loginByMagicLinkErrorMessage === 'magic_link_expired' && (
                    <>
                      <div className="title-modal">{t('sign-in.magic_link_expired_title')}</div>
                      <div className="title-note">
                        {t('sign-in.magic_link_expired_note')}
                      </div>
                    </>
                  )
                }
              </>
            )
          }

          {
            (createMagicLinkErrorMessage === 'verification_in_process' || loginByMagicLinkErrorMessage === 'magic_link_used') && (!createMagicLinkLoading || !loginByMagicLinkLoading) && (
              <>
                {
                  createMagicLinkErrorMessage === 'verification_in_process' && (
                    <>
                      <div className="title-modal">
                        {t('sign-in.verification_in_process_title')}
                      </div>
                      <div className="title-note">
                        {t('sign-in.verification_in_process_note')}
                      </div>
                    </>
                  )
                }
                {
                  loginByMagicLinkErrorMessage === 'magic_link_used' && (
                    <>
                      <div className="title-modal">
                        {t('sign-in.magic_link_used_title')}
                      </div>
                      <div className="title-note">
                        {t('sign-in.magic_link_used_note')}
                      </div>
                    </>
                  )
                }
              </>
            )
          }

          {(!createMagicLinkStatus && !loginByMagicLinkStatus) && (
            <>
              <div className="title-modal">{t('sign-in.login_with_magic_link')}</div>
              <div
                className="title-note"
              >
                {t('sign-in.login_with_magic_link_note')}
              </div>
            </>
          )}

          {
            createMagicLinkStatus === 'success' && !createMagicLinkLoading && (
              <>
                <div className="title-modal">{t('sign-in.login_with_magic_link')}</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('sign-in.magic_link_has_been_sent', { email }),
                  }}
                  className="title-note"
                />
              </>
            )
          }

          {!success && <input placeholder={t('sign-in.email')} className="input-email" onChange={handleChangeInput}/>}

          <div className="box-submit">
            {
              !createMagicLinkLoading && <StyledSubmitButton className="btn-submit" onClick={submit} disabled={!isValidEmail(email)}>
                {success ? t('sign-in.continue') : t('sign-in.get_a_magic_link')}
              </StyledSubmitButton>
            }
            {
              createMagicLinkStatus === 'error' && !createMagicLinkLoading && (
                <LoginWithoutPasswordButton style={{marginTop: 16}} onClick={() => setOpenModalRegisterModal()}>
                  {t('sign-in.register')}
                </LoginWithoutPasswordButton>
              )
            }
            {
              createMagicLinkLoading && <CircularProgress color="inherit" size={22} style={{marginRight: 5}}/>
            }
          </div>
        </DialogContentText>
      </StylesDialogContent>
    </StylesDialog>
  );
};
