import styled from 'styled-components/macro';
import {AccordionDetails, AccordionSummary, FormControlLabel, TextField, withStyles} from '@material-ui/core';
import { Link } from 'react-router-dom';

import { MediumButton } from 'components/common/Buttons/MediumButton';
import { KeyboardDatePicker } from '@material-ui/pickers';

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme: { colors } }) => colors.filterBorderGrey};
  margin-bottom: 30px;
`;

const CustomBreadCrumbs = styled.div`
  @media (max-width: 824px) {
    padding: 0px 10px;
  }
`;

const Layout = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 35px 10px;

  @media (max-width: 824px) {
    padding: 18px 0px;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 824px) {
    flex-direction: column;
  }
`;

const ProductsLayout = styled.div`
  margin-right: 50px;
  width: 100%;
  @media (max-width: 1024px) {
    margin-right: 10px;
  }
  @media (max-width: 824px) {
    margin: 0px 0px 54px 0px;
    padding: 0px 10px;
  }
  .MuiAccordion-root {
    box-shadow: none;
    margin-bottom: 24px;
    &:before {
      display: none;
    }
  }
  .MuiCollapse-container {
    border-bottom: 1px solid #EAEAEA;
    border-left: 1px solid #EAEAEA;
    border-right: 1px solid #EAEAEA;
    border-radius: 0 0 4px 4px;
  }
`;

const Header = styled.h1`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  letter-spacing: 0.1px;
  margin-top: 12px;
  margin-bottom: 24px;
`;

const BlockH = styled.h2`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 177.778% */
  letter-spacing: 0.1px;
  margin: 16px 0;
`;

const Attention = styled.h2`
  font-size: 28px;

  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 25px;
  }
`;

const ViewCatalogue = withStyles({
  root: {
    fontWeight: 600,
    '@media (max-width: 824px)': {
      width: '100%',
    },
  },
})(MediumButton);

const StyledButtonLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.white};
  display: block;
  width: 100%;
  height: 100%;
`;

const RadioControl = withStyles({
  root: {
    margin: ' 0px 0px 15px',
  },
})(FormControlLabel);

const RadioBlockWrapper = styled.div`
  display: flex;
  font-family: Open Sans;

  & > div:first-child {
    margin-right: 20px;
  }

  @media (max-width: 1023px) {
    width: 100%;
    flex-wrap: wrap;
    & > div:first-child {
      margin-right: 0;
    }
  }
`;
const DateBlockWrapper = styled.div`
  display: flex;
  width: 50%;
  margin-top: 20px;
  padding-right: 10px;
  @media (max-width: 1023px) {
    width: 100%;
    flex-wrap: wrap;
    padding-right: 0;
  }
`;

interface IRadioBlock {
  isActive: boolean;
}

const RadioBlock = styled.div`
  cursor: pointer;
  position: relative;
  border: ${(p: IRadioBlock) => (p.isActive ? '2px solid #00853e' : '1px solid #EAEAEA')};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 14px 48px;
  width: 100%;

  @media (max-width: 1023px) {
    margin-bottom: 10px;
  }
`;

const RBLabel = styled.div`
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 177.778% */
  letter-spacing: 0.1px;
`;

interface ICustomRadio {
  isActive: boolean;
  inside: string;
}

const CustomRadioCoords = {
  shipping: {
    top: '24px',
    left: '20px',
  },
  address: {
    top: '14px',
    left: '229px',
  },
};

const CustomRadio = styled.div`
  position: absolute;
  top: 50%;
  left: ${(p: ICustomRadio) => CustomRadioCoords[p.inside].left};
  transform: translate(0, -50%);
  border-radius: 16px;
  border: ${(p: ICustomRadio) => (p.isActive ? '4px solid #00853e' : '2px solid #BFBFBF')};
  box-sizing: border-box;
  width: 16px;
  height: 16px;
`;

const AddressBlock = styled.div`
  cursor: pointer;
  background: ${({ theme: { colors } }) => colors.lightGrey};
  position: relative;
  border-radius: 4px;
  width: 260px;
  padding: 24px;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  margin-right: 16px;

  & > p {
    margin: 15px 0px;
  }

  @media (max-width: 1023px) {
    margin-bottom: 10px;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FlexNoWrap = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1023px) {
    flex-wrap: wrap;
  }
`;

const FieldContainer = styled.div`
  height: auto;
  width: 48%;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const ASTitle = styled.p`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 177.778% */
  letter-spacing: 0.1px;
  margin: 16px 0;
`;

const StyledTextField = withStyles({
  root: {
    margin: '0',
    marginBottom: '25px',
    minHeight: '48px',
    '& .MuiInputBase-root': {
      height: '48px',
      display: 'block',
      '& input': {
        boxSizing: 'border-box',
        height: 'auto',
      },
    },

    '& .MuiInputBase-multiline': {
      height: 'auto',
    },
    '& .MuiInputLabel-root': {
      color: '#939599',
      transform: 'translate(14px, 18px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },

    '& .MuiOutlinedInput-multiline': {
      padding: '17px 14px',
      '& legend': {
        '& span': {
          maxWidth: 'max-content',
        },
      },
    },
    '& .Mui-focused': {
      backgroundColor: '#F7F7F7',
    },
    '& .Mui-focused.MuiFormLabel-root': {
      backgroundColor: '#FFFFFF',
    },
    '& .MuiFormLabel-filled.MuiFormLabel-root': {
      backgroundColor: '#FFFFFF',
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      borderColor: '#00853e',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#C4C4C4',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00853e',
        },
      },
    },
    '& label': {
      maxWidth: '90%',
    },
    '@media (max-width: 980px)': {
      '& .MuiOutlinedInput-multiline': {
        padding: '27px 14px',
        '& legend': {
          '& span': {
            maxWidth: '300px !important',
          },
        },
      },
    },
    '@media (max-width: 320px)': {
      marginBottom: '16px',
      '& .MuiInputBase-root': {
        height: '40px',
      },
      '& .MuiInputBase-multiline': {
        height: 'auto',
      },
      '& .MuiInputLabel-root': {
        transform: 'translate(14px, 12px) scale(1)',
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -8px) scale(0.75)',
      },
      '& .MuiFormHelperText-root.Mui-error': {
        marginBottom: '-16px !important',
      },
    },
    '& .MuiFormHelperText-root.Mui-error': {
      marginBottom: '-25px',
    },
  },
})(TextField);

const StyledMultiTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-inputMultiline': {
      '@media (max-width: 375px)': {
        paddingTop: '5px',
      },
      '@media (max-width: 320px)': {
        paddingTop: '15px',
      },
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -4px) scale(0.64) !important',
      '@media (max-width: 470px)': {
        transform: 'translate(14px, -4px) scale(0.9) !important',
        fontSize: '12px',
      },
    },
    '& .MuiOutlinedInput-multiline': {
      '& legend': {
        transform: 'scale(0.64) !important',
        width: '250px',
      },
    },
  },
})(StyledTextField);

const SAWrapper = styled.div`
  margin-top: 34px;
  margin-bottom: 30px;
`;

const AddressTitle = styled.span`
  font-weight: 700;
`;

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  &:focus {
    outline: inherit;
  }

  .MuiOutlinedInput-root {
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.23);
      }
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: #00853e;
      }
    }
  }

  .MuiInputBase-root {
    &:focus {
      outline: inherit;
    }
  }
  .MuiPickersToolbar-toolbar {
    background-color: #00853e;
  }

  .MuiPickersDay-daySelected {
    background-color: #00853e;
  }
  .MuiFormLabel-root {
    &.Mui-focused {
      color: inherit;
    }
  }
`;

const CheckoutNote = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #333;
  background: #F7F7F7;
  border-radius: 4px;
  padding: 16px 24px;
  margin-top: 15px;
  @media only screen and (max-width: 824px) {
    margin-top: 0;
  }
  p {
    margin-top: 0;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const AccordionContent = styled(AccordionSummary)`
  background: #f7f7f7;
  border-top: 1px solid #DADEE4;
  border-left: 1px solid #DADEE4;
  border-right: 1px solid #DADEE4;
  border-bottom: 1px solid #DADEE4;
  border-radius: 4px;
  &.Mui-expanded {
    border-radius: 4px 4px 0 0;
  }
  .MuiAccordionSummary-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .branch-name {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.19;
      letter-spacing: 0.01em;
      color: #000;
      svg {
        margin-right: 4px;
      }
      .branch-icon {
        path {
          fill: #00133c;
        }
      }
      .shipping-icon {
        color: #00133c;
      }
      .number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        font-size: 12px;
        font-weight: 600;
        color: #fff;
        border-radius: 50%;
        background: ${({ theme: { colors } }) => colors.mainGreen};
        margin-left: 8px;
      }
    }
    .additional-may-change {
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 0.01em;
      color: #000;
      margin-top: 4px;
    }
  }
  .MuiTypography-root {
    height: 25px;
    @media only screen and (max-width: 767px) {
      height: 15px;
    }
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  flex-direction: column;
  padding: 0 24px;
  @media (max-width: 1023px) {
    display: block;
    width: 100%;
    padding: 0 8px;
  }
  > * {
    &:first-child {
      border-top: 0;
    }
  }
`

export {
  AddressTitle,
  SAWrapper,
  StyledTextField,
  StyledMultiTextField,
  ASTitle,
  FieldContainer,
  FlexNoWrap,
  Flex,
  AddressBlock,
  CustomRadio,
  RBLabel,
  RadioBlock,
  RadioBlockWrapper,
  RadioControl,
  StyledButtonLink,
  ViewCatalogue,
  Attention,
  BlockH,
  Header,
  ProductsLayout,
  Content,
  Layout,
  CustomBreadCrumbs,
  Line,
  DateBlockWrapper,
  StyledKeyboardDatePicker,
  CheckoutNote,
  AccordionContent,
  StyledAccordionDetails
};
