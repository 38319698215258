import { IOrderItem, IAdjustments } from 'models/interfaces/order';
import { ICustomer } from 'models/interfaces/customer';
import { IShippingAddress } from 'models/interfaces/shippingAddress';

export class OrderClass {
  id: number = null;

  checkoutCompletedAt: string = null;

  items: IOrderItem[] = null;

  itemsTotal: number = null;

  adjustments: IAdjustments[] = null;

  adjustmentsTotal: number = null;

  total: number = null;

  state: string = null;

  customer: ICustomer = null;

  shippingAddress: IShippingAddress = null;

  currencyCode: string = null;

  note: string = null;

  createdAt: string = null;

  taxTotal: number = null;

  contactName: string = null;

  contactEmail: string = null;

  erpOrderNumber: string = null;

  customerRef: string = null;

  dateRequired: string = null;

  erpStatus: string = null;

  erpSubStatus: string = null;

  wmsStatus: string = null;

  shipDate: string = null;

  poNumber: string = null;

  tag: string = null;

  erpOrderTotal: number = null;

  erpOrderTaxAmount: number = null;

  isOffline: boolean = null;

  pricing: string = null;

  acceptedOrderNumber: number = null;

  bistrackState: string = null;

  subTotal: number = null;

  saleType: string = null;

  deliveryAddress: string = null;

  purchaseOrderNumber: number = null;

  tnStatus: string = null;

  optInOrderUpdate: boolean = null;

  constructor(...args) {
    args.forEach((item: OrderClass) => {
      this.id = item.id || null;
      this.checkoutCompletedAt = item.checkoutCompletedAt || null;
      this.items = item.items || null;
      this.itemsTotal = item.itemsTotal || null;
      this.adjustments = item.adjustments || null;
      this.adjustmentsTotal = item.adjustmentsTotal || null;
      this.total = item.total || null;
      this.state = item.state || null;
      this.customer = item.customer || null;
      this.shippingAddress = item.shippingAddress || null;
      this.currencyCode = item.currencyCode || null;
      this.note = item.note || null;
      this.createdAt = item.createdAt || null;
      this.taxTotal = item.taxTotal || null;
      this.contactName = item.contactName || null;
      this.contactEmail = item.contactEmail || null;
      this.erpOrderNumber = item.erpOrderNumber || null;
      this.customerRef = item.customerRef || null;
      this.dateRequired = item.dateRequired || null;
      this.erpStatus = item.erpStatus || null;
      this.erpSubStatus = item.erpSubStatus || null;
      this.wmsStatus = item.wmsStatus || null;
      this.shipDate = item.shipDate || null;
      this.poNumber = item.poNumber || null;
      this.tag = item.tag || null;
      this.erpOrderTotal = item.erpOrderTotal || null;
      this.erpOrderTaxAmount = item.erpOrderTaxAmount || null;
      this.isOffline = item.isOffline || null;
      this.pricing = item.pricing || null;
      this.acceptedOrderNumber = null;
      this.bistrackState = item.bistrackState || null;
      this.subTotal = item.subTotal || null;
      this.saleType = item.saleType || null;
      this.deliveryAddress = item.deliveryAddress || null;
      this.purchaseOrderNumber = item.purchaseOrderNumber || null;
      this.tnStatus = item.tnStatus || null;
      this.optInOrderUpdate = item.optInOrderUpdate || null;
    });
  }
}
