import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

// all Sagas
import authSaga from 'ducks/auth/sagas';
import branchSaga from 'ducks/branch/sagas';
import userSaga from 'ducks/user/sagas';
import categorySaga from 'ducks/category/sagas';
import productVariantSaga from 'ducks/productVariant/sagas';
import sliderSaga from 'ducks/slider/sagas';
import faqSaga from 'ducks/FAQ/sagas';
import salesRepresentativeSaga from 'ducks/salesRepresentative/sagas';
import searchSaga from 'ducks/search/sagas';
import profileSaga from 'ducks/profile/sagas';
import cartSaga from 'ducks/cart/sagas';
import orderSaga from 'ducks/order/sagas';
import contactSaga from 'ducks/contact/sagas';

// Reducers
import authReducer from 'ducks/auth/reducers';
import userReducer from 'ducks/user/reducers';
import branchReducer from 'ducks/branch/reducers';
import categoryReducer from 'ducks/category/reducers';
import productReducer from 'ducks/product/reducers';
import productVariantReducer from 'ducks/productVariant/reducers';
import appReducer from 'ducks/application/reducers';
import sliderReducer from 'ducks/slider/reducers';
import faqReducer from 'ducks/FAQ/reducers';
import salesRepresentativeReducer from 'ducks/salesRepresentative/reducers';
import searchReducer from 'ducks/search/reducers';
import appSaga from 'ducks/application/sagas';
import profileReducer from 'ducks/profile/reducers';
import cartReducer from 'ducks/cart/reducers';
import orderReducer from 'ducks/order/reducers';
import contactReducer from 'ducks/contact/reducers';
import gaReducer from 'ducks/GA/reducers';
import checkoutReducer from 'ducks/checkout/reducers';
import uiReducer from 'ducks/ui/reducers';
import localeSaga from './siteLocale/sagas';
import siteLocaleReducer from './siteLocale/reducers';
import klaviyoEventReducer from './klaviyoEvent/reducers';
import klaviyoSaga from './klaviyoEvent/saga';
import quickOrderReducer from './quickOrder/reducers';
import quickOrderSaga from './quickOrder/sagas';

export function* rootSaga() {
  yield all([
    authSaga(),
    userSaga(),
    categorySaga(),
    productVariantSaga(),
    sliderSaga(),
    faqSaga(),
    salesRepresentativeSaga(),
    searchSaga(),
    appSaga(),
    profileSaga(),
    cartSaga(),
    orderSaga(),
    contactSaga(),
    branchSaga(),
    localeSaga(),
    klaviyoSaga(),
    quickOrderSaga()
  ]);
}

export const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  categories: categoryReducer,
  products: productReducer,
  productVariants: productVariantReducer,
  app: appReducer,
  slides: sliderReducer,
  FAQ: faqReducer,
  salesRepresentative: salesRepresentativeReducer,
  searchProducts: searchReducer,
  profile: profileReducer,
  cart: cartReducer,
  order: orderReducer,
  contact: contactReducer,
  branch: branchReducer,
  GA: gaReducer,
  checkout: checkoutReducer,
  ui: uiReducer,
  siteLocale: siteLocaleReducer,
  klavyioEvent: klaviyoEventReducer,
  quickOrder: quickOrderReducer,
});
