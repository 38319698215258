import {Reducer} from 'redux';

// Types
import {ProductActionTypes, ProductState} from 'ducks/product/types';

const initialState: ProductState = {
  product: null,
  error: null,
  isOpenPopupInquire: false,
  clickedProductRequestNearbyHubsAndBranch: null,
  isOpenPopupNearbyBranch: false,
  nearbyBranchData: null,
  clickedProductRequestPricing: null,
};

const productReducer: Reducer<ProductState> = (state = initialState, {type, payload}) => {
  switch (type) {
    case ProductActionTypes.REQUEST_PRODUCT: {
      return {...state, loading: true, product: null};
    }
    case ProductActionTypes.REQUEST_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
        product: payload.product,
      };
    }
    case ProductActionTypes.RESET_PRODUCT: {
      return {...state, product: null};
    }
    case ProductActionTypes.RESET_PRODUCTS_ERROR: {
      return {...state, error: null};
    }
    case ProductActionTypes.SET_OPEN_REQUEST_INQUIRE_NOW_POPUP: {
      return {...state, isOpenPopupInquire: payload.data}
    }
    case ProductActionTypes.SET_CLICKED_PRODUCT_REQUEST_NEARBY_HUBS_AND_BRANCH: {
      return {
        ...state,
        clickedProductRequestNearbyHubsAndBranch: payload.data
      }
    }
    case ProductActionTypes.SET_OPEN_POPUP_NEARBY_BRANCH: {
      return {
        ...state,
        isOpenPopupNearbyBranch: payload.data
      }
    }
    case ProductActionTypes.SET_NEARBY_BRANCH_DATA: {
      return {
        ...state,
        nearbyBranchData: payload.data
      }
    }
    case ProductActionTypes.SET_CLICKED_PRODUCT_REQUEST_PRICING: {
      return {
        ...state,
        clickedProductRequestPricing: payload.data
      }
    }
    default:
      return state;
  }
};

export default productReducer;
