import { createSelector } from 'reselect';
import { RootState } from 'store';

const selectSalesRepresentativeState = (state: RootState) => state.salesRepresentative;

export const selectSalesRepresentative = createSelector(
  selectSalesRepresentativeState,
  salesRepresentativeState => salesRepresentativeState.salesRepresentative
);
export const getSalesRepresentativeLoading = createSelector(
  selectSalesRepresentativeState,
  salesRepresentativeState => salesRepresentativeState.loading
);
