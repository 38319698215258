import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {Container, Link, Grid, Fade, withStyles} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import { PhoneNumber } from 'components';

import logo from 'assets/images/taiga-logo.png';

import { PhoneIcon, EmailIcon } from 'assets/svg/Login';

import { selectStorePhoneNumber } from 'ducks/user/selectors';
import { backgroundSelector } from 'ducks/application/selectors';
import { AppActionTypes } from 'ducks/application/types';
import Button from '@material-ui/core/Button';
import {useTranslation} from 'react-i18next';
import {useLanguage} from '../../../hooks/useLanguage';

const LogoImage = styled.div`
  margin: 0 ${({ theme }) => theme.spacing(1)}px;
  background: url(${logo}) center center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 130px;
  width: 229px;

  @media (max-width: 737px) {
    height: 62px;
    width: 110px;
    margin: 0;
  }
`;

const StyledDiv = styled.div`
  width: 100%;
  padding-left: 120px;
  height: fit-content;
  flex-direction: row;
  display: flex;

  @media (max-width: 737px) {
    padding-left: 0;
  }
`;

const LogoDiv = styled.div`
  display: block;
`;

const ContactsDiv = styled.div`
  position: relative;
  right: 120px;
  padding-top: 46px;
  flex-direction: column;
  display: flex;
  @media (max-width: 737px) {
    padding-top: 26px;
    right: 0;
    padding-right: 10px;
  }
`;

const LanguageDiv = styled.div`
  flex: auto;
  color: white;
  padding-top: 46px;

  @media (min-width: 738px) and (max-width: 800px) {
    padding-top: 0px;
  }
`

const SwitchLanguageButton = withStyles({
  root: {
    margin: '0',
    padding: '0',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.01em',
    color: '#FFFFFF',
    minWidth: '0',
    textTransform: 'none',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
    '&:hover': {
      '& .MuiButton-label': {
        fontWeight: 700,
        color: '#37875F',
      },
      background: 'none',
    },
    '@media (max-width: 720px)': {
      fontSize: '14px',
      lineHeight: '19px',
    },
  },
})(Button);

const TelephoneDiv = styled.div`
  flex: auto;
  color: white;
`;

const EmailDiv = styled.div`
  color: white;
  padding-bottom: 37px;
  @media (max-width: 737px) {
    padding-bottom: 15px;
  }
`;

const PhoneTypography = styled.div`
  display: inline;
  padding-left: 8px;
`;

const EmailTypography = styled.div`
  display: inline;
  padding-left: 12px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  letter-spacing: 0.01em;

  @media (max-width: 737px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

interface LoginProps {
  background: string;
}

const LoginContainer = styled(({ background, ...rest }) => (
  <Container background={background} {...rest} />
))`
  max-width: 100%;
  background: url(${(props: LoginProps) => props.background}) no-repeat center fixed;
  background-size: cover;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow: auto;

  @media (max-width: 737px) {
    padding: 0;
  }
`;

interface ILoginLayout {
  children: React.ReactNode;
}

const AuthComponent: React.FC<ILoginLayout> = ({ children }: ILoginLayout) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const currentLang = useLanguage();
  const [isEnglish, setEnglish] = React.useState(true);
  const backgroundUrl = useSelector(backgroundSelector);
  const phoneNumber = useSelector(selectStorePhoneNumber);

  const replaceLanguage = (lang) => {
    if (lang === currentLang) {
      return;
    }
    let newUrl:string;
    if (window.location.pathname.match('/.*/$')) {
      newUrl = window.location.pathname.replace(`/${currentLang}/`, lang);
    } else {
      newUrl = window.location.pathname.replace(`/${currentLang}`, lang);
    }
    if (window.location.search) {
      newUrl += window.location.search;
    }
    window.location.replace(`/${newUrl}`);
  };

  const handleFrenchClick = async () => {
    await i18n.changeLanguage('fr');
    replaceLanguage('fr');
  };

  const handleEnglishClick = async () => {
    await i18n.changeLanguage('en');
    replaceLanguage('en');
  };

  useEffect(() => {
    if (!['en', 'fr'].includes(currentLang)) {
      window.location.href = i18n.language ? `/${i18n.language}` : '/en';
    }
    if (window.location.pathname.includes('en') && i18n.language === 'en') {
      setEnglish(true);
    } else {
      setEnglish(false);
    }
  }, [currentLang]);

  useEffect(() => {
    dispatch({ type: AppActionTypes.REQUEST_BACKGROUND });
  }, [dispatch]);

  return (
    <Fade in timeout={5000}>
      <LoginContainer background={backgroundUrl} component="main">
        {backgroundUrl ? (
          <>
            <Grid item>
              <StyledDiv>
                <LogoDiv>
                  <Link
                    component={RouterLink}
                    to="/"
                    color="inherit"
                    underline="none"
                    variant="body2"
                  >
                    <LogoImage />
                  </Link>
                </LogoDiv>
                <LanguageDiv>
                  { isEnglish ? (
                    <>
                      <SwitchLanguageButton style={{ fontWeight: 700 }} onClick={handleEnglishClick}>
                        EN
                      </SwitchLanguageButton>
                      <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                      <SwitchLanguageButton onClick={handleFrenchClick}>FR</SwitchLanguageButton>
                    </>
                  ) : (
                    <>
                      <SwitchLanguageButton onClick={handleEnglishClick}>
                        EN
                      </SwitchLanguageButton>
                      <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                      <SwitchLanguageButton style={{ fontWeight: 700 }} onClick={handleFrenchClick}>FR</SwitchLanguageButton>
                    </>
                  ) }
                </LanguageDiv>
                <ContactsDiv>
                  <TelephoneDiv>
                    <PhoneIcon />
                    <PhoneTypography>
                      <PhoneNumber />
                    </PhoneTypography>
                  </TelephoneDiv>
                  <EmailDiv>
                    <EmailIcon />
                    <EmailTypography>taiganow@taigabuilding.com</EmailTypography>
                  </EmailDiv>
                </ContactsDiv>
              </StyledDiv>
            </Grid>
            {phoneNumber ? <>{children}</> : null}
          </>
        ) : (
          ''
        )}
      </LoginContainer>
    </Fade>
  );
};

export default AuthComponent;
