import { Reducer } from 'redux';

import { ContactActionTypes, ContactState } from 'ducks/contact/types';

const initialState: ContactState = {
  contacts: null,
};

const contactReducer: Reducer<ContactState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case ContactActionTypes.REQUEST_FETCH_CONTACTS: {
      return { ...state, loading: true, error: null };
    }
    case ContactActionTypes.REQUEST_FETCH_CONTACTS_SUCCESS: {
      return { ...state, error: null, loading: false, contacts: payload };
    }
    case ContactActionTypes.REQUEST_FETCH_CONTACTS_ERROR: {
      return { ...state, error: payload.error, loading: false };
    }
    default:
      return state;
  }
};

export default contactReducer;
