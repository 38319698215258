import { createSelector } from 'reselect';
import { RootState } from 'store';

const selectUserState = (state: RootState) => state.user;

export const selectStorePhoneNumber = createSelector(
  selectUserState,
  userState => userState.storePhoneNumber,
);

export const selectUserData = createSelector(selectUserState, userState => userState.userData);

export const selectCreateUserProfileResult = createSelector(
  selectUserState,
  userState => userState.isCreateUserSuccess,
);

export const selectUserCreateError = createSelector(
  selectUserState,
  userState => userState.isCreateUserError,
);

export const selectCreateCustomerResult = createSelector(
  selectUserState,
  userState => userState.isCreateCustomerSuccess,
);

export const selectUserAddresses = createSelector(selectUserData, userData => userData?.addresses);

export const selectCreateSubscriberResult = createSelector(
  selectUserState,
  userState => userState.isCreateSubscriberSuccess,
);

export const selectUserErrors = createSelector(selectUserState, userState => userState.error);

export const selectUserLoading = createSelector(selectUserState, userState => userState.loading);

export const selectUserDefaultShippingAddress = createSelector(
  selectUserData,
  userData => userData?.defaultAddress?.id,
);

export const selectUserDefaultShippingAddressCode = createSelector(
  selectUserData,
  userData => userData?.defaultAddress?.erpPostCode,
);

export const selectUserProviceCode = createSelector(
  selectUserData,
  userData => userData?.defaultAddress?.provinceCode,
);

export const selectSalesRepresentative = createSelector(
  selectUserData,
  userData => userData?.defaultAddress.salesRepresentative,
);

export const selectUpdateUserProfileStatus = createSelector(
  selectUserState,
  userState => userState.isUpdateProfileSuccess,
);

export const selectShowRecaptchaModalRegister = createSelector(
  selectUserState,
  userState => userState.showRecaptchaModalRegister,
);

export const selectShowRecaptchaModalSubscriber = createSelector(
  selectUserState,
  userState => userState.showRecaptchaModalSubscriber,
);
