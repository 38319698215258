import {KLAVIYO_EVENT} from './types';

export const setKlaviyoEventStatus = (status: any) => ({
  type: KLAVIYO_EVENT.SET_KLAVIYO_EVENT,
  payload: {
    status
  }
});

export const fireKlaviyoEventGetData = (payload: {productId: number, quantity: number}) => ({
  type: KLAVIYO_EVENT.FIRE_KLAVIYO_EVENT,
  payload
});
