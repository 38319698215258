import React from 'react';
import { useSelector } from 'react-redux';
import Routes from 'routes';
import { Link } from 'react-router-dom';
import { selectStoreBanner } from 'ducks/application/selectors';
import styled from 'styled-components';

interface IBannerMainBlockProps {
  urlBg?: string;
}

const BannerMainBlock = styled.div`
  position: relative;
  background: url(${(p: IBannerMainBlockProps) => p.urlBg}) center center;
  background-size: 100%;
  height: 180px;
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled.button`
  position: absolute;
  bottom: 20px;
  border: none;
  background: ${({ theme: { colors } }) => colors.mainGreen};
  width: 134px;
  height: 36px;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 0 13px 0;
  outline: none;

  &:hover {
    cursor: pointer;
  }
`;

const ButtonTitle = styled.p`
  margin: 0;
  color: ${({ theme: { colors } }) => colors.white};
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.white};
`;

const Banner: React.FC = () => {
  const banner = useSelector(selectStoreBanner);

  let productUrl = null;

  if (banner?.link) {
    [, productUrl] = banner.link.split('/product');
  }

  return (
    <BannerMainBlock urlBg={banner.url}>
      {banner.link && (
        <StyledLink to={`${Routes.HOME}product${productUrl}`}>
          <StyledButton>
            <ButtonTitle>Find Out More</ButtonTitle>
          </StyledButton>
        </StyledLink>
      )}
    </BannerMainBlock>
  );
};

export default Banner;
