import styled from 'styled-components/macro';
import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { MediumButton } from 'components/common/Buttons/MediumButton';

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme: { colors } }) => colors.filterBorderGrey};
  margin-bottom: 30px;
`;

const Attention = styled.h2`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  letter-spacing: 0.1px;
  margin-top: 0;
  margin-bottom: 24px;
  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 25px;
  }
`;

const ViewCatalogue = withStyles({
  root: {
    fontWeight: 600,
    '@media (max-width: 824px)': {
      width: '100%',
    },
  },
})(MediumButton);

const StyledButtonLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.white};
`;

export { StyledButtonLink, ViewCatalogue, Attention, Line };
