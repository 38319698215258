import React, { Dispatch, MutableRefObject, SetStateAction, useEffect } from 'react';

import {
  IRangeFilter,
  ISelectedValue,
  IMultiselectFilterValues,
} from 'models/interfaces/productVariant';
import { useMobileFilter } from 'components/Product/ProductFilter/hooks/useMobileFilter';
import DesktopAllFiltersWrapper from 'components/Product/ProductFilter/components/DesktopAllFiltersWrapper/DesktopAllFiltersWrapper';
import MobileAllFiltersWrapper from 'components/Product/ProductFilter/components/MobileAllFiltersWrapper/MobileAllFiltersWrapper';
import { FacetClass } from 'models/classes/facetClass';
import {getFillterAttrs} from 'utils/common/common';
import { selectFacets } from 'ducks/productVariant/selectors';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {generateFilter} from './utils';
import {AppStorage} from '../../App/services/storage';
import DesktopSearchAllFilterWrapper from './components/DesktopSearchAllFiltersWrapper/DesktopSearchAllFilterWrapper';
import MobileSearchPageAllFiltersWrapper
  from './components/MobileSearchPageAllFiltersWrapper/MobileSearchPageAllFiltersWrapper';

interface IProductFilter {
  setSelectedValues: Dispatch<SetStateAction<ISelectedValue[]>>;
  setShow: Dispatch<SetStateAction<boolean>>;
  setFilter: Dispatch<SetStateAction<{}>>;
  selectedValues: ISelectedValue[];
  show: boolean;
  isMobile: boolean;
  productsAmount: number;
  setInStock: Dispatch<SetStateAction<boolean>>;
  rangeFilters: IRangeFilter[];
  multiselectFacets: FacetClass[];
  scrollEl: MutableRefObject<HTMLElement>;
  handleChange: (facetCode: string, bucketLabel: string, bucketKey: string) => void;
  findNecessaryCheck: (facetProperty: string, bucketLabel: string) => boolean;
  handleRangeFilterChange: (code: string, name: string, values: number[]) => void;
  handleMultiselectFilterChange: (
    code: string,
    name: string,
    values: IMultiselectFilterValues
  ) => void;
  categoryCode: string;
}

const ProductFilter: React.FC<IProductFilter> = ({
  setSelectedValues,
  setShow,
  setFilter,
  selectedValues,
  show,
  isMobile,
  productsAmount,
  setInStock,
  rangeFilters,
  multiselectFacets,
  scrollEl,
  handleChange,
  findNecessaryCheck,
  handleRangeFilterChange,
  handleMultiselectFilterChange,
  categoryCode
}: IProductFilter) => {
  const location = useLocation();
  const isSearchPage = location.pathname.includes('/search');
  const {
    clearFilter,
  } = useMobileFilter(selectedValues, setFilter, setSelectedValues, setInStock, isMobile);

  const facets = useSelector(selectFacets);

  useEffect(() => {
    const filteredStorage = AppStorage.getFiltered()
    if (filteredStorage) {
      const filtered = JSON.parse(filteredStorage)
      let filterAttrs: ISelectedValue[] = []
      if (filtered.filterAttributes && JSON.stringify(filtered.filterAttributes) !== JSON.stringify({})) {
        filterAttrs = getFillterAttrs(facets, filtered.filterAttributes)
        setSelectedValues(filterAttrs)
        setFilter(generateFilter(filterAttrs));
      }
    }
  }, []);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [show]);

  useEffect(() => {
    if (scrollEl) {
      scrollEl.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [scrollEl, selectedValues]);

  const renderDesktopFilterByPath = () => {
    return isSearchPage ? (
      <DesktopSearchAllFilterWrapper
        selectedValues={selectedValues}
        productsAmount={productsAmount}
        clearFilter={clearFilter}
        rangeFilters={rangeFilters}
        handleChange={handleChange}
        findNecessaryCheck={findNecessaryCheck}
        handleRangeFilterChange={handleRangeFilterChange}
        handleMultiselectFilterChange={handleMultiselectFilterChange}
        multiselectFacets={multiselectFacets}
      />
    ) : (
      <DesktopAllFiltersWrapper
        selectedValues={selectedValues}
        productsAmount={productsAmount}
        clearFilter={clearFilter}
        rangeFilters={rangeFilters}
        handleChange={handleChange}
        findNecessaryCheck={findNecessaryCheck}
        handleRangeFilterChange={handleRangeFilterChange}
        handleMultiselectFilterChange={handleMultiselectFilterChange}
        multiselectFacets={multiselectFacets}
        categoryCode={categoryCode}
      />
    );
  }

  const renderMobileFilterByPath = () => {
    return !isSearchPage ? (
      <MobileAllFiltersWrapper
        facets={facets}
        findNecessaryCheck={findNecessaryCheck}
        clearFilter={clearFilter}
        handleChange={handleChange}
        setShow={setShow}
        show={show}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        setFilter={setFilter}
        multiselectFacets={multiselectFacets}
        categoryCode={categoryCode}
      />
    ) : (
      <MobileSearchPageAllFiltersWrapper
        findNecessaryCheck={findNecessaryCheck}
        clearFilter={clearFilter}
        handleChange={handleChange}
        setShow={setShow}
        show={show}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        setFilter={setFilter}
        multiselectFacets={multiselectFacets}
      />
    );
  }

  return !isMobile ? renderDesktopFilterByPath() : (
    renderMobileFilterByPath()
  );
};

export default ProductFilter;
