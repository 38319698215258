import React from 'react';

export const SalesManAvatar = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="50" height="50" rx="4" fill="#DADEE4" />
      <path
        d="M37.2597 35.5712L31.194 34.0544L30.6778 31.9897C32.3007 30.3827 33.4122 28.1755 33.7415 25.9113C34.6408 25.658 35.3375 24.8869 35.4578 23.9195L35.8536 20.7528C35.9376 20.0846 35.7317 19.4086 35.2884 18.9019C35.0287 18.6042 34.7009 18.3794 34.3384 18.2416L34.4841 15.2602L35.0763 14.6664C35.9677 13.718 36.7071 12.0919 35.1618 9.73273C33.9743 7.91994 31.9571 7 29.1641 7C28.0621 7 25.5257 7 23.1633 8.5865C16.4183 8.72744 15.5 11.8608 15.5 14.9167C15.5 15.6276 15.6725 17.2284 15.7865 18.1942C15.3796 18.3224 15.0107 18.5599 14.7225 18.8877C14.2713 19.3991 14.0623 20.0784 14.1462 20.7544L14.542 23.9211C14.6719 24.9534 15.4556 25.7657 16.4468 25.9589C16.7745 28.1359 17.829 30.2719 19.3585 31.8521L18.8075 34.0576L12.7418 35.5744C8.7708 36.564 6 40.1138 6 44.2083C6 44.6453 6.35469 45 6.79169 45H43.2083C43.6453 45 44 44.6422 44 44.2052C43.9999 40.1138 41.2291 36.564 37.2597 35.5712Z"
        fill="white"
      />
    </svg>
  );
};
