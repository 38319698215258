import React, {useCallback, useMemo, useState} from 'react';
import { ProductVariantClass } from 'models/classes/productVariantClass';
import { ProductClass } from 'models/classes/productClass';
import orderBy from 'lodash/orderBy';
import clsx from 'clsx';
import {FindMatchProduct, IOption} from 'models/interfaces/product';
import {useTranslation} from 'react-i18next';
import {IPrice} from 'models/interfaces/productVariant';
import { useProductOptions } from '../hooks';
import { OptionSectionSnackBar } from './SnackBar/OptionSectionSnackBar';

import { ProductAttributeWrapper, InfoSectionTitle, WarningNote } from '../styles';
import {SwatchTypeV2} from './SwatchTypeV2';
import {DefaultTypeV2} from './DefaultTypeV2';
import { SYS_WEIGHT } from '../../../../utils/constant';

interface IOptionSection {
  product: ProductClass;
  productVariant: ProductVariantClass;
  onProductVariantChoose: (productVar: ProductVariantClass) => void;
  price: IPrice;
}

function OptionsSection({ product, productVariant, onProductVariantChoose, price }: IOptionSection) {
  const {t} = useTranslation();
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [isHasMatchProduct, setIsHasMatchProduct] = useState(true);
  const [selectedCode, setSelectedCode] = useState(null);
  const productOptionList = useProductOptions(product);
  const initOption = useMemo(() => productVariant.optionValues.length ? productVariant.optionValues.map(option => ({
    code: option.code,
    optionCode: option.optionCode
  })) : [], [productVariant.optionValues]);

  const findMatchProducts: Array<FindMatchProduct> | [] = useMemo(() =>  {
    const matchProducts = [];
    product.variants.filter(variant => variant.id !== productVariant.id).forEach(variant => {
      let count = 0;
      const itemCode = [];
      variant.optionValues.forEach(item => {
        itemCode.push({[item.code]: item.optionCode});
        if (initOption.some(option => option.optionCode === item.optionCode && option.code === item.code)) {
          count+=1;
        }
      });
      matchProducts.push({
        erpProductId: variant.erpProductId,
        count,
        itemCode,
        uom1ToUom2Conversion: variant.uom1ToUom2Conversion,
        units: variant.units
      });
    });
    return matchProducts.filter(math => math.count === initOption.length - 1) || [];
  }, [initOption, product.variants, productVariant.id]);

  const handleOptionClick = useCallback((optionCode: string, code: string): void => {
    const isSelected = productVariant.optionValues.filter(optionValue => {
      return optionValue.optionCode === optionCode
    });

    if (isSelected.length > 0) {
      return;
    }

    const arrayOfPossibleOptionValues = [
      ...productVariant.optionValues
        // exclude an option from current variant to find all other options
        .filter(item => item.optionCode !== code)
        .map(item => {
          return item.optionCode;
        }),
      optionCode,
    ];
    // find whether a variant with selected options exist or not
    const isVariantExist = product.variants.some(item => {
      return item.optionValues.every(item2 => {
        return arrayOfPossibleOptionValues.includes(item2.optionCode);
      });
    });
    let optionDoNotMatchAnyProduct = false;
    if (isVariantExist) {
      const checkedVariant = product.variants.filter(p => p.erpProductId !== productVariant.erpProductId).find(variant => {
        return variant.optionValues.every(item => {
          return arrayOfPossibleOptionValues.includes(item.optionCode);
        });
      });
      if (checkedVariant) {
        onProductVariantChoose(checkedVariant);
        setIsHasMatchProduct(true);
      } else {
        optionDoNotMatchAnyProduct = true;
      }
    } else {
      optionDoNotMatchAnyProduct = true;
    }
    if (optionDoNotMatchAnyProduct) {
      const matchProduct = [];
      product.variants.forEach(variant => {
        let count = 0;
        const itemCode = [];
        variant.optionValues.forEach(item => {
          itemCode.push(item.optionCode);
          if (arrayOfPossibleOptionValues.includes(item.optionCode)) {
            count+=1;
          }
        });
        matchProduct.push({
          erpProductId: variant.erpProductId,
          count,
          itemCode
        });
      });
      const filteredMath = matchProduct.filter(math => math.erpProductId !== productVariant.erpProductId);
      const sortProductMath = orderBy(filteredMath, 'count', 'desc');
      const checkedVariant = product.variants.filter(p => p.erpProductId !== productVariant.erpProductId).find(variant => {
        return sortProductMath.some(item => {
          return variant.erpProductId === item.erpProductId && item.itemCode.includes(optionCode);
        })
      });
      onProductVariantChoose(checkedVariant);
      setIsSnackBarOpen(true);
      setIsHasMatchProduct(false);
    }
    setSelectedCode(code);
  }, [onProductVariantChoose, product.variants, productVariant.erpProductId, productVariant.optionValues]);

  const isOptionChangeToFindProduct = (option: IOption) => {
    const matchNotOption = [];
    productVariant.optionValues.map(pOption => {
      const selectedOption = initOption.find(o => o.code === pOption.code);
      if (selectedOption && selectedOption.code !== pOption.code) {
        matchNotOption.push(selectedOption.optionCode);
      }
      return pOption;
    });

    return selectedCode && selectedCode !== option.code && initOption.length && matchNotOption.includes(option.code);
  }

  const getCurrentOptionName = useCallback((option: IOption) => {
    return productVariant.optionValues.find(item => item.optionCode === option.code)?.value;
  }, [productVariant])

  return (
    <>
      <OptionSectionSnackBar open={isSnackBarOpen} setOpenSnackbar={setIsSnackBarOpen} />
      {product.variants.length > 1 &&
        product.options.filter(option => option.values.length > 0 && option.code !== SYS_WEIGHT).map((option) => (
          <ProductAttributeWrapper key={option.code} className={clsx({
            'warning': isOptionChangeToFindProduct(option),
            'highlight': selectedCode && selectedCode === option.code,
          })}>
            <InfoSectionTitle className={`${option.viewType === 'dropdown' ? 'info-section-title-dropdown' : ''}`}><strong>{option.name}</strong>: {getCurrentOptionName(option)}</InfoSectionTitle>
            {option.viewType === 'swatch' && productOptionList[option.code].length <= 24 ? (
              <SwatchTypeV2
                optionValues={productOptionList[option.code]}
                currentProductVariant={productVariant}
                onAttributeClick={handleOptionClick}
                code={option.code}
                findMatchProducts={findMatchProducts}
                option={option}
                product={product}
                price={price}
              />
            ) : (
              <DefaultTypeV2
                optionValues={productOptionList[option.code]}
                option={option}
                currentProductVariant={productVariant}
                onAttributeClick={handleOptionClick}
                findMatchProducts={findMatchProducts}
                product={product}
                price={price}
              />
            )}
          </ProductAttributeWrapper>
        ))}
      {
        !isHasMatchProduct && (
          // @ts-ignore
          <WarningNote>
            <svg className="ic-warning" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 17.0625C4.5525 17.0625 0.9375 13.4475 0.9375 9C0.9375 4.5525 4.5525 0.9375 9 0.9375C13.4475 0.9375 17.0625 4.5525 17.0625 9C17.0625 13.4475 13.4475 17.0625 9 17.0625ZM9 2.0625C5.175 2.0625 2.0625 5.175 2.0625 9C2.0625 12.825 5.175 15.9375 9 15.9375C12.825 15.9375 15.9375 12.825 15.9375 9C15.9375 5.175 12.825 2.0625 9 2.0625Z" fill="#FFAC62"/>
              <path d="M9 10.3125C8.6925 10.3125 8.4375 10.0575 8.4375 9.75V6C8.4375 5.6925 8.6925 5.4375 9 5.4375C9.3075 5.4375 9.5625 5.6925 9.5625 6V9.75C9.5625 10.0575 9.3075 10.3125 9 10.3125Z" fill="#FFAC62"/>
              <path d="M9 12.7502C8.9025 12.7502 8.805 12.7277 8.715 12.6902C8.625 12.6527 8.5425 12.6002 8.4675 12.5327C8.4 12.4577 8.3475 12.3827 8.31 12.2852C8.2725 12.1952 8.25 12.0977 8.25 12.0002C8.25 11.9027 8.2725 11.8052 8.31 11.7152C8.3475 11.6252 8.4 11.5427 8.4675 11.4677C8.5425 11.4002 8.625 11.3477 8.715 11.3102C8.895 11.2352 9.105 11.2352 9.285 11.3102C9.375 11.3477 9.4575 11.4002 9.5325 11.4677C9.6 11.5427 9.6525 11.6252 9.69 11.7152C9.7275 11.8052 9.75 11.9027 9.75 12.0002C9.75 12.0977 9.7275 12.1952 9.69 12.2852C9.6525 12.3827 9.6 12.4577 9.5325 12.5327C9.4575 12.6002 9.375 12.6527 9.285 12.6902C9.195 12.7277 9.0975 12.7502 9 12.7502Z" fill="#FFAC62"/>
            </svg>
            {t('product_detail.auto_change_option_notification')}
          </WarningNote>
        )
      }
    </>
  );
}

export { OptionsSection };
