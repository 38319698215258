import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useChangeStep } from 'pages/Checkout/hooks';

import { IRequestData } from 'models/interfaces/order';
import axios from 'utils/axios';
import {requestCartSummary, setAddToTruckStatus, setStoreFirstLoadSummaryStatus} from 'ducks/cart/actions';
import { setOrdersOrQuotesVisited } from 'ducks/GA/actions';
import { toggleSnackbarOpen } from 'ducks/ui/actions';
import {priceTypeSelector, selectAddressId} from 'ducks/application/selectors';
import {setReorderStatus} from 'ducks/order/actions';
import {selectCart, selectStoreCartFirstLoadSuccess} from 'ducks/cart/selectors';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import { pushKlaviyoEvent } from 'utils/klaviyo';
import { selectCategories } from 'ducks/category/selectors';
import { useLanguage } from 'hooks/useLanguage';
import { getDefaultCategoryName } from 'utils/categories/categories';
import { getDefaultProductName } from 'utils/products/productUtilsHelpers';

export const useOrdersFilter = (initialState: IRequestData) => {
  const [filterParams, setFilterParams] = useState<IRequestData>(initialState);
  const [isApplyFilterInput, setIsApplyFilterInputInput] = useState(false);

  const handleFilterChange = data => {
    const filter = Object.keys(data).reduce<IRequestData>((accum, value) => {
      if (data[value]) {
        return { ...accum, [value]: data[value] };
      }
      return accum;
    }, {});

    setFilterParams(filter);
    if (filter['input'] !== undefined) {
      setIsApplyFilterInputInput(true);
    }
  };

  return {
    filterParams,
    handleFilterChange,
    isApplyFilterInput,
    setIsApplyFilterInputInput
  };
};

export const useDebouncedStateUpdate = (filterParams: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(filterParams);

  useEffect(() => {
    const handler = setTimeout(() => {
      const filterParamsCopy = { ...filterParams };

      setDebouncedValue(filterParamsCopy);
    }, delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler);
    };
  }, [filterParams, delay]);

  return debouncedValue;
};

export const useSetOrdersOrQuotesVisit = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOrdersOrQuotesVisited());
  }, [dispatch]);
};

interface IAddProductPayload {
	id: number;
	quantity: number;
  orderId: number;
}

export const useAddReorderedProducts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const address = useSelector(selectAddressId);
  const deliveryType = useSelector(priceTypeSelector);
  const changeStep = useChangeStep();
  const cart = useSelector(selectCart);
  const storeCartFirstLoadStatus = useSelector(selectStoreCartFirstLoadSuccess);
  const categories = useSelector(selectCategories);
  const priceType = useSelector(priceTypeSelector);
  const currentLang = useLanguage();

  useEffect(() => {
    if (storeCartFirstLoadStatus && cart.items.length > 0) {
      dispatch(setStoreFirstLoadSummaryStatus(false));
      dispatch(setReorderStatus(true));
    }

  }, [dispatch, storeCartFirstLoadStatus, cart]);

  return useMutation(
    (payload: IAddProductPayload[]) =>
      axios.post('/api/auth/cart/reorder', { products: payload, deliveryType }, {
        headers: {
          'X-Address-Id': address.toString(),
        },
      }),
    {
      onSuccess: data => {
        // debouncedCartUpdate(data); TODO requestCartSummary is temporary decision, later use this
        if (data) {
          changeStep(1);
          dispatch(setAddToTruckStatus(true));
          dispatch(requestCartSummary(address));
          const cartItems = get(data, 'data.data.items', []);
          const total = cartItems.reduce((acc, item) => acc + item.total, 0);
          const addedCategories = cartItems.map(item => item.categoryName);
          const addedItemsName = cartItems.map(item => item.variant.name);
          const items = cartItems.map(item => ({
            ProductID: item.variant.erpProductId,
            SKU: item.variant.erpSku,
            ProductName: item.variant.name,
            Quantity: item.quantity,
            ItemPrice: item.variant.prices[0][priceType],
            RowTotal: item.total,
            ProductURL: `${window.location.origin}/${currentLang}/product/${item.variant.erpProductId}`,
            ImageURL: item.variant.images[0]?.url || '',
            ProductCategories: [item.categoryName],
          }));
          const successAddedToCartItems = [];
          cartItems.map(p => {
            const item = cartItems.find(cItem => cItem.productId === p.productId);
            if (item) {
              let price = 0;
              switch (priceType) {
                case 'delivery':
                  price = item.productDeliveryPrice;
                  break;
                case 'pickup':
                  price = item.productPickupPrice;
                  break;
                default:
                  price = 0;
                  break;
              }
              pushKlaviyoEvent('Added to Cart', {
                '$value': total,
                'AddedItemProductName': item.variant.name,
                'AddedItemProductID': item.variant.erpProductId,
                'AddedItemSKU': item.variant.erpSku,
                'AddedItemCategories': uniq(addedCategories),
                'AddedItemImageURL': item.productImage,
                'AddedItemURL': `${window.location.origin}/${currentLang}/product/${item.variant.erpProductId}`,
                'AddedItemPrice': price,
                'AddedItemQuantity': p.quantity,
                'ItemNames': uniq(addedItemsName),
                'CheckoutURL': `${window.location.origin}/${currentLang}/checkout`,
                'Items': items
              });
              const { itemCategoryName, itemCategory2Name } = getDefaultCategoryName(
                item.categoryId,
                categories
              );
              successAddedToCartItems.push({
                item_id: item.variant.erpSku,
                item_name: getDefaultProductName(item.variant),
                item_category: itemCategoryName,
                item_category2: itemCategory2Name,
                quantity: p.quantity,
                price,
              });
            }
            return p;
          });
        }
      },
      onError: () => {
        dispatch(toggleSnackbarOpen('error', t('products_carousel.can_not_add_to_truck')));
      }
    },
  );
};
