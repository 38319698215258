import styled from 'styled-components/macro';

const ContentContainer = styled.div`
  font-family: Open Sans;
  margin-top: 50px;
  text-align: justify;
`;

const PageTitle = styled.h1`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  letter-spacing: 0.1px;
  margin: 12px 0 24px;
`;

const StyledOl = styled.ol`
   {
    counter-reset: num;
    list-style: none;
    padding: 0;
    margin: 10px 0;

    & > li {
      counter-increment: num;
      margin: 0;
      padding: 0 0 10px 40px;
      position: relative;

      &::before {
        content: counter(num);
      }
    }
    & > div {
      margin: 0;
      padding: 0 0 10px 0;
    }

    ol {
      counter-reset: num2;
      list-style: none;
      padding-inline-start: 0;

      & > li {
        counter-increment: num2;

        &::before {
          content: counter(num) '.' counter(num2);
        }
      }
    }
  }
`;

const StyledLi = styled.li`
   {
    margin: 0;
    padding: 0 0 10px 40px;
    position: relative;

    &:before {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

export { ContentContainer, PageTitle, StyledOl, StyledLi };
