import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {useEffect, useRef} from 'react';

import { selectUserData } from 'ducks/user/selectors';
import {selectAddressCode, selectAddressId, selectLanguage} from 'ducks/application/selectors';
import { authenticatedSelector } from 'ducks/auth/selectors';
import { fetchProfile } from 'ducks/user/actions';
import {fetchSpecialPage, setLanguage, setPriceType} from 'ducks/application/actions';
import { fetchBranch, fetchBranches } from 'ducks/branch/actions';
import { fetchSlider } from 'ducks/slider/actions';
import { fetchCategories, fetchParentCategories } from 'ducks/category/actions';

import { AppStorage } from 'components/App/services/storage';
import { selectSiteLocales } from 'ducks/siteLocale/selectors';
import { fetchSiteLocales } from 'ducks/siteLocale/actions';
import { fetchSalesRepresentative } from 'ducks/salesRepresentative/actions';
import { toggleShowPrices } from 'ducks/productVariant/actions';
import { getFullLanguage, getFullLanguageCode } from '../i18n';
import { selectBranch } from '../ducks/branch/selectors';

export const useApp = () => {
  const user = useSelector(selectUserData);
  const selectedAddressCode = useSelector(selectAddressCode);
  const selectedAddressId = useSelector(selectAddressId);
  const language = useSelector(selectLanguage);
  const isAuthenticated = useSelector(authenticatedSelector);
  const siteLocales = useSelector(selectSiteLocales);
  const branch = useSelector(selectBranch);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const deliveryType = AppStorage.getDeliveryType();
  const currentSelectedAddressId = useRef(null);

  const chosenLanguage = () => {
    if (!i18n.language && user) {
      return getFullLanguageCode(user.defaultLocale.code);
    }

    if (!i18n.language && siteLocales.length > 0) {
      return siteLocales.filter(lang => lang.isDefault).map(lang => lang.code)[0];
    }

    return i18n.language ? getFullLanguage(i18n.language) : 'en_CA';
  };

  const lang = chosenLanguage();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchProfile());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (siteLocales.length === 0) {
      dispatch(fetchSiteLocales());
    }
    dispatch(setLanguage(lang));
  }, [siteLocales, lang, dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(fetchSlider());
      dispatch(fetchBranch());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user && selectedAddressId && currentSelectedAddressId.current !== selectedAddressId && branch) {
      dispatch(fetchSalesRepresentative());
      dispatch(fetchCategories(selectedAddressId, language, branch.id));
      dispatch(fetchParentCategories(branch.id, language));
      dispatch(fetchSpecialPage());
      currentSelectedAddressId.current = selectedAddressId;
    }
    if (!user) {
      dispatch(fetchBranches());
    }
    return () => {
      currentSelectedAddressId.current = null;
    }
  }, [user, selectedAddressId, dispatch, language, currentSelectedAddressId.current, branch]);

  useEffect(() => {
    if (deliveryType === 'hide') {
      dispatch(toggleShowPrices(false));
    }
    dispatch(setPriceType(deliveryType));
  }, [deliveryType, dispatch]);

  return {
    user,
    selectedAddressCode,
    selectedAddressId
  };
};
