import React from 'react';
import styled from 'styled-components/macro';
import { FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { MediumButton } from 'components/common/Buttons/MediumButton';

const FormsContainer = styled.div`
  width: 100%;
  margin: 0;
  max-width: 664px;
  padding: 0 64px 0 0;
  border-right: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  @media (max-width: 1280px) {
    border-right: none;
  }
  @media (max-width: 1023px) {
    max-width: 100%;
    padding: 30px 10px;
    border-radius: 2px;
    border-right: none;
  }
  @media (max-width: 700px) {
    max-width: 100%;
    padding: 30px 10px;
    border-left: 2px solid ${({ theme: { colors } }) => colors.mainGreen};
    border-radius: 2px;
  }
`;

const LanguagePricingWrapper = styled.div`
  display: flex;
  margin-bottom: 49px;

  @media (max-width: 1023px) {
    flex-direction: column;
    margin-bottom: 40px;
  }

  @media (max-width: 320px) {
    flex-direction: column;
    margin-bottom: 40px;
  }

  .MuiFormControl-root {
    &:not(:last-child) {
      @media (max-width: 1023px) {
        margin-bottom: 40px;
      }
    }
  }
`;

const ShippingAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 49px;

  @media (max-width: 1023px) {
    gap: 0;
  }

  @media (max-width: 320px) {
    margin-bottom: 40px;
  }
`;

const ShippingAddressVisual = styled.div`
  position: relative;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  cursor: pointer;
  padding: 18px 28px 18px 14px;
  .txt-field-label {
    position: absolute;
    left: 9px;
    top: 0;
    transform: translate(0, -50%);
    font-size: 12px;
    color: #939599;
    background: #fff;
    padding: 0 5px;
  }
  .ic-arrow {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translate(0, -50%);
    color: #939599;
  }
  .txt-address {
    display: block;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 5px;
  }
  .txt-info {
    display: block;
    font-size: 16px;
    .txt-name {
    }
    .divider {
      padding: 0 10px;
    }
    .txt-phone {
    }
  }
`;

const AddressAutocomplete = styled.div`
  position: relative;
  .MuiAutocomplete-popper {
    position: absolute;
    left: 0;
    top: 48px;
    .MuiAutocomplete-listbox {
      li {
        position: relative;
        padding-left: 28px;
        .ic-check {
          display: block;
          position: absolute;
          left: 0;
          top: 5px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 2px solid #BFBFBF;
          &.checked {
            border: 4px solid ${({ theme: { colors } }) => colors.mainGreen};
          }
        }
        .txt-address {
          display: block;
          font-weight: 600;
          font-size: 18px;
          margin-bottom: 5px;
        }
        .txt-info {
          display: block;
          font-size: 16px;
          .txt-name {
          }
          .divider {
            padding: 0 10px;
          }
          .txt-phone {
          }
        }
      }
    }
  }
`;

const NameEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChangePassword = styled.div`
  float: right;
  margin-top: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme: { colors } }) => colors.mainGreen};

  @media (max-width: 320px) {
    font-size: 12px;
    color: ${({ theme: { colors } }) => colors.mainGreen};
  }
`;

const AddressInfo = styled.div`
  position: relative;
  width: 100%;
  padding: 25px 37px 25px 24px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  background: ${({ theme: { colors } }) => colors.lightGrey};
  border: 1px solid ${({ theme: { colors } }) => colors.lightGreyBoxShadowSearchResult};
  border-radius: 4px;

  @media (max-width: 1023px) {
    margin-top: 40px;
    min-height: 282px;
    padding: 20px 27px 20px 20px;
  }
`;

const AddressName = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.textColor};
  margin-bottom: 15px;
  margin-top: 0;

  @media (max-width: 1023px) {
    margin-bottom: 14px;
    font-size: 16px;
    line-height: 16px;
  }
`;

const AddressProperty = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.textColor};
  margin-bottom: 15px;
  margin-top: 0;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1023px) {
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 19px;
  }
`;

const AddressLabel = styled.div`
  position: absolute;
  top: -10px;
  left: 10px;
  padding: 0 5px;
  font-size: 12px;
  color: ${({ theme: { colors } }) => colors.grey};
  background-color: ${({ theme: { colors } }) => colors.white};
`;

const StyledMenuItem = styled(MenuItem)`
  font-family: 'Open Sans';
`;

const StyledSelect = styled(Select)`
  &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme: { colors } }) => colors.blackOpacity(0.23)};
  }
`;

const StyledTextField = withStyles({
  root: {
    margin: '0 0 33px 0',
    '&:last-child': {
      margin: 0,
    },
    '& .MuiInputBase-root': {
      fontFamily: 'Open Sans',
      height: '48px',
    },
    '& .MuiInputLabel-outlined': {
      fontFamily: 'Open Sans',
    },
    '& .MuiInputBase-multiline': {
      height: 'auto',
    },
    '& .MuiInputLabel-root': {
      fontFamily: 'Open Sans',
      color: '#939599',
      transform: 'translate(14px, 18px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '17px 14px',
    },
    '& .Mui-focused': {
      backgroundColor: '#F7F7F7',
    },
    '& .Mui-focused.MuiFormLabel-root': {
      backgroundColor: '#FFFFFF',
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      borderColor: '#DADEE4',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#000000',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#C4C4C4',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00853e',
        },
      },
    },
    '@media (max-width: 1023px)': {
      margin: '0 0 16px 0',
      '&:last-child': {
        margin: 0,
      },
    },
    '@media (max-width: 320px)': {
      margin: '0 0 16px 0',
      '&:last-child': {
        margin: 0,
      },
      '& .MuiInputBase-root': {
        height: '40px',
      },
      '& .MuiInputBase-multiline': {
        height: 'auto',
      },
      '& .MuiInputLabel-root': {
        transform: 'translate(14px, 12px) scale(1)',
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -8px) scale(0.75)',
      },
    },
  },
})(TextField);

const StyledTextFieldDisabled = withStyles({
  root: {
    backgroundColor: '#F7F7F7',
    margin: '0',
    '& .MuiInputBase-root': {
      height: '48px',
      fontFamily: 'Open Sans',
    },
    '& .MuiInputBase-multiline': {
      height: 'auto',
    },
    '& .MuiInputLabel-root': {
      color: '#939599',
      transform: 'translate(14px, 18px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '17px 14px',
    },
    '& .Mui-focused': {
      backgroundColor: '#F7F7F7',
    },
    '& .Mui-focused.MuiFormLabel-root': {
      backgroundColor: '#FFFFFF',
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      borderColor: '#DADEE4',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#000000',
    },
    '@media (max-width: 320px)': {
      '& .MuiInputBase-root': {
        height: '40px',
      },
      '& .MuiInputBase-multiline': {
        height: 'auto',
      },
      '& .MuiInputLabel-root': {
        transform: 'translate(14px, 12px) scale(1)',
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -8px) scale(0.75)',
      },
    },
  },
})(TextField);

const StyledFormControl = withStyles({
  root: {
    width: '100%',
    margin: '0 20px 0 0',
    '&:last-child': {
      margin: 0,
    },
    '& .MuiInputBase-formControl': {
      height: '48px',
    },
    '& .MuiInputLabel-root': {
      fontFamily: 'Open Sans',
      color: '#939599',
      transform: 'translate(14px, 18px) scale(1)',
      backgroundColor: '#FFFFFF',
      paddingRight: '5px',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .Mui-focused.MuiFormLabel-root': {
      backgroundColor: '#FFFFFF',
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      borderColor: '#00853e',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Open Sans',
    },
    '@media (max-width: 1023px)': {
      height: 'auto',
      margin: '0 0 16px 0',
      '&:last-child': {
        margin: 0,
      },
      '& .MuiInputBase-formControl': {
        height: '40px',
      },
      '& .MuiInputLabel-root': {
        transform: 'translate(14px, 12px) scale(1)',
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -8px) scale(0.75)',
      },
    },
    '@media (max-width: 320px)': {
      height: 'auto',
      margin: '0 0 16px 0',
      '&:last-child': {
        margin: 0,
      },
      '& .MuiInputBase-formControl': {
        height: '40px',
      },
      '& .MuiInputLabel-root': {
        transform: 'translate(14px, 12px) scale(1)',
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -8px) scale(0.75)',
      },
    },
  },
})(FormControl);

const StyledFormButton = withStyles({
  root: {
    height: '48px',
    width: '185px',
    borderRadius: '4px',
    marginTop: '24px',
    '@media (max-width:480px)': { width: '100%' },
    '@media (max-width:320px)': {
      height: '40px',
      marginTop: '24px',
      fontSize: '14px',
    },
  },
})(MediumButton);

const CheckboxWrapper = styled.div`
  display: flex;
  margin-top: 24px;
`;

const CheckboxWrapperWithoutMargin = styled.div`
  display: flex;
  padding-left: 4px;
  padding-right: 10px;
  margin-top: 0;
  margin-bottom: 0;
`;

const CheckboxTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 12px 0;
  letter-spacing: 0.01em;
`;

const GreenCheckbox = withStyles({
  root: {
    height: '24px',
    width: '24px',
    marginTop: '12px',
    marginLeft: '-3px',
    color: '#DADEE4',
    '&$checked': {
      color: '#00853e',
    },
    '&.MuiIconButton-root': {
      padding: '0 9px 0 0',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {},
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export {
  FormsContainer,
  LanguagePricingWrapper,
  ShippingAddressWrapper,
  ShippingAddressVisual,
  AddressAutocomplete,
  NameEmailWrapper,
  ChangePassword,
  AddressInfo,
  AddressName,
  AddressProperty,
  AddressLabel,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
  StyledTextFieldDisabled,
  StyledFormControl,
  StyledFormButton,
  CheckboxWrapper,
  CheckboxTitle,
  GreenCheckbox,
  CheckboxWrapperWithoutMargin,
};
