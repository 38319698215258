import {all, call, debounce, put, takeEvery, takeLatest} from 'redux-saga/effects';
import axios from 'utils/axios';

// Actions
import {
  storeFilteredProducts,
  storeFilteredProductsFirstLoad,
  storeSearchProductDumpIds,
  storeSearchProducts,
  storeSearchProductsFilter,
  storeSearchProductsFirstLoad,
} from 'ducks/search/actions';

// Types
import {
  FetchFilteredProductsAction,
  ISearchDumpProductIdsAction,
  ISearchProductsAction,
  ISearchProductsFilterAction,
  SearchActionTypes,
} from 'ducks/search/types';

// Classes
import {ProductVariantClass} from 'models/classes/productVariantClass';
import {FacetClass} from 'models/classes/facetClass';

import {productToProductVariantClass} from 'utils/products/productVariant';
import {setServerError} from 'ducks/application/actions';
import {getError} from 'utils/errors';
import {IRangeFilter} from 'models/interfaces/productVariant';
import {AppStorage} from '../../components/App/services/storage';

function* requestFilteredProducts(fetchFilteredProductsAction: FetchFilteredProductsAction) {
  try {
    const payload = {...fetchFilteredProductsAction.payload};
    const {firstLoad, addressId, language} = payload;
    const filter = {...fetchFilteredProductsAction.payload.filterAttributes};
    delete payload.filterAttributes;

    if (firstLoad) {
      delete payload.firstLoad;
    }
    const [{data: filtersResponse}, {data: response}] = yield all([
      // @ts-ignore
      call(axios.get, '/api/auth/product/filters', {
        params: {
          ...payload,
          ...filter,
        },
        headers: {
          accept: 'application/ld+json',
          'X-Address-Id': addressId,
          'Accept-Language': language,
        },
      }),
      // @ts-ignore
      call(axios.get, '/api/auth/product/get-list', {
        params: {
          ...payload,
          ...filter,
        },
        headers: {
          accept: 'application/ld+json',
          'X-Address-Id': addressId,
          'Accept-Language': language,
        },
      }),
    ]);

    const products: ProductVariantClass[] = response['hydra:member'].map(product =>
      productToProductVariantClass(product)
    );
    const total = response['hydra:totalItems'];
    const facets: FacetClass[] = filtersResponse.facets.map(facet => new FacetClass(facet));
    facets.sort((a, b) => Number(a.position) - Number(b.position));

    const rangeFilters: IRangeFilter[] = filtersResponse.range_filters.sort(
      (a, b) => Number(a.position) - Number(b.position)
    );

    if (firstLoad) {
      yield put(
        storeFilteredProductsFirstLoad(
          products,
          facets,
          rangeFilters,
          !response['hydra:view']['hydra:next'],
          total
        )
      );
    } else {
      yield put(
        storeFilteredProducts(
          products,
          facets,
          rangeFilters,
          !response['hydra:view']['hydra:next'],
          total
        )
      );
    }
  } catch (error) {
    yield put(setServerError(getError(error.response, [])));
  }
}

function* searchProducts(searchProductAction: ISearchProductsAction) {
  try {
    const payload = {...searchProductAction.payload};
    const filters = payload.filterAttributes;
    const {firstLoad} = payload;
    AppStorage.setFiltered(JSON.stringify(payload))
    if (firstLoad) {
      delete payload.firstLoad;
    }
    if (payload.filterAttributes) {
      delete payload.filterAttributes;
    }
    const {data} = yield call(axios.get, '/api/auth/product/external/search/full-view', {
      headers: {
        accept: 'application/json',
        'X-Address-Id': payload.addressId,
        'Accept-Language': payload.language,
      },
      params: {
        ...payload,
        ...filters,
      },
    });
    const products = data.data.data.map(productVariant =>
      productToProductVariantClass(productVariant)
    );
    const isLastPage = data.data.currentPage === data.data.lastPage;
    const {total} = data.data;
    if (firstLoad) {
      yield put(storeSearchProductsFirstLoad(products, total, isLastPage));
    } else {
      yield put(storeSearchProducts(products, total, isLastPage));
    }
  } catch (error) {
    yield put(setServerError(getError(error.response, [])));
  }
}

function* searchProductsFilter(searchProductFilterAction: ISearchProductsFilterAction) {
  try {
    const payload = {...searchProductFilterAction.payload};
    const {data} = yield call(axios.get, '/api/auth/product/filters/search-product', {
      headers: {
        accept: 'application/json',
        'accept-language': payload.language,
        'X-Address-Id': payload.addressId,
      },
      params: {
        query: payload.query,
      },
    });
    const facets: FacetClass[] = data.data.facets.map(facet => new FacetClass(facet));
    facets.sort((a, b) => Number(a.position) - Number(b.position));
    yield put(storeSearchProductsFilter(facets));
  } catch (error) {
    yield put(setServerError(getError(error.response, [])));
  }
}

function* searchProductDumpIds(searchDumpProductIdsAction: ISearchDumpProductIdsAction) {
  try {
    const {payload} = searchDumpProductIdsAction;
    const {filters} = payload;
    const {data} = yield call(axios.get, '/api/auth/product/search/dump-product-ids', {
      headers: {
        accept: 'application/json',
        'X-Address-Id': payload.addressId,
      },
      params: {
        query: payload.query,
        address_id: payload.addressId,
        ...filters,
      },
    });
    yield put(storeSearchProductDumpIds(data.data));
    // eslint-disable-next-line no-empty
  } catch (error) {
  }
}

function* watchSearchProductDumpIds() {
  yield takeEvery(SearchActionTypes.REQUEST_SEARCH_DUMP_PRODUCT_IDS, searchProductDumpIds);
}

function* watchRequestFilteredProducts() {
  yield debounce(1000, SearchActionTypes.REQUEST_FILTERED_PRODUCTS, requestFilteredProducts);
}

function* watchSearchProducts() {
  yield takeLatest(SearchActionTypes.REQUEST_SEARCH_PRODUCTS, searchProducts);
}

function* watchSearchProductsFilter() {
  yield takeLatest(SearchActionTypes.REQUEST_SEARCH_PRODUCTS_FILTER, searchProductsFilter);
}

export default function* searchSaga() {
  yield all([
    watchRequestFilteredProducts(),
    watchSearchProducts(),
    watchSearchProductsFilter(),
    watchSearchProductDumpIds(),
  ]);
}
