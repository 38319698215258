import { CategoryClass } from 'models/classes/categoryClass';
import { ICategoryResponse } from 'models/interfaces/category';

export const categoryToCategoryClass = (category: ICategoryResponse) => {
  const newCategory = {
    id: category.id,
    name: category.name,
    code: category.code,
    image: category?.images?.length ? category?.images[0]?.url : null,
    url: category?.url || null,
    urlPath: category?.urlPath || null,
    productAmount: category?.productAmount || 3,
    description: category?.description || null,
    children: category?.children || null,
    categoryPage: category?.categoryPage || null,
    items: category?.items || 0,
    showSecondLayout: category?.showSecondLayout,
    bannerUrl: category.bannerUrl || null,
    parent: category.parent || null,
    position: category.position || null,
    parentId: category.parentId || null,
    defaultLanguage: category.defaultLanguage || null,
    translations: category.translations || null,
  };

  return new CategoryClass(newCategory);
};

interface IGetDefaultCategoryNameResponse {
  itemCategoryName: string;
  itemCategory2Name: string;
}
export const getDefaultCategoryName = (categoryId: number, categories: CategoryClass[]): IGetDefaultCategoryNameResponse => {
  const defaultParentCategory = {
    defaultLanguage: {
      name: ''
    }
  };
  const defaultCurrentCategory = {
    defaultLanguage: {
      name: '',
    },
    parentId: 0
  }
  let currentCategory: { defaultLanguage: { name: string }; parentId: number } = categories.find(c => categoryId === c.id);
  let parentCategory;
  if (!currentCategory) {
    currentCategory = defaultCurrentCategory;
    parentCategory = defaultParentCategory;
  } else{
    parentCategory = categories.find(c => currentCategory.parentId === c.id);
    if (!parentCategory) {
      parentCategory = defaultParentCategory;
    }
  }

  return {
    itemCategoryName: parentCategory.defaultLanguage.name,
    itemCategory2Name: currentCategory.defaultLanguage.name
  }
}