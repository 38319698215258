import styled from 'styled-components/macro';
import { Typography } from '@material-ui/core';

const SubcategoriesWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
`;

const SubcategoryBoxList = styled.div`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .thumb-vertical {
    opacity: 0;
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: inherit;
    background-color: rgba(0, 0, 0, 0.2);
    transition: opacity 0.4s ease;
  }
  .list {
    margin: -9px;
  }
  .item {
    padding: 9px;
    margin-bottom: 6px;
    &.expanded {
      .box-item {
        border-bottom: 1px solid #eaeaea;
      }
    }
    a {
      text-decoration: none;
    }
  }
  .box-card-item {
    background: #f7f7f7;
    border-radius: 4px;
  }
  .box-item {
    display: block;
    cursor: pointer;
    &:hover {
      .box-txt {
        .txt-title {
          color: ${({ theme: { colors } }) => colors.mainGreen};
        }
      }
    }
  }
  .box-card-header {
    height: 100%;
    padding: 10px 10px 0;
    .box-item {
      display: block;
      padding: 0 0 10px;
    }
    .box-thumb {
      flex: 0 0 60px;
      .img {
        width: 60px;
        height: 60px;
        border-radius: 3px;
      }
    }
    .box-txt {
      flex: 1 1 calc(100% - 60px - 30px);
      padding-top: 5px;
      .txt-title {
        font-size: 18px;
        @media (max-width: 1023px) {
          font-size: 14px;
        }
      }
      .txt-count {
        font-size: 14px;
        @media (max-width: 1023px) {
          font-size: 12px;
        }
      }
    }
  }
  .box-card-body-wrapper {
    &:hover {
      .thumb-vertical {
        opacity: 1;
      }
    }
  }
  .box-card-body {
    padding: 0 10px 0 25px;
    .box-item {
      border-bottom: 1px solid #eaeaea;
      padding: 10px 0;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  .box-thumb {
    flex: 0 0 46px;
    .img {
      width: 46px;
      height: 46px;
      overflow: hidden;
      border-radius: 50%;
      text-decoration: none;
      background-repeat: no-repeat;
      background-position: center top;
      background-size: cover;
    }
  }
  .box-txt {
    flex: 1 1 calc(100% - 46px - 30px);
    padding-left: 10px;
    .txt-title {
      font-weight: bold;
      font-size: 14px;
      color: ${({ theme: { colors } }) => colors.textColor};
      margin-bottom: 3px;
      transition: all 0.4s ease;
    }
    .txt-count {
      font-size: 12px;
      color: ${({ theme: { colors } }) => colors.textColor};
    }
  }
  .box-arrow {
    display: block;
    flex: 0 0 30px;
    align-self: center;
    &:hover {
      svg {
        left: 5px;
      }
      path {
        stroke: ${({ theme: { colors } }) => colors.mainGreen};
      }
    }
    svg {
      position: relative;
      left: 0;
      transition: all 0.4s ease;
    }
    path {
      transition: all 0.4s ease;
    }
  }
`;

const Header = styled.h1`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  letter-spacing: 0.1px;
  margin-top: 0;
`;

const ShopInfo = styled(Typography)`
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;

  @media (max-width: 1023px) {
    font-size: 14px;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
`;

const FlexTopSkeleton = styled.div`
  margin-top: 16px;
  width: 100%;
`;

const ImageContainer = styled.div`
  width: 100%;

  @media (min-width: 1920px) {
    display: flex;
    justify-content: center;
  }
`;

const ImageComponent = styled.img`
  max-width: 1440px;
	max-height: 400px;
  width: 100%;
  display: block;
`;

export {
  SubcategoriesWrapper,
  SubcategoryBoxList,
  Header,
  ShopInfo,
  Flex,
  FlexTopSkeleton,
  ImageContainer,
  ImageComponent,
};
