import { useQuery } from 'react-query';
import { fetchGlobalSetting } from 'api/Setting';

const GLOBAL_SETTINGS_QUERY_KEY = 'GLOBAL_SETTING_QUERY';

export const useGlobalSettings = () =>
  useQuery<unknown, Error, {isShowOrderUpdateEmail: boolean; isEnableCheckout: boolean}>({
    queryKey: [GLOBAL_SETTINGS_QUERY_KEY],
    queryFn: async () => {
      try {
        const { data} = await fetchGlobalSetting();
        return data.setting;
      } catch {
        return {};
      }
    },
  });

export { GLOBAL_SETTINGS_QUERY_KEY };