import { useQuery } from 'react-query';
import { fetchGlobalBanner } from '../api/GlobalBanner';

const GLOBAL_BANNER_QUERY_KEY = 'GLOBAL_BANNER_QUERY_KEY';

const useGlobalBanner = (branchId: number) => useQuery<unknown, Error, {text: string|null}>({
  queryKey: [GLOBAL_BANNER_QUERY_KEY],
  queryFn: async () => {
    try {
      const { data} = await fetchGlobalBanner(branchId);
      return data.data;
    } catch {
      return {};
    }
  },
  enabled: !!branchId,
});

export { GLOBAL_BANNER_QUERY_KEY, useGlobalBanner };
