import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { IPagination } from 'models/interfaces/productVariant';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectAddressId, selectErpCustomerAddressCode } from 'ducks/application/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import {
  StyledParagraph,
  StyledMenuItem,
  StyledFormControl,
  StyledSelect,
  Wrapper,
} from './styles';

interface ISortDropdown {
  sortProducts: (value: number) => void;
  setPagination: Dispatch<SetStateAction<IPagination>>;
  pagination: IPagination;
  setFirstLoad: Dispatch<SetStateAction<boolean>>;
  isSearch?: boolean;
  defaultSortByValue: number;
  chosenSortType?: number;
}

const useStyles = makeStyles({
  select: {
    '&': {
      marginTop: '10px !important',
      width: '212px',
    },
    '& ul': {
      width: '100%',
    },
    '& li': {
      fontSize: '14px',
      fontFamily: 'Open Sans',
      lineHeight: '21px',
      letterSpacing: '0.01em',
    },
    '@media( max-width: 1023px)': {
      '&': {
        marginTop: '10px !important',
        width: '170px',
      },
    },
  },
});

const SortDropdown: React.FC<ISortDropdown> = ({
  sortProducts,
  setPagination,
  pagination,
  setFirstLoad,
  isSearch,
  defaultSortByValue,
  chosenSortType,
}: ISortDropdown) => {
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const defineSortBy = () => {
    return chosenSortType || defaultSortByValue;
  };

  const [sortBy, setSortBy] = React.useState(defineSortBy());

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFirstLoad(true);
    setPagination({ ...pagination, page: 1 });
    setSortBy(event.target.value as number);
    sortProducts(event.target.value as number);
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'change_sort_by',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
  };
  const classes = useStyles();

  const { t } = useTranslation();

  useEffect(() => {
    setSortBy(chosenSortType);
  }, [chosenSortType]);

  return (
    <Wrapper>
      <StyledFormControl>
        <StyledSelect
          value={sortBy}
          onChange={handleChange}
          disableUnderline
          IconComponent={ExpandMore}
          displayEmpty
          MenuProps={{
            MenuListProps: {
              disablePadding: true,
            },
            classes: {
              paper: classes.select,
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            getContentAnchorEl: null,
          }}
        >
          {isSearch && (
            <StyledMenuItem value={6}>
              <StyledParagraph>{t('products_and_search.relevance')}</StyledParagraph>
            </StyledMenuItem>
          )}
          <StyledMenuItem value={1}>
            <StyledParagraph>{t('products_and_search.best_selling')}</StyledParagraph>
          </StyledMenuItem>
          <StyledMenuItem value={2}>
            <StyledParagraph>{t('products_and_search.sort_price_low_high')}</StyledParagraph>
          </StyledMenuItem>
          <StyledMenuItem value={3}>
            <StyledParagraph>{t('products_and_search.sort_price_high_low')}</StyledParagraph>
          </StyledMenuItem>
          <StyledMenuItem value={4}>
            <StyledParagraph>{t('products_and_search.name_a-z')}</StyledParagraph>
          </StyledMenuItem>
          <StyledMenuItem value={5}>
            <StyledParagraph>{t('products_and_search.name_z-a')}</StyledParagraph>
          </StyledMenuItem>
        </StyledSelect>
      </StyledFormControl>
    </Wrapper>
  );
};

export default SortDropdown;
