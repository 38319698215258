import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { useFormik } from 'formik';
import { BlackPhone2, GreenPhone } from 'assets/svg/Popups/Popups';
import { sendNearbyBranchEmail } from 'ducks/productVariant/actions';
import { selectUserData } from 'ducks/user/selectors';
import { SalesRepresentativeClass } from 'models/classes/salesRepresentative';
import { ProductVariantClass } from 'models/classes/productVariantClass';
import defaultAvatar from 'assets/images/default-product.png';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from 'utils/common/common';
import get from 'lodash/get';
import { selectAddressId, selectErpCustomerAddressCode } from 'ducks/application/selectors';
import { NearbyBranchContext } from 'context/NearbyBranchContext';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import TagManager from 'react-gtm-module';
import { selectBranch } from 'ducks/branch/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import {
  SingleCheckboxWrapper,
  PhoneButton,
  StyledButton,
  ButtonsWrapper,
  BackToProducts,
  MessageTextField,
  QuantityTextField,
  PhoneNumberWrapper,
  PopUpText,
  PopUpText2,
  ProductWrapper,
  PopUpHeader,
  StyledWrapper,
  PhoneNumber,
} from '../styles';

interface IProductPricingEmail {
  product: ProductVariantClass;
  disabled: boolean;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  handleClose: Function;
  salesRepresentative: SalesRepresentativeClass;
  error: boolean;
}

const NearbyBranchEmail: React.FC<IProductPricingEmail> = ({
  product,
  error,
  disabled,
  setDisabled,
  salesRepresentative,
  handleClose,
}: IProductPricingEmail) => {
  const { emailType, fromBranchId, setFromBranchId } = React.useContext(NearbyBranchContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const { defaultAddress, email, customer } = useSelector(selectUserData);
  const selectedAddressId = useSelector(selectAddressId);
  const branch = useSelector(selectBranch);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);

  const renderBackToProducts = () => (
    <BackToProducts type="button" onClick={() => handleClose()}>
      <p>{t('request_pricing_popup.back_to_products')}</p>
    </BackToProducts>
  );

  const initialValues = {
    quantity: undefined,
    message: '',
    addressId: selectedAddressId || defaultAddress.id || undefined,
  };

  const { values, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues,
    onSubmit: ({ message, quantity, addressId }) => {
      if (disabled) return;
      setDisabled(true);
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'inventory_request',
          user_type: getUserType(email),
          branch_id: branch.id,
          customer_code: customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: addressId,
        },
      });
      dispatch(
        sendNearbyBranchEmail(
          emailType,
          addressId,
          fromBranchId,
          product.id,
          product.category.id,
          product?.nearbyBranches?.onHand,
          quantity,
          message
        )
      );
      setTimeout(() => {
        setFromBranchId(null);
      }, 1000);
    },
    enableReinitialize: false,
  });

  const renderTextFields = () => (
    <form onSubmit={handleSubmit}>
      <QuantityTextField
        label={t('request_pricing_popup.quantity')}
        variant="outlined"
        name="quantity"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.quantity}
        fullWidth
      />

      <MessageTextField
        multiline
        rows={5}
        label={t('request_pricing_popup.message')}
        variant="outlined"
        name="message"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.message}
        fullWidth
      />
      <ButtonsWrapper>
        {renderBackToProducts()}
        <StyledButton type="submit" disabled={error || disabled}>
          <p>{t('request_pricing_popup.button')}</p>
        </StyledButton>
      </ButtonsWrapper>
    </form>
  );

  const productImages = get(product, 'images', []);

  return (
    <StyledWrapper>
      <PopUpHeader>{t('nearby_branch.request_nearby_branch_popup_title')}</PopUpHeader>
      <ProductWrapper>
        <img
          alt="product avatar"
          src={
            productImages.length > 0 && productImages[0].url ? productImages[0].url : defaultAvatar
          }
        />
        <p>{product.name}</p>
      </ProductWrapper>
      <PopUpText>
        {t('request_pricing_popup.call_your_representative', {
          representativeName: salesRepresentative.name,
        })}
      </PopUpText>
      <PhoneNumberWrapper>
        {!isMobile
          ? salesRepresentative.workPhone && (
            <>
              <BlackPhone2 />
              <PhoneNumber>{formatPhoneNumber(salesRepresentative.workPhone)}</PhoneNumber>
            </>
          )
          : salesRepresentative.workPhone && (
            <PhoneButton>
              <GreenPhone />
              <PhoneNumber>{formatPhoneNumber(salesRepresentative.workPhone)}</PhoneNumber>
            </PhoneButton>
          )}
      </PhoneNumberWrapper>
      <SingleCheckboxWrapper>
        <PopUpText2>{t('nearby_branch.send_email_to_inquiry')}</PopUpText2>
      </SingleCheckboxWrapper>
      {renderTextFields()}
    </StyledWrapper>
  );
};

export default NearbyBranchEmail;
