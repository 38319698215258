import { createSelector } from 'reselect';
import { RootState } from 'store';

const selectProductState = (state: RootState) => state.productVariants;

export const selectProducts = createSelector(
  selectProductState,
  productState => productState.products
);

export const selectFeaturedProducts = createSelector(
  selectProductState,
  productState => productState.featuredProducts
);

export const selectFacets = createSelector(selectProductState, productState =>
  productState.facets.filter(facet => !facet.configuration.isRange)
);

export const selectMultiselectFacets = createSelector(selectProductState, productState =>
  productState.facets.filter(facet => facet.configuration.isRange)
);

export const selectRangeFilters = createSelector(
  selectProductState,
  productState => productState.rangeFilters
);

export const selectIsLastPage = createSelector(
  selectProductState,
  productState => productState.isLastPage
);

export const selectProductVariantsError = createSelector(
  selectProductState,
  productState => productState.error
);

export const selectProductsLoading = createSelector(
  selectProductState,
  productState => productState.loading
);

export const selectPaginationLoading = createSelector(
  selectProductState,
  productState => productState.paginationLoading
);

export const selectShowPrices = createSelector(
  selectProductState,
  productState => productState.showPrices
);

export const selectAssociations = createSelector(selectProductState, productState => {
  return {
    similarProducts: productState.similarProducts,
    alsoLikeProducts: productState.alsoLikeProducts,
    accessories: productState.accessories,
  };
});

export const selectProductIdsList = createSelector(selectProductState, productState => productState.productIdsList);
export const selectRelatedProducts = createSelector(selectProductState, productState => productState.relatedProducts);
export const selectAlterNativeProducts = createSelector(selectProductState, productState => productState.alterNativeProducts);

export const selectTotal = createSelector(selectProductState, productState => productState.total);
export const selectSendEmailNearbyBranchStatus = createSelector(selectProductState, productState => productState.sendEmailNearbyBranchStatus);
export const selectProductIdListLoading = createSelector(selectProductState, productState => productState.productIdListLoading);
