import { useSelector } from 'react-redux';

import { selectCategories } from 'ducks/category/selectors';
import { ProductClass } from 'models/classes/productClass';

const useProductListBreadCrumbs = (pathname: string, product?: ProductClass) => {
  let mainCategoryCode: string;
  let mainCategoryName: string;
  let categoryName: string;
  let categoryCode: string;

  const allCategories = useSelector(selectCategories);

  const categoriesPath = pathname.split('/');

  if (product && categoriesPath[1] === 'product') {
    [, mainCategoryCode] = product?.mainTaxon.slug.replace(/-/g, '_').split('/');
    const findCategoryName = categories => {
      return categories.reduce((acc, category) => {
        return category.code === mainCategoryCode ? category.name : acc;
      }, '');
    };
    mainCategoryName = findCategoryName(allCategories);
    categoryName = product?.mainTaxon.name;
    categoryCode = product?.mainTaxon.code;
  } else {
    [, , mainCategoryCode] = categoriesPath;

    mainCategoryName = allCategories.reduce((acc, category) => {
      return category.code === mainCategoryCode ? category.name : acc;
    }, '');

    [, , , categoryCode] = categoriesPath;

    categoryName = allCategories.reduce((acc, category) => {
      return category.code === categoryCode ? category.name : acc;
    }, '');
  }

  return {
    mainCategoryCode,
    categoryCode,
    mainCategoryName,
    categoryName,
  };
};

export default useProductListBreadCrumbs;
