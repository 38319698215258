import { ProfileActionTypes } from 'ducks/profile/types';

export const fetchStoreContactProfile = () => ({
  type: ProfileActionTypes.REQUEST_STORE_CONTACT_PROFILE,
});

interface IPostChangePassword {
  currentPassword: string;
  newPassword: string;
}

export const postChangePassword = (payload: IPostChangePassword) => ({
  type: ProfileActionTypes.REQUEST_CHANGE_PASSWORD,
  payload,
});

export const refreshPasswordState = () => ({
  type: ProfileActionTypes.REFRESH_PASSWORD_STATE,
  payload: { error: null },
});

export const resetProfileError = () => ({
  type: ProfileActionTypes.RESET_ERROR,
});
