import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';

import { AddToTruck } from 'assets/svg/FeaturedProducts/FeaturedProducts';

const ProductCardGrid = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
  margin-bottom: 16px;

  @media (max-width: 1023px) {
    width: 50%;
    height: 100%;
    justify-content: center;
    margin-left: 0;
    margin-bottom: 21px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ProductCard = styled.div`
  width: 208px;
  @media (max-width: 1023px) {
    width: 100%;
    height: 100%;
    padding: 0;
  }
`;

const DefaultAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  padding-bottom: 100%;
  @media (max-width: 1023px) {
    padding-bottom: 274px;
  }
  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    object-fit: cover;
    object-position: center top;
    border: 1px solid ${({ theme: { colors } }) => colors.productBorderGrey};
  }
`;

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  padding-bottom: 100%;
  @media (max-width: 1023px) {
    padding-bottom: 274px;
  }
  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    object-fit: cover;
    object-position: center top;
    border: 1px solid ${({ theme: { colors } }) => colors.productBorderGrey};
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  width: 90%;
  position: absolute;
  right: 10px;
  bottom: 10px;

  @media (max-width: 1023px) {
    position: inherit;
    bottom: unset;
    right: unset;
    width: 100%;
  }
`;

const ProductInfoWrapper = styled.div`
  padding: 14px 12px;
  height: 410px;
  position: relative;
  @media (max-width: 1023px) {
    height: auto;
  }
  &:hover {
    @media (min-width: 1024px) {
      background: #ffffff;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.12);
      border-radius: 6px;
      padding: 14px 12px;
    }
  }
`;

const ProductName = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 2px;
  overflow-wrap: break-word;
  @media (max-width: 1023px) {
    -webkit-line-clamp: initial;
    -webkit-box-orient: initial;
    margin-top: 12px;
  }
  &:hover {
    color: ${({ theme: { colors } }) => colors.mainGreen};
  }
`;

const Price = styled.p`
  margin: 0;
  font-family: Open Sans;
  font-weight: 700;
  font-size: 20px;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  line-height: 130%;
  letter-spacing: 0.01em;
`;

const Each = styled.p`
  margin: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme: { colors } }) => colors.letterGrey};
`;

const RequestPricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
`;

const RequestPricingButton = styled.button`
  display: flex;
  justify-content: center;
  background: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  border-radius: 4px;
  line-height: 12px;
  padding: 7px 12px;
  outline: none;
  cursor: pointer;
  &:hover {
    border: 1px solid ${({ theme: { colors } }) => colors.mainGreen};
  }
  &:active {
    border: 1px solid ${({ theme: { colors } }) => colors.greenPeaRequestPricing};
  }
`;

const RequestPricing = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  text-align: center;
  margin: 0;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.black};
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;
  border-radius: 4px;
`;

const StyledAddToTruck = styled(AddToTruck)`
  cursor: pointer;
`;

const AddToTruckWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledPricesContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -4px;
  height: 50px;
`;

const StyledSecondPriceText = styled.span`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme: { colors } }) => colors.textColor};
`;

const StyledFirstPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: -2px;
`;

const StyledAvailableAndLeadTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0 24px;
`;

const StyledAvailableAndLeadTime = styled.p`
  color: #196742;
  font-family: 'Open Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 145.455% */
  text-decoration-line: underline;
  cursor: pointer;
  margin: 0;
  &:hover {
    font-weight: bold;
  }
  &.fr {
    line-height: 13px;
  }
  &.on-detail {
    display: block;
    font-size: 16px;
    line-height: calc(22 / 16);
    margin-top: 4px;
    margin-bottom: 4px;
  }
`;

const StyledCheckNearByBranch = styled.p`
  margin: 0 0 3px;
  &.fr {
    span {
      letter-spacing: -0.1px;
    }
  }
  span {
    display: inline-flex;
    color: ${({ theme: { colors } }) => colors.mainGreen};
    text-align: left;
    font-family: 'Open Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
  }
`;

const InquireNowButtonTitle = styled.p`
  margin: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #007a33;
`;

const InquireNowButton = styled.button`
  margin-top: 8px;
  cursor: pointer;
  padding: 7px 12px;
  background-color: #ffffff;
  border: 1px solid #007a33;
  border-radius: 4px;
  display: flex;
  width: 100%;
  justify-content: center;
  outline: none;
  @media (max-width: 1023px) {
    width: 100%;
  }
  &:hover {
    background-color: ${({ theme: { colors } }) => colors.mainGreenHover};
    color: #ffffff;
  }
  &:hover ${InquireNowButtonTitle} {
    color: #ffffff;
  }
  &:active {
    background-color: ${({ theme: { colors } }) => colors.mainGreefPassed};
    color: #ffffff;
  }
  &.fr {
    padding: 2px 12px;
    margin-top: 5px;
  }
`;

export {
  AddToTruckWrapper,
  StyledAddToTruck,
  StyledIconButton,
  StyledLink,
  RequestPricing,
  RequestPricingButton,
  RequestPricingWrapper,
  Each,
  Price,
  ProductName,
  ProductInfoWrapper,
  PriceWrapper,
  AvatarWrapper,
  DefaultAvatarWrapper,
  ProductCard,
  ProductCardGrid,
  StyledPricesContainer,
  StyledSecondPriceText,
  StyledFirstPriceWrapper,
  StyledAvailableAndLeadTimeWrapper,
  StyledAvailableAndLeadTime,
  StyledCheckNearByBranch,
  InquireNowButton,
  InquireNowButtonTitle,
};
