import {all, call, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import axios from 'utils/axios';
import { storeError } from 'utils/errors';
import defaultBackground from 'assets/images/taiga.png';
import { setDefaultBackground } from 'ducks/application/actions';
import {
  AppActionTypes,
  SendContactUsEmailAction,
  GetBannerByCodeAction,
} from 'ducks/application/types';
import {selectAddressId} from './selectors';

function* requestBannerLink() {
  try {
    const { data } = yield call(axios.get, '/api/auth/authorization-banner');
    if (data?.data?.url) {
      yield put({ type: AppActionTypes.REQUEST_BANNER_SUCCESS, payload: data.data.url });
    }
  } catch (error) {
    yield storeError(AppActionTypes.REQUEST_BANNER_ERROR, [404], error.response);
  }
}

function* requestBackgroundLink() {
  try {
    const res = yield call(axios.get, '/api/auth/background-image');

    if (res.data) {
      yield put({ type: AppActionTypes.REQUEST_BACKGROUND_SUCCESS, payload: res.data.data });
    }
  } catch (error) {
    yield put(setDefaultBackground(defaultBackground));
    yield storeError(AppActionTypes.REQUEST_BACKGROUND_ERROR, [404, 400], error.response);
  }
}

function* requestSendContactUsEmail(sendContactUsEmailAction: SendContactUsEmailAction) {
  try {
    const { payload } = sendContactUsEmailAction;
    yield call(axios.post, '/api/contact-us', { ...payload });
    yield put({ type: AppActionTypes.REQUEST_SEND_CONTACT_US_SUCCESS });
  } catch (error) {
    yield storeError(AppActionTypes.REQUEST_SEND_CONTACT_US_ERROR, [400, 422], error.response);
  }
}

function* getStoreBanner(getBannerByCodeAction: GetBannerByCodeAction) {
  const { code, branch } = getBannerByCodeAction.payload;
  try {
    const { data } = yield call(axios.get, `/api/auth/popup-banner/${code}/${branch}`);
    const storeBanner = data.data;
    yield put({
      type: AppActionTypes.REQUEST_STORE_BANNER_SUCCESS,
      payload: { storeBanner },
    });
  } catch (error) {
    yield storeError(AppActionTypes.REQUEST_STORE_BANNER_ERROR, [404, 422], error.response);
  }
}

function* getSpecialPage() {
  try {
    const address = yield select(selectAddressId);
    const {data: response} = yield call(axios.get, '/api/auth/special-page', {
      headers: {
        'x-address-id': address
      },
    });
    yield put({type: AppActionTypes.REQUEST_GET_SPECIAL_PAGE_SUCCESS, payload: response});
  } catch (error) {
    yield put({type: AppActionTypes.REQUEST_GET_SPECIAL_PAGE_ERROR});
  }
}

function* watchGetSpecialPage() {
  yield takeLatest(AppActionTypes.REQUEST_GET_SPECIAL_PAGE, getSpecialPage);
}

function* watchRequestBannerLink() {
  yield takeEvery(AppActionTypes.REQUEST_BANNER, requestBannerLink);
}
function* watchRequestBackgroundLink() {
  yield takeEvery(AppActionTypes.REQUEST_BACKGROUND, requestBackgroundLink);
}

function* watchRequestSendContactUsEmail() {
  yield takeEvery(AppActionTypes.REQUEST_SEND_CONTACT_US, requestSendContactUsEmail);
}

function* watchGetStoreBanner() {
  yield takeEvery(AppActionTypes.REQUEST_STORE_BANNER, getStoreBanner);
}

export default function* appSaga() {
  yield all([
    watchRequestBannerLink(),
    watchRequestBackgroundLink(),
    watchRequestSendContactUsEmail(),
    watchGetStoreBanner(),
    watchGetSpecialPage(),
  ]);
}
