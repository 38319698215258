/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LocationIcon } from 'assets/svg/LocationIcon';
import { selectUserAddresses, selectUserData } from 'ducks/user/selectors';
import {
  selectAddressCode,
  selectAddressId,
  selectErpCustomerAddressCode,
} from 'ducks/application/selectors';
import { AppStorage } from 'components/App/services/storage';
import { setAddressCode, setAddressId } from 'ducks/application/actions';
import { selectBranch } from 'ducks/branch/selectors';
import TagManager from 'react-gtm-module';
import { getAddressString, getUserType } from 'utils/profile/profileUtilsHelpers';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { useOutsideClick } from 'hooks/useOutSideClick';
import { IAddress } from 'models/interfaces/user';
import { patchProfile } from 'ducks/user/actions';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { LocationWrapperTitle, StyledFormControl, AutocompleteWrapper } from './styles';

const LocationDropdown: React.FC = () => {
  const user = useSelector(selectUserData);
  const userAddresses = useSelector(selectUserAddresses);
  const selectedAddress = useSelector(selectAddressCode);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const dispatch = useDispatch();
  const branch = useSelector(selectBranch);
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const isFiredEventUserLogin = useRef(false);

  const handleChange = async inputSearchAddress => {
    const selectAddress = user.addresses.find(adr => {
      return adr.id === inputSearchAddress.id;
    });
    await AppStorage.setDefaultAddressId(selectAddress.id);
    await AppStorage.setDefaultAddressCode(selectAddress.erpPostCode);
    dispatch(setAddressCode(selectAddress.erpPostCode));
    dispatch(setAddressId(selectAddress.id));
    dispatch(patchProfile({ defaultAddressId: selectAddress.id }));
    setIsShowDropdown(false);
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'change_address',
        branch_id: selectAddress.branchId,
        address_code: selectAddress.erpCustomerAddressCode,
        address_id: selectAddress.id,
        customer_code: selectAddress.customer.erpCustomerCode,
        user_type: getUserType(user.email),
        user_ID: selectAddress.customer.id,
      },
    });
    window.dispatchEvent(new Event('storage'));
  };

  const handleOutSideClick = () => {
    setIsShowDropdown(false);
  };

  useEffect(() => {
    if (user && branch && erpCustomerAddressCode && selectedAddressId) {
      isFiredEventUserLogin.current = true;
      if (!isFiredEventUserLogin.current) {
        clearGtmEcommerce();
        TagManager.dataLayer({
          dataLayer: {
            event: 'userLogin',
            user_ID: user.id,
            user_type: getUserType(user.email),
            branch_id: branch.id,
            customer_code: user?.customer?.erpCustomerCode,
            address_code: erpCustomerAddressCode,
            address_id: selectedAddressId,
          },
        });
      }
    }
  }, [user, erpCustomerAddressCode, selectedAddressId, isFiredEventUserLogin.current]);

  const ref = useOutsideClick(handleOutSideClick);

  return (
    <StyledFormControl ref={ref} data-test-id="addresses-button">
      {!isShowDropdown && userAddresses && selectedAddressId && (
        <LocationWrapperTitle>
          <div className="ic-location">
            <LocationIcon />
          </div>
          <span
            className="txt-location"
            onClick={() => {
              setIsShowDropdown(true);
            }}
          >
            {getAddressString(userAddresses.find(adr => adr.id === selectedAddressId)) ??
              selectedAddress}
          </span>
        </LocationWrapperTitle>
      )}

      {isShowDropdown && (
        <AutocompleteWrapper>
          {isShowDropdown && (
            <div className="ic-location-2">
              <LocationIcon />
            </div>
          )}
          <Autocomplete
            disablePortal
            options={userAddresses}
            disableClearable
            selectOnFocus
            freeSolo
            openOnFocus
            getOptionLabel={(address: IAddress) => {
              let checked = '';
              if (address.id === selectedAddressId) {
                checked = '✓';
              }
              return `${getAddressString(address)} ${checked}`;
            }}
            renderOption={(address: IAddress, props) => {
              const label = getAddressString(address);
              return (
                <li
                  {...props}
                  className={`${address.id === selectedAddressId ? 'item-selected' : ''}`}
                >
                  {label}
                  {address.id === selectedAddressId && <span className="ic-arrow">✓</span>}
                </li>
              );
            }}
            onChange={(event, value) => {
              if (value?.name || value?.erpPostCode) {
                return handleChange(value);
              }
              return () => {};
            }}
            renderInput={params => {
              return <TextField autoFocus {...params} />;
            }}
          />
        </AutocompleteWrapper>
      )}
    </StyledFormControl>
  );
};

export default LocationDropdown;
