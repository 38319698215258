import React from 'react';

export const BranchIcon = ({ ...rest }) => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M17.7611 4.86556L16.65 0.421111C16.5889 0.173333 16.3656 0 16.1111 0H1.66667C1.41222 0 1.18889 0.173333 1.12778 0.421111L0.0166667 4.86556C0.00555556 4.90889 0 4.95444 0 5C0 5.93111 0.441111 6.75222 1.11111 7.25667V15C1.11111 15.3067 1.36 15.5556 1.66667 15.5556H7.22222C7.52889 15.5556 7.77778 15.3067 7.77778 15V11.1111H10V15C10 15.3067 10.2489 15.5556 10.5556 15.5556H16.1111C16.4178 15.5556 16.6667 15.3067 16.6667 15V7.25667C17.3367 6.75222 17.7778 5.93111 17.7778 5C17.7778 4.95444 17.7722 4.90889 17.7611 4.86556ZM15.5556 14.4444H11.1111V10.5556C11.1111 10.2489 10.8622 10 10.5556 10H7.22222C6.91556 10 6.66667 10.2489 6.66667 10.5556V14.4444H2.22222V7.73333C2.35889 7.75667 2.49667 7.77778 2.63889 7.77778C3.48556 7.77778 4.23889 7.35667 4.72222 6.70333C5.20556 7.35667 5.95889 7.77778 6.80556 7.77778C7.65222 7.77778 8.40555 7.35667 8.88889 6.70333C9.37222 7.35667 10.1256 7.77778 10.9722 7.77778C11.8189 7.77778 12.5722 7.35667 13.0556 6.70333C13.5389 7.35667 14.2922 7.77778 15.1389 7.77778C15.2811 7.77778 15.4189 7.75667 15.5556 7.73333V14.4444ZM15.1389 6.66667C14.2967 6.66667 13.6111 5.91889 13.6111 5C13.6111 4.69333 13.3622 4.44444 13.0556 4.44444C12.7489 4.44444 12.5 4.69333 12.5 5C12.5 5.91889 11.8144 6.66667 10.9722 6.66667C10.13 6.66667 9.44444 5.91889 9.44444 5C9.44444 4.69333 9.19555 4.44444 8.88889 4.44444C8.58222 4.44444 8.33333 4.69333 8.33333 5C8.33333 5.91889 7.64778 6.66667 6.80556 6.66667C5.96333 6.66667 5.27778 5.91889 5.27778 5C5.27778 4.69333 5.02889 4.44444 4.72222 4.44444C4.41556 4.44444 4.16667 4.69333 4.16667 5C4.16667 5.91889 3.48111 6.66667 2.63889 6.66667C1.81667 6.66667 1.14333 5.95333 1.11222 5.06444L2.10111 1.11111H15.6778L16.6667 5.06444C16.6344 5.95333 15.9611 6.66667 15.1389 6.66667Z"
        fill="#939599"
      />
    </svg>
  );
};
