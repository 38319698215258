import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Slide,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'utils/common';
import { resubscribeOrder, unsubscribeOrder } from 'api/Order';
import { useMutation } from 'react-query';
import { LoadingIndicator } from 'pages/Product/styles';

const StyledGrid = styled(Grid)`
  padding: 0;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 100px;

  @media (max-width: 737px) {
    padding-top: 9px;
    padding-bottom: 38px;
  }
`;

const StyledButton = withStyles({
  root: {
    textAlign: 'center',
    width: '100%',
    height: '48px',
    backgroundColor: '#00853e',
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    letterSpacing: '0.01em',
    fontWeight: 'bold',
    color: '#FFFFFF',
    '&$disabled': {
      color: '#FFFFFF',
    },
    '&:hover': {
      backgroundColor: '#37875F',
    },
  },
  disabled: {
    backgroundColor: '#DADCE0',
  },
  label: {
    textTransform: 'none',
  },
})(Button);

const StyledCardActions = styled(CardActions)`
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  @media (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const StyledCard = styled(Card)`
  max-width: 500px;
  margin: 0 auto;

  @media (max-width: 737px) {
    margin: 0 10px;
  }
`;

const StyledCardContent = styled(CardContent)`
  padding: 0 50px 30px;
 font-size: 16px;
  @media (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  padding: 50px 50px 30px;
  div > span {
    font-family: Open Sans, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
  }
  @media (max-width: 440px) {
    padding-top: 20px;
    padding-left: 20px;
  }
`;
const UnsubscribeOrder: React.FC = () => {
  const { t, i18n } = useTranslation();

  const query = useQuery();
  const [isUnsubscribe, setIsUnsubscribe] = useState<boolean>(false);
  const [isResubscribe, setIsResubscribe] = useState<boolean>(false);

  useEffect(() => {
    const locale = window.location.pathname.split('/')[1];
    i18n.changeLanguage(locale);
  }, [i18n])

  useEffect(() => {
    if (query.get('account') && query.get('order') && !isUnsubscribe) {
      unsubscribeOrder(query.get('account'), query.get('order')).then(() => {
        setIsUnsubscribe(true);
      })
    }
  }, [query, isUnsubscribe]);

  const resubscribeOrderMutation = useMutation((payload: { account: string, order: string }) => resubscribeOrder(payload.account, payload.order), {
    onSuccess: () => {
      setIsResubscribe(true);
    },
  });

  if (resubscribeOrderMutation.status === 'loading') {
    return (
      <div style={{ margin: '0 auto' }}>
        <LoadingIndicator />
      </div>
    )
  }


  return (
    <Slide direction="down" in>
      <StyledGrid item xs={12} sm={8} md={8}>
        <StyledCard variant="outlined">
          <StyledCardHeader title={!isResubscribe ? t('unsubscribe_order.all_set').toString() : t('unsubscribe_order.resubcribe_success_title').toString()} />
          <StyledCardContent>
            <div dangerouslySetInnerHTML={{ __html: !isResubscribe ? t('unsubscribe_order.success_message') : t('unsubscribe_order.resubcribe_success_message') }} />
          </StyledCardContent>

          <StyledCardActions>
            <Grid container direction="row">
              {
                !isResubscribe && (
                  <StyledButton onClick={() => resubscribeOrderMutation.mutate({ account: query.get('account'), order: query.get('order') })}>
                    {t('unsubscribe_order.re_subscribe').toString()}
                  </StyledButton>
                )
              }
            </Grid>
          </StyledCardActions>
        </StyledCard>
      </StyledGrid>
    </Slide>
  );
};

export default UnsubscribeOrder;
