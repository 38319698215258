import React from 'react';
import {Checkbox, CheckboxProps, withStyles} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {IOrderItem} from 'models/interfaces/order';
import SingleOrderItem
  from 'pages/General/Profile/components/OrdersAndQuotes/ContentItem/components/SingleOrderItem/SingleOrderItem';
import {OrderItemCheckIcon} from 'assets/svg/Profile/OrdersAndQuotes';
import {ItemWithCheckboxWrapper, CheckboxWrapper} from './styles';

const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#00853e',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&$disabled': {
      color: 'red',
      cursor: 'arrow',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles({
  disabledIcon: {
    width: '18px',
    height: '18px',
    border: '1px solid #C4C4C4',
    background: 'rgba(218, 222, 228, 0.5)',
    borderRadius: '2px',
  },
  icon: {
    width: '18px',
    height: '18px',
    border: '1px solid #00853e',
    borderRadius: '3px',
  },
});

interface ISingleOrderItemProps {
  item: IOrderItem;
  checked: boolean;
  handleItemCheck: (item: IOrderItem) => void;
  disabled: boolean;
  setItemQuantity: React.Dispatch<React.SetStateAction<any>>;
  setCountErrorUpdateQuantity: React.Dispatch<React.SetStateAction<any>>;
  setCanReorder: React.Dispatch<React.SetStateAction<any>>;
  itemQuantity: Array<any>;
  selectReorderItems: Array<number>;
  countErrorUpdateQuantity: Array<number>;
}

const SingleOrderItemWithCheckbox: React.FC<ISingleOrderItemProps> = ({
  item,
  checked,
  handleItemCheck,
  disabled,
  setItemQuantity,
  itemQuantity,
  selectReorderItems,
  setCountErrorUpdateQuantity,
  countErrorUpdateQuantity,
  setCanReorder,
}: ISingleOrderItemProps) => {
  const classes = useStyles();
  return (
    <ItemWithCheckboxWrapper>
      <CheckboxWrapper>
        <GreenCheckbox
          checked={checked}
          onChange={() => handleItemCheck(item)}
          disabled={disabled}
          disableRipple
          icon={
            disabled ? <span className={classes.disabledIcon}/> : <span className={classes.icon}/>
          }
          checkedIcon={<OrderItemCheckIcon/>}
        />
      </CheckboxWrapper>
      <SingleOrderItem
        item={item}
        setItemQuantity={setItemQuantity}
        itemQuantity={itemQuantity}
        selectReorderItems={selectReorderItems}
        setCountErrorUpdateQuantity={setCountErrorUpdateQuantity}
        countErrorUpdateQuantity={countErrorUpdateQuantity}
        setCanReorder={setCanReorder}
        isReorder
      />
    </ItemWithCheckboxWrapper>
  );
};

export default React.memo(SingleOrderItemWithCheckbox);
