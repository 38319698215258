import {FilterType, ISelectedValue} from '../../models/interfaces/productVariant';

export const getMonthsBetweenDates = (from: Date, to: Date) => {
  let months = to.getMonth() - from.getMonth() + 12 * (to.getFullYear() - from.getFullYear());

  if (to.getDate() < from.getDate()) {
    months -= 1;
  }

  return months;
};

export const formatPhoneNumber = (number: string) =>
  `${number.substr(0, 3)}.${number.substr(3, 3)}.${number.substr(6, 4)}`;

export const addDotToPrice = (price: string) => {
  if (price !== null && price !== 'null' && price !== undefined) {
    return (Number(price)).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return '0.00';
};

export const getFillterAttrs = (facets, filterAttributes) => {
  const mapBucket = {}
  const filterAttrs: ISelectedValue[] = []
  facets.forEach(facet => {
    facet.buckets.forEach(bucket => {
      mapBucket[bucket.key] = {key: bucket.key, name: bucket.label, code: facet.property}
    })
  } )
  const filterBucketsKey = []
  for (const key in filterAttributes) {
    // eslint-disable-next-line no-prototype-builtins
    if (filterAttributes.hasOwnProperty(key)) {
      filterBucketsKey.push(...filterAttributes[key])
    }
  }
  filterBucketsKey.forEach(f => {
    if (JSON.stringify(mapBucket) !== JSON.stringify({}))  {
      filterAttrs.push({
        name: mapBucket[f]?.name,
        code: mapBucket[f]?.code,
        bucketKey: mapBucket[f]?.key,
        type: FilterType.CHECKBOX,
      })
    }
  })
  return filterAttrs
}

export const SORT_TYPE_KEY = ['order[search_rate]', 'order[price]', 'order[name]'];
export const MAP_SORT_TYPE = {
  'order[search_rate]_desc': 1,
  'order[price]_asc': 2,
  'order[price]_desc': 3,
  'order[name]_asc': 4,
  'order[name]_desc': 5,
}
