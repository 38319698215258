import { Box, Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WarningIcon } from 'assets/svg/Checkout/WarningIcon';
import CloseIcon from '@material-ui/icons/Close';
import {
  ContainerError,
  ModalCard,
  ModalCardContent,
  ModalTitle, ModalWindow,
} from '../OrdersModal/styles';

type CheckoutDisabledModalProps = {
  open: boolean;
  onClose: () => void;
}

const CheckoutDisabledModal = ({open, onClose}: CheckoutDisabledModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalWindow open={open} onClose={onClose}>
      <ModalCard variant="outlined" tabIndex={NaN} style={{ maxWidth: '500px' }}>
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
          <WarningIcon />
          <Button onClick={onClose}>
            <CloseIcon />
          </Button>
        </Box>
        <ModalTitle>{t('checkout.checkout_disabled_modal_header').toString()}</ModalTitle>
        <ModalCardContent />
        <ContainerError>
          <p>{t('checkout.checkout_disabled_modal_content').toString()}</p>
          <br/>
        </ContainerError>
      </ModalCard>
    </ModalWindow>
  )
}

export default CheckoutDisabledModal;
