// Classes
import { ProductClass } from 'models/classes/productClass';
import { IError } from 'models/interfaces/error';

export enum ProductActionTypes {
  REQUEST_PRODUCTS_ERROR = '@@product/REQUEST_PRODUCTS_ERROR',
  REQUEST_PRODUCT = '@@product/REQUEST_PRODUCT',
  REQUEST_PRODUCT_SUCCESS = '@@product/REQUEST_PRODUCT_SUCCESS',

  RESET_PRODUCT = '@@product/RESET_PRODUCT',
  RESET_PRODUCTS_ERROR = '@@product/RESET_PRODUCTS_ERROR',
  SET_OPEN_REQUEST_INQUIRE_NOW_POPUP = '@@product/SET_OPEN_REQUEST_INQUIRE_NOW_POPUP',
  SET_CLICKED_PRODUCT_REQUEST_NEARBY_HUBS_AND_BRANCH = '@@product/SET_CLICKED_PRODUCT_REQUEST_NEARBY_HUBS_AND_BRANCH',
  SET_OPEN_POPUP_NEARBY_BRANCH = '@@product/SET_OPEN_POPUP_NEARBY_BRANCH',
  SET_NEARBY_BRANCH_DATA = '@@product/SET_NEARBY_BRANCH_DATA',
  SET_CLICKED_PRODUCT_REQUEST_PRICING = '@@product/SET_CLICKED_PRODUCT_REQUEST_PRICING',
}

export type NearbyHubs = {
  branchName: string;
  onHand: number;
  branchId: number;
  leadTime: number;
}

export type NearbyBranches = {
  onHand: number;
}

export interface ProductState {
  readonly product: ProductClass;
  readonly error: IError | null;
  readonly isOpenPopupInquire: false;
  readonly clickedProductRequestNearbyHubsAndBranch: null;
  readonly clickedProductRequestPricing: null;
  readonly isOpenPopupNearbyBranch: false;
  readonly nearbyBranchData: {
    nearbyHubs: Array<NearbyHubs>
    nearbyBranches: NearbyBranches
  }
}

export interface FetchProductAction {
  type: ProductActionTypes.REQUEST_PRODUCT;
  payload: {
    code: string;
    addressCode: string;
    language?: 'en_US' | 'en_CA' | 'fr_CA';
  };
}
