import React, {ChangeEvent, useCallback, useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContent, IconButton, Tabs, Tab, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { NearbyBranchEmailType } from 'ducks/productVariant/types';
import { NearbyBranchContext } from 'context/NearbyBranchContext';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectUserData } from 'ducks/user/selectors';
import {selectAddressId, selectErpCustomerAddressCode} from 'ducks/application/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import {
  setClickedProductRequestPricing,
  setOpenPopupInquireNow,
  setOpenPopupNearbyBranch
} from 'ducks/product/actions';
import { NearbyBranches, NearbyHubs } from 'ducks/product/types';
import get from 'lodash/get';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import {ProductVariantClass} from 'models/classes/productVariantClass';
import {IPrice} from 'models/interfaces/productVariant';
import {useRequestPricing} from 'hooks/useRequestPricing';
import {usePermission} from 'hooks/usePermission';
import {selectShowPrices} from 'ducks/productVariant/selectors';
import { ProductStockModalWrapper } from './styles';
import {PriceCalculatorSection} from './PriceCalculatorSection/PriceCalculatorSection';
import {ButtonTitle, ProductInStock, RequestPricingStyledButton} from '../styles';
import {PricesSection} from '../PricesSection/PricesSection';
import { createUserActionLog } from '../../../../api/UserActionLog';

type IProductStockModalProps = {
  open: boolean;
  nearbyHubs: Array<NearbyHubs>;
  nearbyBranches: NearbyBranches;
  product: ProductVariantClass;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function tabProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const ProductStockModal: React.FC<IProductStockModalProps> = ({
  open,
  nearbyHubs,
  nearbyBranches,
  product,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(selectUserData);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const selectedAddressId = useSelector(selectAddressId);
  const branch = useSelector(selectBranch);
  const showPrices = useSelector(selectShowPrices);
  const {canSeePrice, canPlaceQuote, canPlaceOrder} = usePermission();
  const { setEmailType, setFromBranchId } = React.useContext(NearbyBranchContext);
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const [value, setValue] = useState(0);
  const [currentPrice, setCurrentPrice] = useState<Record<number, IPrice>>({});
  const [currenHubProductQuantity, setCurrentHubProductQuantity] = useState<Record<number, number>>({});
  const currentHub = useRef({ id: null });
  const isClickedNearByTab = useRef(false);

  useEffect(() => {
    if (nearbyHubs.length > 0 && product.prices.length) {
      const productPrice = product.prices.reduce((prev, next) => (prev.qty < next.qty ? prev : next)) || null;
      const prices = {};
      nearbyHubs.map((hub) => {
        if (hub.onHand > 0) {
          prices[hub.branchId] = productPrice;
        }
        return hub;
      });
      setCurrentPrice(prev => {
        return {
          ...prev,
          ...prices
        }
      });
    }
  }, [nearbyHubs, product.prices]);

  /**
   * Handle change tab
   */
  const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const openRequestNearbyBranchPopup = () => {
    dispatch(setOpenPopupInquireNow(true));
    dispatch(setOpenPopupNearbyBranch(false));
  };

  const handleGtmHub = (hub: NearbyHubs) => {
    if (currentHub.current.id !== hub.branchId) {
      currentHub.current.id = hub.branchId;
    }
    isClickedNearByTab.current = false;
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_hub_nearby_popup',
        hub_id: currentHub.current.id,
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
        source_branch: hub.branchId
      },
    });
  };

  const handleGtmNearby = () => {
    if (!isClickedNearByTab.current) {
      isClickedNearByTab.current = true;
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'click_hub_nearby_popup',
          hub_id: null,
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
    }
  };

  const handleOpenPopupRequestNearbyBranchBy = (hub: NearbyHubs) => {
    openRequestNearbyBranchPopup();
    if (hub.onHand === 0) {
      setEmailType(NearbyBranchEmailType.HUB_BRANCH_WITHOUT_INVENTORY_AVAILABLE);
    } else {
      setEmailType(NearbyBranchEmailType.HUB_BRANCH_WITH_INVENTORY_AVAILABLE);
    }
    setFromBranchId(hub.branchId);
  };

  const handleOpenPopupRequestByNearbyBranch = async () => {
    await createUserActionLog('Multi-branch Popup', 'Inquire Now');
    openRequestNearbyBranchPopup();
    if (nearbyBranches.onHand > 0) {
      setEmailType(NearbyBranchEmailType.NEARBY_BRANCH_AVAILABLE_WITH_INVENTORY);
    } else {
      setEmailType(NearbyBranchEmailType.NEARBY_BRANCH_AVAILABLE_WITHOUT_INVENTORY);
    }
  };

  const handleClose = () => {
    dispatch(setOpenPopupNearbyBranch(false));
  };

  const {handleRequestPricingClick} = useRequestPricing(
    product,
  );

  const handleRequestPricing = async () => {
    await createUserActionLog('Multi-branch Popup', 'Pricing Inquiry');
    handleRequestPricingClick();
    dispatch(setClickedProductRequestPricing(product));
    handleClose();
  }

  const isProductEmptyPrice = useCallback((nearbyHub: NearbyHubs) => {
    return !get(currentPrice, `${nearbyHub.branchId}.delivery`) && !get(currentPrice, `${nearbyHub.branchId}.pickup`);
  }, [currentPrice]);

  const renderInquiryBlock = (nearbyHub: NearbyHubs) => {
    return (
      <>
        <div className="txt-name">
          {nearbyHub.onHand === 0 ? (
            <div>
              {t('products_and_search.waiting_on_stock')}
            </div>
          ) : (
            <>
              <span>
                {t('product_detail.available_lead_time', {
                  onHand: nearbyHub.onHand,
                  leadTime: nearbyHub.leadTime,
                  branchName: branch.erpName,
                })}
              </span>
            </>
          )}
        </div>

        <div
          dangerouslySetInnerHTML={{
            __html: t('product_detail.inquire_for_available'),
          }}
          className="txt-note"
        />

        <div className="box-action">
          <button
            type="button"
            className="btn-inquire"
            onClick={() => handleOpenPopupRequestNearbyBranchBy(nearbyHub)}
          >
            {t('product_detail.inquire_now')}
          </button>
        </div>
      </>
    )
  }

  return (
    <ProductStockModalWrapper open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogContent>
        <div className="modal-header">
          <h3 className="modal-title">{product.name}</h3>
          <IconButton aria-label="close" className="btn-close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className="box-row">
          <div className="box-menu">
            <Tabs
              value={value}
              // @ts-ignore
              orientation={`${isMobile ? 'horizontal' : 'vertical'}`}
              onChange={(e, i) => handleChangeTab(e, i)}
            >
              {nearbyHubs.length > 0 &&
                nearbyHubs.map((nearbyHub, index) => {
                  return (
                    <Tab
                      onClick={() => handleGtmHub(nearbyHub)}
                      key={nearbyHub.onHand + nearbyHub.branchName}
                      label={
                        <div className="tab-title">
                          <div className="md">
                            {
                              nearbyHub.onHand > 0 ? (
                                <>
                                  {t('product_detail.ready_in', {
                                    leadTime: nearbyHub.leadTime,
                                  })}
                                  <span className="number">{nearbyHub.onHand}</span>
                                </>
                              ) : t('products_and_search.waiting_on_stock')
                            }
                          </div>
                          {nearbyHub.onHand > 0 ? (
                            <div className="sm">
                              {t('product_detail.in_stock')}: {nearbyHub.onHand}
                            </div>
                          ) : (
                            <div className="sm">{t('product_detail.inquire_now')}</div>
                          )}
                        </div>
                      }
                      {...tabProps(index)}
                    />
                  );
                })}
              <Tab
                onClick={handleGtmNearby}
                label={
                  <div className="tab-title">
                    <div className="md">{t('product_detail.other_stock_nearby')}</div>
                    {nearbyBranches.onHand > 0 && (
                      <div className="sm">
                        {t('product_detail.in_stock')}: {nearbyBranches.onHand}
                      </div>
                    )}
                  </div>
                }
                {...tabProps(nearbyHubs.length)}
              />
            </Tabs>
          </div>

          <div className="box-detail">
            {nearbyHubs.length > 0 &&
              nearbyHubs.map((nearbyHub, index) => {
                return (
                  <TabPanel key={nearbyHub.branchName} value={value} index={index}>
                    <div className="box-card">
                      <div className="box-popup-onhand">
                        <div className="ic-cube cube-2 cube-1">
                          <svg viewBox="0 0 40 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M19.9997 0.0834961L39.7913 11.5418V34.4585L19.9997 45.9168L0.208008 34.4585V11.5418L19.9997 0.0834961ZM6.44526 12.7454L19.9999 20.5927L33.5543 12.7455L19.9997 4.89808L6.44526 12.7454ZM4.37467 16.3612V32.0562L17.9165 39.8962V24.2012L4.37467 16.3612ZM22.0832 39.896L35.6247 32.0562V16.3613L22.0832 24.2012V39.896Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        {
                          nearbyHub.onHand > 0 && canSeePrice && (canPlaceQuote || canPlaceOrder) ? (
                            <p
                              className="box-popup-onhand__text"
                              dangerouslySetInnerHTML={{__html: t('product_detail.popup_available_lead_time', {
                                onHand: nearbyHub.onHand,
                                leadTime: nearbyHub.leadTime,
                                branchName: branch.erpName,
                              })}} />
                          ) : null
                        }
                      </div>
                      {
                        nearbyHub.onHand > 0 && canSeePrice && (canPlaceQuote || canPlaceOrder) ? (
                          <div className="product-action">
                            <ProductInStock
                              stock={product.inventory.isStocked || product.inventory.status.status === t('products_and_search.waiting_on_stock')}
                              waitingOnStock={product.inventory.status.waitingOnStock}
                              inStock={product.inventory.status.inStock}
                              t={t}
                            >
                              {nearbyHub.onHand > 0 ? null : (
                                <div className="sm">{t('products_and_search.waiting_on_stock')}</div>
                              )}
                            </ProductInStock>
                            {product.prices.length &&
                            (!isProductEmptyPrice(nearbyHub) || showPrices) ? (
                                <PricesSection productVariant={product} price={currentPrice[nearbyHub.branchId]} border={false}/>
                              ) : (
                                <RequestPricingStyledButton onClick={handleRequestPricing}>
                                  <ButtonTitle>{t('product_detail.request_pricing')}</ButtonTitle>
                                </RequestPricingStyledButton>
                              )}
                            {product.prices.length &&
                            (get(currentPrice, `${nearbyHub.branchId}.delivery`) || get(currentPrice, `${nearbyHub.branchId}.pickup`) || showPrices) ? (
                                <PriceCalculatorSection
                                  productVariant={product}
                                  currentPrice={currentPrice[nearbyHub.branchId]}
                                  setCurrentPrice={(price: IPrice) => setCurrentPrice({...currentPrice, [nearbyHub.branchId]: price})}
                                  addToTruckFromBranchId={nearbyHub.branchId}
                                  showAdditionalFreight
                                  currenHubProductQuantity={currenHubProductQuantity[nearbyHub.branchId]}
                                  setCurrentHubProductQuantity={(quantity: number) => setCurrentHubProductQuantity({...currenHubProductQuantity, [nearbyHub.branchId]: quantity})}
                                  hub={nearbyHub}
                                />
                              ) : null}
                          </div>
                        ) : renderInquiryBlock(nearbyHub)
                      }
                    </div>
                  </TabPanel>
                );
              })}
            <TabPanel value={value} index={nearbyHubs.length}>
              <div className="box-card">
                <div className="ic-cube cube-2">
                  <svg viewBox="0 0 40 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M19.9997 0.0834961L39.7913 11.5418V34.4585L19.9997 45.9168L0.208008 34.4585V11.5418L19.9997 0.0834961ZM6.44526 12.7454L19.9999 20.5927L33.5543 12.7455L19.9997 4.89808L6.44526 12.7454ZM4.37467 16.3612V32.0562L17.9165 39.8962V24.2012L4.37467 16.3612ZM22.0832 39.896L35.6247 32.0562V16.3613L22.0832 24.2012V39.896Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>

                <div className="txt-name">
                  {nearbyBranches.onHand === 0 ? (
                    <div>{t('product_detail.there_may_be_inventory_nearby')}</div>
                  ) : (
                    <span>
                      {nearbyBranches.onHand} {t('product_detail.in_stock_nearby')}
                    </span>
                  )}
                </div>

                <div
                  dangerouslySetInnerHTML={{ __html: t('product_detail.inquire_for_available') }}
                  className="txt-note"
                />

                <div className="box-action">
                  <button
                    type="button"
                    className="btn-inquire"
                    onClick={handleOpenPopupRequestByNearbyBranch}
                  >
                    {t('product_detail.inquire_now')}
                  </button>
                </div>
              </div>
            </TabPanel>
          </div>
        </div>
      </DialogContent>
    </ProductStockModalWrapper>
  );
};

export default ProductStockModal;
