import React from 'react';
import CustomizedSnackbar from 'components/common/Snackbar/Snackbar';
import { useTranslation } from 'react-i18next';

interface ISnackBar {
  open: boolean;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
}

function OptionSectionSnackBar({ open, setOpenSnackbar }: ISnackBar) {
  const { t } = useTranslation();
  const handleCloseSnackBar = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <CustomizedSnackbar
      open={open}
      status="Success"
      handleClose={handleCloseSnackBar}
      successMessage={t('product_detail.selection_reflects')}
      errorMessage={t('product_detail.selection_reflects')}
    />
  );
}

export { OptionSectionSnackBar };
