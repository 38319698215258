// Types
import {NearbyBranches, NearbyHubs, ProductActionTypes} from 'ducks/product/types';

// Classes
import {ProductClass} from 'models/classes/productClass';
import {ProductVariantClass} from '../../models/classes/productVariantClass';

export const fetchProduct = (code: string, addressCode: string) => ({
  type: ProductActionTypes.REQUEST_PRODUCT,
  payload: {
    code,
    addressCode,
  },
});

export const storeProduct = (product: ProductClass) => ({
  type: ProductActionTypes.REQUEST_PRODUCT_SUCCESS,
  payload: {
    product,
  },
});

export const resetProduct = () => ({
  type: ProductActionTypes.RESET_PRODUCT,
});

export const resetProductsError = () => ({
  type: ProductActionTypes.RESET_PRODUCTS_ERROR,
});

export const setOpenPopupInquireNow = (open: boolean) => ({
  type: ProductActionTypes.SET_OPEN_REQUEST_INQUIRE_NOW_POPUP,
  payload: {
    data: open,
  }
})

export const setClickedProductRequestNearbyHubsAndBranch = (data: any) => ({
  type: ProductActionTypes.SET_CLICKED_PRODUCT_REQUEST_NEARBY_HUBS_AND_BRANCH,
  payload: {
    data
  }
})

export const setOpenPopupNearbyBranch = (open: boolean) => ({
  type: ProductActionTypes.SET_OPEN_POPUP_NEARBY_BRANCH,
  payload: {
    data: open
  }
})

export const setNearbyBranchData = (data: {
  nearbyHubs: Array<NearbyHubs>,
  nearbyBranches: NearbyBranches
}) => ({
  type: ProductActionTypes.SET_NEARBY_BRANCH_DATA,
  payload: {
    data
  }
})

export const setClickedProductRequestPricing = (product: ProductVariantClass) => ({
  type: ProductActionTypes.SET_CLICKED_PRODUCT_REQUEST_PRICING,
  payload: {
    data: product
  }
})