import styled from 'styled-components/macro';
import { FormControl, MenuItem, Select } from '@material-ui/core';

const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  width: fit-content;
  padding-left: 4px;
  margin-right: 40px;
  @media (max-width: 1200px) {
    margin-right: 20px;
  }
  @media (max-width: 1023px) {
    margin-right: 10px;
    height: 24px;
    padding: 2px 6px;
  }
`;

const StyledSelect = styled(Select)`
  & .MuiSelect-select {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 27px;
    padding: 0 13px 0 0;
    div {
      display: none;
    }
    @media (max-width: 1023px) {
      width: 23px;
    }
  }
  & .MuiSvgIcon-root {
    width: 18px;
  }
  & .MuiMenu-list {
    border: 1px solid red;
  }
  &:focus {
    background-color: ${({ theme: { colors } }) => colors.white};
  }
  && ul {
    padding: 0;
  }
`;

const StyledFormControl = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 16px 8px 16px 10px;
`;

const StyledParagraph = styled.p`
  margin: 0 4px 0 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.textColor};
`;

export { Wrapper, StyledFormControl, StyledMenuItem, StyledParagraph, StyledSelect };
