import React from 'react';
import { Dialog, DialogContentText } from '@material-ui/core';
import { Track } from 'assets/svg/Filter/Track';
import { useTranslation } from 'react-i18next';
import {
  StylesDialogContent,
  StyledTrack,
  LoadingText,
} from './styles';

interface IConfirmOrderLoadingModal {
  open: boolean;
  handleClose: () => void;
}
export const ConfirmOrderLoadingModal = ({ open, handleClose }: IConfirmOrderLoadingModal) => {
  const { t } = useTranslation();
  return (
    open && (
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StylesDialogContent>
          <DialogContentText id="alert-dialog-description">
            <StyledTrack>
              <Track />
            </StyledTrack>
            <LoadingText>{t('products_not_found_popup.loading')}</LoadingText>
            <p>{t('checkout.do_not_reload_page')}</p>
          </DialogContentText>
        </StylesDialogContent>
      </Dialog>
    )
  );
};
