import React, {memo, useCallback, useMemo} from 'react';
import TagManager from 'react-gtm-module';
import { Tooltip, useMediaQuery } from '@material-ui/core';

import {FindMatchProduct, IOption, IOptionValue} from 'models/interfaces/product';
import {getUserType} from 'utils/profile/profileUtilsHelpers';
import {useSelector} from 'react-redux';
import {selectUserData} from 'ducks/user/selectors';
import {selectBranch} from 'ducks/branch/selectors';
import {
  priceTypeSelector,
  selectAddressId,
  selectErpCustomerAddressCode,
  selectIsHidePrice,
} from 'ducks/application/selectors';
import {clearGtmEcommerce} from 'utils/clearGtmEcommerce';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import reduce from 'lodash/reduce';
import {ProductClass} from 'models/classes/productClass';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';
import {usePermission} from 'hooks/usePermission';
import {ProductVariantClass} from 'models/classes/productVariantClass';
import midDefaultProduct from 'assets/images/default-mid-product.png';
import {IPrice} from 'models/interfaces/productVariant';
import { numberFormat as f } from 'utils/products/product';
import {
  AttributeSpecifyWrapperScroll,
  AttributeSpecifyWrapperScrollRow,
  AttributeSpecifyWrapper,
  AttributeSpecifyItem,
  AttributeSpecifyItemInner,
} from '../AttributesDropdown/styles';
import {ProductStock, SpecialOrder, StyledAvailableAndLeadTime} from '../../../styles';
import { DisabledItemTooltip } from '../../styles';

interface IAttributesDropdown {
  options: IOptionValue[];
  option: IOption;
  handleAttributeClick(name: string, attrValue: string|number): void;
  selected: string;
  findMatchProducts: Array<FindMatchProduct> | [];
  product: ProductClass;
  currentProductVariant: ProductVariantClass;
  price: IPrice;
}

const SpecifyOption = ({
  options,
  option,
  handleAttributeClick,
  selected,
  findMatchProducts,
  product,
  currentProductVariant,
  price
}: IAttributesDropdown) => {
  const {t} = useTranslation();
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const isHidePrice = useSelector(selectIsHidePrice);
  const deliveryType = useSelector(priceTypeSelector);
  const { canSeeInventory, canSeePrice } = usePermission();
  const [defaultPrice = null] = currentProductVariant.prices;
  const currentPrice = price || defaultPrice;
  const isMobile = useMediaQuery('(max-width: 1023px)');

  const handleChange = (optionValue: IOptionValue) => {
    handleAttributeClick(optionValue.optionCode, option.code);
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'change_option',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
  }

  const getOptionClass = useCallback((optionValue: IOptionValue): string => {
    if (optionValue.optionCode === selected) {
      return 'active';
    }

    if (findMatchProducts && findMatchProducts.length) {
      const pickItemCode = map(findMatchProducts, 'itemCode');

      const pickItemCodeObject = flatten(pickItemCode);
      const resultObject = reduce(
        pickItemCodeObject,
        (acc, value) => {
          const innerKey = Object.keys(value)[0];
          const innerValue = value[innerKey];

          // Check if the innerKey exists in the resultObject
          // eslint-disable-next-line no-prototype-builtins
          if (acc.hasOwnProperty(innerKey)) {
            // If it exists, push the value to the existing array
            acc[innerKey].push(innerValue);
          } else {
            // If it doesn't exist, create a new array with the value
            acc[innerKey] = [innerValue];
          }

          return acc;
        },
        {} as { [key: string]: string[] }
      );
      const match = resultObject[optionValue.code]?.includes(optionValue.optionCode);
      if (!match) {
        return 'disabled';
      }
    }
    if (findMatchProducts.length === 0) {
      return 'disabled';
    }

    return '';
  }, [findMatchProducts, selected]);

  const getMatchProductForOption = useCallback((optionValue: IOptionValue) => {
    if (optionValue.optionCode === selected) {
      return currentProductVariant;
    }
    const matchProductForOption =  findMatchProducts.find(match => {
      return match.itemCode.some(item => {
        return item[option.code] === optionValue.optionCode;
      });
    });
    if (!matchProductForOption) return null;
    return product.variants.find(variant => variant.erpProductId === matchProductForOption.erpProductId)
  }, [currentProductVariant, findMatchProducts, option.code, product.variants, selected])

  const getProductPricing = (optionValue: IOptionValue, oumString: string, isOum2 = false) => {
    if (optionValue.optionCode === selected) {
      if (isOum2) {
        return currentProductVariant.prices.length > 0 ? `$${f(currentPrice[deliveryType] * currentProductVariant.units.uom2Conversion)}/${oumString}` : t('product_detail.request_pricing');
      }
      return currentProductVariant.prices.length ? `$${f(currentProductVariant.prices.sort((a, b) => a.qty - b.qty)[0][deliveryType])}/${oumString}` : t('product_detail.request_pricing');
    }
    const findMatchProduct = getMatchProductForOption(optionValue);

    if (findMatchProduct) {
      const pricing = findMatchProduct.prices.length ? findMatchProduct.prices.sort((a, b) => a.qty - b.qty)[0] : null;
      if (isOum2 && findMatchProduct.uom1ToUom2Conversion) {
        return pricing ? `$${f(pricing[deliveryType] * findMatchProduct.units.uom2Conversion)}/${oumString}` : t('product_detail.request_pricing');
      }
      return pricing ? `$${f(pricing[deliveryType])}/${oumString}` : t('product_detail.request_pricing');
    }
    return null;
  };

  const renderProductInventoryStatus = (optionValue: IOptionValue) => {
    if(optionValue.optionCode === selected) {
      const status = get(currentProductVariant, 'inventory.status', {});
      if (status.waitingOnStock) {
        return (
          <ProductStock className="waiting-on-stock">
            {canSeeInventory ? status.status : status.origin}
          </ProductStock>
        );
      }
      if (status.inStock || !status.specialOrderString) {
        return (
          <ProductStock>
            {canSeeInventory ? status.status : status.origin}
          </ProductStock>
        );
      }
      return (
        <SpecialOrder className="special-order">
          {canSeeInventory ? status.status : status.origin}
        </SpecialOrder>
      );
    }
    const findMatchProduct = getMatchProductForOption(optionValue);
    const status = get(findMatchProduct, 'inventory.status', {});
    if (status.waitingOnStock) {
      return (
        <ProductStock className="waiting-on-stock">
          {canSeeInventory ? status.status : status.origin}
        </ProductStock>
      );
    }
    if (status.inStock || !status.specialOrderString) {
      return (
        <ProductStock>
          {canSeeInventory ? status.status : status.origin}
        </ProductStock>
      );
    }
    return (
      <SpecialOrder className="special-order">
        {canSeeInventory ? status.status : status.origin}
      </SpecialOrder>
    );
  }

  /**
   * Render swatch item inner
   */
  const renderSwatchItemInner = (optionValue: any) => {
    const findMatchProduct = getMatchProductForOption(optionValue);

    return (
      <AttributeSpecifyItemInner
        onClick={() => handleChange(optionValue)}
        className={getOptionClass(optionValue)}
      >
        <div title={optionValue.value} className="txt-title">{optionValue.value}</div>

        {getOptionClass(optionValue) === 'disabled' ? (
          <div className="img-default-product">
            <img src={midDefaultProduct} alt={optionValue} style={{maxWidth: '100%'}}/>
          </div>
        ) : (
          <>
            {
              !isHidePrice && canSeePrice && (
                <div className="txt-price">
                  <div className="lg">
                    {getProductPricing(optionValue, get(findMatchProduct, 'units.perCode'))}
                  </div>

                  {findMatchProduct && findMatchProduct.prices.length > 0 && get(findMatchProduct, 'units.uom2Code') && (
                    <div
                      className="md">{getProductPricing(optionValue, get(findMatchProduct, 'units.uom2Code'), true)}</div>
                  )}
                </div>
              )
            }
            <div style={{ width: '100%', marginTop: 15 }}>
              {
                findMatchProduct.newInventory?.onHand > 0 && !findMatchProduct.inventory.isStocked ? (
                  <StyledAvailableAndLeadTime>
                    {t('products_and_search.available_and_lead_time', {
                      onHand: findMatchProduct.newInventory.onHand,
                      leadTime: findMatchProduct.newInventory.leadTime,
                    }).toString()}
                  </StyledAvailableAndLeadTime>
                ) : renderProductInventoryStatus(optionValue)
              }
            </div>
          </>
        )}
      </AttributeSpecifyItemInner>
    )
  }

  /**
   * Render disabled swatch tooltip
   */
  const renderDisabledItemTooltip = useMemo(() => <DisabledItemTooltip>
    <div className="md-1">{t('product_detail.conflict_with').toString()}</div>
    <div className="md-2">{t('product_detail.click_to_see_options').toString()}</div>
  </DisabledItemTooltip>, [t])

  /**
   * Render attribute specify item
   */
  const renderAttributeSpecifyItem = (optionValue: IOptionValue) => {
    return (
      <AttributeSpecifyItem key={optionValue.optionCode}>
        {getOptionClass(optionValue) === 'disabled' ? (
          <Tooltip title={renderDisabledItemTooltip} placement="top" arrow>
            {renderSwatchItemInner(optionValue)}
          </Tooltip>
        ) : (
          renderSwatchItemInner(optionValue)
        )}
      </AttributeSpecifyItem>
    )
  }

  if (isMobile) {
    return (
      <AttributeSpecifyWrapperScroll>
        <AttributeSpecifyWrapperScrollRow>
          {options.map(renderAttributeSpecifyItem)}
        </AttributeSpecifyWrapperScrollRow>
      </AttributeSpecifyWrapperScroll>
    )
  }

  return options.length <= 10 ? (
    <AttributeSpecifyWrapper className="<=10">
      {options.map(renderAttributeSpecifyItem)}
    </AttributeSpecifyWrapper>
  ) : (
    <AttributeSpecifyWrapperScroll className=">10">
      <AttributeSpecifyWrapperScrollRow>
        {options.filter((_, i) => i % 2 === 0).map((optionV) => renderAttributeSpecifyItem(optionV))}
      </AttributeSpecifyWrapperScrollRow>
      <AttributeSpecifyWrapperScrollRow>
        {options.filter((_, i) => i % 2 !== 0).map((optionV) => renderAttributeSpecifyItem(optionV))}
      </AttributeSpecifyWrapperScrollRow>
    </AttributeSpecifyWrapperScroll>
  );
};

export default memo(SpecifyOption);