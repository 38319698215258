import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { productToProductVariantClass } from 'utils/products/productVariant';
import axios from 'utils/axios';
import {
  IFetchSearchProductsPayload,
  storeSearchProducts,
  storeSearchProductsFirstLoad,
} from 'ducks/search/actions';
import { AppStorage } from '../components/App/services/storage';

function searchFullView(payload: IFetchSearchProductsPayload) {
  const filters = payload.filterAttributes;
  AppStorage.setFiltered(JSON.stringify(payload))
  return axios.get('/api/auth/product/external/search/full-view', {
    headers: {
      accept: 'application/json',
      'X-Address-Id': payload.addressId,
      'Accept-Language': payload.language,
    },
    params: {
      ...payload,
      ...filters,
    },
  });
}

function useSearchFullView(payload: IFetchSearchProductsPayload) {
  const dispatch = useDispatch();
  return useQuery(['searchFullView', payload], async () => {
    const {firstLoad} = payload;
    AppStorage.setFiltered(JSON.stringify(payload))
    if (firstLoad) {
      delete payload.firstLoad;
    }
    if (payload.filterAttributes) {
      delete payload.filterAttributes;
    }
    const {data} = await searchFullView(payload);
    const products = data.data.data.map(productVariant =>
      productToProductVariantClass(productVariant)
    );
    const isLastPage = data.data.currentPage === data.data.lastPage;
    const {total} = data.data;
    if (firstLoad) {
      dispatch(storeSearchProductsFirstLoad(products, total, isLastPage));
    } else {
      dispatch(storeSearchProducts(products, total, isLastPage));
    }
    return data.data.data;
  }, {
    enabled: !!payload.addressId,
  });
}

export { useSearchFullView };