import { AxiosResponse } from 'axios';
import axios from '../utils/axios';

export type GlobalBanner = {
  data: {
    text: string;
  }
};

export const fetchGlobalBanner = async (branchId: number): Promise<AxiosResponse<GlobalBanner>> =>
  // eslint-disable-next-line no-return-await
  await axios.get('/api/global-banner', {
    params: {
      branchId,
    },
  });
