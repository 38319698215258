import styled, { keyframes } from 'styled-components/macro';
import { DialogContent } from '@material-ui/core';

const StylesDialogContent = styled(DialogContent)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  text-align: center;
  > * {
    width: 100%;
  }
`;

const truckAnimation = keyframes`
  from {
    left: 0;
    transform: translate(-100%, 0);
  }
  to {
    left: 100%;
    transform: translate(0, 0);
  }
`;

const StyledTrack = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 50px;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-100%, 0);
    animation: ${truckAnimation} 4s linear infinite;
}
`;

const LoadingText = styled.div`
  font-family: Open Sans, serif;
  margin-top: 12px;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: ${({ theme: { colors } }) => colors.textColor};
`;

export {
  StylesDialogContent,
  StyledTrack,
  LoadingText,
};
