import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { Grid } from '@material-ui/core';

const ProductCardGrid = styled(({ isFeatured, children, ...rest }) => (
  <div {...rest}> {children} </div>
))`
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
  margin-bottom: 16px;
  background: ${({ theme: { colors } }) => colors.white};
  box-shadow: 0px 3px 15px ${({ theme: { colors } }) => colors.filterBorderGrey};
  border-radius: 6px;

  @media (min-width: 601px) and (max-width: 1023px) {
    width: ${props => (props.isFeatured ? '100%' : '50%')};
    height: 100%;
    justify-content: center;
    margin-left: 0;
    margin-bottom: 21px;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-left: 0;
    margin-bottom: 21px;
  }
`;

const ProductCard = styled.div`
  width: 208px;
  height: 450px;
  position: relative;
  padding: 0px 12px 16px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1023px) {
    width: 100%;
    height: 100%;
    padding: 10px 12px 10px 12px;
  }

  .special-order {
    display: block;
    -webkit-line-clamp: initial;
    -webkit-box-orient: initial;
    overflow: visible;
    margin-bottom: 5px;
  }
`;

const DefaultAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 53px;
  border: 1px solid ${({ theme: { colors } }) => colors.productBorderGrey};
  box-sizing: border-box;
  border-radius: 4px;

  img {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    object-fit: cover;
  }
`;

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  width: 65px;
  border: 1px solid ${({ theme: { colors } }) => colors.productBorderGrey};
  box-sizing: border-box;
  border-radius: 4px;

  img {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    object-fit: cover;
  }

  @media (max-width: 1023px) {
    width: 138px;
    height: 125px;
  }
`;

const ProductName = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 14px;
  margin-top: 3px;
  margin-bottom: 2px;
  overflow-wrap: break-word;
  @media (max-width: 1023px) {
    -webkit-line-clamp: initial;
    -webkit-box-orient: initial;
  }
  &:hover {
    color: ${({ theme: { colors } }) => colors.mainGreen};
  }
`;

const Price = styled.p`
  margin: 0;
  display: inline;
  font-family: Open Sans;
  font-weight: 700;
  font-size: 20px;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  line-height: 130%;
  letter-spacing: 0.01em;
`;

const Each = styled.p`
  margin: 0;
  display: inline;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.letterGrey};
`;

const RequestPricingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const RequestPricingButton = styled.button`
  display: flex;
  background: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  border-radius: 4px;
  line-height: 12px;
  padding: 9px 12px;
  outline: none;

  &:hover {
    cursor: pointer;
  }
`;

const RequestPricing = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  text-align: center;
  margin: 0;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.black};
`;

const StyledCloseIcon = styled(CloseIcon as any)`
  position: absolute;
  top: 6px;
  color: ${({ theme: { colors } }) => colors.darkGrey};
  right: 6px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const AvatarWrapperGrid = styled(Grid as any)`
  padding-top: 12px;
  @media (max-width: 1023px) {
    padding-top: 0;
  }
`;

const Total = styled.p`
  margin-top: 10px;
  margin-bottom: 8px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.01em;
`;

const AddButton = styled.button`
  cursor: pointer;
  border: none;
  padding: 7px 12px;
  background: ${({ theme: { colors } }) => colors.mainGreen};
  border-radius: 4px;
  display: flex;
  width: 100%;
  justify-content: center;
  outline: none;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.mainGreenHover};
  }

  &:active {
    background-color: ${({ theme: { colors } }) => colors.mainGreefPassed};
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const InquireNowButtonTitle = styled.p`
  margin: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #007a33;
`;

const InquireNowButton = styled.button`
  cursor: pointer;
  padding: 7px 12px;
  background-color: #ffffff;
  border: 1px solid #007a33;
  border-radius: 4px;
  display: flex;
  width: 100%;
  justify-content: center;
  outline: none;
  margin-top: 6px;
  @media (max-width: 1023px) {
    width: 100%;
  }
  &.fr {
    padding: 2px 12px;
    margin-top: 5px;
  }
  &:hover {
    background-color: ${({ theme: { colors } }) => colors.mainGreenHover};
    color: #ffffff;
  }
  &:hover ${InquireNowButtonTitle} {
    color: #ffffff;
  }
  &:active {
    background-color: ${({ theme: { colors } }) => colors.mainGreefPassed};
    color: #ffffff;
  }
`;

const AddButtonTitle = styled.p`
  margin: 0;
  margin-left: 8px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.white};
  white-space: nowrap;
`;

const StyledInputsGrid = styled(Grid)`
  padding-top: 8px;
  [class*='styles__IncrementButtonLabel-'] {
    white-space: nowrap;
  }
`;

const StyledProductNameGrid = styled(Grid)`
`;

export {
  StyledProductNameGrid,
  StyledInputsGrid,
  AddButtonTitle,
  AddButton,
  Total,
  AvatarWrapperGrid,
  StyledCloseIcon,
  StyledLink,
  RequestPricing,
  RequestPricingButton,
  RequestPricingWrapper,
  Each,
  Price,
  ProductName,
  AvatarWrapper,
  DefaultAvatarWrapper,
  ProductCard,
  ProductCardGrid,
  InquireNowButton,
  InquireNowButtonTitle,
};
