import { CartActionTypes, CartItemsNoPrice } from 'ducks/cart/types';
import { AddProductToCartDto, ChangeProductQtyDto } from 'models/interfaces/cart';
import {ProductVariantClass} from '../../models/classes/productVariantClass';

export const requestAddProductToCart = (addProductToCart: AddProductToCartDto) => ({
  type: CartActionTypes.ADD_PRODUCT,
  payload: addProductToCart,
});

export const requestDeleteProductFromCart = cartItemId => ({
  type: CartActionTypes.DELETE_PRODUCT,
  payload: {
    cartItemId,
  },
});

export const requestAddPromocode = (id: string, promoCode: string) => ({
  type: CartActionTypes.ADD_PROMOCODE,
  payload: {
    id,
    promoCode,
  },
});

export const requestDeletePromocode = (id: string) => ({
  type: CartActionTypes.DELETE_PROMOCODE,
  payload: {
    id,
  },
});

export const storeCart = data => ({
  type: CartActionTypes.STORE_CART,
  payload: {
    data,
  },
});

export const storeCartFirstLoad = (data, inputValues) => ({
  type: CartActionTypes.STORE_FIRST_LOAD_SUMMARY,
  payload: {
    data,
    inputValues,
  },
});

interface IUpdateInputValuesPayload {
	cartItemId: string;
	newTopValue?: number;
	newBottomValue?: number;
	newItemPrice?: number;
}

export const updateItemInputValues = (payload: IUpdateInputValuesPayload) => ({
  type: CartActionTypes.UPDATE_ITEM_INPUT_VALUES,
  payload: {
    ...payload,
  },
});

export const storeCartAfterUpdate = data => ({
  type: CartActionTypes.STORE_CART_AFTER_UPDATE,
  payload: {
    data,
  },
});

export const requestCartSummary = (addressId: number) => ({
  type: CartActionTypes.GET_CART_SUMMARY,
  payload: {
    addressId,
  },
});

export const setQtyUpdateStatus = () => ({
  type: CartActionTypes.CHANGE_PRODUCT_QTY_SUCCESS,
});

export const requestChangeProductQty = (changeProductQty: ChangeProductQtyDto) => ({
  type: CartActionTypes.CHANGE_PRODUCT_QTY,
  payload: {
    ...changeProductQty,
  },
});

export const setQtyUpdateFalse = () => ({
  type: CartActionTypes.SET_QTY_UPDATE_FALSE,
});

export const resetCartErrors = () => ({
  type: CartActionTypes.RESET_CART_ERRORS,
});

export const startLoader = () => ({
  type: CartActionTypes.START_LOADER,
});

export const checkCartItemsNoPriceAction = (cartId: number, deliveryType?: string) => ({
  type: CartActionTypes.CHECK_CART_ITEMS_NO_PRICE,
  payload: {
    cartId,
    deliveryType,
  },
});

export const storeCartItemsNoPrice = (data: CartItemsNoPrice[]) => ({
  type: CartActionTypes.STORE_CART_ITEMS_NO_PRICE,
  payload: {
    data
  }
});

export const setAddToTruckStatus = (status: boolean) => ({
  type: CartActionTypes.SET_ADD_TO_TRUCK_STATUS,
  payload: {
    status,
  }
});

export const setAddToTruckItem = (item: ProductVariantClass, quantity: number) => ({
  type: CartActionTypes.SET_ADD_TO_TRUCK_ITEM,
  payload: {
    item,
    quantity
  }
});

export const setStoreFirstLoadSummaryStatus = (status: boolean) => ({
  type: CartActionTypes.SET_STORE_FIRST_LOAD_SUMMARY_STATUS,
  payload: {
    status,
  }
})

export const removeCart = () => ({
  type: CartActionTypes.REMOVE_CART,
})