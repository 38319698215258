import axios from 'utils/axios';
import {IOrderItem} from '../models/interfaces/order';

interface IPrice {
  total: number;
  taxTotal: number;
  subTotal: number;
}

export interface IAdjustments {
  amount: number;
  label: string;
  type: string;
}

export interface IReOrderResponse {
  price: IPrice,
  adjustments: IAdjustments[],
  items: IOrderItem[]
}

interface IPayload {
  orderId: number;
  addressId: number;
  deliveryType: string;
}

export const validateItemsReOrder = async (payload: IPayload): Promise<IReOrderResponse> => {
  const {data} = await axios.get(`/api/auth/order/check-valid-items-reorder/${payload.orderId}?delivery_type=${payload.deliveryType}`, {
    headers: {
      'x-address-id': payload.addressId.toString(),
    },
  });
  return data.data;
}

interface IRequestOrderOrQuoteDetail extends IPayload {
  orderType: string;
}

export const getOrderDetail = async (payload: IRequestOrderOrQuoteDetail) => {
  const {data} = await axios.get(`/api/auth/order/order-detail/${payload.orderId}?delivery_type=${payload.deliveryType}&order_type=${payload.orderType}`, {
    headers: {
      'x-address-id': payload.addressId.toString(),
    },
  });
  return data.data;
}