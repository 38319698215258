import React, { useCallback, useState } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import { useSelector } from 'react-redux';
import { InputAdornment, TextField, Grid, Divider, ClickAwayListener } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import {
  priceTypeSelector,
  selectAddressId,
  selectErpCustomerAddressCode, selectIsHidePrice,
  selectLanguage,
} from 'ducks/application/selectors';
import { useProductVariantSearch } from 'api/ProductVariant';
import { useDebounce } from 'hooks/useDebounce';
import { ProductVariantClass } from 'models/classes/productVariantClass';
import defaultProduct from 'assets/images/default-product.png';
import { Link, useHistory } from 'react-router-dom';
import Routes from 'routes';
import ProductListLoading from 'pages/Product/ProductListLoading/ProductListLoading';
import TagManager from 'react-gtm-module';
import { usePermission } from 'hooks/usePermission';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import findIndex from 'lodash/findIndex';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { getDefaultProductName } from 'utils/products/productUtilsHelpers';
import { getDefaultCategoryName } from 'utils/categories/categories';
import { selectCategories } from 'ducks/category/selectors';
import { StyledAvailableAndLeadTime } from 'pages/Product/ProductTableView/ProductTableCard/styles';
import { useCommonProductListState } from 'hooks/useCommonProductListState';
import { AppStorage } from '../../App/services/storage';
import {
  AvailableGrid,
  Measure,
  NotAvailableGrid,
  PriceGrid,
  SearchWrapper,
  StyledDiv,
  StyledIconButton,
  StyledLi,
  StyledLink,
  StyledUl,
  TextWrapperGrid,
  TitleGrid,
  WrapperDiv,
  ViewAllButton,
} from './styles';

const SearchBox = withStyles({
  root: {
    width: '100%',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    boxSizing: 'border-box',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.01em',
    color: '#939599',
    '& label.Mui-focused': {
      color: '#00853e',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#00853e',
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#C4C4C4',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00853e',
        },
      },
    },
  },
})(TextField);

const SearchComponent = () => {
  const { t } = useTranslation();
  const addressId = useSelector(selectAddressId);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const { canSeePrice, canSeeInventory } = usePermission();
  const categories = useSelector(selectCategories);
  const history = useHistory();
  const selectedLanguage = useSelector(selectLanguage);
  const isHidePrice = useSelector(selectIsHidePrice);
  const priceType = useSelector(priceTypeSelector);
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchVal, setSearchVal] = useState<string>('');

  const handleClear = () => {
    setSearchVal('');
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const debouncedSearchTerm = useDebounce(searchVal, 1000);

  const { data } = useProductVariantSearch({
    addressId,
    language: selectedLanguage,
    searchVal: debouncedSearchTerm,
    openSearchDropdown: setOpen,
    onChangeLoading: setLoading,
  });

  const {setPagination} = useCommonProductListState();

  const handleEventGoToProduct = (productVar: ProductVariantClass) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'use_search_bar_product',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
    clearGtmEcommerce();
    const { itemCategoryName, itemCategory2Name } = getDefaultCategoryName(
      productVar.category.id,
      categories
    );
    TagManager.dataLayer({
      dataLayer: {
        event: 'select_item',
        ecommerce: {
          item_list_id: 'search_results_preview',
          item_list_name: 'Search Results Preview',
          items: [
            {
              item_id: productVar.erpSku,
              item_name: getDefaultProductName(productVar),
              price: productVar.prices.length ? productVar.prices[0][priceType] : 0,
              item_category: itemCategoryName,
              item_category2: itemCategory2Name,
              item_list_id: 'search_results_preview',
              item_list_name: 'Search Results Preview',
              index: findIndex(data.data, product => product.id === productVar.id) + 1,
              quantity: 1,
            },
          ],
        },
      },
    });
    setPagination({
      page: 1,
      itemsPerPage: 12,
    })
    setTimeout(() => {
      setOpen(false);
    }, 100);
  };

  const handleFocus = () => {
    setOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchVal(event.target.value as string);
  };

  const renderPrice = (variant: ProductVariantClass) => {
    const isPrice = Boolean(variant.prices.length);

    if (!isHidePrice && canSeePrice) {
      return (
        <PriceGrid isPrice={isPrice && priceType !== 'hide'} item>
          {isPrice && variant.prices[0][priceType] > 0 && priceType !== 'hide' ? (
            <>
              ${variant.prices[0][priceType]}
              <Measure> /{variant.units.perName}</Measure>
            </>
          ) : (
            <>{t('products_and_search.call_for_pricing')}</>
          )}
        </PriceGrid>
      );
    }
    if (!isHidePrice && !canSeePrice) {
      return (
        <PriceGrid isPrice={false} item>
          {t('products_and_search.call_for_pricing').toString()}
        </PriceGrid>
      );
    }

    return null;
  };

  const renderProductStock = useCallback(
    (variant: ProductVariantClass) => {
      if (!variant.inventory) return null;
      if (variant.newInventory?.onHand > 0 && !variant.inventory.isStocked) {
        return (
          <StyledAvailableAndLeadTime>
            {t('products_and_search.available_and_lead_time', {
              onHand: variant.newInventory.onHand,
              leadTime: variant.newInventory.leadTime,
            }).toString()}
          </StyledAvailableAndLeadTime>
        );
      }
      return variant.inventory.isStocked || !variant.inventory.status.specialOrderString ? (
        <>
          <AvailableGrid
            item
            className={`${variant.inventory.status.waitingOnStock ? 'waiting-on-stock' : ''}`}
          >
            {canSeeInventory ? variant.inventory.status.status : variant.inventory.status.origin}
          </AvailableGrid>
        </>
      ) : (
        <>
          <NotAvailableGrid item>
            {canSeeInventory ? variant.inventory.status.status : variant.inventory.status.origin}
          </NotAvailableGrid>
        </>
      );
    },
    [canSeeInventory, t]
  );

  const handleViewAll = () => {
    setOpen(false);
    AppStorage.removeFiltered();
    TagManager.dataLayer({
      dataLayer: {
        event: 'use_search_search_result',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
    setPagination({
      page: 1,
      itemsPerPage: 12,
    })
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <SearchWrapper data-test-id="search-product">
        <SearchBox
          data-test-id="text-input"
          variant="outlined"
          placeholder={t('search.placeholder')}
          onChange={handleChange}
          onFocus={handleFocus}
          value={searchVal}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              if (searchVal === '') {
                setOpen(false);
              } else {
                setOpen(false);
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'use_search_search_result',
                    user_type: getUserType(user.email),
                    branch_id: branch.id,
                    customer_code: user?.customer?.erpCustomerCode,
                    address_code: erpCustomerAddressCode,
                    address_id: selectedAddressId,
                  },
                });
                history.push(`${Routes.SEARCH}?q=${encodeURIComponent(searchVal)}`);
              }
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="disabled" />
              </InputAdornment>
            ),
            endAdornment: (
              <StyledIconButton
                onClick={handleClear}
                visible={searchVal.length}
                className="btn-clear"
              >
                <ClearIcon />
              </StyledIconButton>
            ),
          }}
        />

        {!data?.data?.length && open && loading && (
          <StyledDiv>
            <WrapperDiv>
              <ProductListLoading />
            </WrapperDiv>
          </StyledDiv>
        )}

        {data?.data?.length && open ? (
          <StyledDiv className="search-dropdown">
            <WrapperDiv className="dropdown-wrapper">
              <StyledUl className="dropdown-menu">
                {data.data.map(variant => (
                  <React.Fragment key={variant.code}>
                    <StyledLi className="item-product-on-search">
                      <StyledLink
                        to={{
                          pathname: `/product/${variant.code}`,
                          state: {
                            variantCode: variant.code,
                            variantId: variant.id,
                          },
                        }}
                        onClick={() => handleEventGoToProduct(variant)}
                      >
                        <Grid key={variant.name} container>
                          <Grid item xs={2}>
                            <img
                              width={50}
                              height={50}
                              src={variant.image ?? defaultProduct}
                              alt="product"
                            />
                          </Grid>
                          <TextWrapperGrid item xs={10} container direction="column" className="text-wrapper-grid">
                            <TitleGrid item>{variant.name}</TitleGrid>
                            {renderProductStock(variant)}
                            {renderPrice(variant)}
                          </TextWrapperGrid>
                        </Grid>
                      </StyledLink>
                    </StyledLi>
                    <Divider />
                  </React.Fragment>
                ))}
              </StyledUl>

              <ViewAllButton className="dropdown-view-all-button">
                <Link
                  to={`${Routes.SEARCH}?q=${encodeURIComponent(searchVal)}`}
                  onClick={handleViewAll}
                >
                  {t('products_and_search.view_all_search').toString()}
                </Link>
              </ViewAllButton>
            </WrapperDiv>
          </StyledDiv>
        ) : null}
      </SearchWrapper>
    </ClickAwayListener>
  );
};

export default SearchComponent;
