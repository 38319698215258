import React, { useMemo } from 'react';
import { createUserActionLog } from 'api/UserActionLog';

import { ProductVariantClass } from 'models/classes/productVariantClass';
import { usePermission } from 'hooks/usePermission';
import { useSelector } from 'react-redux';
import { priceTypeSelector, selectIsHidePrice } from 'ducks/application/selectors';
import { numberFormat } from 'utils/products/product';

import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  AddToTruckWrapper,
  Each,
  Price, PriceWrapper, RequestPricing, RequestPricingButton, RequestPricingWrapper, StyledAddToTruck,
  StyledFirstPriceWrapper, StyledIconButton,
  StyledPricesContainer,
  StyledSecondPriceText,
} from '../ProductTableCard/styles';


type ProductTableCardWithCheckoutNoPriceProps = {
  productVar: ProductVariantClass;
  handleTableProductViewChange: CallableFunction;
  handleRequestPricingClick: CallableFunction;
}

export default function ProductTableCardWithCheckoutNoPrice({
  productVar,
  handleTableProductViewChange,
  handleRequestPricingClick,
}: ProductTableCardWithCheckoutNoPriceProps) {
  const currentPrice = productVar.prices.length ? [productVar.prices[0]] : [];
  const { canSeePrice, canPlaceQuote, canPlaceOrder } = usePermission();
  const priceType = useSelector(priceTypeSelector);
  const isHidePrice = useSelector(selectIsHidePrice);
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const location = useLocation();
  const isSearchPage = location.pathname.includes('/search');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const renderPriceForEach = useMemo(() => {
    return currentPrice.length > 0 ? numberFormat(currentPrice[currentPrice.length - 1][priceType]) : null;
  }, [currentPrice, priceType]);

  const renderPriceForSecondUOM = useMemo(() => {
    const secondUOM = productVar.units.uom2Name === 'ft2' ? 'Ft²' : productVar.units.uom2Name;
    if (
      currentPrice.length > 0 &&
      productVar.units.uom2Conversion &&
      productVar.units.uom2Name !== ''
    ) {
      return `$${numberFormat(
        currentPrice[currentPrice.length - 1][priceType] * productVar.units.uom2Conversion,
      )}/${secondUOM}`;
    }
    return null;
  }, [currentPrice, priceType, productVar.units.uom2Conversion, productVar.units.uom2Name]);

  const renderWhenShowPrice = useMemo(() => {
    return (
      !isHidePrice ? (
        <>
          {
            currentPrice.length > 0 ? (
              <>
                <StyledPricesContainer>
                  <StyledFirstPriceWrapper>
                    <Price>${renderPriceForEach}</Price>
                    <Each>/{productVar.units.perName}</Each>
                  </StyledFirstPriceWrapper>
                  <StyledSecondPriceText>{renderPriceForSecondUOM}</StyledSecondPriceText>
                </StyledPricesContainer>
              </>
            ) : (
              <RequestPricingButton onClick={async () => {
                await createUserActionLog(isSearchPage ? 'Search Page Request Pricing' : 'Catalog Page Request Pricing', 'Pricing Inquiry');
                handleRequestPricingClick();
              }}>
                <RequestPricing>{t('products_and_search.request_pricing').toString()}</RequestPricing>
              </RequestPricingButton>
            )
          }
          <AddToTruckWrapper>
            <StyledIconButton
              aria-label="add to track"
              onMouseEnter={
                isMobile
                  ? () => {
                    return false;
                  }
                  : () => {
                    handleTableProductViewChange(productVar.id);
                  }
              }
              onClick={
                isMobile
                  ? () => {
                    handleTableProductViewChange(productVar.id);
                  }
                  : () => {
                    return false;
                  }
              }
            >
              <StyledAddToTruck />
            </StyledIconButton>
          </AddToTruckWrapper>
        </>
      ) : null);
  }, [isHidePrice, currentPrice.length, renderPriceForEach, productVar.units.perName, productVar.id, renderPriceForSecondUOM, t, isMobile, isSearchPage, handleRequestPricingClick, handleTableProductViewChange]);

  const renderWhenHidePrice = useMemo(() => {
    return isHidePrice ? (
      <RequestPricingWrapper>
        <RequestPricingButton onClick={async () => {
          await createUserActionLog(isSearchPage ? 'Search Page Request Pricing' : 'Catalog Page Request Pricing', 'Pricing Inquiry');
          handleRequestPricingClick();
        }}>
          <RequestPricing>{t('products_and_search.request_pricing').toString()}</RequestPricing>
        </RequestPricingButton>
        <StyledIconButton
          aria-label="add to track"
          onMouseEnter={
            isMobile
              ? () => {
                return false;
              }
              : () => {
                handleTableProductViewChange(productVar.id);
              }
          }
          onClick={
            isMobile
              ? () => {
                handleTableProductViewChange(productVar.id);
              }
              : () => {
                return false;
              }
          }
        >
          <StyledAddToTruck />
        </StyledIconButton>
      </RequestPricingWrapper>
    ) : null;
  }, [isHidePrice, t, isMobile, isSearchPage, handleRequestPricingClick, handleTableProductViewChange, productVar.id]);

  return (
    <PriceWrapper>
      {renderWhenShowPrice}
      {canSeePrice && (canPlaceQuote || canPlaceOrder) && renderWhenHidePrice}
    </PriceWrapper>
  )
}