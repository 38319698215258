import { filterPrices } from 'utils/products/productVariant';

export const setItemPrice = (item, pricing, currentPrices) => {
  const priceArr = currentPrices.filter(price => price.qty <= item.quantity);

  return priceArr.length
    ? priceArr[priceArr.length - 1][`${pricing}Value`]
    : currentPrices[0][`${pricing}Value`];
};

export const getDefaultInputValues = data => {
  let defaultValues = [];
  if (data.items.length) {
    defaultValues = data.items.map(item => {
      const currentPrices = filterPrices(item.variant.addressPrices);
      let cartItemCoeff = 1;
      if (currentPrices.length) {
        cartItemCoeff = Number(
          (currentPrices[0].qty / item.variant.units.uom2Conversion).toFixed(2)
        );
      }

      let bottomValue = null;
      if (item.variant.units.uom2Conversion && currentPrices.length) {
        bottomValue = Number(((item.quantity * cartItemCoeff) / currentPrices[0].qty).toFixed(2));
      }

      return {
        itemId: item.id,
        topValue: item.quantity,
        bottomValue,
        itemPrice: currentPrices.length ? setItemPrice(item, data.pricing, currentPrices) : null,
      };
    });
  }
  return defaultValues;
};

export const renderDate = () => {
  const date = new Date();
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const ScrollWindowTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};
