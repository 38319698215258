import styled from 'styled-components';
import { Popover, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { MediumButton } from 'components/common/Buttons/MediumButton';

const CheckoutWrapper = styled.div`
  box-shadow: 0 0 10px ${({ theme: { colors } }) => colors.blackOpacity(0.25)};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

const CheckoutFooter = styled.div`
  padding: 10px 16px 16px;
`;

const SubtotalWrapper = styled.div`
  margin-bottom: 10px;
`;

const SubtotalText = styled.span`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #8092aa;
`;

const SubtotalPrice = styled.span`
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 25px;
  letter-spacing: 0.01em;
  text-align: left;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  margin-bottom: 8px;
`;

const ModalFormButton = withStyles({
  root: {
    height: '48px',
    width: '100%',
    borderRadius: '4px',
    fontWeight: 600,
    fontSize: '16px',
    marginBottom: '8px',
  },
})(MediumButton);

const ClearButton = withStyles({
  root: {
    height: '48px',
    width: '100%',
    color: '#00133C',
    background: '#fff',
    border: '1px solid #b2becc',
    borderRadius: '4px',
    fontWeight: 600,
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
  label: {
    textTransform: 'none',
  },
})(MediumButton);

const StyledButtonLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.white};
  display: block;
  width: 100%;
  height: 100%;
  @media (max-width: 1023px) {
    font-size: 14px;
  }
`;

const ItemsContainer = styled.div`
  background: ${({ theme: { colors } }) => colors.white};
  padding: 16px;
  height: auto;
  max-height: 400px;
  overflow-y: auto;
`;

const StyledPopover = withStyles({
  paper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '16px',
    overflowY: 'hidden',
    '@media (max-width: 1023px)': {
      width: '100%',
      maxWidth: '100% !important',
      maxHeight: '100% !important',
      left: '0 !important',
    },
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
})(Popover);

const ItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.productBorderGrey};
  padding: 12px;
  margin-bottom: 0;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
`;

const Image = styled.img`
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 12px;
  min-width: 50px;
`;

const Title = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 250px;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  cursor: pointer;
  margin-bottom: 10px;
  @media (max-width: 1023px) {
    max-width: 100%;
    -webkit-line-clamp: initial;
    -webkit-box-orient: initial;
  }
  &:hover {
    color: ${({ theme: { colors } }) => colors.mainGreen};
    text-decoration: underline;
  }
`;

const InfoContainer = styled.div`
  flex: 1 1 calc(100% - 50px - 12px);
  width: 1 1 calc(100% - 50px - 12px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
`;

const Total = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .box-total {
    .total {
      font-size: 12px;
      color: #8092aa;
    }
    .price {
      font-size: 14px;
      font-weight: 600;
      color: #00133c;
    }
    .price-to-be-confirmed {
      font-size: 14px;
      font-weight: 600;
      color: #8092aa;
      width: 100%;
      display: block;
      color: red;
    }
  }
  .box-delete {
    .btn-delete {
      display: flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 0;
      background: #f2f4f6;
      outline: 0;
      cursor: pointer;
      transition: all 0.4s ease;
      &:hover {
        background: #e2e2e2;
      }
    }
  }
`;

export {
  CheckoutWrapper,
  SubtotalWrapper,
  SubtotalText,
  SubtotalPrice,
  CheckoutFooter,
  ClearButton,
  ModalFormButton,
  StyledButtonLink,
  ItemsContainer,
  StyledPopover,
  ItemContainer,
  Image,
  Title,
  InfoContainer,
  Total,
};
