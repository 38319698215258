import React, {FC} from 'react';

import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import {priceTypeSelector} from 'ducks/application/selectors';
import {useTranslation} from 'react-i18next';
import {usePermission} from 'hooks/usePermission';
import {changeField} from 'ducks/checkout/actions';
import {CheckoutStep} from 'ducks/checkout/types';
import {SecondStepInputWrapper, InputValue, InputUom, PromotionText} from './styles';

interface ISecondStepInput {
  values: number[];
  uoms: string[];
  item?: any;
}

const SecondStepInputs: FC<ISecondStepInput> = ({values, uoms, item}: ISecondStepInput) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {canSeePrice} = usePermission();
  const deliveryType = useSelector(priceTypeSelector);
  const renderPromotionPrice = () => {
    if (!canSeePrice || deliveryType === 'hide') {
      return null;
    }
    const prices = get(item, 'product.pricings', []);
    if (prices.length > 1) {
      const sortPrices = prices.sort((a, b) => b.quantity - a.quantity);
      const promotionPrice = sortPrices[0];
      const unitName = get(item, 'variant.units.perName', 'Piece');
      let price;
      let normalPrice;
      if (deliveryType === 'delivery') {
        price = promotionPrice.deliveryPrice;
        normalPrice = sortPrices[1].deliveryPrice;
      } else {
        price = promotionPrice.pickupPrice;
        normalPrice = sortPrices[1].pickupPrice;
      }
      if (promotionPrice.quantity > item.quantity && normalPrice - price > 0) {
        return <PromotionText
          onClick={() => dispatch(changeField('step', CheckoutStep.FIRST))}>{t('product_detail.buy')} {promotionPrice.quantity} {t('product_detail.for')} ${price}/{unitName}</PromotionText>;
      }
      const mapPrices = sortPrices.map(e => {
        return {
          delivery: e.deliveryPrice,
          pickup: e.pickupPrice
        };
      });
      const totalOldPrice = mapPrices[1][deliveryType] * item.quantity;
      const totalNewPrice = mapPrices[0][deliveryType] * item.quantity;
      const percent = Math.ceil((totalOldPrice - totalNewPrice) / totalOldPrice * 100);
      return percent > 0 && values[0] >= promotionPrice.quantity && <div dangerouslySetInnerHTML={{__html: t('product_detail.saving_percent', {percent})}}/>
    }
    return null;
  }
  return (
    values.length &&
    uoms.length && (
      <div>
        {values[0] && uoms[0] && (
          <SecondStepInputWrapper>
            <InputValue>{values[0]}</InputValue>
            <InputUom>{uoms[0]}</InputUom>
          </SecondStepInputWrapper>
        )}
        {values[1] && uoms[1] && (
          <SecondStepInputWrapper>
            <InputValue>{values[1]}</InputValue>
            <InputUom>{uoms[1]}</InputUom>
          </SecondStepInputWrapper>
        )}
        {renderPromotionPrice()}
      </div>
    )
  );
};

export default SecondStepInputs;
