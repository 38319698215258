import { CheckoutActionTypes, CheckoutStep, Order, ErrorItem } from './types';

export type Payload = null | boolean | string | Order | CheckoutStep;
export type FieldName =
  | 'order'
  | 'step'
  | 'shippingMethod'
  | 'previousSelectedAddress'
  | 'addressWasChanged'
  | 'promocode';

export const changeField = (fieldname: FieldName, payload: Payload) => ({
  type: CheckoutActionTypes.CHANGE_FILED,
  fieldName: fieldname,
  payload,
});

export const addItemError = (payload: ErrorItem) => ({
  type: CheckoutActionTypes.ADD_ITEM_ERROR,
  payload,
});

export const removeItemError = (payload: number) => ({
  type: CheckoutActionTypes.REMOVE_ITEM_ERROR,
  payload,
});
