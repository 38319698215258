import React, { useRef, useMemo, useState } from 'react';
import { DialogContentText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { UserActionTypes } from 'ducks/user/types';
import { StylesDialog, StylesDialogContent, StyledSubmitButton } from './styles';

interface IRecaptchaModal {
	open: boolean;
	callbackOnClose?: Function;
	handleSubmit: Function
}

const randomInt = () => {
  return Math.floor(Math.random() * 10) + 1;
}

export const RecaptchaModalRegister = ({open, callbackOnClose, handleSubmit}: IRecaptchaModal) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const refInput = useRef(null);

  const [shake, setShake] = useState(false);

  const math = useMemo(() => {
    return {
      number1: randomInt(),
      number2: randomInt(),
    };
  }, []);

  const [result, setResult] = useState('');

  const handleClose = () => {
    dispatch({ type: UserActionTypes.REQUEST_CREATE_USER_PROFILE_CLOSE_RECAPTCHA_MODAL });
    callbackOnClose();
  }

  const submit = () => {
    if (parseInt(result, 10) === (math.number1 + math.number2)) {
      dispatch({ type: UserActionTypes.REQUEST_CREATE_USER_PROFILE_CLOSE_RECAPTCHA_MODAL });
      handleSubmit();
    } else {
      if (refInput.current) {
        refInput.current.focus();
      }

      setShake(true)
      setTimeout(() => setShake(false), 1000);
    }
  };

  return (
    <StylesDialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={`${shake ? 'shake-dialog' : ''}`}
    >
      <StylesDialogContent>
        <DialogContentText>
          <button type="button" className="btn-close" onClick={handleClose}>
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 1L11 11M1 21L11 11M11 11L1 1M11 11L21 21"
                stroke="currentColor"
                strokeWidth="2"
              />
            </svg>
          </button>

          <div className="title-modal">{t('sign-in.doTheFollowingCalculation')}</div>

          <div className="box-calculate">{ `${math.number1} + ${math.number2}` } =</div>

          <div className="title-enter-results">{t('sign-in.enterResults')}</div>

          <textarea
            ref={refInput}
            value={result}
            rows={1}
            placeholder={t('sign-in.typeHereYourResults')}
            className={`textarea ${shake ? 'invalid' : ''}`}
            onChange={e => setResult(e.target.value)}
          />

          <div className="box-submit">
            <StyledSubmitButton className="btn-submit" onClick={submit}>
              {t('sign-in.submit')}
            </StyledSubmitButton>
          </div>
        </DialogContentText>
      </StylesDialogContent>
    </StylesDialog>
  );
};
