import styled from 'styled-components/macro';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MediumButton } from 'components/common/Buttons/MediumButton';

const OrdersContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  border-right: 1px solid ${({ theme: { colors } }) => colors.borderGrey};

  @media (max-width: 1280px) {
    border-right: none;
  }
  @media (max-width: 1000px) {
    width: 100%;
    margin-right: 32px;
    border-right: none;
  }
  @media (max-width: 700px) {
    border-right: none;
    margin-right: 0;
    padding: 30px 10px;
  }
`;

const NoOrdersText = styled(Typography)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.textColor};
`;

const ViewCatalogueButton = withStyles({
  root: {
    height: '48px',
    width: '236px',
    borderRadius: '4px',
    marginTop: '44px',
    fontWeight: 'normal',
    fontSize: '16px',
    '@media (max-width:480px)': { width: '100%' },
    '@media (max-width:320px)': {
      height: '40px',
      marginTop: '24px',
      fontSize: '14px',
    },
  },
})(MediumButton);

export { OrdersContainer, NoOrdersText, ViewCatalogueButton };
