import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { postChangePassword, resetProfileError } from 'ducks/profile/actions';
import * as Yup from 'yup';
import ModalComponent from '@material-ui/core/Modal';
import { MediumButton } from 'components/common/Buttons/MediumButton';
import { Card, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import {
  selectChangePasswordErrors,
  selectProfileLoading,
} from 'ducks/profile/selectors';
import PasswordStrengthBar from 'react-password-strength-bar/dist';
import { StyledPasswordField } from '../../../Auth/SignIn/styles';

const ModalWindow = styled(ModalComponent)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalCard = styled(Card)`
  position: fixed;
  outline: none;
  max-height: 90%;
  width: 440px;
  overflow: auto;
  padding: 50px 60px 32px 60px;

  @media (max-width: 500px) {
    width: 300px;
    padding: 20px 30px 2px 30px;
  }

  @media (max-width: 320px) {
    width: 250px;
    padding: 20px 30px 2px 30px;
  }
`;

const ModalCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const ModalTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: 0.01em;
  margin-bottom: 21px;
`;

const ModalSubTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin-bottom: 24px;

  color: ${({ theme: { colors } }) => colors.lightGreySearch};
`;

const ModalClose = styled.div`
  position: absolute;
  right: 10px;
  top: 16px;
  width: 20px;
  height: 20px;
  opacity: 0.3;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 26px;
    width: 2px;
    background-color: ${({ theme: { colors } }) => colors.textColor};
  }

  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const ModalFromButton = withStyles({
  root: {
    height: '48px',
    width: '100%',
    borderRadius: '4px',
    fontWeight: 'normal',
    fontSize: '16px',
  },
})(MediumButton);

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  errorText?: string;
}

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(2, 'password_min_length')
    .required('current_password_required'),
  newPassword: Yup.string()
    .required('new_password_required')
    .min(8, 'new_password_rule')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\w\W]\S{8,}$/, 'new_password_rule'),
  repeatPassword: Yup.string()
    .required('repeat_password_required')
    .oneOf([Yup.ref('newPassword')], 'password_not_match'),
});

const useStyles = makeStyles({
  inputLabel: {
    fontFamily: 'Open Sans',
  },
});

const ChangePasswordModal: React.FC<IProps> = ({ isOpen, handleClose, errorText }: IProps) => {
  const dispatch = useDispatch();
  const loading = useSelector(selectProfileLoading);
  const errors = useSelector(selectChangePasswordErrors);
  const error = !loading && errors;
  const classes = useStyles();

  useEffect(() => {
    dispatch(resetProfileError());
  }, [dispatch]);

  useEffect(() => {
    if (errorText) {
      if (errorText === 'Success') {
        handleClose();
      }
    }
  }, [errorText]);

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      repeatPassword: '',
    },
    validationSchema,
    onSubmit() {
      dispatch(
        postChangePassword({
          currentPassword: formik.values.currentPassword,
          newPassword: formik.values.newPassword,
        })
      );
    },
  });

  const { t } = useTranslation();

  const handlePasswordChange = (event: React.ChangeEvent<{ value: string }>) => {
    formik.handleChange(event);
    dispatch(resetProfileError());
  };

  const handleNewPasswordKeyPress = (event: React.KeyboardEvent) => {
    // check input has space bar and ignore it.
    if (event.key === ' ') {
      return event.preventDefault()
    }

    return true;
  };

  return (
    <ModalWindow open={isOpen} onClose={handleClose}>
      <ModalCard variant="outlined">
        <ModalClose onClick={handleClose} />

        <ModalTitle>{t('profile.change_password')}</ModalTitle>

        <ModalSubTitle>{t('profile.new_password_rule')}</ModalSubTitle>

        <ModalCardContent>
          <form onSubmit={formik.handleSubmit}>
            <StyledPasswordField
              fullWidth
              variant="outlined"
              error={!!((formik.errors.currentPassword && formik.touched.currentPassword) || error)}
              label={t('profile.current_password')}
              name="currentPassword"
              value={formik.values.currentPassword}
              onChange={handlePasswordChange}
              onBlur={formik.handleBlur}
              helperText={
                // eslint-disable-next-line no-nested-ternary
                (formik.errors.currentPassword && formik.touched.currentPassword)
                  ? t(`profile.${formik.errors.currentPassword}`)
                  : (error && errors.currentPassword ? t(`profile.${errors.currentPassword}`) : '')
              }
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              margin="normal"
            />
            <StyledPasswordField
              fullWidth
              variant="outlined"
              label={t('profile.new_password')}
              name="newPassword"
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onKeyPress={handleNewPasswordKeyPress}
              value={formik.values.newPassword}
              error={!!(formik.errors.newPassword && formik.touched.newPassword)}
              helperText={
                // eslint-disable-next-line no-nested-ternary
                formik.errors.newPassword && formik.touched.newPassword
                  ? t(`profile.${formik.errors.newPassword}`)
                  : (error && errors.newPassword ? t(`profile.${errors.newPassword}`) : '')
              }
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              margin="normal"
            />

            <PasswordStrengthBar
              minLength={8}
              shortScoreWord={t('profile.new_password_too_short')}
              scoreWords={[
                t('profile.new_password_too_weak'),
                t('profile.new_password_weak'),
                t('profile.new_password_ok'),
                t('profile.new_password_good'),
                t('profile.new_password_strong')
              ]}
              password={formik.values.newPassword} />
            <StyledPasswordField
              fullWidth
              variant="outlined"
              label={t('profile.repeat_password')}
              name="repeatPassword"
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.repeatPassword}
              error={!!(formik.errors.repeatPassword && formik.touched.repeatPassword)}
              helperText={
                formik.errors.repeatPassword && formik.touched.repeatPassword
                  ? t(`profile.${formik.errors.repeatPassword}`)
                  : ''
              }
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              margin="normal"
            />

            <ModalFromButton
              disabled={
                formik.values.newPassword.length === 0 ||
                formik.values.newPassword !== formik.values.repeatPassword
              }
              type="submit"
            >
              {t('profile.change_password_button')}
            </ModalFromButton>
          </form>
        </ModalCardContent>
      </ModalCard>
    </ModalWindow>
  );
};

export default ChangePasswordModal;
