import styled from 'styled-components/macro';

const WrapperDiv = styled.div`
  display: flex;
  position: absolute;
  top: 213px;
  left: 0;
  width: 100%;
  z-index: 1300;
`;

const ShadowDiv = styled.div`
  z-index: -1;
  position: fixed;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
`;

const MainNavWrapper = styled.div`
  position: relative;
  width: 1200px;
  margin: auto;
  @media (max-width: 1200px) {
    width: 100%;
    margin: 0;
  }
`;

const TriggerClose = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
`;

const MainNav = styled.ul`
  position: relative;
  overflow: visible;
  width: 303px;
  min-height: 714px;
  background: ${({ theme: { colors } }) => colors.white};
  list-style: none;
  padding: 15px 0 24px;
  margin: 15px 0;
  .bb-0 {
    border-bottom: 0 !important;
  }
  .sub-menu-title {
    color: #001a3b;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.1px;
    border-bottom: 0 !important;
    padding: 0 24px;
    margin-top: 16px;
    margin-bottom: 4px;
  }
  .new-label {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #fff;
    background: #f79009;
    border-radius: 4px;
    padding: 0 8px;
    margin-left: 14px;
  }
  ul {
    /* all ul */
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    /* all li */
    &:hover {
      > .nav-link {
        background: ${({ theme: { colors } }) => colors.whiteSmoke};
        &:before {
          opacity: 1;
        }
      }
      > .sub-menu.full-height {
        display: block;
      }
    }
    a {
      color: inherit;
      text-decoration: none;
    }
    > .nav-link {
      display: block;
      position: relative;
      transition: all 0.4s ease;
      padding-left: 24px;
      padding-right: 24px;
      &.active {
        background: ${({ theme: { colors } }) => colors.whiteSmoke};
        &:before {
          opacity: 1;
        }
      }
      &:before {
        opacity: 0;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 100%;
        background: ${({ theme: { colors } }) => colors.mainGreen};
        transition: all 0.4s ease;
      }
      > svg {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
  > li {
    /* first li */
    &.product-menu-list {
      > .nav-link {
        display: none;
        @media (max-width: 1023px) {
          display: block;
        }
      }
    }
    &.product-menu-list,
    &.taiga-now-features-menu-list,
    &.your-account-menu-list {
      border-bottom: 7px solid #f6f6f6;
    }
    > .sub-menu {
      /* first .sub-menu */
      > li {
      }
    }
    > .nav-link {
      padding-left: 0;
    }
  }
  .sub-menu {
    /* all sub-menu */
    > li {
      border-bottom: 1px solid #f6f6f6;
      > .nav-link {
        > .nav-link-title {
          display: block;
          font-family: Open Sans;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
          color: #007A33;
          padding: 11px 0;
        }
      }
    }
  }
  .sub-menu.full-height {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    width: 303px;
    min-height: 714px;
    background: ${({ theme: { colors } }) => colors.white};
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
    padding-top: 15px;
  }
  .use-and-help-menu-list {
    .sub-menu > li > .nav-link > .nav-link-title {
      color: #48576b;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
    }
  }
  .your-account-menu-list {
    .nav-link-order-history br {
      display: none;
    }
  }
`;

export { ShadowDiv, MainNav, MainNavWrapper, TriggerClose, WrapperDiv };
