import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { usePopupScrollEffect } from 'hooks/usePopupScrollEffect';
import { ProductVariantClass } from 'models/classes/productVariantClass';
import { PopUpCross, PopUpSmallCross } from 'assets/svg/Popups/Popups';
import {selectProductVariantsError, selectSendEmailNearbyBranchStatus} from 'ducks/productVariant/selectors';
import { selectSalesRepresentative } from 'ducks/salesRepresentative/selectors';
import SubmitEmailSent from 'components/Popups/RequestPricing/components/SubmitEmailSent';
import {setNearbyBranchEmailStatus} from 'ducks/productVariant/actions';
import {useOutsideClick} from 'hooks/useOutSideClick';
import {setOpenPopupInquireNow} from 'ducks/product/actions';
import { PopUpBackground, PopUpWrapper, CrossButtonWrapper, CrossButton } from './styles';
import NearbyBranchEmail from './components/NearbyBranchEmail';

interface IRequestNearbyBranchPopup {
  product: ProductVariantClass;
  setPricingClicked?: Dispatch<SetStateAction<boolean>>;
}

const rootDiv = document.getElementById('root');
const el = document.createElement('div');

const RequestNearbyBranchPopup: React.FC<IRequestNearbyBranchPopup> = ({
  product,
}) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const error = useSelector(selectProductVariantsError);
  const salesRepresentative = useSelector(selectSalesRepresentative);
  const sendEmailNearbyBranchStatus = useSelector(selectSendEmailNearbyBranchStatus);

  useEffect(() => {
    rootDiv.appendChild(el);

    return () => {
      try {
        rootDiv.removeChild(el);
        // eslint-disable-next-line no-empty
      } catch {

      }
    };
  }, []);

  usePopupScrollEffect();

  const handleClose = () => {
    dispatch(setNearbyBranchEmailStatus(false));
    dispatch(setOpenPopupInquireNow(false));
  };

  const refModal = useOutsideClick(handleClose);

  const renderSubmitEmailSent = () =>
    salesRepresentative ? (
      <SubmitEmailSent
        setOpenRequestNearbyBranch={handleClose}
        salesRepresentative={salesRepresentative}
      />
    ) : null;

  const renderNearbyBranchEmail = () =>
    salesRepresentative ? (
      <NearbyBranchEmail
        product={product}
        disabled={disabled}
        setDisabled={setDisabled}
        salesRepresentative={salesRepresentative}
        error={!!error}
        handleClose={handleClose}
      />
    ) : null;

  return ReactDOM.createPortal(
    <PopUpBackground style={{ zIndex: 1300 }}>
      <PopUpWrapper ref={refModal}>
        <CrossButtonWrapper>
          <CrossButton onClick={handleClose}>
            {isMobile ? <PopUpSmallCross /> : <PopUpCross />}
          </CrossButton>
        </CrossButtonWrapper>
        {sendEmailNearbyBranchStatus ? renderSubmitEmailSent() : renderNearbyBranchEmail()}
      </PopUpWrapper>
    </PopUpBackground>,
    el
  );
};

export default RequestNearbyBranchPopup;
