import React from 'react';
import { SalesManAvatar } from 'assets/svg/ContactUs/SalesmanAvatar';
import { SalesManContainer, ImgWrapper, StyledSalesManName, StyledImg } from './styles';

interface SalesManProps {
  salesName: string;
  imgUrl?: string;
}

function defineImage(imgUrl) {
  if (imgUrl) {
    return <StyledImg src={imgUrl} alt="" height="50" width="50" />;
  }
  return <SalesManAvatar />;
}

export const SalesMan: React.FC<SalesManProps> = ({ salesName, imgUrl }: SalesManProps) => {
  const usedImg = defineImage(imgUrl);
  return (
    <SalesManContainer>
      <ImgWrapper>{usedImg}</ImgWrapper>
      <StyledSalesManName>{salesName}</StyledSalesManName>
    </SalesManContainer>
  );
};
