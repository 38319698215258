import axios, { AxiosError, AxiosResponse } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import store from 'store';
import { TokenStorage } from './tokenStorage';
import {setIsChangeRefreshToken} from '../ducks/auth/actions';

export const baseURL = process.env.REACT_APP_BACKEND_HOSTNAME || 'undefined-backend-url';

const axiosInstance = axios.create({
  baseURL,
});

let isRefreshing = false;
const refreshSubscribers = [];

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

function onRefreshed(token) {
  refreshSubscribers.map(cb => cb(token));
}

axiosInstance.interceptors.request.use(request => {
  const appRequest = request;
  appRequest.headers['Accept-Language'] = store.getState()?.app?.language;
  appRequest.headers['Accept'] = 'application/json';
  if (appRequest?.data) {
    appRequest.data = decamelizeKeys(appRequest.data);
  }
  if (appRequest?.params) {
    appRequest.params = decamelizeKeys(appRequest.params);
  }
  return appRequest;
});

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;

    if (status === 401 && originalRequest.headers.Authorization) {
      if (!isRefreshing) {
        isRefreshing = true;
        TokenStorage.getNewToken()
          .then(newToken => {
            isRefreshing = false;
            onRefreshed(newToken);
            store.dispatch(setIsChangeRefreshToken(true));
          })
          .catch(() => {
            TokenStorage.clear();
            const language = localStorage.getItem('i18nextLng');
            return window.location.replace(`/${language}` || '/en');
          });
      }

      return new Promise(resolve => {
        subscribeTokenRefresh(token => {
          // replace the expired token and retry
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data && response.headers['content-type'] === 'application/json') {
      response.data = camelizeKeys(response.data);
    }
    return response;
  },
  (error: AxiosError) => {
    const responseError = error;
    if (responseError.response.status === 503) {
      return window.location.replace('/maintenance');
    }

    responseError.response.data = camelizeKeys(responseError.response.data);
    return Promise.reject(error);
  }
);

export default axiosInstance;
