import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { Cross } from 'assets/svg/Filter/Cross';
import { IPagination, IRangeFilter, ISelectedValue } from 'models/interfaces/productVariant';
import { generateFilter } from 'components/Product/ProductFilter/utils';
import { FacetClass } from 'models/classes/facetClass';

const AllValuesWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 8px 0 19px 8px;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const SingleFilter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 4px;
  background-color: ${({ theme: { colors } }) => colors.lightGrey};
  margin: 0px 8px 8px 0px;
  padding: 9px 12px;
`;

const SingleFilterTitle = styled.p`
  margin: 0 8px 0 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
`;

const StyledButton = styled.button`
  width: fit-content;
  height: fit-content;
  background-color: ${({ theme: { colors } }) => colors.lightGrey};
  border: none;
  padding: 0;
  outline: none;

  &:hover {
    cursor: pointer;
  }
`;

interface IProductFilterPaper {
  facets: FacetClass[];
  rangeFilters: IRangeFilter[];
  filterValues: ISelectedValue[];
  setSelectedValues: Dispatch<SetStateAction<ISelectedValue[]>>;
  setFilter: Dispatch<SetStateAction<object>>;
  setPagination?: Dispatch<SetStateAction<IPagination>>;
  setFirstLoad?: Dispatch<SetStateAction<boolean>>;
}

const ProductFilterPaper: React.FC<IProductFilterPaper> = ({
  facets,
  rangeFilters,
  filterValues,
  setSelectedValues,
  setFilter,
  setPagination,
  setFirstLoad
}: IProductFilterPaper) => {
  const handleDelete = (name: string, facetCode: string) => {
    const clearedFilterValues = filterValues.filter(elem => {
      if (elem.name === name) {
        return elem.code !== facetCode;
      }

      return true;
    });

    setSelectedValues(clearedFilterValues);
    setFilter(generateFilter(clearedFilterValues));
    setPagination({ page: 1, itemsPerPage: 12 });
    setFirstLoad(true);
  };

  return filterValues.length > 0 ? (
    <AllValuesWrapper>
      {filterValues &&
        filterValues.map(value => {
          const existingFacet = facets.find(check => check.property === value.code);
          const existingRangeFilter = rangeFilters.some(
            rangeFilter => rangeFilter.key === value.code
          );
          const isBucketExists = () => {
            if (existingFacet) {
              return value.bucketKey
                ? existingFacet.buckets.some(bucket => bucket.key === value.bucketKey)
                : true;
            }

            return existingRangeFilter;
          };

          return (
            isBucketExists() && (
              <SingleFilter key={`${value.name}+${value.code}`}>
                <SingleFilterTitle>{value.name}</SingleFilterTitle>
                <StyledButton onClick={() => handleDelete(value.name, value.code)}>
                  <Cross />
                </StyledButton>
              </SingleFilter>
            )
          );
        })}
    </AllValuesWrapper>
  ) : null;
};

export default ProductFilterPaper;
