import React from 'react';
import styled from 'styled-components/macro';

const StyledLoadingDiv = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 60px;
  border: 10px solid ${({ theme: { colors } }) => colors.mainGreen};
  border-top-color: ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  animation: loading 1.2s linear infinite;
  -webkit-animation: loading 1.2s linear infinite;
  @media (max-width: 1200px) {
    top: calc(50% - 40px);
    left: calc(50% - 40px);
  }
  @media (max-width: 640px) {
    top: calc(65% - 40px);
    left: calc(50% - 40px);
  }
`;
const StyledLoadingDivWrapper = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchLoader: React.FC = () => {
  return (
    <StyledLoadingDivWrapper>
      <StyledLoadingDiv />
    </StyledLoadingDivWrapper>
  );
};

export default SearchLoader;
