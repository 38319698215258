import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import BackArrow from 'assets/images/backarrow.png';

import PrivacyPolicy from 'pages/Technical/PrivacyPolicy';

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const MainContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 0 120px;

  @media (max-width: 1439px) {
    padding: 0 70px;
  }

  @media (max-width: 1360px) {
    padding: 0 20px;
  }

  @media (max-width: 920px) {
    padding: 0 10px;
  }
`;

const BreadCrumb = styled.div`
  width: fit-content;
  cursor: pointer;
  margin-top: 40px;
`;

const BackArrowImg = styled.img`
  margin-right: 4px;
`;

const BackText = styled.span`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: left;
`;

interface IPrivacy {
  isEnglish: boolean;
  privacySwitcher: MouseEventHandler<HTMLDivElement>;
  withBreadCrumb: boolean;
}

const PrivacyComponent: React.FC<IPrivacy> = ({
  isEnglish,
  privacySwitcher,
  withBreadCrumb,
}: IPrivacy) => {
  const language = isEnglish ? 'en_CA' : 'fr_CA';
  return (
    <PageWrapper>
      <MainContainer>
        {withBreadCrumb ? (
          <BreadCrumb onClick={privacySwitcher}>
            <BackArrowImg src={BackArrow} />
            <BackText>Back</BackText>
          </BreadCrumb>
        ) : null}
        <PrivacyPolicy language={language} />
      </MainContainer>
    </PageWrapper>
  );
};

export default PrivacyComponent;
