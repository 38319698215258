import React from 'react';
import { MediumButton } from 'components/common/Buttons/MediumButton';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';

interface IConfirmButton {
  formError?: string;
  loading?: boolean;
  handleSubmitOrder: () => void;
  isDisabled: boolean;
}

export function ConfirmButton({ formError, loading, handleSubmitOrder, isDisabled }: IConfirmButton) {
  const { t } = useTranslation();
  const style = {
    width: '100%',
    fontWeight: 600,
    backgroundColor: formError ? '#DADCE0' : '#00853e',
    '@media (maxWidth: 1023px)': {
      fontSize: '14px',
      lineHeight: '18px',
    },
    '&:hover': {
      backgroundColor: formError ? '#DADCE0' : '#009650',
    },
  };

  return (
    // @ts-ignore
    <MediumButton style={style} onClick={handleSubmitOrder} disabled={isDisabled}>
      {loading && <CircularProgress color="inherit" size={22} style={{ marginRight: 5 }} />}
      {t('checkout.confirm_order')}
    </MediumButton>
  );
}
