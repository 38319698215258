import * as React from 'react';
import { Paper, Grid, Typography, withStyles } from '@material-ui/core';
import getDate from 'date-fns/getDate';
import isSameMonth from 'date-fns/isSameMonth';
import isToday from 'date-fns/isToday';
import format from 'date-fns/format';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';

import {
  chunks,
  getDaysInMonth,
  isStartOfRange,
  isEndOfRange,
  inDateRange,
  isRangeSameDay,
  isWithinRange,
} from 'pages/General/Profile/components/OrdersAndQuotes/DateRangePicker/utils';
import Header from 'pages/General/Profile/components/OrdersAndQuotes/DateRangePicker/components/Header';
import Day from 'pages/General/Profile/components/OrdersAndQuotes/DateRangePicker/components/Day';
import {
  NavigationAction,
  DateRange,
  Setter,
} from 'pages/General/Profile/components/OrdersAndQuotes/DateRangePicker/types';
import { selectLanguage } from 'ducks/application/selectors';

const EN_WEEK_DAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
const FR_WEEK_DAYS = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];

const StyledPaper = withStyles({
  root: {
    width: 290,
  },
})(Paper);

const StyledGridWeekDaysContainer = withStyles({
  root: {
    marginTop: 10,
    paddingLeft: 30,
    paddingRight: 30,
  },
})(Grid);

const StyledGridDaysContainer = withStyles({
  root: {
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 15,
    marginBottom: 15,
  },
})(Grid);

interface MonthProps {
  value: Date;
  marker: symbol;
  dateRange: DateRange;
  minDate: Date;
  maxDate: Date;
  navState: [boolean, boolean];
  setValue: Setter<Date>;
  helpers: {
    inHoverRange: (day: Date) => boolean;
  };
  handlers: {
    onDayClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, day: Date) => void;
    onDayHover: (day: Date) => void;
    onMonthNavigate: (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      marker: symbol,
      action: NavigationAction
    ) => void;
  };
}

const Month: React.FC<MonthProps> = ({
  value: date,
  marker,
  dateRange,
  minDate,
  maxDate,
  navState,
  setValue: setDate,
  helpers,
  handlers,
}: MonthProps) => {
  const [back, forward] = navState;
  const language = useSelector(selectLanguage);

  return (
    <StyledPaper square elevation={0}>
      <Grid container>
        <Header
          date={date}
          setDate={setDate}
          nextDisabled={!forward}
          prevDisabled={!back}
          onClickPrevious={e => handlers.onMonthNavigate(e, marker, NavigationAction.Previous)}
          onClickNext={e => handlers.onMonthNavigate(e, marker, NavigationAction.Next)}
        />

        <StyledGridWeekDaysContainer item container direction="row" justify="space-between">
          {language !== 'fr_CA'
            ? EN_WEEK_DAYS.map(day => (
              <Typography color="textSecondary" key={day} variant="caption">
                {day}
              </Typography>
            ))
            : FR_WEEK_DAYS.map(day => (
              <Typography color="textSecondary" key={day} variant="caption">
                {day}
              </Typography>
            ))}
        </StyledGridWeekDaysContainer>

        <StyledGridDaysContainer item container direction="column" justify="space-between">
          {chunks(getDaysInMonth(date), 7).map(week => (
            <Grid key={uuidv4()} container direction="row" justify="center">
              {week.map(day => {
                const isStart = isStartOfRange(dateRange, day);
                const isEnd = isEndOfRange(dateRange, day);
                const isRangeOneDay = isRangeSameDay(dateRange);
                const highlighted = inDateRange(dateRange, day) || helpers.inHoverRange(day);

                return (
                  <Day
                    key={format(day, 'MM-dd-yyyy')}
                    filled={isStart || isEnd}
                    outlined={isToday(day)}
                    highlighted={highlighted && !isRangeOneDay}
                    disabled={!isSameMonth(date, day) || !isWithinRange(day, minDate, maxDate)}
                    startOfRange={isStart && !isRangeOneDay}
                    endOfRange={isEnd && !isRangeOneDay}
                    onClick={e => handlers.onDayClick(e, day)}
                    onHover={() => handlers.onDayHover(day)}
                    value={getDate(day)}
                  />
                );
              })}
            </Grid>
          ))}
        </StyledGridDaysContainer>
      </Grid>
    </StyledPaper>
  );
};

export default Month;
