import { GAActionTypes } from './types';

export const setInStockApplied = () => ({
  type: GAActionTypes.SET_IN_STOCK_APPLIED,
});

export const setOrderSubmitted = () => ({
  type: GAActionTypes.SET_ORDER_SUBMITTED,
});

export const setPricingTypeChanged = () => ({
  type: GAActionTypes.SET_PRICING_TYPE_CHANGED,
});

export const setOrdersOrQuotesVisited = () => ({
  type: GAActionTypes.SET_ORDERS_OR_QUOTES_VISITED,
});

export const resetOnePerBranchEventsFlags = () => ({
  type: GAActionTypes.RESET_STATE,
});

export const setIsFireViewItemFeaturedProduct = (status: boolean) => ({
  type: GAActionTypes.SET_IS_FIRE_FEATURED_PRODUCT,
  payload: {
    data: status,
  }
})