import styled from 'styled-components/macro';

const TaxesBlock = styled.div`
  display: flex;
  padding: 26px 24px 0 24px;
  gap: 3px;
  width: 100%;

  @media (max-width: 1023px) {
    padding-right: 10px;
  }
`;

const TaxesBlockItem = styled.div`
  width: 100%;
`;

const TaxesSingleItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 4px;

  &:last-child {
    margin-top: 8px;
  }
`;

const TaxesName = styled.div`
  font-size: 16px;
`;

interface IPrice {
  bold?: boolean;
}

const Price = styled.div`
  font-size: 16px;
  font-weight: ${(props: IPrice) => (props.bold ? 'bold' : 'normal')};
  color: ${({ theme: { colors } }) => colors.mainGreen};
`;

const PaymentInfoBlock = styled.div`
  display: flex;
  padding: 0 24px;
  margin-top: 26px;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.grey};
  margin-bottom: 7px;
`;

const NotesBlock = styled.div`
  display: flex;
  padding: 0 24px;
  margin-top: 26px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.productBorderGrey};
  padding-bottom: 24px;
`;

const NotesText = styled.div`
  word-break: break-word;
`;

const SvgWrapper = styled.div`
  margin-top: 5px;
  margin-right: 11px;
`;

export {
  SvgWrapper,
  NotesBlock,
  Label,
  PaymentInfoBlock,
  Price,
  TaxesName,
  TaxesSingleItem,
  TaxesBlockItem,
  TaxesBlock,
  NotesText,
};
