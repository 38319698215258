import React, { useEffect, useState } from 'react';
import { ExpandMore } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import { ShowPrice } from 'assets/svg/Pricing/ShowPrice';
import { HidePrice } from 'assets/svg/Pricing/HidePrice';
import { GreenCheck } from 'assets/svg/GreenCheck';
import { toggleShowPrices } from 'ducks/productVariant/actions';
import { priceTypeSelector } from 'ducks/application/selectors';
import { DELIVERY_PRICE_TYPE, PICKUP_PRICE_TYPE } from 'assets/constants/application';
import { setPriceType } from 'ducks/application/actions';

import { useTranslation } from 'react-i18next';

import { ColoredSvg } from 'components/Layout/Header/Header/styles';
import { AppStorage } from 'components/App/services/storage';
import { usePermission } from 'hooks/usePermission';
import { setPricingTypeChanged } from 'ducks/GA/actions';
import { changeCartDeliveryType } from 'ducks/order/actions';
import { selectOrderId } from 'ducks/cart/selectors';
import { FormControlWrapper, StyledSelect, StyledMenuItem, StyledParagraph } from './styles';

type Props = {
  noArrow?: boolean;
  className?: string;
};

const PricingDropdown: React.FC<Props> = ({ noArrow, className }) => {
  const { t } = useTranslation();

  const [pricingStatus, setPricingStatus] = React.useState(0);
  const dispatch = useDispatch();
  const pricingType = useSelector(priceTypeSelector);
  const { canSeePrice } = usePermission();
  const cartId = useSelector(selectOrderId);
  const [disableDropdown, setDisableDropdown] = useState(false);
  const pricingOptions = [
    {
      title: t('header.delivery_pricing'),
      icon: 'show',
      type: 'delivery',
      dataTestId: 'delivery-pricing',
    },
    {
      title: t('header.pickup_pricing'),
      icon: 'show',
      type: 'pickup',
      dataTestId: 'pickup-pricing',
    },
  ];

  useEffect(() => {
    if (!canSeePrice) {
      setPricingStatus(2);
      setDisableDropdown(true);
    } else {
      setDisableDropdown(false);
    }
  }, [canSeePrice]);

  useEffect(() => {
    switch (pricingType) {
      case DELIVERY_PRICE_TYPE:
        setPricingStatus(0);
        break;
      case PICKUP_PRICE_TYPE:
        setPricingStatus(1);
        break;
      default:
        setPricingStatus(2);
        break;
    }
  }, [pricingType]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(toggleShowPrices([0, 1].includes(event.target.value as number)));
    const selectedPricingStatus = event.target.value as number;
    setPricingStatus(selectedPricingStatus);

    if (pricingOptions[pricingStatus].type) {
      dispatch(setPriceType(pricingOptions[event.target.value as number].type));
      AppStorage.setDeliveryType(pricingOptions[event.target.value as number].type);
      dispatch(setPricingTypeChanged());
      if (cartId) {
        dispatch(
          changeCartDeliveryType({
            cartId,
            deliveryType: pricingOptions[selectedPricingStatus].type,
          })
        );
      }
    }
  };

  return (
    <FormControlWrapper className="pricing-dropdown-wrapper">
      {/* <FormControlLabel control={<IOSSwitch />} label="Prices" className="prices-switch" /> */}

      <StyledSelect
        disabled={disableDropdown}
        noArrow={noArrow}
        className={className}
        value={pricingStatus}
        onChange={handleChange}
        disableUnderline
        autoWidth
        IconComponent={ExpandMore}
        displayEmpty
        MenuProps={{
          style: { zIndex: 1500 },
          MenuListProps: {
            disablePadding: true,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          getContentAnchorEl: null,
        }}
      >
        {pricingOptions &&
          pricingOptions.map((pricingOption, index) => (
            <StyledMenuItem
              key={`styled-menu-${pricingOption.title}`}
              value={index}
              data-test-id={pricingOption.dataTestId}
            >
              {pricingOption.icon === 'show' ? (
                <ColoredSvg className="colored-svg">
                  <ShowPrice />
                </ColoredSvg>
              ) : (
                <HidePrice />
              )}
              <StyledParagraph className={className}>{pricingOption.title}</StyledParagraph>
              {pricingStatus === index ? (
                <div>
                  <ColoredSvg className="colored-svg">
                    <GreenCheck />
                  </ColoredSvg>
                </div>
              ) : null}
            </StyledMenuItem>
          ))}
      </StyledSelect>
    </FormControlWrapper>
  );
};

export default PricingDropdown;
