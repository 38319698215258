import React from 'react';

export const YouTubeIcon = () => {
  return (
    <svg height="32" width="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4909 5.46819C29.8727 5.84755 30.9636 6.93142 31.3273 8.30432C32 10.7972 32 15.9998 32 15.9998C32 15.9998 32 21.2024 31.3455 23.6953C30.9636 25.0863 29.8909 26.1701 28.5091 26.5314C26 27.1998 16 27.1998 16 27.1998C16 27.1998 6 27.1998 3.50909 26.5314C2.12727 26.1521 1.03636 25.0682 0.672727 23.6953C0 21.2024 0 15.9998 0 15.9998C0 15.9998 0 10.7972 0.654545 8.30432C1.03636 6.91335 2.10909 5.82948 3.49091 5.46819C6 4.7998 16 4.7998 16 4.7998C16 4.7998 26 4.7998 28.4909 5.46819ZM21.091 15.9999L12.7273 11.267V20.7328L21.091 15.9999Z"
        fill="white"
      />
    </svg>
  );
};
