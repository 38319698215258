import { createSelector } from 'reselect';
import { RootState } from '../../store';

export const selectSiteLocale = (state: RootState) => state.siteLocale;

export const selectSiteLocales = createSelector(
  selectSiteLocale,
  state => state.siteLocales,
);

export const selectCurrentSiteLocale = createSelector(
  selectSiteLocale,
  state => state.siteLocales.find(locale => locale.isDefault),
);