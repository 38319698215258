export class AppStorage {
  private static readonly DELIVERY_TYPE = 'deliveryType';

  private static readonly DEFAULT_ADDRESS_ID = 'defaultAddressId';

  private static readonly DEFAULT_ADDRESS_CODE = 'defaultAddressCode';

  private static readonly FILTERED = 'filtered'

  public static setDeliveryType = (deliveryType: string): void => {
	  localStorage.setItem(AppStorage.DELIVERY_TYPE, deliveryType)
  }

  public static getDeliveryType = (): string | undefined => localStorage.getItem(AppStorage.DELIVERY_TYPE);

  public static removeDeliveryType = (): void => {
	  localStorage.removeItem(AppStorage.DELIVERY_TYPE);
  }

  public static setDefaultAddressId = (addressId: number): void => {
	  localStorage.setItem(AppStorage.DEFAULT_ADDRESS_ID, String(addressId));
  }

  public static getDefaultAddressId = (): number | undefined => parseInt(localStorage.getItem(AppStorage.DEFAULT_ADDRESS_ID), 10);

  public static removeDefaultAddressId = (): void => {
	  localStorage.removeItem(AppStorage.DEFAULT_ADDRESS_ID);
  }

  public static setDefaultAddressCode = (addressCode: string): void => {
	  localStorage.setItem(AppStorage.DEFAULT_ADDRESS_CODE, addressCode);
  }

  public static getDefaultAddressCode = (): string | undefined => localStorage.getItem(AppStorage.DEFAULT_ADDRESS_CODE);

  public static removeDefaultAddressCode = (): void => {
	  localStorage.removeItem(AppStorage.DEFAULT_ADDRESS_CODE);
  }

  public static setFiltered = (filtered: string): void => {
	  sessionStorage.setItem(AppStorage.FILTERED, filtered)
  }

  public static getFiltered = (): string | undefined => sessionStorage.getItem(AppStorage.FILTERED)

  public static removeFiltered = (): void => {
	  sessionStorage.removeItem(AppStorage.FILTERED)
  }
}
