import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps, Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import { Scrollbars } from 'react-custom-scrollbars';

// Material
import { Grid, useMediaQuery, Collapse } from '@material-ui/core';

// Icons
import { SubcategoryArrow } from 'assets/svg/SubcategoryArrow';

import { BreadCrumbs, ProductCarousel } from 'components/common';
import { fetchCategory, resetCategory } from 'ducks/category/actions';
import { CategoryClass } from 'models/classes/categoryClass';
import { selectCategory } from 'ducks/category/selectors';
import Routes from 'routes';
import { StyledBreadCrumbsContainer } from 'components/common/BreadCrumbs/styles';

import { useTranslation } from 'react-i18next';
import {
  selectAddressId,
  selectErpCustomerAddressCode,
  selectLanguage,
} from 'ducks/application/selectors';
import { selectUserData, selectUserProviceCode } from 'ducks/user/selectors';
import { Content } from 'components/Layout/Store/styles';
import defaultAvatar from 'assets/images/default-product.png';

import { PROVINCE_CODES_WITH_NAME } from 'assets/constants/application';
import TagManager from 'react-gtm-module';
import { CategoryNotification } from 'components/Layout/Notification/CategoryNotification';
import { getBanner } from 'ducks/application/actions';
import { selectBranch } from 'ducks/branch/selectors';
import { AppStorage } from 'components/App/services/storage';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { fetchFeaturedProducts } from 'ducks/productVariant/actions';

import {
  SubcategoriesWrapper,
  SubcategoryBoxList,
  Header,
  ShopInfo,
  Flex,
  FlexTopSkeleton,
  ImageContainer,
  ImageComponent,
} from './styles';

type IParam = {
  id: string;
};

const SubcategoryList: React.FC<RouteComponentProps<IParam>> = ({
  match,
}: RouteComponentProps<IParam>) => {
  const dispatch = useDispatch();
  const { id } = match.params;
  const category: CategoryClass = useSelector(selectCategory);
  const addressId = useSelector(selectAddressId);
  const proviceCode = useSelector(selectUserProviceCode);
  const language = useSelector(selectLanguage);
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const divRef = useRef(null);
  const history = useHistory();
  const [expandKeys, setExpandKeys] = useState<any[]>([]);
  const branch = useSelector(selectBranch);
  const user = useSelector(selectUserData);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const isFiredEventGoToCategory = useRef(false);

  useEffect(() => {
    return () => {
      isFiredEventGoToCategory.current = false;
    };
  }, []);

  useEffect(() => {
    if (user && branch && !isFiredEventGoToCategory.current) {
      isFiredEventGoToCategory.current = true;
      TagManager.dataLayer({
        dataLayer: {
          event: 'go_to_category',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: addressId,
        },
      });
    }
    AppStorage.removeFiltered();
    if (language && addressId && id) {
      dispatch(fetchCategory(id, language, addressId));
      dispatch(
        fetchFeaturedProducts({
          categoryCode: id,
        })
      );
    }
    return () => {
      dispatch(resetCategory());
    };
  }, [dispatch, id, erpCustomerAddressCode, language, addressId, user, branch]);

  useEffect(() => {
    if (
      category?.children &&
      category.categoryPage?.imageUrl &&
      !isMobile &&
      PROVINCE_CODES_WITH_NAME[proviceCode] !== 'Quebec'
    ) {
      setTimeout(
        () =>
          document.documentElement.scrollTo({
            top: divRef.current.offsetTop + 90,
            behavior: 'smooth',
          }),
        100
      );
    }
  }, [category, isMobile, proviceCode]);

  useEffect(() => {
    if (id && branch) {
      dispatch(getBanner(id, branch.id));
    }
  }, [dispatch, id, branch]);

  const { t } = useTranslation();

  /**
   * On change expand keys
   */
  const onChangeExpandKeys = (itemId: any) => {
    if (expandKeys.includes(itemId)) {
      const newExpandKeys = expandKeys.filter(item => item !== itemId);
      setExpandKeys(newExpandKeys);
    } else {
      setExpandKeys([...expandKeys, itemId]);
    }
  };

  return (
    <>
      <div ref={divRef}>
        {category && category.bannerUrl && PROVINCE_CODES_WITH_NAME[proviceCode] !== 'Quebec' && (
          <>
            <ImageContainer>
              <ImageComponent src={category.bannerUrl} alt={category.name} />
            </ImageContainer>
          </>
        )}
        <CategoryNotification />
      </div>
      <Content>
        <Grid container item md={12} xs={12}>
          {category && (
            <StyledBreadCrumbsContainer>
              <BreadCrumbs currentPageName={`${category.name}`} />
            </StyledBreadCrumbsContainer>
          )}

          <Grid item md={12} xs={12}>
            <Header className="txt-category-name">{category?.name}</Header>
            <ShopInfo>{category?.description}</ShopInfo>
          </Grid>

          <SubcategoriesWrapper>
            {!category && (
              <>
                <Skeleton variant="rect" width="100%" height={96} />
                <br />
                <Skeleton variant="rect" width="100%" height={32} style={{ marginTop: '16px' }} />
                <br />
                <FlexTopSkeleton>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Skeleton variant="rect" width="100%" height={72} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Skeleton variant="rect" width="100%" height={72} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Skeleton variant="rect" width="100%" height={72} />
                    </Grid>
                  </Grid>
                </FlexTopSkeleton>
              </>
            )}
            {category && category.children.length > 0 && (
              <SubcategoryBoxList>
                <Grid container spacing={2} className="list">
                  {category.children.map(
                    (item: any) =>
                      item.items > 0 && (
                        <Grid
                          key={item.id}
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          className={`item ${expandKeys.includes(item.id) ? 'expanded' : ''}`}
                        >
                          <div className="box-card-item">
                            <div className="box-card-header">
                              <div
                                aria-hidden
                                role="button"
                                className="box-item"
                                onClick={() => {
                                  if (item.items > 0) {
                                    if (item.children.length === 0) {
                                      return history.push(
                                        `${Routes.CATEGORIES}/${id}/${item.code}`
                                      );
                                    }
                                    return onChangeExpandKeys(item.id);
                                  }
                                  return false;
                                }}
                              >
                                <Grid container>
                                  <div className="box-thumb">
                                    <div
                                      className="img"
                                      style={{
                                        backgroundImage: `url(${item.urlPath || defaultAvatar})`,
                                      }}
                                    />
                                  </div>

                                  <div className="box-txt">
                                    <div className="txt-title">{item.name}</div>
                                    <div className="txt-count">
                                      {item.items || 0} {t('products_carousel.products')}
                                    </div>
                                  </div>
                                  {item.children.length === 0 && item.items > 0 && (
                                    <Link
                                      to={`${Routes.CATEGORIES}/${id}/${item.code}`}
                                      className="box-arrow"
                                    >
                                      <SubcategoryArrow />
                                    </Link>
                                  )}
                                </Grid>
                              </div>
                            </div>

                            <Collapse in={expandKeys.includes(item.id)}>
                              <Scrollbars
                                universal
                                renderThumbVertical={() => <div className="thumb-vertical" />}
                                style={{ width: '100%', height: 220 }}
                                className="box-card-body-wrapper"
                              >
                                <div className="box-card-body">
                                  {Array.isArray(item.children) && item.children.length > 0 && (
                                    <ul className="sub-list">
                                      {item.children.map((sub: any) => (
                                        <li key={sub.code} className="box-item">
                                          <Link to={`${Routes.CATEGORIES}/${id}/${sub.code}`}>
                                            <Grid container alignItems="center">
                                              <div className="box-thumb">
                                                <div
                                                  className="img"
                                                  style={{
                                                    backgroundImage: `url(${item.urlPath || defaultAvatar})`,
                                                  }}
                                                />
                                              </div>

                                              <div className="box-txt">
                                                <div className="txt-title">{sub.name}</div>
                                                <div className="txt-count">
                                                  {sub.items || 0} {t('products_carousel.products')}
                                                </div>
                                              </div>

                                              <div className="box-arrow">
                                                <SubcategoryArrow />
                                              </div>
                                            </Grid>
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              </Scrollbars>
                            </Collapse>
                          </div>
                        </Grid>
                      )
                  )}
                </Grid>
              </SubcategoryBoxList>
            )}

            {!category?.children && category && (
              <>
                <Flex>
                  <Skeleton
                    variant="rect"
                    width="18.5%"
                    height={301}
                    style={{ margin: '9px', marginLeft: '0' }}
                  />
                  <Skeleton variant="rect" width="18.5%" height={301} style={{ margin: '9px' }} />
                  <Skeleton variant="rect" width="18.5%" height={301} style={{ margin: '9px' }} />
                  <Skeleton variant="rect" width="18.5%" height={301} style={{ margin: '9px' }} />
                  <Skeleton
                    variant="rect"
                    width="18.5%"
                    height={301}
                    style={{ margin: '9px', marginRight: '0' }}
                  />
                </Flex>
              </>
            )}

            <ProductCarousel
              categoryName={category?.name}
              categoryCode={category?.code}
              isFeatured
            />
          </SubcategoriesWrapper>
        </Grid>
      </Content>
    </>
  );
};

export default SubcategoryList;
