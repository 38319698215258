import React, {useCallback, useMemo} from 'react';
import {FindMatchProduct, IOption, IOptionValue} from 'models/interfaces/product';
import { ProductVariantClass } from 'models/classes/productVariantClass';
import { Tooltip } from '@material-ui/core';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import reduce from 'lodash/reduce';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';
import {usePermission} from 'hooks/usePermission';
import {ProductClass} from 'models/classes/productClass';
import {useSelector} from 'react-redux';
import midDefaultProduct from 'assets/images/default-mid-product.png';
import {
  priceTypeSelector,
  selectAddressId,
  selectErpCustomerAddressCode,
  selectIsHidePrice,
} from 'ducks/application/selectors';
import {IPrice} from 'models/interfaces/productVariant';
import { numberFormat } from 'utils/products/product';
import TagManager from 'react-gtm-module';
import {clearGtmEcommerce} from 'utils/clearGtmEcommerce';
import {getUserType} from 'utils/profile/profileUtilsHelpers';
import {selectUserData} from 'ducks/user/selectors';
import {selectBranch} from 'ducks/branch/selectors';
import {ProductStock, SpecialOrder, StyledAvailableAndLeadTime} from '../../styles';
import {
  SwatchItem,
  SwatchItemInnerWithPrice,
  SwatchItemInner,
  SwatchWrapper,
  SwatchWrapperScroll,
  SwatchWrapperScrollRow,
  DisabledItemTooltip,
} from '../styles';


interface ISwatchType {
  code: string;
  optionValues: IOptionValue[];
  currentProductVariant: ProductVariantClass;
  onAttributeClick(optionCode: string, code: string): void;
  findMatchProducts: Array<FindMatchProduct> | [];
  option: IOption;
  product: ProductClass;
  price: IPrice;
}

let currentCode;
const SwatchTypeV2 = React.memo(
  ({ optionValues, code, currentProductVariant, onAttributeClick, findMatchProducts, option, product, price }: ISwatchType) => {
    const {t} = useTranslation();
    const { canSeeInventory, canSeePrice  } = usePermission();
    const isHidePrice = useSelector(selectIsHidePrice);
    const deliveryType = useSelector(priceTypeSelector);
    const user = useSelector(selectUserData);
    const branch = useSelector(selectBranch);
    const selectedAddressId = useSelector(selectAddressId);
    const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
    const [defaultPrice = null] = currentProductVariant.prices;
    const currentPrice = price || defaultPrice;
    const findOptionValue = currentProductVariant.optionValues.find(
      item => item.code === code
    );

    if (findOptionValue) {
      currentCode = findOptionValue.optionCode;
    }

    const getOptionClass = useCallback((optionValue: IOptionValue): string => {
      if (optionValue.optionCode === currentCode) {
        return 'active';
      }

      if (findMatchProducts && findMatchProducts.length) {
        const pickItemCode = map(findMatchProducts, 'itemCode');

        const pickItemCodeObject = flatten(pickItemCode);
        const resultObject = reduce(
          pickItemCodeObject,
          (acc, value) => {
            const innerKey = Object.keys(value)[0];
            const innerValue = value[innerKey];

            // Check if the innerKey exists in the resultObject
            // eslint-disable-next-line no-prototype-builtins
            if (acc.hasOwnProperty(innerKey)) {
              // If it exists, push the value to the existing array
              acc[innerKey].push(innerValue);
            } else {
              // If it doesn't exist, create a new array with the value
              acc[innerKey] = [innerValue];
            }

            return acc;
          },
          {} as { [key: string]: string[] }
        );
        const match = resultObject[optionValue.code]?.includes(optionValue.optionCode);

        if (!match) {
          return 'disabled';
        }
      }
      if (findMatchProducts.length === 0) {
        return 'disabled';
      }

      return '';
    }, [findMatchProducts]);

    const getMatchProductForOption = useCallback((optionValue: IOptionValue) => {
      const matchProductForOption =  findMatchProducts.find(match => {
        return match.itemCode.some(item => {
          return item[option.code] === optionValue.optionCode;
        });
      });
      if (!matchProductForOption) return null;
      return product.variants.find(variant => variant.erpProductId === matchProductForOption.erpProductId)
    }, [findMatchProducts, option.code, product.variants])

    const renderProductInventoryStatus = (optionValue: IOptionValue) => {
      if(optionValue.optionCode === currentCode) {
        const status = get(currentProductVariant, 'inventory.status', {});
        if (status.waitingOnStock) {
          return (
            <ProductStock className="waiting-on-stock">
              {canSeeInventory ? status.status : status.origin}
            </ProductStock>
          );
        }
        if (status.inStock || !status.specialOrderString) {
          return (
            <ProductStock>
              {canSeeInventory ? status.status : status.origin}
            </ProductStock>
          );
        }
        return (
          <SpecialOrder className="special-order">
            {canSeeInventory ? status.status : status.origin}
          </SpecialOrder>
        );
      }
      const findMatchProduct = getMatchProductForOption(optionValue);
      const status = get(findMatchProduct, 'inventory.status', {});
      if (status.waitingOnStock) {
        return (
          <ProductStock className="waiting-on-stock">
            {canSeeInventory ? status.status : status.origin}
          </ProductStock>
        );
      }
      if (status.inStock || !status.specialOrderString) {
        return (
          <ProductStock>
            {canSeeInventory ? status.status : status.origin}
          </ProductStock>
        );
      }
      return (
        <SpecialOrder className="special-order">
          {canSeeInventory ? status.status : status.origin}
        </SpecialOrder>
      );
    }

    const getProductPricing = (optionValue: IOptionValue, oumString: string, isOum2 = false) => {
      if (optionValue.optionCode === currentCode) {
        if (isOum2) {
          return currentProductVariant.prices.length > 0 ? `$${numberFormat(currentPrice[deliveryType] * currentProductVariant.units.uom2Conversion)}/${oumString}` : t('product_detail.request_pricing');
        }
        return currentProductVariant.prices.length ? `$${numberFormat(currentProductVariant.prices.sort((a, b) => a.qty - b.qty)[0][deliveryType])}/${oumString}` : t('product_detail.request_pricing');
      }
      const findMatchProduct = getMatchProductForOption(optionValue);
      if (findMatchProduct) {
        const pricing = findMatchProduct.prices.length ? findMatchProduct.prices.sort((a, b) => a.qty - b.qty)[0] : null;
        if (isOum2 && pricing) {
          return findMatchProduct.prices.length > 0 ? `$${numberFormat(pricing[deliveryType] * findMatchProduct.units.uom2Conversion)}/${oumString}` : t('product_detail.request_pricing');
        }
        return pricing ? `$${numberFormat(pricing[deliveryType])}/${oumString}` : t('product_detail.request_pricing');
      }
      return '';
    };

    const handleChange = useCallback((optionValue: IOptionValue) => {
      onAttributeClick(optionValue.optionCode, optionValue.code)
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'change_option',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
    }, [onAttributeClick, user, branch, erpCustomerAddressCode, selectedAddressId]);

    /**
     * Render swatch item inner
     */
    const renderSwatchItemInner = (optionValue: IOptionValue) => {
      let findMatchProduct = getMatchProductForOption(optionValue);
      if (!findMatchProduct) {
        findMatchProduct = currentProductVariant;
      }
      if (option.isSpecify) {
        return (
          <SwatchItemInnerWithPrice
            className={getOptionClass(optionValue)}
            onClick={() => handleChange(optionValue)}
          >
            <div className="img-product">
              {optionValue.image ? <img src={optionValue.image.url} alt={optionValue.code} /> : null}
            </div>

            <div className="box-detail">
              <div title={optionValue.value as string} className="txt-title">{optionValue.value}</div>

              {getOptionClass(optionValue) === 'disabled' ? (
                <div className="img-default-product">
                  <img src={midDefaultProduct} alt="Default product"/>
                </div>
              ) : (
                <>
                  {
                    canSeePrice && (
                      <div className="txt-price">
                        <div className="lg">
                          {!isHidePrice && `${getProductPricing(optionValue, get(findMatchProduct, 'units.perCode'))}`}
                        </div>

                        {findMatchProduct?.prices?.length > 0 && get(findMatchProduct, 'units.uom2Name') && (
                          <div className="md">
                            {!isHidePrice && getProductPricing(optionValue, get(findMatchProduct, 'units.uom2Code'), true)}
                          </div>
                        )}
                      </div>
                    )
                  }

                  <div style={{ marginTop: 15 }}>
                    {
                      findMatchProduct?.newInventory?.onHand > 0 && !findMatchProduct.inventory.isStocked ? (
                        <StyledAvailableAndLeadTime>
                          {t('products_and_search.available_and_lead_time', {
                            onHand: findMatchProduct.newInventory.onHand,
                            leadTime: findMatchProduct.newInventory.leadTime,
                          }).toString()}
                        </StyledAvailableAndLeadTime>
                      ) : renderProductInventoryStatus(optionValue)
                    }
                  </div>
                </>
              )}
            </div>

          </SwatchItemInnerWithPrice>
        )
      }

      return (
        <SwatchItemInner
          className={getOptionClass(optionValue)}
          onClick={() => handleChange(optionValue)}
        >
          <div className="img-product">
            {optionValue.image ? <img src={optionValue.image.url} alt={optionValue.code} /> : null}
          </div>

          <div title={optionValue.value as string} className="txt-title">
            <span>{optionValue.value}
            </span>
          </div>
        </SwatchItemInner>
      )
    }

    /**
     * Render disabled swatch tooltip
     */
    const renderDisabledItemTooltip = useMemo(() => {
      return (
        <DisabledItemTooltip>
          <div className="md-1">{t('product_detail.conflict_with').toString()}</div>
          <div className="md-2">{t('product_detail.click_to_see_options').toString()}</div>
        </DisabledItemTooltip>
      );
    }, [t]);

    /**
     * Render swatch item
     */
    const renderSwatchItem = (optionValue: IOptionValue) => {
      return (
        <SwatchItem key={optionValue.optionCode}>
          {getOptionClass(optionValue) === 'disabled' ? (
            <Tooltip title={renderDisabledItemTooltip} placement="top" arrow>
              {renderSwatchItemInner(optionValue)}
            </Tooltip>
          ) : (
            renderSwatchItemInner(optionValue)
          )}
        </SwatchItem>
      )
    }

    return optionValues.length <= 10 ? (
      <SwatchWrapper className="<=10">
        {optionValues.map(renderSwatchItem)}
      </SwatchWrapper>
    ) : (
      <SwatchWrapperScroll className=">10">
        <SwatchWrapperScrollRow>
          {optionValues.filter((_, i) => i % 2 === 0).map(renderSwatchItem)}
        </SwatchWrapperScrollRow>
        <SwatchWrapperScrollRow>
          {optionValues.filter((_, i) => i % 2 !== 0).map(renderSwatchItem)}
        </SwatchWrapperScrollRow>
      </SwatchWrapperScroll>
    ) ;
  }
);

export { SwatchTypeV2 };
