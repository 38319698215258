import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

const ProductCardGrid = styled(Grid)`
  height: max-content;
  padding: 14px 2px;
  flex-wrap: nowrap;
`;

const InputButtonsWrapper = styled(Grid)`
  margin-right: 40px;
  @media (max-width: 1023px) {
    margin-right: 20px;
  }
`;

const ButtonsWrapper = styled(Grid)`
  min-width: 152px;
`;

const ProductAvatarWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-right: 60px;
`;

const ProductAvatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 4px;
  margin-right: 12px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
`;

const AddButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
`;

const AddButtonContentWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Total = styled.p`
  margin-bottom: 5px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.01em;
`;

const AddButton = styled.button`
  width: 100%;
  cursor: pointer;
  border: none;
  padding: 8px 13px;
  background: ${({ theme: { colors } }) => colors.mainGreen};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.mainGreenHover};
  }

  &:active {
    background-color: ${({ theme: { colors } }) => colors.mainGreefPassed};
  }
`;

const AddButtonTitle = styled.p`
  cursor: pointer;
  margin: 0 0 0 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.white};

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.mainGreenHover};
  }

  &:active {
    background-color: ${({ theme: { colors } }) => colors.mainGreefPassed};
  }
`;

const SingleCardWrapper = styled.div`
  margin-left: 24px;
  border-top: 1px solid ${({ theme: { colors } }) => colors.filterBorderGrey};
  flex: 1;
  width: calc(100% - 24px);

  &:first-child {
    border: none;
  }

  &:last-child {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.filterBorderGrey};
  }

  &:hover {
    position: relative;
    border-top: none;
    box-shadow: 0px 0px 15px ${({ theme: { colors } }) => colors.filterBorderGrey};
    border-radius: 6px;
    left: -10px;
    width: calc(100% - 4px);
    padding: 0 10px;

    & + div {
      border-top: none;
    }
  }
`;

const RequestPricingWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RequestPricingButton = styled.button`
  display: flex;
  justify-content: center;
  width: 100%;
  background: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  padding: 7px 12px;
  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme: { colors } }) => colors.mainGreenHover};
  }
`;

const RequestPricing = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  margin: 0;
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductName = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  cursor: pointer;
  margin: 0;
  @media (max-width: 1023px) {
    -webkit-line-clamp: initial;
    -webkit-box-orient: initial;
  }
  &:hover {
    color: ${({ theme: { colors } }) => colors.mainGreen};
  }
`;

const ProductNumber = styled.div`
  margin: 4px 0 6px 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.mainGreen};
`;

const Price = styled.p`
  margin: 0;
  font-family: Open Sans;
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  line-height: 23px;
  letter-spacing: 0.01em;
  text-align: bottom;
`;

const Each = styled.p`
  margin: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme: { colors } }) => colors.letterGrey};
  text-align: bottom;
  margin-left: 3px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.black};
  cursor: pointer;
`;

const StyledPricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const StyledSecondPriceText = styled.span`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme: { colors } }) => colors.textColor};
`;

const StyledFirstPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: -2px;
`;

const InquireNowButtonTitle = styled.p`
  margin: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #007A33;
`;

const InquireNowButton = styled.button`
  margin-top: 10px;  
  cursor: pointer;
  padding: 7px 12px;
  background-color: #FFFFFF;
  border: 1px solid #007A33;  
  border-radius: 4px;
  display: flex;
  width: 100%;
  justify-content: center;
  outline: none;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.mainGreenHover};
    color: #ffffff;  
  }
  
  &:hover ${InquireNowButtonTitle} {
    color: #ffffff;
  }  

  &:active {
    background-color: ${({ theme: { colors } }) => colors.mainGreefPassed};
    color: #ffffff;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

export {
  ProductCardGrid,
  InputButtonsWrapper,
  ButtonsWrapper,
  ProductNumber,
  ProductInfo,
  Price,
  ProductAvatar,
  ProductAvatarWrapper,
  ProductName,
  RequestPricing,
  RequestPricingButton,
  RequestPricingWrapper,
  AddButtonContentWrapper,
  AddButtonWrapper,
  StyledLink,
  SingleCardWrapper,
  Each,
  AddButton,
  AddButtonTitle,
  Total,
  StyledPricesContainer,
  StyledSecondPriceText,
  StyledFirstPriceWrapper,
  InquireNowButton,
  InquireNowButtonTitle,
};
