import { ClickAwayListener, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCart, selectCartItemsNoPrice } from 'ducks/cart/selectors';
import { useTranslation } from 'react-i18next';
import { checkCartItemsNoPriceAction, requestDeleteProductFromCart, storeCartItemsNoPrice } from 'ducks/cart/actions';
import defaultProduct from 'assets/images/default-product.png';
import { RemoveIcon } from 'assets/svg/Checkout/Remove';
import { selectShippingMethod } from 'ducks/checkout/selectors';
import {
  Container,
  ModalCard,
  ModalCardContent,
  ModalSubTitle,
  ModalTitle, ModalWindow,
} from '../OrdersModal/styles';
import { ButtonStyled, ImageStyled, Remove } from './styles';

const ItemsNoPriceModal = ({isShowPopup}) => {
  const [showPopup, setShowPopup] = useState(isShowPopup);
  const cartItemsNoPrice = useSelector(selectCartItemsNoPrice);
  const shippingMethod = useSelector(selectShippingMethod);
  const cart = useSelector(selectCart);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const closePopup = () => {
    dispatch(storeCartItemsNoPrice([]));
    setShowPopup(false);
  }
  const removeItem = (cartItemId: number) => {
    dispatch(requestDeleteProductFromCart(cartItemId));
    dispatch(checkCartItemsNoPriceAction(cart.id, shippingMethod));
  }

  return (
    <ModalWindow open={showPopup}>
      <ClickAwayListener onClickAway={closePopup}>
        <ModalCard variant="outlined" tabIndex={NaN}>
          <ModalTitle>{t('checkout.remove_items_no_price')}</ModalTitle>
          <ModalSubTitle />
          <ModalCardContent>
            {
              cartItemsNoPrice.map(item => (
                <Grid key={item.id} container spacing={3}>
                  <Grid item xs><ImageStyled src={item.image || defaultProduct} alt='product' /></Grid>
                  <Grid item xs={6}><b>{item.name}</b></Grid>
                  <Grid item xs>
                    SKU# <b>{item.sku}</b>
                    <Remove onClick={() => removeItem(item.id)}>
                      <RemoveIcon />
                      <span>{t('checkout.remove')}</span>
                    </Remove>
                  </Grid>
                </Grid>
              ))
            }
          </ModalCardContent>
          <Container>
            <ButtonStyled onClick={closePopup}>
              <span>{t('checkout.close_modal')}</span>
            </ButtonStyled>
          </Container>
        </ModalCard>
      </ClickAwayListener>
    </ModalWindow>
  )
}

export default ItemsNoPriceModal;
