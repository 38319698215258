import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme: { colors } }) => colors.white};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActivityIndicator = () => (
  <LoaderWrapper>
    <CircularProgress />
  </LoaderWrapper>
);

export default ActivityIndicator;
