import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BreadCrumbs } from 'components/common';
import { useSelector } from 'react-redux';
import {
  selectAddressId,
  selectErpCustomerAddressCode,
  selectLoadingSpecialPage,
  selectSpecialPages,
} from 'ducks/application/selectors';
import fileDownload from 'js-file-download';
import axiosRequest from 'axios';
import { useHistory } from 'react-router-dom';
import Routes from 'routes';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import TagManager from 'react-gtm-module';
import { selectBranch } from 'ducks/branch/selectors';
import { selectUserData } from 'ducks/user/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { Download, ExternalLink } from 'assets/svg/SpecialPage/SpecialPage';
import { SpecialPageWrapper, SpecialLinks } from './styles';

const Specials = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const specialPages = useSelector(selectSpecialPages);
  const loadingSpecialPage = useSelector(selectLoadingSpecialPage);
  const branch = useSelector(selectBranch);
  const user = useSelector(selectUserData);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const handleDownload = (url: string, filename: string) => {
    axiosRequest
      .get(url, {
        responseType: 'blob',
      })
      .then(res => {
        fileDownload(res.data, filename);
      });
  };

  const handleClickOnSpecial = () => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_specials',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
  };

  useEffect(() => {
    if (!loadingSpecialPage && specialPages.length === 0) {
      history.replace(Routes.NOT_FOUND);
    }
  }, [specialPages, loadingSpecialPage, history]);

  return (
    <SpecialPageWrapper>
      <BreadCrumbs currentPageName={t('header.taiga_specials')} />

      <div className="content">
        <div className="title-page-specials">{t('header.taiga_specials').toString()}</div>

        <SpecialLinks>
          {Array.isArray(specialPages) && specialPages.length
            ? specialPages.map(link => {
              if (link.type === 'upload') {
                return (
                  <a
                    key={link.name}
                    className="item-special download"
                    href={link.url}
                    target="_blank"
                    rel="noreferrer"
                    onClick={e => {
                      if (link.type === 'upload') {
                        e.preventDefault();
                        handleDownload(link.url, `${link.name}.${link.extension}`);
                      }
                      handleClickOnSpecial();
                    }}
                  >
                    {link.name}
                    <Download />
                  </a>
                );
              }

              return (
                <a
                  key={link.name}
                  className="item-special external-link"
                  href={link.url}
                  target="_blank"
                  rel="noreferrer"
                  onClick={handleClickOnSpecial}
                >
                  {link.name}
                  <ExternalLink />
                </a>
              );
            })
            : null}
        </SpecialLinks>
      </div>
    </SpecialPageWrapper>
  );
};

export default Specials;
