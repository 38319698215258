import { OrderClass } from 'models/classes/orderClass';
import { IPrice } from './productVariant';
import {IShippingAddress} from './shippingAddress';
import {ICartItem} from './cart';
import {ProductVariantClass} from '../classes/productVariantClass';

export interface IOrder {
  poNumber?: string;
  tag?: string;
  orderNumber?: number;
  address_id?: number;
  deliveryType?: string;
  promoCode?: string;
  note?: string;
  dateRequired?: string;
  state?: string;
  createdAt?: string;
  total?: number;
  adjustments?: IAdjustments[];
  items?: IOrderItem[];
  shippingAddress?: IShippingAddress;
  branchName?: string;
  itemsTotal?: number;
  taxTotal?: number
  goodsTotal?: number;
  branchId?: number;
  optInOrder?: boolean;
}

export interface UpdateOrderDto {
  cartId: number;
  updatedOrder: IOrder;
}

export interface SubmitOrderDto {
  orderId: string;
  updatedOrder: IOrder;
}

export interface IChangeCartDeliveryTypeDto {
	cartId: number;
	deliveryType: string;
}

export enum ShippingMethod {
  DELIVERY = 'delivery',
  PICKUP = 'pickup',
}

interface IImage {
  id: number;
  type: string;
  path: string;
  url: string;
}

interface IProduct {
  id: number;
  code: string;
  images: IImage[];
  prices: IPrice[];
  erpSku: string;
  erpProductId: number;
}

interface IUnits {
  perCode: string;
  perName: string;
  uom2Name: string;
  uom2Conversion: string;
}

export interface IAddressPrice {
  qty: number;
  deliveryValue: number;
  pickupValue: number;
}

interface IMainTaxonSummary {
  id: number;
  name: string;
  slug: string;
}

export interface IVariant {
  id: number;
  code: string;
  taxCategory: string;
  product: IProduct;
  images: IImage[];
  name: string;
  description: string;
  units: IUnits;
  addressPrices: IAddressPrice[];
  mainTaxonSummary: IMainTaxonSummary;
  isOfflineProductVariant: boolean;
	erpSku: string;
}
export interface IOrderItem {
  id: number;
  quantity: number;
  unitPrice: number;
  total: number;
  adjustmentsTotal: number;
  variant: ProductVariantClass;
  taxTotal: number;
  productName: string;
  productCode: string;
  erpTotalSellPrice: number;
  totalSellPrice: number;
  uomSellPrice: number;
  erpSellUom: string;
  erpQuantityUom: string;
  quantityUom: string;
  unitSellPrice: number;
  inventoryStatus: string;
  inStock: boolean;
	canReorder?: boolean;
	pricingMethod: string;
	units: IUnits;
	prices: IPrice[];
  productImage: string;
  product: IProduct;
  categoryName: string;
  parentCategoryName: string;
  cartItem: ICartItem;
  productDescription: string;
  sellUom: string;
}

export interface IOrders {
  data: OrderClass[];
  nextPage: boolean;
}

export interface IRequestData {
  input?: string;
  status?: string;
  address?: string;
  language?: 'en_US' | 'en_CA' | 'fr_CA';
  contact?: string;
  from?: string;
  to?: string;
  dateRange?: string;
  sort?: string;
  page?: number;
}

export interface IAdjustments {
  id: number;
  label: string;
  type: string;
  amount: number;
}
