import styled from 'styled-components/macro';
import { Grid } from '@material-ui/core';
import Slider from 'nuka-carousel';

const StyledGrid = styled(Grid)`
  margin-top: 40px;

  @media (max-width: 1023px) {
    margin-top: 0;
    margin-bottom: 20px;
  }
`;

const StyledGridItem = styled(Grid)`
  height: 392px;
  @media (max-width: 1023px) {
    height: calc(100vw / 25 * 8);
  }
  &.length-1 {
    overflow: hidden;
  }
`;

const StyledSlider = styled(Slider)`
  .paging-item button:focus {
    outline: none;
  }

  .video {
    position: relative;
    padding-bottom: 32.75%;
    height: 0;
    overflow: hidden;
  }

  .video iframe,
  .video object,
  .video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const SliderImg = styled.img`
  width: 100%;
  height: 100%;
`;

interface IStyledA {
  isUrl: boolean;
}

const StyledA = styled.a`
  text-decoration: none;
  cursor: ${(props: IStyledA) => (props.isUrl ? 'pointer' : 'default')};
`;
export { StyledGrid, StyledGridItem, StyledSlider, StyledA, SliderImg };
