import { SnackbarType, UIActionTypes } from './types';

export interface SnackbarPayload {
  type: SnackbarType;
  message: string;
}

export interface SnackbarAction {
  type: UIActionTypes;
  payload?: SnackbarPayload;
}

export const toggleSnackbarOpen = (type: SnackbarType, message: string): SnackbarAction => ({
  type: UIActionTypes.TOGGLE_SNACKBAR_OPEN,
  payload: {
    type,
    message,
  },
});

export const toggleSnackbarClose = (): SnackbarAction => ({
  type: UIActionTypes.TOGGLE_SNACKBAR_CLOSE,
});
