import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  menuIsOpenedSelector,
  selectAddressId, selectErpCustomerAddressCode,
  selectIsHidePrice
} from 'ducks/application/selectors';
import { selectSalesRepresentative } from 'ducks/salesRepresentative/selectors';
import { menuIsOpened, menuIsClosed, setIsHidePrice } from 'ducks/application/actions';
import { Grid, IconButton, Link, FormControlLabel } from '@material-ui/core';
import {Link as RouterLink, useHistory, useLocation} from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import SmallCheckout from 'components/Checkout/SmallCheckout/SmallCheckout';
import TagManager from 'react-gtm-module';
import MobileMenuBlock from 'components/Layout/MobileMenuBlock/MobileMenuBlock';
import Routes from 'routes';
import {
  MobileLogo,
  MobilePhoneIcon,
  MobileAccountIcon,
  MobileCartIcon,
  LogoutIcon,
  MiddleLogoMobileTestContainer,
  MiddleLogoTest, OrderHistory, Bars,
} from 'assets/svg/MiddleMenu/MiddleMenu';
import SearchComponent from 'components/Layout/Search/SearchComponent';
import { useSetPrices } from 'hooks/useSetPrices';
import { logout } from 'ducks/auth/actions';
import { requestCartSummary } from 'ducks/cart/actions';

import { selectBranch } from 'ducks/branch/selectors';
import { branchCode } from 'themeExtended';
import { usePermission } from 'hooks/usePermission';
import {useTranslation} from 'react-i18next';
import {useGA4CartEvent} from 'GA/CartGa4/cartGA4';
import {selectCart, selectCartItemsQuantity} from 'ducks/cart/selectors';
import get from 'lodash/get';
import {clearGtmEcommerce} from 'utils/clearGtmEcommerce';
import {getUserType} from 'utils/profile/profileUtilsHelpers';
import { useMutation } from 'react-query';
import { TogglePrices } from 'api/User';
import { numberFormat } from 'utils/products/product';
import PricingDropdown from '../Dropdowns/PricingDropdown/PricingDropdown';
import { IOSSwitch } from '../Dropdowns/PricingDropdown/styles';
import {
  StyledAppBar,
  StyledLink,
  StyledToolbar,
  StyledIconButton,
  StyledDiv,
  StyledLanguageDropdown,
  LogoutButton,
  WhiteCloseIcon,
  GridPriceWrapper
} from './styles';
import { GlobalBannerNotification } from '../../Notification/GlobalBannerNotification';

const MobileHeader: React.FC = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isOpened = useSelector(menuIsOpenedSelector);
  const salesRepresentative = useSelector(selectSalesRepresentative);
  const selectedItemsQuantity = useSelector(selectCartItemsQuantity);
  const cart = useSelector(selectCart);
  const location = useLocation();
  const { user } = useSetPrices();
  const branch = useSelector(selectBranch);
  const defaultAddressId = useSelector(selectAddressId);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const isHidePrice = useSelector(selectIsHidePrice);
  const { canSeePrice, canPlaceOrder, canPlaceQuote, canSeeOrderHistory } = usePermission();

  const handleClick = () => {
    if (isOpened) {
      dispatch(menuIsClosed());
    } else {
      dispatch(menuIsOpened());
    }
  };

  const handleLogoutClick = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (user && defaultAddressId) {
      dispatch(requestCartSummary(defaultAddressId));
    }
    if (user) {
      dispatch(setIsHidePrice(!user.showPrice));
    }
  }, [dispatch, user, defaultAddressId]);

  const togglePriceMutation = useMutation(TogglePrices, {
    onSuccess: () => {
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: isHidePrice ? 'click_prices_on' : 'click_prices_off',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
      setTimeout(() => {
        dispatch(setIsHidePrice(!isHidePrice));
      });
    }
  })

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const {handleFireGA4ViewCart} = useGA4CartEvent();
  const handleCheckoutClick = () => {
    if (history.location.pathname === Routes.CHECKOUT) {
      return;
    }
    handleFireGA4ViewCart();
    history.push(Routes.CHECKOUT);
  };

  const handleCheckoutClose = () => {
    setAnchorEl(null);
  };

  const isCheckoutOpen = !!anchorEl;

  return (
    <>
      <Grid container direction="column" className="mobile-header-wrapper">
        <Grid container item direction="row" justify="space-between" alignItems="center" className="grid-logo-wrapper">
          <StyledLink onClick={() => dispatch(menuIsClosed())} aria-label="home" to={Routes.HOME}>
            {branch?.code === branchCode ? (
              <MiddleLogoMobileTestContainer>
                <MiddleLogoTest />
              </MiddleLogoMobileTestContainer>
            ) : (
              <MobileLogo />
            )}
          </StyledLink>
          <StyledLanguageDropdown />
        </Grid>

        <Grid container item direction="row" className="grid-right-wrapper">
          <StyledAppBar position="static">
            <StyledToolbar fullWidthGreen={!!isOpened}>
              <StyledIconButton type="button" onClick={handleClick} color="inherit" aria-label="menu" className="btn-menu">
                {isOpened ? <CloseIcon /> : <Bars />}
              </StyledIconButton>

              <StyledDiv className="box-right">
                <IconButton aria-label="mobile phone" className="right-button item-phone">
                  <a
                    aria-label="mobile phone"
                    href={salesRepresentative ? `tel:${salesRepresentative?.workPhone}` : null}
                  >
                    <MobilePhoneIcon />
                  </a>
                </IconButton>

                <IconButton aria-label="profile" className="item-profile">
                  {location.pathname !== Routes.PROFILE ? (
                    <Link
                      aria-label="profile"
                      component={RouterLink}
                      to={Routes.PROFILE}
                      color="inherit"
                      underline="none"
                      variant="body2"
                      onClick={() => dispatch(menuIsClosed())}
                    >
                      <MobileAccountIcon />
                    </Link>
                  ) : (
                    <LogoutButton
                      onClick={() => {
                        dispatch(menuIsClosed());
                        handleLogoutClick();
                      }}
                    >
                      <LogoutIcon />
                    </LogoutButton>
                  )}
                </IconButton>

                {canSeeOrderHistory && (
                  <IconButton style={{ color: '#fff' }} className="item-order-history">
                    <Link
                      aria-label="profile"
                      component={RouterLink}
                      onClick={() => {
                        dispatch(menuIsClosed());
                        clearGtmEcommerce()
                        TagManager.dataLayer({
                          dataLayer: {
                            event: 'click_order_history',
                            user_type: getUserType(user.email),
                            branch_id: branch?.id,
                            customer_code: user?.customer?.erpCustomerCode,
                            address_code: erpCustomerAddressCode,
                            address_id: selectedAddressId,
                          }
                        });
                      }}
                      to={{
                        pathname: Routes.PROFILE,
                        state: {
                          tab: 'profile.orders'
                        }
                      }}
                      color="inherit"
                      underline="none"
                      variant="body2"
                    >
                      <OrderHistory />
                    </Link>
                  </IconButton>
                )}

                {(canPlaceQuote || canPlaceOrder) && (
                  <IconButton
                    aria-label="cart"
                    className="truck-item"
                    onClick={() => {
                      dispatch(menuIsClosed());
                      handleCheckoutClick();
                    }}
                  >
                    <div className="ic-cart">
                      {isCheckoutOpen ? <WhiteCloseIcon /> : <MobileCartIcon />}
                      <span className="product-number">{selectedItemsQuantity || 0}</span>
                    </div>
                    {
                      !isHidePrice && (
                        <div className="total">
                          <div className="total-label">{t('checkout.subtotal').toString()}</div>
                          <div className="total-number">${numberFormat(get(cart, 'subtotal', 0))}</div>
                        </div>
                      )
                    }
                  </IconButton>
                )}

                <SmallCheckout anchorEl={anchorEl} handleClose={handleCheckoutClose} />
              </StyledDiv>
            </StyledToolbar>
          </StyledAppBar>
        </Grid>

        <GridPriceWrapper container item direction="row" className="grid-price-wrapper">
          <FormControlLabel
            style={{marginLeft: !canSeePrice ? 'auto' : '0'}}
            control={<IOSSwitch />} label={t('header.prices').toString()} className="prices-switch" checked={!isHidePrice} onChange={() => {
            togglePriceMutation.mutate(isHidePrice);
          }} />
          {
            canSeePrice && (
              <PricingDropdown />
            )
          }
        </GridPriceWrapper>

        <Grid container item direction="row" className="grid-search-wrapper">
          {isOpened ? <MobileMenuBlock /> : <SearchComponent />}
        </Grid>
      </Grid>
      <GlobalBannerNotification />
    </>
  );
};

export default MobileHeader;
