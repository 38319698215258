import { useInfiniteQuery } from 'react-query';
import { AxiosError } from 'axios';

import axios from 'utils/axios';
import { IRequestData } from 'models/interfaces/order';
import { IUniversalOrdersDataResponse } from './Order';

export const Quotes = 'quotes';

function useProfileQuotes(params?: IRequestData) {
  return useInfiniteQuery<IUniversalOrdersDataResponse, AxiosError>(
    [Quotes, params],
    async ({ pageParam = 1 }) => {
      const { data } = await axios.get('/api/auth/order', {
        params: {
          ...params,
          page: pageParam,
          orderType: 'quote',
        },
        headers: {
          accept: 'application/ld+json',
        },
      });
      return data.data;
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.currentPage < lastPage.lastPage) {
          return lastPage.currentPage + 1;
        }
        return undefined;

      },
      staleTime: 10000,
      cacheTime: 2000,
    }
  );
}

export { useProfileQuotes };
