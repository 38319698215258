// Types
import { SalesRepresentativeActionTypes } from 'ducks/salesRepresentative/types';

// Classes
import { SalesRepresentativeClass } from 'models/classes/salesRepresentative';

export const fetchSalesRepresentative = () => ({
  type: SalesRepresentativeActionTypes.REQUEST_SALES_REPRESENTATIVE,
});

export const storeSalesRepresentative = (salesRepresentative: SalesRepresentativeClass) => ({
  type: SalesRepresentativeActionTypes.REQUEST_SALES_REPRESENTATIVE_SUCCESS,
  payload: {
    salesRepresentative,
  },
});
