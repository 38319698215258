import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Order } from 'ducks/checkout/types';
import { changeField } from 'ducks/checkout/actions';
import { selectOrder } from 'ducks/checkout/selectors';

import { RadioGroup } from '@material-ui/core';
import { StyledRadio } from 'components';

import { RadioControl } from 'pages/Checkout/styles';
import {usePermission} from 'hooks/usePermission';

const TransactionType: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { canPlaceOrder, canPlaceQuote } = usePermission();

  const order = useSelector(selectOrder);
  const isQuotes = order === 'quote';

  const handleChangeOrderState = (orderState: Order) => {
    dispatch(changeField('order', orderState));
  };

  useEffect(() => {
    if (!canPlaceOrder) {
      dispatch(changeField('order', 'quote'));
    }
    if (!canPlaceQuote) {
      dispatch(changeField('order', 'order'));
    }
  }, [canPlaceOrder, canPlaceQuote]);

  return (
    <RadioGroup aria-label="orders-quotes" name="orders-quotes">
      {
        canPlaceOrder && <RadioControl
          value="order"
          control={<StyledRadio />}
          label={t('checkout.submit_order').toString()}
          checked={!isQuotes}
          onChange={() => {
            handleChangeOrderState('order');
          }}
        />
      }
      {
        canPlaceQuote && <RadioControl
          value="quote"
          control={<StyledRadio />}
          label={t('checkout.submit_quote').toString()}
          checked={isQuotes}
          onChange={() => {
            handleChangeOrderState('quote');
          }}
        />
      }
    </RadioGroup>
  );
};

export default TransactionType;
