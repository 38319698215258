import React, { useEffect, useState } from 'react';

import YouTube from '@u-wave/react-youtube';
import TagManager from 'react-gtm-module';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { useSelector } from 'react-redux';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { selectAddressId, selectErpCustomerAddressCode } from 'ducks/application/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';

interface IVideoProps {
  // eslint-disable-next-line react/no-unused-prop-types
  name?: string;
  url: string;
  width: number | string;
  height: number | string;
  controls?: boolean;
  stopPlaying?: boolean;
}

const Video: React.FC<IVideoProps> = ({
  url,
  width,
  height,
  controls = true,
  stopPlaying = false,
}: IVideoProps) => {
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const [paused, setPaused] = useState<boolean>(false);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);

  const handlePlayerPause = () => setPaused(true);
  const handlePlayerPlay = () => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_play_youtube',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
    setPaused(false);
  };

  useEffect(() => {
    handlePlayerPause();
  }, [stopPlaying]);

  return (
    <YouTube
      paused={paused}
      onPause={handlePlayerPause}
      onPlaying={handlePlayerPlay}
      video={url}
      width={width}
      height={height}
      controls={controls}
    />
  );
};

export default Video;
