import { Reducer } from 'redux';

// Types
import { ProfileActionTypes, ProfileState } from 'ducks/profile/types';

const initialState: ProfileState = {
  loading: false,
  error: null,
  storeContactProfile: null,
  changePasswordStatus: null,
  changePasswordErrors: null
};

const profileReducer: Reducer<ProfileState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case ProfileActionTypes.REQUEST_STORE_CONTACT_PROFILE: {
      return { ...state, loading: true, error: null };
    }
    case ProfileActionTypes.REQUEST_STORE_CONTACT_PROFILE_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false,
        storeContactProfile: payload.storeContactProfile,
      };
    }
    case ProfileActionTypes.REQUEST_STORE_CONTACT_PROFILE_ERROR: {
      return { ...state, error: payload.error, loading: false };
    }
    case ProfileActionTypes.REQUEST_CHANGE_PASSWORD_SUCCESS: {
      return { ...state, changePasswordStatus: payload.status };
    }
    case ProfileActionTypes.REQUEST_CHANGE_PASSWORD_ERROR: {
      return { ...state, changePasswordErrors: payload.data.errors };
    }
    case ProfileActionTypes.REFRESH_PASSWORD_STATE: {
      return { ...state, changePasswordStatus: payload.error };
    }
    case ProfileActionTypes.RESET_ERROR: {
      return { ...state, changePasswordErrors: null };
    }
    default:
      return state;
  }
};

export default profileReducer;
