import styled from 'styled-components';
import { Typography } from '@material-ui/core';

interface Props {
  display: string;
}
const MainContainer = styled.div<Props>`
  margin-left: 40px;
  margin-bottom: 40px;

  @media (min-width: 600px) and (max-width: 1280px) {
    display: flex;
    margin-top: 13px;
    margin-left: 0;
  }
  @media (max-width: 600px) {
    margin-left: 10px;
  }
`;

const TextSubheaderTypography = styled(Typography)`
  font-family: Open Sans;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.small};
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 23px;
  color: ${({ theme: { colors } }) => colors.grey};

  @media (min-width: 600px) and (max-width: 1280px) {
    margin-top: 5px;
    margin-bottom: 0;
  }
  @media (max-width: 600px) {
    margin-bottom: 13px;
  }
`;

const StyledContactsLinkContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (min-width: 600px) and (max-width: 1280px) {
    margin-top: 0;
  }
`;

const StyledContactsBlock = styled.div`
  margin-right: 0;
  @media (min-width: 600px) and (max-width: 1280px) {
    margin-right: 50px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column-reverse;
  }
`;

export { MainContainer, TextSubheaderTypography, StyledContactsLinkContainer, StyledContactsBlock };
