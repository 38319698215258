import { ISalesPhoto } from 'models/interfaces/salesRepresentative';

export class SalesRepresentativeClass {
  code: string = null;

  name: string = null;

  surname: string = null;

  image: ISalesPhoto = null;

  workPhone: string = null;

  mobilePhone: string = null;

  email: string = null;

  constructor(...args) {
    args.forEach((item: SalesRepresentativeClass) => {
      this.surname = item.surname || null;
      this.name = item.name || null;
      this.code = item.code || null;
      this.image = item.image || null;
      this.workPhone = item.workPhone || null;
      this.mobilePhone = item.mobilePhone || null;
      this.email = item.email || null;
    });
  }
}
