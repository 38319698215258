import { all, call, put, takeEvery } from 'redux-saga/effects';
import axios from 'utils/axios';
import { ContactActionTypes } from 'ducks/contact/types';
import { storeError } from 'utils/errors';

function* fetchContacts() {
  try {
    const contacts = yield call(axios.get, '/new-api/contacts/related');
    yield put({ type: ContactActionTypes.REQUEST_FETCH_CONTACTS_SUCCESS, payload: contacts.data });
  } catch (error) {
    yield storeError(ContactActionTypes.REQUEST_FETCH_CONTACTS_ERROR, [401], error.response);
  }
}

function* watchFetchContacts() {
  yield takeEvery(ContactActionTypes.REQUEST_FETCH_CONTACTS, fetchContacts);
}

export default function* userSaga() {
  yield all([watchFetchContacts()]);
}
