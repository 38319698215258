// Types
import { SearchActionTypes } from 'ducks/search/types';

// Classes
import { ProductVariantClass } from 'models/classes/productVariantClass';
import { FacetClass } from 'models/classes/facetClass';
import { IRangeFilter } from 'models/interfaces/productVariant';

export interface IFetchFilteredProductsPayload extends Object {
  filterAttributes: object;
  'order[name]'?: string;
  'order[price]'?: string;
  page?: number;
  itemsPerPage?: number;
  taxon?: string;
  firstLoad?: boolean;
  q?: string;
  addressId: number;
  language?: 'en_US' | 'en_CA' | 'fr_CA';
  inStock: boolean;
}

export interface IFetchSearchProductsPayload {
  filterAttributes: object;
  'order[name]'?: string;
  'order[price]'?: string;
  page?: number;
  itemsPerPage?: number;
  query?: string;
  addressId: number;
  language?: 'en_US' | 'en_CA' | 'fr_CA';
  inStock: boolean;
	firstLoad: boolean;
}

export const storeFilteredProducts = (
  products: ProductVariantClass[],
  facets: FacetClass[],
  rangeFilters: IRangeFilter[],
  isLastPage: boolean,
  total: number
) => ({
  type: SearchActionTypes.REQUEST_FILTERED_PRODUCTS_SUCCESS,
  payload: {
    products,
    facets,
    rangeFilters,
    isLastPage,
    total,
  },
});

export const storeSearchProducts = (
  products: ProductVariantClass[],
  total: number,
  isLastPage: boolean
) => ({
  type: SearchActionTypes.REQUEST_SEARCH_PRODUCTS_SUCCESS,
  payload: {
    products,
    total,
    isLastPage,
  },
});

export const storeSearchProductsFirstLoad = (
  products: ProductVariantClass[],
  total: number,
  isLastPage: boolean
) => ({
  type: SearchActionTypes.REQUEST_SEARCH_PRODUCTS_FIRST_LOAD_SUCCESS,
  payload: {
    products,
    total,
    isLastPage,
  },
})

export const storeFilteredProductsFirstLoad = (
  products: ProductVariantClass[],
  facets: FacetClass[],
  rangeFilters: IRangeFilter[],
  isLastPage: boolean,
  total: number
) => ({
  type: SearchActionTypes.REQUEST_FILTERED_PRODUCTS_SUCCESS_FIRST_LOAD,
  payload: {
    products,
    facets,
    rangeFilters,
    isLastPage,
    total,
  },
});

export const storeSearchProductsFilter = (facets: FacetClass[]) => ({
  type: SearchActionTypes.REQUEST_SEARCH_PRODUCTS_FILTER_SUCCESS,
  payload: {
    facets,
  },
});

export const storeSearchProductDumpIds = (productIdsList: any) => ({
  type: SearchActionTypes.REQUEST_SEARCH_DUMP_PRODUCT_IDS_SUCCESS,
  payload: {
    productIdsList,
  },
});

export interface ISearchProductsFilterPayload {
  query: string;
  language: string;
  addressId: number;
}

export const resetProducts = () => ({
  type: SearchActionTypes.RESET_PRODUCTS,
});

export const setPaginationLoading = () => ({
  type: SearchActionTypes.SET_PAGINATION_LOADING,
});

export const removePaginationLoading = () => ({
  type: SearchActionTypes.REMOVE_PAGINATION_LOADING,
});

export const searchProducts = (payload: IFetchSearchProductsPayload) => ({
  type: SearchActionTypes.REQUEST_SEARCH_PRODUCTS,
  payload,
});

export const searchProductsFilterAction = (payload: ISearchProductsFilterPayload) => ({
  type: SearchActionTypes.REQUEST_SEARCH_PRODUCTS_FILTER,
  payload,
});

export const searchProductIds = (query: string, addressId: number, filters?: any) => ({
  type: SearchActionTypes.REQUEST_SEARCH_DUMP_PRODUCT_IDS,
  payload: {
    query,
    filters,
    addressId
  },
});

export const resetProductOnFilter = () => ({
  type: SearchActionTypes.RESET_PRODUCTS_ON_FILTER,
});
