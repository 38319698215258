import { useDispatch } from 'react-redux';
import { changeField, FieldName, Payload } from 'ducks/checkout/actions';
import { CheckoutActionTypes, CheckoutStep } from 'ducks/checkout/types';

export const useChangeStep = (): CallableFunction => {
  const dispatch = useDispatch();
  return (
    step: CheckoutStep
  ): {
    type: CheckoutActionTypes;
    fieldName: FieldName;
    payload: Payload;
  } => dispatch(changeField('step', step));
};
