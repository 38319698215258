import React from 'react';
import {useTranslation} from 'react-i18next';
import { StyledGrid, MenuContentGrid } from './styles';

const CustomerMaintainNotification = () => {
  const {t} = useTranslation();
  return (
    <StyledGrid container variant='warning'>
      <MenuContentGrid item variant='warning'>
        {t('checkout.maintain')}
      </MenuContentGrid>
    </StyledGrid>
  );
};

export default CustomerMaintainNotification;