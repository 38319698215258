import React, { useCallback, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import LargeMenuBlock from 'components/Layout/LargeMenuBlock/LargeMenuBlock';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';

import { useSelector } from 'react-redux';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import {
  selectAddressId,
  selectErpCustomerAddressCode,
  selectSpecialPages,
} from 'ducks/application/selectors';
import Routes from 'routes';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { ChevronDown } from 'assets/svg/MiddleMenu/MiddleMenu';
import clsx from 'clsx';
import { usePermission } from 'hooks/usePermission';
import {
  StyledTypography,
  StyledIconButton,
  StyledAppBar,
  StyledToolbar,
  ProductsButton,
  LowerMenuContentGrid,
  LowerMenuGrid,
  LowerMenuWrapper,
  QuickButtons,
} from './styles';
import { GlobalBannerNotification } from '../Notification/GlobalBannerNotification';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingLeft: 20,
    },
    title: {
      flexGrow: 1,
    },
  })
);

const SubMenu: React.FC = () => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [isOpened, handleOpened] = useState(false);
  const user = useSelector(selectUserData);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const branch = useSelector(selectBranch);
  const specialPages = useSelector(selectSpecialPages);
  const location = useLocation();
  const { canPlaceOrder, canPlaceQuote } = usePermission();

  const handleClick = () => {
    if (!isOpened) {
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'click_product_nav_bar',
          user_type: getUserType(user?.email),
          branch_id: branch?.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
    }
    handleOpened(!isOpened);
  };

  const handleGoToSpecialPage = useCallback(() => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'go_to_specials_page',
        user_type: getUserType(user?.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
  }, [branch, erpCustomerAddressCode, selectedAddressId, user]);

  const handleGoToQuickOrder = useCallback(() => {
    if (history.location.pathname === Routes.QUICK_ORDER) return;
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_quick_order',
        user_type: getUserType(user.email),
        branch_id: branch?.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
  }, [user, branch, erpCustomerAddressCode, selectedAddressId, history.location.pathname]);

  return (
    <>
      <LowerMenuGrid item container direction="column" className="box-lower-menu-grid">
        <LowerMenuContentGrid
          fullWidthGreen={!!isOpened}
          container
          item
          direction="row"
          alignItems="flex-end"
          className="box-lower-menu-content-grid"
        >
          <LowerMenuWrapper>
            <ProductsButton onClick={handleClick} className="btn-toggle-menu">
              <StyledAppBar position="static">
                <StyledToolbar>
                  <StyledIconButton color="inherit" aria-label="menu">
                    {isOpened ? <CloseIcon /> : <MenuIcon />}
                  </StyledIconButton>

                  <StyledTypography
                    variant="h6"
                    className={`title-products ${classes.title} ${i18n?.language}`}
                    data-test-id="products"
                  >
                    {t('header.shop_category')}
                    <ChevronDown />
                  </StyledTypography>
                </StyledToolbar>
              </StyledAppBar>
            </ProductsButton>

            <QuickButtons>
              <div className="divider" />

              {specialPages.length > 0 && (
                <RouterLink
                  to={Routes.SPECIAL}
                  className={clsx('btn-quick', {
                    active: location.pathname === Routes.SPECIAL,
                  })}
                  onClick={handleGoToSpecialPage}
                >
                  {t('header.specials').toString()}
                </RouterLink>
              )}
              {
                (canPlaceOrder || canPlaceQuote) && (
                  <RouterLink
                    to={Routes.QUICK_ORDER}
                    data-test-id="quick-order"
                    className={clsx('btn-quick', {
                      active: location.pathname === Routes.QUICK_ORDER,
                    })}
                    onClick={handleGoToQuickOrder}
                  >
                    {t('header.quick_order').toString()}
                    <span className="new">{t('header.new').toString()}</span>
                  </RouterLink>
                )
              }

              <RouterLink
                to={Routes.DOCUMENT_GROUP}
                className={clsx('btn-quick', {
                  active: location.pathname === Routes.DOCUMENT_GROUP,
                })}
              >
                {t('document_group.title').toString()}
                <span className="new">{t('header.new').toString()}</span>
              </RouterLink>
            </QuickButtons>
          </LowerMenuWrapper>
        </LowerMenuContentGrid>
      </LowerMenuGrid>
      {isOpened ? <LargeMenuBlock handleClick={handleClick} /> : ''}
      <GlobalBannerNotification />
    </>
  );
};
export default SubMenu;
