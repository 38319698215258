import { IError } from 'models/interfaces/error';

export enum AppActionTypes {
  MENU_IS_OPENED = '@@app/MENU_IS_OPENED',
  MENU_IS_CLOSED = '@@app/MENU_IS_CLOSED',

  REQUEST_BANNER = '@@app/REQUEST_BANNER',
  REQUEST_BANNER_SUCCESS = '@@app/REQUEST_BANNER_SUCCESS',
  REQUEST_BANNER_ERROR = '@@app/REQUEST_BANNER_ERROR',

  REQUEST_BACKGROUND = '@@app/REQUEST_BACKGROUND',
  REQUEST_BACKGROUND_SUCCESS = '@@app/REQUEST_BACKGROUND_SUCCESS',
  REQUEST_BACKGROUND_ERROR = '@@app/REQUEST_BACKGROUND_ERROR',

  SET_DEFAULT_BANNER = '@@app/SET_DEFAULT_BANNER',
  SET_DEFAULT_BACKGROUND = '@@app/SET_DEFAULT_BACKGROUND',

  SET_PRICES_TYPE = '@@app/SET_PRICES_TYPE',

  SET_SERVER_ERROR = '@@app/SET_SERVER_ERROR',

  SET_ADDRESS_CODE = '@@app/SET_ADDRESS_CODE',

	SET_ADDRESS_ID = '@@app/SET_ADDRESS_ID',

  REQUEST_SEND_CONTACT_US = '@@app/REQUEST_SEND_CONTACT_US',
  REQUEST_SEND_CONTACT_US_SUCCESS = '@@app/REQUEST_SEND_CONTACT_US_SUCCESS',
  REQUEST_SEND_CONTACT_US_ERROR = '@@app/REQUEST_SEND_CONTACT_US_ERROR',

  REQUEST_STORE_BANNER = '@@app/REQUEST_STORE_BANNER',
  REQUEST_STORE_BANNER_SUCCESS = '@@app/REQUEST_STORE_BANNER_SUCCESS',
  REQUEST_STORE_BANNER_ERROR = '@@app/REQUEST_STORE_BANNER_ERROR',
  SET_LANGUAGE = '@@app/SET_LANGUAGE',

	OPEN_RECAPTCHA_MODAL = '@app/OPEN_RECAPTCHA_MODAL',
	CLOSE_RECAPTCHA_MODAL = '@@app/CLOSE_RECAPTCHA_MODAL',
  REQUEST_GET_SPECIAL_PAGE = '@@app/REQUEST_GET_SPECIAL_PAGE',
  REQUEST_GET_SPECIAL_PAGE_SUCCESS = '@@app/REQUEST_GET_SPECIAL_PAGE_SUCCESS',
  REQUEST_GET_SPECIAL_PAGE_ERROR = '@@app/REQUEST_GET_SPECIAL_PAGE_ERROR',
  REQUEST_IS_HIDE_PRICE = '@@app/REQUEST_IS_HIDE_PRICE',
}

export interface SendContactUsEmailAction {
  type: AppActionTypes.REQUEST_SEND_CONTACT_US;
  payload: {
    name: string;
    email: string;
    phone?: string;
    subject: string;
    comment: string;
  };
}

enum StoreBannerType {
	warning = 'warning',
	general = 'general'
}

interface IStoreBanner {
  url?: string;
  link?: string;
	text?: string;
	type?: StoreBannerType;
}

interface ISpecialPage {
  url: string,
  isExternal: boolean;
  name: string;
  position: number;
  type: string;
  extension?: string;
}

export interface AppState {
  readonly menuIsOpened: boolean;
  readonly serverError: IError;
  readonly banner: string | null;
  readonly background: string | null;
  readonly priceType: 'delivery' | 'pickup' | 'hide';
  readonly selectedAddressCode: string | null;
  readonly selectedAddressId: number | null;
  readonly storeBanner: IStoreBanner | null;
  readonly loading: boolean;
  readonly error: boolean;
  readonly language: 'en_US' | 'en_CA' | 'fr_CA';
	readonly showRecaptchaModal: boolean;
  readonly specialPages: ISpecialPage[];
  readonly loadingSpecialPage: boolean;
  readonly isHidePrice: boolean;
}

export interface GetBannerByCodeAction {
  type: AppActionTypes.REQUEST_STORE_BANNER;
  payload: {
    code: string;
		branch: number;
  };
}
