import styled from 'styled-components/macro';
import ModalComponent from '@material-ui/core/Modal';
import { Card, CardContent } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { MediumButton } from 'components/common/Buttons/MediumButton';

const ModalWindow = styled(ModalComponent)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: calc(100vh - 100px);
  z-index: 1600 !important;
`;

const ModalCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  padding: 0;
  .MuiAccordion-root {
    overflow: hidden;
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid #dadee4;
    margin-top: 0;
    margin-bottom: 12px;
    &.Mui-expanded {
    }
  }
  .MuiAccordionSummary-root {
    position: relative;
    background: #f7f7f7;
    border: 0;
    padding: 0;
    .box-customer-name {
      display: block;
      width: 100%;
      background: #f1efea;
      padding: 16px 40px 16px 24px;
      @media only screen and (max-width: 767px) {
        padding: 16px 30px 16px 10px;
      }
      .txt-customer-name {
        font-size: 12px;
        line-height: 150%;
        color: #939599;
        margin-bottom: 8px;
      }
      .txt-branch-name {
        font-size: 14px;
        line-height: 150%;
        color: #333333;
      }
    }
    .txt-label {
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #939599;
      margin-bottom: 8px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 4px;
      }
    }
    .txt-field-value {
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #333333;
    }
    .txt-total {
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      color: #333333;
    }
    .txt-order-number {
      margin-bottom: 6px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 2px;
      }
    }
    .box-summary {
      width: 100%;
      background: #f9f6f0;
      padding: 14px 24px;
      @media only screen and (max-width: 767px) {
        padding: 14px 15px;
      }
      .row-summary {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: -5px;
        margin-right: -5px;
        margin-bottom: 8px;
      }
      .col-summary {
        flex: 0 0 auto;
        padding-left: 5px;
        padding-right: 5px;
        @media only screen and (max-width: 767px) {
          width: 50%;
          border-bottom: 1px solid #dadee4;
          padding-bottom: 8px;
          margin-bottom: 8px;
        }
      }
      .col-order-number {
        .txt-field-value {
          font-weight: 600;
          font-size: 14px;
          line-height: 150%;
          color: #202020;
        }
      }
      .col-total {
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
    .box-highlight-label {
      .label {
        display: inline-block;
        vertical-align: middle;
        font-weight: normal;
        font-size: 12px;
        line-height: 100%;
        color: #333333;
        background-image: url("data:image/svg+xml,%3Csvg width='90' height='18' viewBox='0 0 90 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 18H86C88.2 18 90 16.65 90 15V3C90 1.35 88.2 0 86 0H12C9.8 0 0 7.5 0 7.5V10.5C0 10.5 9.8 18 12 18Z' fill='%2342A071' fill-opacity='0.2'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 100% 100%;
        padding: 4px 9px 4px 16px;
      }
    }
  }
  .MuiAccordionSummary-expandIcon {
    position: absolute;
    right: 10px;
    top: 15px;
    color: #939599;
    padding: 0;
    margin: 0;
    @media only screen and (max-width: 767px) {
      right: 5px;
      top: 13px;
    }
    svg {
      width: 30px;
      height: 30px;
      @media only screen and (max-width: 767px) {
        width: 20px;
        height: 20px;
      }
    }
  }
  .MuiAccordionSummary-content {
    margin: 0 !important;
  }
  .MuiAccordionDetails-root {
    @media only screen and (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .MuiCardContent-root {
    border-top: 0;
  }
`;

const ModalCard = styled(Card)`
  position: relative;
  outline: none;
  max-height: 90%;
  max-width: 826px;
  overflow: auto;
  padding: 48px;
  overflow-y: auto;
  .MuiCardContent-root:last-child {
    padding-bottom: 0 !important;
  }
  @media (max-width: 1023px) {
    padding: 20px 10px;
    margin: 0 16px;
  }
`;

const ModalTitle = styled.div`
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0.01em;
  text-align: left;
  color: ${({ theme: { colors } }) => colors.textColor};
  margin-bottom: 20px;
  @media (max-width: 1023px) {
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
  }
`;

const ModalSubTitle = styled.div`
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme: { colors } }) => colors.black};
  margin-bottom: 24px;
  @media (max-width: 1023px) {
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
  }
`;

const StyledLink = styled(Link)`
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: center;
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  border-radius: 4px;
  padding: 11px 27px;
  margin-left: -25px;
  transition: all 0.4s ease;
  @media (max-width: 1023px) {
    margin-top: 24px;
    font-size: 14px;
  }
  &:hover {
    background: #ebebeb;
  }
`;

const ModalFromButton = withStyles({
  root: {
    height: '48px',
    width: '298px',
    borderRadius: '4px',
    fontWeight: 'normal',
    fontSize: '16px',
  },
})(MediumButton);

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  @media (max-width: 1023px) {
    flex-direction: column-reverse;
    margin-bottom: 5px;
  }
`;

const ContainerError = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1023px) {
    flex-direction: column-reverse;
    margin-bottom: 5px;
  }
`;

const StyledButtonLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.white};
  display: block;
  width: 100%;
  height: 100%;
  @media (max-width: 1023px) {
    font-size: 14px;
  }
`;

export {
  ModalWindow,
  ModalCardContent,
  ModalCard,
  ModalTitle,
  ModalSubTitle,
  StyledLink,
  ModalFromButton,
  Container,
  StyledButtonLink,
  ContainerError,
};
