import React from 'react';
import styled from 'styled-components';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const StyledListItem = styled(ListItem)`
  margin-bottom: 18px;
  padding: 0;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 0;

  svg {
    font-size: 9px;
    color: ${({ theme: { colors } }) => colors.mainGreen};
  }
`;

const StyledListItemText = styled(ListItemText)`
  padding-left: 6px;
  margin: 0;

  span {
    color: ${({ theme: { colors } }) => colors.mainGreen};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledList = styled(List)`
  list-style: none;
  margin: 0 0 40px;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    max-height: 440px;

    &.double-row {
      ${StyledListItem} {
        width: 50%;
        max-width: 540px;
      }
    }

    &.triple-row {
      ${StyledListItem} {
        width: 33%;
        max-width: 350px;
      }
    }

    &.quadruple-row {
      ${StyledListItem} {
        width: 25%;
        max-width: 260px;
      }
    }
  }

  @media (max-width: 767px) {
    flex-wrap: nowrap;
    max-height: none;
    margin-bottom: 0 0 32px;
    line-height: 22px;
  }
`;

interface IListProps {
  children: React.ReactNode;
}

const ListComponent: React.FC<IListProps> = ({ children }: IListProps) => {
  const LIST_ITEMS_PER_ROW = 10;

  const handleClass = () => {
    const childrenCount = React.Children.count(children);

    if (childrenCount > LIST_ITEMS_PER_ROW && childrenCount <= LIST_ITEMS_PER_ROW * 2) {
      return 'double-row';
    }
    if (childrenCount > LIST_ITEMS_PER_ROW * 2 && childrenCount <= LIST_ITEMS_PER_ROW * 3) {
      return 'triple-row';
    }
    if (childrenCount > LIST_ITEMS_PER_ROW * 3 && childrenCount <= LIST_ITEMS_PER_ROW * 4) {
      return 'quadruple-row';
    }

    return undefined;
  };

  return <StyledList className={handleClass()}>{children}</StyledList>;
};

interface IListItemProps {
  children: React.ReactNode;
}

const ListItemComponent: React.FC<IListItemProps> = ({ children }: IListItemProps) => {
  return (
    <StyledListItem button>
      <StyledListItemIcon>
        <FiberManualRecordIcon />
      </StyledListItemIcon>
      <StyledListItemText>{children}</StyledListItemText>
    </StyledListItem>
  );
};

export { ListComponent, ListItemComponent };
