import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { usePopupScrollEffect } from 'hooks/usePopupScrollEffect';
import { ProductVariantClass } from 'models/classes/productVariantClass';

import { PopUpCross, PopUpSmallCross } from 'assets/svg/Popups/Popups';

import { selectProductVariantsError } from 'ducks/productVariant/selectors';
import { selectSalesRepresentative } from 'ducks/salesRepresentative/selectors';
import { fetchSalesRepresentative } from 'ducks/salesRepresentative/actions';

import SubmitEmailSent from 'components/Popups/RequestPricing/components/SubmitEmailSent';
import ProductPricingEmail from 'components/Popups/RequestPricing/components/ProductPricingEmail';

import {AssociationVariantClass} from 'models/classes/associationClass';
import {selectAddressId} from 'ducks/application/selectors';
import { PopUpBackground, PopUpWrapper, CrossButtonWrapper, CrossButton } from './styles';
import {setClickedProductRequestPricing} from '../../../ducks/product/actions';

interface IRequestPricingPopup {
  setRequestPricing: Dispatch<SetStateAction<boolean>>;
  product: ProductVariantClass | AssociationVariantClass;
  setPricingClicked?: Dispatch<SetStateAction<boolean>>;
}

const rootDiv = document.getElementById('root');
const el = document.createElement('div');

const RequestPricingPopup: React.FC<IRequestPricingPopup> = ({
  setRequestPricing,
  product,
  setPricingClicked,
}: IRequestPricingPopup) => {
  const [emailSuccess, setEmailSuccess] = useState<boolean>(false);
  const [emailLoading, setEmailLoading] = useState<boolean>(false);
  // need disabled param because the reason of the bug was't found
  const [disabled, setDisabled] = useState<boolean>(false);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const error = useSelector(selectProductVariantsError);
  const salesRepresentative = useSelector(selectSalesRepresentative);
  const selectedAddressId = useSelector(selectAddressId);
  const currentSelectedAddressId = useRef(selectedAddressId);
  const [currentSaleRepresentative, setCurrentSaleRepresentative] = useState(null);

  useEffect(() => {
    rootDiv.appendChild(el);

    return () => {
      if (rootDiv.contains(el)) rootDiv?.removeChild(el);
    };
  }, []);

  useEffect(() => {
    if (selectedAddressId && currentSelectedAddressId.current !== selectedAddressId) {
      dispatch(fetchSalesRepresentative());
    }
  }, [dispatch, selectedAddressId, currentSelectedAddressId.current]);

  usePopupScrollEffect();

  const handleClose = () => {
    if (setPricingClicked) {
      setPricingClicked(false);
    }
    setRequestPricing(false);
    dispatch(setClickedProductRequestPricing(null))
  };

  const renderSubmitEmailSent = () =>
    salesRepresentative ? (
      <SubmitEmailSent
        setOpenRequestNearbyBranch={setRequestPricing}
        salesRepresentative={currentSaleRepresentative || salesRepresentative}
      />
    ) : null;

  const renderPricingEmail = () =>
    salesRepresentative ? (
      <ProductPricingEmail
        setRequestPricing={setRequestPricing}
        product={product}
        disabled={disabled}
        setDisabled={setDisabled}
        setEmailSuccess={setEmailSuccess}
        setEmailLoading={setEmailLoading}
        salesRepresentative={currentSaleRepresentative || salesRepresentative}
        loading={emailLoading}
        error={!!error}
        setCurrentSaleRepresentative={setCurrentSaleRepresentative}
      />
    ) : null;

  return ReactDOM.createPortal(
    <PopUpBackground style={{ zIndex: 1300 }}>
      <PopUpWrapper>
        <CrossButtonWrapper>
          <CrossButton onClick={handleClose}>
            {isMobile ? <PopUpSmallCross /> : <PopUpCross />}
          </CrossButton>
        </CrossButtonWrapper>
        {emailSuccess ? renderSubmitEmailSent() : renderPricingEmail()}
      </PopUpWrapper>
    </PopUpBackground>,
    el
  );
};

export default RequestPricingPopup;
