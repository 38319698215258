import styled, { ThemedStyledProps } from 'styled-components/macro';
import { AppBar, Link, Tab } from '@material-ui/core';
import React from 'react';

const StyledAppBar = styled(AppBar)`
  color: ${({ theme: { colors } }) => colors.grey};
  background: ${({ theme: { colors } }) => colors.white};
  box-shadow: none;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.productBorderGrey};
  margin-top: 56px;

  .MuiTab-wrapper {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.01em;
    width: fit-content;
  }

  @media (max-width: 1023px) {
    margin-bottom: 0;
  }
`;

interface IStyledTab {
  isVisible: boolean;
}

const StyledTab = styled(Tab)`
  margin-right: 32px;
  min-width: 0;
  padding: 0;
  display: ${(p: IStyledTab) => (p.isVisible ? 'block' : 'none')};

  &.MuiTab-textColorInherit.Mui-selected {
    color: ${({ theme: { colors } }) => colors.mainGreen};
    opacity: 1;
  }
`;

const DescriptionTab = styled.div`
  width: 100%;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
`;

const DescriptionText = styled.pre`
  margin: 40px 0 0 0;
  max-width: 658px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  max-width: 100%;
  white-space: break-spaces;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const SpecificationTable = styled.div`
  max-width: 616px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  border-radius: 4px;

  div:nth-child(even) {
    background: ${({ theme: { colors } }) => colors.white};
  }

  div:nth-child(odd) {
    background: ${({ theme: { colors } }) => colors.lightGrey};
  }
`;

const SpecPropertyWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.borderGrey};

  &:last-child {
    border: none;
  }
`;

interface ISpecPropertyText extends ThemedStyledProps<object, HTMLDivElement> {
  isName: boolean;
}

const SpecPropertyText = styled.p`
  width: 50%;
  margin: 0;
  padding: 14px 17px 11px 17px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: ${(props: ISpecPropertyText) => (props.isName ? 'bolder' : 'normal')};
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.nameBlack};
`;

const DocumentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SingleDocumentWrapper = styled(Link)`
  display: flex;
  margin-bottom: 30px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const DocumentInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 6px;
`;

const DocumentName = styled.p`
  margin: 0;
  margin-bottom: 5px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.mainGreen};
`;

const DocumentMeta = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.grey};
  margin: 0;
`;

interface IDropdown extends ThemedStyledProps<object, HTMLDivElement> {
  isOpen: boolean;
}

const DropdownButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media (max-width: 1023px) {
    margin-top: 40px;
  }
`;

const SingleDropdownWrapper = styled(({ isOpen, children, ...rest }) => (
  <div {...rest}> {children} </div>
))`
  flex-direction: column;
  border-left: ${props => (props.isOpen ? `2px solid ${props.theme.colors.mainGreen}` : 'none')};
  position: relative;
  width: 100vw;
  right: 10px;
  display: ${(p: IStyledTab) => (p.isVisible ? 'block' : 'none')};

  &:first-child {
    border-top: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  }
`;

const DropdownButton = styled(({ isOpen, children, ...rest }) => (
  <button {...rest} type="button">
    {' '}
    {children}{' '}
  </button>
))`
  padding: 15px 15px 15px 8px;
  border: none;
  background: ${({ theme: { colors } }) => colors.white};
  height: 44px;
  display: flex;
  justify-content: space-between;
  border-bottom: ${props => (props.isOpen ? 'none' : `1px solid ${props.theme.colors.borderGrey}`)};
  border-radius: ${props => (props.isOpen ? '2px' : 'none')};
  outline: none;
  width: 100%;
  p {
    margin: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: ${({ theme: { colors } }) => colors.grey};
  }
  svg {
    transition: 0.2s;
    transform: rotate(${props => (props.isOpen ? '90deg' : '0deg')});
  }
`;

const DropdownPlace = styled.div`
  display: ${(props: IDropdown) => (props.isOpen ? 'block' : 'none')};
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  border-radius: ${(props: IDropdown) => (props.isOpen ? '2px' : 'none')};
  position: relative;
  width: 100%;
  padding: 0 8px 16px 8px;
`;

const StyledLoadingDiv = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 60px;
  border: 10px solid ${({ theme: { colors } }) => colors.mainGreen};
  border-top-color: ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  animation: loading 1.2s linear infinite;
  -webkit-animation: loading 1.2s linear infinite;
`;

const StyledLoadingDivWrapper = styled.div`
  width: 100%;
  height: 30vh;
  position: relative;
`;

export {
  StyledAppBar,
  StyledTab,
  DescriptionTab,
  DescriptionText,
  SpecificationTable,
  SpecPropertyWrapper,
  SpecPropertyText,
  DocumentsWrapper,
  SingleDocumentWrapper,
  DocumentInfo,
  DocumentMeta,
  DocumentName,
  DropdownButton,
  DropdownButtonsWrapper,
  DropdownPlace,
  SingleDropdownWrapper,
  StyledLoadingDiv,
  StyledLoadingDivWrapper,
};
