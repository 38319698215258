import React from 'react';
import { useTranslation } from 'react-i18next';

import { addDotToPrice } from 'utils/common/common';
import { WhiteTruck } from 'assets/svg/Product/Products';
import { useSelector } from 'react-redux';
import {
  ReorderBlockContainer,
  TaxesRow,
  CancelReorderButton,
  TaxesRowInfoText,
  TaxesRowInfoTextWrapper,
  TaxesRowInfoWrapper,
  AddButtonRow,
  ButtonTitle,
  StyledButton,
  RightColumn,
  LeftColumn,
} from './styles';
import { selectIsHidePrice } from '../../../../../../../../ducks/application/selectors';

interface IOrderItemProps {
  cancelReorder: () => void;
  goodsTotal: number;
  handleAddToTruck: any;
  isAbleToAdd: boolean;
}

const ReorderBlock: React.FC<IOrderItemProps> = ({
  cancelReorder,
  goodsTotal,
  handleAddToTruck,
  isAbleToAdd,
}: IOrderItemProps) => {
  const { t } = useTranslation();
  const isHidePrice = useSelector(selectIsHidePrice);

  return (
    <ReorderBlockContainer>
      <LeftColumn>
        <CancelReorderButton onClick={cancelReorder}>
          {t('orders_and_quotes.cancel_reorder').toString()}
        </CancelReorderButton>
      </LeftColumn>

      <RightColumn>
        {
          !isHidePrice && (
            <TaxesRow>
              <TaxesRowInfoWrapper>
                <TaxesRowInfoTextWrapper>
                  <TaxesRowInfoText> {t('orders_and_quotes.goods_total').toString()}:&nbsp;</TaxesRowInfoText>
                  <TaxesRowInfoText bold>${addDotToPrice(String(goodsTotal))}</TaxesRowInfoText>
                </TaxesRowInfoTextWrapper>
              </TaxesRowInfoWrapper>
            </TaxesRow>
          )
        }
        
        <AddButtonRow>
          <StyledButton onClick={handleAddToTruck} disabled={!isAbleToAdd}>
            <WhiteTruck />
            <ButtonTitle>&nbsp;{t('orders_and_quotes.add_to_truck').toString()}</ButtonTitle>
          </StyledButton>
        </AddButtonRow>
      </RightColumn>
    </ReorderBlockContainer>
  );
};

export default React.memo(ReorderBlock);
