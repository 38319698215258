import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import axios from 'utils/axios';

// Classes
import { SalesRepresentativeClass } from 'models/classes/salesRepresentative';

import { SalesRepresentativeActionTypes } from 'ducks/salesRepresentative/types';
import { storeSalesRepresentative } from 'ducks/salesRepresentative/actions';
import { storeError } from 'utils/errors';
import { selectAddressId } from '../application/selectors';

function* requestSalesRepresentative() {
  try {
    const address = yield select(selectAddressId);
    const { data: salesRepresentative } = yield call(axios.get, '/api/auth/sales-representative', {
      headers: {
        'x-address-id': address,
      },
    });
    yield put(storeSalesRepresentative(new SalesRepresentativeClass(salesRepresentative.data)));
  } catch (error) {
    yield storeError(
      SalesRepresentativeActionTypes.REQUEST_SALES_REPRESENTATIVE_ERROR,
      [404],
      error.response
    );
  }
}

function* watchRequestSalesRepresentative() {
  yield takeEvery(
    SalesRepresentativeActionTypes.REQUEST_SALES_REPRESENTATIVE,
    requestSalesRepresentative
  );
}

export default function* salesRepresentativeSaga() {
  yield all([watchRequestSalesRepresentative()]);
}
