import React from 'react';

export const Delivery = () => {
  return (
    <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 4.57348H16V0.583984H2.00001C0.894983 0.583984 0 1.47662 0 2.57875V13.5499H2.00001C2.00001 15.2006 3.345 16.5421 5.00001 16.5421C6.65502 16.5421 8 15.2006 8 13.5499H14C14 15.2006 15.345 16.5421 17 16.5421C18.655 16.5421 20 15.2006 20 13.5499H22V8.56302L19 4.57348ZM5.00001 15.0459C4.16999 15.0459 3.50001 14.3777 3.50001 13.5499C3.50001 12.722 4.16999 12.0538 5.00001 12.0538C5.83002 12.0538 6.50001 12.722 6.50001 13.5499C6.50001 14.3777 5.82998 15.0459 5.00001 15.0459ZM17 15.0459C16.17 15.0459 15.5 14.3777 15.5 13.5499C15.5 12.722 16.17 12.0538 17 12.0538C17.83 12.0538 18.5 12.722 18.5 13.5499C18.5 14.3777 17.83 15.0459 17 15.0459ZM16 8.56302V6.06959H18.4999L20.4649 8.56302H16Z"
        fill="#939599"
      />
    </svg>
  );
};
