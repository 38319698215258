import styled from 'styled-components/macro';

interface IStyledLoadingDiv {
  isFilterLoader?: boolean;
  isLoadingMore?: boolean;
}

const StyledLoadingDiv = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 60px;
  border: 10px solid ${({ theme: { colors } }) => colors.mainGreen};
  border-top-color: ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  margin-top: ${(props: IStyledLoadingDiv) => (props.isLoadingMore ? '10px' : '120px')};
  margin-left: calc(50% - 40px);
  animation: loading 1.2s linear infinite;
  -webkit-animation: loading 1.2s linear infinite;
  @media (max-width: 1023px) {
    position: static;
    margin-top: ${(props: IStyledLoadingDiv) => (props.isLoadingMore ? '20px' : '70px')};
    margin-bottom: ${(props: IStyledLoadingDiv) => (props.isLoadingMore ? '40px' : '70px')};
  }
`;

export default StyledLoadingDiv;
