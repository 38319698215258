import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { LANDING_IMAGES_URL } from './constants';

const GeneralWrapper = styled.div`
  max-width: 1440px;
  min-width: 320px;
  margin: 0 auto;
`;

const SectionPortfolio = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme: { colors } }) => colors.white};
  font-family: Open Sans;

  span {
    display: none;
  }

  @media (max-width: 720px) {
    span {
      display: block;
      font-weight: 800;
      font-size: 26px;
      line-height: 120%;
      text-align: center;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      text-shadow: 0px 0px 20px ${({ theme: { colors } }) => colors.blackOpacity(0.25)};
      margin-bottom: 24px;
    }
  }
`;

const SectionPortfolioWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1200px;
  max-height: 999px;
  width: 100%;
  background: ${({ theme: { colors } }) => colors.white};
  box-shadow: 0px 0px 20px ${({ theme: { colors } }) => colors.blackOpacity(0.15)};
  border-radius: 4px;
  margin-top: -120px;
  padding: 86px 80px 80px;

  @media (max-width: 1280px) {
    max-width: 1000px;
    padding: 45px;
  }

  @media (max-width: 1024px) {
    padding: 40px;
    max-height: 716px;
    max-width: 880px;
    margin-top: -80px;
  }

  @media (max-width: 920px) {
    max-width: 810px;
  }

  @media (max-width: 810px) {
    max-width: 720px;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    padding: 40px 10px;
    margin-top: -34px;
    max-height: fit-content;
  }
`;

const PortfolioFirstPart = styled.div`
  margin-right: 40px;

  img {
    width: 100%;
    object-fit: cover;
    border-radius: 3px;
    height: fit-content;
  }

  img:first-child {
    margin-bottom: 34px;
  }

  @media (max-width: 1024px) {
    max-width: 413px;
    margin-right: 20px;

    img:first-child {
      max-height: 341px;
      margin-bottom: 14px;
    }

    img:last-child {
      max-height: 275px;
    }
  }

  @media (max-width: 829px) {
    img:first-child {
      max-height: 340px;
    }
  }

  @media (max-width: 720px) {
    margin-right: 0px;
    max-width: 580px;
    margin: 0 auto;

    img:first-child {
      max-height: 330px;
      margin-bottom: 4px;
    }

    img:last-child {
      max-height: 240px;
    }
  }

  @media (max-width: 560px) {
    padding-right: 0px;
    padding-left: 0px;

    img:first-child {
      max-height: 258px;
    }

    img:last-child {
      max-height: 183px;
    }
  }
`;

const PortfolioSecondPart = styled.div`
  span {
    display: block;
    margin-top: -10px;
    margin-bottom: 64px;
    font-weight: 800;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-shadow: 0px 0px 20px ${({ theme: { colors } }) => colors.blackOpacity(0.25)};
  }

  @media (max-width: 1095px) {
    span {
      font-size: 37px;
      margin-bottom: 72px;
    }
  }

  @media (max-width: 1024px) {
    span {
      font-size: 36px;
      margin: -9px 0 24px 10px;
    }
  }

  @media (max-width: 926px) {
    span {
      font-size: 32px;
      margin-bottom: 35px;
      margin-top: -8px;
    }
  }

  @media (max-width: 846px) {
    span {
      font-size: 30px;
      margin-bottom: 34px;
      margin-top: -7px;
    }
  }

  @media (max-width: 810px) {
    span {
      font-size: 28px;
    }
  }

  @media (max-width: 720px) {
    max-width: 580px;
    margin: 0 auto;
    span {
      display: none;
    }
  }

  @media (max-width: 560px) {
    padding: 0;
  }
`;

const PortfolioSecondPartImages = styled.div`
  img {
    width: 100%;
    object-fit: cover;
    height: fit-content;
    border-radius: 3px;
  }

  img:first-child {
    margin-bottom: 34px;
  }

  @media (max-width: 1024px) {
    img:first-child {
      max-height: 221px;
      margin-bottom: 14px;
    }

    img:last-child {
      max-height: 295px;
    }
  }

  @media (max-width: 810px) {
    img:first-child {
      max-height: 227px;
    }
  }

  @media (max-width: 720px) {
    img {
      max-height: 250px;
    }

    img:first-child {
      margin: 5px 0;
      max-height: 210px;
    }

    img:last-child {
      max-height: 290px;
    }
  }

  @media (max-width: 560px) {
    img:first-child {
      max-height: 179px;
    }

    img:last-child {
      max-height: 241px;
    }
  }
`;

const BottomBorder = styled.div`
  height: 145px;
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.white};

  @media (max-width: 810px) {
    height: 106px;
  }
`;

const useStyles = makeStyles({
  span: {
    fontSize: '38px !important',
    marginBottom: '26px !important',

    '@media (max-width: 1024px)': {
      fontSize: '28px !important',
      marginBottom: '43px !important',
    },

    '@media (max-width: 993px)': {
      marginBottom: '45px !important',
    },

    '@media (max-width: 920px)': {
      marginBottom: '10px !important',
    },

    '@media (max-width: 810px)': {
      fontSize: '26px !important',
    },
  },
});

interface TextProps {
  title: string;
  isEnglish: boolean;
}

const PortfolioSection: React.FC<TextProps> = ({ title, isEnglish }: TextProps) => {
  const classes = useStyles();
  return (
    <>
      <GeneralWrapper>
        <SectionPortfolio>
          <SectionPortfolioWrapper>
            <span>{title}</span>
            <PortfolioFirstPart>
              <img src={`${LANDING_IMAGES_URL}/portfolio1.png`} alt="portfolio1" />
              <img src={`${LANDING_IMAGES_URL}/portfolio2.png`} alt="portfolio2" />
            </PortfolioFirstPart>
            <PortfolioSecondPart>
              <span className={!isEnglish ? classes.span : ''}>{title}</span>
              <PortfolioSecondPartImages>
                <img src={`${LANDING_IMAGES_URL}/portfolio3.png`} alt="portfolio3" />
                <img src={`${LANDING_IMAGES_URL}/portfolio4.png`} alt="portfolio4" />
              </PortfolioSecondPartImages>
            </PortfolioSecondPart>
          </SectionPortfolioWrapper>
        </SectionPortfolio>
      </GeneralWrapper>
      <BottomBorder />
    </>
  );
};

export default PortfolioSection;
