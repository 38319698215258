import { all, put, call, takeLatest, select } from 'redux-saga/effects';

import { BranchClass } from 'models/classes/branchClass';
import { storeBranch, storeBranches } from 'ducks/branch/actions';
import { BranchActionTypes } from 'ducks/branch/types';
import { setServerError } from 'ducks/application/actions';
import { getError } from 'utils/errors';
import axios from 'utils/axios';
import { selectAddressId } from '../application/selectors';

function* requestBranch() {
  try {
    const address = yield select(selectAddressId);
    const { data: response } = yield call(axios.get, '/api/auth/branch', {
      headers: {
        'x-address-id': address
      }
    });
    yield put(storeBranch(new BranchClass(response.data)));
  } catch (error) {
    yield put(setServerError(getError(error.response, [])));
  }
}

function* watchRequestBranch() {
  yield takeLatest(BranchActionTypes.REQUEST_BRANCH, requestBranch);
}

function* requestBranches() {
  try {
    const { data } = yield call(axios.get, '/api/branches');
    const response = [];
    data.data.map(branch => response.push(new BranchClass(branch)));
    yield put(storeBranches(response));
  } catch (error) {
    yield put(setServerError(getError(error.response, [])));
  }
}

function* watchRequestBranches() {
  yield takeLatest(BranchActionTypes.REQUEST_BRANCHES, requestBranches);
}

export default function* branchSaga() {
  yield all([watchRequestBranch(), watchRequestBranches()]);
}
