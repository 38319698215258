import {AuthActionTypes} from 'ducks/auth/types';

export const checkAuth = () => ({type: AuthActionTypes.CHECK_AUTH});

export const rememberMe = () => ({type: AuthActionTypes.REMEMBER_ME});

export const forgetMe = () => ({type: AuthActionTypes.FORGET_ME});

export const logout = () => ({type: AuthActionTypes.LOGOUT});

export const validateRecaptcha = (email: string, recaptchaToken: string) => ({
  type: AuthActionTypes.REQUEST_VALIDATE_RECAPTCHA,
  payload: {email, recaptchaToken},
});

export const triggerLogin = (email: string, password: string, recaptchaToken: string, otp?: number, languageCode?: string) => ({
  type: AuthActionTypes.LOGIN,
  payload: {
    email,
    password,
    recaptchaToken,
    languageCode,
    otp,
  },
});

export const login = (email: string, password: string, recaptchaToken: string, fromReset: boolean) => ({
  type: AuthActionTypes.LOGIN,
  payload: {
    email,
    password,
    recaptchaToken,
    fromReset
  }
})

export const changeDisplayLanguageAction = (languageCode: string) => ({
  type: AuthActionTypes.REQUEST_CHANGE_DISPLAY_LANGUAGE,
  payload: {
    languageCode,
  },
});

export const setIsChangeRefreshToken = (data: boolean) => ({
  type: AuthActionTypes.REQUEST_CHANGE_TOKEN,
  payload: {
    data
  }
});

export const requestCreateMagicLink = (email: string) => ({
  type: AuthActionTypes.REQUEST_CREATE_MAGIC_LINK,
  payload: {
    email,
  }
});

export const loginByMagicLink = (token: string) => ({
  type: AuthActionTypes.REQUEST_LOGIN_BY_MAGIC_LINK,
  payload: {
    token,
  }
});

export const reloadStateLoginLink = () => ({
  type: AuthActionTypes.REQUEST_RELOAD_STATE_LOGIN_LINK,
});