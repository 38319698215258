import { IError } from 'models/interfaces/error';
import { put } from 'redux-saga/effects';
import { setServerError } from 'ducks/application/actions';
import get from 'lodash/get';

export function getError(error: any, statusCodes: number[] | []): IError {
  if (typeof get(error, 'data') === 'string' && statusCodes.length === 0) {
    return {
      message: error.statusText ? error.statusText : 'Something went wrong',
      code: error.status,
    };
  }
  if (statusCodes.filter(code => code === (get(error, 'data.code') || get(error, 'status'))).length > 0) {
    return {
      message: error.data.message ? error.data.message : 'Something went wrong',
      code: error.data.code ? error.data.code : error.status,
    };
  }

  return null;
}

export function* storeError(errorType: string, statusCodes: number[], error: any) {
  const errorObject = getError(error, statusCodes);

  if (errorObject) {
    yield put({
      type: errorType,
      payload: {
        error: errorObject,
      },
    });
  } else {
    yield put(setServerError(getError(error, [])));
  }
}

export function* storeErrors(errorType: string, code: number[], errors: any) {
  yield put ({
    type: errorType,
    payload: errors,
  })
}

export const check404error = (errors: IError[]) => {
  return errors.reduce((acc, error) => {
    return error && error.code === 404 ? true : acc;
  }, false);
};
