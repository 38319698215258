import React, { useEffect, useState } from 'react';
import { ExpandMore } from '@material-ui/icons';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';

import { GreenCheck } from 'assets/svg/GreenCheck';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from 'ducks/application/actions';
import { selectLanguage } from 'ducks/application/selectors';
import { selectIsDisplayLanguageChanged } from 'ducks/auth/selectors';
import { changeDisplayLanguageAction } from 'ducks/auth/actions';
import { selectUserData } from 'ducks/user/selectors';
import {
  Wrapper,
  StyledFormControl,
  StyledMenuItem,
  StyledParagraph,
  StyledSelect,
} from './styles';
import {getFullLanguage} from '../../../../../i18n';

type Props = {
	className?: string;
};

const LanguageDropdown: React.FC<Props> = ({ className }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const selectedLanguageCode = useSelector(selectLanguage).slice(-2);
  const isDisplayLanguageChanged = useSelector(selectIsDisplayLanguageChanged);
  const user = useSelector(selectUserData);
  const url = window.location.href;

  const [currentUrl, setCurrentUrl] = useState('');
  const [currentLang, setCurrentLang] = useState('en')

  useEffect(() => {
    setCurrentLang(i18n.language)
  })
  useEffect(() => {
    const splitUrl = window.location.pathname.split('/').filter(v => v !== '');
    switch (splitUrl[0]) {
      case 'en':
        i18n.changeLanguage('en');
        dispatch(setLanguage('en_CA'));
        break;
      case 'fr':
        i18n.changeLanguage('fr');
        dispatch(setLanguage('fr_CA'));
        break;
      default:
        i18n.changeLanguage('en');
        dispatch(setLanguage('en_CA'));
        break;
    }
  }, [dispatch, window.location.href]);

  useEffect(() => {
    if (isDisplayLanguageChanged) {
      window.location.replace(currentUrl);
    }
  }, [isDisplayLanguageChanged]);

  useEffect(() => {
    if (user && user.defaultDisplayLanguage) {
      const language = user.defaultDisplayLanguage.code.slice(0, 2);
      let newUrl;
      setCurrentLang(i18n.language)
      i18n.changeLanguage(language).then(() => {
        if (language === 'en') {
          newUrl = url.split('/');
          newUrl[3] = 'en';
          newUrl = newUrl.join('/');
        } else {
          newUrl = url.split('/');
          newUrl[3] = 'fr';
          newUrl = newUrl.join('/');
        }
        if (window.location.href !== newUrl) {
          window.location.replace(newUrl);
        }
      });
      dispatch(setLanguage(user.defaultDisplayLanguage.code));
    }
  }, [dispatch, user]);

  const changeLanguage = async (e) => {
    await i18n.changeLanguage(e.target.value as string);
    const newUrl = url.replace(`/${currentLang}`, `/${e.target.value as string}`);
    setCurrentLang(e.target.value as string)
    setCurrentUrl(newUrl);
    dispatch(changeDisplayLanguageAction(getFullLanguage(e.target.value as string)));
  };

  const countryCode =
		selectedLanguageCode === 'US' || selectedLanguageCode === 'FR' ? 'CA' : selectedLanguageCode;

  return (
    <Wrapper className={`language-dropdown-wrapper ${className}`}>
      <StyledFormControl>
        <ReactCountryFlag alt={countryCode} countryCode={countryCode} svg />
        <StyledSelect
          data-test-id="language"
          value={i18n.language}
          onChange={changeLanguage}
          disableUnderline
          autoWidth
          IconComponent={ExpandMore}
          displayEmpty
          MenuProps={{
            MenuListProps: {
              disablePadding: true,
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            getContentAnchorEl: null,
            style: {
              zIndex: 1510
            }
          }}
        >
          <StyledMenuItem value='en' data-test-id="en">
            <StyledParagraph>EN</StyledParagraph>
            {i18n.language === 'en' ? (
              <div>
                <GreenCheck />
              </div>
            ) : null}
          </StyledMenuItem>
          <StyledMenuItem value='fr' data-test-id="fr">
            <StyledParagraph>FR</StyledParagraph>
            {i18n.language === 'fr' ? (
              <div>
                <GreenCheck />
              </div>
            ) : null}
          </StyledMenuItem>
        </StyledSelect>
      </StyledFormControl>
    </Wrapper>
  );
};

export default LanguageDropdown;
