import DeleteIcon from '@material-ui/icons/Delete';
import {useTranslation} from 'react-i18next';
import React, {useCallback, useEffect, useState} from 'react';
import {IQuickOrderItem} from 'ducks/quickOrder/types';
import {useDispatch, useSelector} from 'react-redux';
import {
  priceTypeSelector,
  selectAddressId,
  selectErpCustomerAddressCode,
  selectIsHidePrice,
} from 'ducks/application/selectors';
import {setDisableQuickOrderCheckout, removeQuickOrderItem, updateQuickOrderItemQuantity} from 'ducks/quickOrder/actions';
import {filterPrices} from 'utils/products/productVariant';
import size from 'lodash/size';
import {useRequestPricing} from 'hooks/useRequestPricing';
import {ProductVariantClass} from 'models/classes/productVariantClass';
import get from 'lodash/get';
import {NearbyBranchEmailType} from 'ducks/productVariant/types';
import {setClickedProductRequestNearbyHubsAndBranch, setOpenPopupInquireNow} from 'ducks/product/actions';
import {NearbyBranchContext} from 'context/NearbyBranchContext';
import {selectBranch} from 'ducks/branch/selectors';
import TagManager from 'react-gtm-module';
import {IInventoryStatus} from 'models/interfaces/productVariant';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectUserData } from 'ducks/user/selectors';
import { createUserActionLog } from 'api/UserActionLog';
import { numberFormat } from 'utils/products/product';
import {
  CartProductInputsWrapper,
  DeleteButton,
  RequestPricingButton, SearchComponentWrapper, TextError, TextInStock, TextPrice,
  TextWaitingOnStock,
  TextWithPencil
} from '../styles';
import {Pencil} from '../../../assets/svg/MiddleMenu/MiddleMenu';
import SearchComponent from './SearchComponent';
import CartProductInputs from './Inputs/CartProductInputs';
import {NextTierPriceInner, NextTierPriceText, NextTierPriceWrapper} from '../../Product/ProductDetail/styles';
import {StyledAvailableAndLeadTime} from '../../Product/ProductTableView/ProductTableCard/styles';

type Props = {
	quickOrderItem: IQuickOrderItem;
}

export default function QuickOrderItem({quickOrderItem}: Props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const deliveryType = useSelector(priceTypeSelector);
  const branch = useSelector(selectBranch);
  const user = useSelector(selectUserData);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const isHidePrice = useSelector(selectIsHidePrice);
  const {setEmailType, setFromBranchId} = React.useContext(NearbyBranchContext);
  const [isClickEditSku, setIsClickEditSku] = useState(false);
  const [isClickEditName, setIsClickEditName] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [botVal, setBotVal] = useState(0);
  const [oldPrice, setOldPrice] = useState(0);
  const [isShowPromotionPercent, setIsShowPromotionPercent] = useState(false);
  const [topInputValue, setTopInputValue] = useState(quickOrderItem.quantity);

  const {handleRequestPricingClick} = useRequestPricing(
		{
		  id: quickOrderItem.productId,
		  name: quickOrderItem.name,
		  code: quickOrderItem.erpProductId,
		  erpSku: quickOrderItem.erpSku,
		  images: quickOrderItem.images
		} as ProductVariantClass
  );

  const onClickRequestPricing = async () => {
    await createUserActionLog('Quick Order Request Pricing', 'Pricing Inquiry');
    handleRequestPricingClick();
  }

  useEffect(() => {
    if (size(quickOrderItem.prices) > 0 && deliveryType) {
      const sortPrices = filterPrices(quickOrderItem.prices);
      if (quickOrderItem.quantity >= sortPrices[sortPrices.length - 1].qty) {
        setCurrentPrice(sortPrices[sortPrices.length - 1]);
        if (quickOrderItem.units.uom2Conversion) {
          const cartItemCuff = Number((quickOrderItem.quantity / quickOrderItem.units.uom2Conversion).toFixed(2));
          setBotVal(cartItemCuff);
        }
      } else {
        setIsShowPromotionPercent(false);
        setCurrentPrice(sortPrices[0]);
        if (quickOrderItem.units.uom2Conversion) {
          const cartItemCuff = Number((quickOrderItem.quantity / quickOrderItem.units.uom2Conversion).toFixed(2));
          setBotVal(cartItemCuff);
        }
      }
    }
  }, [deliveryType, quickOrderItem]);

  const handleRemoveQuickOrderItem = useCallback(() => {
    dispatch(removeQuickOrderItem({quickOrderItemId: quickOrderItem.id}));
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'quick_order_remove_item',
        user_type: getUserType(user?.email),
        branch_id: branch?.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
  }, [branch?.id, dispatch, erpCustomerAddressCode, quickOrderItem.id, selectedAddressId, user?.customer?.erpCustomerCode, user?.email]);

  const handleClickOnPromotionQuantity = useCallback(() => {
    if (size(quickOrderItem.prices) > 1 && quickOrderItem.quantity < quickOrderItem.prices[1].qty) {
      dispatch(updateQuickOrderItemQuantity({
        quickOrderItemId: quickOrderItem.id,
        quantity: quickOrderItem.prices[1].qty,
      }));
      setTopInputValue(quickOrderItem.prices[1].qty);
      setCurrentPrice(quickOrderItem.prices[1]);
      const prices = filterPrices(quickOrderItem.prices);
      setOldPrice(prices[0][deliveryType] * prices[1].qty);
      setIsShowPromotionPercent(true);
      if (quickOrderItem.units.uom2Conversion) {
        const cartItemCuff = Number((quickOrderItem.prices[1].qty / quickOrderItem.units.uom2Conversion).toFixed(2));
        setBotVal(Number((quickOrderItem.quantity * cartItemCuff).toFixed(2)));
      }
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'product_click_upsell',
          user_type: getUserType(user?.email),
          branch_id: branch?.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
      dispatch(setDisableQuickOrderCheckout({
        disableCheckout: false
      }));
    }
  }, [branch?.id, dispatch, erpCustomerAddressCode, quickOrderItem.id, quickOrderItem.prices, quickOrderItem.quantity, quickOrderItem.units.uom2Conversion, selectedAddressId, user?.customer?.erpCustomerCode, user?.email]);

  const handleOpenEmailPopup = useCallback(async (inventoryStatus: IInventoryStatus) => {
    await createUserActionLog('Quick Order Inventory Status', 'Inventory Request');
    if (inventoryStatus.inStock) {
      setEmailType(NearbyBranchEmailType.HOME_BRANCH_WITH_INVENTORY_IN_STOCK);
    } else if (inventoryStatus.waitingOnStock) {
      setEmailType(NearbyBranchEmailType.HOME_BRANCH_WAITING_ON_STOCK);
    } else if (inventoryStatus.specialOrderString) {
      setEmailType(NearbyBranchEmailType.HOME_BRANCH_WITH_SPECIAL_ORDER_STRING);
    }
    dispatch(setClickedProductRequestNearbyHubsAndBranch({
      id: quickOrderItem.productId,
      category: quickOrderItem.category,
      images: quickOrderItem.images,
      name: quickOrderItem.name,
    }));
    dispatch(setOpenPopupInquireNow(true));
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_inventory_request',
        user_type: getUserType(user?.email),
        branch_id: branch?.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
  }, [setFromBranchId, branch?.id, dispatch, quickOrderItem.productId, quickOrderItem.category, quickOrderItem.images, quickOrderItem.name, user?.email, user?.customer?.erpCustomerCode, erpCustomerAddressCode, selectedAddressId, setEmailType])

  const handleClickLeadTime = useCallback(async () => {
    await createUserActionLog('Quick Order Inventory Status', 'Inventory Request');
    setEmailType(NearbyBranchEmailType.HUB_BRANCH_WITH_INVENTORY_AVAILABLE);
    setFromBranchId(quickOrderItem.newInventory.branchId);
    dispatch(setClickedProductRequestNearbyHubsAndBranch({
      id: quickOrderItem.productId,
      category: quickOrderItem.category,
      images: quickOrderItem.images,
      name: quickOrderItem.name,
    }));
    dispatch(setOpenPopupInquireNow(true));
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_inventory_request',
        user_type: getUserType(user?.email),
        branch_id: branch?.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
  },[branch?.id, dispatch, erpCustomerAddressCode, quickOrderItem.category, quickOrderItem.images, quickOrderItem.name, quickOrderItem.newInventory.branchId, quickOrderItem.productId, selectedAddressId, setEmailType, setFromBranchId, user?.customer?.erpCustomerCode, user?.email]);

  const renderProductStock = useCallback(() => {
    if (quickOrderItem.newInventory?.onHand > 0) {
      return (
        <StyledAvailableAndLeadTime onClick={handleClickLeadTime}>
          {t('products_and_search.available_and_lead_time', {
            onHand: quickOrderItem.newInventory.onHand,
            leadTime: quickOrderItem.newInventory.leadTime,
          }).toString()}
        </StyledAvailableAndLeadTime>
      );
    }
    if (quickOrderItem.inventoryStatus.waitingOnStock) {
      return <TextWaitingOnStock
        onClick={() => handleOpenEmailPopup(quickOrderItem.inventoryStatus)}
      >{t('quick_order.waiting_on_stock').toString()}</TextWaitingOnStock>
    }
    return null;
  }, [handleOpenEmailPopup, quickOrderItem?.inventoryStatus, quickOrderItem?.newInventory?.leadTime, quickOrderItem?.newInventory?.onHand, t])

  return (
    <tr>
      <td>
        {isClickEditSku ? (
          <SearchComponentWrapper>
            <SearchComponent
              handleOuterClick={() => {
                setTimeout(() => {
                  setIsClickEditSku(false)
                }, 1000)
              }}
              isSearchBySku
              isCurrent
              currentQuickOrderItem={quickOrderItem}
            />
          </SearchComponentWrapper>
        ) : (
          <TextWithPencil>
            <span title={quickOrderItem.erpSku || ''} className="text">{quickOrderItem.erpSku}</span>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              onClick={() => {
                setIsClickEditSku(true);
              }}
            >
              <Pencil />
            </div>
          </TextWithPencil>
        )}
      </td>
      <td>
        {isClickEditName ? (
          <SearchComponentWrapper>
            <SearchComponent
              handleOuterClick={() => {
                setTimeout(() => {
                  setIsClickEditName(false)
                }, 1000)
              }}
              isSearchBySku={false}
              isCurrent
              currentQuickOrderItem={quickOrderItem}
            />
          </SearchComponentWrapper>
        ) : (
          <TextWithPencil>
            <span title={quickOrderItem.name || ''} className="text">
              {quickOrderItem.name}
            </span>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              onClick={() => {
                setIsClickEditName(true);
              }}
            >
              <Pencil />
            </div>
          </TextWithPencil>
        )}
      </td>

      <td>
        {quickOrderItem.inventoryStatus.inStock && (
          <TextInStock
            onClick={() => handleOpenEmailPopup(quickOrderItem.inventoryStatus)}
          >
            {get(
              quickOrderItem,
              'inventoryStatus.status',
              quickOrderItem.inventoryStatus.origin
            )}
          </TextInStock>
        )}
        {renderProductStock()}
        {quickOrderItem.inventoryStatus.specialOrderString && !quickOrderItem.newInventory?.onHand && (
          <TextError
            onClick={() => handleOpenEmailPopup(quickOrderItem.inventoryStatus)}
          >
            {quickOrderItem.inventoryStatus.origin}
          </TextError>
        )}
      </td>

      <td>
        {currentPrice ? (
          <CartProductInputsWrapper>
            <CartProductInputs
              quickOrderItem={quickOrderItem}
              topInputValue={topInputValue}
              bottomInputValue={botVal}
              setTopInputValue={setTopInputValue}
              setBottomInputValue={() => {}}
              isDisabled={quickOrderItem.prices.length === 0}
              popperPlacement="right"
              onHasErrors={() => {}}
            />
            {!isHidePrice &&
            size(quickOrderItem.prices) > 1 &&
            quickOrderItem.quantity < quickOrderItem.prices[1].qty ? (
                <NextTierPriceWrapper>
                  <NextTierPriceInner onClick={handleClickOnPromotionQuantity}>
                    <NextTierPriceText>
                      {t('product_detail.buy')} {quickOrderItem.prices[1].qty}{' '}
                      {t('product_detail.for')} ${quickOrderItem.prices[1][deliveryType]}/
                      {quickOrderItem.units.perName}
                    </NextTierPriceText>
                  </NextTierPriceInner>
                </NextTierPriceWrapper>
              ) : (
                deliveryType !== 'hide' &&
              isShowPromotionPercent && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t('product_detail.saving_percent', {
                        percent: Math.ceil(
                          ((oldPrice - currentPrice[deliveryType] * currentPrice.qty) / oldPrice) *
                          100
                        ),
                      }),
                    }}
                  />
                )
              )}
          </CartProductInputsWrapper>
        ) : (
          <CartProductInputsWrapper>
            <CartProductInputs
              quickOrderItem={quickOrderItem}
              topInputValue={1}
              bottomInputValue={0}
              setTopInputValue={() => {}}
              setBottomInputValue={() => {}}
              isDisabled
              popperPlacement="right"
              onHasErrors={() => {}}
            />
          </CartProductInputsWrapper>
        )}
      </td>

      <td>
        {size(quickOrderItem.prices) > 0 && currentPrice && !isHidePrice ? (
          <TextPrice>
            <div className="md">
              <span className="price">${numberFormat(currentPrice[deliveryType])}</span>
              <span className="unit">/{quickOrderItem.units.perCode}</span>
            </div>
            {quickOrderItem.units.uom2Name && (
              <div className="sm">
                (${numberFormat(currentPrice[deliveryType] * quickOrderItem.units.uom2Conversion)}/
                {quickOrderItem.units.uom2Code})
              </div>
            )}
          </TextPrice>
        ) : (
          <RequestPricingButton onClick={onClickRequestPricing}>
            {t('quick_order.request_pricing').toString()}
          </RequestPricingButton>
        )}
      </td>

      <td>
        {size(quickOrderItem.prices) > 0 && currentPrice && !isHidePrice ? (
          <TextPrice>
            <div className="md">
              <span className="price">${numberFormat(quickOrderItem.total ? quickOrderItem.total: 0)}</span>
            </div>
          </TextPrice>
        ) : null}
      </td>

      <td>
        <DeleteButton variant="outlined" data-test-id="delete-product">
          <DeleteIcon onClick={handleRemoveQuickOrderItem} />
        </DeleteButton>
      </td>
    </tr>
  );
}