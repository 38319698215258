import styled from 'styled-components/macro';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';

const LocationWrapperTitle = styled.div`
  display: inline-flex;
  align-items: center;
  min-height: 20px;
  height: auto;
  background: #ebf2ee;
  border-radius: 4px;
  border: 1px solid ${({ theme: { colors } }) => colors.mainGreen};
  padding: 2px 5px;
  cursor: pointer;
  @media only screen and (max-width: 1023px) {
    padding: 5px;
  }
  > * {
    color: #757575;
    line-height: 1;
  }
  .ic-location {
    padding-right: 5px;
  }
  .txt-location {
    font-size: 12px;
  }
  .ic-arrow {
    svg {
      height: 20px;
    }
  }
`;

const AutocompleteWrapper = styled.div`
  position: relative;
  .ic-location {
  }
  .ic-arrow {
  }
  .ic-location-2 {
    position: absolute;
    top: 8px;
    left: 9px;
    z-index: 2;
  }
  .MuiAutocomplete-root {
    min-width: 150px;
    .MuiInputBase-root {
      background: #ebf2ee;
      border-radius: 4px;
      border: 1px solid ${({ theme: { colors } }) => colors.mainGreen};
      &:before,
      &:after {
        display: none;
      }
    }
    .MuiInputBase-input {
      padding-left: 25px !important;
    }
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 30px;
  }
  .MuiAutocomplete-popper {
    position: absolute;
    left: 0;
    top: 31px;
    min-width: 284px;
    @media only screen and (max-width: 599px) {
      right: 0;
      left: auto;
      min-width: 250px;
    }
    .MuiAutocomplete-listbox {
      max-height: 370px;
      li {
        position: relative;
        &.item-selected {
          width: 100%;
          color: ${({ theme: { colors } }) => colors.mainGreen};
          padding-right: 25px;
        }
        .ic-arrow {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }
`;

const StyledFormControl = styled(FormControl)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 40px;
  @media (max-width: 1200px) {
    max-width: 300px;
    margin-right: 20px;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const StyledSelect = styled(({ noArrow, isMobile, ...rest }) => <Select {...rest} />)`
  & .MuiSvgIcon-root.MuiSelect-icon {
    display: ${props => (props.noArrow ? 'none' : 'block')};
  }
  & .MuiSelect-select {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: ${props => (props.noArrow ? '0' : '6px 24px 7px 0')};
    div {
      display: none;
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: ${props => (props.isMobile ? '' : '150px')};
    }
  }
  & .MuiMenu-list {
    border: 1px solid red;
  }

  &:focus {
    background-color: ${({ theme: { colors } }) => colors.white};
  }
  && ul {
    padding: 0;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 16px 8px 16px 10px;
`;

const StyledParagraph = styled.p`
  margin: 0 8px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.letterGrey};
`;

export {
  LocationWrapperTitle,
  AutocompleteWrapper,
  StyledFormControl,
  StyledSelect,
  StyledMenuItem,
  StyledParagraph,
};
