import { IProductImage, IPrice, IUnits, IInventory } from 'models/interfaces/productVariant';
import { ProductClass } from 'models/classes/productClass';
import {IMainCategory, ITaxon} from 'models/interfaces/product';

export class AssociationVariantClass {
  id: number = null;

  images: IProductImage[];

  code: string = null;

  product: ProductClass = null;

  shortDescription: string = null;

  productCode: string = null;

  name: string = null;

  prices: IPrice[] = null;

  pricingMethod: string = null;

  inventory: IInventory = null;

  units: IUnits = null;

  mainTaxon: ITaxon = null;

  erpSku: string = null;
  
  category: IMainCategory;

  constructor(partial: Partial<AssociationVariantClass>) {
    Object.assign(this, partial);
  }
}
