import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { Flex } from './styles';

const FilterSkeleton = () => {
  return (
    <Skeleton variant="rect" width={540} height={728} style={{ marginTop: 12, marginRight: 10 }} />
  );
};

const ProductListSkeleton = () => {
  return (
    <Flex>
      <Skeleton variant="rect" height={352} width={208} style={{ margin: '12px 10px' }} />
      <Skeleton variant="rect" height={352} width={208} style={{ margin: '12px 10px' }} />
      <Skeleton variant="rect" height={352} width={208} style={{ margin: '12px 10px' }} />
      <Skeleton variant="rect" height={352} width={208} style={{ margin: '12px 10px' }} />
      <Skeleton variant="rect" height={352} width={208} style={{ margin: '12px 10px' }} />
      <Skeleton variant="rect" height={352} width={208} style={{ margin: '12px 10px' }} />
      <Skeleton variant="rect" height={352} width={208} style={{ margin: '12px 10px' }} />
      <Skeleton variant="rect" height={352} width={208} style={{ margin: '12px 10px' }} />
    </Flex>
  );
};

const ProductListMobileSkeleton = () => {
  return (
    <div>
      <Skeleton variant="rect" height={400} width="95%" style={{ margin: '12px 10px' }} />
      <Skeleton variant="rect" height={400} width="95%" style={{ margin: '12px 10px' }} />
      <Skeleton variant="rect" height={400} width="95%" style={{ margin: '12px 10px' }} />
    </div>
  );
};

export { FilterSkeleton, ProductListSkeleton, ProductListMobileSkeleton };
