import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import {
  StyledDialog,
  StyledDialogActions, StyledDialogButton,
  StyledDialogContent,
  StyledDialogContentText,
  StyledDialogTitle,
  StyledIconButton
} from './styles';

const PopupRegisterSuccess = ({openPopup, popupheader, popuptext, handleClose, popupclose}) => {
  return (
    <StyledDialog
      open={openPopup}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledIconButton aria-label="close" onClick={handleClose}>
        <CloseIcon />
      </StyledIconButton>
      <StyledDialogTitle id="alert-dialog-title" disableTypography>
        {popupheader}
      </StyledDialogTitle>
      <StyledDialogContent>
        <StyledDialogContentText id="alert-dialog-description">
          {popuptext}
        </StyledDialogContentText>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledDialogButton onClick={handleClose}>{popupclose}</StyledDialogButton>
      </StyledDialogActions>
    </StyledDialog>
  )
}

export default PopupRegisterSuccess;
