import styled from 'styled-components/macro';
import { FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ArrowRightAlt, DateRangeOutlined } from '@material-ui/icons';
import Close from '@material-ui/icons/Close';

const OrdersHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const DateWrapper = styled.div`
  display: flex;
  column-gap: 0px;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const SelectedFiltersBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  margin: 16px 0;
`;

const FilterBlock = styled.div`
  height: 32px;
  display: flex;
  column-gap: 8px;
  padding: 9px 12px;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  background: ${({ theme: { colors } }) => colors.lightGrey};
  margin-bottom: 48px;
  margin-right: 8px;

  @media (max-width: 1023px) {
    margin-bottom: 16px;
  }

  @media (max-width: 320px) {
    margin-bottom: 0;
  }
`;

const ClearAllFiltersButton = styled.div`
  height: 32px;
  display: flex;
  column-gap: 8px;
  padding: 9px 12px;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid ${({ theme: { colors } }) => colors.buttonGrey};
  box-sizing: border-box;
  border-radius: 4px;
  color: ${({ theme: { colors } }) => colors.mainGreen};
`;

const StyledSelect = styled(Select)`
  &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme: { colors } }) => colors.blackOpacity(0.23)};
  }
`;

const StyledMenuItem = withStyles({
  root: {
    display: 'block',
    textOverflow: 'ellipsis',
    minHeight: 'auto',
  },
})(MenuItem);

const StyledTextField = withStyles({
  root: {
    margin: '0 16px 24px 0',
    width: '274px',
    '& .MuiInputLabel-outlined': {
      fontFamily: 'Open Sans',
    },
    '& .MuiInputBase-root': {
      height: '40px',
      color: '#333333',
      fontFamily: 'Open Sans',
    },
    '& .MuiInputBase-multiline': {
      height: 'auto',
    },
    '& .MuiInputLabel-root': {
      color: '#939599',
      fontSize: '14px',
      transform: 'translate(14px, 14px) scale(1)',
      fontFamily: 'Open Sans',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -4px) scale(0.85)',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '17px 14px',
    },
    '& .MuiInputBase-formControl': {
      '&:hover': {
        borderColor: 'blue',
      },
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
    },
    '& .Mui-focused': {
      backgroundColor: '#F7F7F7',
    },
    '& .Mui-focused.MuiFormLabel-root': {
      backgroundColor: '#FFFFFF',
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      borderColor: '#00853e',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#C4C4C4',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00853e',
        },
      },
    },
    '@media (max-width: 1023px)': {
      width: '100%',
      margin: '0 0 16px 0',
    },
    '@media (max-width: 320px)': {
      '& .MuiInputBase-root': {
        height: '40px',
        margin: '0 0 16px 0',
      },
      '& .MuiInputBase-multiline': {
        height: 'auto',
      },
      '& .MuiInputLabel-root': {
        transform: 'translate(14px, 12px) scale(1)',
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -8px) scale(0.75)',
      },
    },
  },
})(TextField);

const StyledDateRangeField = withStyles({
  root: {
    margin: '0 0 16px 0',
    width: '274px',
    '& .Mui-disabled': {
      cursor: 'pointer',
    },
    '& .MuiInputBase-root': {
      height: '40px',
      color: '#333333',
      fontFamily: 'Open Sans',
    },
    '& .MuiInputBase-multiline': {
      height: 'auto',
    },
    '& .MuiInputLabel-root': {
      color: '#939599',
      fontSize: '14px',
      transform: 'translate(14px, 14px) scale(1)',
      fontFamily: 'Open Sans',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -4px) scale(0.85)',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '17px 14px',
    },
    '& .Mui-focused': {
      backgroundColor: '#F7F7F7',
    },
    '& .Mui-focused.MuiFormLabel-root': {
      backgroundColor: '#FFFFFF',
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      borderColor: '#00853e',
    },
    '@media (max-width: 1023px)': {
      width: '100%',
    },
    '@media (max-width: 320px)': {
      maxWidth: '250px',
      '& .MuiInputBase-root': {
        height: '40px',
      },
      '& .MuiInputBase-multiline': {
        height: 'auto',
      },
      '& .MuiInputLabel-root': {
        transform: 'translate(14px, 12px) scale(1)',
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -8px) scale(0.75)',
      },
    },
  },
})(TextField);

const StyledFormControl = withStyles({
  root: {
    fontFamily: 'Open Sans',
    width: '274px',
    margin: '0 16px 24px 0',
    '& .MuiInputBase-formControl': {
      height: '40px',
      color: '#333333',
      fontFamily: 'Open Sans',
    },
    '& .MuiInputLabel-root': {
      color: '#939599',
      fontSize: '14px',
      transform: 'translate(14px, 14px) scale(1)',
      backgroundColor: '#FFFFFF',
      paddingRight: '5px',
      fontFamily: 'Open Sans',
    },
    '& .MuiSelect-root': {
      fontFamily: 'Open Sans',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -4px) scale(0.85)',
    },
    '& .Mui-focused.MuiFormLabel-root': {
      backgroundColor: '#FFFFFF',
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      borderColor: '#00853e',
    },
    '@media (max-width: 1023px)': {
      width: '100%',
      margin: '0 0 16px 0',
    },
    '@media (max-width: 320px)': {
      height: 'auto',
      margin: '0 0 16px 0',
      '& .MuiInputBase-formControl': {
        height: '40px',
      },
      '& .MuiInputLabel-root': {
        transform: 'translate(14px, 12px) scale(1)',
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -8px) scale(0.75)',
      },
    },
  },
})(FormControl);

const CalendarIcon = withStyles({
  root: {
    cursor: 'pointer',
  },
})(DateRangeOutlined);

interface IArrowIconProps {
  sorttype: string;
}

const ArrowIcon = styled(ArrowRightAlt)`
  transform: rotate(${(props: IArrowIconProps) => (props.sorttype === 'DESC' ? '90' : '270')}deg);
  transition: transform 0.5s ease;
  cursor: pointer;
`;

const FilterClose = withStyles({
  root: {
    width: '14px',
    cursor: 'pointer',
  },
})(Close);

const MenuItemText = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Open Sans';
`;

const AutocompleteWrapper = styled.div`
  position: relative;
  .MuiFormControl-root {
    margin: 0;
  }
  .MuiInputBase-root {
    padding-left: 14px;
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 30px;
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 0;
  }
  .MuiAutocomplete-popper {
    position: absolute;
    left: 0;
    top: 40px;
    width: 100%;
    .MuiAutocomplete-listbox {
      max-height: 370px;
      @media only screen and (max-width: 599px) {
        min-width: 40vh;
      }
      li {
        position: relative;
        &.item-selected {
          width: 100%;
          color: ${({ theme: { colors } }) => colors.mainGreen};
          padding-right: 25px;
        }
        .ic-arrow {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }
`;

export {
  FilterClose,
  ArrowIcon,
  CalendarIcon,
  StyledFormControl,
  StyledDateRangeField,
  StyledTextField,
  StyledSelect,
  ClearAllFiltersButton,
  FilterBlock,
  SelectedFiltersBlock,
  DateWrapper,
  OrdersHeader,
  StyledMenuItem,
  MenuItemText,
  AutocompleteWrapper,
};
