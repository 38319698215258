import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

import Fade from 'react-reveal/Fade';
import { useFormik } from 'formik';
import { authErrorsSelector, getRememberMe, selectCheckedOtp, selectShowOtp } from 'ducks/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActionTypes } from 'ducks/auth/types';
import * as Yup from 'yup';
import { CheckedGreyCheckbox, GreyCheckbox } from 'assets/svg/SignIn';
import { bannerSelector } from 'ducks/application/selectors';
import { AppActionTypes } from 'ducks/application/types';
import Routes from 'routes';

import {forgetMe, reloadStateLoginLink, rememberMe, validateRecaptcha} from 'ducks/auth/actions';

import { useTranslation } from 'react-i18next';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { LoginWithMagicLinkModal } from 'pages/Landing/LoginWithMagicLinkModal/LoginWithMagicLinkModal';

import {useHistory} from 'react-router-dom';
import {
  GreenCheckbox,
  PasswordFieldWrapper,
  StyledButton,
  StyledCard,
  StyledCardActions,
  StyledCardContent,
  StyledCardHeader,
  StyledForgotLink,
  StyledFormControlLabel,
  StyledGrid,
  StyledPasswordField,
  StyledRecaptchaTokenError,
  StyledRequestLink,
  StyledTextField,
  StyledTypography,
  Or,
  LoginWithoutPasswordButton
} from './styles';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('invalid_email')
    .required('email_required')
    .max(255),
  password: Yup.string()
    .min(5, 'invalid_password')
    // .matches(/(?=.*[0-9])/, 'invalid_password')
    .required('password_required')
});

const initialValues = {
  email: '',
  password: '',
  otp: '',
};

const SignIn: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const banner = useSelector(bannerSelector);
  const error = useSelector(authErrorsSelector);
  const showOtp = useSelector(selectShowOtp);
  const checkedOtp = useSelector(selectCheckedOtp);

  const rememberMeState = useSelector(getRememberMe);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [showLoginWithMagicLinkModal, setShowLoginWithMagicLinkModal] = useState(false);

  const initialErrors = {};

  const initialTouched = {};

  const BannerImage = React.useMemo(
    () => styled.div`
      background: url(${banner}) center center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 72px;
      width: 320px;
      margin-bottom: 54px;
      @media (max-width: 737px) {
        margin-bottom: 14px;
        height: 57px;
        width: 100%;
      }
    `,
    [banner]
  );

  useEffect(() => {
    dispatch({ type: AppActionTypes.REQUEST_BANNER });
  }, [dispatch]);

  const { t, i18n } = useTranslation();
  const languageCode = i18n.language === 'fr' ? 'fr_CA' : 'en_CA';

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues,
    onSubmit: async ({ email }) => {
      if (!checkedOtp) {
        const recaptchaToken = await executeRecaptcha();
        dispatch(validateRecaptcha(email, recaptchaToken));
      } else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await submitLogin();
      }
    },
    validationSchema,
    initialErrors,
    initialTouched,
    enableReinitialize: false,
  });

  const submitLogin = async () => {
    const { email, password, otp } = values;

    const payload: any = {
      email,
      password,
      languageCode,
      otp,
    }
    if (!otp) {
      payload.recaptchaToken = await executeRecaptcha();
    }
    if (payload.otp.toString() === '') {
      delete payload.otp;
    }
    dispatch({
      type: AuthActionTypes.LOGIN,
      payload,
    });
  }

  useEffect(() => {
    if (checkedOtp && !showOtp) {
      submitLogin();
    }
  }, [dispatch, showOtp, checkedOtp]);

  useEffect(() => {
    dispatch({ type: AuthActionTypes.REMEMBER_ME });
  }, [dispatch]);

  const [checkedG, setState] = useState(rememberMeState);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.checked);
    if (event.target.checked) {
      dispatch(rememberMe());
    } else {
      dispatch(forgetMe());
    }
  };

  return (
    <>
      <Fade>
        <StyledGrid item xs={12} sm={8} md={8}>
          <StyledCard variant="outlined">
            <StyledCardHeader title={t('sign-in.title').toString()} />
            <form onSubmit={handleSubmit}>
              <StyledCardContent>
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  error={!!((errors?.email && touched.email) || error)}
                  label={t('sign-in.email').toString()}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    error?.errors?.email ? '' : errors?.email && touched.email && t(`sign-in.${errors.email}`).toString()
                  }
                  margin="dense"
                />
                <PasswordFieldWrapper>
                  <StyledPasswordField
                    fullWidth
                    variant="outlined"
                    error={!!((errors?.password && touched.password) || error)}
                    label={t('sign-in.password').toString()}
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      error
                        ? error?.errors?.password
                        : errors.password && touched.password && t(`sign-in.${errors.password}`)
                    }
                    margin="dense"
                  />
                </PasswordFieldWrapper>
                {
                  showOtp && <StyledTextField
                    fullWidth
                    type="number"
                    variant="outlined"
                    error={!!((errors.otp && touched.otp) || error)}
                    label={t('sign-in.otp').toString()}
                    name="otp"
                    value={values.otp}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      error?.errors?.otp && touched.otp && t('sign-in.otp_error').toString()
                    }
                    margin="dense"
                    style={{marginTop: '24px'}}
                  />
                }
                <StyledForgotLink to="/reset-password">
                  {t('sign-in.forgot_password').toString()}
                </StyledForgotLink>
                <StyledRecaptchaTokenError>
                  {error?.errors?.recaptchaToken && t('sign-in.recaptcha_token_error').toString()}
                </StyledRecaptchaTokenError>
                <StyledFormControlLabel
                  control={
                    <GreenCheckbox
                      icon={<GreyCheckbox />}
                      checkedIcon={<CheckedGreyCheckbox />}
                      onChange={handleCheckboxChange}
                      defaultChecked
                      name="checkedG"
                      value={checkedG}
                    />
                  }
                  label={t('sign-in.remember_me').toString()}
                />
              </StyledCardContent>
              <StyledCardActions>
                <Grid container direction="row">
                  <StyledButton type="submit" disabled={!(values.email && values.password)}>
                    {t('sign-in.button').toString()}
                  </StyledButton>

                  <Or className="or"><span>{t('sign-in.or').toString()}</span></Or>

                  <LoginWithoutPasswordButton onClick={() => setShowLoginWithMagicLinkModal(true)}>
                    {t('sign-in.login_without_password').toString()}
                  </LoginWithoutPasswordButton>

                  <StyledTypography>
                    {t('sign-in.join').toString()}{' '}
                    <StyledRequestLink to={Routes.HOME}>
                      {t('sign-in.request_account').toString()}
                    </StyledRequestLink>
                  </StyledTypography>
                  {banner ? <BannerImage /> : null}
                </Grid>
              </StyledCardActions>
            </form>
          </StyledCard>
        </StyledGrid>
      </Fade>

      {showLoginWithMagicLinkModal && (
        <LoginWithMagicLinkModal
          open={showLoginWithMagicLinkModal}
          callbackOnClose={() => setShowLoginWithMagicLinkModal(false)}
          setOpenModalRegisterModal={() => {
            dispatch(reloadStateLoginLink());
            history.replace('/?register=true');
          }}
        />
      )}

    </>
  );
};

export default SignIn;
