import { Chip, Grid, List, ListItemText, Typography, withStyles } from '@material-ui/core';
import styled from 'styled-components';

const StyledGridHeader = withStyles({
  root: {
    padding: '20px 70px',
  },
  '@media (max-width: 1023px)': {
    root: {
      padding: '10px',
    },
  },
})(Grid);

const StyledGridHeaderItem = withStyles({
  root: {
    flex: 1,
    textAlign: 'center',
  },
})(Grid);

const StyledTypography = styled(Typography)`
  font-family: Open Sans;
`;

const StyledDivider = styled.div`
  border-left: 1px solid ${({ theme: { colors } }) => colors.lightGrey};
`;

const StyledMonthDivider = styled.div`
  border-left: 1px solid ${({ theme: { colors } }) => colors.lightGrey};
  @media (max-width: 1023px) {
    border-left: none;
    border-top: 1px solid ${({ theme: { colors } }) => colors.lightGrey};
  }
`;

const StyledClearAll = styled.div`
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  font-family: Open Sans;
  color: ${({ theme: { colors } }) => colors.mainGreen};

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.lightGrey};
  }
`;
const StyledBottom = styled.div`
  border-top: 1px solid ${({ theme: { colors } }) => colors.lightGrey};
  position: sticky;
  bottom: 0;
  background-color: ${({ theme: { colors } }) => colors.white};
`;
const StyledMobileClearAll = styled(StyledClearAll)`
  border-top: 1px solid ${({ theme: { colors } }) => colors.lightGrey};
  text-align: center;
`;

const StyledList = withStyles({
  root: {
    paddingBottom: 0,
  },
})(List);

const StyledListItemText = styled(ListItemText)`
  .MuiTypography-root {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
  }
`;
const StyledChip = styled(Chip as any)`
  margin: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  border-radius: 4px;
  color: ${({ theme: { colors } }) => colors.searchDropdownText};
`;

const StyledChipContainer = styled.div`
  text-align: center;
  margin: 4px 0;
`;

export {
  StyledGridHeader,
  StyledGridHeaderItem,
  StyledTypography,
  StyledMonthDivider,
  StyledDivider,
  StyledBottom,
  StyledClearAll,
  StyledMobileClearAll,
  StyledList,
  StyledListItemText,
  StyledChip,
  StyledChipContainer,
};
