// Types
import { BranchActionTypes } from 'ducks/branch/types';

// Classes
import { BranchClass } from 'models/classes/branchClass';

export const storeBranch = (branch: BranchClass) => ({
  type: BranchActionTypes.REQUEST_BRANCH_SUCCESS,
  payload: {
    branch,
  },
});

export const fetchBranch = () => ({
  type: BranchActionTypes.REQUEST_BRANCH,
});

export const storeBranches = (branches: BranchClass[]) => ({
  type: BranchActionTypes.REQUEST_BRANCHES_SUCCESS,
  payload: {
    branches,
  },
});

export const fetchBranches = () => ({
  type: BranchActionTypes.REQUEST_BRANCHES,
});
