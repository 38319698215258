import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import RequestPricingPopup from 'components/Popups/RequestPricing/RequestPricingPopup';
import { ProductVariantClass } from 'models/classes/productVariantClass';
import {
  priceTypeSelector,
  selectAddressId,
  selectErpCustomerAddressCode,
} from 'ducks/application/selectors';
import midDefaultProduct from 'assets/images/default-mid-product.png';
import { useRequestPricing } from 'hooks/useRequestPricing';
import {
  setClickedProductRequestNearbyHubsAndBranch,
  setNearbyBranchData,
  setOpenPopupInquireNow,
  setOpenPopupNearbyBranch,
} from 'ducks/product/actions';
import TagManager from 'react-gtm-module';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { useHistory, useLocation } from 'react-router-dom';
import findIndex from 'lodash/findIndex';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';

import { getDefaultProductName } from 'utils/products/productUtilsHelpers';
import { getDefaultCategoryName } from 'utils/categories/categories';
import { selectCategories } from 'ducks/category/selectors';
import { NearbyBranchEmailType } from 'ducks/productVariant/types';
import { NearbyBranchContext } from 'context/NearbyBranchContext';
import { createUserActionLog } from 'api/UserActionLog';
import {
  StyledLink,
  ProductName,
  ProductInfoWrapper,
  AvatarWrapper,
  DefaultAvatarWrapper,
  ProductCard,
  ProductCardGrid,
  StyledAvailableAndLeadTime,
  StyledCheckNearByBranch,
} from './styles';
import ProductTableCardWithCheckoutNoPrice from '../ProductTableCardWithCheckoutNoPrice';

interface ITableCard {
  products: ProductVariantClass[];
  productVar: ProductVariantClass;
  categoryName?: string;
  code?: string;
  mainCategoryName?: string;
  id?: string;
  renderProductStock: CallableFunction;
  handleTableProductViewChange: CallableFunction;
}

const ProductTableCard: React.FC<ITableCard> = ({
  products,
  productVar,
  categoryName,
  code,
  mainCategoryName,
  id,
  renderProductStock,
  handleTableProductViewChange,
}: ITableCard) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isSearchPage = location.pathname.includes('/search');
  const history = useHistory();
  const priceType = useSelector(priceTypeSelector);
  const addressId = useSelector(selectAddressId);
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const categories = useSelector(selectCategories);
  const { isRequestPricing, setIsRequestPricing, handleRequestPricingClick } = useRequestPricing(
    productVar,
  );
  const { setEmailType, setFromBranchId } = React.useContext(NearbyBranchContext);

  const { t, i18n } = useTranslation();

  const handleRequestNearbyHubsAndBranches = () => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_view_other_branch_inventory_category',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: addressId,
      },
    });
    dispatch(setClickedProductRequestNearbyHubsAndBranch(productVar));
    dispatch(
      setNearbyBranchData({
        nearbyHubs: productVar.nearbyHubs,
        nearbyBranches: productVar.nearbyBranches,
      }),
    );
    setTimeout(() => {
      dispatch(setOpenPopupNearbyBranch(true));
    }, 100);
  };

  const handleClickNavigate = e => {
    e.preventDefault();
    let itemListName;
    let itemListId;
    const { itemCategoryName, itemCategory2Name } = getDefaultCategoryName(
      productVar.category.id,
      categories,
    );
    if (history.location.pathname.includes('/search')) {
      itemListName = 'Search Results Full';
      itemListId = 'search_results_full';
    } else {
      itemListName = itemCategory2Name;
      itemListId = itemCategory2Name
        .toLowerCase()
        .split(' ')
        .join('_');
    }
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'select_item',
        ecommerce: {
          item_list_id: itemListId,
          item_list_name: itemListName,
          items: [
            {
              item_id: productVar.erpSku,
              item_name: getDefaultProductName(productVar),
              price: productVar.prices.length ? productVar.prices[0][priceType] : 0,
              item_category: itemCategoryName,
              item_category2: itemCategory2Name,
              item_list_id: itemListId,
              item_list_name: itemListName,
              index: findIndex(products, product => product.id === productVar.id) + 1,
              quantity: 1,
            },
          ],
        },
      },
    });
    history.push({
      pathname: `/product/${productVar.code}`,
      state: {
        subcategoryName: categoryName,
        subcategoryCode: code,
        mainCategoryName,
        mainCategoryCode: id,
        variantCode: productVar.code,
        variantId: productVar.id,
      },
    });
  };

  const handleClickProductStockLeadTime = useCallback(() => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_inventory_request',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: addressId,
      },
    });
    setEmailType(NearbyBranchEmailType.HUB_BRANCH_WITH_INVENTORY_AVAILABLE);
    setFromBranchId(productVar.newInventory.branchId);
    dispatch(setClickedProductRequestNearbyHubsAndBranch(productVar));
    dispatch(setOpenPopupInquireNow(true));
  }, [addressId, branch, dispatch, erpCustomerAddressCode, productVar, setEmailType, setFromBranchId, user]);

  return (
    <ProductCardGrid key={productVar.name} className="product-card-grid-2">
      <ProductCard className="product-card-2">
        <ProductInfoWrapper>
          <StyledLink
            to={{
              pathname: `/product/${productVar.code}`,
              state: {
                subcategoryName: categoryName,
                subcategoryCode: code,
                mainCategoryName,
                mainCategoryCode: id,
                variantCode: productVar.code,
                variantId: productVar.id,
              },
            }}
            onClick={handleClickNavigate}
          >
            {productVar.image ? (
              <AvatarWrapper>
                <img src={productVar.image} alt="product avatar" />
              </AvatarWrapper>
            ) : (
              <DefaultAvatarWrapper>
                <img src={midDefaultProduct} alt="default product avatar" />
              </DefaultAvatarWrapper>
            )}
            <ProductName title={productVar.name}>{productVar.name}</ProductName>
          </StyledLink>

          {productVar.newInventory?.onHand > 0 && !productVar.inventory.isStocked ? (
            <StyledAvailableAndLeadTime
              className={`${i18n?.language}`}
              onClick={async () => {
                await createUserActionLog(isSearchPage ? 'Search Page Inventory Status' : 'Catalog Page Inventory Status', 'Inventory Request');
                handleClickProductStockLeadTime();
              }}
            >
              {t('products_and_search.available_and_lead_time', {
                onHand: productVar.newInventory.onHand,
                leadTime: productVar.newInventory.leadTime,
              }).toString()}
            </StyledAvailableAndLeadTime>
          ) : (
            renderProductStock(productVar)
          )}

          {productVar?.nearbyHubs?.length > 0 && (
            <StyledCheckNearByBranch
              className={`${i18n?.language}`}
              onClick={async () => {
                await createUserActionLog(isSearchPage ? 'Search Page Nearby Branch' : 'Catalog Page Nearby Branch', 'Multi-branch Inquiry');
                handleRequestNearbyHubsAndBranches();
              }}
            >
              <span>{t('products_carousel.check_nearby_branch').toString()}</span>
            </StyledCheckNearByBranch>
          )}

          <ProductTableCardWithCheckoutNoPrice
            productVar={productVar}
            handleTableProductViewChange={handleTableProductViewChange}
            handleRequestPricingClick={handleRequestPricingClick}
          />
        </ProductInfoWrapper>
      </ProductCard>
      {isRequestPricing ? (
        <RequestPricingPopup setRequestPricing={setIsRequestPricing} product={productVar} />
      ) : null}
    </ProductCardGrid>
  );
};
export default ProductTableCard;
