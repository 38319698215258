import React from 'react';

const context = React.createContext({
  values: null,
  touched: null,
  errors: null,
  handleChange: null,
  handleBlur: null,
  handleSubmit: null,
  setFieldValue: null,
  poRef: null,
  requestDateRef: null,
  transactionTypeRef: null,
  setErrors: null
});
context.displayName = 'FormContext';
export default context;
