import React, { useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { IPagination } from '../models/interfaces/productVariant';
import { selectAddressId, selectLanguage } from '../ducks/application/selectors';

export const ProductFilterContext = React.createContext(undefined);

const ProductFilterProvider: React.FC<React.ReactNode> = ({children}: any) => {
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    itemsPerPage: 12,
  });
  const [filter, setFilter] = useState({});
  const [show, setShow] = useState(false);
  const [selectedView, setSelectedView] = useState('table');
  const [firstLoad, setFirstLoad] = useState(true);
  const [inStock, setInStock] = useState(false);
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const addressId = useSelector(selectAddressId);
  const selectedLanguage = useSelector(selectLanguage);

  return (
    <ProductFilterContext.Provider
      value={{
        pagination,
        setPagination,
        filter,
        setFilter,
        show,
        setShow,
        selectedView,
        setSelectedView,
        firstLoad,
        setFirstLoad,
        isMobile,
        addressId,
        selectedLanguage,
        inStock,
        setInStock
      }}
    >
      {children}
    </ProductFilterContext.Provider>
  );
}

export default ProductFilterProvider;