import { IBucket, IFacetConfiguration } from 'models/interfaces/productVariant';

export class FacetClass {
  label: string = null;

  property: string = null;

  buckets: IBucket[] = null;

  position: string = null;

  type: string = null;

  configuration: IFacetConfiguration = null;

  constructor(...args) {
    args.forEach((item: FacetClass) => {
      this.label = item.label || null;
      this.property = item.property || null;
      this.buckets = item.buckets || null;
      this.position = item.position || null;
      this.configuration = item.configuration || null;
      this.type = item.type || null;
    });
    args.map(facet =>
      facet.buckets.sort((a, b) => a.label.localeCompare(b.label, ['en', 'fr'], { numeric: true }))
    );
  }
}
