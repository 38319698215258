import React, { useCallback } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Slider } from 'components/common';
import { selectUserData } from 'ducks/user/selectors';
import { getMonthsBetweenDates } from 'utils/common/common';
import MonthlyPopups from 'components/Popups/MonthlyPopupsWrapper';
import Routes from 'routes';
// import { GlobalSurveyWrapper } from 'components/Survey/GlobalSurveyWrapper';
import StartSettingsPopup from 'components/Popups/StartSettings/StartSettingsPopup';
import { Root, Content } from './styles';

interface ILayout {
  children: React.ReactNode;
  withContainer: boolean;
}

const StoreComponent: React.FC<ILayout> = ({ children, withContainer }: ILayout) => {
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const location = useLocation();
  const user = useSelector(selectUserData);
  const monthsBetweenAddressSets = user?.updatedAt
    ? getMonthsBetweenDates(new Date(user?.updatedAt), new Date())
    : 1;

  const PopupComponent = useCallback(() => {
    if (user) {
      if (!user.defaultAddress) {
        return <StartSettingsPopup user={user} />;
      }
      if (monthsBetweenAddressSets >= 1) {
        return <MonthlyPopups user={user} />;
      }
    }
    return null;
  }, [user]);

  return (
    <Root>
      <PopupComponent />
      {withContainer ? <Content>{children}</Content> : children}
      {(isMobile && location.pathname === Routes.CATEGORIES) ||
        (isMobile && location.pathname === `${Routes.SEARCH}/empty` && <Slider />)}
      {/* {!isMobile && <GlobalSurveyWrapper />} */}
    </Root>
  );
};

export default StoreComponent;
