import React from 'react';
import { ListItem } from '@material-ui/core';
import {
  DefinedRange,
  DateRange,
} from 'pages/General/Profile/components/OrdersAndQuotes/DateRangePicker/types';
import {
  StyledChip,
  StyledChipContainer,
  StyledList,
  StyledListItemText,
} from 'pages/General/Profile/components/OrdersAndQuotes/DateRangePicker/styles';
import isSameDay from 'date-fns/isSameDay';

type DefinedRangesProps = {
  setRange: (range: DateRange) => void;
  selectedRange: DateRange;
  ranges: DefinedRange[];
  isMobile?: boolean;
};

const isSameRange = (first: DateRange, second: DateRange) => {
  const { startDate: fStart, endDate: fEnd } = first;
  const { startDate: sStart, endDate: sEnd } = second;

  if (fStart && sStart && fEnd && sEnd) {
    return isSameDay(fStart, sStart) && isSameDay(fEnd, sEnd);
  }
  return false;
};

const DefinedRanges: React.FC<DefinedRangesProps> = ({
  setRange,
  selectedRange,
  ranges,
  isMobile,
}: DefinedRangesProps) => {
  return isMobile ? (
    <StyledChipContainer>
      {ranges.map(range => (
        <StyledChip
          label={range.label}
          variant="outlined"
          key={range.label}
          onClick={() => setRange(range)}
        />
      ))}
    </StyledChipContainer>
  ) : (
    <StyledList>
      {ranges.map(range => (
        <ListItem button key={range.label} onClick={() => setRange(range)}>
          <StyledListItemText
            primaryTypographyProps={{
              variant: 'body2',
              style: {
                fontWeight: isSameRange(range, selectedRange) ? 'bold' : 'normal',
              },
            }}
          >
            {range.label}
          </StyledListItemText>
        </ListItem>
      ))}
    </StyledList>
  );
};

export default DefinedRanges;
