import React, { useEffect, useState } from 'react';
import { withStyles, CheckboxProps, Checkbox } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { addDotToPrice } from 'utils/common/common';
import { OrderClass } from 'models/classes/orderClass';
import { QuoteClass } from 'models/classes/quoteClass';
import { selectIsHidePrice, selectLanguage } from 'ducks/application/selectors';
import { goToOrderClickGAEvent } from 'GA/OrderGA/OrderGA';
import { IReOrderResponse } from 'api/ReOrder';
import { usePermission } from 'hooks/usePermission';
import { getSaleType } from 'utils/order/order';
import upperFirst from 'lodash/upperFirst';
import get from 'lodash/get';
import { Mailbox } from 'assets/svg/Checkout/Checkout';
import {
  HeaderButton,
  HeaderButtonText,
  ButtonBlock,
  CardHeader,
  CreatedBlock,
  HeaderText,
  OrderBlock,
  OrderBlockInfo,
  OrderBlockInfoWrapper,
  PaddingText,
  StatusText,
  StyledCardContent,
  StyledIconButton,
  TotalBlock,
  TotalText,
  UserBlock,
  TotalSection,
  TotalWrapper,
  NotificationInvoiced,
  StyledDate,
  StyledButton,
  ButtonTitle,
  UserOptInForOrderUpdateEmails,
} from './styles';
import { useUpdateOptInOrder } from '../../../../../../../../api/Order';

interface ICardContentHeader {
  element: OrderClass | QuoteClass;
  isQuotes: boolean;
  statusText: string;
  expanded: boolean;
  handleExpandClick: () => void;
  // eslint-disable-next-line no-undef
  renderTag: () => JSX.Element;
  handleReorderClick: () => void;
  isReorder?: boolean;
  openOrderTab?: (orderNumber: number) => void;
  reOrderItems?: IReOrderResponse;
  goodsTotal: number;
  loadingGetOrderDetail: boolean;
  handleClickInquireNow: CallableFunction;
  isShowOtpInOrderUpdateEmail: boolean;
}

interface IReorderButton {
  isReorder: boolean;
  handleReorderClick: () => void;
  handleClickInquireNow: CallableFunction;
}

const GreenCheckbox = withStyles({
  root: {
    color: '#b2becc',
    '&$checked': {
      color: '#00853e',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const ReorderButton = ({
  isReorder,
  handleReorderClick,
  handleClickInquireNow,
}: IReorderButton) => {
  const { i18n, t } = useTranslation();
  const { canPlaceQuote, canPlaceOrder } = usePermission();

  return (
    !isReorder && (
      <>
        {
          canPlaceOrder && (
            <ButtonBlock className={`button-block-reorder ${i18n?.language}`}>
              <StyledButton onClick={handleReorderClick}>
                <ButtonTitle>{t('orders_and_quotes.reorder').toString()}</ButtonTitle>
              </StyledButton>
            </ButtonBlock>
          )
        }
        {
          (canPlaceQuote || canPlaceOrder) && (
            <ButtonBlock className={`button-block-inquire-now ${i18n?.language}`}>
              <HeaderButton onClick={() => handleClickInquireNow()}>
                <HeaderButtonText>{t('product_detail.inquire_now').toString()}</HeaderButtonText>
              </HeaderButton>
            </ButtonBlock>
          )
        }
      </>
    )
  );
};

const CardContentHeader: React.FC<ICardContentHeader> = ({
  element,
  isQuotes,
  statusText,
  expanded,
  handleExpandClick,
  renderTag,
  handleReorderClick,
  isReorder,
  openOrderTab,
  reOrderItems,
  goodsTotal,
  loadingGetOrderDetail,
  handleClickInquireNow,
  isShowOtpInOrderUpdateEmail
}: ICardContentHeader) => {
  const { i18n, t } = useTranslation();
  const isHidePrice = useSelector(selectIsHidePrice);
  const { canSeePrice, canPlaceOrder, canPlaceQuote } = usePermission();
  const language = useSelector(selectLanguage);
  const [optInOrderUpdate, setOptInOrderUpdate] = useState(element.optInOrderUpdate);

  const {mutate, isSuccess} = useUpdateOptInOrder(element.id);

  useEffect(() => {
    if (isSuccess) {
      setOptInOrderUpdate(prev => !prev);
    }
  }, [isSuccess]);

  const GoToOrderButton = () =>
    element?.acceptedOrderNumber ? (
      <ButtonBlock>
        <HeaderButton
          quote
          onClick={() => {
            openOrderTab(element?.acceptedOrderNumber);
            goToOrderClickGAEvent();
          }}
        >
          <HeaderButtonText>{t('orders_and_quotes.go_to_order').toString()}</HeaderButtonText>
        </HeaderButton>
      </ButtonBlock>
    ) : null;

  return (
    <StyledCardContent>
      <CardHeader>
        <OrderBlock>
          <OrderBlockInfo>
            <HeaderText>
              {isQuotes
                ? `${upperFirst(t('orders_and_quotes.sm_quote'))}#:`
                : `${upperFirst(t('orders_and_quotes.sm_order'))}#:`}
              &nbsp;
            </HeaderText>
            {element.erpOrderNumber}
          </OrderBlockInfo>
          {get(element, 'purchaseOrderNumber', null) && (
            <OrderBlockInfo>
              <HeaderText>Taiga PO#:</HeaderText>
              {element.purchaseOrderNumber}
            </OrderBlockInfo>
          )}
          {get(element, 'customerRef') && (
            <OrderBlockInfo>
              <HeaderText>{t('orders_and_quotes.po').toString()}#:&nbsp;</HeaderText>
              {element.customerRef}
            </OrderBlockInfo>
          )}

          {!element.isOffline ? (
            <PaddingText type>
              {t('orders_and_quotes.online_order', {
                name: isQuotes
                  ? upperFirst(t('orders_and_quotes.sm_quote'))
                  : upperFirst(t('orders_and_quotes.sm_order')),
              }).toString()}
            </PaddingText>
          ) : (
            <PaddingText type>
              {t('orders_and_quotes.offline_order', {
                name: isQuotes
                  ? upperFirst(t('orders_and_quotes.sm_quote'))
                  : upperFirst(t('orders_and_quotes.sm_order')),
              }).toString()}
            </PaddingText>
          )}

          {renderTag()}
        </OrderBlock>

        <CreatedBlock isFrench={false}>
          <OrderBlockInfoWrapper>
            {statusText && (
              <>
                <HeaderText>{t('orders_and_quotes.status').toString()}:&nbsp;</HeaderText>
                <StatusText>
                  {t(
                    `process.${statusText
                      .toLowerCase()
                      .split(' ')
                      .join('_')}`
                  ).toString()}
                </StatusText>
              </>
            )}
          </OrderBlockInfoWrapper>
          <PaddingText>
            <HeaderText>{t('orders_and_quotes.date_created').toString()}:&nbsp;</HeaderText>
            <StyledDate>{element.createdAt}</StyledDate>
          </PaddingText>
          {element.dateRequired ? (
            <PaddingText>
              <HeaderText>
                {element.saleType.toLowerCase() === 'pickup' ||
                element.saleType.toLowerCase() === 'will call'
                  ? t('orders_and_quotes.est_pickup_date').toString()
                  : t('orders_and_quotes.est_ship_date').toString()}
                :&nbsp;
              </HeaderText>
              <StyledDate>{element.dateRequired}</StyledDate>
            </PaddingText>
          ) : null}
        </CreatedBlock>

        <UserBlock>
          <PaddingText>
            <HeaderText>{t('orders_and_quotes.type').toString()}:&nbsp;</HeaderText>{' '}
            {t(`orders_and_quotes.${getSaleType(element.saleType)}`).toString()}
          </PaddingText>

          <PaddingText>
            <HeaderText>{t('orders_and_quotes.contact_name').toString()}:&nbsp;</HeaderText>{' '}
            {element.contactName}
          </PaddingText>

          <PaddingText>
            <HeaderText>{t('orders_and_quotes.address').toString()}:&nbsp;</HeaderText>{' '}
            {element.deliveryAddress}
          </PaddingText>
        </UserBlock>

        <TotalBlock isFrench={language === 'fr_CA'}>
          <TotalSection>
            <TotalWrapper>
              {canSeePrice && !isHidePrice && (
                <>
                  <HeaderText textAlign="end">{t('orders_and_quotes.order_total').toString()}</HeaderText>
                  <TotalText>
                    ${addDotToPrice(String(isReorder && reOrderItems ? goodsTotal : element.total))}
                  </TotalText>
                </>
              )}
            </TotalWrapper>

            <StyledIconButton
              expanded={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              disabled={loadingGetOrderDetail}
            >
              <ExpandMoreIcon />
            </StyledIconButton>
          </TotalSection>

          {isQuotes && canPlaceQuote && (
            <ButtonBlock className={`button-block-inquire-now ${i18n?.language}`}>
              <HeaderButton onClick={() => handleClickInquireNow()}>
                <HeaderButtonText>{t('product_detail.inquire_now').toString()}</HeaderButtonText>
              </HeaderButton>
            </ButtonBlock>
          )}

          {isQuotes ? (
            <GoToOrderButton />
          ) : (
            canSeePrice &&
            canPlaceOrder &&
            !isHidePrice && (
              <ReorderButton
                handleReorderClick={handleReorderClick}
                isReorder={isReorder}
                handleClickInquireNow={handleClickInquireNow}
              />
            )
          )}
        </TotalBlock>
      </CardHeader>
      {expanded &&
        get(element, 'tnStatus') &&
        get(element, 'tnStatus', '').toLowerCase() === 'invoiced' && (
        <NotificationInvoiced>
          <div className="box-ic">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.6585 26.4583L7.70846 19.5083L5.3418 21.8583L14.6585 31.175L34.6585 11.175L32.3085 8.825L14.6585 26.4583Z"
                fill="white"
              />
            </svg>
          </div>

          <div className="box-text">
            <div className="lg">{t('orders_and_quotes.invoiced').toString()}</div>
            {element.saleType.toLowerCase() === 'will call' ? (
              <div className="md">{t('process.order_picked_up_and_invoiced').toString()}</div>
            ) : (
              <div className="md">{t('process.order_delivery_and_invoiced').toString()}</div>
            )}
          </div>
        </NotificationInvoiced>
      )}
      {
        canPlaceOrder && !isQuotes && isShowOtpInOrderUpdateEmail && (
          <UserOptInForOrderUpdateEmails>
            <span className="ic">
              <Mailbox />
            </span>
            <div className="txt-message">
              {t('checkout.opt_in_order').toString()}
            </div>
            <div className="checkbox-wrapper">
              <GreenCheckbox checked={optInOrderUpdate} onClick={() => {
                mutate();
              }} />
            </div>
          </UserOptInForOrderUpdateEmails>
        )
      }
    </StyledCardContent>
  );
};

export default CardContentHeader;
