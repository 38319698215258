import React, {useCallback, useMemo} from 'react';
import TagManager from 'react-gtm-module';

import {IOptionValue, IOption, FindMatchProduct} from 'models/interfaces/product';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { useSelector } from 'react-redux';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { selectAddressId, selectErpCustomerAddressCode } from 'ducks/application/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import reduce from 'lodash/reduce';
import {Tooltip} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {AttributeOption, AttributeWrapperV2} from './styles';
import {DisabledItemTooltip} from '../../styles';

interface IAttributesDropdown {
  options: IOptionValue[];
  option: IOption;
  handleAttributeClick(name: string, attrValue: string|number): void;
  selected: string;
  findMatchProducts: Array<FindMatchProduct> | [];
}

const AttributesDropdownV2 = React.memo(({
  options,
  option,
  handleAttributeClick,
  selected,
  findMatchProducts
}: IAttributesDropdown) => {
  const {t} = useTranslation();
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);


  const handleChange = (optionValue: IOptionValue) => {
    handleAttributeClick(optionValue.optionCode, option.code);
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'change_option',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
  }

  const getOptionClass = useCallback((optionValue: IOptionValue): string => {
    if (optionValue.optionCode === selected) {
      return 'active';
    }

    if (findMatchProducts && findMatchProducts.length) {
      const pickItemCode = map(findMatchProducts, 'itemCode');

      const pickItemCodeObject = flatten(pickItemCode);
      const resultObject = reduce(
        pickItemCodeObject,
        (acc, value) => {
          const innerKey = Object.keys(value)[0];
          const innerValue = value[innerKey];

          // Check if the innerKey exists in the resultObject
          // eslint-disable-next-line no-prototype-builtins
          if (acc.hasOwnProperty(innerKey)) {
            // If it exists, push the value to the existing array
            acc[innerKey].push(innerValue);
          } else {
            // If it doesn't exist, create a new array with the value
            acc[innerKey] = [innerValue];
          }

          return acc;
        },
        {} as { [key: string]: string[] }
      );
      Object.keys(resultObject).forEach(key => {
        resultObject[key] = Array.from(new Set(resultObject[key]));
      });
      const match = resultObject[optionValue.code]?.includes(optionValue.optionCode);
      if (!match) {
        return 'disabled';
      }
    }
    if(findMatchProducts.length === 0) {
      return 'disabled';
    }

    return '';
  }, [findMatchProducts, selected]);

  const renderDisabledItemTooltip = useMemo(() => <DisabledItemTooltip>
    <div className="md-1">{t('product_detail.conflict_with')}</div>
    <div className="md-2">{t('product_detail.click_to_see_options')}</div>
  </DisabledItemTooltip>, [t])

  const renderItem = (optionValue: IOptionValue) => {
    if (getOptionClass(optionValue) === 'disabled') {
      return (
        <Tooltip title={renderDisabledItemTooltip} placement="top" arrow>
          <>
            <AttributeOption
              key={optionValue.optionCode}
              title={`${optionValue.value}`}
              type="button"
              onClick={() => handleChange(optionValue)}
              className={getOptionClass(optionValue)}
            >
              {optionValue.value}
            </AttributeOption>
          </>
        </Tooltip>
      )
    }
    return (
      <AttributeOption
        key={optionValue.optionCode}
        title={`${optionValue.value}`}
        type="button"
        onClick={() => handleChange(optionValue)}
        className={getOptionClass(optionValue)}
      >
        {optionValue.value}
      </AttributeOption>
    )
  }

  return (
    <AttributeWrapperV2>
      {
        options.map((optionValue: IOptionValue) => (
          <div key={optionValue.optionCode} className="item-attribute">{renderItem(optionValue)}</div>
        ))
      }
    </AttributeWrapperV2>
  );
});

export { AttributesDropdownV2 };
