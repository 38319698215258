export const getSaleType = (saleType: string) => {
  if (saleType.toLowerCase() === 'direct') {
    return 'direct';
  }
  const deliveryType = ['Delivered', 'delivery'];
  if (deliveryType.includes(saleType)) {
    return 'delivery';
  }
  const pickupType = ['Will Call', 'pickup'];
  if (pickupType.includes(saleType)) {
    return 'pickup';
  }
  return saleType;
};

export const getOrderProcessStatus = (saleType: string) => {
  if (saleType.toLowerCase() === 'direct') {
    return [
      {
        status: 'Order Placed',
        label: 'process.order_placed',
      },
      {
        status: 'Waiting On Stock',
        label: 'process.waiting_on_stock',
      },
      {
        status: 'Shipped',
        label: 'process.shipped',
      },
    ];
  }
  if (saleType.toLowerCase() === 'delivery' || saleType.toLowerCase() === 'delivered') {
    return [
      {
        status: 'Order Placed',
        label: 'process.order_placed',
      },
      {
        status: 'Waiting On Stock',
        label: 'process.waiting_on_stock',
      },
      {
        status: 'Picking',
        label: 'process.picking',
      },
      {
        status: 'Shipped',
        label: 'process.shipped',
      },
    ];
  }
  if (saleType.toLowerCase() === 'will call' || saleType.toLowerCase() === 'pickup') {
    return [
      {
        status: 'Order Placed',
        label: 'process.order_placed',
      },
      {
        status: 'Waiting on Stock',
        label: 'process.waiting_on_stock',
      },
      {
        status: 'Picking',
        label: 'process.picking',
      },
      {
        status: 'Picked Up',
        label: 'process.picked_up',
      },
    ];
  }
  return [];
};

export const getActiveStep = (saleType: string, status: string) => {
  const steps = getOrderProcessStatus(saleType);
  let step2 = status;
  if (status.toLowerCase() === 'confirmed') {
    step2 = 'Waiting on Stock';
  }
  if (steps.length) {
    const index = steps.findIndex(step => {
      return step.status.toLowerCase() === step2.toLowerCase();
    });

    if (index !== -1) {
      return index;
    }
  }
  return -1;
};
