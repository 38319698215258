import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import axios, {baseURL} from 'utils/axios';
import axiosRequest from 'axios';
// Types
import {
  AuthActionTypes, ChangeDisplayLanguageAction, CreateMagicLinkAction,
  LoginAction, LoginByMagicLinkAction,
  RecoverPasswordAction,
  ResetPasswordAction, ValidateRecaptchaTokenAction,
  VerifyTokenAction,
} from 'ducks/auth/types';

// Classes
import {storeErrors} from 'utils/errors';
import {TokenStorage} from 'utils/tokenStorage';
import {AppStorage} from 'components/App/services/storage';
import get from 'lodash/get';
import {selectLanguage} from '../application/selectors';
import {resetCategories} from '../category/actions';
import {selectCheckedOtp} from './selectors';

function addAuthorizationHeader(accessToken: string) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
}

function* checkAuth() {
  yield put({type: AuthActionTypes.REQUEST});

  try {
    if (TokenStorage.isAuthenticated()) {
      addAuthorizationHeader(TokenStorage.getToken());
      yield put({
        type: AuthActionTypes.LOGIN_SUCCESS,
        payload: {user: TokenStorage.currentUser()},
      });
    } else {
      yield put({type: AuthActionTypes.LOGOUT});
    }
  } catch (error) {
    yield put({type: AuthActionTypes.LOGOUT});
  }
}

function* verifyToken(verifyTokenAction: VerifyTokenAction) {
  const {token, email} = verifyTokenAction.payload;
  yield put({type: AuthActionTypes.REQUEST});

  try {
    yield call(axios.post, '/api/auth/reset-password/verify-reset-password-token', {token, email});
    yield put({type: AuthActionTypes.VERIFY_TOKEN_SUCCESS});
  } catch (error) {
    yield storeErrors(AuthActionTypes.VERIFY_TOKEN_ERROR, [404, 400, 422], error.response.data);
  }
}

function* validateRecaptchaToken(validateRecaptchaTokenAction: ValidateRecaptchaTokenAction) {
  try {
    const {email, recaptchaToken} = validateRecaptchaTokenAction.payload;
    const {data: validateRecaptcha} = yield call(axios.post, '/api/auth/validate-recaptcha', {email, recaptchaToken});
    if (!validateRecaptcha.data.nextStep) {
      yield put({type: AuthActionTypes.SHOW_OTP});
    }
    yield put({type: AuthActionTypes.REQUEST_VALIDATE_RECAPTCHA_SUCCESS, payload: {isCheck: true}});
  } catch (err) {
    yield storeErrors(AuthActionTypes.REQUEST_VALIDATE_RECAPTCHA_ERROR, [401, 422], err.response);
  }
}

function* login(loginAction: LoginAction) {
  const {email, password, recaptchaToken, languageCode, otp, fromReset} = loginAction.payload;

  const loginParams = {
    email,
    password,
    recaptchaToken,
    otp,
    fromReset
  };

  yield put({type: AuthActionTypes.REQUEST});

  try {
    if (!fromReset) {
      const checkedOtp = yield select(selectCheckedOtp);
      if (!checkedOtp) {
        const {data: validateRecaptcha} = yield call(axios.post, '/api/auth/validate-recaptcha', {
          email,
          recaptchaToken,
        });
        if (!validateRecaptcha.data.nextStep) {
          yield put({type: AuthActionTypes.REQUEST_VALIDATE_RECAPTCHA_SUCCESS, payload: {isCheck: true}});
          yield put({type: AuthActionTypes.SHOW_OTP});
          return;
        }
      }
    }
    const response = yield call(axios.post, '/api/auth/login', loginParams, {
      headers: {
        'Accept-Language': languageCode, // TODO check if we have langauage on login page?
        Authorization: '',
      },
    });

    const {token, refreshToken} = response.data;

    TokenStorage.storeToken(token);
    TokenStorage.storeRefreshToken(refreshToken);

    addAuthorizationHeader(token);

    yield put({
      type: AuthActionTypes.LOGIN_SUCCESS,
      payload: {user: TokenStorage.currentUser()},
    });
  } catch (error) {
    yield storeErrors(AuthActionTypes.LOGIN_ERROR, [401, 422], error.response);
  }
}

function* resetPassword(resetPasswordAction: ResetPasswordAction) {
  // recovery password step 1
  const {email} = resetPasswordAction.payload;

  const resetPasswordParams = {
    email,
  };

  try {
    yield call(axios.post, '/api/auth/reset-password/reset-password-request', resetPasswordParams);

    yield put({
      type: AuthActionTypes.RESET_PASSWORD_SUCCESS,
    });
  } catch (error) {
    yield storeErrors(AuthActionTypes.RESET_PASSWORD_ERROR, [404, 422, 400, 500], error.response);
  }
}

function* recoverPassword(recoverPasswordAction: RecoverPasswordAction) {
  // recovery password step 2
  const {password, recoveryCode, email, otp} = recoverPasswordAction.payload;

  try {
    yield call(axios.post, '/api/auth/reset-password/recover-password', {password, token: recoveryCode, email, otp});
    yield put({type: AuthActionTypes.RECOVER_PASSWORD_SUCCESS});
    const language = localStorage.getItem('i18nextLng') || 'en';
    window.location.href = `/${language}/sign-in?email=${email}`;
  } catch (error) {
    yield storeErrors(AuthActionTypes.RECOVER_PASSWORD_ERROR, [404, 401, 422], error.response);
  }
}

function* logout() {
  if (TokenStorage.getToken()) {
    axiosRequest.get(`${baseURL}/api/auth/logout`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${TokenStorage.getToken()}`,
      },
    });
  }
  TokenStorage.clear();
  AppStorage.removeDefaultAddressId();
  AppStorage.removeDefaultAddressCode();
  AppStorage.removeDeliveryType();
  yield put(resetCategories());
  yield put({type: AuthActionTypes.LOGOUT_SUCCESS});
}

function* changeDisplayLanguage(changeDisplayLanguageAction: ChangeDisplayLanguageAction) {
  try {
    const {languageCode} = changeDisplayLanguageAction.payload;
    yield call(axios.put, '/api/auth/profile/update/change-display-language', {languageCode});
    yield put({type: AuthActionTypes.REQUEST_CHANGE_DISPLAY_LANGUAGE_SUCCESS})
    // eslint-disable-next-line no-empty
  } catch (err) {
  }
}

function* createMagicLink(action: CreateMagicLinkAction) {
  try {
    const {email} = action.payload;
    const language = yield select(selectLanguage);
    yield call(axios.post, '/api/auth/login/create-magic-link', {email}, {
      headers: {
        'Accept-Language': language,
      }
    });
    yield put({type: AuthActionTypes.REQUEST_CREATE_MAGIC_LINK_SUCCESS});
  } catch (err) {
    yield put({
      type: AuthActionTypes.REQUEST_CREATE_MAGIC_LINK_ERROR,
      payload: {data: get(err, 'response.data.message', 'error')}
    });
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* loginByMagicLink(action: LoginByMagicLinkAction) {
  try {
    const {data} = yield call(axios.get, `/api/auth/login/login-by-magic-link/${action.payload.token}`);
    const {token, refreshToken} = data;

    TokenStorage.storeToken(token);
    TokenStorage.storeRefreshToken(refreshToken);

    addAuthorizationHeader(token);

    yield put({
      type: AuthActionTypes.LOGIN_SUCCESS,
      payload: {user: TokenStorage.currentUser()},
    });
  } catch (err) {
    yield put({
      type: AuthActionTypes.REQUEST_LOGIN_BY_MAGIC_LINK_ERROR,
      payload: {data: get(err, 'response.data.message', 'error')}
    });
  }
}

function* watchLoginByMagicLink() {
  yield takeEvery(AuthActionTypes.REQUEST_LOGIN_BY_MAGIC_LINK, loginByMagicLink);
}

function* watchCreateMagicLink() {
  yield takeEvery(AuthActionTypes.REQUEST_CREATE_MAGIC_LINK, createMagicLink);
}

function* watchChangeDisplayLanguage() {
  yield takeEvery(AuthActionTypes.REQUEST_CHANGE_DISPLAY_LANGUAGE, changeDisplayLanguage);
}

function* watchLogin() {
  yield takeEvery(AuthActionTypes.LOGIN, login);
}

function* watchVerifyToken() {
  yield takeEvery(AuthActionTypes.VERIFY_TOKEN, verifyToken);
}

function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT, logout);
}

function* watchResetPassword() {
  yield takeEvery(AuthActionTypes.RESET_PASSWORD, resetPassword);
}

function* watchRecoverPassword() {
  yield takeEvery(AuthActionTypes.RECOVER_PASSWORD, recoverPassword);
}

function* watchCheckAuth() {
  yield takeEvery(AuthActionTypes.CHECK_AUTH, checkAuth);
}

function* watchRememberMe() {
  yield takeEvery(AuthActionTypes.REMEMBER_ME, TokenStorage.remember);
}

function* watchForgetMe() {
  yield takeEvery(AuthActionTypes.FORGET_ME, TokenStorage.forget);
}

function* watchValidateRecaptchaToken() {
  yield takeEvery(AuthActionTypes.REQUEST_VALIDATE_RECAPTCHA, validateRecaptchaToken);
}

export default function* authSaga() {
  yield all([
    watchLogin(),
    watchLogout(),
    watchResetPassword(),
    watchRecoverPassword(),
    watchVerifyToken(),
    watchCheckAuth(),
    watchRememberMe(),
    watchForgetMe(),
    watchValidateRecaptchaToken(),
    watchChangeDisplayLanguage(),
    watchCreateMagicLink(),
    watchLoginByMagicLink()
  ]);
}
