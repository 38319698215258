import { ProductVariantClass } from 'models/classes/productVariantClass';
import { useMemo } from 'react';

const useFilteredProducts = (
  filterRange: string,
  products: ProductVariantClass[],
  pricingForFilter: string
) => {
  return useMemo(
    () =>
      filterRange
        ? products.filter(product => {
          const currentPrice = product.prices.length
            ? Number(product.prices[0][pricingForFilter])
            : 0;
          if (
            Number(filterRange.split('..')[0]) >= currentPrice &&
              Number(filterRange.split('..')[1]) <= currentPrice
          ) {
            return false;
          }
          return true;
        })
        : products,
    [products, filterRange, pricingForFilter]
  );
};

export default useFilteredProducts;
