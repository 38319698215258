import { createSelector } from 'reselect';
import { RootState } from 'store';

const selectCheckoutState = (state: RootState) => state.checkout;

export const selectOrder = createSelector(
  selectCheckoutState,
  checkoutState => checkoutState.order
);

export const selectStep = createSelector(selectCheckoutState, checkoutState => checkoutState.step);

export const selectShippingMethod = createSelector(
  selectCheckoutState,
  checkoutState => checkoutState.shippingMethod
);

export const selectWasAddressChanged = createSelector(
  selectCheckoutState,
  checkoutState => checkoutState.addressWasChanged
);

export const selectPreviousAddress = createSelector(
  selectCheckoutState,
  checkoutState => checkoutState.previousSelectedAddress
);

export const selectItemErrors = createSelector(
  selectCheckoutState,
  checkoutState => checkoutState.itemErrors
);
