export enum GAActionTypes {
  SET_IN_STOCK_APPLIED = '@@ga/SET_IN_STOCK_APPLIED',
  SET_ORDER_SUBMITTED = '@@ga/SET_ORDER_SUBMITTED',
  SET_PRICING_TYPE_CHANGED = '@@ga/SET_PRICING_TYPE_CHANGED',
  SET_ORDERS_OR_QUOTES_VISITED = '@@ga/SET_ORDERS_OR_QUOTES_VISITED',
  RESET_STATE = '@@ga/RESET_STATE',
  SET_IS_FIRE_FEATURED_PRODUCT = '@@ga/SET_IS_FIRE_FEATURED_PRODUCT',
}

export interface GAState {
  readonly inStockApplied: boolean;
  readonly orderSubmitted: boolean;
  readonly pricingTypeChanged: boolean;
  readonly ordersOrQuotesVisited: boolean;
  readonly isFireFeaturedProduct: boolean;
}
