import React from 'react';

export const WarningIcon = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEF0C7" />
      <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FFFAEB" strokeWidth="8" />
      <path
        d="M27.9998 24.0012V28.0012M27.9998 32.0012H28.0098M26.2898 18.8612L17.8198 33.0012C17.6451 33.3036 17.5527 33.6465 17.5518 33.9957C17.5508 34.3449 17.6413 34.6883 17.8142 34.9917C17.9871 35.2951 18.2365 35.5479 18.5375 35.725C18.8385 35.9021 19.1806 35.9973 19.5298 36.0012H36.4698C36.819 35.9973 37.1611 35.9021 37.4621 35.725C37.7631 35.5479 38.0124 35.2951 38.1854 34.9917C38.3583 34.6883 38.4488 34.3449 38.4478 33.9957C38.4468 33.6465 38.3544 33.3036 38.1798 33.0012L29.7098 18.8612C29.5315 18.5673 29.2805 18.3243 28.981 18.1557C28.6814 17.987 28.3435 17.8984 27.9998 17.8984C27.656 17.8984 27.3181 17.987 27.0186 18.1557C26.7191 18.3243 26.468 18.5673 26.2898 18.8612Z"
        stroke="#DC6803" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}