import styled from 'styled-components/macro';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import { AddToTruck } from 'assets/svg/FeaturedProducts/FeaturedProducts';

const SliderWrapper = styled.div`
  @media (min-width: 640px) and (max-width: 1023px) {
    margin-bottom: 40px;
    width: calc(100% - 16px);
    &:last-child {
      margin-bottom: 20px;
    }
  }
  .slick-slider{position:relative;display:block;box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{position:relative;display:block;overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}.slick-track{position:relative;top:0;left:0;display:block;margin-left:auto;margin-right:auto}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-loading .slick-track{visibility:hidden}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-loading .slick-slide{visibility:hidden}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}
`;

const StyledSlider = styled(Slider)`
  padding: 0 30px;
  @media (max-width: 599px) {
    padding: 0;
  }
  .slick-list {
    margin: 0 -15px;
    @media (max-width: 1023px) {
      min-height: 450px;
      padding-bottom: 10px;
    }
    @media (max-width: 599px) {
      margin: 0;
    }
  }
  .slick-slide {
    width: 20%;
    padding: 5px 15px 0;
    @media (max-width: 1200px) {
      width: 25%;
    }
    @media (max-width: 599px) {
      padding: 0;
    }
    @media (max-width: 800px) {
      width: 50%;5*-+-
    }
    @media (max-width: 599px) {
      width: 100%;
    }
  }
  .slick-arrow {
    position: absolute;
    cursor: pointer;
    &:hover {
      path {
        stroke: #000;
      }
    }
    path {
      transition: all 0.4s ease;
    }
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }
  .slick-dots {
    text-align: center;
    list-style: none;
    line-height: 1;
    padding: 0;
    margin: 20px 0 0;
    li {
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      margin: 0 2px;
      &.slick-active {
        button {
          background: #00853e;
        }
      }
      button {
        width: 6px;
        height: 4px;
        line-height: 1;
        background: #D9D9DE;
        border: 0;
        outline: 0;
        text-indent: -999px;
        cursor: pointer;
        transition: all 0.4s ease;
      }
    }
  }
  [class*="product-card-"].on-carousel {
    width: 100%;
    padding: 0;
    [class*="styles__ProductInfoWrapper-"] {
      height: 430px;
      @media (max-width: 1023px) {
        height: 100%;
        padding-bottom: 5px;
      }
    }
    [class*="styles__PriceWrapper-"] {
      @media (max-width: 1023px) {
        padding-bottom: 5px;
      }
      [class*="styles__Each-"] {
        white-space: nowrap;
        letter-spacing: -0.2px;
      }
    }
  }
  [class*="styles__ProductCardGrid-"] {
    width: calc(100% + 12px + 12px);
    margin: 0 0 0 -12px;
    [class*="styles__ProductCard-"] {
      width: 100%;
      height: 430px;
      overflow-y: auto;
      @media (max-width: 1023px) {
        height: 100%;
      }
    }
  }
  [class*="styles__ProductName-"] {
    @media (max-width: 1023px) {
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
`;

const ProductCard = styled.div`
  @media (min-width: 1024px) {
    width: 20%;
    padding: 0 15px;
  }
`;

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  padding-bottom: 100%;
  @media (max-width: 1023px) {
    padding-bottom: 274px;
  }
  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    object-fit: cover;
    object-position: center top;
    border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  }
`;

const DefaultAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  padding-bottom: 100%;
  @media (max-width: 1023px) {
    padding-bottom: 274px;
  }
  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    object-fit: cover;
    object-position: center top;
    border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  width: 214px;

  @media (max-width: 1023px) {
    width: calc(100% - 8px);
  }
  @media (max-height: 1023px) {
    width: auto;
  }
`;

const StyledPricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -4px;
  height: 50px;
`;

const StyledSecondPriceText = styled.span`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

const PriceItem = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: auto !important;
  padding-right: 5px;

  @media (max-width: 640px) {
    justify-content: flex-start;
  }
`;

const ProductInfoWrapper = styled.div`
  @media (max-width: 1023px) {
    width: 100%;
    height: auto;
  }
`;

const ProductName = styled.p`
  font-family: Open Sans;
  font-weight: 600;
  font-size: 14px;
  height: 80px;
`;

const ProductStock = styled.p`
  font-family: "Open Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 145.455% */
  text-decoration-line: underline;
  color: ${props => props.theme.colors.mainGreen};
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
  &:hover {
    font-weight: bold;
  }
  &.waiting-on-stock {
    color: ${props => props.theme.colors.waitingOnStockBlue};
  }
`;

const SpecialOrder = styled.p`
  color: #001A3B;
  font-family: "Open Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 145.455% */
  cursor: pointer;
  text-decoration: underline;
  margin: 0;
  &:hover {
    font-weight: bold;
  }
`;

const Price = styled.p`
  margin: 0;
  font-family: Open Sans;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  color: ${({ theme: { colors } }) => colors.mainGreen};
`;

const Each = styled.p`
  margin: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.letterGrey};
`;

const RequestPricingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const RequestPricingButton = styled.button`
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  background: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

const RequestPricing = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  text-align: center;
  margin: 0;
  padding: 6px 12px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.black};
`;

const CarouselHeader = styled.h2`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 177.778% */
  letter-spacing: 0.1px;
  margin-top: 24px;
  margin-bottom: 16px;
  @media (max-width: 1023px) {
    margin-bottom: 0;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  @media (min-width: 1024px) {
    margin: 0 -15px;
  }
  [class*="product-card-grid-"] {
    margin: 0;
  }
  [class*="styles__ProductCardGrid-"] {
    padding: 0 3px;
    [class*="styles__ProductCard-"] {
      width: 240px;
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;
  border-radius: 4px;
`;

const StyledAddToTruck = styled(AddToTruck)`
  cursor: pointer;
`;

const AddToTruckWrapper = styled.div`
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
`;

export {
  AddToTruckWrapper,
  AvatarWrapper,
  StyledAddToTruck,
  CarouselHeader,
  Price,
  PriceItem,
  PriceWrapper,
  ProductCard,
  ProductInfoWrapper,
  ProductName,
  ProductStock,
  RequestPricing,
  RequestPricingButton,
  RequestPricingWrapper,
  SliderWrapper,
  SpecialOrder,
  StyledIconButton,
  StyledLink,
  StyledSlider,
  DefaultAvatarWrapper,
  ListWrapper,
  Each,
  StyledPricesContainer,
  StyledSecondPriceText,
};
