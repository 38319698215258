import {useLayoutEffect, useMemo, useState} from 'react';
import {
  PERMISSION_PLACE_ORDER, PERMISSION_PLACE_QUOTE, PERMISSION_VIEW_INVENTORY,
  PERMISSION_VIEW_ORDER_QUOTE_HISTORY,
  PERMISSION_VIEW_PRICING
} from 'assets/constants/permission';
import {useSelector} from 'react-redux';
import {selectUserData} from 'ducks/user/selectors';

export const usePermission = () => {
  const [canSeePrice, setCanSeePrice] = useState(false);
  const [canSeeOrderHistory, setCanSeeOrderHistory] = useState(false);
  const [canPlaceOrder, setCanPlaceOrder] = useState(false);
  const [canPlaceQuote, setCanPlaceQuote] = useState(false);
  const [canSeeInventory, setCanSeeInventory] = useState(false);
  const [init, setInit] = useState(false);
  const user = useSelector(selectUserData);
  useLayoutEffect(() => {
    if (user && !init) {
      if (user.roles.includes(PERMISSION_VIEW_PRICING)) {
        setCanSeePrice(true);
      }
      if (user.roles.includes(PERMISSION_PLACE_ORDER)) {
        setCanPlaceOrder(true);
      }
      if(user.roles.includes(PERMISSION_PLACE_QUOTE)) {
        setCanPlaceQuote(true);
      }
      if (user.roles.includes(PERMISSION_VIEW_ORDER_QUOTE_HISTORY)) {
        setCanSeeOrderHistory(true);
      }
      if (user.roles.includes(PERMISSION_VIEW_INVENTORY)) {
        setCanSeeInventory(true);
      }
      setInit(true);
    }
  }, [user, init]);

  return useMemo(() => ({
    canSeePrice,
    canSeeOrderHistory,
    canPlaceOrder,
    canPlaceQuote,
    canSeeInventory,
    isInitialized: init
  }), [init, canSeePrice, canSeeOrderHistory, canPlaceOrder, canPlaceQuote, canSeeInventory])
}