import React, { useCallback, useEffect, useRef, useState } from 'react';
import Routes from 'routes';
import { useDesktopFilter } from 'components/Product/ProductFilter/hooks/useDesktopFilter';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useSortType from 'hooks/useSortType';
import TagManager from 'react-gtm-module';

import { ShowList, ShowListActive, ShowTable, ShowTableActive } from 'assets/svg/Filter/Filter';

import {
  removePaginationLoading,
  resetProducts,
  setPaginationLoading,
  searchProductsFilterAction,
} from 'ducks/search/actions';

import {
  selectIsLastPage,
  selectProducts,
  selectSearchFacets,
  selectSearchLoading,
  selectSearchMultiselectFacets,
  selectSearchRangeFilters,
  selectTotal,
} from 'ducks/search/selectors';

import { FormControl, FormControlLabel, FormGroup, Grid } from '@material-ui/core';
import { BreadCrumbs, ProductCarousel, Slider } from 'components/common';
import ProductRowViewCards from 'pages/Product/ProductRowView/ProductRowCards';
import ProductTableViewCards from 'pages/Product/ProductTableView/ProductTableCards';
import ProductWithPaperWrapper from 'pages/Product/ProductWithPaperWrapper';
import SearchLoader from 'components/common/Loader/SearchLoader';
import { NoProductsModal } from 'pages/Product/NoProductsModal/NoProductsModal';
import ProductFilter from 'components/Product/ProductFilter/ProductFilter';
import { ProductListSkeleton, ProductListMobileSkeleton } from 'pages/Product/ProductListSkeletons';
import ProductListLoading from 'pages/Product/ProductListLoading/ProductListLoading';
import SortDropdown from 'components/Product/Dropdowns/SortDropdown/SortDropdown';
import ShowDropdown from 'components/Product/Dropdowns/ShowDropdown';

import { ISelectedValue } from 'models/interfaces/productVariant';

import {
  SpecialOrder,
  StyledLoadButton,
  LoadNextTitle,
  SubcategoryTitle,
  PageHeaderWrapper,
  DropdownsWrapper,
  DropdownTitle,
  SingleDropdownWrapper,
  MobileButtonsWrapper,
  MobileButtonsRow,
  ProductWorkspace,
  ProductListWorkspace,
  ListStyleButtonsWrapper,
  ShowTableButton,
  ShowListButton,
  SearchResultInfo,
  ReturnHomeButton,
  MarginWrapper,
  CarouselAndSliderWrapper,
  StyledP,
  LoadNextListWrapper,
  StyledLink,
  InStockControlLabel,
  GreenSwitch,
  TitleWithDropdownsWrapper,
  ProductStock,
  ProductListItemsWrapper,
} from 'pages/Search/styles';
import { AppStorage } from 'components/App/services/storage';
import { getFillterAttrs, SORT_TYPE_KEY } from 'utils/common/common';
import { generateFilter } from 'components/Product/ProductFilter/utils';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { selectAddressId, selectErpCustomerAddressCode } from 'ducks/application/selectors';
import get from 'lodash/get';
import { ProductVariantClass } from 'models/classes/productVariantClass';
import { usePermission } from 'hooks/usePermission';
import { NearbyBranchEmailType } from 'ducks/productVariant/types';
import { NearbyBranchContext } from 'context/NearbyBranchContext';
import {
  setClickedProductRequestNearbyHubsAndBranch,
  setOpenPopupInquireNow,
} from 'ducks/product/actions';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import isEmpty from 'lodash';
import { createUserActionLog } from 'api/UserActionLog';
import { useSearchFullView } from 'api/Search';
import { ProductFilterContext } from 'context/ProductFilterContext';

const SearchPage: React.FC = () => {
  const { setEmailType } = React.useContext(NearbyBranchContext);
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const isSearchPage = pathname === Routes.SEARCH;
  const searchValue = new URLSearchParams(search).get('q');
  const products = useSelector(selectProducts);
  const { canSeeInventory } = usePermission();
  const isLastPage = useSelector(selectIsLastPage);
  const loading = useSelector(selectSearchLoading);
  const totalItems = useSelector(selectTotal);
  const facets = useSelector(selectSearchFacets);
  const rangeFilters = useSelector(selectSearchRangeFilters);
  const multiselectFacets = useSelector(selectSearchMultiselectFacets);
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const scrollTo = useRef(null);
  const [selectedValues, setSelectedValues] = useState([]);

  const { chosenSortType, sortType, sortProducts, setValueSortType } = useSortType(
    {

    },
    6
  );
  const {
    pagination,
    setPagination,
    filter,
    setFilter,
    show,
    setShow,
    selectedView,
    setSelectedView,
    firstLoad,
    setFirstLoad,
    isMobile,
    addressId,
    selectedLanguage,
    inStock,
    setInStock
  } = React.useContext(ProductFilterContext);

  useEffect(() => {
    if (searchValue && addressId) {
      setFilter({});
      AppStorage.removeFiltered();
      dispatch(searchProductsFilterAction({
        query: searchValue,
        language: selectedLanguage,
        addressId
      }));
    }
  }, [dispatch, searchValue, addressId]);

  const { t } = useTranslation();
  const {
    handleChange,
    findNecessaryCheck,
    handleMultiselectChange,
    handleRangeFilterChange,
  } = useDesktopFilter(selectedValues, setFirstLoad, setPagination, setFilter, setSelectedValues);

  const paginationItemsPerPage = pagination.itemsPerPage;

  useEffect(() => {
    if (searchValue) {
      const filteredStorage = AppStorage.getFiltered();
      setSelectedValues([]);
      setFilter({});
      setPagination({ page: 1, itemsPerPage: 12 });
      if (filteredStorage) {
        const filtered = JSON.parse(filteredStorage);
        let filterAttrs: ISelectedValue[] = [];
        if (
          filtered.filterAttributes &&
          JSON.stringify(filtered.filterAttributes) !== JSON.stringify({}) &&
          !isEmpty(filtered)
        ) {
          filterAttrs = getFillterAttrs(facets, filtered.filterAttributes);
          setSelectedValues(filterAttrs);
          setFilter(generateFilter(filterAttrs));
        }
      }
      sortProducts(6);
      setFirstLoad(true);
      setInStock(false);
    }
  }, [searchValue, setFilter, setFirstLoad]);

  useEffect(() => {
    if (AppStorage.getFiltered()) {
      const filters = JSON.parse(AppStorage.getFiltered());
      setFilter(filters.filterAttributes);
      setInStock(filters.inStock);
      // eslint-disable-next-line guard-for-in
      for (const key in filters) {
        if (SORT_TYPE_KEY.includes(`${key}`)) {
          const st = {};
          st[key] = filters[key];
          setValueSortType(st);
        }
      }
      return () => {};
    }
    return () => {
      dispatch(resetProducts());
      dispatch(removePaginationLoading());
    };
  }, [dispatch]);

  useSearchFullView({
    filterAttributes: { ...filter },
    ...sortType,
    ...pagination,
    query: searchValue,
    addressId,
    language: selectedLanguage,
    inStock,
    firstLoad,
  });

  useEffect(() => {
    dispatch(removePaginationLoading());
  }, [dispatch, filter, inStock]);

  const handleClickProductStock = useCallback(
    (variant: ProductVariantClass) => {
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'click_inventory_request',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
      const status = get(variant, 'inventory.status', {});
      dispatch(setClickedProductRequestNearbyHubsAndBranch(variant));
      dispatch(setOpenPopupInquireNow(true));
      if (status.inStock) {
        setEmailType(NearbyBranchEmailType.HOME_BRANCH_WITH_INVENTORY_IN_STOCK);
      } else if (status.waitingOnStock) {
        setEmailType(NearbyBranchEmailType.HOME_BRANCH_WAITING_ON_STOCK);
      } else if (status.specialOrderString) {
        setEmailType(NearbyBranchEmailType.HOME_BRANCH_WITH_SPECIAL_ORDER_STRING);
      }
    },
    [dispatch, setEmailType, user, branch, erpCustomerAddressCode, selectedAddressId]
  );

  const onClickProductStock = async () => {
    await createUserActionLog(isSearchPage ? 'Search Page Inventory Status' : 'Catalog Page Inventory Status', 'Inventory Request');
  };

  const renderProductStock = (variant: ProductVariantClass) => {
    const status = get(variant, 'inventory.status', {});

    if (status.inStock || !status.specialOrderString) {
      return (
        <ProductStock
          className={`${status.waitingOnStock ? 'waiting-on-stock' : ''}`}
          onClick={async () => {
            await onClickProductStock();
            handleClickProductStock(variant);
          }}
        >
          {canSeeInventory ? status.status : status.origin}
        </ProductStock>
      );
    }

    return (
      <SpecialOrder
        className="special-order"
        onClick={async () => {
          await onClickProductStock();
          handleClickProductStock(variant);
        }}
      >
        {canSeeInventory ? status.status : status.origin}
      </SpecialOrder>
    );
  };

  const handleLoadClick = () => {
    dispatch(setPaginationLoading());
    const newPage = pagination.page + 1;
    const newPagination = {
      ...pagination,
      page: newPage,
    };

    setPagination(newPagination);
    setFirstLoad(false);
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_load_more_product',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
  };

  const handleInStockSwitch = () => {
    setInStock(!inStock);
    setPagination({ ...pagination, page: 1 });
    setFirstLoad(true);
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_in_stock_filter',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
  };

  const handleEventTrackingChangeView = (event: string) => {
    if (event !== selectedView) {
      setSelectedView(event);
      TagManager.dataLayer({
        dataLayer: {
          event: 'change_view',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
    }
  };

  const renderFilter = () => (
    <ProductFilter
      setSelectedValues={setSelectedValues}
      selectedValues={selectedValues}
      show={show}
      setShow={setShow}
      setFilter={setFilter}
      isMobile={isMobile}
      productsAmount={products.length}
      rangeFilters={rangeFilters}
      multiselectFacets={multiselectFacets}
      setInStock={setInStock}
      scrollEl={scrollTo}
      findNecessaryCheck={findNecessaryCheck}
      handleChange={handleChange}
      handleMultiselectFilterChange={handleMultiselectChange}
      handleRangeFilterChange={handleRangeFilterChange}
      categoryCode="search"
    />
  );

  const closeNoProductModal = () => {
    setInStock(false);
  };
  const ProductListItems = () =>
    !isMobile && selectedView === 'list' ? (
      <ProductRowViewCards
        products={products}
        renderProductStock={renderProductStock}
        handleClickProductStock={handleClickProductStock}
      />
    ) : (
      <ProductTableViewCards
        products={products}
        renderProductStock={renderProductStock}
        handleClickProductStock={handleClickProductStock}
      />
    );

  const ProductSkeleton = () =>
    !isMobile ? <ProductListSkeleton /> : <ProductListMobileSkeleton />;

  return (
    <MarginWrapper>
      <NoProductsModal
        open={!loading && !products.length && inStock}
        handleClose={closeNoProductModal}
      />
      <Grid container item direction="column" md={12} xs={12} ref={scrollTo}>
        <BreadCrumbs currentPageName={t('products_and_search.pagename')} />
        {!isMobile ? (
          <Grid container item direction="row" md={12} xs={12}>
            <PageHeaderWrapper>
              <TitleWithDropdownsWrapper>
                <SubcategoryTitle className="txt-search-results">
                  {t('products_and_search.search_results').toString()}
                </SubcategoryTitle>
                {products.length > 0 && searchValue ? (
                  <DropdownsWrapper>
                    <FormControl>
                      <FormGroup>
                        <FormControlLabel
                          control={<GreenSwitch checked={inStock} onClick={handleInStockSwitch} />}
                          label={
                            <InStockControlLabel>
                              {t('products_and_search.in_stock').toString()}
                            </InStockControlLabel>
                          }
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                    <SingleDropdownWrapper>
                      <DropdownTitle>{t('products_and_search.sort_by').toString()}</DropdownTitle>
                      <SortDropdown
                        sortProducts={sortProducts}
                        setPagination={setPagination}
                        pagination={pagination}
                        setFirstLoad={setFirstLoad}
                        isSearch
                        defaultSortByValue={6}
                        chosenSortType={chosenSortType}
                      />
                    </SingleDropdownWrapper>
                    <SingleDropdownWrapper>
                      <DropdownTitle>{t('products_and_search.show').toString()}</DropdownTitle>
                      <ShowDropdown
                        setPagination={setPagination}
                        setFirstLoad={setFirstLoad}
                        itemsPerPage={pagination.itemsPerPage}
                      />
                    </SingleDropdownWrapper>
                    <ListStyleButtonsWrapper>
                      <ShowTableButton onClick={() => handleEventTrackingChangeView('table')}>
                        {selectedView === 'table' ? <ShowTableActive /> : <ShowTable />}
                      </ShowTableButton>
                      <ShowListButton onClick={() => handleEventTrackingChangeView('list')}>
                        {selectedView === 'list' ? <ShowListActive /> : <ShowList />}
                      </ShowListButton>
                    </ListStyleButtonsWrapper>
                  </DropdownsWrapper>
                ) : null}
              </TitleWithDropdownsWrapper>
              <div>
                {!searchValue ? (
                  <SearchResultInfo>0 {t('products_and_search.products_found').toString()}</SearchResultInfo>
                ) : (
                  <>
                    {products && !loading && (
                      <SearchResultInfo>
                        {totalItems} {t('products_and_search.products_found_for').toString()} &#8220;
                        {searchValue}
                        &#8221;
                      </SearchResultInfo>
                    )}
                  </>
                )}

                {!searchValue || (products?.length === 0 && !loading) ? (
                  <ReturnHomeButton>
                    <StyledLink to={Routes.HOME}>
                      <StyledP>{t('products_and_search.return_home').toString()}</StyledP>
                    </StyledLink>
                  </ReturnHomeButton>
                ) : null}
              </div>
            </PageHeaderWrapper>
          </Grid>
        ) : (
          <>
            <SubcategoryTitle>{t('products_and_search.search_results').toString()}</SubcategoryTitle>
            {!searchValue ? (
              <SearchResultInfo>0 {t('products_and_search.products_found').toString()}</SearchResultInfo>
            ) : (
              <>
                {products && !loading && (
                  <SearchResultInfo>
                    {totalItems} {t('products_and_search.products_found_for').toString()} &#8220;
                    {searchValue}
                    &#8221;
                  </SearchResultInfo>
                )}
              </>
            )}
            {!searchValue || (products?.length === 0 && !loading) ? (
              <ReturnHomeButton>
                <StyledLink to={Routes.HOME}>
                  <StyledP>{t('products_and_search.return_home').toString()}</StyledP>
                </StyledLink>
              </ReturnHomeButton>
            ) : null}
          </>
        )}
        {Boolean(!products.length && loading && searchValue) && <SearchLoader />}
        {products.length > 0 && searchValue ? (
          <Grid container item direction="row" md={12} xs={12}>
            <ProductWorkspace>
              {isMobile ? (
                <MobileButtonsWrapper>
                  <MobileButtonsRow>
                    {renderFilter()}
                    {!show ? (
                      <SortDropdown
                        sortProducts={sortProducts}
                        setPagination={setPagination}
                        pagination={pagination}
                        setFirstLoad={setFirstLoad}
                        defaultSortByValue={6}
                        isSearch
                        chosenSortType={chosenSortType}
                      />
                    ) : null}
                  </MobileButtonsRow>
                  <MobileButtonsRow>
                    <FormControl>
                      <FormGroup>
                        <FormControlLabel
                          control={<GreenSwitch checked={inStock} onClick={handleInStockSwitch} />}
                          label={
                            <InStockControlLabel>
                              {t('products_and_search.in_stock').toString()}
                            </InStockControlLabel>
                          }
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </MobileButtonsRow>
                </MobileButtonsWrapper>
              ) : (
                renderFilter()
              )}
              {!products.length ? (
                <div>
                  <ProductSkeleton />
                </div>
              ) : (
                <ProductListWorkspace>
                  <ProductWithPaperWrapper
                    facets={[...facets, ...multiselectFacets]}
                    rangeFilters={rangeFilters}
                    selectedValues={selectedValues}
                    setSelectedValues={setSelectedValues}
                    setFilter={setFilter}
                    setPagination={setPagination}
                    setFirstLoad={setFirstLoad}
                  >
                    <ProductListItemsWrapper>
                      <ProductListItems />
                      {loading && <ProductListLoading />}
                    </ProductListItemsWrapper>

                    {!isLastPage ? (
                      <>
                        <LoadNextListWrapper>
                          <StyledLoadButton onClick={handleLoadClick}>
                            <LoadNextTitle>
                              {loading && <ProductListLoading />}
                              {t('products_and_search.load_next', { paginationItemsPerPage }).toString()}
                            </LoadNextTitle>
                          </StyledLoadButton>
                        </LoadNextListWrapper>

                        {/* {!paginationLoading ? (
                          <LoadNextListWrapper>
                            <StyledLoadButton onClick={handleLoadClick}>
                              <LoadNextTitle>
                                {loading && <ProductListLoading />}
                                {t('products_and_search.load_next', { paginationItemsPerPage })}
                              </LoadNextTitle>
                            </StyledLoadButton>
                          </LoadNextListWrapper>
                        ) : (
                          <LoadingWrapperPagination isMobile={isMobile}>
                            <LoadingIndicator width={isMobile ? 40 : 80} isMobile={isMobile} />
                          </LoadingWrapperPagination>
                        )} */}
                      </>
                    ) : null}
                  </ProductWithPaperWrapper>
                </ProductListWorkspace>
              )}
            </ProductWorkspace>
          </Grid>
        ) : (
          <>
            {!searchValue || (products.length === 0 && !loading) ? (
              <CarouselAndSliderWrapper>
                <ProductCarousel isFeatured />
                {!isMobile && <Slider />}
              </CarouselAndSliderWrapper>
            ) : null}
          </>
        )}
      </Grid>
    </MarginWrapper>
  );
};

export default SearchPage;
