import React, { useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { withStyles, Link, useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Link as LinkTo, useHistory } from 'react-router-dom';
import Routes from 'routes';
import { HeaderLogoDesktop } from 'assets/svg/Landing/HeaderLogoDesktop';
import { HeaderLogoMobile } from 'assets/svg/Landing/HeaderLogoMobile';
import { PhoneIcon } from 'assets/svg/Landing/PhoneWhiteIcon';
import { EmailIcon } from 'assets/svg/Landing/EmailWhiteIcon';
import { PhoneNumber } from 'components';
import LandingComponent from 'pages/Landing/LandingComponent';
import FooterComponent from 'pages/Landing/LandingFooter';
import PrivacyComponent from 'pages/Landing/PrivacyComponent';
import TermsOfService from 'pages/Technical/TermsOfService/TermsOfService';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../hooks/useLanguage';

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const MainContainerTerms = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 0 120px;

  @media (max-width: 1439px) {
    padding: 0 70px;
  }

  @media (max-width: 1360px) {
    padding: 0 20px;
  }

  @media (max-width: 920px) {
    padding: 0 10px;
  }
`;

interface IProps {
  isFixedMobile?: boolean;
  isFixedDesktop?: boolean;
  isVisible: boolean;
}

const HeaderWrapper = styled.div`
  width: 100%;
  height: 105px;
  position: ${(p: IProps) => (p.isVisible ? 'relative' : 'sticky')};
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg,
  ${({ theme: { colors } }) => colors.greenOpacity(0.4)} 69.51%,
  ${({ theme: { colors } }) => colors.invisible} 100%);
  &.z-index-1 {
    z-index: 1;
  }
  @media (max-width: 720px) {
    height: 91px;
    background: linear-gradient(180deg,
    ${({ theme: { colors } }) => colors.greenOpacity(0.6)} 69.51%,
    ${({ theme: { colors } }) => colors.invisible} 100%);
  }
`;

const Header = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 1440px;
  padding: 0 120px;

  @media (max-width: 1439px) {
    padding: 0 70px;
  }

  @media (max-width: 1360px) {
    padding: 0 20px;
  }

  @media (max-width: 920px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 10px;
  }

  @media (max-width: 720px) {
    align-items: flex-end;
    height: 80px;
  }
`;

const StyledLogo = styled.div`
  margin-right: 59px;
  cursor: pointer;
  order: 1;
  max-height: 70px;

  @media (max-width: 780px) {
    margin-right: 32px;
  }

  @media (max-width: 720px) {
    max-height: 50px;
    margin: 0 0 11px 0;
  }
`;

const LanguageButtonContainer = styled.div`
  order: 2;
  display: flex;
  align-items: flex-end;
  color: ${({ theme: { colors } }) => colors.white};
  margin-bottom: 4px;

  span {
    color: ${({ theme: { colors } }) => colors.white};
  }

  @media (max-width: 720px) {
    margin-bottom: 11px;
  }
`;

const ContactContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  order: 3;
  margin-left: auto;
  margin-right: 32px;
  margin-bottom: 4px;

  @media (max-width: 720px) {
    flex-direction: row-reverse;
    width: 100%;
    order: 0;
    height: auto;
    justify-content: space-between;
    margin-right: 0;
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.buttonGrey};
  }
`;

const SwitchLanguageButton = withStyles({
  root: {
    margin: '0',
    padding: '0',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.01em',
    color: '#FFFFFF',
    minWidth: '0',
    textTransform: 'none',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
    '&:hover': {
      '& .MuiButton-label': {
        fontWeight: 700,
        color: '#37875F',
      },
      background: 'none',
    },
    '@media (max-width: 720px)': {
      fontSize: '14px',
      lineHeight: '19px',
    },
  },
})(Button);

const StyledLink = styled(LinkTo)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 40px;
  width: 200px;
  font-size: 14px;
  font-family: Open Sans, serif;
  font-weight: bold;
  order: 4;
  letter-spacing: 0.01rem;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 4px;
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  line-height: 105%;
  margin-bottom: 4px;

  &:hover {
    color: ${({ theme: { colors } }) => colors.secondaryGreen};
  }

  @media (max-width: 720px) {
    margin-bottom: 11px;
    width: 150px;
  }

  @media (max-width: 480px) {
    width: 100px;
  }
`;

const ContactRowMail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 7px;

  @media (max-width: 720px) {
    margin-top: 8px;
  }
`;

const ContactRowPhone = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  margin-top: 0;

  @media (max-width: 720px) {
    margin-top: 8px;
    margin-bottom: 7px;
  }

  a {
    font-family: Open Sans, serif;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 100% !important;
    color: ${({ theme: { colors } }) => colors.white} !important;
    cursor: pointer !important;
    margin-left: 7px !important;

    &:hover {
      text-decoration: none !important;
      color: ${({ theme: { colors } }) => colors.mainGreen} !important;
    }

    @media (max-width: 720px) {
      font-size: 12px !important;
    }
  }
`;

const StyledContactLink = styled(Link)`
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme: { colors } }) => colors.white};
  cursor: pointer;
  margin-left: 7px;

  &:hover {
    text-decoration: none;
    color: ${({ theme: { colors } }) => colors.mainGreen};
  }

  @media (max-width: 720px) {
    font-size: 12px;
  }
`;

interface ILandingPage {
  isPrivacy?: boolean;
  isTos?: boolean;
}

const LandingPage: React.FC<ILandingPage> = ({ isPrivacy, isTos }: ILandingPage) => {
  const [isEnglish, setEnglish] = React.useState(true);
  const [isPrivacyActive, setPrivacyActive] = React.useState(!!isPrivacy);
  const [isTosActive, setIsTosActive] = React.useState(!!isTos);
  const history = useHistory();
  const isMobile = useMediaQuery('(max-width: 720px)');
  const [isVisible, setIsVisible] = React.useState(false);
  const footerRef = useRef(null);
  const { i18n } = useTranslation();
  const currentLang = useLanguage();
  const HEADER_HEIGHT = isMobile ? 80 : 70;

  const onWindowScroll = useCallback(() => {
    if (footerRef.current) {
      const { y } = footerRef.current.getBoundingClientRect();
      if (y < HEADER_HEIGHT) setIsVisible(true);
      else setIsVisible(false);
    }
  }, [HEADER_HEIGHT]);

  useEffect(() => {
    if (currentLang !== 'en' && currentLang !== 'fr') {
      window.location.href = i18n.language ? `/${i18n.language}` : '/en';
    }
    if (window.location.pathname.includes('en')) {
      setEnglish(true);
    } else {
      setEnglish(false);
    }
  }, [i18n, currentLang]);

  useEffect(() => {
    window.addEventListener('scroll', onWindowScroll);
    return () => window.removeEventListener('scroll', onWindowScroll);
  }, [HEADER_HEIGHT, onWindowScroll]);

  const replaceLanguage = (lang) => {
    if (lang === currentLang) {
      return;
    }
    let newUrl:string;
    if (window.location.pathname.match('/.*/$')) {
      newUrl = window.location.pathname.replace(`/${currentLang}/`, lang);
    } else {
      newUrl = window.location.pathname.replace(`/${currentLang}`, lang);
    }
    if (window.location.search) {
      newUrl += window.location.search;
    }
    window.location.replace(`/${newUrl}`);
  };

  const handleFrenchClick = async () => {
    await i18n.changeLanguage('fr');
    replaceLanguage('fr');
  };

  const handleEnglishClick = async () => {
    await i18n.changeLanguage('en');
    replaceLanguage('en');
  };

  const ScrollTop = () => {
    document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handlePrivacyClick = () => {
    if (!isPrivacy) {
      setPrivacyActive(false);
      history.push('/');
      ScrollTop();
    }
  };

  const handlePrivacyFooterClick = () => {
    if (isTosActive) {
      setIsTosActive(false);
    }
    setPrivacyActive(true);
    history.push('/privacy');
    setTimeout(ScrollTop, 100);
  };

  const handleLogoClick = () => {
    if (!isPrivacy) {
      setPrivacyActive(false);
    }
    if (isTosActive) {
      setIsTosActive(false);
    }

    history.push('/');
  };

  const Content = () => {
    if (!isTosActive && !isPrivacyActive) {
      return <LandingComponent isEnglish={isEnglish} />;
    }
    if (isTosActive)
      return (
        <PageWrapper>
          <MainContainerTerms>
            <TermsOfService language={isEnglish ? 'en_CA' : 'fr_CA'} />{' '}
          </MainContainerTerms>
        </PageWrapper>
      );
    if (isPrivacyActive)
      return (
        <PrivacyComponent
          isEnglish={isEnglish}
          privacySwitcher={handlePrivacyClick}
          withBreadCrumb={!isPrivacy}
        />
      );
    return null;
  };

  return isEnglish ? (
    <>
      <HeaderWrapper isVisible={isVisible} className="main-header">
        <Header>
          <StyledLogo onClick={handleLogoClick}>
            {!isMobile ? <HeaderLogoDesktop /> : <HeaderLogoMobile />}
          </StyledLogo>
          <LanguageButtonContainer>
            <SwitchLanguageButton style={{ fontWeight: 700 }} onClick={handleEnglishClick} data-test-id="en-language">
              EN
            </SwitchLanguageButton>
            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <SwitchLanguageButton onClick={handleFrenchClick} data-test-id="fr-language">FR</SwitchLanguageButton>
          </LanguageButtonContainer>
          <ContactContainer>
            <ContactRowMail>
              <EmailIcon />
              <StyledContactLink href='mailto:taiganow@taigabuilding.com'>
                taiganow@taigabuilding.com
              </StyledContactLink>
            </ContactRowMail>
            <ContactRowPhone>
              <PhoneIcon />
              <PhoneNumber />
            </ContactRowPhone>
          </ContactContainer>
          <StyledLink to={Routes.SIGN_IN}>Shop Now</StyledLink>
        </Header>
      </HeaderWrapper>
      {Content()}
      <FooterComponent
        footerRef={footerRef}
        isEnglish={isEnglish}
        privacySwitcher={handlePrivacyFooterClick}
      />
    </>
  ) : (
    <>
      <HeaderWrapper isVisible={isVisible} className="main-header">
        <Header>
          <StyledLogo onClick={handleLogoClick}>
            {!isMobile ? <HeaderLogoDesktop /> : <HeaderLogoMobile />}
          </StyledLogo>
          <LanguageButtonContainer>
            <SwitchLanguageButton onClick={handleEnglishClick}>EN</SwitchLanguageButton>
            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <SwitchLanguageButton style={{ fontWeight: 700 }} onClick={handleFrenchClick}>
              FR
            </SwitchLanguageButton>
          </LanguageButtonContainer>
          <ContactContainer>
            <ContactRowMail>
              <EmailIcon />
              <StyledContactLink href='mailto:taiganow@taigabuilding.com'>
                taiganow@taigabuilding.com
              </StyledContactLink>
            </ContactRowMail>
            <ContactRowPhone>
              <PhoneIcon />
              <PhoneNumber />
            </ContactRowPhone>
          </ContactContainer>
          <StyledLink to={Routes.SIGN_IN}>Magasiner maintenant</StyledLink>
        </Header>
      </HeaderWrapper>
      {Content()}
      <FooterComponent
        footerRef={footerRef}
        isEnglish={isEnglish}
        privacySwitcher={handlePrivacyFooterClick}
      />
    </>
  );
};

export default LandingPage;
