import React from 'react';

export const ShippingPopUpCross = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 1L11 11M1 21L11 11M11 11L1 1M11 11L21 21" stroke="#BFBFBF" strokeWidth="2" />
    </svg>
  );
};

export const ShippingPopUpSmallCross = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 1L7 7M1 13L7 7M7 7L1 1M7 7L13 13" stroke="#BFBFBF" />
    </svg>
  );
};
