import React from 'react';

export const ShowPrice = () => {
  return (
    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.7981 0C4.38618 0 0 5.71429 0 5.71429C0 5.71429 4.38618 11.4286 9.7981 11.4286C15.21
            11.4286 19.5962 5.71429 19.5962 5.71429C19.5962 5.71429 15.21 0 9.7981 0ZM9.7981 8.95225C8.01072
            8.95225 6.56014 7.50167 6.56014 5.71429C6.56014 3.9269 8.01072 2.47632 9.7981 2.47632C11.5855 2.47632
            13.0361 3.9269 13.0361 5.71429C13.0361 7.50167 11.5855 8.95225 9.7981 8.95225Z"
        fill="#00853e"
      />
    </svg>
  );
};
