import React from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Taxes} from 'assets/svg/Profile/Taxes';
import {Delivery} from 'assets/svg/Profile/Delivery';
import {Purse} from 'assets/svg/Profile/Purse';
import defaultImage from 'assets/images/default-product.png';
import {selectOrder} from 'ducks/checkout/selectors';
import {numberFormat} from 'utils/products/product';
import {getAddressStringOrder} from 'utils/profile/profileUtilsHelpers';
import size from 'lodash/size';
import {IOrder, ShippingMethod} from 'models/interfaces/order';
import { selectIsHidePrice } from 'ducks/application/selectors';
import {
  DeliveryBlock,
  DeliveryLabel,
  DeliveryText,
  Image,
  ItemMainName,
  ItemNameBlock,
  ItemPriceStyled,
  ItemQty,
  ItemsBlock,
  ItemSubName,
  NotesBlock,
  NotesLabel,
  NotesText,
  QtyPriceWrapper,
  SingleItem,
  SingleItemInfoWrapper,
  StyledCardContentOpen,
  SvgWrapper,
  TaxesBlock,
  TaxesBlockItem,
  TaxesLabel,
  TaxesName,
  TaxesPrice,
  TaxesSingleItem,
  TransactionTypeBlock,
  TransactionTypeLabel,
  TransactionTypeText,
} from './styles';

const adjustmentType = 'tax';

type CartItemBodyProps = {
  order: IOrder;
}

const CartItemBody = ({order}: CartItemBodyProps) => {
  const { t } = useTranslation();

  const orderState = useSelector(selectOrder);
  const isHidePrice = useSelector(selectIsHidePrice);
  const isQuotes = orderState === 'quote';
  const deliveryPriceType = order.deliveryType === ShippingMethod.DELIVERY ? 'deliveryPrice' : 'pickupPrice';

  const renderAdjustments = () =>
    order.adjustments.map(adjustment => {
      if (adjustment.type === adjustmentType) {
        return (
          <TaxesSingleItem key={adjustment.id}>
            <TaxesName>{adjustment.label}</TaxesName>
            {!isHidePrice ? <TaxesPrice>${numberFormat(adjustment.amount)}</TaxesPrice> : null}
          </TaxesSingleItem>
        );
      }
      return null;
    });

  const getPricePromotion = (prices, quantity) => {
    if (size(prices) > 1) {
      const sortPrices = prices.sort((a, b) => b.quantity - a.quantity);
      if (sortPrices[1][deliveryPriceType] > sortPrices[0][deliveryPriceType] && quantity >= sortPrices[0].quantity) {
        return numberFormat(parseFloat(String(prices[1][deliveryPriceType])) * quantity);
      }
      return null;
    }
    return null;
  };

  return (
    <StyledCardContentOpen>
      <ItemsBlock>
        {order.items.map(item => {
          return (
            <SingleItem key={item.id}>
              <Image src={item.variant.images[0]?.url || defaultImage} alt="product image" />
              <SingleItemInfoWrapper>
                <ItemNameBlock>
                  <ItemMainName>{item.variant.name}</ItemMainName>
                  <ItemSubName>
                    {t('checkout.sku').toString()}: {item.variant.erpSku}
                  </ItemSubName>
                </ItemNameBlock>
                <QtyPriceWrapper>
                  <ItemQty>
                    {t('checkout.qty').toString()}: {item.quantity}
                  </ItemQty>
                  {!isHidePrice ? (
                    <div>
                      {
                        getPricePromotion(item.product.prices, item.quantity) ? <ItemPriceStyled isDelete>${getPricePromotion(item.product.prices, item.quantity)}</ItemPriceStyled> : null
                      }
                      <ItemPriceStyled isDelete={false}>${numberFormat(item.total)}</ItemPriceStyled>
                    </div>
                  ) : null}
                </QtyPriceWrapper>
              </SingleItemInfoWrapper>
            </SingleItem>
          );
        })}
        <NotesBlock>
          <NotesLabel>{t('checkout.notes').toString()}:</NotesLabel>
          <NotesText dangerouslySetInnerHTML={{__html: order.note}}/>
        </NotesBlock>
      </ItemsBlock>
      {
        !isHidePrice && (
          <TaxesBlock>
            <SvgWrapper>
              <Taxes />
            </SvgWrapper>
            <TaxesBlockItem>
              <TaxesLabel>{t('checkout.taxes').toString()}</TaxesLabel>
              {renderAdjustments()}
            </TaxesBlockItem>
          </TaxesBlock>
        )
      }

      <DeliveryBlock>
        <SvgWrapper>
          <Delivery />
        </SvgWrapper>
        <div>
          <DeliveryLabel>
            {order.deliveryType === 'delivery' ? t('checkout.delivery').toString() : t('checkout.pick_up').toString()}
          </DeliveryLabel>
          <DeliveryText>{getAddressStringOrder(order.shippingAddress)}</DeliveryText>
        </div>
      </DeliveryBlock>

      <TransactionTypeBlock>
        <SvgWrapper>
          <Purse />
        </SvgWrapper>
        <div>
          <TransactionTypeLabel>{t('checkout.transaction_type').toString()}</TransactionTypeLabel>
          <TransactionTypeText>
            {isQuotes ? t('checkout.submit_quote').toString() : t('checkout.submit_order').toString()}
          </TransactionTypeText>
        </div>
      </TransactionTypeBlock>
    </StyledCardContentOpen>
  );
};

export default CartItemBody;
