import { Reducer } from 'redux';

// Types
import { SliderActionTypes, SliderState } from 'ducks/slider/types';

const initialState: SliderState = {
  slides: [],
  error: null,
};

const categoryReducer: Reducer<SliderState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case SliderActionTypes.REQUEST_SLIDER_DATA: {
      return { ...state, error: null };
    }
    case SliderActionTypes.REQUEST_SLIDER_DATA_SUCCESS: {
      return { ...state, slides: payload.slides, error: null };
    }
    case SliderActionTypes.REQUEST_SLIDER_DATA_ERROR: {
      return { ...state, error: payload.error };
    }
    default:
      return state;
  }
};

export default categoryReducer;
