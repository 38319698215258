import axios from 'utils/axios'
import { AxiosResponse } from 'axios';

export type DocumentGroup = {
  id: number;
  name: string;
  files: {
    id: number;
    name: string;
    path: string;
  }[];
}

export const fetchDocumentGroup = async (): Promise<AxiosResponse<DocumentGroup[]>> => {
  return axios.get('/api/auth/document-group/get-document-groups');
}