import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {ShippingMethod} from 'models/interfaces/order';

import {SelectAddress} from 'pages/Checkout/SelectAddress';

import FormContext from 'pages/Checkout/FormContext';

import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import frLocale from 'date-fns/locale/fr-CA';
import enLocale from 'date-fns/locale/en-CA';

import {priceTypeSelector, selectLanguage} from 'ducks/application/selectors';
import {selectOrder} from 'ducks/checkout/selectors';

import {
  DateBlockWrapper,
  FieldContainer,
  FlexNoWrap,
  Line,
  StyledKeyboardDatePicker,
  StyledTextField,
  StyledMultiTextField,
  CheckoutNote
} from 'pages/Checkout/styles';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import addYears from 'date-fns/addYears';

const localeMap = {
  en: enLocale,
  fr: frLocale,
};

const ValidatedOptions: React.FC = () => {
  const {values, errors, touched, handleBlur, handleChange, setFieldValue, poRef, requestDateRef} = useContext(
    FormContext
  );
  const {t} = useTranslation();
  const order = useSelector(selectOrder);
  const selectedLanguage = useSelector(selectLanguage);
  const shippingMethod = useSelector(priceTypeSelector);

  const locale = selectedLanguage === 'fr_CA' ? 'fr' : 'en';

  const dateFieldTitle = t(
    ShippingMethod.DELIVERY === shippingMethod ? 'checkout.delivery_date' : 'checkout.pick-up_date'
  );

  return (
    <>
      <DateBlockWrapper>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
          <StyledKeyboardDatePicker
            autoOk
            clearable={false}
            fullWidth
            id="deliveryDate"
            name="deliveryDate"
            inputVariant="outlined"
            disableToolbar
            variant="inline"
            label={dateFieldTitle}
            minDate={addDays(new Date(), 1)}
            maxDate={addYears(new Date(), 2)}
            placeholder={format(addDays(new Date(), 7), 'dd/MM/yyyy')}
            format="dd/MM/yyyy"
            value={values.deliveryDate}
            onChange={date => setFieldValue('deliveryDate', date, true)}
            error={Boolean(errors.deliveryDate)}
            helperText={
              errors.deliveryDate
                ? errors.deliveryDate.toString()
                : ''
            }
            innerRef={requestDateRef}
          />
        </MuiPickersUtilsProvider>
      </DateBlockWrapper>
      <SelectAddress/>
      <Line/>
      <FlexNoWrap>
        <FieldContainer>
          <StyledTextField
            id="tag"
            name="tag"
            label={t('checkout.tag_label').toString()}
            variant="outlined"
            fullWidth
            value={values.tag}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!(errors.tag && touched.tag)}
            helperText={errors.tag && touched.tag ? t(`checkout.${errors.tag}`).toString() : ''}
          />
        </FieldContainer>
        <FieldContainer>
          <StyledTextField
            id="po"
            name="po"
            label={
              order === 'quote' ? t('checkout.po_label_optional').toString() : t('checkout.po_label_required').toString()
            }
            variant="outlined"
            fullWidth
            value={values.po}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!(errors.po && touched.po)}
            helperText={errors.po && touched.po ? t(`checkout.${errors.po}`).toString() : ''}
            inputRef={poRef}
          />
        </FieldContainer>
      </FlexNoWrap>
      <StyledMultiTextField
        id="note"
        name="note"
        label={t('checkout.notes_label').toString()}
        variant="outlined"
        rows={3}
        fullWidth
        multiline
        value={values.notes}
        onChange={handleChange}
      />

      <CheckoutNote dangerouslySetInnerHTML={{ __html: t('checkout.checkout_note') }} />
    </>
  );
};

export default ValidatedOptions;
