import React from 'react';

export const RemoveIcon = () => {
  return (
    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.58122 5.69727H4.53015V11.6262H5.58122V5.69727Z" fill="#939599" />
      <path d="M8.46465 5.69727H7.41357V11.6262H8.46465V5.69727Z" fill="#939599" />
      <path
        d="M7.68158 0H5.31317C4.94148 0 4.58502 0.151248 4.32221 0.420471C4.05939 0.689694 3.91174 1.05484 3.91174 1.43558V2.32025H0.525536C0.386155 2.32025 0.252483 2.37697 0.153926 2.47793C0.0553689 2.57889 0 2.71582 0 2.85859C0 3.00137 0.0553689 3.1383 0.153926 3.23926C0.252483 3.34022 0.386155 3.39694 0.525536 3.39694H1.45924L1.96901 12.7964C2.00128 13.3924 2.25539 13.9531 2.67902 14.363C3.10265 14.7729 3.66358 15.0009 4.24633 15H8.75367C9.33642 15.0009 9.89734 14.7729 10.321 14.363C10.7446 13.9531 10.9987 13.3924 11.031 12.7964L11.5408 3.39694H12.4745C12.6138 3.39694 12.7475 3.34022 12.8461 3.23926C12.9446 3.1383 13 3.00137 13 2.85859C13 2.71582 12.9446 2.57889 12.8461 2.47793C12.7475 2.37697 12.6138 2.32025 12.4745 2.32025H9.08301V1.43558C9.08301 1.24706 9.04676 1.06038 8.97633 0.886206C8.9059 0.712034 8.80267 0.553777 8.67254 0.420471C8.5424 0.287165 8.38791 0.181421 8.21788 0.109277C8.04785 0.0371324 7.86562 0 7.68158 0ZM4.96456 1.43558C4.96456 1.34039 5.00147 1.24911 5.06718 1.1818C5.13288 1.1145 5.222 1.07668 5.31492 1.07668H7.68158C7.7745 1.07668 7.86361 1.1145 7.92932 1.1818C7.99502 1.24911 8.03194 1.34039 8.03194 1.43558V2.32025H4.96281L4.96456 1.43558ZM10.4844 3.39694L9.97817 12.739C9.96069 13.0598 9.82382 13.3616 9.59572 13.5823C9.36762 13.8029 9.06564 13.9256 8.75192 13.9251H4.24107C3.92735 13.9256 3.62537 13.8029 3.39727 13.5823C3.16917 13.3616 3.0323 13.0598 3.01482 12.739L2.51031 3.39694H10.4844Z"
        fill="#939599"
      />
    </svg>
  );
};
