import { UserClass } from 'models/classes/userClass';
import { IError } from 'models/interfaces/error';

export enum ProfileActionTypes {
  REQUEST_STORE_CONTACT_PROFILE = '@@profile/REQUEST_STORE_CONTACT_PROFILE',
  REQUEST_STORE_CONTACT_PROFILE_SUCCESS = '@@profile/REQUEST_STORE_CONTACT_PROFILE_SUCCESS',
  REQUEST_STORE_CONTACT_PROFILE_ERROR = '@@profile/REQUEST_STORE_CONTACT_PROFILE_ERROR',

  REQUEST_CHANGE_PASSWORD = '@@profile/REQUEST_CHANGE_PASSWORD',
  REQUEST_CHANGE_PASSWORD_SUCCESS = '@@profile/REQUEST_CHANGE_PASSWORD_SUCCESS',
  REQUEST_CHANGE_PASSWORD_ERROR = '@@profile/REQUEST_CHANGE_PASSWORD_ERROR',
  REFRESH_PASSWORD_STATE = '@@profile/REFRESH_PASSWORD_STATE',

  RESET_ERROR = '@@profile/RESET_ERROR',
}

export interface RequestStoreContactProfileAction {
  type: ProfileActionTypes.REQUEST_STORE_CONTACT_PROFILE;
}

export interface RequestChangePasswordAction {
  type: ProfileActionTypes.REQUEST_CHANGE_PASSWORD;
  payload: {
    currentPassword: string;
    newPassword: string;
  };
}

export interface ChangePasswordErrorsInterface {
  currentPassword?: string
  newPassword?: string
}

export interface ProfileState {
  readonly loading: boolean;
  readonly error: IError | null;
  readonly storeContactProfile: UserClass | null;
  readonly changePasswordStatus: string | null;
  readonly changePasswordErrors: ChangePasswordErrorsInterface | null
}
