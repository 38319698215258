import { selectLanguage } from 'ducks/application/selectors';
import React from 'react';
import styled from 'styled-components';

import { useSelector } from 'react-redux';

const ContentContainer = styled.div`
  font-family: Open Sans;
  margin-top: 50px;
`;

const PageLabelWrapper = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
`;

const PageLabel = styled.h1`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  letter-spacing: 0.1px;
  margin: 12px 0 24px;
`;

const StyledH1 = styled.h1`
  font-size: 24px;
  letter-spacing: 0.2px;
  word-spacing: 3px;
  font-style: italic;
  margin-top: 50px;
  font-weight: 400;
`;
const StyledH2 = styled.h2`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 2.25rem;
  margin-bottom: 1rem;
  color: ${({ theme: { colors } }) => colors.disclaimerGrey};
`;

const StyledH3 = styled.h3`
  color: ${({ theme: { colors } }) => colors.disclaimerGrey};
`;

const StyledA = styled.a`
  color: ${({ theme: { colors } }) => colors.mainGreen};
  text-decoration: none;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
    color: ${({ theme: { colors } }) => colors.mainGreenHover};
  }
`;

const StyledLi = styled.li`
  color: ${({ theme: { colors } }) => colors.disclaimerGrey};
  font-size: 14px;
  line-height: 22px;

  .table {
    width: 80%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 10px;
    tr {
      th,
      td {
        font-weight: normal;
        vertical-align: top;
        width: 50%;
        border: solid 1px;
        padding: 10px;
      }
      th {
        padding-right: 10px;
        font-weight: bold;
      }
    }
  }
`;
const StyledP = styled.p`
  margin-top: 0rem;
  margin-bottom: 0.9rem;
  color: ${({ theme: { colors } }) => colors.disclaimerGrey};
  font-size: 14px;
  line-height: 22px;
`;
const StyledP2 = styled.p`
  margin-top: 0rem;
  margin-bottom: 0rem;
  color: ${({ theme: { colors } }) => colors.disclaimerGrey};
  font-size: 14px;
  line-height: 22px;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IPrivacyPolicy {
  language?: string;
}

const PrivacyPolicy: React.FC<IPrivacyPolicy> = ({ language }: IPrivacyPolicy) => {
  const userLanguage = useSelector(selectLanguage);
  const selectedLanguage = language || userLanguage;
  const isEnglish = selectedLanguage === 'en_US' || selectedLanguage === 'en_CA';

  return isEnglish ? (
    <ContentContainer>
      <PageLabelWrapper>
        <StyledP>Last Updated: July 2023</StyledP>
        <PageLabel>PRIVACY POLICY</PageLabel>
      </PageLabelWrapper>
      <StyledP>
        This Privacy Policy (&quot;Policy&quot;) explains how{' '}
        <strong>Taiga Building Products Ltd</strong>. (&quot;Company&quot;, &quot;we&quot;,
        &quot;us&quot;) collects, uses, and discloses information about you through your access or
        use of our websites: <StyledA href="https://www.taiganow.com">https://www.taiganow.com</StyledA> and {' '}
        <StyledA href="www.taigabuilding.com">www.taigabuilding.com</StyledA> (&quot;websites&quot;),
        through opening your account, platform, mobile applications,
        other online services that link to this Policy or when you otherwise interact with us.
        If you obtain your use of the Services in connection with services offered by a third party,
        the individual terms of that third party&apos;s contract with us and its own privacy policy may differ from what is described in this Policy.
        We do not control any third-party websites, and we encourage you to read the privacy policy of every website you visit.
      </StyledP>

      <StyledH1>CHANGES TO THIS POLICY</StyledH1>
      <StyledP>
        We may change this Policy from time to time. If we make any changes to this Policy, we will
        change the &quot;last updated&quot; date above. If there are material changes to this Policy
        and you have an active account with us, we will notify you more directly (such as by adding
        a statement to our website homepage or sending you a notification). We encourage you to
        check this Policy whenever you use any of our Services or otherwise interact with us to
        understand how your personal information is used and the choices available to you.
        If you do not agree with our policies and practices, your choice is not to use our Services.
        By accessing or using our Services, including the websites, you indicate that you understand and consent to the practices described in this Policy.
      </StyledP>

      <StyledH1>CONTENTS</StyledH1>
      <LinksWrapper>
        <StyledA href="#collection-of-information">Collection of Information</StyledA>
        <StyledA href="#use-of-information">Use of Information</StyledA>
        <StyledA href="#sharing-of-information">Sharing of Information</StyledA>
        <StyledA href="#advertising-and-analytics">
          Advertising and Analytics Services Provided by Others
        </StyledA>
        <StyledA href="#record-retention">
          Record Retention
        </StyledA>
        <StyledA href="#netword-and-information-security">
          Network and Information Security
        </StyledA>
        <StyledA href="#childrens-privacy-rights">
          Children’s Privacy Rights
        </StyledA>
        <StyledA href="#california-privacy">Your California Privacy Rights</StyledA>
        <StyledA href="#your-choices">Your Choices</StyledA>
        <StyledA href="#contact-us">Contact Us</StyledA>
      </LinksWrapper>
      <StyledH1 id="collection-of-information">COLLECTION OF INFORMATION</StyledH1>
      <StyledP>
        We collect information from you in various ways when you use our Services.
        We may also supplement this information with information from other companies.
        We collect two general types of information: personal information and aggregated data.
        As used in this Policy, the term &quot;personal information&quot; means information that identifies,
        relates to, describes, is capable of being associated with, or could reasonably be linked,
        directly or indirectly, with a particular individual.
      </StyledP>
      <StyledH2>Information You Provide to Us</StyledH2>
      <StyledP>
        We collect information you provide directly to us.
        For example, we collect information when you register to use our Services, open your account,
        or update your profile, participate in any interactive features of the Services (such as sending a message),
        make a purchase, participate in a contest or promotion, apply for a job, request customer support, or otherwise communicate with us.
        The types of information we may collect include: your name and email address; shipping address; phone number; payment information;
        company name, company title, and other profile information you provide; demographic information; information you upload.
        Please do not upload any sensitive information which has not been requested by Company such as personal information relating to your health,
        ethnic and racial origins, political opinions, genetic and biometric data, sex life or sexual orientation, religious/philosophical beliefs,
        or any information about a minor.
      </StyledP>

      <StyledH2>Other Information We Collect When You Use our Services</StyledH2>
      <StyledP>
        When you access or use our Services, we automatically collect information about you,
        including:
      </StyledP>
      <ul>
        <StyledLi>
          <StyledP>
            <strong>Usage Information:</strong> We automatically receive certain types of
            information when you interact with our Services. For example, it is standard for your
            web browser to automatically send information to every web site you visit, including
            ours. That information includes your computer&apos;s IP address, access times, your
            browser type and language, and referring web site addresses. We may also collect
            information about the type of operating system you use, your account activity, and files
            and pages accessed or used by you.
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            <strong>Transactional Information:</strong> When you make a purchase or return, we will
            collect information about the transaction, such as product details, purchase price, and
            date and location of the transaction.
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            <strong>Log Information:</strong> We collect information related to your access to and
            use of the Services, including the type of browser you use, app version, access times,
            pages viewed, your IP address, and the page you visited before navigating to our
            Services.
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            <strong>Location Information:</strong> We collect information related to your location
            when you use our websites in order to tailor our services, for example to connect you
            with the nearest store or to provide you the websites in the applicable language.
            We use various technologies to determine location, including IP address.
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            <strong>Device and Activity Information:</strong> We collect information about the computer or
            mobile device you use to access our Services, including the hardware model, operating system and version,
            unique device identifiers, and mobile network information. When using your computer or mobile device
            with the Services, data recorded on your computer or mobile device about your activity is transferred
            from your computer or mobile device to our servers. This data is stored and used to provide the
            Service and is associated with your account.
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            <strong>Submissions and Company Service:</strong> From time to time we may use surveys,
            contests or sweepstakes requesting personal or demographic information and customer
            feedback.
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            <strong>Payment and Shipping Information:</strong> If you choose to order a product
            through the Services, payment is accepted based on your account registration information
            which includes your name, billing address, your shipping address and other contact
            details, such as email address and phone number, may also be collected.
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            <strong>Information Collected by Cookies and Similar Tracking Technologies:</strong> We
            (and our service providers) may also use certain kinds of technology such as cookies,
            log files, web beacons and similar technologies to collect personal and other information.
            Among other things, the use of cookies and web beacons enables us to improve our Services
            and emails by seeing which areas and features are most popular, to count the number of computers
            accessing our web site, to personalize and improve your experience, to record your preferences,
            and to allow you to visit our Services without re-entering your account information and/or password.
            A cookie is a small amount of data which is sent to your browser from a web site&apos;s computers and stored
            on your computer&apos;s hard drive. Most browsers automatically accept cookies as the default setting.
            You can modify your browser setting to reject our cookies or to prompt you before accepting a cookie by
            editing your browser options. However, if a browser is set not to accept cookies or if a user rejects a cookie,
            some portions of the web site and services may not function properly. For example, you may not be able to
            sign in and access certain web page features or services. A web beacon is an electronic image, also called a &quot;gif&quot;,
            that may be used on our web pages to deliver cookies, count visits and compile statistics on usage and campaign
            effectiveness or in our emails to tell if an email has been opened and acted upon. In order to properly manage our websites,
            we may anonymously log information on our operational systems, and identify categories of visitors
            by items such as domains and browser types. These statistics are reported in the aggregate to our webmasters and are non-identifiable.
          </StyledP>
        </StyledLi>
      </ul>

      <StyledH2>Information We Collect from Other Sources</StyledH2>
      <StyledP>
        We may also obtain information about you from other sources. For example, we may collect
        information about you from third parties, including but not limited to mailing list
        providers and publicly available sources. Additionally, if you create or log into your
        account through a social media site, we will have access to certain information from that
        site, such as your name, account information, and friends lists, in accordance with the
        authorization procedures determined by such social media site.
      </StyledP>

      <StyledH2>Information We Derive</StyledH2>
      <StyledP>
        We may derive information or draw inferences from you based on the information we or our
        partners collect. For example, we may make inferences about your location based on your IP
        address.
      </StyledP>

      <StyledH1 id="use-of-information">USE OF INFORMATION</StyledH1>
      <StyledP>
        We use your personal information to process your requests or transactions, to
        provide you with information or services you request, to inform you about other information,
        events, promotions, products or services we think will be of interest to you, to facilitate
        your use of, and our administration and operation of, the Services and to otherwise serve
        you and our customers. For example, we may use your personal information:
      </StyledP>
      <ul>
        <StyledLi>
          <StyledP>to present our websites and its contents to you.</StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>to enable you to conduct commercial transactions on the websites.</StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            to send you a welcoming email and to contact you about your use of the Services; to
            respond to your emails, submissions, comments, requests or complaints; to perform
            after-sales services; to anticipate and resolve problems with our service; to respond to
            customer support inquiries, for assistance with our product and service development; and
            to inform you of updates to products and services from Company that better meet your
            needs.
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            to request feedback and to enable us to develop, customize and improve our Services.
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
          to conduct marketing analysis, sometimes working with advertising and personalization
          third parties that use cookies or marketing automation tools, to deliver targeted marketing campaigns
          across multiple platforms (e.g. via web, email, mobile, etc.) and create customer profiles by tracking
          your behavior and preferences while using these platforms. Cookies also assist us with measuring the
          performance of our advertising campaign and with identifying the pages you view, the links and ads you
          click on and the site from which you came before arriving at a webpage. Information gathered may be used
          to personalize and customize future email marketing messages you receive
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
          to send you surveys or newsletters, to contact you about services, products, activities, special events or
          offers from Company or our partners and for other marketing, informational, product development and promotional purposes.
          You may opt out of marketing emails by clicking on the unsubscribe link provided in each email marketing message you receive.
          Please allow up to ten (10) days for your preferences to be processed.
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>to contact you if you win a contest.</StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>to carry out our obligations and enforce our rights arising from any contracts with you,
            including for billing and collection or to comply with legal requirements.</StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>for other purposes about which we notify you.</StyledP>
        </StyledLi>
      </ul>

      <StyledH1 id="sharing-of-information">SHARING OF INFORMATION</StyledH1>
      <StyledP>
        We will not give, sell, rent, share, or trade any of your personal information to any third
        party except as outlined in this Policy or with your consent. We may disclose personal information:
      </StyledP>
      <ul>
        <StyledLi>
          <StyledP>
            To service providers, that perform services for us, including to provide the Services to you, as set below:
            <table className="table">
              <tr>
                <th>Types of service providers</th>
                <th>Examples of information that may be transferred</th>
              </tr>
              <tr>
                <td>Marketing and advertising</td>
                <td>Customer contact details such as email addresses and first names/last names are provided to
                  service providers to allow them to send marketing emails and communications on our behalf.</td>
              </tr>
              <tr>
                <td>Payments and accounting</td>
                <td>Customer transaction data is shared with our payments-related business services providers,
                  to process online payment transactions, to calculate applicable sales tax, to invoice and bill,
                  and to help calculate revenue, pay bills and perform accounting tasks on our behalf.</td>
              </tr>
              <tr>
                <td>Data storage or processing</td>
                <td>Customer purchasing data is stored on cloud platforms.</td>
              </tr>
              <tr>
                <td>Other third-party vendors and service providers</td>
                <td>Customer information is shared with our sales representatives and service providers who ship and deliver your orders,
                  and operate our sales and customer service teams.</td>
              </tr>
              <tr>
                <td>Fraud detection and prevention services</td>
                <td>Customer personal information is shared with fraud detection services to ensure customer data is protected.</td>
              </tr>
            </table>
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            To third parties to protect Company, agents, customers, and others including to enforce our agreements, policies and terms of use;
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            To third parties when the Company, in good faith believes, that disclosure is needed to respond to an emergency, or protect the personal safety of any person;
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            To a potential acquirer, buyer, or successor in the event of an acquisition, merger, restructuring,
            or in connection with a transaction involving the sale of some or all or our business or assets.
            In such an event, you will receive notice if your data is transferred and becomes subject to a substantially different privacy policy;
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            Between and among Company and our current and future parents, affiliates, subsidiaries and other companies under common control and ownership;
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            To comply with any court order, law, or legal process, including to respond to any government or regulatory request, in accordance with applicable law;
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>With your consent.</StyledP>
        </StyledLi>
      </ul>
      <StyledP>
        This Policy in no way restricts or limits our collection and use of aggregated data, and we may share aggregated
        data about you and our users with third parties for various purposes in perpetuity, including to
        help us better understand our customer needs and improve our Services, to provide new Services and for
        advertising and marketing purposes. Aggregated data is information we collect about a group or category of
        services or users from which individual user identities have been removed. In other words, no personal information
        is included in aggregated data. Aggregated data helps us understand trends in our user&apos;s needs so that we
        can better consider new features or otherwise tailor our Services.
      </StyledP>
      <StyledH1 id="">
        TRANSFERRING YOUR PERSONAL INFORMATION
      </StyledH1>
      <StyledP>
        We may process, store, and transfer your personal information in and to a foreign country,
        with different privacy laws that may or may not be as comprehensive as Canadian law.
        In these circumstances, the governments, courts, law enforcement, or regulatory agencies of that country
        may be able to obtain access to your personal information through the laws of the foreign country.
      </StyledP>
      <StyledH1 id="advertising-and-analytics">
        ADVERTISING AND ANALYTICS SERVICES PROVIDED BY OTHERS
      </StyledH1>
      <StyledP>
        We may allow others to provide analytics services on our behalf across the websites and in mobile applications.
        These service providers may use cookies, web beacons, device identifiers and other technologies to collect information
        about your use of the Services and the websites and applications, including your IP address, web browser,
        mobile network information, pages viewed, time spent on pages or in apps, links clicked, where a mouse was scrolled,
        and conversion information. This information may be used by Company and others to, among other things, analyze and track data,
        determine the popularity of certain content, deliver advertising and content targeted to your interests on our Services and other websites,
        and better understand your online activity. For more information about interest-based ads, or to opt out of having your web browsing
        information used for behavioral advertising purposes, please visit www.aboutads.info/choices in the United States.
        You can opt-out of several third party ad servers&apos; and networks&apos; cookies simultaneously by using an opt-out tool
        created by the Digital Advertising Alliance of Canada.
      </StyledP>
      <StyledP>
        We use Google Analytics which allows us to see information on user activities including, but not limited to,
        page views, source and time spent on our website and mobile application. You may opt out of our use of Google Analytics
        by visiting the Google Analytics opt-out page: <StyledA href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</StyledA>.
        We also may use Google Integrated Services, such as BigQuery. More information about Google Analytics
        and its operation can be found by visiting here <StyledA href="https://marketingplatform.google.com/about/analytics/">https://marketingplatform.google.com/about/analytics/</StyledA>.
      </StyledP>
      <StyledH1 id="record-retention">
        RECORD RETENTION
      </StyledH1>
      <StyledP>
        Except as otherwise permitted or required by applicable law or regulation, we will only retain your personal information
        for as long as is necessary to fulfill the purposes for which we originally collected it, including for the purposes of
        satisfying any legal, accounting, or reporting requirements.
      </StyledP>
      <StyledH1 id="netword-and-information-security">NETWORK AND INFORMATION SECURITY</StyledH1>
      <StyledP>
        The security of your personal information is very important to us. We take reasonable steps to protect information we
        collect from you to prevent loss, misuse and unauthorized access, disclosure, alteration, and destruction.
      </StyledP>
      <StyledP>
        The servers on which information is stored are kept in a controlled environment with limited access.
        While we take reasonable efforts to guard personal information we knowingly collect from you, no security system is impenetrable
        and the transmission of information via the Internet is not completely secure. Any transmission of personal information is at your own risk.
        In addition, we cannot guarantee that any collected personal information you choose to include in the Services are maintained
        at adequate levels of protection to meet specific needs or obligations you may have relating to that information.
      </StyledP>

      <StyledH1 id="childrens-privacy-rights">
        CHILDREN’S PRIVACY
      </StyledH1>
      <StyledP>
        Company understands the importance of protecting children&apos;s privacy online.
        The websites and our services are not intentionally designed for or directed at children 13 years of age or younger.
        Company does not knowingly collect or maintain information about anyone under the age of 13.
        If you believe we have collected information from your child in error or have questions or concerns about our
        practices relating to children, please notify us and we will promptly respond.
      </StyledP>

      <StyledH1 id="california-privacy">YOUR CALIFORNIA PRIVACY RIGHTS</StyledH1>
      <StyledP>
        Consumers residing in California are afforded certain additional rights with respect to
        their personal information under the California Consumer Privacy Act or (&quot;CCPA&quot;)
        (California Civil Code Section 1798.100 et seq.) and the &quot;Shine the Light&quot; Law
        (California Civil Code Section 1798.83). If you are a California resident, this section
        applies to you.
      </StyledP>

      <StyledH3>
        <i>California Consumer Privacy Act</i>
      </StyledH3>
      <StyledP>
        <i>
          Additional Disclosures Related to the Collection, Use, Disclosure of Personal Information
        </i>
      </StyledP>
      <StyledP>
        <strong>Collection and Use of Personal Information:</strong> In the preceding 12 months,
        we may have collected the following categories of personal information: identifiers, characteristics of protected classifications
        under California or U.S. law, commercial information, internet and electronic network activity,
        geolocation data, audio and visual information, inferences drawn about your preferences,
        and other categories of personal information that relates to or is reasonably capable of being associated with you.
        For examples of the precise data points we collect and the sources of such collection, please see &quot;Collection of Information&quot;
        above. We collect personal information for the business and commercial purposes described in &quot;Use of Information&quot; above.
      </StyledP>
      <StyledP>
        <strong>Disclosure of Personal Information:</strong> We may share your personal information
        with third parties as described in the &quot;Sharing of Information&quot; section above. In
        the preceding 12 months, we may have disclosed the following categories of personal
        information for business or commercial purposes: identifiers, internet and electronic
        network activity information, commercial information, audio and visual information,
        geolocation data, demographic information, inferred information, and other information that
        we have derived or inferred about you or that relates to or is reasonably capable of being
        associated with you.
      </StyledP>
      <StyledP>
        <strong>Sale of Personal Information:</strong> Company does not, and will not, sell the
        personal information of our customers.
      </StyledP>
      <StyledP>
        <i>Your Consumer Rights</i>
      </StyledP>
      <StyledP>
        If you are a California resident, you have the right to request (1) more information about
        the categories and specific pieces of personal information we have collected and disclosed
        for a business purpose in the last 12 months, (2) deletion of your personal information, and
        (3) to opt out of sales of your personal information, if applicable. You may make these
        requests by calling <StyledA href="tel:6044381471">604.438.1471</StyledA> or visiting this
        page <StyledA href="https://taiganow.com/profile">https://taiganow.com/profile</StyledA>. We
        will verify your request by asking you to provide information related to your recent
        interactions with us, such as information regarding a recent purchase. To designate an
        authorized agent to exercise these rights on your behalf, please contact us. We will not
        discriminate against you if you exercise your rights under the CCPA.
      </StyledP>

      <StyledH1 id="your-choices">YOUR CHOICES</StyledH1>
      <StyledH2>Account Information</StyledH2>
      <StyledP>
        You have the right to request access to personal information we hold about you and you may,
        if provided for by applicable law, have a right to ask that your personal information be corrected,
        erased, or transferred (or withdraw consent to the use of your personal information).
        If you would like to exercise any of these rights, please contact us at <StyledA href="mailto:taiganow@taigabuilding.com">taiganow@taigabuilding.com</StyledA> or
        call us at <StyledA href="tel:6044381471">604.438.1471</StyledA>.
      </StyledP>
      <StyledP>
        We may request specific information from you to help us confirm your identity and your right to access,
        and to provide you with the personal information that we hold about you or make your requested changes.
        Applicable law may allow or require us to refuse to provide you with access to some or all of the personal information
        that we hold about you, or we may have destroyed, erased, or made your personal information anonymous in accordance
        with our record retention obligations and practices. If we cannot provide you with access to your personal information,
        we will inform you of the reasons why, subject to any legal or regulatory restrictions.
      </StyledP>
      <StyledP>
        If you withdraw your consent we may not be able to provide you with a particular product or service.
        We will explain the impact to you at the time to help you with your decision.
      </StyledP>
      <StyledP>
        Please note that we may retain certain information as required by law or for legitimate business purposes after deleting your account.
        We may also retain cached or archived copies of information about you in accordance with our retention policies.
      </StyledP>
      <StyledH2>Promotional Communications</StyledH2>
      <StyledP>
        Company may send you communications or data regarding our Services, including but not
        limited to (i) notices about your use of our Services, including any notices concerning
        violations of use, (ii) updates, and (iii) promotional information and materials regarding
        our Services. You may opt-out of receiving promotional emails from Company by following the
        opt-out instructions provided in those emails. You may also opt-out of receiving promotional
        emails and other promotional communications from us at any time by emailing{' '}
        <StyledA href="mailto:hello@taiganow.com">hello@taiganow.com</StyledA> with your specific
        request. Opt-out requests will not apply to transactional service messages, such as security
        alerts and notices about your current account and Services.
      </StyledP>

      <StyledH1 id="contact-us">CONTACT</StyledH1>
      <StyledH2>Questions, Requests, or Complaints</StyledH2>
      <StyledP>
        We welcome your questions, comments, and requests regarding this Policy and our privacy practices. Please contact us at:
      </StyledP>
      <StyledH3>Privacy Officer</StyledH3>
      <StyledP2><StyledA href="mailto:taiganow@taigabuilding.com">taiganow@taigabuilding.com</StyledA>.</StyledP2>
      <StyledP2>Taiga Building Products</StyledP2>
      <StyledP2>800–4710 Kingsway, Burnaby, BC V5H 4M2</StyledP2>
      <StyledP>800.667.1470 (Toll Free)</StyledP>
      <StyledP>To discuss our compliance with this Policy please contact our Privacy Officer using the contact information listed above.</StyledP>
    </ContentContainer>
  ) : (
    <ContentContainer>
      <PageLabelWrapper>
        <StyledP>Dernière mise à jour : Mars 2021</StyledP>
        <PageLabel>POLITIQUE SUR LA PROTECTION DE LA VIE PRIVÉE</PageLabel>
      </PageLabelWrapper>
      <StyledP>
        La présente politique sur la protection de la vie privée (la &quot; Politique&quot;)
        explique comment <strong>Produits de Batiment Taiga Ltée</strong> (la &quot;Société&quot; et
        &quot;nous&quot;) recueille, utilise et communique les renseignements vous concernant
        obtenus lors de votre accès à nos sites Web ou de votre utilisation de ceux-ci, de
        l’ouverture de votre compte et de l’utilisation de la plateforme, des applications mobiles
        et d’autres services en ligne qui comportent un lien menant à Politique (collectivement, les
        &quot;Services&quot;), ou lors de toute autre interaction avec nous. i vous utilisez les
        Services dans le cadre de services offerts par un tiers, les modalités individuelles du
        contrat entre ce tiers et nous et sa propre politique sur la protection de la vie privée
        peuvent différer de ce qui est décrit dans la Politique.
      </StyledP>

      <StyledH1>Modifications à la Politique</StyledH1>
      <StyledP>
        Nous pouvons modifier la Politique de temps à autre. Si nous apportons des modifications à
        la Politique, nous changerons également la date de &quot;dernière mise à jour&quot;
        ci-dessus. Si des modifications importantes sont apportées à la Politique et que vous avez
        un compte actif auprès de nous, nous vous en informerons directement (par exemple en
        ajoutant une déclaration sur la page d’accueil de notre site Web ou en vous envoyant un
        avis). Nous vous encourageons à consulter la Politique chaque fois que vous utilisez l’un
        des Services ou interagissez avec nous pour comprendre comment vos renseignements personnels
        sont utilisés et les choix qui s’offrent à vous.
      </StyledP>

      <StyledH1>TABLE DES MATIÈRES</StyledH1>
      <LinksWrapper>
        <StyledA href="#collection-of-information">Collecte de renseignements</StyledA>
        <StyledA href="#use-of-information">Utilisation des renseignements</StyledA>
        <StyledA href="#sharing-of-information">Communication des renseignements</StyledA>
        <StyledA href="#advertising-and-analytics">
          Services de publicité et d’analyse fournis par des tiers
        </StyledA>
        <StyledA href="#transfer-of-information">
          Transfert de renseignements aux États-Unis et à d’autres pays
        </StyledA>
        <StyledA href="#your-choices">Vos choix</StyledA>
        <StyledA href="#california-privacy">Vos droits à la vie privée en Californie</StyledA>
        <StyledA href="#eu-privacy">
          Autres déclarations pour les personnes de l’Union européenne
        </StyledA>
        <StyledA href="#contact-us">Coordonnées</StyledA>
      </LinksWrapper>
      <StyledH1 id="collection-of-information">COLLECTE DE RENSEIGNEMENTS</StyledH1>
      <StyledP>
        Nous recueillons les renseignements que vous nous fournissez directement. Par exemple, nous
        recueillons des renseignements lorsque vous vous inscrivez pour utiliser les Services,
        ouvrez votre compte ou mettez à jour votre profil. Nous pouvons recueillir différents types
        de renseignements vous concernant, notamment votre nom et votre adresse courriel, votre
        adresse d’expédition, le nom de votre société, votre poste et d’autres renseignements de
        profil que vous fournissez, des renseignements démographiques et d’autres renseignements que
        vous choisissez de téléverser.
      </StyledP>
      <StyledP>
        Nous recueillons des renseignements sur vous de plusieurs façons lorsque vous utilisez les
        Services. Nous pouvons également compléter ces renseignements par des renseignements
        provenant d’autres sociétés. Nous recueillons deux types généraux de renseignements: les
        renseignements personnels et les données agrégées. Dans la Politique, le terme
        &quot;renseignements personnels&quot; désigne les renseignements au sujet d’un consommateur
        ou d’un foyer donné permettant de les identifier, ou pouvant raisonnablement y être associés
        ou liés, directement ou indirectement.
      </StyledP>

      <StyledH2>Renseignements que vous nous fournissez</StyledH2>
      <StyledP>
        Nous recueillons les renseignements que vous nous fournissez directement. Par exemple, nous
        recueillons des renseignements lorsque vous vous inscrivez pour utiliser les Services,
        mettez à jour votre profil, utilisez des fonctions interactives des Services (comme l’envoi
        d’un message), effectuez un achat, participez à un concours ou à une promotion, postulez à
        un emploi, demandez du soutien à la clientèle ou communiquez avec nous de toute autre
        manière. Les types de renseignements que nous pouvons recueillir comprennent votre nom et
        votre adresse électronique, votre adresse d’expédition, votre numéro de téléphone, vos
        renseignements de paiement, le nom de votre société, votre poste et d’autres renseignements
        de profil que vous fournissez, des renseignements démographiques et des renseignements que
        vous téléversez.
      </StyledP>

      <StyledH2>
        Autres renseignements que nous recueillons lorsque vous utilisez les Services
      </StyledH2>
      <StyledP>
        Lorsque vous accédez aux Services ou les utilisez, nous recueillons automatiquement des
        renseignements vous concernant, notamment :
      </StyledP>
      <ul>
        <StyledLi>
          <StyledP>
            <strong>Renseignements sur l’utilisation:</strong> Nous recevons automatiquement
            certains types de renseignements lorsque vous interagissez avec les Services. Par
            exemple, il est courant que votre navigateur Web envoie automatiquement des
            renseignements à chaque site Web que vous visitez, y compris le nôtre. Ces
            renseignements comprennent l’adresse IP de votre ordinateur, le moment des accès, le
            type et la langue de votre navigateur et les adresses des sites Web de référence. Nous
            pouvons également recueillir des renseignements sur le type de système d’exploitation
            que vous utilisez, l’activité de votre compte, ainsi que les fichiers et les pages
            auxquels vous accédez ou que vous utilisez.
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            <strong>Renseignements sur les transactions:</strong> Lorsque vous effectuez un achat ou
            un retour, nous recueillons des renseignements sur la transaction, comme les détails du
            produit, le prix d’achat, la date et le lieu de la transaction.
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            <strong>Renseignements sur vos visites:</strong> Nous recueillons des renseignements
            liés à votre accès aux Services et à leur utilisation, notamment le type de navigateur
            que vous utilisez, la version de l’application, le moment des accès, les pages
            consultées, votre adresse IP et la page que vous avez visitée avant d’accéder aux
            Services.
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            <strong>Informations sur les appareils et les activités:</strong> Nous recueillons des
            renseignements sur l’ordinateur ou l’appareil mobile que vous utilisez pour accéder aux
            Services, notamment le modèle de l’appareil, le système d’exploitation et sa version,
            les identifiants uniques de l’appareil et les renseignements sur le réseau mobile.
            Lorsque vous utilisez les Services au moyen de votre ordinateur ou appareil mobile, les
            données sur vos activités enregistrées sur votre ordinateur ou votre appareil mobile
            sont transférées à nos serveurs. Ces données sont stockées et utilisées pour fournir les
            Services et sont associées à votre compte. Nous recueillons également des renseignements
            sur les protocoles de reprise que vous utilisez.
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            <strong>Soumissions et service de la Société:</strong> Occasionnellement, nous pouvons
            utiliser des sondages, des concours ou des loteries demandant des renseignements
            personnels ou démographiques et des commentaires des clients.
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            <strong>Renseignements pour le paiement et l’expédition:</strong> Si vous choisissez de
            commander un produit par l’intermédiaire des Services, le paiement est accepté en
            fonction des renseignements d’inscription de votre compte, qui comprennent vos nom,
            adresse de facturation et adresse d’expédition; d’autres coordonnées, comme votre
            adresse courriel et votre numéro de téléphone, peuvent également être recueillies.
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            <strong>
              Renseignements recueillis par les témoins et les technologies de suivi similaires:
            </strong>
            Nous (et nos fournisseurs de services) pouvons également utiliser certains types de
            technologie, comme des témoins et des pixels espions, pour recueillir des
            renseignements. L’utilisation de témoins et de pixels espions nous permet, entre autres,
            d’améliorer les Services et nos courriels en déterminant les zones et les fonctions les
            plus populaires, de compter le nombre d’ordinateurs qui accèdent à notre site Web, de
            personnaliser et d’améliorer votre expérience et d’enregistrer vos préférences, et vous
            permet d’utiliser les Services sans avoir à saisir à nouveau vos renseignements de
            compte ou votre mot de passe. Un témoin est une petite quantité de données qui est
            envoyée à votre navigateur à partir des ordinateurs d’un site Web et stockée sur le
            disque dur de votre ordinateur. La plupart des navigateurs acceptent automatiquement les
            témoins par défaut. Vous pouvez modifier les paramètres de votre navigateur pour refuser
            nos témoins ou pour vous inviter à accepter un témoin. Toutefois, si un navigateur est
            configuré pour ne pas accepter les témoins ou si un utilisateur refuse un témoin,
            certaines parties du site Web et des Services pourraient ne pas fonctionner
            correctement. Par exemple, vous ne pourrez peut-être pas vous ouvrir une session et
            accéder à certaines fonctions ou à certains services de la page Web. Un pixel espion est
            une image électronique, également appelée &quot;gif&quot;, qui peut être utilisée sur
            nos pages Web pour transmettre des témoins, compter les visites et compiler des
            statistiques sur l’utilisation et l’efficacité des campagnes, ou dans nos courriels pour
            indiquer si un courriel a été ouvert et traité.
          </StyledP>
        </StyledLi>
      </ul>

      <StyledH2>Renseignements que nous recueillons auprès d’autres sources</StyledH2>
      <StyledP>
        Nous pouvons également obtenir des renseignements à votre sujet auprès d’autres sources. Par
        exemple, nous pouvons recueillir des renseignements vous concernant auprès de tiers,
        notamment auprès de fournisseurs de listes d’envoi et de sources accessibles au public. De
        plus, si vous créez un compte ou y accédez par l’entremise d’un site de médias sociaux, nous
        aurons accès à certains renseignements de ce site, comme votre nom, les renseignements de
        votre compte et vos listes d’amis, conformément aux procédures d’autorisation établies par
        ce site de médias sociaux.
      </StyledP>

      <StyledH2>Renseignements que nous produisons</StyledH2>
      <StyledP>
        Nous pouvons produire des renseignements ou faire des déductions à votre sujet en fonction
        des renseignements recueillis par nous ou nos partenaires. Par exemple, nous pouvons faire
        des déductions sur votre emplacement à partir de votre adresse IP.
      </StyledP>

      <StyledH1 id="use-of-information">UTILISATION DES RENSEIGNEMENTS</StyledH1>
      <StyledP>
        En général, nous utilisons vos renseignements personnels pour traiter vos demandes ou vos
        transactions, pour vous fournir les renseignements ou les services que vous demandez, pour
        vous informer sur d’autres renseignements, événements, promotions, produits ou services qui,
        selon nous, sont susceptibles de vous intéresser, pour faciliter votre utilisation des
        Services et notre administration et notre exploitation de ceux-ci et pour vous servir, vous
        et nos clients. Par exemple, nous pouvons utiliser vos renseignements personnels aux fins
        suivantes
      </StyledP>
      <ul>
        <StyledLi>
          <StyledP>vous permettre d’effectuer des transactions commerciales sur le site;</StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            vous envoyer un courriel de bienvenue et communiquer avec vous au sujet de votre
            utilisation des Services, répondre à vos courriels, soumissions, commentaires, demandes
            ou plaintes, assurer le service après-vente, anticiper et résoudre les problèmes liés à
            notre service, répondre aux demandes de service à la clientèle, nous aider à développer
            nos produits et services et vous informer des produits et services de la Société ayant
            été mis à jour pour répondre à vos besoins;
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            demander des commentaires en vue de développer, de personnaliser et d’améliorer les
            Services;
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            effectuer des analyses de marketing, vous envoyer des sondages ou des bulletins
            d’information, communiquer avec vous au sujet de services, de produits, d’activités,
            d’événements spéciaux ou d’offres de la Société ou de nos partenaires et à d’autres fins
            de marketing, d’information, de développement de produits et de promotion;
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>communiquer avec vous si vous gagnez un concours;</StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>à d’autres fins dont nous vous informerons.</StyledP>
        </StyledLi>
      </ul>

      <StyledH1 id="sharing-of-information">COMMUNICATION DES RENSEIGNEMENTS</StyledH1>
      <StyledP>
        Nous nous abstenons de donner, vendre, louer, partager ou échanger vos renseignements
        personnels à des tiers, sauf dans les cas prévus dans la Politique ou avec votre
        consentement. Nous pouvons communiquer des renseignements personnels:
      </StyledP>
      <ul>
        <StyledLi>
          <StyledP>
            avec nos fournisseurs de services, y compris ceux mandatés pour vous fournir les
            Services, notamment en fournissant des renseignements personnels à nos fournisseurs de
            traitement des paiements, aux fabricants d’équipement d’origine des produits que vous
            commandez sur le site, et à d’autres tiers vendeurs ou fournisseurs de services qui ont
            conclu un contrat avec nous pour vous fournir les Services et qui ont besoin de ces
            renseignements pour effectuer des travaux en notre nom et qui peuvent utiliser les
            renseignements personnels pour améliorer leurs services;
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            avec nos fournisseurs de services de détection et de prévention des fraudes;
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            avec des tiers pour protéger la Société, ses mandataires, ses clients et d’autres
            personnes, notamment pour faire respecter nos ententes, politiques et conditions
            d’utilisation;
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            avec des tiers lorsque la Société juge, de bonne foi, que la communication des
            renseignements est nécessaire pour répondre à une urgence ou protéger la sécurité de
            toute personne;
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>
            dans le cadre d’une acquisition, d’une fusion ou de la vente de la totalité ou d’une
            partie substantielle de nos activités, avec ou à une autre société. Dans un tel cas,
            vous recevrez un avis si vos données sont transférées et assujetties à une politique sur
            la protection de la vie privée sensiblement différente; entre la Société et nos sociétés
            mères, les membres de notre groupe, nos filiales et nos autres sociétés sous contrôle
            commun, actuels et futurs;
          </StyledP>
        </StyledLi>
        <StyledLi>
          <StyledP>avec votre consentement ou selon vos directives.</StyledP>
        </StyledLi>
      </ul>
      <StyledP>
        La Politique ne limite d’aucune façon notre collecte et notre utilisation de données
        agrégées, et nous pouvons communiquer à perpétuité des données agrégées vous concernant et
        concernant nos utilisateurs avec des tiers à diverses fins, notamment pour nous aider à
        mieux comprendre les besoins de nos clients et à améliorer les Services, pour fournir de
        nouveaux Services et à des fins de publicité et de marketing. Les données agrégées sont des
        renseignements que nous recueillons sur un groupe ou une catégorie de services ou
        d’utilisateurs dont les identités individuelles des utilisateurs ont été supprimées.
        Autrement dit, les données agrégées ne contiennent aucun renseignement personnel. Les
        données agrégées nous aident à comprendre les tendances des besoins de nos utilisateurs en
        vue de concevoir de nouvelles fonctions ou d’adapter les Services.
      </StyledP>

      <StyledH1 id="advertising-and-analytics">
        SERVICES DE PUBLICITÉ ET D’ANALYSE FOURNIS PAR DES TIERS
      </StyledH1>
      <StyledP>
        Nous pouvons autoriser des tiers à fournir des services d’analyse [et à diffuser des
        publicités] pour notre compte sur le Web et dans les applications mobiles. Ces entités
        peuvent utiliser des témoins, des pixels espions, des identifiants d’appareils et d’autres
        technologies pour recueillir des renseignements sur votre utilisation des Services et
        d’autres sites Web et applications, y compris votre adresse IP, votre navigateur Web, des
        renseignements sur le réseau mobile, les pages consultées, le temps passé sur les pages ou
        dans les applications, les liens cliqués et des renseignements de conversion. Ces
        renseignements peuvent être utilisés par la Société et autrui pour, notamment, analyser et
        suivre les données, mesurer la popularité de certains contenus, diffuser de la publicité et
        du contenu ciblant vos intérêts sur les Services et d’autres sites Web, et mieux comprendre
        vos activités en ligne. Pour en savoir plus sur les publicités axées sur les centres
        d’intérêt, ou pour refuser que vos renseignements de navigation sur Internet soient utilisés
        aux fins de publicité comportementale, veuillez consulter le site{' '}
        <StyledA href="https://aboutads.info/choices">www.aboutads.info/choices</StyledA> aux
        États-Unis.
      </StyledP>

      <StyledH2>Conservation des données</StyledH2>
      <StyledP>
        Nous conservons les renseignements que nous recueillons à votre sujet aussi longtemps que
        nécessaire aux fins pour lesquelles nous les avons initialement recueillis. Nous pouvons
        conserver certains renseignements à des fins commerciales légitimes ou si la loi l’exige.
      </StyledP>

      <StyledH2>Sécurité du réseau et des renseignements</StyledH2>
      <StyledP>
        Nous prenons des mesures raisonnables pour protéger les renseignements que nous recueillons
        auprès de vous afin d’éviter la perte, l’utilisation abusive et l’accès, la communication,
        l’altération et la destruction non autorisés.
      </StyledP>
      <StyledP>
        Les serveurs sur lesquels sont stockés les renseignements sont conservés dans un
        environnement contrôlé à accès limité. Bien que nous prenions des mesures raisonnables pour
        protéger les renseignements personnels que nous recueillons directement auprès de vous en
        toute connaissance de cause, aucun système de sécurité n’est infaillible. En outre, nous ne
        pouvons pas garantir que les renseignements personnels recueillis que vous choisissez
        d’inclure dans les Services sont adéquatement protégés eu égard à vos besoins ou obligations
        particulières concernant ces renseignements.
      </StyledP>

      <StyledH1 id="transfer-of-information">
        TRANSFERT DE RENSEIGNEMENTS AUX ÉTATS-UNIS ET À D’AUTRES PAYS
      </StyledH1>
      <StyledP>
        Pour fournir les Services, nous pouvons transférer vos renseignements à nos serveurs aux
        États-Unis ou dans d’autres pays. Lorsque nous transférons des renseignements de l’Espace
        économique européen (&quot;EEE&quot;) vers des pays qui ne sont pas réputés offrir un niveau
        de protection adéquat, nous utilisons des accords sur le traitement de données fondés sur
        les clauses contractuelles standard pour aider à protéger vos données, quel que soit le lieu
        de leur transfert.
      </StyledP>
      <StyledP>
        Conformément aux lois canadiennes et américaines sur la protection de la vie privée, nous
        nous engageons à résoudre les plaintes relatives au traitement de vos données personnelles.
        Veuillez communiquer avec notre agent de protection de la vie privée à l’adresse{' '}
        <StyledA href="mailto:privacy@taiganow.com">privacy@taiganow.com</StyledA> pour discuter de
        votre plainte et nous travaillerons avec vous pour la résoudre efficacement et rapidement.
      </StyledP>
      <StyledP>
        À certaines conditions, vous pourriez demander un arbitrage exécutoire pour régler votre
        plainte. La Société est assujettie aux pouvoirs d’enquête et d’application du Commissariat à
        la protection de la vie privée du Canada et de la Federal Trade Commission des États-Unis.
      </StyledP>

      <StyledH2>Mise à jour des renseignements de votre compte</StyledH2>
      <StyledP>
        Si vos renseignements personnels changent de quelque manière que ce soit, vous devez
        corriger ou mettre à jour vos renseignements afin de pouvoir commander des produits par
        l’intermédiaire des Services. Vous pouvez mettre à jour les renseignements de votre compte
        accédant à votre compte des Services à tout moment.
      </StyledP>

      <StyledH1 id="your-choices">VOS CHOIX</StyledH1>
      <StyledH2>Renseignements sur le compte</StyledH2>
      <StyledP>
        Vous pouvez mettre à jour et corriger certains renseignements que vous nous fournissez dans
        votre compte à tout moment en nous envoyant un courriel à l’adresse{' '}
        <StyledA href="mailto:taiganow@taigabuilding.com">taiganow@taigabuilding.com</StyledA>, ou en nous
        appelant au <StyledA href="tel:6044381471">604.438.1471</StyledA>. Si vous souhaitez
        supprimer votre compte, veuillez nous envoyer un courriel à l’adresse{' '}
        <StyledA href="mailto:taiganow@taigabuilding.com">taiganow@taigabuilding.com</StyledA>. Veuillez noter
        que nous pouvons conserver certains renseignements si la loi l’exige ou à des fins
        commerciales légitimes. Nous pouvons également conserver des copies en cache ou archivées
        des renseignements vous concernant pendant un certain temps.
      </StyledP>

      <StyledH2>Témoins</StyledH2>
      <StyledP>
        La plupart des navigateurs Web sont configurés pour accepter les témoins par défaut. Si vous
        préférez, vous pouvez généralement choisir de configurer votre navigateur pour qu’il
        supprime ou refuse les témoins du navigateur. Veuillez noter que si vous choisissez de
        supprimer ou de refuser les témoins, cela pourrait avoir une incidence sur la disponibilité
        et la fonctionnalité des Services.
      </StyledP>

      <StyledH2>Communications promotionnelles</StyledH2>
      <StyledP>
        La Société peut vous envoyer des communications ou des données concernant les Services,
        notamment (i) des avis concernant votre utilisation des Services, y compris tout avis
        concernant des violations d’utilisation; (ii) des mises à jour; (iii) des renseignements et
        des documents promotionnels concernant les Services. Vous pouvez refuser de recevoir des
        courriels promotionnels de la Société en suivant les directives de retrait fournies dans ces
        courriels. Vous pouvez également choisir de ne pas recevoir de courriels promotionnels et
        d’autres communications promotionnelles de notre part à tout moment en envoyant une demande
        à cet effet par courriel à{' '}
        <StyledA href="mailto:hello@taiganow.com">hello@taiganow.com</StyledA>. Les demandes
        d’exclusion ne s’appliqueront pas aux messages de service transactionnels, comme les alertes
        de sécurité et les avis concernant votre compte et vos services actuels.
      </StyledP>

      <StyledH1 id="california-privacy">VOS DROITS À LA VIE PRIVÉE EN CALIFORNIE</StyledH1>
      <StyledP>
        Les consommateurs qui résident en Californie bénéficient de certains droits supplémentaires
        relativement à leurs renseignements personnels en vertu de la California Consumer Privacy
        Act (&quot;CCPA&quot;) article 1798.100 et suiv. du Code civil de la Californie) et de la
        loi &quot;Shine the Light&quot; (article 1798.83 du Code civil de la Californie). Si vous
        êtes un résident de Californie, cette section s’applique à vous.
      </StyledP>

      <StyledH3>
        <i>California Consumer Privacy Act</i>
      </StyledH3>
      <StyledP>
        <i>
          Autres déclarations liées à la collecte, à l’utilisation et à la communication de
          renseignements personnels
        </i>
      </StyledP>
      <StyledP>
        <strong>Collecte et utilisation de renseignements personnels:</strong> Au cours des 12 mois
        précédents, nous pourrions avoir recueilli les catégories suivantes de renseignements
        personnels : identifiants, caractéristiques des classifications protégées par les lois
        californiennes ou américaines, renseignements commerciaux, activité sur Internet et sur les
        réseaux électroniques, données de géolocalisation, renseignements audio et visuels,
        déductions faites au sujet de vos préférences, et autres catégories de données personnelles
        qui vous concernent ou qui peuvent raisonnablement vous être associées. Pour des exemples
        des points de données précis que nous recueillons et des sources de cette collecte, veuillez
        consulter l’article &quot;Collecte de renseignements&quot; ci-dessus. Nous recueillons des
        renseignements personnels à des fins professionnelles et commerciales décrites à l’article
        &quot;Utilisation des renseignements&quot; ci-dessus.
      </StyledP>
      <StyledP>
        <strong>Communication de renseignements personnels:</strong> Nous pouvons communiquer vos
        renseignements personnels à des tiers de la façon indiquée à l’article &quot;Communication
        des renseignements&quot; ci-dessus. Au cours des 12 mois précédents, nous pourrions avoir
        communiqué les catégories suivantes de renseignements personnels à des fins professionnelles
        ou commerciales : identifiants, renseignements sur l’activité sur Internet et sur les
        réseaux électroniques, renseignements commerciaux, renseignements audio et visuels, données
        de géolocalisation, renseignements démographiques, déductions et autres renseignements que
        nous avons produits qui vous concernent ou qui peuvent raisonnablement vous être associés.
      </StyledP>
      <StyledP>
        <strong>Vente de renseignements personnels:</strong> La Société ne vend pas, et ne vendra
        pas, les renseignements personnels de ses clients.
      </StyledP>
      <StyledP>
        <i>Vos droits de consommateur</i>
      </StyledP>
      <StyledP>
        Si vous êtes un résident de Californie, vous avez le droit de demander (1) de plus amples
        renseignements sur les catégories et les éléments particuliers de renseignements personnels
        que nous avons recueillis et communiqués à des fins commerciales au cours des 12 derniers
        mois; (2) la suppression de vos renseignements personnels; (3) le refus de la vente de vos
        renseignements personnels, le cas échéant. Vous pouvez faire ces demandes en composant le{' '}
        <StyledA href="tel:6044381471">604.438.1471</StyledA> ou en vous rendant sur la page{' '}
        <StyledA href="https://taiganow.com/profile">https://taiganow.com/profile</StyledA>. Nous
        confirmerons votre demande en vous demandant de fournir des renseignements liés à vos
        récentes interactions avec nous, comme des renseignements concernant un achat récent. Pour
        désigner un mandataire autorisé à exercer ces droits en votre nom, veuillez communiquer avec
        nous. Vous ne subirez aucune discrimination si vous exercez vos droits en vertu de la CCPA.
      </StyledP>

      <StyledH2>Demandes des personnes concernées par les données</StyledH2>
      <StyledP>
        Vous avez le droit d’accéder aux données personnelles que nous détenons à votre sujet et de
        demander que vos données personnelles soient corrigées, effacées ou transférées. Vous pouvez
        également avoir le droit de vous opposer à certains traitements ou de nous demander de les
        restreindre. Si vous souhaitez exercer l’un de ces droits, veuillez communiquer avec nous à
        l’adresse <StyledA href="mailto:taiganow@taigabuilding.com">taiganow@taigabuilding.com</StyledA> ou nous
        appeler au <StyledA href="tel:6044381471">604.438.1471</StyledA>. Vous pouvez également
        communiquer avec nous en utilisant les coordonnées ci-dessous pour exercer ces droits.
      </StyledP>

      <StyledH2>Questions ou plaintess</StyledH2>
      <StyledP>
        Si vous avez des préoccupations concernant notre traitement des données personnelles que
        nous ne sommes pas en mesure de résoudre, vous avez le droit de déposer une plainte auprès
        de l’agent de protection de la vie privée à l’adresse{' '}
        <StyledA href="mailto:privacy@taiganow.com">privacy@taiganow.com</StyledA>.
      </StyledP>

      <StyledH1 id="contact-us">COORDONNÉES</StyledH1>
      <StyledP>
        Si vous avez des questions concernant la présente politique sur la protection de la vie
        privée, veuillez communiquer avec nous à l’adresse:{' '}
        <StyledA href="mailto:taiganow@taigabuilding.com">taiganow@taigabuilding.com</StyledA>.
      </StyledP>
    </ContentContainer>
  );
};

export default PrivacyPolicy;
