import React from 'react';
import { Route } from 'react-router-dom';

import { ErrorComponent } from 'components';

type Props = {
  component: React.ComponentType<any>;
  [key: string]: unknown;
};

const ErrorRoutes: React.FC<Props> = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <ErrorComponent>
          <Component {...props} />
        </ErrorComponent>
      )}
    />
  );
};

export default ErrorRoutes;
