import {IMainCategory} from 'models/interfaces/product';
import {IInventoryStatus, INewInventory, IPrice, IProductImage, IUnits} from 'models/interfaces/productVariant';

export enum QuickOrderActionTypes {
	REQUEST_GET_QUICK_ORDER_ITEMS = '@@quickOrder/REQUEST_GET_QUICK_ORDER_ITEMS',
	REQUEST_GET_QUICK_ORDER_ITEMS_SUCCESS = '@@quickOrder/REQUEST_GET_QUICK_ORDER_ITEMS_SUCCESS',
	REQUEST_GET_QUICK_ORDER_ITEMS_ERROR = '@@quickOrder/REQUEST_GET_QUICK_ORDER_ITEMS_ERROR',
	ADD_ITEM_TO_QUICK_ORDER = '@@quickOrder/ADD_ITEM_TO_QUICK_ORDER',
	ADD_ITEM_TO_QUICK_ORDER_SUCCESS = '@@quickOrder/ADD_ITEM_TO_QUICK_ORDER_SUCCESS',
	UPDATE_QUICK_ORDER_ITEM_QUANTITY = '@@quickOrder/UPDATE_QUICK_ORDER_ITEM_QUANTITY',
	UPDATE_QUICK_ORDER_ITEM_QUANTITY_SUCCESS = '@@quickOrder/UPDATE_QUICK_ORDER_ITEM_QUANTITY_SUCCESS',
	REQUEST_REMOVE_QUICK_ORDER_ITEM = '@@quickOrder/REQUEST_REMOVE_QUICK_ORDER_ITEM',
	REQUEST_REMOVE_QUICK_ORDER_ITEM_SUCCESS = '@@quickOrder/REQUEST_REMOVE_QUICK_ORDER_ITEM_SUCCESS',
	REQUEST_CLEAR_ALL_QUICK_ORDER_ITEMS = '@@quickOrder/REQUEST_CLEAR_ALL_QUICK_ORDER_ITEMS',
	REQUEST_BULK_ADD_TO_CART = '@@quickOrder/REQUEST_BULK_ADD_TO_CART',
	REPLACE_QUICK_ORDER_ITEMS = '@@quickOrder/REPLACE_QUICK_ORDER_ITEMS',
	DISABLE_CHECKOUT = '@@quickOrder/DISABLE_CHECKOUT',
	REQUEST_CLEAR_SEARCH_TERM = '@@quickOrder/REQUEST_CLEAR_SEARCH_TERM',
	REQUEST_REPLACE_QUICK_ORDER_ITEM = '@@quickOrder/REQUEST_REPLACE_QUICK_ORDER_ITEM',
	REQUEST_REPLACE_QUICK_ORDER_ITEM_SUCCESS = '@@quickOrder/REQUEST_REPLACE_QUICK_ORDER_ITEM_SUCCESS',
	REQUEST_BULK_ADD_TO_CART_SUCCESS = '@@quickOrder/REQUEST_BULK_ADD_TO_CART_SUCCESS',
	REQUEST_SET_QUICK_ORDER_ADD_TO_CART_STATUS = '@@quickOrder/REQUEST_SET_QUICK_ORDER_ADD_TO_CART_STATUS',
	REQUEST_CLEAR_QUICK_ORDER = '@@quickOrder/REQUEST_CLEAR_QUICK_ORDER',
	REQUEST_CLEAR_QUICK_ORDER_SUCCESS = '@@quickOrder/REQUEST_CLEAR_QUICK_ORDER_SUCCESS',
}

export type IQuickOrderItem = {
	id: number;
	name: string;
	nameEn: string;
	nameFr: string;
	productId: number;
	erpProductId: number;
	quantity: number;
	erpSku: string;
	inventoryStatus: IInventoryStatus;
	prices: IPrice[];
	units: IUnits;
	total: number;
	images: Array<IProductImage>;
	category: IMainCategory;
	newInventory: INewInventory;
}

export interface QuickOrderState {
	readonly loading: boolean;
	readonly error: string | null;
	readonly quickOrderItems: IQuickOrderItem[];
	readonly disableCheckout: boolean;
	readonly clearSearchTerm: boolean;
	readonly quickOrderAddToCartStatus: boolean;
	readonly quickOrderProducts: Array<{
		productId: number;
		quantity: number;
		categoryId: number;
	}>
}

export interface BulkAddToCartAction {
	type: QuickOrderActionTypes.REQUEST_BULK_ADD_TO_CART;
	payload: {
		products: {
			productId: number;
			quantity: number;
			categoryId: number;
		}[];
		deliveryType: string;
	};
}

export interface AddItemToQuickOrderAction {
	type: QuickOrderActionTypes.ADD_ITEM_TO_QUICK_ORDER;
	payload: {
		productId: number;
	};
}

export interface UpdateQuickOrderItemQuantityAction {
	type: QuickOrderActionTypes.UPDATE_QUICK_ORDER_ITEM_QUANTITY;
	payload: {
		quickOrderItemId: number;
		quantity: number;
	};
}

export interface RemoveQuickOrderItemAction {
	type: QuickOrderActionTypes.REQUEST_REMOVE_QUICK_ORDER_ITEM;
	payload: {
		quickOrderItemId: number;
	};
}

export interface ReplaceQuickOrderItemAction {
	type: QuickOrderActionTypes.REQUEST_REPLACE_QUICK_ORDER_ITEM;
	payload: {
		quickOrderItemId: number;
		productId: number;
		categoryId: number;
	};
}