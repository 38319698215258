import { useMemo } from 'react';
import { ProductClass } from 'models/classes/productClass';
import { ProductVariantClass } from 'models/classes/productVariantClass';
import { IProductOptions } from 'models/interfaces/product';
import { parseInt } from 'lodash';

interface IProduct {
	product?: ProductClass;
	variantCode?: string;
	productId: number|string;
	chosenProductVariant: ProductVariantClass;
}

/**
 * Get current product variant
 */
export const useProductVariant = ({ product, productId, chosenProductVariant }: IProduct) => {
  return useMemo(() => {
    if (chosenProductVariant) {
      return chosenProductVariant;
    }
    if (product) {
      if (productId) {
        return product.variants.find(variant => parseInt(String(variant.erpProductId)) === parseInt(String(productId)));
      }
      const [defaultVariant] = product.variants;

      if (product.variants.length) {
        return defaultVariant;
      }
    }

    return null;
  }, [product, productId, chosenProductVariant]);
};

/**
 * Group all product options
 */
export const useProductOptions = (product: ProductClass | null) => {
  return useMemo(() => {
    if (product) {
      return product.variants.reduce<IProductOptions>((accum, variant) => {
        const result = { ...accum };

        variant.optionValues.forEach(variantOptionValue => {
          if (!result[variantOptionValue.code]) {
            result[variantOptionValue.code] = [];
          }
          const productOptionValue = product.options
            .find(item => item.code === variantOptionValue.code)
            ?.values.find(item => item.optionCode === variantOptionValue.optionCode);

          if (
            !result[variantOptionValue.code].some(
              item => item.optionCode === productOptionValue.optionCode,
            )
          ) {
            result[variantOptionValue.code].push({ ...productOptionValue });
          }
        });

        return result;
      }, {});
    }

    return null;
  }, [product]);
};
