import React, { useMemo } from 'react';
// Material
import { Grid } from '@material-ui/core';

import {
  LocationIcon,
  PhoneIcon,
  EmailIcon,
  MobileLogoEnglish,
  MobileLogoFrench,
  DesktopLogoTest,
} from 'assets/svg/Footer/FooterIcons';
import { useSelector } from 'react-redux';
import { menuIsOpenedSelector } from 'ducks/application/selectors';
import Routes from 'routes';
import { selectParentCategories } from 'ducks/category/selectors';

import { useTranslation } from 'react-i18next';
import { selectBranch } from 'ducks/branch/selectors';

import { branchCode } from 'themeExtended';
import { FaxIcon, TollFreeIcon } from 'assets/svg/ContactUs/ContactIcons';
import { formatPhoneNumber } from 'utils/common/common';
import {
  FooterWrapper,
  LogoGrid,
  LocationTypography,
  LocationDiv,
  EmailTypography,
  EmailDiv,
  StyledEmailDiv,
  SecondGrid,
  StyledTypography,
  StyledLocationDiv,
  StyledLink,
  StyledHyperLink,
  FirstGrid,
  ThirdGrid,
  ForthGrid,
  PhonesDiv,
  PhoneWrapperDiv,
  CopyrightGrid,
  HeaderTypography,
  BodyTypography,
  MenuTypography,
  MenuGrid,
} from './styles/MobileFooterStyles';
import { StyledProductGrid } from './styles/FooterStyles';
import { GlobalBannerNotification } from '../Notification/GlobalBannerNotification';

const ScrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const MobileFooter: React.FC = () => {
  const isOpened = useSelector(menuIsOpenedSelector);
  const categories = useSelector(selectParentCategories);

  const categoriesResult = useMemo(() => {
    return categories.map(category => (
      <StyledLink key={category.code} to={`${Routes.CATEGORIES}/${category.code}`}>
        <BodyTypography>{category.name}</BodyTypography>
      </StyledLink>
    ));
  }, [categories]);

  const branch = useSelector(selectBranch);

  const phonesList = useMemo(() => {
    if (branch && branch?.phones?.length) {
      return (
        <PhonesDiv>
          <PhoneWrapperDiv>
            {branch?.phones?.map(phone => {
              let icon = <></>;
              switch (phone.type) {
                case 'Tel.':
                  icon = <PhoneIcon />;
                  break;
                case 'Fax':
                  icon = <FaxIcon />;
                  break;
                case 'Toll Free':
                  icon = <TollFreeIcon />;
                  break;
                default:
                  return <></>;
              }

              return (
                <div className="item">
                  {icon} <span className="txt">{`${formatPhoneNumber(phone.number)} ${phone.type}`}</span>
                </div>
              );
            })}
          </PhoneWrapperDiv>
        </PhonesDiv>
      );
    }

    return null;
  }, [branch]);

  const address = useMemo(() => {
    if (branch) {
      return (
        <LocationDiv>
          <StyledLocationDiv>
            <LocationIcon />
          </StyledLocationDiv>
          <LocationTypography>{branch.address}</LocationTypography>
        </LocationDiv>
      );
    }
    return null;
  }, [branch]);

  const emails = useMemo(() => {
    let email = '';
    if (branch) {
      if (branch?.email !== '') {
        email = branch?.email;
      } else {
        email = 'taiganow@taigabuilding.com';
      }

      if (typeof email === 'string' && email.indexOf(',') > -1) {
        return (
          <EmailDiv>
            {email.split(',').map((em, idx) => (
              <div key={`${Math.random()}`} style={{ display: 'flex' }}>
                <StyledEmailDiv style={{ opacity: idx !== 0 ? 0 : null }}>
                  <EmailIcon />
                </StyledEmailDiv>
                <EmailTypography as="a" href={`mailto:${em}`} style={{ textDecoration: 'none' }}>
                  {em}
                </EmailTypography>
              </div>
            ))}
          </EmailDiv>
        );
      }

      return (
        <EmailDiv style={{ display: 'flex' }}>
          <StyledEmailDiv>
            <EmailIcon />
          </StyledEmailDiv>
          <EmailTypography
            as="a"
            href={`mailto:${email}`}
            style={{ textDecoration: 'none' }}
          >
            {email}
          </EmailTypography>
        </EmailDiv>
      );
    }
    return null;
  }, [branch]);

  const { t, i18n } = useTranslation();
  const MobileLogo = () => (i18n.language === 'en' ? <MobileLogoEnglish /> : <MobileLogoFrench />);

  return !isOpened ? (
    <>
      <GlobalBannerNotification />
      <FooterWrapper container direction="column">
        <LogoGrid container item>
          {branch?.code === branchCode ? <DesktopLogoTest /> : <MobileLogo />}
        </LogoGrid>
        <MenuGrid container item direction="column">
          <FirstGrid container item direction="column" xs={12}>
            <MenuTypography>{t('footer.taiga_products')}</MenuTypography>
            {address}
            {phonesList}
            {emails}
          </FirstGrid>
          <SecondGrid container item direction="column" xs={12}>
            <HeaderTypography>{t('footer.use_and_help')}</HeaderTypography>
            <StyledLink to={Routes.FAQ} onClick={ScrollTop}>
              <BodyTypography>{t('footer.faq')}</BodyTypography>
            </StyledLink>
            <StyledLink to={Routes.COLOUR_DISCLAIMER} onClick={ScrollTop}>
              <BodyTypography>{t('footer.color_disclaimer')}</BodyTypography>
            </StyledLink>
            <StyledLink to={Routes.PRIVACY_POLICY} onClick={ScrollTop}>
              <BodyTypography>{t('footer.policy')}</BodyTypography>
            </StyledLink>
            <StyledLink to={Routes.TERMS_OF_SERVICE} onClick={ScrollTop}>
              <BodyTypography>{t('footer.terms')}</BodyTypography>
            </StyledLink>
          </SecondGrid>
          <ThirdGrid container item direction="column" xs={12}>
            <Grid container item>
              <HeaderTypography>{t('footer.products')}</HeaderTypography>
            </Grid>
            <StyledProductGrid container item justify="space-between">
              {categoriesResult}
            </StyledProductGrid>
          </ThirdGrid>
          <ForthGrid container item direction="column" xs={12}>
            <HeaderTypography>{t('footer.company_info')}</HeaderTypography>
            <StyledLink to={Routes.CONTACT_US} onClick={ScrollTop}>
              <BodyTypography>{t('footer.feedback')}</BodyTypography>
            </StyledLink>
            <StyledLink to={Routes.CONTACT_US} onClick={ScrollTop}>
              <BodyTypography>{t('footer.contact_us')}</BodyTypography>
            </StyledLink>
            <StyledHyperLink href="https://www.taigabuilding.com" target="_blank">
              <BodyTypography>{t('footer.about_us')}</BodyTypography>
            </StyledHyperLink>
          </ForthGrid>
        </MenuGrid>
        <CopyrightGrid container item alignItems="center" justify="center">
          <StyledTypography>{t('footer.copyright', {year: new Date().getFullYear()})}</StyledTypography>
        </CopyrightGrid>
      </FooterWrapper>
    </>
  ) : null;
};

export default MobileFooter;
