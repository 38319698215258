import { all, call, put, takeEvery } from 'redux-saga/effects';
import request from '../../utils/publicRequest';
import { fetchSiteLocalesError, storeSiteLocales } from './actions';
import { SiteLocaleActionsTypes } from './types';

function* requestSiteLocales() {
  try {
    const { data } = yield call(request.get, '/api/site-locales');
    yield put(storeSiteLocales(data));
  } catch (err) {
    yield put(fetchSiteLocalesError())
  }
}

function* watchRequestSiteLocales() {
  yield takeEvery(
    SiteLocaleActionsTypes.REQUEST_SITE_LOCALES,
    requestSiteLocales
  )
}

export default function* localeSaga() {
  yield all([watchRequestSiteLocales()])
}
