import styled from 'styled-components/macro';

interface IRadioBlock {
  isActive: boolean;
  disabled: boolean;
}

const RadioBlock = styled.div`
  cursor: ${(p: IRadioBlock) => p.disabled ? 'no-drop': 'pointer'};
  position: relative;
  border: ${(p: IRadioBlock) => (p.isActive && !p.disabled ? '2px solid #00853e' : '1px solid #EAEAEA')};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 14px 48px;
  width: 100%;
  min-width: 160px;
  pointer-events: ${(p: IRadioBlock) => (p.disabled) ? 'none' : ''};

  @media (max-width: 1023px) {
    margin-bottom: 10px;
  }
`;

const RBLabel = styled.div`
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 177.778% */
  letter-spacing: 0.1px;
`;

interface ICustomRadio {
  isActive: boolean;
  inside: string;
  disabled: boolean;
}

const CustomRadioCoords = {
  shipping: {
    top: '24px',
    left: '20px',
  },
  address: {
    top: '14px',
    left: '229px',
  },
};

const CustomRadio = styled.div`
  position: absolute;
  top: 50%;
  left: ${(p: ICustomRadio) => CustomRadioCoords[p.inside].left};
  transform: translate(0, -50%);
  border-radius: 16px;
  border: ${(p: ICustomRadio) => (p.isActive && !p.disabled ? '4px solid #00853e' : '2px solid #BFBFBF')};
  box-sizing: border-box;
  width: 16px;
  height: 16px;
`;

export {CustomRadio, RBLabel, RadioBlock};
