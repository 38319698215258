import {all, call, debounce, delay, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import axios from 'utils/axios';

// Actions
import {
  storeAssociationProducts,
  storeFeaturedProducts,
  storeFilteredProducts,
  storeFilteredProductsFirstLoad,
  storeFilterProductError,
  storeFilters,
  storeProductIdsList,
  storeVariantAssociations,
} from 'ducks/productVariant/actions';

// Types
import {
  FetchFeaturedProductAction,
  FetchFilteredProductsAction,
  IRequestAssociationProductAction, IRequestSendNearbyBranchEmailAction,
  ProductActionTypes,
  RequestPricingAction,
  RequestProductIdsByCategory,
  RequestVariantAssociations,
} from 'ducks/productVariant/types';

// Classes
import {ProductVariantClass} from 'models/classes/productVariantClass';

import {productToProductVariantClass} from 'utils/products/productVariant';
import {setServerError} from 'ducks/application/actions';
import {priceTypeSelector, selectAddressCode, selectAddressId} from 'ducks/application/selectors';
import {getError, storeError} from 'utils/errors';

import {mapAssociatedProducts} from 'utils/products/productUtilsHelpers';
import {FacetClass} from 'models/classes/facetClass';
import {IRangeFilter} from 'models/interfaces/productVariant';
import get from 'lodash/get';
import {selectBranch} from '../branch/selectors';
import {AppStorage} from '../../components/App/services/storage';

function* requestFeaturedProducts(fetchFeaturedProductsAction: FetchFeaturedProductAction) {
  try {
    const branch = yield select(selectBranch);
    const address = yield select(selectAddressId);
    const {data: response} = yield call(axios.get, '/api/auth/product/featured-product', {
      params: {...fetchFeaturedProductsAction.payload, branchId: branch.id},
      headers: {
        accept: 'application/json',
        'X-Address-Id': address,
      },
    });

    const products: ProductVariantClass[] = response.data.map(product =>
      productToProductVariantClass(product),
    );

    yield put(storeFeaturedProducts(products));
  } catch (error) {
    yield put(setServerError(getError(error.response, [])));
  }
}

function* requestFilteredProducts(fetchFilteredProductsAction: FetchFilteredProductsAction) {
  try {
    const payload = {...fetchFilteredProductsAction.payload};
    AppStorage.setFiltered(JSON.stringify(payload))
    const {firstLoad} = payload;
    const filter = {...fetchFilteredProductsAction.payload.filterAttributes};
    delete payload.filterAttributes;
    const addressId = yield select(selectAddressId);
    const deliveryType = yield select(priceTypeSelector);

    if (firstLoad) {
      delete payload.firstLoad;
    }
    const {data: response} = yield call(axios.get, '/api/auth/product/get-list', {
      params: {
        ...payload,
        ...filter,
        deliveryType,
      },
      headers: {
        accept: 'application/json',
        'X-Address-Id': addressId,
      },
    });

    const products: ProductVariantClass[] = response.data.data.map(product => productToProductVariantClass(product));
    yield put(
      firstLoad
        ? storeFilteredProductsFirstLoad(products, response.data.currentPage === response.data.lastPage, response.data.total)
        : storeFilteredProducts(products, response.data.currentPage === response.data.lastPage, response.data.total),
    );
  } catch (error) {
    yield put(storeFilterProductError(parseInt(get(error, 'response.data.code', 400), 10)));
  }
}

function* requestFilters(fetchFilteredProductsAction: FetchFilteredProductsAction) {
  try {
    const payload = {...fetchFilteredProductsAction.payload};

    const filter = {...fetchFilteredProductsAction.payload.filterAttributes};
    delete payload.filterAttributes;
    const addressId = yield select(selectAddressId);

    const {data: filtersResponse} = yield call(axios.get, '/api/auth/product/filters', {
      params: {
        ...payload,
        ...filter,
      },
      headers: {
        accept: 'application/json',
        'X-Address-Id': addressId,
      },
    });

    const facets: FacetClass[] = filtersResponse.data.facets.map(facet => new FacetClass(facet));
    facets.sort((a, b) => Number(a.position) - Number(b.position));

    const rangeFilters: IRangeFilter[] = filtersResponse.data.rangeFilters.sort(
      (a, b) => Number(a.position) - Number(b.position),
    );

    yield put(storeFilters(facets, rangeFilters));
  } catch (error) {
    yield put(setServerError(getError(error.response, [])));
  }
}

function* requestPricing(requestPricingAction: RequestPricingAction) {
  const {
    message,
    quantity,
    addressId,
    productCode,
    productSku,
    setEmailSuccess,
    setEmailLoading,
  } = requestPricingAction.payload;
  setEmailLoading(true);
  try {
    yield call(
      axios.post,
      '/api/auth/send-request-for-pricing',
      {
        message,
        quantity,
        addressId,
        product_code: productCode,
        product_sku: productSku,
      },
      {
        headers: {
          accept: 'application/json',
        },
      },
    );

    yield put({type: ProductActionTypes.REQUEST_PRICING_SUCCESS});
    setEmailLoading(false);
    setEmailSuccess(true);
  } catch (error) {
    setEmailLoading(false);
    yield storeError(ProductActionTypes.REQUEST_PRICING_ERROR, [422], error.response);
  }
}

function* requestVariantAssociation(requestVariantAssociations: RequestVariantAssociations) {
  try {
    const addressCode = yield select(selectAddressCode);
    const {variantCode} = requestVariantAssociations.payload;

    const {data: response} = yield call(
      axios.get,
      `/api/product-variants/${variantCode}/associations`,
      {
        headers: {
          accept: 'application/ld+json',
          'X-Address-Code': addressCode,
        },
      },
    );

    const {
      similarProductsToStore,
      alsoLikeProductsToStore,
      accessoriesToStore,
    } = mapAssociatedProducts(response['hydra:member'] || []);

    yield put(
      storeVariantAssociations(similarProductsToStore, alsoLikeProductsToStore, accessoriesToStore),
    );
  } catch (error) {
    yield storeError(ProductActionTypes.REQUEST_VARIANT_ASSOCIATIONS_ERROR, [404], error.response);
  }
}

function* requestProductIdsByCategory(IRequestProductIdsByCategory: RequestProductIdsByCategory) {
  const {category, filters} = IRequestProductIdsByCategory.payload;
  const addressId = yield select(selectAddressId);
  const deliveryType = yield select(priceTypeSelector);
  if (filters.filterAttributes) {
    delete filters.filterAttributes
  }
  try {
    const {data: response} = yield call(
      axios.get,
      `/api/auth/product/dump-product-ids/${category}/${deliveryType}`,
      {
        params: {
          ...filters,
          deliveryType,
        },
        headers: {
          accept: 'application/json',
          contentType: 'application/ld+json',
          'x-address-id': addressId,
        },
      },
    );
    yield put(storeProductIdsList(response.data));
  } catch (err) {
    delay(1000);
    for (let i = 0; i < 3; i += 1) {
      if (i < 2) {
        const {data: response} = yield call(
          axios.get,
          `/api/auth/product/dump-product-ids/${category}/${deliveryType}`,
          {
            params: {
              ...filters,
            },
            headers: {
              accept: 'application/json',
              contentType: 'application/ld+json',
              'x-address-id': addressId,
            },
          },
        );
        yield put(storeProductIdsList(response.data));
      }
    }
  }
}

function* requestAssociationProduct(requestAssociationProductAction: IRequestAssociationProductAction) {
  try {
    const {productId} = requestAssociationProductAction.payload;
    const addressId = yield select(selectAddressId);
    const {data: response} = yield call(axios.get, `/api/auth/product/association/${productId}`, {
      headers: {
        'x-address-id': addressId,
      },
    });
    const relatedProducts: ProductVariantClass[] = response.data.relatedProducts.map(product =>
      productToProductVariantClass(product),
    );
    const alterNativeProducts: ProductVariantClass[] = response.data.alterNativeProducts.map(product =>
      productToProductVariantClass(product),
    );
    yield put(storeAssociationProducts(relatedProducts, alterNativeProducts));
    // eslint-disable-next-line no-empty
  } catch (err) {
  }
}

function* sendNearbyBranchEmail(request: IRequestSendNearbyBranchEmailAction) {
  try {
    const {data: response} = yield call(axios.post, 'api/auth/nearby-branch/request-email', request.payload, {
      headers: {
        'x-address-id': request.payload.addressId.toString(),
        'x-category-id': request.payload.categoryId.toString()
      }
    });
    if (!response.error) {
      yield put({type: ProductActionTypes.REQUEST_SEND_NEARBY_BRANCH_EMAIL_SUCCESS});
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('===', err)
  }
}

function* watchSendNearbyBranchEmail() {
  yield takeLatest(ProductActionTypes.REQUEST_SEND_NEARBY_BRANCH_EMAIL, sendNearbyBranchEmail);
}

function* watchRequestAssociationProduct() {
  yield takeEvery(ProductActionTypes.REQUEST_PRODUCT_ASSOCIATION, requestAssociationProduct);
}

function* watchRequestProductIdsByCategory() {
  yield debounce(1000, ProductActionTypes.REQUEST_GET_PRODUCT_IDS, requestProductIdsByCategory);
}

function* watchRequestFeaturedProducts() {
  yield takeEvery(ProductActionTypes.REQUEST_FEATURED_PRODUCT, requestFeaturedProducts);
}

function* watchRequestFilteredProducts() {
  yield debounce(1000, ProductActionTypes.REQUEST_FILTERED_PRODUCTS, requestFilteredProducts);
}

function* watchRequestFilters() {
  yield takeEvery(ProductActionTypes.REQUEST_FILTERS, requestFilters);
}

function* watchRequestPricing() {
  yield takeEvery(ProductActionTypes.REQUEST_PRICING, requestPricing);
}

function* watchRequestVariantAssociations() {
  yield takeEvery(ProductActionTypes.REQUEST_VARIANT_ASSOCIATIONS, requestVariantAssociation);
}

export default function* productVariantSaga() {
  yield all([
    watchRequestFeaturedProducts(),
    watchRequestFilteredProducts(),
    watchRequestPricing(),
    watchRequestVariantAssociations(),
    watchRequestFilters(),
    watchRequestProductIdsByCategory(),
    watchRequestAssociationProduct(),
    watchSendNearbyBranchEmail(),
  ]);
}
