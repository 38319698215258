import React, {useCallback, useRef, useState} from 'react';
import { PopperPlacementType } from '@material-ui/core';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';

import { ListViewMinus, ListViewPlus } from 'assets/svg/Product/Products';
import ProductInputPopper from 'components/Popper/ProductInputPopper';
import { requestChangeProductQty, updateItemInputValues } from 'ducks/cart/actions';
import { useTranslation } from 'react-i18next';

import {filterPrices, getPriceByDeliveryType} from 'utils/products/productVariant';
import TagManager from 'react-gtm-module';

import {
  priceTypeSelector,
  selectAddressId,
  selectErpCustomerAddressCode, selectIsHidePrice,
} from 'ducks/application/selectors';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import {useGA4CartEvent} from 'GA/CartGa4/cartGA4';
import {
  InputButtonsWrapper,
  InputWithLabelWrapper,
  StyledInputWrapper,
  IncrementButton,
  IncrementButtonLabel,
  StyledTextField,
  // PromotionText,
  Price
} from './styles';

interface IProductInputs {
  cartItem?: any;
  bottomInputValue?: number;
  setBottomInputValue: React.Dispatch<React.SetStateAction<number>>;
  isSecondStep?: boolean;
  popperPlacement: PopperPlacementType;
  onHasErrors?(hasError: boolean): void;
}

const CartProductInputs: React.FC<IProductInputs> = ({
  bottomInputValue,
  setBottomInputValue,
  cartItem,
  isSecondStep,
  popperPlacement,
  onHasErrors,
}: IProductInputs) => {
  const dispatch = useDispatch();
  const {handleFireGA4RemoveFromCartSubTract, handleFireGA4AddToCartPlus} = useGA4CartEvent();
  const timeClickGtm = useRef(0);
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const deliveryType = useSelector(priceTypeSelector);
  const isHidePrice = useSelector(selectIsHidePrice);
  const [messageTop, setMessageTop] = useState('');
  const [messageBottom, setMessageBottom] = useState('');
  const { t } = useTranslation();
  const productPrices = filterPrices(cartItem?.variant?.addressPrices);
  const [quantity, setQuantity] = useState(cartItem.quantity);
  const topRef = useRef();
  const buttonTopRef = useRef();
  const currentInputRef = useRef(cartItem.quantity)

  const uomConversion = Number(cartItem.variant.units.uom2Conversion);
  const cartItemCoeff = Number((productPrices[0].qty / uomConversion).toFixed(2));

  const handleIfIncorrectValues = useCallback(
    (topValue: number, bottomValue: number | null) => {
      let messageTopTemp: string;
      let messageBottomTemp: string;

      const minQuantity = `${productPrices[0].qty}`;
      const minimalQuantityValue = `${productPrices[0].qty} ${cartItem.variant.units.perName}`;
      const unitsPerName = `${cartItem.variant.units.perName}`;
      const orderOnly = `${cartItem.variant.units.perName} (1 ${
        cartItem.variant.units.perName
      } = ${Number((productPrices[0].qty / cartItem.variant.units.uom2Conversion).toFixed(2))} ${
        cartItem.variant.units.uom2Name
      }) `;

      // min order uom quantity
      if (productPrices[0].qty > topValue) {
        // top uom value
        messageTopTemp = t('price_calculator.min_quantity', { minimalQuantityValue });
        setMessageTop(messageTopTemp);
      } else {
        messageTopTemp = '';
        setMessageTop('');
      }

      if (!messageTopTemp && topValue % productPrices[0].qty !== 0) {
        messageTopTemp = t('price_calculator.divisible', { minQuantity });
        setMessageTop(messageTopTemp);
      } else if (!messageTopTemp) {
        messageTopTemp = '';
        setMessageTop('');
      }

      if (bottomValue) {
        const remaining = Number((Number(bottomValue.toFixed(2)) % cartItemCoeff).toFixed(2));
        // min order uom2 quantity
        if (remaining !== 0 && remaining !== cartItemCoeff) {
          // bottom uom value
          messageBottomTemp = t('price_calculator.packs_provide', { unitsPerName, orderOnly });
          setMessageBottom(messageBottomTemp);
        } else {
          messageBottomTemp = '';
          setMessageBottom('');
        }
      } else {
        messageBottomTemp = '';
        setMessageBottom('');
      }

      if (onHasErrors) {
        onHasErrors(!(messageTopTemp === '' && messageBottomTemp === ''));
      }

      return messageTopTemp === '' && messageBottomTemp === '';
    },
    [
      cartItem.variant.units.perName,
      cartItem.variant.units.uom2Conversion,
      cartItem.variant.units.uom2Name,
      cartItemCoeff,
      onHasErrors,
      productPrices,
      t,
    ]
  );

  const handleGtmEventChangeUOM = () => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'checkout_change_UOM',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
    timeClickGtm.current = Date.now() / 1000;
  };

  const delayedUpdateCartItemQty = useCallback(
    debounce((newQty, newBottomValue) => {
      if (handleIfIncorrectValues(newQty, newBottomValue)) {
        dispatch(
          requestChangeProductQty({
            cartItemId: cartItem.id,
            qty: newQty,
          })
        );
        handleGtmEventChangeUOM();
        if (newQty > currentInputRef.current) {
          if (newQty - currentInputRef.current > 0) {
            handleFireGA4AddToCartPlus(cartItem, newQty - currentInputRef.current);
          }
        } else if (currentInputRef.current - newQty > 0) {
          handleFireGA4RemoveFromCartSubTract(cartItem, currentInputRef.current - newQty);
        }
        currentInputRef.current = newQty;
      }
    }, 1000),
    [cartItem]
  );

  const handleIncrement = () => {
    const newTopValue =
      quantity - (quantity % productPrices[0].qty) + productPrices[0].qty;
    let newBottomValue: number | null;
    setQuantity(newTopValue);

    if (bottomInputValue || bottomInputValue === 0) {
      newBottomValue = parseFloat(
        ((newTopValue * cartItemCoeff) / productPrices[0].qty).toFixed(2)
      );
      setBottomInputValue(newBottomValue);
    }

    delayedUpdateCartItemQty(newTopValue, newBottomValue || null);
  };

  const handleDecrement = () => {
    const decrementCoeff = quantity % productPrices[0].qty;
    let newTopValue = decrementCoeff
      ? Number((quantity - Number(decrementCoeff.toFixed(2))).toFixed(2))
      : quantity - decrementCoeff - productPrices[0].qty;

    newTopValue = newTopValue <= 0 ? productPrices[0].qty : newTopValue;

    let newBottomValue: number | null;

    setQuantity(newTopValue !== 0 ? newTopValue : 1); /* TODO qty not 1 */

    if (bottomInputValue) {
      newBottomValue = parseFloat(
        ((newTopValue * cartItemCoeff) / productPrices[0].qty).toFixed(2)
      );

      setBottomInputValue(newBottomValue !== 0 ? newBottomValue : cartItemCoeff);
    }
    delayedUpdateCartItemQty(newTopValue, newBottomValue || null);
  };

  const handleTopFieldChange = e => {
    let newTopValue1: number;
    let newBottomValue1: number;

    if (e.target.value === '') {
      newTopValue1 = 0;
    } else {
      newTopValue1 = parseInt(e.target.value, 10);
    }

    setQuantity(newTopValue1);

    if (bottomInputValue !== null) {
      newBottomValue1 = parseFloat(
        ((newTopValue1 * cartItemCoeff) / productPrices[0].qty).toFixed(2)
      );
      setBottomInputValue(newBottomValue1);
    }

    if (newTopValue1 >= 0) {
      dispatch(
        updateItemInputValues({
          cartItemId: cartItem.id,
          newTopValue: newTopValue1,
          newBottomValue: newBottomValue1 || 0,
        })
      );
      delayedUpdateCartItemQty(newTopValue1, newBottomValue1 || null);
    }

    if (timeClickGtm.current === 0) {
      handleGtmEventChangeUOM();
    } else if (timeClickGtm.current > 0 && Date.now() / 1000 - timeClickGtm.current > 10) {
      handleGtmEventChangeUOM();
    }
  };

  return cartItem ? (
    <>
      <InputButtonsWrapper>
        <InputWithLabelWrapper>
          {
            !isHidePrice && <Price>${getPriceByDeliveryType(deliveryType, cartItem).toFixed(2)} <span className="sign">x</span></Price>
          }
          <StyledInputWrapper>
            {messageTop && popperPlacement === 'left' ? (
              <ProductInputPopper
                element={buttonTopRef.current}
                message={messageTop}
                placement={popperPlacement}
              />
            ) : null}
            <IncrementButton
              ref={buttonTopRef}
              disabled={quantity === 0 || isSecondStep}
              onClick={handleDecrement}
            >
              <ListViewMinus />
            </IncrementButton>
            <StyledTextField
              type="tel"
              size="small"
              InputProps={{ disableUnderline: true }}
              onChange={e => handleTopFieldChange(e)}
              value={quantity}
              disabled
            />
            <IncrementButton disabled={isSecondStep} onClick={handleIncrement}>
              <ListViewPlus />
            </IncrementButton>
          </StyledInputWrapper>

          <IncrementButtonLabel ref={topRef}>{cartItem.variant.units.perName}</IncrementButtonLabel>
          {messageTop && popperPlacement === 'right' ? (
            <ProductInputPopper
              element={topRef.current}
              message={messageTop}
              placement={messageTop && messageBottom ? 'top-end' : popperPlacement}
            />
          ) : null}
        </InputWithLabelWrapper>
      </InputButtonsWrapper>
    </>
  ) : null;
};

export default CartProductInputs;
