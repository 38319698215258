import 'App.scss'
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import SnackBar from 'components/common/Snackbar/Test/Snackbar';

import {
  CategoryList,
  Checkout,
  ColourDisclaimer,
  ContactUs,
  Error500,
  FAQ,
  LandingPage,
  Login,
  Maintenance,
  NotFound,
  ProductDetail,
  ProductList,
  Profile,
  ResetPassword,
  Search,
  SignIn,
  SubcategoryList,
} from 'pages';

import Routes from 'routes';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { AuthComponent, AuthRoutes, ErrorRoutes, LandingRoutes, StoreRoutes } from 'components';
import PrivacyPolicy from 'pages/Technical/PrivacyPolicy';
import { useApp } from 'hooks/useApp';
import TermsOfService from 'pages/Technical/TermsOfService/TermsOfService';
import { useDispatch, useSelector } from 'react-redux';
import ScrollToTop from 'components/common/ScrollToTop';
import { selectBranch } from 'ducks/branch/selectors';
import { branchCode, themeExtended } from 'themeExtended';
import AppCssBaseline from 'components/App/CssBaseline';
import { authenticatedSelector } from 'ducks/auth/selectors';
import UnsubscribeOrder from 'pages/UnsubscribeOrder/UnsubscibeOrder';
import theme from './theme';
import { useLanguage } from './hooks/useLanguage';
import Error400 from './pages/Technical/Error400';
import { KLAVIYO_KEY, REACT_APP_ENV } from './assets/constants/application';
import { PERMISSION_VIEW_PRICING } from './assets/constants/permission';
import { setPriceType } from './ducks/application/actions';
import { changeField } from './ducks/checkout/actions';
import Specials from './pages/Specials';
import QuickOrder from './pages/QuickOrder';
import DocumentGroup from './pages/DocumentGroup';

const App: React.FC = () => {
  const { user } = useApp();
  const branch = useSelector(selectBranch);
  const isAuthenticated = useSelector(authenticatedSelector);
  const [defaultLang, setDefaultLang] = React.useState('en');
  const currentLang = useLanguage();
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('storage', (e) => {
      if (e.key === 'accessToken' && e.newValue !== e.oldValue) {
        window.location.reload();
      }
    });
    setDefaultLang(currentLang);
  }, [currentLang]);

  if (user && user.addresses.length === 0) {
    window.location.href = `/${defaultLang}${Routes.ADDRESS_MISSING}`;
  }

  if (user && REACT_APP_ENV === 'production') {
    // @ts-ignore
    window.pendo.initialize({
      visitor: {
        id: user.id,
        email: user.email,
        full_name: `${user.firstName} ${user.lastName}`
      },

      account: {
        id: REACT_APP_ENV
      }
    });
  }

  if (user && !user.roles.includes(PERMISSION_VIEW_PRICING)) {
    dispatch(changeField('shippingMethod', 'hide'));
    dispatch(setPriceType('hide'));
  }

  const customTheme = branch?.code === branchCode ? themeExtended : theme;
  return (
    <ThemeProvider theme={customTheme}>
      <Helmet>
        <script type="text/javascript" async
          src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${KLAVIYO_KEY}`} />;
      </Helmet>

      <SnackBar />
      <BrowserRouter basename={`${defaultLang}`} key={defaultLang}>
        <AppCssBaseline />
        <ScrollToTop />
        <Switch>
          <ErrorRoutes exact path={Routes.MAINTENANCE} component={Maintenance} />
          <ErrorRoutes exact path={Routes.ERROR_500} component={Error500} />
          <ErrorRoutes exact path={Routes.NOT_FOUND} component={NotFound} />
          <ErrorRoutes exact path={Routes.ADDRESS_MISSING} component={Error400} />
          <StoreRoutes exact path={Routes.PROFILE} component={Profile} />
          <StoreRoutes exact path={Routes.CATEGORIES} component={CategoryList} withContainer={false} />
          <StoreRoutes
            exact
            path={Routes.SUBCATEGORIES}
            component={SubcategoryList}
            withContainer={false}
          />
          <StoreRoutes exact path={Routes.NOT_FOUND} component={NotFound} />
          <StoreRoutes
            exact
            path={Routes.SUBCATEGORY_PRODUCTS}
            component={ProductList}
            withContainer={false}
          />
          <StoreRoutes exact path={Routes.PRODUCT_DETAIL} component={ProductDetail} />
          <StoreRoutes exact path={Routes.CONTACT_US} component={ContactUs} />
          <StoreRoutes exact path={Routes.FAQ} component={FAQ} />
          <StoreRoutes exact path={Routes.SEARCH} component={Search} />
          <StoreRoutes exact path={Routes.COLOUR_DISCLAIMER} component={ColourDisclaimer} />
          <StoreRoutes exact path={Routes.CHECKOUT} component={Checkout} />
          <StoreRoutes exact path={Routes.SPECIAL} component={Specials} />
          <StoreRoutes exact path={Routes.QUICK_ORDER} component={QuickOrder} />
          <StoreRoutes exact path={Routes.DOCUMENT_GROUP} component={DocumentGroup} />
          {isAuthenticated ? (
            <StoreRoutes exact path={Routes.PRIVACY_POLICY} component={PrivacyPolicy} />
          ) : (
            <LandingRoutes
              exact
              path={Routes.PRIVACY_POLICY}
              component={() => <LandingPage isPrivacy />}
            />
          )}

          {isAuthenticated ? (
            <StoreRoutes exact path={Routes.TERMS_OF_SERVICE} component={TermsOfService} />
          ) : (
            <LandingRoutes
              exact
              path={Routes.TERMS_OF_SERVICE}
              component={() => <LandingPage isTos />}
            />
          )}

          {/* <StoreRoutes exact path={Routes.TERMS_OF_SERVICE} component={TermsOfService} /> */}
          <AuthRoutes exact path={Routes.LOGIN} component={Login} />
          <AuthRoutes exact path={Routes.SIGN_IN} component={SignIn} />
          <AuthRoutes exact path={Routes.RESET_PASSWORD} component={ResetPassword} />
          <LandingRoutes exact path={Routes.HOME} component={LandingPage} />
          <Route
            render={() => (
              <AuthComponent>
                <UnsubscribeOrder  />
              </AuthComponent>
            )}
          />

          {/* NOT FOUND ROUTE MUST BE THE LAST ONE */}
          <ErrorRoutes component={NotFound} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
