import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import RangeFilter from 'components/Product/ProductFilter/components/RangeFilter/RangeFilter';
import FacetRangeFilter from 'components/Product/ProductFilter/components/FacetRangeFilter/FacetRangeFilter';
import {
  ActionButtonsWrapper,
  FilterWrapper,
  FilterInner,
  SingleClearButtonWrapper,
  StyledClearButton,
} from 'components/Product/ProductFilter/styles';
import {
  IMultiselectFilterValues,
  IRangeFilter,
  ISelectedValue,
} from 'models/interfaces/productVariant';
import { FacetClass } from 'models/classes/facetClass';
import { useTranslation } from 'react-i18next';
import ProductListLoading from 'pages/Product/ProductListLoading/ProductListLoading';
import { selectSearchFacets, selectSearchLoading } from 'ducks/search/selectors';
import SearchPageCheckboxFilter from '../SearchPageCheckboxFilter/SearchPageCheckboxFilter';

interface IDesktopSearchAllFilterWrapper {
  selectedValues: ISelectedValue[];
  productsAmount: number;
  clearFilter: () => void;
  rangeFilters: IRangeFilter[];
  handleChange: (facetCode: string, bucketLabel: string, bucketKey: string) => void;
  findNecessaryCheck: (facetProperty: string, bucketLabel: string) => boolean;
  handleRangeFilterChange: (code: string, name: string, values: number[]) => void;
  handleMultiselectFilterChange: (
    code: string,
    name: string,
    values: IMultiselectFilterValues
  ) => void;
  multiselectFacets: FacetClass[];
}

const DesktopSearchAllFilterWrapper = ({
  selectedValues,
  productsAmount,
  clearFilter,
  rangeFilters,
  handleChange,
  findNecessaryCheck,
  handleRangeFilterChange,
  handleMultiselectFilterChange,
  multiselectFacets,
}: IDesktopSearchAllFilterWrapper) => {
  const { t } = useTranslation();
  const loading = useSelector(selectSearchLoading);
  const facets = useSelector(selectSearchFacets);

  const [sortedFilters, setSortedFilters] = React.useState([]);

  const renderIfNoProducts = () => {
    if (!productsAmount && !loading) {
      return (
        <SingleClearButtonWrapper>
          <StyledClearButton onClick={clearFilter}>
            <p>{t('products_and_search.clear_all').toString()}</p>
          </StyledClearButton>
        </SingleClearButtonWrapper>
      );
    }

    return null;
  };


  const filters = useMemo(() => [...facets, ...multiselectFacets, ...rangeFilters], [facets, multiselectFacets, rangeFilters]);

  useEffect(() => {
    setSortedFilters([...filters.sort((a, b) => Number(a.position) - Number(b.position))]);
  }, [JSON.stringify(filters)])

  const filterItem = item => {
    if (item.configuration?.is_range) {
      return (
        <RangeFilter
          key={item.key}
          rangeFilter={item}
          selectedValues={selectedValues}
          handleFilterChange={handleRangeFilterChange}
        />
      );
    }

    if (item.configuration?.isRange) {
      return (
        <FacetRangeFilter
          key={item.property}
          facet={item}
          selectedValues={selectedValues}
          handleMultiselectFilterChange={handleMultiselectFilterChange}
        />
      );
    }

    return (
      <SearchPageCheckboxFilter
        key={item.property}
        facet={item}
        handleChange={handleChange}
        findNecessaryCheck={findNecessaryCheck}
        selectedValues={selectedValues}
      />
    );
  };

  const renderFilter = () => {
    if (loading) {
      return <ProductListLoading />;
    }
    return sortedFilters.length > 0 ? (
      <>
        <div>{sortedFilters.map(item => filterItem(item))}</div>
        <ActionButtonsWrapper>
          {selectedValues.length > 0 ? (
            <StyledClearButton onClick={clearFilter}>
              <p>{t('products_and_search.clear_all').toString()}</p>
            </StyledClearButton>
          ) : null}
        </ActionButtonsWrapper>
      </>
    ) : (
      renderIfNoProducts()
    );
  }

  return (
    <FilterWrapper>
      <FilterInner>
        {renderFilter()}
      </FilterInner>
    </FilterWrapper>
  );
};

export default DesktopSearchAllFilterWrapper;
