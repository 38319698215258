/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BreadCrumbs } from 'components/common';
import { TruckIcon } from 'assets/svg/MiddleMenu/MiddleMenu';
import { useDispatch, useSelector } from 'react-redux';
import { selectDisableQuickOrderCheckout, selectQuickOrderItems } from 'ducks/quickOrder/selectors';
import {
  bulkAddToCart,
  clearQuickOrder,
  setDisableQuickOrderCheckout,
  useGetQuickOrder,
} from 'ducks/quickOrder/actions';
import { numberFormat } from 'utils/products/product';
import { IQuickOrderItem } from 'ducks/quickOrder/types';
import {
  priceTypeSelector,
  selectAddressId,
  selectErpCustomerAddressCode, selectIsHidePrice,
} from 'ducks/application/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import Routes from 'routes';
import TagManager from 'react-gtm-module';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { useHistory } from 'react-router-dom';
import size from 'lodash/size';
import { filterPrices } from 'utils/products/productVariant';
import { ClearAllConfirmModal } from './ClearAllConfirmModal/ClearAllConfirmModal';
import { CheckoutConfirmModal } from './CheckoutConfirnModal/CheckoutConfirmModal';
import {
  QuickOrderPageWrapper,
  QuickOrderTableWrapper,
  QuickOrderTable,
  SearchComponentWrapper,
  QuickOrderAction,
  ClearAllButton,
  FinalPriceTotal,
  AddToCartButton,
} from './styles';
import QuickOrderItem from './components/QuickOrderItem';
import SearchComponent from './components/SearchComponent';
import { useGA4CartEvent } from '../../GA/CartGa4/cartGA4';
import { usePermission } from '../../hooks/usePermission';

const QuickOrder = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const quickOrderItems = useSelector(selectQuickOrderItems);
  const deliveryType = useSelector(priceTypeSelector);
  const isHidePrice = useSelector(selectIsHidePrice);
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const disableQuickOrderCheckout = useSelector(selectDisableQuickOrderCheckout);
  const addressId = useSelector(selectAddressId);

  const [visibleClearAllConfirmModal, setVisibleClearAllConfirmModal] = useState(false);
  const [visibleCheckoutConfirmModal, setVisibleCheckoutConfirmModal] = useState(false);
  const [quickOrderItemsState, setQuickOrderItemsState] = useState<IQuickOrderItem[]>(
    quickOrderItems
  );

  const { canPlaceOrder, canPlaceQuote, isInitialized } = usePermission();

  useGetQuickOrder(addressId);

  useEffect(() => {
    setQuickOrderItemsState(quickOrderItems);
    if (quickOrderItems.length > 0) {
      const quickOrderTotalPrice = quickOrderItems.reduce(
        (acc, current) => acc + (current.total ?? 0),
        0
      );
      if (quickOrderTotalPrice === 0) {
        dispatch(setDisableQuickOrderCheckout({ disableCheckout: true }));
      } else {
        dispatch(setDisableQuickOrderCheckout({ disableCheckout: false }));
      }
    }
  }, [JSON.stringify(quickOrderItems)]);


  const handleSetQuickOrderItems = useCallback(() => {
    setQuickOrderItemsState(quickOrderItems);
  }, [quickOrderItems]);

  useEffect(() => {
    window.addEventListener('storage', handleSetQuickOrderItems);
    return () => {
      window.removeEventListener('storage', handleSetQuickOrderItems);
    };
  }, [handleSetQuickOrderItems]);

  useEffect(() => {
    if (quickOrderItems.length > 0) {
      const buildQuickOrderItemPrice = quickOrderItems.map((item: IQuickOrderItem) => {
        if (size(item.prices) === 0) return item;
        const prices = filterPrices(item.prices);
        if (item.quantity >= prices[prices.length - 1].qty) {
          item.total = item.quantity * prices[prices.length - 1][deliveryType];
        } else {
          item.total = item.quantity * prices[0][deliveryType];
        }
        return item;
      });
      setQuickOrderItemsState(buildQuickOrderItemPrice);
    }
  }, [deliveryType, dispatch, quickOrderItems]);

  const { handleFireGA4ViewCart } = useGA4CartEvent();

  const handleClearAll = useCallback(() => {
    dispatch(clearQuickOrder());
    setVisibleClearAllConfirmModal(false);
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'quick_order_clear_items',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: addressId,
      },
    });
  }, [dispatch, user, branch, erpCustomerAddressCode, addressId]);

  const handleCheckout = (isContinue: boolean = false) => {
    if (!isContinue && quickOrderItems.some((item: IQuickOrderItem) => item.total === 0)) {
      setVisibleCheckoutConfirmModal(true);
    } else {
      dispatch(
        bulkAddToCart({
          products: quickOrderItems
            .filter(item => item.quantity > 0)
            .map((item: IQuickOrderItem) => ({
              productId: item.productId,
              quantity: item.quantity,
              categoryId: item.category.id,
            })),
          deliveryType,
        })
      );
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'go_to_checkout',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: addressId,
        },
      });
      handleFireGA4ViewCart();
      setTimeout(() => {
        history.push(Routes.CHECKOUT);
      });
    }
  };

  useEffect(() => {
    if (isInitialized) {
      if (!canPlaceQuote && !canPlaceOrder) {
        setTimeout(() => {
          history.replace(Routes.NOT_FOUND);
        });
      }
    }
  }, [canPlaceOrder, canPlaceQuote, history, isInitialized]);

  // @ts-ignore
  return (
    <QuickOrderPageWrapper>
      <BreadCrumbs currentPageName={t('header.quick_order')} />

      <div className="title-page">{t('header.quick_order').toString()}</div>

      <QuickOrderTableWrapper>
        <QuickOrderTable>
          <div className="corner-1" />
          <div className="corner-2" />
          <div className="corner-3" />
          <div className="corner-4" />
          <div className="line-1" />
          <div className="line-2" />
          <div className="line-3" />
          <div className="line-4" />

          <table>
            <colgroup>
              <col className="col-item-sku-product" />
              <col className="col-product-name" />
              <col className="col-stock-status" />
              <col className="col-quantity" />
              <col className="col-price" />
              <col className="col-total-price" />
              <col className="col-action" />
            </colgroup>
            <thead>
              <tr>
                <th className="col-item-sku-product">{t('quick_order.sku').toString()}</th>
                <th className="col-product-name">{t('quick_order.product_name').toString()}</th>
                <th className="col-stock-status">{t('quick_order.stock_status').toString()}</th>
                <th className="col-quantity">{t('quick_order.quantity').toString()}</th>
                <th className="col-price">{t('quick_order.price').toString()}</th>
                <th className="col-total-price">{t('quick_order.total_price').toString()}</th>
                <th className="col-action" />
              </tr>
            </thead>

            <tbody>
              {quickOrderItemsState.length > 0 &&
                quickOrderItemsState.map(item => (
                  // @ts-ignore
                  <QuickOrderItem quickOrderItem={item} key={item.erpSku} />
                ))}
              <tr>
                <td>
                  <SearchComponentWrapper>
                    <SearchComponent handleOuterClick={() => {}} isSearchBySku />
                  </SearchComponentWrapper>
                </td>

                <td>
                  <SearchComponentWrapper>
                    <SearchComponent handleOuterClick={() => {}} isSearchBySku={false} />
                  </SearchComponentWrapper>
                </td>

                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
            </tbody>
          </table>
        </QuickOrderTable>
      </QuickOrderTableWrapper>

      <QuickOrderAction>
        <div className="col box-left">
          <ClearAllButton
            onClick={() => setVisibleClearAllConfirmModal(true)}
            disabled={quickOrderItems.length === 0}
            data-test-id="clear-all"
          >
            {t('quick_order.clear_all').toString()}
          </ClearAllButton>
        </div>

        <div className="col box-right">
          <FinalPriceTotal>
            <span className="txt-total">{t('quick_order.total').toString()}:</span>
            {!isHidePrice ? (
              <span className="txt-price">
                {quickOrderItems.length === 0
                  ? '$0.00'
                  : `$${numberFormat(
                    quickOrderItems.reduce((acc, current) => acc + (current.total ?? 0), 0)
                  )}`}
              </span>
            ) : (
              <span className="txt-price">0.00</span>
            )}
          </FinalPriceTotal>

          <AddToCartButton
            onClick={() => handleCheckout(false)}
            disabled={quickOrderItems.length === 0 || disableQuickOrderCheckout}
            data-test-id="add-to-cart"
          >
            <TruckIcon />
            <span className="text">{t('product_detail.add_to_truck').toString()}</span>
          </AddToCartButton>
        </div>
      </QuickOrderAction>

      {visibleClearAllConfirmModal && (
        <ClearAllConfirmModal
          open={visibleClearAllConfirmModal}
          callbackOnClose={() => setVisibleClearAllConfirmModal(false)}
          callbackOnSubmit={handleClearAll}
        />
      )}

      {visibleCheckoutConfirmModal && (
        <CheckoutConfirmModal
          open={visibleCheckoutConfirmModal}
          callbackOnClose={() => setVisibleCheckoutConfirmModal(false)}
          callbackOnSubmit={() => handleCheckout(true)}
        />
      )}
    </QuickOrderPageWrapper>
  );
};

export default QuickOrder;
