import { Reducer } from 'redux';

// Types
import { AppState, AppActionTypes } from 'ducks/application/types';

const initialState: AppState = {
  menuIsOpened: false,
  priceType: null,
  selectedAddressCode: null,
  selectedAddressId: null,
  serverError: null,
  banner: null,
  background: null,
  storeBanner: null,
  loading: true,
  error: false,
  language: 'en_CA',
  showRecaptchaModal: false,
  specialPages: [],
  loadingSpecialPage: true,
  isHidePrice: false,
};

const appReducer: Reducer<AppState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case AppActionTypes.MENU_IS_OPENED: {
      return { ...state, menuIsOpened: true };
    }
    case AppActionTypes.MENU_IS_CLOSED: {
      return { ...state, menuIsOpened: false };
    }
    case AppActionTypes.SET_PRICES_TYPE: {
      return { ...state, priceType: payload.priceType };
    }
    case AppActionTypes.SET_SERVER_ERROR: {
      return { ...state, serverError: payload.error };
    }
    case AppActionTypes.REQUEST_BANNER: {
      return { ...state, loading: true, banner: null, error: null };
    }
    case AppActionTypes.REQUEST_BANNER_SUCCESS: {
      return { ...state, loading: false, banner: payload, error: null };
    }
    case AppActionTypes.REQUEST_BANNER_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case AppActionTypes.REQUEST_BACKGROUND: {
      return { ...state, error: null, loading: true };
    }
    case AppActionTypes.REQUEST_BACKGROUND_SUCCESS: {
      return { ...state, loading: false, background: payload.url, error: null };
    }
    case AppActionTypes.REQUEST_BACKGROUND_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case AppActionTypes.SET_DEFAULT_BANNER: {
      return { ...state, banner: payload.url };
    }
    case AppActionTypes.SET_DEFAULT_BACKGROUND: {
      return { ...state, background: payload.url };
    }
    case AppActionTypes.SET_ADDRESS_CODE: {
      return { ...state, selectedAddressCode: payload.addressCode };
    }
    case AppActionTypes.SET_ADDRESS_ID: {
      return { ...state, selectedAddressId: payload.addressId };
    }
    case AppActionTypes.REQUEST_SEND_CONTACT_US: {
      return { ...state, error: null, loading: true };
    }
    case AppActionTypes.REQUEST_SEND_CONTACT_US_SUCCESS: {
      return { ...state, loading: false, error: null };
    }
    case AppActionTypes.REQUEST_SEND_CONTACT_US_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case AppActionTypes.REQUEST_STORE_BANNER: {
      return { ...state, loading: true, error: null };
    }
    case AppActionTypes.REQUEST_STORE_BANNER_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false,
        storeBanner: payload.storeBanner,
      };
    }
    case AppActionTypes.REQUEST_STORE_BANNER_ERROR: {
      return { ...state, error: payload.error, loading: false, storeBanner: null };
    }
    case AppActionTypes.SET_LANGUAGE: {
      return { ...state, language: payload.language };
    }
    case AppActionTypes.OPEN_RECAPTCHA_MODAL: {
      return {
        ...state,
        showRecaptchaModal: true,
      };
    }
    case AppActionTypes.CLOSE_RECAPTCHA_MODAL: {
      return {
        ...state,
        showRecaptchaModal: false,
      };
    }
    case AppActionTypes.REQUEST_GET_SPECIAL_PAGE: {
      return {
        ...state,
        loadingSpecialPage: true,
      };
    }
    case AppActionTypes.REQUEST_GET_SPECIAL_PAGE_SUCCESS: {
      return {
        ...state,
        loadingSpecialPage: false,
        specialPages: payload.data,
      };
    }
    case AppActionTypes.REQUEST_GET_SPECIAL_PAGE_ERROR: {
      return {
        ...state,
        loadingSpecialPage: false,
      };
    }
    case AppActionTypes.REQUEST_IS_HIDE_PRICE: {
      return {
        ...state,
        isHidePrice: payload.isHidePrice,
      };
    }
    default:
      return state;
  }
};

export default appReducer;
