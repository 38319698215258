import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { BranchIcon } from 'assets/svg/BranchIcon';

import SubMenu from 'components/Layout/Submenu/SubMenu';
import PricingDropdown from 'components/Layout/Header/Dropdowns/PricingDropdown/PricingDropdown';
import LanguageDropdown from 'components/Layout/Header/Dropdowns/LanguageDropdown/LanguageDropdown';
import LocationDropdown from 'components/Layout/Header/Dropdowns/LocationDropdown/LocationDropdown';
import { requestCartSummary } from 'ducks/cart/actions';
import { selectBranch } from 'ducks/branch/selectors';
import { useSetPrices } from 'hooks/useSetPrices';
import Routes from 'routes';

import { MiddleMenu } from 'components/Layout/Header/Header/MiddleMenu/MiddleMenu';
import { selectAddressId, selectErpCustomerAddressCode, selectIsHidePrice } from 'ducks/application/selectors';
import { usePermission } from 'hooks/usePermission';
import { logout } from 'ducks/auth/actions';
import TagManager from 'react-gtm-module';

import { setIsHidePrice } from 'ducks/application/actions';
import { useMutation } from 'react-query';
import { TogglePrices } from 'api/User';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { IOSSwitch } from '../Dropdowns/PricingDropdown/styles';
import {
  HeaderGrid,
  MenuContentGrid,
  UpperMenuGridWapper,
  UpperMenuGrid,
  TwoDropdownsWrapper,
  UpperMenuContent,
  HeaderLink,
  StyledLink,
  StyledLinkFAQ,
  PricingGrid,
  BranchWrapper,
  StyledParagraph,
} from './styles';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const { canSeePrice, canPlaceOrder, canPlaceQuote } = usePermission();
  const { user } = useSetPrices();
  const dispatch = useDispatch();
  const branch = useSelector(selectBranch);
  const defaultAddress = useSelector(selectAddressId);
  const isHidePrice = useSelector(selectIsHidePrice);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  // const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (user && defaultAddress && canSeePrice && (canPlaceQuote || canPlaceOrder)) {
      dispatch(requestCartSummary(defaultAddress));
    }
    if (user) {
      dispatch(setIsHidePrice(!user.showPrice));
    }
  }, [dispatch, user, defaultAddress, canSeePrice, canPlaceOrder, canPlaceQuote]);

  const togglePriceMutation = useMutation(TogglePrices, {
    onSuccess: () => {
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: isHidePrice ? 'click_prices_on' : 'click_prices_off',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
      setTimeout(() => {
        dispatch(setIsHidePrice(!isHidePrice));
      })
    }
  })

  // const onScroll = () => {
  //   if (window.scrollY > 0) {
  //     setIsSticky(true)
  //   } else {
  //     setIsSticky(false)
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', onScroll);

  //   return () => {
  //     window.removeEventListener('scroll', onScroll);
  //   };
  // });

  return (
    <HeaderGrid container direction="column">
      <UpperMenuGridWapper>
        <UpperMenuGrid item container xs={12}>
          <MenuContentGrid item container>
            <PricingGrid item md={3} xs={6}>
              <FormControlLabel
                control={<IOSSwitch />} label={t('header.prices').toString()}
                className="prices-switch" checked={!isHidePrice} onChange={() => {
                  togglePriceMutation.mutate(isHidePrice);
                }} />
              {
                canSeePrice && (
                  <PricingDropdown />
                )
              }
            </PricingGrid>

            <Grid item md={9} xs={6}>
              <UpperMenuContent>
                <TwoDropdownsWrapper>
                  <BranchWrapper>
                    <BranchIcon />
                    <StyledParagraph>{branch?.erpName}</StyledParagraph>
                  </BranchWrapper>

                  <LocationDropdown />

                  <LanguageDropdown />

                  <StyledLinkFAQ to={Routes.FAQ} data-test-id="faq-button">
                    <HeaderLink>{t('header.faq').toString()}</HeaderLink>
                  </StyledLinkFAQ>

                  <StyledLink to={Routes.CONTACT_US} data-test-id="contact-us-button">
                    <HeaderLink>{t('header.contact_us').toString()}</HeaderLink>
                  </StyledLink>

                  <StyledLink to="/" onClick={() => {
                    dispatch(logout());
                    return false;
                  }}>
                    <HeaderLink style={{ textTransform: 'uppercase' }} data-test-id="logout">
                      {t('header.logout').toString()}
                    </HeaderLink>
                  </StyledLink>
                </TwoDropdownsWrapper>
              </UpperMenuContent>
            </Grid>
          </MenuContentGrid>
        </UpperMenuGrid>
      </UpperMenuGridWapper>

      <MiddleMenu user={user} />

      <SubMenu />
    </HeaderGrid>
  );
};

export default Header;
