import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ThemeProvider} from 'styled-components';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import TagManager from 'react-gtm-module';

import App from 'App';
import store from 'store';
import theme from 'theme';

import {I18nextProvider} from 'react-i18next';
import {REACT_APP_ENV, REACT_APP_UA_ID, REACT_APP_SENTRY_DSN} from 'assets/constants/application';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import i18n from './i18n';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

TagManager.initialize({
  gtmId: REACT_APP_UA_ID
});

if (REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <App/>
        </I18nextProvider>
      </Provider>
    </ThemeProvider>
    <ReactQueryDevtools initialIsOpen={false}/>
  </QueryClientProvider>,
  document.getElementById('root')
);
