import styled from 'styled-components/macro';
import { TextField } from '@material-ui/core';

const InputButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InputWithLabelWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  &:first-child {
    margin-bottom: 8px;
  }
  .MuiInput-input {
    color: #00133C;
    font-weight: 600;
  }
  &.has-error {
    [class*="StyledInputWrapper-"] {
      border-color: #d92d20;
    }
    [class*="IncrementButton-"] {
      border-left-color: #d92d20;
      &:first-child {
        border-right-color: #d92d20;
      }
    }
    [class*="IncrementButton-"],
    .MuiFormControl-root {
      background:#fff3f3;
    }
  }
`;

const StyledInputWrapper = styled.div`
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  max-width: 132px;
  overflow: hidden;
`;

const IncrementButton = styled.button`
  height: 30px;
  border: none;
  border-left: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { colors } }) => colors.white};
  outline: none;
  width: 80px;

  &:hover {
    cursor: pointer;
  }

  &:first-child {
    border: none;
    border-right: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  }
`;

const IncrementButtonLabel = styled.p`
  margin-bottom: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.01em;
`;

const StyledTextField = styled(TextField as any)`
  .MuiInputBase-input {
    text-align: center;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const PromotionText = styled.span`
  text-decoration: underline;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  &:hover {
    font-size: 18px;
    font-weight: 900;
  }
`;

const NextTierPrice = styled.span`
  font-family: Open Sans,serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  margin: 0;
`;

const BranchName = styled.p`
  color: #337D58;
  font-size: 14px;
  font-weight: 600;
`;

export {
  StyledTextField,
  IncrementButtonLabel,
  IncrementButton,
  StyledInputWrapper,
  InputWithLabelWrapper,
  InputButtonsWrapper,
  PromotionText,
  NextTierPrice,
  BranchName
};
