import React from 'react';

import { useSelector } from 'react-redux';
import { selectLanguage } from 'ducks/application/selectors';
import TermsOfServiceEnglish from './TermsOfServiceEnglish';
import TermsOfServiceFrench from './TermsOfServiceFrench';

interface ILanguage {
  language?: string;
}

const TermsOfService: React.FC<ILanguage> = ({ language }: ILanguage) => {

  const userLanguage = useSelector(selectLanguage);
  const selectedLanguage = language || userLanguage;
  const isEnglish = selectedLanguage === 'en_US' || selectedLanguage === 'en_CA';

  const Terms = isEnglish ? TermsOfServiceEnglish : TermsOfServiceFrench;

  return <Terms />;
};

export default TermsOfService;
