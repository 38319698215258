import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { snackBarZIndex } from 'assets/constants/application';
import { makeStyles } from '@material-ui/core';

interface IProps {
  open?: boolean;
  handleClose?: (event?: React.SyntheticEvent, reason?: string) => void;
  status?: string;
  errorMessage: string;
  successMessage: string;
}

const useStyles = makeStyles({
  root: {
    zIndex: snackBarZIndex,
  },
});

const CustomizedSnackbar: React.FC<IProps> = ({
  open,
  handleClose,
  status,
  successMessage,
  errorMessage,
}: IProps) => {
  const classes = useStyles();

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    ['Success', 'Error'].includes(status) ?
      <>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} className={classes.root}>
          <Alert onClose={handleClose} severity={status === 'Success' ? 'success' : 'error'}>
            {status === 'Success' ? successMessage : errorMessage}
          </Alert>
        </Snackbar>
      </> : null
  );
};

export default CustomizedSnackbar;
