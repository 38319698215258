import React, { useCallback, useEffect, useState } from 'react';
import { useMediaQuery, Stepper, StepLabel, Step } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OrderClass } from 'models/classes/orderClass';
import { QuoteClass } from 'models/classes/quoteClass';
import SingleOrderItem from 'pages/General/Profile/components/OrdersAndQuotes/ContentItem/components/SingleOrderItem/SingleOrderItem';
import ReorderBlock from 'pages/General/Profile/components/OrdersAndQuotes/ContentItem/components/ReorderBlock/ReorderBlock';
import CardContentHeader from 'pages/General/Profile/components/OrdersAndQuotes/ContentItem/components/CardContentHeader/CardContentHeader';
import PaymentInformationBlock from 'pages/General/Profile/components/OrdersAndQuotes/ContentItem/components/PaymentInformationBlock/PaymentInformationBlock';
import SingleOrderItemWithCheckbox from 'pages/General/Profile/components/OrdersAndQuotes/ContentItem/components/SingleOrderItem/SingleOrderItemWithCheckbox';
import { useAddReorderedProducts } from 'pages/General/Profile/hooks/hooks';
import MobileCardContentHeader from 'pages/General/Profile/components/OrdersAndQuotes/ContentItem/components/CardContentHeader/MobileCardContentHeader';
import { reorderClickGAEvent } from 'GA/OrderGA/OrderGA';
import { useDispatch, useSelector } from 'react-redux';
import {
  priceTypeSelector,
  selectAddressId,
  selectErpCustomerAddressCode, selectIsHidePrice,
} from 'ducks/application/selectors';
import { getOrderDetail, validateItemsReOrder } from 'api/ReOrder';
import { filterPrices } from 'utils/products/productVariant';
import TagManager from 'react-gtm-module';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import get from 'lodash/get';
import { getActiveStep, getOrderProcessStatus } from 'utils/order/order';
import {clearGtmEcommerce} from 'utils/clearGtmEcommerce';
import {
  setEmailInquireNowOrderHistorySent,
  setInquireNowOrderHistory,
  setOpenInquireNowOrderHistory,
} from 'ducks/order/actions';
import { createUserActionLog } from 'api/UserActionLog';
import { useGlobalSettings } from 'hooks/useGlobalSetting';
import {
  StyledCollapse,
  StyledCard,
  StyledCardContentOpen,
  ItemsBlock,
  TagWrapper,
  StatusSteps,
} from './styles';

interface OrderItemProps {
  element?: OrderClass | QuoteClass;
  openOrderTab?: (orderNumber: number) => void;
}

const ContentItem: React.FC<OrderItemProps> = ({ element, openOrderTab }: OrderItemProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [isReorder, setIsReorder] = useState(false);
  const [itemQuantity, setItemQuantity] = useState([]);
  const [reorderItems, setReorderItems] = useState(null);
  const [subPrice, setSubPrice] = useState(null);
  const [countErrorUpdateQuantity, setCountErrorUpdateQuantity] = useState([]);
  const [canReorder, setCanReorder] = useState(false);
  const [selectReorderItems, setSelectReorderItems] = useState([]);
  const [order, setOrder] = useState(element);
  const [loadingGetOrderDetail, setLoadingGetOrderDetail] = useState(false);
  const { mutate } = useAddReorderedProducts();
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const addressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const deliveryType = useSelector(priceTypeSelector);
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const isHidePrice = useSelector(selectIsHidePrice);
  const isQuotes = element instanceof QuoteClass;
  const steps = React.useMemo(() => {
    if (!get(order, 'tnStatus')) {
      return []
    }
    const arrSteps = getOrderProcessStatus(order.saleType);
    let tnStatus = get(order, 'tnStatus')?.toLowerCase();
    if (tnStatus?.toLowerCase() === 'confirmed') {
      tnStatus = 'waiting on stock';
    }
    const index = arrSteps.findIndex(
      step => step.status.toLowerCase() === tnStatus
    );
    if (index !== 1 && index > -1) {
      // arrSteps[1] mean label = waiting on stock
      arrSteps[1].label = t('process.confirmed');
    }
    if (index === 1 && get(order, 'tnStatus')?.toLowerCase() === 'confirmed') {
      arrSteps[1].label = t('process.confirmed');
    }
    return arrSteps;
  }, [order.saleType, order.tnStatus]);

  const { data: globalSettings } = useGlobalSettings()

  const handleExpandClick = async () => {
    if (!expanded) {
      setLoadingGetOrderDetail(true);
      const data = await getOrderDetail({orderId: element.id, addressId, deliveryType, orderType: isQuotes ? 'quote' : 'order'});
      setOrder(data);
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'click_expand_order',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: addressId,
        },
      });
      setLoadingGetOrderDetail(false);
    }
    setExpanded(!expanded);
    setIsReorder(false);
  };

  const handleItemCheck = (item: any) => {
    const checkedItems = [...selectReorderItems];
    const index = checkedItems.findIndex(e => e === item.id);
    if (index !== -1) {
      checkedItems.splice(index, 1);
    } else if (item.canReorder) {
      checkedItems.push(item.id);
    }
    setSelectReorderItems(checkedItems);
  };

  const renderTag = () => {
    if (element?.tag !== '' && element?.tag !== null && element?.tag !== 'null') {
      return (
        <TagWrapper>
          <span>{element?.tag}</span>
        </TagWrapper>
      );
    }
    return null;
  };

  const handleReorderClick = async () => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_order_again',
        user_type: getUserType(user.email),
        branch_id: branch?.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: addressId,
      },
    });

    setIsReorder(true);
    setExpanded(true);
    const data = await validateItemsReOrder({ orderId: element.id, addressId, deliveryType });
    if (data.items.length) {
      const checkedItems = [];
      const objItemAndQuantity = data.items
        .filter(item => item.canReorder)
        .map(item => {
          checkedItems.push(item.id);
          let price = item.prices[0][deliveryType];
          const sortPrices = filterPrices(item.prices);
          if (sortPrices.length > 1) {
            if (item.quantity >= sortPrices[1].qty) {
              price = item.prices[1][deliveryType];
            }
          }
          return {
            productId: item.variant.id,
            quantity: item.quantity,
            id: item.id,
            price,
            prices: item.prices,
            orderId: element.id,
          };
        });
      setSelectReorderItems(checkedItems);
      setItemQuantity(objItemAndQuantity);
    }
    setReorderItems(data);
    if (data.items.filter(item => item.canReorder).length === 0) {
      setCanReorder(false);
    }
    reorderClickGAEvent();
  };

  React.useEffect(() => {
    if (isReorder && addressId) {
      handleReorderClick();
    }
  }, [addressId, deliveryType]);

  useEffect(() => {
    if (itemQuantity.length) {
      let calSubPrice = 0;
      itemQuantity
        .filter(item => selectReorderItems.includes(item.id))
        .map(item => {
          const normalPrice = filterPrices(item.prices)[0];
          if (item.prices.length > 1) {
            const promotionPrice = filterPrices(item.prices)[1];
            if (item.quantity >= promotionPrice.qty) {
              calSubPrice += promotionPrice[deliveryType] * item.quantity;
            } else {
              calSubPrice += normalPrice[deliveryType] * item.quantity;
            }
          } else {
            calSubPrice += normalPrice[deliveryType] * item.quantity;
          }
          return item;
        });
      setSubPrice(calSubPrice);
    }
  }, [itemQuantity, selectReorderItems]);

  const handleCancelReorderClick = useCallback(() => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'reorder_cancel',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: addressId,
      },
    });

    setIsReorder(false);
    setExpanded(false);
  }, [addressId, branch?.id, erpCustomerAddressCode, user?.email, user?.customer?.erpCustomerCode]);

  useEffect(() => {
    if (deliveryType && isHidePrice) {
      handleCancelReorderClick();
    }
  }, [deliveryType, handleCancelReorderClick]);

  const handleSetItemQuantity = (value: React.SetStateAction<any[]>) => {
    setItemQuantity(value);
  };

  const handleAddToTruck = () => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'add_to_cart_order_again',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: addressId,
      },
    });

    const addProductObjects = itemQuantity
      .filter(item => selectReorderItems.includes(item.id))
      .map(item => {
        return {
          id: item.productId,
          quantity: item.quantity,
          orderId: item.orderId,
        };
      });

    mutate(addProductObjects);
  };

  const handleClickInquireNow = useCallback(async () => {
    await createUserActionLog('Order History Page Inquire Now', 'Order history Inquiry');
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: isQuotes ? 'click_quote_inquiry' : 'click_order_inquiry',
        user_type: getUserType(user?.email),
        branch_id: branch?.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: addressId,
      },
    });
    dispatch(setInquireNowOrderHistory({
      type: isQuotes ? 'quote' : 'order',
      orderNumber: order?.erpOrderNumber,
      dateRequired: order?.dateRequired,
      orderType: order?.saleType,
    }));
    setTimeout(() => {
      dispatch(setOpenInquireNowOrderHistory(true));
      dispatch(setEmailInquireNowOrderHistorySent(false));
    });
  }, [dispatch, isQuotes, order?.dateRequired, order?.erpOrderNumber, order?.saleType]);

  return (
    <StyledCard isquotes={isQuotes}>
      {isMobile ? (
        <MobileCardContentHeader
          element={element}
          expanded={expanded}
          isQuotes={isQuotes}
          statusText={element.erpStatus}
          handleExpandClick={handleExpandClick}
          renderTag={renderTag}
          handleReorderClick={handleReorderClick}
          orderTotal={subPrice}
          isReorder={isReorder}
          openOrderTab={openOrderTab}
          handleClickInquireNow={handleClickInquireNow}
          isShowOtpInOrderUpdateEmail={globalSettings?.isShowOrderUpdateEmail}
        />
      ) : (
        <CardContentHeader
          element={order}
          expanded={expanded}
          isQuotes={isQuotes}
          statusText={element.erpStatus}
          handleExpandClick={handleExpandClick}
          renderTag={renderTag}
          handleReorderClick={handleReorderClick}
          reOrderItems={reorderItems}
          isReorder={isReorder}
          openOrderTab={openOrderTab}
          goodsTotal={subPrice}
          loadingGetOrderDetail={loadingGetOrderDetail}
          handleClickInquireNow={handleClickInquireNow}
          isShowOtpInOrderUpdateEmail={globalSettings?.isShowOrderUpdateEmail}
        />
      )}
      <StyledCollapse in={expanded} timeout="auto" unmountOnExit>
        {expanded && get(order, 'tnStatus') && get(order, 'tnStatus', '').toLowerCase() !== 'invoiced' && steps.length > 0 && (
          <StatusSteps>
            <div className="title-status-steps">{t('orders_and_quotes.order_status').toString()}</div>
            <Stepper
              activeStep={getActiveStep(order.saleType, order.tnStatus)}
              alternativeLabel={!isMobile}
              orientation={isMobile ? 'vertical' : 'horizontal'}
            >
              {steps.map((label, idx) => (
                <Step key={label.label}>
                  <StepLabel>
                    <div className="md">{t(label.label).toString()}</div>
                    {idx === steps.length - 1 && (
                      <div className="sm">Est Date: {order.dateRequired}</div>
                    )}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </StatusSteps>
        )}

        <StyledCardContentOpen style={{ width: '100%' }}>
          {!isReorder && (
            <ItemsBlock>
              {order.items.map(i => (
                <SingleOrderItem
                  key={i.id}
                  item={i}
                  setItemQuantity={handleSetItemQuantity}
                  itemQuantity={itemQuantity}
                  setCountErrorUpdateQuantity={setCountErrorUpdateQuantity}
                  countErrorUpdateQuantity={countErrorUpdateQuantity}
                  setCanReorder={setCanReorder}
                />
              ))}
            </ItemsBlock>
          )}
          <ItemsBlock>
            {isReorder && reorderItems
              ? reorderItems.items.map(item => (
                <SingleOrderItemWithCheckbox
                  key={`checked-${item.id}`}
                  item={item}
                  checked={selectReorderItems.includes(item.id)}
                  handleItemCheck={() => handleItemCheck(item)}
                  disabled={item?.variant?.addressPrices?.length === 0 || !item.canReorder}
                  setItemQuantity={handleSetItemQuantity}
                  itemQuantity={itemQuantity}
                  selectReorderItems={selectReorderItems}
                  countErrorUpdateQuantity={countErrorUpdateQuantity}
                  setCountErrorUpdateQuantity={setCountErrorUpdateQuantity}
                  setCanReorder={setCanReorder}
                />
              ))
              : null}
          </ItemsBlock>
          {isReorder ? (
            <ReorderBlock
              cancelReorder={handleCancelReorderClick}
              goodsTotal={subPrice}
              handleAddToTruck={handleAddToTruck}
              isAbleToAdd={canReorder}
            />
          ) : (
            expanded && (
              <PaymentInformationBlock element={order} isQuotes={isQuotes} expanded={expanded} />
            )
          )}
        </StyledCardContentOpen>
      </StyledCollapse>
    </StyledCard>
  );
};

export default React.memo(ContentItem);
