// Classes
import { ProductVariantClass } from 'models/classes/productVariantClass';
import { FacetClass } from 'models/classes/facetClass';
import { IFetchFilteredProductsPayload, IFetchSearchProductsPayload } from 'ducks/search/actions';
import { IError } from 'models/interfaces/error';
import { IRangeFilter } from 'models/interfaces/productVariant';

export enum SearchActionTypes {
  REQUEST_FILTERED_PRODUCTS = '@@searchProducts/REQUEST_FILTERED_PRODUCTS',
  REQUEST_FILTERED_PRODUCTS_SUCCESS = '@@searchProducts/REQUEST_FILTERED_PRODUCTS_SUCCESS',
  REQUEST_FILTERED_PRODUCTS_SUCCESS_FIRST_LOAD = '@@searchProducts/REQUEST_FILTERED_PRODUCTS_SUCCESS_FIRST_LOAD',
  REQUEST_FILTERED_PRODUCTS_ERROR = '@@searchProducts/REQUEST_FILTERED_PRODUCTS_ERROR',

  RESET_PRODUCTS = '@@searchProducts/RESET_PRODUCTS',

  SET_PAGINATION_LOADING = '@@searchProducts/SET_PAGINARION_LOADING',
  REMOVE_PAGINATION_LOADING = '@@searchProducts/REMOVE_PAGINATION_LOADING',

  REQUEST_SEARCH_PRODUCTS = '@@searchProducts/REQUEST_SEARCH_PRODUCTS',
  REQUEST_SEARCH_PRODUCTS_SUCCESS = '@@searchProducts/REQUEST_SEARCH_PRODUCTS_SUCCESS',
  REQUEST_SEARCH_PRODUCTS_FIRST_LOAD_SUCCESS = '@@searchProducts/REQUEST_SEARCH_PRODUCTS_FIRST_LOAD_SUCCESS',

  REQUEST_SEARCH_PRODUCTS_FILTER = '@@searchProducts/REQUEST_SEARCH_PRODUCTS_FILTER',
  REQUEST_SEARCH_PRODUCTS_FILTER_SUCCESS = '@@searchProducts/REQUEST_SEARCH_PRODUCTS_FILTER_SUCCESS',
  REQUEST_SEARCH_DUMP_PRODUCT_IDS = '@@searchProducts/REQUEST_SEARCH_DUMP_PRODUCT_IDS',
  REQUEST_SEARCH_DUMP_PRODUCT_IDS_SUCCESS = '@@searchProducts/REQUEST_SEARCH_DUMP_PRODUCT_IDS_SUCCESS',

	RESET_PRODUCTS_ON_FILTER = '@@searchProducts/RESET_PRODUCTS_ON_FILTER',
}

export interface SearchProductState {
  readonly products: ProductVariantClass[];
  readonly facets: FacetClass[];
  readonly rangeFilters: IRangeFilter[];
  readonly loading: boolean;
  readonly paginationLoading: boolean;
  readonly error: IError | null;
  readonly isLastPage: boolean;
  readonly total: number;
	readonly searchProductIdsList: [];
}

export interface FetchFilteredProductsAction {
  type: SearchActionTypes.REQUEST_FILTERED_PRODUCTS;
  payload: IFetchFilteredProductsPayload;
}

export interface ISearchProductsAction {
  type: SearchActionTypes.REQUEST_FILTERED_PRODUCTS;
  payload: IFetchSearchProductsPayload;
}

export interface ISearchProductsFilterAction {
  type: SearchActionTypes.REQUEST_SEARCH_PRODUCTS_FILTER;
  payload: {
    query: string;
    language: string;
    addressId: number;
  };
}

export interface ISearchDumpProductIdsAction {
  type: SearchActionTypes.REQUEST_SEARCH_DUMP_PRODUCT_IDS;
  payload: {
    query: string;
    filters: any;
    addressId: number;
  };
}
