import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const Root = styled.div`
  height: 100vh;
  width: 100vw;
  background: ${({ theme: { colors } }) => colors.white};
`;

const Content = styled(({ children, ...rest }) => <Grid {...rest}>{children}</Grid>)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ILayout {
  children: React.ReactNode;
}

const ErrorComponent: React.FC<ILayout> = ({ children }: ILayout) => {
  return (
    <Root>
      <Content>{children}</Content>
    </Root>
  );
};

export default ErrorComponent;
