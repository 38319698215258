import React from 'react';
import Fade from 'react-reveal/Fade';
import Page from 'pages/Landing/SecondPageBlock';
import LandingThird from 'pages/Landing/LandingPageThird';
import PortfolioSection from 'pages/Landing/SectionPortfolio';
import BenefitsSection from 'pages/Landing/SectionBenefits';
import LandingFive from 'pages/Landing/LandingFive';
import LandingFirst from 'pages/Landing/LandingFirst';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {useTranslation} from 'react-i18next';

interface ILandingProps {
	isEnglish: boolean;
}

const recaptureKey = process.env.REACT_APP_GOOGLE_CAPTCHA_KEY;

const LandingComponent: React.FC<ILandingProps> = ({ isEnglish }: ILandingProps) => {
  const { t } = useTranslation();
  const landingFirstRef = React.useRef<HTMLDivElement>(null);

  return isEnglish ? (
    <GoogleReCaptchaProvider reCaptchaKey={recaptureKey}>
      <Fade>
        <LandingFirst
          ref={landingFirstRef}
          header='Prepare to order anytime, anywhere.'
          subHeader='Preregister for TaigaNow. All you need to do is fill out this simple form. You’ll be set the instant TaigaNow is active.'
          formheader={t('app.register_now')}
          buttonLabel='Register'
          popupheader='Thank You For Registering!'
          popuptext='Soon you’ll be able to place your orders, monitor your history, and quote requests. Anytime, Anywhere.'
          popupclose='Close'
          firstnameLabel='First Name'
          lastnameLabel='Last Name'
          emailLabel='E-mail'
          companyLabel='Company'
          salesLabel='Taiga Sales Representative'
          branchLabel='Taiga Branch'
          firstnameValidateMin='This field should be at least 2 characters'
          firstnameValidateMax='This field should be 50 characters maximum'
          firstnameRequired='First Name is required'
          lastnameValidateMin='This field should be at least 2 characters'
          lastnameValidateMax='This field should be 50 characters maximum'
          passwordRequired='Password is required'
          companyValidateMin='This field should be at least 2 characters'
          companyValidateMax='This field should be 150 characters maximum'
          companyRequired='Company Name is required'
          salesValidateMin='This field should be at least 2 characters'
          salesValidateMax='This field should be 50 characters maximum'
          salesRequired='Sales Representative is required'
          emailInvalid='Email is invalid'
          emailValidateMax='This field should be 255 characters maximum'
          emailRequired='Email is required'
          branchRequired='Taiga Branch is required'
          emailIsUsed='This email is already in use'
          passwordLabel='Password'
          isEnglish={isEnglish}
        />
      </Fade>
      <Fade>
        <Page
          squareTitle='Requesting Quotes And Submitting Orders Has Never Been Easier'
          squareText="With a couple quick clicks you can request quotes to lock in pricing or submit an order. It's just that easy."
          bottomTitle='24/7 ordering of building products anytime, anywhere.'
          isEnglish={isEnglish}
        />
      </Fade>
      <Fade>
        <LandingThird
          squareTitle='ON or OFF Pricing Feature'
          squareText='The contractor’s desk has the ability to show product pricing or just use it as a customer catalogue. Either way, you’re in control.'
          bottomTitle='SURF THE PRODUCTS YOU WANT ANYTIME, ANYWHERE.'
          isEnglish={isEnglish}
        />
      </Fade>
      <Fade>
        <LandingFive
          popupheader='Thank you, a Taiga Representative will contact you shortly.'
          closepopup='Close'
          cardContentH3='TaigaNow was built for the way the world works'
          cardContentParagraph='Your office is anywhere you choose to be. As long as you can get online your devices
                give the orders anytime, anywhere.'
          firstnameLabel='First Name'
          lastnameLabel='Last Name'
          emailLabel='E-mail'
          companyLabel='Company'
          salesLabel='Taiga Sales Representative'
          branchLabel='Taiga Branch'
          buttonLabel='Register'
          firstButtonLabel='Shop Now'
          secondButtonLabel='Submit'
          notCustomer='Not Yet A Customer?'
          expandButtonLabel='Go Online'
          click='Click here'
          slogan='Keep up to date with the Taiga Newsletter.'
          firstnameValidateMin='This field should be at least 2 characters'
          firstnameValidateMax='This field should be 50 characters maximum'
          firstnameRequired='First Name is required'
          lastnameValidateMin='This field should be at least 2 characters'
          lastnameValidateMax='This field should be 50 characters maximum'
          passwordRequired='Password is required'
          companyValidateMin='This field should be at least 2 characters'
          companyValidateMax='This field should be 150 characters maximum'
          companyRequired='This field is required'
          salesValidateMin='This field should be at least 2 characters'
          salesValidateMax='This field should be 50 characters maximum'
          salesRequired='Sales Representative is required'
          emailInvalid='Email is invalid'
          emailValidateMax='This field should be 255 characters maximum'
          emailRequired='Email is required'
          branchRequired='Taiga Branch is required'
          subslogan='The latest in products and promotions every month in a new digital format.'
          submittedEmail='Your Email is Successfully Submitted'
          emailIsUsed='This email is already in use'
          passwordLabel='Password'
          isEnglish={isEnglish}
          landingFirstRef={landingFirstRef}
        />
      </Fade>
      <Fade>
        <BenefitsSection
          titleFirst='Whatever it takes to improve the way we do business.'
          benefitFirst='TaigaNow is faster'
          benefitSecond='It’s a precise, quality management system'
          benefitThird='24/7, anytime anywhere access'
          benefitFourth='Secure and dependable'
          benefitFifth='Thousands of products at your fingertips'
          titleSecond='Seriously, anywhere'
          isEnglish={isEnglish}
        />
      </Fade>
      <Fade>
        <PortfolioSection title='Taiga built and built for you.' isEnglish={isEnglish} />
      </Fade>
    </GoogleReCaptchaProvider>
  ) : (
    <GoogleReCaptchaProvider reCaptchaKey={recaptureKey}>
      <Fade>
        <LandingFirst
          header="Préparez votre commande, n'importe où, n'importe quand."
          subHeader='Preregister for TaigaNow. All you need to do is fill out this simple form. You’ll be set the instant TaigaNow is active.'
          formheader={t('app.register_now')}
          buttonLabel='Inscription'
          popupheader='Merci pour votre inscription!'
          popuptext="Prochainement, vous pourrez placer vos commandes, gérer votre historique et demander une soumission. N'importe où. N'importe quand."
          popupclose='Fermer'
          firstnameLabel='Nom'
          lastnameLabel='Prénom'
          emailLabel='Courriel'
          companyLabel='Compagnie'
          salesLabel='Représentant des ventes Taiga'
          branchLabel='Succursale Taiga'
          firstnameValidateMin='Ce champ doit comporter au moins 2 caractères'
          firstnameValidateMax='Ce champ doit comporter 50 caractères maximum'
          firstnameRequired='Un prénom est requis'
          lastnameValidateMin='Ce champ doit comporter au moins 2 caractères'
          lastnameValidateMax='Ce champ doit comporter 50 caractères maximum'
          passwordRequired='Password est requis'
          companyValidateMin='Ce champ doit comporter au moins 2 caractères'
          companyValidateMax='Ce champ doit comporter 150 caractères maximum'
          companyRequired='Un nom de compagnie est requis'
          salesValidateMin='Ce champ doit comporter au moins 2 caractères'
          salesValidateMax='Ce champ doit comporter 50 caractères maximum'
          salesRequired='Le nom du représentant est requis'
          emailInvalid="L'adresse courriel est invalide"
          emailValidateMax='Ce champ doit comporter 255 caractères maximum'
          emailRequired='Une adresse courriel est requise'
          branchRequired='Une succursale Taiga est requise'
          emailIsUsed='Cette adresse courriel est déjà utilisée'
          passwordLabel='Password'
          isEnglish={isEnglish}
        />
      </Fade>
      <Fade>
        <Page
          squareTitle="Demander une soumission ou placer une commande n'a jamais été aussi facile"
          squareText="En quelques clics vous pouvez demander une soumission pour obtenir un prix ou placer une commande. C'est tellement simple."
          bottomTitle="Commandez des produits de bâtiment n'importe où, n'importe quand 24h/7j"
          isEnglish={isEnglish}
        />
      </Fade>
      <Fade>
        <LandingThird
          squareTitle='Activer ou désactiver la fonctionnalité de tarification'
          squareText="L'entrepreneur a la possibilité de montrer le prix des produits ou d'utiliser le catalogue client. Autrement dit, vous avez le contrôle."
          bottomTitle="Consulter les produits n'importe où, n'importe quand."
          isEnglish={isEnglish}
        />
      </Fade>
      <Fade>
        <LandingFive
          popupheader='Merci, un représentant Taiga vous contactera rapidement.'
          closepopup='Fermer'
          cardContentH3="TaigaNow a été conçu pour s'adapter à la façon dont le monde fonctionne"
          cardContentParagraph="Votre bureau est partout où vous choisissez d'être. Aussi longtemps que vous aurez une connexion, vos appareils vous permettrons de commander n'importe où, n'importe quand."
          firstnameLabel='Nom'
          lastnameLabel='Prénom'
          emailLabel='Courriel'
          companyLabel='Compagnie'
          salesLabel='Représentant des ventes Taiga'
          branchLabel='Succursale Taiga'
          buttonLabel="S'inscrire"
          firstButtonLabel='Magasiner maintenant'
          secondButtonLabel='Soumettre'
          notCustomer='Pas encore client ?'
          expandButtonLabel='Aller en ligne'
          click='Cliquer ici'
          slogan="Restez informé avec l'infolettre de Taiga"
          firstnameValidateMin='Ce champ doit comporter au moins 2 caractères'
          firstnameValidateMax='Ce champ doit comporter 50 caractères maximum'
          firstnameRequired='Un prénom est requis'
          lastnameValidateMin='Ce champ doit comporter au moins 2 caractères'
          lastnameValidateMax='Ce champ doit comporter 50 caractères maximum'
          passwordRequired='Password est requis'
          companyValidateMin='Ce champ doit comporter au moins 2 caractères'
          companyValidateMax='Ce champ doit comporter 150 caractères maximum'
          companyRequired='Un nom de compagnie est requis'
          salesValidateMin='Ce champ doit comporter au moins 2 caractères'
          salesValidateMax='Ce champ doit comporter 50 caractères maximum'
          salesRequired='Le nom du représentant est requis'
          emailInvalid="L'adresse courriel est invalide"
          emailValidateMax='Ce champ doit comporter 255 caractères maximum'
          emailRequired='Une adresse courriel est requise'
          branchRequired='Une succursale Taiga est requise'
          subslogan='Les derniers produits et spéciaux de chaque mois au format numérique.'
          submittedEmail='Votre courriel a été soumis avec succès'
          emailIsUsed='Cette adresse courriel est déjà utilisée'
          passwordLabel='Password'
          isEnglish={isEnglish}
        />
      </Fade>
      <Fade>
        <BenefitsSection
          titleFirst="tout ce qu'il vous faut pour améliorer vos affaires."
          benefitFirst='TaigaNow est plus rapide'
          benefitSecond="C'est un système précis de gestion de la qualité"
          benefitThird="Accessible n'importe où, n'importe quand, 24h/7j"
          benefitFourth='Sécuritaire et fiable'
          benefitFifth='Des milliers de produits sous vos doigts'
          titleSecond="Sérieusement, n'importe où"
          isEnglish={isEnglish}
        />
      </Fade>
      <Fade>
        <PortfolioSection title='Taiga conçu et construit pour vous' isEnglish={isEnglish} />
      </Fade>
    </GoogleReCaptchaProvider>
  );
};

export default LandingComponent;
