import React from 'react';

export const EditQuantityIcon = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6806 0.698951C12.7851 -0.196525 11.3333 -0.196525 10.4379 0.698951L1.34735 9.78946C1.28505 9.85176 1.24007 9.92898 1.21659 10.0138L0.0211571 14.3295C-0.0280056 14.5065 0.0219532 14.696 0.151727 14.8259C0.281699 14.9557 0.471184 15.0057 0.64813 14.9567L4.96389 13.7611C5.04868 13.7376 5.12591 13.6926 5.18821 13.6303L14.2785 4.53961C15.1726 3.64353 15.1726 2.19294 14.2785 1.29686L13.6806 0.698951ZM2.45759 10.1207L9.89747 2.68058L12.2969 5.08L4.85681 12.5201L2.45759 10.1207ZM1.97831 11.0824L3.89525 12.9996L1.24365 13.7342L1.97831 11.0824ZM13.558 3.81909L13.0176 4.35948L10.618 1.95986L11.1586 1.41947C11.656 0.922073 12.4625 0.922073 12.9599 1.41947L13.558 2.01738C14.0546 2.51538 14.0546 3.32129 13.558 3.81909Z"
        fill="#00853e"
      />
    </svg>
  );
};
