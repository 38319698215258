import { Grid, withStyles, IconButton, Select, MenuItem } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import setMonth from 'date-fns/setMonth';
import getMonth from 'date-fns/getMonth';
import setYear from 'date-fns/setYear';
import getYear from 'date-fns/getYear';
import { useSelector } from 'react-redux';
import { selectLanguage } from 'ducks/application/selectors';

interface HeaderProps {
  date: Date;
  setDate: (date: Date) => void;
  nextDisabled: boolean;
  prevDisabled: boolean;
  onClickNext: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClickPrevious: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const StyledGrid = withStyles({
  root: {
    padding: 5,
  },
})(Grid);

const StyledIconButton = withStyles({
  root: {
    padding: 10,
    '&:hover': {
      background: 'none',
    },
  },
})(IconButton);

const StyledSelect = styled(Select)`
  font-family: Open Sans;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.black};

  & .MuiSelect-select:focus {
    background-color: ${({ theme: { colors } }) => colors.white};
  }
`;

const EN_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

const FR_MONTHS = [
  'Janv',
  'Févr',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juil',
  'Août',
  'Sept',
  'Oct',
  'Nov',
  'Déc',
];

const generateYears = (relativeTo: Date, count: number) => {
  const half = Math.floor(count / 2);
  return Array(count)
    .fill(0)
    .map((y, i) => relativeTo.getFullYear() - half + i);
};

const Header: React.FC<HeaderProps> = ({
  date,
  setDate,
  nextDisabled,
  prevDisabled,
  onClickNext,
  onClickPrevious,
}: HeaderProps) => {
  const language = useSelector(selectLanguage);

  const handleMonthChange = (month: string) => {
    setDate(setMonth(date, parseInt(month, 10)));
  };

  const handleYearChange = (year: string) => {
    setDate(setYear(date, parseInt(year, 10)));
  };

  return (
    <Grid container justify="space-between" alignItems="center">
      <StyledGrid item>
        <StyledIconButton disabled={prevDisabled} onClick={onClickPrevious}>
          <ChevronLeft color={prevDisabled ? 'disabled' : 'action'} />
        </StyledIconButton>
      </StyledGrid>
      <Grid item>
        <StyledSelect
          value={getMonth(date)}
          onChange={e => handleMonthChange(e.target.value as string)}
          MenuProps={{ disablePortal: true }}
          disableUnderline
        >
          {language !== 'fr_CA'
            ? EN_MONTHS.map((month, idx) => (
              <MenuItem key={month} value={idx}>
                {month}
              </MenuItem>
            ))
            : FR_MONTHS.map((month, idx) => (
              <MenuItem key={month} value={idx}>
                {month}
              </MenuItem>
            ))}
        </StyledSelect>
      </Grid>

      <Grid item>
        <StyledSelect
          value={getYear(date)}
          onChange={e => handleYearChange(e.target.value as string)}
          MenuProps={{ disablePortal: true }}
          disableUnderline
        >
          {generateYears(date, 30).map(year => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </StyledSelect>
      </Grid>
      <StyledGrid item>
        <StyledIconButton disabled={nextDisabled} onClick={onClickNext}>
          <ChevronRight color={nextDisabled ? 'disabled' : 'action'} />
        </StyledIconButton>
      </StyledGrid>
    </Grid>
  );
};

export default Header;
