import addMonths from 'date-fns/addMonths';
import endOfToday from 'date-fns/endOfToday';
import endOfYesterday from 'date-fns/endOfYesterday';
import startOfToday from 'date-fns/startOfToday';
import startOfYesterday from 'date-fns/startOfYesterday';
import { TFunction } from 'i18next';

import { DefinedRange } from 'pages/General/Profile/components/OrdersAndQuotes/DateRangePicker/types';

const getDefaultRanges = (date: Date, t: TFunction): DefinedRange[] => [
  {
    label: t('orders_and_quotes.today'),
    startDate: startOfToday(),
    endDate: endOfToday(),
  },
  {
    label: t('orders_and_quotes.yesterday'),
    startDate: startOfYesterday(),
    endDate: endOfYesterday(),
  },
  {
    label: t('orders_and_quotes.last_month'),
    startDate: addMonths(date, -1),
    endDate: date,
  },
  {
    label: t('orders_and_quotes.last_x_months', {
      numberOfMonths: 3,
    }),
    startDate: addMonths(date, -3),
    endDate: date,
  },
  {
    label: t('orders_and_quotes.last_x_months', {
      numberOfMonths: 6,
    }),
    startDate: addMonths(date, -6),
    endDate: date,
  },
  {
    label: t('orders_and_quotes.last_x_months', {
      numberOfMonths: 8,
    }),
    startDate: addMonths(date, -8),
    endDate: date,
  },
  {
    label: t('orders_and_quotes.last_x_months', {
      numberOfMonths: 12,
    }),
    startDate: addMonths(date, -12),
    endDate: date,
  },
];

export const defaultRanges = (t: TFunction) => getDefaultRanges(new Date(), t);
