import React, {useCallback, useRef, useState} from 'react';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';

import { ListViewMinus, ListViewPlus } from 'assets/svg/Product/Products';
import { requestChangeProductQty, updateItemInputValues } from 'ducks/cart/actions';

import TagManager from 'react-gtm-module';

import {
  selectAddressId,
  selectErpCustomerAddressCode,
} from 'ducks/application/selectors';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import {useGA4CartEvent} from 'GA/CartGa4/cartGA4';
import {
  InputButtonsWrapper,
  InputWithLabelWrapper,
  StyledInputWrapper,
  IncrementButton,
  IncrementButtonLabel,
  StyledTextField,
  // PromotionText,
} from './styles';

interface IProductInputs {
  cartItem?: any;
  isSecondStep?: boolean;
}

const CartProductInputWithoutPrice: React.FC<IProductInputs> = ({
  cartItem,
  isSecondStep,
}: IProductInputs) => {
  const dispatch = useDispatch();
  const {handleFireGA4RemoveFromCartSubTract, handleFireGA4AddToCartPlus} = useGA4CartEvent();
  const timeClickGtm = useRef(0);
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const [quantity, setQuantity] = useState(cartItem.quantity);
  const topRef = useRef();
  const buttonTopRef = useRef();
  const currentInputRef = useRef(cartItem.quantity)

  const handleGtmEventChangeUOM = () => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'checkout_change_UOM',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
    timeClickGtm.current = Date.now() / 1000;
  };

  const delayedUpdateCartItemQty = useCallback(
    debounce((newQty) => {
      dispatch(
        requestChangeProductQty({
          cartItemId: cartItem.id,
          qty: newQty,
        })
      );
      handleGtmEventChangeUOM();
      if (newQty > currentInputRef.current) {
        if (newQty - currentInputRef.current > 0) {
          handleFireGA4AddToCartPlus(cartItem, newQty - currentInputRef.current);
        }
      } else if (currentInputRef.current - newQty > 0) {
        handleFireGA4RemoveFromCartSubTract(cartItem, currentInputRef.current - newQty);
      }
      currentInputRef.current = newQty;
    }, 1000),
    [cartItem]
  );

  const handleIncrement = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);

    delayedUpdateCartItemQty(newQuantity);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      delayedUpdateCartItemQty(newQuantity);
    } else {
      setQuantity(1);
      delayedUpdateCartItemQty(1);
    }
  };

  const handleTopFieldChange = e => {
    let newTopValue1: number;

    if (e.target.value === '') {
      newTopValue1 = 0;
    } else {
      newTopValue1 = parseInt(e.target.value, 10);
    }

    setQuantity(newTopValue1);
    

    if (newTopValue1 >= 0) {
      dispatch(
        updateItemInputValues({
          cartItemId: cartItem.id,
          newTopValue: newTopValue1,
          newBottomValue: 0,
        })
      );
      delayedUpdateCartItemQty(newTopValue1);
    }

    if (timeClickGtm.current === 0) {
      handleGtmEventChangeUOM();
    } else if (timeClickGtm.current > 0 && Date.now() / 1000 - timeClickGtm.current > 10) {
      handleGtmEventChangeUOM();
    }
  };

  return cartItem ? (
    <>
      <InputButtonsWrapper>
        <InputWithLabelWrapper>
          <StyledInputWrapper>
            <IncrementButton
              ref={buttonTopRef}
              disabled={quantity === 0 || isSecondStep}
              onClick={handleDecrement}
            >
              <ListViewMinus />
            </IncrementButton>
            <StyledTextField
              type="tel"
              size="small"
              InputProps={{ disableUnderline: true }}
              onChange={e => handleTopFieldChange(e)}
              value={quantity}
              disabled
            />
            <IncrementButton disabled={isSecondStep} onClick={handleIncrement}>
              <ListViewPlus />
            </IncrementButton>
          </StyledInputWrapper>

          <IncrementButtonLabel ref={topRef}>{cartItem.variant.units.perName}</IncrementButtonLabel>
        </InputWithLabelWrapper>
      </InputButtonsWrapper>
    </>
  ) : null;
};

export default CartProductInputWithoutPrice;
