import React from 'react';
import styled from 'styled-components/macro';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  select: {
    '&': {
      marginTop: '10px !important',
      width: '100%',
    },
    '& ul': {
      width: '100%',
    },
    '& li': {
      fontSize: '14px',
      fontFamily: 'Open Sans',
      lineHeight: '21px',
      letterSpacing: '0.01em',
    },
    '@media(min-width: 1023px)': {
      '&': {
        width: 'auto',
      },
    },
  },
});

const Wrapper = styled.div`
  padding: 4px 8px 5px 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  height: 40px;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 1023px) {
    max-width: 100%;
  }
`;

const StyledSelect = styled(({ isPlaceholder: boolean, ...rest }) => <Select {...rest} />)`
  .MuiSelect-select {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 24px 0 0;
    width: 100%;
    color: ${({ theme: { colors }, isPlaceholder }) => (isPlaceholder ? colors.grey : '')};
    div {
      display: none;
    }

    &:focus {
      background: none;
    }
  }
`;

const StyledFormControl = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  .MuiInput-root {
    width: 100%;
  }
`;

const StyledMenuItem = styled(({ visible, ...rest }) => <MenuItem {...rest} />)`
  display: ${props => (props.visible ? 'flex' : 'none')};
  padding: 0 17px 0 12px;
`;

const StyledParagraph = styled.p`
  font-family: Open Sans;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
`;

const AttributeWrapperV2 = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px 12px;
  margin-bottom: 24px;
  @media (max-width: 1023px) {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
  }
  .item-attribute {
    @media (max-width: 1023px) {
      display: inline-flex;
      vertical-align: top;
      white-space: initial;
      margin-right: 12px;
    }
  }
`;

const AttributeOption = styled.button`
  display: block;
  position: relative;
  overflow: hidden;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.2px;
  font-style: normal;
  font-weight: normal;
  border-radius: 4px;
  border: 1px solid #c2cad6;
  background: #fff;
  white-space: nowrap;
  height: 44px;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.4s ease;
  &:hover {
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
    border: 2px solid #bfbfbf;
  }
  &.active {
    border: 2px solid #007a33;
    color: #007a33;
    font-weight: 600;
  }
  &.warning {
    border: 2px solid rgb(255, 220, 188);
  }
  &.disabled {
    cursor: cursor;
    color: #99a1b1;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: transparent
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width: 100%25; height: 100%25;'%3E%3Cline x1='0' y1='100%25' x2='100%25' y2='0' stroke='%23C2CAD6' stroke-width='1' /%3E%3C/svg%3E")
        no-repeat left top;
      background-size: 100% 100%;
    }
  }
`;

const AttributeSpecifyWrapperScroll = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 24px;
  [class*="AttributeSpecifyWrapperScrollRow-"] {
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
`
const AttributeSpecifyWrapperScrollRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  [class*="AttributeSpecifyItem-"] {
    margin-right: 12px;
    &:last-child {
      margin-right: 0 !important;
    }
  }
`

const AttributeSpecifyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0;
  overflow: hidden;
  margin-left: -6px;
  margin-right: -6px;
  margin-bottom: 24px;
  @media (max-width: 1023px) {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    margin: 0;
  }
  [class*="AttributeSpecifyItem-"] {
    padding-left: 6px;
    padding-right: 6px;
    margin-bottom: 10px;
    @media (max-width: 1023px) {
      display: inline-flex;
      vertical-align: top;
      white-space: initial;
      padding: 0;
      margin-right: 12px;
    }
  }
  [class*="AttributeSpecifyItemInner-"] {
    width: 129px;
  }
`;

const AttributeSpecifyItem = styled.div`
  border-radius: 0;
  border: 0;
  outline: 0;
  background: none;
  padding: 0;
`;

const AttributeSpecifyItemInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 129px;
  height: 188px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #c2cad6;
  background: #fff;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  * {
    @media only screen and (max-width: 1023px) {
      white-space: initial;
    }
  }
  &.active {
    border: 2px solid #007a33;
  }
  &.warning {
    border: 2px solid rgb(255, 220, 188);
  }
  &.disabled {
    cursor: cursor;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: transparent
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width: 100%25; height: 100%25;'%3E%3Cline x1='0' y1='100%25' x2='100%25' y2='0' stroke='%23C2CAD6' stroke-width='1' /%3E%3C/svg%3E")
        no-repeat left top;
      background-size: 100% 100%;
    }
    img {
      opacity: 0.5;
    }
    .txt-title {
      flex: 0 0 auto;
      color: #99a1b1;
      border-bottom: 1px solid #e7eaef;
      margin-bottom: 0;
    }
    .txt-price {
      border: 1px solid #cdcdcd;
      background: #f2f2f2;
      .lg {
        color: #99a1b1;
      }
      .md {
        color: #99a1b1;
      }
    }
    .txt-on-stock {
      color: #99a1b1;
    }
    .txt-special-availability {
      .md {
        color: #99a1b1;
      }
      .lg {
        color: #99a1b1;
      }
    }
    .txt-waiting-on-stock {
      color: #99a1b1;
    }
  }
  .img-default-product {
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  .txt-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    color: #007a33;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.1px;
    border-bottom: 1px solid #c2cad6;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
  .txt-price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 88px;
    min-height: 52px;
    border-radius: 4px;
    border: 1px solid #4eb86c;
    background: #edf8f2;
    padding: 4px 7px;
    .lg {
      color: #007a33;
      font-family: Open Sans;
      font-style: normal;
      font-size: 12px;
      font-weight: 600;
      line-height: 24px; /* 160% */
      letter-spacing: 0.1px;
      white-space: initial;
      word-break: break-all;
    }
    .md {
      color: #00133c;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 20px; /* 166.667% */
      letter-spacing: 0.2px;
      white-space: initial;
      word-break: break-all;
    }
  }
  .txt-on-stock {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px; /* 160% */
    letter-spacing: 0.2px;
    color: #007a33;
    padding: 0 5px;
    margin-top: 8px;
  }
  .txt-special-availability {
    margin-top: 8px;
    .md {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #f5001d;
      text-align: center;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.2px;
      padding: 0 5px;
      margin-bottom: 4px;
    }
    .lg {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #f5001d;
      text-align: center;
      font-family: Open Sans;
      font-style: normal;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      padding: 0 5px;
    }
  }
  .txt-waiting-on-stock {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #007aff;
    text-align: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px; /* 160% */
    letter-spacing: 0.2px;
    padding: 0 5px;
    margin-top: 8px;
  }
`;

export {
  Wrapper,
  StyledSelect,
  StyledFormControl,
  StyledMenuItem,
  StyledParagraph,
  useStyles,
  AttributeWrapperV2,
  AttributeOption,
  AttributeSpecifyWrapperScroll,
  AttributeSpecifyWrapperScrollRow,
  AttributeSpecifyWrapper,
  AttributeSpecifyItem,
  AttributeSpecifyItemInner,
};
