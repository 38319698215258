import { createSelector } from 'reselect';
import { RootState } from 'store';

const selectFAQState = (state: RootState) => state.FAQ;

export const selectFAQ = createSelector(selectFAQState, faqState => faqState.FAQ);

export const selectFAQLoading = createSelector(selectFAQState, faqState => faqState.loading);

export const selectIsFetchFAQ = createSelector(selectFAQState, faqState => faqState.isFetchFAQ);
