import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// Selectors
import { selectStorePhoneNumber } from 'ducks/user/selectors';

// Actions
import { fetchStorePhoneNumber } from 'ducks/user/actions';
// import useMediaQuery from '@material-ui/core/useMediaQuery';

const StyledA = styled.a`
  text-decoration: none;
  color: white;
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  text-align: right;
  letter-spacing: 0.05em;

  @media (max-width: 737px) {
    font-size: 16px;
    line-height: 16px;
  }
`;

const PhoneNumber: React.FC = () => {
  const phoneNumber = useSelector(selectStorePhoneNumber);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!phoneNumber) {
      dispatch(fetchStorePhoneNumber());
    }
  }, [dispatch, phoneNumber]);

  // const isMobile = useMediaQuery('(max-width:1023px)');

  return (
    <>
      {/* <StyledA href={isMobile && `tel:${phoneNumber}`}>{phoneNumber}</StyledA> */}
      <StyledA href={`tel:${phoneNumber}`}>{phoneNumber}</StyledA>
    </>
  );
};

export default PhoneNumber;
