import React from 'react';
import { useTranslation } from 'react-i18next';

import { Separator } from 'assets/svg/BreadCrumpSeparator';
import { StyledBreadCrumbsWrapper, StyledLink, StyledCurrentPageName } from './styles';

interface BreadCrumbsProps {
  currentPageName: string;
  previousPages?: Array<PreviousPage>;
  separator?: string;
}

export interface PreviousPage {
  pagename: string;
  url: string;
  state?: object;
}

function defineSeparator(separator: string | undefined) {
  if (separator !== undefined) {
    return separator;
  }
  return <Separator />;
}

export const BreadCrumbs: React.FC<BreadCrumbsProps> = ({
  currentPageName,
  previousPages,
  separator,
}: BreadCrumbsProps) => {
  const { t } = useTranslation();

  let pagesList: Array<PreviousPage> = [{ pagename: t('breadcrumbs.home'), url: '/categories' }];

  const usedSeparator = defineSeparator(separator);

  if (previousPages !== undefined) {
    pagesList = pagesList.concat(previousPages);
  }

  return (
    <StyledBreadCrumbsWrapper separator={usedSeparator} aria-label="breadcrumb">
      {pagesList.map(pageProps => (
        <StyledLink
          to={{
            pathname: pageProps.url,
          }}
          key={pageProps.pagename}
        >
          {pageProps.pagename}
        </StyledLink>
      ))}
      <StyledCurrentPageName>{currentPageName}</StyledCurrentPageName>
    </StyledBreadCrumbsWrapper>
  );
};
