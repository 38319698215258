import { useState } from 'react';

import { requestPricingClickGAEvent } from 'GA/ProductVariantGA/ProductVariantGA';
import { ProductVariantClass } from 'models/classes/productVariantClass';
import TagManager from 'react-gtm-module';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import {useDispatch, useSelector} from 'react-redux';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { selectAddressId, selectErpCustomerAddressCode } from 'ducks/application/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import {setClickedProductRequestPricing} from 'ducks/product/actions';

export const useRequestPricing = (
  productVariant: ProductVariantClass
) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const [isRequestPricing, setIsRequestPricing] = useState(false);

  const handleRequestPricingClick = () => {
    requestPricingClickGAEvent(productVariant.name);
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_price_request',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
    dispatch(
      setClickedProductRequestPricing(productVariant)
    );
    setIsRequestPricing(true);
  };

  return {
    isRequestPricing,
    setIsRequestPricing,
    handleRequestPricingClick,
  };
};
