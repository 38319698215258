import styled from 'styled-components/macro';

const SecondStepInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const InputValue = styled.div`
  padding: 0 5px;
  width: 142px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
`;

const InputUom = styled.div`
  margin-left: 8px;
`;

const PromotionText = styled.span`
  text-decoration: underline;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  cursor: pointer;
	font-weight: 600;
	font-size: 16px;
  &:hover {
    font-size: 18px;
    font-weight: 900;
  }
`;

const BranchName = styled.p`
  color: #337D58;
  font-size: 14px;
  font-weight: 600;
`;

export { SecondStepInputWrapper, InputValue, InputUom, PromotionText, BranchName };
