import React, { useState, useEffect, useMemo } from 'react';
import {useTranslation} from 'react-i18next';
import {useChangeStep} from 'pages/Checkout/hooks';
import {ProductVariantClass} from 'models/classes/productVariantClass';
import {
  priceTypeSelector,
  selectAddressId,
  selectErpCustomerAddressCode, selectIsHidePrice,
} from 'ducks/application/selectors';
import {IPrice} from 'models/interfaces/productVariant';
import {ProductInputFields} from 'components/Product/Inputs/ProductInputFields';
import {useDispatch, useSelector} from 'react-redux';
import {numberFormat as f} from 'utils/products/product';
import {WhiteTruck} from 'assets/svg/Product/Products';
import {useAddProductToCart} from 'hooks/useAddProductToCart';
import {PRODUCT_PRICING_METHOD_BLENDED} from 'ducks/productVariant/types';
import last from 'lodash/last';
import {addDotToPrice} from 'utils/common/common';
import TagManager from 'react-gtm-module';
import {getUserType} from 'utils/profile/profileUtilsHelpers';
import {selectUserData} from 'ducks/user/selectors';
import isNaN from 'lodash/isNaN';
import {clearGtmEcommerce} from 'utils/clearGtmEcommerce';
import {getDefaultProductName} from 'utils/products/productUtilsHelpers';
import {selectCategories} from 'ducks/category/selectors';
import {getDefaultCategoryName} from 'utils/categories/categories';

import {setAddToTruckItem} from 'ducks/cart/actions';
import { createUserActionLog } from 'api/UserActionLog';
import { Box, Typography } from '@material-ui/core';
import {
  ProductAttributeWrapper,
  InfoSectionTitle,
  NextTierPriceWrapper,
  NextTierPriceInner,
  NextTierPriceText,
  TotalWrapper,
  TotalText,
  ButtonTitle,
  StyledButton, InquireNowButton, InquireNowButtonTitle,
} from '../styles';


interface IPriceCalculatorSection {
  productVariant: ProductVariantClass;
  currentPrice: IPrice | null;
  setCurrentPrice: React.Dispatch<React.SetStateAction<IPrice>>;
  addToTruckFromBranchId: number|null;
  showAdditionalFreight: boolean;
  handleOpenEmailPopup: CallableFunction;
  inputValues: {
    topInputValue: number;
    bottomInputValue: number;
  };
  setInputValues: React.Dispatch<React.SetStateAction<{
    topInputValue: number;
    bottomInputValue: number;
  }>>;
  minQuantity: number;
}

const PriceCalculatorSection = React.memo(
  ({
    productVariant,
    currentPrice,
    setCurrentPrice,
    addToTruckFromBranchId = null,
    showAdditionalFreight = false,
    handleOpenEmailPopup,
    inputValues,
    setInputValues,
    minQuantity,
  }: IPriceCalculatorSection) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const isHidePrice = useSelector(selectIsHidePrice);
    const priceType = useSelector(priceTypeSelector);
    const deliveryType = useSelector(priceTypeSelector);
    const user = useSelector(selectUserData);
    const categories = useSelector(selectCategories);
    const selectedAddressId = useSelector(selectAddressId);
    const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
    const changeStep = useChangeStep();
    const [totalPrice, setTotalPrice] = useState(0);
    const [isClickOnPromotionPrice, setIsClickOnPromotionPrice] = useState(false);

    const productPrices = productVariant.prices
      .filter((price, index, self) => index === self.findIndex(thing => thing.qty === price.qty))
      .sort((a, b) => a.qty - b.qty);

    useEffect(() => {
      setInputValues({
        topInputValue: minQuantity,
        bottomInputValue: productVariant.units.uom2Conversion
          ? minQuantity / productVariant.units.uom2Conversion
          : null,
      });
    }, [minQuantity, productVariant]);

    const [messageTop, setMessageTop] = useState<string | null>(null);
    const [messageBottom, setMessageBottom] = useState<string | null>(null);

    const addProduct = useAddProductToCart();
    const productMaxQuantity = productVariant.prices[productVariant.prices.length - 1];

    useEffect(() => {
      if (productMaxQuantity && productVariant.pricingMethod === PRODUCT_PRICING_METHOD_BLENDED) {
        const topQuantity = parseInt(String(productMaxQuantity?.qty || productPrices[0]?.qty), 10);
        if (inputValues.topInputValue >= topQuantity) {
          const topPrice = parseFloat(productMaxQuantity[priceType]);
          const productOutGroup = inputValues.topInputValue % topQuantity;
          const productInGroup = inputValues.topInputValue - productOutGroup;
          setTotalPrice(parseFloat(f((productPrices[0][priceType] * productOutGroup) + (topPrice * productInGroup))));
        }
      } else {
        setTotalPrice(currentPrice && currentPrice[priceType] * inputValues.topInputValue);
      }
    }, [inputValues.topInputValue, priceType, productMaxQuantity, productPrices, productVariant.pricingMethod]);

    const getNextTierPriceIndex = (prices: IPrice[]) => {
      const nextIndex = prices.findIndex(price => price.qty === currentPrice.qty) + 1;
      return prices[nextIndex] ? nextIndex : null;
    };

    const handleInputChange = ({
      topInputValue,
      bottomInputValue,
    }: {
      topInputValue: number;
      bottomInputValue: number;
    }) => {
      const [price] = productPrices.filter(item => topInputValue >= item.qty).slice(-1);
      if (price && currentPrice.qty !== price.qty) {
        setCurrentPrice(price);
      }

      setInputValues({topInputValue, bottomInputValue});
    };

    const handlePurchaseClick = () => {
      let messageTopTemp: string = null;
      let messageBottomTemp: string = null;
      const minimalQuantityValue = `${minQuantity} ${productVariant.units.perName}`;
      const orderOnly = `${productVariant.units.perName} (1 ${
        productVariant.units.perName
      } = ${Number((minQuantity / productVariant.units.uom2Conversion).toFixed(2))} ${
        productVariant.units.uom2Name
      }) `;
      const unitsPerName = `${productVariant.units.perName}`;

      // min order uom quantity
      if (!messageTopTemp && inputValues.topInputValue < minQuantity) {
        // top uom value
        messageTopTemp = t('price_calculator.min_quantity', {minimalQuantityValue});
      }

      if ((!messageTopTemp && inputValues.topInputValue % minQuantity) !== 0) {
        messageTopTemp = t('price_calculator.min_quantity', {minimalQuantityValue});
      }

      if (inputValues.bottomInputValue) {
        const productCoeff = Number(f(minQuantity / productVariant.units.uom2Conversion));
        const remaining = Number(
          (Number(inputValues.bottomInputValue.toFixed(2)) % productCoeff).toFixed(2)
        );
        // min order uom2 quantity
        if (remaining !== 0 && remaining !== productCoeff) {
          // bottom uom value
          messageBottomTemp = t('price_calculator.packs_provide', {unitsPerName, orderOnly});
        }
      }

      setMessageTop(messageTopTemp);
      setMessageBottom(messageBottomTemp);

      if (!messageTopTemp && !messageBottomTemp) {
        // add to cart
        addProduct(productVariant.id, inputValues.topInputValue, deliveryType, addToTruckFromBranchId, productVariant.category.id);
        changeStep(1);
        TagManager.dataLayer({
          dataLayer: {
            event: 'add_to_cart_product',
            user_type: getUserType(user.email),
            branch_id: addToTruckFromBranchId,
            customer_code: user?.customer?.erpCustomerCode,
            address_code: erpCustomerAddressCode,
            address_id: selectedAddressId,
          }
        });
        let price;
        if (productPrices.length > 1) {
          if (inputValues.topInputValue >= productPrices[1].qty) {
            price = productPrices[1][deliveryType];
          } else {
            price = productPrices[0][deliveryType];
          }
        } else {
          price = productPrices[0][deliveryType];
        }
        clearGtmEcommerce();
        const {itemCategoryName, itemCategory2Name} = getDefaultCategoryName(productVariant.category.id, categories);
        TagManager.dataLayer({
          dataLayer: {
            event: 'add_to_cart',
            ecommerce: {
              items: [
                {
                  item_id: productVariant.erpSku,
                  item_name: getDefaultProductName(productVariant),
                  item_category: itemCategoryName,
                  item_category2: itemCategory2Name,
                  quantity: inputValues.topInputValue,
                  price,
                }
              ]
            }
          }
        });
        dispatch(setAddToTruckItem(productVariant, inputValues.topInputValue))
      }
    };

    let nextTierPrice = null;
    const nextTierPriceIndex = getNextTierPriceIndex(productPrices);

    if (nextTierPriceIndex) {
      nextTierPrice = productPrices[nextTierPriceIndex];
    }

    const handleClickOnPromotionQuantity = () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'product_click_upsell',
          user_type: getUserType(user.email),
          branch_id: addToTruckFromBranchId,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        }
      });

      const isMultiInput = productVariant.units.uom2Conversion && productVariant.units.uom2Name;
      let bottomInputQuantity;
      if (isMultiInput) {
        const topInputValue = nextTierPrice.qty;
        const newTopValue = topInputValue - (topInputValue % minQuantity);
        const productCoeff = parseFloat(f(minQuantity / productVariant.units.uom2Conversion));
        bottomInputQuantity = parseFloat(f((newTopValue * productCoeff) / minQuantity));
      }
      handleInputChange({...inputValues, topInputValue: nextTierPrice.qty, bottomInputValue: bottomInputQuantity})
      setIsClickOnPromotionPrice(true);
    };

    useEffect(() => {
      if (productPrices.length > 1) {
        const promotionPrice = last(productPrices);
        if (inputValues.topInputValue < promotionPrice.qty) {
          setIsClickOnPromotionPrice(false);
        }
      }
    }, [inputValues.topInputValue, productPrices]);

    const handleClickInquire = async () => {
      await createUserActionLog('Product Detail Page Inquire Now', 'Inventory Request');
      handleOpenEmailPopup(productVariant);
    }

    const showProductPricing = () => {
      if (productPrices.length > 1 && inputValues.topInputValue >= productPrices[1].qty) {
        const totalOldPrice = inputValues.topInputValue * productPrices[0][priceType];
        const totalNewPrice = inputValues.topInputValue * productPrices[1][priceType];
        const percent = Math.ceil((totalOldPrice - totalNewPrice) / totalOldPrice * 100);
        if (totalOldPrice > totalNewPrice) {
          return (
            <table className="table-total">
              <tr>
                <th>{t('product_detail.total').toString()}</th>
                <td>
                  <div className="old-price">${addDotToPrice(f(totalOldPrice))}</div>
                  <div className="price">${addDotToPrice(f(totalNewPrice))}</div>
                  {
                    // eslint-disable-next-line react/no-danger
                    percent > 0 &&
                    <div dangerouslySetInnerHTML={{__html: t('product_detail.saving_percent', {percent})}}/>
                  }
                </td>
              </tr>
              <tr>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th style={{paddingRight: 0}} />
                <td>
                  {showAdditionalFreight && (
                    <p style={{marginBottom: 10}}>{t('checkout.additional_may_charge').toString()}</p>
                  )}
                </td>
              </tr>
            </table>
          );
        }
      }
      return (
        <>
          <TotalText>
            <span className="text-total">{t('product_detail.total').toString()}</span>
            <span className="text-price">${addDotToPrice(f(!isNaN(totalPrice) ? totalPrice : 0))}</span>
          </TotalText>

          {showAdditionalFreight && (
            <p style={{marginBottom: 10}}>{t('checkout.additional_may_charge').toString()}</p>
          )}
        </>
      )
    };

    const productSysWeight = useMemo(() => {
      const sysWeightOption = productVariant.sysWeight;
      return (
        <Box style={{ display: 'flex', flexDirection: 'row', marginTop: '8px', gap: '3px', alignItems: 'center' }}>
          <Typography style={{ fontSize: '14px', lineHeight: '24px' }}>
            {t('product_detail.weight').toString()}:
          </Typography>
          <Typography style={{ fontSize: '16px', fontWeight: 700, color: sysWeightOption ? '#007A33' : '#FF0000', lineHeight: '28px' }}>
            {sysWeightOption ? (
              `${parseFloat(f(sysWeightOption * inputValues.topInputValue))} ${t('product_detail.lbs').toString()}`) : t('product_detail.sys_weight_not_available').toString()}
          </Typography>
        </Box>
      );
    }, [inputValues.topInputValue, productVariant.sysWeight, t]);

    return (
      <>
        <ProductAttributeWrapper>
          <InfoSectionTitle>{t('product_detail.quantity').toString()}: </InfoSectionTitle>
          {currentPrice && (
            <>
              {(nextTierPrice && nextTierPrice[priceType] && !isHidePrice && !isClickOnPromotionPrice && currentPrice[priceType] > nextTierPrice[priceType]) ? (
                <NextTierPriceWrapper>
                  <NextTierPriceInner onClick={handleClickOnPromotionQuantity}>
                    <NextTierPriceText>
                      {t('product_detail.buy')} {nextTierPrice.qty} {t('product_detail.for')} ${nextTierPrice[priceType]}/{productVariant.units.perName}
                    </NextTierPriceText>
                  </NextTierPriceInner>
                </NextTierPriceWrapper>
              ) : null}

              <ProductInputFields
                product={{
                  minQuantity,
                  uom2Conversion: productVariant.units.uom2Conversion,
                  perName: productVariant.units.perName,
                  uom2Name: productVariant.units.uom2Name,
                }}
                topInputValue={inputValues.topInputValue}
                bottomInputValue={inputValues.bottomInputValue}
                onInputChange={handleInputChange}
                validationMessageTop={messageTop}
                validationMessageBottom={messageBottom}
              />
            </>
          )}

          <TotalWrapper>
            {!isHidePrice ? (
              showProductPricing()
            ) : null}

            <StyledButton onClick={handlePurchaseClick}>
              <WhiteTruck/>
              <ButtonTitle>&nbsp;{t('product_detail.add_to_truck').toString()}</ButtonTitle>
            </StyledButton>
            <InquireNowButton onClick={handleClickInquire}>
              <InquireNowButtonTitle>&nbsp;{t('product_detail.inquire_now').toString()}</InquireNowButtonTitle>
            </InquireNowButton>
            {productSysWeight}
          </TotalWrapper>
        </ProductAttributeWrapper>

      </>
    );
  }
);

export {PriceCalculatorSection};
