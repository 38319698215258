import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { InputLabel, MenuItem, FormHelperText, useMediaQuery, makeStyles } from '@material-ui/core';
import { BreadCrumbs } from 'components/common/BreadCrumbs/BreadCrumbs';
import { ContactPhones } from 'components/common/ContactLinks/ContactPhones';
import { ContactEmails } from 'components/common/ContactLinks/ContactEmails';
import { ContactGeos } from 'components/common/ContactLinks/ContactGeos';
import { RepresentativeContactComponent } from 'pages/General/Profile';
import {
  menuIsOpenedSelector,
  selectAppError,
  selectAppLoading,
} from 'ducks/application/selectors';
import { sendContactUsEmail } from 'ducks/application/actions';
import CustomizedSnackbar from 'components/common/Snackbar/Snackbar';

import { useTranslation } from 'react-i18next';
import { selectBranch } from 'ducks/branch/selectors';
import {
  getSalesRepresentativeLoading, selectSalesRepresentative,
} from 'ducks/salesRepresentative/selectors';
import {
  MainContainer,
  PageTitle,
  ContentWrapper,
  FormsContainer,
  StyledTextField,
  StyledFromButton,
  StyledFormControl,
  VerticalLine,
  StyledContactsLinkContainer,
  SalesAndContactsWrapper,
  ContactInfo,
  TextSubheaderTypography,
  StyledSelect,
} from './styles';

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'name_min_length')
    .max(255, 'name_max_length')
    .trim()
    .required('name_required'),
  email: Yup.string()
    .email('invalid_mail')
    .max(255, 'email_max_length')
    .required('email_required'),
  phone: Yup.string()
    .max(50, 'phone_max_length')
    .matches(phoneRegExp, 'invalid_phone_format'),
  subject: Yup.string().required('subject_required'),
  comment: Yup.string()
    .min(10, 'message_min_length')
    .max(4095, 'message_max_length')
    .trim()
    .required('message_required'),
});

const useStyles = makeStyles({
  inputLabel: {
    fontFamily: 'Open Sans',
  },
});

const ContactUs: React.FC = () => {
  const isOpened = useSelector(menuIsOpenedSelector);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [clicked, setClicked] = useState(false);
  const error = useSelector(selectAppError);
  const loading = useSelector(selectAppLoading);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const classes = useStyles();

  useEffect(() => {
    if (!loading && clicked) {
      setOpenSnackbar(true);
    }
  }, [clicked, error, loading, setOpenSnackbar]);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const detectSendEmailStatus = () => (error && !loading ? 'Error' : 'Success');

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      subject: '',
      comment: '',
    },
    validationSchema,
    onSubmit: ({ name, email, phone, subject, comment }, { resetForm }) => {
      if (phone) {
        dispatch(
          sendContactUsEmail({
            name,
            email,
            phone,
            subject,
            comment,
          })
        );
        setClicked(true);
      } else {
        dispatch(
          sendContactUsEmail({
            name,
            email,
            subject,
            comment,
          })
        );
        setClicked(true);
      }
      resetForm();
    },
  });

  const branch = useSelector(selectBranch);

  const emails = useMemo(() => {
    const emailsDisplay = [];

    if (branch) {
      if (branch?.email && typeof branch?.email === 'string') {
        if (branch?.email.includes(',')) {
          branch?.email.split(',').forEach(em => {
            emailsDisplay.push(em);
          })
        } else {
          emailsDisplay.push(branch.email);
        }
      } else {
        emailsDisplay.push('taiganow@taigabuilding.com');
      }
    }

    return (<ContactEmails emails={emailsDisplay} />)
  }, [branch]);

  const phones = useMemo(() => {
    if (branch) {
      return (
        <ContactPhones
          phones={branch.phones.map(phone => ({
            text: `${phone.number} ${phone.type}`,
            phoneTo: phone.number.replace('.', ''),
            type: phone.type
          }))}
        />
      );
    }
    return null;
  }, [branch]);

  const address = useMemo(() => {
    if (branch) {
      return <ContactGeos geos={[{ geoAddress: branch.address }]} />;
    }
    return null;
  }, [branch]);

  const { t } = useTranslation();

  const salesRepresentative = useSelector(selectSalesRepresentative);
  const salesRepresentativeLoading = useSelector(getSalesRepresentativeLoading);

  return !isOpened ? (
    <>
      <CustomizedSnackbar
        open={openSnackbar}
        handleClose={handleSnackbarClose}
        status={detectSendEmailStatus()}
        errorMessage={t('contact_us.message_sent_error')}
        successMessage={t('contact_us.message_sent')}
      />
      <MainContainer>
        <BreadCrumbs currentPageName={t('contact_us.pagename')} />
        <PageTitle>{t('contact_us.title')}</PageTitle>
        <ContentWrapper>
          <form onSubmit={handleSubmit}>
            <FormsContainer>
              <StyledTextField
                fullWidth
                variant="outlined"
                label={t('contact_us.name')}
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={!!(errors.name && touched.name)}
                helperText={errors.name && touched.name ? t(`contact_us.${errors.name}`) : ''}
                size={isMobile ? 'small' : 'medium'}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                data-test-id="contact-us-name-input"
              />
              <StyledTextField
                fullWidth
                variant="outlined"
                label={t('contact_us.email')}
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={!!(errors.email && touched.email)}
                helperText={errors.email && touched.email ? t(`contact_us.${errors.email}`) : ''}
                size={isMobile ? 'small' : 'medium'}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                data-test-id="contact-us-email-input"
              />
              <StyledTextField
                fullWidth
                variant="outlined"
                label={t('contact_us.phone_number')}
                name="phone"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                error={!!(errors.phone && touched.phone)}
                helperText={errors.phone && touched.phone ? t(`contact_us.${errors.phone}`) : ''}
                size={isMobile ? 'small' : 'medium'}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                data-test-id="contact-us-phone-input"
              />
              <StyledFormControl variant="outlined">
                <InputLabel id="project-select-label">{t('contact_us.subject')}</InputLabel>
                <StyledSelect
                  labelId="project-select-label"
                  id="project-select"
                  name="subject"
                  value={values.subject}
                  label="Subject"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!(errors.subject && touched.subject)}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                  data-test-id="contact-us-subject-option"
                >
                  <MenuItem value="general inquires">{t('contact_us.general_inquires')}</MenuItem>
                  <MenuItem value="product catalog">{t('contact_us.product_catalog')}</MenuItem>
                  <MenuItem value="account information">
                    {t('contact_us.account_information')}
                  </MenuItem>
                  <MenuItem value="technical issues">{t('contact_us.technical_issues')}</MenuItem>
                </StyledSelect>
                <FormHelperText error focused>
                  {errors.subject && touched.subject ? t(`contact_us.${errors.subject}`) : null}
                </FormHelperText>
              </StyledFormControl>
              <StyledTextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                label={t('contact_us.what_on_your_mind')}
                name="comment"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.comment}
                error={!!(errors.comment && touched.comment)}
                helperText={
                  errors.comment && touched.comment ? t(`contact_us.${errors.comment}`) : ''
                }
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                data-test-id="contact-us-what-on-your-mind"
              />
              <StyledFromButton type="submit" disabled={!(dirty && isValid)} data-test-id="contact-us-send-button">
                {t('contact_us.send')}
              </StyledFromButton>
            </FormsContainer>
          </form>
          <VerticalLine />
          <SalesAndContactsWrapper>
            {
              !salesRepresentativeLoading && <RepresentativeContactComponent salesRepresentative={salesRepresentative} />
            }
            <ContactInfo>
              <TextSubheaderTypography>{t('contact_us.contact_info')}</TextSubheaderTypography>
              <StyledContactsLinkContainer>
                {address}
                {phones}
                {emails}
              </StyledContactsLinkContainer>
            </ContactInfo>
          </SalesAndContactsWrapper>
        </ContentWrapper>
      </MainContainer>
    </>
  ) : null;
};

export default ContactUs;
