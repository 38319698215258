import { createSelector } from 'reselect';
import { RootState } from 'store';

const selectSearchProductState = (state: RootState) => state.searchProducts;

export const selectProducts = createSelector(
  selectSearchProductState,
  productState => productState.products
);

export const selectIsLastPage = createSelector(
  selectSearchProductState,
  productState => productState.isLastPage
);

export const selectSearchFacets = createSelector(selectSearchProductState, productState =>
  productState.facets.filter(facet => !facet.configuration.isRange)
);

export const selectTotal = createSelector(
  selectSearchProductState,
  productState => productState.total
);

export const selectSearchLoading = createSelector(
  selectSearchProductState,
  productState => productState.loading
);

export const selectPaginationLoading = createSelector(
  selectSearchProductState,
  productState => productState.paginationLoading
);

export const selectSearchRangeFilters = createSelector(
  selectSearchProductState,
  productState => productState.rangeFilters
);

export const selectSearchMultiselectFacets = createSelector(
  selectSearchProductState,
  productState => productState.facets.filter(facet => facet.configuration.isRange)
);

export const selectSearchProductIdsList = createSelector(
  selectSearchProductState,
  productState => productState.searchProductIdsList
);
