import { all, put, takeLatest, call, debounce, throttle, select } from 'redux-saga/effects';
import axios from 'utils/axios';
import { storeError, storeErrors } from 'utils/errors';
import {
  CartActionTypes, IRequestCheckCartItemsNoPriceAction,
  RequestAddProductAction,
  RequestAddPromocodeAction,
  RequestCartSummaryAction,
  RequestChangeProductQtyAction,
  RequestDeleteProductAction,
  RequestDeletePromocodeAction,
} from 'ducks/cart/types';
import {
  setQtyUpdateStatus,
  storeCartFirstLoad,
  requestCartSummary as requestSummary, storeCartItemsNoPrice,
} from 'ducks/cart/actions';
import { getDefaultInputValues } from 'utils/cart/cart';
import { priceTypeSelector, selectAddressCode, selectAddressId } from 'ducks/application/selectors';
import get from 'lodash/get';
import { startLoader } from './actions';
import {selectCart} from './selectors';

const hidePriceType = 'hide';

function* requestAddProduct(requestAddProductAction: RequestAddProductAction) {
  const { payload } = requestAddProductAction;
  const address = yield select(selectAddressId);

  try {
    const addToCartReq = yield call(axios.post, '/api/auth/cart/add-to-cart', payload, {
      headers: {
        'X-Address-Id': address,
      },
    });
    yield put(storeCartFirstLoad(addToCartReq.data, getDefaultInputValues(addToCartReq.data.data)));
    yield put({
      type: CartActionTypes.ADD_PRODUCT_SUCCESS
    })
  } catch (error) {
    if (get(error.response, 'data.data.forceRefresh')) {
      window.location.reload();
    }
    yield storeErrors(CartActionTypes.ADD_PRODUCT_ERROR, [403, 404, 400], true);
  }
}

function* requestCartSummary(requestCartSummaryAction: RequestCartSummaryAction) {
  try {
    const { addressId } = requestCartSummaryAction.payload;
    const priceType = yield select(priceTypeSelector);
    const requestUrl =
			priceType === hidePriceType
			  ? '/api/auth/cart'
			  : `/api/auth/cart?pricing=${priceType}`;

    const summaryReq = yield call(axios.get, requestUrl, {
      headers: {
        'X-Address-Id': addressId.toString(),
      },
    });

    yield put(storeCartFirstLoad(summaryReq.data, getDefaultInputValues(summaryReq.data.data)));
  } catch (error) {
    if (get(error, 'response.data.data.forceRefresh') || get(error, 'response.data.data.cartDeleted')) {
      window.location.reload();
    }
    console.log('=================',error);
    yield storeErrors(CartActionTypes.GET_CART_SUMMARY_ERROR, [404, 400], error.response.data.error);
  }
}

function* requestChangeProductQty(requestChangeProductAction: RequestChangeProductQtyAction) {
  const { cartItemId, qty } = requestChangeProductAction.payload;
  const address = yield select(selectAddressId);
  const deliveryType = yield select(priceTypeSelector);

  try {
    const response = yield call(
      axios.patch,
      `/api/auth/cart/update-product-quantity/${cartItemId}?delivery_type=${deliveryType}`,
      { qty },
      {
        headers: {
          'X-Address-ID': address,
        },
      }
    );
    yield put(storeCartFirstLoad(response.data, getDefaultInputValues(response.data.data)));
    yield put(setQtyUpdateStatus());
  } catch (error) {
    yield put(requestSummary(address));
    yield storeError(CartActionTypes.CHANGE_PRODUCT_QTY_ERROR, [403, 404], error.response);
  }
}

function* requestDeleteProduct(requestDeleteProductAction: RequestDeleteProductAction) {
  const { cartItemId } = requestDeleteProductAction.payload;
  const address = yield select(selectAddressId);

  try {
    yield put(startLoader());
    const resp = yield call(axios.delete, `/api/auth/cart/remove/cart-item/${cartItemId}`, {
      headers: {
        'X-Address-Id': address,
      },
    });
    yield put(storeCartFirstLoad(resp.data, getDefaultInputValues(resp.data.data)));
  } catch (error) {
    yield storeErrors(CartActionTypes.DELETE_PRODUCT_ERROR, [404, 400], error.response);
  }
}

function* requestAddPromocode(requestAddPromocodeAction: RequestAddPromocodeAction) {
  const { id, promoCode } = requestAddPromocodeAction.payload;
  const address = yield select(selectAddressCode);

  try {
    const resp = yield call(
      axios.post,
      `/new-api/apply-promotion/${id}`,
      { promoCode },
      {
        headers: {
          'X-Address-Code': address,
        },
      },
    );
    yield put(storeCartFirstLoad(resp.data, getDefaultInputValues(resp.data.cart)));
  } catch (error) {
    yield storeError(CartActionTypes.PROMOCODE_ERROR, [404], error.response);
  }
}

function* requestDeletePromocode(requestDeletePromocodeAction: RequestDeletePromocodeAction) {
  const { id } = requestDeletePromocodeAction.payload;
  const address = yield select(selectAddressCode);

  try {
    const resp = yield call(axios.post, `/new-api/remove-promotion/${id}`, {
      headers: {
        'X-Address-Code': address,
      },
    });
    yield put(storeCartFirstLoad(resp.data, getDefaultInputValues(resp.data.cart)));
  } catch (error) {
    yield storeError(CartActionTypes.PROMOCODE_ERROR, [403], error.response);
  }
}

function* requestCheckCartItemsNoPrice(requestCheckCartItemsNoPriceAction: IRequestCheckCartItemsNoPriceAction) {
  const { cartId, deliveryType } = requestCheckCartItemsNoPriceAction.payload;
  const response = yield call(axios.post, `/api/auth/cart/validate-cart-item-price/${cartId}`, {}, {
    params: {
      deliveryType,
    }
  });
  if (response.data) {
    yield put(storeCartItemsNoPrice(response.data.data));
  }
}

function* removeCart() {
  const cart = yield select(selectCart);
  const address = yield select(selectAddressId);
  const response = yield call(axios.delete, `/api/auth/cart/remove-all-cart-item/${cart.id}`, {
    headers: {
      'X-Address-Id': address,
    }
  });
  yield put({type: CartActionTypes.REMOVE_CART_SUCCESS});
  yield put(storeCartFirstLoad(response.data, getDefaultInputValues(response.data.data)));
}

function* watchRequestAddProduct() {
  yield takeLatest(CartActionTypes.ADD_PRODUCT, requestAddProduct);
}

function* watchRequestChangeProductQty() {
  yield throttle(1000, CartActionTypes.CHANGE_PRODUCT_QTY, requestChangeProductQty);
}

function* watchRequestCartSummary() {
  yield debounce(1000, CartActionTypes.GET_CART_SUMMARY, requestCartSummary);
}

function* watchRequestDeleteProduct() {
  yield takeLatest(CartActionTypes.DELETE_PRODUCT, requestDeleteProduct);
}

function* watchRequestAddPromocode() {
  yield takeLatest(CartActionTypes.ADD_PROMOCODE, requestAddPromocode);
}

function* watchRequestDeletePromocode() {
  yield takeLatest(CartActionTypes.DELETE_PROMOCODE, requestDeletePromocode);
}

function* watchCheckCartItemsNoPrice() {
  yield takeLatest(CartActionTypes.CHECK_CART_ITEMS_NO_PRICE, requestCheckCartItemsNoPrice);
}

function* watchRemoveCart() {
  yield takeLatest(CartActionTypes.REMOVE_CART, removeCart);
}

export default function* cartSaga() {
  yield all([
    watchRequestAddProduct(),
    watchRequestCartSummary(),
    watchRequestChangeProductQty(),
    watchRequestDeleteProduct(),
    watchRequestAddPromocode(),
    watchRequestDeletePromocode(),
    watchCheckCartItemsNoPrice(),
    watchRemoveCart(),
  ]);
}
