import styled from 'styled-components/macro';

const ImageStyled = styled.img`
	width: 80%;
`;

const ButtonStyled = styled.button`
  color: ${({ theme: { colors } }) => colors.white};
  display: block;
  width: 50%;
  height: 50px;
  font-size: 16px;
	background: #02853e;
	border-radius: 4px;
  text-transform: capitalize;
	font-weight: 600;
	line-height: 18px;
`

const Remove = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: flex-end;
  line-height: 16px;
  margin-top: 16px;
  color: ${({ theme: { colors } }) => colors.grey};

  svg {
    margin-right: 5px;
  }

  @media (max-width: 1023px) {
    margin-top: 0px;
    padding: 8px;
    border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
    border-radius: 4px;

    &:hover {
      background-color: ${({ theme: { colors } }) => colors.whiteSmoke};
    }

    svg {
      margin-right: 0;
    }

    span {
      display: none;
    }
  }
`;

export {
  ImageStyled,
  ButtonStyled,
  Remove,
}
