import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { PopperPlacementType } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { ListViewMinus, ListViewPlus } from 'assets/svg/Product/Products';
import ProductInputPopper from 'components/Popper/ProductInputPopper';
import { useTranslation } from 'react-i18next';

import { filterPrices } from 'utils/products/productVariant';
import TagManager from 'react-gtm-module';
import get from 'lodash/get';
import size from 'lodash/size';
import {
  priceTypeSelector, selectAddressId, selectErpCustomerAddressCode,
} from 'ducks/application/selectors';
import { usePermission } from 'hooks/usePermission';
import {IQuickOrderItem} from 'ducks/quickOrder/types';
import {setDisableQuickOrderCheckout, updateQuickOrderItemQuantity} from 'ducks/quickOrder/actions';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import {
  InputButtonsWrapper,
  InputWithLabelWrapper,
  StyledInputWrapper,
  IncrementButton,
  IncrementButtonLabel,
  StyledTextField,
  PromotionText,
} from './styles';

interface IProductInputs {
  quickOrderItem: IQuickOrderItem;
  topInputValue: number;
  bottomInputValue?: number;
  setTopInputValue: React.Dispatch<React.SetStateAction<number>>;
  setBottomInputValue: React.Dispatch<React.SetStateAction<number>>;
  isDisabled?: boolean;
  popperPlacement: PopperPlacementType;
  onHasErrors?(hasError: boolean): void;
}

const CartProductInputs: React.FC<IProductInputs> = ({
  topInputValue,
  bottomInputValue,
  setTopInputValue,
  setBottomInputValue,
  quickOrderItem,
  isDisabled,
  popperPlacement,
  onHasErrors,
}: IProductInputs) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deliveryType = useSelector(priceTypeSelector);
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const { canSeePrice } = usePermission();
  const [messageTop, setMessageTop] = useState('');
  const [messageBottom, setMessageBottom] = useState('');
  const [currentPrice, setCurrentPrice] = useState(null);
  const [botVal, setBotVal] = useState(bottomInputValue ? bottomInputValue.toString() : '');
  const productPrices = useMemo(() => {
    return size(quickOrderItem.prices) ? filterPrices(get(quickOrderItem, 'prices', [])) : [];
  }, [quickOrderItem]);

  const timeClickGtm = useRef(0);

  useEffect(() => {
    if (bottomInputValue && bottomInputValue !== 0) {
      setBotVal(bottomInputValue.toString());
    }
  }, [bottomInputValue]);

  useEffect(() => {
    if (size(quickOrderItem.prices) > 0 && deliveryType) {
      const sortPrices = filterPrices(quickOrderItem.prices);
      if (sortPrices.length > 1 && quickOrderItem.quantity >= sortPrices[sortPrices.length - 1].qty) {
        setCurrentPrice(sortPrices[sortPrices.length - 1]);
        if (quickOrderItem.units.uom2Conversion) {
          const cartItemCuff = Number((quickOrderItem.quantity / quickOrderItem.units.uom2Conversion).toFixed(2));
          setBotVal(cartItemCuff.toString());
        }
      } else {
        setCurrentPrice(sortPrices[0]);
        if (quickOrderItem.units.uom2Conversion) {
          const cartItemCuff = Number((quickOrderItem.quantity / quickOrderItem.units.uom2Conversion).toFixed(2));
          setBotVal(cartItemCuff.toString());
        }
      }
    }
  }, [deliveryType, quickOrderItem]);

  const topRef = useRef();
  const bottomRef = useRef();

  const buttonTopRef = useRef();
  const buttonBottomRef = useRef();

  const uomConversion = Number(quickOrderItem.units.uom2Conversion);
  const cartItemCoeff = productPrices.length ? Number((productPrices[0].qty / uomConversion).toFixed(2)) : null;

  useEffect(() => {
    if (productPrices.length > 1) {
      if (topInputValue % productPrices[1].qty === 0) {
        setMessageTop('');
        setMessageBottom('');
      }
    }
  }, [productPrices, topInputValue]);

  const handleGtmEventChangeUOM = useCallback(() => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'quick_order_change_UOM',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
    timeClickGtm.current = Date.now();
  }, [branch?.id, erpCustomerAddressCode, selectedAddressId, user.email, user.customer]);

  const handleIfIncorrectValues = useCallback(
    (topValue: number, bottomValue: number | null) => {
      if (!productPrices.length) return false;
      let messageTopTemp: string;
      let messageBottomTemp: string;

      const minQuantity = productPrices[0].qty;
      const unitsPerName = quickOrderItem.units.perName;
      const unitPerName2 = quickOrderItem.units.uom2Name;
      const minimalQuantityValue = `${productPrices[0].qty} ${unitsPerName}`;
      const orderOnly = `${unitsPerName} (${minQuantity} ${
        unitsPerName
      } = ${Number((productPrices[0].qty / quickOrderItem.units.uom2Conversion).toFixed(2))} ${
        unitPerName2
      }) `;
      // min order uom quantity
      if (productPrices[0].qty > topValue) {
        dispatch(setDisableQuickOrderCheckout({disableCheckout: true}));
        // top uom value
        messageTopTemp = t('price_calculator.min_quantity', { minimalQuantityValue });
        setMessageTop(messageTopTemp);
      } else {
        messageTopTemp = '';
        setMessageTop('');
        dispatch(setDisableQuickOrderCheckout({disableCheckout: false}));
      }

      if (!messageTopTemp && topValue % productPrices[0].qty !== 0) {
        messageTopTemp = t('price_calculator.divisible', { minQuantity });
        setMessageTop(messageTopTemp);
        dispatch(setDisableQuickOrderCheckout({disableCheckout: true}));
      } else if (!messageTopTemp) {
        messageTopTemp = '';
        setMessageTop('');
        dispatch(setDisableQuickOrderCheckout({disableCheckout: false}));
      }

      if (bottomValue) {
        const remaining = Number((Number(bottomValue.toFixed(2)) % cartItemCoeff).toFixed(2));
        // min order uom2 quantity
        if (remaining !== 0 && remaining !== cartItemCoeff) {
          // bottom uom value
          messageBottomTemp = t('price_calculator.packs_provide', { unitsPerName, orderOnly });
          setMessageBottom(messageBottomTemp);
          dispatch(setDisableQuickOrderCheckout({disableCheckout: true}));
        } else {
          messageBottomTemp = '';
          setMessageBottom('');
          dispatch(setDisableQuickOrderCheckout({disableCheckout: false}));
        }
      } else {
        messageBottomTemp = '';
        setMessageBottom('');
      }

      if (onHasErrors) {
        onHasErrors(!(messageTopTemp === '' && messageBottomTemp === ''));
      }

      return messageTopTemp === '' && messageBottomTemp === '';
    },
    [productPrices, quickOrderItem.units.perName, quickOrderItem.units.uom2Name, quickOrderItem.units.uom2Conversion, onHasErrors, dispatch, t, cartItemCoeff]
  );

  const delayedUpdateCartItemQty = useCallback(
    (newQty, newBottomValue) => {
      if (handleIfIncorrectValues(newQty, newBottomValue)) {
        dispatch(
          updateQuickOrderItemQuantity({
            quickOrderItemId: quickOrderItem.id,
            quantity: newQty,
          })
        );
      }
    },
    [currentPrice, deliveryType, dispatch, handleIfIncorrectValues, quickOrderItem]
  );

  const handleIncrement = () => {
    const newTopValue =
      topInputValue - (topInputValue % productPrices[0].qty) + productPrices[0].qty;
    let newBottomValue: number | null;

    setTopInputValue(() => newTopValue);

    if (bottomInputValue || bottomInputValue === 0) {
      newBottomValue = parseFloat(
        ((newTopValue * cartItemCoeff) / productPrices[0].qty).toFixed(2)
      );
      setBottomInputValue(newBottomValue);
      setBotVal(newBottomValue.toString());
      setMessageBottom('');
      dispatch(setDisableQuickOrderCheckout({disableCheckout: false}));
    }
    setMessageTop('');
    if (timeClickGtm.current === 0) {
      handleGtmEventChangeUOM();
    } else if (timeClickGtm.current > 0 && Date.now() - timeClickGtm.current > 1000) {
      handleGtmEventChangeUOM();
    }
    delayedUpdateCartItemQty(newTopValue, newBottomValue || null);
  };

  const handleDecrement = () => {
    const decrementCoeff = topInputValue % productPrices[0].qty;
    let newTopValue = decrementCoeff
      ? Number((topInputValue - Number(decrementCoeff.toFixed(2))).toFixed(2))
      : topInputValue - decrementCoeff - productPrices[0].qty;

    newTopValue = newTopValue <= 0 ? productPrices[0].qty : newTopValue;

    let newBottomValue: number | null;

    setTopInputValue(newTopValue !== 0 ? newTopValue : 1); /* TODO qty not 1 */

    if (bottomInputValue) {
      newBottomValue = parseFloat(
        ((newTopValue * cartItemCoeff) / productPrices[0].qty).toFixed(2)
      );

      setBottomInputValue(newBottomValue !== 0 ? newBottomValue : cartItemCoeff);
      setBotVal(newBottomValue !== 0 ? newBottomValue.toString() : cartItemCoeff.toString());
      setMessageBottom('');
      dispatch(setDisableQuickOrderCheckout({disableCheckout: false}));
    }
    if (newTopValue === topInputValue || newBottomValue === bottomInputValue) return;
    setMessageTop('');
    dispatch(setDisableQuickOrderCheckout({disableCheckout: false}));
    delayedUpdateCartItemQty(newTopValue, newBottomValue || null);
    if (timeClickGtm.current === 0) {
      handleGtmEventChangeUOM();
    } else if (timeClickGtm.current > 0 && Date.now() - timeClickGtm.current > 1000) {
      handleGtmEventChangeUOM();
    }
  };

  const handleTopFieldChange = (e) => {
    let newTopValue1: number;
    let newBottomValue1: number;

    if (e.target.value === '') {
      newTopValue1 = 0;
    } else {
      newTopValue1 = parseInt(e.target.value, 10);
    }

    if (bottomInputValue !== null) {
      newBottomValue1 = parseFloat(
        ((newTopValue1 * cartItemCoeff) / productPrices[0].qty).toFixed(2)
      );
      setBottomInputValue(newBottomValue1);
      setBotVal(newBottomValue1.toString());
    }
    if (newTopValue1 >= 0) {
      delayedUpdateCartItemQty(newTopValue1, newBottomValue1 || null);
    }
    setTopInputValue(newTopValue1);
    if (timeClickGtm.current === 0) {
      handleGtmEventChangeUOM();
    } else if (timeClickGtm.current > 0 && Date.now() - timeClickGtm.current > 1000) {
      handleGtmEventChangeUOM();
    }
  };

  const delayedUpdateTopValue = useCallback(
    newBottomValue2 => {
      let newTopValue: number;
      let newBottomValue: number;
      const floatRegEx = '^([+-]?\\d*\\.?\\d*)$';
      const getNewTopValue = (bottomValue: number) => {
        return parseFloat(((bottomValue / cartItemCoeff) * productPrices[0].qty).toFixed(2));
      };

      if (newBottomValue2.search(floatRegEx) !== -1) {
        newBottomValue = Number(Number(newBottomValue2).toFixed(2));
        newTopValue = getNewTopValue(newBottomValue);
      } else {
        newTopValue = getNewTopValue(bottomInputValue);
        newBottomValue = bottomInputValue;
        setBotVal(newBottomValue.toString());
      }

      setTopInputValue(newTopValue);
      setBottomInputValue(newBottomValue);
      if (newTopValue >= 0) {
        delayedUpdateCartItemQty(newTopValue, newBottomValue);
      }
    },
    [bottomInputValue, cartItemCoeff, delayedUpdateCartItemQty, productPrices, setBottomInputValue, setTopInputValue]
  );

  const handleBottomFieldChange = e => {
    const newBottomValue2 = e.target.value;
    const letterCheckRegExp = /[a-zA-z]/;

    if (newBottomValue2.search(letterCheckRegExp) === -1) {
      setBotVal(newBottomValue2);
      delayedUpdateTopValue(newBottomValue2);
    }
  };

  const renderPromotionPrice = () => {
    if (!canSeePrice || deliveryType === 'hide') {
      return null;
    }
    const prices = get(quickOrderItem, 'prices', []);
    if (size(prices) > 1) {
      const sortPrices = prices.sort((a, b) => b.quantity - a.quantity);
      const promotionPrice = sortPrices[0];
      const nonSalePrice = sortPrices[1];
      const unitName = get(quickOrderItem, 'units.perName', 'Piece');
      let price;
      let normalPrice;
      if (deliveryType === 'delivery') {
        price = promotionPrice.deliveryPrice;
        normalPrice = nonSalePrice.deliveryPrice;
      } else {
        price = promotionPrice.pickupPrice;
        normalPrice = nonSalePrice.pickupPrice;
      }
      const handleClickPromotionPrice = () => {
        const newTopValue = promotionPrice.quantity;
        const newBottomValue = parseFloat(
          ((newTopValue * cartItemCoeff) / productPrices[0].qty).toFixed(2)
        );
        setBottomInputValue(newBottomValue !== 0 ? newBottomValue : cartItemCoeff);
        setBotVal(newBottomValue !== 0 ? newBottomValue.toString() : cartItemCoeff.toString());
        delayedUpdateTopValue(
          newBottomValue !== 0 ? newBottomValue.toString() : cartItemCoeff.toString()
        );
        setTopInputValue(promotionPrice.quantity);
      };
      if (topInputValue < promotionPrice.quantity && normalPrice - price > 0) {
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        return (
          <PromotionText style={{ cursor: 'pointer' }} onClick={handleClickPromotionPrice}>
            {t('product_detail.buy')} {promotionPrice.quantity} {t('product_detail.for')} ${price}/
            {unitName}
          </PromotionText>
        );
      }
      const mapPrices = sortPrices.map(e => {
        return {
          delivery: e.deliveryPrice,
          pickup: e.pickupPrice,
        };
      });
      const totalOldPrice = mapPrices[1][deliveryType] * quickOrderItem.quantity;
      const totalNewPrice = mapPrices[0][deliveryType] * quickOrderItem.quantity;
      const percent = Math.ceil(((totalOldPrice - totalNewPrice) / totalOldPrice) * 100);
      return (
        percent > 0 &&
        topInputValue >= promotionPrice.quantity && (
          <div
            dangerouslySetInnerHTML={{ __html: t('product_detail.saving_percent', { percent }) }}
            style={{ marginTop: 10 }}
          />
        )
      );
    }
    return null;
  };

  return quickOrderItem ? (
    <>
      <InputButtonsWrapper>
        <InputWithLabelWrapper className={`${messageTop ? 'has-error' : ''}`}>
          <StyledInputWrapper>
            {messageTop ? (
              <ProductInputPopper
                element={buttonTopRef.current}
                message={messageTop}
                placement='left'
              />
            ) : null}
            <IncrementButton
              ref={buttonTopRef}
              disabled={topInputValue === 0 || isDisabled}
              onClick={handleDecrement}
              data-test-id="decrease-quantity"
            >
              <ListViewMinus />
            </IncrementButton>
            <StyledTextField
              type="tel"
              InputProps={{ disableUnderline: true }}
              onChange={e => handleTopFieldChange(e)}
              value={topInputValue}
              disabled={isDisabled}
            />
            <IncrementButton disabled={isDisabled} onClick={handleIncrement} data-test-id="increase-quantity">
              <ListViewPlus />
            </IncrementButton>
          </StyledInputWrapper>
          <IncrementButtonLabel ref={topRef}>{quickOrderItem.units.perName}</IncrementButtonLabel>
        </InputWithLabelWrapper>

        {quickOrderItem &&
          quickOrderItem.units.uom2Name &&
          uomConversion !== 0 &&
          bottomInputValue !== null && (
          <InputWithLabelWrapper
            className={`${
              (messageBottom && popperPlacement === 'right') ||
              (messageBottom && popperPlacement === 'left')
                ? 'has-error'
                : ''
            }`}
          >
            <StyledInputWrapper>
              {messageBottom && popperPlacement === 'left' ? (
                <ProductInputPopper
                  element={buttonBottomRef.current}
                  message={messageBottom}
                  placement={popperPlacement}
                />
              ) : null}
              <IncrementButton
                ref={buttonBottomRef}
                disabled={Number(bottomInputValue.toFixed(2)) === 0 || isDisabled}
                onClick={handleDecrement}
              >
                <ListViewMinus />
              </IncrementButton>
              <StyledTextField
                InputProps={{ disableUnderline: true }}
                onChange={handleBottomFieldChange}
                value={botVal}
                disabled={isDisabled}
              />
              <IncrementButton disabled={isDisabled} onClick={handleIncrement}>
                <ListViewPlus />
              </IncrementButton>
            </StyledInputWrapper>
            <IncrementButtonLabel ref={bottomRef}>
              {quickOrderItem.units.uom2Name}
            </IncrementButtonLabel>
            {messageBottom && popperPlacement === 'right' ? (
              <ProductInputPopper
                element={bottomRef.current}
                message={messageBottom}
                placement={popperPlacement}
              />
            ) : null}
          </InputWithLabelWrapper>
        )}
      </InputButtonsWrapper>
      {renderPromotionPrice()}
    </>
  ) : null;
};

export default CartProductInputs;
