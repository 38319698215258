import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { usePopupScrollEffect } from 'hooks/usePopupScrollEffect';
import { PopUpCross, PopUpSmallCross } from 'assets/svg/Popups/Popups';
import {selectProductVariantsError} from 'ducks/productVariant/selectors';
import { selectSalesRepresentative } from 'ducks/salesRepresentative/selectors';
import {useOutsideClick} from 'hooks/useOutSideClick';
import { setOpenInquireNowOrderHistory } from 'ducks/order/actions';
import { selectEmailInquireNowOrderHistorySent } from 'ducks/order/selectors';
import { PopUpBackground, PopUpWrapper, CrossButtonWrapper, CrossButton } from './styles';
import InquireNowOrderHistoryEmail from './components/InquireNowOrderHistoryEmail';
import SubmitEmailSentInquireNow from './components/SubmitEmailSentInquireNow';

interface IRequestNearbyBranchPopup {
  setPricingClicked?: Dispatch<SetStateAction<boolean>>;
}

const rootDiv = document.getElementById('root');
const el = document.createElement('div');

const InquireNowOrderHistory: React.FC<IRequestNearbyBranchPopup> = () => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const error = useSelector(selectProductVariantsError);
  const salesRepresentative = useSelector(selectSalesRepresentative);
  const emailInquireNowOrderHistorySent = useSelector(selectEmailInquireNowOrderHistorySent);

  useEffect(() => {
    rootDiv.appendChild(el);

    return () => {
      try {
        rootDiv.removeChild(el);
        // eslint-disable-next-line no-empty
      } catch {

      }
    };
  }, []);

  usePopupScrollEffect();

  const handleClose = useCallback(() => {
    dispatch(setOpenInquireNowOrderHistory(false));
  }, [dispatch]);

  const refModal = useOutsideClick(handleClose);

  const renderSubmitEmailSent = useMemo(() => (
    salesRepresentative ? (
      <SubmitEmailSentInquireNow
        handleClose={handleClose}
        salesRepresentative={salesRepresentative}
      />
    ) : null
  ), [salesRepresentative, handleClose]);

  const renderInquireNowOrderHistoryEmail = useMemo(() => (
    salesRepresentative ? (
      <InquireNowOrderHistoryEmail
        disabled={disabled}
        setDisabled={setDisabled}
        salesRepresentative={salesRepresentative}
        error={!!error}
        handleClose={handleClose}
      />
    ) : null
  ), [disabled, error, handleClose, salesRepresentative]);

  return ReactDOM.createPortal(
    <PopUpBackground style={{ zIndex: 1300 }}>
      <PopUpWrapper ref={refModal}>
        <CrossButtonWrapper>
          <CrossButton onClick={handleClose}>
            {isMobile ? <PopUpSmallCross /> : <PopUpCross />}
          </CrossButton>
        </CrossButtonWrapper>
        {emailInquireNowOrderHistorySent ? renderSubmitEmailSent : renderInquireNowOrderHistoryEmail}
      </PopUpWrapper>
    </PopUpBackground>,
    el
  );
};

export default InquireNowOrderHistory;
