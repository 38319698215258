import { Reducer } from 'redux';

// Types
import { SearchActionTypes, SearchProductState } from 'ducks/search/types';
import uniqBy from 'lodash/uniqBy';

const initialState: SearchProductState = {
  products: [],
  facets: [],
  rangeFilters: [],
  isLastPage: true,
  loading: true,
  paginationLoading: false,
  error: null,
  total: 0,
  searchProductIdsList: [],
};

const searchReducer: Reducer<SearchProductState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case SearchActionTypes.REQUEST_FILTERED_PRODUCTS: {
      return {
        ...state,
        loading: !state.paginationLoading,
        total: 0,
        facets: [],
        rangeFilters: [],
      };
    }
    case SearchActionTypes.REQUEST_FILTERED_PRODUCTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        paginationLoading: false,
        products: [...state.products, ...payload.products],
        facets: payload.facets,
        rangeFilters: payload.rangeFilters,
        isLastPage: payload.isLastPage,
        total: payload.total,
      };
    }
    case SearchActionTypes.REQUEST_FILTERED_PRODUCTS_SUCCESS_FIRST_LOAD: {
      return {
        ...state,
        loading: false,
        products: payload.products,
        facets: payload.facets,
        rangeFilters: payload.rangeFilters,
        isLastPage: payload.isLastPage,
        total: payload.total,
      };
    }
    case SearchActionTypes.REQUEST_FILTERED_PRODUCTS_ERROR: {
      return { ...state, loading: false, paginationLoading: false, error: payload.error };
    }
    case SearchActionTypes.RESET_PRODUCTS: {
      return {
        ...state,
        loading: false,
        paginationLoading: false,
        error: null,
        products: [],
        facets: [],
        searchProductIdsList: [],
      };
    }
    case SearchActionTypes.SET_PAGINATION_LOADING: {
      return { ...state, paginationLoading: true };
    }
    case SearchActionTypes.REMOVE_PAGINATION_LOADING: {
      return { ...state, paginationLoading: false };
    }
    case SearchActionTypes.REQUEST_SEARCH_PRODUCTS: {
      return { ...state, loading: true };
    }
    case SearchActionTypes.REQUEST_SEARCH_PRODUCTS_SUCCESS: {
      let filteredProducts = [...state.products, ...payload.products];
      filteredProducts = uniqBy(filteredProducts, 'id');
      return {
        ...state,
        products: filteredProducts,
        total: payload.total,
        isLastPage: payload.isLastPage,
        loading: false,
        paginationLoading: false,
      };
    }
    case SearchActionTypes.REQUEST_SEARCH_PRODUCTS_FIRST_LOAD_SUCCESS: {
      return {
        ...state,
        products: payload.products,
        total: payload.total,
        isLastPage: payload.isLastPage,
        loading: false,
      }
    }
    case SearchActionTypes.REQUEST_SEARCH_PRODUCTS_FILTER_SUCCESS: {
      return { ...state, facets: payload.facets };
    }
    case SearchActionTypes.REQUEST_SEARCH_DUMP_PRODUCT_IDS_SUCCESS: {
      return { ...state, searchProductIdsList: payload.productIdsList };
    }
    case SearchActionTypes.RESET_PRODUCTS_ON_FILTER: {
      return {
        ...state,
        loading: true,
        products: [],
        searchProductIdsList: [],
      };
    }
    default:
      return state;
  }
};

export default searchReducer;
