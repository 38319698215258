import styled from 'styled-components';
import { Container, Typography, Breadcrumbs } from '@material-ui/core';
import { Link } from 'react-router-dom';

const StyledBreadCrumbsContainer = styled(Container)`
  margin-top: 48px;
  margin-bottom: 12px;
  padding: 0;
`;

const StyledBreadCrumbsWrapper = styled(Breadcrumbs)`
  .MuiBreadcrumbs-separator {
    margin: 0 5px;
  }
`;

const StyledCurrentPageName = styled(Typography)`
  color: #48576B;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  text-align: left;
  text-decoration: none;
`;

const StyledLink = styled(Link)`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  text-align: left;
  text-decoration: none;
`;

export { StyledBreadCrumbsContainer, StyledBreadCrumbsWrapper, StyledCurrentPageName, StyledLink };
