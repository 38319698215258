import React, { useEffect } from 'react';

import { ProfileLoader } from 'components/common';
import { Content } from 'pages/General/Profile/components/OrdersAndQuotes/Content/Content';
import { useOrdersFilter, useSetOrdersOrQuotesVisit } from 'pages/General/Profile/hooks/hooks';
import NoResults from 'pages/General/Profile/components/OrdersAndQuotes/NoResults/NoResults';
import { useProfileQuotes } from 'api/Quote';
import { QuoteClass } from 'models/classes/quoteClass';
import { quoteTabClickGAEvent } from 'GA/OrderGA/OrderGA';
import { useTranslation } from 'react-i18next';
import { OrdersContainer } from './styles';

interface IQuotes {
  openOrderTab: (orderNumber: number) => void;
}

const Quotes: React.FC<IQuotes> = ({ openOrderTab }: IQuotes) => {
  const { t } = useTranslation();
  const { filterParams, handleFilterChange, isApplyFilterInput, setIsApplyFilterInputInput } = useOrdersFilter({
    sort: 'DESC',
  });

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    status,
    isFetching,
  } = useProfileQuotes(filterParams);

  useSetOrdersOrQuotesVisit();

  useEffect(() => {
    quoteTabClickGAEvent();
  }, []);

  const quotesPages = data && {
    ...data,
    pages: data.pages.map((page, index) => {
      return {
        ...page,
        data: page.data.map(quote => new QuoteClass(quote)),
        pageId: index,
      };
    }),
  };

  if (isFetching && !isFetchingNextPage) {
    return (
      <OrdersContainer>
        <ProfileLoader />
      </OrdersContainer>
    );
  }

  const [firstPage] = quotesPages.pages;
  if (!firstPage.data.length && Object.keys(filterParams).length === 1) {
    return <NoResults typeOfOrder={t('profile.quotes').toLowerCase()} />;
  }

  return (
    <OrdersContainer>
      <Content
        onFilterChange={handleFilterChange}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        data={quotesPages}
        status={status}
        filterParams={filterParams}
        openOrderTab={openOrderTab}
        isQuotes
        isApplyFilterInput={isApplyFilterInput}
        setIsApplyFilterInputInput={setIsApplyFilterInputInput}
      />
    </OrdersContainer>
  );
};

export default Quotes;
