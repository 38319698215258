import React from 'react';
import styled from 'styled-components/macro';
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  Select,
  TextField,
  withStyles,
} from '@material-ui/core';

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin-bottom: 21px;
  & .MuiOutlinedInput-root {
    border-color: #dadee4;
  }
  & .MuiInputLabel-root {
    color: #939599;
  }
  & .Mui-focused.MuiFormLabel-root {
    background-color: #ffffff;
  }
  & .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #00853e;
  }
`;

const StyledSelect = styled(Select)`
  &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme: { colors } }) => colors.blackOpacity(0.23)};
  }
`;

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1023px) {
    padding-right: 0px;
    display: inline-block;
  }
`;

const PopUpHeader = styled.h2`
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.1px;
  text-align: left;
  margin: 0;
  @media (max-width: 1023px) {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.1px;
  }
`;

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  margin-top: 24px;
  border: 1px solid #c2cad6;
  box-shadow: 0px 2px 5px 0px rgb(27 36 44 / 12%);
  border-radius: 4px;
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    margin-bottom: 16px;
  }
  img {
    width: 68px;
    height: 68px;
    object-fit: cover;
    margin-right: 15px;
  }
  p {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.1px;
    margin: 0;
    @media (max-width: 1023px) {
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
    }
  }
`;

const PopUpText = styled.p`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.2px;
  margin: 0;
  @media (max-width: 1023px) {
  }
`;

const PopUpText2 = styled.p`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 0 18px 0;
  @media (max-width: 1023px) {
  }
`;

const PhoneNumberWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 32px;
  height: fit-content;
  margin: 8px 0 18px;
`;

const PhoneNumber = styled.p`
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.1px;
  margin: 0 0 0 8px;
  @media (max-width: 1023px) {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.1px;
    color: ${({ theme: { colors } }) => colors.mainGreen};
  }
`;

const QuantityTextField = styled(({ ...rest }) => <TextField type="number" {...rest} />)`
  margin-bottom: 24px;
  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
    }
  }
  .MuiInputBase-input {
    padding: 12px 14px 14px 16px;
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${({ theme: { colors } }) => colors.grey};
    top: 0;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  }
  .MuiInputLabel-formControl {
    top: -5px;
  }
`;

const MessageTextField = styled(({ ...rest }) => <TextField {...rest} />)`
  margin-bottom: 20px;
  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
    }
  }
  .MuiInputBase-input {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
  }
  .MuiOutlinedInput-multiline {
    padding: 0;
  }
  .MuiInputBase-input {
    padding: 12px 14px 14px 16px;
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${({ theme: { colors } }) => colors.grey};
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  }
`;

const BackToProducts = styled.button`
  background: ${({ theme: { colors } }) => colors.white};
  border: none;
  height: fit-content;
  display: flex;
  align-items: center;
  padding: 0;
  margin-right: 16px;
  outline: none;
  @media (max-width: 1023px) {
    order: 2;
    margin-top: 16px;
    margin-right: 0;
  }
  &:hover {
    cursor: pointer;
  }
  p {
    margin: 0;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.1px;
    color: ${({ theme: { colors } }) => colors.mainGreen};
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

const StyledButton = styled(({ disabled, children, ...rest }) => (
  <button type="button" {...rest}>
    {' '}
    {children}{' '}
  </button>
))`
  height: 56px;
  border: none;
  background: ${({ theme: { colors }, disabled }) => (!disabled ? colors.mainGreen : '#DADCE0')};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  outline: none;
  @media (max-width: 1023px) {
    order: 1;
    height: 44px;
    width: 100%;
    border-radius: 4px;
  }
  &:hover {
    cursor: pointer;
  }
  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: ${({ theme: { colors } }) => colors.white};
  }
`;

const PhoneButton = styled.button`
  display: flex;
  background: ${({ theme: { colors } }) => colors.white};
  border: none;
  width: fit-content;
  height: fit-content;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
  padding: 0;
  outline: none;
`;

const StyledCheckbox = withStyles({
  root: {
    width: 'fit-content',
    height: 'fit-content',
    marginRight: '4px',
    padding: '0',
    '&:hover': {
      background: 'none',
      cursor: 'pointer',
    },
    '& > span': {
      padding: '0',
    },
  },
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const SingleCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PopUpBackground = styled.div`
  z-index: 1;
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { colors } }) => colors.blackOpacity(0.3)};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
`;

const PopUpWrapper = styled.div`
  width: 484px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 32px;
  background: ${({ theme: { colors } }) => colors.white};
  border-radius: 6px;
  position: relative;
  max-height: 100%;
  overflow-y: auto;
  @media (max-width: 1023px) {
    width: 300px;
    padding: 38px 16px 24px;
  }
`;

const CrossButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1023px) {
    width: fit-content;
    position: absolute;
    top: 16px;
    right: 16px;
  }
`;

const CrossButton = styled.button`
  width: fit-content;
  height: fit-content;
  border: none;
  background: none;
  padding: 0;
  outline: none;

  &:hover {
    cursor: pointer;
  }
`;

const PopUpHeaderSmall = styled.h2`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0.01em;
  text-align: center;
  margin: 0;
  margin-bottom: 32px;
  @media (max-width: 1023px) {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 24px;
  }
`;

const PopUpTextSmall = styled.p`
  font-family: Open Sans;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  text-align: center;
  margin: 0;
  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const StyledButtonSmall = styled.button`
  width: 100%;
  max-width: 364px;
  height: 48px;
  border: none;
  background: ${({ theme: { colors } }) => colors.mainGreen};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px auto 0;
  padding: 0;
  outline: none;

  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: ${({ theme: { colors } }) => colors.white};
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1023px) {
    height: 40px;
    margin-top: 20px;
    width: 100%;

    p {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const AutocompleteWrapper = styled.div`
  position: relative;
  .MuiAutocomplete-popper {
    position: absolute;
    left: 0;
    top: 48px;
    .MuiAutocomplete-listbox {
      li {
        position: relative;
        &.item-selected {
          width: 100%;
          color: ${({ theme: { colors } }) => colors.mainGreen};
          padding-right: 25px;
        }
        .ic-arrow {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }
`;

export {
  SingleCheckboxWrapper,
  StyledCheckbox,
  PhoneButton,
  StyledButton,
  ButtonsWrapper,
  BackToProducts,
  MessageTextField,
  QuantityTextField,
  PhoneNumberWrapper,
  PopUpText,
  PopUpText2,
  ProductWrapper,
  PopUpHeader,
  StyledWrapper,
  StyledSelect,
  StyledFormControl,
  PhoneNumber,
  PopUpBackground,
  PopUpWrapper,
  CrossButtonWrapper,
  CrossButton,
  PopUpHeaderSmall,
  PopUpTextSmall,
  StyledButtonSmall,
  AutocompleteWrapper,
};
