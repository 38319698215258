import { IError } from '../../models/interfaces/error';
import { SiteLocaleClass } from '../../models/classes/siteLocaleClass';

export const SiteLocaleActionsTypes = {
  REQUEST_SITE_LOCALES: '@@siteLocale/REQUEST_SITE_LOCALES',
  REQUEST_SITE_LOCALES_SUCCESS: '@@siteLocale/REQUEST_SITE_LOCALES_SUCCESS',
  REQUEST_SITE_LOCALES_ERROR: '@@siteLocale/REQUEST_SITE_LOCALES_ERROR',
}

export interface SiteLocaleState {
	readonly siteLocales: SiteLocaleClass[];
	readonly loading: boolean;
	readonly error: IError;
}

export const defaultSiteLocales = [
  {
    code: 'en_CA',
    isDefault: true,
  },
  {
    code: 'fr_CA',
    isDefault: false,
  }
]
