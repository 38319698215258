import React from 'react';
import styled from 'styled-components';
import {Link} from '@material-ui/core';
import {FaxIcon, MobileIcon, PhoneIcon, TollFreeIcon} from 'assets/svg/ContactUs/ContactIcons';
import {formatPhoneNumber} from 'utils/common/common';

const StyledLink = styled(Link)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: ${({theme: {fontSizes}}) => fontSizes.small};
  line-height: 100%;
  color: ${({theme: {colors}}) => colors.mainGreen};
  cursor: pointer;
  margin-bottom: 8px;
`;

const ContactRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 6px;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

interface PhoneProps {
  text: string;
  phoneTo: string;
  type: string;
}

interface PhonesProps {
  phones: Array<PhoneProps>;
  mobile?: boolean;
}

function defineIcon(mobile: boolean, type: string) {
  if (mobile) {
    return (
      <MobileIcon/>
    );
  }
  switch (type) {
    case 'Tel.':
      return <PhoneIcon/>;
    case 'Fax':
      return <FaxIcon/>;
    case 'Toll Free':
      return <TollFreeIcon/>;
    default:
      return null;
  }

}

export const ContactPhones: React.FC<PhonesProps> = ({mobile, phones}: PhonesProps) => {
  return (
    <ContactRow>
      <LinksContainer>
        {phones.map(phone => (
          <StyledLink key={`tel:${phone.phoneTo}`} href={`tel:${phone.phoneTo}`}>
            {defineIcon(mobile, phone.type)} {formatPhoneNumber(phone.text)}
          </StyledLink>
        ))}
      </LinksContainer>
    </ContactRow>
  );
};
