import {ICategoryImage, ICategoryPage, IDefaultLanguage, INotification} from 'models/interfaces/category';

export class CategoryClass {
  id: number = null;

  name: string = null;

  code: string = null;

  url: string = null;

  urlPath: string = null;

  image: string = null;

  images: ICategoryImage[] = null;

  categoryPage: ICategoryPage = null;

  description: string = null;

  subcategories: CategoryClass[] = null;

  children: CategoryClass[] = null;

  items: number;

  popupBanner?: INotification;

  showSecondLayout: boolean;

  bannerUrl: string = null;

  parent: CategoryClass = null;

  parentId: number;

  position: number;

  defaultLanguage: IDefaultLanguage;

  translations: any;

  constructor(...args) {
	  args.forEach((item: CategoryClass) => {
	    this.id = item.id || null;
	    this.name = item.name || null;
	    this.code = item.code || null;
	    this.image = item.image || null;
	    this.urlPath = item.urlPath || null;
	    this.url = item.urlPath || null;
	    this.images = item.images || null;
	    this.categoryPage = item.categoryPage || null;
	    this.description = item.description || null;
	    this.children = item.children || null;
	    this.items = item.items || 0;
	    this.popupBanner = item.popupBanner || null;
	    this.showSecondLayout = item.showSecondLayout;
	    this.bannerUrl = item.bannerUrl;
	    this.parent = item.parent;
	    this.parentId = item.parentId;
	    this.position = item.position;
	    this.defaultLanguage = item.defaultLanguage;
      this.translations = item.translations;
	  });
  }
}
