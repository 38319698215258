export const DELIVERY_PRICE_TYPE = 'delivery';
export const PICKUP_PRICE_TYPE = 'pickup';
export const KLAVIYO_KEY = process.env.REACT_APP_KLAVIYO_KEY || 'undefined-klaviyo-key';

export const PROVINCE_CODES_WITH_NAME = {
  CA: 'Canada',
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NT: 'Northwest territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
};

export const REACT_APP_ORDERS_QUOTES_VISIBLE =
  process.env.REACT_APP_ORDERS_QUOTES_VISIBLE || 'undefined-order-quote';

export const REACT_APP_UA_ID = process.env.REACT_APP_UA_ID || 'undefined-ua-id';
export const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || 'undefined-sentry-dsn';
export const REACT_APP_ENV = process.env.REACT_APP_ENV || 'production';

export const snackBarZIndex = 2200000000; // need to override the feedback button
export const REACT_APP_INTERNAL_EMAIL_PREFIX = process.env.REACT_APP_INTERNAL_EMAIL_PREFIX || '@taigabuilding.com';
