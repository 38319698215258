import styled from 'styled-components/macro';
import { MenuItem, Select, FormControl, SwitchProps, Switch } from '@material-ui/core';
import React from 'react';

const FormControlWrapper = styled(FormControl)`
  display: flex;
  align-items: center;
  flex-direction: row;
  .prices-switch {
    margin-left: 0;
    margin-right: 8px;
    .MuiFormControlLabel-label {
      color: #00133c;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      margin-left: 8px;
    }
  }
  .MuiInputBase-root {
    margin-top: 0;
  }
`;

const IOSSwitch = styled((props: SwitchProps) => (
  // @ts-ignore
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple inputProps={{ 'data-test-id': 'prices-switch'}} {...props} />
))(({ theme }) => ({
  width: 35,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#00853e',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#c0c8d4',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const StyledSelect = styled(({ noArrow, ...rest }) => <Select {...rest} />)`
  & .MuiSvgIcon-root.MuiSelect-icon {
    display: ${props => (props.noArrow ? 'none' : 'block')};
  }
  & .MuiSelect-select {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: ${props => (props.noArrow ? '0' : '6px 24px 7px 10px')};
    div {
      display: none;
    }
    color: ${props =>
    props.value === 2
      ? ({ theme: { colors } }) => colors.grey
      : ({ theme: { colors } }) => colors.textColor};
  }
  & .MuiMenu-list {
    border: 1px solid red;
  }
  &:focus {
    background-color: ${({ theme: { colors } }) => colors.white};
  }
  && ul {
    padding: 0;
  }
  .colored-svg {
    margin-bottom: 0;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 16px 8px 16px 10px;
`;

const StyledParagraph = styled.p`
  margin: 0 8px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
`;

export {
  FormControlWrapper,
  IOSSwitch,
  StyledSelect,
  StyledMenuItem,
  StyledParagraph,
};
