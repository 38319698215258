import axios from 'utils/axios'
import { AxiosResponse } from 'axios';

export type Setting = {
  error: boolean;
  setting: {
    isShowOrderUpdateEmail: boolean;
    isEnableCheckout: boolean;
  };
}

export const fetchGlobalSetting = async (): Promise<AxiosResponse<Setting>> =>
  // eslint-disable-next-line no-return-await
  await axios.get('/api/global-settings');