import {createSelector} from 'reselect';
import {RootState} from '../../store';

export const selectQuickOrderState = (state: RootState) => state.quickOrder;

export const selectQuickOrderItems = createSelector(
  selectQuickOrderState,
  quickOrderState => quickOrderState.quickOrderItems
);

export const selectDisableQuickOrderCheckout = createSelector(
  selectQuickOrderState,
  quickOrderState => quickOrderState.disableCheckout
);

export const selectClearSearchTerm = createSelector(
  selectQuickOrderState,
  quickOrderState => quickOrderState.clearSearchTerm
);

export const selectQuickOrderAddToCartStatus = createSelector(
  selectQuickOrderState,
  quickOrderState => quickOrderState.quickOrderAddToCartStatus
);

export const selectQuickOrderProducts = createSelector(
  selectQuickOrderState,
  quickOrderState => quickOrderState.quickOrderProducts
);