import isSameDay from 'date-fns/isSameDay';
import isBefore from 'date-fns/isBefore';
import startOfWeek from 'date-fns/startOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import endOfMonth from 'date-fns/endOfMonth';
import addDays from 'date-fns/addDays';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import { DateRange } from 'pages/General/Profile/components/OrdersAndQuotes/DateRangePicker/types';

export const identity = <T>(x: T) => x;

export const chunks = <T>(array: ReadonlyArray<T>, size: number): T[][] => {
  return Array.from({ length: Math.ceil(array.length / size) }, (v, i) =>
    array.slice(i * size, i * size + size)
  );
};

export const combine = (...args: []): string => args.filter(identity).join(' ');

export function isWithinRange(dirtyDate: Date, dirtyStartDate: Date, dirtyEndDate: Date) {
  const time = dirtyDate.getTime();
  const startTime = dirtyStartDate.getTime();
  const endTime = dirtyEndDate.getTime();

  if (startTime > endTime) {
    throw new Error('The start of the range cannot be after the end of the range');
  }

  return time >= startTime && time <= endTime;
}

// Date
export const getDaysInMonth = (date: Date) => {
  const startWeek = startOfWeek(startOfMonth(date));
  const endWeek = endOfWeek(endOfMonth(date));
  const days = [];
  for (let curr = startWeek; isBefore(curr, endWeek); ) {
    days.push(curr);
    curr = addDays(curr, 1);
  }
  return days;
};

export const isStartOfRange = ({ startDate }: DateRange, day: Date) =>
  (startDate && isSameDay(day, startDate)) as boolean;

export const isEndOfRange = ({ endDate }: DateRange, day: Date) =>
  (endDate && isSameDay(day, endDate)) as boolean;

export const inDateRange = ({ startDate, endDate }: DateRange, day: Date) =>
  (startDate &&
    endDate &&
    (isWithinRange(day, startDate, endDate) ||
      isSameDay(day, startDate) ||
      isSameDay(day, endDate))) as boolean;

export const isRangeSameDay = ({ startDate, endDate }: DateRange) => {
  if (startDate && endDate) {
    return isSameDay(startDate, endDate);
  }
  return false;
};

type Falsy = false | null | undefined | 0 | '';

export const parseOptionalDate = (date: Date | string | Falsy, defaultValue: Date) => {
  if (date instanceof Date && date.getTime()) {
    if (isValid(date)) return date;
  }

  if (date) {
    const parsed = parseISO(date as string);
    if (isValid(parsed)) return parsed;
  }
  return defaultValue;
};
