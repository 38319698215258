import React, { MouseEventHandler } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IFrameIcon from 'assets/images/iframeicon.png';

import {
  StyledIFrameOpenButton,
  StyledIFrameOpenButtonText,
  ModalWindow,
  ModalCard,
  StyledIFrame,
  StyledIFrameIcon,
  StyledIconButton,
} from './styles';
import { ProductClass } from '../../../../models/classes/productClass';

interface IFrameModalInterface {
  open: boolean;
  onClickClose: MouseEventHandler<HTMLButtonElement>;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
	product: ProductClass;
}

const IFrameModal: React.FC<IFrameModalInterface> = ({
  open,
  onClose,
  onClickClose,
  product
}: IFrameModalInterface) => {
  return product.interactiveUrl ? (
    <ModalWindow open={open} onClose={onClose}>
      <ModalCard>
        <StyledIconButton aria-label="close" onClick={onClickClose}>
          <CloseIcon />
        </StyledIconButton>
        <StyledIFrame
          src={product.interactiveUrl}
          sandbox="allow-scripts"
          loading="lazy"
          referrerPolicy="no-referrer"
        >
          <p>Sorry, but it&apos;s impossible to display this content</p>
        </StyledIFrame>
      </ModalCard>
    </ModalWindow>
  ) : null;
};

interface IFrameButtonInterface {
  onClick: () => void;
	product: ProductClass;
}

const IFrameButton: React.FC<IFrameButtonInterface> = ({
  onClick,
  product
}: IFrameButtonInterface) => {
  return product.interactiveUrl ? (
    <StyledIFrameOpenButton>
      <StyledIFrameIcon src={IFrameIcon} />
      <StyledIFrameOpenButtonText onClick={onClick}>Interactive eSample</StyledIFrameOpenButtonText>
    </StyledIFrameOpenButton>
  ) : null;
};

export { IFrameModal, IFrameButton };
