import React  from 'react';
import { StyledBreadCrumbsContainer } from 'components/common/BreadCrumbs/styles';
import { ProductClass } from 'models/classes/productClass';
import { CustomBreadCrumbs } from 'components/common/CustomBreadCrumbs/CustomBreadCrumbs';

interface IProductBreadcrumbs {
  product: ProductClass;
}

const ProductBreadcrumbs = React.memo(({ product }: IProductBreadcrumbs) => {

  return (
    <StyledBreadCrumbsContainer>
      <CustomBreadCrumbs data={product.breadcrumbs} />
    </StyledBreadCrumbsContainer>
  );
});

export { ProductBreadcrumbs };
