import { defaultSiteLocales, SiteLocaleActionsTypes } from './types';
import { SiteLocaleClass } from '../../models/classes/siteLocaleClass';

export const fetchSiteLocales = () => ({
  type: SiteLocaleActionsTypes.REQUEST_SITE_LOCALES,
})

export const storeSiteLocales = (siteLocales: SiteLocaleClass[]) => ({
  type: SiteLocaleActionsTypes.REQUEST_SITE_LOCALES_SUCCESS,
  payload: siteLocales,
})

export const fetchSiteLocalesError = () => ({
  type: SiteLocaleActionsTypes.REQUEST_SITE_LOCALES_ERROR,
  payload: defaultSiteLocales,
})
