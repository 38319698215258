import {IKlaviyoEventState, KLAVIYO_EVENT} from './types';

const initialState: IKlaviyoEventState = {
  status: false,
  data: {}
}

const klaviyoEventReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case KLAVIYO_EVENT.SET_KLAVIYO_EVENT: {
      return {
        ...state,
        status: payload.status
      }
    }
    case KLAVIYO_EVENT.FIRE_KLAVIYO_EVENT_SUCCESS: {
      return {
        ...state,
        data: payload,
        status: true,
      }
    }
    default:
      return state
  }
}

export default klaviyoEventReducer;