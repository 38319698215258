import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
// import { Grid } from '@material-ui/core';

import { RightArrow } from 'assets/svg/SubMenu/SubMenu';
import Routes from 'routes';

import { useQuery } from 'react-query';
import size from 'lodash/size';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import { selectParentCategories } from 'ducks/category/selectors';
import {
  selectAddressId,
  selectSpecialPages,
  selectErpCustomerAddressCode,
} from 'ducks/application/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { useSetPrices } from 'hooks/useSetPrices';
import { ShadowDiv, MainNav, WrapperDiv, MainNavWrapper, TriggerClose } from './styles';
import { fetchDocumentGroup } from '../../../api/DocumentGroup';
import { usePermission } from '../../../hooks/usePermission';

type Props = {
  handleClick?: (event: React.MouseEvent) => void;
};

const LargeMenuBlock: React.FC<Props> = ({ handleClick }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const { user } = useSetPrices();
  const branch = useSelector(selectBranch);
  const categories = useSelector(selectParentCategories);
  const specialPages = useSelector(selectSpecialPages);
  const { canPlaceOrder, canPlaceQuote, canSeeOrderHistory } = usePermission();

  const triggerLink = (event, category = null) => {
    if (category && category?.children?.length > 0) {
      return event.preventDefault();
    }
    handleClick(event);
    return true;
  };

  const getCategoryName = (category: any) => {
    return category.name;
  }

  const handleGoToYourAccount = (type: string) => {
    if (type === 'profile') {
      clearGtmEcommerce();
      history.push(Routes.PROFILE, {
        tab: 'profile.personal_settings',
      });
    }
    if (type === 'order_history') {
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'click_order_history',
          user_type: getUserType(user.email),
          branch_id: branch?.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
      history.push(Routes.PROFILE, {
        tab: 'profile.orders',
      });
    }
  };

  const { data: documentGroup } = useQuery({
    queryKey: ['documentGroup'],
    queryFn: fetchDocumentGroup
  });

  // @ts-ignore
  return (
    <WrapperDiv className="large-menu-block_wrapper-div">
      <ShadowDiv className="shadow-div" onClick={handleClick} />
      <MainNavWrapper className="main-nav-wrapper">
        <TriggerClose onClick={handleClick} />
        <MainNav className="main-nav">
          {Array.isArray(categories) && categories.length > 0 && (
            <li className="product-menu-list">
              <div className="sub-menu-title">{t('header.products').toString()}</div>
              <ul className="sub-menu">
                {categories.map((category: any) => (
                  <li key={category.code}>
                    <Link
                      to={`${Routes.CATEGORIES}/${category.code}`}
                      onClick={e => triggerLink(e)}
                      className="nav-link"
                    >
                      <span className="nav-link-title">{category.name}</span>
                      {Array.isArray(category.children) && category.children.length > 0 && (
                        <RightArrow className="ic-arrow" />
                      )}
                    </Link>

                    {Array.isArray(category.children) && category.children.length > 0 && (
                      <ul className="sub-menu full-height">
                        {/* menu title */}
                        <li className="sub-menu-title">
                          <div className="txt-left">{category.name}</div>
                        </li>

                        {/* menu list */}
                        {category.children.map(sub1 => {
                          return sub1.items && sub1.items > 0 ? (
                            <li key={sub1.code}>
                              <Link
                                to={`${Routes.CATEGORIES}/${category.code}/${sub1.code}`}
                                onClick={e => triggerLink(e, sub1)}
                                className="nav-link"
                              >
                                <span className="nav-link-title">{getCategoryName(sub1)}</span>
                                {Array.isArray(sub1.children) && sub1.children.length > 0 && (
                                  <RightArrow className="ic-arrow" />
                                )}
                              </Link>

                              {Array.isArray(sub1.children) && sub1.children.length > 0 && (
                                <ul className="sub-menu full-height">
                                  {/* menu title */}
                                  <li className="sub-menu-title">
                                    <div className="txt-left">{getCategoryName(sub1)}</div>
                                  </li>

                                  {/* menu list */}
                                  {sub1.children.map((sub2: any) =>
                                    sub2.items && sub2.items > 0 ? (
                                      <li key={sub2.code}>
                                        <Link
                                          to={`${Routes.CATEGORIES}/${category.code}/${sub2.code}`}
                                          onClick={e => triggerLink(e, sub2)}
                                          className="nav-link"
                                        >
                                          <span className="nav-link-title">{getCategoryName(sub2)}</span>
                                        </Link>
                                      </li>
                                    ) : null
                                  )}
                                </ul>
                              )}
                            </li>
                          ) : null;
                        })}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </li>
          )}

          <li className="taiga-now-features-menu-list">
            <div className="sub-menu-title">{t('header.taiga_now_features').toString()}</div>
            <ul className="sub-menu">
              {Array.isArray(specialPages) && specialPages.length > 0 && (
                <li>
                  <Link to={Routes.SPECIAL} className="nav-link" onClick={handleClick}>
                    <span className="nav-link-title">{t('header.specials_pages').toString()}</span>
                  </Link>
                </li>
              )}
              {(canPlaceOrder || canPlaceQuote) && (
                <li>
                  <Link to={Routes.QUICK_ORDER} className="nav-link" onClick={handleClick}>
                    <span className="nav-link-title">
                      {t('header.quick_order').toString()}
                      <span className="new-label">{t('header.new').toString()}</span>
                    </span>
                  </Link>
                </li>
              )}
              {
                size(documentGroup?.data?.data) > 0 && (
                  <li className="nav-item-quick-order">
                    <Link
                      to={Routes.DOCUMENT_GROUP}
                      className="nav-link"
                      onClick={handleClick}
                    >
                      <span className="nav-link-title">
                        {t('document_group.title').toString()}
                        <span className="new-label">{t('header.new').toString()}</span>
                      </span>
                    </Link>
                  </li>
                )
              }
              {canSeeOrderHistory && (
              <li>
                <Link
                  to={Routes.PROFILE}
                  className="nav-link nav-link-order-history"
                  onClick={e => {
                    handleClick(e);
                    setTimeout(() => handleGoToYourAccount('order_history'), 300);
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: t('header.order_history') }} className="nav-link-title" />
                </Link>
              </li>
              )}
            </ul>
          </li>
          <li className="your-account-menu-list">
            <div className="sub-menu-title">{t('header.your_account').toString()}</div>
            <ul className="sub-menu">
              <li>
                <Link
                  to={Routes.PROFILE}
                  className="nav-link"
                  onClick={e => {
                    handleClick(e);
                    setTimeout(() => handleGoToYourAccount('profile'), 300);
                  }}
                >
                  <span className="nav-link-title">{t('header.profile').toString()}</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="use-and-help-menu-list">
            <div className="sub-menu-title">{t('header.use_and_help').toString()}</div>
            <ul className="sub-menu">
              <li>
                <Link
                  to={Routes.FAQ}
                  className={`nav-link ${location.pathname === Routes.FAQ ? 'active' : ''}`}
                  onClick={handleClick}
                >
                  <span className="nav-link-title">{t('header.faq').toString()}</span>
                </Link>
              </li>
              <li className="bb-0">
                <Link to={Routes.CONTACT_US} className="nav-link" onClick={handleClick}>
                  <span className="nav-link-title">{t('header.contact_us').toString()}</span>
                </Link>
              </li>
            </ul>
          </li>
        </MainNav>
      </MainNavWrapper>
    </WrapperDiv>
  );
};

export default LargeMenuBlock;
