import React, { useEffect, useState, useMemo, createRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ProductItem from 'pages/Checkout/ProductList/ProductItem';
import { OrdersModal } from 'pages/Checkout/OrdersModal';
import { ConfirmOrderLoadingModal } from 'pages/Checkout/ConfirmOrderLoadingModal/ConfirmOrderLoadingModal';
import { EmptyCheckout } from 'pages/Checkout/EmptyCheckout';
import { CheckoutOptions } from 'pages/Checkout/CheckoutOptions';
import { CheckoutSidebar } from 'components';
import { BreadCrumbs } from 'components/common';

import {
  selectOrderId,
  selectCartLoading,
  selectCartItemQtyUpdateStatus,
  selectDefaultCartDeliveryType,
  selectCartError,
  selectCartPurchaseValues, selectCartItemsNoPrice, selectCart,
} from 'ducks/cart/selectors';
import { requestCartSummary } from 'ducks/cart/actions';
import { finishLoadingOrder, submitOrder } from 'ducks/order/actions';
import { selectBranch } from 'ducks/branch/selectors';
import {
  priceTypeSelector,
  selectAddressCode,
  selectAddressId,
  selectErpCustomerAddressCode,
} from 'ducks/application/selectors';
import { selectOrderError, selectSubmitOrderError, selectOrderLoading } from 'ducks/order/selectors';
import { toggleSnackbarOpen } from 'ducks/ui/actions';
import { changeField } from 'ducks/checkout/actions';
import {
  selectOrder,
  selectStep,
  selectWasAddressChanged,
  selectPreviousAddress,
} from 'ducks/checkout/selectors';
import Routes from 'routes';
import { purchaseGAEvent } from 'GA/OrderGA/OrderGA';
import { setOrderSubmitted } from 'ducks/GA/actions';
import isBefore from 'date-fns/isBefore';
import formatISO from 'date-fns/formatISO';
import { DELIVERY } from 'assets/constants/order';
import { useLanguage } from 'hooks/useLanguage';
import { CheckoutStep } from 'ducks/checkout/types';
import { selectUserData } from 'ducks/user/selectors';
import { Accordion } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { PERMISSION_PLACE_ORDER, PERMISSION_PLACE_QUOTE } from 'assets/constants/permission';
import size from 'lodash/size';
import TagManager from 'react-gtm-module';
import { getDefaultCategoryName } from 'utils/categories/categories';
import { selectCategories } from 'ducks/category/selectors';
import { pushKlaviyoEvent } from 'utils/klaviyo';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import { getDefaultProductName } from 'utils/products/productUtilsHelpers';
import { BranchIcon } from 'assets/svg/BranchIcon';
import { ShippingIcon } from 'assets/svg/ShippingIcon';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import isAfter from 'date-fns/isAfter';
import addYears from 'date-fns/addYears';
import {
  Header,
  ProductsLayout,
  Content,
  Layout,
  CustomBreadCrumbs,
  AccordionContent,
  StyledAccordionDetails,
} from './styles';
import OrdersModalError from './OrdersModalError';
import ItemsNoPriceModal from './ItemsNoPriceModal';
import FormContext from './FormContext';
import { clearGtmEcommerce } from '../../utils/clearGtmEcommerce';

const CheckoutPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUserData);
  const currentLang = useLanguage();
  const cart = useSelector(selectCart);
  const cartItems = cart?.items;
  const previousSelectedAddress = useSelector(selectPreviousAddress);
  const selectedAddress = useSelector(selectAddressCode);
  const selectedAddressId = useSelector(selectAddressId);
  const selectedOrderId = useSelector(selectOrderId);
  const isQtyUpdated = useSelector(selectCartItemQtyUpdateStatus);
  const orderPricing = useSelector(selectDefaultCartDeliveryType);
  const cartError = useSelector(selectCartError);
  const order = useSelector(selectOrder);
  const priceType = useSelector(priceTypeSelector);
  const isQuotes = order === 'quote';
  const orderError = useSelector(selectOrderError);
  const categories = useSelector(selectCategories);
  const { taxes, price, currency } = useSelector(selectCartPurchaseValues);
  const checkoutStep = useSelector(selectStep);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const isEmptyCart = useMemo(() => cartItems?.length === 0, [cartItems]);
  const [zeroPriceError, setZeroPriceError] = useState(null);
  const [isFireEventBeginCheckout, setIsFireEventBeginCheckout] = useState(false);
  const isSecondStep = useMemo(() => checkoutStep === CheckoutStep.SECOND, [checkoutStep]);
  const addressWasChanged = useSelector(selectWasAddressChanged);
  const branch = useSelector(selectBranch);
  const submitOrderError = useSelector(selectSubmitOrderError);
  const cartItemsNoPrice = useSelector(selectCartItemsNoPrice);
  const orderLoading = useSelector(selectOrderLoading);
  const [isFireCheckoutKlavyioEvent, setIsFireCheckoutKlavyioEvent] = useState(false);
  const hasZeroPriceProductsMessage = t('checkout.cannot_proceed_to_checkout');

  useEffect(() => {
    return () => {
      setIsFireCheckoutKlavyioEvent(false);
    };
  }, []);

  useEffect(() => {
    dispatch(changeField('previousSelectedAddress', selectedAddress));
    if (
      selectedAddress !== null &&
      previousSelectedAddress &&
      selectedAddress !== previousSelectedAddress
    ) {
      dispatch(changeField('addressWasChanged', true));
    }
  }, [selectedAddress, dispatch, previousSelectedAddress]);

  useEffect(() => {
    if (user && !user.roles.includes(PERMISSION_PLACE_ORDER) && !user.roles.includes(PERMISSION_PLACE_QUOTE)) {
      window.location.replace(`/${currentLang}${Routes.NOT_FOUND}`);
    }
  }, [currentLang, user]);

  useEffect(() => {
    if (orderPricing) {
      dispatch(changeField('shippingMethod', orderPricing));
    }
  }, [orderPricing, dispatch]);

  useEffect(() => {
    if (submitOrderError) {
      dispatch(finishLoadingOrder());
    }
  }, [submitOrderError, dispatch]);

  useEffect(() => {
    if (selectedAddressId) {
      dispatch(requestCartSummary(selectedAddressId));
    }
  }, [dispatch, selectedAddressId]);

  useEffect(() => {
    if (cart && size(cart.items) && !isFireCheckoutKlavyioEvent) {
      const itemNames = cart.items.map(item => !isEmpty(item.variant.name) ? item.variant.name : item.variant.erpSku);
      const categoryNames = cart.items.map(item => [item.parentCategoryName, item.categoryName]);
      const items = cart.items.map(item => {
        let itemPrice;
        switch (priceType) {
          case 'delivery':
            itemPrice = item.productDeliveryPrice;
            break;
          case 'pickup':
            itemPrice = item.productPickupPrice;
            break;
          default:
            itemPrice = 0;
            break;
        }
        return {
          ProductID: item.variant.erpProductId,
          SKU: item.variant.erpSku,
          ProductName: !isEmpty(item.variant.name) ? item.variant.name : item.variant.erpSku,
          Quantity: item.quantity,
          ItemPrice: itemPrice,
          RowTotal: item.total,
          ProductURL: `${window.location.origin}/${currentLang}/product/${item.variant.erpProductId}`,
          ImageURL: item.variant.images[0]?.url || '',
          ProductCategories: [item.parentCategoryName, item.categoryName],
        };
      });
      pushKlaviyoEvent('Started Checkout', {
        '$event_id': `${cart.id}_${new Date().getTime()}`,
        '$value': cart.subtotal,
        ItemNames: itemNames,
        CheckoutURL: window.location.href,
        Categories: uniq(flatten(categoryNames)),
        Items: items,
      });
      setIsFireCheckoutKlavyioEvent(true);
    }
  }, [cart, currentLang, isFireCheckoutKlavyioEvent, priceType]);

  const handleGTMExpanded = useCallback((branchId: number) => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'checkout_click_grouping',
        user_type: getUserType(user.email),
        branch_id: branchId,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
  }, [branch, erpCustomerAddressCode, selectedAddressId, user?.email, user?.customer?.erpCustomerCode]);

  const mapCartItems = groupBy(cartItems, 'branchName');

  const renderCartItems = useMemo(() => {
    return map(mapCartItems, (items, branchName) => {
      return (
        <Accordion key={branchName} defaultExpanded>
          <AccordionContent expandIcon={<ExpandMoreIcon />} onClick={() => handleGTMExpanded(items[0].branchId)}>
            <div className="col-branch-name">
              <div className="branch-name">
                <>
                  {branchName !== branch.erpName ? (
                    <ShippingIcon className="shipping-icon" />
                  ) : (
                    <BranchIcon className="branch-icon" />
                  )}
                  {branchName !== branch.erpName
                    ? t('product_detail.ready_in', { leadTime: items[0].leadTime })
                    : branchName}
                  <div className="number">{items.length}</div>
                </>
              </div>
              {branchName !== branch.erpName && (
                <div className="additional-may-change">{t('checkout.additional_may_charge').toString()}</div>
              )}
            </div>
          </AccordionContent>

          <StyledAccordionDetails>
            {
              items.map(item => <ProductItem key={item.id} item={item} isSecondStep={isSecondStep} />)
            }
          </StyledAccordionDetails>
        </Accordion>
      );
    });
  }, [branch, handleGTMExpanded, isSecondStep, mapCartItems, t]);

  useEffect(() => {
    if (cartItems) {
      const zeroPriceItemFound = !!cartItems.find(item => item.unitPrice === 0);
      if (zeroPriceItemFound) {
        setZeroPriceError(hasZeroPriceProductsMessage);
      } else {
        setZeroPriceError(null);
      }
    } else {
      setZeroPriceError(null);
    }
  }, [cartItems, hasZeroPriceProductsMessage, setZeroPriceError]);

  useEffect(() => {
    if (size(cartItems) && isSecondStep && !isFireEventBeginCheckout) {
      setIsFireEventBeginCheckout(true);
      const items = cartItems.map(item => {
        const { itemCategoryName, itemCategory2Name } = getDefaultCategoryName(item.variant.category.id, categories);
        let itemPrice;
        const sortPrices = item.variant.prices.sort((a, b) => a.qty - b.qty);
        if (size(item.variant.prices) > 1) {
          if (item.quantity >= sortPrices[1].quantity) {
            itemPrice = sortPrices[1][priceType];
          } else {
            itemPrice = sortPrices[0][priceType];
          }
        } else {
          itemPrice = sortPrices[0][priceType];
        }
        return {
          item_id: item.variant.erpSku,
          item_name: getDefaultProductName(item.variant),
          price: itemPrice,
          item_category: itemCategoryName,
          item_category2: itemCategory2Name,
          quantity: item.quantity,
        };
      });
      TagManager.dataLayer({
        dataLayer: {
          event: 'begin_checkout',
          ecommerce: {
            items,
          },
        },
      });
    }
  }, [cartItems, isSecondStep, isFireEventBeginCheckout, categories, priceType]);

  useEffect(() => {
    if (!isSecondStep) {
      setIsFireEventBeginCheckout(false);
    }
  }, [isSecondStep]);

  useEffect(() => {
    return () => {
      setIsFireEventBeginCheckout(false);
    };
  }, []);

  const validationSchema = Yup.object().shape({
    po: isQuotes
      ? Yup.string().max(40, 'po_max_length')
      : Yup.string()
        .trim()
        .required('po_required')
        .max(40, 'po_max_length'),
    tag: Yup.string().max(40, 'tag_max_length'),
    deliveryDate: Yup.date()
      .test('deliveryDate', t('checkout.delivery_date_error'), value => {
        if (value) {
          if (isAfter(value, addYears(new Date(), 2)) || isBefore(new Date(value), new Date())) {
            return false;
          }
        }
        return true;
      })
      .required(orderPricing === DELIVERY ? 'delivery_date_required' : 'pickup_date_required'),
    optInOrder: Yup.boolean().oneOf([true, false], 'opt_in_required').default(false),
  });

  const cartLoading = useSelector(selectCartLoading);

  useEffect(() => {
    if (isEmptyCart) {
      dispatch(changeField('step', CheckoutStep.FIRST));
    }
  }, [isEmptyCart, dispatch]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const initialValues = { deliveryDate: null, tag: '', po: '', note: '', isQuotes, optInOrder: false };
  const initialErrors = {};
  const initialTouched = {};

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setErrors,
  } = useFormik({
    initialValues,
    onSubmit: data => {
      dispatch(setOrderSubmitted());
      purchaseGAEvent(cartItems, price, taxes, currency, selectedOrderId, branch.erpName, !isQuotes);
      dispatch(
        submitOrder({
          orderId: selectedOrderId,
          updatedOrder: {
            note: data.note,
            poNumber: data.po,
            tag: data.tag,
            dateRequired: data.deliveryDate ? formatISO(data.deliveryDate, { representation: 'date' }) : null,
            state: order,
            optInOrder: data.optInOrder,
          },
        }),
      );
    },
    validationSchema,
    initialErrors,
    initialTouched,
  });

  const poRef = createRef<HTMLInputElement>();
  const requestDateRef = createRef<HTMLInputElement>();
  const transactionTypeRef = createRef<HTMLInputElement>();

  const formContextValue = useMemo(
    () => ({
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      poRef,
      requestDateRef,
      transactionTypeRef,
      setErrors,
    }),
    [values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, poRef, requestDateRef, transactionTypeRef, setErrors],
  );

  const getCurrentFormError = (): string => {
    if (isAfter(values.deliveryDate, addYears(new Date(), 2))) {
      return t('checkout.delivery_date_error');
    }
    if (!isQuotes) {
      if (values.po.trim().length) {
        if (values.po.trim().length > 40) {
          return t('checkout.po_max_length');
        }
      } else {
        return t('checkout.po_required');
      }
    }

    if (isQuotes) {
      if (values.po.trim().length) {
        if (values.po.trim().length > 40) {
          return t('checkout.po_max_length');
        }
      }
    }

    if (values.tag.length > 40) {
      return t('checkout.tag_max_length');
    }

    if (values.deliveryDate && !isBefore(new Date(), new Date(values.deliveryDate))) {
      return t('checkout.delivery_date_error');
    }

    if (!values.deliveryDate) {
      return priceType === DELIVERY ? t('checkout.delivery_date_required') : t('checkout.pickup_date_required');
    }

    return '';
  };

  useEffect(() => {
    if (!cartLoading && !cartError && addressWasChanged) {
      dispatch(toggleSnackbarOpen('success', t('checkout.cart_refreshed')));
      dispatch(changeField('addressWasChanged', false));
    }
  }, [cartLoading, cartError, addressWasChanged, dispatch, t]);

  useEffect(() => {
    if (cartError) {
      dispatch(toggleSnackbarOpen('error', cartError.message));
    }
  }, [cartError, dispatch]);

  return (
    <FormContext.Provider value={formContextValue}>
      <Layout>
        <CustomBreadCrumbs>
          <BreadCrumbs currentPageName={t('checkout.pagename')} />
        </CustomBreadCrumbs>
        <Header className="txt-checkout-header">{t('checkout.title').toString()}</Header>
        <Content>
          <>
            <OrdersModal />
            {
              !isEmptyCart && cartItemsNoPrice && cartItemsNoPrice.length > 0 &&
              <ItemsNoPriceModal isShowPopup={cartItemsNoPrice} />
            }
            {
              submitOrderError && <OrdersModalError />
            }
            <ProductsLayout>
              {isEmptyCart || orderError?.code === 404 ? <EmptyCheckout /> : null}
              {orderError?.code !== 404 ? renderCartItems : null}
              {isSecondStep && isQtyUpdated && orderError?.code !== 404 && !isEmptyCart && (
                <>{cartLoading ? null : <CheckoutOptions />}</>
              )}
            </ProductsLayout>
            {orderError?.code !== 404 && (
              <CheckoutSidebar
                isAbleToSecondStep={!isEmptyCart}
                formError={getCurrentFormError()}
                zeroPriceError={zeroPriceError}
              />
            )}
          </>
        </Content>
      </Layout>

      <ConfirmOrderLoadingModal open={orderLoading} handleClose={() => {}} />
    </FormContext.Provider>
  );
};

export default CheckoutPage;
