// Classes
import { CategoryClass } from 'models/classes/categoryClass';
import { IError } from 'models/interfaces/error';

export enum CategoryActionTypes {
  REQUEST_CATEGORIES = '@@category/REQUEST_CATEGORIES',
  REQUEST_CATEGORIES_SUCCESS = '@@category/REQUEST_CATEGORIES_SUCCESS',
  REQUEST_CATEGORIES_ERROR = '@@category/REQUEST_CATEGORIES_ERROR',

	REQUEST_PARENT_CATEGORY = '@@category/REQUEST_PARENT_CATEGORY',
	REQUEST_PARENT_CATEGORY_SUCCESS = '@@category/REQUEST_PARENT_CATEGORY_SUCCESS',
	REQUEST_PARENT_CATEGORY_ERROR = '@@category/REQUEST_PARENT_CATEGORY_ERROR',

  REQUEST_CATEGORY = '@@category/REQUEST_CATEGORY',
  REQUEST_CATEGORY_SUCCESS = '@@category/REQUEST_CATEGORY_SUCCESS',
  REQUEST_CATEGORY_ERROR = '@@category/REQUEST_CATEGORY_ERROR',

  RESET_CATEGORY = '@@category/RESET_CATEGORY',
  RESET_CATEGORIES = '@@category/RESET_CATEGORIES',
  RESET_CATEGORIES_ERROR = '@@category/RESET_CATEGORIES_ERROR',
}

export interface CategoryState {
  readonly category: CategoryClass;
  readonly categories: CategoryClass[];
  readonly parentCategories: CategoryClass[];
  readonly loading: boolean;
  readonly error: IError | null;
  readonly language: 'en_US' | 'en_CA' | 'fr_CA' | null;
}

export interface FetchCategoryByCodeAction {
  type: CategoryActionTypes.REQUEST_CATEGORY;
  payload: {
    code: string;
    language: string;
		addressId: number;
  };
}

export interface FetchCategoriesAction {
  type: CategoryActionTypes.REQUEST_CATEGORIES;
  payload: {
    addressId: number;
    language: string;
    branchId: number;
  };
}

export interface IFetchParentCategoryAction {
	type: CategoryActionTypes.REQUEST_PARENT_CATEGORY;
	payload: {
    branchId: number;
		language: string;
	}
}
