import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IAddress } from 'models/interfaces/user';
import { selectUserAddresses, selectUserData} from 'ducks/user/selectors';
import { selectShippingAddress } from 'ducks/cart/selectors';
import { useOutsideClick } from 'hooks/useOutSideClick';
import { AppStorage } from 'components/App/services/storage';
import { setAddressCode, setAddressId } from 'ducks/application/actions';
import { KeyboardArrowDown } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import {formatPhoneNumber} from 'utils/common/common';
import {getAddressString, getUserType} from 'utils/profile/profileUtilsHelpers';
import TagManager from 'react-gtm-module';
import {PROVINCE_CODES_WITH_NAME} from 'assets/constants/application';
import {patchProfile} from 'ducks/user/actions';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import {
  SAWrapper,
  ASTitle,
  AddressWrapper,
  AddressVisual,
  AddressAutocomplete,
  StyledTextField,
} from './styles';
import {AddressInfo, AddressLabel, AddressName, AddressProperty} from '../../General/Profile/PersonalSettings/styles';

const SelectAddress: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userAddresses = useSelector(selectUserAddresses);
  const user = useSelector(selectUserData);
  const shippingAddress = useSelector(selectShippingAddress);
  const [isShowAddressDropdown, setIsShowAddressDropdown] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const updateOrderAction = async (address: number) => {
    const selectAddress = userAddresses.find(adr => adr.id === address);
    await AppStorage.setDefaultAddressCode(selectAddress.erpPostCode);
    await AppStorage.setDefaultAddressId(selectAddress.id);
    dispatch(setAddressCode(selectAddress.erpPostCode));
    dispatch(setAddressId(selectAddress.id));
    dispatch(patchProfile({defaultAddressId: selectAddress.id}));
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'change_address',
        branch_id: selectAddress.branchId,
        address_code: selectAddress.erpCustomerAddressCode,
        address_id: selectAddress.id,
        customer_code: selectAddress.customer.erpCustomerCode,
        user_type: getUserType(user.email),
        user_ID: selectAddress.customer.id,
      }
    });
  };

  const handleOutSideClick = () => {
    setIsShowAddressDropdown(false);
  };

  const refAddress = useOutsideClick(handleOutSideClick);

  useEffect(() => {
    if (userAddresses && shippingAddress) {
      const userPickAddress = userAddresses.find(address => address.id === shippingAddress);
      setSelectedAddress(userPickAddress);
    }
  }, [userAddresses, shippingAddress]);

  return (
    <SAWrapper>
      <ASTitle>{t('checkout.select_address')}:</ASTitle>

      <AddressWrapper ref={refAddress}>
        {!isShowAddressDropdown && selectedAddress && (
          <AddressVisual onClick={() => setIsShowAddressDropdown(true)}>
            <div className="txt-field-label">{t('checkout.shipping_address')}</div>
            <KeyboardArrowDown className="ic-arrow" />
            <span className="txt-address">{getAddressString(selectedAddress)}</span>
          </AddressVisual>
        )}

        {isShowAddressDropdown && (
          <AddressAutocomplete>
            <Autocomplete
              disablePortal
              options={userAddresses}
              popupIcon={<KeyboardArrowDown />}
              openOnFocus
              disableClearable
              getOptionLabel={(address: IAddress) => getAddressString(address)}
              renderOption={(address: IAddress) => {
                return (
                  <li>
                    <span className={`ic-check ${address.id === shippingAddress ? 'checked' : ''}`} />
                    <span className="txt-address">{getAddressString(address)}</span>
                  </li>
                );
              }}
              onChange={(event, address: IAddress) => {
                updateOrderAction(address.id);
                setIsShowAddressDropdown(false);
              }}
              renderInput={params => (
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  autoFocus
                  label={t('checkout.select_address')}
                  {...params}
                />
              )}
              defaultValue={userAddresses.find(address => address.id === shippingAddress)}
            />
          </AddressAutocomplete>
        )}
        <AddressInfo>
          <AddressLabel>{t('checkout.shipping_address')}</AddressLabel>
          {userAddresses && selectedAddress && (
            <React.Fragment key={selectedAddress.erpPostCode}>
              <AddressName>{selectedAddress.name ?? selectedAddress.postcode}</AddressName>
              <AddressProperty>{selectedAddress.erpAddress1}</AddressProperty>
              <AddressProperty>
                {selectedAddress.erpCity}, {PROVINCE_CODES_WITH_NAME[selectedAddress.provinceCode]}
              </AddressProperty>
              <AddressProperty>{selectedAddress.erpPostCode}</AddressProperty>
              <AddressProperty>
                {PROVINCE_CODES_WITH_NAME[selectedAddress.countryCode]}
              </AddressProperty>
              {
                selectedAddress.erpTelephone1 && <AddressProperty>
                  {formatPhoneNumber(selectedAddress.erpTelephone1)}
                </AddressProperty>
              }
            </React.Fragment>
          )}
        </AddressInfo>
      </AddressWrapper>
    </SAWrapper>
  );
};

export default SelectAddress;
