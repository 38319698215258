import { useDispatch } from 'react-redux';
import { requestAddProductToCart } from 'ducks/cart/actions';

export const useAddProductToCart = () => {
  const dispatch = useDispatch();

  return (productId, quantity, deliveryType, branchId, categoryId) => {
    dispatch(
      requestAddProductToCart({
        productId,
        quantity,
        deliveryType,
        branchId,
        categoryId
      })
    );
  };
};
