import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  CONFIRMED,
  SAVED_PARKED_CREDITCONTROL,
  WAITING_ON_STOCK,
  AWAITING_STOCK,
  PICKING,
  AWAITING_DELIVERY,
  PLEASE_CONTACT_SALES_REP_FOR_PICK_UP,
  SHIPPED,
  DELIVERY_INVOICING,
  PICKED_UP,
  TO_PICK_UP_INVOICING,
  INVOICED,
} from 'assets/constants/order';

export const useCreateFilterObject = (filters, filtersData) => {
  const dispatch = useDispatch();
  const data = filtersData;

  useEffect(() => {
    if (filters.input !== '') {
      data.input = filters.input;
    }

    if (filters.status !== '') {
      if (filters.status === CONFIRMED) {
        data.status = SAVED_PARKED_CREDITCONTROL;
      }

      if (filters.status === WAITING_ON_STOCK) {
        data.status = AWAITING_STOCK;
      }

      if (
        filters.status === PICKING ||
        filters.status === AWAITING_DELIVERY ||
        filters.status === PLEASE_CONTACT_SALES_REP_FOR_PICK_UP
      ) {
        data.status = PICKING;
      }

      if (filters.status === SHIPPED) {
        data.status = DELIVERY_INVOICING;
      }

      if (filters.status === PICKED_UP) {
        data.status = TO_PICK_UP_INVOICING;
      }

      if (filters.status === INVOICED) {
        data.status = INVOICED;
      }
    }

    if (filters.contact !== '') {
      data.contact = filters.contact;
    }

    if (filters.address !== '') {
      data.address = filters.address;
    }

    if (filters.from !== '') {
      data.from = filters.from;
    }

    if (filters.to !== '') {
      data.to = filters.to;
    }

    data.sort = filters.sort;
    // Be careful with automatic ESLint and Prettier,  [dispatch, filters] should only be presented here,
  }, [dispatch, filters]);
};
