import React from 'react';
import { StyledA, SliderImg } from './styles';

interface IBannerProps {
  url: string;
  path: string;
}

const Banner: React.FC<IBannerProps> = ({ url, path }: IBannerProps) => {
  return (
    <div>
      <StyledA aria-label="slider" isUrl={Boolean(url)} href={url}>
        <SliderImg src={path} alt="" />
      </StyledA>
    </div>
  );
};

export default Banner;
