import { all, put, takeEvery, call } from 'redux-saga/effects';

import axios from 'utils/axios';

import { FAQClass } from 'models/classes/faqClass';
import { faqTofaqClass } from 'utils/FAQ/FAQ';
import { storeFAQ } from 'ducks/FAQ/actions';
import { FAQActionTypes } from 'ducks/FAQ/types';
import { storeErrors } from 'utils/errors';

function* requestFAQ() {
  try {
    const { data: response } = yield call(axios.get, '/api/faq');

    const FAQ: FAQClass[] = response.data.map(faq => faqTofaqClass(faq));
    yield put(storeFAQ(FAQ));
  } catch (error) {
    yield storeErrors(FAQActionTypes.REQUEST_FAQ_ERROR, [400], error.response)
  }
}

function* watchRequestFAQ() {
  yield takeEvery(FAQActionTypes.REQUEST_FAQ, requestFAQ);
}

export default function* faqSaga() {
  yield all([watchRequestFAQ()]);
}
