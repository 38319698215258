import {useSelector} from 'react-redux';
import {selectCartItems} from 'ducks/cart/selectors';
import TagManager from 'react-gtm-module';
import {clearGtmEcommerce} from 'utils/clearGtmEcommerce';
import {getPriceByDeliveryType} from 'utils/products/productVariant';
import {priceTypeSelector} from 'ducks/application/selectors';
import {getDefaultCategoryName} from 'utils/categories/categories';
import {selectCategories} from 'ducks/category/selectors';
import {getDefaultProductName} from 'utils/products/productUtilsHelpers';
import {ICartItem} from 'models/interfaces/cart';

export const useGA4CartEvent = () => {
  const cartItems = useSelector(selectCartItems);
  const deliveryType = useSelector(priceTypeSelector);
  const categories = useSelector(selectCategories);
  const handleFireGA4ViewCart = () => {
    clearGtmEcommerce();
    const items =
      Array.isArray(cartItems) && cartItems.length
        ? cartItems.map((item: ICartItem) => {
          const { itemCategoryName, itemCategory2Name } = getDefaultCategoryName(
            item.categoryId,
            categories
          );
          return {
            item_id: item.variant.erpSku,
            item_name: getDefaultProductName(item.variant),
            price: getPriceByDeliveryType(deliveryType, item),
            item_category: itemCategoryName,
            item_category2: itemCategory2Name,
            quantity: item.quantity,
          };
        })
        : [];
    TagManager.dataLayer({
      dataLayer: {
        event: 'view_cart',
        ecommerce: {
          items
        }
      }
    })
  }

  const handleFireGA4RemoveFromCartSubTract = (item: ICartItem, quantity: number = 1) => {
    clearGtmEcommerce();
    const {itemCategoryName, itemCategory2Name} = getDefaultCategoryName(item.categoryId, categories);
    TagManager.dataLayer({
      dataLayer: {
        event: 'remove_from_cart',
        ecommerce: {
          items: [
            {
              item_id: item.variant.erpSku,
              item_name: getDefaultProductName(item.variant),
              price: getPriceByDeliveryType(deliveryType, item),
              item_category: itemCategoryName,
              item_category2: itemCategory2Name,
              quantity: Math.abs(quantity)
            }
          ]
        }
      }
    })
  }

  const handleFireGA4AddToCartPlus = (item: ICartItem, quantity: number = 1) => {
    clearGtmEcommerce();
    const {itemCategoryName, itemCategory2Name} = getDefaultCategoryName(item.categoryId, categories);
    TagManager.dataLayer({
      dataLayer: {
        event: 'add_to_cart',
        ecommerce: {
          items: [
            {
              item_id: item.variant.erpSku,
              item_name: getDefaultProductName(item.variant),
              price: getPriceByDeliveryType(deliveryType, item),
              item_category: itemCategoryName,
              item_category2: itemCategory2Name,
              quantity: Math.abs(quantity)
            }
          ]
        }
      }
    })
  }

  const handleFireGA4RemoveItemFromCart = (item: ICartItem) => {
    clearGtmEcommerce();
    const {itemCategoryName, itemCategory2Name} = getDefaultCategoryName(item.categoryId, categories);
    TagManager.dataLayer({
      dataLayer: {
        event: 'remove_from_cart',
        ecommerce: {
          items: [
            {
              item_id: item.variant.erpSku,
              item_name: getDefaultProductName(item.variant),
              price: getPriceByDeliveryType(deliveryType, item),
              item_category: itemCategoryName,
              item_category2: itemCategory2Name,
              quantity: item.quantity,
            }
          ]
        }
      }
    })
  }

  const handleFireGA4RemoveCart = () => {
    clearGtmEcommerce();
    const items = cartItems.map((item: ICartItem) => {
      const {itemCategoryName, itemCategory2Name} = getDefaultCategoryName(item.categoryId, categories);
      return {
        item_id: item.variant.erpSku,
        item_name: getDefaultProductName(item.variant),
        price: getPriceByDeliveryType(deliveryType, item),
        item_category: itemCategoryName,
        item_category2: itemCategory2Name,
        quantity: item.quantity,
      }
    })
    TagManager.dataLayer({
      dataLayer: {
        event: 'remove_from_cart',
        ecommerce: {
          items
        }
      }
    })
  }

  return {handleFireGA4ViewCart, handleFireGA4RemoveFromCartSubTract, handleFireGA4RemoveItemFromCart, handleFireGA4AddToCartPlus, handleFireGA4RemoveCart};
}