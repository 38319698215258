// Types
import { AppActionTypes } from 'ducks/application/types';
import { IError } from 'models/interfaces/error';

export const menuIsOpened = () => ({
  type: AppActionTypes.MENU_IS_OPENED,
});

export const menuIsClosed = () => ({
  type: AppActionTypes.MENU_IS_CLOSED,
});

export const setPriceType = (priceType: string) => ({
  type: AppActionTypes.SET_PRICES_TYPE,
  payload: {
    priceType,
  },
});

export const setServerError = (error: IError) => ({
  type: AppActionTypes.SET_SERVER_ERROR,
  payload: {
    error,
  },
});

export const setDefaultBanner = (url: string) => ({
  type: AppActionTypes.SET_DEFAULT_BANNER,
  payload: {
    url,
  },
});

export const setDefaultBackground = (url: string) => ({
  type: AppActionTypes.SET_DEFAULT_BACKGROUND,
  payload: {
    url,
  },
});

export const setAddressCode = (addressCode: string) => ({
  type: AppActionTypes.SET_ADDRESS_CODE,
  payload: {
    addressCode,
  },
});

export const setAddressId = (addressId: number) => ({
  type: AppActionTypes.SET_ADDRESS_ID,
  payload: {
    addressId,
  },
});

interface IContactUs {
	name: string;
	email: string;
	phone?: string;
	subject: string;
	comment: string;
}

export const sendContactUsEmail = (payload: IContactUs) => ({
  type: AppActionTypes.REQUEST_SEND_CONTACT_US,
  payload: {
    ...payload,
  },
});

export const getBanner = (code: string, branch: number) => ({
  type: AppActionTypes.REQUEST_STORE_BANNER,
  payload: {
    code,
    branch,
  },
});

export const setLanguage = (language: string) => ({
  type: AppActionTypes.SET_LANGUAGE,
  payload: {
    language,
  },
});

export const openRecaptchaModal = () => ({
  type: AppActionTypes.OPEN_RECAPTCHA_MODAL,
});

export const closeRecaptchaModal = () => ({
  type: AppActionTypes.CLOSE_RECAPTCHA_MODAL,
});
export const fetchSpecialPage = () => ({
  type: AppActionTypes.REQUEST_GET_SPECIAL_PAGE,
})

export const setIsHidePrice = (isHidePrice: boolean) => ({
  type: AppActionTypes.REQUEST_IS_HIDE_PRICE,
  payload: {
    isHidePrice,
  },
});