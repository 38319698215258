/* eslint-disable no-console */
import React, {useEffect, useState} from 'react';
import { DocumentIcon } from 'assets/svg/Product/Products';
import {getProductVariantDocuments} from 'api/ProductVariant';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectAddressId, selectErpCustomerAddressCode } from 'ducks/application/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import get from 'lodash/get';
import {
  DescriptionTab,
  DocumentsWrapper,
  SingleDocumentWrapper,
  DocumentInfo,
  DocumentMeta,
  DocumentName,
  StyledLoadingDivWrapper,
  StyledLoadingDiv,
} from '../styles';

interface ISpecificationsTab {
  productId: number;
  addressId: number;
  selectedLanguage: 'en_US' | 'en_CA' | 'fr_CA';
  isMobile?: boolean;
}

function ConvertFileSize(bytes) {
  if (bytes === 0) {
    return '0.00 B';
  }
  const e = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / 1024 ** e).toFixed(2)} ${' KMGTP'.charAt(e)}B`;
}

export const DocumentationsTab = ({
  productId,
  addressId,
  selectedLanguage,
  isMobile = false,
}: ISpecificationsTab) => {
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const [productDocuments, setProductDocuments] = useState([]);

  useEffect(() => {
    if (productId && addressId && selectedLanguage) {
      getProductVariantDocuments({
        productId,
        addressId,
        language: selectedLanguage,
      }).then(res => {
        setProductDocuments(res.data);
      });
    }
  }, [productId, addressId, selectedLanguage]);

  if (!productDocuments) {
    return (
      <StyledLoadingDivWrapper>
        <StyledLoadingDiv />
      </StyledLoadingDivWrapper>
    );
  }

  return !isMobile ? (
    <div role="tabpanel">
      <DescriptionTab>
        <DocumentsWrapper>
          {productDocuments &&
            get(productDocuments, 'data', []).map(document => (
              <SingleDocumentWrapper
                onClick={() => {
                  clearGtmEcommerce();
                  TagManager.dataLayer({
                    dataLayer: {
                      event: 'download_document',
                      user_type: getUserType(user.email),
                      branch_id: branch.id,
                      customer_code: user?.customer?.erpCustomerCode,
                      address_code: erpCustomerAddressCode,
                      address_id: selectedAddressId,
                    },
                  });
                }}
                key={document.name}
                href={document.url}
                target="_blank"
              >
                <DocumentIcon />
                <DocumentInfo>
                  <DocumentName>{document.name}</DocumentName>
                  <DocumentMeta>
                    {document.extension}, {ConvertFileSize(document.size)}
                  </DocumentMeta>
                </DocumentInfo>
              </SingleDocumentWrapper>
            ))}
        </DocumentsWrapper>
      </DescriptionTab>
    </div>
  ) : (
    <>
      {productDocuments &&
        get(productDocuments, 'data', []).map(document => (
          <SingleDocumentWrapper key={document.name} href={document.url} target="_blank">
            <DocumentIcon />
            <DocumentInfo>
              <DocumentName>{document.name}</DocumentName>
              <DocumentMeta>
                {document.extension}, {ConvertFileSize(document.size)}
              </DocumentMeta>
            </DocumentInfo>
          </SingleDocumentWrapper>
        ))}
    </>
  );
};
