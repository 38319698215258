import { createSelector } from 'reselect';
import { RootState } from 'store';

const selectAppState = (state: RootState) => state.app;

export const menuIsOpenedSelector = createSelector(
  selectAppState,
  appState => appState.menuIsOpened,
);

export const priceTypeSelector = createSelector(selectAppState, appState => appState.priceType);
export const serverErrorSelector = createSelector(selectAppState, appState => appState.serverError);
export const backgroundSelector = createSelector(selectAppState, appState => appState.background);
export const bannerSelector = createSelector(selectAppState, appState => appState.banner);
export const selectAppLoading = createSelector(selectAppState, appState => appState.loading);
export const selectAddressCode = createSelector(
  selectAppState,
  appState => appState.selectedAddressCode,
);
export const selectAddressId = createSelector(
  selectAppState,
  appState => appState.selectedAddressId,
);
export const selectAppError = createSelector(selectAppState, appState => appState.error);

export const selectStoreBanner = createSelector(selectAppState, appState => appState.storeBanner);

export const selectLanguage = createSelector(selectAppState, appState => appState.language);

export const selectErpCustomerAddressCode = createSelector((state: RootState) => state, appState => appState.user.userData?.addresses?.find(address => address.id === appState.app.selectedAddressId)?.erpCustomerAddressCode);
export const selectSpecialPages = createSelector(selectAppState, appState => appState.specialPages);
export const selectLoadingSpecialPage = createSelector(selectAppState, appState => appState.loadingSpecialPage);
export const selectIsHidePrice = createSelector(selectAppState, appState => appState.isHidePrice);
