import styled from 'styled-components/macro';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { ListViewMinus, ListViewPlus } from 'assets/svg/Product/Products';
import { useSelector } from 'react-redux';
import { IPrice } from 'models/interfaces/productVariant';
import {
  priceTypeSelector,
  selectAddressId,
  selectErpCustomerAddressCode, selectIsHidePrice,
} from 'ducks/application/selectors';
import { PRODUCT_PRICING_METHOD_BLENDED } from 'ducks/productVariant/types';
import { numberFormat as f } from 'utils/products/product';
import { IOrderItem } from 'models/interfaces/order';
import { filterPrices } from 'utils/products/productVariant';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';
import TagManager from 'react-gtm-module';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';

const StyledInputWrapper = styled.label`
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  max-width: 132px;
  overflow: hidden;
`;

const IncrementButton = styled.button`
  height: 30px;
  border: none;
  border-left: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { colors } }) => colors.white};
  outline: none;
  width: 80px;

  &:hover {
    cursor: pointer;
  }

  &:first-child {
    border: none;
    border-right: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  }
`;

const StyledTextField = styled(TextField as any)`
  .MuiInputBase-input {
    text-align: center;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const IncrementButtonLabel = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.01em;
`;
const StyledInputsGrid = styled(Grid as any)`
  padding-top: 8px;
`;

const StyledInputsGridInner = styled.div`
  display: inline-flex;
  align-items: center;
`;

const PromotionText = styled.p`
  text-decoration: underline;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  &:hover {
    font-size: 18px;
    font-weight: 900;
  }
`;

interface IQuantityInput {
  orderItem: IOrderItem;
  setPrice: React.Dispatch<React.SetStateAction<number>>;
  setIsUsePromotionPrice: React.Dispatch<React.SetStateAction<boolean>>;
  setItemQuantity: React.Dispatch<React.SetStateAction<any>>;
  setCountErrorUpdateQuantity: React.Dispatch<React.SetStateAction<any>>;
  setCanReorder: React.Dispatch<React.SetStateAction<any>>;
  itemQuantity: Array<any>;
  selectReorderItems: Array<number>;
  countErrorUpdateQuantity: Array<number>;
}

export const QuantityInput = React.memo(
  ({
    orderItem,
    setPrice,
    setIsUsePromotionPrice,
    setItemQuantity,
    setCountErrorUpdateQuantity,
    countErrorUpdateQuantity,
    itemQuantity,
    selectReorderItems,
    setCanReorder,
  }: IQuantityInput) => {
    const { t } = useTranslation();
    const buttonBottomRef = useRef();
    const deliveryType = useSelector(priceTypeSelector);
    const user = useSelector(selectUserData);
    const branch = useSelector(selectBranch);
    const selectedAddressId = useSelector(selectAddressId);
    const isHidePrice = useSelector(selectIsHidePrice);
    const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
    const filterProductPrices = useMemo(() => filterPrices(orderItem.prices), [orderItem.prices]);
    const [quantity, setQuantity] = useState(0);
    const [showUpdateWrongQuantity, setShowUpdateWrongQuantity] = useState(false);
    const productCurrentPrice = filterProductPrices[0];
    const productPromotionPrice = filterProductPrices[filterProductPrices.length - 1];
    const quantityStep = filterProductPrices[0].qty;
    const timeClickGtm = useRef(0);

    const handleGtmEventChangeUOM = () => {
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'reorder_change_UOM',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        },
      });
      timeClickGtm.current = Date.now() / 1000;
    };

    const handleDecrement = () => {
      if (
        quantity - quantityStep >= 1 &&
        selectReorderItems.includes(orderItem.id) &&
        quantity % quantityStep === 0
      ) {
        const newQuantity = quantity - quantityStep;
        setQuantity(newQuantity);
        setShowUpdateWrongQuantity(false);
        if (timeClickGtm.current === 0) {
          handleGtmEventChangeUOM();
        } else if (timeClickGtm.current > 0 && Date.now() / 1000 - timeClickGtm.current > 10) {
          handleGtmEventChangeUOM();
        }
      }
      if (
        quantity - quantityStep >= 1 &&
        selectReorderItems.includes(orderItem.id) &&
        quantity % quantityStep !== 0
      ) {
        setQuantity(quantityStep);
        setShowUpdateWrongQuantity(false);
        if (timeClickGtm.current === 0) {
          handleGtmEventChangeUOM();
        } else if (timeClickGtm.current > 0 && Date.now() / 1000 - timeClickGtm.current > 10) {
          handleGtmEventChangeUOM();
        }
      }
    };

    const handleIncrement = () => {
      if (selectReorderItems.includes(orderItem.id) && quantity % quantityStep === 0) {
        setQuantity(quantity + quantityStep);
        setShowUpdateWrongQuantity(false);
        if (timeClickGtm.current === 0) {
          handleGtmEventChangeUOM();
        } else if (timeClickGtm.current > 0 && Date.now() / 1000 - timeClickGtm.current > 10) {
          handleGtmEventChangeUOM();
        }
      }
      if (selectReorderItems.includes(orderItem.id) && quantity % quantityStep !== 0) {
        setQuantity(quantityStep * 2);
        setShowUpdateWrongQuantity(false);
        if (timeClickGtm.current === 0) {
          handleGtmEventChangeUOM();
        } else if (timeClickGtm.current > 0 && Date.now() / 1000 - timeClickGtm.current > 10) {
          handleGtmEventChangeUOM();
        }
      }
    };

    const handleTopFieldChange = (value: string) => {
      if (timeClickGtm.current === 0) {
        handleGtmEventChangeUOM();
      } else if (timeClickGtm.current > 0 && Date.now() / 1000 - timeClickGtm.current > 10) {
        handleGtmEventChangeUOM();
      }

      if (selectReorderItems.includes(orderItem.id) && Number(value) > 0) {
        setQuantity(parseInt(value, 10));
      }
      if (Number(value) < 0 || Number(value) === 0) {
        setQuantity(state => state);
      }
    };

    React.useEffect(() => {
      if (orderItem) {
        if (orderItem.quantity > quantityStep) {
          setQuantity(Math.floor(orderItem.quantity / quantityStep) * quantityStep);
        } else {
          setQuantity(quantityStep);
        }
      }
    }, [orderItem]);

    React.useEffect(() => {
      if (showUpdateWrongQuantity) {
        const uniqueOrderArr = uniq(countErrorUpdateQuantity);
        uniqueOrderArr.push(orderItem.id);
        setCountErrorUpdateQuantity(uniqueOrderArr);
      } else {
        const uniqueOrderArr = uniq(countErrorUpdateQuantity);
        const index = uniqueOrderArr.indexOf(orderItem.id);
        uniqueOrderArr.splice(index, 1);
        setCountErrorUpdateQuantity(uniqueOrderArr);
      }
    }, [showUpdateWrongQuantity]);

    useEffect(() => {
      if (countErrorUpdateQuantity.length === 0) {
        setCanReorder(true);
      } else {
        setCanReorder(false);
      }
    }, [countErrorUpdateQuantity]);

    React.useEffect(() => {
      const cloneItemQuantity = cloneDeep(itemQuantity);
      const index = cloneItemQuantity.findIndex(item => item.id === orderItem.id);
      if (orderItem.pricingMethod === PRODUCT_PRICING_METHOD_BLENDED) {
        if (orderItem.prices.length > 1) {
          const topQuantity = parseInt(
            String(productPromotionPrice?.qty || productCurrentPrice?.qty),
            10
          );
          if (quantity >= topQuantity) {
            const promotionPrice = productPromotionPrice[deliveryType];
            const productOutGroup = quantity % topQuantity;
            const productInGroup = quantity - productOutGroup;
            setPrice(
              parseFloat(
                f(
                  productCurrentPrice[deliveryType] * productOutGroup +
                    promotionPrice * productInGroup
                )
              )
            );
            setIsUsePromotionPrice(true);
            cloneItemQuantity[index].price = parseFloat(
              f(
                productCurrentPrice[deliveryType] * productOutGroup +
                  promotionPrice * productInGroup
              )
            );
            cloneItemQuantity[index].quantity = quantity;
          } else {
            cloneItemQuantity[index].price = parseFloat(
              f(productCurrentPrice[deliveryType] * quantity)
            );
            cloneItemQuantity[index].quantity = quantity;
            setPrice(parseFloat(f(productCurrentPrice[deliveryType] * quantity)));
            setIsUsePromotionPrice(false);
          }
        }
      } else {
        const topQuantity = parseInt(
          String(productPromotionPrice?.qty || productCurrentPrice?.qty),
          10
        );
        if (quantity >= topQuantity) {
          setPrice(parseFloat(f(productPromotionPrice[deliveryType] * quantity)));
          cloneItemQuantity[index].price = parseFloat(
            f(productPromotionPrice[deliveryType] * quantity)
          );
          cloneItemQuantity[index].quantity = quantity;
          setIsUsePromotionPrice(true);
        } else if (quantity > 0 && quantity < topQuantity) {
          setPrice(parseFloat(f(productCurrentPrice[deliveryType] * quantity)));
          cloneItemQuantity[index].price = parseFloat(
            f(productCurrentPrice[deliveryType] * quantity)
          );
          cloneItemQuantity[index].quantity = quantity;
          setIsUsePromotionPrice(false);
        }
      }
      setItemQuantity(cloneItemQuantity);
    }, [quantity, deliveryType]);

    useEffect(() => {
      const cloneItemQuantity = cloneDeep(itemQuantity);
      if (
        selectReorderItems.includes(orderItem.id) &&
        quantity % quantityStep === 0 &&
        quantity > 0 &&
        cloneItemQuantity.length
      ) {
        setShowUpdateWrongQuantity(false);
      } else if (
        selectReorderItems.includes(orderItem.id) &&
        quantity % quantityStep !== 0 &&
        quantity > 0
      ) {
        setShowUpdateWrongQuantity(true);
      }
    }, [selectReorderItems, quantity, deliveryType, itemQuantity]);

    const renderPromotionPrice = (prices: IPrice[]) => {
      if (isHidePrice) return null;
      const topQuantity = parseInt(
        String(productPromotionPrice?.qty || productCurrentPrice?.qty),
        10
      );
      if (prices.length > 1 && quantity < topQuantity) {
        const sortPrices = prices.sort((a, b) => b.qty - a.qty);
        const promotionPrice = sortPrices[0];
        const normalPrice = sortPrices[1];
        if (promotionPrice[deliveryType] < normalPrice[deliveryType]) {
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
          return (
            <PromotionText
              onClick={() => {
                clearGtmEcommerce();
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'reorder_click_upsell',
                    user_type: getUserType(user.email),
                    branch_id: branch.id,
                    customer_code: user?.customer?.erpCustomerCode,
                    address_code: erpCustomerAddressCode,
                    address_id: selectedAddressId,
                  },
                });
                handleTopFieldChange(String(promotionPrice.qty));
              }}
              style={{ display: 'inline-flex', cursor: 'pointer', margin: '8px 0' }}
            >
              {t('product_detail.buy')} {promotionPrice.qty} {t('product_detail.for')} $
              {promotionPrice[deliveryType]}/{orderItem.units.perName}
            </PromotionText>
          );
        }
      }
      return null;
    };

    const renderUpdateWrongQuantity = () => {
      return (
        showUpdateWrongQuantity && (
          <p style={{ color: 'red', margin: '8px 0' }}>
            {t('price_calculator.divisible', { minQuantity: quantityStep }).toString()}
          </p>
        )
      );
    };

    return (
      <StyledInputsGrid item>
        <StyledInputsGridInner>
          <StyledInputWrapper>
            <IncrementButton aria-label="minus" ref={buttonBottomRef} onClick={handleDecrement}>
              <ListViewMinus />
            </IncrementButton>
            <StyledTextField
              type="number"
              InputProps={{ disableUnderline: true }}
              onChange={e => handleTopFieldChange(e.target.value as string)}
              value={quantity}
            />
            <IncrementButton aria-label="plus" onClick={handleIncrement}>
              <ListViewPlus />
            </IncrementButton>
          </StyledInputWrapper>
          <IncrementButtonLabel>{orderItem.units.perName}</IncrementButtonLabel>
        </StyledInputsGridInner>
        {renderPromotionPrice(orderItem.prices)}
        {renderUpdateWrongQuantity()}
      </StyledInputsGrid>
    );
  }
);
