import React from 'react';
import styled from 'styled-components/macro';
import { Grid } from '@material-ui/core';
import LocationDropdown from 'components/Layout/Header/Dropdowns/LocationDropdown/LocationDropdown';

const StyledLocationDropdownWrapper = styled.div`
  @media only screen and (max-width: 599px) {
    display: flex;
  }
  .MuiFormControl-root {
    @media only screen and (max-width: 599px) {
      justify-content: flex-end;
      min-width: 56vw;
      margin-right: 0;
      margin-left: auto;
    }
  }
  .MuiAutocomplete-popper {
    position: absolute;
    right: 0;
    top: 31px;
    min-width: 284px;
  }
`;

const StyledLocationDropdown = styled(LocationDropdown)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.textColor};
  margin: 0 8px 0 12px;
`;

const StyledDiv = styled.div`
  position: absolute;
  top: 72px;
  z-index: 1500;
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  box-sizing: border-box;
  background: ${({ theme: { colors } }) => colors.white};
  width: 100%;
  align-self: center;
  flex: 1;
  padding: 0;
`;

const MobileMainNav = styled.ul`
  background: ${({ theme: { colors } }) => colors.white};
  list-style: none;
  padding: 0 0 30px;
  margin: 0;
  .bb-0 {
    border-bottom: 0 !important;
  }
  .sub-menu-title {
    color: #001a3b;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.1px;
    border-bottom: 0 !important;
    padding: 0 24px;
    margin-top: 16px;
    margin-bottom: 4px;
  }
  .new-label {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #fff;
    background: #f79009;
    border-radius: 4px;
    padding: 0 8px;
    margin-left: 14px;
  }
  ul {
    /* all ul */
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    /* all li */
    &:hover {
      > .nav-link {
        background: ${({ theme: { colors } }) => colors.whiteSmoke};
        &:before {
          opacity: 1;
        }
      }
      > .sub-menu.full-height {
        display: block;
      }
    }
    a {
      color: inherit;
      text-decoration: none;
    }
    > .nav-link {
      display: block;
      position: relative;
      transition: all 0.4s ease;
      padding-left: 24px;
      padding-right: 24px;
      &.active {
        background: ${({ theme: { colors } }) => colors.whiteSmoke};
        &:before {
          opacity: 1;
        }
      }
      &:before {
        opacity: 0;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 100%;
        background: ${({ theme: { colors } }) => colors.mainGreen};
        transition: all 0.4s ease;
      }
      > svg {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
  > li {
    /* first li */
    > .nav-link {
      padding-left: 0;
      > .nav-link-title {
        display: block;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        color: #007A33;
        border-bottom: 1px solid #dfe2e8;
        padding: 13px 20px;
        > a {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
    > .sub-menu {
      > li {
        > .nav-link {
          > .nav-link-title {
            font-weight: bold;
            color: ${({ theme: { colors } }) => colors.switchCheckedGreen};
          }
        }
      }
    }
  }
  .sub-menu {
    /* all sub-menu */
    > li {
      border-bottom: 1px solid #f6f6f6;
      > .nav-link {
        > .nav-link-title {
          display: block;
          font-family: Open Sans;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
          color: #007A33;
          padding: 11px 0;
        }
      }
    }
  }
  .sub-menu.full-height {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100%;
    background: ${({ theme: { colors } }) => colors.white};
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
    z-index: 2;
    .sub-menu-back {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dfe2e8;
      padding: 14.5px 20px;
      .btn-back,
      .btn-close {
        display: flex;
        align-items: center;
        color: #2f80ed;
        cursor: pointer;
      }
      .btn-back {
        svg {
          margin-right: 5px;
        }
      }
      .btn-close {
        text-decoration: underline;
      }
    }
  }
  .product-menu-list,
  .taiga-now-features-menu-list,
  .your-account-menu-list {
    border-bottom: 7px solid #f6f6f6;
  }

  .taiga-now-features-menu-list {
    .nav-item-quick-order {
      @media (max-width: 1023px) {
        //display: none;
      }
    }
  }
  .use-and-help-menu-list {
    .sub-menu > li > .nav-link > .nav-link-title {
      color: #48576B;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
    }
  }
  .your-account-menu-list {
    .nav-link-order-history br {
      display: none;
    }
  }
`;

const StyledPricingGrid = styled(Grid)`
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 26px;
`;

const StyledLocationGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dfe2e8;
  padding: 16.5px 20px;
`;

const StyledContainer = styled(({ children, ...rest }) => (
  <Grid container {...rest}>
    {children}
  </Grid>
))`
  background: ${({ theme: { colors } }) => colors.white};
`;

const BranchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BranchBlock = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  @media only screen and (max-width: 599px) {
    max-width: 40vw;
  }
`;

const StyledParagraph = styled.p`
  margin: 0 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.xSmall};
  line-height: 20px;
  letter-spacing: 0.01em;
  white-space: nowrap;
  color: ${({ theme: { colors } }) => colors.letterGrey};
  @media only screen and (max-width: 599px) {
    white-space: normal;
    line-height: 1.5;
  }
`;

export {
  StyledDiv,
  StyledContainer,
  MobileMainNav,
  StyledLocationDropdownWrapper,
  StyledLocationDropdown,
  StyledLocationGrid,
  StyledPricingGrid,
  BranchWrapper,
  BranchBlock,
  StyledParagraph,
};
