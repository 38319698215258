import { useSelector } from 'react-redux';
import { useProductListBreadCrumbs } from 'pages/Product/hooks';
import { selectCategories } from 'ducks/category/selectors';

const useShowSecondLayout = (pathname: string): [boolean, boolean] => {
  const categories = useSelector(selectCategories);
  const { mainCategoryCode, categoryCode } = useProductListBreadCrumbs(pathname);
  return [
    !!categories.filter(category => category.code === mainCategoryCode)[0]?.showSecondLayout,
    !!categories
      .filter(category => category.code === mainCategoryCode)[0]
      ?.subcategories?.filter(subcategory => subcategory.code === categoryCode)[0]
      ?.showSecondLayout,
  ];
};

export default useShowSecondLayout;
