import React, { useState, useEffect, Fragment } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {InputLabel} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import {patchProfile, setUpdateUserProfileStatus} from 'ducks/user/actions';
import { ChangePasswordModal } from 'pages/General/Profile/index';
import { CustomizedSnackbar } from 'components/common';
import {selectUpdateUserProfileStatus, selectUserAddresses, selectUserData} from 'ducks/user/selectors';
import { selectChangePasswordStatus } from 'ducks/profile/selectors';
import { PROVINCE_CODES_WITH_NAME } from 'assets/constants/application';
import { formatPhoneNumber } from 'utils/common/common';
import { refreshPasswordState } from 'ducks/profile/actions';
import { useOutsideClick } from 'hooks/useOutSideClick';

import { useTranslation } from 'react-i18next';
import { getShortLanguageCode } from 'i18n';

import { selectSiteLocales } from 'ducks/siteLocale/selectors';
import {getAddressString, getUserType} from 'utils/profile/profileUtilsHelpers';
import {useHistory} from 'react-router-dom';
import Routes from 'routes';
import {KeyboardArrowDown} from '@material-ui/icons';
import {Autocomplete} from '@material-ui/lab';
import {IAddress} from 'models/interfaces/user';
import {setAddressCode, setAddressId} from 'ducks/application/actions';
import {selectAddressId} from 'ducks/application/selectors';
import TagManager from 'react-gtm-module';
import {
  FormsContainer,
  LanguagePricingWrapper,
  ShippingAddressWrapper,
  ShippingAddressVisual,
  AddressAutocomplete,
  NameEmailWrapper,
  ChangePassword,
  AddressInfo,
  AddressName,
  AddressProperty,
  AddressLabel,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
  StyledTextFieldDisabled,
  StyledFormControl,
  StyledFormButton,
  GreenCheckbox,
  CheckboxTitle,
  CheckboxWrapperWithoutMargin,
} from './styles';
import {AppStorage} from '../../../../components/App/services/storage';


const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('first_name_is_required')
    .min(2, 'name_min_length')
    .max(255, 'name_max_length'),
  lastName: Yup.string()
    .required('last_name_is_required')
    .min(2, 'name_min_length')
    .max(255, 'name_max_length'),
  email: Yup.string().max(255, 'email_max_length'),
  defaultLocaleId: Yup.number().required('default_language_required'),
  defaultPricing: Yup.string().required('default_pricing_required'),
  defaultShippingAddressId: Yup.string().required('default_address_required'),
  address: Yup.string()
    .min(10, 'address_min_length')
    .max(4095, 'address_max_length'),
});

interface IDefaultLocale {
	id?: number;
  code?: string;
  name?: string;
}

interface IDefaultAddress {
  erpAddress1?: string;
	erpAddress2?: string;
  city?: string;
  code?: string;
  countryCode?: string;
  id?: number;
  name?: string;
  phoneNumber?: string;
  postcode?: string;
  provinceCode?: string;
  street?: string;
}

interface IAddresses extends Array<IAddress> {}

interface IInitialState {
  defaultLocale?: IDefaultLocale;
  defaultDisplayLanguage?: IDefaultLocale;
  defaultPricing?: string;
  defaultShippingAddress?: string;
  defaultAddress?: IDefaultAddress;
  addresses?: IAddresses;
  firstName?: string;
  lastName?: string;
  isEmailEnabled?: boolean;
  isMailchimpEnabled?: boolean;
}

interface IRequestData {
	defaultLocaleId?: number;
	defaultDisplayLanguageId?: number;
  defaultPricing?: string;
	defaultShippingAddressId?: number;
  firstName?: string;
  lastName?: string;
  isEmailEnabled?: boolean;
  isMailchimpEnabled?: boolean;
}

const useStyles = makeStyles({
  inputLabel: {
    fontFamily: 'Open Sans',
  },
});

const PersonalSettings: React.FC = () => {
  const contactProfile = useSelector(selectUserData);
  const updateUserProfileStatus = useSelector(selectUpdateUserProfileStatus);
  const changePasswordStatus = useSelector(selectChangePasswordStatus);
  const siteLocales = useSelector(selectSiteLocales);
  const selectedAddressId = useSelector(selectAddressId);
  const userAddresses = useSelector(selectUserAddresses);
  const user = useSelector(selectUserData);
  const classes = useStyles();

  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openUpdateProfile, setOpenUpdateProfile] = React.useState(false);
  const [initialState, setInitialState] = useState<IInitialState>(null);
  const [isShowShippingAddressDropdown, setIsShowShippingAddressDropdown] = useState(false);

  const history = useHistory();

  const requestData: IRequestData = {};

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      defaultLocaleId: -1,
      defaultPricing: '',
      defaultShippingAddressId: selectedAddressId,
      address: null,
      isEmailEnabled: false,
      isMailchimpEnabled: false,
      defaultDisplayLanguageId: 0,
    },
    validationSchema,
    onSubmit() {
      if (
        (initialState && !initialState.defaultLocale) ||
        (initialState && formik.values.defaultLocaleId !== initialState.defaultLocale.id)
      ) {
        requestData.defaultLocaleId = formik.values.defaultLocaleId;
      }

      if (initialState && formik.values.defaultDisplayLanguageId !== initialState.defaultDisplayLanguage.id) {
        requestData.defaultDisplayLanguageId = formik.values.defaultDisplayLanguageId;
        const defaultLocale = siteLocales.filter(language => language.id === formik.values.defaultDisplayLanguageId);
        i18n.changeLanguage(getShortLanguageCode(defaultLocale[0].code));
      }

      if (initialState && formik.values.defaultPricing !== initialState.defaultPricing) {
        requestData.defaultPricing = formik.values.defaultPricing;
      }
      if (
        initialState &&
        initialState.defaultAddress &&
        initialState.defaultAddress.id !== formik.values.defaultShippingAddressId
      ) {
        requestData.defaultShippingAddressId = initialState?.addresses.filter(
          address => address.id === formik.values.defaultShippingAddressId
        )[0].id;
        const selectAddress = contactProfile.addresses.find(adr => adr.id === formik.values.defaultShippingAddressId);
        dispatch(setAddressCode(selectAddress.erpPostCode));
        dispatch(setAddressId(selectAddress.id));
        AppStorage.setDefaultAddressId(selectAddress.id);
        AppStorage.setDefaultAddressCode(selectAddress.erpPostCode);
      }

      if (initialState && formik.values.isEmailEnabled !== initialState.isEmailEnabled) {
        requestData.isEmailEnabled = formik.values.isEmailEnabled;
      }
      if (initialState && formik.values.isMailchimpEnabled !== initialState.isMailchimpEnabled) {
        requestData.isMailchimpEnabled = formik.values.isMailchimpEnabled;
      }

      requestData.firstName = formik.values.firstName;
      requestData.lastName = formik.values.lastName;

      dispatch(patchProfile(requestData));
      history.replace(Routes.PROFILE, { tab: 'profile.personal_settings' })
      if (formik.values.defaultShippingAddressId !== selectedAddressId) {
        const selectAddress = userAddresses.find(adr => adr.id === formik.values.defaultShippingAddressId);
        TagManager.dataLayer({
          dataLayer: {
            event: 'change_address',
            branch_id: selectAddress.branchId,
            address_code: selectAddress.erpCustomerAddressCode,
            address_id: selectAddress.id,
            customer_code: selectAddress.customer.erpCustomerCode,
            user_type: getUserType(user.email),
            user_ID: selectAddress.customer.id,
          },
        });
      }
    },
  });

  const userSelectedAddress = contactProfile?.addresses?.find(address => address.id === formik.values.defaultShippingAddressId);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const isDisabledSendButton = () => {
    if (initialState) {
      return (formik.values.defaultPricing === initialState?.defaultPricing &&
					formik.values.defaultShippingAddressId === initialState?.defaultAddress?.id &&
					initialState.defaultLocale &&
					formik.values.defaultLocaleId === initialState?.defaultLocale?.id &&
					formik.values.defaultDisplayLanguageId === initialState?.defaultDisplayLanguage?.id &&
					formik.values.firstName === `${initialState?.firstName}` &&
					formik.values.lastName === `${initialState?.lastName}` &&
					formik.values.isEmailEnabled === initialState?.isEmailEnabled &&
					formik.values.isMailchimpEnabled === initialState?.isMailchimpEnabled);
    }
    return false;
  };

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
    dispatch(refreshPasswordState());
  };

  const handleClosePopupUpdateProfile = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenUpdateProfile(false);
  }

  useEffect(() => {
    if (contactProfile) {
      formik.setFieldValue('defaultPricing', contactProfile?.defaultPricing);
      formik.setFieldValue('defaultShippingAddressId', contactProfile?.defaultAddress?.id);
      formik.setFieldValue('address', contactProfile?.defaultAddress);
      formik.setFieldValue('firstName', contactProfile?.firstName);
      formik.setFieldValue('lastName', contactProfile?.lastName);
      formik.setFieldValue('email', contactProfile?.email);
      const defaultLocale = siteLocales.filter(language => language.isDefault)
      formik.setFieldValue(
        'defaultLocaleId',
        contactProfile?.defaultLocale ? contactProfile?.defaultLocale.id : defaultLocale[0]?.id
      );
      formik.setFieldValue('defaultDisplayLanguageId', contactProfile?.defaultDisplayLanguage?.id)
      formik.setFieldValue('isEmailEnabled', !!contactProfile?.isEmailEnabled);
      formik.setFieldValue('isMailchimpEnabled', !!contactProfile?.isMailchimpEnabled);
      setInitialState(contactProfile);
    }
  }, [contactProfile]);

  useEffect(() => {
    if (changePasswordStatus) {
      handleOpenSnackbar();
    }
  }, [changePasswordStatus]);

  useEffect(() => {
    if (updateUserProfileStatus === 'Success') {
      setOpenUpdateProfile(true);

      setTimeout(() => {
        dispatch(setUpdateUserProfileStatus(null));

      },2000);
    }
  }, [updateUserProfileStatus]);

  const renderAddresses = (address: IAddress) =>
    [1, 2, 3].map(number =>
      address[`erpAddress${number}`] ? (
        <AddressProperty key={`erpAddress${number}`}>{address[`erpAddress${number}`]}</AddressProperty>
      ) : null
    );

  const handleOutSideClick = () => {
    setIsShowShippingAddressDropdown(false);
  }

  const refShippingAddress = useOutsideClick(handleOutSideClick);

  return (
    <>
      <CustomizedSnackbar
        open={openSnackbar}
        handleClose={handleClose}
        status={changePasswordStatus}
        successMessage={t('profile.password_changed_success')}
        errorMessage={t('profile.password_changed_error')}
      />

      <CustomizedSnackbar
        open={openUpdateProfile}
        handleClose={handleClosePopupUpdateProfile}
        status={updateUserProfileStatus}
        successMessage={t('profile.update_profile_success')}
        errorMessage={t('profile.update_profile_error')}
      />

      {isOpen && (
        <ChangePasswordModal
          isOpen={isOpen}
          handleClose={() => closeModal()}
          errorText={changePasswordStatus}
        />
      )}
      <FormsContainer>
        <form onSubmit={formik.handleSubmit}>
          <LanguagePricingWrapper>
            <StyledFormControl variant="outlined">
              <InputLabel id="project-select-label">{t('profile.default_language')}</InputLabel>
              <StyledSelect
                labelId="project-select-label"
                id="project-select"
                name="defaultLocaleId"
                value={formik.values.defaultLocaleId}
                label={t('profile.default_language')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {
                  siteLocales.length > 0 && siteLocales.map(language =>  <StyledMenuItem key={language.code} value={language.id}>
                    {language.name}
                  </StyledMenuItem>)
                }
              </StyledSelect>
            </StyledFormControl>

            <StyledFormControl variant="outlined">
              <InputLabel id="project-select-label">{t('profile.default_pricing')}</InputLabel>
              <StyledSelect
                labelId="project-select-label"
                id="project-select"
                name="defaultPricing"
                value={formik.values.defaultPricing}
                label={t('profile.default_pricing')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <StyledMenuItem value="delivery">{t('profile.delivery')}</StyledMenuItem>
                <StyledMenuItem value="pickup">{t('profile.pick_up')}</StyledMenuItem>
              </StyledSelect>
            </StyledFormControl>
          </LanguagePricingWrapper>

          <LanguagePricingWrapper>
            <StyledFormControl variant="outlined">
              <InputLabel id="project-select-label">{t('profile.default_display_language')}</InputLabel>
              <StyledSelect
                labelId="project-select-label"
                id="project-select"
                name="defaultDisplayLanguageId"
                value={formik.values.defaultDisplayLanguageId}
                label={t('profile.default_display_language')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {
                  siteLocales.length > 0 && siteLocales.map(language =>  <StyledMenuItem key={language.code} value={language.id}>
                    {language.name}
                  </StyledMenuItem>)
                }
              </StyledSelect>
            </StyledFormControl>
          </LanguagePricingWrapper>

          <ShippingAddressWrapper ref={refShippingAddress}>
            {contactProfile && !isShowShippingAddressDropdown && userSelectedAddress &&  (
              <ShippingAddressVisual onClick={() => setIsShowShippingAddressDropdown(true)}>
                <div className="txt-field-label">{t('profile.default_shipping_address')}</div>
                <KeyboardArrowDown className="ic-arrow" />
                <span className="txt-address">{getAddressString(userSelectedAddress)}</span>
              </ShippingAddressVisual>
            )}

            {
              contactProfile && isShowShippingAddressDropdown && (
                <AddressAutocomplete>
                  <Autocomplete
                    disablePortal
                    options={contactProfile?.addresses}
                    popupIcon={<KeyboardArrowDown />}
                    openOnFocus
                    disableClearable
                    getOptionLabel={(option) => getAddressString(option)}
                    renderOption={(address: IAddress) => {
                      return (
                        <li>
                          <span className={`ic-check ${address.id === formik.values.defaultShippingAddressId ? 'checked' : ''}`} />
                          <span className="txt-address">{getAddressString(address)}</span>
                        </li>
                      );
                    }}
                    onChange={(event, value) => {
                      formik.setValues({...formik.values, defaultShippingAddressId: value.id});
                      setIsShowShippingAddressDropdown(false);
                    }}
                    renderInput={(params) => (
                      <StyledTextField
                        fullWidth
                        variant="outlined"
                        autoFocus
                        label={t('profile.default_shipping_address')}
                        {...params}
                      />
                    )}
                    defaultValue={contactProfile?.addresses.find(adr => adr.id === contactProfile?.defaultAddress.id)}
                  />
                </AddressAutocomplete>
              )
            }
            <AddressInfo>
              <AddressLabel>{t('profile.default_shipping_address')}</AddressLabel>
              {contactProfile &&
                contactProfile.addresses.map(address => {
                  if (address.id === formik.values.defaultShippingAddressId) {
                    return (
                      <Fragment key={address.erpPostCode}>
                        <AddressName>{address.name ?? address.postcode}</AddressName>
                        {renderAddresses(address)}
                        <AddressProperty>
                          {address.erpCity}, {PROVINCE_CODES_WITH_NAME[address.provinceCode]}
                        </AddressProperty>
                        <AddressProperty>{address.erpPostCode}</AddressProperty>
                        <AddressProperty>
                          {PROVINCE_CODES_WITH_NAME[address.countryCode]}
                        </AddressProperty>
                        {
                          address.erpTelephone1 && <AddressProperty>
                            {formatPhoneNumber(address.erpTelephone1)}
                          </AddressProperty>
                        }
                      </Fragment>
                    );
                  }
                  return null;
                })}
            </AddressInfo>
          </ShippingAddressWrapper>

          <NameEmailWrapper>
            <StyledTextField
              fullWidth
              variant="outlined"
              label={t('profile.first_name')}
              name="firstName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              error={!!(formik.errors.firstName && formik.touched.firstName)}
              helperText={
                formik.errors.firstName && formik.touched.firstName
                  ? t(`profile.${formik.errors.firstName}`)
                  : ''
              }
              InputLabelProps={{
                className: classes.inputLabel,
              }}
            />

            <StyledTextField
              fullWidth
              variant="outlined"
              label={t('profile.last_name')}
              name="lastName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              error={!!(formik.errors.lastName && formik.touched.lastName)}
              helperText={
                formik.errors.lastName && formik.touched.lastName
                  ? t(`profile.${formik.errors.lastName}`)
                  : ''
              }
              InputLabelProps={{
                className: classes.inputLabel,
              }}
            />

            <StyledTextFieldDisabled
              fullWidth
              variant="outlined"
              label={t('profile.email')}
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={!!(formik.errors.email && formik.touched.email)}
              helperText={
                formik.errors.email && formik.touched.email
                  ? t(`profile.${formik.errors.email}`)
                  : ''
              }
              disabled
              InputLabelProps={{
                className: classes.inputLabel,
              }}
            />
          </NameEmailWrapper>
          <ChangePassword onClick={openModal}>{t('profile.change_password')}</ChangePassword>
          {/* <CheckboxWrapper> */}
          {/*  <GreenCheckbox */}
          {/*    name="isEmailEnabled" */}
          {/*    checked={formik.values.isEmailEnabled} */}
          {/*    onChange={formik.handleChange} */}
          {/*    disableRipple */}
          {/*  /> */}
          {/*  <CheckboxTitle>{t('profile.email_agreement')}</CheckboxTitle> */}
          {/* </CheckboxWrapper> */}
          <CheckboxWrapperWithoutMargin>
            <GreenCheckbox
              name="isMailchimpEnabled"
              checked={formik.values.isMailchimpEnabled}
              onChange={formik.handleChange}
              disableRipple
              data-test-id="agree-button"
            />
            <CheckboxTitle>{t('profile.mailchimp_agreement')}</CheckboxTitle>
          </CheckboxWrapperWithoutMargin>
          <StyledFormButton disabled={isDisabledSendButton()} type="submit" data-test-id="save-button">
            {t('profile.save')}
          </StyledFormButton>
        </form>
      </FormsContainer>
    </>
  );
};

export default PersonalSettings;
