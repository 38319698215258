import { IContact } from 'models/interfaces/contacts';

export enum ContactActionTypes {
  REQUEST_FETCH_CONTACTS = '@@contact/REQUEST_FETCH_CONTACTS',
  REQUEST_FETCH_CONTACTS_SUCCESS = '@@contact/REQUEST_FETCH_CONTACTS_SUCCESS',
  REQUEST_FETCH_CONTACTS_ERROR = '@@contact/REQUEST_FETCH_CONTACTS_ERROR',
}

export interface ContactState {
  readonly contacts: IContact[];
}

export interface RequestFetchContactAction {
  type: ContactActionTypes.REQUEST_FETCH_CONTACTS;
}
