import styled from 'styled-components/macro';
import Slider from 'react-slick';
import { Dialog, DialogContent } from '@material-ui/core';

const SliderWrapper = styled.div`
  border-radius: 4px;
  @media (max-width: 1023px) {
    margin-top: 16px;
    width: 100%;
    margin-bottom: 24px;
  }
`;

const StyledSlider = styled(Slider)`
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  width: 440px;
  @media (max-width: 1023px) {
    width: 100%;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 440px !important;
    width: 440px !important;
    @media (max-width: 1023px) {
      height: 294px !important;
      width: 100% !important;
    }
  }

  .slick-track {
    height: 100%;
  }

  .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
  }

  .slick-arrow {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    top: 45%;
    z-index: 1;
    @media (max-width: 1023px) {
      top: 50%;
    }

    &.slick-disabled {
      display: none;
    }
  }

  .slick-prev {
    left: 16px;

    &:before {
      display: none;
    }
  }

  .slick-next {
    right: 16px;

    &:before {
      display: none;
    }

    @media (max-width: 1023px) {
      right: 6px;
    }
  }

  .slick-dots {
    display: flex !important;
    justify-content: flex-start;
    position: relative;
    list-style: none;
    padding: 0;
    margin: 25px 0 0;

    .slick-active {
      border: 1px solid ${({ theme: { colors } }) => colors.borderSliderGreen};
    }

    li {
      width: 70px;
      height: 70px;
      border: 1px solid ${({ theme: { colors } }) => colors.productBorderGrey};
      box-sizing: border-box;
      border-radius: 4px;
      margin: 0 5px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-sizing: border-box;
        border-radius: 4px;
        outline: none;
        cursor: pointer;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
`;

const ProductCard = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  outline: none;
  &.product-card-on-slider {
    position: relative;
  }
  div.zoom-image {
    height: 440px;
    > img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }
`;

const DotWrapper = styled.div`
  width: 68px;
  height: 68px;
  object-fit: cover;
  box-sizing: border-box;
  border-radius: 4px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

const EnlargeImage = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  overflow: visible;
  z-index: 1;
  width: auto !important;
  height: auto !important;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
  }
`;

const StyledToolbar = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 10px;
  display: flex;
`;
const StyledDialog = styled(Dialog)`
  z-index: 1400 !important;
  .MuiDialogContent-root {
    position: relative;
    overflow-y: initial;
  }
`;
const StyledArrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 2;
  &.left-arrow {
    left: 0;
  }
  &.right-arrow {
    right: 0;
  }
`;

export {
  SliderWrapper,
  StyledSlider,
  ProductCard,
  DotWrapper,
  EnlargeImage,
  StyledToolbar,
  StyledDialogContent,
  StyledDialog,
  StyledArrow,
};
