// eslint-disable-next-line max-classes-per-file
import { IBranchPhoneValues } from 'models/interfaces/branch';

export class BranchClass {
  id: number = null;

  erpName: string = null;

  code: string = null;

  address: string = null;

  email: string = null;

  phones: IBranchPhoneValues[] = null;

  constructor(...args) {
    args.forEach((item: BranchClass) => {
      this.id = item.id || null;
      this.erpName = item.erpName || null;
      this.code = item.code || null;
      this.address = item.address || null;
      this.phones = item.phones || null;
      this.email = item.email || '';
    });
  }
}
