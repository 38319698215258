import React, { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectAddressId, selectErpCustomerAddressCode } from 'ducks/application/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';

const Wrapper = styled.div`
  padding: 4px 8px 4px 1px;
  border-radius: 4px;
  border: 1px solid ${({ theme: { colors } }) => colors.grey};
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
`;

const StyledSelect = styled(Select)`
  .MuiSelect-select {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60px;
    height: 20px;
    padding-right: 13px;
    padding: 0 13px 0 0;

    div {
      display: none;
    }
  }

  &:focus {
    background-color: ${({ theme: { colors } }) => colors.white};
  }

  && ul {
    padding: 0;
  }
`;

const StyledFormControl = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 8px 4px 16px 10px;
`;

const StyledParagraph = styled.p`
  font-family: Open Sans;
  font-weight: 400;
  size: 12px;
  margin: 0 8px;
  text-align: right;
`;

const useStyles = makeStyles({
  select: {
    '&': {
      marginTop: '20px !important',
      width: '77px',
    },
    '& ul': {
      width: '100%',
    },
    '& li': {
      fontSize: '14px',
      fontFamily: 'Open Sans',
      lineHeight: '21px',
      letterSpacing: '0.01em',
    },
  },
});

export interface IPagination {
  page: number;
  itemsPerPage: number;
}

interface IShowDropdown {
  setPagination: Dispatch<SetStateAction<IPagination>>;
  setFirstLoad: Dispatch<SetStateAction<boolean>>;
  itemsPerPage: number;
}

const ShowDropdown: React.FC<IShowDropdown> = ({ setPagination, setFirstLoad, itemsPerPage }: IShowDropdown) => {
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const [sortBy, setSortBy] = React.useState(itemsPerPage || 12);
  const classes = useStyles();

  useEffect(() => {
    setSortBy(itemsPerPage);
  }, [itemsPerPage]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSortBy(event.target.value as number);
    setPagination({ page: 1, itemsPerPage: event.target.value as number });
    setFirstLoad(true);
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'change_show_item_quantity',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
  };

  return (
    <Wrapper>
      <StyledFormControl>
        <StyledSelect
          value={sortBy}
          onChange={handleChange}
          disableUnderline
          IconComponent={ExpandMore}
          displayEmpty
          MenuProps={{
            MenuListProps: {
              disablePadding: true,
            },
            classes: {
              paper: classes.select,
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            getContentAnchorEl: null,
          }}
        >
          <StyledMenuItem value={12}>
            <StyledParagraph>12</StyledParagraph>
          </StyledMenuItem>
          <StyledMenuItem value={24}>
            <StyledParagraph>24</StyledParagraph>
          </StyledMenuItem>
          <StyledMenuItem value={48}>
            <StyledParagraph>48</StyledParagraph>
          </StyledMenuItem>
        </StyledSelect>
      </StyledFormControl>
    </Wrapper>
  );
};

export default ShowDropdown;
