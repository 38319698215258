import React, { useEffect, useRef } from 'react';
import { ValueLabelProps } from '@material-ui/core';

import { StyledTooltip } from './styles';

const ValueLabelComponent = ({ value, open, children }: ValueLabelProps) => {
  const popperRef = useRef(null);
  useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });

  return (
    <StyledTooltip
      title={value}
      open={open}
      placement="top"
      PopperProps={{ popperRef, disablePortal: true }}
      arrow
    >
      {children}
    </StyledTooltip>
  );
};

export default ValueLabelComponent;
