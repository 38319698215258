/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Accordion, AccordionSummary, Grid, useMediaQuery, Typography, AccordionDetails } from '@material-ui/core';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Routes from 'routes';
import { useTranslation } from 'react-i18next';
import ImagesSlider from 'components/common/ImagesSlider/ImagesSlider';
import {
  priceTypeSelector,
  selectAddressId,
  selectErpCustomerAddressCode,
  selectLanguage,
} from 'ducks/application/selectors';
import {  useGetProductDetail } from 'api/Product';
import { ProductVariantClass } from 'models/classes/productVariantClass';
import { IPrice } from 'models/interfaces/productVariant';
import midDefaultProduct from 'assets/images/default-mid-product.png';
import { selectAlterNativeProducts, selectRelatedProducts, selectShowPrices } from 'ducks/productVariant/selectors';
import { IFrameModal, IFrameButton } from 'pages/Product/ProductDetail/IFrame/IFrameComponent';
import {
  setClickedProductRequestNearbyHubsAndBranch, setNearbyBranchData,
  setOpenPopupInquireNow,
  setOpenPopupNearbyBranch,
  storeProduct,
} from 'ducks/product/actions';
import { useRequestPricing } from 'hooks/useRequestPricing';
import AssociationProductSlider from 'components/common/AssociationProductSlider';
import { fetchProductAssociation } from 'ducks/productVariant/actions';
import { usePermission } from 'hooks/usePermission';
import TagManager from 'react-gtm-module';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { NearbyBranchContext } from 'context/NearbyBranchContext';
import { NearbyBranchEmailType } from 'ducks/productVariant/types';
import size from 'lodash/size';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { getDefaultProductName } from 'utils/products/productUtilsHelpers';
import { getDefaultCategoryName } from 'utils/categories/categories';
import { selectCategories } from 'ducks/category/selectors';
import { pushKlaviyoEvent } from 'utils/klaviyo';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import get from 'lodash/get';
import { createUserActionLog } from 'api/UserActionLog';
import Video from 'components/common/Slider/Video';
import { SpecificationsTab } from 'components/Product/ProductDetail/SpecificationsTab/SpecificationsTab';
import { DocumentationsTab } from 'components/Product/ProductDetail/DocumentationsTab/DocumentationsTab';
import { ProductBreadcrumbs } from './ProductBreadcrumbs/ProductBreadcrumbs';
import { PricesSection } from './PricesSection/PricesSection';
import { OptionsSection } from './OptionsSection/OptionsSection';
import { PriceCalculatorSection } from './PriceCalculatorSection/PriceCalculatorSection';
import {
  ProductInfoPartContainer,
  SliderContainer,
  ProductInfoWrapper,
  ProductSliderWrapper,
  ProductName,
  ProductSku,
  NearbyHubsButton,
  ProductInStock,
  ProductDescriptionShort,
  ProductAttributeWrapperList,
  StyledButton,
  ButtonTitle,
  StyledLoadingDiv,
  StyledLoadingDivWrapper,
  ProductInStockV2,
  SpecificationAccordionWrapper,
  StyledLeftBlock,
  TotalWrapper, InquireNowButtonTitle, InquireNowButton,
} from './styles';
import {
  StyledAvailableAndLeadTimeWrapper,
  StyledAvailableAndLeadTime,
} from '../ProductTableView/ProductTableCard/styles';

interface IProductDetail
  extends RouteComponentProps<{ code: string },
    { statusCode?: number },
    {
      variantCode?: string;
    }> {
}

// @ts-ignore
const ProductDetailNew = React.memo(({ match, location }: IProductDetail) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { canSeePrice, canPlaceQuote, canSeeInventory, canPlaceOrder } = usePermission();
  const { setEmailType, setFromBranchId } = React.useContext(NearbyBranchContext);
  const [currentPrice, setCurrentPrice] = useState<IPrice | null>(null);
  const gridRef = useRef<HTMLDivElement>(null);
  const { code: productId } = match.params;
  const addressId = useSelector(selectAddressId);
  const selectedLanguage = useSelector(selectLanguage);
  const priceType = useSelector(priceTypeSelector);
  const showPrices = useSelector(selectShowPrices);
  const relatedProducts = useSelector(selectRelatedProducts);
  const alterNativeProducts = useSelector(selectAlterNativeProducts);
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const categories = useSelector(selectCategories);
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const { t } = useTranslation();
  const [iFrameOpened, setIFrameOpened] = useState(false);
  const [specificationExpanded, setSpecificationExpanded] = useState('specifications');
  const [hasValueDocument, setHasValueDocument] = useState(false);

  const {data: productDetail, isError, isLoading } = useGetProductDetail(productId, addressId, selectedLanguage, get(location, 'state.subcategoryCode', ''), {
    onSuccess: (data) => {
      const variant = data.variants.find(product => product.erpProductId === Number(productId));
      dispatch(storeProduct(data));
      TagManager.dataLayer({
        dataLayer: {
          event: 'go_to_product_detail',
          user_type: getUserType(user.email),
          branch_id: branch?.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: addressId,
        },
      });
      let price;
      if (size(variant?.prices)) {
        price = variant.prices.sort((a, b) => a.qty - b.qty)[0][priceType];
      }
      const { itemCategoryName, itemCategory2Name } = getDefaultCategoryName(variant.category.id, categories);
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'view_item',
          user_type: getUserType(user.email),
          branch_id: branch?.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: addressId,
          user_ID: user.id,
          ecommerce: {
            items: [
              {
                item_id: variant.erpSku,
                item_name: getDefaultProductName(variant),
                price,
                item_category2: itemCategory2Name,
                item_category: itemCategoryName,
                quantity: 1,
              },
            ],
          },
        },
      });
      const parentCategory = categories.find(c => c.id === variant.category.parent.id);
      const klaviyoItems = {
        ProductName: variant.name,
        ProductId: variant.erpProductId,
        SKU: variant.erpSku,
        Categories: [parentCategory?.name, variant?.category?.name],
        ImageURL: variant.images[0]?.url,
        URL: window.location.href,
        Price: variant.prices.length > 0 ? parseFloat(variant.prices[0][priceType]) : 0,
        // Brand: branch.erpName,
      };
      pushKlaviyoEvent('Viewed Product', klaviyoItems);
    }
  });

  const productVariant = useMemo(() => {
    if (productDetail) {
      const variant = productDetail.variants.find(product => product.erpProductId === Number(productId));
      setHasValueDocument(variant?.isDocumentExists ?? false);
      return variant;
    }
    return null;
  }, [productDetail, productId]);

  const { qty: minQuantity } = get(productVariant, 'prices[0]', {});

  const [inputValues, setInputValues] = useState({
      topInputValue: minQuantity,
      bottomInputValue: get(productVariant, 'units.uom2Conversion', null)
        ? minQuantity / get(productVariant, 'units.uom2Conversion', null)
        : null,
    });

  useEffect(() => {
    if (productDetail) {
      dispatch(fetchProductAssociation(productDetail.id));
    }
  }, [dispatch, productDetail?.id]);

  const { handleRequestPricingClick, setIsRequestPricing } = useRequestPricing(
    productVariant,
  );

  const handleClickRequestPricing = async () => {
    await createUserActionLog('Product Detail Page Request Pricing', 'Pricing Inquiry');
    handleRequestPricingClick();
    setIsRequestPricing(true);
  };

  useEffect(() => {
    if (productVariant?.prices?.length > 0) {
      setCurrentPrice(
        productVariant.prices.reduce((prev, next) => (prev.qty < next.qty ? prev : next)) || null,
      );
    }
  }, [productVariant]);

  useEffect(() => {
    const { state }: any = history.location;
    if (state?.scroll) {
      window.scrollTo(0, state.scroll);
    }
  }, [history.location]);

  const ProductDetailLoader: React.FC = () => {
    return (
      <StyledLoadingDivWrapper>
        <StyledLoadingDiv />
      </StyledLoadingDivWrapper>
    );
  };

  const openIFrame = () => {
    setIFrameOpened(true);
  };

  const closeIFrame = () => {
    setIFrameOpened(false);
  };

  const handleChooseProduct = (productVar: ProductVariantClass) => {
    if (productVar) {
      history.replace(Routes.PRODUCT_DETAIL.replace(':code', productVar.code.toString()), { scroll: window.scrollY });
    }
  };

  const getNearbyText = (product: ProductVariantClass) => {
    if (product.nearbyHubs.length === 0 && product.nearbyBranches.onHand === 0) {
      return t('product_detail.click_here_to_inquire');
    }
    return t('product_detail.click_here_to_see_nearby_hubs');
  };

  const handleClickNearbyText = async (product: ProductVariantClass) => {
    await createUserActionLog('Product Detail Page Nearby Branch', 'Multi-branch Inquiry');
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_view_other_branch_inventory',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: addressId,
      },
    });
    dispatch(setClickedProductRequestNearbyHubsAndBranch(product));
    if (product.nearbyHubs.length === 0 && product.nearbyBranches.onHand <= 0) {
      dispatch(setOpenPopupInquireNow(true));
      return;
    }
    dispatch(setNearbyBranchData({ nearbyHubs: product.nearbyHubs, nearbyBranches: product.nearbyBranches }));
    setTimeout(() => {
      dispatch(setOpenPopupNearbyBranch(true));
    }, 100);
  };

  const handleClickInventoryStatus = async () => {
    await createUserActionLog('Product Detail Page Inventory Status', 'Inventory Request');
  };

  const handleOpenEmailPopup = useCallback((product: ProductVariantClass) => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_inventory_request',
        user_type: getUserType(user?.email),
        branch_id: branch?.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: addressId,
      },
    });
    const { status } = product.inventory;
    if (!product.inventory.isStocked && product.newInventory?.onHand > 0) {
      setEmailType(NearbyBranchEmailType.HUB_BRANCH_WITH_INVENTORY_AVAILABLE);
      setFromBranchId(product.newInventory.branchId);
    } else if (status.inStock) {
      setEmailType(NearbyBranchEmailType.HOME_BRANCH_WITH_INVENTORY_IN_STOCK);
    } else if (status.waitingOnStock) {
      setEmailType(NearbyBranchEmailType.HOME_BRANCH_WAITING_ON_STOCK);
    } else if (status.specialOrderString) {
      setEmailType(NearbyBranchEmailType.HOME_BRANCH_WITH_SPECIAL_ORDER_STRING);
    }
    dispatch(setClickedProductRequestNearbyHubsAndBranch(product));
    dispatch(setOpenPopupInquireNow(true));
  }, [addressId, branch, dispatch, erpCustomerAddressCode, setEmailType, setFromBranchId, user]);

  const handleSpecificationChange = (panel: string, isExpanded: boolean) => {
    setSpecificationExpanded(isExpanded ? panel : '');
  };

  const onClickInquireNow = async () => {
    await createUserActionLog('Product Detail Page Inquire Now', 'Inventory Request');
  };

  if (isError && !isLoading) {
    return history.replace(Routes.NOT_FOUND);
  }

  return productVariant && !isLoading ? (
    <Grid ref={gridRef} item container xs={12} md={12} style={{ minHeight: '100vh' }}>
      <IFrameModal
        open={iFrameOpened}
        onClose={closeIFrame}
        onClickClose={closeIFrame}
        product={productDetail}
      />
      <ProductBreadcrumbs product={productVariant} />
      <ProductInfoPartContainer>
        <ProductSliderWrapper>
          {isMobile ? <ProductName>{productVariant.name}</ProductName> : null}

          <SliderContainer className="product-slider">
            <ImagesSlider
              images={
                productVariant?.resizeImages?.length > 0
                  ? productVariant?.resizeImages
                  : [
                    {
                      original: midDefaultProduct,
                      medium: midDefaultProduct,
                      small: midDefaultProduct,
                      large: midDefaultProduct,
                    },
                  ]
              }
            />
            <IFrameButton product={productDetail} onClick={openIFrame} />
          </SliderContainer>
          <br />
          {
            !isMobile && (
              <StyledLeftBlock>
                {productVariant.shortDescription ? (
                  <div className="short-description">{productVariant.shortDescription}</div>
                ) : null}
                {
                  !productVariant.inventory.isStocked && productVariant.newInventory?.onHand > 0 ? (
                    <StyledAvailableAndLeadTimeWrapper>
                      <StyledAvailableAndLeadTime
                        className="on-detail"
                        onClick={() => {
                          handleClickInventoryStatus();
                          handleOpenEmailPopup(productVariant);
                        }}
                      >
                        {t('products_and_search.available_and_lead_time', {
                          onHand: productVariant.newInventory.onHand,
                          leadTime: productVariant.newInventory.leadTime,
                        }).toString()}
                      </StyledAvailableAndLeadTime>
                      <NearbyHubsButton type="button" onClick={() => handleClickNearbyText(productVariant)}>
                        {getNearbyText(productVariant)}
                      </NearbyHubsButton>
                    </StyledAvailableAndLeadTimeWrapper>
                  ) : (
                    <ProductInStockV2
                      stock={productVariant.inventory.isStocked || productVariant.inventory.status.status === t('products_and_search.waiting_on_stock')}
                      waitingOnStock={productVariant.inventory.status.waitingOnStock}
                      inStock={productVariant.inventory.status.inStock}
                      t={t}
                    >
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */}
                      <div className="txt-stock" onClick={() => {
                        handleClickInventoryStatus();
                        handleOpenEmailPopup(productVariant);
                      }}>
                        {canSeeInventory ? productVariant.inventory.status.status : productVariant.inventory.status.origin}
                      </div>
                      <NearbyHubsButton type="button" onClick={() => handleClickNearbyText(productVariant)}>
                        {getNearbyText(productVariant)}
                      </NearbyHubsButton>
                    </ProductInStockV2>
                  )
                }
                {
                  !isMobile && (
                    <SpecificationAccordionWrapper
                      className={Array.isArray(productVariant?.mergedAttributes) && productVariant?.mergedAttributes.length > 10 ? 'has-scroll' : ''}
                    >
                      {Array.isArray(productVariant?.mergedAttributes) &&
                        productVariant?.mergedAttributes.length > 0 && (
                          <Accordion
                            expanded={specificationExpanded === 'specifications'}
                            onChange={(_event: React.ChangeEvent<{}>, expanded: boolean) =>
                              handleSpecificationChange('specifications', expanded)
                            }
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="specification"
                            >
                              <Typography>{t('product_detail.specifications').toString()}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <SpecificationsTab variant={productVariant} fullWidth />
                            </AccordionDetails>
                          </Accordion>
                        )}
                      {hasValueDocument && (
                        <Accordion
                          expanded={specificationExpanded === 'documentation'}
                          onChange={(event: React.ChangeEvent<{}>, expanded: boolean) =>
                            handleSpecificationChange('documentation', expanded)
                          }
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="document"
                          >
                            <Typography>{t('product_detail.documentation').toString()}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <DocumentationsTab
                              productId={productDetail.id}
                              addressId={addressId}
                              selectedLanguage={selectedLanguage}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )}
                      {productDetail.description && (
                        <Accordion
                          expanded={specificationExpanded === 'description'}
                          onChange={(event: React.ChangeEvent<{}>, expanded: boolean) =>
                            handleSpecificationChange('description', expanded)
                          }
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="description"
                          >
                            <Typography>{t('product_detail.description').toString()}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {productDetail.videoUrl || productDetail.videoUrl ? (
                              <Video
                                name="media"
                                url={productDetail.videoUrl ?? productDetail.videoUrl}
                                width={width - 20}
                                height={206}
                              />
                            ) : null}
                            {productDetail.description}
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </SpecificationAccordionWrapper>
                  )
                }
              </StyledLeftBlock>
            )
          }
        </ProductSliderWrapper>

        <ProductInfoWrapper>
          {!isMobile ? <ProductName>{productVariant.name}</ProductName> : null}
          <ProductSku>
            {t('product_detail.sku')}: {productVariant.erpSku}
          </ProductSku>
          {
            !productVariant.inventory.isStocked && productVariant.newInventory?.onHand > 0 ? (
              <>
                <StyledAvailableAndLeadTime
                  className="on-detail"
                  onClick={() => {
                    handleClickInventoryStatus();
                    handleOpenEmailPopup(productVariant);
                  }}
                >
                  {t('products_and_search.available_and_lead_time', {
                    onHand: productVariant.newInventory.onHand,
                    leadTime: productVariant.newInventory.leadTime,
                  }).toString()}
                </StyledAvailableAndLeadTime>
                <NearbyHubsButton type="button" onClick={() => handleClickNearbyText(productVariant)}>
                  {getNearbyText(productVariant)}
                </NearbyHubsButton>
              </>
            ) : (
              <ProductInStock
                stock={productVariant.inventory.isStocked || productVariant.inventory.status.status === t('products_and_search.waiting_on_stock')}
                waitingOnStock={productVariant.inventory.status.waitingOnStock}
                inStock={productVariant.inventory.status.inStock}
                t={t}
              >
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div className="txt-stock" onClick={() => {
                  handleClickInventoryStatus();
                  handleOpenEmailPopup(productVariant);
                }}>
                  {canSeeInventory ? productVariant.inventory.status.status : productVariant.inventory.status.origin}
                </div>
                <NearbyHubsButton type="button" onClick={() => handleClickNearbyText(productVariant)}>
                  {getNearbyText(productVariant)}
                </NearbyHubsButton>
              </ProductInStock>
            )
          }
          {productVariant.shortDescription ? (
            <ProductDescriptionShort>{productVariant.shortDescription}</ProductDescriptionShort>
          ) : null}

          {productVariant.prices.length &&
            currentPrice &&
            (currentPrice[priceType] || !showPrices) ? (
            <PricesSection productVariant={productVariant} price={currentPrice} border quantity={inputValues.topInputValue} />
          ) : null}
          <ProductAttributeWrapperList>
            <OptionsSection
              product={productDetail}
              productVariant={productVariant}
              onProductVariantChoose={handleChooseProduct}
              price={currentPrice}
            />
          </ProductAttributeWrapperList>
          {productVariant.prices.length &&
            branch &&
            currentPrice &&
            (currentPrice[priceType] || !showPrices) ? (
            canSeePrice && (canPlaceQuote || canPlaceOrder) && (
              <PriceCalculatorSection
                productVariant={productVariant}
                currentPrice={currentPrice}
                setCurrentPrice={setCurrentPrice}
                addToTruckFromBranchId={branch.id}
                showAdditionalFreight={false}
                handleOpenEmailPopup={handleOpenEmailPopup}
                inputValues={inputValues}
                setInputValues={setInputValues}
                minQuantity={minQuantity}
              />
            )
          ) : <TotalWrapper>
            <StyledButton onClick={handleClickRequestPricing}>
              <ButtonTitle>{t('product_detail.request_pricing').toString()}</ButtonTitle>
            </StyledButton>
            <InquireNowButton onClick={async () => {
              await onClickInquireNow();
              handleOpenEmailPopup(productVariant);
            }}>
              <InquireNowButtonTitle>&nbsp;{t('product_detail.inquire_now').toString()}</InquireNowButtonTitle>
            </InquireNowButton>
          </TotalWrapper>}
          {
            isMobile && (
              <SpecificationAccordionWrapper
                style={{ marginTop: 20 }}
                className={Array.isArray(productVariant?.mergedAttributes) && productVariant?.mergedAttributes.length > 10 ? 'has-scroll' : ''}
              >
                {Array.isArray(productVariant?.mergedAttributes) &&
                  productVariant?.mergedAttributes.length > 0 && (
                    <Accordion
                      className="specifications"
                      expanded={specificationExpanded === 'specifications'}
                      onChange={(event: React.ChangeEvent<{}>, expanded: boolean) =>
                        handleSpecificationChange('specifications', expanded)
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="specification"
                      >
                        <Typography>{t('product_detail.specifications').toString()}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <SpecificationsTab variant={productVariant} fullWidth />
                      </AccordionDetails>
                    </Accordion>
                  )}
                {hasValueDocument && (
                  <Accordion
                    expanded={specificationExpanded === 'documentation'}
                    onChange={(event: React.ChangeEvent<{}>, expanded: boolean) =>
                      handleSpecificationChange('documentation', expanded)
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="document"
                    >
                      <Typography>{t('product_detail.documentation').toString()}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <DocumentationsTab
                        productId={productDetail.id}
                        addressId={addressId}
                        selectedLanguage={selectedLanguage}
                      />
                    </AccordionDetails>
                  </Accordion>
                )}
                {productDetail.description && (
                  <Accordion
                    expanded={specificationExpanded === 'description'}
                    onChange={(event: React.ChangeEvent<{}>, expanded: boolean) =>
                      handleSpecificationChange('description', expanded)
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="description"
                    >
                      <Typography>{t('product_detail.description').toString()}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {productDetail.videoUrl || productDetail.videoUrl ? (
                        <Video
                          name="media"
                          url={productDetail.videoUrl ?? productDetail.videoUrl}
                          width={width - 20}
                          height={206}
                        />
                      ) : null}
                      {productDetail.description}
                    </AccordionDetails>
                  </Accordion>
                )}
              </SpecificationAccordionWrapper>
            )
          }
        </ProductInfoWrapper>
      </ProductInfoPartContainer>
      {/* <ProductDetailTabs */}
      {/*  product={productDetail.product} */}
      {/*  variant={productVariant} */}
      {/*  addressId={addressId} */}
      {/*  selectedLanguage={selectedLanguage} */}
      {/* /> */}
      <AssociationProductSlider isRelated products={relatedProducts} productType="related" />
      <AssociationProductSlider isRelated={false} products={alterNativeProducts} productType="alternative" />
    </Grid>
  ) : (
    <ProductDetailLoader />
  );
});

export default ProductDetailNew;