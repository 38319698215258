import React from 'react';

export const LocationIcon = () => {
  return (
    <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.11827 0C2.29603 0 0 2.33729 0 5.21023C0 6.81722 0.812767 8.76774 2.4158 11.0076C3.5889 12.6468 4.74489 13.8158 4.79353 13.8648C4.88295 13.9549 5.00058 14 5.11835 14C5.2327 14 5.34714 13.9574 5.43584 13.8719C5.48459 13.825 6.64351 12.7033 7.81882 11.0867C9.42314 8.88004 10.2366 6.90292 10.2366 5.2102C10.2365 2.33729 7.94045 0 5.11827 0ZM5.11827 7.45955C3.7385 7.45955 2.61601 6.33704 2.61601 4.95729C2.61601 3.57755 3.73853 2.45504 5.11827 2.45504C6.49801 2.45504 7.62053 3.57755 7.62053 4.95729C7.62053 6.33704 6.49798 7.45955 5.11827 7.45955Z"
        fill="#939599"
      />
    </svg>
  );
};
