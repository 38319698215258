import React from 'react';

export const HidePrice = () => {
  return (
    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.825 3.50908L17.8676 1.46658C18.2034 1.13072 18.2034 0.587878 17.8676 0.252017C17.5317
            -0.0838431 16.9888 -0.0838431 16.653 0.252017L3.6872 13.2178C3.35134 13.5537 3.35134 14.0965 3.6872
            14.4324C3.85513 14.6003 4.07383 14.6823 4.29643 14.6823C4.51904 14.6823 4.73774 14.6003 4.90567
            14.4324L6.79196 12.5461C7.79954 12.9288 8.87742 13.1709 10.0022 13.1709C15.5243 13.1709 19.9999
            7.34024 19.9999 7.34024C19.9999 7.34024 18.3518 5.1962 15.825 3.50908ZM9.99826 10.6442C9.60772
            10.6442 9.23281 10.5739 8.88523 10.4489L13.1108 6.2233C13.2358 6.57088 13.3061 6.9458 13.3061
            7.33633C13.3022 9.16404 11.8221 10.6442 9.99826 10.6442Z"
        fill="#939599"
      />
      <path
        d="M6.84609 8.34023C6.74455 8.0239 6.69378 7.69194 6.69378 7.34046C6.69378 5.51666 8.17391
            4.03653 9.99771 4.03653C10.3453 4.03653 10.6811 4.09121 10.9975 4.18884L13.0947 2.09166C12.1183
            1.73237 11.0795 1.50977 9.99771 1.50977C4.47554 1.50977 0 7.34046 0 7.34046C0 7.34046 1.6051 9.42983
            4.07719 11.1091L6.84609 8.34023Z"
        fill="#939599"
      />
    </svg>
  );
};
