import React, { useMemo }  from 'react';
import { ProductVariantClass } from 'models/classes/productVariantClass';
import { IPrice } from 'models/interfaces/productVariant';
import { priceTypeSelector, selectIsHidePrice } from 'ducks/application/selectors';
import { useSelector } from 'react-redux';
import { numberFormat as f } from 'utils/products/product';
import { usePermission } from 'hooks/usePermission';
import { Box, Typography } from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {
  ProductPriceSectionWrapper,
  ProductPriceWrapper,
  ProductPrice,
  ProductPackage,
  ProductSquarePrice,
} from '../styles';


interface IPricesSection {
  productVariant: ProductVariantClass;
  price: IPrice | null;
  border?: boolean;
  quantity?: number;
}

function PricesSection({ productVariant, price, border, quantity }: IPricesSection) {
  const isHidePrice = useSelector(selectIsHidePrice);
  const priceType = useSelector(priceTypeSelector);
  const [defaultPrice = null] = productVariant.prices;
  const currentPrice = price || defaultPrice;
  const isValidCurrentPrice = currentPrice && currentPrice[priceType];
  const { canSeePrice } = usePermission();
  const { t } = useTranslation();

  const productSysWeight = useMemo(() => {
    const sysWeightOption = productVariant.sysWeight;
    return (
      <Box style={{ display: 'flex', flexDirection: 'row', marginTop: '8px', gap: '3px', alignItems: 'center' }}>
        <Typography style={{ fontSize: '14px', lineHeight: '24px' }}>
          {t('product_detail.weight').toString()}:
        </Typography>
        <Typography style={{ fontSize: '16px', fontWeight: 700, color: sysWeightOption ? '#007A33' : '#FF0000', lineHeight: '28px' }}>
          {sysWeightOption ? (
            `${parseFloat(f(sysWeightOption * quantity))} ${t('product_detail.lbs').toString()}`) : t('product_detail.sys_weight_not_available').toString()}
        </Typography>
      </Box>
    );
  }, [productVariant, quantity, t]);

  return (
    <>
      {!isHidePrice && currentPrice && isValidCurrentPrice ? (
        <ProductPriceSectionWrapper border={border} className="product-price-section-wrapper">
          <ProductPriceWrapper>
            <ProductPrice>${currentPrice[priceType] ? f(currentPrice[priceType]) : null}</ProductPrice>
            <ProductPackage>/{productVariant.units.perName}</ProductPackage>
          </ProductPriceWrapper>
          {productVariant.units.uom2Name && canSeePrice && (
            <ProductSquarePrice>
              (${f(currentPrice[priceType] * productVariant.units.uom2Conversion)} /{' '}
              {productVariant.units.uom2Name})
            </ProductSquarePrice>
          )}
        </ProductPriceSectionWrapper>
      ) : null}
      {productSysWeight}
    </>
  );
}

export { PricesSection };
