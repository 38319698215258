import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSnackbarClose } from 'ducks/ui/actions';
import { selectSnackbarType, selectSnackbarMessage, selectSnackbarOpen } from 'ducks/ui/selectors';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { selectCartDataErrors } from 'ducks/cart/selectors';
import { resetCartErrors } from 'ducks/cart/actions';
import { snackBarZIndex } from 'assets/constants/application';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    zIndex: snackBarZIndex,
  },
});

const CustomizedSnackbar: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const open = useSelector(selectSnackbarOpen);
  const message = useSelector(selectSnackbarMessage);
  const type = useSelector(selectSnackbarType);

  const cartErrors = useSelector(selectCartDataErrors);

  const handleClose = () => {
    dispatch(toggleSnackbarClose());
    if (cartErrors && cartErrors.length) {
      dispatch(resetCartErrors());
    }
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} className={classes.root}>
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CustomizedSnackbar;
