import { createSelector } from 'reselect';
import { RootState } from 'store';

export const selectProfileState = (state: RootState) => state.profile;

export const selectStoreContactProfile = createSelector(
  selectProfileState,
  profileState => profileState.storeContactProfile
);

export const selectChangePasswordStatus = createSelector(
  selectProfileState,
  profileState => profileState.changePasswordStatus
);

export const selectChangePasswordErrors = createSelector(
  selectProfileState,
  profileState => profileState.changePasswordErrors
);

export const selectProfileError = createSelector(
  selectProfileState,
  profileState => profileState.error
);

export const selectProfileLoading = createSelector(
  selectProfileState,
  profileState => profileState.loading
);
