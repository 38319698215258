import styled, {ThemedStyledProps} from 'styled-components/macro';
import CardContent from '@material-ui/core/CardContent';

const StyledCardContentOpen = styled(CardContent)`
  border-top: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  background-color: ${({ theme: { colors } }) => colors.white};
  padding: 0;
  width: 100%;
`;

const ItemsBlock = styled.div`
  padding: 0 24px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.productBorderGrey};
  @media (max-width: 1023px) {
    padding: 0 10px;
  }
`;

const SingleItem = styled.div`
  display: flex;
  column-gap: 14px;
  padding: 14px 0;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.productBorderGrey};
  align-items: center;
`;

const SingleItemInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 17px;
  }
`;

const ItemNameBlock = styled.div`
  width: 325px;
  margin-right: 48px;
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }
`;

const ItemMainName = styled.div`
  font-size: 16px;
  font-weight: 400;
  @media (max-width: 1023px) {
    font-size: 14px;
  }
`;

const ItemSubName = styled.div`
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.grey};
`;

const QtyPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: 1024px) {
    width: 40%;
  }
`;

const ItemQty = styled.div`
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

interface ItemPriceProps extends ThemedStyledProps<object, HTMLParagraphElement> {
  isDelete: boolean;
}

const ItemPriceStyled = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  text-decoration: ${(props: ItemPriceProps) => props.isDelete ? 'line-through' : ''};
`;

const NotesBlock = styled.div`
  display: flex;
  gap: 25px;
  padding: 16px 0;
  font-size: 16px;
  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 15px;
  }
`;

const NotesLabel = styled.div`
  color: ${({ theme: { colors } }) => colors.grey};
  @media (max-width: 1023px) {
    font-size: 14px;
  }
`;

const NotesText = styled.div`
  word-wrap: break-word;
	width: 90%;
	white-space: initial;
  @media (max-width: 1023px) {
    font-size: 14px;
  }
`;

const TaxesBlock = styled.div`
  display: flex;
  gap: 11px;
  padding: 26px 24px;
  width: 100%;
  @media (max-width: 1023px) {
    padding: 26px 10px;
  }
`;

const TaxesBlockItem = styled.div`
  width: 100%;
`;

const PromocodeLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.grey};
  line-height: 1.7;
`;

const TaxesLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.grey};
  margin-bottom: 7px;
`;

const TaxesSingleItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const TaxesName = styled.div`
  max-width: 685px;
  @media (max-width: 1023px) {
    width: 100%;
    font-size: 12px;
  }
`;

const TaxesPrice = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.mainGreen};
`;

const DeliveryBlock = styled.div`
  display: flex;
  padding: 0 24px;
  gap: 11px;
  margin-top: 26px;
  @media (max-width: 1023px) {
    padding: 0 10px;
  }
`;

const DeliveryLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.grey};
  margin-bottom: 7px;
  @media (max-width: 1023px) {
    font-size: 14px;
  }
`;

const DeliveryText = styled.div`
  @media (max-width: 1023px) {
    font-size: 12px;
  }
`;

const TransactionTypeBlock = styled.div`
  display: flex;
  padding: 0 24px;
  gap: 11px;
  margin-top: 26px;
  padding-bottom: 24px;
  @media (max-width: 1023px) {
    padding: 0 10px 25px;
    border: none;
  }
`;

const TransactionTypeLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.grey};
  margin-bottom: 7px;
  @media (max-width: 1023px) {
    font-size: 14px;
  }
`;
const TransactionTypeText = styled.div`
  @media (max-width: 1023px) {
    font-size: 12px;
  }
`;

const SvgWrapper = styled.div`
  margin-top: 2px;
`;

const CreatedBlock = styled.div`
  width: 110px;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 12px;
  background-color: ${({ theme: { colors } }) => colors.productBorderGrey};

  @media (max-width: 988px) {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
`;

export {
  Image,
  CreatedBlock,
  SvgWrapper,
  TransactionTypeText,
  TransactionTypeLabel,
  TransactionTypeBlock,
  DeliveryText,
  DeliveryLabel,
  DeliveryBlock,
  TaxesPrice,
  TaxesName,
  TaxesSingleItem,
  TaxesLabel,
  TaxesBlockItem,
  TaxesBlock,
  NotesText,
  NotesLabel,
  NotesBlock,
  ItemPriceStyled,
  ItemQty,
  QtyPriceWrapper,
  ItemSubName,
  ItemMainName,
  ItemNameBlock,
  SingleItemInfoWrapper,
  SingleItem,
  ItemsBlock,
  StyledCardContentOpen,
  PromocodeLabel,
};
