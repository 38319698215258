import React, { useEffect } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { authenticatedSelector } from 'ducks/auth/selectors';
import { checkAuth } from 'ducks/auth/actions';

import Routes from 'routes';
import { serverErrorSelector } from 'ducks/application/selectors';
import styled from 'styled-components/macro';

const Root = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: ${({ theme: { colors } }) => colors.white};
`;

type Props = {
  component: React.ComponentType<any>;
  [key: string]: unknown;
};

const LandingRoutes: React.FC<Props> = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const isAuthenticated = useSelector(authenticatedSelector);
  const serverError = useSelector(serverErrorSelector);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuth());
    if (serverError) {
      history.replace(Routes.ERROR_500);
    }

    if (isAuthenticated) {
      const locationState = location.state as { from: string };
      const redirectTo = locationState?.from || Routes.CATEGORIES;
      history.replace(redirectTo);
    }
  }, [isAuthenticated]);

  return !isAuthenticated ? (
    <Route
      {...rest}
      render={props => (
        <Root>
          <Component {...props} />
        </Root>
      )}
    />
  ) : null;
};

export default LandingRoutes;
