import { Reducer } from 'redux';

// Types
import { ProductActionTypes, ProductState } from 'ducks/productVariant/types';
import uniqBy from 'lodash/uniqBy';

const initialState: ProductState = {
  products: [],
  featuredProducts: [],
  facets: [],
  rangeFilters: [],
  isLastPage: true,
  pricingReqSuccess: false,
  loading: true,
  productIdListLoading: false,
  featuredProductsLoading: true,
  error: null,
  showPrices: true,
  similarProducts: [],
  alsoLikeProducts: [],
  accessories: [],
  paginationLoading: false,
  total: 0,
  productIdsList: [],
  relatedProducts: [],
  alterNativeProducts: [],
  sendEmailNearbyBranchStatus: false,
};

const productVariantReducer: Reducer<ProductState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case ProductActionTypes.REQUEST_PRODUCTS: {
      return {
        ...state,
        loading: payload.featured ? state.loading : true,
        featuredProductsLoading: payload.featured ? true : state.featuredProductsLoading,
        product: null,
      };
    }
    case ProductActionTypes.REQUEST_PRODUCTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        products: [...state.products, ...payload.products],
        isLastPage: payload.isLastPage,
      };
    }
    case ProductActionTypes.REQUEST_FEATURED_PRODUCTS_SUCCESS: {
      return {
        ...state,
        featuredProductsLoading: false,
        featuredProducts: payload.products,
      };
    }
    case ProductActionTypes.RESET_FEATURED_PRODUCTS: {
      return { ...state, featuredProducts: [] };
    }
    case ProductActionTypes.REQUEST_PRODUCTS_ERROR: {
      return { ...state, loading: false, error: payload.error };
    }
    case ProductActionTypes.REQUEST_FILTERED_PRODUCTS: {
      return {
        ...state,
        loading: !state.paginationLoading,
        total: payload.total,
      };
    }
    case ProductActionTypes.REQUEST_FILTERED_PRODUCTS_SUCCESS: {
      let filteredProducts = [...state.products, ...payload.products];
      filteredProducts = uniqBy(filteredProducts, 'id');
      return {
        ...state,
        loading: false,
        paginationLoading: false,
        products: filteredProducts,
        isLastPage: payload.isLastPage,
        total: payload.total,
      };
    }
    case ProductActionTypes.REQUEST_FILTERED_PRODUCTS_SUCCESS_FIRST_LOAD: {
      return {
        ...state,
        loading: false,
        products: payload.products,
        isLastPage: payload.isLastPage,
        total: payload.total,
      };
    }

    case ProductActionTypes.REQUEST_FILTERS_SUCCESS: {
      return {
        ...state,
        // loading: false,
        facets: payload.facets,
        rangeFilters: payload.rangeFilters,
      };
    }

    case ProductActionTypes.REQUEST_FILTERED_PRODUCTS_ERROR: {
      return { ...state, loading: false, paginationLoading: false, error: payload.error };
    }
    case ProductActionTypes.RESET_PRODUCTS: {
      return { ...state, products: [], facets: [] };
    }
    case ProductActionTypes.REQUEST_PRICING:
    case ProductActionTypes.REQUEST_PRICING_SUCCESS: {
      return state;
    }
    case ProductActionTypes.REQUEST_PRICING_ERROR: {
      return { ...state, error: payload.error };
    }
    case ProductActionTypes.SET_SHOW_PRICES: {
      return { ...state, showPrices: payload.value };
    }
    case ProductActionTypes.RESET_PRODUCTS_ERROR: {
      return { ...state, error: null };
    }
    case ProductActionTypes.REQUEST_VARIANT_ASSOCIATIONS: {
      return { ...state, error: null };
    }
    case ProductActionTypes.REQUEST_VARIANT_ASSOCIATIONS_SUCCESS: {
      return {
        ...state,
        similarProducts: payload.similarProducts,
        alsoLikeProducts: payload.alsoLikeProducts,
        accessories: payload.accessories,
      };
    }
    case ProductActionTypes.RESET_VARIANT_ASSOCIATIONS: {
      return { ...state, similarProducts: [], alsoLikeProducts: [], accessories: [] };
    }
    case ProductActionTypes.SET_PAGINATION_LOADING: {
      return { ...state, paginationLoading: true };
    }
    case ProductActionTypes.REMOVE_PAGINATION_LOADING: {
      return { ...state, paginationLoading: false };
    }
    case ProductActionTypes.REQUEST_GET_PRODUCT_IDS: {
      return { ...state, productIdListLoading: true };
    }
    case ProductActionTypes.REQUEST_GET_PRODUCT_IDS_SUCCESS: {
      return { ...state, productIdsList: payload.productIdsList, productIdListLoading: false };
    }
    case ProductActionTypes.REQUEST_PRODUCT_ASSOCIATION_SUCCESS: {
      return {
        ...state,
        relatedProducts: payload.relatedProducts,
        alterNativeProducts: payload.alterNativeProducts,
      }
    }
    case ProductActionTypes.SET_FILTERED_PRODUCTS_ERROR: {
      return {
        ...state,
        error: payload.error,
      }
    }
    case ProductActionTypes.REQUEST_SEND_NEARBY_BRANCH_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmailNearbyBranchStatus: true,
      }
    case ProductActionTypes.REQUEST_SET_SEND_NEARBY_BRANCH_EMAIL_STATUS: {
      return {
        ...state,
        sendEmailNearbyBranchStatus: payload.status,
      }
    }
    default:
      return state;
  }
};

export default productVariantReducer;
