import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import ProductRowCard from 'pages/Product/ProductRowView/ProductRowCard';
import { ProductVariantClass } from 'models/classes/productVariantClass';

import { selectStoreBanner } from 'ducks/application/selectors';
import { BannerItem } from 'components/common';

// Styles
const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const WrapperBanner = styled.div`
  margin: 16px 0 16px 24px;
`;

interface IProductRowsCard {
  products: ProductVariantClass[];
  categoryName?: string;
  code?: string;
  mainCategoryName?: string;
  id?: string;
  renderProductStock: CallableFunction;
  handleClickProductStock: CallableFunction;
}

const ProductRowsCard: React.FC<IProductRowsCard> = ({
  products,
  categoryName,
  code,
  mainCategoryName,
  id,
  renderProductStock,
  handleClickProductStock
}: IProductRowsCard) => {
  const banner = useSelector(selectStoreBanner);
  return (
    <Container>
      {products.map((product, index) => (
        <React.Fragment key={product.code}>
          <ProductRowCard
            productVar={product}
            categoryName={categoryName}
            code={code}
            mainCategoryName={mainCategoryName}
            id={id}
            renderProductStock={renderProductStock}
            handleClickProductStock={handleClickProductStock}
            products={products}
          />
          {banner?.url && (index + 1) % 6 === 0 && (
            <WrapperBanner>
              <BannerItem />
            </WrapperBanner>
          )}
        </React.Fragment>
      ))}
    </Container>
  );
};

export default ProductRowsCard;
