import { Reducer } from 'redux';
import { SnackbarState, UIActionTypes } from './types';
import { SnackbarAction } from './actions';

const initialState: SnackbarState = {
  open: false,
  type: 'success',
  message: null,
};

const uiReducer: Reducer<SnackbarState, SnackbarAction> = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case UIActionTypes.TOGGLE_SNACKBAR_OPEN: {
      return {
        ...state,
        open: true,
        type: payload.type,
        message: payload.message,
      };
    }

    case UIActionTypes.TOGGLE_SNACKBAR_CLOSE: {
      return {
        ...state,
        open: false,
        message: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default uiReducer;
