import axios, { AxiosError, AxiosResponse } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import store from '../store';

export const baseURL = process.env.REACT_APP_BACKEND_HOSTNAME || 'undefined-backend-url';

const publicRequest = axios.create({
  baseURL,
  headers: {
    accept: 'application/json',
  }
})

publicRequest.interceptors.request.use(request => {
  const appRequest = request;
  appRequest.headers['Accept-Language'] = store.getState()?.app?.language;
  appRequest.headers['Accept'] = 'application/json';
  if (appRequest?.data) {
    appRequest.data = decamelizeKeys(appRequest.data);
  }
  if (appRequest?.params) {
    appRequest.params = decamelizeKeys(appRequest.params);
  }
  return appRequest;
})

publicRequest.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data && response.headers['content-type'] === 'application/json') {
      response.data = camelizeKeys(response.data);
    }
    return response;
  },
  (error: AxiosError) => {
    const responseError = error;
    if (responseError.response.status === 503) {
      return window.location.replace('/maintenance');
    }

    responseError.response.data = camelizeKeys(responseError.response.data);
    return Promise.reject(error);
  }
);

export default publicRequest;
