import React from 'react';

import { useSelector } from 'react-redux';
import { selectStoreBanner } from 'ducks/application/selectors';
import isEmpty from 'lodash/isEmpty';
import { useGlobalBanner } from 'hooks/useGlobalBanner';
import { selectBranch } from 'ducks/branch/selectors';
import { StyledGrid, MenuContentGrid } from './styles';


const CategoryNotification = () => {
  const storeBanner = useSelector(selectStoreBanner);
  const branch = useSelector(selectBranch);
  const {data: globalBanner} = useGlobalBanner(branch?.id);

  if (!isEmpty(globalBanner)) return null;

  return (
    <>
      {storeBanner && (
        <StyledGrid container variant={storeBanner.type}>
          <MenuContentGrid item variant={storeBanner.type}>
            {storeBanner.text}
          </MenuContentGrid>
        </StyledGrid>
      )}
    </>
  );
};

export { CategoryNotification };
