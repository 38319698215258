import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';

import { FilterArrowDown, FilterArrowUp } from 'assets/svg/Filter/Arrow';
import { IRangeFilter, ISelectedValue } from 'models/interfaces/productVariant';
import {
  ButtonNameWrapper,
  SingleFilterWrapper,
  StyledButton,
  StyledSlider,
  RangeFilterInputsWrapper,
  RangeFilterInput,
  RangeFilterContentWrapper,
} from 'components/Product/ProductFilter/styles';
import ValueLabelComponent from 'components/Product/ProductFilter/components/RangeFilter/components/ValueLabelComponent/ValueLabelComponent';

interface IRangeFilterProps {
  rangeFilter: IRangeFilter;
  selectedValues: ISelectedValue[];
  handleFilterChange: (code: string, name: string, values: number[]) => void;
}

const RangeFilter = ({ rangeFilter, selectedValues, handleFilterChange }: IRangeFilterProps) => {
  const [open, setOpen] = useState(selectedValues.some(value => value.code === rangeFilter.code));
  const { step, min_value: minValue, max_value: maxValue } = rangeFilter.configuration;
  const selectedFacetValues = selectedValues.find(
    selectedValue => selectedValue.code === rangeFilter.key
  );
  const defaultValues = selectedFacetValues
    ? ([
      selectedFacetValues.values[0],
      selectedFacetValues.values[selectedFacetValues.values.length - 1],
    ] as number[])
    : [minValue, maxValue];
  const [value, setValue] = useState<number[]>(defaultValues);

  const handleOpenClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    selectedValues.every(selectedValue => {
      if (selectedValue.code === rangeFilter.key) {
        setOpen(true);
        return false;
      }

      return true;
    });
  }, [selectedValues, rangeFilter]);

  const delayedFilterChange = useCallback(
    debounce((code, name, values) => {
      const valuesToUse = [
        values[0] < minValue ? minValue : values[0],
        values[1] > maxValue ? maxValue : values[1],
      ];

      handleFilterChange(code, name, valuesToUse);
    }, 1000),
    [rangeFilter]
  );

  const handleChange = (event: any, newValue: number | number[]) => {
    setValue(newValue as number[]);

    delayedFilterChange(rangeFilter.key, rangeFilter.name, newValue as number[]);
  };

  const handleMinInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = [Number(event.target.value), value[1]];
    setValue(newValue);

    delayedFilterChange(rangeFilter.key, rangeFilter.name, newValue);
  };

  const handleMaxInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = [value[0], Number(event.target.value)];
    setValue(newValue);

    delayedFilterChange(rangeFilter.key, rangeFilter.name, newValue);
  };

  return (
    <SingleFilterWrapper>
      <StyledButton onClick={handleOpenClick}>
        <ButtonNameWrapper>{rangeFilter.name}</ButtonNameWrapper>
        {open ? <FilterArrowUp /> : <FilterArrowDown />}
      </StyledButton>
      <RangeFilterContentWrapper isOpen={open}>
        <StyledSlider
          value={value}
          onChange={handleChange}
          valueLabelDisplay={open ? 'on' : 'off'}
          aria-labelledby="range-slider"
          step={step || 1}
          min={minValue}
          max={maxValue}
          ValueLabelComponent={ValueLabelComponent}
        />
        <RangeFilterInputsWrapper>
          <RangeFilterInput
            InputProps={{ disableUnderline: true }}
            placeholder={`from ${minValue}`}
            value={value[0]}
            onChange={handleMinInputChange}
            type="number"
          />
          <RangeFilterInput
            InputProps={{ disableUnderline: true }}
            placeholder={`to ${maxValue}`}
            value={value[1]}
            onChange={handleMaxInputChange}
            type="number"
          />
        </RangeFilterInputsWrapper>
      </RangeFilterContentWrapper>
    </SingleFilterWrapper>
  );
};

export default RangeFilter;
