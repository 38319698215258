import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { InputAdornment, InputLabel, MenuItem, Theme } from '@material-ui/core';
import { Search, ExpandMore, KeyboardArrowDown } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { DateRangePicker, DateRangePickerModal } from 'pages/General/Profile/index';
import { selectUserAddresses, selectUserData } from 'ducks/user/selectors';
import { selectContacts } from 'ducks/contact/selectors';
import {
  selectAddressId,
  selectErpCustomerAddressCode,
  selectLanguage,
} from 'ducks/application/selectors';
import { ORDER_STATUSES } from 'assets/constants/order';
// import {getAddressString, getUserType, getValue} from 'utils/profile/profileUtilsHelpers';
import { getAddressString, getUserType, getValue } from 'utils/profile/profileUtilsHelpers';
import { useCreateFilterObject } from 'hooks/useCreateFilterObject';
import { QUOTES_STATUSES } from 'assets/constants/quotes';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import TagManager from 'react-gtm-module';
import { selectBranch } from 'ducks/branch/selectors';
import { Autocomplete } from '@material-ui/lab';
import { IAddress } from 'models/interfaces/user';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import {
  FilterClose,
  ArrowIcon,
  CalendarIcon,
  StyledFormControl,
  StyledDateRangeField,
  StyledTextField,
  StyledSelect,
  ClearAllFiltersButton,
  FilterBlock,
  SelectedFiltersBlock,
  DateWrapper,
  OrdersHeader,
  // StyledMenuItem,
  MenuItemText,
  AutocompleteWrapper,
} from './styles';

interface IRequestData {
  input?: string;
  status?: string;
  address?: string;
  contact?: string;
  from?: string;
  to?: string;
  dateRange?: string;
  sort?: string;
}

interface FiltersBlockProps {
  onFilterChange?(data: IRequestData): void;

  defaultFilterValues: IRequestData;
  isQuotes: boolean;
  arrowFromUpToDown: boolean;
  setIsApplyFilterInputInput: Function;
  isApplyFilterInput: boolean;
}

interface IUseStylesProps {
  isFrench: boolean;
}

const useStyles = makeStyles<Theme, IUseStylesProps>({
  root: {
    fontSize: ({ isFrench }: IUseStylesProps) => (isFrench ? '12px !important' : 'inherit'),
    width: '82%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '16px',
  },
});

const FiltersBlock: React.FC<FiltersBlockProps> = ({
  onFilterChange,
  defaultFilterValues,
  isQuotes,
  arrowFromUpToDown,
  isApplyFilterInput,
  setIsApplyFilterInputInput,
}: FiltersBlockProps) => {
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState(defaultFilterValues);

  const [statuses, setStatuses] = useState([...ORDER_STATUSES]);
  const oldValueInput = useRef(defaultFilterValues.input);
  const oldValueStatus = useRef(defaultFilterValues.status);
  const oldValueDateRange = useRef(defaultFilterValues.dateRange);
  const oldValueAddress = useRef(defaultFilterValues.address);

  const filtersData: IRequestData = {};

  const user = useSelector(selectUserData);
  const userAddresses = useSelector(selectUserAddresses);
  const contacts = useSelector(selectContacts);
  const language = useSelector(selectLanguage);
  const branch = useSelector(selectBranch);
  const { t } = useTranslation();
  const classes = useStyles({
    isFrench: language === 'fr_CA',
  });

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const applyFilter = (resultParams: IRequestData) => {
    if (onFilterChange) {
      if (resultParams.input !== '' && oldValueInput.current !== resultParams.input) {
        clearGtmEcommerce();
        TagManager.dataLayer({
          dataLayer: {
            event: 'order_quotes_search',
            user_type: getUserType(user.email),
            branch_id: branch.id,
            customer_code: user?.customer?.erpCustomerCode,
            address_code: erpCustomerAddressCode,
            address_id: selectedAddressId,
          },
        });
      }
      if (resultParams.status !== '' && oldValueStatus.current !== resultParams.status) {
        clearGtmEcommerce();
        TagManager.dataLayer({
          dataLayer: {
            event: 'order_quotes_change_status',
            user_type: getUserType(user.email),
            branch_id: branch.id,
            customer_code: user?.customer?.erpCustomerCode,
            address_code: erpCustomerAddressCode,
            address_id: selectedAddressId,
          },
        });
      }
      if (oldValueDateRange.current !== resultParams.dateRange) {
        clearGtmEcommerce();
        TagManager.dataLayer({
          dataLayer: {
            event: 'order_quotes_change_date',
            user_type: getUserType(user.email),
            branch_id: branch.id,
            customer_code: user?.customer?.erpCustomerCode,
            address_code: erpCustomerAddressCode,
            address_id: selectedAddressId,
          },
        });
      }
      if (resultParams.address !== '' && oldValueAddress.current !== resultParams.address) {
        clearGtmEcommerce();
        TagManager.dataLayer({
          dataLayer: {
            event: 'order_quotes_change_address',
            user_type: getUserType(user.email),
            branch_id: branch.id,
            customer_code: user?.customer?.erpCustomerCode,
            address_code: erpCustomerAddressCode,
            address_id: selectedAddressId,
          },
        });
      }
      onFilterChange(resultParams);
      oldValueInput.current = resultParams.input;
      oldValueStatus.current = resultParams.status;
      oldValueDateRange.current = resultParams.dateRange;
      oldValueAddress.current = resultParams.address;
    }
  };

  const handleOnChangeFilter = (filterParams: Partial<IRequestData>) => {
    setFilters({ ...filters, ...filterParams });
  };

  const handleOnChange = (filterParams: Partial<IRequestData>) => {
    const resultParams = { ...filters, ...filterParams };
    setFilters(resultParams);
    applyFilter(resultParams);
  };

  const getDateFromDateRangePicker = range => {
    handleOnChange({
      dateRange: `${format(range.startDate, 'MM/dd/yyyy')} - ${format(
        range.endDate,
        'MM/dd/yyyy'
      )}`,
      from: format(range.startDate, 'dd/MM/yyyy HH:mm:ss'),
      to: format(range.endDate, 'dd/MM/yyyy HH:mm:ss'),
    });
  };

  const clearDateRangeFilterHandler = () => {
    handleOnChange({ dateRange: '', from: '', to: '' });
  };

  const filterCloseHandler = filter => {
    if (filter[0] === 'dateRange') {
      handleOnChange({ [filter[0]]: '', from: '', to: '' });
    } else {
      handleOnChange({ [filter[0]]: '' });
    }
  };

  const clearAllFiltersHandler = () => {
    handleOnChange({
      input: '',
      status: '',
      address: '',
      contact: '',
      dateRange: '',
      from: '',
      to: '',
    });
    setIsApplyFilterInputInput(false);
  };

  const getStatuses = () => {
    if (!isQuotes) {
      setStatuses(ORDER_STATUSES);
    } else {
      setStatuses(QUOTES_STATUSES);
    }
  };

  useEffect(() => {
    getStatuses();
  }, [user]);

  useCreateFilterObject(filters, filtersData);

  const handleArrowClick = () => {
    handleOnChange({ sort: !arrowFromUpToDown ? 'DESC' : 'ASC' });
  };

  return (
    <>
      <DateRangePickerModal isOpen={isOpen} handleClose={() => closeModal()}>
        <DateRangePicker
          open
          onChange={getDateFromDateRangePicker}
          clearDataRangeFilter={clearDateRangeFilterHandler}
        />
      </DateRangePickerModal>

      <>
        <OrdersHeader>
          <StyledTextField
            variant="outlined"
            name="tagSearch"
            label={t('orders_and_quotes.taiga_order_po')}
            value={filters.input}
            onChange={e => handleOnChangeFilter({ input: e.currentTarget.value })}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                applyFilter(filters);
              }
            }}
            InputLabelProps={{
              classes: {
                root: classes.root,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={() => applyFilter(filters)}>
                  <Search cursor="pointer" color="disabled" />
                </InputAdornment>
              ),
            }}
          />
          <StyledFormControl variant="outlined">
            <InputLabel id="project-select-label">{t('orders_and_quotes.status')}</InputLabel>
            <StyledSelect
              labelId="project-select-label"
              id="project-select"
              name="status"
              label="status"
              value={filters.status}
              onChange={e => handleOnChange({ status: e.target.value as string })}
              IconComponent={ExpandMore}
            >
              {statuses.map(status => (
                <MenuItem key={status} value={status}>
                  <MenuItemText>
                    {t(
                      `orders_and_quotes.${status
                        .toLowerCase()
                        .split(' ')
                        .join('_')}`
                    )}
                  </MenuItemText>
                </MenuItem>
              ))}
            </StyledSelect>
          </StyledFormControl>

          <DateWrapper>
            <StyledDateRangeField
              variant="outlined"
              label={t('orders_and_quotes.date_range')}
              value={filters.dateRange}
              onClick={openModal}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
              disabled
            />
            <ArrowIcon
              color="action"
              fontSize="large"
              style={{ transform: !arrowFromUpToDown && 'rotate(270deg)' }}
              onClick={handleArrowClick}
              sorttype={filters.sort}
            />
          </DateWrapper>

          <StyledFormControl variant="outlined">
            <AutocompleteWrapper>
              <Autocomplete
                disablePortal
                disableClearable
                blurOnSelect
                options={userAddresses}
                getOptionLabel={(address: IAddress) => getAddressString(address)}
                renderOption={(address: IAddress, props) => {
                  const label = getAddressString(address);

                  return (
                    <li
                      {...props}
                      className={`${
                        Number(address?.id) === Number(filters.address) ? 'item-selected' : ''
                      }`}
                    >
                      {label}
                      {Number(address?.id) === Number(filters.address) && (
                        <span className="ic-arrow">✓</span>
                      )}
                    </li>
                  );
                }}
                popupIcon={<KeyboardArrowDown />}
                openOnFocus
                renderInput={params => (
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    label={t('orders_and_quotes.address')}
                    {...params}
                  />
                )}
                onChange={(event, address: IAddress) => {
                  if (address) {
                    return handleOnChange({ address: String(address.id) });
                  }
                  return handleOnChange({ address: '' });
                }}
                defaultValue={userAddresses?.find(
                  address => Number(address.id) === Number(filters.address)
                )}
              />
            </AutocompleteWrapper>

            {/* <Autocomplete
              disablePortal
              options={userAddresses}
              selectOnFocus
              freeSolo
              getOptionLabel={(address: IAddress) => {
                return address.name
                  ? `${address?.name?.toString()}`
                  : `${address?.erpPostCode?.toString()}`;
              }}
              renderOption={(address: IAddress) => getAddressString(address)}
              renderInput={(params) => {
                return <TextField autoFocus {...params}/>
              }}
              onChange={(event, address: IAddress) => {
                if (address) {
                  return handleOnChange({address: String(address.id)});
                }
                return handleOnChange({address: ''});
              }}
              defaultValue={userAddresses.find(address => Number(address.id) === Number(filters.address))}
            /> */}
          </StyledFormControl>

          {/* <StyledFormControl variant="outlined"> */}
          {/*  <InputLabel id="project-select-label">{t('orders_and_quotes.address')}</InputLabel> */}
          {/*  <StyledSelect */}
          {/*    labelId="project-select-label" */}
          {/*    id="project-select" */}
          {/*    name="address" */}
          {/*    label="address" */}
          {/*    value={filters.address} */}
          {/*    onChange={e => handleOnChange({address: e.target.value as string})} */}
          {/*    IconComponent={ExpandMore} */}
          {/*  > */}
          {/*    {user?.addresses?.map(address => ( */}
          {/*      <StyledMenuItem key={address.id} value={address.id}> */}
          {/*        {getAddressString(address)} */}
          {/*      </StyledMenuItem> */}
          {/*    ))} */}
          {/*  </StyledSelect> */}
          {/* </StyledFormControl> */}

          {/*  <StyledFormControl variant="outlined"> */}
          {/*    <InputLabel id="project-select-label">{t('orders_and_quotes.user')}</InputLabel> */}
          {/*    <StyledSelect */}
          {/*      labelId="project-select-label" */}
          {/*      id="project-select" */}
          {/*      name="user" */}
          {/*      label="user" */}
          {/*      value={filters.contact} */}
          {/*      onChange={e => handleOnChange({ contact: e.target.value as string })} */}
          {/*      IconComponent={ExpandMore} */}
          {/*    > */}
          {/*      {contacts?.map(contact => ( */}
          {/*        <StyledMenuItem key={contact.id} value={contact.email}> */}
          {/*          {contact.fullName} */}
          {/*        </StyledMenuItem> */}
          {/*      ))} */}
          {/*      <MenuItem value="offline">{t('products_and_search.offlineUsers')}</MenuItem> */}
          {/*    </StyledSelect> */}
          {/*  </StyledFormControl> */}
        </OrdersHeader>

        <SelectedFiltersBlock>
          {Object.entries(filters)
            .filter(v => v[0] === 'input')
            .map(filter => {
              if (isApplyFilterInput && (filter[1] !== '' || oldValueInput.current !== '')) {
                return (
                  <FilterBlock key={filter[0]}>
                    {oldValueInput.current !== null && oldValueInput.current !== filter[1]
                      ? oldValueInput.current
                      : filter[1]}
                    <FilterClose
                      color="disabled"
                      onClick={() => {
                        filterCloseHandler(filter);
                        setIsApplyFilterInputInput(false);
                      }}
                    />
                  </FilterBlock>
                );
              }
              return null;
            })}
          {Object.entries(filters).map(filter => {
            if (
              filter[1] !== '' &&
              filter[0] !== 'from' &&
              filter[0] !== 'to' &&
              filter[0] !== 'sort'
            ) {
              if (filter[0] === 'input') {
                return null;
              }
              return (
                <FilterBlock key={filter[0]}>
                  {getValue(filter, user, contacts)}
                  <FilterClose color="disabled" onClick={() => filterCloseHandler(filter)} />
                </FilterBlock>
              );
            }
            return null;
          })}
          {(filters.input !== '' && isApplyFilterInput) ||
          filters.status !== '' ||
          filters.address !== '' ||
          filters.contact !== '' ||
          filters.dateRange !== '' ? (
              <ClearAllFiltersButton onClick={clearAllFiltersHandler}>
                {t('products_and_search.clear_all')}
              </ClearAllFiltersButton>
            ) : null}
        </SelectedFiltersBlock>
      </>
    </>
  );
};

export default FiltersBlock;
