/* eslint-disable no-console */
import React, {useCallback, useEffect, useRef, useState} from 'react';
import { PopperPlacementType } from '@material-ui/core';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';

import { ListViewMinus, ListViewPlus } from 'assets/svg/Product/Products';
import ProductInputPopper from 'components/Popper/ProductInputPopper';
import { requestChangeProductQty, updateItemInputValues } from 'ducks/cart/actions';
import { useTranslation } from 'react-i18next';

import { filterPrices } from 'utils/products/productVariant';
import TagManager from 'react-gtm-module';

import get from 'lodash/get';
import {
  priceTypeSelector,
  selectAddressId,
  selectErpCustomerAddressCode,
} from 'ducks/application/selectors';
import { usePermission } from 'hooks/usePermission';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import {useGA4CartEvent} from 'GA/CartGa4/cartGA4';
import {
  InputButtonsWrapper,
  InputWithLabelWrapper,
  StyledInputWrapper,
  IncrementButton,
  IncrementButtonLabel,
  StyledTextField,
  PromotionText,
} from './styles';

interface IProductInputs {
  cartItem?: any;
  topInputValue: number;
  bottomInputValue?: number;
  setTopInputValue: React.Dispatch<React.SetStateAction<number>>;
  setBottomInputValue: React.Dispatch<React.SetStateAction<number>>;
  isSecondStep?: boolean;
  popperPlacement: PopperPlacementType;
  onHasErrors?(hasError: boolean): void;
}

const CartProductInputs: React.FC<IProductInputs> = ({
  topInputValue,
  bottomInputValue,
  setTopInputValue,
  setBottomInputValue,
  cartItem,
  isSecondStep,
  popperPlacement,
  onHasErrors,
}: IProductInputs) => {
  const {handleFireGA4RemoveFromCartSubTract, handleFireGA4AddToCartPlus} = useGA4CartEvent();
  const dispatch = useDispatch();
  const timeClickGtm = useRef(0);
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const deliveryType = useSelector(priceTypeSelector);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const { canSeePrice } = usePermission();
  const [messageTop, setMessageTop] = useState('');
  const [messageBottom, setMessageBottom] = useState('');
  const { t } = useTranslation();
  const productPrices = filterPrices(cartItem.variant.addressPrices);
  const [botVal, setBotVal] = useState(bottomInputValue ? bottomInputValue.toString() : '');

  useEffect(() => {
    if (bottomInputValue) {
      setBotVal(bottomInputValue.toString());
    }
  }, [bottomInputValue]);

  const topRef = useRef();
  const bottomRef = useRef();

  const buttonTopRef = useRef();
  const buttonBottomRef = useRef();

  const uomConversion = Number(cartItem.variant.units.uom2Conversion);
  const cartItemCoeff = Number((productPrices[0].qty / uomConversion).toFixed(2));

  const handleIfIncorrectValues = useCallback(
    (topValue: number, bottomValue: number | null) => {
      let messageTopTemp: string;
      let messageBottomTemp: string;

      const minQuantity = `${productPrices[0].qty}`;
      const minimalQuantityValue = `${productPrices[0].qty} ${cartItem.variant.units.perName}`;
      const unitsPerName = `${cartItem.variant.units.perName}`;
      const orderOnly = `${cartItem.variant.units.perName} (1 ${
        cartItem.variant.units.perName
      } = ${Number((productPrices[0].qty / cartItem.variant.units.uom2Conversion).toFixed(2))} ${
        cartItem.variant.units.uom2Name
      }) `;

      // min order uom quantity
      if (productPrices[0].qty > topValue) {
        // top uom value
        messageTopTemp = t('price_calculator.min_quantity', { minimalQuantityValue });
        setMessageTop(messageTopTemp);
      } else {
        messageTopTemp = '';
        setMessageTop('');
      }

      if (!messageTopTemp && topValue % productPrices[0].qty !== 0) {
        messageTopTemp = t('price_calculator.divisible', { minQuantity });
        setMessageTop(messageTopTemp);
      } else if (!messageTopTemp) {
        messageTopTemp = '';
        setMessageTop('');
      }

      if (bottomValue) {
        const remaining = Number((Number(bottomValue.toFixed(2)) % cartItemCoeff).toFixed(2));
        // min order uom2 quantity
        if (remaining !== 0 && remaining !== cartItemCoeff) {
          // bottom uom value
          messageBottomTemp = t('price_calculator.packs_provide', { unitsPerName, orderOnly });
          setMessageBottom(messageBottomTemp);
        } else {
          messageBottomTemp = '';
          setMessageBottom('');
        }
      } else {
        messageBottomTemp = '';
        setMessageBottom('');
      }

      if (onHasErrors) {
        onHasErrors(!(messageTopTemp === '' && messageBottomTemp === ''));
      }

      return messageTopTemp === '' && messageBottomTemp === '';
    },
    [
      cartItem.variant.units.perName,
      cartItem.variant.units.uom2Conversion,
      cartItem.variant.units.uom2Name,
      cartItemCoeff,
      onHasErrors,
      productPrices,
      t,
    ]
  );

  const delayedUpdateCartItemQty = useCallback(
    debounce((newQty, newBottomValue) => {
      if (handleIfIncorrectValues(newQty, newBottomValue)) {
        if (newQty > cartItem.quantity) {
          handleFireGA4AddToCartPlus(cartItem, newQty - cartItem.quantity);
        } else {
          handleFireGA4RemoveFromCartSubTract(cartItem, cartItem.quantity - newQty);
        }
        dispatch(
          requestChangeProductQty({
            cartItemId: cartItem.id,
            qty: newQty,
          })
        );
      }
    }, 1000),
    [cartItem]
  );

  const handleGtmEventChangeUOM = () => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'checkout_change_UOM',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      },
    });
    timeClickGtm.current = Date.now() / 1000;
  };

  const handleIncrement = () => {
    const newTopValue =
      topInputValue - (topInputValue % productPrices[0].qty) + productPrices[0].qty;
    let newBottomValue: number | null;

    setTopInputValue(newTopValue);

    if (bottomInputValue || bottomInputValue === 0) {
      newBottomValue = parseFloat(
        ((newTopValue * cartItemCoeff) / productPrices[0].qty).toFixed(2)
      );
      setBottomInputValue(newBottomValue);
      setBotVal(newBottomValue.toString());
    }

    dispatch(
      updateItemInputValues({
        cartItemId: cartItem.id,
        newTopValue,
        newBottomValue: newBottomValue || 0,
      })
    );
    delayedUpdateCartItemQty(newTopValue, newBottomValue || null);
    if (timeClickGtm.current === 0) {
      handleGtmEventChangeUOM();
      handleFireGA4AddToCartPlus(cartItem, newTopValue - cartItem.quantity);
    } else if (timeClickGtm.current > 0 && Date.now() / 1000 - timeClickGtm.current > 10) {
      handleGtmEventChangeUOM();
      handleFireGA4AddToCartPlus(cartItem, newTopValue - cartItem.quantity);
    }
  };

  const handleDecrement = () => {
    const decrementCoeff = topInputValue % productPrices[0].qty;
    let newTopValue = decrementCoeff
      ? Number((topInputValue - Number(decrementCoeff.toFixed(2))).toFixed(2))
      : topInputValue - decrementCoeff - productPrices[0].qty;

    newTopValue = newTopValue <= 0 ? productPrices[0].qty : newTopValue;

    let newBottomValue: number | null;

    setTopInputValue(newTopValue !== 0 ? newTopValue : 1); /* TODO qty not 1 */

    if (bottomInputValue) {
      newBottomValue = parseFloat(
        ((newTopValue * cartItemCoeff) / productPrices[0].qty).toFixed(2)
      );

      setBottomInputValue(newBottomValue !== 0 ? newBottomValue : cartItemCoeff);
      setBotVal(newBottomValue !== 0 ? newBottomValue.toString() : cartItemCoeff.toString());
    }
    if (newTopValue === topInputValue || newBottomValue === bottomInputValue) return;
    dispatch(
      updateItemInputValues({
        cartItemId: cartItem.id,
        newTopValue,
        newBottomValue: newBottomValue || 0,
      })
    );
    delayedUpdateCartItemQty(newTopValue !== 0 ? newTopValue : 1, newBottomValue || null);
    if (timeClickGtm.current === 0) {
      handleGtmEventChangeUOM();
      handleFireGA4RemoveFromCartSubTract(cartItem, cartItem.quantity - newTopValue);
    } else if (timeClickGtm.current > 0 && Date.now() / 1000 - timeClickGtm.current > 10) {
      handleGtmEventChangeUOM();
      handleFireGA4RemoveFromCartSubTract(cartItem, cartItem.quantity - newTopValue);
    }
  };

  const handleTopFieldChange = e => {
    let newTopValue1: number;
    let newBottomValue1: number;

    if (e.target.value === '') {
      newTopValue1 = 0;
    } else {
      newTopValue1 = parseInt(e.target.value, 10);
    }

    if (bottomInputValue !== null) {
      newBottomValue1 = parseFloat(
        ((newTopValue1 * cartItemCoeff) / productPrices[0].qty).toFixed(2)
      );
      setBottomInputValue(newBottomValue1);
      setBotVal(newBottomValue1.toString());
    }

    setTopInputValue(newTopValue1);
    if (newTopValue1 >= 0) {
      dispatch(
        updateItemInputValues({
          cartItemId: cartItem.id,
          newTopValue: newTopValue1,
          newBottomValue: newBottomValue1 || 0,
        })
      )
      delayedUpdateCartItemQty(newTopValue1, newBottomValue1 || null);
    }

    if (timeClickGtm.current === 0) {
      handleGtmEventChangeUOM();
    } else if (timeClickGtm.current > 0 && Date.now() / 1000 - timeClickGtm.current > 10) {
      handleGtmEventChangeUOM();
    }
  };

  const delayedUpdateTopValue = useCallback(
    debounce(newBottomValue2 => {
      let newTopValue: number;
      let newBottomValue: number;
      const floatRegEx = '^([+-]?\\d*\\.?\\d*)$';
      const getNewTopValue = (bottomValue: number) => {
        return parseFloat(((bottomValue / cartItemCoeff) * productPrices[0].qty).toFixed(2));
      };

      if (newBottomValue2.search(floatRegEx) !== -1) {
        newBottomValue = Number(Number(newBottomValue2).toFixed(2));
        newTopValue = getNewTopValue(newBottomValue);
      } else {
        newTopValue = getNewTopValue(bottomInputValue);
        newBottomValue = bottomInputValue;
        setBotVal(newBottomValue.toString());
      }

      setTopInputValue(newTopValue);
      setBottomInputValue(newBottomValue);

      if (newTopValue >= 0) {
        dispatch(
          updateItemInputValues({
            cartItemId: cartItem.id,
            newTopValue,
            newBottomValue,
          })
        );
        delayedUpdateCartItemQty(newTopValue, newBottomValue);
      }
    }, 1000),
    [cartItem]
  );

  const handleBottomFieldChange = e => {
    const newBottomValue2 = e.target.value;
    const letterCheckRegExp = /[a-zA-z]/;

    if (newBottomValue2.search(letterCheckRegExp) === -1) {
      setBotVal(newBottomValue2);
      delayedUpdateTopValue(newBottomValue2);
    }
  };

  const renderPromotionPrice = () => {
    if (!canSeePrice || deliveryType === 'hide') {
      return null;
    }
    const prices = get(cartItem, 'product.pricings', []);
    if (prices.length > 1) {
      const sortPrices = prices.sort((a, b) => b.quantity - a.quantity);
      const promotionPrice = sortPrices[0];
      const nonSalePrice = sortPrices[1];
      const unitName = get(cartItem, 'variant.units.perName', 'Piece');
      let price;
      let normalPrice;
      if (deliveryType === 'delivery') {
        price = promotionPrice.deliveryPrice;
        normalPrice = nonSalePrice.deliveryPrice;
      } else {
        price = promotionPrice.pickupPrice;
        normalPrice = nonSalePrice.pickupPrice;
      }
      const handleClickPromotionPrice = () => {
        clearGtmEcommerce();
        TagManager.dataLayer({
          dataLayer: {
            event: 'checkout_click_upsell',
            user_type: getUserType(user.email),
            branch_id: branch.id,
            customer_code: user?.customer?.erpCustomerCode,
            address_code: erpCustomerAddressCode,
            address_id: selectedAddressId,
          },
        });

        const newTopValue = promotionPrice.quantity;
        const newBottomValue = parseFloat(
          ((newTopValue * cartItemCoeff) / productPrices[0].qty).toFixed(2)
        );
        setBottomInputValue(newBottomValue !== 0 ? newBottomValue : cartItemCoeff);
        setBotVal(newBottomValue !== 0 ? newBottomValue.toString() : cartItemCoeff.toString());
        delayedUpdateTopValue(
          newBottomValue !== 0 ? newBottomValue.toString() : cartItemCoeff.toString()
        );
        delayedUpdateCartItemQty(promotionPrice.quantity, newBottomValue);
        setTopInputValue(promotionPrice.quantity);
      };
      if (topInputValue < promotionPrice.quantity && normalPrice - price > 0) {
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        return (
          <PromotionText style={{ cursor: 'pointer' }} onClick={handleClickPromotionPrice}>
            {t('product_detail.buy')} {promotionPrice.quantity} {t('product_detail.for')} ${price}/
            {unitName}
          </PromotionText>
        );
      }
      const mapPrices = sortPrices.map(e => {
        return {
          delivery: e.deliveryPrice,
          pickup: e.pickupPrice,
        };
      });
      const totalOldPrice = mapPrices[1][deliveryType] * cartItem.quantity;
      const totalNewPrice = mapPrices[0][deliveryType] * cartItem.quantity;
      const percent = Math.ceil(((totalOldPrice - totalNewPrice) / totalOldPrice) * 100);
      return (
        percent > 0 &&
        topInputValue >= promotionPrice.quantity && (
          <div
            dangerouslySetInnerHTML={{ __html: t('product_detail.saving_percent', { percent }) }}
            style={{ marginTop: 10 }}
          />
        )
      );
    }
    return null;
  };

  return cartItem ? (
    <>
      <InputButtonsWrapper>
        <InputWithLabelWrapper>
          <StyledInputWrapper>
            {messageTop && popperPlacement === 'left' ? (
              <ProductInputPopper
                element={buttonTopRef.current}
                message={messageTop}
                placement={popperPlacement}
              />
            ) : null}
            <IncrementButton
              ref={buttonTopRef}
              disabled={topInputValue === 0 || isSecondStep}
              onClick={handleDecrement}
            >
              <ListViewMinus />
            </IncrementButton>
            <StyledTextField
              type="tel"
              InputProps={{ disableUnderline: true }}
              onChange={e => handleTopFieldChange(e)}
              value={topInputValue}
              disabled={isSecondStep}
            />
            <IncrementButton disabled={isSecondStep} onClick={() => handleIncrement()}>
              <ListViewPlus />
            </IncrementButton>
          </StyledInputWrapper>
          <IncrementButtonLabel ref={topRef}>{cartItem.variant.units.perName}</IncrementButtonLabel>
          {messageTop && popperPlacement === 'right' ? (
            <ProductInputPopper
              element={topRef.current}
              message={messageTop}
              placement={messageTop && messageBottom ? 'top-end' : popperPlacement}
            />
          ) : null}
        </InputWithLabelWrapper>
        {cartItem &&
          cartItem.variant.units.uom2Name &&
          uomConversion !== 0 &&
          bottomInputValue !== null && (
          <InputWithLabelWrapper>
            <StyledInputWrapper>
              {messageBottom && popperPlacement === 'left' ? (
                <ProductInputPopper
                  element={buttonBottomRef.current}
                  message={messageBottom}
                  placement={popperPlacement}
                />
              ) : null}
              <IncrementButton
                ref={buttonBottomRef}
                disabled={Number(bottomInputValue.toFixed(2)) === 0 || isSecondStep}
                onClick={handleDecrement}
              >
                <ListViewMinus />
              </IncrementButton>
              <StyledTextField
                InputProps={{ disableUnderline: true }}
                onChange={handleBottomFieldChange}
                value={botVal}
                disabled={isSecondStep}
              />
              <IncrementButton disabled={isSecondStep} onClick={handleIncrement}>
                <ListViewPlus />
              </IncrementButton>
            </StyledInputWrapper>
            <IncrementButtonLabel ref={bottomRef}>
              {cartItem.variant.units.uom2Name}
            </IncrementButtonLabel>
            {messageBottom && popperPlacement === 'right' ? (
              <ProductInputPopper
                element={bottomRef.current}
                message={messageBottom}
                placement={popperPlacement}
              />
            ) : null}
          </InputWithLabelWrapper>
        )}
      </InputButtonsWrapper>
      {renderPromotionPrice()}
    </>
  ) : null;
};

export default CartProductInputs;
