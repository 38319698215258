import React, { memo } from 'react';
import { CustomRadio, RBLabel, RadioBlock } from './styles';

interface IShippingRadio {
  label: string;
  onClick: () => void;
  isActive: boolean;
  disabled: boolean;
}

const ShippingRadio: React.FC<IShippingRadio> = ({ label, onClick, isActive, disabled }: IShippingRadio) => {
  return (
    <RadioBlock onClick={onClick} isActive={isActive} disabled={disabled}>
      <CustomRadio isActive={isActive} inside="shipping" disabled={disabled} />
      <RBLabel>{label}</RBLabel>
    </RadioBlock>
  );
};

export default memo(ShippingRadio);
