import React, { MouseEventHandler, RefObject } from 'react';
import Fade from 'react-reveal/Fade';
import { EmailIcon } from 'assets/svg/Landing/EmailWhiteIcon';
import { PhoneIcon } from 'assets/svg/Landing/PhoneWhiteIcon';
import { GeoIcon } from 'assets/svg/Landing/GeoWhiteIcon';
import { TaigaLogo } from 'assets/svg/Landing/TaigaLogo';
import { YouTubeIcon } from 'assets/svg/Landing/YouTubeIcon';
import { LinkedInIcon } from 'assets/svg/Landing/LinkedInIcon';
import { TaigaFrench } from 'assets/svg/Landing/TaigaFrch';
import { TaigaEnglish } from 'assets/svg/Landing/TaigaEng';

import {
  Footer,
  FooterWrapper,
  FooterLogoWrapper,
  FooterLogoIconsWrapper,
  FooterContentBlockWrapper,
  FooterContentBlockIconsWrapper,
  FooterContentBlockWrapperWithBorders,
  FooterContentBlockCompanyWrapper,
  FooterIcon,
  StyledFooterLink,
  FooterTaigaProducts,
  FooterFirstContentBlockRow,
  FooterContentBlockHeader,
  FooterProductListContainer,
  StyledFooterProductsLink,
  StyledFooterPrivacyLink,
  FooterContentBlockIndentWrapper,
  FooterCompanyInfoList,
  StyledSocialIcon,
  FooterCopyrightWrapper,
  FooterCopyrightText,
  LogoWrapper, StyledFooterLinkToEmail,
} from 'pages/Landing/styles/footerstyles';

interface IFooterProps {
  isEnglish: boolean;
  privacySwitcher: MouseEventHandler<HTMLDivElement>;
  footerRef?: RefObject<HTMLDivElement>;
}

const DeckingEnglishLink = 'https://www.taigabuilding.com/products/composite-decking';
const DeckingFrenchLink = 'https://www.taigabuilding.com/fr/produits/terrasse-en-composite';

const SidingEnglishLink = 'https://www.taigabuilding.com/products/siding-trim';
const SidingFrenchLink = 'https://www.taigabuilding.com/fr/produits/parements-et-moulures';

const InsulationEnglishLink = 'https://www.taigabuilding.com/products/insulation';
const InsulationFrenchLink = 'https://www.taigabuilding.com/fr/produits/isolant';

const FlooringEnglishLink = 'https://www.taigabuilding.com/products/flooring';
const FlooringFrenchLink = 'https://www.taigabuilding.com/fr/produits/rev%C3%AAtement-de-sol';

const EngineeredEnglishLink = 'https://www.taigabuilding.com/products/engineered-wood';
const EngineeredFrenchLink =
  'https://www.taigabuilding.com/fr/products/bois-d%E2%80%99ing%C3%A9nierie';

const TreatedEnglishLink = 'https://www.taigabuilding.com/products/preserved-wood';
const TreatedFrenchLink = 'https://www.taigabuilding.com/fr/products/bois-trait%C3%A9';

const ContactUsEnglishLink = 'https://www.taigabuilding.com/contact/general-contact';
const ContactUsFrenchLink = 'https://www.taigabuilding.com/fr/contact/contact';

const AboutUsEnglishLink = 'https://www.taigabuilding.com/contact/canada-sales-distribution';
const AboutUsFrenchLink = 'https://www.taigabuilding.com/fr/contact/canada';

const FooterComponent: React.FC<IFooterProps> = ({
  isEnglish,
  privacySwitcher,
  footerRef,
}: IFooterProps) => (
  <>
    <Fade>
      <FooterWrapper ref={footerRef}>
        <Footer>
          <FooterLogoWrapper>
            <TaigaLogo />
          </FooterLogoWrapper>
          <FooterContentBlockWrapper>
            <FooterTaigaProducts>
              {isEnglish ? 'Taiga Building Products' : 'Taiga produits de bâtiment'}
            </FooterTaigaProducts>
            <FooterFirstContentBlockRow>
              <FooterIcon>
                <GeoIcon />
              </FooterIcon>
              <StyledFooterLink to="/">800–4710 Kingsway, Burnaby, BC V5H 4M2</StyledFooterLink>
            </FooterFirstContentBlockRow>
            <FooterFirstContentBlockRow>
              <FooterIcon>
                <PhoneIcon />
              </FooterIcon>
              <StyledFooterLinkToEmail href="tel:8006671470">
                <b>800.667.1470</b> (Toll Free)
              </StyledFooterLinkToEmail>
            </FooterFirstContentBlockRow>
            <FooterFirstContentBlockRow>
              <FooterIcon>
                <EmailIcon />
              </FooterIcon>
              <StyledFooterLinkToEmail href="mailto:taiganow@taigabuilding.com">
                <b>taiganow@taigabuilding.com</b>
              </StyledFooterLinkToEmail>
            </FooterFirstContentBlockRow>
          </FooterContentBlockWrapper>
          <FooterContentBlockWrapperWithBorders>
            <FooterContentBlockHeader>
              {isEnglish ? 'PRODUCTS' : 'PRODUITS'}
            </FooterContentBlockHeader>
            <FooterProductListContainer>
              <StyledFooterProductsLink href={isEnglish ? DeckingEnglishLink : DeckingFrenchLink}>
                {isEnglish ? 'Composite Decking' : 'Terrasse en composite'}
              </StyledFooterProductsLink>
              <StyledFooterProductsLink href={isEnglish ? SidingEnglishLink : SidingFrenchLink}>
                {isEnglish ? 'Siding' : 'Parements et de moulures'}
              </StyledFooterProductsLink>
              <StyledFooterProductsLink
                href={isEnglish ? InsulationEnglishLink : InsulationFrenchLink}
              >
                {isEnglish ? 'Insulation' : 'Isolant'}
              </StyledFooterProductsLink>
              <StyledFooterProductsLink href={isEnglish ? FlooringEnglishLink : FlooringFrenchLink}>
                {isEnglish ? 'Flooring' : 'Revêtements de sol'}
              </StyledFooterProductsLink>
              <StyledFooterProductsLink
                href={isEnglish ? EngineeredEnglishLink : EngineeredFrenchLink}
              >
                {isEnglish ? 'Engineered Wood' : "Bois d'ingénierie"}
              </StyledFooterProductsLink>
              <StyledFooterProductsLink href={isEnglish ? TreatedEnglishLink : TreatedFrenchLink}>
                {isEnglish ? 'Treated Wood' : 'Bois traité'}
              </StyledFooterProductsLink>
            </FooterProductListContainer>
          </FooterContentBlockWrapperWithBorders>
          <FooterContentBlockCompanyWrapper>
            <FooterContentBlockHeader>
              {isEnglish ? 'COMPANY INFORMATION' : 'INFORMATION SUR LA COMPAGNIE'}
            </FooterContentBlockHeader>
            <FooterCompanyInfoList>
              <StyledFooterProductsLink
                href={isEnglish ? ContactUsEnglishLink : ContactUsFrenchLink}
              >
                {isEnglish ? 'Contact Us' : 'Contactez-nous'}
              </StyledFooterProductsLink>
              <StyledFooterProductsLink href={isEnglish ? AboutUsEnglishLink : AboutUsFrenchLink}>
                {isEnglish ? 'About Us' : 'Â notre propos'}
              </StyledFooterProductsLink>
              <StyledFooterPrivacyLink onClick={privacySwitcher}>
                {isEnglish ? 'Privacy Policy' : 'Privacy Policy'}
              </StyledFooterPrivacyLink>
              <FooterLogoIconsWrapper>
                <a
                  aria-label="youtube"
                  href="https://www.youtube.com/channel/UCZmZ1ZvT5sjws_nzRJzoYhw"
                >
                  <StyledSocialIcon>
                    <YouTubeIcon />
                  </StyledSocialIcon>
                </a>
                <a
                  aria-label="linkedin"
                  href="https://www.linkedin.com/company/taiga-building-products/"
                >
                  <StyledSocialIcon>
                    <LinkedInIcon />
                  </StyledSocialIcon>
                </a>
              </FooterLogoIconsWrapper>
            </FooterCompanyInfoList>
          </FooterContentBlockCompanyWrapper>
          <FooterContentBlockIconsWrapper>
            <FooterContentBlockIndentWrapper>
              <LogoWrapper>{isEnglish ? <TaigaEnglish /> : <TaigaFrench />}</LogoWrapper>
            </FooterContentBlockIndentWrapper>
          </FooterContentBlockIconsWrapper>
        </Footer>
      </FooterWrapper>
      <FooterCopyrightWrapper>
        <FooterCopyrightText>
          Copyright © {new Date().getFullYear()}{' '}
          {isEnglish
            ? 'TaigaBuilding Ltd. All rights reserved.'
            : 'TOUS DROITS RÉSERVÉS. Politique de confidentialité Légal'}
        </FooterCopyrightText>
      </FooterCopyrightWrapper>
    </Fade>
  </>
);

export default FooterComponent;
