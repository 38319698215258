import styled from 'styled-components/macro';
import { Grid } from '@material-ui/core';
import React from 'react';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: ${({ theme: { colors } }) => colors.white};
`;

const Content = styled(({ children, ...rest }) => <Grid {...rest}>{children}</Grid>)`
  flex: 1;
  align-self: center;
  background-color: ${({ theme: { colors } }) => colors.white};
  margin-bottom: 54px;
  width: 100%;
  @media (min-width: 1201px) {
    width: 1200px;
    padding: 0;
  }
  @media (max-width: 1023px) {
    margin-bottom: 20px;
  }
  padding: 0 10px;
`;

export { Root, Content };
