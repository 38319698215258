import ReactGA from 'react-ga';

import { ProductVariantClass } from 'models/classes/productVariantClass';
import { AssociationVariantClass } from 'models/classes/associationClass';

export const addProductGAEvent = (
  productVariant: ProductVariantClass | AssociationVariantClass,
  address: string,
  branch: string,
  qty: number,
  listName: string,
  categoryName?: string
) => {
  ReactGA.plugin.execute('ec', 'addProduct', {
    id: productVariant.code,
    name: productVariant.name,
    category: categoryName,
    quantity: qty,
  });

  ReactGA.plugin.execute('ec', 'setAction', 'add', {
    list: listName,
    affiliation: branch,
  });

  ReactGA.event({
    category: 'Product Variant',
    action: 'Add to cart',
    label: `Add product to cart (Address: ${address})`,
  });
};

export const chooseProductVariantGAEvent = (
  productVariant: ProductVariantClass | AssociationVariantClass,
  categoryName: string,
  branch: string
) => {
  ReactGA.plugin.execute('ec', 'addProduct', {
    id: productVariant.code,
    name: productVariant.name,
    list: 'Product Details',
    category: categoryName,
  });

  ReactGA.plugin.execute('ec', 'setAction', 'detail', {
    affiliation: branch,
  });

  ReactGA.event({
    category: 'Product Variant',
    action: 'Open variant',
    label: 'Select variant on Product page',
  });
};

export const requestPricingClickGAEvent = (name: string) => {
  ReactGA.event({
    category: 'Product Variant',
    action: 'Request for pricing (click)',
    label: `Request pricing button was clicked for ${name}`,
    metric2: 1,
  });
};

export const requestPricingEmailSentGAEvent = (name: string) => {
  ReactGA.event({
    category: 'Product Variant',
    action: 'Request for pricing (email)',
    label: `Request pricing email was sent for ${name}`,
    metric3: 1,
  });
};

export const clickInStockGAEvent = (inStockApplied: boolean, orderSubmitted: boolean) => {
  if (inStockApplied) {
    if (orderSubmitted) {
      ReactGA.event({
        category: 'Product Variant',
        action: 'In stock applied (with submitted order)',
        label: 'In stock applied (with submitted order)',
        metric5: 1,
      });
    } else {
      ReactGA.event({
        category: 'Product Variant',
        action: 'In stock applied',
        label: 'In stock applied',
        metric4: 1,
      });
    }
  }
};

export const pricingTypeChangedGAEvent = (pricingTypeChanged: boolean, orderSubmitted: boolean) => {
  if (pricingTypeChanged) {
    if (orderSubmitted) {
      ReactGA.event({
        category: 'Application',
        action: 'Pricing type was changed (with submitted order)',
        label: 'Pricing type was changed (with submitted order)',
        metric7: 1,
      });
    } else {
      ReactGA.event({
        category: 'Application',
        action: 'Pricing type was changed',
        label: 'Pricing type was changed',
        metric6: 1,
      });
    }
  }
};

export const visitOrdersOrQuotesGAEvent = (
  ordersOrQuotesVisited: boolean,
  orderSubmitted: boolean
) => {
  if (ordersOrQuotesVisited) {
    if (orderSubmitted) {
      ReactGA.event({
        category: 'Application',
        action: 'Orders or quotes was visited (with submitted order)',
        label: 'Orders or quotes was visited (with submitted order)',
        metric9: 1,
      });
    } else {
      ReactGA.event({
        category: 'Application',
        action: 'Orders or quotes was visited',
        label: 'Orders or quotes was visited',
        metric8: 1,
      });
    }
  }
};
