import React from 'react';

export const Arrow = () => {
  return (
    <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.29395 1L8.29395 7.58824L15.2939 1"
        stroke="#939599"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
