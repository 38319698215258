import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockH, Line } from 'pages/Checkout/styles';
import {useSelector} from 'react-redux';
import {selectChangeCartDeliveryStatus} from 'ducks/order/selectors';
import { TransactionType } from './TransactionType';
import { ShippingMethod } from './ShippingMethod';
import { ValidatedOptions } from './ValidatedOptions';
import FormContext from '../FormContext';

const CheckoutOptions: React.FC = () => {
  const { t } = useTranslation();
  const changeCartDeliveryStatus = useSelector(selectChangeCartDeliveryStatus);
  const {transactionTypeRef} = useContext(FormContext);

  return (
    <>
      <Line />
      <BlockH ref={transactionTypeRef}>{t('checkout.transaction_type').toString()}</BlockH>
      <TransactionType />
      <Line />
      <BlockH>{t('checkout.shipping_method').toString()}</BlockH>
      {
        changeCartDeliveryStatus === 'error' && <p style={{color: 'red'}}>{t('checkout.change_delivery_type_error').toString()}</p>
      }
      <ShippingMethod />
      <ValidatedOptions />
    </>
  );
};

export default CheckoutOptions;
