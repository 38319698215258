import React from 'react';
import styled from 'styled-components/macro';

import { MaintenanceIcon } from 'assets/svg/MaintenanceIcon';

import { useTranslation } from 'react-i18next';

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 87px 10px 96px 10px;

  @media (max-width: 320px) {
    padding-top: 40px;
    padding-bottom: 56px;
  }
`;

const StyledIconDiv = styled.div`
  svg {
    height: auto;
    width: 100%;
  }
`;

const StyledTitleDiv = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  padding-top: 45px;

  @media (max-width: 320px) {
    font-size: 18px;
    line-height: 25px;
    color: #333333;
    padding-top: 32px;
  }
`;

const StyledDescriptionDiv = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  padding-top: 24px;

  @media (max-width: 320px) {
    font-size: 14px;
    line-height: 150%;
    padding-top: 16px;
    max-width: 280px;
  }
`;

const Maintenance: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledDiv>
      <StyledIconDiv>
        <MaintenanceIcon />
      </StyledIconDiv>
      <StyledTitleDiv>{t('errors.maintenance_title')}</StyledTitleDiv>
      <StyledDescriptionDiv>{t('errors.maintenance_subtitle')}</StyledDescriptionDiv>
    </StyledDiv>
  );
};

export default Maintenance;
