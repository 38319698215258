import { createMuiTheme } from '@material-ui/core';

export interface ITheme {
  [key: string]: string | Function;
}

export const colors: ITheme = {
  black: '#000',
  white: '#FFF',
  lightGrey: '#F7F7F7',
  grey: '#939599',
  greyLink: '#444',
  borderGrey: '#DADEE4',
  btnDisabled: '#DADCE0',
  mainGreen: '#00853e',
  mainGreenHover: '#37875F',
  mainGreefPassed: '#327A56',
  letterGrey: '#757575',
  orange: '#FF3B30',
  secondaryGreen: '#4A9463',
  copyrightGreen: '#97BAA8',
  borderGreen: '#1B5633',
  textColor: '#333333',
  productBorderGrey: '#EAEAEA',
  filterBorderGrey: '#E0E0E0',
  buttonGrey: '#D9D9DE',
  red: '#F5001D',
  redOpacity: opacity => `rgba(245,0,29, ${opacity})`,
  yellow: '#a18739',
  yellowOpacity: opacity => `rgba(161,135,57, ${opacity})`,
  yellowBackground: 'rgba(218, 169, 21, 0.1)',
  circleGreen: '#008748',
  shadow: '#E6E6E6',
  backgroundPage: '#FAFAFA',
  borderSliderGreen: '#046635',
  nameBlack: '#444444',
  popupBackground: '#B3B3B3',
  searchDropdownText: '#373737',
  lightGreySearch: '#939599',
  lightGreyHoverSearchResult: '#EBEBEB',
  lightGreyBoxShadowSearchResult: '#CCC',
  whiteSmoke: '#F4F5F7',
  invisible: 'rgba(255, 255, 255, 0)',
  pressGrey: '#F2F3F5',
  blackOpacity: opacity => `rgba(0, 0, 0, ${opacity})`,
  greenOpacity: opacity => `rgba(51, 125, 88, ${opacity})`,
  greenLandingOpacity: opacity => `rgba(0, 133, 62, ${opacity})`,
  closeProductGrey: '#D8E0F7',
  disclaimerGrey: '#666766',
  pantone: '#F9F6F0',
  menuBackgroundGray: 'rgba(218, 222, 228, 0.3)',
  greenPeaRequestPricing: '#2A6648',
  waitingOnStockBlue: '#007AFF',
  popperColor: '#FFFBEE',
  darkGrey: '#BFBFBF',
  greengreyOpacity: opacity => `rgba(66, 160, 113, ${opacity})`,
  greenBorder: '#007738',
  darkGreen: '#1B5633',
  whiteOpacity: opacity => `rgba(255, 255, 255, ${opacity})`,
  switchCheckedGreen: '#337D58',
};

export const fontSizes: ITheme = {
  xxSmall: '8px',
  xSmall: '12px',
  small: '14px',
  medium: '16px',
  large: '18px',
  xLarge: '20px',
  xxLarge: '34px',
  xxxLarge: '48px',
};

export const themeOptions: { [key: string]: ITheme } = {
  colors,
  fontSizes,
  palette: {
    type: 'light',
  },
};

const theme = createMuiTheme(themeOptions);

export default theme;
