import get from 'lodash/get';

export const clearGtmEcommerce = () => {
  if (get(window, 'dataLayer', undefined)) {
    // @ts-ignore
    window.dataLayer.push(function() {
      // @ts-ignore
      // eslint-disable-next-line react/no-this-in-sfc
      this.reset();
    });
  }
};
