import { withStyles } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';

const AppCssBaseline = withStyles({
  '@global': {
    '.MuiInputLabel-root, .MuiFormLabel-root, .MuiTypography-root, .MuiInputBase-root, .MuiMenuItem-root': {
      fontFamily: 'Open Sans',
    },
    'div, span, ul, li, h1, h2, h3, h4, h5, h6, p': {
      fontFamily: 'Open Sans',
    },
  },
})(CssBaseline);

export default AppCssBaseline;
