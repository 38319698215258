import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ProductVariantClass } from 'models/classes/productVariantClass';
import bigDefaultAvatar from 'assets/images/big-default-product.png';
import defaultAvatar from 'assets/images/default-product.png';
import { numberFormat } from 'utils/products/product';
import {
  priceTypeSelector,
  selectAddressId,
  selectErpCustomerAddressCode,
  selectIsHidePrice,
  selectLanguage,
} from 'ducks/application/selectors';
import { useRequestPricing } from 'hooks/useRequestPricing';
import TagManager from 'react-gtm-module';
import {
  AddToTruckWrapper,
  AvatarWrapper,
  DefaultAvatarWrapper,
  Each,
  Price,
  PriceItem,
  PriceWrapper,
  ProductCard,
  ProductInfoWrapper,
  ProductName,
  RequestPricing,
  RequestPricingButton,
  RequestPricingWrapper,
  StyledAddToTruck,
  StyledIconButton,
  StyledLink,
  StyledPricesContainer,
  StyledSecondPriceText,
  InquireNowButton,
  InquireNowButtonTitle,
} from 'components/common/ProductCarousel/styles';
import findIndex from 'lodash/findIndex';
import {
  StyledAvailableAndLeadTime,
  StyledCheckNearByBranch,
} from 'pages/Product/ProductTableView/ProductTableCard/styles';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import {
  setClickedProductRequestNearbyHubsAndBranch,
  setNearbyBranchData,
  setOpenPopupInquireNow,
  setOpenPopupNearbyBranch,
} from 'ducks/product/actions';
import { requestProductNearbyBranch } from 'api/Product';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { getDefaultCategoryName } from 'utils/categories/categories';
import { selectCategories } from 'ducks/category/selectors';
import { getDefaultProductName } from 'utils/products/productUtilsHelpers';
import { NearbyBranchEmailType } from 'ducks/productVariant/types';
import { NearbyBranchContext } from 'context/NearbyBranchContext';
import { createUserActionLog } from 'api/UserActionLog';
import { usePermission } from 'hooks/usePermission';

interface ICarouselProductCard {
  productVar: ProductVariantClass;
  setHoveredTableView: Dispatch<SetStateAction<number>>;
  isMobile: boolean;
  categoryName: string;
  categoryCode: string;
  renderProductStock: CallableFunction;
  productType: 'featured' | 'related' | 'alternative';
  products: Array<any>;
  handleClickProductStock: CallableFunction;
}

const CarouselProductCard: React.FC<ICarouselProductCard> = ({
  productVar,
  setHoveredTableView,
  isMobile,
  categoryName,
  categoryCode,
  renderProductStock,
  productType = 'featured',
  products,
  handleClickProductStock,
}: ICarouselProductCard) => {
  const dispatch = useDispatch();
  const isHidePrice = useSelector(selectIsHidePrice);
  const priceType = useSelector(priceTypeSelector);
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const addressId = useSelector(selectAddressId);
  const categories = useSelector(selectCategories);
  const selectedLanguage = useSelector(selectLanguage);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const { setEmailType, setFromBranchId } = React.useContext(NearbyBranchContext);
  const { canSeePrice, canPlaceQuote, canPlaceOrder } = usePermission();

  const { handleRequestPricingClick } = useRequestPricing(productVar);
  const { t, i18n } = useTranslation();

  const handleTableProductViewChange = productId => {
    setHoveredTableView(productId);
  };

  const renderPriceForEach = () => {
    const tempPrice = productVar.prices.length && [...productVar.prices][0];

    return tempPrice && numberFormat(tempPrice[priceType] ?? 0);
  };

  const onClickRequestPricing = async () => {
    let entryPoint = 'Featured Products Request Pricing';
    if (productType === 'related') {
      entryPoint = 'Related Products Request Pricing';
    }
    if (productType === 'alternative') {
      entryPoint = 'Alternative Products Request Pricing';
    }
    await createUserActionLog(entryPoint, 'Request Pricing');
    handleRequestPricingClick();
  };

  const onClickProductStock = async () => {
    let entryPoint = 'Featured Products Inquire Now';
    if (productType === 'related') {
      entryPoint = 'Related Products Inquire Now';
    }
    if (productType === 'alternative') {
      entryPoint = 'Alternative Products Inquire Now';
    }
    await createUserActionLog(entryPoint, 'Inventory Request');
    handleClickProductStock(productVar);
  };

  const renderAvatar = () => {
    if (isMobile) {
      return (
        <AvatarWrapper>
          <img
            src={productVar?.images.length ? productVar?.images[0].url : bigDefaultAvatar}
            alt="Product avatar or default avatar"
          />
        </AvatarWrapper>
      );
    }

    return productVar?.images.length ? (
      <AvatarWrapper>
        <img src={productVar?.images[0].url} alt="Product avatar" />
      </AvatarWrapper>
    ) : (
      <DefaultAvatarWrapper>
        <img src={defaultAvatar} alt="Default avatar" />
      </DefaultAvatarWrapper>
    );
  };

  const renderPriceForSecondUOM = () => {
    const secondUOM = productVar.units.uom2Name === 'ft2' ? 'Ft²' : productVar.units.uom2Name;
    const currentPrice = productVar.prices.length && productVar.prices[0];
    if (productVar.units.uom2Conversion && productVar.units.uom2Name) {
      return `$${numberFormat(
        currentPrice[priceType] * productVar.units.uom2Conversion
      )}/${secondUOM}`;
    }
    return null;
  };

  const handleGtm = () => {
    clearGtmEcommerce();
    let itemListId;
    let itemListName;
    if (productType === 'featured') {
      itemListId = 'featured_products';
      itemListName = 'Featured Products';
    }
    if (productType === 'related') {
      itemListId = 'related_products';
      itemListName = 'Related Products';
    }
    if (productType === 'alternative') {
      itemListId = 'alternative_products';
      itemListName = 'Alternative Products';
    }
    const { itemCategoryName, itemCategory2Name } = getDefaultCategoryName(
      productVar.category.id,
      categories
    );
    TagManager.dataLayer({
      dataLayer: {
        event: 'select_item',
        ecommerce: {
          item_list_id: itemListId,
          item_list_name: itemListName,
          items: [
            {
              item_id: productVar.erpSku,
              item_name: getDefaultProductName(productVar),
              price: productVar.prices.length ? productVar.prices[0][priceType] : 0,
              item_category: itemCategoryName,
              item_category2: itemCategory2Name,
              item_list_id: itemListId,
              item_list_name: itemListName,
              index: findIndex(products, product => product.id === productVar.id) + 1,
              quantity: 1,
            },
          ],
        },
      },
    });
  };

  const handleRequestNearbyHubsAndBranches = async () => {
    let entryPoint = 'Featured Products Nearby Branch';
    if (productType === 'related') {
      entryPoint = 'Related Products Nearby Branch';
    }
    if (productType === 'alternative') {
      entryPoint = 'Alternative Products Nearby Branch';
    }
    await createUserActionLog(entryPoint, 'Multi-branch Inquiry');
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_view_other_branch_inventory_category',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: addressId,
      },
    });
    dispatch(setClickedProductRequestNearbyHubsAndBranch(productVar));
    requestProductNearbyBranch({
      productId: productVar.id,
      addressId,
      language: selectedLanguage,
      categoryCode: productVar.category.code,
    }).then(res => {
      if (res.data.data.nearbyHubs.length === 0 && res.data.data.nearbyBranches.onHand === 0) {
        dispatch(setOpenPopupInquireNow(true));
        return;
      }
      dispatch(setNearbyBranchData(res.data.data));
      setTimeout(() => {
        dispatch(setOpenPopupNearbyBranch(true));
      }, 100);
    });
  };

  const handleClickProductStockLeadTime = useCallback(async () => {
    let entryPoint = 'Featured Products Inventory Status';
    if (productType === 'related') {
      entryPoint = 'Related Products Inventory Status';
    }
    if (productType === 'alternative') {
      entryPoint = 'Alternative Products Inventory Status';
    }
    await createUserActionLog(entryPoint, 'Inventory Request');
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_inventory_request',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: addressId,
      },
    });
    setEmailType(NearbyBranchEmailType.HUB_BRANCH_WITH_INVENTORY_AVAILABLE);
    setFromBranchId(productVar.newInventory.branchId);
    dispatch(setClickedProductRequestNearbyHubsAndBranch(productVar));
    dispatch(setOpenPopupInquireNow(true));
  }, [
    addressId,
    branch,
    dispatch,
    erpCustomerAddressCode,
    productVar,
    setEmailType,
    setFromBranchId,
    user,
  ]);

  return (
    <ProductCard key={productVar.name} className="product-card-3 on-carousel">
      <ProductInfoWrapper className="product-info-wrapper">
        <StyledLink
          to={{
            pathname: `/product/${productVar.code}`,
            state: {
              mainCategoryName: categoryName,
              mainCategoryCode: categoryCode,
              variantCode: productVar.code,
              variantId: productVar.id,
            },
          }}
          onClick={handleGtm}
        >
          {renderAvatar()}
          <ProductName>{productVar.name}</ProductName>
        </StyledLink>
        {!productVar.inventory.isStocked && productVar.newInventory?.onHand > 0 ? (
          <StyledAvailableAndLeadTime
            className={`${i18n?.language}`}
            onClick={handleClickProductStockLeadTime}
          >
            {t('products_and_search.available_and_lead_time', {
              onHand: productVar.newInventory.onHand,
              leadTime: productVar.newInventory.leadTime,
            }).toString()}
          </StyledAvailableAndLeadTime>
        ) : (
          renderProductStock(productVar)
        )}
        {productVar?.nearbyHubs?.length > 0 && (
          <StyledCheckNearByBranch onClick={handleRequestNearbyHubsAndBranches}>
            <span>{t('products_carousel.check_nearby_branch').toString()}</span>
          </StyledCheckNearByBranch>
        )}
        <PriceWrapper className="price-wrapper">
          {productVar.prices?.length &&
          canSeePrice &&
          (productVar.prices[0][priceType] || !isHidePrice) ? (
              <>
                {!isHidePrice ? (
                  <StyledPricesContainer>
                    <PriceItem>
                      <Price>${renderPriceForEach()}</Price>
                      <Each>/{productVar.units.perName}</Each>
                    </PriceItem>
                    <StyledSecondPriceText>{renderPriceForSecondUOM()}</StyledSecondPriceText>
                  </StyledPricesContainer>
                ) : null}
                {(canPlaceOrder || canPlaceQuote) &&
                  <AddToTruckWrapper>
                    <StyledIconButton
                      aria-label="add to track"
                      onMouseEnter={
                        isMobile
                          ? () => {
                            return false;
                          }
                          : () => {
                            handleTableProductViewChange(productVar.id);
                          }
                      }
                      onClick={
                        isMobile
                          ? () => {
                            handleTableProductViewChange(productVar.id);
                          }
                          : () => {
                            return false;
                          }
                      }
                    >
                      <StyledAddToTruck />
                    </StyledIconButton>
                  </AddToTruckWrapper>
                }
              </>
            ) : (
              <RequestPricingWrapper className="request-pricing-wrapper">
                <RequestPricingButton onClick={onClickRequestPricing}>
                  <RequestPricing>{t('products_carousel.request_pricing').toString()}</RequestPricing>
                </RequestPricingButton>
                <InquireNowButton className={`${i18n?.language}`} onClick={onClickProductStock}>
                  <InquireNowButtonTitle>
                    {t('product_detail.inquire_now').toString()}
                  </InquireNowButtonTitle>
                </InquireNowButton>
              </RequestPricingWrapper>
            )}
        </PriceWrapper>
      </ProductInfoWrapper>
    </ProductCard>
  );
};

export default CarouselProductCard;
