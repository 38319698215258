import React from 'react';
import { LoadingWrapperList, LoadingIndicator } from '../styles';

const ProductListLoading = () => {
  return (
    <LoadingWrapperList className="loading-wrapper-list">
      <LoadingIndicator />
    </LoadingWrapperList>
  );
};

export default ProductListLoading;
