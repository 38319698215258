import Login from 'pages/Auth/Login';
import ResetPassword from 'pages/Auth/ResetPassword';
import SignIn from 'pages/Auth/SignIn/SignIn';
import Maintenance from 'pages/Technical/Maintenance';
import CategoryList from 'pages/Category/CategoryList';
import SubcategoryList from 'pages/Subcategory/SubcategoryList';
import ProductList from 'pages/Product/ProductList';
import Error500 from 'pages/Technical/Error500';
import ContactUs from 'pages/General/ContactUs/ContactUs';
import FAQ from 'pages/General/FAQ';
import NotFound from 'pages/Technical/NotFound';
import ProductDetail from 'pages/Product/ProductDetail/ProductDetail';
import Search from 'pages/Search/Search';
import LandingPage from 'pages/Landing/LandingPage';
import ColourDisclaimer from 'pages/Technical/ColourDisclaimer';
import Checkout from 'pages/Checkout/Checkout';
import Profile from 'pages/General/Profile/Profile';

export {
  Login,
  ResetPassword,
  SignIn,
  Maintenance,
  CategoryList,
  NotFound,
  SubcategoryList,
  ProductList,
  Error500,
  ContactUs,
  FAQ,
  ProductDetail,
  Search,
  LandingPage,
  ColourDisclaimer,
  Checkout,
  Profile,
};
