import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import defaultAvatar from 'assets/images/default-product.png';
import { WhiteTruck } from 'assets/svg/Product/Products';
import { useDispatch, useSelector } from 'react-redux';

import { ProductMultiInput } from 'components/Product/Inputs/ProductMultiInput';
import { ProductVariantClass } from 'models/classes/productVariantClass';
import { IPrice } from 'models/interfaces/productVariant';
import {
  priceTypeSelector,
  selectAddressId,
  selectErpCustomerAddressCode, selectIsHidePrice,
  selectLanguage,
} from 'ducks/application/selectors';
import TagManager from 'react-gtm-module';

import { useTranslation } from 'react-i18next';
import { CustomizedSnackbar } from 'components/common';
import { usePurchaseClickHandler } from 'pages/Product/ProductTableView/hooks';
import { numberFormat } from 'utils/products/product';

import { useRequestPricing } from 'hooks/useRequestPricing';
import { filterPrices } from 'utils/products/productVariant';

import { selectAddToCartError } from 'ducks/cart/selectors';
import findIndex from 'lodash/findIndex';
import { useHistory, useLocation } from 'react-router-dom';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { getDefaultProductName } from 'utils/products/productUtilsHelpers';
import { getDefaultCategoryName } from 'utils/categories/categories';
import { selectCategories } from 'ducks/category/selectors';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import {
  setClickedProductRequestNearbyHubsAndBranch,
  setNearbyBranchData,
  setOpenPopupInquireNow, setOpenPopupNearbyBranch,
} from 'ducks/product/actions';
import { requestProductNearbyBranch } from 'api/Product';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { setAddToTruckItem } from 'ducks/cart/actions';
import { NearbyBranchEmailType } from 'ducks/productVariant/types';
import { NearbyBranchContext } from 'context/NearbyBranchContext';
import { createUserActionLog } from 'api/UserActionLog';
import { usePermission } from 'hooks/usePermission';
import {
  ProductCardGrid,
  InputButtonsWrapper,
  ButtonsWrapper,
  ProductNumber,
  ProductInfo,
  Price,
  ProductAvatar,
  ProductAvatarWrapper,
  ProductName,
  RequestPricing,
  RequestPricingButton,
  RequestPricingWrapper,
  AddButtonContentWrapper,
  AddButtonWrapper,
  StyledLink,
  SingleCardWrapper,
  Each,
  AddButton,
  AddButtonTitle,
  Total,
  StyledPricesContainer,
  StyledSecondPriceText,
  StyledFirstPriceWrapper, InquireNowButton, InquireNowButtonTitle,
} from './styles';
import { StyledAvailableAndLeadTime, StyledCheckNearByBranch } from '../ProductTableView/ProductTableCard/styles';

interface IProductCard {
  productVar: ProductVariantClass;
  categoryName?: string;
  code?: string;
  mainCategoryName?: string;
  id?: string;
  renderProductStock: CallableFunction;
  handleClickProductStock: CallableFunction;
  products: ProductVariantClass[];
}

const ProductRowCard: React.FC<IProductCard> = ({
  productVar,
  categoryName,
  code,
  mainCategoryName,
  id,
  renderProductStock,
  handleClickProductStock,
  products,
}: IProductCard) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isSearchPage = location.pathname.includes('/search');
  const isHidePrice = useSelector(selectIsHidePrice);
  const priceType = useSelector(priceTypeSelector);
  const addToCartError = useSelector(selectAddToCartError);
  const categories = useSelector(selectCategories);
  const addressId = useSelector(selectAddressId);
  const selectedLanguage = useSelector(selectLanguage);
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const productPrices = React.useMemo(() => filterPrices(productVar.prices), [productVar.prices]);
  const [currentPrice, setCurrentPrice] = useState<IPrice[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [topInputValue, setTopInputValue] = useState(0);
  const [bottomInputValue, setBottomInputValue] = useState<number | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { messageTop, messageBottom, handlePurchaseClick } = usePurchaseClickHandler(
    productVar,
    topInputValue,
    bottomInputValue,
    mainCategoryName,
    categoryName,
  );
  const { handleRequestPricingClick } = useRequestPricing(
    productVar,
  );
  const { setEmailType, setFromBranchId } = React.useContext(NearbyBranchContext);

  const { t } = useTranslation();

  const { canPlaceQuote, canPlaceOrder, canSeePrice } = usePermission();

  const handleCloseSnackBar = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (productPrices.length) {
      setCurrentPrice([productPrices[0]]);
      setTopInputValue(productPrices[0].qty);
      if (productVar.units.uom2Conversion) {
        setBottomInputValue(productPrices[0].qty / productVar.units.uom2Conversion);
      }
    }
  }, [productPrices, productVar]);

  useEffect(() => {
    if (productVar) {
      const priceToSet =
        currentPrice.length > 0 ? currentPrice[currentPrice.length - 1][priceType] : null;

      setTotalPrice(parseFloat(numberFormat(priceToSet * topInputValue)) || 0);
    }
  }, [productVar, priceType, currentPrice, topInputValue]);

  const renderPriceForEach = () =>
    currentPrice.length > 0 ? numberFormat(currentPrice[currentPrice.length - 1][priceType]) : null;

  const renderPriceForSecondUOM = () => {
    const secondUOM = productVar.units.uom2Name === 'ft2' ? 'Ft²' : productVar.units.uom2Name;
    if (currentPrice.length > 0 && productVar.units.uom2Conversion && productVar.units.uom2Name) {
      return `$${numberFormat(
        currentPrice[currentPrice.length - 1][priceType] * productVar.units.uom2Conversion,
      )}/${secondUOM}`;
    }
    return null;
  };

  const handleFireGtm = () => {
    clearGtmEcommerce();
    let itemListId;
    let itemListName;
    const { itemCategoryName, itemCategory2Name } = getDefaultCategoryName(productVar.category.id, categories);
    if (history.location.pathname.includes('/search')) {
      itemListId = 'search_results_full';
      itemListName = 'Search Results Full';
    } else {
      itemListId = itemCategory2Name.toLowerCase().split(' ').join('_');
      itemListName = itemCategory2Name;
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'select_item',
        ecommerce: {
          item_list_id: itemListId,
          item_list_name: itemListName,
          items: [
            {
              item_id: productVar.erpSku,
              item_name: getDefaultProductName(productVar),
              price: productVar.prices.length ? productVar.prices[0][priceType] : 0,
              item_category: itemCategoryName,
              item_category2: itemCategory2Name,
              item_list_id: itemListId,
              item_list_name: itemListName,
              index: findIndex(products, product => product.id === productVar.id) + 1,
              quantity: 1,
            },
          ],
        },
      },
    });
  };

  const handleRequestNearbyHubsAndBranches = () => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_view_other_branch_inventory_category',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: addressId,
      },
    });
    dispatch(setClickedProductRequestNearbyHubsAndBranch(productVar));
    requestProductNearbyBranch({
      productId: productVar.id,
      addressId,
      language: selectedLanguage,
      categoryCode: productVar.category.code,
    }).then(res => {
      if (res.data.data.nearbyHubs.length === 0 && res.data.data.nearbyBranches.onHand === 0) {
        dispatch(setOpenPopupInquireNow(true));
        return;
      }
      dispatch(setNearbyBranchData({
        nearbyHubs: res.data.data.nearbyHubs,
        nearbyBranches: res.data.data.nearbyBranches,
      }));
      setTimeout(() => {
        dispatch(setOpenPopupNearbyBranch(true));
      }, 100);
    });
  };

  const onClickRequestPricing = async () => {
    await createUserActionLog(isSearchPage ? 'Search Page Request Pricing' : 'Catalog Page Request Pricing', 'Pricing Inquiry');
    handleRequestPricingClick();
  };

  const addToTruck = () => {
    if (handlePurchaseClick()) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'add_to_cart_catalog',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: addressId,
        },
      });
      let price;
      if (productPrices.length > 1) {
        if (topInputValue >= productPrices[1].qty) {
          price = productPrices[1][priceType];
        } else {
          price = productPrices[0][priceType];
        }
      } else {
        price = productPrices[0][priceType];
      }
      clearGtmEcommerce();
      const { itemCategoryName, itemCategory2Name } = getDefaultCategoryName(productVar.category.id, categories);
      TagManager.dataLayer({
        dataLayer: {
          event: 'add_to_cart',
          ecommerce: {
            items: [
              {
                item_id: productVar.erpSku,
                item_name: getDefaultProductName(productVar),
                item_category2: itemCategory2Name,
                item_category: itemCategoryName,
                quantity: topInputValue,
                price,
              },
            ],
          },
        },
      });
      dispatch(setAddToTruckItem(productVar, topInputValue));
    }
  };

  const handleClickProductStockLeadTime = useCallback(() => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_inventory_request',
        user_type: getUserType(user.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: addressId,
      },
    });
    setEmailType(NearbyBranchEmailType.HUB_BRANCH_WITH_INVENTORY_AVAILABLE);
    setFromBranchId(productVar.newInventory.branchId);
    dispatch(setClickedProductRequestNearbyHubsAndBranch(productVar));
    dispatch(setOpenPopupInquireNow(true));
  }, [dispatch, productVar, setEmailType, setFromBranchId]);

  const handleInquireNow = useCallback(() => {
    if (productVar.newInventory?.onHand > 0 && !productVar.inventory.isStocked) {
      handleClickProductStockLeadTime();
    } else {
      handleClickProductStock(productVar);
    }
  }, [addressId, branch, erpCustomerAddressCode, handleClickProductStock, handleClickProductStockLeadTime, productVar, user]);

  const onClickInquireNow = async () => {
    await createUserActionLog(isSearchPage ? 'Search Page Inquire Now' : 'Catalog Page Inquire Now', 'Inventory Request');
    handleInquireNow();
  };

  return (
    <SingleCardWrapper key={`${productVar.name}-list-card`}>
      <ProductCardGrid container item direction="row" md={12} xs={12} className="product-card-grid-1">
        <Grid item md={7} className="col-product-on-list-view">
          <CustomizedSnackbar
            open={openSnackbar}
            status={addToCartError ? 'Error' : 'Success'}
            handleClose={handleCloseSnackBar}
            successMessage={t('products_carousel.added_to_truck')}
            errorMessage={t('products_carousel.product_wasnt_added')}
          />
          <ProductAvatarWrapper>
            <StyledLink
              onClick={handleFireGtm}
              to={{
                pathname: `/product/${productVar.code}`,
                state: {
                  subcategoryName: categoryName,
                  subcategoryCode: code,
                  mainCategoryName,
                  mainCategoryCode: id,
                },
              }}
            >
              <ProductAvatar>
                <img src={productVar.image || defaultAvatar} alt="product avatar" />
              </ProductAvatar>
            </StyledLink>
            <ProductInfo>
              <StyledLink
                onClick={handleFireGtm}
                to={{
                  pathname: `/product/${productVar.code}`,
                  state: {
                    subcategoryName: categoryName,
                    subcategoryCode: code,
                    mainCategoryName,
                    mainCategoryCode: id,
                    variantCode: productVar.code,
                    variantId: productVar.id,
                  },
                }}
              >
                <ProductName>{productVar.name}</ProductName>
              </StyledLink>
              {productVar.newInventory?.onHand > 0 && !productVar.inventory.isStocked ? (
                <StyledAvailableAndLeadTime onClick={async () => {
                  await createUserActionLog(isSearchPage ? 'Search Page Inventory Status' : 'Catalog Page Inventory Status', 'Inventory Request');
                  handleClickProductStockLeadTime();
                }}>
                  {t('products_and_search.available_and_lead_time', {
                    onHand: productVar.newInventory.onHand,
                    leadTime: productVar.newInventory.leadTime,
                  }).toString()}
                </StyledAvailableAndLeadTime>
              ) : <ProductNumber>{renderProductStock(productVar)}</ProductNumber>}
              {
                productVar?.nearbyHubs?.length > 0 && (
                  <StyledCheckNearByBranch onClick={async () => {
                    await createUserActionLog(isSearchPage ? 'Search Page Nearby Branch' : 'Catalog Page Nearby Branch', 'Multi-branch Inquiry');
                    handleRequestNearbyHubsAndBranches();
                  }}>
                    <span>{t('products_carousel.check_nearby_branch').toString()}</span>
                  </StyledCheckNearByBranch>
                )
              }

              {productPrices.length > 0 &&
              currentPrice.length > 0 &&
              currentPrice[currentPrice.length - 1][priceType] &&
              !isHidePrice ? (
                  <StyledPricesContainer>
                    <StyledFirstPriceWrapper>
                      <Price>${renderPriceForEach()}</Price>
                      <Each>/{productVar.units.perName}</Each>
                    </StyledFirstPriceWrapper>
                    <StyledSecondPriceText>{renderPriceForSecondUOM()}</StyledSecondPriceText>
                  </StyledPricesContainer>
                ) : null}
            </ProductInfo>
          </ProductAvatarWrapper>
        </Grid>

        <InputButtonsWrapper item md={3} className="col-input-buttons-wrapper-on-list-view">
          {currentPrice.length &&
          (currentPrice[currentPrice.length - 1][priceType] || !isHidePrice) ? (
              <ProductMultiInput
                product={productVar}
                productPrices={productPrices}
                topInputValue={topInputValue}
                bottomInputValue={bottomInputValue}
                setTopInputValue={setTopInputValue}
                setBottomInputValue={setBottomInputValue}
                setCurrentPrice={setCurrentPrice}
                messageTop={messageTop ?? null}
                messageBottom={messageBottom ?? null}
                popperPlacement="left"
              />
            ) : null}
        </InputButtonsWrapper>

        <ButtonsWrapper item md={2} className="col-action">
          {productPrices.length > 0 &&
          currentPrice.length > 0 &&
          (currentPrice[currentPrice.length - 1][priceType] || !isHidePrice) ? (
              <AddButtonContentWrapper>
                <AddButtonWrapper>
                  {!isHidePrice && canSeePrice ? (
                    <Total>
                      {t('products_and_search.total_s').toString()}
                      {totalPrice ? numberFormat(totalPrice) : null}
                    </Total>
                  ) : null}
                  {(canPlaceOrder || canPlaceQuote) &&
                    <AddButton onClick={addToTruck}>
                      <WhiteTruck />
                      <AddButtonTitle>{t('products_and_search.add_to_truck').toString()}</AddButtonTitle>
                    </AddButton>
                  }
                  <InquireNowButton onClick={onClickInquireNow}>
                    <InquireNowButtonTitle>{t('product_detail.inquire_now').toString()}</InquireNowButtonTitle>
                  </InquireNowButton>
                </AddButtonWrapper>
              </AddButtonContentWrapper>
            ) : (
              <RequestPricingWrapper>
                <RequestPricingButton onClick={onClickRequestPricing}>
                  <RequestPricing>{t('products_and_search.request_pricing').toString()}</RequestPricing>
                </RequestPricingButton>

                <InquireNowButton onClick={onClickInquireNow}>
                  <InquireNowButtonTitle>{t('product_detail.inquire_now').toString()}</InquireNowButtonTitle>
                </InquireNowButton>
              </RequestPricingWrapper>
            )}
        </ButtonsWrapper>
      </ProductCardGrid>
    </SingleCardWrapper>
  );
};

export default ProductRowCard;
