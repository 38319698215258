import React from 'react';
import styled from 'styled-components';
import ModalComponent from '@material-ui/core/Modal';
import { Card, CardContent, CardHeader } from '@material-ui/core';

const ModalWindow = styled(ModalComponent)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalCard = styled(Card)`
  outline: none;
  max-height: 90%;
  overflow: auto;
`;

const ModalCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface IProps {
  isOpen: boolean;
  handleClose: () => any;
  title: string;
  children: React.ReactNode;
}

const Modal: React.FC<IProps> = ({ isOpen, handleClose, title, children }: IProps) => {
  return (
    <ModalWindow open={isOpen} onClose={handleClose}>
      <ModalCard variant="outlined">
        {title ? <CardHeader title={title} /> : null}
        <ModalCardContent>{children}</ModalCardContent>
      </ModalCard>
    </ModalWindow>
  );
};

export default Modal;
