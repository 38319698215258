import { Reducer } from 'redux';
import { SiteLocaleActionsTypes, SiteLocaleState } from './types';

const initialState: SiteLocaleState = {
  siteLocales: [],
  loading: false,
  error: null,
}

const siteLocaleReducer: Reducer<SiteLocaleState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case SiteLocaleActionsTypes.REQUEST_SITE_LOCALES:
      return {
        ...state,
        loading: true,
      }
    case SiteLocaleActionsTypes.REQUEST_SITE_LOCALES_SUCCESS:
      return {
        ...state,
        loading: false,
        siteLocales: payload.data,
      }
    case SiteLocaleActionsTypes.REQUEST_SITE_LOCALES_ERROR:
      return {
        ...state,
        loading: false,
        siteLocales: payload,
      }
    default:
      return state;
  }
}

export default siteLocaleReducer;
