import {createSelector} from 'reselect';
import {RootState} from 'store';

export const selectKlavyioEventState = (state: RootState) => state.klavyioEvent;

export const selectKlaviyoEventStatus = createSelector(
  selectKlavyioEventState,
  klaviyoEventState => klaviyoEventState.status,
);

export const selectKlaviyoEventData = createSelector(
  selectKlavyioEventState,
  klaviyoEventState => klaviyoEventState.data,
);