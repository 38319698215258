// Classes
import { BranchClass } from 'models/classes/branchClass';
import { IError } from 'models/interfaces/error';

export enum BranchActionTypes {
  REQUEST_BRANCH = '@@branch/REQUEST_BRANCH',
  REQUEST_BRANCH_SUCCESS = '@@branch/REQUEST_BRANCH_SUCCESS',
  REQUEST_BRANCH_ERROR = '@@branch/REQUEST_BRANCH_ERROR',
  REQUEST_BRANCHES = '@@branches/REQUEST_BRANCHES',
  REQUEST_BRANCHES_SUCCESS = '@@branches/REQUEST_BRANCHES_SUCCESS',
  REQUEST_BRANCHES_ERROR = '@@branches/REQUEST_BRANCHES_ERROR',
}

export interface BranchState {
  readonly branch: BranchClass;
  readonly branches: [];
  readonly loading: boolean;
  readonly error: IError | null;
}
