import React from 'react';
import { Dialog, DialogContentText, IconButton } from '@material-ui/core';
import { Track } from 'assets/svg/Filter/Track';
import { useTranslation } from 'react-i18next';
import {
  CloseButton,
  StylesDialogContent,
  StyledTrack,
  NotFoundText,
  LoadingText,
  StyledButton,
  ButtonTitle,
  StyledCloseIcon,
} from './styles';

interface INoProductsModal {
  open: boolean;
  handleClose: () => void;
}
export const NoInStockModal = ({ open, handleClose }: INoProductsModal) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StylesDialogContent>
        <CloseButton>
          <IconButton aria-label="close" onClick={handleClose}>
            <StyledCloseIcon />
          </IconButton>
        </CloseButton>
        <DialogContentText id="alert-dialog-description">
          <StyledTrack>
            <Track />
          </StyledTrack>
          <NotFoundText>{t('products_not_found_popup.out_of_stock')}</NotFoundText>
          <LoadingText>{t('products_not_found_popup.loading')}</LoadingText>
          <div>
            <StyledButton onClick={handleClose}>
              <ButtonTitle>{t('products_not_found_popup.go_to_products')}</ButtonTitle>
            </StyledButton>
          </div>
        </DialogContentText>
      </StylesDialogContent>
    </Dialog>
  );
};
