import { ProductVariantClass } from 'models/classes/productVariantClass';

export enum FilterType {
  CHECKBOX = 'checkbox',
  RANGE = 'range',
  MULTISELECT = 'multiselect',
}
export interface IOptionValueImage {
  url: string;
}

export interface IOptionValue {
  code: string;
  value: string | number;
  optionCode: string;
  image: IOptionValueImage | null;
  specHide: boolean;
}

export interface IVariantValue {
  code: string;
  values: IVariantOptionValue[];
  optionCode: string;
}

export interface IVariantOptionValue {
  code: string;
  value: string | number;
  image: IOptionValueImage | null;
}

export interface IPrice {
  qty: number;
  pickup: number;
  delivery: number;
}

export interface IBucket {
  image?: string;
  label: string;
  key: string;
  count: number;
	productIds: Array<number>;
}

export interface IProductValues {
  name: string;
  uom: number;
  uom2: number;
}

export interface IProductImage {
  id: number;
  url: string;
}

export interface IProductResizeImage {
  large: string;
  medium: string;
  original: string;
  small: string;
}

export interface IDocument {
  name: string;
  originalName: string;
  extension: string;
  size: number;
  id: number;
  url: string;
}

export interface IPagination {
  page: number;
  itemsPerPage: number;
}

export interface IFetchProductsPayload {
  page?: number;
  itemsPerPage?: number;
  categoryCode: string;
}

export interface IFetchFilters {
	category: string;
  language: string;
}

export interface IFetchFilteredProductsPayload {
  filterAttributes: object;
  'order[name]'?: string;
  'order[price]'?: string;
  page?: number;
  itemsPerPage?: number;
  category?: string;
  firstLoad?: boolean;
  addressId: number;
  inStock: boolean;
  language: string;
}

export interface IProductAssociation {
  associatedProductVariants?: ProductVariantClass[];
  code: string;
  title: string;
  description: string;
}

export interface IMergedAttributes {
  name: string;
  position: number;
  code: string;
  value: string;
  type: string;
  specHide: boolean;
  optionCode: string;
}

export interface IUnits {
  perCode: string;
  perName: string;
  uom2Name: string;
  uom2Code: string;
  uom2Conversion: number;
  perNameEn: string;
  perNameFr: string;
  uom2NameEn: string;
  uom2NameFr: string;
}

export interface IInventoryStatus {
  inStock: boolean;
  origin: string;
  specialOrderString: boolean;
  status: string;
  waitingOnStock: boolean;
  unit?: number;
  originEn?: string;
  originFr?: string;
  statusEn?: string;
  statusFr?: string;
}
export interface IInventory {
  onHand: number;
  isStocked: boolean;
  status: IInventoryStatus;
}

export interface ISelectedValue {
  name: string;
  code: string;
  bucketKey?: string;
  type: FilterType.CHECKBOX | FilterType.MULTISELECT | FilterType.RANGE;
  values?: number[] | string[];
  isRangeFilter?: boolean;
	productIds?: number[];
}

export interface IRangeFilterConfiguration {
  is_range: boolean;
  max_value: number;
  min_value: number;
  step: number;
}

export interface IRangeFilter {
  code: string;
  key: string;
  name: string;
  position: number;
  type: string;
  configuration: IRangeFilterConfiguration;
}

export interface IMobileFilterSelectedValue {
  name: string;
  property: string;
  values: string[];
}

export interface IFacetConfiguration {
  isRange: boolean;
}

export interface IMultiselectFilterValues {
  facetKey: string;
  values: string[];
}

export interface INearbyHubs {
  branchName: string;
  onHand: number;
  branchId: number;
  leadTime: number;
}

export interface INearbyBranches {
  onHand: number;
}

export interface INewInventory {
  onHand: number|null;
  leadTime: number|null;
  branchId: number|null;
}