import React from 'react';

export const TaigaLogo = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="120px"
      height="70px"
      viewBox="0 0 120 70"
      enableBackground="new 0 0 120 70"
      xmlSpace="preserve"
    >
      {' '}
      <image
        id="image0"
        width="120"
        height="70"
        x="0"
        y="0"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAABGCAQAAACtHZiEAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
cwAACxMAAAsTAQCanBgAAAAHdElNRQflBBcTEhmw7CxNAAAEU0lEQVR42u2b3XWbShSFv7nrvptU
kHEF4VZgXIGVCixXIKeC6FYgpwLhCmxXAKlApAJIBeAK9n1gGEBgLynLPwmXzYM483v2mTPnjLQ0
Rgm/Eyrz+XUnMNJ7c+yhMKevO8Ff783wrTETnjpmwlPHTHjqmAlPHTPhqWMmPHXMhKeOmfDUMROe
OmbCU8dMeOqYCU8dM+GpYyY8dcyEp46Z8NQxE546ZsJTx0x46pgJTx1/v8AYGRUAFvvedN6G8BeT
Aij+8wgX7vMPUPxFCDd/7FRC9N6KvQnhX8SdKgCC11BQASEBVb1tejWWkIrKZL9CuOJbp/SW7/7d
cgmk/CCjdXoICLnEAveuJCKg4p6MR98q4oKUHwBcEFJxSwZUHfOcELEY0Fmy5ZQL1nVLFXwx9742
ZNN4oTI+mwJAJZk577TZdf+OLEtOjGrkT1o4krR9svZGUjNxrFx2uA5aPFfvZyn7emgpaa1ckQIF
ipSrbHorVNmrcTroRlLQG2Ffjg4jHD5ZG6jsEL7kGSjW9TO16xHCu9ZAiiTduPdceYdIoFylAtfm
wpcnSqV2TsXKW5cOtAQTu6pFx+E+ApWbeOuDWVq7jqmUdrR+8IOvOOUE+ElcuxuAy9doyVcsUFE0
SY1sxAq3bV+Tqqizh5ZYzk3laypdkXDN2qSqOPdahFwjPvnRzkihWWFJ7WUAJdpHqZ227rlTInn3
itsV9v03nZ5+LRQ7Oek8ZbNdZEdWOOz5QFLfz1Ay9Eflri5W2fEIq+uefHFolP7GurVorR7VoFVb
EnVKLdavXsbVfkx9erv0RuzCuiDYxXdqV465VGRSYEFmCqVsvIx5OOwsXZjrPl0wxX5JT72gV95K
n4cp5Lik4giXI6WBM2nlzH3GdzBd+QGeJxwbY4wx/FuLskqUuydpg8YIsaJvsMYgzY7USrl372cD
3SgKPgzKTmpzm4qMM5ej7wG49fIdPOXSD0RAOiAUdaxsB2EmUODW/YolHwF45N4Hnsq3dLkVi+UH
t0cTPhuUhV6bBzYKiMAFw5RVLddnix5h2Vo5c6MYvNOG9YfJdM4nPlDySDHqiktuAEzBeqQ28G//
sPTK3x9JFx6I3L5s9I6wfsaYDQsu/HKlUMvO8L1YvBmOrtV4ju7E3si/lWMHi2HL0fHGonRvNB+l
A5WDPNw50ijRVnm7VZRoq9LLe+ln251Egb7WhtgnopXWI4TrEVZaKFKkSEutlDRHAcXK92OyomfT
0ihh0EJSrggUKNJOak5zALqWun33ZA1RKtdOuQssbT5tMmjujoGHojHInR+9Hme3N8PBhEFhp++u
7zmy/ZOjQkm7RnrZu4cxP4H6a0KDtDnOH/Sl84i7hwqxBGTHJbaXJXzufvuwnlrRBBdZ8gNGePXL
lhzgkodj8+QswchxdQyHGOU3Iix9HR4VZf1B4zcg/Dr3h4vOuz2u52u79Ev8xDPEcSTfFP+7H+Jn
wlPHf0L/TasJyKGsAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTA0LTIzVDE5OjE4OjI1KzAzOjAw
xUPYewAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wNC0yM1QxOToxODoyNSswMzowMLQeYMcAAAAA
SUVORK5CYII="
      />
    </svg>
  );
};
