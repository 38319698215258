export enum KLAVIYO_EVENT {
  FIRE_KLAVIYO_EVENT = '@@klaviyo/FIRE_KLAVYIO_EVENT',
  FIRE_KLAVIYO_EVENT_SUCCESS = '@@klaviyo/FIRE_KLAVYIO_EVENT_SUCCESS',
  SET_KLAVIYO_EVENT = '@@klaviyo/SET_KLAVYIO_EVENT',
}

export interface IKlaviyoEventState {
	status: boolean;
	data: any;
}

export interface IFireKlaviyoEventAction {
	type: KLAVIYO_EVENT.FIRE_KLAVIYO_EVENT;
	payload: {
		productId: number;
		quantity: number;
	}
}