import { Dispatch, SetStateAction } from 'react';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { ProductVariantClass } from 'models/classes/productVariantClass';

import axios from 'utils/axios';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { productToProductVariantClass } from 'utils/products/productVariant';

const PRODUCT_VARIANT_ATTRIBUTES = 'productVariantAttributes';
const PRODUCT_VARIANT_DOCUMENTS = 'productVariantDocuments';
const PRODUCT_VARIANT_SEARCH = 'productVariantSearch';
const PRODUCT_VARIANT_SEARCH_BY_SKU = 'productVariantSearchBySKU';
const PRODUCT_VARIANT_SEARCH_BY_NAME = 'productVariantSearchByName';
const PRODUCT_FILTER_DUMP_IDS = 'productFilterDumpIds';
const PRODUCT_SEARCH_FILTER_DUMP_IDS = 'productSearchFilterDumpIds';

interface IFetchProduct {
  productId: number;
  addressId: number;
  language?: 'en_US' | 'en_CA' | 'fr_CA';
}

function useProductVariantAttributes({ productId, addressId, language }: IFetchProduct) {
  return useQuery(
    [PRODUCT_VARIANT_ATTRIBUTES, { productId, addressId, language }],
    async () => {
      if (productId && addressId) {
        const { data } = await axios.get(
          `/api/product-variants/${productId}/attributes`,
          {
            headers: {
              accept: 'application/json',
              'X-Address-Id': addressId.toString(),
              'Accept-Language': language,
            },
          },
        );

        return data;
      }

      return null;
    },
  );
}

function useProductVariantDocuments({ productId, addressId, language }: IFetchProduct) {
  return useQuery(
    [PRODUCT_VARIANT_DOCUMENTS, { productId, addressId, language }],
    async () => {
      if (productId && addressId) {
        const { data } = await axios.get(
          `/api/auth/product/product-documents/${productId}`,
          {
            headers: {
              accept: 'application/json',
              'X-Address-Id': addressId.toString(),
              'Accept-Language': language,
            },
          },
        );

        return data;
      }

      return null;
    },
  );
}

function getProductVariantDocuments({ productId, addressId, language }: IFetchProduct) {
  return axios.get(
    `/api/auth/product/product-documents/${productId}`,
    {
      headers: {
        accept: 'application/json',
        'X-Address-Id': addressId.toString(),
        'Accept-Language': language,
      },
    },
  );
}

interface IFetchProductVariantSearch {
  addressId: number;
  language?: 'en_US' | 'en_CA' | 'fr_CA';
  searchVal: string;
  openSearchDropdown: Dispatch<SetStateAction<boolean>>;
  onChangeLoading: Dispatch<SetStateAction<boolean>>;
  page?: number;
  perPage?: number;
}

interface IProductVariantSearchReturnData {
  data: ProductVariantClass[];
  total?: Number;
}

function useProductVariantSearch({
  addressId,
  language,
  searchVal,
  openSearchDropdown,
  onChangeLoading,
  page = 1,
  perPage = 20,
}: IFetchProductVariantSearch) {
  return useQuery<IProductVariantSearchReturnData, AxiosError>(
    [PRODUCT_VARIANT_SEARCH, { addressId, language, searchVal }],
    async () => {
      const searchTrim = searchVal.trim();
      if (addressId && searchVal && searchTrim.length > 2) {
        onChangeLoading(true);

        const { data } = await axios.get('/api/auth/product/external/search/search-preview', {
          headers: {
            accept: 'application/json',
            'X-Address-Id': addressId.toString(),
            'Accept-Language': language,
          },
          params: {
            query: searchTrim,
            page,
            limit: perPage,
          },
        });
        const productVariants = data.data.data.map(productVariant =>
          productToProductVariantClass(productVariant),
        );
        return {
          data: productVariants,
          total: data.data.total,
          currentPage: data.data.currentPage,
          lastPage: data.data.lastPage,
        };
      }

      return null;
    },
    {
      onSuccess: () => {
        openSearchDropdown(true);
      },
      onSettled: () => {
        onChangeLoading(false);
      },
      retry: 3,
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  );
}

function useProductVariantSearchBySKU({
  addressId,
  language,
  searchVal,
  openSearchDropdown,
  onChangeLoading,
  page = 1,
  perPage = 20,
}: IFetchProductVariantSearch) {
  return useQuery<IProductVariantSearchReturnData, AxiosError>(
    [PRODUCT_VARIANT_SEARCH_BY_SKU, { addressId, language, searchVal }],
    async () => {
      const searchTrim = searchVal.trim();
      if (addressId && searchVal && searchTrim.length > 2) {
        onChangeLoading(true);

        const { data } = await axios.get('/api/auth/product/search/product-by-sku', {
          headers: {
            accept: 'application/json',
            'X-Address-Id': addressId.toString(),
            'Accept-Language': language,
          },
          params: {
            query: searchTrim,
            page,
            limit: perPage,
          },
        });
        const productVariants = data.data.data.map(productVariant =>
          productToProductVariantClass(productVariant),
        );
        return {
          data: productVariants,
          total: data.data.total,
          currentPage: data.data.currentPage,
          lastPage: data.data.lastPage,
        };
      }

      return null;
    },
    {
      onSuccess: () => {
        openSearchDropdown(true);
      },
      onSettled: () => {
        onChangeLoading(false);
      },
      retry: 3,
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  );
}

function useGetDumpProductFilterIds(category: string, deliveryType: string, addressId: number, filters: Record<string, any>) {
  return useQuery(
    [PRODUCT_FILTER_DUMP_IDS, { category, deliveryType, addressId, filters }],
    async () => {
      const result = await axios.get(`/api/auth/product/dump-product-ids/${category}/${deliveryType}`, {
        params: {
          ...filters,
        },
        headers: {
          'X-Address-Id': addressId.toString(),
        },
      });
      return result.data;
    },
  );
}

function useGetDumpProductSearchFilterIds(query: string, addressId: number, filters: Record<string, any>) {
  return useQuery(
    [PRODUCT_SEARCH_FILTER_DUMP_IDS, { addressId, filters }],
    async () => {
      if (filters.filterAttributes) {
        delete filters.filterAttributes;
      }
      const result = await axios.get('/api/auth/product/search/dump-product-ids', {
        params: {
          query,
          ...filters,
        },
        headers: {
          'X-Address-Id': addressId.toString(),
        },
      });
      return result.data;
    }, {
      enabled: false,
    });
}

function useSearchProductVariantByName({
  addressId,
  language,
  searchVal,
  openSearchDropdown,
  onChangeLoading,
  page = 1,
  perPage = 20,
}: IFetchProductVariantSearch) {
  return useQuery<IProductVariantSearchReturnData, AxiosError>(
    [PRODUCT_VARIANT_SEARCH_BY_NAME, { addressId, language, searchVal }],
    async () => {
      const searchTrim = searchVal.trim();
      if (addressId && searchVal && searchTrim.length > 2) {
        onChangeLoading(true);

        const { data } = await axios.get('/api/auth/product/search/product-by-name', {
          headers: {
            accept: 'application/json',
            'X-Address-Id': addressId.toString(),
            'Accept-Language': language,
          },
          params: {
            query: searchTrim,
            page,
            limit: perPage,
          },
        });
        const productVariants = data.data.data.map(productVariant =>
          productToProductVariantClass(productVariant),
        );
        return {
          data: productVariants,
          total: data.data.total,
          currentPage: data.data.currentPage,
          lastPage: data.data.lastPage,
        };
      }

      return null;
    },
    {
      onSuccess: () => {
        openSearchDropdown(true);
      },
      onSettled: () => {
        onChangeLoading(false);
      },
    },
  );
}

export {
  useProductVariantAttributes,
  useProductVariantDocuments,
  useProductVariantSearch,
  getProductVariantDocuments,
  useProductVariantSearchBySKU,
  useSearchProductVariantByName,
  PRODUCT_VARIANT_ATTRIBUTES,
  PRODUCT_VARIANT_DOCUMENTS,
  PRODUCT_FILTER_DUMP_IDS,
  useGetDumpProductFilterIds,
  useGetDumpProductSearchFilterIds,
  PRODUCT_SEARCH_FILTER_DUMP_IDS,
};
