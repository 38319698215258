import { Reducer } from 'redux';
import { OrderActionTypes, OrderState } from 'ducks/order/types';

const initialState: OrderState = {
  loading: false,
  error: null,
  submitOrderError: null,
  changeCartDeliveryLoading: false,
  changeCartDeliveryStatus: null,
  orders: [],
  submitOrderSuccess: false,
  reorderSuccess: false,
  inquireNowOrderHistory: {
    type: '',
    orderNumber: '',
    dateRequired: '',
    orderType: '',
  },
  openInquireNowOrderHistory: false,
  emailInquireNowOrderHistorySent: false,
};

const orderReducer: Reducer<OrderState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case OrderActionTypes.CHANGE_ORDER: {
      return { ...state, loading: true, error: null };
    }
    case OrderActionTypes.CHANGE_ORDER_ERROR: {
      return { ...state, loading: false, error: payload.error };
    }
    case OrderActionTypes.FINISH_LOADING: {
      return { ...state, loading: false, error: null };
    }
    case OrderActionTypes.START_LOADING: {
      return { ...state, loading: true, error: null };
    }
    case OrderActionTypes.SUBMIT_ORDER_ERROR: {
      return { ...state, loading: false, submitOrderError: payload.data.error };
    }
    case OrderActionTypes.SUBMIT_ORDER_SUCCESS: {
      return {
        ...state,
        orders: payload.orders,
        submitOrderError: false,
        loading: false,
        submitOrderSuccess: true,
      }
    }
    case OrderActionTypes.SET_SUBMIT_ORDER_ERROR: {
      return { ...state, submitOrderError: false };
    }
    case OrderActionTypes.CHANGE_CART_DELIVERY_TYPE: {
      return {
        ...state,
        changeCartDeliveryLoading: true,
        changeCartDeliveryStatus: null
      }
    }
    case OrderActionTypes.CHANGE_CART_DELIVERY_TYPE_ERROR: {
      return {
        ...state,
        changeCartDeliveryLoading: false,
        changeCartDeliveryStatus: 'error',
      }
    }
    case OrderActionTypes.CHANGE_CART_DELIVERY_TYPE_SUCCESS: {
      return {
        ...state,
        changeCartDeliveryLoading: false,
        changeCartDeliveryStatus: 'success',
      }
    }
    case OrderActionTypes.SET_SUBMIT_ORDER_STATUS: {
      return {
        ...state,
        submitOrderSuccess: payload.status,
      }
    }
    case OrderActionTypes.SET_REORDER_STATUS: {
      return {
        ...state,
        reorderSuccess: payload.status,
      }
    }
    case OrderActionTypes.SET_INQUIRE_NOW_ORDER_HISTORY: {
      return {
        ...state,
        inquireNowOrderHistory: payload,
      }
    }
    case OrderActionTypes.SET_OPEN_INQUIRE_NOW_ORDER_HISTORY: {
      return {
        ...state,
        openInquireNowOrderHistory: payload,
      }
    }
    case OrderActionTypes.SET_EMAIL_INQUIRE_NOW_ORDER_HISTORY_SENT: {
      return {
        ...state,
        emailInquireNowOrderHistorySent: payload,
      }
    }
    default:
      return state;
  }
};

export default orderReducer;
