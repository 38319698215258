import {finishLoadingOrder, setOrdersSubmitted, startLoadingOrder} from 'ducks/order/actions';
import { storeCartFirstLoad, storeCartItemsNoPrice } from 'ducks/cart/actions';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import axios from 'utils/axios';
import {
  IChangeCartDeliveryAction,
  OrderActionTypes,
  SubmitOrderAction,
  UpdateCartAction,
} from 'ducks/order/types';
import { storeErrors } from 'utils/errors';
import {selectAddressId, selectLanguage} from 'ducks/application/selectors';
import { getDefaultInputValues } from 'utils/cart/cart';
import Routes from 'routes';
import {getShortLanguageCode} from 'i18n';
import get from 'lodash/get';

function* updateCart(updateOrderAction: UpdateCartAction) {
  const { cartId, updatedOrder } = updateOrderAction.payload;
  const address = yield select(selectAddressId);
  try {
    const response = yield call(axios.put, `/api/auth/cart/update-cart/${cartId}`, updatedOrder, {
      headers: {
        'X-Address-Id': address,
      },
    });
    if (response) {
      yield put(storeCartFirstLoad(response.data, getDefaultInputValues(response.data.data)));
      yield put(finishLoadingOrder());
    }
  } catch (error) {
    yield storeErrors(OrderActionTypes.CHANGE_ORDER_ERROR, [404, 422, 400], error.response);
  }
}

function* changeCartDeliveryType(changeCartDeliveryTypeAction: IChangeCartDeliveryAction) {
  const { cartId, deliveryType } = changeCartDeliveryTypeAction.payload;
  try {
    const addressId = yield select(selectAddressId);
    const response = yield call(axios.patch, `/api/auth/cart/change-delivery-type/${cartId}`, { deliveryType }, {
      headers: {
        'X-Address-Id': addressId,
      },
    });
    if (response) {
      yield put(storeCartFirstLoad(response.data, getDefaultInputValues(response.data.data)));
      yield put({type: OrderActionTypes.CHANGE_CART_DELIVERY_TYPE_SUCCESS});
    }
  } catch (error) {
    yield storeErrors(OrderActionTypes.CHANGE_CART_DELIVERY_TYPE_ERROR, [404, 400], error.response);
    yield put({type: OrderActionTypes.CHANGE_CART_DELIVERY_TYPE_ERROR});
  }
}

function* submitOrder(submitOrderAction: SubmitOrderAction) {
  const { updatedOrder } = submitOrderAction.payload;

  const address = yield select(selectAddressId);

  try {
    yield put(startLoadingOrder());
    const { data: cart } = yield call(axios.get, '/api/auth/cart', {
      headers: {
        'X-Address-Id': address,
      },
    });

    if (cart.data.id) {
      const checkItemsNoPrice = yield call(axios.post, `/api/auth/cart/validate-cart-item-price/${cart.data.id}`, {}, {
        headers: {
          'X-Address-Id': address,
        }
      });
      if (checkItemsNoPrice.data.data) {
        yield put(storeCartItemsNoPrice(checkItemsNoPrice.data.data));
        yield put(finishLoadingOrder());
      } else {
        const response = yield call(axios.post, `/api/auth/order/submit/${cart.data.id}`, updatedOrder, {
          headers: {
            'X-Address-Id': address,
          },
        });
        if (response) {
          yield put(finishLoadingOrder());
          yield put(setOrdersSubmitted(response.data.data));
        } else {
          yield put(storeCartFirstLoad(cart.data, getDefaultInputValues(cart.data.data)));
        }
      }
    } else {
      yield put(finishLoadingOrder());
      window.location.reload();
    }
  } catch (error) {
    if (get(error, 'response.data.data.forceRefresh')) {
      const language = getShortLanguageCode(yield select(selectLanguage));
      window.location.href = `/${language}${Routes.NOT_FOUND}`;
    }
    yield storeErrors(OrderActionTypes.SUBMIT_ORDER_ERROR, [404, 422, 405, 400], error.response);
  }
}

function* watchChangeCart() {
  yield takeLatest(OrderActionTypes.CHANGE_ORDER, updateCart);
}

function* watchOrderSubmit() {
  yield takeLatest(OrderActionTypes.SUBMIT_ORDER, submitOrder);
}

function* watchChangeCartDeliveryType() {
  yield takeLatest(OrderActionTypes.CHANGE_CART_DELIVERY_TYPE, changeCartDeliveryType);
}

export default function* orderSaga() {
  yield all([watchChangeCart(), watchOrderSubmit(), watchChangeCartDeliveryType()]);
}
