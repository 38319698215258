import styled from 'styled-components/macro';

const ReorderBlockContainer = styled.div`
  display: flex;
  padding: 23px 30px;
  background-color: ${({ theme: { colors } }) => colors.lightGrey};
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 1023px) {
    padding: 17px 10px;
    align-items: flex-end;
  }
`;

const LeftColumn = styled.div`
  @media (max-width: 1023px) {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const TaxesRow = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

const AddButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

const CancelReorderButton = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  height: max-content;
  cursor: pointer;

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 19.07px;
  }
`;

const TaxesRowInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const TaxesRowInfoTextWrapper = styled.div`
  display: flex;
`;

interface ITaxesRowText {
  bold?: boolean;
}

const TaxesRowInfoText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: ${(p: ITaxesRowText) => (p.bold ? '700' : '400')};
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
`;

const StyledButton = styled.button`
  cursor: pointer;
  border: none;
  background: ${({ theme: { colors } }) => colors.mainGreen};
  width: 300px;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 0 13px 0;
  outline: none;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.mainGreenHover};
  }

  &:active {
    background-color: ${({ theme: { colors } }) => colors.mainGreefPassed};
  }

  &:disabled {
    background-color: ${({ theme: { colors } }) => colors.grey};
    cursor: default;
  }

  @media (max-width: 1023px) {
    max-width: 157px;
  }
`;

const ButtonTitle = styled.p`
  margin: 0;
  color: ${({ theme: { colors } }) => colors.white};
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 19.07px;
  }
`;

export {
  ReorderBlockContainer,
  TaxesRow,
  CancelReorderButton,
  TaxesRowInfoTextWrapper,
  TaxesRowInfoText,
  TaxesRowInfoWrapper,
  AddButtonRow,
  ButtonTitle,
  StyledButton,
  RightColumn,
  LeftColumn,
};
