import React from 'react';
import styled from 'styled-components';
import { Link } from '@material-ui/core';
import { EmailIcon } from 'assets/svg/ContactUs/ContactIcons';

const StyledLink = styled(Link)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.small};
  line-height: 80%;
  color: ${({ theme: { colors } }) => colors.mainGreen};
  cursor: pointer;
  margin-left: 6px;
  margin-bottom: 8px;
`;

const ContactRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 6px;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

interface EmailsProps {
  emails: Array<String>;
}

export const ContactEmails: React.FC<EmailsProps> = ({ emails }: EmailsProps) => {
  return (
    <ContactRow>
      <EmailIcon />
      <LinksContainer>
        {emails.map(email => (
          <StyledLink key={`${email}`} title={email} href={`mailto:${email}`}>
            {email}
          </StyledLink>
        ))}
      </LinksContainer>
    </ContactRow>
  );
};
