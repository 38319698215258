// Classes
import { SliderClass } from 'models/classes/sliderClass';
import { IError } from 'models/interfaces/error';

export enum SliderActionTypes {
  REQUEST_SLIDER_DATA = '@@slider/REQUEST_SLIDER_DATA',
  REQUEST_SLIDER_DATA_SUCCESS = '@@slider/REQUEST_SLIDER_DATA_SUCCESS',
  REQUEST_SLIDER_DATA_ERROR = '@@slider/REQUEST_SLIDER_DATA_ERROR',
}

export interface SliderState {
  readonly slides: SliderClass[];
  readonly error: IError | null;
}

export interface FetchSliderDataAction {
  type: SliderActionTypes.REQUEST_SLIDER_DATA;
}
