import React from 'react';

export const SuccessIcon = () => {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.125 26.25C20.3737 26.25 26.25 20.3737 26.25 13.125C26.25 5.87626 20.3737 0 13.125 0C5.87626 0 0 5.87626 0 13.125C0 20.3737 5.87626 26.25 13.125 26.25Z"
        fill="#00A169"
      />
      <path
        d="M7.7226 11.9649C7.08189 11.3242 6.04311 11.3242 5.4024 11.9649C4.7617 12.6056 4.7617 13.6444 5.4024 14.2851L10.9375 19.8202L20.8476 9.9101C21.4883 9.26939 21.4883 8.23061 20.8476 7.5899C20.2069 6.9492 19.1681 6.9492 18.5274 7.5899L10.9375 15.1798L7.7226 11.9649Z"
        fill="white"
      />
    </svg>
  );
};
