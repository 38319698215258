import { Tooltip, withStyles } from '@material-ui/core';

const StyledTooltip = withStyles({
  popper: {
    zIndex: 9999,
  },
  tooltip: {
    background: '#FFF',
    fontFamily: 'Open Sans',
    color: '#000',
    border: '1px solid #E5E5E5',
  },
  arrow: {
    color: '#fff',
  },
})(Tooltip);

export { StyledTooltip };
