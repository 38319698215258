import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import { UserClass } from 'models/classes/userClass';
import { IAddress } from 'models/interfaces/user';
import ChoosePricingStep from 'components/Popups/StartSettings/ChoosePricingStep';
import ShippingAddressStep from 'components/Popups/StartSettings/ShippingAddressStep';
import { patchProfile } from 'ducks/user/actions';
import { useDispatch } from 'react-redux';
import { DELIVERY_PRICE_TYPE } from 'assets/constants/application';
import { useTranslation } from 'react-i18next';
import {
  PopUpHeader,
  PopUpHelperText,
  StyledDialogContent,
} from 'components/Popups/StartSettings/styles';
import ChooseMailchimpStep from 'components/Popups/StartSettings/ChooseMailchimpStep';
import { setAddressCode, setAddressId } from 'ducks/application/actions';

interface IMonthlyPopupsProps {
  user: UserClass;
}

const StartSettingsPopup: React.FC<IMonthlyPopupsProps> = ({ user }: IMonthlyPopupsProps) => {
  const { t } = useTranslation();
  const [close, setClose] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const dispatch = useDispatch();

  const nextStep = () => {
    setStep(s => s + 1);
  };

  const [address, setAddress] = useState<IAddress>(user?.defaultAddress || user?.addresses[0]);
  const [checkedShipping, setCheckedShipping] = useState(
    user?.defaultPricing || DELIVERY_PRICE_TYPE
  );
  const [isMailChimpEnabled, setMailChimpEnabled] = useState(true);

  const handleSave = () => {
    dispatch(
      patchProfile({
        defaultPricing: checkedShipping,
        defaultAddressId: address.id,
        isMailchimpEnabled: isMailChimpEnabled,
      })
    );
    dispatch(setAddressId(address.id));
    dispatch(setAddressCode(address.erpPostCode));
    setClose(true);
  };
  const firstName = user?.firstName;

  return !close && user ? (
    <Dialog
      open={!close}
      maxWidth="lg"
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="form-dialog-title"
    >
      <StyledDialogContent>
        <PopUpHeader>{t('shipping_address_popup.welcome', { firstName })}</PopUpHeader>
        <PopUpHelperText>{t('start_settings_popup.preferences_before_you_start')}</PopUpHelperText>
        {step === 1 && (
          <ShippingAddressStep
            nextStep={nextStep}
            address={address}
            setAddress={setAddress}
            user={user}
          />
        )}
        {step === 2 && (
          <ChoosePricingStep
            nextStep={nextStep}
            setCheckedShipping={setCheckedShipping}
            checkedShipping={checkedShipping}
          />
        )}
        {step === 3 && (
          <ChooseMailchimpStep
            handleSave={handleSave}
            setMailChimpEnabled={setMailChimpEnabled}
            isMailChimpEnabled={isMailChimpEnabled}
          />
        )}
      </StyledDialogContent>
    </Dialog>
  ) : null;
};

export default StartSettingsPopup;
