import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';

import { BreadCrumbs } from 'components/common/BreadCrumbs/BreadCrumbs';
import {
  RepresentativeContactComponent,
  PersonalSettings,
  Orders,
  Quotes,
} from 'pages/General/Profile/index';
import { Arrow } from 'assets/svg/Profile/Tabs';
import { selectUserData } from 'ducks/user/selectors';

import { useTranslation } from 'react-i18next';
import {
  getSalesRepresentativeLoading,
  selectSalesRepresentative,
} from 'ducks/salesRepresentative/selectors';
import { useMediaQuery } from '@material-ui/core';
import {
  selectAddressId,
  selectErpCustomerAddressCode,
  selectLanguage,
} from 'ducks/application/selectors';
import { usePermission } from 'hooks/usePermission';
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';
import { useHistory } from 'react-router-dom';
import Routes from 'routes';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectBranch } from 'ducks/branch/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { selectOpenInquireNowOrderHistory } from 'ducks/order/selectors';
import InquireNowOrderHistory from '../../../components/Popups/InquireNowOrderHistoryPopup/InquireNowOrderHistory';
import { useQuery } from '../../../utils/common';

const MainContainer = styled.div`
  margin-top: 32px;
  width: 100%;
  @media (max-width: 1023px) {
    width: 100%;
  }
  @media (max-width: 700px) {
    padding: 0;
    width: 100%;
  }
  @media (max-width: 380px) {
  }
`;

const PageTitle = styled.h1`
  color: #00133C;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  letter-spacing: 0.1px;
  margin-top: 12px;
  margin-top: 24px;
`;

const ContentWrapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 41px;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 1023px) {
    margin-top: 32px;
    flex-direction: column;
  }
  @media (max-width: 767px) {
    margin-top: 32px;
    flex-direction: column;
  }
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const VerticalLine = styled.div`
  height: 489px;

  @media (max-width: 700px) {
    border-left: none;
    height: 170px;
    width: 100%;
    max-width: 600px;
    border-top: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  }
`;

const DestopTabs = styled.div`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.productBorderGrey};
  @media (max-width: 700px) {
    display: none;
  }
`;

interface IDesktopTab {
  selected?: boolean;
}

const DesktopTab = styled.span`
  cursor: pointer;
  display: inline-block;
  padding-bottom: 19px;
  border-bottom: ${(p: IDesktopTab) => (p.selected ? '2px solid' : null)};
  border-color: ${(p: IDesktopTab) =>
    p.selected ? ({ theme: { colors } }) => colors.mainGreen : null};
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-right: 23px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: ${(p: IDesktopTab) =>
    !p.selected
      ? ({ theme: { colors } }) => colors.grey
      : ({ theme: { colors } }) => colors.mainGreen};
`;

const MobileTab = styled.div`
  cursor: pointer;
  display: flex;
  padding: 14px 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.01em;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-left: 2px solid
    ${(p: IDesktopTab) =>
    !p.selected
      ? ({ theme: { colors } }) => colors.grey
      : ({ theme: { colors } }) => colors.mainGreen};

  svg {
    transform: ${(p: IDesktopTab) => `rotate(${!p.selected ? 270 : 0}deg);`};
  }

  border-top: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  color: ${(p: IDesktopTab) =>
    !p.selected
      ? ({ theme: { colors } }) => colors.grey
      : ({ theme: { colors } }) => colors.mainGreen};
  @media (min-width: 700px) {
    display: none;
  }
`;

const BreadCrumbsWrapper = styled.div`
  @media (max-width: 700px) {
    padding-left: 10px;
  }
`;

interface ITabs {
  tabs: string[];
  currentTab: string;
  onTabClick: (tab: string, i: number) => void;
}

interface IState {
  tab?: string | undefined;
}

interface ILocation {
  state?: IState | undefined;
}

interface IProps {
  location?: ILocation | undefined;
}

const Profile: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const { location } = props;
  const query = useQuery();
  const [orderToOpenNumber, setOrderToOpenNumber] = useState(0);
  const isNarrowWidth = useMediaQuery('(max-width: 1280px)');
  const selectedLanguage = useSelector(selectLanguage);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const index = useRef(0);
  const { canSeeOrderHistory } = usePermission();
  const [TABS, setTABS] = useState([t('profile.personal_settings')]);
  const queryTabState = query.get('state');
  const propsActiveTab = queryTabState || (location?.state?.tab ? t(location.state.tab) : TABS[index.current]);
  const [tab, setTab] = React.useState<string>(propsActiveTab);
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const isOpenInquireNowOrderHistory = useSelector(selectOpenInquireNowOrderHistory);

  const history = useHistory();

  useEffect(() => {
    if (canSeeOrderHistory) {
      const newTabs = cloneDeep(TABS);
      newTabs.unshift(t('profile.orders'), t('profile.quotes'));
      setTABS(uniq(newTabs));
    }
  }, [canSeeOrderHistory]);

  const getDataTestId = (tabName: string) => {
    if (tabName === t('profile.personal_settings')) {
      return 'personal-settings';
    }
    if (tabName === t('profile.quotes')) {
      return 'quotes-button';
    }
    if (tabName === t('profile.orders')) {
      return 'orders-button';
    }
    return '';
  };

  const Tabs = ({ tabs, currentTab, onTabClick }: ITabs) => {
    return (
      <DestopTabs>
        {tabs.map((e, i) => (
          <DesktopTab
            data-test-id={getDataTestId(e)}
            key={e}
            selected={currentTab === e}
            onClick={() => {
              if (currentTab !== 'Quotes' && branch) {
                clearGtmEcommerce();
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'click_tab_quotes',
                    user_type: getUserType(user.email),
                    branch_id: branch.id,
                    customer_code: user?.customer?.erpCustomerCode,
                    address_code: erpCustomerAddressCode,
                    address_id: selectedAddressId,
                  },
                });
              }
              onTabClick(TABS[i], i);
            }}
          >
            {e}
          </DesktopTab>
        ))}
      </DestopTabs>
    );
  };

  const setMobileTab = (selectedTab: string): void => {
    if (selectedTab === tab) {
      setTab('');
    } else setTab(selectedTab);
  };

  const isThis = (expect: string) => expect === tab;

  const isSetting = isThis(t('profile.personal_settings'));
  const isQuotes = isThis(t('profile.quotes'));
  const isOrders = isThis(t('profile.orders'));

  const firstName = user ? user.firstName : '';

  const salesRepresentative = useSelector(selectSalesRepresentative);
  const salesRepresentativeLoading = useSelector(getSalesRepresentativeLoading);

  const handleTabClick = (value: string, i: number) => {
    setTab(value);
    index.current = i;
    switch (value) {
      case 'Orders':
        history.replace(`${Routes.PROFILE}?state=profile.orders`, { tab: 'profile.orders' });
        break;
      case 'Quotes':
        history.replace(`${Routes.PROFILE}?state=profile.quotes`, { tab: 'profile.quotes' });
        break;
      case 'Personal Settings':
        history.replace(`${Routes.PROFILE}?state=profile.personal_settings`, { tab: 'profile.personal_settings' });
        break;
      default:
        break;
    }
  };

  const goToOrderClick = (orderNumber: number) => {
    setTab(t('profile.orders'));
    setOrderToOpenNumber(orderNumber);
  };

  useEffect(() => {
    if (queryTabState) {
      index.current = TABS.findIndex(value => value === t(queryTabState));
      setTab(t(queryTabState));
    } else if (location?.state?.tab && canSeeOrderHistory) {
      index.current = TABS.findIndex(value => value === t(location.state.tab));
      setTab(t(location.state.tab));
    }
  }, [TABS, selectedLanguage, queryTabState, t, ...Object.values(location)]);

  return (
    <MainContainer>
      <BreadCrumbsWrapper>
        <BreadCrumbs currentPageName={t('profile.pagename')} />
      </BreadCrumbsWrapper>

      <PageTitle className="txt-page-title">{t('profile.hello', { firstName }).toString()}</PageTitle>

      {isNarrowWidth && !salesRepresentativeLoading && salesRepresentative && (
        <RepresentativeContactComponent display="flex" salesRepresentative={salesRepresentative} />
      )}
      <Tabs tabs={TABS} currentTab={tab} onTabClick={handleTabClick} />
      <ContentWrapper>
        <MobileTab
          selected={isSetting}
          onClick={() => setMobileTab(t('profile.personal_settings'))}
          data-test-id="personal-settings"
        >
          {t('profile.personal_settings').toString()} <Arrow />
        </MobileTab>
        {isSetting && <PersonalSettings />}
        {canSeeOrderHistory && (
          <MobileTab selected={isQuotes} onClick={() => setMobileTab(t('profile.quotes'))} data-test-id="quotes-button">
          {t('profile.quotes').toString()} <Arrow />
        </MobileTab>)}
        {isQuotes && <Quotes openOrderTab={goToOrderClick} />}
        {canSeeOrderHistory && (
          <MobileTab selected={isOrders} onClick={() => setMobileTab(t('profile.orders'))} data-test-id="orders-button">
          {t('profile.orders').toString()} <Arrow />
        </MobileTab>)}
        {isOrders && <Orders orderToOpen={orderToOpenNumber} />}
        {!isNarrowWidth && salesRepresentative && (
          <VerticalLine>
            {!salesRepresentativeLoading && (
              <RepresentativeContactComponent salesRepresentative={salesRepresentative} />
            )}
          </VerticalLine>
        )}
      </ContentWrapper>
      {
        isOpenInquireNowOrderHistory && <InquireNowOrderHistory />
      }
    </MainContainer>
  );
};

export default Profile;
