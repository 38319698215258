import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { useMediaQuery } from '@material-ui/core';
import ProductFilterPaper from 'components/Product/ProductFilterPapers';
import { IPagination, IRangeFilter } from 'models/interfaces/productVariant';
import { FacetClass } from 'models/classes/facetClass';

// Styles
const ProductWithPaper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

interface IPaperWrapper {
  facets: FacetClass[];
  rangeFilters: IRangeFilter[];
  selectedValues;
  setSelectedValues;
  children;
  setFilter: Dispatch<SetStateAction<object>>;
  setPagination: Dispatch<SetStateAction<IPagination>>;
  setFirstLoad?: Dispatch<SetStateAction<boolean>>;
}

const ProductWithPaperWrapper: React.FC<IPaperWrapper> = ({
  selectedValues,
  setSelectedValues,
  children,
  setFilter,
  facets,
  rangeFilters,
  setPagination,
  setFirstLoad
}: IPaperWrapper) => {
  const isMobile = useMediaQuery('(max-width: 1023px)');
  return (
    <ProductWithPaper>
      {!isMobile ? (
        <ProductFilterPaper
          facets={facets}
          rangeFilters={rangeFilters}
          filterValues={selectedValues}
          setSelectedValues={setSelectedValues}
          setFilter={setFilter}
          setPagination={setPagination}
          setFirstLoad={setFirstLoad}
        />
      ) : null}
      {children}
    </ProductWithPaper>
  );
};

export default ProductWithPaperWrapper;
