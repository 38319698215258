import React from 'react';

export const GreyCheckbox = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DADEE4" />
    </svg>
  );
};

export const CheckedGreyCheckbox = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3333 0H2.66667C1.2 0 0 1.2 0 2.66667V21.3333C0 22.8 1.2 24 2.66667 24H21.3333C22.8 24 24 22.8 24 21.3333V2.66667C24 1.2 22.8 0 21.3333 0ZM9.33333 18.6667L2.66667 12.2564L4.53333 10.4615L9.33333 15.0769L19.4667 5.33333L21.3333 7.12821L9.33333 18.6667Z"
        fill="#00853e"
      />
    </svg>
  );
};
