// Types
import { CategoryActionTypes } from 'ducks/category/types';

// Classes
import { CategoryClass } from 'models/classes/categoryClass';

export const fetchCategories = (addressId: number, language: string, branchId: number) => ({
  type: CategoryActionTypes.REQUEST_CATEGORIES,
  payload: {
    addressId,
    language,
    branchId
  },
});

export const storeCategories = (categories: CategoryClass[]) => ({
  type: CategoryActionTypes.REQUEST_CATEGORIES_SUCCESS,
  payload: {
    categories,
  },
});

export const storeParentCategories = (categories: CategoryClass[]) => ({
  type: CategoryActionTypes.REQUEST_PARENT_CATEGORY_SUCCESS,
  payload: {
    categories,
  }
})

export const fetchCategory = (code: string, language: string, addressId: number) => ({
  type: CategoryActionTypes.REQUEST_CATEGORY,
  payload: {
    code,
    language,
    addressId,
  },
});

export const fetchParentCategories = (branchId: number, language: string) => ({
  type: CategoryActionTypes.REQUEST_PARENT_CATEGORY,
  payload: {
    branchId,
    language,
  }
})

export const storeCategory = (category: CategoryClass) => ({
  type: CategoryActionTypes.REQUEST_CATEGORY_SUCCESS,
  payload: {
    category,
  },
});

export const resetCategories = () => ({
  type: CategoryActionTypes.RESET_CATEGORIES,
});

export const resetCategory = () => ({
  type: CategoryActionTypes.RESET_CATEGORY,
});

export const resetCategoriesError = () => ({
  type: CategoryActionTypes.RESET_CATEGORIES_ERROR,
});
