import styled from 'styled-components/macro';
import { DialogContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const CloseButton = styled.div`
  text-align: right;
`;

const StylesDialogContent = styled(DialogContent as any)`
  text-align: center;
`;

const StyledTrack = styled.div``;

const NotFoundText = styled.div`
  font-family: Open Sans, serif;
  margin-top: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme: { colors } }) => colors.black};
`;

const LoadingText = styled.div`
  font-family: Open Sans, serif;
  margin-top: 12px;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: ${({ theme: { colors } }) => colors.textColor};
`;

const StyledButton = styled.button`
  margin-top: 12px;
  margin-bottom: 40px;
  cursor: pointer;
  border: none;
  background: ${({ theme: { colors } }) => colors.mainGreen};
  border-radius: 4px;
  padding: 13px 0;
  outline: none;
  text-align: center;
  min-width: 364px;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.mainGreenHover};
  }

  &:active {
    background-color: ${({ theme: { colors } }) => colors.mainGreefPassed};
  }

  @media (max-width: 600px) {
    min-width: 100%;
  }

  @media (max-width: 1023px) {
    max-width: 100%;
  }
`;

const ButtonTitle = styled.span`
  margin: 0;
  color: ${({ theme: { colors } }) => colors.white};
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
`;

const StyledCloseIcon = styled(CloseIcon as any)`
  font-size: 40px;
  color: ${({ theme: { colors } }) => colors.darkGrey};
`;

export {
  CloseButton,
  StylesDialogContent,
  StyledTrack,
  NotFoundText,
  LoadingText,
  StyledButton,
  ButtonTitle,
  StyledCloseIcon,
};
