import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const FooterWrapper = styled(Grid)`
  background: ${({ theme: { colors } }) => colors.mainGreen};
  width: 100%;
`;

const LogoGrid = styled(Grid)`
  height: fit-content;
  padding-top: 13px;
  padding-left: 21px;
  padding-bottom: 24px;
`;

const MenuGrid = styled(Grid)`
  padding-left: 21px;
  padding-right: 21px;
  padding-bottom: 35px;
  color: ${({ theme: { colors } }) => colors.white};
  cursor: pointer;
`;

const LocationDiv = styled.div`
  display: flex;
  color: ${({ theme: { colors } }) => colors.white};
  padding-top: 18px;
`;

const LocationTypography = styled(Typography)`
  display: inline;
  padding-left: 9px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  text-transform: capitalize;
  color: ${({ theme: { colors } }) => colors.white};
`;

const PhonesDiv = styled.div`
  display: flex;
  color: ${({ theme: { colors } }) => colors.white};
  padding-top: 20px;
`;

const StyledLocationDiv = styled.div`
  margin-top: 1px;
`;

const StyledEmailDiv = styled.div`
  margin-top: -1px;
`;

const PhoneWrapperDiv = styled.div`
  .item {
    margin-bottom: 3px;
  }
  svg {
    vertical-align: middle;
    path {
      fill: #fff;
    }
  }
  .txt {
    padding-left: 9px;
  }
`;

const EmailDiv = styled.div`
  color: ${({ theme: { colors } }) => colors.white};
  padding-top: 20px;
`;

const StyledA = styled.a`
  text-decoration: none;
`;

const EmailTypography = styled(Typography)`
  display: inline;
  padding-left: 9px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.white};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const MenuTypography = styled(Typography)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: ${({ theme: { colors } }) => colors.white};
`;

const CopyrightGrid = styled(Grid)`
  background: ${({ theme: { colors } }) => colors.borderGreen};
  color: ${({ theme: { colors } }) => colors.copyrightGreen};
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 56px;
  padding-right: 56px;
`;

const HeaderTypography = styled(Typography)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 155%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: ${({ theme: { colors } }) => colors.white};
  text-size-adjust: none;
`;

const BodyTypography = styled(Typography)`
  padding-top: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.white};
  text-size-adjust: none;
`;

const FirstGrid = styled(Grid)`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.borderGreen};
  padding-bottom: 24px;
  > * {
    width: 100%;
  }
`;

const SecondGrid = styled(Grid)`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.borderGreen};
  padding-top: 24px;
  padding-bottom: 24px;
`;

const ThirdGrid = styled(Grid)`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.borderGreen};
  padding-top: 24px;
  padding-bottom: 24px;
`;

const ForthGrid = styled(Grid)`
  padding-top: 24px;
`;

const StyledTypography = styled(Typography)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledHyperLink = styled.a`
  text-decoration: none;
`;

export {
  FooterWrapper,
  LogoGrid,
  LocationTypography,
  LocationDiv,
  EmailTypography,
  EmailDiv,
  StyledEmailDiv,
  SecondGrid,
  StyledTypography,
  StyledLocationDiv,
  StyledLink,
  StyledHyperLink,
  StyledA,
  FirstGrid,
  ThirdGrid,
  ForthGrid,
  PhonesDiv,
  PhoneWrapperDiv,
  CopyrightGrid,
  HeaderTypography,
  BodyTypography,
  MenuTypography,
  MenuGrid,
};
