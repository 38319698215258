import styled from 'styled-components';
import { Link as LinkTo } from 'react-router-dom';

const FooterWrapper = styled.div`
  width: 100%;
  background: ${({ theme: { colors } }) => colors.greenLandingOpacity(1)};
  display: flex;
  justify-content: center;
`;

const Footer = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 60px 120px;
  display: grid;
  grid-template-areas: 'a b c d e';
  grid-template-columns: auto;
  grid-template-rows: auto;

  @media (max-width: 1439px) {
    padding: 40px 70px;
  }

  @media (max-width: 1360px) {
    padding: 40px 20px 20px 20px;
  }

  @media (max-width: 980px) {
    padding-top: 0px;
    grid-template-areas: 'a' 'b' 'c' 'd' 'e';
  }

  @media (max-width: 920px) {
    padding: 0 10px 20px 20px;
  }
`;

const FooterLogoWrapper = styled.div`
  grid-area: a;
  width: fit-content;

  @media (max-width: 980px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 40px;
  }
`;

const FooterLogoIconsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 980px) {
    margin-bottom: 20px;
  }
`;

const FooterContentBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: fit-content;
  height: fit-content;
  grid-area: b;
`;

const FooterContentBlockIconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: fit-content;
  height: 100%;
  grid-area: e;

  @media (max-width: 980px) {
    margin-top: 24px;
  }
`;

const FooterContentBlockWrapperWithBorders = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: fit-content;
  height: 100%;
  border-left: 1px solid ${({ theme: { colors } }) => colors.greenBorder};
  border-right: 1px solid ${({ theme: { colors } }) => colors.greenBorder};
  padding: 0 15px 0 40px;
  grid-area: c;

  @media (max-width: 1179px) {
    padding: 0 7px 0 32px;
  }

  @media (max-width: 980px) {
    width: 100%;
    border-top: 1px solid ${({ theme: { colors } }) => colors.greenBorder};
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.greenBorder};
    border-left: none;
    border-right: none;
    padding: 24px 0 8px 0;
    margin-bottom: 8px;
  }
`;

const FooterContentBlockCompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: fit-content;
  height: 100%;
  grid-area: d;

  @media (max-width: 980px) {
    width: 100%;
    padding: 24px 0;
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.greenBorder};
  }
`;

const FooterIcon = styled.div`
  display: inline-block;
  margin-right: 9px;
`;

const StyledFooterLink = styled(LinkTo)`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: ${({ theme: { colors } }) => colors.white};
  cursor: pointer;
  text-decoration: none;
`;

const StyledFooterLinkToEmail = styled.a`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: ${({ theme: { colors } }) => colors.white};
  cursor: pointer;
  text-decoration: none;
`;

const FooterTaigaProducts = styled.span`
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.01em;
  margin-bottom: 16px;
  color: ${({ theme: { colors } }) => colors.white};
  display: inline-block;
`;

const FooterFirstContentBlockRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-right: 8px;
  max-width: 200px;
`;

const FooterContentBlockHeader = styled.span`
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.01em;
  display: inline-block;
  margin-bottom: 16px;
  color: ${({ theme: { colors } }) => colors.white};
  max-width: 210px;
`;

const FooterProductListContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(3, auto);

  & > span {
    width: 165px;
    margin-bottom: 16px;
  }

  @media (max-width: 1179px) {
    grid-auto-flow: row;
    grid-template-rows: auto;
  }

  @media (max-width: 980px) {
    grid-auto-flow: column;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledFooterProductsLink = styled.a`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: ${({ theme: { colors } }) => colors.white};
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 16px;
  margin-right: 25px;

  @media (max-width: 980px) {
    margin-right: 0;
  }
`;

const StyledFooterPrivacyLink = styled.span`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: ${({ theme: { colors } }) => colors.white};
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 16px;
`;

const FooterContentBlockIndentWrapper = styled.div`
  width: 100%;
  padding: 0 15px;

  @media (max-width: 1179px) {
    padding: 0;
  }
`;

const FooterCompanyInfoList = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 980px) {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledSocialIcon = styled.div`
  height: 32px;
  width: auto;
  cursor: pointer;
  margin-right: 16px;

  @media (max-width: 980px) {
    height: 20px;
  }
`;

const FooterCopyrightWrapper = styled.div`
  width: 100%;
  padding: 0 120px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme: { colors } }) => colors.darkGreen};

  @media (max-width: 980px) {
    padding: 0;
    margin: 0 auto;
  }
`;

const FooterCopyrightText = styled.span`
  display: inline-block;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.01em;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.copyrightGreen};

  @media (max-width: 980px) {
    max-width: 290px;
  }
`;

const LogoWrapper = styled.div`
  width: 150px;
  height: auto;
`;

export {
  Footer,
  FooterWrapper,
  FooterLogoWrapper,
  FooterLogoIconsWrapper,
  FooterContentBlockWrapper,
  FooterContentBlockIconsWrapper,
  FooterContentBlockWrapperWithBorders,
  FooterContentBlockCompanyWrapper,
  FooterIcon,
  StyledFooterLink,
  FooterTaigaProducts,
  FooterFirstContentBlockRow,
  FooterContentBlockHeader,
  FooterProductListContainer,
  StyledFooterProductsLink,
  StyledFooterPrivacyLink,
  FooterContentBlockIndentWrapper,
  FooterCompanyInfoList,
  StyledSocialIcon,
  FooterCopyrightWrapper,
  FooterCopyrightText,
  LogoWrapper,
  StyledFooterLinkToEmail
};
