import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { WhiteTruck } from 'assets/svg/Product/Products';
import RequestPricingPopup from 'components/Popups/RequestPricing/RequestPricingPopup';
import { ProductMultiInput } from 'components/Product/Inputs/ProductMultiInput';
import { ProductVariantClass } from 'models/classes/productVariantClass';
import { IPrice } from 'models/interfaces/productVariant';
import {
  priceTypeSelector,
  selectAddressId,
  selectErpCustomerAddressCode, selectIsHidePrice,
} from 'ducks/application/selectors';
import { numberFormat } from 'utils/products/product';
import { usePurchaseClickHandler } from 'pages/Product/ProductTableView/hooks';
import defaultProduct from 'assets/images/default-product.png';
import { useRequestPricing } from 'hooks/useRequestPricing';
import { filterPrices } from 'utils/products/productVariant';

import { NearbyBranchEmailType, PRODUCT_PRICING_METHOD_BLENDED } from 'ducks/productVariant/types';
import TagManager from 'react-gtm-module';
import { getUserType } from 'utils/profile/profileUtilsHelpers';
import { selectUserData } from 'ducks/user/selectors';
import { selectBranch } from 'ducks/branch/selectors';
import { clearGtmEcommerce } from 'utils/clearGtmEcommerce';
import { getDefaultProductName } from 'utils/products/productUtilsHelpers';
import { selectCategories } from 'ducks/category/selectors';
import { getDefaultCategoryName } from 'utils/categories/categories';
import { setAddToTruckItem } from 'ducks/cart/actions';
import { setClickedProductRequestNearbyHubsAndBranch, setOpenPopupInquireNow } from 'ducks/product/actions';
import { NearbyBranchContext } from 'context/NearbyBranchContext';
import size from 'lodash/size';
import {
  StyledProductNameGrid,
  StyledInputsGrid,
  AddButtonTitle,
  AddButton,
  Total,
  AvatarWrapperGrid,
  StyledCloseIcon,
  StyledLink,
  RequestPricing,
  RequestPricingButton,
  RequestPricingWrapper,
  Each,
  Price,
  ProductName,
  AvatarWrapper,
  DefaultAvatarWrapper,
  ProductCard,
  ProductCardGrid,
} from './styles';

import { StyledAvailableAndLeadTime } from '../ProductTableCard/styles';

interface ITableHoveredCard {
  productVar: ProductVariantClass;
  categoryName?: string;
  code?: string;
  mainCategoryName?: string;
  id?: string;
  renderProductStock: CallableFunction;
  handleChangeTableView: () => void;
  listName?: string;
  isFeatured?: boolean;
}

const ProductTableHoveredCard: React.FC<ITableHoveredCard> = ({
  productVar,
  categoryName,
  code,
  mainCategoryName,
  id,
  renderProductStock,
  handleChangeTableView,
  listName,
  isFeatured = true,
}: ITableHoveredCard) => {
  const dispatch = useDispatch();
  const isHidePrice = useSelector(selectIsHidePrice);
  const priceType = useSelector(priceTypeSelector);
  const user = useSelector(selectUserData);
  const branch = useSelector(selectBranch);
  const selectedAddressId = useSelector(selectAddressId);
  const erpCustomerAddressCode = useSelector(selectErpCustomerAddressCode);
  const categories = useSelector(selectCategories);
  const { setEmailType, setFromBranchId } = React.useContext(NearbyBranchContext);

  const productPrices = filterPrices(productVar.prices);
  const productMaxQuantity = productVar.prices[productVar.prices.length - 1];

  const [currentPrice, setCurrentPrice] = useState<IPrice[]>(
    productVar && productPrices.length ? [productPrices[0]] : [],
  );
  const [totalPrice, setTotalPrice] = useState(0);

  const [topInputValue, setTopInputValue] = useState(
    productVar && productPrices.length ? productPrices[0]?.qty : 1,
  );
  const [bottomInputValue, setBottomInputValue] = useState<number | null>(
    productVar && productPrices.length && productVar.units.uom2Conversion
      ? productPrices[0]?.qty / productVar.units.uom2Conversion
      : null,
  );
  const { messageTop, messageBottom, handlePurchaseClick } = usePurchaseClickHandler(
    productVar,
    topInputValue,
    bottomInputValue,
    listName ?? categoryName,
    mainCategoryName,
  );
  const { isRequestPricing, setIsRequestPricing, handleRequestPricingClick } = useRequestPricing(
    productVar,
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (productVar) {
      const priceToSet =
        currentPrice.length > 0 ? currentPrice[currentPrice.length - 1][priceType] : null;
      if (productMaxQuantity && productVar.pricingMethod === PRODUCT_PRICING_METHOD_BLENDED) {
        const topQuantity = parseInt(String(productMaxQuantity?.qty || productPrices[0]?.qty), 10);
        if (topInputValue >= topQuantity) {
          const topPrice = parseFloat(productMaxQuantity[priceType]);
          const productOutGroup = topInputValue % topQuantity;
          const productInGroup = topInputValue - productOutGroup;
          setTotalPrice(parseFloat(numberFormat((priceToSet * productOutGroup) + (topPrice * productInGroup))) || 0);
        } else {
          setTotalPrice(parseFloat(numberFormat(priceToSet * topInputValue)) || 0);
        }
      } else {
        setTotalPrice(parseFloat(numberFormat(priceToSet * topInputValue)) || 0);
      }
    }
  }, [productVar, priceType, currentPrice, topInputValue]);

  const renderPriceForEach = () =>
    currentPrice.length > 0 ? numberFormat(currentPrice[currentPrice.length - 1][priceType]) : null;

  const addToTruck = useCallback(() => {
    if (handlePurchaseClick()) {
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'add_to_cart_catalog',
          user_type: getUserType(user.email),
          branch_id: branch.id,
          customer_code: user?.customer?.erpCustomerCode,
          address_code: erpCustomerAddressCode,
          address_id: selectedAddressId,
        }
      });
      let price = 0;
      if (size(productPrices) > 0) {
        if (productPrices.length > 1) {
          if (topInputValue >= productPrices[1].qty) {
            price = productPrices[1][priceType];
          } else {
            price = productPrices[0][priceType];
          }
        } else {
          price = productPrices[0][priceType];
        }
      }
      const { itemCategoryName, itemCategory2Name } = getDefaultCategoryName(productVar.category.id, categories);
      clearGtmEcommerce();
      TagManager.dataLayer({
        dataLayer: {
          event: 'add_to_cart',
          ecommerce: {
            items: [
              {
                item_id: productVar.erpSku,
                item_name: getDefaultProductName(productVar),
                item_category2: itemCategory2Name,
                item_category: itemCategoryName,
                quantity: topInputValue,
                price,
              }
            ]
          }
        }
      })
      dispatch(setAddToTruckItem(productVar, topInputValue));
      handleChangeTableView();
    }
  }, [branch?.id, categories, dispatch, erpCustomerAddressCode, handleChangeTableView, handlePurchaseClick, priceType, productPrices, productVar, selectedAddressId, topInputValue, user?.customer?.erpCustomerCode, user.email]);

  const handleClickProductStockLeadTime = useCallback(async () => {
    clearGtmEcommerce();
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_inventory_request',
        user_type: getUserType(user?.email),
        branch_id: branch.id,
        customer_code: user?.customer?.erpCustomerCode,
        address_code: erpCustomerAddressCode,
        address_id: selectedAddressId,
      }
    });
    setEmailType(NearbyBranchEmailType.HUB_BRANCH_WITH_INVENTORY_AVAILABLE);
    setFromBranchId(productVar.newInventory.branchId);
    dispatch(setClickedProductRequestNearbyHubsAndBranch(productVar));
    dispatch(setOpenPopupInquireNow(true));
  }, [user, branch?.id, erpCustomerAddressCode, selectedAddressId, setEmailType, setFromBranchId, productVar, dispatch]);

  return (
    <ProductCardGrid key={productVar.name} isFeatured={isFeatured} className="product-card-grid-3">
      <ProductCard className="product-card-4">
        <StyledCloseIcon onClick={handleChangeTableView} />
        <StyledLink
          to={{
            pathname: `/product/${productVar.code}`,
            state: {
              subcategoryName: categoryName,
              subcategoryCode: code,
              mainCategoryName,
              mainCategoryCode: id,
              variantCode: productVar.code,
              variantId: productVar.id,
            },
          }}
        >
          <>
            <AvatarWrapperGrid item>
              {productVar.image ? (
                <AvatarWrapper>
                  <img src={productVar.image} alt='product avatar' />
                </AvatarWrapper>
              ) : (
                <DefaultAvatarWrapper>
                  <img src={defaultProduct} alt='default product avatar' />
                </DefaultAvatarWrapper>
              )}
            </AvatarWrapperGrid>

            <StyledProductNameGrid item>
              <ProductName>{productVar.name}</ProductName>
            </StyledProductNameGrid>
          </>
        </StyledLink>
        <div>
          <Grid item>
            {!productVar.inventory.isStocked && productVar.newInventory?.onHand > 0 ? (
              <StyledAvailableAndLeadTime onClick={handleClickProductStockLeadTime}>
                {t('products_and_search.available_and_lead_time', {
                  onHand: productVar.newInventory.onHand,
                  leadTime: productVar.newInventory.leadTime,
                }).toString()}
              </StyledAvailableAndLeadTime>
            ) : renderProductStock(productVar)}
          </Grid>
          <Grid item>
            {productPrices.length &&
              currentPrice.length &&
              (currentPrice[currentPrice.length - 1][priceType] || !isHidePrice) ? (
              <>
                {!isHidePrice ? (
                  <div>
                    <Price>${renderPriceForEach()}</Price>
                    <Each>/{productVar.units.perName}</Each>
                  </div>
                ) : null}
              </>
            ) : null}
          </Grid>
          <StyledInputsGrid item>
            {currentPrice.length &&
              (currentPrice[currentPrice.length - 1][priceType] || !isHidePrice) ? (
              <ProductMultiInput
                product={productVar}
                productPrices={productPrices}
                topInputValue={topInputValue}
                bottomInputValue={bottomInputValue}
                setTopInputValue={setTopInputValue}
                setBottomInputValue={setBottomInputValue}
                setCurrentPrice={setCurrentPrice}
                messageTop={messageTop ?? null}
                messageBottom={messageBottom ?? null}
                popperPlacement='right'
              />
            ) : null}
          </StyledInputsGrid>
          <Grid item>
            {!isHidePrice ? (
              <div>
                <div>
                  {
                    currentPrice.length > 0 ? (
                      <Total>
                        {t('products_and_search.total').toString()}: ${numberFormat(totalPrice)}
                      </Total>
                    ) : null
                  }
                  <AddButton onClick={addToTruck}>
                    <WhiteTruck />
                    <AddButtonTitle>{t('products_and_search.add_to_truck').toString()}</AddButtonTitle>
                  </AddButton>
                  <RequestPricingWrapper style={{ width: '100%' }}>
                    <RequestPricingButton onClick={handleRequestPricingClick} style={{ width: '100%', justifyContent: 'center', marginTop: '10px' }}>
                      <RequestPricing>{t('products_and_search.request_pricing').toString()}</RequestPricing>
                    </RequestPricingButton>
                  </RequestPricingWrapper>
                </div>
              </div>
            ) : null}
          </Grid>
        </div>
      </ProductCard>
      {isRequestPricing ? (
        <RequestPricingPopup setRequestPricing={setIsRequestPricing} product={productVar} />
      ) : null}
    </ProductCardGrid>
  );
};

export default ProductTableHoveredCard;
