import React from 'react';

export const Track = () => {
  return (
    <svg
      width="171"
      height="43"
      viewBox="0 0 171 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 40.8867L171 40.8867V39.2512L0 39.2512V40.8867Z" fill="#DDDDDD" />
      <path
        d="M109.629 31.0332C108.967 31.5422 108.424 32.204 108.051 32.9675H106.846C106.473 32.204 105.93 31.5422 105.268 31.0332H109.629Z"
        fill="#EFEFEF"
      />
      <path
        d="M111.648 30.6094C110.952 31.1523 110.392 31.831 110.002 32.6285H108.729C108.339 31.831 107.779 31.1354 107.083 30.6094H111.648Z"
        fill="#DDDDDD"
      />
      <path d="M55.7147 39.4492H61.3818V35.0886H55.7147V39.4492Z" fill="#EFEFEF" />
      <path d="M55.7147 34.8906H61.3818V30.53H55.7147V34.8906Z" fill="#EFEFEF" />
      <path d="M55.7147 30.3281H61.3818V25.9674H55.7147V30.3281Z" fill="#EFEFEF" />
      <path d="M55.7147 25.752H61.3818V21.3913H55.7147V25.752Z" fill="#EFEFEF" />
      <path d="M55.7147 21.1914H61.3818V16.8308H55.7147V21.1914Z" fill="#EFEFEF" />
      <path d="M55.7147 16.6309H61.3818V12.2702H55.7147V16.6309Z" fill="#EFEFEF" />
      <path d="M55.7147 12.0703H61.3818V7.70968L55.7147 7.70968V12.0703Z" fill="#EFEFEF" />
      <path d="M49.6893 39.4492H55.3564V35.0886H49.6893V39.4492Z" fill="#EFEFEF" />
      <path d="M49.6893 34.8906H55.3564V30.53H49.6893V34.8906Z" fill="#EFEFEF" />
      <path d="M49.6893 30.3281H55.3564V25.9674H49.6893V30.3281Z" fill="#EFEFEF" />
      <path d="M49.6893 25.752H55.3564V21.3913H49.6893V25.752Z" fill="#EFEFEF" />
      <path d="M49.6893 21.1914H55.3564V16.8308H49.6893V21.1914Z" fill="#EFEFEF" />
      <path d="M49.6893 16.6309H55.3564V12.2702H49.6893V16.6309Z" fill="#EFEFEF" />
      <path d="M49.6893 12.0703H55.3564V7.70968L49.6893 7.70968V12.0703Z" fill="#EFEFEF" />
      <path d="M43.681 39.4492H49.3481V35.0886H43.681V39.4492Z" fill="#EFEFEF" />
      <path d="M43.681 34.8906H49.3481V30.53H43.681V34.8906Z" fill="#EFEFEF" />
      <path d="M43.681 30.3281H49.3481V25.9674H43.681V30.3281Z" fill="#EFEFEF" />
      <path d="M43.681 25.752H49.3481V21.3913H43.681V25.752Z" fill="#EFEFEF" />
      <path d="M43.681 21.1914H49.3481V16.8308H43.681V21.1914Z" fill="#EFEFEF" />
      <path d="M37.6732 39.4492H43.3403V35.0886H37.6732V39.4492Z" fill="#EFEFEF" />
      <path d="M37.6732 34.8906H43.3403V30.53H37.6732V34.8906Z" fill="#EFEFEF" />
      <path d="M37.6732 30.3281H43.3403V25.9674H37.6732V30.3281Z" fill="#EFEFEF" />
      <path d="M31.6478 39.4492H37.3149V35.0886H31.6478V39.4492Z" fill="#EFEFEF" />
      <path d="M31.6478 34.8906H37.3149V30.53H31.6478V34.8906Z" fill="#EFEFEF" />
      <path d="M31.6478 30.3281H37.3149V25.9674H31.6478V30.3281Z" fill="#EFEFEF" />
      <path d="M31.6478 25.752H37.3149V21.3913H31.6478V25.752Z" fill="#EFEFEF" />
      <path d="M25.6395 39.4492H31.3066V35.0886H25.6395V39.4492Z" fill="#EFEFEF" />
      <path d="M25.6395 34.8906H31.3066V30.53H25.6395V34.8906Z" fill="#EFEFEF" />
      <path d="M25.6395 30.3281H31.3066V25.9674H25.6395V30.3281Z" fill="#EFEFEF" />
      <path d="M25.6395 25.752H31.3066V21.3913H25.6395V25.752Z" fill="#EFEFEF" />
      <path d="M19.5209 39.4492H25.188V35.0886H19.5209V39.4492Z" fill="#EFEFEF" />
      <path d="M19.5209 34.8887H25.188V30.528H19.5209V34.8887Z" fill="#EFEFEF" />
      <path d="M19.5209 30.3281H25.188V25.9675H19.5209V30.3281Z" fill="#EFEFEF" />
      <path d="M13.5125 39.4492H19.1797V35.0886H13.5125V39.4492Z" fill="#EFEFEF" />
      <path d="M13.5125 34.8887H19.1797V30.528H13.5125V34.8887Z" fill="#EFEFEF" />
      <path d="M13.5125 30.3281H19.1797V25.9675H13.5125V30.3281Z" fill="#EFEFEF" />
      <path d="M13.5125 25.75H19.1797V21.3894H13.5125V25.75Z" fill="#EFEFEF" />
      <path d="M13.5125 21.1914H19.1797V16.8308H13.5125V21.1914Z" fill="#EFEFEF" />
      <path d="M7.48766 39.4492H13.1548V35.0886H7.48766L7.48766 39.4492Z" fill="#EFEFEF" />
      <path d="M7.48766 34.8887H13.1548V30.528H7.48766L7.48766 34.8887Z" fill="#EFEFEF" />
      <path d="M7.48766 30.3281H13.1548V25.9675H7.48766L7.48766 30.3281Z" fill="#EFEFEF" />
      <path d="M7.48766 25.75H13.1548V21.3894H7.48766L7.48766 25.75Z" fill="#EFEFEF" />
      <path d="M7.48766 21.1914H13.1548V16.8308H7.48766L7.48766 21.1914Z" fill="#EFEFEF" />
      <path d="M1.47934 39.4492H7.14648L7.14648 35.0886H1.47934L1.47934 39.4492Z" fill="#EFEFEF" />
      <path d="M1.47934 34.8887H7.14648L7.14648 30.528H1.47934L1.47934 34.8887Z" fill="#EFEFEF" />
      <path d="M1.47934 30.3281H7.14648L7.14648 25.9675H1.47934L1.47934 30.3281Z" fill="#EFEFEF" />
      <path d="M25.9246 32.6836H26.4336L26.4336 17.345H25.9246L25.9246 32.6836Z" fill="#323B49" />
      <path
        d="M19.3386 27.2318L21.4764 25.0939C21.5782 24.9921 21.714 24.9921 21.8158 25.0939C21.9176 25.1957 21.9176 25.3315 21.8158 25.4333L21.5952 25.6539C21.3916 25.8575 21.3916 26.2138 21.5952 26.4174L20.662 27.3506C20.4584 27.147 20.1021 27.147 19.8985 27.3506L19.6779 27.5712C19.5761 27.673 19.4404 27.673 19.3386 27.5712C19.2368 27.4863 19.2368 27.3336 19.3386 27.2318Z"
        fill="#323B49"
      />
      <path
        d="M21.8608 28.0709L22.3047 27.627L21.3449 26.6671L20.901 27.111L21.8608 28.0709Z"
        fill="#6B7787"
      />
      <path
        d="M14.7743 25.0262V28.0464H17.4551C18.0999 28.0464 18.6089 28.5724 18.6089 29.2002H13.6035V23.8555C14.2483 23.8724 14.7743 24.3815 14.7743 25.0262Z"
        fill="#6B7787"
      />
      <path
        d="M8.9375 17.3574H18.1848C20.0681 17.3574 21.697 18.6469 22.1212 20.4794L25.4468 34.6982H8.9375V17.3574ZM21.4425 20.6321C21.0862 19.1051 19.7458 18.0531 18.1848 18.0531H10.6851V34.0025H24.5645L21.4425 20.6321Z"
        fill="#6B7787"
      />
      <path d="M20.2768 35.9844H26.436V32.6927H20.2768V35.9844Z" fill="#323B49" />
      <path
        d="M29.536 21.4297V26.1466H38.2743V26.6557L28.7046 27.1647V21.4297H29.536Z"
        fill="#6B7787"
      />
      <path d="M28.2484 27.1699H28.7065V19.4667H28.2484V27.1699Z" fill="#3C4759" />
      <path
        d="M25.1753 17.3574H28.2464V37.4639H26.4479V18.63C25.7522 18.613 25.1753 18.0531 25.1753 17.3574Z"
        fill="#6B7787"
      />
      <path
        d="M5.79871 35.6299H21.1543L19.6951 29.7422H9.73516C7.54636 29.7422 5.78174 31.5068 5.78174 33.6956V35.6299H5.79871Z"
        fill="#337D58"
      />
      <path
        d="M5.25537 38.6506H23.6481V35.4098L8.54706 34.0863C6.78244 33.9166 5.25537 35.308 5.25537 37.0895V38.6506Z"
        fill="#42A071"
      />
      <path
        d="M22.1888 32.7127H24.3776L22.8336 26.5195C21.6967 26.5195 20.8653 27.5885 21.1538 28.6914L22.1888 32.7127Z"
        fill="#42A071"
      />
      <path
        d="M8.53064 30.8965H10.7194C10.8382 30.8965 10.94 30.9983 10.94 31.1171C10.94 31.2358 10.8382 31.3376 10.7194 31.3376H8.53064C8.41186 31.3376 8.31006 31.2358 8.31006 31.1171C8.31006 30.9983 8.41186 30.8965 8.53064 30.8965Z"
        fill="#42A071"
      />
      <path
        d="M8.53015 31.5254H10.719C10.8377 31.5254 10.9395 31.6272 10.9395 31.746C10.9395 31.8647 10.8377 31.9666 10.719 31.9666H8.53015C8.41138 31.9666 8.30957 31.8647 8.30957 31.746C8.30957 31.6272 8.41138 31.5254 8.53015 31.5254Z"
        fill="#42A071"
      />
      <path
        d="M8.53015 32.1543H10.719C10.8377 32.1543 10.9395 32.2561 10.9395 32.3749C10.9395 32.4936 10.8377 32.5954 10.719 32.5954H8.53015C8.41138 32.5954 8.30957 32.4936 8.30957 32.3749C8.30957 32.2561 8.41138 32.1543 8.53015 32.1543Z"
        fill="#42A071"
      />
      <path
        d="M8.53015 32.7812H10.719C10.8377 32.7812 10.9395 32.8831 10.9395 33.0018C10.9395 33.1206 10.8377 33.2224 10.719 33.2224H8.53015C8.41138 33.2224 8.30957 33.1206 8.30957 33.0018C8.30957 32.8831 8.41138 32.7812 8.53015 32.7812Z"
        fill="#42A071"
      />
      <path d="M13.5981 29.7422H18.6035V29.1992H13.5981V29.7422Z" fill="#323B49" />
      <path d="M5.26792 38.6602H23.6606V37.71H5.26792V38.6602Z" fill="#6B7787" />
      <path
        d="M10.1446 39.2829C11.2048 38.2227 11.2048 36.5038 10.1446 35.4436C9.08446 34.3835 7.36556 34.3835 6.30537 35.4436C5.24519 36.5038 5.24519 38.2227 6.30537 39.2829C7.36556 40.3431 9.08446 40.3431 10.1446 39.2829Z"
        fill="#3C4759"
      />
      <path
        d="M8.22468 34.9355C9.56511 34.9355 10.651 36.0215 10.651 37.3619C10.651 38.7023 9.56511 39.7882 8.22468 39.7882C6.88426 39.7882 5.79834 38.7023 5.79834 37.3619C5.79834 36.0215 6.88426 34.9355 8.22468 34.9355Z"
        fill="#6B7787"
      />
      <path
        d="M9.90937 37.7635C10.129 36.8332 9.553 35.9011 8.62278 35.6814C7.69257 35.4618 6.76043 36.0378 6.54078 36.968C6.32113 37.8983 6.89715 38.8304 7.82736 39.0501C8.75757 39.2697 9.68972 38.6937 9.90937 37.7635Z"
        fill="#3C4759"
      />
      <path
        d="M8.2247 38.9738C9.11493 38.9738 9.83661 38.2521 9.83661 37.3619C9.83661 36.4717 9.11493 35.75 8.2247 35.75C7.33447 35.75 6.61279 36.4717 6.61279 37.3619C6.61279 38.2521 7.33447 38.9738 8.2247 38.9738Z"
        fill="#B2B2B2"
      />
      <path
        d="M8.22484 36.6484C8.61509 36.6484 8.93747 36.9708 8.93747 37.3611C8.93747 37.7513 8.61509 38.0737 8.22484 38.0737C7.83459 38.0737 7.51221 37.7513 7.51221 37.3611C7.51221 36.9708 7.83459 36.6484 8.22484 36.6484Z"
        fill="#3C4759"
      />
      <path
        d="M8.22533 36.8535C8.51377 36.8535 8.73434 37.0911 8.73434 37.3625C8.73434 37.634 8.4968 37.8715 8.22533 37.8715C7.93688 37.8715 7.71631 37.634 7.71631 37.3625C7.71631 37.0911 7.93688 36.8535 8.22533 36.8535Z"
        fill="#6B7787"
      />
      <path
        d="M27.7166 37.5919C27.8403 36.0976 26.7292 34.7861 25.2349 34.6624C23.7407 34.5387 22.4291 35.6498 22.3055 37.144C22.1818 38.6383 23.2929 39.9499 24.7871 40.0735C26.2813 40.1972 27.5929 39.0861 27.7166 37.5919Z"
        fill="#3C4759"
      />
      <path
        d="M26.5442 39.2397C27.5805 38.3901 27.7318 36.8613 26.8822 35.8251C26.0326 34.7888 24.5039 34.6375 23.4676 35.4871C22.4314 36.3367 22.28 37.8654 23.1296 38.9017C23.9792 39.9379 25.508 40.0893 26.5442 39.2397Z"
        fill="#6B7787"
      />
      <path
        d="M26.5782 38.1022C26.9837 37.2366 26.6107 36.2062 25.7451 35.8007C24.8795 35.3952 23.8491 35.7683 23.4436 36.6339C23.0382 37.4995 23.4112 38.5299 24.2768 38.9353C25.1424 39.3408 26.1728 38.9678 26.5782 38.1022Z"
        fill="#3C4759"
      />
      <path
        d="M26.6181 37.4882C26.686 36.6005 26.0215 35.8259 25.1338 35.758C24.2462 35.6902 23.4716 36.3547 23.4037 37.2424C23.3358 38.13 24.0003 38.9046 24.888 38.9725C25.7756 39.0404 26.5503 38.3758 26.6181 37.4882Z"
        fill="#B2B2B2"
      />
      <path
        d="M25.0056 36.6484C25.3959 36.6484 25.7182 36.9708 25.7182 37.3611C25.7182 37.7513 25.3959 38.0737 25.0056 38.0737C24.6153 38.0737 24.293 37.7513 24.293 37.3611C24.293 36.9708 24.6153 36.6484 25.0056 36.6484Z"
        fill="#3C4759"
      />
      <path
        d="M25.0056 37.8696C25.2867 37.8696 25.5146 37.6417 25.5146 37.3606C25.5146 37.0795 25.2867 36.8516 25.0056 36.8516C24.7245 36.8516 24.4966 37.0795 24.4966 37.3606C24.4966 37.6417 24.7245 37.8696 25.0056 37.8696Z"
        fill="#6B7787"
      />
      <path d="M29.5037 26.1387H41.7373V25.6636H29.5037V26.1387Z" fill="#42A071" />
      <path d="M29.5037 27.6953H41.7373V27.2202H29.5037V27.6953Z" fill="#42A071" />
      <path d="M40.9568 27.2305H41.7373V26.1445H40.9568V27.2305Z" fill="#337D58" />
      <path d="M29.5041 27.2305H30.2847V26.1445H29.5041V27.2305Z" fill="#337D58" />
      <path d="M35.239 27.2305H36.0195V26.1445H35.239V27.2305Z" fill="#337D58" />
      <path d="M35.9039 25.6562H41.7407V21.1768H35.9039V25.6562Z" fill="#337D58" />
      <path d="M39.76 22.6719H41.2871V21.6369H39.76V22.6719Z" fill="#E7F9EE" />
      <path d="M39.76 23.043H41.2871V22.8563H39.76V23.043Z" fill="#E7F9EE" />
      <path d="M39.76 23.3125H41.2871V23.1259H39.76V23.3125Z" fill="#E7F9EE" />
      <path d="M29.5011 25.6562H35.3379V21.1768H29.5011V25.6562Z" fill="#337D58" />
      <path d="M33.3572 22.6719H34.8843V21.6369H33.3572V22.6719Z" fill="#E7F9EE" />
      <path d="M33.3572 23.043H34.8843V22.8563H33.3572V23.043Z" fill="#E7F9EE" />
      <path d="M33.3572 23.3125H34.8843V23.1259H33.3572V23.3125Z" fill="#E7F9EE" />
      <path d="M35.9039 20.9902H41.7407V16.5108H35.9039V20.9902Z" fill="#337D58" />
      <path d="M39.76 18.0215H41.2871V16.9865H39.76V18.0215Z" fill="#E7F9EE" />
      <path d="M39.76 18.377H41.2871V18.1903H39.76V18.377Z" fill="#E7F9EE" />
      <path d="M39.76 18.6465H41.2871V18.4598H39.76V18.6465Z" fill="#E7F9EE" />
      <path d="M29.5011 20.9902H35.3379V16.5108H29.5011V20.9902Z" fill="#337D58" />
      <path d="M33.3577 18.0215H34.8848V16.9865H33.3577V18.0215Z" fill="#E7F9EE" />
      <path d="M33.3577 18.377H34.8848V18.1903H33.3577V18.377Z" fill="#E7F9EE" />
      <path d="M33.3577 18.6465H34.8848V18.4598H33.3577V18.6465Z" fill="#E7F9EE" />
      <path
        d="M18.711 25.6189C19.4406 26.128 20.9677 25.6698 20.9677 25.6698L20.6283 24.8384C20.6283 24.8384 19.5424 25.076 19.1861 24.8554C18.4056 24.3803 18.3716 23.4301 18.3716 23.4132C18.3547 23.1586 18.1341 22.972 17.8796 23.0059C17.6251 23.0229 17.4554 23.2435 17.4724 23.498C17.4724 23.5659 17.5911 24.8384 18.711 25.6189Z"
        fill="#337D58"
      />
      <path d="M18.3208 23.6L18.4735 23.1758L18.3208 23.6Z" fill="#F1AC70" />
      <path
        d="M16.6054 21.9028L16.5332 23.3262L17.5669 23.3786L17.6391 21.9552L16.6054 21.9028Z"
        fill="#CE8B5E"
      />
      <path
        d="M18.5074 27.5361V23.396C18.5074 23.0906 18.2699 22.8361 17.9644 22.8192L16.2507 22.7683C15.8605 22.7513 15.5551 23.0567 15.5381 23.447V27.5192H18.5074V27.5361Z"
        fill="#42A071"
      />
      <path
        d="M18.6261 20.6142C18.677 19.4604 17.9983 19.0871 17.1499 19.0532C16.3016 19.0192 15.6059 19.3416 15.555 20.4954C15.5211 21.6492 16.1658 22.6163 17.0142 22.6503C17.8626 22.6842 18.5922 21.768 18.6261 20.6142Z"
        fill="#FAC48D"
      />
      <path
        d="M17.1157 20.953C17.1157 20.8342 17.0479 20.7324 16.946 20.7324C16.8612 20.7324 16.7764 20.8173 16.7764 20.936C16.7764 21.0548 16.8442 21.1566 16.946 21.1566C17.0309 21.1566 17.1157 21.0718 17.1157 20.953Z"
        fill="#030404"
      />
      <path
        d="M18.151 20.953C18.151 20.8342 18.0831 20.7324 17.9983 20.7324C17.8965 20.7324 17.8286 20.8173 17.8286 20.936C17.8286 21.0548 17.8965 21.1566 17.9813 21.1566C18.0662 21.1566 18.151 21.0718 18.151 20.953Z"
        fill="#030404"
      />
      <path
        d="M17.6248 20.3091C18.4053 20.377 18.6429 20.5467 18.6429 20.5467C18.6429 20.5467 19.0162 18.5445 17.014 18.6633C15.0288 18.7651 15.5548 20.7333 15.5548 20.7333C15.5548 20.7333 16.9122 20.2413 17.6248 20.3091Z"
        fill="#42A071"
      />
      <path
        d="M17.6079 20.2915C18.2866 20.3424 18.5581 20.4951 18.6259 20.529L18.6429 20.512C18.9144 20.3424 19.084 20.1048 19.084 19.8503C19.084 19.4601 18.8465 19.2395 18.3884 19.1037C17.1498 18.7474 16.1826 20.003 16.1826 20.512C16.6238 20.3933 17.2007 20.2575 17.6079 20.2915Z"
        fill="#337D58"
      />
      <path
        d="M16.4715 23.6853C16.4715 23.4308 16.2679 23.2441 16.0134 23.2441C15.7589 23.2441 15.5723 23.4478 15.5723 23.7023C15.5723 23.7701 15.5892 25.0427 16.6582 25.908C17.3539 26.468 18.9149 26.1286 18.9149 26.1286L18.6434 25.2633C18.6434 25.2633 17.5405 25.433 17.2011 25.1784C16.4376 24.6524 16.4715 23.7023 16.4715 23.6853Z"
        fill="#337D58"
      />
      <path
        d="M15.5723 27.5352H19.0845L20.7304 29.9276L20.0178 30.5554L18.5246 28.672H16.5733C16.0134 28.672 15.5723 28.2139 15.5723 27.6709V27.5352Z"
        fill="#337D58"
      />
      <path
        d="M130.94 7.24411C135.911 7.49863 148.993 8.19429 150.486 8.73725C152.336 9.39899 154.168 11.2315 154.168 11.2315L162.601 21.4459C162.601 21.4459 168.625 23.8044 169.575 24.2795C170.372 24.6697 170.372 25.3993 170.372 25.3993V35.5629C170.372 36.0549 169.965 36.4621 169.473 36.4621H129.04V9.04267C129.04 8.00765 129.905 7.17624 130.94 7.24411Z"
        fill="#42A071"
      />
      <path
        d="M146.397 13.7936V30.252C146.397 30.8289 146.873 31.304 147.449 31.304H153.592L153.626 31.2361C154.796 29.1491 156.815 27.7917 159.174 27.5203C159.7 27.4524 160.107 27.0112 160.107 26.4683V21.8192L154.983 13.1997C154.813 12.9113 154.491 12.7246 154.135 12.7246H147.449C146.873 12.7416 146.397 13.2167 146.397 13.7936ZM146.669 13.7936C146.669 13.3524 147.025 12.9961 147.466 12.9961H154.152C154.406 12.9961 154.644 13.1318 154.762 13.3524L159.853 21.904V26.4852C159.853 26.8925 159.547 27.2148 159.157 27.2657C156.748 27.5542 154.678 28.9286 153.456 31.0495H147.466C147.025 31.0495 146.669 30.6932 146.669 30.252V13.7936Z"
        fill="#337D58"
      />
      <path
        d="M149.57 13.6582H154.118C154.287 13.6582 154.44 13.743 154.525 13.8957L158.36 20.7336C158.529 21.0391 158.309 21.4293 157.952 21.4293H149.57C148.909 21.4293 148.366 20.8864 148.366 20.2246V14.8629C148.366 14.2012 148.909 13.6582 149.57 13.6582Z"
        fill="white"
      />
      <path
        d="M149.57 13.6582H154.118C154.287 13.6582 154.44 13.743 154.525 13.8957L158.36 20.7336C158.529 21.0391 158.309 21.4293 157.952 21.4293H149.57C148.909 21.4293 148.366 20.8864 148.366 20.2246V14.8629C148.366 14.2012 148.909 13.6582 149.57 13.6582Z"
        fill="#7AC5FC"
        fillOpacity="0.2"
      />
      <path
        d="M155.56 13.6582H156.188L162.602 21.4293H160.616C160.158 21.4293 159.734 21.1918 159.496 20.7845L155.407 13.9127C155.339 13.8109 155.424 13.6582 155.56 13.6582Z"
        fill="#E4F3FE"
      />
      <path
        d="M147.687 22.584H148.417C148.603 22.584 148.756 22.7367 148.756 22.9233V24.1789C148.756 24.3656 148.603 24.5183 148.417 24.5183H147.687C147.5 24.5183 147.348 24.3656 147.348 24.1789V22.9064C147.348 22.7197 147.5 22.584 147.687 22.584Z"
        fill="#6B7787"
      />
      <path
        d="M170.372 29.9466H169.337L168.998 25.8574H170.372C170.372 25.8744 171.628 27.7917 170.372 29.9466Z"
        fill="#DAD9D9"
      />
      <path
        d="M168.998 25.875L169.337 29.9642C168.828 29.9642 168.387 29.6079 168.302 29.0988L167.946 27.1306C167.827 26.4689 168.319 25.875 168.998 25.875Z"
        fill="#FC861D"
      />
      <path
        d="M157.901 14.93V21.7679H157.63V14.93C157.63 14.0986 156.951 13.4199 156.12 13.4199H154.864V13.1484H156.12C157.104 13.1484 157.901 13.9459 157.901 14.93Z"
        fill="#6B7787"
      />
      <path
        d="M156.883 15.0145L157.901 14.9297V19.986L156.765 19.7485C156.442 19.6636 156.205 19.3752 156.205 19.0358V15.7611C156.222 15.3708 156.493 15.0654 156.883 15.0145Z"
        fill="#6B7787"
      />
      <path
        d="M135.47 7.48181C134.91 9.09372 132.246 17.6453 132.162 32.3052H131.89C131.975 17.815 134.588 9.2634 135.199 7.46484L135.47 7.48181Z"
        fill="#337D58"
      />
      <path
        d="M157.765 21.5312C157.901 21.5312 158.003 21.6331 158.003 21.7688C158.003 21.9045 157.901 22.0063 157.765 22.0063C157.63 22.0063 157.528 21.9045 157.528 21.7688C157.545 21.6331 157.647 21.5312 157.765 21.5312Z"
        fill="#6B7787"
      />
      <path
        d="M154.864 13.0645C155 13.0645 155.102 13.1663 155.102 13.302C155.102 13.4377 155 13.5395 154.864 13.5395C154.728 13.5395 154.626 13.4377 154.626 13.302C154.643 13.1663 154.745 13.0645 154.864 13.0645Z"
        fill="#6B7787"
      />
      <path
        d="M142.953 8.24665V30.6607H141.901V8.43329V8.21271C141.935 7.07589 141.053 6.10875 139.899 6.05784L139.933 5.00586C141.664 5.07373 143.004 6.53293 142.953 8.24665Z"
        fill="#99BDAB"
      />
      <path
        d="M170.236 32.3066C170.61 32.3066 170.898 32.5612 170.847 32.8496L170.338 35.6832C170.253 36.1243 169.761 36.4637 169.167 36.4637H124C124.119 35.9716 124.187 35.4456 124.187 34.9196C124.187 33.9864 123.983 33.1211 123.644 32.3236H170.236V32.3066Z"
        fill="#3C4759"
      />
      <path
        d="M124.187 34.902C124.187 35.428 124.119 35.954 124.001 36.4461H112.412H111.546H105.184V32.2891H123.644C123.984 33.1035 124.187 33.9688 124.187 34.902Z"
        fill="#1F252D"
      />
      <path
        d="M133.621 30.6602H145.345C146.007 30.6602 146.533 31.1861 146.533 31.8479V36.463C146.533 36.6836 146.346 36.8533 146.143 36.8533H132.84C132.62 36.8533 132.45 36.6666 132.45 36.463V31.8479C132.433 31.1861 132.976 30.6602 133.621 30.6602Z"
        fill="#323B49"
      />
      <path d="M144.429 31.3047H143.326V37.0228H144.429V31.3047Z" fill="#3C4759" />
      <path d="M135.657 31.3047H134.554V37.0228H135.657V31.3047Z" fill="#3C4759" />
      <path d="M125.952 0.865234H44.1514V27.606H125.952V0.865234Z" fill="#CFE2D7" />
      <path
        d="M126.817 0H43.269V28.4884H126.817V0ZM125.951 27.6061H44.1513V0.865341H125.951V27.6061Z"
        fill="#99BDAB"
      />
      <path d="M123.05 3.12109H121.812V25.5182H123.05V3.12109Z" fill="#E7F9EE" />
      <path d="M118.911 3.12109H117.672V25.5182H118.911V3.12109Z" fill="#E7F9EE" />
      <path d="M114.753 3.12109H113.515V25.5182H114.753V3.12109Z" fill="#E7F9EE" />
      <path d="M110.613 3.12109H109.375V25.5182H110.613V3.12109Z" fill="#E7F9EE" />
      <path d="M106.456 3.12109H105.217V25.5182H106.456V3.12109Z" fill="#E7F9EE" />
      <path d="M102.299 3.12109H101.061V25.5182H102.299V3.12109Z" fill="#E7F9EE" />
      <path d="M98.159 3.12109H96.9204V25.5182H98.159V3.12109Z" fill="#E7F9EE" />
      <path d="M94.0018 3.12109H92.7632V25.5182H94.0018V3.12109Z" fill="#E7F9EE" />
      <path d="M89.8616 3.12109H88.623V25.5182H89.8616V3.12109Z" fill="#E7F9EE" />
      <path d="M85.7049 3.12109H84.4663V25.5182H85.7049V3.12109Z" fill="#E7F9EE" />
      <path d="M81.5477 3.12109H80.3091V25.5182H81.5477V3.12109Z" fill="#E7F9EE" />
      <path d="M85.6366 3.12109H84.3979V25.5182H85.6366V3.12109Z" fill="#E7F9EE" />
      <path d="M81.4798 3.12109H80.2412V25.5182H81.4798V3.12109Z" fill="#E7F9EE" />
      <path d="M77.3397 3.12109H76.1011V25.5182H77.3397V3.12109Z" fill="#E7F9EE" />
      <path d="M73.183 3.12109H71.9443V25.5182H73.183V3.12109Z" fill="#E7F9EE" />
      <path d="M69.0424 3.12109H67.8037V25.5182H69.0424V3.12109Z" fill="#E7F9EE" />
      <path d="M64.8856 3.12109H63.647V25.5182H64.8856V3.12109Z" fill="#E7F9EE" />
      <path d="M60.7284 3.12109H59.4897V25.5182H60.7284V3.12109Z" fill="#E7F9EE" />
      <path d="M56.5882 3.12109H55.3496V25.5182H56.5882V3.12109Z" fill="#E7F9EE" />
      <path d="M52.4315 3.12109H51.1929V25.5182H52.4315V3.12109Z" fill="#E7F9EE" />
      <path d="M48.2743 3.12109H47.0356V25.5182H48.2743V3.12109Z" fill="#E7F9EE" />
      <path d="M123.83 28.4883H100.127V30.1511H123.83V28.4883Z" fill="#3C4759" />
      <path
        d="M100.161 29.7269H99.2276L96.92 31.8648H84.6016V29.7269H80.258L77.3226 32.3908H47.0356V28.4883H100.161V29.7269Z"
        fill="#3C4759"
      />
      <path
        d="M160.056 28.4883C163.602 28.4883 166.469 31.3558 166.469 34.902C166.469 35.428 166.402 35.954 166.283 36.446H153.829C153.71 35.954 153.642 35.428 153.642 34.902C153.642 31.3558 156.51 28.4883 160.056 28.4883Z"
        fill="#1F252D"
      />
      <path
        d="M165.011 36.3881C165.833 33.6506 164.28 30.7653 161.542 29.9436C158.805 29.1219 155.919 30.6749 155.098 33.4124C154.276 36.15 155.829 39.0353 158.567 39.857C161.304 40.6787 164.189 39.1256 165.011 36.3881Z"
        fill="#3C4759"
      />
      <path
        d="M164.635 35.7339C165.096 33.2079 163.422 30.7865 160.896 30.3256C158.37 29.8647 155.948 31.5389 155.487 34.0649C155.026 36.5909 156.701 39.0123 159.227 39.4732C161.753 39.9341 164.174 38.2599 164.635 35.7339Z"
        fill="#6B7787"
      />
      <path
        d="M160.056 31.5918C161.872 31.5918 163.365 33.068 163.365 34.9005C163.365 36.716 161.889 38.2091 160.056 38.2091C158.241 38.2091 156.748 36.733 156.748 34.9005C156.765 33.0849 158.241 31.5918 160.056 31.5918Z"
        fill="#3C4759"
      />
      <path
        d="M160.056 31.8301C161.753 31.8301 163.128 33.2044 163.128 34.9012C163.128 36.5979 161.753 37.9723 160.056 37.9723C158.36 37.9723 156.985 36.5979 156.985 34.9012C156.985 33.2044 158.36 31.8301 160.056 31.8301Z"
        fill="#99BDAB"
      />
      <path
        d="M162.143 35.3269C162.377 35.3269 162.567 35.137 162.567 34.9027C162.567 34.6684 162.377 34.4785 162.143 34.4785C161.909 34.4785 161.719 34.6684 161.719 34.9027C161.719 35.137 161.909 35.3269 162.143 35.3269Z"
        fill="#3C4759"
      />
      <path
        d="M157.969 35.3269C158.203 35.3269 158.393 35.137 158.393 34.9027C158.393 34.6684 158.203 34.4785 157.969 34.4785C157.735 34.4785 157.545 34.6684 157.545 34.9027C157.545 35.137 157.735 35.3269 157.969 35.3269Z"
        fill="#3C4759"
      />
      <path
        d="M160.362 32.5091C160.531 32.6787 160.531 32.9333 160.362 33.1029C160.192 33.2726 159.938 33.2726 159.768 33.1029C159.598 32.9333 159.598 32.6787 159.768 32.5091C159.938 32.3564 160.192 32.3564 160.362 32.5091Z"
        fill="#3C4759"
      />
      <path
        d="M160.362 36.6995C160.531 36.8692 160.531 37.1237 160.362 37.2934C160.192 37.4631 159.938 37.4631 159.768 37.2934C159.598 37.1237 159.598 36.8692 159.768 36.6995C159.938 36.5298 160.192 36.5298 160.362 36.6995Z"
        fill="#3C4759"
      />
      <path
        d="M158.58 33.002C158.818 33.002 159.004 33.1886 159.004 33.4261C159.004 33.6637 158.818 33.8503 158.58 33.8503C158.342 33.8503 158.156 33.6637 158.156 33.4261C158.173 33.1886 158.359 33.002 158.58 33.002Z"
        fill="#3C4759"
      />
      <path
        d="M161.532 35.9707C161.77 35.9707 161.956 36.1573 161.956 36.3949C161.956 36.6324 161.77 36.8191 161.532 36.8191C161.295 36.8191 161.108 36.6324 161.108 36.3949C161.125 36.1573 161.312 35.9707 161.532 35.9707Z"
        fill="#3C4759"
      />
      <path
        d="M158.58 35.9707C158.818 35.9707 159.004 36.1573 159.004 36.3949C159.004 36.6324 158.818 36.8191 158.58 36.8191C158.342 36.8191 158.156 36.6324 158.156 36.3949C158.173 36.1573 158.359 35.9707 158.58 35.9707Z"
        fill="#3C4759"
      />
      <path
        d="M161.532 33.002C161.77 33.002 161.956 33.1886 161.956 33.4261C161.956 33.6637 161.77 33.8503 161.532 33.8503C161.295 33.8503 161.108 33.6637 161.108 33.4261C161.125 33.1886 161.312 33.002 161.532 33.002Z"
        fill="#3C4759"
      />
      <path
        d="M160.056 34.1035C160.497 34.1035 160.853 34.4598 160.853 34.901C160.853 35.3421 160.497 35.6985 160.056 35.6985C159.615 35.6985 159.258 35.3421 159.258 34.901C159.258 34.4598 159.615 34.1035 160.056 34.1035Z"
        fill="#3C4759"
      />
      <path
        d="M160.056 34.3242C160.378 34.3242 160.633 34.5787 160.633 34.9011C160.633 35.2235 160.378 35.478 160.056 35.478C159.734 35.478 159.479 35.2235 159.479 34.9011C159.479 34.5787 159.734 34.3242 160.056 34.3242Z"
        fill="#6B7787"
      />
      <path
        d="M121.417 38.5675C123.438 36.5465 123.438 33.2699 121.417 31.2489C119.396 29.2279 116.12 29.2279 114.099 31.2489C112.078 33.2699 112.078 36.5465 114.099 38.5675C116.12 40.5885 119.396 40.5885 121.417 38.5675Z"
        fill="#3C4759"
      />
      <path
        d="M122.403 35.2234C122.578 32.6617 120.643 30.4432 118.082 30.2684C115.52 30.0935 113.302 32.0284 113.127 34.5901C112.952 37.1518 114.887 39.3703 117.448 39.5451C120.01 39.72 122.229 37.7851 122.403 35.2234Z"
        fill="#6B7787"
      />
      <path
        d="M121.061 35.1841C121.211 33.363 119.857 31.7649 118.036 31.6147C116.215 31.4645 114.617 32.819 114.466 34.6401C114.316 36.4612 115.671 38.0593 117.492 38.2095C119.313 38.3598 120.911 37.0053 121.061 35.1841Z"
        fill="#3C4759"
      />
      <path
        d="M120.796 35.3924C121.065 33.7178 119.927 32.1415 118.252 31.8717C116.577 31.6018 115.001 32.7406 114.731 34.4152C114.461 36.0897 115.6 37.666 117.275 37.9359C118.949 38.2057 120.526 37.067 120.796 35.3924Z"
        fill="#99BDAB"
      />
      <path
        d="M120.149 34.6136C120.319 34.7833 120.319 35.0378 120.149 35.2074C119.979 35.3771 119.725 35.3771 119.555 35.2074C119.385 35.0378 119.385 34.7833 119.555 34.6136C119.725 34.4439 119.996 34.4439 120.149 34.6136Z"
        fill="#3C4759"
      />
      <path
        d="M115.975 34.6136C116.145 34.7833 116.145 35.0378 115.975 35.2074C115.805 35.3771 115.551 35.3771 115.381 35.2074C115.211 35.0378 115.211 34.7833 115.381 34.6136C115.551 34.4439 115.805 34.4439 115.975 34.6136Z"
        fill="#3C4759"
      />
      <path
        d="M118.062 32.5091C118.232 32.6787 118.232 32.9333 118.062 33.1029C117.892 33.2726 117.638 33.2726 117.468 33.1029C117.298 32.9333 117.298 32.6787 117.468 32.5091C117.638 32.3564 117.892 32.3564 118.062 32.5091Z"
        fill="#3C4759"
      />
      <path
        d="M118.062 36.6995C118.232 36.8692 118.232 37.1237 118.062 37.2934C117.892 37.4631 117.638 37.4631 117.468 37.2934C117.298 37.1237 117.298 36.8692 117.468 36.6995C117.638 36.5298 117.892 36.5298 118.062 36.6995Z"
        fill="#3C4759"
      />
      <path
        d="M116.28 33.002C116.518 33.002 116.704 33.1886 116.704 33.4261C116.704 33.6637 116.518 33.8503 116.28 33.8503C116.043 33.8503 115.856 33.6637 115.856 33.4261C115.873 33.1886 116.06 33.002 116.28 33.002Z"
        fill="#3C4759"
      />
      <path
        d="M119.25 35.9707C119.487 35.9707 119.674 36.1573 119.674 36.3949C119.674 36.6324 119.487 36.8191 119.25 36.8191C119.012 36.8191 118.826 36.6324 118.826 36.3949C118.826 36.1573 119.012 35.9707 119.25 35.9707Z"
        fill="#3C4759"
      />
      <path
        d="M116.28 35.9707C116.518 35.9707 116.704 36.1573 116.704 36.3949C116.704 36.6324 116.518 36.8191 116.28 36.8191C116.043 36.8191 115.856 36.6324 115.856 36.3949C115.873 36.1573 116.06 35.9707 116.28 35.9707Z"
        fill="#3C4759"
      />
      <path
        d="M119.25 33.002C119.487 33.002 119.674 33.1886 119.674 33.4261C119.674 33.6637 119.487 33.8503 119.25 33.8503C119.012 33.8503 118.826 33.6637 118.826 33.4261C118.826 33.1886 119.012 33.002 119.25 33.002Z"
        fill="#3C4759"
      />
      <path
        d="M117.756 34.1035C118.198 34.1035 118.554 34.4598 118.554 34.901C118.554 35.3421 118.198 35.6985 117.756 35.6985C117.315 35.6985 116.959 35.3421 116.959 34.901C116.959 34.4598 117.315 34.1035 117.756 34.1035Z"
        fill="#3C4759"
      />
      <path
        d="M117.757 34.3242C118.079 34.3242 118.334 34.5787 118.334 34.9011C118.334 35.2235 118.079 35.478 117.757 35.478C117.434 35.478 117.18 35.2235 117.18 34.9011C117.18 34.5787 117.451 34.3242 117.757 34.3242Z"
        fill="#6B7787"
      />
      <path
        d="M117.756 28.8945C120.674 28.8945 123.118 30.9985 123.644 33.7642H122.829C122.303 31.4396 120.233 29.675 117.739 29.675C115.262 29.675 113.175 31.4227 112.649 33.7642H111.834C112.394 30.9985 114.838 28.8945 117.756 28.8945Z"
        fill="#99BDAB"
      />
      <path
        d="M105.591 40.0767C108.449 40.0767 110.766 37.7598 110.766 34.9016C110.766 32.0435 108.449 29.7266 105.591 29.7266C102.733 29.7266 100.416 32.0435 100.416 34.9016C100.416 37.7598 102.733 40.0767 105.591 40.0767Z"
        fill="#3C4759"
      />
      <path
        d="M110.123 35.9569C110.707 33.4563 109.152 30.9565 106.652 30.3734C104.151 29.7902 101.651 31.3447 101.068 33.8453C100.485 36.3459 102.039 38.8457 104.54 39.4289C107.041 40.012 109.54 38.4575 110.123 35.9569Z"
        fill="#6B7787"
      />
      <path
        d="M106.096 38.1722C107.901 37.8905 109.137 36.1985 108.855 34.3931C108.573 32.5877 106.881 31.3526 105.076 31.6343C103.27 31.9161 102.035 33.6081 102.317 35.4135C102.599 37.2189 104.291 38.454 106.096 38.1722Z"
        fill="#3C4759"
      />
      <path
        d="M105.591 37.9723C107.287 37.9723 108.662 36.5973 108.662 34.9012C108.662 33.2051 107.287 31.8301 105.591 31.8301C103.895 31.8301 102.52 33.2051 102.52 34.9012C102.52 36.5973 103.895 37.9723 105.591 37.9723Z"
        fill="#99BDAB"
      />
      <path
        d="M107.983 34.6136C108.153 34.7833 108.153 35.0378 107.983 35.2075C107.813 35.3771 107.559 35.3771 107.389 35.2075C107.219 35.0378 107.219 34.7833 107.389 34.6136C107.559 34.4439 107.813 34.4439 107.983 34.6136Z"
        fill="#3C4759"
      />
      <path
        d="M103.792 34.6136C103.962 34.7833 103.962 35.0378 103.792 35.2075C103.622 35.3771 103.368 35.3771 103.198 35.2075C103.028 35.0378 103.028 34.7833 103.198 34.6136C103.368 34.4439 103.639 34.4439 103.792 34.6136Z"
        fill="#3C4759"
      />
      <path
        d="M105.896 32.5091C106.066 32.6787 106.066 32.9333 105.896 33.1029C105.726 33.2726 105.472 33.2726 105.302 33.1029C105.132 32.9333 105.132 32.6787 105.302 32.5091C105.455 32.3564 105.726 32.3564 105.896 32.5091Z"
        fill="#3C4759"
      />
      <path
        d="M105.896 36.6995C106.066 36.8692 106.066 37.1237 105.896 37.2934C105.726 37.4631 105.472 37.4631 105.302 37.2934C105.132 37.1237 105.132 36.8692 105.302 36.6995C105.455 36.5298 105.726 36.5298 105.896 36.6995Z"
        fill="#3C4759"
      />
      <path
        d="M104.115 33.002C104.352 33.002 104.539 33.1886 104.539 33.4261C104.539 33.6637 104.352 33.8503 104.115 33.8503C103.877 33.8503 103.69 33.6637 103.69 33.4261C103.707 33.1886 103.894 33.002 104.115 33.002Z"
        fill="#3C4759"
      />
      <path
        d="M107.067 35.9707C107.304 35.9707 107.491 36.1573 107.491 36.3949C107.491 36.6324 107.304 36.8191 107.067 36.8191C106.829 36.8191 106.643 36.6324 106.643 36.3949C106.66 36.1573 106.846 35.9707 107.067 35.9707Z"
        fill="#3C4759"
      />
      <path
        d="M104.115 35.9707C104.352 35.9707 104.539 36.1573 104.539 36.3949C104.539 36.6324 104.352 36.8191 104.115 36.8191C103.877 36.8191 103.69 36.6324 103.69 36.3949C103.707 36.1573 103.894 35.9707 104.115 35.9707Z"
        fill="#3C4759"
      />
      <path
        d="M107.067 33.002C107.304 33.002 107.491 33.1886 107.491 33.4261C107.491 33.6637 107.304 33.8503 107.067 33.8503C106.829 33.8503 106.643 33.6637 106.643 33.4261C106.66 33.1886 106.846 33.002 107.067 33.002Z"
        fill="#3C4759"
      />
      <path
        d="M105.59 34.1035C106.032 34.1035 106.388 34.4598 106.388 34.901C106.388 35.3421 106.032 35.6984 105.59 35.6984C105.149 35.6984 104.793 35.3421 104.793 34.901C104.793 34.4598 105.149 34.1035 105.59 34.1035Z"
        fill="#3C4759"
      />
      <path
        d="M105.591 34.3242C105.913 34.3242 106.167 34.5787 106.167 34.9011C106.167 35.2235 105.913 35.478 105.591 35.478C105.268 35.478 105.014 35.2235 105.014 34.9011C105.014 34.5787 105.268 34.3242 105.591 34.3242Z"
        fill="#6B7787"
      />
      <path
        d="M105.591 28.8945C108.509 28.8945 110.952 30.9985 111.478 33.7642H110.664C110.138 31.4396 108.068 29.675 105.574 29.675C103.096 29.675 101.009 31.4227 100.483 33.7642H99.6689C100.229 30.9985 102.672 28.8945 105.591 28.8945Z"
        fill="#99BDAB"
      />
      <path
        d="M71.9506 35.523C72.2973 32.686 70.2785 30.1052 67.4416 29.7585C64.6046 29.4118 62.0237 31.4305 61.6771 34.2675C61.3304 37.1045 63.3491 39.6853 66.1861 40.032C69.0231 40.3787 71.6039 38.36 71.9506 35.523Z"
        fill="#3C4759"
      />
      <path
        d="M71.2308 36.3589C72.0388 33.9217 70.7181 31.291 68.2809 30.4829C65.8437 29.6749 63.213 30.9956 62.405 33.4328C61.5969 35.87 62.9176 38.5007 65.3548 39.3088C67.792 40.1168 70.4227 38.7961 71.2308 36.3589Z"
        fill="#6B7787"
      />
      <path
        d="M70.0607 35.5148C70.405 33.7202 69.2292 31.9863 67.4346 31.6421C65.64 31.2978 63.9061 32.4735 63.5618 34.2681C63.2176 36.0628 64.3933 37.7967 66.1879 38.1409C67.9825 38.4852 69.7164 37.3095 70.0607 35.5148Z"
        fill="#3C4759"
      />
      <path
        d="M69.8446 35.3808C70.1155 33.7063 68.9777 32.1294 67.3032 31.8585C65.6288 31.5876 64.0519 32.7254 63.781 34.3998C63.5101 36.0743 64.6479 37.6512 66.3223 37.9221C67.9967 38.193 69.5737 37.0552 69.8446 35.3808Z"
        fill="#99BDAB"
      />
      <path
        d="M69.1952 34.6136C69.3649 34.7833 69.3649 35.0378 69.1952 35.2074C69.0255 35.3771 68.771 35.3771 68.6014 35.2074C68.4317 35.0378 68.4317 34.7833 68.6014 34.6136C68.771 34.4439 69.0425 34.4439 69.1952 34.6136Z"
        fill="#3C4759"
      />
      <path
        d="M64.7152 35.3269C64.9495 35.3269 65.1394 35.137 65.1394 34.9027C65.1394 34.6684 64.9495 34.4785 64.7152 34.4785C64.4809 34.4785 64.291 34.6684 64.291 34.9027C64.291 35.137 64.4809 35.3269 64.7152 35.3269Z"
        fill="#3C4759"
      />
      <path
        d="M67.1078 32.5091C67.2775 32.6787 67.2775 32.9333 67.1078 33.1029C66.9382 33.2726 66.6837 33.2726 66.514 33.1029C66.3443 32.9333 66.3443 32.6787 66.514 32.5091C66.6837 32.3564 66.9382 32.3564 67.1078 32.5091Z"
        fill="#3C4759"
      />
      <path
        d="M67.1078 36.6995C67.2775 36.8692 67.2775 37.1237 67.1078 37.2934C66.9382 37.4631 66.6837 37.4631 66.514 37.2934C66.3443 37.1237 66.3443 36.8692 66.514 36.6995C66.6837 36.5298 66.9382 36.5298 67.1078 36.6995Z"
        fill="#3C4759"
      />
      <path
        d="M65.326 33.002C65.5636 33.002 65.7502 33.1886 65.7502 33.4261C65.7502 33.6637 65.5636 33.8503 65.326 33.8503C65.0885 33.8503 64.9019 33.6637 64.9019 33.4261C64.9188 33.1886 65.1055 33.002 65.326 33.002Z"
        fill="#3C4759"
      />
      <path
        d="M68.2953 35.9707C68.5328 35.9707 68.7195 36.1573 68.7195 36.3949C68.7195 36.6324 68.5328 36.8191 68.2953 36.8191C68.0577 36.8191 67.8711 36.6324 67.8711 36.3949C67.8711 36.1573 68.0577 35.9707 68.2953 35.9707Z"
        fill="#3C4759"
      />
      <path
        d="M65.326 35.9707C65.5636 35.9707 65.7502 36.1573 65.7502 36.3949C65.7502 36.6324 65.5636 36.8191 65.326 36.8191C65.0885 36.8191 64.9019 36.6324 64.9019 36.3949C64.9188 36.1573 65.1055 35.9707 65.326 35.9707Z"
        fill="#3C4759"
      />
      <path
        d="M68.2953 33.002C68.5328 33.002 68.7195 33.1886 68.7195 33.4261C68.7195 33.6637 68.5328 33.8503 68.2953 33.8503C68.0577 33.8503 67.8711 33.6637 67.8711 33.4261C67.8711 33.1886 68.0577 33.002 68.2953 33.002Z"
        fill="#3C4759"
      />
      <path
        d="M66.8195 34.1035C67.2606 34.1035 67.6169 34.4598 67.6169 34.901C67.6169 35.3421 67.2606 35.6985 66.8195 35.6985C66.3783 35.6985 66.022 35.3421 66.022 34.901C66.022 34.4598 66.3783 34.1035 66.8195 34.1035Z"
        fill="#3C4759"
      />
      <path
        d="M66.8198 34.3242C67.1422 34.3242 67.3967 34.5787 67.3967 34.9011C67.3967 35.2235 67.1422 35.478 66.8198 35.478C66.4975 35.478 66.2429 35.2235 66.2429 34.9011C66.226 34.5787 66.4975 34.3242 66.8198 34.3242Z"
        fill="#6B7787"
      />
      <path
        d="M66.8197 28.8945C69.7381 28.8945 72.1814 30.9985 72.7074 33.7642H71.893C71.367 31.4396 69.297 29.675 66.8027 29.675C64.3085 29.675 62.2385 31.4396 61.7295 33.7642H60.915C61.441 30.9985 63.8843 28.8945 66.8197 28.8945Z"
        fill="#99BDAB"
      />
      <path
        d="M58.2972 38.5655C60.3182 36.5446 60.3182 33.2679 58.2972 31.247C56.2762 29.226 52.9996 29.226 50.9786 31.247C48.9577 33.2679 48.9577 36.5446 50.9786 38.5655C52.9996 40.5865 56.2762 40.5865 58.2972 38.5655Z"
        fill="#3C4759"
      />
      <path
        d="M59.2074 35.7836C59.6906 33.262 58.0381 30.826 55.5165 30.3428C52.9948 29.8596 50.5589 31.5121 50.0756 34.0337C49.5924 36.5554 51.2449 38.9913 53.7665 39.4746C56.2882 39.9578 58.7242 38.3053 59.2074 35.7836Z"
        fill="#6B7787"
      />
      <path
        d="M57.8883 35.5266C58.2326 33.732 57.0569 31.998 55.2622 31.6538C53.4676 31.3095 51.7337 32.4852 51.3895 34.2799C51.0452 36.0745 52.2209 37.8084 54.0155 38.1526C55.8101 38.4969 57.5441 37.3212 57.8883 35.5266Z"
        fill="#3C4759"
      />
      <path
        d="M54.6588 37.9773C56.3549 37.966 57.7208 36.5819 57.7095 34.8858C57.6982 33.1896 56.3141 31.8238 54.618 31.8351C52.9219 31.8463 51.556 33.2305 51.5673 34.9266C51.5786 36.6227 52.9627 37.9886 54.6588 37.9773Z"
        fill="#99BDAB"
      />
      <path
        d="M57.0293 34.6136C57.1989 34.7833 57.1989 35.0378 57.0293 35.2075C56.8596 35.3771 56.6051 35.3771 56.4354 35.2075C56.2657 35.0378 56.2657 34.7833 56.4354 34.6136C56.6051 34.4439 56.8596 34.4439 57.0293 34.6136Z"
        fill="#3C4759"
      />
      <path
        d="M52.5501 35.3269C52.7844 35.3269 52.9743 35.137 52.9743 34.9027C52.9743 34.6684 52.7844 34.4785 52.5501 34.4785C52.3159 34.4785 52.126 34.6684 52.126 34.9027C52.126 35.137 52.3159 35.3269 52.5501 35.3269Z"
        fill="#3C4759"
      />
      <path
        d="M54.9428 32.5091C55.1125 32.6787 55.1125 32.9333 54.9428 33.1029C54.7731 33.2726 54.5186 33.2726 54.3489 33.1029C54.1793 32.9333 54.1793 32.6787 54.3489 32.5091C54.5186 32.3564 54.7731 32.3564 54.9428 32.5091Z"
        fill="#3C4759"
      />
      <path
        d="M54.9428 36.6995C55.1125 36.8692 55.1125 37.1237 54.9428 37.2934C54.7731 37.4631 54.5186 37.4631 54.3489 37.2934C54.1793 37.1237 54.1793 36.8692 54.3489 36.6995C54.5186 36.5298 54.7731 36.5298 54.9428 36.6995Z"
        fill="#3C4759"
      />
      <path
        d="M53.161 33.002C53.3986 33.002 53.5852 33.1886 53.5852 33.4261C53.5852 33.6637 53.3986 33.8503 53.161 33.8503C52.9235 33.8503 52.7368 33.6637 52.7368 33.4261C52.7538 33.1886 52.9404 33.002 53.161 33.002Z"
        fill="#3C4759"
      />
      <path
        d="M56.1131 35.9707C56.3507 35.9707 56.5373 36.1573 56.5373 36.3949C56.5373 36.6324 56.3507 36.8191 56.1131 36.8191C55.8756 36.8191 55.689 36.6324 55.689 36.3949C55.7059 36.1573 55.8926 35.9707 56.1131 35.9707Z"
        fill="#3C4759"
      />
      <path
        d="M53.161 35.9707C53.3986 35.9707 53.5852 36.1573 53.5852 36.3949C53.5852 36.6324 53.3986 36.8191 53.161 36.8191C52.9235 36.8191 52.7368 36.6324 52.7368 36.3949C52.7538 36.1573 52.9404 35.9707 53.161 35.9707Z"
        fill="#3C4759"
      />
      <path
        d="M56.1131 33.002C56.3507 33.002 56.5373 33.1886 56.5373 33.4261C56.5373 33.6637 56.3507 33.8503 56.1131 33.8503C55.8756 33.8503 55.689 33.6637 55.689 33.4261C55.7059 33.1886 55.8926 33.002 56.1131 33.002Z"
        fill="#3C4759"
      />
      <path
        d="M54.6368 34.1035C55.078 34.1035 55.4343 34.4598 55.4343 34.901C55.4343 35.3421 55.078 35.6985 54.6368 35.6985C54.1957 35.6985 53.8394 35.3421 53.8394 34.901C53.8394 34.4598 54.1957 34.1035 54.6368 34.1035Z"
        fill="#3C4759"
      />
      <path
        d="M54.637 34.3262C54.9593 34.3262 55.2138 34.5807 55.2138 34.9031C55.2138 35.2254 54.9593 35.48 54.637 35.48C54.3146 35.48 54.0601 35.2254 54.0601 34.9031C54.0601 34.5807 54.3146 34.3262 54.637 34.3262Z"
        fill="#6B7787"
      />
      <path
        d="M54.637 28.8945C57.5554 28.8945 59.9987 30.9985 60.5247 33.7642H59.7103C59.1843 31.4396 57.1142 29.675 54.62 29.675C52.1428 29.675 50.0558 31.4227 49.5298 33.7642H48.7153C49.2753 30.9985 51.7186 28.8945 54.637 28.8945Z"
        fill="#99BDAB"
      />
    </svg>
  );
};
