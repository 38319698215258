import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { FormControl, InputLabel, MenuItem, Select, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ShippingPopUpCross, ShippingPopUpSmallCross } from 'assets/svg/Popups/Cross';
import { UserClass } from 'models/classes/userClass';
import { IAddress } from 'models/interfaces/user';
import { usePopupScrollEffect } from 'hooks/usePopupScrollEffect';
import { PROVINCE_CODES_WITH_NAME } from 'assets/constants/application';

import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from 'utils/common/common';

const CrossButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1024px) {
    width: fit-content;
    position: absolute;
    top: 16px;
    right: 16px;
  }
`;

const CrossButton = styled.button`
  width: fit-content;
  height: fit-content;
  border: none;
  background: none;
  padding: 0;
  outline: none;

  &:hover {
    cursor: pointer;
  }
`;

const PopUpContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 36px;

  @media (max-width: 1024px) {
    padding-right: 0px;
  }
`;

const PopUpHeader = styled.h2`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0.01em;
  margin: 0;

  @media (max-width: 1024px) {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
  }
`;

const PopUpHelperText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.grey};
  margin-top: 17px;
  margin-bottom: 31px;

  @media (max-width: 1024px) {
    margin: 16px 0;
  }
`;

const AddressInfo = styled.div`
  min-height: 321px;
  width: 100%;
  padding: 25px 37px 24px 24px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  background: ${({ theme: { colors } }) => colors.lightGrey};
  border-radius: 4px;

  @media (max-width: 1024px) {
    min-height: 282px;
    padding: 20px 27px 20px 20px;
  }
`;

const AddressName = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.textColor};
  margin-bottom: 15px;
  margin-top: 0;

  @media (max-width: 1024px) {
    margin-bottom: 14px;
    font-size: 16px;
    line-height: 16px;
  }
`;

const AddressProperty = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.textColor};
  margin-bottom: 15px;
  margin-top: 0;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 19px;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  height: 48px;
  border: none;
  background: ${({ theme: { colors } }) => colors.mainGreen};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  outline: none;

  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: ${({ theme: { colors } }) => colors.white};
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    min-height: 48px;
    margin-top: 20px;

    p {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const useStyles = makeStyles({
  select: {
    '&': {
      width: '364px',
      marginTop: '2px',
    },
    '& li': {
      fontSize: '16px',
      fontFamily: 'Open Sans',
      lineHeight: '22px',
      letterSpacing: '0.01em',
      width: '100%',
      whiteSpace: 'normal',
    },
    '@media(max-width: 1024px)': {
      width: '260px',
      '& li': {
        fontSize: '14px',
        fontFamily: 'Open Sans',
        lineHeight: '14px',
        letterSpacing: '0.01em',
        width: '100%',
        whiteSpace: 'normal',
      },
    },
  },
});

const StyledSelect = styled(Select)`
  &.MuiInputBase-root {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
  }

  .MuiOutlinedInput-input.MuiOutlinedInput-input {
    padding-top: 12px;
    padding-bottom: 14px;
    padding-left: 16px;
  }

  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  }

  &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme: { colors } }) => colors.blackOpacity(0.23)};
  }

  @media (max-width: 1024px) {
    &.MuiInputBase-root {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0;
    }
    .MuiOutlinedInput-input.MuiOutlinedInput-input {
      padding-top: 13px;
      padding-bottom: 13px;
      padding-left: 12px;
    }
  }
`;

const StyledFormControl = styled(FormControl)`
  .MuiFormLabel-root.Mui-focused.MuiFormLabel-root.Mui-focused {
    color: ${({ theme: { colors } }) => colors.grey};
  }

  @media (max-width: 1024px) {
    min-height: 50px;
    margin-top: 6px;
  }
`;

interface IShippingAddressProps {
  user: UserClass;
  setShippingOpen: Dispatch<SetStateAction<boolean>>;
  address: IAddress;
  setAddress: Dispatch<SetStateAction<IAddress>>;
}

const ShippingAddressPopup: React.FC<IShippingAddressProps> = ({
  user,
  setShippingOpen,
  address,
  setAddress,
}: IShippingAddressProps) => {
  const { addresses } = user;
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const classes = useStyles();

  usePopupScrollEffect();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newSelectedStore = addresses.find(store => store.id === (event.target.value as number));
    setAddress(newSelectedStore);
  };

  const handleSelectClick = () => {
    setShippingOpen(false);
  };

  const handleCloseClick = () => {
    setAddress(addresses[0]);
    setShippingOpen(false);
  };

  const { t } = useTranslation();
  const firstName = user?.firstName;

  const renderAddresses = () =>
    [1, 2, 3].map(number =>
      address[`address${number}`] ? (
        <AddressProperty key={`address${number}`}>{address[`address${number}`]}</AddressProperty>
      ) : null
    );

  return (
    <>
      <CrossButtonWrapper>
        <CrossButton onClick={handleCloseClick}>
          {isMobile ? <ShippingPopUpSmallCross /> : <ShippingPopUpCross />}
        </CrossButton>
      </CrossButtonWrapper>
      <PopUpContentWrapper>
        <PopUpHeader>{t('shipping_address_popup.welcome', { firstName })}</PopUpHeader>
        <PopUpHelperText>{t('shipping_address_popup.select_store')}</PopUpHelperText>
        <StyledFormControl variant="outlined">
          <InputLabel shrink>{t('shipping_address_popup.default_address')}</InputLabel>
          <StyledSelect
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={address.id}
            label={t('shipping_address_popup.default_address')}
            onChange={handleChange}
            MenuProps={{
              style: {
                zIndex: 1600,
              },
              classes: {
                paper: classes.select,
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            {user &&
              user?.addresses?.map(store => (
                <MenuItem key={store.id} value={store.id}>
                  {`${store.erpCity} ${store.erpPostCode}` }
                </MenuItem>
              ))}
          </StyledSelect>
        </StyledFormControl>
        <AddressInfo>
          <AddressName>{`${address.erpCity} ${address.erpPostCode}`}</AddressName>
          {address.salesRepresentative ? (
            <AddressProperty>{address.salesRepresentative.fullName}</AddressProperty>
          ) : null}
          {renderAddresses()}
          <AddressProperty>
            {address.erpCity}, {PROVINCE_CODES_WITH_NAME[address.provinceCode]}
          </AddressProperty>
          <AddressProperty>{address.postcode}</AddressProperty>
          <AddressProperty>{PROVINCE_CODES_WITH_NAME[address.countryCode]}</AddressProperty>
          {address.salesRepresentative && address.salesRepresentative.workPhone ? (
            <AddressProperty>
              {formatPhoneNumber(address.salesRepresentative.workPhone)}
            </AddressProperty>
          ) : null}
        </AddressInfo>
        <StyledButton onClick={handleSelectClick}>
          <p>{t('shipping_address_popup.button')}</p>
        </StyledButton>
      </PopUpContentWrapper>
    </>
  );
};

export default ShippingAddressPopup;
