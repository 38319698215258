import styled, { keyframes }from 'styled-components/macro';
import { Dialog, Button, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const shake = keyframes`
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
`;

const StylesDialog = styled(Dialog)`
  &.shake-dialog {
    .MuiPaper-root {
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-name: ${shake};
    }
  }
`

const StylesDialogContent = styled(DialogContent)`
  position: relative;
  padding: 36px 48px !important;
  @media only screen and (max-width: 479px) {
    padding: 36px 20px 20px !important;
  }
  .btn-close {
    position: absolute;
    right: 10px;
    top: 16px;
    color: #939599;
    border: 0;
    background: none;
    cursor: pointer;
    transition: all 0.4s ease;
    &:hover {
      color: #333;
    }
  }
  .title-modal {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 22px;
    @media only screen and (max-width: 479px) {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  .box-calculate {
    background: #EBEFF1;
    font-size: 80px;
    line-height: 1.35;
    font-weight: 800;
    color: #000;
    text-align: center;
    pointer-events: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 17px;
    margin-bottom: 16px;
    @media only screen and (max-width: 767px) {
      font-size: 60px;
    }
    @media only screen and (max-width: 599px) {
      font-size: 50px;
    }
    @media only screen and (max-width: 479px) {
      font-size: 40px;
    }
  }
  .title-enter-results {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 16px;
    @media only screen and (max-width: 479px) {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  .textarea {
    width: 100%;
    min-height: 64px;
    font-size: 26px;
    color: #000;
    font-weight: 400;
    border: 1px solid #939599;
    border-radius: 4px;
    resize: none;
    outline: 0;
    padding: 14px 9px;
    margin-bottom: 61px;
    @media only screen and (max-width: 479px) {
      font-size: 20px;
      margin-bottom: 20px;
    }
    &:focus {
      border-color: ${({ theme: { colors } }) => colors.mainGreen};;
    }
    &:-ms-input-placeholder {
      font-size: 16px;
      color: #3C4759;
    }
    &:-moz-placeholder {
      font-size: 16px;
      color: #3C4759;
    }
    &::-moz-placeholder {
      font-size: 16px;
      color: #3C4759;
    }
    &::-webkit-input-placeholder {
      font-size: 16px;
      color: #3C4759;
    }
    &.invalid {
      border-color: #d32f2f;
    }
  }
  .box-submit {
    text-align: center;
  }
  .btn-submit {
    max-width: 298px;
    @media only screen and (max-width: 479px) {
      max-width: 100%;
    }
  }
`;

const StyledSubmitButton = withStyles({
  root: {
    textAlign: 'center',
    width: '100%',
    height: '48px',
    backgroundColor: '#00853e',
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    letterSpacing: '0.01em',
    fontWeight: 'bold',
    color: '#FFFFFF',
    '&$disabled': {
      color: '#FFFFFF',
    },
    '&:hover': {
      backgroundColor: '#37875F',
    },
  },
  disabled: {
    backgroundColor: '#DADCE0',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

export {
  StylesDialog,
  StylesDialogContent,
  StyledSubmitButton
};
