import React, { useCallback, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import defaultAvatar from 'assets/images/default-product.png';
import { addDotToPrice } from 'utils/common/common';
import { IOrderItem } from 'models/interfaces/order';
import { ProductStock, SpecialOrder } from 'pages/Product/styles';
import { filterPrices } from 'utils/products/productVariant';
import { useSelector } from 'react-redux';
import { priceTypeSelector, selectIsHidePrice } from 'ducks/application/selectors';
import { usePermission } from 'hooks/usePermission';
import last from 'lodash/last';
import { QuantityInput } from './QuantityInput';
import {
  ItemImage,
  ItemMainName,
  ItemNameBlock,
  ItemInfoElementValue,
  ItemInfoElementHeader,
  ItemSubName,
  SingleItem,
  SingleItemInfoWrapper,
  SingleItemInfoElementWrapper,
  MobileInfoWrapper,
  NotAvailableText,
  NotAvailableWrapper,
} from './styles';

interface ISingleOrderItemProps {
  item: IOrderItem;
  isReorder?: boolean;
  setItemQuantity: React.Dispatch<React.SetStateAction<any>>;
  setCountErrorUpdateQuantity: React.Dispatch<React.SetStateAction<any>>;
  setCanReorder: React.Dispatch<React.SetStateAction<any>>;
  itemQuantity: Array<any>;
  selectReorderItems?: Array<number>;
  countErrorUpdateQuantity?: Array<number>;
}

const SingleOrderItem: React.FC<ISingleOrderItemProps> = ({
  item,
  isReorder,
  setItemQuantity,
  setCountErrorUpdateQuantity,
  itemQuantity,
  selectReorderItems,
  countErrorUpdateQuantity,
  setCanReorder,
}: ISingleOrderItemProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const [price, setPrice] = useState(
    parseFloat(
      !isReorder && item?.erpTotalSellPrice ? String(item?.erpTotalSellPrice) : String(item?.total)
    )
  );
  const [isUsePromotionPrice, setIsUsePromotionPrice] = useState(false);
  const deliveryType = useSelector(priceTypeSelector);
  const isHidePrice = useSelector(selectIsHidePrice);
  const { canSeePrice } = usePermission();

  const RequestPricingBlock = () => {
    if (isReorder) {
      if (item?.variant?.isOfflineProductVariant) {
        return (
          <NotAvailableWrapper>
            <NotAvailableText>{t('checkout.product_is_not_available').toString()}</NotAvailableText>
          </NotAvailableWrapper>
        );
      }
    }

    return null;
  };

  const renderUnitPrice = () => {
    if (isReorder) {
      let unitPrice = 0;
      if (item.prices.length > 1) {
        const promotionPrice = last(filterPrices(item.prices));
        const currentPrice = filterPrices(item.prices)[0];
        unitPrice = currentPrice[deliveryType];
        if (isUsePromotionPrice) {
          unitPrice = promotionPrice[deliveryType];
        }
      } else {
        const currentPrice = last(filterPrices(item.prices));
        unitPrice = currentPrice[deliveryType];
      }
      return `$${addDotToPrice(String(unitPrice))}/${item?.variant.units.perName}`;
    }
    if (!isReorder && !item?.unitSellPrice) {
      return '$0/pc';
    }
    return `$${addDotToPrice(String(item?.unitSellPrice))}/${item?.sellUom}`;
  };

  const [renderPrice, setRenderPrice] = useState();
  const [renderPercent, setRenderPercent] = useState();

  React.useEffect(() => {
    if (isReorder && itemQuantity.length > 0 && item.canReorder) {
      const prices = filterPrices(item.prices);
      const currentItem = itemQuantity.find(i => i.id === item.id);
      if (prices.length > 1 && currentItem && currentItem.quantity >= prices[1].qty) {
        const totalOldPrice = currentItem.quantity * prices[0][deliveryType];
        const totalNewPrice = currentItem.quantity * prices[1][deliveryType];
        const percent = Math.ceil(((totalOldPrice - totalNewPrice) / totalOldPrice) * 100);
        if (percent > 0) {
          const percentText = (
            <div
              dangerouslySetInnerHTML={{ __html: t('product_detail.saving_percent', { percent }) }}
            />
          );
          setRenderPercent(percentText);
          setRenderPrice(
            <>
              {totalOldPrice > totalNewPrice && <p>${addDotToPrice(String(totalOldPrice))}</p>}
              <p>${addDotToPrice(String(totalNewPrice))}</p>
            </>
          );
        } else {
          setRenderPrice(`$${addDotToPrice(String(price))}`);
          setRenderPercent(null);
        }
      } else {
        setRenderPrice(`$${addDotToPrice(String(price))}`);
        setRenderPercent(null);
      }
    } else {
      setRenderPrice(`$${addDotToPrice(String(item.totalSellPrice))}`);
      setRenderPercent(null);
    }
  }, [JSON.stringify(itemQuantity), isReorder, deliveryType, isReorder, price]);

  const handleOnClick = useCallback(() => {
    if (!item.canReorder) return;
    if (item.product) {
      window.open(`product/${item.product.erpProductId}`);
    } else {
      window.open(`product/${item.variant.erpProductId}`);
    }
  }, [item.canReorder, item.product, item.variant]);

  const ItemPriceInfoBlock = () => {
    return (
      canSeePrice &&
      !isHidePrice && (
        <>
          <SingleItemInfoElementWrapper>
            <ItemInfoElementHeader>{t('orders_and_quotes.unit_price').toString()}:</ItemInfoElementHeader>
            <ItemInfoElementValue>{renderUnitPrice()}</ItemInfoElementValue>
            {renderPercent}
          </SingleItemInfoElementWrapper>
          <SingleItemInfoElementWrapper>
            <ItemInfoElementHeader>{t('orders_and_quotes.total_price').toString()}:</ItemInfoElementHeader>
            <ItemInfoElementValue bold>{renderPrice}</ItemInfoElementValue>
          </SingleItemInfoElementWrapper>
        </>
      )
    );
  };

  const MobileItemPriceInfoBlock = () => {
    if (!item.productCode || (isReorder && !item.canReorder)) return null;
    return (
      canSeePrice &&
      !isHidePrice && (
        <>
          <SingleItemInfoElementWrapper>
            <ItemInfoElementHeader>{t('orders_and_quotes.qty').toString()}:</ItemInfoElementHeader>
            <ItemInfoElementHeader>{item?.quantity}</ItemInfoElementHeader>
          </SingleItemInfoElementWrapper>
          <ItemInfoElementValue bold>
          $
            {addDotToPrice(
              !isReorder && item?.totalSellPrice
                ? String(item?.totalSellPrice)
                : String(item?.total)
            )}
          </ItemInfoElementValue>
        </>
      ));
  };

  const ReorderItemPriceInfoBlock = () => {
    if (!item.canReorder) {
      return null;
    }
    return item?.variant?.addressPrices?.length && !item?.variant?.isOfflineProductVariant ? (
      <ItemPriceInfoBlock />
    ) : (
      <RequestPricingBlock />
    );
  };

  const MobileReorderItemPriceInfoBlock = () =>
    item?.variant?.addressPrices?.length && !item?.variant?.isOfflineProductVariant ? (
      <MobileItemPriceInfoBlock />
    ) : (
      <RequestPricingBlock />
    );

  const ProductStockElement = () => {
    return item?.inStock ? (
      <ProductStock>
        {item?.inventoryStatus}
      </ProductStock>
    ) : (
      <SpecialOrder>{item?.inventoryStatus}</SpecialOrder>
    );
  };

  const RenderProductStockElement = () => {
    if (isReorder) {
      return item.canReorder ? (
        <ProductStockElement />
      ) : <NotAvailableWrapper>
        <NotAvailableText>{t('checkout.product_is_not_available').toString()}</NotAvailableText>
      </NotAvailableWrapper>
    }
    return null;
  }

  return (
    <SingleItem key={item.id}>
      <ItemImage
        imageUrl={item.productImage ? item.productImage : defaultAvatar}
        canReorder={item.canReorder}
        onClick={handleOnClick}
      />
      {!isMobile ? (
        <SingleItemInfoWrapper canReorder={item.canReorder}>
          <ItemNameBlock onClick={handleOnClick}>
            <ItemMainName>
              {item.productName || item.productDescription ||  item.productCode }
            </ItemMainName>
            {item.productCode && (
              <ItemSubName>
                {t('checkout.sku').toString()}: {item.productCode}
              </ItemSubName>
            )}
            <RenderProductStockElement />
          </ItemNameBlock>
          {isReorder ? (
            <>
              {item.canReorder && (
                <SingleItemInfoElementWrapper>
                  <ItemInfoElementHeader>{t('orders_and_quotes.quantity').toString()}:</ItemInfoElementHeader>
                  <QuantityInput
                    orderItem={item}
                    setPrice={setPrice}
                    setIsUsePromotionPrice={setIsUsePromotionPrice}
                    setItemQuantity={setItemQuantity}
                    itemQuantity={itemQuantity}
                    selectReorderItems={selectReorderItems}
                    setCountErrorUpdateQuantity={setCountErrorUpdateQuantity}
                    countErrorUpdateQuantity={countErrorUpdateQuantity}
                    setCanReorder={setCanReorder}
                  />
                </SingleItemInfoElementWrapper>
              )}
              <ReorderItemPriceInfoBlock />
            </>
          ) : (
            item.productCode && (
              <>
                <SingleItemInfoElementWrapper>
                  <ItemInfoElementHeader>{t('orders_and_quotes.quantity').toString()}:</ItemInfoElementHeader>
                  <ItemInfoElementValue>
                    {item?.quantity} {item?.quantityUom}
                  </ItemInfoElementValue>
                </SingleItemInfoElementWrapper>
                <ItemPriceInfoBlock />
              </>
            )
          )}
        </SingleItemInfoWrapper>
      ) : (
        <SingleItemInfoWrapper>
          <ItemNameBlock onClick={handleOnClick}>
            <ItemMainName>{item.productName || item.productCode || item.productDescription}</ItemMainName>
            {
              item.productCode && (
                <ItemSubName>
                  {t('checkout.sku').toString()}: {item.productCode}
                </ItemSubName>
              )
            }
          </ItemNameBlock>
          <MobileInfoWrapper>
            {isReorder ? <MobileReorderItemPriceInfoBlock /> : <MobileItemPriceInfoBlock />}
          </MobileInfoWrapper>
          {!item.canReorder && isReorder && (
            <NotAvailableWrapper>
              <NotAvailableText>{t('checkout.product_is_not_available').toString()}</NotAvailableText>
            </NotAvailableWrapper>
          )}
        </SingleItemInfoWrapper>
      )}
    </SingleItem>
  );
};

export default React.memo(SingleOrderItem);
