import { Reducer } from 'redux';

// Types
import { GAActionTypes, GAState } from './types';

const initialState: GAState = {
  inStockApplied: false,
  orderSubmitted: false,
  pricingTypeChanged: false,
  ordersOrQuotesVisited: false,
  isFireFeaturedProduct: false,
};

const gaReducer: Reducer<GAState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case GAActionTypes.SET_IN_STOCK_APPLIED: {
      return { ...state, inStockApplied: true };
    }
    case GAActionTypes.SET_ORDER_SUBMITTED: {
      return { ...state, orderSubmitted: true };
    }
    case GAActionTypes.SET_PRICING_TYPE_CHANGED: {
      return { ...state, pricingTypeChanged: true };
    }
    case GAActionTypes.SET_ORDERS_OR_QUOTES_VISITED: {
      return { ...state, ordersOrQuotesVisited: true };
    }
    case GAActionTypes.RESET_STATE: {
      return initialState;
    }
    case GAActionTypes.SET_IS_FIRE_FEATURED_PRODUCT: {
      return { ...state, isFireFeaturedProduct: payload.data };
    }
    default:
      return state;
  }
};

export default gaReducer;
