import React from 'react';

import {
  DescriptionTab,
  SpecificationTable,
  SpecPropertyWrapper,
  SpecPropertyText,
} from '../styles';

export const SpecificationsTab = ({
  variant,
  fullWidth
}) => {

  return (
    <div role='tabpanel' style={{width: fullWidth ? '100%': 'auto'}}>
      <DescriptionTab>
        <SpecificationTable>
          {variant.mergedAttributes.length
            ? variant.mergedAttributes.map(option =>
              !option.specHide ? (
                <SpecPropertyWrapper key={option.code}>
                  <SpecPropertyText isName>{option.name}</SpecPropertyText>
                  <SpecPropertyText isName={false}>{option.value}</SpecPropertyText>
                </SpecPropertyWrapper>
              ) : null,
            )
            : null}
        </SpecificationTable>
      </DescriptionTab>
    </div>
  );
};
