import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import axios from 'utils/axios';
import get from 'lodash/get';
import {storeErrors} from 'utils/errors';
import {getDefaultInputValues} from 'utils/cart/cart';
import {
  AddItemToQuickOrderAction,
  BulkAddToCartAction,
  QuickOrderActionTypes, RemoveQuickOrderItemAction, ReplaceQuickOrderItemAction,
  UpdateQuickOrderItemQuantityAction,
} from './types';
import {selectAddressId} from '../application/selectors';
import {storeCartFirstLoad} from '../cart/actions';
import {CartActionTypes} from '../cart/types';
import {bulkAddToCartSuccess} from './actions';


function* bulkAddToCart(action: BulkAddToCartAction) {
  const address = yield select(selectAddressId);
  try {
    const addToCartReq = yield call(axios.post, '/api/auth/cart/bulk-add-to-cart', action.payload, {
      headers: {
        'X-Address-Id': address,
      },
    });
    yield put(storeCartFirstLoad(addToCartReq.data, getDefaultInputValues(addToCartReq.data.data)));
    yield put(bulkAddToCartSuccess(action.payload.products));
  } catch (error) {
    if (get(error.response, 'data.data.forceRefresh')) {
      window.location.reload();
    }
    yield storeErrors(CartActionTypes.ADD_PRODUCT_ERROR, [403, 404, 400], true);
  }
}

function* addQuickOrderItem(action: AddItemToQuickOrderAction) {
  const address = yield select(selectAddressId);
  try {
    const { data } = yield call(axios.post, '/api/auth/quick-order/add-quick-order-item', action.payload, {
      headers: {
        'X-Address-Id': address,
      },
    });
    yield put({
      type: QuickOrderActionTypes.ADD_ITEM_TO_QUICK_ORDER_SUCCESS,
      payload: {
        quickOrderItem: data.data,
      },
    })
  } catch (error) {
    if (get(error.response, 'data.data.forceRefresh')) {
      window.location.reload();
    }
    yield storeErrors(CartActionTypes.ADD_PRODUCT_ERROR, [403, 404, 400], true);
  }
}

function* getQuickOrderItems() {
  const address = yield select(selectAddressId);
  try {
    const quickOrderItems = yield call(axios.get, '/api/auth/quick-order/get-quick-order', {
      headers: {
        'X-Address-Id': address,
      },
    });
    yield put({
      type: QuickOrderActionTypes.REQUEST_GET_QUICK_ORDER_ITEMS_SUCCESS,
      payload: {
        quickOrderItems: quickOrderItems.data.data,
      },
    });
  } catch (error) {
    if (get(error.response, 'data.data.forceRefresh')) {
      window.location.reload();
    }
    yield put({
      type: QuickOrderActionTypes.REQUEST_GET_QUICK_ORDER_ITEMS_ERROR,
    });
  }
}

function* updateQuickOrderItemQuantity(action: UpdateQuickOrderItemQuantityAction) {
  const address = yield select(selectAddressId);
  try {
    yield call(axios.put, '/api/auth/quick-order/update-quick-order-item-quantity', action.payload, {
      headers: {
        'X-Address-Id': address,
      },
    });
    yield put({
      type: QuickOrderActionTypes.UPDATE_QUICK_ORDER_ITEM_QUANTITY_SUCCESS,
      payload: {
        quickOrderItemId: action.payload.quickOrderItemId,
        quantity: action.payload.quantity,
      },
    })
  } catch (error) {
    if (get(error.response, 'data.data.forceRefresh')) {
      window.location.reload();
    }
    yield storeErrors(CartActionTypes.ADD_PRODUCT_ERROR, [403, 404, 400], true);
  }
}

function* removeQuickOrderItem(action: RemoveQuickOrderItemAction) {
  const address = yield select(selectAddressId);
  try {
    yield call(axios.delete, `/api/auth/quick-order/delete-quick-order-item/${action.payload.quickOrderItemId}`, {
      headers: {
        'X-Address-Id': address,
      },
    });
    yield put({
      type: QuickOrderActionTypes.REQUEST_REMOVE_QUICK_ORDER_ITEM_SUCCESS,
      payload: {
        quickOrderItemId: action.payload.quickOrderItemId,
      },
    });
  } catch (error) {
    if (get(error.response, 'data.data.forceRefresh')) {
      window.location.reload();
    }
    yield storeErrors(CartActionTypes.ADD_PRODUCT_ERROR, [403, 404, 400], true);
  }
}

function* replaceQuickOrderItem(action: ReplaceQuickOrderItemAction) {
  const address = yield select(selectAddressId);
  try {
    const { data } = yield call(axios.put, '/api/auth/quick-order/replace-quick-order-item', action.payload, {
      headers: {
        'X-Address-Id': address,
      },
    });
    yield put({
      type: QuickOrderActionTypes.REQUEST_REPLACE_QUICK_ORDER_ITEM_SUCCESS,
      payload: {
        quickOrderItem: data.data,
      },
    });
  } catch (error) {
    if (get(error.response, 'data.data.forceRefresh')) {
      window.location.reload();
    }
    yield storeErrors(CartActionTypes.ADD_PRODUCT_ERROR, [403, 404, 400], true);
  }
}

function* removeQuickOrder() {
  const address = yield select(selectAddressId);
  try {
    yield call(axios.delete, '/api/auth/quick-order/remove-quick-order', {
      headers: {
        'X-Address-Id': address,
      },
    });
    yield put({
      type: QuickOrderActionTypes.REQUEST_CLEAR_QUICK_ORDER_SUCCESS,
    });
  } catch (error) {
    if (get(error.response, 'data.data.forceRefresh')) {
      window.location.reload();
    }
    yield storeErrors(CartActionTypes.ADD_PRODUCT_ERROR, [403, 404, 400], true);
  }
}

function* watchRemoveQuickOrder() {
  yield takeLatest(QuickOrderActionTypes.REQUEST_CLEAR_QUICK_ORDER, removeQuickOrder);
}

function* watchReplaceQuickOrderItem() {
  yield takeLatest(QuickOrderActionTypes.REQUEST_REPLACE_QUICK_ORDER_ITEM, replaceQuickOrderItem);
}

function* watchRemoveQuickOrderItem() {
  yield takeLatest(QuickOrderActionTypes.REQUEST_REMOVE_QUICK_ORDER_ITEM, removeQuickOrderItem);

}

function* watchUpdateQuickOrderItemQuantity() {
  yield takeLatest(QuickOrderActionTypes.UPDATE_QUICK_ORDER_ITEM_QUANTITY, updateQuickOrderItemQuantity);
}

function* watchGetQuickOrderItems() {
  yield takeLatest(QuickOrderActionTypes.REQUEST_GET_QUICK_ORDER_ITEMS, getQuickOrderItems);
}

function* watchAddQuickOrderItem() {
  yield takeLatest(QuickOrderActionTypes.ADD_ITEM_TO_QUICK_ORDER, addQuickOrderItem);
}

function* watchBulkAddToCart() {
  yield takeLatest(QuickOrderActionTypes.REQUEST_BULK_ADD_TO_CART, bulkAddToCart);
}

export default function* quickOrderSaga() {
  yield all([
    watchBulkAddToCart(),
    watchAddQuickOrderItem(),
    watchGetQuickOrderItems(),
    watchUpdateQuickOrderItemQuantity(),
    watchRemoveQuickOrderItem(),
    watchReplaceQuickOrderItem(),
    watchRemoveQuickOrder(),
  ]);
}