import styled from 'styled-components';
import {
  Checkbox,
  CheckboxProps,
  DialogContent,
  FormControl,
  Select,
  TextField,
  withStyles,
} from '@material-ui/core';
import React from 'react';

const PopUpBackground = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { colors } }) => colors.blackOpacity(0.3)};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1500;
`;

const StyledDialogContent = styled(DialogContent)`
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.01em;
  padding: 50px 60px !important;
  width: 484px;
  border-radius: 6px;
  @media (max-width: 600px) {
    width: 320px;
    padding: 16px 20px !important;
  }
`;

const PopUpWrapper = styled.div`
  width: 484px;
  min-height: 750px;
  display: flex;
  flex-direction: column;
  padding: 50px 60px;
  background: ${({ theme: { colors } }) => colors.white};
  border-radius: 6px;
  position: relative;
  max-height: 100%;
  overflow-y: auto;

  @media (max-width: 1024px) {
    overflow: scroll;
    display: inline-block;
    min-height: initial;
  }
  @media (max-width: 600px) {
    width: 320px;
    padding: 16px 20px;
  }
`;

const PopUpHeader = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0.01em;
  margin: 0;

  @media (max-width: 600px) {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
  }
`;

const PopUpHelperText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.textColor};
  margin-top: 25px;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    margin-top: 16px;
  }
`;

const StepTitle = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.01em;

  color: ${({ theme: { colors } }) => colors.textColor};
  margin-top: 32px;
  margin-bottom: 24px;

  @media (max-width: 600px) {
    margin: 24px 0;
  }
`;

const PopUpContentWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 510px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  @media only screen and (max-width: 600px) {
    min-height: 460px;
  }
  .MuiAutocomplete-popper {
    position: absolute;
    left: 0;
    top: 48px;
    .MuiAutocomplete-option {
      &[aria-selected="true"] {
        position: relative;
        color: ${({ theme: { colors } }) => colors.mainGreen};
        background: none;
        padding-right: 20px;
        &:after {
          content: '✓';
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }
  }
`;

const StyledTextField = withStyles({
  root: {
    margin: '0 0 33px 0',
    '&:last-child': {
      margin: 0,
    },
    '& .MuiInputBase-root': {
      fontFamily: 'Open Sans',
      height: '48px',
    },
    '& .MuiInputLabel-outlined': {
      fontFamily: 'Open Sans',
    },
    '& .MuiInputBase-multiline': {
      height: 'auto',
    },
    '& .MuiInputLabel-root': {
      fontFamily: 'Open Sans',
      color: '#939599',
      transform: 'translate(14px, 18px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '17px 14px',
    },
    '& .Mui-focused': {
      backgroundColor: '#F7F7F7',
    },
    '& .Mui-focused.MuiFormLabel-root': {
      backgroundColor: '#FFFFFF',
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      borderColor: '#DADEE4',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#000000',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#C4C4C4',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00853e',
        },
      },
    },
    '@media (max-width: 1023px)': {
      margin: '0 0 16px 0',
      '&:last-child': {
        margin: 0,
      },
    },
    '@media (max-width: 320px)': {
      margin: '0 0 16px 0',
      '&:last-child': {
        margin: 0,
      },
      '& .MuiInputBase-root': {
        height: '40px',
      },
      '& .MuiInputBase-multiline': {
        height: 'auto',
      },
      '& .MuiInputLabel-root': {
        transform: 'translate(14px, 12px) scale(1)',
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -8px) scale(0.75)',
      },
    },
  },
})(TextField);

const AddressInfo = styled.div`
  min-height: 321px;
  width: 100%;
  padding: 25px 37px 24px 24px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  background: ${({ theme: { colors } }) => colors.lightGrey};
  border-radius: 4px;

  @media (max-width: 600px) {
    min-height: 282px;
    padding: 20px 27px 20px 20px;
  }
`;

const AddressName = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.textColor};
  margin-bottom: 15px;
  margin-top: 0;

  @media (max-width: 600px) {
    margin-bottom: 14px;
    font-size: 16px;
    line-height: 16px;
  }
`;

const AddressProperty = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: ${({ theme: { colors } }) => colors.textColor};
  margin-bottom: 15px;
  margin-top: 0;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 600px) {
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 19px;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  height: 48px;
  border: none;
  background: ${({ theme: { colors } }) => colors.mainGreen};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  outline: none;

  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: ${({ theme: { colors } }) => colors.white};
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 600px) {
    min-height: 48px;
    margin-top: 20px;

    p {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const StyledSelect = styled(Select)`
  &.MuiInputBase-root {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
  }

  .MuiOutlinedInput-input.MuiOutlinedInput-input {
    padding-top: 12px;
    padding-bottom: 14px;
    padding-left: 16px;
  }

  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  }

  &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme: { colors } }) => colors.blackOpacity(0.23)};
  }

  @media (max-width: 1024px) {
    &.MuiInputBase-root {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0;
    }

    .MuiOutlinedInput-input.MuiOutlinedInput-input {
      padding-top: 13px;
      padding-bottom: 13px;
      padding-left: 12px;
    }
  }
`;

const StyledFormControl = styled(FormControl)`
  .MuiFormLabel-root.Mui-focused.MuiFormLabel-root.Mui-focused {
    color: ${({ theme: { colors } }) => colors.grey};
  }

  @media (max-width: 600px) {
    min-height: 50px;
    margin-top: 6px;
  }
`;

const CheckboxesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    margin-top: 16px;
  }
`;

const Circle = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid ${({ theme: { colors } }) => colors.buttonGrey};
  border-radius: 50%;
`;

const CheckedCircle = styled.div`
  width: 16px;
  height: 16px;
  border: 4px solid ${({ theme: { colors } }) => colors.circleGreen};
  border-radius: 50%;
`;

const CheckboxTitle = styled.p`
  margin: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  margin-left: 10px;
`;

const StyledCheckbox = withStyles({
  root: {
    width: 'fit-content',
    height: 'fit-content',
    padding: '0',
    '&:hover': {
      background: 'none',
      cursor: 'pointer',
    },
    '&:last-child': {
      marginTop: '26px',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const SingleCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  &:last-child {
    margin-top: 20px;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-content: center;
`;

const StepBoxContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StepBox = styled.div`
  width: 6px;
  height: 4px;
  border-radius: 1px;
  background-color: ${({ theme: { colors } }) => colors.switchCheckedGreen};
  margin: 0 2px;
`;
const StepBoxLight = styled.div`
  width: 6px;
  height: 4px;
  border-radius: 1px;
  opacity: 0.4;
  background-color: ${({ theme: { colors } }) => colors.switchCheckedGreen};
  margin: 0 2px;
`;

const GreenCheckbox = withStyles({
  root: {
    height: '24px',
    width: '24px',
    color: '#DADEE4',
    '&$checked': {
      color: '#00853e',
    },
    '&.MuiIconButton-root': {
      padding: '0 9px 0 0',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {},
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export {
  PopUpBackground,
  PopUpWrapper,
  PopUpHeader,
  PopUpHelperText,
  PopUpContentWrapper,
  StyledTextField,
  AddressInfo,
  AddressName,
  AddressProperty,
  StyledButton,
  StyledSelect,
  CheckboxesWrapper,
  StyledFormControl,
  Circle,
  CheckedCircle,
  CheckboxTitle,
  StyledCheckbox,
  SingleCheckboxWrapper,
  StepTitle,
  CheckboxWrapper,
  GreenCheckbox,
  StepBoxContainer,
  StepBox,
  StepBoxLight,
  StyledDialogContent,
};
