import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

// @ts-ignore
const StyledSvg = styled(SvgIcon)`
  pointer-events: none;
  position: absolute;
  transform: translate(0%, -20%);
  top: 50%;
  right: 8px;
`;

export const Arrow: React.FC = () => {
  return (
    <StyledSvg>
      <svg
        width="19"
        height="10"
        viewBox="0 0 19 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L9.5 9L18 1"
          stroke="#333333"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </StyledSvg>
  );
};
