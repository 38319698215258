import React, { useEffect } from 'react';

import ProfileLoader from 'components/common/Loader/ProfileLoader';
import NoResults from 'pages/General/Profile/components/OrdersAndQuotes/NoResults/NoResults';
import { useProfileOrders } from 'api/Order';
import { Content } from 'pages/General/Profile/components/OrdersAndQuotes/Content/Content';
import { useOrdersFilter, useSetOrdersOrQuotesVisit } from 'pages/General/Profile/hooks/hooks';
import { OrderClass } from 'models/classes/orderClass';
import { orderTabClickGAEvent } from 'GA/OrderGA/OrderGA';
import { useTranslation } from 'react-i18next';
import { OrdersContainer } from './styles';

interface IOrders {
  orderToOpen: number;
}

const Orders: React.FC<IOrders> = ({ orderToOpen }: IOrders) => {
  const { t } = useTranslation();
  const searchOrderCondition = orderToOpen
    ? {
      sort: 'DESC',
      input: orderToOpen.toString(),
    }
    : {
      sort: 'DESC',
    };
  const { filterParams, handleFilterChange, isApplyFilterInput, setIsApplyFilterInputInput } = useOrdersFilter(searchOrderCondition);

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    status,
    isFetching,
  } = useProfileOrders(filterParams);


  useSetOrdersOrQuotesVisit();

  useEffect(() => {
    orderTabClickGAEvent();
  }, []);

  const orderPages = data ? {
    ...data,
    pages: data?.pages?.map((page, index) => {
      return {
        ...page,
        data: page.data.map(order => new OrderClass(order)),
        pageId: index,
      };
    }),
  } : undefined;

  if (isFetching && !isFetchingNextPage) {
    return (
      <OrdersContainer>
        <ProfileLoader />
      </OrdersContainer>
    );
  }

  const firstPage = orderPages?.pages[0];
  if (!firstPage?.data?.length && Object.keys(filterParams).length === 1) {
    return <NoResults typeOfOrder={t('profile.orders').toLowerCase()} />;
  }

  return (
    <OrdersContainer>
      <Content
        onFilterChange={handleFilterChange}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        data={orderPages}
        status={status}
        filterParams={filterParams}
        isApplyFilterInput={isApplyFilterInput}
        setIsApplyFilterInputInput={setIsApplyFilterInputInput}
      />
    </OrdersContainer>
  );
};

export default Orders;
